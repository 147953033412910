import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {GrooveButton,} from "tfo-groove";

const CVMSaveModal = (function () {
  class CVMWarningModalPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="stepperClose-Alert">
            <div className="stepperClose-Alert-Content">
              <div className="stepperClose-Alert-Icon">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <div className="stepperClose-Alert-text">
                <p>{this.props.body}</p>
                <br />
                <p>{this.props.bodySecondary}</p>
              </div>
            </div>
          </div>
          {
            this.props.hideButtons ?            
            "" 
            :
            <div className="notifyModal-buttons">
              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="outline"
                colorClass="stat-alternate"
                size="auto"
                text="Cancel"
                callbackFunction={() => this.props.handleCancel()}
              />

              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={this.props.disableSendBtn}
                hasIcon={false}
                type="solid"
                colorClass="stat-alternate"
                size="auto"
                text="Save"
                callbackFunction={() => this.props.handleYes()}
              />
            </div>
          }
        </React.Fragment>
      );
    }
  }

  class CVMWarningModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount = () => { };

    render() {
      return (
        <CVMWarningModalPresentational
          handleCancel={this.props.handleCancel}
          handleYes={this.props.handleYes}
          hideButtons={this.props.hideButtons}
          body={this.props.body}
          bodySecondary={this.props.bodySecondary}
          disableSendBtn={this.props.disableSendBtn || false}
        />
      );
    }
  }

  return CVMWarningModalContainer;
})();

export default CVMSaveModal;
