import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { GrooveButton } from 'tfo-groove';
import { Card } from 'react-bootstrap';

import Download from '../../Static/images/360cvm/download.svg';
import WheelInsideLogo_withborder from '../../Static/images/360cvm/WheelInsideLogo_withborder.svg';
import WheelLogo from '../../Static/images/360cvm/WheelInsideLogo_withborder.svg';
import WheelBG from '../../Static/images/360cvm/WheelBGimg.PNG';
import HeaderBG from '../../Static/images/360cvm/ReportHeaderBG.PNG';
import SynopsLogo from '../../Static/images/360cvm/SynOps.svg';
import ReportFooter from '../../Static/images/360cvm/ReportFooter.PNG';
import PPTGenerator from '../Shared/PPTGenerator/PPTGenerator';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TryGetObjValueAlt } from '../../Utilities/Formatter';
import { ScreenWidth as sw } from '../../Utilities/BrowserUtil';
import { HasValue, IsValid } from '../../Utilities/Validations';
import { SliceLableText } from './DashboardUtil';

import './Dashboard.css';

const SummaryWheel = (function () {
  const masterDim = {
    experience: 'Experience',
    sustainability: 'Sustainability',
    talent: 'Talent',
    inclusionDiversity: 'Inclusion & Diversity',
    custom: 'Custom',
    financial: 'Financial',
  };

  const panelSize = {
    exp: 1,
    norm: 2,
    comp: 3,
  };

  const sdFontColor = [
    { dimension: 'Financial', fill: 'rgb(74, 33, 93)', color: '4a215d' },
    { dimension: 'Custom', fill: 'rgb(133, 68, 154)', color: '85449a' },
    { dimension: 'Inclusion & Diversity', fill: 'rgb(109, 65, 153)', color: '6d4199' },
    { dimension: 'Experience', fill: 'rgb(149, 77, 158)', color: '954d9e' },
    { dimension: 'Sustainability', fill: 'rgb(113, 69, 118)', color: '714576' },
    { dimension: 'Talent', fill: 'rgb(149, 77, 158)', color: '954d9e' },
  ];

  class SummaryWheelPresentational extends Component {
    getLabelWidth = (custSDimCount) => {
      let lableWidths = {
        label1: 61,
        label2: 86,
        dots: 6.78,
        elip: 2.69,
      };

      switch (custSDimCount) {
        case 1:
          lableWidths =
            sw <= 1280
              ? { label1: 61, label2: 86, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 104, label2: 149, dots: 6.92, elip: 3.22 }
                : { label1: 107, label2: 158, dots: 6.78, elip: 3.02 };
          break;
        case 2:
          lableWidths =
            sw <= 1280
              ? { label1: 61, label2: 59, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 83, label2: 78, dots: 6.92, elip: 3.22 }
                : { label1: 86, label2: 75, dots: 6.78, elip: 3.02 };
          break;
        case 3:
          lableWidths =
            sw <= 1280
              ? { label1: 38, label2: 35, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 55, label2: 49, dots: 6.92, elip: 3.22 }
                : { label1: 53, label2: 50, dots: 6.78, elip: 3.02 };
          break;
        default:
          lableWidths =
            sw <= 1280
              ? { label1: 28, label2: 28, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 35, label2: 35, dots: 6.92, elip: 3.22 }
                : { label1: 32, label2: 32, dots: 6.78, elip: 3.02 };
          break;
      }
      return lableWidths;
    };

    getCustomSubdim = (customSDims) => {
      let staticCusSD = [
        {
          name: 'Control & Compliance',
          value: 100,
          fill: '#a100ff',
          DimID: 5,
          sDimID: 0,
          posAndAngle: 250,
        },
        {
          name: 'Efficiency',
          value: 100,
          fill: '#a100ff',
          DimID: 5,
          sDimID: 0,
          posAndAngle: 270,
        },
        {
          name: 'Speed & Agility',
          value: 100,
          fill: '#a100ff',
          DimID: 5,
          sDimID: 0,
          posAndAngle: 290,
        },
      ]
      try {
        let customSDim = customSDims;
        const cusSDimCount = customSDim.length;       
        
        if (cusSDimCount > 0) {
          let sdCount = 0;
          let tempArr = [];
          // Sort array with number of BOIs
          customSDim.sort((sd1, sd2) => { return sd2.boiList.length - sd1.boiList.length});

          if (cusSDimCount >= 3) {
            // If 3 or more data then display only top 3
            customSDim.forEach((sDim, i) => {
              if (sdCount < 3) {
                let cusSlice = i === 0 ? 250 : i === 1 ? 270 : 290;
                tempArr.push({
                  name: sDim.subDim,
                  value: 100,
                  fill: '#a100ff',
                  DimID: 5,
                  posAndAngle: cusSlice,
                });
                sdCount++;
              }
            })
            customSDim = tempArr;
          } else {
            let subdims = [];
            // fill array with non static data
            customSDim.forEach((sDim, i) => {
              if (sdCount < 3) {
                subdims.push({
                  name: sDim.subDim,
                  subDimID: sDim.subDimID
                })
                sdCount++;
              }
            });
            
            // Get static data
            staticCusSD.forEach((staticSD) => {
              let isDuplicate = subdims.some((tmpSD) => tmpSD.name === staticSD.name);
              if (sdCount < 3 && !isDuplicate) {
                subdims.push({
                  name: staticSD.name,
                  subDimID: 0
                })
                sdCount++;
              }
            });
            // subdims.reverse();
            subdims.forEach((subdim, i) => {
              let cusSlice = i === 0 ? 250 : i === 1 ? 270 : 290;
              tempArr.push({
                name: subdim.name,
                value: 100,
                fill: '#a100ff',
                DimID: 5,
                posAndAngle: cusSlice,
              }); 
            })
            customSDim = tempArr;
          }
        } else {
          // If no data then display 3 static data
          customSDim = staticCusSD
        }
        return customSDim;
      } catch (e) {
        console.log(e);
        return staticCusSD;
      }
    }

    render() {
      let customSDim = this.props.panelsLeft.find((dim) => dim.dim === masterDim.custom);
      customSDim = TryGetObjValueAlt(customSDim, [], 'subDimList');
      const cusSDimCount = 3;//.length;
      // let cusSlice = 270;
      // const cusSliceValue = cusSDimCount < 4 ? 300 : 305 - 10 * (cusSDimCount - 3);
      customSDim = this.getCustomSubdim(customSDim);
        /* cusSDimCount === 0
          ? [
            {
              name: '',
              value: 300,
              fill: '#a100ff',
              DimID: 5,
              sDimID: 0,
              posAndAngle: 0,
            },
          ]
          : customSDim.map((sDim, i) => {
            switch (cusSDimCount) {
              case 1:
                cusSlice = 270;
                break;
              case 2:
                cusSlice = i === 0 ? 253 : 283;
                break;
              case 3:
                cusSlice = i === 0 ? 250 : i === 1 ? 270 : 290;
                break;
              case 4:
                cusSlice = i === 0 ? 336 : i === 1 ? 356 : i === 2 ? 369 : 386;
                break;
              case 5:
                cusSlice = i === 0 ? 330 : i === 1 ? 345 : i === 2 ? 360 : i === 3 ? 375 : 385;
                break;
              case 6:
                cusSlice =
                  i === 0
                    ? 335
                    : i === 1
                      ? 345
                      : i === 2
                        ? 355
                        : i === 3
                          ? 365
                          : i === 4
                            ? 375
                            : 385;
                break;
              default:
                cusSlice = i === 0 ? 320 + 60 / cusSDimCount : cusSlice;
                cusSlice += 60 / cusSDimCount;
                break;
            }
            return {
              name: sDim.subDim,
              value: cusSliceValue / cusSDimCount,
              fill: '#a100ff',
              DimID: 5,
              posAndAngle: cusSlice,
            };
          }); */
      const dimListMaster = [
        { ID: 1, name: 'Experience', value: 300, fill: '#A055F5' },
        { ID: 2, name: 'Sustainability', value: 300, fill: '#595959' },
        { ID: 3, name: 'Talent', value: 300, fill: '#460073' },
        { ID: 4, name: 'Inclusion & Diversity', value: 300, fill: '#7500C1' },
        { ID: 5, name: 'Custom', value: 300, fill: '#a100ff' },
        { ID: 6, name: 'Financial', value: 300, fill: '#B455AA' },
      ];

      const suDimListRing = [
        { name: 'Customer', value: 100, fill: '#A055F5', DimID: 1, posAndAngle: 10 },
        { name: 'Employee', value: 100, fill: '#A055F5', DimID: 1, posAndAngle: 30 },
        {
          name: 'Vendor/ Partner',
          label: 'Vendor/ Partner',
          value: 100,
          fill: '#A055F5',
          DimID: 1,
          posAndAngle: 50,
        },
        {
          name: 'Environmental',
          label: 'Environ mental',
          value: 100,
          fill: '#595959',
          DimID: 2,
          posAndAngle: 75,
        },
        { name: 'Social', value: 90, fill: '#595959', DimID: 2, posAndAngle: 90 },
        { name: 'Governance', value: 105, fill: '#595959', DimID: 2, posAndAngle: 110 },
        {
          name: 'Development',
          value: 155,
          fill: '#460073',
          DimID: 3,
          posAndAngle: 320,
          topCircle: true,
        },
        {
          name: 'Change',
          value: 150,
          fill: '#460073',
          DimID: 3,
          posAndAngle: 340,
          topCircle: true,
        },
        {
          name: 'Team Diversity',
          value: 100,
          fill: '#7500C1',
          DimID: 4,
          posAndAngle: 10,
          topCircle: true,
        },
        {
          name: 'Inclusive Design',
          value: 100,
          fill: '#7500C1',
          DimID: 4,
          posAndAngle: 30,
          topCircle: true,
        },
        {
          name: 'Inclusive Culture',
          value: 100,
          fill: '#7500C1',
          DimID: 4,
          posAndAngle: 50,
          topCircle: true,
        },
        ...customSDim,
        { name: 'Capital', value: 100, fill: '#B455AA', DimID: 6, posAndAngle: 310 },
        { name: 'Opex', value: 100, fill: '#B455AA', DimID: 6, posAndAngle: 330 },
        { name: 'Revenue', value: 100, fill: '#B455AA', DimID: 6, posAndAngle: 350 },
      ];
      let data01b = dimListMaster.map((item) => {
        return {
          ID: item.ID,
          name: item.name,
          fill: item.ID % 2 == 0 ? '#eff2f5' : '#e2e2e2',
          value: item.value,
        };
      });

      const data02b = suDimListRing.map((item) => {
        return {
          name: item.name,
          value: item.value,
          fill: item.DimID % 2 === 0 ? '#eff2f5' : '#e2e2e2',
          DimID: item.DimID,
        };
      });

      let centre = [{ name: 'Centre', value: 1 }];

      const RADIAN = Math.PI / 180;

      const renderDimLabel = ({
        cx,
        cy,
        midAngle,
        startAngle,
        innerRadius,
        outerRadius,
        index,
      }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        let a = 0;
        let b = 0;
        if (index === 0 || index === 3) {
          a = 30;
        } else if (index === 1) {
          a = 90;
        } else if (index === 4) {
          a = -90;
        } else {
          a = -35;
        }
        if (index == 2 || index == 3) {
          b = 5;
        } else {
          b = -5;
        }
        return (
          /*Dimnension*/
          <text
            x={x}
            y={y + b}
            fill="black"
            scaleToFit={false}
            textAnchor="middle"
            dominantBaseline="central"
            style={{}}
            fontSize={9}
            fontWeight={900}
            width={12}
            transform={'rotate(' + a + ' ' + x + ' ' + y + ')'}
          >
            {dimListMaster[index].name.indexOf('&') === -1 ? (
              dimListMaster[index].name
            ) : (
              <>
                <tspan x={x} y={y + b - 13}>
                  {dimListMaster[index].name.split(' ')[0]}
                </tspan>
                <tspan x={x} y={y + b}>
                  &
                </tspan>
                <tspan x={x} y={y + b + 10}>
                  {dimListMaster[index].name.split(' ')[2]}
                </tspan>
              </>
            )}
          </text>
        );
      };

      const renderSubDimLabel = ({
        cx,
        cy,
        midAngle,
        startAngle,
        endAngle,
        innerRadius,
        outerRadius,
        index,
      }) => {
        const isCustom = suDimListRing[index].DimID === 5;
        const radius = innerRadius + (outerRadius - innerRadius) * (isCustom ? 0.6 : 0.5);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const ar = innerRadius + (outerRadius - innerRadius) * 0.2;
        const ax = cx + ar * Math.cos(-midAngle * RADIAN);
        const ay = cy + ar * Math.sin(-midAngle * RADIAN);
        const a = suDimListRing[index].posAndAngle;
        let oneLinerLabelSpace = 5;

        let datalabel = HasValue(suDimListRing[index])
          ? HasValue(suDimListRing[index].label)
            ? suDimListRing[index].label
            : suDimListRing[index].name
          : '';
        let label = [];
        let searchKey = datalabel.search('&');
        if (searchKey > -1 && !isCustom) {
          label.push(datalabel.slice(0, searchKey + 1));
          label.push(datalabel.slice(searchKey + 1));
        } else if (isCustom) {
          label = SliceLableText(
            datalabel,
            this.getLabelWidth(cusSDimCount),
            cusSDimCount < 7 ? 2 : 1
          );
          oneLinerLabelSpace = label.length === 1 ? 0 : oneLinerLabelSpace; //IF COUNT OF LABELS IS 2 THEN ALLOW 2 LINES
        } else {
          label = datalabel.split(' ');
        }

        let newY = suDimListRing[index].topCircle ? y + 8 : y;

        return (
          /*SubDimnension*/
          <>
            <text
              x={x}
              y={newY}
              fill="black"
              textAnchor="middle"
              dominantBaseline="top"
              scaleToFit={true}
              fontSize={9}
              fontWeight={500}
              width={12}
              transform={'rotate(' + a + ' ' + x + ' ' + y + ')'}
            >
              <title>{datalabel}</title>
              <tspan x={x} y={newY - oneLinerLabelSpace} alignmentBaseline="middle" fontSize="8">
                {label[0]}
              </tspan>
              {label.length > 1 && (
                <tspan fontSize="8" x={x} y={newY + 5}>
                  {label[1]}
                </tspan>
              )}
            </text>
            <circle cx={ax} cy={ay} r={3} fill={suDimListRing[index].fill} stroke="none" />
          </>
        );
      };
      const renderLogo = ({ cx, cy, midAngle, innerRadius, outerRadius }) => {
        return (
          <svg x="37.59%" y="22.22%" dy={5} textAnchor={'middle'} width="25%" height="12%">
            <image href={WheelInsideLogo_withborder} height="100%" width="100%" />
          </svg>
        );
      };

      return (
        <React.Fragment>
          {!this.props.isGlobal ? (
            <PPTGenerator
              docuID="svmOverview-page"
              preDLFunction={this.props.preDLFunction}
              postDLFunction={this.props.postDLFunction}
              postDLFunctionErr={this.props.postDLFunction}
              image={[WheelBG, HeaderBG, SynopsLogo, ReportFooter, WheelLogo]}
              data={this.props.dimensionList}
              dimColors={this.props.sdFontColor}
              programName={this.props.programName}
              reportingYear={this.props.reportingYear}
              dashboardProgramDetails={this.props.dashboardProgramDetails}
              dashboardClientDetails={this.props.dashboardClientDetails}
            >
              <img src={Download} alt="download" /> Download Report
            </PPTGenerator>
          ) : (
            ''
          )}

          <hr id="hrTop" />
          <div id="svmOverview-page" className="svmOverview-page">
            <div className="row" id="wheel-box">
              <div className="col-lg-3">
                {
                  //FINANCIAL, CUSTOM, INC & DIVERSITY
                  this.props.panelsLeft.map((dim, dimI) => {
                    //NO DATA
                    if (dim.subDimList.length === 0)
                      return (
                        <div key={dimI} className="dimension-card">
                          <div className="dm-card-header" style={{ background: '#b2b2b2' }}>
                            {dim.dim}
                          </div>
                          {dim.expandType === panelSize.norm && (
                            <div className="dm-card-box-disabled">No Data Available</div>
                          )}
                        </div>
                      );

                    //HAS DATA
                    return (
                      <div key={dimI} className="dimension-card">
                        <div
                          className="dm-card-header"
                          style={{
                            background: dim.fill,
                            cursor: dim.expandType === panelSize.comp ? 'pointer' : '',
                          }}
                          onClick={
                            dim.expandType === panelSize.comp
                              ? () =>
                                this.props.onChangePanelSize(
                                  'A',
                                  this.props.panelsLeft,
                                  dim.dimID,
                                  dim.expandType
                                )
                              : () => { }
                          }
                        >
                          {dim.dim}
                        </div>
                        {dim.expandType !== panelSize.comp && (
                          <div className="dm-card-box" id={`boxID${dimI}`}>
                            <div
                              className="dm-card-details"
                              style={{
                                maxHeight:
                                  dim.expandType === panelSize.exp
                                    ? 'none'
                                    : dim.expandType === panelSize.norm
                                      ? dim.boiPanelMaxHeight //'142.2px'
                                      : dim.boiPanelMaxHeight + 10,//'152px',s
                              }}
                            >
                              {dim.subDimList.map((sDim, sInd) => {
                                  //REVENUE, OPEX, CAPITAL
                                  return (
                                    <>
                                      <div className="db-subdim-container">
                                        <h6
                                          className="dashboard-subdim"
                                          style={{
                                            color: dim.fill,
                                          }}
                                        >
                                          {sDim.subDim}:
                                        </h6>
                                      </div>
                                      <div className="dashboard-boi">
                                        {sDim.boiList.map((boi, boiI) => {
                                          return (
                                            <>
                                              {boi.details.length === 1 ? (
                                                <div>
                                                  <div style={{ display: 'flex' }}>
                                                    <div className={'boi-title'} title={boi.boi}>
                                                      {boi.boi}
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                      {boi.details.map((detail) => {
                                                        return (
                                                          <>
                                                            {' '}
                                                            <span className="boi-detail boi-target">
                                                              (
                                                              {detail.target === '-' ? (
                                                                detail.target
                                                              ) : (
                                                                <>
                                                                  {detail.target}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>
                                                            <span className="boi-detail boi-delivered">
                                                              (
                                                              {detail.delivered === '-' ? (
                                                                detail.delivered
                                                              ) : (
                                                                <>
                                                                  {detail.delivered}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>
                                                          </>
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                  {boi.details.map((detail) => {
                                                    return (
                                                      <>
                                                        {IsValid(detail.commentary) ? (
                                                          <div
                                                            className="boi-detail boi-commentary"
                                                            title={detail.commentary}
                                                          >
                                                            {detail.commentary}
                                                          </div>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              ) : (
                                                <div>
                                                  <div className={'boi-title'} title={boi.boi}>
                                                    {boi.boi}
                                                  </div>
                                                  <div>
                                                    {boi.details.map((detail) => {
                                                      return (
                                                        <>
                                                          <div
                                                            style={{
                                                              marginLeft: '40px',
                                                              lineHeight: '15px',
                                                              display: 'flex',
                                                            }}
                                                          >
                                                            <span className="boi-detail boi-uom">
                                                              {detail.uom} -{' '}
                                                            </span>
                                                            <span className="boi-detail boi-target">
                                                              (
                                                              {detail.target === '-' ? (
                                                                detail.target
                                                              ) : (
                                                                <>
                                                                  {detail.target}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>{' '}
                                                            <span className="boi-detail boi-delivered">
                                                              (
                                                              {detail.delivered === '-' ? (
                                                                detail.delivered
                                                              ) : (
                                                                <>
                                                                  {detail.delivered}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>
                                                          </div>
                                                          {IsValid(detail.commentary) ? (
                                                            <div
                                                              className="boi-detail boi-commentary"
                                                              title={detail.commentary}
                                                            >
                                                              {detail.commentary} for {detail.uom}
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                            {dim.showViewMore && (
                              <button
                                className="view-more-link"
                                onClick={() =>
                                  this.props.onChangePanelSize(
                                    'A',
                                    this.props.panelsLeft,
                                    dim.dimID,
                                    dim.expandType
                                  )
                                }
                              >
                                {dim.expandType === panelSize.norm ? 'VIEW MORE' : 'VIEW LESS'} &gt;
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })
                }
              </div>
              <div id="wheel-chart-box" className="col-lg-6">
                <div style={{ maxHeight: "670px", overflow: "hidden" }}>
                  <div id='piechartContainer' className="align-piechart">
                    <ResponsiveContainer width="100%" id="wheel-chart-container">
                      <PieChart>
                        {/*Dimension*/}
                        <Pie
                          data={data01b}
                          dataKey="value"
                          cx="50%"
                          cy="28%"
                          startAngle={90}
                          endAngle={-270}
                          innerRadius="24%"
                          outerRadius="56%"
                          label={renderDimLabel}
                          labelLine={false}
                          isAnimationActive={false}
                          animationEasing={'ease-out'}
                        />

                        {/*Dimension Ring*/}
                        <Pie
                          data={dimListMaster}
                          cx="50%"
                          cy="28%"
                          startAngle={90}
                          endAngle={-270}
                          innerRadius="58%"
                          outerRadius="62%"
                          isAnimationActive={false}
                        />

                        {/*SubDimension*/}
                        <Pie
                          startAngle={90}
                          endAngle={-270}
                          data={data02b}
                          cx="50%"
                          cy="28%"
                          outerRadius="86%"
                          innerRadius="64%"
                          paddingAngle={1.5}
                          label={renderSubDimLabel}
                          labelLine={false}
                          isAnimationActive={false}
                          animationDuration={500}
                          animationEasing={'ease-out'}
                        />

                        {/*SubDimension Ring*/}
                        <Pie
                          startAngle={90}
                          endAngle={-270}
                          data={suDimListRing}
                          cx="50%"
                          cy="28%"
                          innerRadius="88%"
                          outerRadius="92%"
                          paddingAngle={1.5}
                          isAnimationActive={false}
                          animationDuration={500}
                          animationEasing={'ease-out'}
                        />
                        <Pie
                          data={dimListMaster}
                          cx="50%"
                          cy="28%"
                          startAngle={90}
                          endAngle={-270}
                          innerRadius="96%"
                          outerRadius="97%"
                          isAnimationActive={false}
                        />
                        {/* SPACE IN BETWEEN SLICE */}
                        {[
                          { y: '27.8%', r: 89 },
                          { y: '28%', r: 149 },
                          { y: '28%', r: [0, 1].indexOf(cusSDimCount) > -1 ? -150 : -151.2 },
                          { y: '27.8%', r: cusSDimCount === 3 ? -92 : -91 },
                          { y: '28%', r: [0, 1].indexOf(cusSDimCount) > -1 ? -29 : -30.5 },
                          { y: '28%', r: 29 },
                        ].map((s) => {
                          return (
                            <rect
                              className="dashboard-wheel-spacing"
                              x="0"
                              y={s.y}
                              width="50%"
                              height="7"
                              transform={`rotate(${s.r})`}
                            />
                          );
                        })}
                        <Pie
                          data={[{ name: 'Centre', value: 1, fill: '#f9fafb' }]}
                          dataKey="value"
                          cx="50%"
                          cy="28%"
                          startAngle={90}
                          endAngle={-270}
                          innerRadius="0%"
                          outerRadius="24%"
                          labelLine={false}
                          isAnimationActive={false}
                          animationEasing={'ease-out'}
                        />
                        {/* logo circle */}
                        <Pie
                          data={centre}
                          cx="50%"
                          cy="28%"
                          innerRadius="22%"
                          outerRadius="24%"
                          label={renderLogo}
                          isAnimationActive={false}
                          fill={'transparent'}
                          paddingAngle={0}
                          labelLine={false}
                          stroke=""
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  {this.props.showKnowMore && (
                    <div className="btn-knowMore-container">
                      {!this.props.isGlobal ||
                        (this.props.isGlobal && !this.props.isGlobalPreview) ? (
                        <GrooveButton
                          id="primry-btn-2"
                          hasIcon={true}
                          type="solid"
                          colorClass="stat-alternate"
                          text="Know More"
                          callbackFunction={this.props.onKnowMore}
                          customClassName="btn-knowMore"
                          iconAlignment="left"
                          iconSize="small"
                          iconName="chevron-right"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                        />
                      ) : (
                        ''
                      )}
                      <div id="legend-container">
                        <span>
                          <FontAwesomeIcon id="legend-identified" icon={faSquare} />
                          Target{' '}
                        </span>
                        <span>
                          <FontAwesomeIcon id="legend-delivered" icon={faSquare} />
                          Delivered{' '}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                {
                  //EXPERIENCE, SUSTAINABILITY, TALENT
                  this.props.panelsRight.map((dim, dimI) => {
                    //NO DATA
                    if (dim.subDimList.length === 0)
                      return (
                        <div key={dimI} className="dimension-card">
                          <div className="dm-card-header" style={{ background: '#b2b2b2' }}>
                            {dim.dim}
                          </div>
                          {dim.expandType === panelSize.norm && (
                            <div className="dm-card-box-disabled">No Data Available</div>
                          )}
                        </div>
                      );

                    return (
                      <div key={dimI} className="dimension-card">
                        <div
                          className="dm-card-header"
                          style={{
                            background: dim.fill,
                            cursor: dim.expandType === panelSize.comp ? 'pointer' : '',
                          }}
                          onClick={
                            dim.expandType === panelSize.comp
                              ? () =>
                                this.props.onChangePanelSize(
                                  'B',
                                  this.props.panelsRight,
                                  dim.dimID,
                                  dim.expandType
                                )
                              : () => { }
                          }
                        >
                          {dim.dim}
                        </div>
                        {dim.expandType !== panelSize.comp && (
                          <div className="dm-card-box" id={`boxIDB${dimI}`}>
                            <div
                              className="dm-card-details"
                              style={{
                                maxHeight:
                                  dim.expandType === panelSize.exp
                                    ? 'none'
                                    : dim.expandType === panelSize.norm
                                      ? dim.boiPanelMaxHeight //'142.2px'
                                      : dim.boiPanelMaxHeight + 10,//'152px',
                              }}
                            >
                              {dim.subDimList.map((sDim, sInd) => {
                                  //CUSTOMER, GOVERNANCE, CHANGE
                                  return (
                                    <>
                                      <div className="db-subdim-container">
                                        <h6
                                          className="dashboard-subdim"
                                          style={{
                                            color: dim.fill,
                                          }}
                                        >
                                          {sDim.subDim}:
                                        </h6>
                                      </div>
                                      <div className="dashboard-boi">
                                        {sDim.boiList.map((boi, boiI) => {
                                          return (
                                            <>
                                              {boi.details.length === 1 ? (
                                                <div>
                                                  <div style={{ display: 'flex' }}>
                                                    <div className={'boi-title'} title={boi.boi}>
                                                      {boi.boi}
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                      {boi.details.map((detail) => {
                                                        return (
                                                          <>
                                                            {' '}
                                                            <span className="boi-detail boi-target">
                                                              (
                                                              {detail.target === '-' ? (
                                                                detail.target
                                                              ) : (
                                                                <>
                                                                  {detail.target}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>
                                                            <span className="boi-detail boi-delivered">
                                                              (
                                                              {detail.delivered === '-' ? (
                                                                detail.delivered
                                                              ) : (
                                                                <>
                                                                  {detail.delivered}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>
                                                          </>
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                  {boi.details.map((detail) => {
                                                    return (
                                                      <>
                                                        {IsValid(detail.commentary) ? (
                                                          <div
                                                            className="boi-detail boi-commentary"
                                                            title={detail.commentary}
                                                          >
                                                            {detail.commentary}
                                                          </div>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              ) : (
                                                <div>
                                                  <div className={'boi-title'} title={boi.boi}>
                                                    {boi.boi}
                                                  </div>
                                                  <div>
                                                    {boi.details.map((detail) => {
                                                      return (
                                                        <>
                                                          <div
                                                            style={{
                                                              marginLeft: '40px',
                                                              lineHeight: '15px',
                                                              display: 'flex',
                                                            }}
                                                          >
                                                            <span className="boi-detail boi-uom">
                                                              {detail.uom} -{' '}
                                                            </span>
                                                            <span className="boi-detail boi-target">
                                                              (
                                                              {detail.target === '-' ? (
                                                                detail.target
                                                              ) : (
                                                                <>
                                                                  {detail.target}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>{' '}
                                                            <span className="boi-detail boi-delivered">
                                                              (
                                                              {detail.delivered === '-' ? (
                                                                detail.delivered
                                                              ) : (
                                                                <>
                                                                  {detail.delivered}
                                                                  {detail.uom === '%' ? '' : ' '}
                                                                  {this.props.formatUOM(
                                                                    detail.target,
                                                                    detail.uom
                                                                  )}
                                                                </>
                                                              )}
                                                              )
                                                            </span>
                                                          </div>
                                                          {IsValid(detail.commentary) ? (
                                                            <div
                                                              className="boi-detail boi-commentary"
                                                              title={detail.commentary}
                                                            >
                                                              {detail.commentary} for {detail.uom}
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                            {dim.showViewMore && (
                              <button
                                className="view-more-link"
                                onClick={() =>
                                  this.props.onChangePanelSize(
                                    'B',
                                    this.props.panelsRight,
                                    dim.dimID,
                                    dim.expandType
                                  )
                                }
                              >
                                {dim.expandType === panelSize.norm ? 'VIEW MORE' : 'VIEW LESS'} &gt;
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  class SummaryWheelContainer extends Component {
    constructor() {
      super();
      this.state = {
        expandedCard: 0,
        expandedCardB: 0,
        panelsLeft: [],
        panelsRight: [],
        showKnowMore: true,
      };
    }
    componentDidMount() {
      this.formatData(JSON.parse(JSON.stringify(this.props.dimensionList)));
    }
    componentDidUpdate(prevProps) {
      if (JSON.stringify(this.props.dimensionList) !== JSON.stringify(prevProps.dimensionList))
        this.formatData(
          JSON.parse(
            JSON.stringify(this.props.dimensionList === undefined ? [] : this.props.dimensionList)
          )
        );
    }
    formatData = (dimList) => {
      const dimL = [
        masterDim.financial.toLowerCase(),
        masterDim.custom.toLowerCase(),
        masterDim.inclusionDiversity.toLowerCase(),
      ];
      const dimR = [
        masterDim.experience.toLowerCase(),
        masterDim.sustainability.toLowerCase(),
        masterDim.talent.toLowerCase(),
      ];

      let tPanelsLeft = this.formatDim(dimList, dimL);
      tPanelsLeft = [
        tPanelsLeft.find((dim) => dim.dim.toLowerCase() === masterDim.financial.toLowerCase()),
        tPanelsLeft.find((dim) => dim.dim.toLowerCase() === masterDim.custom.toLowerCase()),
        tPanelsLeft.find(
          (dim) => dim.dim.toLowerCase() === masterDim.inclusionDiversity.toLowerCase()
        ),
      ];

      let tPanelsRight = this.formatDim(dimList, dimR);
      tPanelsRight = [
        tPanelsRight.find((dim) => dim.dim.toLowerCase() === masterDim.experience.toLowerCase()),
        tPanelsRight.find(
          (dim) => dim.dim.toLowerCase() === masterDim.sustainability.toLowerCase()
        ),
        tPanelsRight.find((dim) => dim.dim.toLowerCase() === masterDim.talent.toLowerCase()),
      ];

      this.setState(
        {
          panelsLeft: tPanelsLeft,
          panelsRight: tPanelsRight,
        },
        () => {
          setTimeout(() => {
            const tpLShow = tPanelsLeft.map((dim, i) => {
              // let card = document.getElementById(`boxID${i}`) || { clientHeight: 0 };
              let sdCount = 0;
              let boiCont = 0;
              let boiCount = 0;
              let totalBOI = 0;
              let commentCount = 0;
              dim.subDimList.forEach(sd => {
                if (boiCount < 3) {
                  sdCount++
                  sd.boiList.forEach(boi => {
                    if (boiCount < 3) {
                      boiCont++;
                      boi.details.forEach(boiDetails => {
                        if (boiCount < 3) {
                          boiCount++;
                          totalBOI++;
                          if (IsValid(boiDetails.commentary)) {
                            commentCount++;
                          }
                        } else {
                          totalBOI++;
                        }
                      })
                    } else {
                      boi.details.forEach(() => {
                        totalBOI++;
                      })
                    }
                  })
                } else {
                  sd.boiList.forEach(boi => {
                    boi.details.forEach(() => {
                      totalBOI++;
                    })
                  })
                }
              });
              let tempHeight =  (sdCount*34) /* + 14 */ + (boiCont*32) + (boiCount*28) + (boiCount*31.6) + (commentCount*17) + 4;
              const tShow = totalBOI > 3 ? /* card.clientHeight > tempHeight */true : false; //142.2;
              return {
                ...dim,
                showViewMore: tShow,
                boiPanelMaxHeight: tempHeight
              };
            });

            const tpRShow = tPanelsRight.map((dim, i) => {
              // let card = document.getElementById(`boxIDB${i}`) || { clientHeight: 0 };

              let sdCount = 0;
              let boiCont = 0;
              let boiCount = 0;
              let totalBOI = 0;
              let commentCount = 0;
              dim.subDimList.forEach(sd => {
                if (boiCount < 3) {
                  sdCount++
                  sd.boiList.forEach(boi => {
                    if (boiCount < 3) {
                      boiCont++;
                      boi.details.forEach(boiDetails => {
                        if (boiCount < 3) {
                          boiCount++;
                          totalBOI++;
                          if (IsValid(boiDetails.commentary)) {
                            commentCount++;
                          }
                        } else {
                          totalBOI++;
                        }
                      })
                    } else {
                      boi.details.forEach(() => {
                        totalBOI++;
                      })
                    }
                  })
                } else {
                  sd.boiList.forEach(boi => {
                    boi.details.forEach(() => {
                      totalBOI++;
                    })
                  })
                }
              });
              let tempHeight =  (sdCount*34) /* + 14 */ + (boiCont*32) + (boiCount*28) + (boiCount*31.7) + (commentCount*17) + 4;
              const tShow = totalBOI > 3 ? /* card.clientHeight > tempHeight */true : false;// 142.2;
              return {
                ...dim,
                showViewMore: tShow,
                boiPanelMaxHeight: tempHeight
              };
            });

            this.setState({
              panelsLeft: JSON.parse(JSON.stringify(tpLShow)),
              panelsRight: JSON.parse(JSON.stringify(tpRShow)),
            });
          }, 1400);
        }
      );
    };
    getDimColor = (dimID) => {
      let fillColor = '';
      switch (dimID) {
        case 1: // Financial
          fillColor = 'rgb(180, 85, 170)';
          break;
        case 2: // Experience
          fillColor = 'rgb(190, 130, 255)';
          break;
        case 3: // Sustainability
          fillColor = 'rgb(99, 115, 129)';
          break;
        case 4: // Talent
          fillColor = 'rgb(70, 0, 115)';
          break;
        case 5: // Inclusion & Diversity
          fillColor = 'rgb(117, 0, 192)';
          break;
        case 6: // Custom
          fillColor = 'rgb(161, 0, 255)';
          break;
        default:
          fillColor = '#b2b2b2';
          break;
      }
      return fillColor;
    }
    formatDim = (dimList, dimLR) => {
      let UOMWithNoDecimal = ["Days", "Hours", "Minutes", "#Count"];
      return dimList
        .filter((e) => dimLR.indexOf(e.CoreDimensionName.toLowerCase()) > -1)
        .map((dim) => {
          const tSDim = dim.Subdimesions.filter((subDim2) => {
            return subDim2.BIOSlIST.length > 0;
          });
          const sdFColor = sdFontColor.find((item) => item.dimension === dim.CoreDimensionName);
          return {
            dimID: dim.ID,
            dim: dim.CoreDimensionName,
            fill: this.getDimColor(dim.ID),//dim.CoreDimensionColor.toLowerCase(),
            subDimFill: TryGetObjValueAlt(sdFColor, '', 'fill'),
            expandType: panelSize.norm,
            showViewMore: false,
            subDimList: tSDim.map((subDim) => {
              const boiList = [];
              subDim.BIOSlIST.forEach((boi) => {
                const inListIdx = boiList.findIndex(boitemp => boitemp.boi === boi.BOIName);
                if (inListIdx > -1) {
                  boiList[inListIdx].details.push({
                    target: UOMWithNoDecimal.includes(boi.UOM)
                      ? this.DataFormater2(
                          TryGetObjValueAlt(boi, "-", "IdentifiedValue")
                        )
                      : this.DataFormater(
                          TryGetObjValueAlt(boi, "-", "IdentifiedValue")
                        ),
                    delivered: UOMWithNoDecimal.includes(boi.UOM)
                      ? this.DataFormater2(
                          TryGetObjValueAlt(boi, "-", "DeliveredValue")
                        )
                      : this.DataFormater(
                          TryGetObjValueAlt(boi, "-", "DeliveredValue")
                        ),
                    uom: boi.UOM,
                    commentary: TryGetObjValueAlt(boi, '', 'ProgramCommentary'),
                  })
                } else {
                  boiList.push({
                    boi: boi.BOIName,
                    details: [
                      {
                        target: UOMWithNoDecimal.includes(boi.UOM)
                        ? this.DataFormater2(
                            TryGetObjValueAlt(boi, "-", "IdentifiedValue")
                          )
                        : this.DataFormater(
                            TryGetObjValueAlt(boi, "-", "IdentifiedValue")
                          ),
                        delivered: UOMWithNoDecimal.includes(boi.UOM)
                        ? this.DataFormater2(
                            TryGetObjValueAlt(boi, "-", "DeliveredValue")
                          )
                        : this.DataFormater(
                            TryGetObjValueAlt(boi, "-", "DeliveredValue")
                        ),
                        uom: boi.UOM,
                        commentary: TryGetObjValueAlt(boi, '', 'ProgramCommentary'),
                      }
                    ],
                  })
                }
              })

              return {
                subDim: subDim.SubDimensionName,
                boiList,
              };
            }),
          };
        });
    };
    DataFormater = (n) => {
      var ranges = [
        { divider: 1e12, suffix: "T" },
        { divider: 1e9, suffix: "Bn" },
        { divider: 1e6, suffix: "Mn" },
        { divider: 1e3, suffix: "K" },
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          let tempRange = n / ranges[i].divider;
          if (n >= 1e3 && n < 1e6) {
            return Math.round(tempRange).toString() + ranges[i].suffix;
          }
          if (tempRange.toFixed(2) % 1 === 0) {
            return parseInt(tempRange).toString() + ranges[i].suffix;
          }
          return tempRange.toFixed(2).toString() + ranges[i].suffix;
        }
      }
      if (n % 1 === 0) {
        return parseInt(n).toString();
      }
      return n;
    };

    onChangePanelSize = (type, dimList, dimID, panelSz) => {
      const pSTargetDim = panelSz === panelSize.norm ? panelSize.exp : panelSize.norm;
      const pSOtherDim = panelSz === panelSize.norm ? panelSize.comp : panelSize.norm;
      const tDimList = dimList.map((dim) => {
        return {
          ...dim,
          expandType: dim.dimID === dimID ? pSTargetDim : pSOtherDim,
        };
      });
      if (type === 'A') this.setState({ panelsLeft: tDimList });
      else this.setState({ panelsRight: tDimList });
    };

    getDatalabel = (str) => {
      let label = [];
      let searchKey = str.search('&');
      if (searchKey > -1) {
        label.push(str.slice(0, searchKey + 1));
        label.push(str.slice(searchKey + 1));
      } else {
        label = str.split(' ');
      }
      return label;
    };

    preDLFunction = (callback) => {
      this.setState({ showKnowMore: false }, () => {
        setTimeout(() => {
          callback();
        }, 1400);
      });
    };
    postDLFunction = () => {
      this.setState({ showKnowMore: true });
    };

    DataFormater2 = (n) => {
      var ranges = [
        { divider: 1e12, suffix: "T" },
        { divider: 1e9, suffix: "Bn" },
        { divider: 1e6, suffix: "Mn" },
        { divider: 1e3, suffix: "K" },
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          let tempRange = n / ranges[i].divider;
          return Math.round(tempRange).toString() + ranges[i].suffix;
        }
      }
      if (n % 1 === 0) {
        return parseInt(n).toString();
      }
      return n;
    };

    formatUOM = (val, uom) => {
      try {
        switch (uom) {
          case "Days":
            if (val <= 1) {
              return "Day";
            }
            return uom;
          case "Hours":
            if (val <= 1) {
              return "Hour";
            }
            return uom;
          case "Minutes":
            if (val <= 1) {
              return "Minute";
            }
            return uom;
          default:
            return uom;
        }
      } catch (e) {
        console.log(e);
      }
    };
    render() {
      const { selectedProgram, selectedYear, dashboardProgramDetails, dashboardClientDetails } = this.props.DashboardData;
      return (
        <SummaryWheelPresentational
          key={1}
          showKnowMore={this.state.showKnowMore}
          onChangePanelSize={this.onChangePanelSize}
          expandedCard={this.state.expandedCard}
          expandedCardB={this.state.expandedCardB}
          getDatalabel={this.getDatalabel}
          onKnowMore={this.props.onKnowMore}
          panelsLeft={this.state.panelsLeft}
          panelsRight={this.state.panelsRight}
          preDLFunction={this.preDLFunction}
          postDLFunction={this.postDLFunction}
          dimensionList={this.props.dimensionList}
          sdFontColor={sdFontColor}
          programName={selectedProgram}
          reportingYear={selectedYear.label[0].toString()}
          dashboardProgramDetails={dashboardProgramDetails}
          dashboardClientDetails={dashboardClientDetails}
          isGlobal={this.props.isGlobal}
          isGlobalPreview={this.props.isGlobalPreview}
          formatUOM={this.formatUOM}
        />
      );
    }
  }

  return SummaryWheelContainer;
})();

export default connect(
  (state) => {
    return {
      DashboardData: state.DashboardData,
      dimensionList: state.DashboardData.dimensionSubDimension.CoreDimesionList,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(SummaryWheel);
