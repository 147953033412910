import {
    ON_CHANGE_USER_DETAILS
} from '../Constants'

const initialState = {
    newUserDetails: {
        userID: 0,
        userName:"",
        emailID:"",
        isActive: false,
        clientType: 1,
        clientAgnostic: [],
        clientSpecific: [],
        dashboard: [],
    },    
}

export const UserManagementData = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case ON_CHANGE_USER_DETAILS:
            return {
                ...state,
                newUserDetails: action.newUserDetails
            }        
        default:
            return state
    }
}