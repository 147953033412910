import {
  SHOW_TOAST_NOTIFICATION,
  GET_RDV_PROGRAM_LIST,
  SET_RDV_CLIENT_DETAILS,
  GET_LANDING_PROGRAM_PROJECTS_LIST,
  ON_SELECT_REPORTING_YEAR_OPTIONS,
  ON_SELECT_CREATION_YEAR_OPTIONS,
  ON_SELECT_STATUS_OPTIONS,
  GET_RVD_BOI_HISTORY,
  GET_PDE_TABLE_DATA,
  GET_PROGRAM_OPTIONS,
  GET_PROJECT_OPTIONS,
  SET_RVD_SELECTED_PROGRAM,
  SET_RVD_SELECTED_PROJECT,
  GET_PROJECT_CREATION_YEAR_LIST,
  SET_PROJECT_LIST_ROLLER,
} from "../Constants";

const initialState = {
  showToastNotification: false,
  programList: [],
  reportingYearFilter: {},
  reportingYearKeyword: "",
  creationYearValue: [],
  creationYear: "",
  statusFilter: {},
  statusFilterKeyword: [],
  statusOptions: [
    { label: "Awaiting Approval", value: "Awaiting Approval" },
    { label: "In Progress", value: "In Progress" },
    { label: "Rejected", value: "Rejected" },
    { label: "Approved", value: "Approved" },
    { label: "All Status", value: "All Status" },
  ],
  landingProgramProjectsList: [],
  rdvClientDetails: {},
  rvdBOIHistory: {
    rejectedBOIHistories: [],
    modifiedBOIHistories: [],
    deletedBOIHistories: []
  },
  pdeTableData: [],
  programOptions: [],
  projectOptions: [],
  selectedProgramDDValue: {},
  selectedProject: {},
  creationYearList: [],
  showProjectListRoller: false,
};

export const DataIngestionData = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SHOW_TOAST_NOTIFICATION:
      return {
        ...state,
        showToastNotification: action.showToastNotification,
      };
    case GET_RDV_PROGRAM_LIST:
      return {
        ...state,
        programList: action.programList,
      };
    case GET_PROGRAM_OPTIONS:
      return {
        ...state,
        programOptions: action.programOptions,
      };
    case GET_PROJECT_OPTIONS:
      return {
        ...state,
        projectOptions: action.projectOptions,
      };
    case SET_RVD_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgramDDValue: action.selectedProgramDDValue,
      };
    case SET_RVD_SELECTED_PROJECT:
      return {
        ...state,
        selectedProjectDDValue: action.selectedProjectDDValue,
      };
    case GET_LANDING_PROGRAM_PROJECTS_LIST:
      return {
        ...state,
        reportingYearFilter: action.reportingYearFilter,
        statusFilter: action.statusFilter,
        landingProgramProjectsList: action.landingProgramProjectsList
      }
    case ON_SELECT_STATUS_OPTIONS:
      return {
        ...state,
        statusFilterKeyword: action.statusFilterKeyword
      }
    case ON_SELECT_REPORTING_YEAR_OPTIONS:
      return {
        ...state,
        reportingYearKeyword: action.reportingYearKeyword
      }
    case ON_SELECT_CREATION_YEAR_OPTIONS:
      return {
        ...state,
        creationYearValue: action.creationYearValue,
        creationYear: action.creationYear
      }
    case SET_RDV_CLIENT_DETAILS:
      return {
        ...state,
        rdvClientDetails: action.rdvClientDetails,
      };
    case GET_RVD_BOI_HISTORY:
      return {
        ...state,
        rvdBOIHistory: action.rvdBOIHistory
      };
    case GET_PDE_TABLE_DATA:
      return {
        ...state,
        pdeTableData: action.pdeTableData
      };
    case GET_PROJECT_CREATION_YEAR_LIST:
      return {
        ...state,
        creationYearList: action.creationYearList,
      }
    case SET_PROJECT_LIST_ROLLER:
      return {
        ...state,
        showProjectListRoller: action.showProjectListRoller,
      }
    default:
      return state;
  }
};
