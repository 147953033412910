import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { GrooveTabComponent, GrooveIconSvg, GrooveButton } from 'tfo-groove';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import { history } from '../../../store';
import {
  DeleteCallbackURL,
  DeleteReferrer,
  DeleteDistiURL,
  CheckModuleAccess,
  LogUserSiteAction,
} from '../../../Utilities/AccessManagementUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormDateDDMMMYYY, TryGetNodeAlt } from '../../../Utilities/Formatter';

import {
  clearAllProjectFields,
  clearProjectList,
  getProjectStakeHolders,
  getAssetNameAndDeliveredByList,
} from '../../../Actions/ProjectConfiguration.Action';

// import AddNewProgramPopup from './AddNewProgramPopup'

import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import CVMToasterNotif from '../../Shared/CVMToasterNotif/CVMToasterNotif';

import {
  onClickFavorite,
  // setClientDetails,
  setReportingYear,
  setWorkFlowReportingYear,
  getReportingYearList,
  clearConfigClientFields,
  getClientBasicDetails,
  getOfferingsList,
  onCloseNotifyToast,
} from '../../../Actions/ClientConfiguration.Action';

import {
  getClientList,
  onSearch,
  showClientDropdown,
  setSelectedClient,
  setStepperActiveIndex,
  setHistoryPath,
  setCurrentUserDetails,
} from '../../../Actions/Shared.Action';

import {
  getProgramList,
  setProgramDetails,
  setSelectedProgram,
  getProgramClientDetails,
  fetchAddNewProgramDetails,
  getBOIMappingList,
  getBOIMappingListAllOfferings,
  getCustomBOIDropdownList,
  getProgramBOI,
  clearAllProgramProjectFields,
} from '../../../Actions/ProgramConfiguration.Action';

import {
  getProjectProgramDetails,
  getProjectBOI,
  setSelectedProjectdetails,
  setSelectedProject,
  onChangeReportedYear,
  getBusinessAdvisorListByClientID
} from '../../../Actions/ProjectConfiguration.Action';

import { InitPnP } from '../../../Actions/AccessValidation.Action';

import { setPath } from '../../../Actions/Home.Action';

import { withRouter } from 'react-router-dom';

import {
  HasProperty,
  HasValue,
  IsArray,
  IsObjEmpty,
  IsValid,
  IsValidStr,
} from '../../../Utilities/Validations';

// import {
//   DataEvent,
// } from '../../../Utilities/DataEvents';

//icons

import clientDetailIcon from './../../../Static/images/newClientConfig/clientDetailIcon.svg';
import listViewIcon from './../../../Static/images/newClientConfig/listViewIcon.svg';
import cardViewIcon from './../../../Static/images/newClientConfig/cardViewIcon.svg';
import AddFileIcon from './../../../Static/images/360cvm/AddFilesIcon.svg';
import { ReactComponent as EyeIcon } from './../../../Static/images/360cvm/eye.svg';
import { ReactComponent as EditIcon } from './../../../Static/images/360cvm/EditIcon.svg';
import { CONFIG_MOD_LANDING_PAGE } from '../../../Constants/Modules';

const ProgramMain = (function () {
  class ProgramMainPresentational extends Component {
    render() {
      const toBeAlignedPrograms = this.props.toBeAlignedPrograms();
      const programList = this.props.programList;
      const clientDetailsPopover = (
        <Popover id="clientDetails-popover" trigger="focus">
          <Popover.Title>
            <span className="close-btn" onClick={() => document.body.click()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </Popover.Title>
          <Popover.Content>
            <div>
              <div className="sub-section">
                <div className="detail-popup-section">
                  <div className="section-title">Client Name</div>
                  <div className="section-desc">
                    {this.props.programClientDetails.ClientMasterName}
                  </div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Industry</div>
                  <div className="section-desc">{this.props.programClientDetails.IndustryName}</div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Market Unit</div>
                  <div className="section-desc">
                    {this.props.programClientDetails.MarketUnitName}
                  </div>
                </div>
              </div>
              <div className="sub-section">
                <div className="detail-popup-section">
                  <div className="section-title">Sub-Market Unit</div>
                  <div className="section-desc">
                    {this.props.programClientDetails.SubMarketUnitName}
                  </div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Client Type</div>
                  <div className="section-desc">
                    {this.props.programClientDetails.ClientTypeName}
                  </div>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );

      let programHeaders = ['Program Name', 'Sub Offering', 'BT Lead', 'Stage', 'Actions'];
      let projectHeaders = ['Project Name', 'Stage', 'Reporting Year', 'Actions'];
      let projectList = this.props.projectList;

      return (
        <React.Fragment>
          <CVMToasterNotif />

          <CVMModal
            title={'Select Client'}
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            size={this.props.size}
            content={
              <ModalContent
                modalActiveKey={this.props.modalActiveKey}
                onChangeModalTab={this.props.onChangeModalTab}
                clientList={this.props.clientList}
                onClickFavorite={this.props.onClickFavorite}
                onSearch={this.props.onSearch}
                filterKeyword={this.props.filterKeyword}
                onSelectClient={this.props.onSelectClient}
                selectedTab={this.props.selectedTab}
              />
            }
          />
          {this.props.selectedClient.value !== undefined &&
            this.props.selectedClient.value !== 0 ? (
            this.props.PnPComponents.landingPage ? (
              <Row className="access_denied_container">
                <Col md={12} xs={12}>
                  <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                  <div className="access_denied_content">
                    <h1>Access Denied</h1>
                    <p>
                      You don't have the necessary user privileges to view the client that you are
                      attempting to access.
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              <div>
                {/* <div className="module-title">Program & Project Configuration</div> */}
                {this.props.isDisplayMessage && toBeAlignedPrograms.length !== 0 && (
                  <div
                    className="no-boidefinition-warning"
                    style={{
                      position: 'relative',
                      marginBottom: '10px',
                      height: 'unset',
                    }}
                  >
                    <GrooveIconSvg
                      customClassName="accordion-button"
                      size="small"
                      name="exclamation-triangle"
                      iconStyle="solid"
                      iconColor="stat-warning"
                    />
                    <div className="no-boidefinition-text">
                      Client Alignment is pending for {toBeAlignedPrograms.join(', ')}.
                    </div>
                    <GrooveIconSvg
                      customClassName="accordion-button"
                      size="small"
                      name="times"
                      iconStyle="solid"
                      iconColor="stat-warning"
                      callbackFunction={this.props.onCloseMessage}
                    />
                  </div>
                )}
                <div class="title-section">
                  <h4 class="header24-semibold-midnight">Program & Project Configuration</h4>
                  <div class="notify-toast"></div>
                </div>
                <div className="client-details-button">
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="right-start"
                    overlay={clientDetailsPopover}
                  >
                    <div>
                      <img src={clientDetailIcon} alt="clientDetailIcon" />
                      Client Details
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="add-program-line">
                  <div className="bodytext16-medium-midnight">
                    Programs ({this.props.programList.length})
                  </div>
                  <div>
                    {this.props.PnPComponents.program.add ? (
                      ''
                    ) : (
                      // Add New Program Button
                      <GrooveButton
                        id="primry-btn-2"
                        hasIcon={true}
                        type="solid"
                        colorClass="stat-alternate"
                        text="Add New Program"
                        callbackFunction={() => {
                          this.props.clearAllProgramProjectFields();
                          this.props.setStepperActiveIndex(1);
                          this.props.history.push('/ProgramProjectConfig/ProgramIDValueSettings');
                        }}
                        customClassName="add-project-button"
                        iconAlignment="right"
                        iconSize="small"
                        iconName="plus"
                        iconStyle="solid"
                        iconId="btn-icon-01"
                        isDisabled={this.props.isAddDisabled}
                      />
                    )}
                    <div className="landing-img-box">
                      <img
                        src={cardViewIcon}
                        alt="cardViewIcon"
                        onClick={() => this.props.onChangeView('Card')}
                      />
                      <img src={listViewIcon} className="disabled-view" alt="listViewIcon" />
                    </div>
                  </div>
                </div>

                {/* No programs added then display below block */}
                {(programList.length || 0) === 0 ? (
                  <div className="applyFlex-justify-center">
                    <div className="select-client-container">
                      <img src={AddFileIcon} />
                      <p>No Program Added</p>
                      <span>
                        Please add program(s) to this client by using the ‘Add New Program’ button
                        above.
                      </span>
                    </div>
                  </div>
                ) : this.props.cardView ? (
                  //card view
                  <div className="program-card-container" >
                    <Row>
                      {this.props.programList.map((e, ind) => {
                        let actionOwner =
                          [2, 4, 9].indexOf(e.StatusId) > -1 && e.ProgramCreator !== null
                            ? e.ProgramCreator.split('@')[0]
                            : [3, 5, 6, 7, 8].indexOf(e.StatusId) > -1 &&
                              IsValid(e.ActionOwner) ? (e.ActionOwner).replace(/@accenture.com/g, '') : '-';

                        let creationDate = new Date(e.CreatedDate);
                        let showTileBtns = (e.StageId > 1 && !this.props.PnPComponents.program.projectDtls) ||
                          e.ProjectCount > 0;
                        let hoverBtnID = `btn-hover-proj-${ind}`;
                        return (
                          <Col sm={12} md={6} lg={3}>
                            <div className="program-card-box"
                              id={"progBoxContainer"}
                            >
                              <div
                                className="program-card-wrap"
                                id={"progWrapContainer"}
                                onClick={() => this.props.onClickProgram(e)}
                              >
                                <div className="program-card-detail">
                                  <div
                                    className="titleText-medium-14 card-title rdv-label ppc-text-ellipsis title-ellip"
                                    title={e.ClientProgramName}
                                  >
                                    {e.ClientProgramName}
                                  </div>
                                  {/* <img src={programEdit} alt="programEdit" className="programEdit-icon" onClick={(e) => e.preventDefault} /> */}
                                  <span id="tooltiptext-program" className="tooltiptext">
                                    Modify Asignee
                                  </span>
                                </div>
                                <div className="program-card-detail prog-hover prog-stage">
                                  <div className="titleText-medium-12">Stage:</div>
                                  <div className="titleText-regular-12 prog-card-stage-cont">
                                    <span className="prog-card-stage-name" title={e.Stage}>
                                      {e.Stage}
                                    </span>
                                    {e.IsEdited ? (
                                      <div className="edited-program-project">Edited</div>
                                    ) : (
                                      ''
                                    )}{' '}
                                  </div>
                                </div>
                                <div className="program-card-detail detail-status prog-hover">
                                  <div className="titleText-medium-12">Status:</div>
                                  <div
                                    className={
                                      e.StatusId === 1
                                        ? 'card-row-status status-notStarted'
                                        : e.StatusId === 2 || e.StatusId === 9
                                          ? 'card-row-status status-notStarted'
                                          : e.StatusId === 3
                                            ? 'card-row-status status-inProgress'
                                            : e.StatusId === 4
                                              ? 'card-row-status status-rejected'
                                              : e.StatusId === 5
                                                ? 'card-row-status status-inProgress'
                                                : e.StatusId === 6
                                                  ? 'card-row-status status-completed'
                                                  : e.StatusId === 7
                                                    ? 'card-row-status status-completed'
                                                    : e.StatusId === 8 && 'card-row-status status-rejected'
                                    }
                                  >
                                    {e.Status}
                                  </div>
                                </div>
                                <div className="program-card-detail prog-hover">
                                  <div className="titleText-medium-12 text-tile">
                                    Creation Date:
                                  </div>
                                  <div className="titleText-regular-12" title={FormDateDDMMMYYY(creationDate, true)}>
                                    {FormDateDDMMMYYY(creationDate, true)}
                                  </div>
                                </div>
                                <div className="program-card-detail text-gap prog-hover">
                                  <div className="titleText-medium-12 ppc-text-ellipsis">
                                    Program Creator:
                                  </div>
                                  <div
                                    className="titleText-regular-12"
                                    title={
                                      e.ProgramCreator !== null
                                        ? e.ProgramCreator.split('@')[0]
                                        : ''
                                    }
                                  >
                                    {e.ProgramCreator !== null
                                      ? e.ProgramCreator.split('@')[0]
                                      : '-'}
                                  </div>
                                </div>
                                <div className="program-card-detail text-gap prog-hover">
                                  <div className="titleText-medium-12 ppc-text-ellipsis">
                                    Action Owner:
                                  </div>
                                  <div className="titleText-regular-12 action-owner-list" title={actionOwner}>
                                    {actionOwner}
                                  </div>
                                </div>
                              </div>
                              {showTileBtns ? (
                                <div className="detail-row project-section"
                                  id={'projdetails'}
                                  onMouseOver={(e) => this.props.handleOnMouseOverBtn(hoverBtnID, "hover")}
                                  onMouseOut={(e) => this.props.handleOnMouseOverBtn(hoverBtnID, "out")}
                                >
                                  <GrooveButton
                                    id="primry-btn-2"
                                    hasIcon={true}
                                    type="filter-outline"
                                    colorClass="stat-alternate"
                                    text="View Project Details"
                                    callbackFunction={() => this.props.onClickProjectDetails(e)}
                                    customClassName="view-project-detail"
                                    iconAlignment="left"
                                    iconSize="small"
                                    iconName="chevron-right"
                                    iconStyle="solid"
                                    iconId="btn-icon-01"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              {/* <span id="hover-view-prog">View Program Details</span> */}
                              <div className={"hover-view-prog"} id={hoverBtnID}>
                                <GrooveButton
                                  id="primry-btn-2"
                                  hasIcon={true}
                                  type="filter-outline"
                                  colorClass="stat-alternate"
                                  text="View Program Details"
                                  callbackFunction={() => this.props.onClickProgram(e)}
                                  customClassName="view-project-detail"
                                  iconAlignment="left"
                                  iconSize="small"
                                  iconName="chevron-right"
                                  iconStyle="solid"
                                  iconId="btn-icon-01"
                                />
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  //list view
                  <div className="dimension-table">
                    <div className="program-table-header">
                      <div className="dimension-table-row">
                        {programHeaders.map((name) => (
                          <div>{name}</div>
                        ))}
                      </div>
                    </div>
                    <div className="dimension-table-body">
                      {this.props.programList.map((prog, i) => {
                        return (
                          <Accordion>
                            <Card key={prog.ClientProgramID}>
                              <Accordion.Collapse eventKey={String(i)}>
                                <Card.Body>
                                  <div className="project-table-header">
                                    <div className="dimension-table-row">
                                      {projectHeaders.map((name) => (
                                        <div>{name}</div>
                                      ))}
                                      {this.props.PnPComponents.project.add ? (
                                        <div style={{ width: 27 }} />
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor: '#a100ff',
                                            height: 27,
                                            width: 27,
                                            borderRadius: 50,
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            lineHeight: '24px',
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon="plus"
                                            color="white"
                                            onClick={() => {
                                              this.props.clearAllProjectFields();
                                              this.props.setStepperActiveIndex(1);
                                              this.props.history.push(
                                                '/ProgramProjectConfig/projectidvaluesettings'
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="dimension-table-body">
                                    {projectList
                                      .filter((x) => x.ClientProgramID === prog.ClientProgramID)
                                      .map((proj) => {
                                        return (
                                          <div
                                            className="project-header-container"
                                            key={proj.ProjectID}
                                          >
                                            <div className="dimension-table-row">
                                              <div className="program-column">
                                                {proj.ClientProjectName}
                                              </div>
                                              <div className="program-column">
                                                <div className="program-card-detail">
                                                  <div className="titleText-regular-12">
                                                    {proj.IsEdited
                                                      ? 'Edited Project: ' + proj.Stage
                                                      : proj.Stage}
                                                  </div>
                                                </div>
                                                <div className="program-card-detail">
                                                  <div
                                                    className={
                                                      proj.StatusId === 1
                                                        ? 'card-row-status status-notStarted'
                                                        : proj.StatusId === 2 || proj.StatusId === 9
                                                          ? 'card-row-status status-notStarted'
                                                          : proj.StatusId === 3
                                                            ? 'card-row-status status-inProgress'
                                                            : proj.StatusId === 4
                                                              ? 'card-row-status status-rejected'
                                                              : proj.StatusId === 5
                                                                ? 'card-row-status status-inProgress'
                                                                : proj.StatusId === 6
                                                                  ? 'card-row-status status-completed'
                                                                  : proj.StatusId === 7
                                                                    ? 'card-row-status status-completed'
                                                                    : proj.StatusId === 8 &&
                                                                    'card-row-status status-rejected'
                                                    }
                                                  >
                                                    {proj.Status}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="program-column">
                                                {proj.ReportedYear}
                                              </div>
                                              <div className="program-column">
                                                {(!this.props.PnPComponents.project
                                                  .stageOneFields &&
                                                  (this.props.userInformation.emailAddress ===
                                                    proj.ProjectLead ||
                                                    proj.ProjectLead === undefined)) ||
                                                  this.props.userInformation.isSuperAdmin ? (
                                                  <EditIcon
                                                    onClick={() => this.props.onClickProject(proj)}
                                                    style={{ cursor: 'pointer' }}
                                                  />
                                                ) : (
                                                  <EyeIcon
                                                    onClick={() => this.props.onClickProject(proj)}
                                                    style={{ cursor: 'pointer' }}
                                                  />
                                                )}
                                              </div>
                                              <div className="program-column"></div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                              <Card.Header className="dim-card-header program-card">
                                <div className="program-header-container">
                                  {prog.StageId === 3 &&
                                    !this.props.PnPComponents.program.projectDtls ? (
                                    <Accordion.Toggle
                                      id="dim-btn"
                                      className="dim-acc-btn"
                                      as={Button}
                                      variant="link"
                                      eventKey={String(i)}
                                      onClick={() => this.props.onProjectShow(prog)}
                                    >
                                      <GrooveIconSvg
                                        id="basicDetailsIcon"
                                        customClassName="accordion-button"
                                        size="large"
                                        name="chevron-right"
                                        iconStyle="solid"
                                        iconColor="stat-neutral"
                                      />
                                    </Accordion.Toggle>
                                  ) : null}
                                  <div className="dimension-table-row">
                                    <div className="program-column">{prog.ClientProgramName}</div>

                                    <div className="program-column">
                                      {this.props.getSubOffering(i).join(', ')}
                                    </div>
                                    <div className="program-column">{prog.ProgramCreator}</div>
                                    <div className="program-column">
                                      <div className="program-card-detail">
                                        <div className="titleText-regular-12">
                                          {prog.IsEdited
                                            ? 'Edited Program: ' + prog.Stage
                                            : prog.Stage}
                                        </div>
                                      </div>
                                      <div className="program-card-detail">
                                        <div
                                          className={
                                            prog.StatusId === 1
                                              ? 'card-row-status status-notStarted'
                                              : prog.StatusId === 2 || prog.StatusId === 9
                                                ? 'card-row-status status-notStarted'
                                                : prog.StatusId === 3
                                                  ? 'card-row-status status-inProgress'
                                                  : prog.StatusId === 4
                                                    ? 'card-row-status status-rejected'
                                                    : prog.StatusId === 5
                                                      ? 'card-row-status status-inProgress'
                                                      : prog.StatusId === 6
                                                        ? 'card-row-status status-completed'
                                                        : prog.StatusId === 7
                                                          ? 'card-row-status status-completed'
                                                          : prog.StatusId === 8 &&
                                                          'card-row-status status-rejected'
                                          }
                                        >
                                          {prog.Status}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="program-column">
                                      {(!this.props.PnPComponents.program.stageOneFields &&
                                        (this.props.userInformation.emailAddress ===
                                          prog.ProgramCreator ||
                                          prog.ProgramCreator === undefined)) ||
                                        this.props.userInformation.isSuperAdmin ? (
                                        <EditIcon
                                          onClick={() => this.props.onClickProgram(prog)}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      ) : (
                                        <EyeIcon
                                          onClick={() => this.props.onClickProgram(prog)}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Card.Header>
                            </Card>
                          </Accordion>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )
          ) : (
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </React.Fragment>
      );
    }
  }

  class ModalContent extends Component {
    render() {
      const tab_data = [
        {
          tab_label: 'All Clients',
          tab_value: 1,
          tab_id: 'all-tab',
          tab_content: (
            <div className="cvm-tab-content">
              <div className="cvm-project-tile-container">
                <Row>
                  {this.props.clientList
                    .filter((x) =>
                      x.label.toLowerCase().includes(this.props.filterKeyword.toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <Col lg={4} md={6} key={index}>
                          <div className="cvm-project-tile">
                            <div
                              className="client-details-select"
                              onClick={() => this.props.onSelectClient(item, false)}
                            >
                              <div className="project-logo">
                                <img src={item.icon} alt="dashboard" />
                              </div>
                              <div className="project-name label14-medium-midnight">
                                {item.label}
                              </div>
                            </div>
                            <div className={`star-container ${item.favorite ? 'no-filter' : ''}`}>
                              <div onClick={() => this.props.onClickFavorite(item)}>
                                <FontAwesomeIcon icon={item.favorite ? solidStar : regularStar} />
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          ),
        },
        {
          tab_label: 'Favorites',
          tab_value: 2,
          tab_id: 'favorites-tab',
          tab_content: (
            <div className="cvm-tab-content">
              <div className="cvm-project-tile-container">
                <Row>
                  {this.props.clientList
                    .filter(
                      (x) =>
                        x.label.toLowerCase().includes(this.props.filterKeyword.toLowerCase()) &&
                        x.favorite
                    )
                    .map((item, index) => {
                      return (
                        <Col lg={4} md={6} key={index}>
                          <div className="cvm-project-tile">
                            <div
                              className="client-details-select"
                              onClick={() => this.props.onSelectClient(item, false)}
                            >
                              <div className="project-logo">
                                <img src={item.icon} alt="dashboard" />
                              </div>
                              <div className="project-name label14-medium-midnight">
                                {item.label}
                              </div>
                            </div>
                            <div className={`star-container ${item.favorite ? 'no-filter' : ''}`}>
                              <div onClick={() => this.props.onClickFavorite(item)}>
                                <FontAwesomeIcon icon={item.favorite ? solidStar : regularStar} />
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          ),
        },
      ];
      return (
        <div>
          <div className="search-bar-container">
            <div className="search-bar label14-regular-midnight">
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="large"
                name="search"
                iconStyle="solid"
                iconColor="stat-neutral"
              />
              <input
                type="text"
                name="name"
                placeholder="Search..."
                maxLength="255"
                onChange={(e) => this.props.onSearch(e)}
              />
            </div>
          </div>
          <GrooveTabComponent
            theme="purple"
            tab_data={tab_data}
            selectedIndex={this.props.selectedTab}
          />
        </div>
      );
    }
  }

  class ProgramMainContainer extends Component {
    constructor() {
      super();
      this.state = {
        modalActiveKey: 1,
        show: false,
        size: 'md',
        selectedTab: 0,
        isLoadingInitData: true,
        isDisplayMessage: true,
        cardView: true,
        projectList: [],
        isAddDisabled: false,
        dataEvent: {
          client: 0
        },
      };
    }
    async componentDidMount() {
      //DO THE FF IF URL CONTAINS CLIENT
      this.props.onChangeReportedYear([]);
      let clientName = window.location.pathname.split('/')[2];
      let tClient = IsValidStr(clientName) ? decodeURIComponent(clientName).replaceAll('*', '/'): undefined;
      this.props.clearProjectList();
      DeleteCallbackURL();
      DeleteReferrer();
      DeleteDistiURL();
      if (IsValidStr(tClient)) {
        this.loadDataFromURL(tClient);
        return;
      }
      const client = HasProperty(this.props.SharedData.selectedClient, 'value')
        ? this.props.SharedData.selectedClient.value
        : undefined;
      if (document.visibilityState === 'visible') LogUserSiteAction({
        module: CONFIG_MOD_LANDING_PAGE,
        ShouldLogPPCID: true,
        clientID: client === undefined ? 0 : client,
        ClientProgramID: null,
        ClientProjectID: null,
        progStatus: null,
        projStatus: null,
      });
      if (IsValid(client) && HasProperty(this.props.history.location.state, 'prevPath')) {
        this.setState({
          show: false,
          isLoadingInitData: false,
          isAddDisabled: true
        });
        await this.props.fetchAddNewProgramDetails(HasProperty(client, 'value') ? client.value : client);
        this.props.getProgramList(HasProperty(client, 'value') ? client.value : client);
        this.props.getProjectStakeHolders(HasProperty(client, 'value') ? client.value : client);
        this.props.getProgramClientDetails(HasProperty(client, 'value') ? client.value : client).then(() => {
          this.setState({
            isAddDisabled: false
          })
        })
        this.props.InitPnP();
        return;
      } else {
        this.props.getClientList();
        this.props.showClientDropdown();
        await this.displayModalOnLoad();
        this.props.getReportingYearList();
        this.setState({
          selectedTab:
            this.props.SharedData.clientList.filter((x) => x.favorite).length > 0 ? 1 : 0,
          isLoadingInitData: false,
          isAddDisabled: false
        });

        if (IsValid(client)) {
          this.props.InitPnP();
        }
      }
    }

    componentDidUpdate = (nextProps) => {
      if (nextProps.SharedData.selectedClient.value !== "" && nextProps.SharedData.selectedClient.value && nextProps.SharedData.selectedClient.value !== this.state.dataEvent.client) {
        //DataEvent("programLanding", nextProps.SharedData.selectedClient.text)
        this.setState({
          dataEvent: {
            client: nextProps.SharedData.selectedClient.value
          }
        })
      }

      if (
        nextProps.SharedData.clientList.length !== this.props.SharedData.clientList.length &&
        !this.state.show
      ) {
        this.setState({
          selectedTab:
            this.props.SharedData.clientList.filter((x) => x.favorite).length > 0 ? 1 : 0,
        });
      }
      if (nextProps.SharedData.selectedClient !== this.props.SharedData.selectedClient) {
        this.setState({
          isAddDisabled: true
        }, () => {
          this.props.InitPnP()
        })

        this.setState({
          isDisplayMessage: true,
        });
        setTimeout(async () => {
          this.setState({
            isAddDisabled: false
          })
        }, 1500)
      }

      if (
        nextProps.ProjectConfigurationData.projectList.length === 0 &&
        this.props.ProjectConfigurationData.projectList.length !== 0
      ) {
        this.props.ProjectConfigurationData.projectList.map((e) => {
          this.state.projectList.push(e);
          let uniqueProjectList = [...new Set(this.state.projectList)];
          this.setState({
            projectList: uniqueProjectList,
          });
        });
      } else if (
        (nextProps.ProjectConfigurationData.projectList.length === 0 &&
          this.props.ProjectConfigurationData.projectList.length === 0) ||
        this.props.ProjectConfigurationData.projectList.length === 0
      ) {
        //no action
      } else if (
        nextProps.ProjectConfigurationData.projectList[0].ClientProgramID !==
        this.props.ProjectConfigurationData.projectList[0].ClientProgramID
      ) {
        let isDuplicate = false;
        for (let progId in this.state.projectList) {
          if (
            this.state.projectList[progId].ClientProgramID ===
            this.props.ProjectConfigurationData.projectList[0].ClientProgramID
          ) {
            isDuplicate = true;
            break;
          }
        }
        if (!isDuplicate) {
          this.props.ProjectConfigurationData.projectList.map((e) => {
            this.state.projectList.push(e);
            let uniqueProjectList = [...new Set(this.state.projectList)];
            this.setState({
              projectList: uniqueProjectList,
            });
          });
        }
      }
    };

    onClickProgram = async (e, isEmail) => {
      this.setState({
        isLoadingInitData: true,
      });

      const clientMasterId = !IsObjEmpty(e) ? e.ClientMasterID : ""
      this.props.fetchAddNewProgramDetails(clientMasterId);
      const programList = await this.props.getProgramList(clientMasterId);
      let tempSelProg = e;
      if (IsValid(programList)) {
        let tempSelProgArr = programList.filter((prog) => { return prog.ClientProgramID === e.ClientProgramID })
        if (IsValid(tempSelProgArr) && tempSelProgArr.length > 0) {
          tempSelProg = tempSelProgArr[0];
        }
      }

      this.props.getBOIMappingList(e.ClientProgramID);
      await this.props.getBOIMappingListAllOfferings(e.ClientProgramID, "Config Module landing page");
      this.props.getCustomBOIDropdownList(e.ClientProgramID, "Config Module landing page");
      this.props.getProgramBOI(e.ClientProgramID);
      const clientID = !IsObjEmpty(this.props.SharedData.selectedClient) ? this.props.SharedData.selectedClient.value : "";

      let userRole = "";
      if (!this.props.UserInformationData.isSuperAdmin)
        userRole = this.props.UserInformationData.userProfiles.filter(x => x.ClientMasterId === clientID)[0].ProfileName;
      let bTLeadUrl = '';
      let OALUrl = '';
      switch (e.StatusId) {
        case 1:
        case 2: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramIDValueSettings';
          OALUrl = '/ProgramProjectConfig/ProgramIDValueSettings';
          break;
        }
        case 3: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
          OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval/review';
          break;
        }
        case 4: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
          OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
          break;
        }
        case 5: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
          OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval/review';
          break;
        }
        case 6: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
          OALUrl = '/ProgramProjectConfig/ProgramClientAlignment/submit';
          break;
        }
        case 7: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramClientAlignment';
          OALUrl = '/ProgramProjectConfig/ProgramClientAlignment';
          break;
        }
        case 8: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramClientAlignment';
          OALUrl = '/ProgramProjectConfig/ProgramClientAlignment/submit';
          break;
        }
        case 9: {
          bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
          OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
          break;
        }
        default:
          break;
      }
      this.props.setHistoryPath({ url: window.location.pathname });

      if (!IsObjEmpty(tempSelProg)) {
        this.props.setProgramDetails(tempSelProg, "Config Module landing page");
      } else {
        this.props.setProgramDetails(e, "Config Module landing page"); // update this to new selected program
      }
      this.props.setSelectedProgram({
        value: e.ClientProgramID,
        label: e.ClientProgramName,
        text: e.ClientProgramName,
      });
      this.props.InitPnP();
      if (isEmail === true) {
        this.props.setStepperActiveIndex(
          e.StatusId === 6 &&
            (!this.props.AccessValidationData.PnPComponents.program.stageTwoApprv ||
              !this.props.AccessValidationData.PnPComponents.project.stageTwoApprv)
            ? e.StageId + 1
            : e.StageId
        );
      } else {
        this.props.setStepperActiveIndex(
          e.StatusId === 8
            ? e.StageId
            : (e.StatusId === 3 || e.StatusId === 5) &&
              (userRole === 'CX Lead' ||
                userRole === 'Operations Account Lead' ||
                this.props.UserInformationData.isSuperAdmin)
              ? e.StageId
              : 1
        );
      }

      let userisCreatedUser = false;
      // if super admin cant review/approve/reject programs he/she created.
      if (this.props.UserInformationData.enterpriseID === e.CreatedUser) {
        userisCreatedUser = true;
      }
      this.setState({ isLoadingInitData: false });
      if (e.StatusId < 6) {
        // if(this.props.AccessValidationData.PnPComponents.program.stageTwoApprv || userisCreatedUser){ //if user has approve/reject asscess level it will return false, hence the condition
        setTimeout(() => {
          if (clientID !== clientMasterId) { //back to landing page-Bug 184531
            this.props.history.push('/ProgramProjectConfig')
          } else {
            if (this.props.AccessValidationData.PnPComponents.program.stageTwoApprv) {
              this.props.history.push(bTLeadUrl);
            } else {
              this.props.history.push(OALUrl);
            }
          }
        }, 250);
      } else {
        // if(this.props.AccessValidationData.PnPComponents.program.stageThreeAcknwldg || userisCreatedUser){ //if user has acknowledge asscess level it will return false, hence the condition
        if (clientID !== clientMasterId) { //back to landing page-Bug 184531
          this.props.history.push('/ProgramProjectConfig')
        } else {
          if (this.props.AccessValidationData.PnPComponents.program.stageTwoApprv) {
            this.props.history.push(bTLeadUrl);
          } else {
            this.props.history.push(OALUrl);
          }
        }
      }
    };

    onClickProjectDetails = async (e) => {
      this.props.setProgramDetails(e, "Config Module landing page");
      this.props.getBOIMappingList(e.ClientProgramID);
      this.props.getCustomBOIDropdownList(e.ClientProgramID, "Config Module landing page");
      this.props.getProgramBOI(e.ClientProgramID);
      await this.props.getBOIMappingListAllOfferings(e.ClientProgramID, "Config Module landing page");
      this.props.setSelectedProgram({
        value: e.ClientProgramID,
        label: e.ClientProgramName,
        text: e.ClientProgramName,
      });
      this.props.getBusinessAdvisorListByClientID();

      const client = HasProperty(this.props.SharedData.selectedClient, 'value')
        ? this.props.SharedData.selectedClient.value : undefined;
      if (IsValid(client)) {
        this.props.fetchAddNewProgramDetails(HasProperty(client, 'value') ? client.value : client);
      }
      //DataEvent("projectLanding", this.props.SharedData.selectedClient.text);
      this.props.setPath('/ProgramProjectConfig/projects');
      this.props.history.push('/ProgramProjectConfig/Projects');
    };
    onRedirectProject = (e) => {
      try {
        //for redirecting using url from email
        let path = window.location.pathname
        let historyPath = this.props.SharedData.historyPath.length !== 0 ? this.props.SharedData.historyPath[0].url.toLowerCase() : []
        if (path === '/ProgramProjectConfig' && historyPath !== "/programprojectconfig/projectaccountleadershipapproval/review" && historyPath.length === 0) {
          this.props.setSelectedProjectdetails(e, "Config Module landing page");
        }
        const SytemGenerated = TryGetNodeAlt(this.props.ProjectConfigurationData, "", 'selectedProjectDetails')
        const projCADetails = TryGetNodeAlt(this.props.ProjectConfigurationData, "", 'projectCADetails')
        const isSystemGenerated = IsValid(SytemGenerated) ? String(SytemGenerated.CreatedUser).toLowerCase() === 'system generated' : false
        const projCAApproved = !IsValid(projCADetails) ? false : projCADetails.isSubmit && this.props.ProjectConfigurationData.projectCAFileDetails.length > 0 ? true : false
        let projectLeadUrl = '';
        let OALUrl = '';
        switch (e.StatusId) {
          case 1:
          case 2: {
            // Not Started | In Progress
            projectLeadUrl = '/ProgramProjectConfig/ProjectIDValueSettings';
            OALUrl = '/ProgramProjectConfig/ProjectIDValueSettings';
            break;
          }
          case 3: {
            // Awaiting Approval
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval/review';
            break;
          }
          case 4: {
            // Rejected
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            break;
          }
          case 5: {
            // Awaiting Approval – Modified
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval/review';
            break;
          }
          case 6: {
            // Approved
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectClientAlignment/submit';
            break;
          }
          case 7: {
            // Configured & Aligned
            projectLeadUrl = '/ProgramProjectConfig/ProjectClientAlignment';
            OALUrl = `/ProgramProjectConfig/ProjectClientAlignment${!isSystemGenerated || !projCAApproved ? '/submit' : ''}`; //redirect from stage 2 & approved <- new flow 167486
            break;
          }
          case 8: {
            // Configured - yet to be aligned
            projectLeadUrl = '/ProgramProjectConfig/ProjectClientAlignment';
            OALUrl = '/ProgramProjectConfig/ProjectClientAlignment/submit';
            break;
          }
          case 9: {
            // Value Identification in Progress
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            break;
          }
          default:
            break;
        }

        this.props.getProjectBOI(e.ProjectID);
        this.props.setSelectedProjectdetails(e, "Config Module landing page");
        this.props.setSelectedProject({
          value: e.ProjectID,
          label: e.ClientProjectName,
          text: e.ClientProjectName,
        });
        this.props.InitPnP();
        this.props.setStepperActiveIndex(
          e.StatusId === 6 &&
            (!this.props.AccessValidationData.PnPComponents.program.stageTwoApprv ||
              !this.props.AccessValidationData.PnPComponents.project.stageTwoApprv)
            ? e.StageId + 1
            : e.StageId
        );
        this.setState({ isLoadingInitData: false });
        let userisCreatedUser = false;
        // if super admin cant review/approve/reject projects he/she created.
        if (this.props.UserInformationData.enterpriseID === e.CreatedUser) {
          userisCreatedUser = true;
        }

        if (e.StatusId < 6) {
          // if(this.props.AccessValidationData.PnPComponents.project.stageTwoApprv || userisCreatedUser){ //if user has approve/reject asscess level it will return false, hence the condition
          if (this.props.AccessValidationData.PnPComponents.project.stageTwoApprv) {
            this.props.history.push(projectLeadUrl);
          } else {
            this.props.history.push(OALUrl);
          }
        } else {
          // if(this.props.AccessValidationData.PnPComponents.project.stageThreeAcknwldg || userisCreatedUser){ //if user has acknowledge asscess level it will return false, hence the condition
          if (this.props.AccessValidationData.PnPComponents.project.stageThreeAcknwldg) {
            this.props.history.push(projectLeadUrl);
          } else {
            this.props.history.push(OALUrl);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    loadDataFromURL = async (tClient) => {
      //GET & SAVE URL PARAMETERS
      tClient = decodeURIComponent(tClient);
      const programID = window.location.pathname.split('/')[3] || '';
      const projectID = window.location.pathname.split('/')[4] || '';

      //GET CLIENT LIST INFORMATION & VERIFY CLIENT FROM URL
      this.props.getReportingYearList();
      const clientList = await this.props.getClientList();
      const client = clientList.find((e) => {
        return tClient.toLowerCase().trim() === e.label.toLowerCase().trim();
      });

      //SET CLIENT
      if (IsValid(client)) {
        this.setState({ show: true }, async () => {
          //VERIFY URL PROGRAM
          const programList = await this.onSelectClient(client);
          let selProg = programList.filter((e) => {
            return String(e.ClientProgramID) === programID;
          });
          await this.props.setCurrentUserDetails()

          //SET PROGRAM
          if (IsArray(selProg) && selProg.length > 0) {
            //GET PROJECT LIST & VERIFY URL PROJECT
            selProg = selProg[0];
            let projectList = await this.props.getProjectProgramDetails(programID);
            projectList = HasProperty(projectList, 'ProjectList') ? projectList.ProjectList : [];
            let selProj = projectList.filter((e) => {
              return String(e.ProjectID) === projectID;
            });

            //SET PROJECT
            if (IsArray(selProj) && selProj.length > 0) {
              this.props.setProgramDetails(selProg, "Config Module landing page");
              this.props.setSelectedProgram({
                value: selProg.ClientProgramID,
                label: selProg.ClientProgramName,
                text: selProg.ClientProgramName,
              });
              selProj = selProj[0];
              this.onRedirectProject(selProj);
            } else if (
              selProj.length === 0 &&
              String(projectID) !== '' &&
              [7, 8].indexOf(selProg.StatusId) > -1
            ) {
              this.onClickProjectDetails(selProg);
            } else {
              this.onClickProgram(selProg, true);
            }
          } else {
            this.setState({ isLoadingInitData: false });
          }
        });
      } else {
        this.setState({ isLoadingInitData: false });
      }
      this.props.getAssetNameAndDeliveredByList()
      this.props.showClientDropdown();
      this.props.history.push('/ProgramProjectConfig');
    };

    displayModalOnLoad = async () => {
      const path = window.location.pathname.toLowerCase();
      if (
        path === '/clientconfiguration' ||
        path === '/dataingestion' ||
        path === '/programprojectconfig'
      ) {
        if (Object.keys(this.props.SharedData.selectedClient).length > 0) {
          if (
            this.props.SharedData.selectedClient.label === '' ||
            this.props.SharedData.selectedClient.label === undefined
          ) {
            this.setState({
              show: true,
            });
          } else {
            this.setState({
              show: false,
            });
          }
        } else {
          this.setState({
            show: !this.props.SharedData.isLeftPanel,
          });
        }
      } else {
        this.setState({
          show: !this.props.SharedData.isLeftPanel,
        });
      }
    };

    onChangeModalTab = (event) => {
      let selectedActiveKey = event;
      this.setState({
        ...this.state,
        modalActiveKey: selectedActiveKey,
      });
    };
    onCloseModal = () => {
      this.setState(
        {
          ...this.state,
          show: !this.state.show,
        },
        () => {
          this.props.history.push('/');
          this.props.showClientDropdown();
        }
      );
    };
    onSelectClient = async (item, shouldLogAction) => {
      this.props.getProgramClientDetails(item.value);
      this.props.fetchAddNewProgramDetails(item.value);
      const programList = await this.props.getProgramList(item.value);
      this.setState(
        {
          show:shouldLogAction,
          isAddDisabled: true
        },
        async () => {
          await this.props.setSelectedClient(item);
          await this.props.setCurrentUserDetails();

          if (HasValue(shouldLogAction)) {
            LogUserSiteAction({
              module: CONFIG_MOD_LANDING_PAGE,
              ShouldLogPPCID: true,
              clientID: item.value,
              ClientProgramID: null,
              ClientProjectID: null,
              progStatus: null,
              projStatus: null,
            });
          }
          this.setState({
            isAddDisabled: false
          })
        }
      );

      return programList;
    };

    onChangeView = (view) => {
      if (view === 'Card') {
        this.setState({
          cardView: true,
        });
      } else {
        this.setState({
          projectList: [],
          cardView: false,
        });
      }
    };

    getSubOffering = (index) => {
      const { SubOfferingList } = this.props.ProgramConfigurationData.programList[index];
      let subOffering = [];
      try {
        SubOfferingList.map((e) => {
          subOffering.push(e.SubOfferingName);
        });
      } catch (err) {
        console.log(err);
      }
      return subOffering;
    };

    onClickAccordion = (e, action) => {
      let isCurrentlyCollapsed =
        e.currentTarget.parentElement.previousSibling.className === 'collapse' ? true : false;
      if (action === 'div' && isCurrentlyCollapsed) {
        e.currentTarget.nextSibling.click();
        e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
      } else {
        //btn
        if (!isCurrentlyCollapsed) {
          e.currentTarget.parentElement.parentElement.style.cursor = 'pointer';
        } else {
          e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
        }
      }
    };

    onProjectShow = (e) => {
      this.props.setProgramDetails(e, "Config Module landing page");
      this.props.setSelectedProgram({
        value: e.ClientProgramID,
        label: e.ClientProgramName,
        text: e.ClientProgramName,
      });
      try {
        this.props.getProjectProgramDetails(e.ClientProgramID);
        this.props.getProgramBOIList(e.ClientProgramID);
      } catch (err) {
        console.log(err);
      }
    };

    onClickProject = (e) => {
      try {
        const clientID = this.props.clientID;
        // const userRole = this.props.UserInformationData.userProfiles.filter(x => x.ClientMasterId === clientID)[0].ProfileName
        let projectLeadUrl = '';
        let OALUrl = '';
        switch (e.StatusId) {
          case 1:
          case 2: {
            // Not Started | In Progress
            projectLeadUrl = '/ProgramProjectConfig/ProjectIDValueSettings';
            OALUrl = '/ProgramProjectConfig/ProjectIDValueSettings';
            break;
          }
          case 3: {
            // Awaiting Approval
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval/review';
            break;
          }
          case 4: {
            // Rejected
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            break;
          }
          case 5: {
            // Awaiting Approval – Modified
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval/review';
            break;
          }
          case 6: {
            // Approved
            projectLeadUrl = '/ProgramProjectConfig/ProjectAccountLeadershipApproval';
            OALUrl = '/ProgramProjectConfig/ProjectClientAlignment/submit';
            break;
          }
          case 7: {
            // Configured & Aligned
            projectLeadUrl = '/ProgramProjectConfig/ProjectClientAlignment';
            OALUrl = '/ProgramProjectConfig/ProjectClientAlignment';
            break;
          }
          case 8: {
            // Configured - yet to be aligned
            projectLeadUrl = '/ProgramProjectConfig/ProjectClientAlignment';
            OALUrl = '/ProgramProjectConfig/ProjectClientAlignment/submit';
            break;
          }
          default:
            break;
        }

        this.props.getProjectBOI(e.ProjectID);
        this.props.setSelectedProjectdetails(e, "Config Module landing page");
        this.props.setSelectedProject({
          value: e.ProjectID,
          label: e.ClientProjectName,
          text: e.ClientProjectName,
        });
        this.props.setStepperActiveIndex(
          e.StatusId === 6 &&
            (!this.props.AccessValidationData.PnPComponents.program.stageTwoApprv ||
              !this.props.AccessValidationData.PnPComponents.project.stageTwoApprv)
            ? e.StageId + 1
            : e.StageId
        );

        let userisCreatedUser = false;
        // if super admin cant review/approve/reject projects he/she created.
        if (this.props.UserInformationData.enterpriseID === e.CreatedUser) {
          userisCreatedUser = true;
        }

        if (e.StatusId < 6) {
          // if(this.props.AccessValidationData.PnPComponents.project.stageTwoApprv || userisCreatedUser){ //if user has approve/reject asscess level it will return false, hence the condition
          if (this.props.AccessValidationData.PnPComponents.project.stageTwoApprv) {
            this.props.history.push(projectLeadUrl);
          } else {
            this.props.history.push(OALUrl);
          }
        } else {
          // if(this.props.AccessValidationData.PnPComponents.project.stageThreeAcknwldg || userisCreatedUser){ //if user has acknowledge asscess level it will return false, hence the condition
          if (this.props.AccessValidationData.PnPComponents.project.stageThreeAcknwldg) {
            this.props.history.push(projectLeadUrl);
          } else {
            this.props.history.push(OALUrl);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    onCloseMessage = () => {
      this.setState({
        isDisplayMessage: false,
      });
    };

    toBeAlignedPrograms = () => {
      const { programList } = this.props.ProgramConfigurationData;
      let programs = [];
      try {
        programList.map((e) => {
          if (e.StatusId === 8) programs.push(e.ClientProgramName);
        });
      } catch (err) { }
      return programs;
    };

    handleOnMouseOverBtn = (e, action) => {
      try {
        let display = "block"
        if (action === "hover") {
          display = "none";
        }
        document.getElementById(e).style.display = display;
      }
      catch (e) {
        console.log(e)
      }
    }

    render() {
      const { filterKeyword } = this.props.ClientConfigurationData;
      const { clientList, selectedClient } = this.props.SharedData;
      const { programClientDetails, programList, selectedProgramDetails } =
        this.props.ProgramConfigurationData;
      const {
        onClickFavorite,
        onSearch,
        getProgramClientDetails,
        clearAllProgramProjectFields,
        setStepperActiveIndex,
        clearAllProjectFields,
      } = this.props;

      const { modules } = this.props.HomeData;
      const { PnPComponents } = this.props.AccessValidationData;
      const userInformation = this.props.UserInformationData;
      return modules.length > 0 && !this.state.isLoadingInitData ? (
        CheckModuleAccess(modules) ? (
          <ProgramMainPresentational
            programList={programList}
            onClickProgram={this.onClickProgram}
            onClickProjectDetails={this.onClickProjectDetails}
            selectedTab={this.state.selectedTab}
            modalActiveKey={this.state.modalActiveKey}
            onChangeModalTab={this.onChangeModalTab}
            show={this.state.show}
            size={this.state.size}
            onCloseModal={this.onCloseModal}
            clientList={clientList}
            onClickFavorite={onClickFavorite}
            onSearch={onSearch}
            filterKeyword={filterKeyword}
            onSelectClient={this.onSelectClient}
            getProgramClientDetails={getProgramClientDetails}
            programClientDetails={programClientDetails}
            clearAllProgramProjectFields={clearAllProgramProjectFields}
            setStepperActiveIndex={setStepperActiveIndex}
            PnPComponents={PnPComponents}
            selectedClient={selectedClient}
            onChangeView={this.onChangeView}
            cardView={this.state.cardView}
            getSubOffering={this.getSubOffering}
            userInformation={userInformation}
            onProjectShow={this.onProjectShow}
            onClickProject={this.onClickProject}
            clearAllProjectFields={clearAllProjectFields}
            selectedProgramDetails={selectedProgramDetails}
            projectList={this.state.projectList}
            toBeAlignedPrograms={this.toBeAlignedPrograms}
            onCloseMessage={this.onCloseMessage}
            isDisplayMessage={this.state.isDisplayMessage}
            history={this.props.history}
            handleOnMouseOverBtn={this.handleOnMouseOverBtn}
            isAddDisabled={this.state.isAddDisabled}
          />
        ) : (
          <Row className="access_denied_container">
            <Col md={12} xs={12}>
              <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
              <div className="access_denied_content">
                <h1>Access Denied</h1>
                <p>
                  You don't have the necessary user privileges to view the client that you are
                  attempting to access.
                </p>
              </div>
            </Col>
          </Row>
        )
      ) : (
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
  }

  return ProgramMainContainer;
})();

// export default ProgramMain;
export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      UserInformationData: state.userInformation,
      HomeData: state.HomeData,
      ProgramConfigurationData: state.ProgramConfigurationData,
      ProjectConfigurationData: state.ProjectConfigurationData,
      AccessValidationData: state.AccessValidationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getClientList,
          onClickFavorite,
          onSearch,
          showClientDropdown,
          setSelectedClient,
          setCurrentUserDetails,
          // setClientDetails,
          setReportingYear,
          setWorkFlowReportingYear,
          getReportingYearList,
          clearConfigClientFields,
          getClientBasicDetails,
          getOfferingsList,
          onCloseNotifyToast,
          getProgramClientDetails,
          getProgramList,
          fetchAddNewProgramDetails,
          getBOIMappingList,
          getCustomBOIDropdownList,
          getProgramBOI,
          setProgramDetails,
          setSelectedProgram,
          clearAllProgramProjectFields,
          setStepperActiveIndex,
          setPath,
          InitPnP,
          getProjectProgramDetails,
          setStepperActiveIndex,
          getProjectBOI,
          setSelectedProjectdetails,
          setSelectedProject,
          clearAllProjectFields,
          clearProjectList,
          getBOIMappingListAllOfferings,
          onChangeReportedYear,
          getProjectStakeHolders,
          setHistoryPath,
          getAssetNameAndDeliveredByList,
          getBusinessAdvisorListByClientID
        }
      ),
      dispatch
    )
)(withRouter(ProgramMain));
