import React, { Component } from "react"
import { GrooveRadioButton, GrooveButton, GrooveTextBox } from 'tfo-groove'
import { GrooveSelectMenuBasic } from 'tfo-groove'
import CVMModal from "../Shared/CVMModal/CVMModal"
import { IsValidText } from "../../Utilities/Validations";

const SubmitSettingsPopup = (function () {
  class SubmitSettingsPopupPresentational extends Component {
    render() {
      const form = this.props.form
      const formContents = this.props.formContents
      const formCB = this.props.formCB
      return (
        <React.Fragment>
          <GrooveButton
            d="primry-btn-2"
            type="solid"
            colorClass="stat-alternate"
            text="Submit"
            callbackFunction={this.props.onClickSubmitMain}
            isDisabled={this.props.isDisabled || this.props.shouldDisable || this.props.disableSendBtn}
          />
          <CVMModal
            className="progproj-submit-modal"
            title="Submit Settings"
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            size="md"
            content={
              <div>
                <div className="bodytext14-regular-midnight">
                  {formContents.question}
                </div>
                <div className="radio-button-container flip-switch-container bodytext14-medium-midnight">
                  <div className={form.yesNo === 'Yes' ? 'selected' : 'not-selected'} onClick={() => { formCB('button', 'Yes') }}>Yes</div>
                  <div className={form.yesNo === 'No' ? 'selected' : 'not-selected'} onClick={() => { formCB('button', 'No') }}>No</div>
                </div>
                <label>Rejection Recipient</label>
                <GrooveSelectMenuBasic
                  name="Approver ID"
                  isDisabled={true}
                  placeholder={formContents.recipientLabel}
                  list={formContents.recipientList}
                  defaultData={formContents.recipient}
                  id="approver"
                />
                <div className="comments">
                  <GrooveTextBox
                    maxLength={255}
                    id="comments"
                    name="comments"
                    type="text"
                    placeholder={`${(form.comment !== "") ? "" : "Enter "}Comment`}
                    rows="2"
                    textValue={form.comment}
                    callbackFunction={(e) => { formCB('comment', e.value) }}
                  />
                </div>
                <div className="add-program-buttons">
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Cancel"
                    callbackFunction={this.props.onCloseModal}
                  />
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={form.yesNo !== 'Yes' || form.invalidComment || this.props.disableSendBtn}
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Submit"
                    callbackFunction={this.props.onClickSubmit}
                  />
                </div>
              </div>
            }
          />
        </React.Fragment>
      )
    }
  }

  class SubmitSettingsPopupContainer extends Component {
    constructor() {
      super()
      this.state = {
        show: false,
        form: {
          comment: '',
          yesNo: 'No',
          invalidComment: false,
        }
      }
    }

    componentDidMount = () => {
      this.changeTextAreaClass()
    }
    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    onCloseModal = () => {
      this.setState({
        show: false,
        form: {
          comment: '',
          yesNo: 'No'
        }
      })
    }

    formCB = (type, e) => {
      let fdata = this.state.form
      switch (type) {
        case 'button':
          fdata.yesNo = e
          break;
        case 'comment':
          fdata.comment = e;
          fdata.invalidComment = !IsValidText(e);
          break;
        default:
          break;
      }
      this.setState({ form: fdata })
    }

    onClickSubmit = async () => {
      await this.props.onClickSubmit(this.state.form)
      this.setState({ show: false })
    }
    onModalRadioChange = () => { }
    onClickSubmitMain = () => {
      if (!this.props.preSubmit()) return
      this.setState({ show: true })

    }
    render() {
      return (<SubmitSettingsPopupPresentational
        show={this.state.show}
        formContents={this.props.formContents}
        form={this.state.form}
        formCB={this.formCB}
        onCloseModal={this.onCloseModal}
        onClickSubmit={this.onClickSubmit}
        onModalRadioChange={this.onModalRadioChange}
        onClickSubmitMain={this.onClickSubmitMain}
        shouldDisable={this.props.shouldDisable}
        disableSendBtn={this.props.disableSendBtn || false}
        isDisabled={this.props.isDisabled || false}
      />)
    }
  }

  return SubmitSettingsPopupContainer;
})();

export default SubmitSettingsPopup;
