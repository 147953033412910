import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveButton, GrooveIconSvg } from 'tfo-groove';
import { Row, Col } from 'react-bootstrap';
// import { history } from '../../../store';
import { withRouter } from "react-router-dom";
import { TryGetObjValueAlt, TryGetValue } from '../../../Utilities/Formatter';
import { HttpPostText, GetSubMenuId } from '../../../Utilities/HTTPUtil';
import ProgramProjectHeader from '../ProgramProjectHeader';
import DimensionAccordion from '../DimensionAccordion/DimensionAccordion';
import Stepper from '../Stepper';
import RequestorCommentPopup from '../RequestorCommentPopup';
import SubmitSettingsPopup from '../SubmitSettingsPopup';
import {
  setToasterParam,
  getCustomBOIDetails,
  setHistoryPath,
  setEmailError
} from '../../../Actions/Shared.Action';

import {
  getProgramBOI,
  getProgramList,
  clearAllProgramProjectFields,
  setNewProgramDetails
} from '../../../Actions/ProgramConfiguration.Action';
import { getProgramBOIHistory } from '../../../Actions/ProgramProjectBOIHistory.Action';
import { IsValid, IsValidStr, HasProperty, IsArrayEmpty, IsValidText, IsValidAccentureEmail } from '../../../Utilities/Validations';

import historyIcon from '../../../Static/images/360cvm/History.svg';
// import {
//   DataEvent,
// } from '../../../Utilities/DataEvents';

import CVMModal from '../../Shared/CVMModal/CVMModal';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import BOIHistoryModal from '../BOIHistoryModal';
import {
  PROG_ID_VAL_ALA,
  PPC_NEW_PROG_APP,
  PPC_EX_PROG_APP,
  ACC_PROG
} from '../../../Constants/Modules';
import { LogUserSiteAction, LogPPCUserAction } from '../../../Utilities/AccessManagementUtil';

const ProgramALAppReview = (function () {
  class ProgramALAppReviewPresentational extends Component {
    render() {
      const isReadOnly =
        this.props.programDetails.StatusId === 4 ||
        this.props.PnPComponents.program.stageTwotransactBtn;

      let clientID = this.props.programClientDetails.ClientMasterID;
      let user = this.props.userInformation.userProfiles.find(x => x.ClientMasterId === clientID && x.ProfileID !== 16); //exclude in filter QC user
      let isProgProjConfig = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15; //find if user role in client is BTLead
      const userType = this.props.userInformation.isSuperAdmin ? 'admin' : isProgProjConfig ? 'ppcUser' : '';

      const missingPPC =
        Object.keys(this.props.newProgramDetails.progCreator).length === 0
        || !IsValid(this.props.newProgramDetails.progCreator.label)

      const isProgCreatorListEmpty = !IsValid(this.props.ConfiguratorsList) || this.props.ConfiguratorsList.length === 0

      return (
        <div className="BT-Lead-Landing-Page">
          <ProgramProjectHeader type="Program" />
          <Stepper type="Program" />
          {this.props.displayDeleted && this.props.deletedBOIS !== 0 && !this.props.isSuperAdmin && (
            <div
              className="no-boidefinition-warning"
              style={{ position: 'relative', marginTop: '10px' }}
            >
              <GrooveIconSvg
                customClassName="accordion-button"
                size="small"
                name="exclamation-triangle"
                iconStyle="solid"
                iconColor="stat-warning"
              />
              <div className="no-boidefinition-text">
                {this.props.deletedBOIS} Business Outcome Indicator has been deleted. To learn more
                about deleted BOIs, please view the 'Deleted BOI' page in BOI History section.
              </div>
              <GrooveIconSvg
                customClassName="accordion-button"
                size="small"
                name="times"
                iconStyle="solid"
                iconColor="stat-warning"
                callbackFunction={this.props.onCloseDeleted}
              />
            </div>
          )}
          {this.props.displayModified && this.props.modifiedBOIS !== 0 && (
            <div
              className="no-boidefinition-warning"
              style={{ position: 'relative', marginTop: '10px' }}
            >
              <GrooveIconSvg
                customClassName="accordion-button"
                size="small"
                name="exclamation-triangle"
                iconStyle="solid"
                iconColor="stat-warning"
              />
              <div className="no-boidefinition-text">
                {this.props.modifiedBOIS} Approved Business Outcome Indicators were edited. To learn
                more about the edited BOIs, please view the 'Modified BOI' section in BOI History
                page.
              </div>
              <GrooveIconSvg
                customClassName="accordion-button"
                size="small"
                name="times"
                iconStyle="solid"
                iconColor="stat-warning"
                callbackFunction={this.props.onCloseModified}
              />
            </div>
          )}
          {this.props.PnPComponents.program.acctLeadApprv ||
            this.props.PnPComponents.program.stageTwoApprv ? (
            <Row className="access_denied_container">
              <Col md={12} xs={12}>
                <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                <div className="access_denied_content">
                  <h1>Access Denied</h1>
                  <p>
                    You don't have the necessary user privileges to view the page that you are
                    attempting to access.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              <DimensionAccordion
                type={'Program'}
                mode={isReadOnly ? 'view' : 'review'}
                customBOI={this.props.SharedData.customBOI}
                ProgramConfigurationData={this.props.ProgramConfigurationData}
                dimensionList={this.props.dimensionList}
                formCallback={this.props.dimFormCB}
                isApproverEditView={this.props.isApproverEditView}
                userType={userType}
                topLeftContainer={
                  <React.Fragment>
                    {missingPPC && (
                      <div className="missing prog-creator aa">{`No Program Creator is mapped to the program. 
                      ${isProgCreatorListEmpty
                          ? 'Please reach out to SVM.Admin.'
                          : 'Please navigate to Program Target Value Settings page to update Program Creator for the program.'
                        }`}</div>
                    )}
                    {this.props.showError.show && (
                      <div className="missing prog-creator aa">{this.props.showError.message}</div>
                    )}
                    <BOIHistoryModal historyType="Program" />
                    <RequestorCommentPopup formContents={this.props.requestorFormContents} />
                  </React.Fragment>
                }
                componentType={'review'}
                listenToScroll={this.props.listenToScroll}
              // userType={userType}
              />
              {this.props.displayErrorMessage && (
                <div className="missing">There are blank Target value field(s)</div>
              )}
              {this.props.isSubmitDisabled && this.props.invalidEmailsList.length === 0 && (
                <div className="missing boi-stat-warning">Please approve or reject BOI(s) before you proceed.</div>
                )}

              {this.props.invalidEmailsList.length > 0 ? (
                <div className="missing boi-stat-warning">
                  {`Please update the given invalid EIDs in order to proceed: ${this.props.invalidEmailsList.join(', ')}`}
                </div>
              ) : <></>}
              {isReadOnly ? (
                ''
              ) : (
                <React.Fragment>
                  <div id='track-scroll'>
                    <span className="BT-Lead-Landing-bottom-btn">
                      <span className="applyFlex-space-between">
                        <GrooveButton
                          id="primry-btn-2"
                          type="outline"
                          colorClass="stat-alternate"
                          text="Save as Draft"
                          customClassName="add-project-button"
                          callbackFunction={this.props.onClickSAD}
                          isDisabled={this.props.disableSADBtn || missingPPC}
                        />
                      </span>
                      <span className="applyFlex-space-between">
                        {this.props.submitFormContents.submitType === 'submit' ? (
                          <GrooveButton
                            d="primry-btn-2"
                            type="solid"
                            colorClass="stat-alternate"
                            text="Submit"
                            callbackFunction={() => this.props.onClickConfirmBoi(true)}
                            isDisabled={this.props.isSubmitDisabled || missingPPC}
                          />
                        ) : (
                          <SubmitSettingsPopup
                            preSubmit={this.props.validateDimension}
                            onClickSubmit={this.props.onClickReject}
                            formContents={this.props.submitFormContents}
                            shouldDisable={this.props.isSubmitDisabled || missingPPC}
                            disableSendBtn={this.props.disableRejectBtn}
                          />
                        )}
                      </span>
                    </span>
                  </div>
                  <div className={this.props.positionFixed ? !(this.props.isSubmitDisabled || missingPPC) ? 'sticky-button' : 'sticky-button hide-sticky' : 'sticky-button hide-sticky'}>
                    <span className="BT-Lead-Landing-bottom-btn">
                      <span className="applyFlex-space-between">
                        <GrooveButton
                          id="primry-btn-2"
                          type="outline"
                          colorClass="stat-alternate"
                          text="Save as Draft"
                          customClassName="add-project-button"
                          callbackFunction={this.props.onClickSAD}
                          isDisabled={this.props.disableSADBtn || missingPPC}
                        />
                      </span>

                      <span className="applyFlex-space-between">
                        {this.props.submitFormContents.submitType === 'submit' ? (
                          <GrooveButton
                            d="primry-btn-2"
                            type="solid"
                            colorClass="stat-alternate"
                            text="Submit"
                            callbackFunction={() => this.props.onClickConfirmBoi(true)}
                            isDisabled={this.props.isSubmitDisabled || missingPPC}
                          />
                        ) : (
                          <SubmitSettingsPopup
                            preSubmit={this.props.validateDimension}
                            onClickSubmit={this.props.onClickReject}
                            formContents={this.props.submitFormContents}
                            shouldDisable={this.props.isSubmitDisabled || missingPPC}
                            disableSendBtn={this.props.disableRejectBtn}
                          />
                        )}
                      </span>
                    </span>
                  </div>
                </React.Fragment>
              )}
              {/**MODAL */}
              <CVMModal
                title={''}
                customclass={'notifyModal-dialog'}
                show={this.props.showConfirmApprovalModal}
                onCloseModal={() => this.props.onClickConfirmBoi(false)}
                content={
                  <CVMWarningModal
                    body={"Are you sure you would like to approve BOI's?"}
                    handleCancel={() => this.props.onClickConfirmBoi(false)}
                    handleYes={this.props.onClickSubmit}
                    disableSendBtn={this.props.disableSendBtn}
                  />
                }
              />
            </React.Fragment>
          )}
        </div>
      );
    }
  }

  class ProgramALAppReviewContainer extends Component {
    constructor(props) {
      super(props);
      const progCreator = this.props.programDetails.ProgramCreator;

      this.state = {
        dimensionList: this.props.dimensionList || [],
        submitFormContents: {
          submitType: 'submit',
          comment: '',
          question: `Are you sure you want to reject this Program Target Value settings?`,
          recipientLabel: 'Program Creator',
          recipient: { value: progCreator, label: progCreator, text: progCreator },
          recipientList: [{ value: progCreator, label: progCreator }]
        },
        requestorFormContents: {
          comment: '',
          requestor: '',
          requestorLabel: 'Program Creator'
        },
        displayErrorMessage: false,
        displayDeleted: true,
        displayModified: true,
        isSubmitDisabled: false,
        showConfirmApprovalModal: false,
        isApproverEditView: false,
        disableSendBtn: false,
        disableSADBtn: false,
        disableRejectBtn: false,
        positionFixed: false,
        invalidEmailsList: [],
      };
    }

    checkForInvalidEmailFirst = async () => {
      let invalidEmailsList = [];
      let CXLeadList = this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.split(',') : [];
      let OALeadList = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.split(',') : [];
      let progCreator = this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator !== null ? [this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator] : [];

      const data = await IsValidAccentureEmail([...CXLeadList, ...OALeadList, ...progCreator]);
      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          invalidEmailsList.push(data[i].UserName.split('@')[0]);
        }
      }

      this.setState({
        invalidEmailsList: invalidEmailsList,
        isSubmitDisabled: invalidEmailsList.length > 0,
        disableSADBtn: invalidEmailsList.length > 0,
      })
    }

    listenToScroll = () => {
      let trackScroll = document.getElementById('track-scroll');
      let var1 = trackScroll
        ? document.getElementById('root').clientHeight - trackScroll.offsetTop - 80
        : 0;
      let var2 =
        document.getElementById('root').clientHeight -
        document.documentElement.scrollTop -
        window.innerHeight;

      if (var2 > var1) {
        this.setState({
          positionFixed: true,
        });
      } else {
        this.setState({
          positionFixed: false,
        });
      }
    };

    componentDidMount() {
      window.scrollTo(0, 0)
      this.listenToScroll();
      this.props.setEmailError({
        show: false,
        message: ""
      });
      this.checkForInvalidEmailFirst();
      const prog = this.props.ProgramConfigurationData.selectedProgram.value;
      let isApproverEditView = this.props.isSuperAdmin || this.props.OAL.includes(this.props.userEmail);

      LogUserSiteAction({
        module: PROG_ID_VAL_ALA,
        ShouldLogPPCID: true,
        ClientProgramID: prog,
        ClientProjectID: null,
        progStatus: ACC_PROG
      });
      // this.dimFormCB(this.setBOIsToApprovedDefault(this.props.dimensionList));
      this.dimFormCB(this.props.dimensionList);
      this.setRequestorCommentForm();
      this.setNewProgDetails();
      this.props.getProgramBOIHistory(prog);
      this.formatBOIs(this.props.dimensionList);
      this.setState({
        isApproverEditView: isApproverEditView
      })
    }

    componentDidUpdate(prevProps) {
      window.addEventListener('scroll', this.listenToScroll);
      if (JSON.stringify(this.props.dimensionList) !== JSON.stringify(prevProps.dimensionList)) {
        // this.dimFormCB(this.setBOIsToApprovedDefault(this.props.dimensionList));
        this.dimFormCB(this.props.dimensionList);
        this.setNewProgDetails();
        this.formatBOIs(this.props.dimensionList);
      }

      if (JSON.stringify(this.props.programDetails) !== JSON.stringify(prevProps.programDetails)) {
        const progCreator = this.props.programDetails.ProgramCreator;
        this.setState({
          submitFormContents: {
            submitType: 'submit',
            comment: '',
            question: `Are you sure you want to reject this Program Target Value settings?`,
            recipientLabel: 'Program Creator',
            recipient: { value: progCreator, label: progCreator, text: progCreator },
            recipientList: [{ value: progCreator, label: progCreator }]
          }
        });
      }

      if (
        this.props.arsDetails[this.props.arsDetails.length - 1] !==
        prevProps.arsDetails[prevProps.arsDetails.length - 1]
      )
        this.setRequestorCommentForm();
      setTimeout(this.listenToScroll, 200);
    }

    setNewProgDetails = () => {
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      if (programDetails.ClientProgramID) {
        let subOff = [];
        let cxl = [];
        let oal = [];
        programDetails.SubOfferingList.map(item => {
          subOff.push({
            label: item.SubOfferingName,
            value: item.SubOfferingID
          });
        });

        programDetails.CXLLead.split(',').map(x => {
          cxl.push({
            label: x,
            text: x,
            value: x
          });
        });

        programDetails.OperationsAccountLead.split(',').map(x => {
          oal.push({
            label: x,
            text: x,
            value: x
          });
        });

        newProgramDetails.offering = {
          label: programDetails.Offering,
          text: programDetails.Offering,
          value: programDetails.OfferingID
        };
        newProgramDetails.subOffering = subOff;
        newProgramDetails.programName = programDetails.ClientProgramName;
        newProgramDetails.cxLead = cxl;
        newProgramDetails.oaLead = oal;
        newProgramDetails.progCreator = {
          label: programDetails.ProgramCreator,
          text: programDetails.ProgramCreator,
          value: programDetails.ProgramCreator
        };

        this.props.setNewProgramDetails(newProgramDetails);
      }
    };

    setBOIsToApprovedDefault = dimList => {
      let dim = dimList;
      if (
        [3, 5].indexOf(this.props.programDetails.StatusId) > -1 &&
        dim.some(e =>
          e.subDimensionList.some(e2 => e2.boiList.some(e3 => !IsValid(e3.boiStatus.trim())))
        )
      ) {
        dim.forEach(e => {
          e.subDimensionList.forEach(e2 => {
            e2.boiList.forEach(e3 => {
              e3.boiStatus = 'Approved';
              e3.comment = '';
            });
          });
        });
      }
      return dim;
    };
    setRequestorCommentForm = () => {
      const ars = this.props.arsDetails;
      if (ars.length > 0) {
        this.setState({
          requestorFormContents: {
            comment: ars[ars.length - 1].requestorComment || '',
            requestor: ars[ars.length - 1].lead1,
            requestorLabel: 'Program Creator'
          }
        });
      }
    };

    dimFormCB = (dimList, params) => {
      const dim = dimList;
      let approved = false;
      let isSubmitDisabled = false;
      approved = dim.some(e => {
        return e.subDimensionList.some(e2 => {
          return e2.boiList.some(e3 => {
            return ['Approved', 'Conditional Approval', ''].indexOf(e3.boiStatus) > -1;
          });
        });
      });
      let tDisplayErrorMessage = this.state.displayErrorMessage;
      if (this.state.displayErrorMessage) {
        if (
          !dim.some(e => {
            return e.subDimensionList.some(e2 => {
              return e2.boiList.some(e3 => {
                return !IsValidStr(String(e3.paramValue).trim());
              });
            });
          })
        )
          tDisplayErrorMessage = false;
      }

      // if (
      //   [3, 5].indexOf(this.props.programDetails.StatusId) > -1 &&
      //   dim.some(e =>
      //     e.subDimensionList.some(e2 => e2.boiList.some(e3 => !IsValid(e3.boiStatus.trim())))
      //   )
      // ) {
      //   isSubmitDisabled = true;
      // }

      //check if the toggle is off then set the status to approved.
      // dimList.map(x => {
      //   x.subDimensionList.map(y => {
      //     y.boiList.map(z => {
      //       if (!z.boiWheelVisibility) {
      //         z.boiStatus = 'Approved';
      //       }
      //     });
      //   });
      // });

      //if rejected, bring back to old param value

      if (this.state.isApproverEditView && HasProperty(params, 'type') && params.type === 'status' && params.e === "Rejected") {
        let tBOI = dim[params.dimIndex].subDimensionList[params.subDIndex].boiList[params.boiIndex];
        tBOI.paramValue = tBOI.oldParamValue;
      }

      this.setState({
        displayErrorMessage: tDisplayErrorMessage,
        dimensionList: dim,
        submitFormContents: {
          ...this.state.submitFormContents,
          submitType: approved ? 'submit' : 'reject'
        },
        // isSubmitDisabled: isSubmitDisabled
      });
    };

    onClickSAD = async () => {
      //PREPARE DATA
      try {
        this.setState({
          disableSADBtn: true
        }, async () => {
          const prog = this.props.programDetails;
          let oal = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead;
          const dL = this.state.dimensionList;
          let data = [];
          dL.forEach((d, i) => {
            d.subDimensionList.forEach((sD, i2) => {
              sD.boiList.forEach((bL, i3) => {
                data.push({
                  ClientProgramTargetID: bL.boiClientProgramTargetID,
                  BOIName: bL.boiName,
                  ClientProgramID: prog.ClientProgramID,
                  CoreDimensionID: d.dimensionID,
                  SubDimensionID: sD.subDimensionID,
                  BOIID: bL.boiID,
                  BOITarget: bL.boiStatus.toLowerCase().trim() === 'rejected' ? bL.oldParamValue : bL.paramValue,
                  UOM: bL.uom,
                  BOIStatus: bL.boiStatus,
                  BOIReason: bL.boiStatus.toLowerCase().trim() === 'approved' ? '' : bL.comment.trim(),
                  Approver: oal,
                  RejectionRecipient: prog.ProgramCreator,
                  IsActive: 1,
                  boiParentID: bL.boiParentID,
                  IsCustom: bL.IsCustom,
                  isCustomSubDimension: false,
                  boiDescription: bL.description,
                  offeringID: bL.offeringID,
                  subDimensionName: '',
                  boiDefinitionMasterID: bL.boiDefinitionMasterID === '' ? 0 : bL.boiDefinitionMasterID,
                  aggregationLogicMasterID:
                    bL.aggregationLogicMasterID === '' ? 0 : bL.aggregationLogicMasterID,
                  leadingIndicator: bL.leadingIndicator,
                  boiAdditionComment: bL.boiAdditionComment,
                  boiOldComment: bL.boiOldComment,
                  reasonForNoBOIDefinition: bL.reasonForNoBOIDefinition,
                  reasonForNoAggregationLogic: bL.reasonForNoAggregationLogic,
                  noOfParameter: bL.noOfParameter === '' ? 0 : bL.noOfParameter,
                  loggedInUserName: '',
                  metricSQL: '',
                  projectAggregationSQL: '',
                  programAggregationSQL: '',
                  accountAggregationSQL: '',
                  isDeletedCustomBOI: bL.isDeleted,
                  customParameterList: bL.customParameterList,
                  subOfferingID: bL.subofferingID,
                  wheelVisibility: bL.boiWheelVisibility ? 1 : 0
                });
              });
            });
          });

          let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
          const result = await HttpPostText(
            `NewProgramIdentifiedValueSetting/SaveAsDraftReviewedProgramIdentifiedValues?programID=${prog.ClientProgramID}&SubMenuID=${subMenuId}`,
            data
          );
          if (TryGetValue(result) === 'success') {
            this.setState({
              disableSADBtn: false,
            });
            this.props.setEmailError({
              show: false,
              message: ""
            })
            this.props.setToasterParam({
              show: true,
              type: 'success',
              message: 'Your Progress was saved as draft successfully!'
            });
            this.props.clearAllProgramProjectFields();
            this.props.getProgramList(this.props.clientID);
            this.props.history.push('/ProgramProjectConfig');
          } else {
            let errorMessage = JSON.parse(result);
            this.props.setEmailError({
              show: true,
              message: errorMessage.Message
            })
          }
          this.setState({
            disableSADBtn: false
          })
        })
      }
      catch (e) {
        console.log(e)
      }
    };

    onClickReject = async (form) => {
      try {
        this.setState({
          disableRejectBtn: true
        }, async () => {
          //PREPARE DATA
          let progDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
          let progCreator = this.props.programDetails.ProgramCreator;
          let emailAddress = this.props.userInformation.emailAddress;
          const prog = this.props.programDetails;
          let data = {
            ProgramID: prog.ClientProgramID,
            Comments: form.comment.trim(),
            MailTo: progCreator,
            ProgramCreator: progCreator,
            OperationsAccountLead: progDetails.OperationsAccountLead,
            Username: '',
            ClientProgramTargetList: [],
            IsEdited: progDetails.IsEdited ? 1 : 0,
            UserEmailID: emailAddress
          };
          const dL = this.state.dimensionList;
          dL.forEach((d, i) => {
            d.subDimensionList.forEach((sD, i2) => {
              sD.boiList.forEach((bL, i3) => {
                data.ClientProgramTargetList.push({
                  ClientProgramTargetID: bL.boiClientProgramTargetID,
                  BOIName: bL.boiName,
                  ClientProgramID: prog.ClientProgramID,
                  CoreDimensionID: d.dimensionID,
                  SubDimensionID: sD.subDimensionID,
                  BOIID: bL.boiID,
                  BOITarget: bL.boiStatus.toLowerCase().trim() === 'rejected' ? bL.oldParamValue : bL.paramValue,
                  UOM: bL.uom,
                  BOIStatus: bL.boiStatus,
                  BOIReason: bL.boiStatus.toLowerCase().trim() === 'approved' ? '' : bL.comment.trim(),
                  Approver: progDetails.OperationsAccountLead,
                  RejectionRecipient: prog.ProgramCreator,
                  IsActive: 1,
                  IsCustom: bL.IsCustom,
                  isCustomSubDimension: false,
                  boiDescription: bL.description,
                  offeringID: bL.offeringID,
                  subDimensionName: '',
                  boiDefinitionMasterID: bL.boiDefinitionMasterID === '' ? 0 : bL.boiDefinitionMasterID,
                  aggregationLogicMasterID:
                    bL.aggregationLogicMasterID === '' ? 0 : bL.aggregationLogicMasterID,
                  leadingIndicator: bL.leadingIndicator,
                  boiAdditionComment: bL.boiAdditionComment,
                  boiOldComment: bL.boiOldComment,
                  reasonForNoBOIDefinition: bL.reasonForNoBOIDefinition,
                  reasonForNoAggregationLogic: bL.reasonForNoAggregationLogic,
                  noOfParameter: bL.noOfParameter === '' ? 0 : bL.noOfParameter,
                  loggedInUserName: '',
                  metricSQL: '',
                  projectAggregationSQL: '',
                  programAggregationSQL: '',
                  accountAggregationSQL: '',
                  isDeletedCustomBOI: bL.isDeleted,
                  customParameterList: bL.customParameterList,
                  subOfferingID: bL.subofferingID,
                  wheelVisibility: bL.boiWheelVisibility ? 1 : 0,
                  metricDefinitionID: bL.metricDefinitionID
                });
              });
            });
          });
          let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
          const result = await HttpPostText(
            `NewProgramIdentifiedValueSetting/SubmitReviewedProgramIdentifiedValues?programID=${prog.ClientProgramID}&SubMenuID=${subMenuId}`,
            data
          );
          if (TryGetValue(result) === 'success') {
            await this.props.getProgramList(this.props.clientID);
            await this.props.setToasterParam({
              show: true,
              type: 'success',
              message: 'Notification is sent successfully!'
            });
            this.props.setEmailError({
              show: false,
              message: ""
            })
            await this.props.clearAllProgramProjectFields();
            this.setState({
              disableRejectBtn: false
            })
            this.props.history.push('/ProgramProjectConfig');
          } else {
            let errorMessage = JSON.parse(result);
            this.props.setEmailError({
              show: true,
              message: errorMessage.Message
            })
          }
          this.setState({
            disableRejectBtn: false
          })
        })
      }
      catch (e) {
        console.log(e)
      }
    };

    validateDimension = () => {
      let dim = this.state.dimensionList;
      let isValid = true;
      let tDisplayErrorMessage = false;
      const newDim = dim.map(e => {
        return {
          ...e,
          subDimensionList: e.subDimensionList.map(e2 => {
            return {
              ...e2,
              boiList: e2.boiList.map(e3 => {
                if (
                  ['rejected', 'conditional approval'].indexOf(e3.boiStatus.toLowerCase().trim()) >
                  -1
                ) {
                  if (!IsValidStr(e3.comment.trim())) {
                    e3['inValidComment'] = true;
                    isValid = false; 
                  }
                  else if (IsValidStr(e3.comment.trim()) && !IsValidText(e3.comment.trim())) {
                    e3['inValidComment'] = 'invalid text';
                    isValid = false;  
                  }
                }
                // if (!IsValidStr(e3.paramValue) && !tDisplayErrorMessage) tDisplayErrorMessage = true
                return { ...e3 };
              })
            };
          })
        };
      });
      this.setState({ dimensionList: newDim, displayErrorMessage: tDisplayErrorMessage });
      return isValid;
    };

    onClickConfirmBoi = e => {
      if (!this.validateDimension()) return;
      this.setState({ showConfirmApprovalModal: e }
        , () => {
          if (!this.state.showConfirmApprovalModal) {
            this.props.setEmailError({
              show: false,
              message: '',
            }
            )
          }
        });

    };

    onClickSubmit = async () => {
      //VALIDATE CONDITIONAL APPROVAL
      if (!this.validateDimension()) return;
      //PREPARE DATA
      let oal = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead;
      let stakeHolders = this.props.programStakeholders.join(';');
      const prog = this.props.programDetails;

      let actionOwnerList = this.props.ProgramConfigurationData.selectedProgramDetails.ActionOwner.split(",");
      let sysActionOwner = "";

      if (!IsArrayEmpty(actionOwnerList.filter((a) => oal.split(",").find((b) => a === b)))) {
        sysActionOwner = oal.split(",")[0];
      } else {
        sysActionOwner = actionOwnerList[0];
      }


      let emailAddress = this.props.userInformation.isSuperAdmin ?
        sysActionOwner
        : this.props.userInformation.emailAddress;

      let data = {
        ProgramID: prog.ClientProgramID,
        Comments: '',
        MailTo: stakeHolders,
        ProgramCreator: this.props.programDetails.ProgramCreator,
        OperationsAccountLead: oal,
        Username: '',
        ClientProgramTargetList: [],
        IsEdited: prog.IsEdited ? 1 : 0,
        UserEmailID: emailAddress
      };

      const d = new Date();
      const month = d.getMonth();
      let year = d.getFullYear();
      if (month > 7) year++;
      const targetEndDate = `${year}-08-31`;
      let agnosticBOIs = [];

      const dL = this.state.dimensionList;
      dL.forEach((d, i) => {
        d.subDimensionList.forEach((sD, i2) => {
          sD.boiList.forEach((bL, i3) => {
            data.ClientProgramTargetList.push({
              ClientProgramTargetID: bL.boiClientProgramTargetID,
              BOIName: bL.boiName,
              ClientProgramID: prog.ClientProgramID,
              CoreDimensionID: d.dimensionID,
              SubDimensionID: sD.subDimensionID,
              BOIID: bL.boiID,
              BOITarget: bL.boiStatus.toLowerCase().trim() === 'rejected' ? bL.oldParamValue : bL.paramValue,
              BOITargetByCreator: bL.oldParamValue,
              UOM: bL.uom,
              BOIStatus: bL.boiStatus === "" ? 'Approved' : bL.boiStatus,
              BOIReason: bL.boiStatus.toLowerCase().trim() === 'approved' ? '' : bL.comment.trim(),
              Approver: oal,
              RejectionRecipient: prog.ProgramCreator,
              IsActive: 1,
              IsCustom: bL.IsCustom,
              isCustomSubDimension: false,
              boiDescription: bL.description,
              offeringID: bL.offeringID,
              subDimensionName: '',
              boiDefinitionMasterID: bL.boiDefinitionMasterID === '' ? 0 : bL.boiDefinitionMasterID,
              aggregationLogicMasterID:
                bL.aggregationLogicMasterID === '' ? 0 : bL.aggregationLogicMasterID,
              leadingIndicator: bL.leadingIndicator,
              boiAdditionComment: bL.boiAdditionComment,
              boiOldComment: bL.boiOldComment,
              reasonForNoBOIDefinition: bL.reasonForNoBOIDefinition,
              reasonForNoAggregationLogic: bL.reasonForNoAggregationLogic,
              noOfParameter: bL.noOfParameter === '' ? 0 : bL.noOfParameter,
              loggedInUserName: '',
              metricSQL: '',
              projectAggregationSQL: '',
              programAggregationSQL: '',
              accountAggregationSQL: '',
              isDeletedCustomBOI: bL.isDeleted,
              customParameterList: bL.customParameterList,
              subOfferingID: bL.subofferingID,
              wheelVisibility: bL.boiWheelVisibility ? 1 : 0,
              metricDefinitionID: bL.metricDefinitionID
            });

            if (bL.boiParentID === 3 && bL.boiStatus !== 'Rejected') {
              agnosticBOIs.push({
                offeringID: bL.offeringID,
                targetEndDate: targetEndDate,
                boiid: bL.boiID,
                uom: bL.uom,
                boiTarget: bL.paramValue,
                coreDimensionID: d.dimensionID,
                subDimensionID: sD.subDimensionID,
                boiTargetApprovedValue: bL.paramValue,
                BOIStatus: bL.boiStatus === "" ? 'Approved' : bL.boiStatus,
                BOIName: bL.boiName,
                WheelVisibility: bL.boiWheelVisibility ? 1 : 0
              });
            }
          });
        });
      });

      this.setState({
        disableSendBtn: true
      }, async () => {
        let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
        const result = await HttpPostText(
          `NewProgramIdentifiedValueSetting/SubmitReviewedProgramIdentifiedValues?programID=${prog.ClientProgramID}&SubMenuID=${subMenuId}`,
          data
        );

        // generate project
        const generateProjPayload = {
          clientProgramID: prog.ClientProgramID,
          stakeHolders: stakeHolders,
          boIs: agnosticBOIs
        };

        const generateresult = await HttpPostText(
          `NewProgramIdentifiedValueSetting/GenerateSystemGeneratedProjects?SubMenuID=${subMenuId}`,
          generateProjPayload
        );

        if (TryGetValue(result) === 'success' && TryGetValue(generateresult) === 'success') {
          this.props.setToasterParam({
            show: true,
            type: 'success',
            message: 'Notification is sent successfully!'
          });
          this.props.setEmailError({
            show: false,
            message: ""
          })
          this.props.setHistoryPath({
            url: window.location.pathname,
            state: { programStatus: 'approved' }
          });
          this.props.history.push(`/ProgramProjectConfig/${this.props.clientName}/${prog.ClientProgramID}`);
          //DataEvent("programApprove", this.props.SharedData.selectedClient.text)
          LogPPCUserAction({
            clientID: prog.ClientMasterID,
            ClientProgramID: prog.ClientProgramID,
            progStatus: prog.IsEdited ? PPC_EX_PROG_APP : PPC_NEW_PROG_APP,
            module: PROG_ID_VAL_ALA
          });
        } else {
          if (TryGetValue(result) !== 'success') {
            let errorMessage = JSON.parse(result);
            this.props.setEmailError({
              show: true,
              message: errorMessage.Message
            })
          } else {
            let errorMessage = JSON.parse(generateresult);
            this.props.setEmailError({
              show: true,
              message: errorMessage.Message
            })
          }
        }
        this.setState({
          disableSendBtn: false,
        }, () => {
          if (this.state.showConfirmApprovalModal) {
            this.setState({ showConfirmApprovalModal: false });
          }
        });
      }
      );
    };

    onSaveAddBOI = bois => {
      this.setState({ dimensionList: bois });
    };

    deletedBOIS = () => {
      const deleted = this.props.ProgramConfigurationData.programBOIHistory.deletedBOIHistories;

      if (deleted.length === 0) return 0;

      let deletedCount = 0;
      deleted.forEach(dimension => {
        dimension.SubDimensionList.forEach(subDimension => {
          subDimension.boiDataList.forEach(boi => {
            if (boi.IsRecentlyDeleted) {
              deletedCount += subDimension.boiDataList.length;
            }
          });
        });
      });
      return deletedCount;
    };

    modifiedBOIS = () => {
      const modified = this.props.ProgramConfigurationData.programBOIHistory.modifiedBOIHistories;
      const dimList = this.props.ProgramConfigurationData.dimensionList;
      const rejected = this.props.ProgramConfigurationData.programBOIHistory.rejectedBOIHistories;

      if (modified.length === 0) return 0;

      let modifiedCount = 0;
      let modifiedBOI = [];
      modified.forEach(dimension => {
        const dList = dimList.filter(df => df.dimensionName === dimension.Dimension);
        const rList = rejected.filter(r => r.Dimension === dimension.Dimension);
        if (dList.length > 0) {
          dList.map(d => {
            let rDList = rList.length > 0 ? rList[0].SubDimensionList : [];
            dimension.SubDimensionList.forEach(subDimension => {
              const sdList = d.subDimensionList.filter(
                sdf => sdf.subDimensionName === subDimension.SubDimension
              );
              let rSDList = rDList.filter(rd => subDimension.SubDimension === rd.SubDimension);
              if (sdList.length > 0) {
                sdList.map(sd => {
                  let rBOIList = rSDList.length > 0 ? rSDList[0].boiDataList : [];
                  subDimension.boiDataList.forEach(x => {
                    let boi = [];
                    boi = sd.boiList.filter(
                      bf =>
                        bf.boiID === x.BOIID &&
                        bf.boiStatus.toLowerCase() === 'approved' &&
                        (x.OldValue !== null) & x.IsRecentlyModified &&
                        x.BOIStatus.toLowerCase() === 'approved'
                    );
                    if (
                      rBOIList.filter(
                        rb =>
                          rb.BOIID === x.BOIID &&
                          (x.BOIStatus === null ||
                            x.BOIStatus === '' ||
                            x.BOIStatus.toLowerCase() === 'rejected')
                      ).length > 0
                    ) {
                      boi = [];
                    }
                    boi.map(m => {
                      if (m.length != 0 && m.boiID != '') {
                        modifiedBOI.push(m.boiID);
                      }
                    });
                  });
                });
              }
            });
          });
        }
      });
      modifiedCount = new Set(modifiedBOI).size;
      return modifiedCount;
    };

    onCloseDeleted = () => {
      this.setState({
        displayDeleted: false
      });
    };

    onCloseModified = () => {
      this.setState({
        displayModified: false
      });
    };

    formatBOIs = () => {
      let tempBois = this.props.dimensionList;
      //sort boi
      tempBois.map(dim => {
        dim.subDimensionList.map((subdim, index) => {
          let tempFilterList = [];
          let tempCustomList = [];
          let sortedBoiList = [];
          let customBoiList = [];
          let filteredBoiList = [];
          // subdim.boiList.sort(function(a,b){
          //   return a.boiParentID - b.boiParentID
          // })

          //stores custom boi list
          customBoiList = subdim.boiList.filter(e => e.boiParentID === 2);
          customBoiList.forEach(e => {
            tempCustomList.push(e);
          });

          //removes custom from boilist and sorts boi parent id
          filteredBoiList = subdim.boiList.filter(e => e.boiParentID !== 2);
          filteredBoiList.sort((a, b) => a.boiParentID - b.boiParentID);
          filteredBoiList.forEach(e => {
            tempFilterList.push(e);
          });

          sortedBoiList.push(...tempFilterList, ...tempCustomList);

          subdim.boiList = sortedBoiList;
        });
      });
      this.setState({
        dimensionList: tempBois
      });
    };

    render() {
      const { PnPComponents } = this.props.AccessValidationData;
      const { programDetails, userInformation } = this.props;
      const { programClientDetails, newProgramDetails, ConfiguratorsList } = this.props.ProgramConfigurationData;
      return (
        <ProgramALAppReviewPresentational
          displayErrorMessage={this.state.displayErrorMessage}
          submitFormContents={this.state.submitFormContents}
          requestorFormContents={this.state.requestorFormContents}
          dimensionList={this.state.dimensionList}
          validateDimension={this.validateDimension}
          dimFormCB={this.dimFormCB}
          onClickSubmit={this.onClickSubmit}
          onClickSAD={this.onClickSAD}
          onClickReject={this.onClickReject}
          onSaveAddBOI={this.onSaveAddBOI}
          PnPComponents={PnPComponents}
          programDetails={programDetails}
          SharedData={this.props.SharedData}
          ProgramConfigurationData={this.props.ProgramConfigurationData}
          deletedBOIS={this.deletedBOIS()}
          displayDeleted={this.state.displayDeleted}
          onCloseDeleted={this.onCloseDeleted}
          modifiedBOIS={this.modifiedBOIS()}
          displayModified={this.state.displayModified}
          onCloseModified={this.onCloseModified}
          showConfirmApprovalModal={this.state.showConfirmApprovalModal}
          onClickConfirmBoi={this.onClickConfirmBoi}
          isSuperAdmin={this.props.isSuperAdmin}
          isSubmitDisabled={this.state.isSubmitDisabled}
          isApproverEditView={this.state.isApproverEditView}
          programClientDetails={programClientDetails}
          userInformation={userInformation}
          disableSendBtn={this.state.disableSendBtn}
          disableSADBtn={this.state.disableSADBtn}
          disableRejectBtn={this.state.disableRejectBtn}
          positionFixed={this.state.positionFixed}
          newProgramDetails={newProgramDetails}
          ConfiguratorsList={ConfiguratorsList}
          listenToScroll={this.listenToScroll}
          showError={this.props.showError}
          invalidEmailsList={this.state.invalidEmailsList}
        />
      );
    }
  }

  return ProgramALAppReviewContainer;
})();

export default connect(
  state => {
    return {
      dimensionList: state.ProgramConfigurationData.dimensionList,
      programDetails: state.ProgramConfigurationData.selectedProgramDetails,
      OAL: state.ProgramConfigurationData.programClientDetails.OperationAccountLead,
      programStakeholders: state.ProgramConfigurationData.programStakeholders,
      clientID: state.SharedData.selectedClient.value,
      clientName: state.SharedData.selectedClient.text,
      arsDetails: state.ProgramConfigurationData.arsDetails,
      userEmail: state.userInformation.emailAddress,
      AccessValidationData: state.AccessValidationData,
      SharedData: state.SharedData,
      ProgramConfigurationData: state.ProgramConfigurationData,
      isSuperAdmin: state.userInformation.isSuperAdmin,
      userInformation: state.userInformation,
      showError: state.SharedData.showError
    };
  },
  dispatch =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setToasterParam,
          getProgramBOI,
          getProgramList,
          clearAllProgramProjectFields,
          getProgramBOIHistory,
          getCustomBOIDetails,
          setNewProgramDetails,
          setHistoryPath,
          setEmailError
        }
      ),
      dispatch
    )
)(withRouter(ProgramALAppReview));
