import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { GrooveIconSvg } from "tfo-groove";
import store from "../../store";


const CustomBOIModal = (function () {
  class CustomBOIModalPresentational extends Component {
    render() {
      const { subDimensionName, dimensionName, boi } = this.props;

      return (
        <Modal
          show={this.props.isShow}
          onHide={this.props.onCustomBOIModalCancelClose}
          className="modal-custom-BOI-container"
        >
          <Modal.Header>
            <Modal.Title>Custom BOI - {boi.boiName}</Modal.Title>
            <GrooveIconSvg
              customClassName="close-modal-btn"
              size="large"
              name="times"
              iconStyle="solid"
              iconColor="rgb(145, 158, 171)"
              callbackFunction={this.props.onCustomBOIModalCancelClose}
            />
          </Modal.Header>
          <Modal.Body>


            <Row className="custom-boi-container">
              <Col md={6}>
                <h6>Client Name</h6>
                <p className={boi.ClientName === "" ? "custom-boi-empty" : ""}>{boi.ClientName}</p>

                <h6>Offering</h6>
                <p className={boi.offeringName === "" ? "custom-boi-empty" : ""}>{boi.offeringName}</p>

                <h6>Sub-Offering</h6>
                <p className={boi.SubOffering === "" ? "custom-boi-empty" : ""}>{boi.SubOffering}</p>

                <h6>BOI Name</h6>
                <p className={boi.boiName === "" ? "custom-boi-empty" : ""}>{boi.boiName}</p>

                <h6>BOI Unit of Measure</h6>
                <p className={boi.uom === "" ? "custom-boi-empty" : ""}>{boi.uom}</p>

                <h6>BOI Description</h6>
                <p className={boi.description === "" ? "custom-boi-empty" : ""}>{boi.description}</p>

                <h6>Leading Practice</h6>
                <p className={boi.LeadingIndicator === "" ? "custom-boi-empty" : ""}>{boi.LeadingIndicator}</p>

                <h6>Comments for BOI Addition</h6>
                <p className={boi.comment === "" ? "custom-boi-empty" : ""}>
                  {boi.comment}
                </p>

              </Col>
              <Col md={6}>
                <h6>Dimension</h6>
                <p className={dimensionName === "" ? "custom-boi-empty" : ""}>{dimensionName}</p>

                <h6>Subdimension</h6>
                <p className={subDimensionName === "" ? "custom-boi-empty" : ""}>{subDimensionName === "Add new Subdimension" ? boi.newSubDimensionName : subDimensionName}</p>

                {/* <h6>New Subdimension Name</h6>
              <p className={subDimensionName === "" ? "custom-boi-empty" : ""}>{subDimensionName}</p> */}

                <h6>BOI definition/Metric Calculation</h6>
                <p className={boi.MetricCalculation === "" ? "custom-boi-empty" : ""}>{boi.MetricCalculation}</p>
                <table class="col-md-12">
                  <tr class="col-md-12">
                    <th class="col-md-6 custom-boi-param"><h6>Parameter Name</h6></th>
                    <th class="col-md-6 custom-boi-param"><h6>Unit of Measure</h6></th>
                  </tr>
                  {(boi.hasOwnProperty("customParameterList") ? boi.customParameterList : []).map((params) => {
                    return (
                      <tr class="col-md-12">
                        <td class="col-md-6 custom-boi-param">
                          <p>{params.ParameterCustomCode}</p>
                        </td>
                        <td class="col-md-6 custom-boi-param">
                          <p>{params.ParameterUOM}</p>
                        </td>
                      </tr>
                    )
                  })}
                </table>

                <h6>Aggregation Logic</h6>
                <p className={boi.AggregationLogic === "" ? "custom-boi-empty" : ""}>
                  {boi.AggregationLogic}
                </p>

              </Col>
            </Row>


          </Modal.Body>
        </Modal>
      );
    }
  }

  class CustomBOIModalContainer extends Component {
    render() {
      return (
        <CustomBOIModalPresentational
          {...this.props}
        />
      );
    }
  }

  return CustomBOIModalContainer;
})();

export default CustomBOIModal;
