import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  GrooveIconSvg,
  GrooveButton,
  GrooveSelectMenuBasic,
  GrooveTabComponent,
  GrooveCheckbox,
  GrooveInput,
} from 'tfo-groove';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import newInfo from '../../../Static/images/svg/new-info.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import TabbedDropdown from '../../Shared/CVMTabbedDropdown/TabbedDropdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
const AddAgnosticBOI = (function () {
  class AddAgnosticBOIPresentational extends Component {
    render() {
      const showNotif = this.props.showNotifMessage;
      const failedValidation =
        this.props.isExisting ||
        Object.keys(this.props.boiToAddDetails.BOIName).length === 0 ||
        Object.keys(this.props.boiToAddDetails.UOM).length === 0 ||
        Object.keys(this.props.boiToAddDetails.CoreDimensionName).length === 0 ||
        Object.keys(this.props.boiToAddDetails.SubDimensionName).length === 0 ||
        this.props.duplicateBOI;
        const tooltipDisabledFields = (
          <Popover id="deleteboi-popover" trigger="focus" placement="left">
            <Popover.Content>
              Delivered value is already reported for this BOI. 
              Hence the same cannot be de-selected from this program.
            </Popover.Content>
          </Popover>
        );
      return (
        <React.Fragment>
          <div className="cvm-tab-content add-boi-modal">
            <div className="agnostic-disclaimer-container">
              <div className="exclamation-icon-container">
                <GrooveIconSvg
                  size="normal"
                  name="exclamation"
                  iconStyle="solid"
                  className="exclamation-icon"
                />
              </div>
              <div>
                Talent, Inclusion & Diversity and Global BOIs would be tracked at program level and
                would not be available for selection for user-driven project config. Once program is
                approved, system will generate auto-approved projects for selected BOIs with same
                target value as defined at program level.
              </div>
            </div>
            <div
              className={
                this.props.isExisting
                  ? 'add-boi-field-container duplicate-boi'
                  : 'add-boi-field-container'
              }
            >
              <div className="select-boi-dropdown">
                <TabbedDropdown
                  label="BOI"
                  isRequired={true}
                  isDisabled={false}
                  value={this.props.tabValue}
                  list={this.props.agnosticDropdownDisplay}
                  callbackFunction={(e) => this.props.selectAgnostic(e)}
                  onSearchAgnosticDropdownValue={this.props.onSearchAgnosticDropdownValue}
                  resetAgnosticDropdownDisplay={this.props.resetAgnosticDropdownDisplay}
                  selectAgnosticTab={this.props.selectAgnosticTab}
                />
              </div>
              <div className="select-boi-dropdown">
                <GrooveInput
                  name="unitofMeasure"
                  isDisabled
                  type="text"
                  placeholder="Unit of Measure*"
                  inputValue={this.props.boiToAddDetails.UOM}
                  id="uom"
                />
                <span className="boiAgnosticUOMModal tooltiptext">
                  For Offering Agnostic BOIs, user cannot change the mapped UOM.
                </span>
              </div>
              <div className="select-boi-dropdown">
                <GrooveInput
                  name="dimension"
                  isDisabled
                  type="text"
                  placeholder="Dimension*"
                  inputValue={this.props.boiToAddDetails.CoreDimensionName}
                  id="dimension"
                />
                <span className="boiAgnosticDimensionModal tooltiptext">
                  For Offering Agnostic BOIs, user cannot change the mapped Dimension.
                </span>
              </div>
              <div className="select-boi-dropdown">
                <GrooveInput
                  name="subdimension"
                  isDisabled
                  type="text"
                  placeholder="Sub Dimension*"
                  inputValue={this.props.boiToAddDetails.SubDimensionName}
                  id="subdimension"
                />
                <span className="boiAgnosticSubdimensionModal tooltiptext">
                  For Offering Agnostic BOIs, user cannot change the mapped Subdimension.
                </span>
              </div>
            </div>
            {showNotif ? (< div className="disclaimer-message">
              <GrooveIconSvg
                customClassName="accordion-button"
                size="small"
                name="exclamation-triangle"
                iconStyle="solid"
                iconColor="stat-warning"
              />
            BOIs with $ UOM selected under Financial Dimension will be Considered as client P&L Sign-Off. $ value under all other Dimensions except Financial Dimension, will not be considered as Client P&L Sign-Off.
            </div>) : ''}
            <GrooveButton
              id="primry-btn-3"
              hasIcon={true}
              type="filter-outline"
              colorClass="stat-alternate"
              text="Add"
              iconAlignment="right"
              iconSize="small"
              iconName="plus"
              iconStyle="solid"
              iconId="btn-icon-01"
              customClassName="add-boi-button"
              callbackFunction={() => this.props.addAgnosticBOI()}
              isDisabled={failedValidation}
            />
            {
              //this.props.selectedBOI.length > 0
              this.props.selectedAgnosticBOIList.length > 0 ? (
                <React.Fragment>
                  <div className="divider-horizontal" />
                  <div className="search-bar-container add-boi-search">
                    <div className="search-bar label14-regular-midnight">
                      <GrooveIconSvg
                        customClassName="close-modal-btn"
                        size="large"
                        name="search"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                      />
                      <input
                        type="text"
                        name="name"
                        placeholder="Search..."
                        autoComplete="off"
                        id="searchBox"
                        maxLength="255"
                        onChange={(e) => {
                          this.props.setKeyword(e.target.value.toLowerCase(), true);
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ''
              )
            }
            <div className="added-boi-container">
              <div className="deleted-bois-container">
                {this.props.deletedAgnosticBOIForUndo.map((item) => {
                  return (
                    <div className="deleted-bois">
                      <GrooveIconSvg
                        customClassName="close-modal-btn"
                        size="large"
                        name="exclamation-triangle"
                        iconStyle="solid"
                        iconColor="stat-alternate"
                      />
                      <div className="deleted-bois-text">
                        The Business Outcome Indicator
                        <div>
                          <div className="deleted-boi-details">
                            <div className="deleted-boi-details-label">
                              BOI: <span>{item.boi}</span>
                            </div>
                            <div className="deleted-boi-details-label">
                              Unit of Measure: <span>{item.uom}</span>
                            </div>
                            <div className="deleted-boi-details-label">
                              Dimension: <span>{item.dimension}</span>
                            </div>
                            <div className="deleted-boi-details-label">
                              Subdimension: <span>{item.subdimension}</span>
                            </div>
                          </div>
                          '{item.boi}'
                        </div>
                        has been deleted. Click on the undo button to restore the deleted BOI.
                      </div>
                      <GrooveButton
                        id="primry-btn-3"
                        type="solid"
                        colorClass="stat-alternate"
                        text="Undo Delete"
                        customClassName="undo-deleted-boi-btn"
                        callbackFunction={() => this.props.restoreDeletedAgnosticBOI(item)}
                      />
                      <GrooveIconSvg
                        customClassName="close-modal-btn"
                        size="large"
                        name="times"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                        callbackFunction={() => this.props.removeDeletedAgnosticBOI(item)}
                      />
                    </div>
                  );
                })}
              </div>
              {this.props.selectedAgnosticBOIDisplay.map((item, index) => {
                let that = this;
                let isEditDisabled = item.boiStatus === 'Rejected' ? false : true;
                let isItemDisabled =
                  item.isExisting || item.missingDetails
                    ? false
                    : this.props.duplicateBOI || this.props.inComplete;
                let type = that.props.type === 'Edit Rejected' ? true : false;
                let boiValue = {
                  value: item.boiID,
                  label: item.boi,
                };
                if (!item.IsCustom)
                  return (
                    <div
                      className={
                        item.isExisting ||
                          item.missingDetails ||
                          (item.boiOutsideOffering && this.props.clickedUncheck)
                          ? 'add-boi-field-container duplicate-boi'
                          : 'add-boi-field-container'
                      }
                    >
                      <div className="new-info-wrap">
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip id="boi-tooltip-right">
                              <div className="boi-details-wrap row">
                                <div class="col-md-6">
                                  <h6>Offering</h6>
                                  <p class="">{item.offeringName}</p>
                                  <h6>Dimension</h6>
                                  <p class="">{item.dimension}</p>
                                  <h6>BOI Description</h6>
                                  <p class="">{item.boiDescription}</p>
                                </div>
                                <div class="col-md-6">
                                  <h6>Sub-Offering</h6>
                                  <p class="">{item.suboffering.label}</p>
                                  <h6>Sub-dimension</h6>
                                  <p class="">{item.subdimension}</p>
                                  <h6>Leading Practice</h6>
                                  <p class="">{item.leadingIndicator}</p>
                                  <table>
                                    <tr class="col-md-12">
                                      <th class="col-md-6">
                                        <h6>Parameter</h6>
                                      </th>
                                      <th class="col-md-6">
                                        <h6>Unit of Measure</h6>
                                      </th>
                                    </tr>
                                    {(item.hasOwnProperty('parameterList')
                                      ? item.parameterList
                                      : []
                                    ).map((params) => {
                                      return (
                                        <tr>
                                          <td class="col-md-6">
                                            <p>{params.ParameterName}</p>
                                          </td>
                                          <td class="col-md-6">
                                            <p>{params.ParameterUOM}</p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </table>
                                </div>
                              </div>
                            </Tooltip>
                          }
                        >
                          <div className="rdv-new-info">
                            <img className="new-info-icon" src={newInfo} alt="info" />
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="addboi-dropdown-containers">
                        <div className="addedBOI-dropdown">
                          {item.isBOIUsedInProject && this.props.tooltipDisabledFields}
                          <TabbedDropdown
                            label="BOI"
                            isRequired={true}
                            isDisabled={item.isBOIUsedInProject ? true : false}
                            value={boiValue}
                            list={this.props.agnosticDropdownDisplay}
                            onSearchAgnosticDropdownValue={this.props.onSearchAgnosticDropdownValue}
                            resetAgnosticDropdownDisplay={this.props.resetAgnosticDropdownDisplay}
                            callbackFunction={(e) => this.props.editAgnosticBOI(e, item, index)}
                            selectAgnosticTab={this.props.selectAgnosticTab}
                          />
                        </div>
                        <div className="addedBOI-dropdown">
                          <GrooveInput
                            name="unitofMeasure"
                            isDisabled
                            placeholder={'Unit of Measure*'}
                            inputValue={item.uom}
                            id="uom"
                          />
                          <span className="boiAgnosticUOMModal tooltiptext">
                            For Offering Agnostic BOIs, user cannot change the mapped UOM.
                          </span>
                        </div>
                        <div className="addedBOI-dropdown">
                          <GrooveInput
                            name="dimension"
                            isDisabled
                            placeholder={'Dimension*'}
                            inputValue={item.dimension}
                            id="dimension"
                          />
                          <span className="boiAgnosticDimensionModal tooltiptext">
                            For Offering Agnostic BOIs, user cannot change the mapped Dimension.
                          </span>
                        </div>
                        <div className="addedBOI-dropdown">
                          <GrooveInput
                            name="subdimension"
                            isDisabled
                            placeholder={'Sub Dimension*'}
                            inputValue={item.subdimension}
                            id="subdimension"
                          />
                          <span className="boiAgnosticSubdimensionModal tooltiptext">
                            For Offering Agnostic BOIs, user cannot change the mapped Subdimension.
                          </span>
                        </div>{' '}
                      </div>
                      {/*(item.isExisting || item.missingDetails ? "delete-boi-button" : this.props.duplicateBOI || this.props.inComplete ? "delete-boi-button disabled-delete" : "delete-boi-button") */}
                      <div className="delete-boi">
                        {item.isBOIUsedInProject && tooltipDisabledFields}
                        <div
                          className={
                            isItemDisabled || item.isBOIUsedInProject
                              ? 'delete-boi edit-rejected-boi'
                              : 'delete-boi'
                          }
                        >
                          <GrooveIconSvg
                            customClassName={
                              type
                                ? item.isBOIUsedInProject
                                  ? 'delete-boi-button disabled-delete'
                                  : 'delete-boi-button'
                                : isItemDisabled
                                  ? 'delete-boi-button disabled-delete'
                                  : 'delete-boi-button'
                            }
                            size="large"
                            name="trash-alt"
                            iconStyle="solid"
                            iconColor="stat-alternate"
                            callbackFunction={
                              item.isBOIUsedInProject
                                ? ''
                                : item.isExisting || item.missingDetails
                                  ? () => this.props.deleteAgnosticBOI(item, index)
                                  : this.props.duplicateBOI || this.props.inComplete
                                    ? ''
                                    : () => this.props.deleteAgnosticBOI(item, index)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  class AddAgnosticBOIContainer extends Component {
    constructor() {
      super();
      this.state = {
        tabValue: {},
        show: false,
        isDisabled: false,
        isExisting: false,
        agnosticDropdownList: [],
        boiToAddDetails: {
          UOM: '',
          CoreDimensionName: '',
          SubDimensionName: '',
          BOIName: '',
        },
        selectedBOIList: [],
        agnosticType: [
          //agnostic filter config, update to filter boilist if agnostic type will be updated
          {
            label: 'Talent',
            value: 4,
          },
          {
            label: 'Inclusion & Diversity',
            value: 5,
          },
          {
            label: 'Global',
            value: 6,
          },
        ],
        duplicateBOI: false,
        selectedBOIDisplay: [],
        keyword: '',
        boiToAddDetails: {
          UOM: '',
          CoreDimensionName: '',
          SubDimensionName: '',
          BOIName: '',
        },
        showNotifMessage: false,
      };
    }

    componentDidMount = async () => {
      await this.props.filterAgnosticBOIList();
      await this.props.filterAgnosticDropdownList();
      await this.showDisclaimerMessage();
    };

    componentDidUpdate = (prevProps) => {
      if (prevProps.boiToAddDetails !== this.props.boiToAddDetails) {
        this.setState({
          boiToAddDetails: this.props.boiToAddDetails,
        });
      }
    };

    clearState = () => {
      this.setState({
        tabValue: {},
        isExisting: false,
        keyword: '',
        isClick: false,
      });
    };

    componentWillUnmount = () => {
      this.clearState();
    };

    checkForDuplicates = (toAdd, selectedBOIList) => {
      let doesExist = false;
      selectedBOIList.map((item) => {
        if (
          (item.boi === toAdd.boi || item.boi === toAdd.BOIName) &&
          (item.uom === toAdd.uom || item.uom === toAdd.UOM) &&
          (item.dimension === toAdd.dimension || item.dimension === toAdd.CoreDimensionName) &&
          (item.subdimension === toAdd.subdimension || item.subdimension === toAdd.SubDimensionName)
        ) {
          doesExist = true;
        }
      });
      return doesExist;
    };
    showDisclaimerMessage = async () => {
      await this.setState({
        showNotifMessage: true
      })
      setTimeout(() =>
        this.setState({
          showNotifMessage: false
        }), 60000);

    }
    render() {
      return (
        <AddAgnosticBOIPresentational
          {...this.props}
          show={this.props.show}
          isDisabled={this.state.isDisabled}
          shouldDisable={this.props.shouldDisable}
          boiToAddDetails={this.state.boiToAddDetails}
          showNotifMessage={this.state.showNotifMessage}
          showDisclaimerMessage={this.showDisclaimerMessage}
        />
      );
    }
  }
  return AddAgnosticBOIContainer;
})();

export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(withRouter(AddAgnosticBOI));
