import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ScrollableTabsButtonAuto from "./ScrollableTabsButtonAuto";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';


const DashboardProjectDetailsModal = (function () {
  class DashboardProjectDetailsModalPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          {this.props.showModal && this.props.boiProjectDetails.length > 0 && (
            <div className="dashboard-projectDetails-modal">
              <div className="projectDetails-modal-header">
                <h4>BOI </h4>
                <h5> {this.props.boiProjectDetails[0].boiName} ({this.props.boiProjectDetails[0].boiUOM})</h5>
                <FontAwesomeIcon icon={faTimes} onClick={this.props.onCloseModal} />
              </div>
              <div className="projectDetails-modal-body">
                <ScrollableTabsButtonAuto
                  boiItem={this.props.boiItem}
                  // handleBOIComment={this.props.handleBOIComment}
                  sdItem={this.props.sdItem}
                  dItem={this.props.dItem}
                  selectedYear={this.props.selectedYear}
                  createLog={this.props.createLog}
                />
              </div>
            </div>
          )
          }
        </React.Fragment>
      );
    }
  }

  class DashboardProjectDetailsModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const {
        boiProjectDetails,
      } = this.props.DashboardData

      return (
        <DashboardProjectDetailsModalPresentational
          onCloseModal={this.props.onCloseModal}
          showModal={this.props.showModal}
          boiItem={this.props.boiItem}
          boiProjectDetails={boiProjectDetails}
          handleBOIComment={this.props.handleBOIComment}
          sdItem={this.props.sdItem}
          dItem={this.props.dItem}
          selectedYear={this.props.selectedYear}
          createLog={this.props.createLog}
        />
      );
    }
  }

  return DashboardProjectDetailsModalContainer;
})();

export default connect(
  (state) => {
    return {
      DashboardData: state.DashboardData
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {},
      ),
      dispatch
    )
)(DashboardProjectDetailsModal);

