import { IsValid, IsValidStr, IsValidNum, HasProperty } from './Validations';
export function FormatFloat(data, decimals, roundOff) {
  let tAmount = String(data).replace(/\s/g, '').replace(/,/g, '');
  let realValue = 0;
  if (roundOff === true && IsValid(decimals)) {
    realValue = parseFloat(tAmount).toFixed(decimals);
  } else if (IsValid(decimals)) {
    realValue =
      tAmount.length !== parseFloat(tAmount)
        ? parseFloat(tAmount).toFixed(decimals)
        : parseFloat(tAmount);
  } else {
    const tDecimal = IsValid(decimals) ? decimals : 2;
    const decimal =
      tAmount.indexOf('.') > -1 ? tAmount.length - (tAmount.indexOf('.') + 1) : tDecimal;
    realValue =
      tAmount.length !== parseFloat(tAmount)
        ? parseFloat(tAmount).toFixed(decimal)
        : parseFloat(tAmount);
  }
  return realValue;
}

export function ParseFloat(value, decimals) {
  let tempValue = String(value).replace(/[^0-9.-]/g, '');
  let realValue = '';
  if (tempValue.split('.').length > 2) {
    let valueArr = tempValue.split('.');
    for (let i = 0; i < valueArr.length; i++) {
      if (i === 0) {
        realValue = valueArr[i] + '.';
      } else {
        realValue = realValue + valueArr[i];
      }
    }
  } else {
    realValue = tempValue;
  }
  if (realValue.split('.').length > 1) {
    const tDecimal = IsValidStr(decimals) ? decimals : realValue.split('.')[1].length;
    if (realValue.split('.')[0] === '' || realValue.split('.')[0] === '-') {
      if (realValue.split('.')[0] === '-') {
        realValue = '-0.' + realValue.split('.')[1].substring(0, tDecimal);
      } else {
        realValue = '0.' + realValue.split('.')[1].substring(0, tDecimal);
      }
    } else {
      realValue = realValue.split('.')[0] + '.' + realValue.split('.')[1].substring(0, tDecimal);
    }
  }
  if (realValue.indexOf('-') > 0 || tempValue.split('-').length > 2) {
    tempValue = '';
    let valArr = realValue.split('-');
    for (let i = 0; i < valArr.length; i++) {
      if (i === 0) {
        if (realValue.charAt(0) === '-') {
          tempValue = '-';
        }
        tempValue = tempValue + valArr[i];
      } else {
        tempValue = tempValue + valArr[i];
      }
    }
    realValue = tempValue;
  }
  return realValue;
}

export function parseField(value, field) {
  let re = '';
  let val = '';
  let wholeString = '';
  let decimalString = '';
  let tempFin = '';
  let negString = '';
  let output = '';

  switch (field) {
    case 'negative-positive-decimal':
      re = /[^\d.-]+/g;
      val = value.replace(re, '');
      val = val.split('.');

      if (
        value.trim() === '' ||
        value.trim() === '-' ||
        value.trim() === '.' ||
        value.trim() === '-.'
      ) {
        //false
        return value.trim();
      } else {
        if (val.length > 1) {
          for (let i = 1; i < val.length; i++) {
            decimalString = decimalString + val[i];
          }
          decimalString = decimalString.substring(0, 2);
          wholeString = val[0] + '.' + decimalString;

          re = /[^\d.-]+/g;
          wholeString = wholeString.replace(re, '');

          tempFin = wholeString.split('-');
          if (tempFin.length > 1) {
            for (let i = 0; i < tempFin.length; i++) {
              negString = negString + tempFin[i];
            }
            output = '-' + negString;
            return output;
          } else {
            return wholeString;
          }
        } else {
          //negative
          re = /[^\d.-]+/g;
          tempFin = value.replace(re, '');

          tempFin = tempFin.split('-');
          if (tempFin.length > 1) {
            for (var i = 0; i < tempFin.length; i++) {
              negString = negString + tempFin[i];
            }
            output = '-' + negString;
            return output;
          } else {
            return val.toString();
          }
        }
      }
    case 'positive-decimal':
      re = /[^0-9.]+/g;
      val = value.replace(re, '');
      val = val.split('.');

      if (value.trim() === '' || value.trim() === '.') {
        //false
        return value.trim();
      } else {
        if (val.length > 1) {
          for (let i = 1; i < val.length; i++) {
            decimalString = decimalString + val[i];
          }
          decimalString = decimalString.substring(0, 2);
          wholeString = val[0] + '.' + decimalString;

          return wholeString;
        } else {
          return val.toString();
        }
      }
    case 'wholeNumber-positive-decimal':
      re = /[^0-9.]+/g;
      val = value.replace(re, '');
      val = val.split('.');

      if (value.trim() === '' || value.trim() === '.') {
        //false
        return '';
      } else {
        if (val.length > 1) {
          for (let i = 1; i < val.length; i++) {
            decimalString = decimalString + val[i];
          }
          decimalString = decimalString.substring(0, 2);
          wholeString = val[0] + '.' + decimalString;

          return wholeString;
        } else {
          return val.toString();
        }
      }
    case 'negative':
      re = /[^\d-]+/g;
      val = value.replace(re, '');
      val = val.split('-');

      if (value.trim() === '' || value.trim() === '-') {
        //false
        return value.trim();
      } else {
        if (val.length > 1) {
          for (let i = 0; i < val.length; i++) {
            negString = negString + val[i];
          }
          output = '-' + negString;
          return output;
        } else {
          return val.toString();
        }
      }
    case 'positive':
      re = /[^\d]+/g;
      val = value.replace(re, '');

      if (value.trim() === '') {
        //false
        return value.trim();
      } else {
        return val.toString();
      }
    default:
      break;
  }
}
export function TryGetValue(val) {
  try {
    return val;
  } catch {
    return undefined;
  }
}
export function TryGetObjValueAlt(obj, altVal, node) {
  try {
    if (!IsValid(obj)) return altVal;
    const nodes = node.split('.');
    return nodes.reduce((o, n) => o && o[n], obj) || altVal;
  } catch {
    return altVal;
  }
}
export function TryGetNodeAlt(obj, altVal, key) {
  try {
    if (!IsValid(obj)) return altVal;
    return HasProperty(obj, key) ? obj[key] : altVal
  } catch {
    return altVal;
  }
}

export function FormatCVMParamValue(action, value, uom) {
  let fVal = {
    value: '',
    inValidMsg: '',
  };
  let deci = '';

  //dollar, percentage, count, number -trill to +
  if (action === 'blur') {
    if (value !== '' && value.indexOf('.') === -1 && Number(value) === 0) {
      fVal.value = '0';
    } else if (value == '') {
      fVal.value = '';
    } else {
      //TRIM PRECEEDING 0
      let num = value.indexOf('.') > -1 ? Number(value.split('.')[0]) : Number(value);
      num = isNaN(num) ? 0 : num;
      value = `${num}${value.indexOf('.') > -1 ? `.${value.split('.')[1]}` : ''}`;

      fVal.value =
        value.split('').reverse().join('').indexOf('.') === 0 ? value.replace('.', '') : value;
    }
  } else {
    if (value !== '' && value.trim() !== '') {
      if (
        Number(value) === 0 &&
        value.split('0').length >= 12 &&
        ['percentage', '%', '#count', 'number'].indexOf(uom.toLowerCase()) > -1
      ) {
        fVal.value = '000000000000';
      } else if (
        Number(value) === 0 &&
        value.split('0').length >= 12 &&
        'usd' === uom.toLowerCase()
      ) {
        fVal.value = '000000000000';
      } else {
        if (['percentage', '%', '#count', 'number'].indexOf(uom.toLowerCase()) > -1) {
          fVal.value = parseField(value, 'negative-positive-decimal');
          if (
            fVal.value.split('-').length > 1 &&
            parseFloat(fVal.value).toFixed(2) < -99999999999.99
          ) {
            deci =
              parseFloat(fVal.value).toFixed(2).split('.')[1] === '00'
                ? '.99'
                : fVal.value.slice(fVal.value.lastIndexOf('.'));
            fVal.value = fVal.value.substring(0, 12) + deci;
            fVal.inValidMsg = 'You have reached your maximum limit of characters allowed.';
          } else if (parseFloat(fVal.value).toFixed(2) > 999999999999.99) {
            deci =
              parseFloat(fVal.value).toFixed(2).split('.')[1] === '00'
                ? '.99'
                : fVal.value.slice(fVal.value.lastIndexOf('.'));
            fVal.value = fVal.value.substring(0, 12) + deci;
            fVal.inValidMsg = 'You have reached your maximum limit of characters allowed.';
          }
        } else if (['usd', 'dollar', '$'].indexOf(uom.toLowerCase()) > -1) {
          fVal.value = parseField(value, 'negative-positive-decimal');
          if (
            fVal.value.split('-').length > 1 &&
            parseFloat(fVal.value).toFixed(2) < -99999999999.99
          ) {
            deci =
              parseFloat(fVal.value).toFixed(2).split('.')[1] === '00'
                ? '.99'
                : fVal.value.slice(fVal.value.lastIndexOf('.'));
            fVal.value = fVal.value.substring(0, 12) + deci;
            fVal.inValidMsg = 'You have reached your maximum limit of characters allowed.';
          } else if (parseFloat(fVal.value).toFixed(2) > 999999999999.99) {
            deci =
              parseFloat(fVal.value).toFixed(2).split('.')[1] === '00'
                ? '.99'
                : fVal.value.slice(fVal.value.lastIndexOf('.'));
            fVal.value = fVal.value.substring(0, 12) + deci;
            fVal.inValidMsg = 'You have reached your maximum limit of characters allowed.';
          }
        } else {
          //days hours min 0 to + trill
          fVal.value = parseField(value, 'negative-positive-decimal');
          if (IsValidNum(fVal.value) && fVal.value !== '') {
            if (parseFloat(fVal.value).toFixed(2) > 999999999999.99) {
              deci =
                parseFloat(fVal.value).toFixed(2).split('.')[1] === '00'
                  ? '.99'
                  : fVal.value.slice(fVal.value.lastIndexOf('.'));
              fVal.value = fVal.value.substring(0, 12) + deci;
              fVal.inValidMsg = 'You have reached your maximum limit of characters allowed.';
            } else if (parseFloat(fVal.value).toFixed(2) <= -99999999999.99) {
              deci =
                parseFloat(fVal.value).toFixed(2).split('.')[1] === '00'
                  ? '.99'
                  : fVal.value.slice(fVal.value.lastIndexOf('.'));
              fVal.value = fVal.value.substring(0, 12) + deci;
              fVal.inValidMsg = 'You have reached your maximum limit of characters allowed.';
            }
          }
        }
      }
    }
  }
  return fVal;
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
export function GetMonthMMM(mon) {
  try {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[mon];
  } catch (e) {
    return '';
  }
}

export function GetQuarter(mon) {
  try {
    let quarter = [8, 9, 10].includes(mon) ? "1" :
      [11, 0, 1].includes(mon) ? "2" : [2, 3, 4].includes(mon) ? "3" : "4"; //fiscal year

    return quarter;
  } catch (e) {
    return '';
  }
}

export function GetMonthIndex(mon) {
  try {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months.indexOf(mon);
  } catch (e) {
    return '';
  }
}

export function FormDateDDMMMYYY(dt, isUTC = false) {
  try {
    dt = new Date(`${dt} ${isUTC ? 'UTC' : ''}`);
    const d = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
    const m = GetMonthMMM(dt.getMonth());
    const y = dt.getFullYear();
    return d + '-' + m + '-' + y;
  } catch (e) {
    return '';
  }
}
export function FormDateDDMMMYYYUTC(dt) {
  try {
    dt = new Date(`${dt}`);
    const d = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
    const m = GetMonthMMM(dt.getMonth());
    const y = dt.getFullYear();
    return d + '-' + m + '-' + y;
  } catch (e) {
    return '';
  }
}
export function FormTimeAPM(dt, isUTC = false) {
  try {
    let date = new Date(`${dt} ${isUTC ? 'UTC' : ''}`);
    let hour = date.getHours().toString();
    let ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    hour = hour.toString().length > 1 ? hour : '0' + hour;
    let minute = date.getMinutes().toString();
    minute = minute.length > 1 ? minute : '0' + minute;
    return hour + ':' + minute + ' ' + ampm;
  } catch (e) {
    return '';
  }
}
export const placeValueFormater = (n) => {
  let space = ' ';
  var ranges = [
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'K' },
  ];
  if (n.toString() !== '-Infinity') {
    for (var i = 0; i < ranges.length; i++) {
      if (n >= ranges[i].divider) {
        let tempRange = (n / ranges[i].divider).toFixed(2);
        return tempRange.toString() + ' ' + ranges[i].suffix;
      }
    }
  }
  return n;
};

export const trimFieldForFileName = (field, lastIndex) => {
  try {
    let data = field;
    if (field.length > lastIndex) {
      let original = field.split(' ');
      let trimString = field.substring(0, lastIndex);
      let trimmed = trimString.split(' ');
      if (trimmed[trimmed.length] !== original[trimmed.length]) {
        trimmed.pop();
      }
      data = trimmed.join(' ');
    }
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const UnRefObj = (obj) => {
  //THIS IS USE TO REMEDIATE HARD COPYING OR REFERENCE EDITING
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch {
    return '';
  }
};

export const AreObjsEqual = (obj1, obj2) => {
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } catch {
    return undefined;
  }
};

export const ObjToStr = (str) => {
  try {
    return JSON.stringify(str);
  } catch {
    return '';
  }
};

export const StrToObj = (str) => {
  try {
    return JSON.parse(str);
  } catch {
    return {};
  }
};

export const getUserTimeZone = () => {
  try {
    let tOffset = new Date().getTimezoneOffset();
    let offset = Math.abs(tOffset);
    return (
      (tOffset < 0 ? '+' : '-') +
      ('00' + Math.floor(offset / 60)).slice(-2) +
      ':' +
      ('00' + (offset % 60)).slice(-2)
    ).toString();
  } catch (err) {
    console.log(err);
  }
};

export const ParseJwt = (token) => {
  try {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};

export const notificationDuration = (date) => {
  try {
    //SOURCE: https://gist.github.com/IbeVanmeenen/4e3e58820c9168806e57530563612886
    // Epochs
    const epochs = [
      // ['year', 31536000],
      // ['month', 2592000],
      ['week', 604800],
      ['day', 86400],
      ['hr', 3600],
      ['min', 60],
      ['second', 1],
    ];

    // Get duration
    const getDuration = (timeAgoInSeconds) => {
      for (let [name, seconds] of epochs) {
        const interval = Math.floor(timeAgoInSeconds / seconds);
        if (interval >= 1) {
          return {
            interval: interval,
            epoch: name,
          };
        }
      }
    };

    // Calculate
    const newDate = date.replace(/-/g, '/');
    const timeAgoInSeconds = Math.floor((new Date() - new Date(newDate)) / 1000);
    const { interval, epoch } = getDuration(timeAgoInSeconds);
    const suffix = interval === 1 ? '' : 's';

    return `${interval} ${epoch}${suffix} ago`;
  } catch (e) {
    console.log(e);
  }
};

// https://github.com/alexandersmanning/read-more-react/blob/master/source/utils/trimText.js
export const trimText = (text, min = 80, ideal = 100, max = 200) => {
  try {
    const PUNCTUATION_LIST = ['.', ',', '!', '?', "'", '{', '}', '(', ')', '[', ']', '/'];
    //This main function uses two pointers to move out from the ideal, to find the first instance of a punctuation mark followed by a space. If one cannot be found, it will go with the first space closest to the ideal.
    const spaceMatch = (character) => {
      if (character === ' ') {
        return true;
      }
    };

    const punctuationMatch = (idx, text) => {
      let punctuationIdx = PUNCTUATION_LIST.indexOf(text[idx]);
      if (punctuationIdx >= 0 && spaceMatch(text[idx + 1])) {
        return true;
      }
    };

    const checkMatch = (idx, text, max, min) => {
      if (idx < max && idx > min && punctuationMatch(idx, text)) {
        return true;
      }
    };

    if (max < min || ideal > max || ideal < min) {
      throw new Error(
        'The minimum length must be less than the maximum, and the ideal must be between the minimum and maximum.'
      );
    }

    if (text.length < ideal) {
      return [text, ''];
    }

    let pointerOne = ideal;
    let pointerTwo = ideal;
    let firstSpace, resultIdx;

    const setSpace = (idx) => {
      if (spaceMatch(text[idx])) {
        firstSpace = firstSpace || idx;
      }
    };

    while (pointerOne < max || pointerTwo > min) {
      if (checkMatch(pointerOne, text, max, min)) {
        resultIdx = pointerOne + 1;
        break;
      } else if (checkMatch(pointerTwo, text, max, min)) {
        resultIdx = pointerTwo + 1;
        break;
      } else {
        setSpace(pointerOne);
        setSpace(pointerTwo);
      }

      pointerOne++;
      pointerTwo--;
    }

    if (resultIdx === undefined) {
      if (firstSpace && firstSpace >= min && firstSpace <= max) {
        resultIdx = firstSpace;
      } else if (ideal - min < max - ideal) {
        resultIdx = min;
      } else {
        resultIdx = max;
      }
    }

    return [text.slice(0, resultIdx), text.slice(resultIdx).trim()];
  } catch (e) {
    console.log(e);
  }
};

export const formatMultiLabel = (textArr) => {
  try {
    let labels = "";
    let textArrs = textArr.sort();
    if (textArrs.length > 2) {
      for (let i = 0; i < textArrs.length; i++) {
        if (i === (textArrs.length - 1)) {
          labels += "and " + textArrs[i];
        } else {
          labels += textArrs[i] + ", ";
        }
      }
    } else if (textArrs.length === 2) {
      labels = textArrs.join(' and ');
    } else {
      labels = textArrs[0];
    }
    return labels;
  } catch (e) {
    return "";
  }
}