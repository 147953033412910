import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-bootstrap/Tooltip"

//components
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import CustomBOIModal from "../../../Views/ProgramProjectConfiguration/CustomBOIModal";

//icons
import { GrooveIconSvg } from "tfo-groove/dist/components/Icons/IconSvg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import newInfo from "../../../Static/images/svg/new-info.svg";

//actions
import { getCustomBOIDetails } from "../../../Actions/Shared.Action";

//css
import "./ProjectDataEntryList.css";

//to check this function if still needed
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <div className="aggregated-tabpanel">{children}</div>}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const ViewAggregatedBOIDetails = (function () {

    class ViewAggregatedBOIDetailsPresentational extends Component {
        render() {
            let disabledBOIs = this.props.aggregatedDisabledBOIs
            return (
                <React.Fragment>
                    <div className="aggregated-boi-popover-header">
                        <div className="aggregated-boi-popover-title">Business Outcomes Indicator(s)</div>
                        <span className="aggregated-boi-popover-close-btn" onClick={() => document.body.click()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </div>

                    <div className="aggregated-boi-accordion-container">
                        <Accordion
                            className="configure-client-collapse visualization-collapse"
                            activeKey={(this.props.panelClicked === 0 && disabledBOIs.length !== 0) ? disabledBOIs[0].dimensionID : this.props.panelClicked}
                            onSelect={(e) => {
                                this.props.panelOnClick(e, true);
                            }}
                        >
                            {this.props.aggregatedDisabledBOIs === undefined ? [] :
                                this.props.aggregatedDisabledBOIs.length === 0 ?
                                    <span>No Aggregated Disabled BOIs data</span>
                                    :
                                    this.props.aggregatedDisabledBOIs.map((dItem, dIndex) => {

                                        if (dItem.dimensionID !== 0 && dItem.subDimensionList.subDimensionID !== 0) {
                                            let data = this.props.setTabData(dItem, dIndex);
                                            return (
                                                <Card>
                                                    <Accordion.Collapse eventKey={dItem.dimensionID}>
                                                        <Card.Body>
                                                            <div className="dim-tabs">
                                                                <div position="static" color="default">
                                                                    <Tabs
                                                                        className="aggregated-boi-detail-tabs"
                                                                        value={this.props.selectedTab || 0}
                                                                        onChange={this.props.handleChange}
                                                                        variant="scrollable"
                                                                        scrollButtons="on"
                                                                        indicatorColor="primary"
                                                                        textColor="primary"
                                                                        aria-label="scrollable auto tabs example"
                                                                    >
                                                                        {
                                                                            data.map((tab, index) => {
                                                                                return (
                                                                                    <Tab 
                                                                                      label={tab.tab_label} 
                                                                                      key={tab.tab_label} 
                                                                                      {...a11yProps(index)}
                                                                                      />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tabs>
                                                                </div>
                                                                {
                                                                    data.map((tab, index) => {
                                                                        return (
                                                                            <TabPanel value={this.props.selectedTab} index={index}>
                                                                                {tab.tab_content}
                                                                            </TabPanel>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Button} variant="link" eventKey={dItem.dimensionID}>
                                                            <div className="bodytext18-medium-midnight">{dItem.dimensionName}</div>
                                                            <GrooveIconSvg
                                                                id="vaBOIAccordButton"
                                                                customClassName="accordion-button"
                                                                size="small"
                                                                name="chevron-down"
                                                                iconStyle="solid"
                                                                iconColor="stat-neutral"
                                                            />
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                </Card>
                                            );
                                        }

                                    }
                                    )}

                        </Accordion >
                    </div >
                    <CustomBOIModal
                        isShow={this.props.showCustomBOI}
                        onCustomBOIModalCancelClose={() => this.props.onClickCustomBOI(0, false)}
                        subDimensionName={this.props.subDimensionName}
                        dimensionName={this.props.dimensionName}
                        boi={this.props.boi}
                    />
                </React.Fragment >
            );
        }
    }

    class ViewAggregatedBOIDetailsContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                showModal: false,
                dimensionName: "",
                subDimensionName: "",
                boi: {
                    ClientName: "",
                    offeringName: "",
                    boiName: "",
                    uom: "",
                    description: "",
                    LeadingIndicator: "",
                    comment: "",
                    MetricCalculation: "",
                    customParameterList: [],
                    AggregationLogic: "",
                    SubOffering: "",
                    AggregationLogicId: 0,
                    MetricReason: "",
                    AggregateReason: "",
                },
                panelClicked: 0,
                selectedTab: 0
            };
        }

        onCloseModal = () => {
            this.setState({
                showModal: !this.state.showModal
            });
        }

        setTabData = (item, dIndex) => {
            let program_tab_data = [];

            item.subDimensionList.map((sdItem, sdIndex) => {
                if (sdItem.subDimensionID !== 0) {
                    program_tab_data.push({
                        tab_label: sdItem.subDimensionName,
                        tab_value: sdIndex + 1,
                        tab_id: sdItem.subDimensionName,
                        tab_content: (
                            sdItem.boiList.map((boiItem, boiIndex) => {

                                return (
                                    <div className="aggregated-boi-label-container">
                                        <div className="aggregated-boi-left-label">
                                            <div className="aggregated-boi-left-text" title={boiItem.boiName}>{`${boiIndex + 1}) ${boiItem.boiName}`}</div> ({boiItem.uom})
                                            {!boiItem.IsCustom &&
                                                <div className="aggregated-info info-icon-wrap">
                                                    <OverlayTrigger
                                                        key="right"
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="boi-tooltip-right">
                                                                <div className="boi-details-wrap row">
                                                                    <div class="col-md-6">
                                                                        <h6>Offering</h6>
                                                                        <p class="">{boiItem.offeringName}</p>
                                                                        <h6>Dimension</h6>
                                                                        <p class="">{item.dimensionName}</p>
                                                                        <h6>BOI Description</h6>
                                                                        <p class="">{boiItem.description}</p>

                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <h6>Sub-Offering</h6>
                                                                        <p class="">{boiItem.suboffering}</p>
                                                                        <h6>Sub-dimension</h6>
                                                                        <p class="">{sdItem.subDimensionName}</p>
                                                                        <h6>Leading Practice</h6>
                                                                        <p class="">{boiItem.leadingIndicator}</p>
                                                                        <table>
                                                                            <tr class="col-md-12">
                                                                                <th class="col-md-6"><h6>Parameter</h6></th>
                                                                                <th class="col-md-6"><h6>Unit of Measure</h6></th>
                                                                            </tr>
                                                                            {(boiItem.hasOwnProperty("parameterList") ? boiItem.parameterList : []).map((params) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td class="col-md-6">
                                                                                            <p>{params.ParameterName}</p>
                                                                                        </td>
                                                                                        <td class="col-md-6">
                                                                                            <p>{params.ParameterUOM}</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="dimension-new-info">
                                                            <img className="new-info-icon" src={newInfo} alt="info" />
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            }
                                            {
                                                boiItem.IsCustom ? (
                                                    <button
                                                        className="custom-boi"
                                                        onClick={() => this.onClickCustomBOI(boiItem.boiID, true)}
                                                    >
                                                        Custom
                                                    </button>
                                                ) : null
                                            }
                                        </div>
                                        <div className="aggregated-boi-right-label">
                                            {
                                                boiItem.aggregatedDeliveredValue !== null && boiItem.aggregatedDeliveredValue !== undefined ?
                                                    <div>{boiItem.aggregatedDeliveredValue} {boiItem.uom}</div> : ""
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        )
                    });
                }

            });
            return program_tab_data;
        }

        onClickCustomBOI = async (boiId, isOpen) => {
            // let programId = store.getState().DashboardData.programID;
            let selectProject = this.props.ConfigureProgramData.mainSelectedProject;
            let programId = selectProject.programID;

            this.setState({
                showCustomBOI: !this.state.showCustomBOI,
            })

            try {
                if (isOpen) await this.props.getCustomBOIDetails(programId, boiId);

                const data = this.props.SharedData.customBOI
                const hasData = Object.keys(data).length > 0 ? true : false;

                setTimeout(() => {
                    this.setState({
                        dimensionName: hasData ? data[0].CoreDimensionName : "",
                        subDimensionName: hasData ? data[0].SubDimensionName : "",
                        boi: {
                            ClientName: hasData ? data[0].ClientName : "",
                            offeringName: hasData ? data[0].Offering : "",
                            boiName: hasData ? data[0].BOIName : "",
                            uom: hasData ? data[0].UOM : "",
                            description: hasData ? data[0].BOIDescription : "",
                            LeadingIndicator: hasData ? data[0].LeadingIndicator : "",
                            comment: hasData ? data[0].BOIAdditionComment : "",
                            MetricCalculation: hasData ? data[0].MetricCalculation : "",
                            customParameterList: hasData ? data[0].CustomParameterList.map((x, i) => {
                                return ({
                                    ParameterCustomCode: x.ParameterName,
                                    ParameterUOM: x.ParameterUOM
                                })
                            }) : [],
                            AggregationLogic: hasData ? data[0].AggregationLogic : "",
                            SubOffering: hasData ? data[0].SubOfferingName : "",
                            AggregationLogicId: hasData ? data[0].BOIDefinitionMasterID : 0,
                            MetricReason: hasData ? data[0].ReasonForNoBOIDefinition : "",
                            AggregateReason: hasData ? data[0].ReasonForNoAggregationLogic : "",
                        },
                    })
                }, 10)
            }
            catch (err) {
                console.log(err)
            }
        }

        panelOnClick = (index, isClicked) => {
            this.setState({
                panelClicked: index
            })
        }

        handleChange = (event, newValue) => {
            this.setState({
                selectedTab: newValue,
            });
        };

        render() {

            return (
                <ViewAggregatedBOIDetailsPresentational
                    onCloseModal={this.onCloseModal}
                    coreDimesionList={this.props.coreDimesionList}
                    setTabData={this.setTabData}
                    boi={this.state.boi}
                    subDimensionName={this.state.subDimensionName}
                    showModal={this.state.showModal}
                    dimensionName={this.state.dimensionName}
                    panelClicked={this.state.panelClicked}
                    panelOnClick={this.panelOnClick}
                    selectedTab={this.state.selectedTab}
                    aggregatedDisabledBOIs={this.props.aggregatedDisabledBOIs}
                    handleChange={this.handleChange}
                    showCustomBOI={this.state.showCustomBOI}
                />
            );
        }
    }

    return ViewAggregatedBOIDetailsContainer;
})();

export default connect(
    (state) => {
        return {
            ConfigureProgramData: state.ConfigureProgramData,
            SharedData: state.SharedData
        };
    },
    (dispatch) =>
        bindActionCreators(
            Object.assign(
                {},
                {
                    getCustomBOIDetails,
                }
            ),
            dispatch
        )
)(ViewAggregatedBOIDetails);
