import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import { TryGetValue } from '../../../Utilities/Formatter';
import addfiles from '../../../Static/images/360cvm/AddFilesIcon.svg';
import './DimensionAccordionSpecificBOIHistory.css';
import svgHistory from '../../../Static/images/360cvm/History.svg';
import newInfo from '../../../Static/images/svg/new-info.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomBOIModal from '../../ProgramProjectConfiguration/CustomBOIModal';
import { FormDateDDMMMYYY, FormTimeAPM } from '../../../Utilities/Formatter';

const DimensionAccordionSpecificBOIHistory = (function () {
  class DimensionAccordionSpecificBOIHistoryPresentational extends Component {
    removeSec = (dateTime) => {
      if (dateTime === null) return '';

      try {
        const dateTimeArr = dateTime.split(' ');
        const timeArr = dateTimeArr[1].split(':');
        return `${dateTimeArr[0]} ${timeArr[0]}:${timeArr[1]}`;
      } catch (err) {
        return '';
      }
    };
    removeDomain = (name) => {
      if (name === null) return '';
      return name.split('@')[0];
    };

    render() {
      const dimList = this.props.dimensionList;
      const headers = this.props.headers;
      const mode = this.props.mode;

      if ((dimList.length || 0) === 0)
        return (
          <div className="empty-dimension-container">
            <div className="empty-program-container bodytext18-regular-slate">
              <img src={addfiles} alt="select client" />
              <span className="bodytext24-medium-slate">No Business Outcome Indicator {mode}</span>
            </div>
          </div>
        );

      return (
        <div>
          <div className="prog-acct-table-header-container"></div>

          <div className="dimension-table">
            <div className="dimension-table-header">
              <div className="dimension-table-row specific-history-header dimension-boi-history">
                {headers.map((name, i) => (
                  <div style={name === 'Revised Target Value' ? { width: '12%' } : {}} key={i}>
                    {name}
                  </div>
                ))}
              </div>
            </div>
            <div className="dimension-table-body">
              {dimList.map((dimension, i) => {
                let firstSubDChild = dimension.SubDimensionList[0] || [];
                if (firstSubDChild.length === 0) return <></>;

                let firstBoiChild = firstSubDChild.boiDataList[0] || [];
                let skipSubD = false;

                return (
                  <Accordion
                    className="dimension-table-collapse"
                    defaultActiveKey={String(i)}
                    onSelect={(e) => {
                      this.props.onSelectAccordion(e);
                    }}
                  >
                    <Card key={i}>
                      <Accordion.Collapse eventKey={String(i)}>
                        <Card.Header className="dim-card-header">
                          <div className="dim-header-container">
                            {dimension.SubDimensionList.map((subD, i2) => {
                              return (
                                <>
                                  {subD.boiDataList.map((boi, i3) => {
                                    if (!skipSubD) {
                                      skipSubD = true;
                                      return <></>;
                                    }
                                    return (
                                      <div
                                        className="dimension-table-row dimension-boi-history"
                                        style={{ alignItems: 'center' }}
                                      >
                                        <div></div>
                                        <div>{i3 === 0 && subD.SubDimension}</div>
                                        <div className="row boi-column">
                                          <div>
                                            {boi.BOIName}
                                            {!boi.IsCustom && (
                                              <div className="info-icon-wrap">
                                                <OverlayTrigger
                                                  key="right"
                                                  placement="auto"
                                                  overlay={
                                                    <Tooltip id="boi-tooltip-right">
                                                      <div className="boi-details-wrap row">
                                                        <div class="col-md-6">
                                                          <h6>Offering</h6>
                                                          <p class="">{boi.OfferingName}</p>
                                                          <h6>Dimension</h6>
                                                          <p class="">{dimension.Dimension}</p>
                                                          <h6>BOI Description</h6>
                                                          <p class="">{boi.BOIDescription}</p>
                                                        </div>
                                                        <div class="col-md-6">
                                                          <h6>Sub-Offering</h6>
                                                          <p class="">{boi.SubOfferingName}</p>
                                                          <h6>Sub-dimension</h6>
                                                          <p class="">{subD.SubDimension}</p>
                                                          <h6>Leading Practice</h6>
                                                          <p class="">{boi.LeadingIndicator}</p>
                                                          <table>
                                                            <tr class="col-md-12">
                                                              <th class="col-md-6">
                                                                <h6>Parameter</h6>
                                                              </th>
                                                              <th class="col-md-6">
                                                                <h6>Unit of Measure</h6>
                                                              </th>
                                                            </tr>
                                                            {(boi.hasOwnProperty('ParameterList')
                                                              ? boi.ParameterList
                                                              : []
                                                            ).map((params) => {
                                                              return (
                                                                <tr>
                                                                  <td class="col-md-6">
                                                                    <p>{params.ParameterName}</p>
                                                                  </td>
                                                                  <td class="col-md-6">
                                                                    <p>{params.ParameterUOM}</p>
                                                                  </td>
                                                                </tr>
                                                              );
                                                            })}
                                                          </table>
                                                        </div>
                                                      </div>
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div className="dimension-new-info">
                                                    <img
                                                      className="new-info-icon"
                                                      src={newInfo}
                                                      alt="info"
                                                    />
                                                  </div>
                                                </OverlayTrigger>
                                              </div>
                                            )}
                                            {boi.IsCustom ? (
                                              <div>
                                                <div>
                                                  <button
                                                    className="custom-boi"
                                                    onClick={() =>
                                                      this.props.onClickCustomBOI(
                                                        boi.BOIID,
                                                        true,
                                                        i,
                                                        i2,
                                                        i3
                                                      )
                                                    }
                                                  >
                                                    Custom
                                                  </button>
                                                  {this.props.showCustomBOI && (
                                                    <CustomBOIModal
                                                      dimensionName={this.props.dimensionName}
                                                      subDimensionName={this.props.subDimensionName}
                                                      isShow={this.props.showCustomBOI}
                                                      onCustomBOIModalCancelClose={() =>
                                                        this.props.onClickCustomBOI(
                                                          0,
                                                          false,
                                                          null,
                                                          null,
                                                          null
                                                        )
                                                      }
                                                      boi={this.props.boi}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="row boi-column">{boi.ActivityName}</div>
                                        {headers.includes('Previous Target Value') && (
                                          <div>
                                            {boi.OldValue !== null && boi.OldValue !== ''
                                              ? `${boi.OldValue.replace(/\.00$/, '')} 
                                              ${!boi.OldValue.toLowerCase().includes("on") && !boi.OldValue.toLowerCase().includes("off") ? boi.UOM : ""}`
                                              : ''}
                                          </div>
                                        )}
                                        {headers.includes('Revised Target Value') && (
                                          <div>
                                            {boi.NewValue !== null && boi.NewValue !== ''
                                              ? `${boi.NewValue.replace(/\.00$/, '')} 
                                              ${!boi.NewValue.toLowerCase().includes("on") && !boi.NewValue.toLowerCase().includes("off") ? boi.UOM : ""}`
                                              : ''}
                                          </div>
                                        )}
                                        {headers.includes('Modified By & On') && (
                                          <div>

                                            {this.removeDomain(boi.ModifiedBy)}
                                            <span className="boi-divider"></span>

                                            <div style={{ whiteSpace: 'nowrap', display: 'inline' }}>
                                              {FormDateDDMMMYYY(boi.ModifiedOn, true)
                                                + ' ' + FormTimeAPM(boi.ModifiedOn, true)
                                              }

                                            </div>


                                          </div>
                                        )}
                                        {headers.includes('Approver Comment') && (
                                          <div>
                                            <textarea
                                              className="comment-input"
                                              name="comment"
                                              placeholder={boi.ApproverComment}
                                              readOnly
                                            />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </div>
                        </Card.Header>
                      </Accordion.Collapse>
                      <Card.Header className="dim-card-header">
                        <div
                          className="dim-header-container"
                          onClick={(e) => this.props.onClickAccordion(e, 'div')}
                        >
                          <div
                            className="dimension-table-row dimension-boi-history"
                            style={{ alignItems: 'center' }}
                          >
                            <div className="dim-name">{dimension.Dimension}</div>
                            <div>{TryGetValue(firstSubDChild.SubDimension)}</div>
                            <div className="row boi-column">
                              <div>
                                {firstBoiChild.BOIName}
                                {(firstBoiChild.BOIName.length || false) &&
                                  !firstBoiChild.IsCustom && (
                                    <div className="info-icon-wrap">
                                      <OverlayTrigger
                                        key="right"
                                        placement="auto"
                                        overlay={
                                          <Tooltip id="boi-tooltip-right">
                                            <div className="boi-details-wrap row">
                                              <div class="col-md-6">
                                                <h6>Offering</h6>
                                                <p class="">{firstBoiChild.OfferingName}</p>
                                                <h6>Dimension</h6>
                                                <p class="">{dimension.Dimension}</p>
                                                <h6>BOI Description</h6>
                                                <p class="">{firstBoiChild.BOIDescription}</p>
                                              </div>
                                              <div class="col-md-6">
                                                <h6>Sub-Offering</h6>
                                                <p class="">{firstBoiChild.SubOfferingName}</p>
                                                <h6>Sub-dimension</h6>
                                                <p class="">{firstSubDChild.SubDimension}</p>
                                                <h6>Leading Practice</h6>
                                                <p class="">{firstBoiChild.LeadingIndicator}</p>
                                                <table>
                                                  <tr class="col-md-12">
                                                    <th class="col-md-6">
                                                      <h6>Parameter</h6>
                                                    </th>
                                                    <th class="col-md-6">
                                                      <h6>Unit of Measure</h6>
                                                    </th>
                                                  </tr>
                                                  {(firstBoiChild.hasOwnProperty('ParameterList')
                                                    ? firstBoiChild.ParameterList
                                                    : []
                                                  ).map((params) => {
                                                    return (
                                                      <tr>
                                                        <td class="col-md-6">
                                                          <p>{params.ParameterName}</p>
                                                        </td>
                                                        <td class="col-md-6">
                                                          <p>{params.ParameterUOM}</p>
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                                </table>
                                              </div>
                                            </div>
                                          </Tooltip>
                                        }
                                      >
                                        <div className="dimension-new-info">
                                          <img className="new-info-icon" src={newInfo} alt="info" />
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  )}
                                {firstBoiChild.IsCustom ? (
                                  <div>
                                    <div>
                                      <button
                                        className="custom-boi"
                                        onClick={() =>
                                          this.props.onClickCustomBOI(
                                            firstBoiChild.BOIID,
                                            true,
                                            i,
                                            0,
                                            0
                                          )
                                        }
                                      >
                                        Custom
                                      </button>
                                      {this.props.showCustomBOI && (
                                        <CustomBOIModal
                                          dimensionName={this.props.dimensionName}
                                          subDimensionName={this.props.subDimensionName}
                                          isShow={this.props.showCustomBOI}
                                          onCustomBOIModalCancelClose={() =>
                                            this.props.onClickCustomBOI(0, false, null, null, null)
                                          }
                                          boi={this.props.boi}
                                        />
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row boi-column">{firstBoiChild.ActivityName}</div>
                            {headers.includes('Previous Target Value') && (
                              <div>
                                {firstBoiChild.OldValue !== null && firstBoiChild.OldValue !== ''
                                  ? `${firstBoiChild.OldValue.replace(/\.00$/, '')} 
                                  ${!firstBoiChild.OldValue.toLowerCase().includes("on") && !firstBoiChild.OldValue.toLowerCase().includes("off") ? firstBoiChild.UOM : ""}`
                                  : ''}
                              </div>
                            )}
                            {headers.includes('Revised Target Value') && (
                              <div>
                                {firstBoiChild.NewValue !== null && firstBoiChild.NewValue !== ''
                                  ? `${firstBoiChild.NewValue.replace(/\.00$/, '')} 
                                  ${!firstBoiChild.NewValue.toLowerCase().includes("on") && !firstBoiChild.NewValue.toLowerCase().includes("off") ? firstBoiChild.UOM : ""}`
                                  : ''}
                              </div>
                            )}
                            {headers.includes('Modified By & On') && (
                              <div>

                                {this.removeDomain(firstBoiChild.ModifiedBy)}
                                <span className="boi-divider"></span>

                                <div style={{ whiteSpace: 'nowrap', display: 'inline' }}>
                                  {FormDateDDMMMYYY(firstBoiChild.ModifiedOn, true)
                                    + ' ' + FormTimeAPM(firstBoiChild.ModifiedOn, true)
                                  }

                                </div>


                              </div>
                            )}

                            {headers.includes('Approver Comment') && (
                              <div>
                                <textarea
                                  className="comment-input"
                                  name="comment"
                                  placeholder={firstBoiChild.ApproverComment}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Card.Header>
                    </Card>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }

  class DimensionAccordionSpecificBOIHistoryContainer extends Component {
    constructor() {
      super();
      this.state = {
        activeExpand: false,
        activeCollapse: false,
        accordionCount: 0,
        onLoadExpand: false,
        isCollapsed: false,
        showCustomBOI: false,
        dimensionName: '',
        subDimensionName: '',
        boi: {
          ClientName: '',
          offeringName: '',
          boiName: '',
          uom: '',
          description: '',
          LeadingIndicator: '',
          comment: '',
          MetricCalculation: '',
          customParameterList: [],
          AggregationLogic: '',
          SubOffering: '',
          AggregationLogicId: 0,
          MetricReason: '',
          AggregateReason: '',
        },
      };
    }

    componentDidUpdate = () => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.onExpandCollapse(null, 'onload');
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
    };

    componentDidMount = () => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.onExpandCollapse(null, 'onload');
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
    };

    onExpandCollapse = (e, action) => {
      try {
        let elems = [];
        let isFirstStage = window.location.pathname.toLowerCase().includes('idvaluesettings')
          ? true
          : false;
        let caseString =
          action !== undefined && action === 'onload'
            ? 'expandAll'
            : e.currentTarget.id.includes('expandAll')
              ? 'expandAll'
              : 'collapseAll';
        switch (caseString) {
          case 'expandAll':
            elems = document.getElementsByClassName('collapse');
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === 'collapse') {
                  document
                    .getElementsByClassName('collapse')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === 'collapse') {
                  document
                    .getElementsByClassName('collapse')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: true,
              activeCollapse: false,
            });
            break;
          case 'collapseAll':
            elems = document.getElementsByClassName('collapse show');
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === 'collapse show') {
                  document
                    .getElementsByClassName('collapse show')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === 'collapse show') {
                  document
                    .getElementsByClassName('collapse show')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: false,
              activeCollapse: true,
            });
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
    };

    onSelectAccordion = (e) => {
      try {
        let accordionCount = this.state.accordionCount;
        let activeCollapse = false; //accordion close
        let activeExpand = false; //accordion open

        let dimensionList =
          this.props.dimensionList !== undefined
            ? this.props.dimensionList
            : this.state.dimensionList; //change
        if (e === null) {
          accordionCount -= 1;
        } else {
          accordionCount += 1;
        }

        if (dimensionList.length === accordionCount) {
          activeExpand = true;
          activeCollapse = false;
        } else if (accordionCount === 0) {
          activeCollapse = true;
          activeExpand = false;
        }

        this.setState({
          accordionCount: accordionCount,
          activeExpand: activeExpand,
          activeCollapse: activeCollapse,
        });
      } catch (e) {
        console.log(e);
      }
    };

    onClickAccordion = (e, action) => {
      let isCurrentlyCollapsed =
        e.currentTarget.parentElement.previousSibling.className === 'collapse' ? true : false;
      if (action === 'div' && isCurrentlyCollapsed) {
        e.currentTarget.nextSibling.click();
        e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
      } else {
        //btn
        if (!isCurrentlyCollapsed) {
          e.currentTarget.parentElement.parentElement.style.cursor = 'pointer';
        } else {
          e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
        }
      }
    };

    setHeaders = () => {
      let headers = [
        'Dimension',
        'Subdimension',
        'Business Outcome Indicators',
        'Activity',
        'Previous Target Value',
        'Revised Target Value',
        'Modified By & On',
        'Approver Comment',
      ];
      return headers;
    };

    onClickCustomBOI = async (boiId, isOpen, dimIdx, sdIdx, boiIdx) => {
      let programId = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
      let isProject = this.props.historyType.toLowerCase() === 'project';
      this.setState({
        showCustomBOI: !this.state.showCustomBOI,
      });
      try {
        const data = this.props.dimensionList;
        const hasData = Object.keys(data).length > 0 ? true : false;

        if (isOpen && hasData) {
          const subDimensionName = data[dimIdx].SubDimensionList[sdIdx].SubDimension;
          const boi = data[dimIdx].SubDimensionList[sdIdx].boiDataList[boiIdx];
          const newBoi = {
            ClientName: this.props.ProgramConfigurationData.programClientDetails.ClientMasterName,
            offeringName: isProject
              ? boi.OfferingName
              : this.props.ProgramConfigurationData.newProgramDetails.offering.label,
            boiName: boi.BOIName,
            uom: boi.UOM,
            description: boi.BOIDescription,
            LeadingIndicator: boi.LeadingIndicator,
            comment: boi.BOIAdditionComment,
            MetricCalculation: boi.MetricCalculation,
            newSubDimensionName: boi.SubDimension,
            customParameterList: hasData
              ? boi.ParameterList.map((x, i) => {
                return {
                  ParameterCustomCode: x.ParameterName,
                  ParameterUOM: x.ParameterUOM,
                };
              })
              : [],
            AggregationLogic: boi.AggregationLogic,
            SubOffering: boi.SubOfferingName,
            AggregationLogicId: boi.AggregationLogicMasterID, //why boi.boiDefinitionMasterID if program ? changed it to boi.aggregationLogicMasterID
            MetricReason: boi.ReasonForNoBOIDefinition,
            AggregateReason: boi.ReasonForNoAggregationLogic,
          };

          this.setState({
            dimensionName: data[dimIdx].Dimension,
            subDimensionName: subDimensionName,
            boi: newBoi,
          });
        } else {
          this.setState({
            dimensionName: '',
            subDimensionName: '',
            boi: {
              ClientName: '',
              offeringName: '',
              boiName: '',
              uom: '',
              description: '',
              LeadingIndicator: '',
              comment: '',
              MetricCalculation: '',
              customParameterList: [],
              AggregationLogic: '',
              SubOffering: '',
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    render() {
      return (
        <DimensionAccordionSpecificBOIHistoryPresentational
          dimensionList={this.props.dimensionList}
          onSelectAccordion={this.onSelectAccordion}
          mode={this.props.mode}
          headers={this.setHeaders()}
          onExpandCollapse={this.onExpandCollapse}
          activeExpand={this.state.activeExpand}
          activeCollapse={this.state.activeCollapse}
          onClickAccordion={this.onClickAccordion}
          isCollapsed={this.state.isCollapsed}
          showCustomBOI={this.state.showCustomBOI}
          onClickCustomBOI={this.onClickCustomBOI}
          boi={this.state.boi}
          dimensionName={this.state.dimensionName}
          subDimensionName={this.state.subDimensionName}
        />
      );
    }
  }

  return DimensionAccordionSpecificBOIHistoryContainer;
})();

export default connect(
  (state) => {
    return {};
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(DimensionAccordionSpecificBOIHistory);
