import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
// import { history } from "../store";
import { setUserInformation } from "../Actions/index";
import { showProfileButton } from "../Actions/Shared.Action";
import { GetCallbackURL, GetReferrer } from "../Utilities/AccessManagementUtil";
import { IsValidStr, HasValue } from '../Utilities/Validations'
import {
  Header,
  Footer,
  TermsAndAgreement,
  PrivacyStatement,
} from "./MasterLayout";
import {
  Home,
  AccessManagement,
  SomethingWentWrong,
  Modules,
  DashboardGlobal,
  Report,
  SubProgramProjectConfig,
  SubDataIngestionConfig,
  ReportingModule,
  DirectDownload,
} from "./index";

import "tfo-groove/dist/assets/css/groove.css";

require("es6-promise").polyfill();
require("isomorphic-fetch");

let headerRef,
  footerRef = React.createRef();

const Main = (function () {
  class MainContainer extends Component {
    componentDidMount = () => {
      const callbackURL = GetCallbackURL()
      const disti = sessionStorage.getItem('distinationUrl');
      if (HasValue(GetReferrer()) && String(disti).toLowerCase().includes('dashboard/global')) {
        this.props.history.push(disti)
      } else if (IsValidStr(callbackURL)) {
        this.props.history.push(callbackURL);
      }
    };
    closePop = () => {
      if (this.props.SharedData.showProfile === true) {
        this.props.showProfileButton(false);
      }
    };

    programProjectConfig = () => {
      const url = window.location.pathname.toLowerCase();

      return url.indexOf("/programprojectconfig/programidvaluesettings") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/programIDValueSettings"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf(
        "/programprojectconfig/programaccountleadershipapproval/review") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/programAccountLeadershipApproval/review"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf(
        "/programprojectconfig/programaccountleadershipapproval") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/programAccountLeadershipApproval"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf("/programprojectconfig/programclientalignment/submit") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/programClientAlignment/submit"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf("/programprojectconfig/programclientalignment") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/programClientAlignment"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf("/programprojectconfig/projectidvaluesettings") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/projectIDValueSettings"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf(
        "/programprojectconfig/projectaccountleadershipapproval") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/projectAccountLeadershipApproval"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf(
        "/programprojectconfig/projectaccountleadershipapproval/review") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/projectAccountLeadershipApproval/review"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf(
        "/programprojectconfig/projectaccountleadershipapproval/rejectedboi") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/projectAccountLeadershipApproval/rejectedboi"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf("/programprojectconfig/projectclientalignment") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/projectClientAlignment"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf("/programprojectconfig/projectclientalignment/submit") !== -1 ? (
        <Route
          path="/ProgramProjectConfig/projectClientAlignment/submit"
          component={SubProgramProjectConfig}
        />
      ) : url.indexOf("/programprojectconfig/projects") !== -1 ? (
        <Route path="/programprojectconfig/projects" component={Modules} />
      ) : url.indexOf("/programprojectconfig") !== -1 ? (
        <Route path="/programprojectconfig" component={Modules} />
      ) : (
        false
      );
    };
    dataIngestion = () => {
      const url = window.location.pathname.toLowerCase();

      return url.indexOf("/dataingestion/boihistory") !== -1 ? (
        <Route
          path="/DataIngestion/boihistory"
          component={SubDataIngestionConfig}
        />
      ) : url.indexOf("/dataingestion/configureprogram") !== -1 ? (
        <Route
          path="/DataIngestion/ConfigureProgram"
          component={SubDataIngestionConfig}
        />
      ) : url.indexOf("/reportvaluedelivered/downloadclientacknowledgementfilehistory") !== -1 ? (
        <Route
          path="/ReportValueDelivered/DownloadClientAcknowledgementFileHistory"
          component={DirectDownload}
        />
      ) : url.indexOf("/projectdataentry/downloadclientstoryfilehistory") !== -1 ? (
        <Route
          path="/ProjectDataEntry/DownloadClientStoryFileHistory"
          component={DirectDownload}
        />
      ) : url.indexOf("/dataingestion/") !== -1 ? (
        <Route path="/DataIngestion/:client" component={Modules} />
      ) : url.indexOf("/dataingestion") !== -1 ? (
        <Route path="/DataIngestion" component={Modules} />
      ) : (
        false
      );
    };

    render() {
      const programProjectConfig = this.programProjectConfig();
      const dataIngestion = this.dataIngestion();
      const url = window.location.pathname.toLowerCase();
      const disableClient = this.props.userInformation.isGlobalUser && this.props.userInformation.fromGlobal

      return (
        <div
          id="outer-container"
          style={{ height: "100%" }}
          onClick={() => this.closePop()}
        >
          <div id="page-wrap">
            <div id="page-container">
              <Header
                ref={headerRef}
                userName={this.props.userInformation.userName}
                isDisabled={disableClient}
              />
              {window.location.pathname === "/" ? (
                <Route exact path="/" component={Home} />
              ) : url === "/masterconfiguration" ? (
                <Route exact path="/masterconfiguration" component={Modules} />
              ) : programProjectConfig !== false ? (
                programProjectConfig
              ) : dataIngestion !== false ? (
                dataIngestion
              ) : //DASHBOARD
                url === "/dashboard" ? (
                  <Route exact path="/Dashboard" component={Modules} />
                ) : url.indexOf(
                  "/dashboard/global"
                ) !== -1 ? (
                  <Route path="/dashboard/global" component={DashboardGlobal} />
                ) : //View Reports
                  url === "/reportingmodule" &&
                    this.props.userInformation.userProfiles[0].ProfileID === 14 ? (
                    this.props.history.push('/')
                  ) :
                    url === "/reportingmodule" ? (
                      <Route path="/ReportingModule" component={ReportingModule} />
                    ) : //OTHERS
                      url === "/termsandagreement" ? (
                        <Route
                          exact
                          path="/TermsAndAgreement"
                          component={TermsAndAgreement}
                        />
                      ) : url === "/privacystatement" ? (
                        <Route
                          exact
                          path="/PrivacyStatement"
                          component={PrivacyStatement}
                        />
                      ) : url === "/accessmanagement" ? (
                        <Route
                          exact
                          path="/AccessManagement"
                          component={AccessManagement}
                        />
                      ) : url.indexOf("/accessmanagement/report/") !== -1 ? (
                        <Route path="/AccessManagement/Report/Users" component={Report} />
                      ) : url.indexOf("/config/:details") !== -1 ? (
                        <Route path="/config/:details" component={SomethingWentWrong} />
                      ) : (
                        <Redirect to={{ pathname: "/PageNotFound" }} />
                      )}
            </div>
            <Footer ref={footerRef} />
          </div>
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      SetUserInformation: (userInfo) => dispatch(setUserInformation(userInfo)),
      showProfileButton: (value) => dispatch(showProfileButton(value)),
    };
  }
  function mapStateToProps(state) {
    return {
      userInformation: state.userInformation,
      SharedData: state.SharedData,
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(MainContainer);
})();

export default withRouter(Main);
