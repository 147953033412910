import React from 'react'

import { GrooveButton } from 'tfo-groove';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const CVMSendReminderButton = (props) => {
    return (
        props.isDisabledReminderButton ?
            <div className='reminder-button'>
                <GrooveButton
                    id="primry-btn-2"
                    type="outline"
                    colorClass="stat-alternate"
                    text="Send Reminder"
                    customClassName="send-reminder-button"
                    hasIcon={true}
                    iconAlignment="right"
                    iconSize="small"
                    iconName="bell"
                    iconColor="acn-purple"
                    size="large"
                    iconStyle="solid"
                    callbackFunction={props.onSendReminder}
                    isDisabled={props.isDisabledReminderButton}
                />
            </div>
            :
            <OverlayTrigger
                key="bottom"
                placement="top"
                overlay={
                    <Tooltip id="rvd-refresh-tooltip-bottom">
                        {props.tooltipText}
                    </Tooltip>
                }
            >
                <div className='reminder-button'>
                    <GrooveButton
                        id="primry-btn-2"
                        type="outline"
                        colorClass="stat-alternate"
                        text="Send Reminder"
                        customClassName="send-reminder-button"
                        hasIcon={true}
                        iconAlignment="right"
                        iconSize="small"
                        iconName="bell"
                        iconColor="acn-purple"
                        size="large"
                        iconStyle="solid"
                        callbackFunction={props.onSendReminder}
                        isDisabled={props.isDisabledReminderButton}
                    />
                </div>
            </OverlayTrigger>
    )
}

export default CVMSendReminderButton