import React, { Component } from "react";
// import { history } from "../../store";
import { withRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Form,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import Utilities from "../../Utilities";
import { Link } from "react-router-dom";
import { setUserInformation } from "../../Actions/index";
import { connect } from "react-redux";
import { Header, Footer } from "../MasterLayout";

const _AccessRequest = (function() {
  class AccessRequestPresentational extends Component {
    render() {
      return <div />;
    }
  }

  class AccessRequestContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    goBack = (event) => {
      this.props.history.push("/");
    };

    render() {
      return <AccessRequestPresentational />;
    }
  }
  function mapDispatchToProps(dispatch) {
    return {
      SetUserInformation: (userInfo) => dispatch(setUserInformation(userInfo)),
    };
  }
  function mapStateToProps(state) {
    return {
      userInformation: state.userInformation,
    };
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccessRequestContainer);
})();

export default withRouter(_AccessRequest);
