import React, { Component } from 'react';

import { GrooveIconSvg, GrooveInput } from 'tfo-groove';
import CommentsIcon from '../../../Static/images/svg/CommentsIcon.svg';

const CommentaryList = (function () {
  class CommentaryListPresentational extends Component {
    render() {
      const commentList = this.props.commentList;
      return (
        <React.Fragment>
          {commentList.length > 0 ? (
            commentList.map((item, index) => {
              let fullDate = this.props.formatDate(item.CreatedTS);
              return (
                <div accessKey={index} class="comment-card">
                  <div>
                    <div>
                      <GrooveIconSvg
                        size="large"
                        name="user-circle"
                        iconStyle="regular"
                        iconColor="stat-alternate"
                        callbackFunction={() => ''}
                      />
                    </div>
                    <div>
                      <div>{item.CreatedUser}</div>
                      <div>
                        {item.IsEdited ? (
                          <span>
                            <span style={{ color: 'rgb(161, 0, 255)' }}>Edited</span> |{' '}
                          </span>
                        ) : (
                          ''
                        )}{' '}
                        {fullDate}
                      </div>
                    </div>
                  </div>
                  <div className="commentary-comment">
                    {/* <div>
                                            <span>{item.Comment}</span>
                                        </div> */}
                    <GrooveInput
                      customClassName="commentary-history-label"
                      id="comment"
                      name="comment"
                      placeholder="Comment"
                      type="text"
                      isAutocomplete="false"
                      inputValue={item.Comment}
                      maxLength={150}
                      isDisabled
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="commentary-no-comment">
              <img src={CommentsIcon} alt="CommentsIcon" />
              <div style={{ fontWeight: '600', paddingBottom: '10px', paddingTop: '3px' }}>
                No comment(s) available.
              </div>
            </div>
          )}
        </React.Fragment>
      );
    }
  }

  class CommentaryListContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return (
        <CommentaryListPresentational
          commentList={this.props.commentList}
          formatDate={this.props.formatDate}
        />
      );
    }
  }

  return CommentaryListContainer;
})();

export default CommentaryList;
