import React, { Component } from "react";
import { GrooveButton, GrooveTextBox } from "tfo-groove";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FetchCMVProjLeadDrpdown } from "../../../../Actions/Client.Action";
import {
  HasProperty,
  IsValid,
  GetType,
} from "../../../../Utilities/Validations";
import CVMModal from "../../../Shared/CVMModal/CVMModal";
import CVMMutiSelectDropdown from "../../../Shared/CVMMutiSelectDropdown/CVMMutiSelectDropdown";
import CVMApprovalRequestModal from "../../../Shared/CVMApprovalRequestModal/CVMApprovalRequestModal";

import "./ProjectDetails.css";
const ProjectAddModal = (() => {
  class ProjectAddModalPresentational extends Component {
    render() {
      const callback = this.props.formCallback;
      const form = this.props.formData;
      return (
        <div className="tab-section-header bodytext18-medium-midnight">
          <GrooveButton
            id="primry-btn-1"
            name="Solid Button Primary-Ops"
            isDisabled={false}
            hasIcon={false}
            type="solid"
            colorClass="stat-alternate"
            size="auto"
            text="Notify"
            callbackFunction={this.props.onOpenCloseModal}
            isDisabled={this.props.isDisabled}
          />
          <CVMModal
            title={"Notify Project Lead(s)"}
            show={this.props.show}
            onCloseModal={this.props.onOpenCloseModal}
            customclass={"notifyModal-dialog"}
            content={
              <React.Fragment>
                <div className="ApprovalRequestModal">
                  <div className="notifyModal-dropdown">
                    <CVMMutiSelectDropdown
                      id="leadList"
                      label={this.props.label}
                      placeholder="Enter ID"
                      onChange={callback}
                      options={this.props.projLeadList}
                      value={this.props.projLeadList}
                      isDisabled={true}
                    />
                  </div>
                  <div className="notifyModal-textarea">
                    <div className="comments">
                      <GrooveTextBox
                        id="notifyTextarea"
                        name="notifyTextarea"
                        type="text"
                        placeholder={`${(form.comment !== "") ? "" : "Enter "}Comment`}
                        rows="4"
                        callbackFunction={callback}
                        textValue={form.comment}
                        maxLength={255}
                      />
                    </div>
                    {/* <div className="textareaLabel">Comment</div>
                    <textarea
                      id="notifyTextarea"
                      name="notifyTextarea"
                      rows="4"
                      cols="73"
                      maxlength="255"
                      value={form.comment}
                      onChange={callback}
                    /> */}
                  </div>
                </div>
                <div className="notifyModal-buttons">
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Cancel"
                    callbackFunction={this.props.onOpenCloseModal}
                  />
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Notify"
                    callbackFunction={this.props.onClickNotify}
                  />
                </div>
              </React.Fragment>
            }
          />
        </div>
      );
    }
  }

  class ProjectAddModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        show: false,
        selectedLeadList: [],
        formData: {
          selectedLeadList: [],
          comment: "",
        },
        projLeadList: [],
      };
    }

    componentDidUpdate(prevProps) {
      if (JSON.stringify(this.props.projLeadList) !== JSON.stringify(prevProps.projLeadList)) {
        this.updateLeadList(this.props.projLeadList);
      }
    }
    updateLeadList = (projLeadList) => {
      try {
        let tempList = projLeadList.map((e) => {
          return {
            value: e,
            label: e,
            text: e,
          };
        });
        this.setState({ projLeadList: tempList });
      } catch (e) { }
    }

    onOpenCloseModal = () => {
      this.setState({ show: !this.state.show });
    }
    onClickNotify = () => {
      this.props.onClickNotify(this.state.formData);
      this.onOpenCloseModal();
    }
    formCallback = (e, id) => {
      id = HasProperty(e, "target") ? e.target.id : id;
      let tempForm = this.state.formData;

      switch (id) {
        case "leadList":
          tempForm.selectedLeadList = e;
          break;
        case "notifyTextarea":
          tempForm.comment = e.target.value;
          break;
        default:
          break;
      }
      this.setState({ formData: tempForm });
    }
    render() {
      return (
        <ProjectAddModalPresentational
          {...this.props}
          {...this.state}
          onOpenCloseModal={this.onOpenCloseModal}
          onClickNotify={this.onClickNotify}
          formCallback={this.formCallback}
        />
      );
    }
  }

  return ProjectAddModalContainer;
})();

export default ProjectAddModal
