import React, { Component } from "react";
import pptxgen from "pptxgenjs";
import * as htmlToImage from 'html-to-image';
import { trimFieldForFileName } from "../../../Utilities/Formatter";

require("./PPTGenerator.css");
const PPTGenerator = (function () {
  class PPTGeneratorPresentational extends Component {
    render() {
      return <span
        id='download'
        style={{ cursor: this.props.downloading ? 'progress' : 'pointer' }}
        onClick={this.props.downloading ? () => { } : this.props.generate}
      >
        {this.props.children}
      </span >
    }
  }

  class PPTGeneratorContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        downloading: false
      };

    }

    DataFormater = (n) => {
      var ranges = [
        { divider: 1e12, suffix: 'T' },
        { divider: 1e9, suffix: 'B' },
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'K' }
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          let tempRange = (n / ranges[i].divider).toString().split(".").length >= 2 ? (n / ranges[i].divider).toFixed(1)
            : (n / ranges[i].divider);
          return (tempRange).toString() + ranges[i].suffix;
        }
      }
      return n;
    }

    generate = () => {
      document.getElementById("wheel-chart-box").style.minWidth = "513px";
      document.getElementById("wheel-chart-box").style.maxWidth = "513px";
      document.getElementById("wheel-box").classList.add("taking-screenshot");
      const masterDim = {
        "Financial": 1,
        "Experience": 2,
        "Inclusion & Diversity": 5,
        "Sustainability": 4,
        "Custom": 3,
        "Talent": 6
      }

      let that = this;
      let dimensionList = this.props.data.sort((a, b) => (masterDim[a.CoreDimensionName] - masterDim[b.CoreDimensionName]));
      let dbProgDetails = this.props.dashboardProgramDetails;
      let dbClientDetails = this.props.dashboardClientDetails;
      let wheelSS = {};
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      let month = monthNames[new Date().getMonth()];
      let year = new Date().getFullYear();
      this.setState({ downloading: true })
      this.props.preDLFunction(() => {


        htmlToImage.toPng(document.getElementById("wheel-chart-box"))
          .then(function (dataUrl) {
            document.getElementById("wheel-chart-box").style.minWidth = "0";
            document.getElementById("wheel-chart-box").style.maxWidth = "none";
            document.getElementById("wheel-box").classList.remove("taking-screenshot");
            let pptx = new pptxgen();
            pptx.defineLayout({ name: 'Custom', width: 13.33, height: 7.51 });
            pptx.layout = 'Custom';
            pptx.defineSlideMaster({
              title: "MASTER_SLIDE",
              background: { color: "F8FAFA" },
              objects: [
                { image: { x: 0, y: 0, w: "100%", h: 0.57, path: that.props.image[1] } },
                { image: { x: 0.2, y: 0.12, w: 0.33, h: 0.33, path: that.props.image[2] } },
                { image: { x: 6.53, y: 1.09, h: 6.41, w: 6.8, path: that.props.image[0] } },
                { text: { text: "SynOps Value Meter", options: { x: 0.58, y: 0.1, h: 0.35, w: 2.26, fontSize: 15, color: { color: "FFFFFF" }, align: "left", valign: 'top', fontFace: "Graphik Medium" } } },
              ],
              // slideNumber: { x: 0.3, y: "90%", fontSize: 9 },
            });
            pptx.defineSlideMaster({
              title: "MASTER_SLIDE_NO_BG",
              background: { color: "F8FAFA" },
              objects: [
                { image: { x: 0, y: 0, w: "100%", h: 0.57, path: that.props.image[1] } },
                { image: { x: 0.2, y: 0.12, w: 0.33, h: 0.33, path: that.props.image[2] } },
                { text: { text: "SynOps Value Meter", options: { x: 0.58, y: 0.1, h: 0.35, w: 2.26, fontSize: 15, color: { color: "FFFFFF" }, align: "left", valign: 'top', fontFace: "Graphik Medium" } } },
                { line: { x: 0, y: 7.13, w: 13.33, h: 0, line: { color: "B200E2", width: 1.5, dashType: "solid" } } },
                { image: { x: 0, y: 7.13, w: 13.33, h: 0.38, path: that.props.image[3] } },
              ],
              // slideNumber: { x: 0.3, y: "90%", fontSize: 9 },
            });

            // let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });
            // slide.addText("SynOps Value Meter", { x: 0.53, y: 3.21, h: 0.51, w: 3.66, fontSize: 24, color: { color: "000000" }, align: "left", valign: 'top', fontFace: "Graphik Semibold" });
            // slide.addText(that.props.programName, { x: 0.53, y: 3.67, h: 0.51, w: 6.14, fontSize: 16, color: { color: "595959" }, align: "left", valign: 'top', fontFace: "Graphik Regular" });
            // slide.addText("Report", { x: 0.58, y: 4.2, h: 0.37, w: 3.66, fontSize: 16, color: { color: "000000" }, align: "left", valign: 'top', fontFace: "Graphik Regular" });
            // slide.addText(month + " " + year, { x: 0.58, y: 4.47, h: 0.3, w: 3.66, fontSize: 12, color: { color: "9F00FC" }, align: "left", valign: 'top', fontFace: "Graphik Regular" });

            // Dashboard Summary SS
            let slide2 = pptx.addSlide({ masterName: "MASTER_SLIDE_NO_BG" })
              .addImage({ data: dataUrl, x: 4.48, y: 1.17, h: 5.75, w: 4.37 })
              .addImage({ path: that.props.image[4], x: 6.15, y: 3.05, h: 1.03, w: 1.02 }); //wheel logo

            slide2._slideObjects = slide2._slideObjects.filter((x) => x.image !== that.props.image[4]);

            // Legends
            // Target
            slide2.addShape(pptx.shapes.ROUNDED_RECTANGLE,
              {
                x: 5.88,
                y: 6.23,
                h: 0.15,
                w: 0.15,
                fill: { color: "C89A15" },
                // line: { color: "D0CECE", width: 1, dashType: "solid" },
                rectRadius: 0.01
              })
            slide2.addText("Target", { x: 5.99, y: 6.18, h: 0.25, w: 0.7, fontSize: 9, color: { color: "000000" }, align: "left", valign: 'top', fontFace: "Graphik Regular" });

            // Delivered
            slide2.addShape(pptx.shapes.ROUNDED_RECTANGLE,
              {
                x: 6.77,
                y: 6.23,
                h: 0.15,
                w: 0.15,
                fill: { color: "0C7FAA" },
                // line: { color: "D0CECE", width: 1, dashType: "solid" },
                rectRadius: 0.01
              })
            slide2.addText("Delivered", { x: 6.88, y: 6.18, h: 0.25, w: 0.78, fontSize: 9, color: { color: "000000" }, align: "left", valign: 'top', fontFace: "Graphik Regular" });

            // Header
            const progName = that.props.programName.split("_")[0];
            const offeringName = that.props.programName.split("_")[1];

            slide2.addText(
              [
                { text: progName + "\n", options: { color: 'FFFFFF', fontFace: "Graphik Semibold" } },
                { text: offeringName, options: { color: 'FFFFFF' } },
              ],
              { x: 3.08, y: 0.03, h: 0.51, w: 8.03, fontSize: 12, color: { color: "FFFFFF" }, align: "left", valign: 'top', fontFace: "Graphik Regular" }
            );
            slide2.addText("Reporting Year FY" + that.props.reportingYear.substring(2, 4), { x: 11.11, y: 0.03, h: 0.3, w: 2.09, fontSize: 12, color: { color: "ffffff" }, align: "left", valign: 'top', fontFace: "Graphik Regular" });

            // Program Details
            slide2.addText(
              [
                { text: "Market Unit: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.marketUnit + "\n", options: { color: '7030A0' } },
                { text: "Last Update: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.lastUpdate + "\n", options: { color: '7030A0' } },
                { text: "Submitted by: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.submittedBy + "\n", options: { color: '7030A0' } },
                { text: "Account Lead: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.accountLead + "\n", options: { color: '7030A0' } },
                { text: "CX Lead: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.cxLead + "\n", options: { color: '7030A0' } },
              ],
              { x: 4.52, y: 0.62, h: 0.85, w: 2.34, fontSize: 8, color: { color: "7030A0" }, align: "left", valign: 'top', fontFace: "Graphik Regular" }
            );
            slide2.addText(
              [
                { text: "BT Lead: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.btLead + "\n", options: { color: '7030A0' } },
                { text: "I&I Lead: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.iandILead + "\n", options: { color: '7030A0' } },
                { text: "Client Contact: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbProgDetails.clientContact + "\n", options: { color: '7030A0' } },
                { text: "Client Stage: ", options: { color: '7030A0', fontFace: "Graphik Semibold" } },
                { text: dbClientDetails.Stage + " | " + dbClientDetails.StageName + "\n", options: { color: '7030A0' } },
              ],
              { x: 6.77, y: 0.62, h: 0.85, w: 2.11, fontSize: 8, color: { color: "7030A0" }, align: "left", valign: 'top', fontFace: "Graphik Regular" }
            );


            let leftPosHead = [0.3, 0.71];
            let leftPosBody = [0.19, 0.86];
            let rightPosHead = [9.07, 0.71];
            let rightPosBody = [8.96, 0.86];
            // let distanceUpDown = 0; 
            //Dimension Data
            dimensionList.map((d, i) => {
              if (d.CoreDimensionID > 0) {

                let dimCountWithContent = 0;
                let even = i % 2 === 0;
                dimensionList.map((x, y) => {
                  let test = y % 2 === 0;
                  if (test === even && x.CoreDimensionID > 0) {
                    dimCountWithContent++;
                  }
                });
                let sCount = 0;
                let boiCount = 0;
                let height = 0;
                let comCount = 0;
                d.Subdimesions.map(sItem => {

                  if (sItem.SubDimensionID > 0) {
                    sCount++
                  }
                  if (sItem.BIOSlIST.length > 0) {
                    // boiCount += sItem.BIOSlIST.length;

                    sItem.BIOSlIST.map(item => {
                      let boiTemp = item.BOIName + " ";
                      let uomTemp = item.UOM;
                      let targetTemp = item.IdentifiedValue !== null && item.IdentifiedValue !== undefined ? "(" + that.DataFormater(item.IdentifiedValue) + " " + uomTemp + ") " : "(-) ";
                      let identTemp = item.DeliveredValue !== null && item.DeliveredValue !== undefined ? "(" + that.DataFormater(item.DeliveredValue) + " " + uomTemp + ")" : "(-)";
                      let boiLine = boiTemp + targetTemp + identTemp;
                      if (boiLine !== "") {
                        boiCount = boiCount + Math.ceil(boiLine.length / 79);
                      }

                      let com = item.ProgramCommentary === null || item.ProgramCommentary === undefined ? "" : item.ProgramCommentary;
                      if (com !== "") {
                        comCount = comCount + Math.ceil(com.length / 80);
                      }
                    })
                  }
                })
                // if (sCount > 1 || boiCount > 1) {
                height = .35 + (sCount * .2);
                height = height + (0.14 * boiCount);
                // } else {
                //   height = 0.8
                // }
                height = height + (0.12 * comCount);
                //let height = dimCountWithContent === 3 ? 1.88 : dimCountWithContent === 2 ? 2.64 : .5;
                // distanceUpDown = 0.14 + height + 0.8;                             
                slide2.addShape(pptx.shapes.ROUNDED_RECTANGLE,
                  {
                    x: i % 2 !== 0 ? rightPosBody[0] : leftPosBody[0],
                    y: i % 2 !== 0 ? rightPosBody[1] : leftPosBody[1],
                    h: height,
                    w: 4.18,
                    fill: { color: "FFFFFF" },
                    line: { color: "D0CECE", width: 1, dashType: "solid" },
                    rectRadius: 0.05
                  })
                slide2.addText(d.CoreDimensionName,
                  {
                    shape: pptx.shapes.ROUNDED_RECTANGLE,
                    rectRadius: 0.05,
                    x: i % 2 !== 0 ? rightPosHead[0] : leftPosHead[0],
                    y: i % 2 !== 0 ? rightPosHead[1] : leftPosHead[1],
                    w: 2.25,
                    h: 0.27,
                    fill: { color: d.CoreDimensionColor },
                    align: "left", fontSize: 12,
                    fontFace: "Graphik Medium",
                    color: "FFFFFF"
                  })

                let vHeight = i % 2 !== 0 ? rightPosBody[1] + 0.17 : leftPosBody[1] + 0.17;
                let hHeight = i % 2 !== 0 ? rightPosBody[0] + 0.06 : leftPosBody[0] + 0.06;
                d.Subdimesions.map(s => {
                  if (s.SubDimensionID > 0) {
                    slide2.addText(s.SubDimensionName + ":",
                      {
                        y: vHeight,
                        x: hHeight, h: 0.18, w: 4.18,
                        fontSize: 8, color: { color: "85449A" },
                        align: "left", valign: 'top', fontFace: "Graphik Semibold"
                      });
                    vHeight = vHeight + 0.18;
                    let bullets = [];
                    if (s.BIOSlIST.length > 0) {
                      let boxHeight = 0;
                      let shouldAddSpace = false;
                      s.BIOSlIST.map((b, i) => {
                        let boi = b.BOIName + " ";
                        let uom = b.UOM;
                        let target = b.IdentifiedValue !== null && b.IdentifiedValue !== undefined ? "(" + that.DataFormater(b.IdentifiedValue) + " " + uom + ") " : "(-) ";
                        let ident = b.DeliveredValue !== null && b.DeliveredValue !== undefined ? "(" + that.DataFormater(b.DeliveredValue) + " " + uom + ")" : "(-)";
                        let commentary = b.ProgramCommentary === null || b.ProgramCommentary === undefined ? "" : b.ProgramCommentary;

                        const isLast = i === s.BIOSlIST.length - 1;
                        bullets.push(
                          { text: boi, options: { fontSize: 7, bullet: { indent: 10 }, color: '000000' } },
                          { text: target, options: { fontSize: 7, color: 'C89A15' } },
                          { text: isLast && commentary === "" ? ident : ident + "\n", options: { fontSize: 7, color: '0C7FAA' } }
                        )
                        if (commentary !== "") {
                          let spaceToAdd = Math.ceil(commentary.length / 80);
                          boxHeight = boxHeight + (0.12 * spaceToAdd); //previously .18
                          bullets.push(
                            { text: " ", options: { fontSize: 7, color: 'FFFFFF', fontFace: "Graphik Regular" } },
                            { text: isLast ? commentary : commentary + "\n", options: { fontSize: 7, color: '000000', fontFace: "Graphik Regular" } }
                          )
                        }
                        boxHeight = boxHeight + (0.14 * Math.ceil((boi.length + uom.length + target.length + ident.length) / 79));
                        // boxHeight = boxHeight + 0.14; //previously .18                        
                      });
                      if (shouldAddSpace) {
                        boxHeight = boxHeight + 0.14; //previously .18
                      }
                      slide2.addText(
                        bullets,
                        { y: vHeight, x: hHeight, h: boxHeight, w: 4.18, align: "left", valign: 'top', fontFace: "Graphik Semibold" }
                      );
                      vHeight = vHeight + boxHeight + 0.05;
                    }
                  }
                });

                if (i % 2 !== 0) {
                  rightPosHead[1] = rightPosBody[1] + height + 0.08;
                  rightPosBody[1] = rightPosHead[1] + 0.14;
                }
                else {
                  leftPosHead[1] = leftPosBody[1] + height + 0.08;
                  leftPosBody[1] = leftPosHead[1] + 0.14;
                }
              }
              else {
                slide2.addText(d.CoreDimensionName,
                  {
                    shape: pptx.shapes.ROUNDED_RECTANGLE,
                    rectRadius: 0.05,
                    x: i % 2 !== 0 ? rightPosHead[0] : leftPosHead[0],
                    y: i % 2 !== 0 ? rightPosHead[1] : leftPosHead[1],
                    w: 2.25,
                    h: 0.27,
                    fill: { color: "B2B2B1" },
                    align: "left",
                    fontSize: 12,
                    fontFace: "Graphik Medium",
                    color: "FFFFFF"
                  })
                if (i % 2 !== 0) {
                  rightPosHead[1] = rightPosHead[1] + 0.35;
                  rightPosBody[1] = rightPosHead[1] + 0.14;
                }
                else {
                  leftPosHead[1] = leftPosHead[1] + 0.35;
                  leftPosBody[1] = leftPosHead[1] + 0.14;
                }
              }
            })

            let fileNameAppend = "_Wheel Dashboard.pptx";
            let programName = dbProgDetails.clientProgramName;
            let lastIndex = 200 - fileNameAppend.length;
            let fileName = trimFieldForFileName(programName, lastIndex);
            let downloadFileName = `${fileName}${fileNameAppend}`;

            pptx.writeFile({ fileName: downloadFileName });
            if (that.props.postDLFunction !== undefined) that.props.postDLFunction()
            that.setState({ downloading: false })
          })
          .catch(function (error) {
            document.getElementById("wheel-chart-box").style.minWidth = "0";
            document.getElementById("wheel-chart-box").style.maxWidth = "none";
            document.getElementById("wheel-box").classList.remove("taking-screenshot");
            if (that.props.postDLFunctionErr !== undefined) that.props.postDLFunctionErr()
            that.setState({ downloading: false })
            console.error('oops, something went wrong!', error);
          });
      })

    }
    render() {
      return (
        <PPTGeneratorPresentational generate={this.generate} programName={this.props.programName} reportingYear={this.props.reportingYear} downloading={this.state.downloading} data={this.props.data} dimColors={this.props.dimColors}>
          {this.props.children}
        </PPTGeneratorPresentational>
      );
    }
  }

  return PPTGeneratorContainer;
})();

export default PPTGenerator;
