import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveRadioButton } from 'tfo-groove';

//css
import './ConfigureProgramStyles.css';

const ApproveRejectRadioButton = (function () {
  class ApproveRejectRadioButtonPresentational extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return (
        <div
          className={
            this.props.mainSelectedProject.projectDetails.assignedToRoleId === 8 ||
              (this.props.mainSelectedProject.projectDetails.assignedToRoleId === 16 && this.props.isExistingIO)
              ? this.props.boiUOM === 'USD'
                ? this.props.dimensionName.toLowerCase() === 'financial'
                  ? '' //enabled
                  : 'io-validation' //disabled
                : 'io-validation'
              : ''
          }
        >
          <GrooveRadioButton
            callbackFunction={(e) =>
              this.props.radioButtonOnClick(e, {
                dimensionIndex: this.props.dIndex,
                subDimensionIndex: this.props.SDIndex,
                boiIndex: this.props.boiIndex,
              })
            }
            radioBtnValues={this.props.radioBtnValues}
            radioBtnTheme="Light"
            customClassName="approve-reject-radio-button"
          />
        </div>
      );
    }
  }

  class ApproveRejectRadioButtonContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        radioBtnValues: [
          {
            radioBtnLabel: 'Approve',
            radioBtnValue: true,
            defaultChecked:
              this.props.boiStatus === 'Approve' || this.props.boiStatus === '' ? true : false,
            name: 'radio-select',
            id: 'radio1-' + this.props.boiID,
          },
          {
            radioBtnLabel: 'Reject',
            radioBtnValue: false,
            defaultChecked: this.props.boiStatus === 'Rejected' ? true : false,
            name: 'radio-select',
            id: 'radio2-' + this.props.boiID,
          },
        ],
      };
    }

    componentDidUpdate(nextProps) {
      if (nextProps.boiStatus !== this.props.boiStatus) {
        this.setState({
          radioBtnValues: [
            {
              radioBtnLabel: 'Approve',
              radioBtnValue: true,
              defaultChecked:
                this.props.boiStatus === 'Approve' ||
                  this.props.boiStatus === '' ||
                  this.props.boiStatus === null
                  ? true
                  : false,
              name: 'radio-select',
              id: 'radio1-' + this.props.boiID,
            },
            {
              radioBtnLabel: 'Reject',
              radioBtnValue: false,
              defaultChecked: this.props.boiStatus === 'Rejected' ? true : false,
              name: 'radio-select',
              id: 'radio2-' + this.props.boiID,
            },
          ],
        });
      }
    }

    radioButtonOnClick = (e, indexes) => {
      try {
        this.props.isRejected(e, this.props.userRoleID, indexes);
        if (e.label === 'Reject') {
          // window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
          // document.body.style.overflowY = 'hidden'
        }
      } catch (err) {
        console.log(err);
      }
    };

    render() {
      const { mainSelectedProject } = this.props.ConfigureProgramData;
      return (
        <ApproveRejectRadioButtonPresentational
          dIndex={this.props.dIndex}
          SDIndex={this.props.SDIndex}
          boiIndex={this.props.boiIndex}
          radioBtnValues={this.state.radioBtnValues}
          radioButtonOnClick={this.radioButtonOnClick}
          dimensionName={this.props.dimensionName}
          boiUOM={this.props.boiUOM}
          currentUserRoleID={this.props.currentUserRoleID}
          mainSelectedProject={mainSelectedProject}
          isExistingIO={this.props.isExistingIO}
        />
      );
    }
  }

  return ApproveRejectRadioButtonContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(ApproveRejectRadioButton);
