import {
    GET_APP_Modules,
    SET_PATH
} from '../Constants'

const initialState = {
    modules: [],
    path: "",
}

export const HomeData = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_APP_Modules:
            return {
                ...state,
                modules: action.modules
            }
        case SET_PATH:
            return {
                ...state,
                path: action.path
            }
        default:
            return state
    }
}