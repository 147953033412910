import React, { Component } from "react";
import { GrooveIconSvg } from "tfo-groove";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
// import { history } from "../../../store";
import { withRouter } from "react-router-dom";
import { HasProperty } from '../../../Utilities/Validations';
import { FormDateDDMMMYYY/* , TryGetObjValueAlt */ } from "../../../Utilities/Formatter";
// import { GenRVDLink } from '../PPCUtil'
import ApprovedBOIEditWarnModal from './ApprovedBOIEditWarnModal';
import "./ALATable.css";

import ProgramApprovalHistoryModal from './ProgramApprovalHistoryModal';

const ALATable = (function () {
  const statuses = {
    aa: 'Awaiting Approval'.toLowerCase(),
    aaMod: 'Awaiting Approval – Modified'.toLowerCase(),
    rejected: 'Rejected'.toLowerCase(),
    approved: 'Approved'.toLowerCase(),
    configAligned: 'Configured & Aligned'.toLowerCase(),
    configYetToAligned: 'Configured - yet to be aligned'.toLowerCase(),
    completed: 'Completed'.toLowerCase(),
    inProgress: 'Value Identification In progress'.toLowerCase(),
  }
  class ALATablePresentational extends Component {
    render() {
      const props = this.props
      const arsDetails = props.arsDetails
      let status = arsDetails.length > 0 && arsDetails[arsDetails.length - 1].status.toLowerCase();

      const pName = props.type === "program" ? "Program Name" : "Project Name";
      const lead1 = props.type === "program" ? "Program Creator" : "Project Creator";
      let headers = [pName, lead1, 'Approver', 'Submitted Date', 'Status']
      /* let hasRedirectLink = props.type === 'project' && !props.PnPComponents.project.stageTwoFields &&
        arsDetails.some(e => e.status.toLowerCase() === statuses.approved) && status !== statuses.approved */

      if (!props.isReadOnly && props.type === 'program' &&
        (
          !props.PnPComponents.program.stageTwoFields ||
          ([statuses.aa, statuses.aaMod, statuses.rejected].indexOf(status) > -1 && props.PnPComponents.program.stageTwoApprv)
          || [statuses.approved, statuses.configAligned, statuses.configYetToAligned, statuses.inProgress].indexOf(status) > -1
        )
      ) {
        headers.push('Action')
      } else if (!props.isReadOnly && props.type === 'project' &&
        (
          !props.PnPComponents.project.stageTwoFields ||
          ([statuses.aa, statuses.aaMod, statuses.rejected].indexOf(status) > -1 && props.PnPComponents.project.stageTwoApprv)
          || [statuses.approved, statuses.configAligned, statuses.configYetToAligned].indexOf(status) > -1
        )
      ) {
        headers.push('Action')
      }

      let css = "";
      return (
        <React.Fragment>
          <div className="header18-semibold-midnight table-title">
            Approval Request Status
          </div>
          {
            props.type === 'program' ? (
              <ProgramApprovalHistoryModal
                arsDetails={arsDetails}
                statuses={statuses}
              />
            ) : ""
          }
          <div className="ALATable">
            <div className="dimension-table-header">
              <div className="dimension-table-row">
                {headers.map(name => <div>{name}</div>)}
              </div>
            </div>
            <div className="dimension-table-body">
              {
                arsDetails.slice(0).reverse().slice(0,1).map((item, i) => {
                  css = [statuses.completed, statuses.approved].indexOf(item.status.toLowerCase()) > -1 ? "status-completed"
                    : (item.status.toLowerCase() === statuses.rejected ? "status-rejected"
                      : [statuses.inProgress].indexOf(item.status.toLowerCase()) > -1 ? "status-notStarted"
                        : "status-inProgress");

                  return (
                    HasProperty(item, 'pName') ?
                      <div className="dimension-table-row">
                        <div>{item.pName}</div>
                        <div>{item.lead1.split("@")[0]}</div>
                        <div>{item.lead2 !== null ? item.lead2.split("@")[0] : ''}</div>
                        <div>{FormDateDDMMMYYY(item.submittedDate)}</div>
                        <div>
                          <div className={"program-status " + css}>
                            {item.status}
                          </div>
                        </div>
                        {
                          headers.length === 6 && (item.status.toLowerCase() === statuses.approved || item.status.toLowerCase() === statuses.inProgress) ?
                            <ApprovedBOIEditWarnModal
                              type={props.type}
                              disabledEdit={arsDetails.length > 1 && i !== 0 || props.editIconDisable}
                            />
                            :
                            headers.length === 6 &&
                            <div className={props.editIconDisable || this.props.editApproved ? "disabled-editIcon" : ""}>
                              <GrooveIconSvg
                                id="calendarIcon"
                                customClassName="calendar-icon-dropdown"
                                size="large"
                                name="edit"
                                iconStyle="solid"
                                iconColor="stat-alternate"
                                callbackFunction={() => {
                                  if (!props.editIconDisable) props.onClickEdit(item.status.toLowerCase())
                                }}
                                isDisabled={props.editIconDisable || this.props.editApproved}
                              />
                            </div>
                        }
                      </div>
                      :
                      < div className="empty-dimension-container" >
                        <div className="empty-program-container bodytext18-regular-slate">
                          <span className="bodytext24-medium-slate">
                            No Approval Request Status
                          </span>
                          Please select program.
                        </div>
                      </div >
                  )
                })

              }
            </div>
          </div>
        </React.Fragment >
      );
    }
  }

  class ALATableContainer extends Component {
    onClickEdit = (status) => {
      try {
        let type = this.props.type !== undefined ? this.props.type : "program";
        let url = `/ProgramProjectConfig/${type === "program" ? 'ProgramAccountLeadershipApproval/' : 'ProjectAccountLeadershipApproval/'}`
        this.props.checkForInvalidEmailFirst();
        switch (status) {
          case statuses.rejected:
            url = url.concat('rejectedboi')
            break;
          case (statuses.aa):
          case (statuses.aaMod):
            url = url.concat('awaitingappboi')
            break
          default:
            break;
        }
        if (!String(window.location.href).toLowerCase().includes(url.toLowerCase())) this.props.history.push(url)
      }
      catch (e) {
        console.log(e)
      }
    }

    render() {
      const { PnPComponents } = this.props.AccessValidationData;
      const { isSuperAdmin } = this.props.userInformation;
      return (
        <ALATablePresentational
          headers={this.props.headers}
          type={this.props.type !== undefined ? this.props.type : "program"}
          onClickEdit={this.onClickEdit}
          isSuperAdmin={isSuperAdmin}
          redirectLink={this.props.redirectLink}

          //reducers
          arsDetails={this.props.arsDetails}
          PnPComponents={PnPComponents}
          isReadOnly={this.props.isReadOnly}
          editIconDisable={this.props.editIconDisable}
          editApproved={this.props.SharedData.editApproved}
        />
      );
    }
  }

  return ALATableContainer;
})();

export default connect(
  state => {
    return {
      clientID: state.SharedData.selectedClient.value,
      userInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
      SharedData: state.SharedData
    }
  },
  dispatch => bindActionCreators(Object.assign({}, {}), dispatch)
)(withRouter(ALATable));
