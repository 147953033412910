import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row, Col } from "react-bootstrap";
// import { history } from "../../store";
import { withRouter } from "react-router-dom";
//actions
import { VAL_DELIV_LANDING } from '../../Constants/Modules'
import {
  getClientList,
  onSearch,
  showClientDropdown,
  setSelectedClient,
  setCurrentUserDetails,
} from "../../Actions/Shared.Action";

import {
  getRDVClientDetails,
  getLandingProgramProjectsList,
  setProgramSelected,
  onSelectCreationYearOptions,
  getProjectDataEntryTableData,
  getProjectDetails,
  setDeliveryCenterSelected,
  clearProgramProjectList,
  getProgramDropdownData,
  getLandingProjectsList,
  setProjectSelected
} from "../../Actions/DataIngestion.Action";

import {
  clearMainSelectedDetails,
  getProgramProjectCreationYear,
  getMappedStakeHolders,
  clearRVDCreationYearList
} from "../../Actions/ConfigureProgram.Action";

import {
  onClickFavorite,
} from "../../Actions/ClientConfiguration.Action";

import {
  InitRVD
} from "../../Actions/AccessValidation.Action"

//components
import { TryGetObjValueAlt } from "../../Utilities/Formatter";
import { CheckModuleAccess, DeleteCallbackURL, DeleteDistiURL, DeleteReferrer, LogUserSiteAction } from '../../Utilities/AccessManagementUtil';
import { HasProperty, HasValue, IsArray, IsValid, IsValidStr, IsObjEmpty } from "../../Utilities/Validations";
import DataIngestionLanding from "./DataIngestionLanding";
import CVMModal from "../Shared/CVMModal/CVMModal";
import CVMSelectClientModal from "../Shared/CVMSelectClientModal/CVMSelectClientModal";

// icons
import addfiles from "./../../Static/images/360cvm/AddFilesIcon.svg";

//css
import "./DataIngestionStyles.css";

const DataIngestion = (function () {
  class DataIngestionPresentational extends Component {
    render() {
      return (
        <>
          {
            this.props.selectedClient.value !== undefined && this.props.selectedClient.value !== 0 ?
              this.props.RVDComponents.landingPage ?
                <Row className="access_denied_container">
                  <Col md={12} xs={12}>
                    <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                    <div className="access_denied_content">
                      <h1>Access Denied</h1>
                      <p>You don't have the necessary user privileges to view the client that you are attempting to access.</p>
                    </div>
                  </Col>
                </Row>
                :
                <DataIngestionLanding />
              :
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          }

          <CVMModal
            title={"Select Client"}
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            size={this.props.size}
            content={
              <CVMSelectClientModal
                modalActiveKey={this.props.modalActiveKey}
                onChangeModalTab={this.props.onChangeModalTab}
                clientList={this.props.clientList}
                onClickFavorite={this.props.onClickFavorite}
                onSearch={this.props.onSearch}
                filterKeyword={this.props.filterKeyword}
                onSelectClient={this.props.onSelectClient}
                selectedTab={this.props.selectedTab}
                disableClientTile={this.props.disableClientTile}
              />
            }
          />
          {
            !this.props.RVDComponents.landingPage && this.props.selectedClient.value !== undefined ?
              IsValid(this.props.selectedProgramDDValue.value) ?
                this.props.showProjectListRoller ?
                  ""
                  :
                  Object.keys(this.props.programList).length <= 0 ?
                    Object.keys(this.props.clientDetails).length > 0 ?
                      <Col md={12} className="data-ingestion-placeholder">
                        <img src={addfiles} alt="select client" />
                        <span>Client does not have programs</span>
                        Please select a client with programs to access Data Ingestion Module
                      </Col>
                      :
                      <Col md={12} className="data-ingestion-placeholder">
                        <img src={addfiles} alt="select client" />
                        <span>Select Client</span>
                        Please select a client with programs to access Data Ingestion Module
                      </Col>
                    :
                    ""
                :
                <Col md={12} className="data-ingestion-placeholder">
                  <img src={addfiles} alt="select client" />
                  <span>No Program Selected</span>
                  Please select a program from the program list.
                </Col>
              :
              ""
          }
        </>
      );
    }
  }

  class DataIngestionContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modalActiveKey: 1,
        show: false,
        size: "md",
        selectedTab: 0,
        accessDenied: false,
        isLoadingInitData: true,
        disableClientTile: false,
      };
    }

    async componentDidMount() {
      //RUN THIS CODE IF URL HAS CLIENT
      // let tClient = window.location.pathname.split("/")[2]
      let tClient = this.props.SharedData.selectedClient.label || window.location.pathname.split('/')[2];
      let loc = this.props.history.location.state;
      let selProg = this.props.DataIngestionData.selectedProgramDDValue;
      let progOptions = this.props.DataIngestionData.programOptions;
      const valProgramID = IsValid(window.location.pathname.split("/")[3]);
      const valProjectID = IsValid(window.location.pathname.split("/")[4]);

      DeleteCallbackURL();
      DeleteReferrer();
      DeleteDistiURL();

      if (IsValidStr(tClient)) {
        if (!window.location.pathname.toLowerCase().includes('/dataingestion')) return;

        //routed from URL
        if (valProgramID) {
          this.loadRVDFromURL(tClient);
          return;
        }

        this.props.showClientDropdown();

        const client = this.props.SharedData.selectedClient.value
        if (document.visibilityState === 'visible') LogUserSiteAction({
          module: VAL_DELIV_LANDING,
          ShouldLogPPCID: true,
          clientID: HasValue(client) ? client : 0,
          ClientProgramID: null,
          ClientProjectID: null,
          progStatus: null,
          projStatus: null
        })

        // routed from RVD
        if (!HasProperty(loc, 'prevPath') && IsValid(selProg.value)) {
          let b = 'aaa';
          await this.props.getLandingProjectsList();
          this.setState({ isLoadingInitData: false });
          return;
        }

        //routed from another module
        if (HasProperty(loc, 'prevPath')) {
          this.props.getRDVClientDetails();
          this.props.setProgramSelected({});
          this.props.clearRVDCreationYearList();
          await this.props.clearProgramProjectList();
          await this.props.getProgramDropdownData();
          await this.props.getProgramProjectCreationYear(valProgramID.value);
          await this.props.onSelectCreationYearOptions(this.props.DataIngestionData.creationYearList)
          progOptions = this.props.DataIngestionData.programOptions;
          let tSelProg = progOptions.length === 1 ? progOptions[0] : {};
          await this.props.setProgramSelected(tSelProg);
          if (!IsObjEmpty(tSelProg)) await this.props.getLandingProjectsList();

          this.checkUserAccessOnClientSelect(this.props.userInformation.currentUserRoleID)
          this.setState({ isLoadingInitData: false })
        }
        else {
          //from landing page
          this.props.clearProgramProjectList();
          this.props.clearRVDCreationYearList();
          await this.props.getClientList()
          await this.displayModalOnLoad()
          if (this.props.userInformation.currentUserRoleID !== "") {
            this.checkUserAccessOnClientSelect(this.props.userInformation.currentUserRoleID)
          }
          this.setState({
            selectedTab: this.props.SharedData.clientList.filter((x) => x.favorite).length > 0 ? 1 : 0,
            isLoadingInitData: false
          })
        }
      }
      else {
        //from landing page
        this.props.clearProgramProjectList();
        this.props.showClientDropdown();
        this.props.clearRVDCreationYearList();

        await this.props.getClientList()
        await this.displayModalOnLoad()
        if (this.props.userInformation.currentUserRoleID !== "") {
          this.checkUserAccessOnClientSelect(this.props.userInformation.currentUserRoleID)
        }
        this.setState({
          selectedTab: this.props.SharedData.clientList.filter((x) => x.favorite).length > 0 ? 1 : 0,
          isLoadingInitData: false
        })
      }

    }


    componentDidUpdate = async (prevProps) => {
      if (prevProps.SharedData.selectedClient.value !== this.props.SharedData.selectedClient.value
        && !IsValid(window.location.pathname.split("/")[2]) && IsValid(prevProps.SharedData.selectedClient.value)) {
        this.props.clearProgramProjectList();
        this.checkUserAccessOnClientSelect(this.props.userInformation.currentUserRoleID);
        await this.props.getProgramDropdownData();
        let tSelProg = this.props.DataIngestionData.programOptions.length === 1 ?
          this.props.DataIngestionData.programOptions[0] : {};
        this.props.setProgramSelected(tSelProg);
        if (!IsObjEmpty(tSelProg)) await this.props.getLandingProjectsList();
      }
    }

    loadRVDFromURL = async (tClient) => {
      //GET & SET URL DATA
      tClient = decodeURIComponent(tClient)
      const programID = window.location.pathname.split("/")[3] || ''
      const projectID = window.location.pathname.split("/")[4] || ''

      //VERIFY CLIENT
      const clientList = await this.props.getClientList()
      const client = clientList.find(e => { return tClient.toLowerCase().trim() === e.label.toLowerCase().trim() })

      //IF CLIENT IS VALID
      if (HasValue(client)) {
        //SET CLIENT DETAILS
        this.props.setSelectedClient(client)
        await this.props.setCurrentUserDetails()
        this.props.getRDVClientDetails()
        await this.props.getProgramProjectCreationYear(programID)
        await this.props.onSelectCreationYearOptions(this.props.DataIngestionData.creationYearList)

        //VERIFY PROGRAM
        let prog = await this.props.getProgramDropdownData();
        prog = prog.filter(e => { return String(e.value) === programID })

        //IF PROGRAM IS VALID
        if (IsArray(prog) && prog.length > 0) {
          prog = prog[0]
          await this.props.setProgramSelected(prog);
          await this.props.getMappedStakeHolders(prog.value, "Value Delivered_Landing page")
          await this.props.getLandingProjectsList();

          //IF PROJECT IS VALID
          if (IsValid(projectID)) {
            let project = this.props.DataIngestionData.programList.filter((proj) => String(proj.projectID) === projectID);
            project = IsValid(project[0]) ? project[0] : {};
            await this.props.setProjectSelected(project);

            let selProj = this.props.ConfigureProgramData.mainSelectedProject;

            const prog = {
              programID: selProj.programID,
              programName: selProj.programName,
              creationYear: this.props.DataIngestionData.creationYear,
            };

            const proj = {
              actionOwner: project.actionOwner,
              assignedTo: project.actionOwner,
              assignedToRoleId: project.assignedToRoleId,
              clientProjectID: project.projectID,
              clientProjectName: project.clientProjectName,
              createdBy: project.createdBy,
              creationDate: project.creationDate,
              deliveryCenterID: project.deliveryCenterID,
              deliveryCenterName: project.deliveryCenterName,
              deliveryLocations: project.deliveryLocations,
              lastReportingDate: project.creationDate, //
              pdeModifiedDate: project.pdeModifiedDate,
              ID: project.projectOverallStatusID, //
              projectID: project.projectID,
              reportedBy: project.reportedBy,
              reportedMonth: project.reportedMonth,
              status: project.status,
              statusID: project.statusID,
            }

            // await this.props.setProjectSelected(projData);

            await this.props.getProjectDetails(prog, proj)
            this.props.showClientDropdown();
            this.setState({ isLoadingInitData: false })
            this.props.history.push('/DataIngestion/ConfigureProgram');
            return;
          }
          //VALID PROGRAM BUT PROJECT IS NOT
          else {
            this.props.showClientDropdown()
            this.setState({ isLoadingInitData: false })
            this.props.clearMainSelectedDetails()
            await this.props.setProgramSelected(prog);
            this.props.history.push("/DataIngestion")
            this.checkUserAccessOnClientSelect(this.props.userInformation.currentUserRoleID)
            return
          }
        }
        //VALID CLIENT BUT PROGRAM IS NOT
        else {
          this.setState({ isLoadingInitData: false })
          this.props.showClientDropdown()
          this.props.history.push('/DataIngestion')
          this.checkUserAccessOnClientSelect(this.props.userInformation.currentUserRoleID)
          return;
        }

      }
      this.setState({ isLoadingInitData: false })
      this.props.showClientDropdown()
      this.props.history.push('/DataIngestion')
      return

    }

    checkUserAccessOnClientSelect = (currentRole) => {
      try {
        this.props.InitRVD();
      }
      catch (err) {
        console.log(err)
      }
    }

    displayModalOnLoad = async () => {
      const path = window.location.pathname.toLowerCase()
      if (
        path === "/dataingestion/configureprogram" ||
        path === "/dataingestion"
      ) {
        if (Object.keys(this.props.SharedData.selectedClient).length > 0) {
          if (
            this.props.SharedData.selectedClient.label === "" ||
            this.props.SharedData.selectedClient.label === undefined
          ) {
            this.setState({
              show: true
            })
          } else {
            this.setState({
              show: false
            })
          }
        } else {
          this.setState({
            show: !this.props.SharedData.isLeftPanel
          })
        }
      } else {
        this.setState({
          show: !this.props.SharedData.isLeftPanel
        })
      }
    }

    onChangeModalTab = (event) => {
      let selectedActiveKey = event;
      this.setState({
        ...this.state,
        modalActiveKey: selectedActiveKey,
      });
    };
    onCloseModal = () => {
      this.setState({
        ...this.state,
        show: !this.state.show,
      }, () => {
        this.props.history.push("/")
        this.props.showClientDropdown();
      });
    };
    onSearch = (event) => {
      const value = event.target.value

      this.setState({
        filterKeyword: value
      })
    }
    onSelectClient = (item, show) => {
      this.setState({
        isLoadingInitData: true,
        disableClientTile: true,
        show: show,
      }, async () => {
        await this.props.setSelectedClient(item);
        await this.props.clearProgramProjectList();
        await this.props.getProgramDropdownData();
        let tSelProg = this.props.DataIngestionData.programOptions.length === 1 ?
          this.props.DataIngestionData.programOptions[0] : {};
        await this.props.setProgramSelected(tSelProg);
        if (!IsObjEmpty(tSelProg)) await this.props.getLandingProjectsList();



        await this.props.setCurrentUserDetails();
        await this.props.getRDVClientDetails();
        // await this.props.getProgramProjectCreationYear();
        await this.props.onSelectCreationYearOptions(this.props.DataIngestionData.creationYearList)
        setTimeout(async () => {
          // await this.props.getLandingProgramProjectsList();
          this.setState({
            isLoadingInitData: false,
            disableClientTile: false,
          })
        }, 1000);
        this.checkUserAccessOnClientSelect(this.props.userInformation.currentUserRoleID);
      })
      LogUserSiteAction({
        module: VAL_DELIV_LANDING,
        ShouldLogPPCID: true,
        clientID: item.value,
        ClientProgramID: null,
        ClientProjectID: null,
        progStatus: null,
        projStatus: null
      });
    }

    render() {
      const {
        filterKeyword,
        clientDetails
      } = this.props.ClientConfigurationData

      const {
        clientList,
        selectedClient,
      } = this.props.SharedData

      const {
        programList,
        selectedProgramDDValue,
        showProjectListRoller,
      } = this.props.DataIngestionData

      const {
        RVDComponents
      } = this.props.AccessValidationData

      const {
        onClickFavorite,
        onSearch,
        getProgramProjectCreationYear,
        getLandingProgramProjectsList,
        onSelectCreationYearOptions,
      } = this.props

      const { modules } = this.props.HomeData;
      return (modules.length > 0 && !this.state.isLoadingInitData ?
        CheckModuleAccess(modules) ?
          <DataIngestionPresentational
            modalActiveKey={this.state.modalActiveKey}
            onChangeModalTab={this.onChangeModalTab}
            show={this.state.show}
            size={this.state.size}
            onCloseModal={this.onCloseModal}
            clientList={clientList}
            onClickFavorite={onClickFavorite}
            onSearch={onSearch}
            filterKeyword={filterKeyword}
            onSelectClient={this.onSelectClient}
            selectedTab={this.state.selectedTab}
            clientDetails={clientDetails}
            programList={programList}
            getProgramProjectCreationYear={getProgramProjectCreationYear}
            getLandingProgramProjectsList={getLandingProgramProjectsList}
            onSelectCreationYearOptions={onSelectCreationYearOptions}
            accessDenied={this.state.accessDenied}
            RVDComponents={RVDComponents}
            selectedClient={selectedClient}
            disableClientTile={this.state.disableClientTile}
            selectedProgramDDValue={selectedProgramDDValue}
            showProjectListRoller={showProjectListRoller}
          />
          :
          <Row className="access_denied_container">
            <Col md={12} xs={12}>
              <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
              <div className="access_denied_content">
                <h1>Access Denied</h1>
                <p>You don't have the necessary user privileges to view the client that you are attempting to access.</p>
              </div>
            </Col>
          </Row>
        :
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      )
    }
  }

  return DataIngestionContainer;
})();

export default connect(
  state => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      DataIngestionData: state.DataIngestionData,
      ConfigureProgramData: state.ConfigureProgramData,
      userInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
      HomeData: state.HomeData
    }
  },
  dispatch => bindActionCreators(Object.assign({},
    {
      getClientList,
      onClickFavorite,
      onSearch,
      showClientDropdown,
      setSelectedClient,
      setCurrentUserDetails,
      getRDVClientDetails,
      getProgramProjectCreationYear,
      getLandingProgramProjectsList,
      getProjectDetails,
      onSelectCreationYearOptions,
      setDeliveryCenterSelected,
      getMappedStakeHolders,
      setProgramSelected,
      getProjectDataEntryTableData,
      InitRVD,
      clearMainSelectedDetails,
      clearProgramProjectList,
      getProgramDropdownData,
      getLandingProjectsList,
      setProjectSelected,
      clearRVDCreationYearList
    }), dispatch)
)(withRouter(DataIngestion));
