import React, { Component } from "react";
import { Accordion, Card, Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import store from "../../../store";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  GrooveTabComponent,
  GrooveIconSvg,
  GrooveInput,
  GrooveSelectMenuBasic,
  GrooveDateCalendarPicker,
  GrooveButton,
  GrooveRadioButton,
} from "tfo-groove";
import {
  faChevronLeft,
  faTimes,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import CVMViewComments from "../../Shared/CVMViewComments/CVMViewComments";
import CVMRejectionModal from "../../Shared/CVMRejectionModal/CVMRejectionModal";
import CVMConditionalApproveModal from "../../Shared/CVMConditionalApproveModal/CVMConditionalApproveModal";
import CVMModal from "../../Shared/CVMModal/CVMModal";
import CVMMutiSelectDropdown from "../../Shared/CVMMutiSelectDropdown/CVMMutiSelectDropdown";
import CVMSubmitRejectionModal from "../../Shared/CVMSubmitRejectionModal/CVMSubmitRejectionModal";
import CVMApprovalRequestModal from "../../Shared/CVMApprovalRequestModal/CVMApprovalRequestModal";
import addfiles from "./../../../Static/images/360cvm/AddFilesIcon.svg";
import InfoIcon from "./../../../Static/images/360cvm/InfoIcon.svg";

import "./ProgramIdentified.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { data, progress } from "react-dom-factories";

import Utilities from "../../../Utilities";
import { HttpGet, HttpPost } from "../../../Utilities/HTTPUtil";
import NoInitialBOI from "./ProgramIdentified/NoInitialBOI";
import Dimension from "./ProgramIdentified/Dimension";
import { setToasterParam } from "../../../Actions/Shared.Action";

const ProgramIdentified = (function() {
  class ProgramIdentifiedPresentational extends Component {
    render() {
      const infoPopover = (
        <Popover id="info-popover">
          <Popover.Title as="h3">
            BOI Description
            <span className="close-btn">
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </Popover.Title>
          <Popover.Content>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              egestas ipsum eget nunc efficitur, imperdiet hendrerit metus
              tempor. Vivamus tempus a elit ut convallis.
            </p>
          </Popover.Content>
        </Popover>
      );
      return (
        <React.Fragment>
          <div className="client-config-container">
            <CVMModal
              title={"Add BOI(s) to " + this.props.selectedProgramName}
              show={this.props.show}
              onCloseModal={this.props.onCancelAddBOI}
              size={this.props.size}
              content={
                <ModalContent
                  modalActiveKey={this.props.modalActiveKey}
                  onChangeModalTab={this.props.onChangeModalTab}
                  BOIoptions={this.props.BOIoptions}
                  UOMoptions={this.props.UOMoptions}
                  onCancelAddBOI={this.props.onCancelAddBOI}
                  BOIDetailsList={this.props.BOIDetailsList}
                  callbackFunction={this.props.callbackFunction}
                  AddBOI={this.props.AddBOI}
                  deleteBOI={this.props.deleteBOI}
                  saveOnChange={this.props.saveOnChange}
                  onSaveBOI={this.props.onSaveBOI}
                  validateBOI={this.props.validateBOI}
                  dimensionValue={this.props.dimensionValue}
                  subdimensionValue={this.props.subdimensionValue}
                  fieldValidation={this.props.fieldValidation}
                  selectedBOI={this.props.selectedBOI}
                  selectedUOM={this.props.selectedUOM}
                  erroMessage={this.props.erroMessage}
                />
              }
            />

            <div className="stepper-tab-left-section">
              <div className="tab-section-header header18-semibold-midnight">
                Programs ({this.props.programs.length})
              </div>
              <div className="subtab-list">
                {this.props.programs.map((program) => {
                  return (
                    <ul className="bodytext16-medium-midnight">
                      <li
                        className={
                          this.props.selectedProgram.ClientProgramID ===
                          program.ClientProgramID
                            ? "active"
                            : null
                        }
                        onClick={(e) => this.props.setSelectedProgram(program)}
                      >
                        {program.ClientProgramName}
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>

            <div className="stepper-tab-right-section">
              {this.props.isRejected && this.props.role !== "Operations Account Lead" ? 
                <div className="container-PIV">
                  <div className="container-PIV-box">
                    <div className="errorbox" onClick={this.props.showCommentsSection}>
                      This Program Target Value settings has been rejected by the
                      Operations Account Lead {this.props.clientOperationAccountLead.OperationsAccountLead}. Please click here
                      to view the rejection comment.
                    </div>
                  </div>
                </div>
              : null
              }
              <div className="container-PIV"></div>
              <Row className="tab-header-row">
                <div>
                  <div className="bodytext18-medium-midnight">
                    {this.props.selectedProgram["ClientProgramName"]} Dimensions
                  </div>
                </div>
                <div>
                  {this.props.role === "Operations Account Lead" ? (
                    <Row className="tab-header-row-left">
                        <GrooveButton
                          id="primry-btn-1"
                          hasIcon={true}
                          type=""
                          colorClass=""
                          text="View Comment"
                          callbackFunction={this.props.showCommentsSection}
                          customClassName="viewComment-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="comment"
                          iconStyle="regular"
                          iconId="btn-icon-01"
                          isDisabled={Object.keys(this.props.commentList).length > 0 
                            ? this.props.commentList.ReJectedList.length > 0 || this.props.commentList.ConditionalApprovedList.length > 0 
                            ? false
                            : true
                            : true}
                        />
                      {this.props.showcomments && (
                        <CVMViewComments
                          commentList={this.props.commentList}
                          show={this.props.showcomments}
                          closeCommentsSection={this.props.closeCommentsSection}
                        />
                      )}
                      <GrooveButton
                        id="expandAll"
                        hasIcon={false}
                        type=""
                        colorClass=""
                        text="Expand All"
                        callbackFunction={(e) => this.props.onExpandCollapse(e)}
                        customClassName="expandAll-button"
                        iconAlignment="right"
                        iconSize="small"
                        iconName="plus"
                        iconStyle="solid"
                        iconId="btn-icon-01"
                      />
                      <GrooveButton
                        id="collapseAll"
                        hasIcon={false}
                        type=""
                        colorClass=""
                        text="Collapse All"
                        callbackFunction={(e) => this.props.onExpandCollapse(e)}
                        customClassName="collapseAll-button"
                        iconAlignment="right"
                        iconSize="small"
                        iconName="plus"
                        iconStyle="solid"
                        iconId="btn-icon-01"
                      />
                      <GrooveButton
                        id="primry-btn-4"
                        hasIcon={true}
                        type="solid"
                        isDisabled={true}
                        colorClass="stat-alternate"
                        size="auto"
                        text="Edit BOIs"
                        callbackFunction={this.props.onOpenAddBOIModal}
                        customClassName="add-project-button"
                        iconAlignment="right"
                        iconSize="small"
                        iconName="pen"
                        iconStyle="solid"
                        iconId="btn-icon-01"
                      />
                    </Row>
                  ) : (
                    this.props.selectedProgramDetails.length <= 0 ? (
                      <GrooveButton
                        id="primry-btn-4"
                        hasIcon={true}
                        type="solid"
                        colorClass="stat-alternate"
                        size="auto"
                        text="Add BOIs"
                        callbackFunction={this.props.onOpenAddBOIModal}
                        customClassName="add-project-button"
                        iconAlignment="right"
                        iconSize="small"
                        iconName="pen"
                        iconStyle="solid"
                        iconId="btn-icon-01"
                        isDisabled={this.props.role === "Operations Account Lead" || this.props.role === "" ? true : this.props.selectedProgram.IsReadOnly}
                      />
                    ) : (
                      <Row className="tab-header-row-left">
                        {
                        Object.keys(this.props.commentList).length > 0 ? this.props.commentList.ReJectedList.length > 0 || this.props.commentList.ConditionalApprovedList.length > 0 ?
                        <GrooveButton
                          id="primry-btn-1"
                          hasIcon={true}
                          type=""
                          colorClass=""
                          text="View Comment"
                          callbackFunction={this.props.showCommentsSection}
                          customClassName="viewComment-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="comment"
                          iconStyle="regular"
                          iconId="btn-icon-01"
                        />
                        : null : null
                      }
                      {this.props.showcomments && (
                        <CVMViewComments
                          commentList={this.props.commentList}
                          show={this.props.showcomments}
                          closeCommentsSection={this.props.closeCommentsSection}
                        />
                      )}
                        <GrooveButton
                          id="expandAll"
                          hasIcon={false}
                          type=""
                          colorClass=""
                          text="Expand All"
                          callbackFunction={(e) =>
                            this.props.onExpandCollapse(e)
                          }
                          customClassName="expandAll-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="plus"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                        />
                        <GrooveButton
                          id="collapseAll"
                          hasIcon={false}
                          type=""
                          colorClass=""
                          text="Collapse All"
                          callbackFunction={(e) =>
                            this.props.onExpandCollapse(e)
                          }
                          customClassName="collapseAll-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="plus"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                        />
                        <GrooveButton
                          id="primry-btn-4"
                          hasIcon={true}
                          type="solid"
                          colorClass="stat-alternate"
                          size="auto"
                          text="Edit BOIs"
                          callbackFunction={this.props.onOpenAddBOIModal}
                          customClassName="add-project-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="pen"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                          isDisabled={this.props.selectedProgram.IsReadOnly}
                        />
                      </Row>
                    )
                  )}
                </div>
              </Row>

              {this.props.selectedProgramDetails.length > 0 ? null : (
                <NoInitialBOI />
              )}

              {this.props.selectedProgramDetails.map(
                (dimensionMap, dimensionIndex) => (
                  <Dimension
                    selectedProgramID={this.props.selectedProgramID}
                    dimensionName={dimensionMap.CoreDimensionName}
                    dimensionID={dimensionMap.CoreDimensionID}
                    dimensionTarget={dimensionMap.CoreDimensionTarget}
                    subDimension={dimensionMap.SubDimensionList}
                    dimensionReason={dimensionMap.CoreDimensionReason}
                    dimensionStatus={dimensionMap.CoreDimensionStatus}
                    dimensionIndex={dimensionIndex}
                    updateDimension={this.props.updateDimension}
                    updateSubDimension={this.props.updateSubDimension}
                    updateBOI={this.props.updateBOI}
                    role={this.props.role}
                    IsReadOnly={this.props.selectedProgram.IsReadOnly}
                  />
                )
              )}

              <Row className="program-identified-buttons">
                {this.props.role !== "Operations Account Lead" ? (
                  <>
                    <GrooveButton
                      id="primry-btn-1"
                      name="Solid Button Primary-Ops"
                      isDisabled={ this.props.role !== "Operations Account Lead" &&
                        this.props.selectedProgram.IsReadOnly ? true :
                        this.props.selectedProgramDetails.length > 0
                          ? false
                          : true
                      }
                      hasIcon={false}
                      type="outline"
                      colorClass="stat-alternate"
                      size="auto"
                      text="Save as Draft"
                      callbackFunction={this.props.saveDraft}
                    />
                    <GrooveButton
                      id="primry-btn-2"
                      name="Solid Button Primary-Ops"
                      isDisabled={ this.props.role !== "Operations Account Lead" &&
                        this.props.selectedProgram.IsReadOnly ? true :
                        this.props.selectedProgramDetails.length > 0
                          ? false
                          : true
                      }
                      hasIcon={false}
                      type="solid"
                      colorClass="stat-alternate"
                      size="auto"
                      text="Send for Approval"
                      callbackFunction={this.props.onApprovalRequest}
                      customClassName=""
                      iconAlignment="right"
                      iconSize="small"
                      iconName="plus"
                      iconStyle="solid"
                      iconId="btn-icon-01"
                    />
                  </>
                ) : (
                  <>
                    <GrooveButton
                      id="primry-btn-1"
                      name="Solid Button Primary-Ops"
                      isDisabled={ this.props.role !== "Operations Account Lead" &&
                        this.props.selectedProgram.IsReadOnly ? true :
                        this.props.selectedProgramDetails.length > 0
                          ? false
                          : true
                      }
                      hasIcon={false}
                      type="outline"
                      colorClass="stat-alternate"
                      size="auto"
                      text="Save as Draft"
                      callbackFunction={() => this.props.saveDraftApprover()}
                    />
                    <GrooveButton
                      id="primry-btn-2"
                      name="Solid Button Primary-Ops"
                      isDisabled={ this.props.role !== "Operations Account Lead" &&
                        this.props.selectedProgram.IsReadOnly ? true :
                        this.props.selectedProgramDetails.length > 0
                          ? false
                          : true
                      }
                      hasIcon={false}
                      type="solid"
                      colorClass="stat-alternate"
                      size="auto"
                      text="Submit"
                      callbackFunction={this.props.onCloseSubmitSettingsModal}
                      customClassName=""
                      iconAlignment="right"
                      iconSize="small"
                      iconName="plus"
                      iconStyle="solid"
                      iconId="btn-icon-01"
                    />
                  </>
                )}
              </Row>
            </div>
          </div>
          {this.props.rejectionModal.show && (
            <CVMRejectionModal
              {...this.props.rejectionModal}
              closeReasonModal={this.props.closeReasonModal}
              onChangeDimension={this.props.updateDimension}
              onChangeSubDimension={this.props.updateSubDimension}
              onChangeBOI={this.props.updateBOI}
            />
          )}
          {this.props.conditionalModal.show && (
            <CVMConditionalApproveModal
              {...this.props.conditionalModal}
              closeReasonModal={this.props.closeReasonModal}
              onChangeDimension={this.props.updateDimension}
              onChangeSubDimension={this.props.updateSubDimension}
              onChangeBOI={this.props.updateBOI}
            />
          )}

          <CVMModal
            title={"Send for Approval"}
            customclass={"notifyModal-dialog"}
            show={this.props.approvalRequestModal}
            onCloseModal={this.props.closeRequestModal}
            content={
              <CVMApprovalRequestModal
                label={"Approver ID"}
                onCloseModal={this.props.closeRequestModal}
                sendApproval={this.props.sendApproval}
                clientList={this.props.clientList}
                selectedProgram={this.props.selectedProgram}
                actionType={"Send"}
                sendForApprovalComment={this.props.sendForApprovalComment}
                clientOperationAccountLead={
                  this.props.clientOperationAccountLead
                }
                disableSendBtn={this.props.disableSendBtn}
              />
            }
          />
          <CVMModal
            title={"Submit Settings"}
            customclass={"submitSettings-dialog"}
            show={this.props.showSubmitSettingsModal}
            onCloseModal={this.props.onCloseSubmitSettingsModal}
            content={
              <CVMSubmitRejectionModal
                onCloseModal={this.props.onCloseSubmitSettingsModal}
                selectedProgramID={this.props.selectedProgramID}
                selectedProgramDetails={this.props.selectedProgramDetails}
                Files={this.props.Files}
                onDropAccepted={this.props.onDropAccepted}
                onDropRejected={this.props.onDropRejected}
                onDeleteFile={this.props.onDeleteFile}
                onSubmitClick={this.props.onSubmitClick}
                isRejected={this.props.isRejected}
                clearUpload={this.props.clearUpload}
                submitComment={this.props.submitComment}
                type="Program"
                label={
                  !this.props.isRejected ? "Mapped Stakeholders" : "BT Lead"
                }
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class ModalContent extends Component {
    render() {
      const fieldValidation = this.props.fieldValidation;
      return (
        <React.Fragment>
          <div className="cvm-tab-content add-boi-modal">
            <GrooveButton
              id="primry-btn-3"
              hasIcon={true}
              type="outline"
              colorClass="stat-alternate"
              text="Add"
              iconAlignment="right"
              iconSize="small"
              iconName="plus"
              iconStyle="solid"
              iconId="btn-icon-01"
              customClassName="add-boi-button"
              callbackFunction={() => this.props.AddBOI()}
            />
            <div className="add-boi-field-container">
              <Col lg={3} md={3} sm={3}>
                <GrooveSelectMenuBasic
                  name="BOI"
                  isDisabled={false}
                  placeholder={
                    Object.keys(this.props.selectedBOI).length !== 0
                      ? "BOI*"
                      : "Select BOI*"
                  }
                  list={this.props.BOIoptions}
                  id="boi"
                  customClassName={
                    this.props.fieldValidation.BOIValid
                      ? ""
                      : " dropdown-invalid"
                  }
                  callbackFunction={(e) =>
                    this.props.callbackFunction(e, "boi")
                  }
                />
              </Col>
              <Col lg={3} md={3} sm={3}>
                <GrooveSelectMenuBasic
                  name="unitofMeasure"
                  isDisabled={
                    Object.keys(this.props.selectedBOI).length !== 0
                      ? false
                      : true
                  }
                  placeholder={
                    Object.keys(this.props.selectedUOM).length !== 0
                      ? "Unit of Measure*"
                      : "Select Unit of Measure*"
                  }
                  list={this.props.UOMoptions}
                  id="uom"
                  customClassName={
                    this.props.fieldValidation.UOMValid
                      ? ""
                      : " dropdown-invalid"
                  }
                  callbackFunction={(e) =>
                    this.props.callbackFunction(e, "uom")
                  }
                />
              </Col>
              <Col lg={3} md={3} sm={3}>
                <GrooveInput
                  name="Dimension"
                  isDisabled={true}
                  placeholder="Dimension"
                  inputValue={this.props.dimensionValue.name ? this.props.dimensionValue.name : ""}
                  maxLength={255}
                />
              </Col>
              <Col lg={3} md={3} sm={3}>
                <GrooveInput
                  name="Subdimension"
                  isDisabled={true}
                  placeholder="Subdimension"
                  inputValue={this.props.subdimensionValue.name ? this.props.subdimensionValue.name : ""}
                  maxLength={255}
                />
              </Col>
            </div>
            <div className="divider-horizontal" />
            <div className="added-boi-container">
              {this.props.BOIDetailsList.map((item, index) => {
                return (
                  <div key={index} className="add-boi-field-container">
                    <Col lg={3} md={3} sm={3}>
                      <GrooveSelectMenuBasic
                        name="BOI"
                        isDisabled={false}
                        placeholder="BOI*"
                        list={item.boiList}
                        defaultData={item.selectedBOI}
                        callbackFunction={(e) =>
                          this.props.saveOnChange(e, "boi", index)
                        }
                      />
                    </Col>
                    <Col lg={3} md={3} sm={3}>
                      <GrooveSelectMenuBasic
                        name="UOM"
                        isDisabled={false}
                        placeholder={
                          Object.keys(item.selectedUOM).length > 0
                            ? "Unit of Measure*"
                            : "Select  Unit of Measure*"
                        }
                        list={item.uomList}
                        defaultData={item.selectedUOM}
                        callbackFunction={(e) =>
                          this.props.saveOnChange(e, "uom", index)
                        }
                      />
                    </Col>
                    <Col lg={3} md={3} sm={3}>
                      <GrooveInput
                        name="Dimension"
                        isDisabled={true}
                        placeholder="Dimension"
                        inputValue={item.dimensionValue.name}
                        callbackFunction={(e) =>
                          this.props.saveOnChange(e, "dimension", index)
                        }
                        maxLength={255}
                      />
                    </Col>
                    <Col lg={3} md={3} sm={3}>
                      <GrooveInput
                        name="Subdimension"
                        placeholder="Subdimension"
                        isDisabled={true}
                        inputValue={item.subdimensionValue.name}
                        callbackFunction={(e) =>
                          this.props.saveOnChange(e, "subdimension", index)
                        }
                        maxLength={255}
                      />
                    </Col>
                    <div className="delete-boi">
                      <GrooveIconSvg
                        customClassName="delete-boi-button"
                        size="large"
                        name="trash-alt"
                        iconStyle="solid"
                        iconColor="stat-alternate"
                        callbackFunction={() => this.props.deleteBOI(index)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {fieldValidation.BOIValid && fieldValidation.UOMValid ? (
            ""
          ) : (
            <p className="missing">{this.props.erroMessage}</p>
          )}

          <div className="add-boi-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={this.props.onCancelAddBOI}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Save"
              callbackFunction={this.props.onSaveBOI}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class ProgramIdentifiedContainer extends Component {
    constructor() {
      super();
      this.state = {
        boi: {
          boiList: {},
          uomList: {},
          boiMappingsList: {},
        },
        onSaveBOIDetails: [],
        BOIDetailsList: [],
        stagingBOIDetailsList: [],
        selectedBOI: {},
        selectedUOM: {},
        dimensionValue: "",
        subdimensionValue: "",
        fieldValidation: {
          BOIValid: true,
          UOMValid: true,
        },
        validateBOI: {
          boi: "",
          uom: "",
        },
        BOIoptions: [],
        UOMoptions: [],
        size: "lg",
        show: false,        
        showcomments: false,
        showSaveDraftToast: false,
        showRejection: false,
        approvalRequestModal: false,
        showConditionalApproveModal: false,
        showSubmitSettingsModal: false,
        role: "",
        programList: [],
        selectedProgram: [],
        selectedProgramDetails: [],
        selectedProgramName: "",
        selectedProgramID: "",
        tempReason: "",
        sendForApprovalComment: "",
        rejectionModal: {
          show: false,
          type: "",
          name: "",
          value: "",
          reason: "",
          dimensionIndex: "",
          subDimensionIndex: "",
          boiIndex: "",
        },
        conditionalModal: {
          show: false,
          type: "",
          name: "",
          value: "",
          reason: "",
          dimensionIndex: "",
          subDimensionIndex: "",
          boiIndex: "",
        },
        isRejected: false,
        Files: [],
        clientOperationAccountLead: {},
        erroMessage: "",
        submitComment: "",
        commentList: [],
        currentBOIStatus: {
          status: "",
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0,
        },
        disableSendBtn: false
      };
    }

    componentDidMount = async () => {
      let selectedClientID = store.getState().SharedData.selectedClient.value;
      let selectedReportingYear = store.getState().ClientConfigurationData.workFlowReportingYear;
      const programs = await HttpGet(
        `ProgramIdentifiedValueSettings/GetClientPrograms?clientID=${selectedClientID}&reportingYear=${selectedReportingYear}`
      );
      this.setState(
        {
          programList: programs,
          selectedProgram: programs[0],
          selectedProgramName: programs[0].ClientProgramName,
          selectedProgramID: programs[0].ClientProgramID,
        },
        () => {
          this.getProgramDetails();
          this.props.setProgDetailsProjectDetails({
            programName: this.state.selectedProgram.ClientProgramName,
            reportingYear: this.state.selectedProgram.ReportingYear,
            offering: this.state.selectedProgram.Offering,
            offeringID: 0,
            bTLead: this.state.selectedProgram.BTLead,
            cXLead: this.state.selectedProgram.CXLLead,
          });
        }
      );
      this.getClientOperationAccountLead();
      this.getBOIList();
    };

    getProgramDetails = async () => {
      let programID = this.state.selectedProgram.ClientProgramID;

      const programDetail = await HttpGet(
        `ProgramIdentifiedValueSettings/GetProgramDimensions?programID=${programID}`
      );
      this.setState(
        {
          selectedProgramDetails: programDetail,
        },
        () => {
          this.fetchBOIData(this.state.selectedProgramID);
        }
      );
      this.setDefaultStatus();
      this.checkRejectedStatus();

      let userProfiles = store.getState().userInformation.userProfiles;
      let selectedClient = store.getState().SharedData.selectedClient;
      let userProfile = userProfiles.find(
        (client) => client.ClientName === selectedClient.label
      );
      this.setState({ role: userProfile.ProfileName });
    };

    setSelectedProgram = (program) => {
      this.setState(
        {
          ...this.state,
          selectedProgram: program,
          selectedProgramName: program.ClientProgramName,
          selectedProgramID: program.ClientProgramID,
        },
        () => {
          this.getProgramDetails();
          this.getBOIList();
        }
      );
    };

    submitComment = (e) => {
      this.setState({
        submitComment: e.value
      });
    }

    setDefaultStatus() {
      let program = this.state.selectedProgramDetails;
      for (var i = 0; i < program.length; i++) {
        if (program[i].CoreDimensionStatus === "") {
          program[i]["CoreDimensionStatus"] = "Approve";
          this.setState({
            selectedProgramDetails: program,
          });
        } else {
          for (var j = 0; j < program[i].SubDimensionList.length; j++) {
            if (program[i].SubDimensionList[j].SubDimensionStatus === "") {
              let _subDimension = program[i]["SubDimensionList"];
              let _subDimStatus = _subDimension[j]["SubDimensionStatus"];
              _subDimStatus = "Approve";
              this.setState({
                selectedProgramDetails: program,
              });
            } else {
              for (
                var k = 0;
                k < program[i].SubDimensionList[j].BOIList.length;
                k++
              ) {
                if (
                  program[i].SubDimensionList[j].BOIList[k].BOIStatus === ""
                ) {
                  let _subDimension = program[i]["SubDimensionList"];
                  let _boi = _subDimension[j]["BOIList"];
                  let _boiStatus = _boi[k]["BOIStatus"];
                  _boiStatus = "Approve";
                  this.setState({
                    selectedProgramDetails: program,
                  });
                }
              }
            }
          }
        }
      }
    }

    prepareBOIData = () => {
      let tempBOIDetailsList = [];
      let item = this.state.selectedProgramDetails;
      let boiList = this.state.BOIoptions;
      let uom = this.state.boi.boiMappingsList;
      for (var i = 0; i < item.length; i++) {
        for (var j = 0; j < item[i].SubDimensionList.length; j++) {
          for (var k = 0; k < item[i].SubDimensionList[j].BOIList.length; k++) {
            let uomList = [];
            for (var x = 0; x < uom.length; x++) {
              if (
                uom[x].BOIName === item[i].SubDimensionList[j].BOIList[k].BOIName
              ) {
                uomList.push({label: uom[x].UOM, text: uom[x].UOM, value: uom[x].UOM});
              }
            }
            let selectedBOIID = 0;
            //select boi with matching name, can't match with ID since we remove duplicate entry for BOI dropdown. 
            //ID that will be set here may not match its real ID, real ID will be supplied before saving.
            boiList.map((boi) => {
              if(boi.label === item[i].SubDimensionList[j].BOIList[k].BOIName){
                selectedBOIID = boi.value;
              }
            })
            tempBOIDetailsList.push({
              boiList: boiList,
              dimensionValue: {
                name: item[i].CoreDimensionName,
                id: item[i].CoreDimensionID,
              },
              
              selectedBOI: {
                label: item[i].SubDimensionList[j].BOIList[k].BOIName,
                text: item[i].SubDimensionList[j].BOIList[k].BOIName,
                value: selectedBOIID,
              },
              selectedUOM: {
                label: item[i].SubDimensionList[j].BOIList[k].UOM,
                text: item[i].SubDimensionList[j].BOIList[k].UOM,
                value: item[i].SubDimensionList[j].BOIList[k].UOM,
              },
              subdimensionValue: {
                name: item[i].SubDimensionList[j].SubDimensionName,
                id: item[i].SubDimensionList[j].SubDimensionID,
              },
              uomList: uomList,
            });
          }
        }
      }

      this.setState({
        ...this.state,
        BOIDetailsList: tempBOIDetailsList,
      });
    };

    updateDimension = (event, type, dimensionIndex) => {
      let tempCurrentDimension = this.state.selectedProgramDetails;
      switch (type) {
        case "radioButton":
          tempCurrentDimension[dimensionIndex]["CoreDimensionStatus"] =
            event.value;
          this.setState({
            selectedProgramDetails: tempCurrentDimension,
          });
          switch (event.label) {
            case "Reject":
              this.setState({
                rejectionModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionName"],
                  type: "Dimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            case "Conditional Approval":
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionName"],
                  type: "Dimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            default:
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
          }
          this.checkRejectedStatus();
          break;
        case "inputReason":
          let tempReason = this.state.tempReason;
          tempReason = event.value;
          this.setState({
            tempReason: tempReason,
          });
          break;
        case "saveReason":
          let tempDimensionReason = this.state.selectedProgramDetails;
          tempDimensionReason[dimensionIndex][
            "CoreDimensionReason"
          ] = this.state.tempReason;
          this.setState({
            selectedProgramDetails: tempDimensionReason,
            rejectionModal: {
              show: false,
            },
            conditionalModal: {
              show: false,
            },
          });
          break;
        case "inputIdentified":
          let val = event.value;
          if(val.toFixed(2) >= 9999.99){
            val = 9999.99
          }  
          let tempDimensionInput = this.state.selectedProgramDetails;
          tempDimensionInput[dimensionIndex]["CoreDimensionTarget"] = val;
          this.setState({
            selectedProgramDetails: tempDimensionInput,
          });
          break;
        default:
      }
    };
    updateSubDimension = (event, type, dimensionIndex, subDimensionIndex) => {
      switch (type) {
        case "radioButton":
          let tempCurrentDimension = this.state.selectedProgramDetails;
          let tempCurrentSubDimension =
            tempCurrentDimension[dimensionIndex]["SubDimensionList"];
          tempCurrentSubDimension[subDimensionIndex]["SubDimensionStatus"] =
            event.value;
          this.setState({
            selectedProgramDetails: tempCurrentDimension,
          });
          switch (event.label) {
            case "Reject":
              this.setState({
                rejectionModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionName"],
                  type: "SubDimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                },
              });
              break;
            case "Conditional Approval":
              this.setState({
                conditionalModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionName"],
                  type: "SubDimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: "",
                },
                rejectionModal: {
                  show: false,
                },
              });
              break;
            default:
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
          }
          this.checkRejectedStatus();
          break;
        case "inputReason":
          let tempReason = this.state.tempReason;
          tempReason = event.value;
          this.setState({
            tempReason: tempReason,
          });
          break;
        case "saveReason":
          let tempCurrentDimensionSave = this.state.selectedProgramDetails;
          let tempCurrentSubDimensionSave =
            tempCurrentDimensionSave[dimensionIndex]["SubDimensionList"];
          tempCurrentSubDimensionSave[subDimensionIndex][
            "SubDimensionReason"
          ] = this.state.tempReason;
          this.setState({
            selectedProgramDetails: tempCurrentDimensionSave,
            rejectionModal: {
              show: false,
            },
            conditionalModal: {
              show: false,
            },
          });
          break;
        case "inputIdentified":
          let val = event.value;
          if(val.toFixed(2) >= 9999.99){
            val = 9999.99
          } 
          let tempCurrentDimensionIdentified = this.state
            .selectedProgramDetails;
          let tempCurrentSubDimensionIdentified =
            tempCurrentDimensionIdentified[dimensionIndex]["SubDimensionList"];
          tempCurrentSubDimensionIdentified[subDimensionIndex][
            "SubDimensionTarget"
          ] = val;
          this.setState({
            selectedProgramDetails: tempCurrentDimensionIdentified,
          });
          break;
        default:
      }
    };

    updateBOI = (event, type, dimensionIndex, subDimensionIndex, boiIndex) => {
      switch (type) {
        case "radioButton":
          let tempCurrentDimension = this.state.selectedProgramDetails;
          if(this.state.currentBOIStatus.status === "" 
              || (this.state.currentBOIStatus.dimensionIndex !== dimensionIndex)
              || (this.state.currentBOIStatus.subDimensionIndex !== subDimensionIndex)
              || (this.state.currentBOIStatus.boiIndex !== boiIndex)){

                if((this.state.currentBOIStatus.dimensionIndex !== dimensionIndex)
                || (this.state.currentBOIStatus.subDimensionIndex !== subDimensionIndex)
                || (this.state.currentBOIStatus.boiIndex !== boiIndex) && (this.state.rejectionModal.show || this.state.conditionalModal.show)){
                  tempCurrentDimension[this.state.currentBOIStatus.dimensionIndex]["SubDimensionList"][this.state.currentBOIStatus.subDimensionIndex]["BOIList"][this.state.currentBOIStatus.boiIndex]["BOIStatus"] = this.state.currentBOIStatus.status === "approve" ? "" :  this.state.currentBOIStatus.status;
                }
                this.setState({
                  currentBOIStatus: {
                    status: tempCurrentDimension[dimensionIndex]["SubDimensionList"][subDimensionIndex]["BOIList"][boiIndex]["BOIStatus"] === "" ? "approve" : tempCurrentDimension[dimensionIndex]["SubDimensionList"][subDimensionIndex]["BOIList"][boiIndex]["BOIStatus"],
                    dimensionIndex: dimensionIndex,
                    subDimensionIndex: subDimensionIndex,
                    boiIndex: boiIndex,
                  },
                })
          }
          else{
            tempCurrentDimension[this.state.currentBOIStatus.dimensionIndex]["SubDimensionList"][this.state.currentBOIStatus.subDimensionIndex]["BOIList"][this.state.currentBOIStatus.boiIndex]["BOIStatus"] = this.state.currentBOIStatus.status
          }

          let tempCurrentSubDimensionRadio =
            tempCurrentDimension[dimensionIndex]["SubDimensionList"];
          let currentStatus = tempCurrentDimension[dimensionIndex]["SubDimensionList"][subDimensionIndex]["BOIList"][boiIndex]["BOIStatus"];
          let tempCurrentBOIRadio =
            tempCurrentSubDimensionRadio[subDimensionIndex]["BOIList"];
          tempCurrentBOIRadio[boiIndex]["BOIStatus"] = event.value;
          this.setState({
            selectedProgramDetails: tempCurrentDimension,
          });
          switch (event.label) {
            case "Reject":
              this.setState({
                rejectionModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOIName"],
                  type: "BOI",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOITarget"],
                  reason: currentStatus === "Rejected" ?
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOIReason"] : "",
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: boiIndex,
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            case "Conditional Approval":
              this.setState({
                conditionalModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOIName"],
                  type: "BOI",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOITarget"],
                  reason: currentStatus === "Conditional Approval" ?
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOIReason"] : "",
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: boiIndex,
                },
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            case "Approve":
              let tempCurrentDimensionSave = this.state.selectedProgramDetails;
              let tempCurrentSubDimensionSave =
                tempCurrentDimensionSave[dimensionIndex]["SubDimensionList"];
              let tempCurrentBOISave =
                tempCurrentSubDimensionSave[subDimensionIndex]["BOIList"];
              tempCurrentBOISave[boiIndex]["BOIReason"] = ""
              this.setState({
                currentBOIStatus: {
                  status: "",
                  dimensionIndex: 0,
                  subDimensionIndex: 0,
                  boiIndex: 0,
                },
              })
            default:
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
          }
          this.checkRejectedStatus();
          break;
        case "inputReason":
          let tempReason = this.state.tempReason;
          tempReason = event.value;
          this.setState({
            tempReason: tempReason,
          });
          break;
        case "saveReason":
          let tempCurrentDimensionSave = this.state.selectedProgramDetails;
          let tempCurrentSubDimensionSave =
            tempCurrentDimensionSave[dimensionIndex]["SubDimensionList"];
          let tempCurrentBOISave =
            tempCurrentSubDimensionSave[subDimensionIndex]["BOIList"];
          tempCurrentBOISave[boiIndex]["BOIReason"] = this.state.tempReason;
          this.setState({
            selectedProgramDetails: tempCurrentDimensionSave,
            rejectionModal: {
              show: false,
            },
            conditionalModal: {
              show: false,
            },   
            currentBOIStatus: {
              status: tempCurrentDimensionSave[dimensionIndex]["SubDimensionList"][subDimensionIndex]["BOIList"][boiIndex]["BOIStatus"],
              dimensionIndex: dimensionIndex,
              subDimensionIndex: subDimensionIndex,
              boiIndex: boiIndex,
            },        
          });
          break;
        case "inputIdentified":
          let val = event.value;
          if(val.toFixed(2) >= 999999999999.99){
            val = 999999999999.99
          }                      
          let tempCurrentDimensionIdentified = this.state
            .selectedProgramDetails;
          let tempCurrentBOIIdentified =
            tempCurrentDimensionIdentified[dimensionIndex]["SubDimensionList"][
              subDimensionIndex
            ]["BOIList"];
          tempCurrentBOIIdentified[boiIndex]["BOITarget"] = val;
          this.setState({
            selectedProgramDetails: tempCurrentDimensionIdentified,
          });
          break;
        default:
      }
    };

    closeReasonModal = (type) => {
      let tempCurrentDimension = this.state.selectedProgramDetails;
      tempCurrentDimension[this.state.currentBOIStatus.dimensionIndex]["SubDimensionList"][this.state.currentBOIStatus.subDimensionIndex]["BOIList"][this.state.currentBOIStatus.boiIndex]["BOIStatus"] = this.state.currentBOIStatus.status === "approve" ? "" : this.state.currentBOIStatus.status;
      
      this.setState({
        currentBOIStatus: {
          status: "",
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0,
        },
      })
      switch (type) {
        case "Reject":
          this.setState({
            rejectionModal: {
              show: false,
            },
          });
          break;
        case "Conditional":
          this.setState({
            conditionalModal: {
              show: false,
            },
          });
          break;          
      }
    };

    uploadFiles = async (index, file) => {
      let userName = store.getState().userInformation.userName;
      var files = new FormData();
      for (var i = 0; i < this.state.Files.length; i++) {
        files.append("files", this.state.Files[i]);
      }
      files.append("uploadedBy", userName);
      files.append("program_id", this.state.selectedProgramID);
      let token = await Utilities.getToken();
      var _progressPercentage = await fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/AddAttachment`,
        {
          headers: {
            "Cache-Control":"no-store",
            Pragma: "no-cache",
            Expires: 0,
            Authorization: "Bearer " + token,
          },
          method: "POST",
          body: files,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            return res.text();
          }
        })
        .catch((error) => console.log(error));
    };

    onDropAccepted = (acceptedFiles) => {
      let uploadFiles = acceptedFiles;
      let uploadCount = uploadFiles.length;
      let fileCount = this.state.Files.length;
      if (fileCount + uploadCount <= 3) {
        uploadFiles = this.state.Files.concat(uploadFiles);
        this.setState({
          Files: uploadFiles,
        });
      }
      else{
        console.log("File upload exceeded total (3)");
      }
    };

    onDropRejected = (rejectedFiles) => {
      let rejectFiles = rejectedFiles;
      console.log(rejectFiles);
    };

    onDeleteFile = (fileIndex) => {
      let tempFiles = [...this.state.Files];
      tempFiles.splice(fileIndex, 1);
      this.setState({
        Files: tempFiles,
      });
    };

    onSubmitClick = async (isApproved, stakeholders) => {
      let programID = this.state.selectedProgramID;
      let userID = store.getState().userInformation.userID;
      let userName = store.getState().userInformation.userName;
      let uom = this.state.boi.boiMappingsList;
      const tempList = [];
      this.state.selectedProgramDetails.map((dimension) => {
        return dimension.SubDimensionList.map((subDimension) => {
          return subDimension.BOIList.map((boi) => {
            let realBOIID = 0;
            uom.map((uomMapping) => {
              if(uomMapping.UOM === boi.UOM && uomMapping.BOIName === boi.BOIName){
                realBOIID = uomMapping.BOIID;
              } 
            })  
            var approvalData = {
              clientProgramTargetID: boi.ClientProgramTargetID,
              clientProgramID: this.state.selectedProgramID,
              clientProgramName: this.state.selectedProgramName,
              coreDimensionID: dimension.CoreDimensionID, 
              coreDimensionName: dimension.CoreDimensionName,
              coreDimensionTarget: dimension.CoreDimensionTarget,
              coreDimensionStatus: dimension.CoreDimensionStatus === null || dimension.CoreDimensionStatus === "" ? "Approve" : dimension.CoreDimensionStatus,
              coreDimensionReason: dimension.CoreDimensionReason,
              subDimensionID: subDimension.SubDimensionID,
              subDimensionName: subDimension.SubDimensionName,
              subDimensionTarget: subDimension.SubDimensionTarget,
              subDimensionStatus: subDimension.SubDimensionStatus  === null || subDimension.SubDimensionStatus  === "" ? "Approve" : subDimension.SubDimensionStatus,
              subDimensionReason: subDimension.SubDimensionReason,
              boiid: realBOIID,
              boiName: boi.BOIName,
              boiDescription: boi.BOIDescription,
              boiTarget: boi.BOITarget === "" ? 0 : boi.BOITarget,
              boiStatus: boi.BOIStatus  === null || boi.BOIStatus  === "" ? "Approve" : boi.BOIStatus,
              boiReason: boi.BOIReason,
              uom: boi.UOM,
              rejectionRecipient: boi.rejectionRecipient === null ? "" : boi.rejectionRecipient,
            };
            tempList.push(approvalData);
            return approvalData;
          });
        });
      });
      var bodyParam = tempList;
      let token = await Utilities.getToken();
      await fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/AddEditClientProgramTarget?programID=${programID}&userID=${userID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control":"no-store",
            pragma: "no-cache",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(bodyParam),
        }
      );
      this.uploadFiles();
      await fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/AddEditClientProgramTargetStatus?programID=${programID}&userID=${userID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control":"no-store",
            pragma: "no-cache",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(bodyParam),
        }
      );
      let inputData = {
        programTargets: bodyParam,
        stakeholders,
        status: isApproved,
        userName,
        comment: this.state.submitComment,
      };
        var response = await HttpPost(`ProgramIdentifiedValueSettings/SendStakeholderEmail`, inputData);
        if(response.IsWorkflowCompleted === true){
          this.props.setToasterParam({
            show: true,
            type: 'success',
            message: "Your Progress was submitted successfully!",
          });
          this.props.history.push("/ClientConfiguration"); //("/ClientConfiguration/projectdetails")
        }
        else if(response.IsWorkflowCompleted === false){
          this.props.setToasterParam({
            show: true,
            type: 'success',
            message: "Your Progress was submitted successfully!",
          });
          this.props.history.push("/ClientConfiguration");
        } 
    };

    clearUpload = () => {
      this.setState({
        Files: [],
      });
    }

    checkRejectedStatus() {
      this.setState({
        isRejected: false //set default value for isRejected
      }, () => {
        let program = this.state.selectedProgramDetails;
        let isRejected = false;
        for (var i = 0; i < program.length && !this.state.isRejected; i++) {
          if (program[i].CoreDimensionStatus === "Rejected") {
            isRejected = true;
            break;
          } else {
            for (
              var j = 0;
              j < program[i].SubDimensionList.length && !this.state.isRejected;
              j++
            ) {
              if (
                program[i].SubDimensionList[j].SubDimensionStatus === "Rejected"
              ) {
                isRejected = true;
                break;
              } else {
                for (
                  var k = 0;
                  k < program[i].SubDimensionList[j].BOIList.length &&
                  !this.state.isRejected;
                  k++
                ) {
                  if (
                    program[i].SubDimensionList[j].BOIList[k].BOIStatus ===
                    "Rejected"
                  ) {
                    isRejected = true;
                    break;
                  }
                }
              }
            }
          }
        }
        this.setState({
          isRejected: isRejected
        })
      })
    }

    onOpenAddBOIModal = () => {
      let data = this.state.BOIDetailsList;
      this.setState({
        ...this.state,
        show: true,
        stagingBOIDetailsList: JSON.stringify(data),
      });
    };

    onCancelAddBOI = () => {
      let data = this.state.stagingBOIDetailsList;
      this.setState({
        ...this.state,
        show: false,
        BOIDetailsList: JSON.parse(data),
        selectedBOI: {},
        selectedUOM: {},
        dimensionValue: {},
        subdimensionValue: {},
        fieldValidation: {
          BOIValid: true,
          UOMValid: true,
        },
      });
    };

    showCommentsSection = () => {
      this.setState({
        showcomments: true,
      });
    };
    closeCommentsSection = () => {
      this.setState({
        showcomments: false,
      });
    };
    showRejectionModal = () => {
      this.setState({
        showRejection: true,
      });
    };
    closeRejectionModal = () => {
      this.setState({
        showRejection: false,
      });
    };
    closeRequestModal = () => {
      this.setState({
        approvalRequestModal: false,
      });
    };
    onApprovalRequest = () => {
      this.setState({
        approvalRequestModal: true,
      });
    };
    closeConditionalApproveModal = () => {
      this.setState({
        showConditionalApproveModal: false,
      });
    };

    getClientOperationAccountLead = async () => {
      let client = this.props.SharedData.selectedClient;
      let token = await Utilities.getToken();
      fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/GetClientOperationAccountLead?clientId=${client.value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control":"no-store",
            pragma: "no-cache",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            ...this.state,
            clientOperationAccountLead: data,
          });
        });
    };

    sendApproval = async () => {
      let client = this.props.SharedData.selectedClient;
      let user = this.props.userInformation;
      let operationsAccountLead = this.state.clientOperationAccountLead;
      let uom = this.state.boi.boiMappingsList;
      const tempList = [];
      this.state.selectedProgramDetails.map((dimension) => {
        return dimension.SubDimensionList.map((subDimension) => {
          return subDimension.BOIList.map((boi) => {
            let realBOIID = 0;
            uom.map((uomMapping) => {
              if(uomMapping.UOM === boi.UOM && uomMapping.BOIName === boi.BOIName){
                realBOIID = uomMapping.BOIID;
              } 
            })   
            var approvalData = {
              clientProgramTargetID: boi.ClientProgramTargetID,
              clientProgramID: this.state.selectedProgramID,
              clientProgramName: this.state.selectedProgramName,
              coreDimensionID: dimension.CoreDimensionID,
              coreDimensionName: dimension.CoreDimensionName,
              coreDimensionTarget: dimension.CoreDimensionTarget,
              coreDimensionStatus: dimension.CoreDimensionStatus,
              coreDimensionReason: dimension.CoreDimensionReason,
              subDimensionID: subDimension.SubDimensionID,
              subDimensionName: subDimension.SubDimensionName,
              subDimensionTarget: subDimension.SubDimensionTarget,
              subDimensionStatus: subDimension.SubDimensionStatus,
              subDimensionReason: subDimension.SubDimensionReason,
              boiid: realBOIID,
              boiName: boi.BOIName,
              boiDescription: boi.BOIDescription,
              boiTarget: boi.BOITarget === "" ? 0 : boi.BOITarget,
              boiStatus: boi.BOIStatus,
              boiReason: boi.BOIReason,
              uom: boi.UOM,
              rejectionRecipient: boi.rejectionRecipient === null ? "" : boi.rejectionRecipient,
            };
            tempList.push(approvalData);
            return approvalData;
          });
        });
      });
      var bodyParam = {
        operationsAccountLead: operationsAccountLead.OperationsAccountLead,
        operationsAccountLeadUserID:
          operationsAccountLead.OperationsAccountLeadUserID,
        comments: this.state.sendForApprovalComment,
        clientProgramTargetList: tempList,
        clientName: client.label,
        programID: this.state.selectedProgramID,
        programName: this.state.selectedProgramName,
        userID: user.userID,
      };
      let token = await Utilities.getToken();
      this.setState({
        disableSendBtn: true
      }, () => {
        fetch(
          Utilities.getApiHttps() +
            `ProgramIdentifiedValueSettings/SendNotificationForApproval`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Cache-Control":"no-store",
              pragma: "no-cache",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(bodyParam),
          }
        )
          .then((response) => response.text())
          .then((data) => {
            if (data === "success") {
              this.props.setToasterParam({
                show: true,
                type: 'success',
                message: "Notification sent successfully",
              });
              this.props.history.push("/ClientConfiguration");
            }
  
            this.setState({
              disableSendBtn: false,
              approvalRequestModal: false,
            });
          });
      })
    };

    showCommentsSection = () => {
      this.setState({
        showcomments: true,
      });
    };
    closeCommentsSection = () => {
      this.setState({
        showcomments: false,
      });
    };
    showRejectionModal = () => {
      this.setState({
        showRejection: true,
      });
    };
    closeRejectionModal = () => {
      this.setState({
        showRejection: false,
      });
    };
    closeRequestModal = () => {
      this.setState({
        approvalRequestModal: false,
      });
    };
    onApprovalRequest = () => {
      this.setState({
        approvalRequestModal: true,
      });
    };
    closeConditionalApproveModal = () => {
      this.setState({
        showConditionalApproveModal: false,
      });
    };

    getBOIList = async () => {
        const res = await HttpGet(
          `ProgramIdentifiedValueSettings/GetProgramTargetComments?programId=${this.state.selectedProgramID}`
        )
        this.setState({
          commentList: res,
        })
    };

    onCloseSubmitSettingsModal = () => {
      this.setState({
        ...this.state,
        showSubmitSettingsModal: !this.state.showSubmitSettingsModal,
      });
    };
    callbackFunction = (event, key) => {
      let tempFormData = this.state.validateBOI;
      let fieldsValidation = this.state.fieldValidation;
      switch (key) {
        case "boi":
          tempFormData.boi = event.value;
          fieldsValidation.BOIValid = true;
          let tempUOMList = [];
          let UOMList = this.state.boi.uomList;
          let BOIList = this.state.boi.boiList;
          
          for (var i = 0; i < UOMList.length; i++) {
            let shouldAdd = false;
            let boiID = UOMList[i].BOIID;
            for (var j = 0; j < BOIList.length; j++){
              if(BOIList[j].BOIID === boiID &&  BOIList[j].BOIName === event.label){
                shouldAdd = true;
                break;
              }
            }
            if (shouldAdd) {
              tempUOMList.push({
                value: UOMList[i].Value,
                label: UOMList[i].UOM,
                text: UOMList[i].Text,
              });
            }
          }
          this.setState({
            ...this.state,
            selectedBOI: event,
            UOMoptions: tempUOMList,
            dimensionValue: {},
            subdimensionValue: {},
          });
          break;
        case "uom":
          tempFormData.uom = event.value;
          fieldsValidation.UOMValid = true;
          let boi = this.state.selectedBOI.label;
          let dimension = {};
          let subDimension = {};
          let boiMapping = this.state.boi.boiMappingsList;
          for (var i = 0; i < boiMapping.length; i++) {
            if (
              boiMapping[i].BOIName === boi &&
              boiMapping[i].UOM === event.label
            ) {
              dimension = {
                name: boiMapping[i].CoreDimensionName,
                id: boiMapping[i].CoreDimensionID,
              };
              subDimension = {
                name: boiMapping[i].SubDimensionName,
                id: boiMapping[i].SubDimensionID,
              };
            }
          }
          this.setState({
            ...this.state,
            selectedUOM: event,
            dimensionValue: dimension,
            subdimensionValue: subDimension,
          });
          break;
        default:
          break;
      }
      this.setState({
        validateBOI: tempFormData,
        fieldValidation: fieldsValidation,
      });
    };
    AddBOI = () => {
      let fieldsValidation = this.state.fieldValidation;
      let fields = this.state.validateBOI;
      let shouldAddBOI = true;
      let data = this.state.BOIDetailsList;
      const isExist = data.filter(
        (x) =>
          x.selectedBOI.value === this.state.selectedBOI.value &&
          x.selectedUOM.value === this.state.selectedUOM.value &&
          x.dimensionValue.name.toLowerCase() ===
            this.state.dimensionValue.name.toLowerCase() &&
          x.subdimensionValue.name.toLowerCase() ===
            this.state.subdimensionValue.name.toLowerCase()
      );
      if (fields.boi === "") {
        fieldsValidation.BOIValid = false;
        shouldAddBOI = false;
      }
      if (fields.uom === "") {
        fieldsValidation.UOMValid = false;
        shouldAddBOI = false;
      }
      if (!shouldAddBOI) {
        this.setState({
          fieldValidation: fieldsValidation,
          erroMessage: "Missing required fields!",
        });
        return;
      }
      if (isExist.length === 0) {
        data.push({
          selectedBOI: this.state.selectedBOI,
          boiList: this.state.BOIoptions,
          selectedUOM: this.state.selectedUOM,
          uomList: this.state.UOMoptions,
          dimensionValue: this.state.dimensionValue,
          subdimensionValue: this.state.subdimensionValue,
        });
        let tempBOIOptions = this.state.BOIoptions;
        let tempUOMOptions = this.state.UOMoptions;
        this.setState(
          {
            ...this.state,
            BOIDetailsList: data,
            fieldValidation: fieldsValidation,
            BOIoptions: {},
            UOMoptions: {},
            dimensionValue: "",
            subdimensionValue: "",
            fieldValidation: {
              BOIValid: true,
              UOMValid: true,
            },
          },
          () => {
            this.setState({
              ...this.state,
              BOIoptions: tempBOIOptions,
              UOMoptions: tempUOMOptions,
              fieldValidation: fieldsValidation,
              selectedBOI: {},
              selectedUOM: {},
              dimensionValue: "",
              subdimensionValue: "",
              validateBOI: {
                boi: "",
                uom: "",
              },
            });
          }
        );
      } else {
        const fieldValidation = this.state.fieldValidation;
        fieldValidation.BOIValid = false;
        fieldValidation.UOMValid = false;
        this.setState({
          ...this.state,
          fieldValidation: fieldValidation,
          erroMessage: "BOI already exist!",
        });
      }
    };
    saveOnChange = (event, key, index) => {
      let data = this.state.BOIDetailsList;
      switch (key) {
        case "boi":
          let tempUOMList = [];
          let UOMList = this.state.boi.uomList;
          let BOIList = this.state.boi.boiList;
          
          for (var i = 0; i < UOMList.length; i++) {
            let shouldAdd = false;
            let boiID = UOMList[i].BOIID;
            for (var j = 0; j < BOIList.length; j++){
              if(BOIList[j].BOIID === boiID &&  BOIList[j].BOIName === event.label){
                shouldAdd = true;
                break;
              }
            }
            if (shouldAdd) {
              tempUOMList.push({
                value: UOMList[i].Value,
                label: UOMList[i].UOM,
                text: UOMList[i].Text,
              });
            }
          }

          data[index].selectedBOI = event;
          data[index].selectedUOM = {};
          data[index].uomList = tempUOMList;
          break;
        case "uom":
          let boi = data[index].selectedBOI;
          let boiMapping = this.state.boi.boiMappingsList;
          for (var i = 0; i < boiMapping.length; i++) {
            if (
              boiMapping[i].BOIID === boi.value &&
              boiMapping[i].UOM === event.label
            ) {
              data[index].dimensionValue = {
                name: boiMapping[i].CoreDimensionName,
                id: boiMapping[i].CoreDimensionID,
              };
              data[index].subdimensionValue = {
                name: boiMapping[i].SubDimensionName,
                id: boiMapping[i].SubDimensionID,
              };
            }
          }
          data[index].selectedUOM = event;
          break;
        default:
          break;
      }
      this.setState({
        BOIDetailsList: data,
      });
    };
    deleteBOI = (index) => {
      let data = this.state.BOIDetailsList;
      data.splice(index, 1);
      this.setState({
        BOIDetailsList: data,
      });
    };
    onShowSaveDraftToast = () => {
      this.setState({
        showSaveDraftToast: true,
      });
    };
    onCloseSaveDraftToast = () => {
      this.setState({
        showSaveDraftToast: false,
      });
    };
    onSaveBOI = () => {
      let item = this.state.BOIDetailsList;
      let dimensions = [];

      for (var i = 0; i < item.length; i++) {
        let dimensionIndex = -1;
        let boi = {};
        let boiOpts = item[i].boiList;
        // get boi details to be added
        for (var x = 0; x < boiOpts.length; x++) {
          if (boiOpts[x].value === item[i].selectedBOI.value) {
            boi = {
              BOIID: boiOpts[x].value,
              BOIName: boiOpts[x].label,
              BOICode: boiOpts[x].boiCode,
              BOIDescription: boiOpts[x].description,
              BOITarget: boiOpts[x].boiTarget === 0 ? "" : boiOpts[x].boiTarget,
              BOIStatus: boiOpts[x].boiStatus,
              BOIReason: boiOpts[x].boiReason,
              UOM: item[i].selectedUOM.value,
              Value: boiOpts[x].value,
              Text: boiOpts[x].text,
              RejectionRecipient: boiOpts[x].rejectionRecipient,
              ClientProgramTargetID: boiOpts[x].clientProgramTargetID,
            };
            break;
          }
        }

        for (var j = 0; j < dimensions.length; j++) {
          if (dimensions[j].CoreDimensionID === item[i].dimensionValue.id) {
            dimensionIndex = j;
          }
        }
        //dimension does not exist create new entry
        if (dimensionIndex < 0) {
          dimensions.push({
            CoreDimensionID: item[i].dimensionValue.id,
            CoreDimensionName: item[i].dimensionValue.name,
            CoreDimensionTarget: "",
            CoreDimensionStatus: "",
            CoreDimensionReason: "",
            SubDimensionList: [
              {
                SubDimensionID: item[i].subdimensionValue.id,
                SubDimensionName: item[i].subdimensionValue.name,
                SubDimensionTarget: "",
                SubDimensionStatus: "",
                SubDimensionReason: "",
                BOIList: [boi],
              },
            ],
          });
        }
        //dimension exist, update to add details
        else {
          let subDimensionIndex = -1;
          for (
            var k = 0;
            k < dimensions[dimensionIndex].SubDimensionList.length;
            k++
          ) {
            if (
              dimensions[dimensionIndex].SubDimensionList[k].SubDimensionID ===
              item[i].subdimensionValue.id
            ) {
              subDimensionIndex = k;
            }
          }
          // check subdimension if existing, if not create new entry
          if (subDimensionIndex < 0) {
            dimensions[dimensionIndex].SubDimensionList.push({
              SubDimensionID: item[i].subdimensionValue.id,
              SubDimensionName: item[i].subdimensionValue.name,
              SubDimensionTarget: "",
              SubDimensionStatus: "",
              SubDimensionReason: "",
              BOIList: [boi],
            });
          } else {
            dimensions[dimensionIndex].SubDimensionList[
              subDimensionIndex
            ].BOIList.push(boi);
          }
        }
      }
      this.setState({
        ...this.state,
        selectedProgramDetails: dimensions,
        onSaveBOIDetails: dimensions,
        dimensionValue: "",
        subdimensionValue: "",
        fieldValidation: {
          BOIValid: true,
          UOMValid: true,
        },
        show: !this.state.show,
      });
    };
    saveDraft = async () => {
      let programID = this.state.selectedProgramID;
      let userID = store.getState().userInformation.userID;
      let uom = this.state.boi.boiMappingsList;
      const tempList = [];
      this.state.selectedProgramDetails.map((dimension) => {
        return dimension.SubDimensionList.map((subDimension) => {
          return subDimension.BOIList.map((boi) => {
            let realBOIID = 0;
            uom.map((uomMapping) => {
              if(uomMapping.UOM === boi.UOM && uomMapping.BOIName === boi.BOIName){
                realBOIID = uomMapping.BOIID;
              } 
            })            
            var approvalData = {
              clientProgramTargetID: boi.ClientProgramTargetID,
              clientProgramID: this.state.selectedProgramID,
              clientProgramName: this.state.selectedProgramName,
              coreDimensionID: dimension.CoreDimensionID,
              coreDimensionName: dimension.CoreDimensionName,
              coreDimensionTarget: dimension.CoreDimensionTarget,
              coreDimensionStatus: dimension.CoreDimensionStatus,
              coreDimensionReason: dimension.CoreDimensionReason,
              subDimensionID: subDimension.SubDimensionID,
              subDimensionName: subDimension.SubDimensionName,
              subDimensionTarget: subDimension.SubDimensionTarget,
              subDimensionStatus: subDimension.SubDimensionStatus,

              subDimensionReason: subDimension.SubDimensionReason,
              boiid: realBOIID,
              boiName: boi.BOIName,
              boiDescription: boi.BOIDescription,
              boiTarget: boi.BOITarget === "" ? 0 : boi.BOITarget,
              boiStatus: boi.BOIStatus,
              boiReason: boi.BOIReason,
              uom: boi.UOM,
              rejectionRecipient: boi.rejectionRecipient === null ? "" : boi.rejectionRecipient,
            };
            tempList.push(approvalData);
            return approvalData;
          });
        });
      });
      var bodyParam = tempList;
      let token = await Utilities.getToken();
      fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/AddEditClientProgramTarget?programID=${programID}&userID=${userID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control":"no-store",
            pragma: "no-cache",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(bodyParam),
        }
      )
        .then((response) => response.text())
        .then((data) => {
          if (data === "success") {
            this.props.setToasterParam({
              show: true,
              type: 'success',
              message: "Your Progress was saved as draft successfully!",
            });
            this.props.history.push("/ClientConfiguration");
          }
        });
    };

    saveDraftApprover = async () => {
      let programID = this.state.selectedProgramID;
      let userID = store.getState().userInformation.userID;
      let uom = this.state.boi.boiMappingsList;
      const tempList = [];
      this.state.selectedProgramDetails.map((dimension) => {
        return dimension.SubDimensionList.map((subDimension) => {
          return subDimension.BOIList.map((boi) => {
            let realBOIID = 0;
            uom.map((uomMapping) => {
              if(uomMapping.UOM === boi.UOM && uomMapping.BOIName === boi.BOIName){
                realBOIID = uomMapping.BOIID;
              } 
            })  
            var approvalData = {
              clientProgramTargetID: boi.ClientProgramTargetID,
              clientProgramID: this.state.selectedProgramID,
              clientProgramName: this.state.selectedProgramName,
              coreDimensionID: dimension.CoreDimensionID,
              coreDimensionName: dimension.CoreDimensionName,
              coreDimensionTarget: dimension.CoreDimensionTarget,
              coreDimensionStatus: dimension.CoreDimensionStatus,
              coreDimensionReason: dimension.CoreDimensionReason,
              subDimensionID: subDimension.SubDimensionID,
              subDimensionName: subDimension.SubDimensionName,
              subDimensionTarget: subDimension.SubDimensionTarget,
              subDimensionStatus: subDimension.SubDimensionStatus,

              subDimensionReason: subDimension.SubDimensionReason,
              boiid: realBOIID,
              boiName: boi.BOIName,
              boiDescription: boi.BOIDescription,
              boiTarget: boi.BOITarget === "" ? 0 : boi.BOITarget,
              boiStatus: boi.BOIStatus,
              boiReason: boi.BOIReason,
              uom: boi.UOM,
              rejectionRecipient: boi.rejectionRecipient === null ? "" : boi.rejectionRecipient,
            };
            tempList.push(approvalData);
            return approvalData;
          });
        });
      });
      var bodyParam = tempList;
      let token = await Utilities.getToken();
      await fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/AddEditClientProgramTarget?programID=${programID}&userID=${userID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control":"no-store",
            pragma: "no-cache",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(bodyParam),
        }
      );
      await fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/AddEditClientProgramTargetStatus?programID=${programID}&userID=${userID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control":"no-store",
            pragma: "no-cache",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(bodyParam),
        }
      )
        .then((response) => response.text())
        .then((data) => {
          if (data === "success") {
            this.props.setToasterParam({
              show: true,
              type: 'success',
              message: "Your Progress was saved as draft successfully!",
            });
            this.props.history.push("/ClientConfiguration");
          }
        });
    };

    async fetchBOIData(programID) {
      let token = await Utilities.getToken();
      fetch(
        Utilities.getApiHttps() +
          `ProgramIdentifiedValueSettings/GetBOIDetails?programID=${programID}`,
        {
          method: "GET",
          headers: {
            "Cache-Control":"no-store",
            Pragma: "no-cache",
            Expires: 0,
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          var boiListTemp = [];
          var boiNames = [];
          for (var i = 0; i < data.BOIList.length; i++) {
            if(boiNames.indexOf(data.BOIList[i].BOIName) === -1){
              boiListTemp.push({
                value: data.BOIList[i].BOIID,
                label: data.BOIList[i].BOIName,
                text: data.BOIList[i].Text,
                description: data.BOIList[i].BOIDescription,
                clientProgramTargetID: data.BOIList[i].ClientProgramTargetID,
                rejectionRecipient: data.BOIList[i].RejectionRecipient,
                boiReason: data.BOIList[i].BOIReason,
                boiStatus: data.BOIList[i].BOIStatus,
                boiTarget: data.BOIList[i].BOITarget,
                boiCode: data.BOIList[i].BOICode,
              });
              boiNames.push(data.BOIList[i].BOIName);
            }
            
          }
          this.setState(
            {
              ...this.state,
              BOIoptions: boiListTemp,
              boi: {
                boiList: data.BOIList,
                uomList: data.BOIUnitOfMeasureList,
                boiMappingsList: data.BioMappings,
              },
            },
            this.prepareBOIData
          );
        });
    }
    sendForApprovalComment = (e) => {
      this.setState({
        ...this.state,
        sendForApprovalComment: e.target.value,
      });
    };

    onExpandCollapse = (e) => {
      let elems = [];
      switch (e.currentTarget.id) {
        case "expandAll":
          elems = document.getElementsByClassName("collapse");
          // while (document.getElementsByClassName("collapse").length) {
          for (var i = 0; i < elems.length; i++) {
            if (elems[i].className === "collapse") {
              document
                .getElementsByClassName("collapse")
                [
                  i
                ].parentElement.childNodes[1].childNodes[0].childNodes[1].childNodes[0].click()
            }
          }
          break;
        case "collapseAll":
          elems = document.getElementsByClassName("collapse show");
          // while (document.getElementsByClassName("collapse show").length) {
          for (var i = 0; i < elems.length; i++) {
            if (elems[i].className === "collapse show") {
              document
                .getElementsByClassName("collapse show")
                [
                  i
                ].parentElement.childNodes[1].childNodes[0].childNodes[1].childNodes[0].click()
            }
          }
          break;
        default:
          break;
      }
    };

    render() {
      const { clientList, selectedClient } = this.props.SharedData;

      const { setToasterParam } = this.props;

      return (
        <ProgramIdentifiedPresentational
          {...this.state}
          onOpenAddBOIModal={this.onOpenAddBOIModal}
          onCancelAddBOI={this.onCancelAddBOI}
          showCommentsSection={this.showCommentsSection}
          closeCommentsSection={this.closeCommentsSection}
          closeRejectionModal={this.closeRejectionModal}
          closeRequestModal={this.closeRequestModal}
          onApprovalRequest={this.onApprovalRequest}
          closeConditionalApproveModal={this.closeConditionalApproveModal}
          onCloseSubmitSettingsModal={this.onCloseSubmitSettingsModal}
          callbackFunction={this.callbackFunction}
          AddBOI={this.AddBOI}
          deleteBOI={this.deleteBOI}
          saveOnChange={this.saveOnChange}
          onCloseSaveDraftToast={this.onCloseSaveDraftToast}
          onShowSaveDraftToast={this.onShowSaveDraftToast}
          onSaveBOI={this.onSaveBOI}
          programs={this.state.programList}
          saveDraftApprover={this.saveDraftApprover}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          onDeleteFile={this.onDeleteFile}
          onSubmitClick={this.onSubmitClick}
          updateDimension={this.updateDimension}
          updateSubDimension={this.updateSubDimension}
          updateBOI={this.updateBOI}
          closeReasonModal={this.closeReasonModal}
          sendApproval={this.sendApproval}
          saveDraft={this.saveDraft}
          sendForApprovalComment={this.sendForApprovalComment}
          setSelectedProgram={this.setSelectedProgram}
          onExpandCollapse={this.onExpandCollapse}
          clientOperationAccountLead={this.state.clientOperationAccountLead}
          erroMessage={this.state.erroMessage}
          clearUpload={this.clearUpload}
          selectedProgram={this.state.selectedProgram}
          submitComment={this.submitComment}
          disableSendBtn={this.state.disableSendBtn}
        />
      );
    }
  }

  return ProgramIdentifiedContainer;
})();

export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      userInformation: state.userInformation,
    };
  },
  (dispatch) =>
    bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(withRouter(ProgramIdentified));
