import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../../store';
import { GrooveIconSvg, GrooveInput, GrooveButton } from 'tfo-groove';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';

//actions
import { getMappedStakeHolders } from '../../../Actions/ConfigureProgram.Action';
import {
  setDeliveryCenterSelected,
} from '../../../Actions/DataIngestion.Action';
import { getNotificationHistoryData } from '../../../Actions/Notifcation.Action';

//components
import CVMPagination from '../../Shared/CVMPagination/CVMPagination';
import CVMTable from '../../Shared/CVMTable/CVMTable';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import AddFileIcon from '../../../Static/images/360cvm/AddFilesIcon.svg';

//css
import './NotificationHistory.css';

const FilterPopover = (function () {
  class FilterPopoverPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div>
            <div>
              <div className="search-bar-container" id="popover-searchbox">
                <div className="search-bar label14-regular-midnight">
                  <GrooveIconSvg
                    customClassName="close-modal-btn"
                    size="large"
                    name="search"
                    iconStyle="solid"
                    iconColor="stat-neutral"
                  />
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    placeholder={`Search ${this.props.placeholder}...`}
                    id="searchBox"
                    maxlength="255"
                    onChange={(e) => {
                      this.props.handleSearchFilter(e);
                    }}
                  />
                </div>
              </div>

              <div className="filter-select-table">
                <ul>
                  {this.props.options.map((item, index) => {
                    return (
                      <li key={index}>
                        <label class="checkbox-container">
                          <input
                            type="checkbox"
                            onChange={(e) => this.props.handleCheckbox(e.target.checked, item)}
                            checked={item.isChecked}
                          />
                          <span class="checkmark-checkbox"></span>
                          {item.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="filter-action-btns">
              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="outline"
                colorClass="gr-silver"
                size="auto"
                text="Clear"
                callbackFunction={() => this.props.handleClearFilter()}
              />
              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="solid"
                colorClass="stat-alternate"
                size="auto"
                text="Apply"
                callbackFunction={() => this.props.handleApplyFilter()}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  class FilterPopoverContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        options: [],
      };
    }

    componentDidMount = () => {
      try {
        console.log('mount');
        this.setState({
          options: this.props.options,
        });
      } catch (e) {
        console.log(e);
      }
    };

    componentDidUpdate = () => {
      console.log('Udate');
    };

    handleSearchFilter = (e) => {
      try {
        let options = [];
        let tOptions = this.props.options;

        tOptions.map((op) => {
          if (op.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
            options.push(op);
          }
        });
        this.setState({
          options: options,
        });
      } catch (e) {
        console.log(e);
      }
    };

    handleCheckbox = (e, item) => {
      try {
        let options = [];
        let tOptions = this.state.options;

        tOptions.map((op) => {
          options.push({
            isChecked: op.value === item.value ? e : op.isChecked,
            label: op.label,
            value: op.value,
          });
        });

        this.setState({
          options: options,
        });
      } catch (e) {
        console.log(e);
      }
    };

    handleApplyFilter = () => {
      try {
        let options = [];
        let tOptions = this.state.options;

        tOptions.map((op) => {
          if (op.isChecked) {
            options.push(op);
          }
        });
        this.props.handleApplyFilter(options);
        // document.body.click();
      } catch (e) {
        console.log(e);
      }
    };

    handleClearFilter = () => {
      try {
        let options = [];
        let tOptions = this.state.options;

        // options[options.indexOf(item)].isChecked = e;
        tOptions.map((op) => {
          options.push({
            isChecked: false,
            label: op.label,
            value: op.value,
          });
        });

        this.setState({
          options: options,
        });
      } catch (e) {
        console.log(e);
      }
    };

    render() {
      return (
        <FilterPopoverPresentational
          handleCheckbox={this.handleCheckbox}
          handleApplyFilter={this.handleApplyFilter}
          handleClearFilter={this.handleClearFilter}
          //add
          options={this.state.options}
          placeholder={this.props.placeholder}
          handleSearchFilter={this.handleSearchFilter}
        />
      );
    }
  }

  return FilterPopoverContainer;
})();
export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      clientID: state.SharedData.selectedClient.value,
      reportingYear: state.ClientConfigurationData.clientDetails.reportingYear,
      SharedData: state.SharedData,
      UserInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
      DataIngestionData: state.DataIngestionData,
      NotificationData: state.NotificationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getMappedStakeHolders,
          setDeliveryCenterSelected,
          getNotificationHistoryData,
        }
      ),
      dispatch
    )
)(FilterPopover);
