import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { GrooveSelectMenuBasic, GrooveButton, GrooveIconSvg } from "tfo-groove";
import CVMDateRange from "../../../Shared/CVMDateRange/CVMDateRange";

const NewProjectDataEntry = (function () {
  class NewProjectDataEntryPresentational extends Component {
    render() {
      const mainSelectedProject = this.props.mainSelectedProject;
      let projectDetails = mainSelectedProject.projectDetails;
      const onLoad = Object.keys(mainSelectedProject).length > 0 ? false : true;
      let deliveryLocationLst = projectDetails.deliveryLocations !== "" ? projectDetails.deliveryLocations.map((dl) => {
        return {
          value: dl.deliveryCenterID,
          text: dl.deliveryCenterName,
          label: dl.deliveryCenterName,
        }
      }) : [];

      let selectedDeliveryCenter = projectDetails.deliveryCenterID === "" ?
        projectDetails.deliveryLocations.length === 1 ?
          this.props.onChangeSelectProject({
            value: projectDetails.deliveryLocations[0].deliveryCenterID,
            text: projectDetails.deliveryLocations[0].deliveryCenterName,
            label: projectDetails.deliveryLocations[0].deliveryCenterName,
          }, "deliveryLocation")
          :
          {}
        :
        {
          value: projectDetails.deliveryCenterID,
          text: projectDetails.deliveryCenterName,
          label: projectDetails.deliveryCenterName,
        };
      //yymmdd to ddmmyy
      let startDate = new Date(projectDetails.creationDate);
      let endDate = projectDetails.lastReportingDate === "" ? {} : new Date(projectDetails.lastReportingDate);
      return (
        <React.Fragment>
          <div className="add-new-program-container">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveSelectMenuBasic
                  name="valueMeterProgramName"
                  isDisabled={true}
                  list={this.props.programOptions}
                  placeholder={
                    onLoad
                      ? "Select Value Meter Program Name*"
                      : mainSelectedProject.programID !== ""
                        ? "Program"
                        : "Select Value Meter Program Name*"
                  }
                  id="valueMeterProgramName"
                  customClassName={this.props.fieldValidation.valueMeterProgramNameValid ? "" : " dropdown-invalid"}
                  defaultData={this.props.selectedProgram}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveSelectMenuBasic
                  name="project"
                  isDisabled={true}
                  placeholder={
                    onLoad
                      ? "Select Project*"
                      : mainSelectedProject.projectDetails.clientProjectID !== ""
                        ? "Project"
                        : "Select Project*"
                  }
                  list={this.props.projectOptions}
                  id="project"
                  customClassName={this.props.fieldValidation.projectValid ? "" : " dropdown-invalid"}
                  // callbackFunction={(e) => this.props.onChangeSelectProject(e, "project")}
                  defaultData={this.props.selectedProject}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveSelectMenuBasic
                  name="deliveryLocation"
                  placeholder={
                    onLoad
                      ? "Select Delivery Location*"
                      : mainSelectedProject.projectDetails.deliveryCenterID !== ""
                        ? "Delivery Location"
                        : "Select Delivery Location"
                  }
                  list={deliveryLocationLst}
                  id="deliveryLocation"
                  customClassName={this.props.fieldValidation.deliveryLocationValid ? "" : " dropdown-invalid"}
                  callbackFunction={(e) => this.props.onChangeSelectProject(e, "deliveryLocation")}
                  defaultData={selectedDeliveryCenter}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <CVMDateRange
                  isStartDateDisabled={true}
                  isEndDateDisabled={false}
                  start={startDate}
                  end={endDate}
                  placeholder="Select Reporting Period"
                  required={true}
                  callbackFunction={(e) => this.props.onChangeSelectProject(e, "lastReportingDate")}
                  label={"Reporting Period"}
                  customCSS={this.props.fieldValidation.reportingPeriodValid ? "" : "cvm-date-range-input-required"}
                />
              </Col>
            </Row>
          </div>
          {this.props.showNotifMessage ? (< div className="newProjectDataEntry-Disclaimer-Message">
            <div className="exclamation-icon-disclaimerMessage">
              <GrooveIconSvg
                size="normal"
                name="exclamation"
                iconStyle="solid"
                className="exclamation-icon"
              />
            </div>
            <div className="disclaimer-text">BOIs with $ UOM selected under Financial Dimension will be Considered as client P&L Sign-Off. $ value under all other Dimensions except Financial Dimension, will not be considered as Client P&L Sign-Off.</div>
          </div>) : ''}
          <div className="add-program-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={() => {
                this.props.onCloseSelectProjectModal();
              }}
            />
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              hasIcon={false}
              isDisabled={
                mainSelectedProject.programName !== "" ||
                  mainSelectedProject.projectDetails.clientProjectName !== "" ||
                  mainSelectedProject.projectDetails.deliveryCenterName !== ""
                  ? this.props.isDisableRPD
                  : true
              }
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Report Project Data"
              callbackFunction={(e) => {
                this.props.onClickSelectProject(e);
              }}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class NewProjectDataEntryContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showNotifMessage: false,
        isDisableRPD: false,
      };
    }
    componentDidMount = async () => {
      this.showDisclaimerMessage();
    }

    showDisclaimerMessage = async () => {
      await this.setState({
        showNotifMessage: true
      })
      setTimeout(() =>
        this.setState({
          showNotifMessage: false
        }), 60000);
    }

    onClickSelectProject = async (data) => {
      try {
        this.setState({
          isDisableRPD: true
        }, async () => {
          await this.props.onClickSelectProject(data);
          this.setState({
            isDisableRPD: false,
          })
        })
      }
      catch(e) {
        console.log(e)
      }
    }

    render() {
      return (
        <NewProjectDataEntryPresentational
          //redux
          onCloseSelectProjectModal={this.props.onCloseSelectProjectModal}
          onChangeSelectProject={this.props.onChangeSelectProject}
          fieldValidation={this.props.fieldValidation}
          mainSelectedProject={this.props.mainSelectedProject}
          onCancelSelectProject={this.props.onCancelSelectProject}
          selectProjectDropdown={this.props.selectProjectDropdown}
          getProgramProject={this.props.getProgramProject}
          getProjectDetails={this.props.getProjectDetails}
          onClickSelectProject={this.onClickSelectProject}
          programOptions={this.props.programOptions}
          projectOptions={this.props.projectOptions}
          selectedProgram={this.props.selectedProgram}
          selectedProject={this.props.selectedProject}
          showNotifMessage={this.state.showNotifMessage}
          showDisclaimerMessage={this.showDisclaimerMessage}
          isDisableRPD={this.state.isDisableRPD}
        />
      );
    }
  }

  return NewProjectDataEntryContainer;
})();

export default NewProjectDataEntry;
