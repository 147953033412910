import React, { Component } from "react";
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import { Row, Col } from "react-bootstrap";
import { GrooveButton, GrooveIconSvg } from "tfo-groove";
import CVMModal from "../../Shared/CVMModal/CVMModal";
// import { history } from "../../../store";
import { withRouter } from "react-router-dom";
import { setEditApproved } from "../../../Actions/Shared.Action";
import "./ALATable.css";

const ApprovedBOIEditWarnModal = (function () {
	class ApprovedBOIEditWarnModalPresentational extends Component {
		render() {
			return (
				<React.Fragment>
					<GrooveIconSvg
						id="calendarIcon"
						customClassName={this.props.disabledEdit || this.props.editApproved ? "calendar-icon-dropdown disabled-edit" : "calendar-icon-dropdown"}
						size="large"
						name="edit"
						iconStyle="solid"
						iconColor="stat-alternate"
						callbackFunction={() => this.props.handleShowEditWarning(true)}
						isDisabled={this.props.disabledEdit || this.props.editApproved}
					/>
					<CVMModal
						title={"Warning"}
						show={this.props.showEditWarning}
						onCloseModal={() => this.props.handleShowEditWarning(false)}
						customclass="approved-edit-modal"
						content={
							<React.Fragment>
								<Row>
									<Row>
										<Col md={3} xs={3}>
											<GrooveIconSvg
												customClassName="warning-edit-icon"
												size="large"
												name="exclamation-triangle"
												iconStyle="solid"
												iconColor="sp-orange"
											/>
										</Col>
										<Col md={9} xs={9}>
											<Row className="approved-edit-bold">
												Are you sure you want to edit approved values?
											</Row>
											<Row>
												Please note that you will be asked to provide reason for editing the values and it will go through the approval cycle again. Please also note that you are expected to provide cumulative target values rather than providing just incremental updates.
											</Row>
										</Col>
									</Row>
									<Row>
										<Col md={12} xs={12} className="approved-edit-btn">
											<GrooveButton
												id="primry-btn-1"
												name="Solid Button Primary-Ops"
												isDisabled={false}
												hasIcon={false}
												type="outline"
												colorClass="stat-alternate"
												size="auto"
												text="Cancel"
												callbackFunction={() => this.props.handleShowEditWarning(false)}
											/>

											<GrooveButton
												id="primry-btn-1"
												name="Solid Button Primary-Ops"
												isDisabled={false}
												hasIcon={false}
												type="solid"
												colorClass="stat-alternate"
												size="auto"
												text="Yes Sure"
												callbackFunction={() => this.props.handleIsEditApprovedBOI()}
											/>
										</Col>
									</Row>
								</Row>
							</React.Fragment>
						}
					/>
				</React.Fragment >
			);
		}
	}

	class ApprovedBOIEditWarnModalContainer extends Component {
		constructor() {
			super();
			this.state = {
				showEditWarning: false,
			}
		}

		handleShowEditWarning = (e) => {
			this.setState({ showEditWarning: e })
		}

		handleIsEditApprovedBOI = () => {
			this.setState({ showEditWarning: false },
				() => {
					this.props.setEditApproved(true);
					this.editApprovedRedirect();
				})
		}

		editApprovedRedirect = () => {
			try {
				let type = this.props.type !== undefined ? this.props.type : "program";
				let url = `/ProgramProjectConfig/${type === "program" ? 'ProgramAccountLeadershipApproval/' : 'ProjectAccountLeadershipApproval/'}`
				url = url.concat('approvedboi')
				this.props.history.push(url)
			}
			catch (e) {
				console.log(e)
			}
		}

		render() {
			return (
				<ApprovedBOIEditWarnModalPresentational
					showEditWarning={this.state.showEditWarning}
					handleShowEditWarning={this.handleShowEditWarning}
					handleIsEditApprovedBOI={this.handleIsEditApprovedBOI}
					arsDetailsIndex={this.props.arsDetailsIndex}
					disabledEdit={this.props.disabledEdit}
					editApproved={this.props.SharedData.editApproved}
				/>
			);
		}
	}

	return ApprovedBOIEditWarnModalContainer;
})();


export default connect(
	state => {
		return {
			SharedData: state.SharedData,
		}
	},
	dispatch => bindActionCreators(Object.assign({},
		{
			setEditApproved
		}), dispatch)
)(withRouter(ApprovedBOIEditWarnModal));
