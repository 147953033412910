import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Utilities from '../../Utilities';
import { Row, Col, Accordion, Button, Card, Overlay, Popover } from 'react-bootstrap';
import Logo from '../../Static/images/360cvm/SynOps.svg';
import burger_menu from '../../Static/images/svg/BurgerMenu.svg';
import default_user_logo from '../../Static/images/360cvm/ProfileIcon.svg';
// import { history } from '../../store';
import { withRouter } from "react-router-dom";
import { setUserInformation } from '../../Actions/index';
import { GrooveSelectMenuBasic, GrooveIconSvg } from 'tfo-groove';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTimes, faRedoAlt, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import feedbackIcon from "../../Static/images/feedback-form-icon-new.svg";

import {
  getClientList,
  showClientDropdown,
  setSelectedClient,
  leftPanelOnClick,
  setCurrentUserDetails,
  showProfileButton,
  showLeftSideBar,
} from '../../Actions/Shared.Action';

import { setReportingYear } from '../../Actions/ClientConfiguration.Action';

import {
  clearDashboard,
  FetchDashboardData,
  getDashboardClientList,
  fetchClientDetails,
} from '../../Actions/Dashboard.Action';

import { getModules } from '../../Actions/Home.Action';

import {
  fetchAddNewProgramDetails,
  getProgramClientDetails,
  getProgramList,
} from '../../Actions/ProgramConfiguration.Action';

import samplelogo from './../../Static/images/360cvm/SampleClientLogo.png';
import CVMModal from '../Shared/CVMModal/CVMModal';
import NotificationHistoryTable from './Notifications/NotificationHistoryTable';
import { TryGetObjValueAlt, ParseJwt, StrToObj, trimText } from '../../Utilities/Formatter';
import { HasProperty } from '../../Utilities/Validations';
import {
  DeleteCallbackURL,
  LogUserSiteAction,
  LogUserBounce,
  DeleteReferrer,
  DeleteDistiURL,
} from '../../Utilities/AccessManagementUtil';
import { HttpGetText } from '../../Utilities/HTTPUtil';
import { CLIENT_CHANGE, LOGOUT, USER_SITE_ACT_PAR } from '../../Constants/Modules';
import { GetSessionStorage, GetLocalStorage } from '../../Utilities/BrowserUtil';

import {
  setNotificationData,
  refreshNotif,
  updateNotificationList,
  markAsRead,
  updateTimeStamp,
  clearNotifHistoryData,
  clearNewlyAddedList,
  getNotificationCount,
} from '../../Actions/Notifcation.Action';

import {
  clearProgramProjectList,
} from '../../Actions/DataIngestion.Action';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProfileMenu from './ProfileMenu';
import UploadSupportDocument from './UploadSupportDocument/UploadSupportDocument';
import ContactUs from './ContactUs/ContactUs';

const Header = (function () {
  class _Header extends Component {
    constructor() {
      super();
      this.state = {
        profile: false,
        burger: false,
        home: true,
        menu_icon: burger_menu,
        bo_module: false,
        dashboard_module: false,
        showModal: false,
        urlPath: '',
        modal_message: '',
        modalID: '',
        flg: true,
        clientList: [],
        showBellNotif: false,
        showProfile: false,
        showClientDropdown: false,
        showNotificationHistory: false,
        timer: 0,
        message: '',
        limit: 360,
        isLoading: false,
        isExpand: false,
        showContactUs: false
      };
      this.notificationPopoverTarget = React.createRef();
      this.scrollRef = React.createRef();
      this.notifAlertMessageTimer = null;
    }

    async componentWillMount() {
      this.checkPathName();
      this.props.getClientList();
      await this.props.getModules();

      var root = document.getElementById('root');
      let sessionWarnEl = document.createElement('div');
      sessionWarnEl.id = 'session-warning-message';
      root.insertAdjacentElement("afterend", sessionWarnEl);

      setInterval(() => {
        //IF TOKEN IS IN 5 MIN BEFORE EXPIRATION THEN TRIGGER REFRESH = 300
        try {
          const currentDT = new Date().getTime();
          const idleTime = parseInt(GetLocalStorage('idleTime'))
          const idleTimeMS = new Date(idleTime + (55 * 60000)).getTime(); // if idle 5 minutes before 1 hour show message

          // If current datetime is greater than idle time, show session warning message
          if (currentDT >= idleTimeMS) {
            document.getElementById('session-warning-message').className = 'message-show';
          } else {
            let isPreviouslyOpen =
              document.getElementById('session-warning-message').className.indexOf('message-show') !== -1;
            if (isPreviouslyOpen) {
              setTimeout(() => {
                document.getElementById('session-warning-message').className = '';
              }, 5000);
            }
          }

          if (
            document.getElementById('session-warning-message').className.indexOf('message-show') !== -1
          ) {
            let idleTimeMaxMS = new Date(idleTime + (60 * 60000)).getTime(); // Max Idle is 1 hour
            let time = (idleTimeMaxMS - currentDT) / 1000; // countdown ms convert to seconds - 5 Minute

            if (time > 0) {
              time--;

              if (time < this.state.limit) {
                sessionWarnEl.textContent = 'Session is about to expire in ' + this.timerToMins(this.state.timer) + ' due to inactivity. Unsaved work will ' +
                  'be discarded and you will be logged out of the application.';
              } else {
                sessionWarnEl.textContent = 'Session has been refreshed successfully!';
              }
            } else {
              sessionWarnEl.textContent = 'Session has expired, please refresh your browser manually.';
              time = 0;
              // localStorage.clear();
              // sessionStorage.clear();
            }
            this.setState({
              timer: time
            });
          }
        } catch (e) { }
      }, 1000);
    }

    componentDidMount = async () => {
      this.checkPathName();
      // if (!this.props.userInformation.isSuperAdmin) {
      await this.props.setNotificationData();
      setInterval(() => {
        this.props.updateTimeStamp();
      }, 60000); // 1 min

      setInterval(() => {
        this.props.getNotificationCount();
      }, 1800000); //30 mins
      // }
    };

    componentDidUpdate(prevProps) {
      try {
        if (
          (prevProps.NotificationData.newlyAddedList.length === 0 &&
            this.props.NotificationData.newlyAddedList.length > 0) ||
          (prevProps.NotificationData.newlyAddedList.length === 0 &&
            this.props.NotificationData.newlyAddedList.length === 0 &&
            this.state.isLoading)
        ) {
          if (this.notifAlertMessageTimer === null) {
            this.notifAlertMessageTimer = setTimeout(() => this.onClickAlertMessage(false), 5000);
          }
        }

        if (prevProps.NotificationData.notifCount !== this.props.NotificationData.notifCount) {
          this.onClickRefresh();
        }
      } catch (e) {
        console.log(e);
      }
    }

    checkPathName = () => {
      let path = window.location.pathname.toLowerCase();

      if (path === '/accessdenied' || path === '/pagenotfound' || path === '/accessrequest') {
        this.setState({
          profile: false,
          burger: false,
        });
      } else {
        this.setState({
          profile: true,
          burger: true,
          home: true,
        });
      }
    };

    goTo = (path) => {
      let value = {
        value: '',
        text: '',
        label: '',
        iconName: '',
        iconStyle: '',
        isClientDataEmpty: '',
      };
      this.setState({ showModal: false });
      this.props.history.push(path);
      this.checkPathName();
      this.props.showClientDropdown();
      this.props.leftPanelOnClick(false);
      this.props.setSelectedClient(value);
    };

    handleSelectClient = async (e) => {
      try {
        if (e.value !== this.props.SharedData.selectedClient.value) {
          this.props.showLeftSideBar(true)
          await this.props.clearProgramProjectList();
          LogUserSiteAction({
            action: CLIENT_CHANGE,
            clientID: e.value,
          });
          this.props.setSelectedClient(e);
          this.props.setCurrentUserDetails();
          this.props.clearDashboard();
          setTimeout(async () => {
            this.props.fetchClientDetails(e.value);
            this.props.FetchDashboardData(e.value);
            this.props.getProgramClientDetails(e.value);
            this.props.getProgramList(e.value);
            await this.props.fetchAddNewProgramDetails(e.value);
          }, 500);
        }
      }
      catch (e) {
        console.log(e)
      }
    }

    getHeaderTitle() {
      const isHeaderDisabled = this.props.isDisabled;
      const client = TryGetObjValueAlt(this.props.SharedData, '', 'selectedClient.label');
      return (
        <Col md={9} xs={12} className={`header_title ${isHeaderDisabled ? 'def-cursor' : ''}`}>
          <div onClick={isHeaderDisabled ? () => { } : this.onClickHome}>
            <img alt="" id="logo" src={Logo} />
            <span className={`cvm-span ${!this.props.SharedData.showClientDropdown ? 'admin-module' : ''}`}>360° Synops Value Meter</span>
          </div>
          {this.props.SharedData.showClientDropdown ? (
            <>
              <div className="divider" />
              <img alt="" id="clientlogo" src={samplelogo} />
              {isHeaderDisabled ? (
                <label className="client-dg-lable">{client}</label>
              ) : (
                <div title={client}>
                  <GrooveSelectMenuBasic
                    name="headerClient"
                    isDisabled={isHeaderDisabled}
                    placeholder="Select Client"
                    list={this.props.SharedData.clientList}
                    contentType="search"
                    defaultData={this.props.SharedData.selectedClient}
                    callbackFunction={(e) => { this.handleSelectClient(e) }}
                  />
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </Col>
      );
    }

    displayProfilePop = () => {
      if (this.state.flg || this.props.SharedData.showProfile === false) {
        this.props.showProfileButton(true);
        this.setState({
          flg: !this.state.flg,
        });
      } else {
        this.props.showProfileButton(false);
        this.setState({
          flg: !this.state.flg,
        });
      }

      this.setState({
        showBellNotif: false,
      });
    };

    onLogoutButtonClicked = async () => {
      const data = await HttpGetText('user/Logout');
      if (data === '') {
        localStorage.setItem('logout', true);
        const param = StrToObj(GetSessionStorage(USER_SITE_ACT_PAR));
        if (HasProperty(param, 'hadDoneNav')) LogUserBounce();
        const r = await LogUserSiteAction({ action: LOGOUT });

        if (r !== false) {
          sessionStorage.clear();
          Utilities.stopSession();
          // localStorage.setItem('logout-event', 'logout' + Math.random());
          window.location.href = Utilities.environment.msalConfig.auth.logout;
        }
      }
    };

    handleBellNotifcation = () => {
      if (this.props.userInformation.userProfiles[0].ProfileID !== 14) {
        this.setState({
          showBellNotif: !this.state.showBellNotif,
          showProfile: false,
        });
      }
    };

    handleShowContactUs = (e) => {
      console.log('open', e.type)
      this.setState({
        showContactUs: true
      });
    };

    handleCloseContactUs = (e) => {
      console.log('close', e)
      this.setState({
        showContactUs: false
      });
    };

    onClickHome = () => {
      this.props.showLeftSideBar(true)
      DeleteCallbackURL();
      DeleteReferrer();
      DeleteDistiURL();
      this.goTo('/');
    };

    handleNotificationHistory = () => {
      let showBellNotif = !this.state.showNotificationHistory ? false : this.state.showBellNotif;
      this.setState(
        {
          showNotificationHistory: !this.state.showNotificationHistory,
          showBellNotif: showBellNotif,
        },
        () => {
          if (!this.state.showNotificationHistory) {
            this.props.clearNotifHistoryData();
          }
        }
      );
    };
    onClickRefresh = async () => {
      try {
        const currentScrollPosition = this.scrollRef.current.scrollTop;
        this.setState({
          isLoading: true,
        });
        await this.props.refreshNotif();
        const scrollHeight = this.scrollRef.current.children[0].children[0].scrollHeight; // scroll height of the 1st item of the notif list
        if (this.props.NotificationData.newlyAddedList.length > 0) {
          this.scrollRef.current.scrollTop =
            currentScrollPosition === 0
              ? scrollHeight + 3
              : currentScrollPosition + scrollHeight + 12;
        }
      } catch (e) {
        console.log(e);
      }
    };

    onClickAlertMessage = async (isClicked) => {
      try {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            if (isClicked) {
              this.scrollRef.current.style.scrollBehavior = 'smooth';
              this.scrollRef.current.scrollTop = 0;
              this.scrollRef.current.style.scrollBehavior = '';
              clearTimeout(this.notifAlertMessageTimer);
            }
            this.notifAlertMessageTimer = null;
            this.props.clearNewlyAddedList();
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onClickMarkAllAsRead = async () => {
      try {
        let newList = this.props.NotificationData.NewInAppNotifications;
        let oldList = this.props.NotificationData.OldInAppNotifications;
        let idList = newList.filter((x) => !x.IsMarkAsRead).map((x) => x.ID);
        let oldIDList = oldList.filter((x) => !x.IsMarkAsRead).map((x) => x.ID);

        await this.props.markAsRead(idList.concat(oldIDList));
      } catch (e) {
        console.log(e);
      }
    };

    onClickMarkAsRead = async (id, isRead) => {
      try {
        if (!isRead) {
          await this.props.markAsRead([id]);
        }

        this.setState({
          isExpand: false,
        });
      } catch (e) {
        console.log(e);
      }
    };

    onClickGoTo = async (id, actionLink, isRead) => {
      try {
        if (!isRead) {
          await this.onClickMarkAsRead(id);
        }
        let a = document.createElement('a');
        a.href = actionLink;
        document.body.appendChild(a);
        a.click();
      } catch (e) {
        console.log(e);
      }
    };

    onClickViewMore = () => {
      try {
        this.setState({
          isExpand: !this.state.isExpand,
        });
      } catch (e) {
        console.log(e);
      }
    };

    getPeopleHeader() {
      let path = window.location.pathname.toLowerCase();
      let newNotifList = this.props.NotificationData.NewInAppNotifications;
      let oldNotifList = this.props.NotificationData.OldInAppNotifications;
      let newlyAddedList = this.props.NotificationData.newlyAddedList;
      let showNotifHist = this.props.NotificationData.showNotifHist;
      let count;

      count =
        newNotifList.filter((x) => !x.IsMarkAsRead).length +
        oldNotifList.filter((x) => !x.IsMarkAsRead).length;
      // count = newlyAddedList.length > 0 ? count + newlyAddedList.length : count;
      if (path === '/accessdenied' || path === '/pagenotfound') {
        return <Col xs={12} sm={6} id="navigation" />;
      } else if (path === '/accessrequest') {
        return (
          <Col xs={12} sm={6} id="navigation">
            <div id="nav-home" className="navigation-link" onClick={this.onClickHome}>
              Home
            </div>
          </Col>
        );
      } else {
        let isDemoUser =
          this.props.userInformation.userProfiles.filter((x) => x.ProfileID === 14).length > 0 && this.props.userInformation.userProfiles.length === 1
            ? true
            : false;
        return (
          <Col xs={12} sm={3} id="navigation">
            {/* PLEASE DO NOT DELETE */}
            <div className="bell-notification"  onClick={this.handleShowContactUs}>
              <div className='contact-us-text cursor'>Contact Us</div>
            </div>
            {this.state.showContactUs && <ContactUs
                closeContactSupportModal={() => this.handleCloseContactUs()}
                open={this.state.showContactUs}
              />}
            <div id="bell-link" className={`${isDemoUser ? 'disable-notif' : ''}`}>
              {!this.props.userInformation.fromGlobal && (
                <div
                  ref={this.notificationPopoverTarget}
                  className="bell-notification"
                  onClick={() => this.handleBellNotifcation()}
                >
                  <FontAwesomeIcon icon={faBell} />
                  {count > 0 && !isDemoUser && <span className="notif-count">{count}</span>}
                </div>
              )}
              <Overlay
                show={this.state.showBellNotif}
                target={this.notificationPopoverTarget.current}
                placement="bottom-end"
                rootClose
                rootCloseEvent="click"
                onHide={this.handleBellNotifcation}
                style={{ inset: '-10px 0px auto auto' }}
              >
                <Popover id="popover-notification">
                  <div>
                    {newlyAddedList.length > 0 && (
                      <div
                        className={`${this.state.isLoading ? 'show' : ''} notif-added`}
                        onClick={() => this.onClickAlertMessage(true)}
                      >
                        <span>
                          <FontAwesomeIcon icon={faAngleUp} /> {newlyAddedList.length} New
                          notifications added
                        </span>
                      </div>
                    )}
                    <Popover.Title>
                      <div>Notifications</div>
                      <span className="close-btn">
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => this.handleBellNotifcation()}
                        />
                      </span>
                    </Popover.Title>
                    <Popover.Content>
                      <div className="new-section">
                        <div className="new-header">
                          <span className="new-count">
                            NEW ({newNotifList.length}){' '}
                            <span className="refresh-btn" onClick={() => this.onClickRefresh()}>
                              <FontAwesomeIcon
                                icon={faRedoAlt}
                                className={`${this.state.isLoading ? 'rotate' : ''}`}
                              />
                            </span>
                          </span>
                          {newNotifList.length > 0 || oldNotifList.length > 0 ? (
                            <span className="mark-read" onClick={() => this.onClickMarkAllAsRead()}>
                              Mark as read
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div ref={this.scrollRef} onScroll={this.onScroll} className="new-list">
                          <Accordion className="">
                            {newNotifList.length > 0 ? (
                              newNotifList.map((newNotif, index) => {
                                let description = newNotif.NotificationDescription;
                                let comment = trimText((newNotif.Comment || ""), 120, 120, 1000);
                                return (
                                  <Card
                                    className={`${!newNotif.IsMarkAsRead
                                      ? newNotif.IsImportantUser
                                        ? 'reject'
                                        : 'normal'
                                      : ''
                                      } notification-item-wrap active`}
                                  >
                                    <div
                                      className="notice-ext-link"
                                      onClick={() =>
                                        this.onClickGoTo(
                                          newNotif.ID,
                                          newNotif.ActionLinkUrl,
                                          newNotif.IsMarkAsRead
                                        )
                                      }
                                    >
                                      <OverlayTrigger
                                        key="focus"
                                        placement="bottom-start"
                                        overlay={
                                          <Tooltip id="notif-goTo">{newNotif.ActionLink}</Tooltip>
                                        }
                                      >
                                        <div className="action-link">
                                          <span>{newNotif.ActionLink}</span>
                                        </div>
                                      </OverlayTrigger>
                                      <GrooveIconSvg
                                        customClassName="accordion-button"
                                        size="small"
                                        name="external-link-alt"
                                        iconStyle="solid"
                                        iconColor="stat-alternate"
                                      // callbackFunction={this.props.onSearch}
                                      />
                                    </div>
                                    <Accordion.Collapse eventKey={index + 1}>
                                      <Card.Body>
                                        <div className="notice-desc-wrap">
                                          <div className="notice-desc">
                                            <b>
                                              {description
                                                .substr(0, description.indexOf(' '))
                                                .toLowerCase()}
                                            </b>{' '}
                                            commented:
                                            <div
                                              className={`${(newNotif.Comment || "").length > 120 && this.state.isExpand
                                                ? 'comment-expand'
                                                : ''
                                                }`}
                                            >
                                              {/* {oldNotif.Comment.slice(0, 120)} */}
                                              {(newNotif.Comment || "").length > 120 && !this.state.isExpand
                                                ? comment[0] + '...'
                                                : comment[0] + ' '}
                                              {this.state.isExpand ? comment[1] : ''}
                                              <br />
                                              {(newNotif.Comment || "").length > 120 && (
                                                <span
                                                  className="veiw-more"
                                                  onClick={() => this.onClickViewMore()}
                                                >
                                                  {!this.state.isExpand
                                                    ? '+ View More ...'
                                                    : '- View Less'}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                    {(newNotif.Comment || "").length > 0 ? (
                                      <Card.Header>
                                        <Accordion.Toggle
                                          as={Button}
                                          variant="link"
                                          eventKey={index + 1}
                                          onClick={() => {
                                            this.onClickMarkAsRead(
                                              newNotif.ID,
                                              newNotif.IsMarkAsRead
                                            );
                                          }}
                                        >
                                          <div
                                            className={`${newNotif.IsImportantUser ? 'action-required' : ''
                                              } notice-title`}
                                          >
                                            <h5>
                                              {newNotif.IsImportantUser ? '!' : ''}{' '}
                                              {newNotif.NotificationHeader}
                                            </h5>
                                            <p>{newNotif.NotificationSentTS}</p>
                                          </div>
                                          <div className="notice-header">
                                            <div className="notice-text">
                                              <div className="user-wrap">
                                                <img
                                                  alt=""
                                                  id="user_logo"
                                                  src={default_user_logo}
                                                />
                                              </div>
                                              {description.substr(0, description.indexOf(' ')) ===
                                                'A' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'Please' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'System' ? (
                                                <p>{description}</p>
                                              ) : (
                                                <p>
                                                  <b>
                                                    {description
                                                      .substr(0, description.indexOf(' '))
                                                      .toLowerCase()}
                                                  </b>{' '}
                                                  {description.substr(description.indexOf(' ') + 1)}
                                                </p>
                                              )}
                                            </div>
                                            {(newNotif.Comment || "").length > 0 && (
                                              <GrooveIconSvg
                                                customClassName="accordion-button"
                                                size="small"
                                                name="chevron-down"
                                                iconStyle="solid"
                                                iconColor="stat-neutral"
                                              // callbackFunction={this.props.onSearch}
                                              />
                                            )}
                                          </div>
                                        </Accordion.Toggle>
                                      </Card.Header>
                                    ) : (
                                      <Card.Header>
                                        <div>
                                          <div
                                            className={`${newNotif.IsImportantUser ? 'action-required' : ''
                                              } notice-title`}
                                          >
                                            <h5>
                                              {newNotif.IsImportantUser ? '!' : ''}{' '}
                                              {newNotif.NotificationHeader}
                                            </h5>
                                            <p>{newNotif.NotificationSentTS}</p>
                                          </div>
                                          <div className="notice-header">
                                            <div className="notice-text">
                                              <div className="user-wrap">
                                                <img
                                                  alt=""
                                                  id="user_logo"
                                                  src={default_user_logo}
                                                />
                                              </div>
                                              {description.substr(0, description.indexOf(' ')) ===
                                                'A' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'Please' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'System' ? (
                                                <p>{description}</p>
                                              ) : (
                                                <p>
                                                  <b>
                                                    {description
                                                      .substr(0, description.indexOf(' '))
                                                      .toLowerCase()}
                                                  </b>{' '}
                                                  {description.substr(description.indexOf(' ') + 1)}
                                                </p>
                                              )}
                                            </div>
                                            {(newNotif.Comment || "").length > 0 && (
                                              <GrooveIconSvg
                                                customClassName="accordion-button"
                                                size="small"
                                                name="chevron-down"
                                                iconStyle="solid"
                                                iconColor="stat-neutral"
                                              // callbackFunction={this.props.onSearch}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </Card>
                                );
                              })
                            ) : (
                              <div className="no-notif">
                                <span>That's Everything! No new notifications</span>
                              </div>
                            )}
                          </Accordion>
                        </div>
                      </div>
                      <div className="old-section">
                        <div className="old-header">
                          <span className="old-title">OLDER</span>
                          {showNotifHist ? (
                            <span
                              className="view-complete-list"
                              onClick={() => this.handleNotificationHistory()}
                            >
                              View Complete List
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="old-list">
                          <Accordion className="">
                            {oldNotifList.length > 0 ? (
                              oldNotifList.map((oldNotif, index) => {
                                let description = oldNotif.NotificationDescription;
                                let comment = trimText((oldNotif.Comment || ""), 120, 120, 1000);
                                return (
                                  <Card
                                    className={`${!oldNotif.IsMarkAsRead
                                      ? oldNotif.IsImportantUser
                                        ? 'reject'
                                        : 'normal'
                                      : ''
                                      } notification-item-wrap active`}
                                  >
                                    <div
                                      className="notice-ext-link"
                                      onClick={() =>
                                        this.onClickGoTo(
                                          oldNotif.ID,
                                          oldNotif.ActionLinkUrl,
                                          oldNotif.IsMarkAsRead
                                        )
                                      }
                                    >
                                      <OverlayTrigger
                                        key="focus"
                                        placement="bottom-start"
                                        overlay={
                                          <Tooltip id="notif-goTo">{oldNotif.ActionLink}</Tooltip>
                                        }
                                      >
                                        <div className="action-link">
                                          <span>{oldNotif.ActionLink}</span>
                                        </div>
                                      </OverlayTrigger>
                                      <GrooveIconSvg
                                        customClassName="accordion-button"
                                        size="small"
                                        name="external-link-alt"
                                        iconStyle="solid"
                                        iconColor="stat-alternate"
                                      // callbackFunction={this.props.onSearch}
                                      />
                                    </div>
                                    <Accordion.Collapse eventKey={index + 1}>
                                      <Card.Body>
                                        <div className="notice-desc-wrap">
                                          <div className="notice-desc">
                                            <b>
                                              {description
                                                .substr(0, description.indexOf(' '))
                                                .toLowerCase()}
                                            </b>{' '}
                                            commented:
                                            <div>
                                              <div
                                                className={`${(oldNotif.Comment || "").length > 120 &&
                                                  this.state.isExpand
                                                  ? 'comment-expand'
                                                  : ''
                                                  }`}
                                              >
                                                {/* {oldNotif.Comment.slice(0, 120)} */}
                                                {(oldNotif.Comment || "").length > 120 &&
                                                  !this.state.isExpand
                                                  ? comment[0] + '...'
                                                  : comment[0] + ' '}
                                                {this.state.isExpand ? comment[1] : ''}
                                                <br />
                                                {(oldNotif.Comment || "").length > 120 && (
                                                  <span
                                                    className="veiw-more"
                                                    onClick={() => this.onClickViewMore()}
                                                  >
                                                    {!this.state.isExpand
                                                      ? '+ View More ...'
                                                      : '- View Less'}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                    {(oldNotif.Comment || "").length > 0 ? (
                                      <Card.Header>
                                        <Accordion.Toggle
                                          as={Button}
                                          variant="link"
                                          eventKey={index + 1}
                                          onClick={() => {
                                            this.onClickMarkAsRead(
                                              oldNotif.ID,
                                              oldNotif.IsMarkAsRead
                                            );
                                          }}
                                        >
                                          <div
                                            className={`${oldNotif.IsImportantUser ? 'action-required' : ''
                                              } notice-title`}
                                          >
                                            <h5>
                                              {oldNotif.IsImportantUser ? '!' : ''}{' '}
                                              {oldNotif.NotificationHeader}
                                            </h5>
                                            <p>{oldNotif.NotificationSentTS}</p>
                                          </div>
                                          <div className="notice-header">
                                            <div className="notice-text">
                                              <div className="user-wrap">
                                                <img
                                                  alt=""
                                                  id="user_logo"
                                                  src={default_user_logo}
                                                />
                                              </div>
                                              {description.substr(0, description.indexOf(' ')) ===
                                                'A' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'Please' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'System' ? (
                                                <p>{description}</p>
                                              ) : (
                                                <p>
                                                  <b>
                                                    {description
                                                      .substr(0, description.indexOf(' '))
                                                      .toLowerCase()}
                                                  </b>{' '}
                                                  {description.substr(description.indexOf(' ') + 1)}
                                                </p>
                                              )}
                                            </div>
                                            {(oldNotif.Comment || "").length > 0 && (
                                              <GrooveIconSvg
                                                customClassName="accordion-button"
                                                size="small"
                                                name="chevron-down"
                                                iconStyle="solid"
                                                iconColor="stat-neutral"
                                              // callbackFunction={this.props.onSearch}
                                              />
                                            )}
                                          </div>
                                        </Accordion.Toggle>
                                      </Card.Header>
                                    ) : (
                                      <Card.Header>
                                        <div>
                                          <div
                                            className={`${oldNotif.IsImportantUser ? 'action-required' : ''
                                              } notice-title`}
                                          >
                                            <h5>
                                              {oldNotif.IsImportantUser ? '!' : ''}{' '}
                                              {oldNotif.NotificationHeader}
                                            </h5>
                                            <p>{oldNotif.NotificationSentTS}</p>
                                          </div>
                                          <div className="notice-header">
                                            <div className="notice-text">
                                              <div className="user-wrap">
                                                <img
                                                  alt=""
                                                  id="user_logo"
                                                  src={default_user_logo}
                                                />
                                              </div>
                                              {description.substr(0, description.indexOf(' ')) ===
                                                'A' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'Please' ||
                                                description.substr(0, description.indexOf(' ')) ===
                                                'System' ? (
                                                <p>{description}</p>
                                              ) : (
                                                <p>
                                                  <b>
                                                    {description
                                                      .substr(0, description.indexOf(' '))
                                                      .toLowerCase()}
                                                  </b>{' '}
                                                  {description.substr(description.indexOf(' ') + 1)}
                                                </p>
                                              )}
                                            </div>
                                            {(oldNotif.Comment || "").length > 0 && (
                                              <GrooveIconSvg
                                                customClassName="accordion-button"
                                                size="small"
                                                name="chevron-down"
                                                iconStyle="solid"
                                                iconColor="stat-neutral"
                                              // callbackFunction={this.props.onSearch}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </Card>
                                );
                              })
                            ) : (
                              <div className="no-notif">
                                <span>That's Everything! No old notifications</span>
                              </div>
                            )}
                          </Accordion>
                        </div>
                      </div>
                    </Popover.Content>
                  </div>
                </Popover>
              </Overlay>

              <CVMModal
                title={'Notifications'}
                // size={'lg'}
                show={this.state.showNotificationHistory}
                onCloseModal={this.handleNotificationHistory}
                customclass={'notif-history-modal'}
                enforceFocus={false}
                content={
                  <NotificationHistoryTable
                    handleNotificationHistory={this.handleNotificationHistory}
                  />
                }
              />
            </div>
            <div className="divider" />
            <div className="user-eid">{`${this.props.userInformation.enterpriseID} ${isDemoUser ? '(Demo User)' : ''
              }`}</div>
            {/* <div className="divider" /> */}
            <div
              id="profile-link"
              className={this.props.isDisabled ? 'def-cursor' : ''}
              onClick={this.props.isDisabled ? () => { } : () => this.displayProfilePop()}
            >
              <div>
                <img
                  alt=""
                  id="user_logo"
                  src={default_user_logo}
                  className={this.state.profile ? '' : 'hide'}
                />
              </div>
            </div>
            {this.props.SharedData.showProfile && (
              <ProfileMenu onLogoutButtonClicked={this.onLogoutButtonClicked} />
            )}
            <UploadSupportDocument />
          </Col>
        );
      }
    }

    timerToMins = (time) => {
      let min = Math.floor(time / 60);
      let sec =
        time - min * 60 > 9 ? Math.floor(time - min * 60) : '0' + Math.floor(time - min * 60);
      return min + ':' + sec;
    };

    render() {
      let path = window.location.pathname.toLowerCase();
      if (path === '/bo/downloadfile') {
        return <Row />;
      } else {
        return (
          <Row data-testid="test-com-Header" id="header_container">
            {this.getHeaderTitle()}
            {this.getPeopleHeader()}
          </Row>
        );
      }
    }
  }

  return _Header;
})();

export default connect(
  (state) => {
    return {
      SharedData: state.SharedData,
      userInformation: state.userInformation,
      NotificationData: state.NotificationData,
      HomeData: state.HomeData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getClientList,
          showClientDropdown,
          setSelectedClient,
          setUserInformation,
          leftPanelOnClick,
          setReportingYear,
          setCurrentUserDetails,
          clearDashboard,
          FetchDashboardData,
          getDashboardClientList,
          getModules,
          getProgramClientDetails,
          fetchAddNewProgramDetails,
          getProgramList,
          showProfileButton,
          setNotificationData,
          clearNotifHistoryData,
          refreshNotif,
          updateNotificationList,
          markAsRead,
          updateTimeStamp,
          clearNewlyAddedList,
          getNotificationCount,
          fetchClientDetails,
          showLeftSideBar,
          clearProgramProjectList
        }
      ),
      dispatch
    )
)(withRouter(Header));
