import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PieChart, ResponsiveContainer, Pie, Sector } from 'recharts';
import WheelInsideLogo_withborder from '../../Static/images/360cvm/WheelInsideLogo_withborder.svg';
import { onSubdimensionClick, panelOnClick } from '../../Actions/Dashboard.Action';
import { UnRefObj, TryGetNodeAlt, AreObjsEqual } from '../../Utilities/Formatter';
import { HasProperty, HasValue } from '../../Utilities/Validations';

import { ScreenWidth as sw } from '../../Utilities/BrowserUtil';
import { SliceLableText } from './DashboardUtil';

const DashboardDimGraph = (function () {
  const masterDim = {
    experience: 'Experience',
    sustainability: 'Sustainability',
    talent: 'Talent',
    inclusionDiversity: 'Inclusion & Diversity',
    custom: 'Custom',
    financial: 'Financial',
  };
  const sdFontColor = [
    { dimension: 'Financial', fill: '#b455aa' },
    { dimension: 'Custom', fill: '#a100ff' },
    { dimension: 'Inclusion & Diversity', fill: '#7500c1' },
    { dimension: 'Experience', fill: '#a055f5' },
    { dimension: 'Sustainability', fill: '#595959' },
    { dimension: 'Talent', fill: '#460073' },
  ];

  class DashboardDimGraphPresentational extends Component {
    getLabelWidth = (custSDimCount) => {
      let lableWidths = {
        label1: 106,
        label2: 143,
        dots: 6.78,
        elip: 2.69,
      };

      switch (custSDimCount) {
        case 1:
          lableWidths =
            sw <= 1280
              ? { label1: 101, label2: 131, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 106, label2: 154, dots: 6.92, elip: 3.22 }
                : { label1: 106, label2: 149, dots: 6.78, elip: 3.02 };
          break;
        case 2:
          lableWidths =
            sw <= 1280
              ? { label1: 79, label2: 71, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 81, label2: 76, dots: 6.92, elip: 3.22 }
                : { label1: 86, label2: 80, dots: 6.78, elip: 3.02 };
          break;
        case 3:
          lableWidths =
            sw <= 1280
              ? { label1: 50, label2: 45, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 50, label2: 45, dots: 6.92, elip: 3.22 }
                : { label1: 55, label2: 50, dots: 6.78, elip: 3.02 };
          break;
        default:
          lableWidths =
            sw <= 1280
              ? { label1: 33, label2: 33, dots: 6.78, elip: 2.69 }
              : sw <= 1536
                ? { label1: 23, label2: 23, dots: 6.92, elip: 3.22 }
                : { label1: 29, label2: 29, dots: 6.78, elip: 3.02 };
          break;
      }
      return lableWidths;
    };

    render() {
      let shouldRotate = false;
      const cusSDimCount = this.props.cusSDimCount;
      let dimListMaster = this.props.dimListMaster.map((dim) => {
        shouldRotate = shouldRotate
          ? true
          : dim.dimSequence === 6 && dim.startAngle === 120 && this.props.panelIsClicked;
        let tDim = this.props.dimList.find((dim2) => dim2.DimID === dim.ID);
        tDim = HasValue(tDim)
          ? tDim
          :
          {
            fill: '#eff2f5',
            coreDimID: dim.ID,
            isDisabled: true,
          };
        return {
          ...dim,
          fill: tDim.isDisabled ? '#e2e2e2' : tDim.fill,
          coreDimID: tDim.coreDimID,
          isDisabled: tDim.isDisabled,
        };
      });

      let dimListRing = dimListMaster.map((item) => {
        return {
          ID: item.ID,
          name: item.name,
          fill: item.ID % 2 === 0 ? '#e2e2e2' : '#eff2f5',
          value: item.value,
          cursor: item.isDisabled ? 'default' : 'pointer'
        };
      });

      let suDimListRing = this.props.subDimListMaster.map((sDim, i) => {
        let tSDim = this.props.subDimList.find((sDim2) => {
          return sDim2.subDim.toLowerCase() === sDim.name.toLowerCase() && sDim2.DimID === sDim.ID;
        });
        tSDim = HasValue(tSDim)
          ? tSDim
          : {
            fill: '#a9a9a9',
            coreDimID: 6,
            DimID: 6,
            sDimID: sDim.sDimID ? sDim.sDimID : 0,
            coreSubDimID:  sDim.coreSubDimID ? sDim.coreSubDimID : 0,
            subDimID:  sDim.sDimID ? sDim.sDimID : 0,
            hasBoi: false,
          };
        return {
          ...sDim,
          DimID: tSDim.DimID,
          sDimID: tSDim.subDimID,
          coreSubDimID: tSDim.coreSubDimID,
          fill: tSDim.hasBoi ? tSDim.fill : '#a9a9a9',
          hasBoi: tSDim.hasBoi
        };
      });

      let subDimListMaster = suDimListRing.map((item) => {
        return {
          name: item.name,
          value: item.value,
          fill: item.DimID % 2 === 0 ? '#e2e2e2' : '#eff2f5',
          DimID: item.DimID,
          sDimID: item.sDimID,
          cursor: item.hasBoi ? 'pointer' : 'default'
        };
      });

      let centre = [{ name: 'Centre', value: 1 }];

      const RADIAN = Math.PI / 180;

      let disabledSector = (this.props.isRotating && this.props.panelClicked > 0 ? dimListMaster : dimListMaster
        .filter((dim1) =>
          this.props.panelIsClicked && this.props.panelClicked !== null
            ? dim1.ID !== dimListMaster[0].ID
            : dim1.isDisabled
        ))
        .map((dim2) => {
          return {
            ID: dim2.ID,
            startAngle: dim2.startAngle,
            endAngle: dim2.endAngle,
          };
        });

      const wheelPadding = shouldRotate
        ? [
          { y: '27.8%', r: 119, dimL: 6, dimR: 1 },
          { y: '28%', r: 179, dimL: 1, dimR: 2 },
          {
            y: '28%',
            r: [0, 1].indexOf(cusSDimCount) > -1 ? -121 : cusSDimCount === 3 ? -122 : -120.5,
            dimL: 2,
            dimR: 3,
          },
          { y: '27.8%', r: -61, dimL: 3, dimR: 4 },
          { y: '28%', r: cusSDimCount === 1 ? 0 : 0.5, dimL: 4, dimR: 5 },
          { y: '28%', r: 59.5, dimL: 5, dimR: 6 },
        ]
        : [
          { y: '27.8%', r: 89, dimL: 6, dimR: 1 },
          { y: '28%', r: 149, dimL: 2, dimR: 3 },
          {
            y: '28%',
            r: [0, 1].indexOf(cusSDimCount) > -1 ? -150 : cusSDimCount === 3 ? -151 : -150.5,
            dimL: 2,
            dimR: 3,
          },
          { y: '27.8%', r: -91, dimL: 3, dimR: 4 },
          { y: '28%', r: cusSDimCount === 1 ? -29.5 : -30.5, dimL: 4, dimR: 5 },
          { y: '28%', r: 29.5, dimL: 5, dimR: 6 },
        ];

      const renderDimLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index, cursor }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        let a = 0;
        let b = 0;

        if (!shouldRotate) {
          if (index === 0 || index === 3) {
            a = 30;
          } else if (index === 1) {
            a = 90;
          } else if (index === 4) {
            a = -90;
          } else {
            a = -35;
          }
          if (index == 2 || index == 3) {
            b = 5;
          } else {
            b = -5;
          }
        } else {
          if (index === 0 || index === 3) {
            a = 0;
          } else if (index === 1) {
            a = 60;
          } else if (index === 4) {
            a = 60;
          } else {
            a = -60;
          }
          if (index == 2 || index == 3) {
            b = 10;
          } else {
            b = 0;
          }
        }

        return (
          /*Dimnension*/
          <text
            x={x}
            y={y + b}
            fill="black"
            scaleToFit={false}
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={9}
            fontWeight={900}
            width={12}
            style={{ cursor: cursor }}
            transform={'rotate(' + a + ' ' + x + ' ' + y + ')'}
            onClick={() => {
              this.props.onClickDims(dimListMaster[index]);
            }}
          >
            {dimListMaster[index].name.indexOf('&') === -1 ? (
              dimListMaster[index].name
            ) : (
              <>
                <tspan x={x} y={y + b - 13}>
                  {dimListMaster[index].name.split(' ')[0]}
                </tspan>
                <tspan x={x} y={y + b}>
                  &
                </tspan>
                <tspan x={x} y={y + b + 10}>
                  {dimListMaster[index].name.split(' ')[2]}
                </tspan>
              </>
            )}
          </text>
        );
      };

      const renderSubDimLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, sdimParam, cursor }) => {
        const isCustom = sdimParam.ID === 6;
        const radius = innerRadius + (outerRadius - innerRadius) * (isCustom ? 0.6 : 0.5);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const ar = innerRadius + (outerRadius - innerRadius) * 0.2;
        const ax = cx + ar * Math.cos(-midAngle * RADIAN);
        const ay = cy + ar * Math.sin(-midAngle * RADIAN);
        let a = 0;
        const dimIdx = dimListMaster.findIndex((i) => {
          return i.ID === sdimParam.ID;
        });
        const sDimListById = suDimListRing.filter((x) => x.ID === sdimParam.ID);
        const sDimLength = sDimListById.length;
        const idx = sDimListById.findIndex((x) => x.name === sdimParam.name);

        if (!shouldRotate) {
          switch (dimIdx) {
            case 0:
            case 3:
              a = idx === 0 ? 10 : idx === 1 ? 30 : 50;
              break;
            case 1:
              a = idx === 0 ? 75 : idx === 1 ? 90 : 110;
              break;
            case 2:
              a = idx === 0 ? 320 : 340;
              break;
            case 4:
              if (sDimLength === 1) {
                a = 268;
              } else if (sDimLength === 2) {
                a = idx === 0 ? 253 : 283;
              } else if (sDimLength === 3) {
                a = idx === 0 ? 250 : idx === 1 ? 270 : 290;
              } else if (sDimLength === 4) {
                a = idx === 0 ? 336 : idx === 1 ? 356 : idx === 2 ? 369 : 386;
              } else if (sDimLength === 5) {
                a = idx === 0 ? 330 : idx === 1 ? 345 : idx === 2 ? 360 : idx === 3 ? 375 : 385;
              } else if (sDimLength === 6) {
                a =
                  idx === 0
                    ? 335
                    : idx === 1
                      ? 345
                      : idx === 2
                        ? 355
                        : idx === 3
                          ? 365
                          : idx === 4
                            ? 375
                            : 385;
              }
              break;
            case 5:
              a = idx === 0 ? 305 : idx === 1 ? 330 : 350;
              break;
            default:
              break;
          }
        } else {
          switch (dimIdx) {
            case 0:
            case 3:
              if (sDimLength === 1) {
                a = 0;
              } else if (sDimLength === 2) {
                a = idx === 0 ? 345 : 15;
              } else if (sDimLength === 3) {
                a = idx === 0 ? 340 : idx === 1 ? 0 : 20;
              } else if (sDimLength === 4) {
                a = idx === 0 ? 245 : idx === 1 ? 260 : idx === 2 ? 280 : 290;
              } else if (sDimLength === 5) {
                a = idx === 0 ? 245 : idx === 1 ? 255 : idx === 2 ? 270 : idx === 3 ? 280 : 290;
              } else if (sDimLength === 6) {
                a =
                  idx === 0
                    ? 245
                    : idx === 1
                      ? 250
                      : idx === 2
                        ? 260
                        : idx === 3
                          ? 270
                          : idx === 4
                            ? 280
                            : 290; // not yet configured
              }
              break;
            case 1:
            case 4:
              if (sDimLength === 1) {
                a = 60;
              } else if (sDimLength === 2) {
                a = idx === 0 ? 45 : 75;
              } else if (sDimLength === 3) {
                a = idx === 0 ? 40 : idx === 1 ? 60 : 80;
              } else if (sDimLength === 4) {
                a = idx === 0 ? 305 : idx === 1 ? 320 : idx === 2 ? 335 : 350;
              } else if (sDimLength === 5) {
                a = idx === 0 ? 305 : idx === 1 ? 315 : idx === 2 ? 330 : idx === 3 ? 340 : 350;
              } else if (sDimLength === 6) {
                a =
                  idx === 0
                    ? 305
                    : idx === 1
                      ? 310
                      : idx === 2
                        ? 320
                        : idx === 3
                          ? 335
                          : idx === 4
                            ? 345
                            : 355;
              }
              break;
            case 2:
            case 5:
              if (sDimLength === 1) {
                a = 300;
              } else if (sDimLength === 2) {
                a = idx === 0 ? 285 : 315;
              } else if (sDimLength === 3) {
                a = idx === 0 ? 280 : idx === 1 ? 300 : 320;
              } else if (sDimLength === 4) {
                a = idx === 0 ? 5 : idx === 1 ? 25 : idx === 2 ? 40 : 55;
              } else if (sDimLength === 5) {
                a = idx === 0 ? 5 : idx === 1 ? 15 : idx === 2 ? 30 : idx === 3 ? 40 : 55;
              } else if (sDimLength === 6) {
                a =
                  idx === 0
                    ? 5
                    : idx === 1
                      ? 15
                      : idx === 2
                        ? 25
                        : idx === 3
                          ? 35
                          : idx === 4
                            ? 45
                            : 55;
              }
              break;
            default:
              break;
          }
        }

        let oneLinerLabelSpace = 5;

        let datalabel = HasValue(sdimParam.label) ? sdimParam.label : sdimParam.name;
        let label = [];
        let searchKey = datalabel.search('&');
        if (searchKey > -1 && !isCustom) {
          label.push(datalabel.slice(0, searchKey + 1));
          label.push(datalabel.slice(searchKey + 1));
        } else if (isCustom) {
          label = SliceLableText(
            datalabel,
            this.getLabelWidth(cusSDimCount),
            cusSDimCount < 7 ? 2 : 1
          );
          oneLinerLabelSpace = label.length === 1 ? 0 : oneLinerLabelSpace; //IF COUNT OF LABELS IS 2 THEN ALLOW 2 LINER
        } else {
          label = datalabel.split(' ');
        }

        let newY = sDimLength <= 4 && (dimIdx === 2 || dimIdx === 3) ? y + 8 : y;

        return (
          /*SubDimnension*/
          <>
            <text
              x={x}
              y={newY}
              fill="black"
              textAnchor="middle"
              dominantBaseline="top"
              scaleToFit={true}
              fontSize={9}
              fontWeight={500}
              width={12}
              style={{ cursor: cursor }}
              transform={'rotate(' + a + ' ' + x + ' ' + y + ')'}
              onClick={() => {
                this.props.onClickDims(sdimParam);
              }}
            >
              <title>{datalabel}</title>
              <tspan x={x} y={newY - oneLinerLabelSpace} alignmentBaseline="middle" fontSize="8">
                {label[0]}
              </tspan>
              {label.length > 1 && (
                <tspan fontSize="8" x={x} y={newY + 5}>
                  {label[1]}
                </tspan>
              )}
            </text>
            <circle cx={ax} cy={ay} r={3} fill={sdimParam.fill} stroke="none" />
          </>
        );
      };


      const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
        return (
          <g>
            <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius + 1}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
            />
          </g>
        );
      };

      const startAngle = shouldRotate ? 120 : 90;
      const endAngle = shouldRotate ? -240 : -270;

      return (
        <ResponsiveContainer
          width="100%"
          height={1000}
          id="wheel-chart-container"
        >
          <PieChart>
            {/*Dimension*/}
            <Pie
              activeIndex={this.props.activeIndex}
              activeShape={renderActiveShape}
              data={dimListRing}
              dataKey="value"
              cx="50%"
              cy="28%"
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius="24%"
              outerRadius="56%"
              label={renderDimLabel}
              labelLine={false}
              isAnimationActive={false}
              animationEasing={'ease-out'}
              onClick={this.props.onClickDims}
            />
            {/*Dimension Ring*/}
            <Pie
              activeIndex={this.props.activeIndex}
              activeShape={renderActiveShape}
              data={dimListMaster}
              cx="50%"
              cy="28%"
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius="58%"
              outerRadius="62%"
              isAnimationActive={false}
            />

            {/*SubDimension*/}
            {dimListMaster.map((dim) => {
              const sDim = subDimListMaster.filter((sDim) => sDim.DimID === dim.ID);
              const rendLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index, cursor }) => {
                const sdimParam = suDimListRing.find(
                  (sDim2) => sDim2.sDimID === sDim[index].sDimID
                );
                return renderSubDimLabel({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  sdimParam,
                  cursor
                });
              };
              return (
                <Pie
                  activeIndex={this.props.activeIndex}
                  startAngle={dim.startAngle}
                  endAngle={dim.endAngle}
                  data={sDim}
                  cx="50%"
                  cy="28%"
                  outerRadius="86%"
                  innerRadius="64%"
                  paddingAngle={1.5}
                  label={rendLabel}
                  labelLine={false}
                  isAnimationActive={false}
                  animationDuration={500}
                  animationEasing={'ease-out'}
                  onClick={this.props.onClickDims}
                />
              );
            })}

            {/*SubDimension Ring*/}
            {dimListMaster.map((dim) => {
              const sDim = suDimListRing.filter((sDim) => sDim.DimID === dim.ID);
              return (
                <Pie
                  activeIndex={this.props.activeIndex}
                  startAngle={dim.startAngle}
                  endAngle={dim.endAngle}
                  data={sDim}
                  cx="50%"
                  cy="28%"
                  innerRadius="88%"
                  outerRadius="92%"
                  paddingAngle={1.5}
                  isAnimationActive={false}
                  animationDuration={500}
                  animationEasing={'ease-out'}
                />
              );
            })}
            <Pie
              activeIndex={this.props.activeIndex}
              data={dimListMaster}
              cx="50%"
              cy="28%"
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius="96%"
              outerRadius="97%"
              isAnimationActive={false}
            />

            {/* SPACE IN BETWEEN SLICE */}
            {wheelPadding.map((s) => {
              if (
                this.props.isRotating ||
                this.props.panelClicked > 0 ||
                (dimListMaster.some((dim) => dim.isDisabled && dim.dimSequence === s.dimR) &&
                  dimListMaster.some((dim) => dim.isDisabled && dim.dimSequence === s.dimL))
              ) {
                return (
                  <rect
                    className="dashboard-wheel-vd-spacing"
                    x="0"
                    y={s.y}
                    width="50%"
                    height="7"
                    transform={`rotate(${s.r})`}
                  />
                );
              }
              return <></>;
            })}
            {
              // Disabled Subdimension Sector with no data
              disabledSector.map((disSec) => {
                return (
                  <Pie
                    activeIndex={this.props.activeIndex}
                    activeShape={renderActiveShape}
                    startAngle={disSec.startAngle}
                    endAngle={disSec.endAngle}
                    data={centre}
                    cx="50%"
                    cy="28%"
                    innerRadius="64%"
                    outerRadius="97%"
                    isAnimationActive={false}
                    fill="rgb(228, 230, 233, 0.8)"
                    paddingAngle={0}
                    labelLine={false}
                    stroke=""
                  />
                );
              })
            }

            {wheelPadding.map((s, i) => {
              if (this.props.isRotating && this.props.panelClicked === null  //DON'T SHOW PADDING DURING ROTATION && NOT FIRST SELECT
              ) return <></>
              else if (
                (!this.props.isRotating && this.props.panelClicked > 0 && (s.dimL === 5 || s.dimL === 6)) || //AFTER SELECTING DIM/SDIM THEN ONLY THE UPPER
                (this.props.panelClicked === null && dimListMaster.some(  //SHOW PADDING WHEN ATLEAST 1 OF DIM BESIDE IS ENABLED
                  (dim) => !dim.isDisabled && (dim.dimSequence === s.dimL || dim.dimSequence === s.dimR)
                ))
              ) {
                return (
                  <rect
                    className="dashboard-wheel-vd-spacing"
                    x="0"
                    y={s.y}
                    width="50%"
                    height="7"
                    transform={`rotate(${s.r})`}
                  />
                );
              }
              return <></>;
            })}

            {/* logo circle */}
            <svg
              x="37.59%"
              y="22.22%"
              dy={5}
              textAnchor={'middle'}
              width="25%"
              height="12%"
            >
              <image
                id='wheel-center-logo'
                style={{
                  transformBox: 'fill-box',
                  transformOrigin: '50% 50%'
                }}
                href={WheelInsideLogo_withborder}
                height="100%"
                width="100%"
              />
            </svg>
          </PieChart>
        </ResponsiveContainer>
      );
    }
  }
  class DashboardDimGraphContainer extends Component {
    constructor(props) {
      super(props);

      this.dimListMaster = [
        { ID: 2, dimSequence: 1, name: 'Experience', value: 300, startAngle: 90, endAngle: 30 },
        {
          ID: 3,
          dimSequence: 2,
          name: 'Sustainability',
          value: 300,
          startAngle: 30,
          endAngle: -30,
        },
        { ID: 4, dimSequence: 3, name: 'Talent', value: 300, startAngle: -30, endAngle: -90 },
        {
          ID: 5,
          dimSequence: 4,
          name: 'Inclusion & Diversity',
          value: 300,
          startAngle: -90,
          endAngle: -150,
        },
        { ID: 6, dimSequence: 5, name: 'Custom', value: 300, startAngle: -150, endAngle: -210 },
        { ID: 1, dimSequence: 6, name: 'Financial', value: 300, startAngle: -210, endAngle: -270 },
      ];
      this.subDimListMaster = [
        { ID: 2, name: 'Customer', value: 100 },
        { ID: 2, name: 'Employee', value: 100 },
        { ID: 2, name: 'Vendor / Partner', label: 'Vendor/ Partner', value: 100 },
        { ID: 3, name: 'Environmental', label: 'Environ mental', value: 100 },
        { ID: 3, name: 'Social', value: 100 },
        { ID: 3, name: 'Governance', value: 100 },
        { ID: 4, name: 'Change', value: 150 },
        { ID: 4, name: 'Development', value: 150 },
        { ID: 5, name: 'Inclusive Culture', value: 100 },
        { ID: 5, name: 'Inclusive Design', value: 100 },
        { ID: 5, name: 'Team Diversity', value: 100 },
        { ID: 6, name: '', value: 300 },
        { ID: 1, name: 'Capital', value: 100 },
        { ID: 1, name: 'OpEx', value: 100 },
        { ID: 1, name: 'Revenue', value: 100 },
      ];

      this.state = {
        customDim: [],
        dimList: [],
        subDimList: [],
        activeIndex: 0,
        dimListMaster: UnRefObj(this.dimListMaster),
        subDimListMaster: UnRefObj(this.subDimListMaster),
        cusSDimCount: 0,
        isRotating: false,
        selectedDimID: 0
      };
      this.rotating = false
    }
    componentDidMount() {
      this.setCenterWheel()
      this.formatData(UnRefObj(this.props.dimensionList));
    }
    componentDidUpdate(prevProps) {
      if (
        !AreObjsEqual(this.props.dimensionList, prevProps.dimensionList) ||
        (this.props.DashboardData.panelIsClicked !== prevProps.DashboardData.panelIsClicked &&
          !this.props.DashboardData.panelIsClicked &&
          !HasValue(this.props.DashboardData.panelClicked))
      ) {
        this.resetWheel()
      }

      if (
        (prevProps.DashboardData.panelClicked !== this.props.DashboardData.panelClicked ||
          prevProps.DashboardData.subDimensionClicked !==
          this.props.DashboardData.subDimensionClicked) &&
        HasValue(this.props.DashboardData.panelClicked)
      ) {
        this.rotateWheel(this.props.DashboardData.panelClicked)
      }
    }
    resetWheel = async () => {
      this.setState({
        dimListMaster: UnRefObj(this.dimListMaster),
        subDimListMaster: UnRefObj(this.subDimListMaster),
        panelIsClicked: this.props.DashboardData.panelIsClicked,
        panelClicked: this.props.DashboardData.panelClicked
      });
      this.formatData(UnRefObj(this.props.dimensionList === undefined ? [] : this.props.dimensionList));
    }

    getCustomSubdim = (customSDims) => {
      let staticCusSD = [
        {
          name: 'Control & Compliance',
          ID: 6,
          value: 100,
          fill: '#a100ff',
          DimID: 6,
          sDimID: 15,
          coreSubDimID: 6,
        },
        {
          name: 'Efficiency',
          ID: 6,
          value: 100,
          fill: '#a100ff',
          DimID: 6,
          sDimID: 16,
          coreSubDimID: 6,
        },
        {
          name: 'Speed & Agility',
          ID: 6,
          value: 100,
          fill: '#a100ff',
          DimID: 6,
          sDimID: 17,
          coreSubDimID: 6,
        },
      ]
      try {
        let customSDim = customSDims;
        const cusSDimCount = customSDim.length;
              
        if (cusSDimCount > 0) {
          let sdCount = 0;
          let tempArr = [];
          // Sort array with number of BOIs
          customSDim.sort((sd1, sd2) => { return sd2.boiListLen - sd1.boiListLen});

          if (cusSDimCount >= 3) {
            // If 3 or more data then display only top 3
            customSDim.forEach((sDim, i) => {
              if (sdCount < 3) {
                tempArr.push({
                  ID: 6,
                  name: sDim.name,
                  value: 100,
                  fill: '#a100ff',
                  DimID: 6,
                  sDimID: sDim.subDimID,
                  coreSubDimID: sDim.coreSubDimID,
                });
                sdCount++;
              }
            })
            customSDim = tempArr;
          } else {
            let subdims = [];
            // fill array with non static data
            customSDim.forEach((sDim, i) => {
              if (sdCount < 3) {
                subdims.push({
                  name: sDim.subDim,
                  subDimID: sDim.subDimID,
                  coreSubDimID: sDim.coreSubDimID
                })
                sdCount++;
              }
            });
            
            // Get static data
            staticCusSD.forEach((staticSD) => {
              let isDuplicate = subdims.some((tmpSD) => tmpSD.name === staticSD.name);
              if (sdCount < 3 && !isDuplicate) {
                subdims.push({
                  name: staticSD.name,
                  subDimID: staticSD.sDimID,
                  coreSubDimID: staticSD.coreSubDimID
                })
                sdCount++;
              }
            });
            // subdims.reverse();
            subdims.forEach((subdim, i) => {
              tempArr.push({
                ID: 6,
                name: subdim.name,
                value: 100,
                fill: '#a100ff',
                DimID: 6,
                sDimID: subdim.subDimID,
                coreSubDimID: subdim.coreSubDimID,
              }); 
            })
            customSDim = tempArr;
          }
        } else {
          // If no data then display 3 static data
          customSDim = staticCusSD
        }
        return customSDim;
      } catch (e) {
        console.log(e);
        return staticCusSD;
      }
    }

    formatCustomSDim = (customDim) => {
      try {
        let customSDim = TryGetNodeAlt(customDim, [], 'subDimList');
        const cusSDimCount = 3;//customSDim.length;
        // const cusSliceValue = 300 / cusSDimCount;
        customSDim = this.getCustomSubdim(customSDim);
          /* cusSDimCount === 0
            ? [
              {
                ID: 6,
                name: '',
                value: 300,
                fill: '#a100ff',
                DimID: 6,
                sDimID: 0,
                coreSubDimID: 0,
              },
            ]
            : customSDim.map((sDim, i) => {
              return {
                ID: 6,
                name: sDim.subDim,
                value: cusSliceValue,
                fill: '#a100ff',
                DimID: 6,
                sDimID: sDim.subDimID,
                coreSubDimID: sDim.coreSubDimID,
              };
            }); */
        const subDimListMaster = UnRefObj(this.subDimListMaster);

        let sdimSplice = subDimListMaster.splice(12, subDimListMaster.length);
        let fSubDimListMaster = subDimListMaster.splice(0, 11);
        fSubDimListMaster = fSubDimListMaster.concat(customSDim);
        fSubDimListMaster = fSubDimListMaster.concat(sdimSplice);

        this.setState({
          subDimListMaster: fSubDimListMaster,
          cusSDimCount: cusSDimCount,
        });
      } catch (e) {
        console.log(e);
      }
    };

    formatData = (dimL) => {

      const dims = this.formatDim(dimL);
      this.setState(
        {
          dimList: dims.dim,
          subDimList: dims.sDim,
          customDim: dims.custDim,
          selectedDimID: 0
        },
        () => {
          this.formatCustomSDim(dims.custDim);
        }
      );
    };
    formatDim = (dimList) => {
      let tSDim = [];
      let tDim = [];
      let custDim = [];
      dimList.forEach((dim) => {
        const sdFColor = sdFontColor.find(
          (item) => item.dimension.toLowerCase() === dim.CoreDimensionName.toLowerCase()
        );
        const fill = TryGetNodeAlt(sdFColor, '', 'fill');

        tSDim = tSDim.concat(
          dim.Subdimesions.map((subDim) => {
            return {
              DimID: dim.ID,
              subDimID: subDim.ID,
              coreSubDimID: subDim.CoreDimensionID,
              subDim: subDim.SubDimensionName,
              fill: fill,
              hasBoi: subDim.BIOSlIST.length > 0,
            };
          })
        );

        tDim.push({
          DimID: dim.ID,
          coreDimID: dim.CoreDimensionID,
          dim: dim.CoreDimensionName,
          fill: fill,
          isDisabled: !dim.Subdimesions.some((subDim) => subDim.BIOSlIST.length > 0),
        });

        if (dim.CoreDimensionName.toLowerCase() === masterDim.custom.toLowerCase()) {
          custDim = {
            DimID: dim.ID,
            coreDimID: dim.CoreDimensionID,
            dim: dim.CoreDimensionName,
            fill: fill,
            subDimList: dim.Subdimesions.filter((subDim) => subDim.BIOSlIST.length > 0).map(
              (subDim2) => {
                return {
                  subDimID: subDim2.ID,
                  coreSubDimID: subDim2.CoreDimensionID,
                  subDim: subDim2.SubDimensionName,
                  fill: fill,
                  boiListLen: subDim2.BIOSlIST.length
                };
              }
            ),
          };
        }
      });

      return {
        dim: tDim,
        sDim: tSDim,
        custDim,
      };
    };

    onClickDims = async (e) => {
      const isSDim = HasProperty(e, 'DimID');
      let sDimID = TryGetNodeAlt(e, 0, 'sDimID');
      const DimID = isSDim ? e.DimID : e.ID;

      const dimListOrig = this.props.dbDimensionList;
      let dimList = dimListOrig
        .filter((dim) => {
          return DimID === dim.ID;
        })
        .map((dim2) => {
          return {
            ...dim2,
            subDimensionList: dim2.subDimensionList.filter((sDim) => {
              return sDim.subDimensionID > 0 && sDim.boiList.length > 0;
            }),
          };
        });
      const subDim = dimList[0].subDimensionList;
      sDimID = subDim.length > 0 ? (!isSDim ? 0 : subDim.findIndex((sDim) => sDim.ID === sDimID)) : 0;

      if (sDimID === -1 || TryGetNodeAlt(subDim[sDimID], [], 'boiList').length === 0) return;

      const dimPanelID = TryGetNodeAlt(
        dimListOrig.find((dim) => {
          return DimID === dim.dimensionID;
        }),
        0,
        'dimensionID'
      );

      this.props.onSubdimensionClick(sDimID);
      this.props.panelOnClick(dimPanelID, true);

    };
    setCenterWheel = async () => {
      await new Promise((resolve, reject) => setTimeout(() => {
        resolve()
      }, 1000));

      let wheelCont = document.getElementsByClassName('recharts-surface')[0]
      const svgWh = wheelCont.getBoundingClientRect().height;
      const t28 = (svgWh * .28) + 2.5
      const y = (t28 / svgWh) * 100

      wheelCont.style.transformBox = 'fill-box'
      wheelCont.style.transformOrigin = '50% ' + y + '%'
    }

    rotateWheel = async (dimID) => {
      if (dimID === this.state.selectedDimID || this.rotating) return
      this.rotating = true

      this.setState({ isRotating: true }, async () => {
        await new Promise(resolve => setTimeout(resolve, 200))
        const isReset = dimID === 0
        dimID = isReset ? 2 : dimID
        const selDim = this.state.dimListMaster.find(e => e.ID === dimID)
        const selDegree = selDim.dimSequence

        let deg = 60 * (6 - selDegree)
        deg += isReset ? 30 : 0
        deg += [90, 30, -30, -90, -150, -210].indexOf(selDim.startAngle) > -1 ? 30 : 0

        let logo = document.getElementById('wheel-center-logo')
        let wheelCont = document.getElementsByClassName('recharts-surface')[0]


        wheelCont.style.transition = 'all 2s ease'
        logo.style.transition = 'all 2s ease'
        wheelCont.style.transform = `rotate(${deg}deg)`
        logo.style.transform = `rotate(${-deg}deg)`

        await new Promise((resolve) => setTimeout(() => {
          wheelCont.style.transform = null
          wheelCont.style.transition = null
          logo.style.transition = null
          logo.style.transform = null
          resolve()
        }, 2000));

        this.setState({ isRotating: false })
        this.rotating = false
        this.reorderWheel(dimID)
      })

    }
    reorderWheel = (dimID) => {
      // reorder all list
      let tempDim = [];
      let activeIndex = 0
      const tDimList = UnRefObj(this.state.dimListMaster);

      tempDim = tDimList.splice(
        tDimList.findIndex((e) => e.ID === dimID),
        tDimList.length
      );
      tempDim = tempDim.concat(tDimList);

      tempDim[0].startAngle = 120;
      tempDim[0].endAngle = 60;
      tempDim[0].dimSequence = 6;

      tempDim[1].startAngle = 60;
      tempDim[1].endAngle = 0;
      tempDim[1].dimSequence = 1;

      tempDim[2].startAngle = 0;
      tempDim[2].endAngle = -60;
      tempDim[2].dimSequence = 2;

      tempDim[3].startAngle = -60;
      tempDim[3].endAngle = -120;
      tempDim[3].dimSequence = 3;

      tempDim[4].startAngle = -120;
      tempDim[4].endAngle = -180;
      tempDim[4].dimSequence = 4;

      tempDim[5].startAngle = -180;
      tempDim[5].endAngle = -240;
      tempDim[5].dimSequence = 5;

      this.setState({
        selectedDimID: dimID,
        activeIndex: activeIndex,
        dimListMaster: tempDim
      });
    };

    render() {
      const { panelIsClicked, panelClicked } = this.props.DashboardData;
      return (
        <DashboardDimGraphPresentational
          onClickDims={this.onClickDims}
          customDim={this.state.customDim}
          dimList={this.state.dimList}
          subDimList={this.state.subDimList}
          activeIndex={this.state.activeIndex}
          dimListMaster={this.state.dimListMaster}
          cusSDimCount={this.state.cusSDimCount}
          subDimListMaster={this.state.subDimListMaster}
          panelIsClicked={panelIsClicked}
          panelClicked={panelClicked}
          isRotating={this.state.isRotating}
        />
      );
    }
  }
  return DashboardDimGraphContainer;
})();
export default connect(
  (state) => {
    return {
      dimensionList: state.DashboardData.dimensionSubDimension.CoreDimesionList,
      dbDimensionList: state.DashboardData.dbDimensionList,
      DashboardData: state.DashboardData,
      SharedData: state.SharedData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          onSubdimensionClick,
          panelOnClick,
        }
      ),
      dispatch
    )
)(DashboardDimGraph);
