import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
// import { Row, Col, Tabs, Tab, Accordion, Card, Button } from "react-bootstrap";
import { Accordion, Card, Button } from 'react-bootstrap';

import {
  getBOIProjectDetails,
  panelOnClick,
  onSubdimensionClick,
  setCommentaryRequiredData,
} from '../../Actions/Dashboard.Action';
import { getCustomBOIDetails } from '../../Actions/Shared.Action';
import store from '../../store';
import { HttpGet } from '../../Utilities/HTTPUtil';
import { TryGetObjValueAlt } from '../../Utilities/Formatter';
import { placeValueFormater } from '../../Utilities/Formatter';
import newInfo from '../../Static/images/svg/new-info.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// Components
import DashboardProjectDetailsModal from './DashboardProjectDetailsModal';
import CustomBOIModal from '../../Views/ProgramProjectConfiguration/CustomBOIModal';
import DashboardBarChart from './DashboardBarChart';
import DashboardTrendChart from '../Dashboard/DashboardTrendChart/DashboardTrendChart';
// import DashboardCommentaryModal from '../Dashboard/DashboardCommentaryModal/DashboardCommentaryModal'
import DashboardCommentaryHistoryModal from '../Dashboard/DashboardCommentaryModal/DashboardCommentaryHistoryModal';
import { HasProperty } from '../../Utilities/Validations';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const DashboardProjectDetails = (function () {
  class DashboardProjectDetailsPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <h5 className="bodyText24-Semibold">Dimension</h5>
          <div>
            <Accordion
              className="configure-client-collapse visualization-collapse"
              activeKey={this.props.panelClicked}
              onSelect={(e) => {
                this.props.panelOnClick(e, true);
                this.props.handleChange(e, 0);
                this.props.refreshData(e !== null ? true : false);
                if (this.props.showModal) this.props.onCloseModal();
              }}
            >
              {this.props.dimList.map((dItem, dIndex) => {
                if (dItem.dimensionID !== 0 && dItem.hasData) {
                  let data = this.props.setTabData(dItem, dIndex, this.props.summation);
                  return (
                    <Card>
                      <Accordion.Collapse eventKey={dItem.dimensionID}>
                        <Card.Body>
                          <div className="project-tabs-list dim-tabs">
                            <div position="static" color="default">
                              <Tabs
                                className="project-detail-tabs"
                                value={this.props.selectedTab || 0}
                                onChange={this.props.handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable auto tabs example"
                              >
                                {data.map((tab, index) => {
                                  const sDim = TryGetObjValueAlt(
                                    this.props.summation.sDim.find(
                                      (sDim) => sDim.sDimID === tab.sDimID
                                    ),
                                    '',
                                    'label'
                                  );
                                  return (
                                    <Tab
                                      label={
                                        <div style={{ display: 'flex', placeContent: 'center' }}>
                                          <div>{tab.tab_label}</div>
                                          {dItem.dimensionName === 'Financial' &&
                                            sDim.length > 0 && (
                                              <div className="summation sum-subdim">{sDim}</div>
                                            )}
                                        </div>
                                      }
                                      {...a11yProps(index)}
                                    />
                                  );
                                })}{' '}
                              </Tabs>
                            </div>
                            {data.map((tab, index) => {
                              return (
                                <TabPanel value={this.props.selectedTab} index={index}>
                                  {tab.tab_content}
                                </TabPanel>
                              );
                            })}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={dItem.dimensionID}>
                          <div className="bodytext18-medium-midnight">
                            {dItem.dimensionName}
                            {dItem.dimensionName === 'Financial' &&
                              TryGetObjValueAlt(this.props.summation.financial, false, 'sum') && (
                                <div className="summation sum-dim">
                                  {this.props.summation.financial.label}
                                </div>
                              )}
                          </div>
                          <GrooveIconSvg
                            id="dimAccordionButton"
                            customClassName="accordion-button"
                            size="large"
                            name="chevron-down"
                            iconStyle="solid"
                            iconColor="stat-neutral"
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                  );
                }
              })}
            </Accordion>
          </div>
          {this.props.showCustomBOI && (
            <CustomBOIModal
              isShow={this.props.showCustomBOI}
              onCustomBOIModalCancelClose={() => this.props.onClickCustomBOI(0, false)}
              subDimensionName={this.props.subDimensionName}
              dimensionName={this.props.dimensionName}
              boi={this.props.boi}
            />
          )}
        </React.Fragment>
      );
    }
  }

  class DashboardProjectDetailsContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
        isShow: false,
        dimensionName: '',
        subDimensionName: '',
        dimList: [],
        boi: {
          ClientName: '',
          offeringName: '',
          boiName: '',
          uom: '',
          description: '',
          LeadingIndicator: '',
          comment: '',
          MetricCalculation: '',
          customParameterList: [],
          AggregationLogic: '',
          SubOffering: '',
          AggregationLogicId: 0,
          MetricReason: '',
          AggregateReason: '',
        },
        summation: {
          financial: '',
        },
        reloadData: false,
        selectedBOIProjectDetail: {},
        selectedBOITab: 0,
        selectedBOITabMatrix: [[0], [0], [0], [0], [0], [0], [0]],
      };
      this.notificationPopoverTarget = React.createRef();
    }

    componentDidMount = () => {
      const programID = this.props.DashboardData.programID;
      this.props.setCommentaryRequiredData(programID, false);
      this.computeSummation(
        JSON.parse(JSON.stringify(this.props.dimList === undefined ? [] : this.props.dimList))
      );
      this.formatDim(
        JSON.parse(JSON.stringify(this.props.dimList === undefined ? [] : this.props.dimList))
      );
    };

    componentDidUpdate = (prevProps) => {
      if (
        (this.props.DashboardData.panelClicked !== prevProps.DashboardData.panelClicked ||
          this.props.DashboardData.panelClicked === null) &&
        this.state.showModal
      ) {
        this.onCloseModal();
      }
      if (JSON.stringify(this.props.dimList) !== JSON.stringify(prevProps.dimList)) {
        this.computeSummation(
          JSON.parse(JSON.stringify(this.props.dimList === undefined ? [] : this.props.dimList))
        );
        this.formatDim(
          JSON.parse(JSON.stringify(this.props.dimList === undefined ? [] : this.props.dimList))
        );
      }
    };

    computeSummation = (d) => {
      if (d.length === 0) return;
      let sumFinancial = 0;
      const finDim = d.find((dim) => dim.dimensionName.toLowerCase() === 'financial');
      const sDimSum = finDim.subDimensionList.map((sDim) => {
        let sum = 0;
        sDim.boiList
          .filter((boi) => boi.boiUOM.toLowerCase() === 'usd')
          .forEach((boi) => {
            sum += Number(boi.boiDeliveredValue);
          });
        sumFinancial += sum;
        return {
          sDimID: sDim.subDimensionID,
          sum: sum,
          label: sum === 0 ? '' : placeValueFormater(sum) + ' USD',
        };
      });
      this.setState({
        summation: {
          financial: {
            sum: sumFinancial,
            label: placeValueFormater(sumFinancial) + ' USD',
          },
          sDim: sDimSum,
        },
      });
    };

    formatDim = (d) => {
      const tDimList = d.map((dim) => {
        return {
          ...dim,
          hasData: dim.subDimensionList.some((subDim) => subDim.boiList.length > 0),
        };
      });

      this.initBOITabMatrix();
      this.setState({ dimList: tDimList });
    };

    handleChange = (event, newValue) => {
      this.props.onSubdimensionClick(newValue);
      this.initBOITabMatrix();
      this.setState({
        value: newValue,
        selectedBOITab: 0,
      });
    };

    onCloseModal = () => {
      this.setState({
        ...this.state,
        showModal: !this.state.showModal,
      });
    };

    handleProjectDetails = async (boiID, boiUOM, subDimID, dimID) => {
      const selectedBOI = this.props.DashboardData.dashboardCommentary.boiOption.find(
        (boi) =>
          boi.boiID === boiID &&
          boi.boiUOM === boiUOM &&
          boi.subDimensionID === subDimID &&
          boi.dimensionID === dimID
      );
      try {
        await this.props.getBOIProjectDetails(
          boiID,
          this.props.selectedYear.value,
          selectedBOI.clientProgramTargetID
        );
        this.setState({
          ...this.state,
          selectedBOIProjectDetail: {
            boiID,
            boiUOM,
            subDimID,
            dimID,
          },
          showModal: true,
        });
        const { clientProjectID } = this.props.DashboardData.boiProjectDetails[0];
        const programID = this.props.DashboardData.programID;
        this.props.createLog({
          programID: programID,
          projectID: clientProjectID
        });
      } catch (e) {
        console.log(e);
      }
    };

    customeBOIMOdalShow = () => {
      this.setState({
        isShow: true,
      });
    };

    refreshData = (value) => {
      this.setState({
        reloadData: value,
      });
    };

    groupByBOIName = (arr, prop) => {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
      arr.forEach((obj) => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    };

    initBOITabMatrix = async () => {
      let tDimList = this.state.dimList;
      let defaultBOITabMatrix = [[0], [0], [0], [0], [0], [0], [0]]
      let tempSelectedBOITabMatrix = [...defaultBOITabMatrix];

      tDimList.map(dItem => {
        if (dItem.dimensionID !== 0 && dItem.hasData) {
          let tempArr = [];
          let boiLens = [];
          let maxBOICount = 0;
          dItem.subDimensionList.map((sdItem, sdIndex) => {
            let boiLen = sdItem.boiList.length
            if (sdItem.subDimensionID !== 0 && boiLen !== 0) {
              boiLens.push(boiLen);
            }
          })
          if (boiLens.length > 0) {
            maxBOICount = Math.max(...boiLens);
            if (maxBOICount > 0) {
              for (let i = 0; i < maxBOICount; i++) {
                tempArr.push(0);
              }
              tempSelectedBOITabMatrix[dItem.ID] = tempArr;
            }
          }
        } else {
          tempSelectedBOITabMatrix[dItem.ID] = [0];
        }
      });
      console.log("initBOITabMatrix: ", tempSelectedBOITabMatrix)

      await this.setState({
        selectedBOITabMatrix: tempSelectedBOITabMatrix
      });
    }

    setTabData = (dItem, dIndex, summation) => {
      let program_tab_data = [];
      dItem.subDimensionList.map((sdItem, sdIndex) => {

        let combinedBOI = [];

        if (sdItem.subDimensionID !== 0 && sdItem.boiList.length !== 0) {
          combinedBOI = this.groupByBOIName(sdItem.boiList, 'boiName');
          program_tab_data.push({
            tab_label: sdItem.subDimensionName,
            tab_value: sdIndex + 1,
            tab_id: sdItem.subDimensionName,
            sDimID: sdItem.subDimensionID,
            tab_content: combinedBOI.map((groupedBOI, groupedBOIIndex) => {
              let boiSubTabdata = this.setTabSubData(groupedBOI, sdItem, dItem);

              let boiName = '';
              let uomArray = [];
              let boiList = [];
              let isMultipleUOMwithUSD =
                groupedBOI.filter((x) => x.isMultipleUOMwithUSD).length >= 1;

              groupedBOI.map((boi) => {
                if (JSON.stringify(boi) !== '{}') {
                  uomArray.push(boi.boiUOM);
                  boiName =
                    boi.boiName + ' (' + JSON.stringify(uomArray).replace(/[\[\]"]+/g, '') + ')';
                  boiList.push(boi);
                }
              });

              boiName =
                isMultipleUOMwithUSD && uomArray.filter((x) => x === 'USD').length > 0
                  ? boiName + '*'
                  : boiName;

              let selectedYear = this.props.selectedYear;
              let reportingYear = this.props.DashboardData.reportingYear;
              let latestReportingYear =
                reportingYear.length > 0 ? reportingYear[reportingYear.length - 1].value : 0;

              let dimID = dItem.ID;
              let selectedBOITab = this.state.selectedBOITabMatrix[dimID][groupedBOIIndex];

              return (
                <div className={`program-box-container`}>
                  {
                    // boiList.map((boiItem, boiIndex) => {
                    //   if (this.state.selectedBOITab === boiIndex)
                    //     return (
                    <Accordion
                      className="configure-client-collapse visualization-collapse"
                      defaultActiveKey={'boi-' + groupedBOIIndex}
                      onSelect={(e) => {
                        this.handleBOITabChange(e, 0, dimID, groupedBOIIndex);
                      }}
                    >
                      <Card>
                        <Accordion.Collapse eventKey={'boi-' + groupedBOIIndex}>
                          <Card.Body>
                            <div className="subtab-uom"></div>
                            <div className="project-tabs-list dim-tabs">
                              <div position="static" color="default">
                                <Tabs
                                  className="project-detail-tabs"
                                  value={selectedBOITab || 0}
                                  onChange={(e, value) => {
                                    this.handleBOITabChange(e, value, dimID, groupedBOIIndex);
                                  }}
                                  variant="scrollable"
                                  scrollButtons="on"
                                  indicatorColor="primary"
                                  textColor="primary"
                                  aria-label="scrollable auto tabs example"
                                >
                                  {boiSubTabdata.map((tab, index) => {
                                    return (
                                      <Tab
                                        className='subtabs-UOM-Highlight'
                                        label={
                                          <div style={{ display: 'flex', placeContent: 'center' }}>
                                            <div className='subtabs-UOM'>
                                              <div>{tab.tab_label === 'USD'
                                                ? 'USD ($)' : tab.tab_label === '%' ? 'Percentage (%)'
                                                  : tab.tab_label}</div>
                                            </div>
                                          </div>
                                        }
                                        {...a11yProps(index)}
                                      />
                                    );
                                  })}
                                </Tabs>
                              </div>
                              {boiSubTabdata.map((tab, index) => {
                                return (
                                  <TabPanel value={selectedBOITab} index={index}>
                                    {tab.tab_content}
                                  </TabPanel>
                                );
                              })}
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                          <div
                            className="dimension-header-container"
                          >
                            <div className="bodytext18-medium-midnight dashboi-header">
                              <div className="program-box-title">
                                <div id="prog-view">BOI</div>
                                <div className="program-box-title-container">
                                  <div className="program-box-title-label">
                                    <div id="prog-view-colored">{boiName}</div>
                                    {
                                      boiList.map((boiItem, boiIndex) => {
                                        if (selectedBOITab === boiIndex)
                                          return (
                                            <div id="dashboard-info-icon">
                                              <OverlayTrigger
                                                key="bottom"
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip id="dashboard-tooltip-right">
                                                    <div className="dashboard-details-wrap row">
                                                      <div class="col-md-6">
                                                        <h6>Offering</h6>
                                                        <p class="">{boiItem.offeringName}</p>
                                                        <h6>Dimension</h6>
                                                        <p class="">{dItem.dimensionName}</p>
                                                        <h6>BOI Description</h6>
                                                        <p class="">{boiItem.boiDescription}</p>
                                                      </div>
                                                      <div class="col-md-6">
                                                        <h6>Sub-Offering</h6>
                                                        <p class="">{boiItem.suboffering}</p>
                                                        <h6>Sub-dimension</h6>
                                                        <p class="">{sdItem.subDimensionName}</p>
                                                        <h6>Leading Practice</h6>
                                                        <p class="">{boiItem.leadingIndicator}</p>
                                                        <table>
                                                          <tr class="col-md-12">
                                                            <th class="col-md-6">
                                                              <h6>Parameter</h6>
                                                            </th>
                                                            <th class="col-md-6">
                                                              <h6>Unit of Measure</h6>
                                                            </th>
                                                          </tr>
                                                          {(boiItem.hasOwnProperty('customParameterList')
                                                            ? boiItem.customParameterList
                                                            : []
                                                          ).map((params) => {
                                                            return (
                                                              <tr>
                                                                <td class="col-md-6">
                                                                  <p>{params.ParameterName}</p>
                                                                </td>
                                                                <td class="col-md-6">
                                                                  <p>{params.ParameterUOM}</p>
                                                                </td>
                                                              </tr>
                                                            );
                                                          })}
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="dashboard-new-info-wrap">
                                                  {!boiItem.IsCustom ? (
                                                    <img className="new-info-icon" src={newInfo} alt="info" />
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </OverlayTrigger>
                                              {boiItem.IsCustom ? (
                                                <button
                                                  className="custom-boi"
                                                  style={{ marginLeft: '1em' }}
                                                  onClick={() => this.onClickCustomBOI(boiItem.boiID, true)}
                                                >
                                                  Custom
                                                </button>
                                              ) : null}
                                            </div>
                                          )
                                      })
                                    }
                                  </div>
                                </div>
                                {
                                  boiList.map((boiItem, boiIndex) => {
                                    if (selectedBOITab === boiIndex)
                                      return (
                                        <div className="dashboard-button-container">
                                          {boiItem.projectCount > 0 ? (
                                            <GrooveButton
                                              id="info-details"
                                              name="Solid Button Primary-Ops"
                                              hasIcon={true}
                                              type="outline"
                                              colorClass="stat-alternate"
                                              text="Project Details"
                                              ref={this.notificationPopoverTarget}
                                              callbackFunction={() =>
                                                this.handleProjectDetails(
                                                  boiItem.boiID,
                                                  boiItem.boiUOM,
                                                  sdItem.subDimensionID,
                                                  dItem.dimensionID
                                                )
                                              }
                                              iconAlignment="right"
                                              iconSize="tiny"
                                              iconName="info"
                                              iconStyle="solid"
                                              iconId="btn-icon-info"
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )
                                  })
                                }
                              </div>
                              {
                                boiList.map((boiItem, boiIndex) => {
                                  if (selectedBOITab === boiIndex)
                                    return (
                                      <div className="comment-section">
                                        <div className="comment">{boiItem.programCommentary}</div>
                                        {boiItem.programCommentary !== null &&
                                          boiItem.programCommentary !== '' &&
                                          (
                                            <DashboardCommentaryHistoryModal
                                              boiItem={boiItem}
                                              sdItem={sdItem}
                                              dItem={dItem}
                                              selectedYear={selectedYear}
                                              isProject={false}
                                              isFromGlobal={true}
                                            />
                                          )}
                                      </div>
                                    )
                                })
                              }
                            </div>
                          </div>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={'boi-' + groupedBOIIndex}
                          >
                            <GrooveIconSvg
                              id="subDimAccordionButton"
                              customClassName="accordion-button"
                              size="large"
                              name="chevron-down"
                              iconStyle="solid"
                              iconColor="stat-neutral"
                            />
                          </Accordion.Toggle>
                        </Card.Header>
                      </Card>
                    </Accordion>
                    //     )
                    // })
                  }
                </div>
              );
            }),
          });
        }
      });
      return program_tab_data;
    };
    setTabSubData = (groupedBOI, sdItem, dItem) => {
      let sub_tab_data = [];
      groupedBOI.map((boi, boiIndex) => {
        let boiItem = [boi];
        sub_tab_data.push({
          tab_label: boi.boiUOM,
          tab_value: boiIndex + 1,
          tab_id: boi.boiID,
          boiId: boi.boiID,
          tab_content: boiItem.map((boiItem) => {
            let negative = {
              both: Math.max(boiItem.boiTarget, boiItem.boiDeliveredValue) < 0,
              hasNegative: Math.min(boiItem.boiTarget, boiItem.boiDeliveredValue) < 0,
            };
            let max = negative.both
              ? Math.min(boiItem.boiTarget, boiItem.boiDeliveredValue) * -1
              : Math.max(boiItem.boiTarget, boiItem.boiDeliveredValue);
            if (negative.hasNegative && !negative.both) {
              max = Math.max(Math.abs(boiItem.boiTarget), Math.abs(boiItem.boiDeliveredValue));
            }
            max = Math.ceil(max / 4 + max);

            if (max <= 40) {
              while (max % 4 !== 0) {
                max++;
              }
            } else {
              let minRange = 4;
              let maxRange = 4 * 10;
              while (max < minRange || max > maxRange) {
                minRange = minRange * 10;
                maxRange = maxRange * 10;
              }
              max = max + (minRange - (max % minRange));
            }

            let range = negative.hasNegative && !negative.both ? [max * -1] : [0];

            if (negative.hasNegative && !negative.both) {
              let slash = max / 2;
              range.push(Math.floor(slash) * -1);
              range.push(0);
              range.push(Math.floor(slash));
              range.push(max);
            } else {
              for (var i = 1; i < 4; i++) {
                let slash = (max / 4) * i;
                negative.both ? range.push(Math.floor(slash) * -1) : range.push(Math.floor(slash));
              }
              range.push(negative.both ? max * -1 : max);
            }

            let item = {
              boiid: boiItem.boiID,
              // Validated
              boiIdentifiedValue: boiItem.boiIdentifiedValue,
              boiDeliveredValue: boiItem.boiDeliveredValue,
              boiDescription: boiItem.boiDescription,
              boiName: boiItem.boiName,
              boiUOM: boiItem.boiUOM,
              // Improvement
              impIdentified: boiItem.impIdentified,
              impDelivered: boiItem.impDelivered,
              // Yet to be Validated
              ytvDelivered: boiItem.ytvDelivered,
              ytvIdentified: boiItem.ytvIdentified,
            };
            let programFiscalYearRange = this.props.DashboardData.programFiscalYearRange;
            let selectedReportingYear = this.props.DashboardData.selectedYear.label[0];
            const fy =
              'FY ' +
              programFiscalYearRange.min.toString().slice(-2) +
              ' - FY ' +
              selectedReportingYear.toString().slice(-2);

            let isProgramCreator = false;
            const dashboardCommentary = this.props.DashboardData.dashboardCommentary;
            if (dashboardCommentary) {
              isProgramCreator =
                dashboardCommentary.selectedProgram.programCreator ===
                this.props.UserInformation.enterpriseID ||
                (this.props.UserInformation.isSuperAdmin
                  //  && !this.props.UserInformation.isIOAdmin
                  ); 
                //for global dashboard, IO is not admin
            }
            const selectedBOI = HasProperty(this.props.DashboardData, 'dashboardCommentary')
              ? this.props.DashboardData.dashboardCommentary.boiOption.find(
                (boi) =>
                  boi.boiID === groupedBOI.boiID &&
                  boi.boiUOM === groupedBOI.boiUOM &&
                  boi.subDimensionID === sdItem.subDimensionID &&
                  boi.dimensionID === dItem.dimensionID
              )
              : [];
            let clientProgramTargetID = 0;
            if (selectedBOI) {
              clientProgramTargetID = selectedBOI.clientProgramTargetID;
            }

            return (
              <div className={`program-box-container`}>
                <div className="modal-container">
                  <DashboardProjectDetailsModal
                    boiItem={boiItem}
                    onCloseModal={this.onCloseModal}
                    showModal={
                      this.state.showModal &&
                      this.state.selectedBOIProjectDetail.boiID === boiItem.boiID &&
                      this.state.selectedBOIProjectDetail.boiUOM === boiItem.boiUOM &&
                      this.state.selectedBOIProjectDetail.subDimID === sdItem.subDimensionID &&
                      this.state.selectedBOIProjectDetail.dimID === dItem.dimensionID
                    }
                    notificationPopoverTarget={this.notificationPopoverTarget.current}
                    sdItem={sdItem}
                    dItem={dItem}
                    selectedYear={this.props.selectedYear}
                    createLog={this.props.createLog}
                  />
                </div>
                <div>
                  <div className="cumulative-container">
                    <div className="title-container">
                      <span className="fy"> {fy} </span>
                      <span className="title"> Target and Delivered </span>
                    </div>
                    <div className="graph-container">
                      <div className="chart-col">
                        <div className="global-graph-left">
                          <div className="global-program-cumulative-container">
                            <DashboardBarChart projectItem={item} />
                          </div>
                        </div>
                        <div className="global-legend-list">
                          <div className="global-legend-list-item">
                            <span className="global-dot-square-palette-purple"></span>Target
                          </div>
                          <div className="global-legend-list-item">
                            <span className="global-dot-square-palette-amber"></span>Delivered
                          </div>
                        </div>
                        {boiItem.boiUOM === 'USD' && boi.isMultipleUOMwithUSD ? (
                          <div className="disclaimer-BOI-UOM">
                            <div className="disclaimer-info-icon">
                              <GrooveIconSvg
                                name="exclamation"
                                iconStyle="solid"
                                // className="exclamation-icon"
                                iconAlignment="right"
                                iconSize="tiny"
                                className="exclamation-icon-dashboard"
                              />
                            </div>
                            <div className="disclaimer-usd">
                              * Disclaimer: The BOI unit of measurement has been converted to dollar
                              ($)
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="vertical-divider"></div>
                      <div className="chart-col">
                        <div className="graph-right">
                          <div className="trend-header">Cumulative Approved Value Trend</div>
                          <div className="prog-UOM">{boiItem.boiUOM}</div>
                          {boiItem.trendGraphList && boiItem.trendGraphList.length > 0 ? (
                            <DashboardTrendChart
                              className="program-trend-chart"
                              trendGraphList={boiItem.trendGraphList}
                              unit={boiItem.boiUOM}
                            />
                          ) : (
                            <div className="no-trend-label">No Trend Data available</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }),
        });
      });

      return sub_tab_data;
    };

    handleBOITabChange = (e, value, dimID, groupedBOIIndex) => {
      try {
        let tempSelectedBOITabMatrix = [...this.state.selectedBOITabMatrix];
        let tempSelectedBOITabMatrixArr = [...tempSelectedBOITabMatrix[dimID]]

        tempSelectedBOITabMatrixArr[groupedBOIIndex] = value;
        tempSelectedBOITabMatrix[dimID] = tempSelectedBOITabMatrixArr;

        this.setState({
          selectedBOITab: value,
          selectedBOITabMatrix: tempSelectedBOITabMatrix
        });
      } catch (e) {
        console.log(e);
      }
    };


    onClickCustomBOI = async (boiId, isOpen) => {
      let programId = store.getState().DashboardData.programID;
      this.setState({
        showCustomBOI: !this.state.showCustomBOI,
      });

      try {
        if (isOpen) await this.props.getCustomBOIDetails(programId, boiId);
        const data = this.props.SharedData.customBOI;
        const hasData = Object.keys(data).length > 0 ? true : false;
        this.setState({
          dimensionName: hasData ? data[0].CoreDimensionName : '',
          subDimensionName: hasData ? data[0].SubDimensionName : '',
          boi: {
            ClientName: hasData ? data[0].ClientName : '',
            offeringName: hasData ? data[0].Offering : '',
            boiName: hasData ? data[0].BOIName : '',
            uom: hasData ? data[0].UOM : '',
            description: hasData ? data[0].BOIDescription : '',
            LeadingIndicator: hasData ? data[0].LeadingIndicator : '',
            comment: hasData ? data[0].BOIAdditionComment : '',
            MetricCalculation: hasData ? data[0].MetricCalculation : '',
            customParameterList: hasData
              ? data[0].CustomParameterList.map((x, i) => {
                return {
                  ParameterCustomCode: x.ParameterName,
                  ParameterUOM: x.ParameterUOM,
                };
              })
              : [],
            AggregationLogic: hasData ? data[0].AggregationLogic : '',
            SubOffering: hasData ? data[0].SubOfferingName : '',
            AggregationLogicId: hasData ? data[0].BOIDefinitionMasterID : 0,
            MetricReason: hasData ? data[0].ReasonForNoBOIDefinition : '',
            AggregateReason: hasData ? data[0].ReasonForNoAggregationLogic : '',
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    render() {
      const { subDimensionClicked, panelClicked } = this.props.DashboardData;

      const { panelOnClick, onSubdimensionClick } = this.props;
      return (
        <DashboardProjectDetailsPresentational
          value={this.state.value}
          handleChange={this.handleChange}
          dimList={this.state.dimList}
          showModal={this.state.showModal}
          onCloseModal={this.onCloseModal}
          setTabData={this.setTabData}
          getBOIProjectDetails={getBOIProjectDetails}
          selectedTab={subDimensionClicked}
          panelClicked={panelClicked}
          panelOnClick={panelOnClick}
          onSubdimensionClick={onSubdimensionClick}
          onClickCustomBOI={this.onClickCustomBOI}
          showCustomBOI={this.state.showCustomBOI}
          subDimensionName={this.state.subDimensionName}
          dimensionName={this.state.dimensionName}
          boi={this.state.boi}
          showProjectDetails={this.showProjectDetails}
          boiIDList={this.state.boiIDList}
          refreshData={this.refreshData}
          summation={this.state.summation}
          groupByBOIName={this.groupByBOIName}
          createLog={this.props.createLog}
        />
      );
    }
  }

  return DashboardProjectDetailsContainer;
})();

export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      DataIngestionData: state.DataIngestionData,
      ConfigureProgramData: state.ConfigureProgramData,
      SharedData: state.SharedData,
      DashboardData: state.DashboardData,
      UserInformation: state.userInformation,
      ProgramConfigurationData: state.ProgramConfigurationData,
      dimList: state.DashboardData.dbDimensionList,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getCustomBOIDetails,
          getBOIProjectDetails,
          panelOnClick,
          onSubdimensionClick,
          setCommentaryRequiredData,
        }
      ),
      dispatch
    )
)(DashboardProjectDetails);
