import React, { Component } from "react";
import addfiles from "../../Static/images/360cvm/AddFilesIcon.svg";

const EmptyBOI = (function () {
  class EmptyBOIPresentational extends Component {
    render() {
      return (
        <div className="empty-dimension-container">
          <div className="empty-program-container bodytext18-regular-slate">
            <img src={addfiles} alt="select client" />
            <span className="bodytext24-medium-slate">
              {this.props.isApprovedProgram ? 'No available Business Outcome Indicator' :
                'No Business Outcome Indicator Added'
              }
            </span>
            {this.props.isApprovedProgram ? '' :
              this.props.type === "program" ? "Please add BOI(s) to this program by using the 'Add BOIs' button above."
                :
                "Please select BOI(s) to this project by using the 'Select BOIs' button above."
            }

          </div>
        </div>
      )
    }
  }

  class EmptyBOIContainer extends Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      return <EmptyBOIPresentational type={this.props.type} isApprovedProgram={this.props.isApprovedProgram} />;
    }
  }

  return EmptyBOIContainer;
})();

export default EmptyBOI;
