import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
//icons
import AddFileIcon from '../../../../Static/images/360cvm/AddFilesIcon.svg';
import downloadIcon from '../../../../Static/images/360cvm/download-purple.svg';
import CVMToasterNotif from '../../../Shared/CVMToasterNotif/CVMToasterNotif';
//css
import './ClientStoryAcknowledgementFileHistory.css';
import { trimFieldForFileName, getUserTimeZone } from '../../../../Utilities/Formatter';
import { HttpPostDownloadFile, GetSubMenuId } from '../../../../Utilities/HTTPUtil';
import CVMPagination from '../../../Shared/CVMPagination/CVMPagination';
import {
  successClientDownload,
  failedClientDownload,
  onSelectFileEntry,
  onSelectAllFileEntry,
} from '../../../../Actions/ConfigureProgram.Action';

const ClientStoryAlignmentFileHistory = (function () {
  class ClientStoryAlignmentFileHistoryPresentational extends Component {
    render() {
      let hasSelected = this.props.fileHistoryData.filter((item) => item.selected).length > 0;
      return (
        <React.Fragment>
          <CVMToasterNotif />
          <div className="cvm-tab-content file-history-container">
            {this.props.fileHistoryData.length > 0 ? (
              <div className="select-boi-modal">
                <div className="select-boi-header">
                  <div className="file-history-column select-boi-modal-column">
                    <div
                      className={
                        this.props.selectAll
                          ? 'cvm-custom-check-box select-all-boi-checked'
                          : 'cvm-custom-check-box'
                      }
                      onClick={(e) => {
                        this.props.onSelectAll();
                      }}
                    >
                      <div />
                    </div>
                  </div>
                  <div className="file-history-column select-boi-modal-column boi-name">
                    File Name
                  </div>
                  <div className="file-history-column select-boi-modal-column">Uploaded By</div>
                  <div className="file-history-column select-boi-modal-column">
                    Uploaded Date & Time
                  </div>
                  <div className="file-history-column select-boi-modal-column">
                    <GrooveButton
                      customClassName="download-history-btn"
                      id="primry-btn-1"
                      name="Solid Button Primary-Ops"
                      isDisabled={!hasSelected}
                      hasIcon={true}
                      type="outline"
                      colorClass="stat-alternate"
                      size="normal"
                      text="Download History"
                      iconAlignment="right"
                      iconSize="small"
                      iconName="clock"
                      iconStyle="regular"
                      iconId="btn-icon-01"
                      callbackFunction={(e) => this.props.onDownloadFileHistory()}
                    />
                  </div>
                </div>
                <div className="select-boi-body">
                  {this.props.fileHistoryDataPaged.map((item) => {
                    // item.isBOIUsedInRVD = true
                    item.isDisabled = false;
                    item.isDisabled = false;
                    let uploadedDateTime = item.uploadedDateTime.replace('-', ' ');
                    uploadedDateTime = uploadedDateTime.replace('-', ', ');
                    return (
                      <div className={'select-boi-body-row'}>
                        <div className="file-history-column select-boi-modal-column">
                          <div
                            className={
                              item.selected
                                ? 'cvm-custom-check-box cvm-custom-checked'
                                : 'cvm-custom-check-box'
                            }
                            onClick={(e) => this.props.onSelectFileEntry(item)}
                          >
                            <GrooveIconSvg
                              customClassName="cvm-check-box"
                              size="small"
                              name="check"
                              iconStyle="solid"
                              iconColor="dl-white"
                            />
                          </div>
                        </div>
                        <div
                          className="file-history-column select-boi-modal-column file-name"
                          title={item.fileName}
                        >
                          <GrooveIconSvg
                            customClassName="file-history-paperclip"
                            size="small"
                            name="paperclip"
                            iconStyle="solid"
                            iconColor="stat-alternate"
                          />
                          {item.fileName}
                        </div>
                        <div
                          className="file-history-column select-boi-modal-column"
                          title={item.uploadedBy}
                        >
                          {item.uploadedBy}
                        </div>
                        <div
                          className="file-history-column select-boi-modal-column"
                          title={uploadedDateTime}
                        >
                          {uploadedDateTime}
                        </div>
                        <div className="file-history-column select-boi-modal-column">
                          <img
                            className="file-download"
                            src={downloadIcon}
                            alt="file download"
                            onClick={(e) => this.props.onDownloadFileHistory(item)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <CVMPagination
                  list={this.props.fileHistoryData}
                  callbackFunction={(e) => this.props.setPageItems(e)}
                />
              </div>
            ) : (
              <div className="empty-program-container bodytext18-regular-slate">
                <img src={AddFileIcon} alt="select client" />
                <span className="bodytext24-medium-slate">No Available Attachment(s)</span>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }
  }

  class ClientStoryAlignmentFileHistoryContainer extends Component {
    constructor() {
      super();
      this.state = {
        fileHistoryData: [],
        fileHistoryDataPaged: [],
        selectAll: false,
        downloadComplete: false,
      };
    }

    componentDidUpdate = (prevProps) => {
      if (this.props.fileHistoryData !== this.state.fileHistoryData) {
        this.setState({
          fileHistoryData: this.props.fileHistoryData,
          fileHistoryDataPaged: this.props.fileHistoryData,
        });
      }
    };

    componentDidMount = () => {
      if (this.props.fileHistoryData !== this.state.fileHistoryData) {
        this.setState({
          fileHistoryData: this.props.fileHistoryData,
          fileHistoryDataPaged: this.props.fileHistoryData,
        });
      }
    };

    componentWillUnmount = () => {
      this.setState({
        fileHistoryData: [],
        fileHistoryDataPaged: [],
        selectAll: false,
      });
    };

    onSelectAll = () => {
      let selectAll = !this.state.selectAll;
      let fileHistoryData = this.state.fileHistoryData;

      fileHistoryData.map((item) => {
        item.selected = selectAll;
      });

      this.setState(
        {
          selectAll: selectAll,
          fileHistoryData: fileHistoryData,
        },
        () => {
          this.props.onSelectAllFileEntry();
        }
      );
    };

    onSelectFileEntry = (fileData) => {
      let fileHistoryData = this.state.fileHistoryData;
      let isAllSelected = true;

      fileHistoryData.map((item) => {
        if (item.ID === fileData.ID) {
          item.selected = !item.selected;
        }

        if (!item.selected) {
          isAllSelected = false;
        }
      });

      this.setState(
        {
          selectAll: isAllSelected,
          fileHistoryData: fileHistoryData,
        },
        () => {
          this.props.onSelectFileEntry();
        }
      );
    };

    onDownloadFileHistory = async (item) => {
      let mainSelectedProject = this.props.ConfigureProgramData.mainSelectedProject;
      let fileHistoryData = this.state.fileHistoryData;

      let fileHistoryIds = [];
      item
        ? (fileHistoryIds = [{ fileID: item.ID, fileType: item.fileType }])
        : fileHistoryData
          .filter((items) => items.selected)
          .map((data) => fileHistoryIds.push({ fileID: data.ID, fileType: data.fileType }));
      let singleData = fileHistoryData.filter((items) => items.selected);

      let fileItem = item ? item : singleData[0];

      let fileType = fileItem.fileName.substring(fileItem.fileName.lastIndexOf('.') + 1);
      let fileName = fileItem.fileName.substring(0, fileItem.fileName.lastIndexOf('.'));
      let projectName = trimFieldForFileName(
        mainSelectedProject.projectDetails.clientProjectName,
        100
      );
      let uniqueID = mainSelectedProject.projectDetails.projectID;
      let date = fileItem.uploadedDateTime.replace('|', '');
      date = date.replace('   ', ' ');
      date = date.replace('  ', ' ');
      date = date.replace(':', ' ');
      date = date.replace(/ /g, '_');
      date = date + '';
      let clientName = this.props.SharedData.selectedClient.label;
      let fileNameLength = `${clientName}_${projectName}_${uniqueID}__${date}.${fileType}`.length;
      let lastIndex = 200 - fileNameLength;
      fileName = encodeURI(trimFieldForFileName(fileName, lastIndex));
      let downloadFileName = `${decodeURI(
        `${clientName}_${projectName}_${uniqueID}_${fileName}_${date}`
      )}.${fileType}`;

      try {
        let data;
        let tz = getUserTimeZone();
        let isNegative = tz.includes('-');
        tz = tz.replace('-', '').replace('+', '');
        let subMenuId = GetSubMenuId("Project Data Entry");

        data = await HttpPostDownloadFile(
          `ProjectDataEntry/DownloadProjectDataEntryFileHistory?clientProjectID=${uniqueID}&UTCOffset=${tz}&isNegative=${isNegative}&SubMenuID=${subMenuId}`,
          fileHistoryIds,
          item
            ? downloadFileName
            : !item && singleData.length === 1
              ? downloadFileName
              : `${decodeURI(`${clientName}_${projectName}_${uniqueID}`)}.zip`
        );

        this.props.successClientDownload();
        if (data.status === 200) {
          this.props.successClientDownload();
          this.setState({
            downloadComplete: true,
          });
        } else {
          this.props.failedClientDownload();
        }
      } catch (err) {
        console.log(err);
      }
    };

    setPageItems = (items) => {
      this.setState({
        fileHistoryDataPaged: items,
      });
    };

    render() {
      return (
        <ClientStoryAlignmentFileHistoryPresentational
          type={this.props.type}
          fileHistoryData={this.state.fileHistoryData}
          selectAll={this.state.selectAll}
          fileHistoryDataPaged={this.state.fileHistoryDataPaged}
          onSelectFileEntry={this.onSelectFileEntry}
          onSelectAll={this.onSelectAll}
          onDownloadFileHistory={this.onDownloadFileHistory}
          setPageItems={this.setPageItems}
        />
      );
    }
  }

  return ClientStoryAlignmentFileHistoryContainer;
})();

export default connect(
  (state) => {
    return {
      SharedData: state.SharedData,
      ConfigureProgramData: state.ConfigureProgramData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          successClientDownload,
          failedClientDownload,
          onSelectFileEntry,
          onSelectAllFileEntry,
        }
      ),
      dispatch
    )
)(withRouter(ClientStoryAlignmentFileHistory));
