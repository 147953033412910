import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { GrooveIconSvg } from "tfo-groove";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { isValidURL } from "../../../Utilities/Validations";

import {
    onShowUploadSupport,
    setUploadSupportDoc,
} from '../../../Actions/Shared.Action';  
import { FormDateDDMMMYYYUTC } from '../../../Utilities/Formatter';

const ModalFileLink = (function () {
    class ModalFileLinkPresentaional extends Component {
        render() {
            let document = this.props.document;
            return (
                <React.Fragment>
                    <div className="link-wrapper">
                        <div className="label14-regular-midnight">
                            <GrooveIconSvg
                                size="small"
                                name="link"
                                iconStyle="solid"
                                iconColor="stat-alternate"
                            />
                            <input
                                type="text"
                                name="link"
                                placeholder="https//www.example.com/index.html"
                                maxlength="500"
                                onChange={(e) => this.props.onChangeLink(e)}
                                value={this.props.link}
                            />
                        </div>
                    </div>
                    {!this.props.fileIsValid ? (
                        <div className="invalid-reason">{this.props.errorUpload}</div>
                    ) : null}
                    
                    {Object.keys(document).length > 0 && <div className="view-button-wrapper">
                        <div className="view-button" onClick={() => {this.props.onViewLast()}}>
                            View last updated URL
                            <FontAwesomeIcon icon={this.props.viewLastOpen ? faChevronUp : faChevronDown} size="sm" />
                        </div>
                    </div>} 
                    {this.props.viewLastOpen && Object.keys(document).length > 0 && <div className="view-last-wrapper">
                        <div>
                            <GrooveIconSvg
                                size="small"
                                name="link"
                                iconStyle="solid"
                                iconColor="stat-alternate"
                            />  
                            <span>{document.Link}</span>
                        </div>
                        <div>{FormDateDDMMMYYYUTC(document.LastModifiedDate)}</div>
                        <div>
                            <a
                                href={document.Link}
                                target="_blank"
                            >
                                <FontAwesomeIcon 
                                    className="view-last-download" 
                                    icon={faArrowAltCircleRight} size="sm"
                                />
                            </a>
                        </div>
                    </div>}
                </React.Fragment>
            );
        }
    }

    class ModalFileLinkContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                link: '',
                errorUpload: '',
                fileIsValid: null,
                viewLastOpen: false,
            };
        }

        onViewLast = () => {
            this.setState({
                viewLastOpen: !this.state.viewLastOpen,
            })
        }

        onChangeLink = (e) => {
            if(isValidURL(e.currentTarget.value)){
                let { uploadSupportDoc } = this.props.SharedData;
                uploadSupportDoc.link = e.currentTarget.value;
                uploadSupportDoc.docType = this.props.radioSelected;
            
                this.setState({
                    link: e.currentTarget.value,
                    errorUpload: '',
                    fileIsValid: null,
                }, () => { this.props.setUploadSupportDoc(uploadSupportDoc) });
            }
            else{
                let uploadSupportDoc = {
                    file: {},
                    link: "",
                    docType: 0,
                    docID: 0,
                };

                this.setState({
                    link: e.currentTarget.value,
                    errorUpload: 'Invalid URL',
                    fileIsValid: false,
                }, () => { this.props.setUploadSupportDoc(uploadSupportDoc) });
            }
        }
    
        render() {
          let document = {};
          if(this.props.radioSelected !== null){
            let idx = this.props.supportLinks.findIndex((doc) => doc.DocumentType === this.props.radioSelected.toString())
            if(idx !== -1) document = this.props.supportLinks[idx];  
          }
          return (
            <ModalFileLinkPresentaional
                {...this.state}
                onViewLast={this.onViewLast}
                onChangeLink={this.onChangeLink}
                document={document}
            />
          );
        }
      }
    
      return ModalFileLinkContainer;
})();

export default connect(
    state => {
      return {
        SharedData: state.SharedData,
      }
    },
    dispatch => bindActionCreators(Object.assign({},
      {
        onShowUploadSupport,
        setUploadSupportDoc,
      }), dispatch)
  )(ModalFileLink);