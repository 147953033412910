import React, { Component } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import store, { history } from "../../../store";
import Utilities from "../../../Utilities";
import { GrooveIconSvg } from "tfo-groove";

import "./ConfigurationCompleteStyles.css";

const ConfigurationComplete = (function() {
  class ConfigurationCompletePresentational extends Component {
    render() {
      return (
        <div className="config-complete-container">
          <div className="completed-status-details">
            <div className="completed-icon">
              <GrooveIconSvg
                customClassName="accordion-button"
                size="large"
                name="check"
                iconStyle="solid"
                iconColor="stat-alternate"
              />
            </div>
            <div className="main-text bodytext24-medium-slate">
              Configuration process successfully completed
            </div>

            <div className="completed-details bodytext18-medium-slate">
              <div>
                <div className="completed-label">
                  {" "}
                  Reporting Year:<div className="completed-value">{this.props.details.ReportingYear}</div>
                </div>
                <div className="completed-label">
                  {" "}
                  Operations Accounts Lead:
                  <div className="completed-value">{this.props.details.OperationsAccountLead}</div>
                </div>
              </div>
              <div>
                <div className="completed-label">
                  {" "}
                  No. of Programs: <div className="completed-value">{this.props.details.NoOfPrograms}</div>
                </div>
                <div className="completed-label">
                  {" "}
                  Client Admin:
                  <div className="completed-value">{this.props.details.ClientAdmin}</div>
                </div>
              </div>
            </div>
          </div>

          <Row className="completed-programs-container">

            {
              this.props.details.Programinfo.map((program) => {
              return(
                <Col lg={3} md={3} sm={4}>
                  <div className="completed-program-tile">
                    <div className="completed-program-title">{program.ProgramName}</div>
                    <div className="completed-program-label">
                      No. of projects : {program.NoOfProjects}
                    </div>
                  </div>
                </Col>
              )})
            }
          </Row>
        </div>
      );
    }
  }

  class ConfigurationCompleteContainer extends Component {
    constructor() {
      super();
      this.state = {
        details: {
          "ReportingYear": 0,
          "OperationsAccountLead": "",
          "ClientAdmin": "",
          "NoOfPrograms": 0,
          "Programinfo": [
            {
              "ProgramName": "",
              "NoOfProjects": 0
            }
          ]
        }
      };
    }
    componentDidMount = () => {
      this.getCompletedDetails();
    }

    getCompletedDetails = async () => {
      let clientId = this.props.SharedData.selectedClient.value;
      let reportingYear = this.props.ClientConfigurationData.workFlowReportingYear;
      let token = await Utilities.getToken();
      fetch(Utilities.getApiHttps() + `ClientConfiguration/GetConfigurationCompleteDetails?ClientId=${clientId}&ReportingYear=${reportingYear}`, {
        method: "GET",
        headers: {
          "Cache-Control":"no-store",
          Pragma: "no-cache",
          Expires: 0,
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== "") {
            this.setState({
              details: data
            })
          }
        });
    };

    render() {
      return <ConfigurationCompletePresentational details={this.state.details}/>;
    }
  }

  return ConfigurationCompleteContainer;
})();

// export default ConfigurationComplete;
export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      userInformation: state.userInformation,
    };
  },
  (dispatch) =>
    bindActionCreators(Object.assign({}, { }), dispatch)
)(ConfigurationComplete);