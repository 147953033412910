import "babel-polyfill";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { AsyncComponent } from "./_modules";
import { USER_SITE_ACT_PAR } from './main/Constants/Modules'
import Utilities from "./main/Utilities/index";
import { IsValidStr } from "./main/Utilities/Validations";
import { SetCallbackURL, SetReferrer, SetSessionTabID } from "./main/Utilities/AccessManagementUtil";
import UserSession, { SetToken } from "./main/Utilities/UserSession";
import { DeleteSessionStorage } from './main/Utilities/BrowserUtil';
import { unregister } from "./registerServiceWorker"
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import Application from './main/index';

// const Application = AsyncComponent(() => import("./main/index"));

export const msalInstance = new PublicClientApplication(window.webConfig.msalConfig);

class App extends Component {
  constructor(props) {
    super(props);
  }
  // Login the User
  componentWillMount() {
    DeleteSessionStorage(USER_SITE_ACT_PAR)
    this.preloadSettings()
  }

  componentDidMount() {
    UserSession.handleUserActions();
    Utilities.handleLogout();
    
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }

      // Optional - This will update account state if a user signs in from another tab or window
      msalInstance.enableAccountStorageEvents();

      msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          //let usageTrackingAppID = window.webConfig.usageTracking[window.webConfig.environment];
          //window.tfo_ping (usageTrackingAppID, window, event.payload.accessToken);
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
      }
    });
  }

  preloadSettings = () => {
    let path = window.location.pathname.toLowerCase();
    let tClient = path.split("/")[2];
    if (IsValidStr(tClient) &&
      !(path.includes("reportvaluedelivered/downloadclientacknowledgementfilehistory") ||
        path.includes("projectdataentry/downloadclientstoryfilehistory") ||
        path.includes('dashboard/global')
      )
    ) {
      tClient = decodeURIComponent(tClient);
      const comModule = path.split("/")[1];
      const programID = path.split("/")[3] || "";
      const projectID = path.split("/")[4] || "";
      const dcID = path.split("/")[5] || "";
      path = `/${comModule}/${tClient}${isNaN(programID) ? "" : `/${programID}`}${isNaN(projectID) ? "" : `/${projectID}`
        }${isNaN(dcID) ? "" : `/${dcID}`}`
    } else if (IsValidStr(path) && path.includes('dashboard') && !path.includes('dashboard/global')) {
      let urlParams = window.location.search.toLowerCase();
      if (IsValidStr(urlParams)) {
        path = path + urlParams;
      }
    }
    SetSessionTabID()
    SetCallbackURL(path)
    SetReferrer()
  }

  render() {
    return <Application pca={msalInstance}/>;
  }
}



ReactDOM.render(
    <React.StrictMode>
      <Router>
        <App pca={msalInstance}/>
      </Router>
    </React.StrictMode>, document.getElementById("root"));
unregister();