import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { GrooveButton, GrooveIconSvg } from 'tfo-groove';
import { withRouter } from 'react-router-dom';
import ProgramProjectHeader from '../ProgramProjectHeader';
import ProgramDimAccordionRejected from '../DimensionAccordion/ProgramDimAccordionRejected';
import ALATable from '../ALATable/ALATable';
import AddBOIPopup from './AddBOIPopup';
import SendForApproval from '../SendForApproval';
import Stepper from '../Stepper';
import {
  setNewProgramDetails,
  sendSADEditReject,
  sendEditReject,
  setProgSFAPayload,
  getProgramDeletedBOISAD,
  handleCheckAllOfferings,
} from '../../../Actions/ProgramConfiguration.Action';

import { getCustomBOIDetails, setEditApproved, setEmailError } from '../../../Actions/Shared.Action';
import { IsValid, IsValidStr, HasProperty, IsValidAccentureEmail } from '../../../Utilities/Validations';
import { TryGetObjValueAlt, FormatCVMParamValue } from '../../../Utilities/Formatter';
import BOIHistoryModal from '../BOIHistoryModal';
import { LogPPCUserAction } from '../../../Utilities/AccessManagementUtil';
import { PPC_EX_PROG_UPD, PROG_ID_VAL_ALA } from '../../../Constants/Modules'

const ProgramALAppRejectedEditBOIs = (function () {
  class ProgramALAppRejectedEditBOIsPresentational extends Component {
    render() {
      const isReadOnly =
        this.props.selectedProgramDetails.BTLead !== this.props.userInformation.emailAddress &&
        !this.props.userInformation.isSuperAdmin;
      const sfaForm = this.props.sfaForm;
      let clientID = this.props.programClientDetails.ClientMasterID;
      let user = this.props.userInformation.userProfiles.find((x) => x.ClientMasterId === clientID && x.ProfileID !== 16); //exclude QC user
      let isConfigurator = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15;
      const userType = this.props.userInformation.isSuperAdmin
        ? 'admin'
        : isConfigurator
          ? 'ppcUser'
          : '';
      const missingPPC =
        Object.keys(this.props.newProgramDetails.progCreator).length === 0
        || !IsValid(this.props.newProgramDetails.progCreator.label)

      return (
        <div className="BT-Lead-Landing-Page">
          <ProgramProjectHeader type="Program" />
          <Stepper type="Program" />
          {this.props.PnPComponents.program.acctLeadApprv ? (
            <Row className="access_denied_container">
              <Col md={12} xs={12}>
                <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                <div className="access_denied_content">
                  <h1>Access Denied</h1>
                  <p>
                    You don't have the necessary user privileges to view the page that you are
                    attempting to access.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="BT-container">
              <ALATable
                type={'program'}
                arsDetails={this.props.arsDetails}
                isReadOnly={isReadOnly}
                editIconDisable={true}
              />
              {this.props.showNotifMessage ? (< div className="disclaimer-message">
                <GrooveIconSvg
                  customClassName="accordion-button"
                  size="small"
                  name="exclamation-triangle"
                  iconStyle="solid"
                  iconColor="stat-warning"
                />
                BOIs with $ UOM selected under Financial Dimension will be Considered as client P&L Sign-Off. $ value under all other Dimensions except Financial Dimension, will not be considered as Client P&L Sign-Off.
              </div>) : ''}
              <ProgramDimAccordionRejected
                type={'Program'}
                mode={'viewWithComment'}
                customBOI={this.props.customBOI}
                ProgramConfigurationData={this.props.ProgramConfigurationData}
                dimensionList={this.props.dimensionList}
                dimensionListToDisplay={this.props.dimensionListToDisplay}
                onEditBOI={this.props.onEditBOI}
                callbackFunction={this.props.dimAccCFunc}
                formCallback={this.props.dimFormCallback}
                handleCheckAllOfferings={this.props.handleCheckAllOfferings}
                userType={userType}
                topLeftContainer={
                  <React.Fragment>
                    {missingPPC && (
                      <div className="missing prog-creator">No Program Creator is mapped for the program. Please navigate to <span className="missing-ppc-ptvs">Program Target Value Settings</span> page to update Program Creator for the program.</div>
                    )}
                    <div class="header18-semibold-midnight">Program Target Value Settings</div>
                    <BOIHistoryModal historyType="Program" />
                  </React.Fragment>
                }
                topRightContainer={
                  <AddBOIPopup
                    type={'Edit'}
                    onSaveAddBOI={this.props.formatBOIs}
                    selectedBOIs={this.props.dimForBOICRUD}
                    disableAddBOI={this.props.duplicateBOI || this.props.inComplete}
                  />
                }
                duplicateBOI={this.props.duplicateBOI}
                inComplete={this.props.inComplete}
                listenToScroll={this.props.listenToScroll}
              />
              <div
                id="track-scroll"
              >
                <div className="edit-BOIS-bottom-btn">
                  {this.props.duplicateBOI ? (
                    <p className="missing">BOI already exists</p>
                  ) : this.props.inComplete ? (
                    <p className="missing">Missing Required Field(s)</p>
                  ) : (
                    ''
                  )}
                  {this.props.invalidEmailsList.length > 0 && (
                    <div className="missing">
                      {`Please update the given invalid EIDs in order to proceed: ${this.props.invalidEmailsList.join(', ')}`}
                    </div>
                  )}
                  <span className="applyFlex-space-between">
                    <GrooveButton
                      id="primry-btn-2"
                      type="outline"
                      colorClass="stat-alternate"
                      text="Save as Draft"
                      customClassName="add-project-button"
                      callbackFunction={this.props.onClickSAD}
                      isDisabled={
                        this.props.disableSADSFA ||
                        this.props.duplicateBOI ||
                        this.props.inComplete ||
                        this.props.disableSADBtn ||
                        missingPPC
                      }
                    />
                  </span>
                  <span className="applyFlex-space-between">
                    <SendForApproval
                      size="md"
                      approverList={sfaForm.approverList}
                      defaultData={sfaForm.selectedApprovers}
                      show={sfaForm.showSFAReject}
                      deletedBOI={sfaForm.deletedBOI}
                      modifiedBOI={sfaForm.modifiedBOI}
                      shouldDisable={
                        this.props.disableSADSFA || this.props.duplicateBOI || this.props.inComplete || missingPPC
                      }
                      shouldShowDeletedReason={this.props.shouldShowDeletedReason}
                      onClickSFA={this.props.onClickSFA}
                      onClickSend={this.props.onClickSendSFA}
                      onCloseModal={this.props.onCloseSFAReject}
                      onChangeDeleteComment={this.props.onChangeDeleteComment}
                      onChangeModifiedComment={this.props.onChangeModifiedComment}
                      onChangeApprover={this.props.onChangeApprover}
                      type={'program'}
                      disableSendBtn={this.props.disableSendBtn}
                    />
                  </span>
                </div>
              </div>
              <div className={this.props.positionFixed ? !(this.props.disableSADSFA ||
                this.props.duplicateBOI ||
                this.props.inComplete ||
                this.props.disableSADBtn ||
                missingPPC) ? 'sticky-button-edit' : 'sticky-button-edit hide-sticky' : 'sticky-button-edit hide-sticky'}>
                <div className="edit-BOIS-bottom-btn">
                  {this.props.duplicateBOI ? (
                    <p className="missing">BOI already exists</p>
                  ) : this.props.inComplete ? (
                    <p className="missing">Missing Required Field(s)</p>
                  ) : (
                    ''
                  )}
                  <span className="applyFlex-space-between">
                    <GrooveButton
                      id="primry-btn-2"
                      type="outline"
                      colorClass="stat-alternate"
                      text="Save as Draft"
                      customClassName="add-project-button"
                      callbackFunction={this.props.onClickSAD}
                      isDisabled={
                        this.props.disableSADSFA ||
                        this.props.duplicateBOI ||
                        this.props.inComplete ||
                        this.props.disableSADBtn ||
                        missingPPC
                      }
                    />
                  </span>
                  <span className="applyFlex-space-between">
                    <SendForApproval
                      size="md"
                      approverList={sfaForm.approverList}
                      defaultData={sfaForm.selectedApprovers}
                      show={sfaForm.showSFAReject}
                      deletedBOI={sfaForm.deletedBOI}
                      modifiedBOI={sfaForm.modifiedBOI}
                      shouldDisable={
                        this.props.disableSADSFA || this.props.duplicateBOI || this.props.inComplete || missingPPC
                      }
                      shouldShowDeletedReason={this.props.shouldShowDeletedReason}
                      onClickSFA={this.props.onClickSFA}
                      onClickSend={this.props.onClickSendSFA}
                      onCloseModal={this.props.onCloseSFAReject}
                      onChangeDeleteComment={this.props.onChangeDeleteComment}
                      onChangeModifiedComment={this.props.onChangeModifiedComment}
                      onChangeApprover={this.props.onChangeApprover}
                      type={'program'}
                      disableSendBtn={this.props.disableSendBtn}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  class ProgramALAppRejectedEditBOIsContainer extends Component {
    constructor() {
      super();
      this.state = {
        origDimensionList: [],
        dimensionList: [],
        disableSADSFA: false,
        sfaForm: {
          approver: { text: 'OAL', value: 'OAL' },
          showSFAReject: false,
          deletedBOI: [],
          modifiedBOI: [],
          selectedApprovers: [],
          approverList: []
        },
        shouldShowDeletedReason: false,
        dimForBOICRUD: [],
        list: {
          boi: [],
          uom: [],
          dimension: [],
          subdimension: [],
        },
        dimensionListToDisplay: [],
        isClick: false,
        duplicateBOI: false,
        inComplete: false,
        showNotifMessage: false,
        disableSADBtn: false,
        disableSendBtn: false,
        positionFixed: false,
        invalidEmailsList: [],
      };
    }

    checkForInvalidEmailFirst = async () => {
      let invalidEmailsList = [];
      let CXLeadList = this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.split(',') : [];
      let OALeadList = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.split(',') : [];
      let progCreator = this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator !== null ? [this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator] : [];

      const data = await IsValidAccentureEmail([...CXLeadList, ...OALeadList, ...progCreator]);
      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          invalidEmailsList.push(data[i].UserName.split('@')[0]);
        }
      }

      this.setState({
        invalidEmailsList: invalidEmailsList,
        disableSADSFA: invalidEmailsList.length > 0,
        disableSADBtn: invalidEmailsList.length > 0,
      })
    }


    listenToScroll = () => {
      let trackScroll = document.getElementById('track-scroll');
      let var1 = trackScroll
        ? document.getElementById('root').clientHeight - trackScroll.offsetTop - 80
        : 0;
      let var2 =
        document.getElementById('root').clientHeight -
        document.documentElement.scrollTop -
        window.innerHeight;

      if (var2 > var1) {
        this.setState({
          positionFixed: true,
        });
      } else {
        this.setState({
          positionFixed: false,
        });
      }
    };

    componentDidMount = async () => {
      window.scrollTo(0, 0)
      this.listenToScroll();
      this.checkForInvalidEmailFirst();
      try {
        this.props.setEmailError({
          show: false,
          message: ""
        })
        await this.props.getProgramDeletedBOISAD();
        const progConfigData = this.props.ProgramConfigurationData;
        const dimList = progConfigData.dimensionList;
        let shouldShowDeletedReason =
          this.props.ProgramConfigurationData.selectedProgramDetails.StatusId === 9 ? false : true;

        this.setState({
          dimensionList: JSON.parse(JSON.stringify(dimList)),
          origDimensionList: JSON.parse(JSON.stringify(dimList)),
          dimForBOICRUD: JSON.parse(JSON.stringify(dimList)),
          shouldShowDeletedReason: shouldShowDeletedReason,
        });
        this.setNewProgDetails();
        this.formatBOIs(dimList);
        this.initApproverDropdowns();

        /* let oal = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead;
        this.setState({
          sfaForm: {
            ...this.state.sfaForm,
            approver: { text: oal, value: oal, label: oal },
          },
        }); */

        this.prepInitialBOIList(this.props.ProgramConfigurationData.isCheckedAllOfferings);
        if (
          this.props.ProgramConfigurationData.selectedProgramDetails.length <= 0 &&
          !this.state.isClick
        ) {
          this.handleCheckAllOfferings(
            this.props.ProgramConfigurationData.selectedProgramDetails.allOfferings
          );
        } else if (
          this.props.ProgramConfigurationData.newProgramDetails.offering.label === 'Overall-'
        ) {
          this.handleCheckAllOfferings(true);
        }
      } catch (e) {
        console.log(e);
      }
    };

    componentDidUpdate(prevProps) {
      window.addEventListener('scroll', this.listenToScroll)
      const progConfigData = this.props.ProgramConfigurationData;
      const dimList = progConfigData.dimensionList;

      if (prevProps.ProgramConfigurationData.dimensionList !== dimList) {
        this.setState({
          dimensionList: JSON.parse(JSON.stringify(dimList)),
          origDimensionList: JSON.parse(JSON.stringify(dimList)),
          dimForBOICRUD: JSON.parse(JSON.stringify(dimList)),
        });
        this.setNewProgDetails();
        this.initApproverDropdowns();
      }

      // Prepare initial list of BOIs
      if (
        prevProps.ProgramConfigurationData.boiMappingListAllOfferings !==
        this.props.ProgramConfigurationData.boiMappingListAllOfferings &&
        prevProps.ProgramConfigurationData.boiMappingListAllOfferings.length !== 0
      ) {
        this.prepInitialBOIList(this.props.ProgramConfigurationData.isCheckedAllOfferings);
      }
      if (!this.state.isClick) {
        this.handleCheckAllOfferings(this.props.ProgramConfigurationData.isCheckedAllOfferings);
        this.showDisclaimerMessage();
      }
    }

    componentWillUnmount() {
      this.props.setEditApproved(false);
    }

    formatBOIs = (bois) => {
      let tempBois = bois;
      //sort boi
      tempBois.map((dim) => {
        dim.subDimensionList.map((subdim, index) => {
          let tempFilterList = [];
          let tempCustomList = [];
          let sortedBoiList = [];
          let customBoiList = [];
          let filteredBoiList = [];

          //stores custom boi list
          customBoiList = subdim.boiList.filter((e) => e.boiParentID === 2);
          customBoiList.forEach((e) => {
            tempCustomList.push(e);
          });

          //removes custom from boilist and sorts boi parent id
          filteredBoiList = subdim.boiList.filter((e) => e.boiParentID !== 2);
          filteredBoiList.sort((a, b) => a.boiParentID - b.boiParentID);
          filteredBoiList.forEach((e) => {
            tempFilterList.push(e);
          });

          sortedBoiList.push(...tempFilterList, ...tempCustomList);

          subdim.boiList = sortedBoiList;
        });
      });

      let temp = [];
      tempBois.map((dim) => {
        dim.subDimensionList.map((subdim) => {
          subdim.boiList.map((boi) => {
            //  this is for setting BOI ID based on the boi dropdown
            let boiList = this.state.list.boi;
            let toAdd = {
              boi: {
                label: boi.boiName,
                text: boi.boiName,
                value: boi.boiID,
              },
              uom: { label: boi.uom, text: boi.uom, value: boi.uom },
              dimension: {
                label: dim.dimensionName,
                text: dim.dimensionName,
                value: dim.dimensionID,
              },
              subdimension: {
                label: subdim.subDimensionName,
                text: subdim.subDimensionName,
                value: subdim.subDimensionID,
              },
              boidefinition: {
                label: boi.IsCustom ? boi.boiDefinition : '',
                text: boi.IsCustom ? boi.boiDefinition : '',
                value: boi.IsCustom ? boi.boiDefinitionMasterID : 0,
              },
              aggregationlogic: {
                label: boi.IsCustom ? boi.aggregationLogic : '',
                text: boi.IsCustom ? boi.aggregationLogic : '',
                value: boi.IsCustom ? boi.aggregationLogicMasterID : 0,
              },
              suboffering: {
                label: boi.suboffering,
                text: boi.suboffering,
                value: boi.subofferingID,
              },
            };
            temp.push({
              ...toAdd,
              boiList: boiList,
              uomList: this.prepDropdownLists(boi.IsCustom ? 'uomCustom' : 'uom', toAdd),
              dimensionList: this.prepDropdownLists('dimension', toAdd),
              subdimensionList: this.prepDropdownLists(
                boi.IsCustom ? 'subdimensionCustom' : 'subdimension',
                toAdd
              ),
              boiParentID: boi.boiParentID,
              boiStatus: boi.boiStatus,
              comment: boi.comment,
              boiDescription: boi.boiDescription,
              //added description since some of the files are using description instead of boiDescription
              description: boi.boiDescription,
              paramValue: boi.paramValue,
              oldParamValue: TryGetObjValueAlt(boi, '', 'oldParamValue'),
              boiClientProgramTargetID: boi.boiClientProgramTargetID,
              boiCode: boi.boiCode,
              boiRealizedValue: boi.boiRealizedValue,
              boiRejectionRecipient: boi.boiRejectionRecipient,
              IsCustom: boi.IsCustom,
              isBOIUsedInProject: boi.isBOIUsedInProject,
              isExisting: boi.hasOwnProperty('isExisting') ? boi.isExisting : false,
              isDisableUpdatingDimension: boi.hasOwnProperty('isDisableUpdatingDimension') ? boi.isDisableUpdatingDimension : false,
              missingDetails: boi.hasOwnProperty('missingDetails') ? boi.missingDetails : false,
              oldUOM: boi.uom,
              boiWheelVisibility: boi.boiWheelVisibility !== undefined ? boi.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1

              leadingIndicator: boi.leadingIndicator,
              reasonForNoBOIDefinition: boi.IsCustom ? boi.reasonForNoBOIDefinition : '',
              reasonForNoAggregationLogic: boi.IsCustom ? boi.reasonForNoAggregationLogic : '',
              customParameterList: boi.IsCustom ? boi.customParameterList : [],
              newSubDimensionName: boi.newSubDimensionName ? boi.newSubDimensionName : '',
              noOfParameter: boi.IsCustom ? boi.noOfParameter : 0,
              boiAdditionComment: boi.boiAdditionComment,
              boiOldComment: boi.boiOldComment,
              metricDefinitionID: boi.metricDefinitionID,
              offeringId: boi.offeringID,
              offeringName: boi.offeringName,
              parameterList: boi.parameterList ? boi.parameterList : [],
              boiOutsideOffering: false,

              isActive: boi.isActive,
            });
          });
        });
      });
      this.setState(
        {
          dimensionListToDisplay: JSON.parse(JSON.stringify(temp)),
          dimensionList: tempBois,
          dimForBOICRUD: tempBois,
          disableSADSFA: this.state.invalidEmailsList.length > 0 ? true : tempBois.length === 0,
        },
        () => {
          // if onload and there is a boi selected that was outside the selected offering then check boc should default to checked
          if (this.checkForBOIOutsideOffering()) {
            this.handleCheckAllOfferings(true);
          }
        }
      );

      setTimeout(() => {
        if (document.getElementById('expandAll')) {
          document.getElementById('expandAll').click();
        }
      }, 300);
    };

    prepInitialBOIList = (status) => {
      let { dropdownListByOfferings, dropdownListAllOfferings } =
        this.props.ProgramConfigurationData;
      this.setState({
        list: {
          boi: status ? dropdownListAllOfferings : dropdownListByOfferings,
          uom: [],
          dimension: [],
          subdimension: [],
        },
      });
    };

    prepDropdownLists = (field, values) => {
      //check if exist on the mappinglist
      let boiMapping = this.props.ProgramConfigurationData.boiMappingListAllOfferings;
      let boiMappingCustom = this.props.ProgramConfigurationData.customBOIDropdownList;
      let list = [];
      let listRef = [];

      switch (field) {
        case 'uom':
          let map1= boiMapping.BOIUnitOfMeasureList? boiMapping.BOIUnitOfMeasureList.map((item) => {
            if (item.BOIName === values.boi.label && listRef.indexOf(item.UOM) === -1) {
              list.push({ label: item.UOM, text: item.UOM, value: item.UOM });
              listRef.push(item.UOM);
            }
          }):null;
          break;
        case 'uomCustom':
          let map2=boiMappingCustom.UnitOfMeasureList? boiMappingCustom.UnitOfMeasureList.map((item) => {
            if (listRef.indexOf(item.UOMName) === -1) {
              list.push({ label: item.UOMName, text: item.UOMName, value: item.UOMName });
              listRef.push(item.UOMName);
            }
          }):null;
          break;
        case 'dimension':
          let map3=boiMapping.DimensionList? boiMapping.DimensionList.map((item) => {
            if (listRef.indexOf(item.DimensionName) === -1) {
              list.push({
                label: item.DimensionName,
                text: item.DimensionName,
                value: item.DimensionID,
              });
              listRef.push(item.DimensionName);
            }
          }):null;
          break;
        case 'subdimension':
          let map4=boiMapping.BOIMappedSubDimensionList?boiMapping.BOIMappedSubDimensionList.map((item) => {
            if (
              item.DimensionID === values.dimension.value &&
              listRef.indexOf(item.SubDimensionName) === -1
            ) {
              list.push({
                label: item.SubDimensionName,
                text: item.SubDimensionName,
                value: item.SubDimensionID,
              });
              listRef.push(item.SubDimensionName);
            }
          }):null;
          break;
        case 'subdimensionCustom':
          let newListSubdimension = [];

          let map5=boiMappingCustom.BOIMappedSubDimensionList?boiMappingCustom.BOIMappedSubDimensionList.map((item) => {
            if (
              item.DimensionID === values.dimension.value &&
              listRef.indexOf(item.SubDimensionName) === -1
            ) {
              newListSubdimension.push({
                label: item.SubDimensionName,
                text: item.SubDimensionName,
                value: item.SubDimensionID,
              });
              listRef.push(item.SubDimensionName);
            }
          }):null;
          list = newListSubdimension;
          break;
        default:
          break;
      }
      return list;
    };

    checkForBOIOutsideOffering = () => {
      const selectedBOI = this.state.dimensionListToDisplay;
      const boiUnderOffering = this.props.ProgramConfigurationData.boiMappingList.BIOMappingList;
      let hasBOIOutside = false;
      if (selectedBOI.length > 0) {
        selectedBOI.map((b) => {
          if (!b.IsCustom) {
            let boi = b.boi.label;
            let uom = b.uom.label;
            let doesMatch = false;
            // if(!hasBOIOutside){
            boiUnderOffering.map((a) => {
              if (a.BOIName === boi && a.UOM === uom) {
                doesMatch = true;
              }
            });
            // }
            if (!doesMatch) {
              hasBOIOutside = true;
              b.boiOutsideOffering = true;
            }
          }
        });
      }
      return hasBOIOutside;
    };

    handleCheckAllOfferings = (checkboxState) => {
      let shouldContinue = false;
      if (!checkboxState) {
        shouldContinue = !this.checkForBOIOutsideOffering(); //if user uncheck the box but ther is boi outside the selected offering process should not continue.
      } else {
        shouldContinue = true;
      }
      if (shouldContinue) {
        const { isCheckedAllOfferings, newProgramDetails, selectedProgramDetails } =
          this.props.ProgramConfigurationData;
        const offering = newProgramDetails.offering.label;

        if (offering !== 'Overall') {
          let subofferingsID = '';

          if (this.props.ProgramConfigurationData.selectedProgramDetails.length <= 0) {
            subofferingsID = this.prepNewSubofferingsID(
              this.props.ProgramConfigurationData.newProgramDetails.subOffering
            );
          }

          this.props.handleCheckAllOfferings(checkboxState).then((result) => {
            this.prepInitialBOIList(result);
          });
        }
        this.setState({
          isClick: true,
        });
      }
    };

    prepNewSubofferingsID = (subOfferings) => {
      let arrId = [];
      if (subOfferings.length > 0) {
        subOfferings.map((item) => {
          arrId.push(item.value);
        });
      }
      return arrId.join(',');
    };

    filterEditUOM = (toAdd) => {
      let selected = toAdd;
      let uom = this.prepDropdownLists('uom', selected);
      // if options is only 1 then select it by default
      if (uom.length === 1) {
        selected.uom = uom[0];
      } else {
        selected.uom = {};
      }
      return { sel: selected, list: uom };
    };

    filterEditDimension = (toAdd) => {
      const boiMappingList =
        this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList;
      let selected = toAdd;
      let dimension = this.prepDropdownLists('dimension', selected);
      let listDimension = [];
      // if options is only 1 then select it by default
      boiMappingList.map((item) => {
        if (
          item.BOIName === toAdd.boi.label &&
          item.UOM === toAdd.uom.label &&
          listDimension.indexOf(item.CoreDimensionName) === -1
        ) {
          listDimension.push({
            label: item.CoreDimensionName,
            text: item.CoreDimensionName,
            value: item.CoreDimensionID,
          });
        }
      });

      if (listDimension.length === 1) {
        selected.dimension = listDimension[0];
      } else {
        selected.dimension = {};
      }
      return { sel: selected, list: dimension };
    };

    filterEditSubDimension = (toAdd) => {
      const boiMappingList =
        this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList;
      let selected = toAdd;
      let subdimension = this.prepDropdownLists('subdimension', selected);
      let listSubDimension = [];
      // if options is only 1 then select it by default
      if (!selected.IsCustom) {
        boiMappingList.map((item) => {
          if (
            item.BOIName === toAdd.boi.label &&
            item.UOM === toAdd.uom.label &&
            item.CoreDimensionName === toAdd.dimension.label &&
            listSubDimension.indexOf(item.SubDimensionName) === -1
          ) {
            listSubDimension.push({
              label: item.SubDimensionName,
              text: item.SubDimensionName,
              value: item.SubDimensionID,
            });
          }
        });
        if (listSubDimension.length === 1) {
          selected.subdimension = listSubDimension[0];
        } else {
          selected.subdimension = {};
        }
      } else {
        if (subdimension.length === 1) {
          selected.subdimension = subdimension[0];
        } else {
          selected.subdimension = {};
        }
      }
      return { sel: selected, list: subdimension };
    };

    onEditBOI = (e, field, prev, index) => {
      let bois = this.state.dimensionListToDisplay;

      switch (field) {
        case 'uom':
          bois[index].uom = e;
          if (!bois[index].IsCustom) {
            let dataUom = this.filterEditDimension({
              boi: bois[index].boi,
              uom: bois[index].uom,
              dimension: bois[index].dimension,
              subdimension: bois[index].subdimension,
            });
            bois[index].dimensionList = dataUom.list;
            bois[index].dimension = dataUom.sel.dimension;
            let dataUom2 = this.filterEditSubDimension(dataUom.sel);
            bois[index].subdimensionList = dataUom2.list;
            bois[index].subdimension = dataUom2.sel.subdimension;
          } else {
            if (bois[index].customParameterList.length === 1)
              bois[index].customParameterList[0].ParameterUOM = e.label;
          }
          break;
        case 'dimension':
          bois[index].dimension = e;
          let dataDim = this.filterEditSubDimension({
            boi: bois[index].boi,
            uom: bois[index].uom,
            dimension: bois[index].dimension,
            subdimension: bois[index].subdimension,
          });
          bois[index].subdimensionList = dataDim.list;
          bois[index].subdimension = dataDim.sel.subdimension;
          if (bois[index].IsCustom) bois[index].newSubDimensionName = '';
          break;
        case 'subdimension':
          bois[index].subdimension = e;
          if (bois[index].IsCustom) bois[index].newSubDimensionName = '';
          break;
        default:
          break;
      }

      // check duplicate
      let toAdd = {
        boi: bois[index].boi,
        dimension: bois[index].dimension,
        subdimension: bois[index].subdimension,
        uom: bois[index].uom,
      };
      let boiListToCheck = bois.filter((item, i) => i !== index);

      bois[index].isExisting = this.checkForDuplicates(toAdd, boiListToCheck);
      if (
        Object.keys(bois[index].uom).length === 0 ||
        Object.keys(bois[index].dimension).length === 0 ||
        Object.keys(bois[index].subdimension).length === 0
      ) {
        bois[index].missingDetails = true;
      } else {
        bois[index].missingDetails = false;
      }

      let duplicateBOI = false;
      let inComplete = false;
      bois.map((item) => {
        if (item.isExisting) {
          duplicateBOI = true;
        }
        if (
          Object.keys(item.uom).length === 0 ||
          Object.keys(item.dimension).length === 0 ||
          Object.keys(item.subdimension).length === 0
        ) {
          inComplete = true;
        }
      });
      // end check duplicate

      let list = this.prepForSaving(bois);
      this.formatBOIs(list, true);
      this.setState(
        {
          duplicateBOI: duplicateBOI,
          inComplete: inComplete,
        },
      );
    };

    checkForDuplicates = (toAdd, selectedBOIList) => {
      let doesExist = false;
      selectedBOIList.map((item) => {
        if (
          item.boi.label === toAdd.boi.label &&
          item.uom.label === toAdd.uom.label &&
          item.dimension.label === toAdd.dimension.label &&
          item.subdimension.label === toAdd.subdimension.label
        ) {
          doesExist = true;
        }
      });
      return doesExist;
    };

    prepForSaving = (selectedBOI) => {
      let list = [];
      selectedBOI.forEach((item) => {
        //format paramValue if uom has change from usd
        if (
          TryGetObjValueAlt(item, '', 'oldUOM').toLowerCase() === 'usd' &&
          item.uom.text.toLowerCase() !== 'usd' &&
          item.paramValue !== null
        ) {
          const pValue = FormatCVMParamValue('', item.paramValue.toString(), item.uom.text);
          item['paramValue'] = pValue.value;
          item['inValidMsg'] = pValue.inValidMsg;
        }
        // Check if dimension already added
        let dimNotExist = true;
        list.forEach((dim) => {
          if (String(dim.dimensionID) === String(item.dimension.value)) {
            dimNotExist = false;
            // Check if subdimension already added
            let subdimNotExist = true;
            dim.subDimensionList.forEach((subdim) => {
              if (
                subdim.subDimensionID === item.subdimension.value &&
                item.subdimension.value !== 0
              ) {
                subdimNotExist = false;
                subdim.boiList.push({
                  boiParentID: item.boiParentID,
                  boiID: item.boi.value,
                  boiName: item.boi.label,
                  paramValue: item.paramValue,
                  oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                  uom: item.uom.label,
                  comment: item.comment,
                  boiDescription: item.boiDescription,
                  //added description since some of the files are using description instead of boiDescription
                  description: item.boiDescription,
                  boiRealizedValue: item.boiRealizedValue,
                  boiStatus: item.boiStatus,
                  boiClientProgramTargetID: item.boiClientProgramTargetID,
                  boiRejectionRecipient: item.boiRejectionRecipient,
                  boiCode: item.boiCode,
                  IsCustom: item.IsCustom,
                  isActive: 1,
                  //additional details
                  reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                  reasonForNoAggregationLogic: item.IsCustom
                    ? item.reasonForNoAggregationLogic
                    : '',
                  aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                  aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                  boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                  boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                  leadingIndicator: item.leadingIndicator,
                  customParameterList: item.IsCustom ? item.customParameterList : [],
                  newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                  noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                  offeringID: item.offeringNameID,
                  offeringName: item.offeringName,
                  suboffering: item.suboffering.label,
                  subofferingID: item.suboffering.value,
                  boiAdditionComment: item.boiAdditionComment,
                  boiOldComment: item.boiOldComment,
                  metricDefinitionID: item.metricDefinitionID,
                  isBOIUsedInProject: item.isBOIUsedInProject,
                  boiWheelVisibility:
                    item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                  parameterList: item.parameterList !== undefined ? item.parameterList : [],
                  isExisting: item.isExisting,
                  missingDetails: item.missingDetails,
                  isDisableUpdatingDimension: item.isDisableUpdatingDimension
                });
              }
            });

            //IF SUBDIM IS NOT YET CREATED
            if (subdimNotExist) {
              dim.subDimensionList.push({
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension.label,
                subDimensionID: item.subdimension.value,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiParentID: item.boiParentID,
                    boiID: item.boi.value,
                    boiName: item.boi.label,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom.label,
                    comment: item.comment,
                    boiDescription: item.boiDescription,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.boiDescription,
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringNameID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID: item.metricDefinitionID,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility: item.boiWheelVisibility ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    isExisting: item.isExisting,
                    missingDetails: item.missingDetails,
                    isDisableUpdatingDimension: item.isDisableUpdatingDimension
                  },
                ],
              });
            }
          }
        });
        //IF DIM IS NOT YET CREATED
        if (dimNotExist) {
          list.push({
            dimensionName: item.dimension.label,
            dimensionID: item.dimension.value,
            dimensionReason: '',
            dimensionStatus: '',
            dimensionTarget: '',
            subDimensionList: [
              {
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension.label,
                subDimensionID: item.subdimension.value,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiParentID: item.boiParentID,
                    boiID: item.boi.value,
                    boiName: item.boi.label,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom.label,
                    comment: item.comment,
                    leadingIndicator: item.leadingIndicator,
                    boiDescription: item.boiDescription,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.boiDescription,
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringNameID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID: item.metricDefinitionID,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility:
                      item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    isExisting: item.isExisting,
                    missingDetails: item.missingDetails,
                    isDisableUpdatingDimension: item.isDisableUpdatingDimension
                  },
                ],
              },
            ],
          });
        }
      });
      return list;
    };

    setNewProgDetails = () => {
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      if (programDetails.ClientProgramID) {
        let oal = [];
        let cxl = [];
        let subOff = programDetails.SubOfferingList.map((item) => {
          return {
            label: item.SubOfferingName,
            value: item.SubOfferingID,
          };
        });

        programDetails.OperationsAccountLead.split(',').map(x => {
          oal.push({
            label: x,
            text: x,
            value: x
          });
        });

        programDetails.CXLLead.split(',').map(x => {
          cxl.push({
            label: x,
            text: x,
            value: x
          });
        });

        newProgramDetails.offering = {
          label: programDetails.Offering,
          text: programDetails.Offering,
          value: programDetails.OfferingID,
        };
        newProgramDetails.subOffering = subOff;
        newProgramDetails.programName = programDetails.ClientProgramName;
        newProgramDetails.cxLead = cxl;
        newProgramDetails.ioApprover = {
          label: programDetails.IOApprover,
          text: programDetails.IOApprover,
          value: programDetails.IOApprover,
        };
        newProgramDetails.oaLead = oal;
        newProgramDetails.progCreator = {
          label: programDetails.ProgramCreator,
          text: programDetails.ProgramCreator,
          value: programDetails.ProgramCreator,
        };

        this.props.setNewProgramDetails(newProgramDetails);
      }
    };

    dimFormCallback = (dimList, action) => {
      let disBtn = true;
      const dimForBOICRUD = dimList.map((sd) => {
        const subd = sd.subDimensionList.map((boi) => {
          const boiList = boi.boiList.filter((i) => i.isActive !== 0);
          if (disBtn && boiList.length > 0) disBtn = false;
          return { ...boi, boiList: boiList };
        });
        return { ...sd, subDimensionList: subd };
      });

      this.setState(
        {
          dimensionList: dimList,
          dimForBOICRUD: disBtn ? [] : dimForBOICRUD,
          disableSADSFA: this.state.invalidEmailsList.length > 0 ? true : disBtn,
        },
        () => {
          this.formatBOIs(dimList);
        }
      );

      if (action === 'removeDeleted') {
        this.setState({
          duplicateBOI: false,
          inComplete: false,
        });
      }
    };

    //SAD & SFA SECTION
    validateParamValues = () => {
      let dim = this.state.dimensionList;
      let isValid = true;
      dim.forEach((e) => {
        e.subDimensionList.forEach((e2) => {
          e2.boiList.forEach((e3) => {
            if (!IsValidStr(e3.paramValue.trim())) e3['inValidParam'] = true;
          });
        });
      });
      if (!isValid) this.setState({ dimensionList: dim });

      return isValid;
    };
    getModifiedDeletedBOIs = async () => {
      let editedBoisID = [];
      let editedBois = [];
      let selectedProgramID = TryGetObjValueAlt(
        this.props,
        0,
        'ProgramConfigurationData.selectedProgramDetails.ClientProgramID'
      );

      //GET DB-SAVED BOIS THAT ARE STILL ACTIVE
      this.state.dimensionList.forEach((dim) => {
        dim.subDimensionList.forEach((sDim) => {
          sDim.boiList.forEach((boi) => {
            if (
              IsValid(boi.boiClientProgramTargetID) &&
              HasProperty(boi, 'isActive') &&
              boi.isActive
            ) {
              editedBoisID.push(boi.boiClientProgramTargetID);
              editedBois.push({
                ...boi,
                dimensionID: dim.dimensionID,
                subDimensionID: sDim.subDimensionID,
              });
            }
          });
        });
      });

      //GET ALL DB-SAVED BOIS THAT ARE NOT IN EDITBOILIST
      let deletedBOIList = [];
      let modifiedBOIList = [];
      this.state.origDimensionList.forEach((dim) => {
        dim.subDimensionList.forEach((sDim) => {
          sDim.boiList.forEach((boi) => {
            if (
              IsValid(boi.boiClientProgramTargetID) &&
              editedBoisID.indexOf(boi.boiClientProgramTargetID) == -1
            ) {
              deletedBOIList.push({
                ClientProgramTargetID: selectedProgramID,
                CoreDimensionID: dim.dimensionID,
                SubDimensionID: sDim.subDimensionID,
                BOIID: boi.boiID,
                BOITarget: boi.paramValue,
                BOIStatus: boi.boiStatus,
                BOIReason: '',
                IsActive: boi.isActive,
                BOIName: boi.boiName,
                Description: boi.description,
                BOIClientProgramTargetID: boi.boiClientProgramTargetID,
              });
            }
            if (
              IsValid(boi.boiClientProgramTargetID) &&
              !this.objectsAreSame(
                editedBois.filter(
                  (e) =>
                    e.boiID === boi.boiID &&
                    e.boiClientProgramTargetID === boi.boiClientProgramTargetID
                )[0],
                { dim, sDim, boi }
              ) &&
              editedBoisID.includes(boi.boiClientProgramTargetID)
            ) {
              modifiedBOIList.push({
                ClientProgramTargetID: selectedProgramID,
                CoreDimensionID: dim.dimensionID,
                SubDimensionID: sDim.subDimensionID,
                BOIID: boi.boiID,
                BOITarget: boi.paramValue,
                BOIStatus: boi.boiStatus,
                BOIReason: '',
                IsActive: boi.isActive,
                BOIName: boi.boiName,
                Description: boi.description,
                BOIClientProgramTargetID: boi.boiClientProgramTargetID,
              });
            }
          });
        });
      });

      this.props.ProgramConfigurationData.deletedDimensionList.map((dim) => {
        dim.subDimensionList.map((sDim) => {
          sDim.boiList.map((boi) => {
            deletedBOIList.push({
              ClientProgramTargetID: selectedProgramID,
              CoreDimensionID: dim.dimensionID,
              SubDimensionID: sDim.subDimensionID,
              BOIID: boi.boiID,
              BOITarget: boi.paramValue,
              BOIStatus: boi.boiStatus,
              BOIReason: '',
              IsActive: boi.isActive,
              BOIName: boi.boiName,
              Description: boi.description,
              BOIClientProgramTargetID: boi.boiClientProgramTargetID,
            });
          });
        });
      });

      await this.setState({
        sfaForm: {
          ...this.state.sfaForm,
          deletedBOI: deletedBOIList,
          modifiedBOI: modifiedBOIList,
        },
      });
    };

    objectsAreSame = (o1, o2) => {
      if (o1 === undefined) return;
      let o1l = {
        dimID: o1.dimensionID,
        subDimID: o1.subDimensionID,
        boiID: o1.boiID,
        boiName: o1.boiName,
        uom: o1.uom,
        boiRealizedValue: o1.boiRealizedValue ? o1.boiRealizedValue : '',
        boiRejectionRecipient: o1.boiRejectionRecipient ? o1.boiRejectionRecipient : '',
        boiCode: o1.boiCode ? o1.boiCode : 0,
        oldParamValue: o1.paramValue ? String(o1.paramValue) : '',
        boiWheelVisibility: [1, true].includes(o1.boiWheelVisibility),
        boiParam: o1.IsCustom
          ? o1.customParameterList.filter((boi3) => boi3.BOIID === o1.boiID)
          : [],
      };
      let o2l = {
        dimID: o2.dim.dimensionID,
        subDimID: o2.sDim.subDimensionID,
        boiID: o2.boi.boiID,
        boiName: o2.boi.boiName,
        uom: o2.boi.uom,
        boiRealizedValue: o2.boi.boiRealizedValue ? o2.boi.boiRealizedValue : '',
        boiRejectionRecipient: o2.boi.boiRejectionRecipient ? o2.boi.boiRejectionRecipient : '',
        boiCode: o2.boi.boiCode ? o2.boi.boiCode : 0,
        oldParamValue: o2.boi.oldParamValue ? String(o2.boi.oldParamValue) : '',
        boiWheelVisibility: [1, true].includes(o2.boi.boiWheelVisibility),
        boiParam: o2.boi.IsCustom
          ? o2.boi.customParameterList.filter((boi3) => boi3.BOIID === o2.boi.boiID)
          : [],
      };
      return JSON.stringify(o1l) === JSON.stringify(o2l);
    };

    formatBOIForSaving = () => {
      let param = [];
      const progData = this.props.ProgramConfigurationData;
      const selectedProgram = progData.selectedProgramDetails;
      const newProgramDetails = progData.newProgramDetails;

      this.state.dimensionList.forEach((dimension) => {
        dimension.subDimensionList.forEach((subDimension) => {
          subDimension.boiList
            .filter((f) => {
              return HasProperty(f, 'isActive') && f.isActive;
            })
            .forEach((boi) => {
              param.push({
                ClientProgramTargetID: boi.boiClientProgramTargetID,
                BOIName: boi.boiName,
                ClientProgramID: selectedProgram.ClientProgramID
                  ? selectedProgram.ClientProgramID
                  : 0,
                CoreDimensionID: dimension.dimensionID,
                SubDimensionID: subDimension.subDimensionID,
                subDimensionName:
                  subDimension.subDimensionName === 'Add new Subdimension'
                    ? boi.newSubDimensionName
                    : subDimension.subDimensionName,
                BOIID: boi.boiID,
                BOITarget: boi.paramValue,
                UOM: boi.uom,
                BOIStatus: boi.boiStatus,
                BOIReason: boi.boiReason,
                Approver: selectedProgram.OperationsAccountLead,
                RejectionRecipient: '',
                IsActive: boi.isActive,
                IsCustom: boi.IsCustom,
                isCustomSubDimension: subDimension.subDimensionID === 0 ? true : false,
                boiDescription: boi.boiDescription,
                offeringID:
                  newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
                boiDefinitionMasterID:
                  boi.boiDefinitionMasterID === '' ? 0 : boi.boiDefinitionMasterID,
                aggregationLogicMasterID:
                  boi.aggregationLogicMasterID === '' ? 0 : boi.aggregationLogicMasterID,
                leadingIndicator: boi.leadingIndicator,
                boiAdditionComment: boi.boiAdditionComment,
                boiOldComment: boi.boiOldComment,
                reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
                reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
                noOfParameter: boi.noOfParameter === '' ? 0 : boi.noOfParameter,
                loggedInUserName: progData.progSFAPayload.UserName,
                boiDeletionReason: '',
                metricSQL: '',
                projectAggregationSQL: '',
                programAggregationSQL: '',
                accountAggregationSQL: '',
                isDeletedCustomBOI: boi.isDeleted,
                customParameterList: boi.customParameterList,
                subOfferingID: boi.subofferingID,
                isBOIUsedInProject: boi.isBOIUsedInProject,
                metricDefinitionID: boi.metricDefinitionID,
                isDeletedOnSAD: false,
                wheelVisibility: boi.boiWheelVisibility ? 1 : 0,
              });
            });
        });
      });
      //DELETED BOI
      let deletedDimIDs = [];
      let deletedSubDim = [];
      let deletedBoi = [];
      const tDelBOI = this.state.sfaForm.deletedBOI;
      tDelBOI.forEach((e) => {
        deletedDimIDs.push(e.CoreDimensionID);
        deletedSubDim.push(e.SubDimensionID);
        deletedBoi.push(e.BOIClientProgramTargetID);
      });
      this.state.origDimensionList
        .filter((f) => {
          return deletedDimIDs.indexOf(f.dimensionID) > -1;
        })
        .forEach((dimension) => {
          dimension.subDimensionList
            .filter((f2) => {
              return deletedSubDim.indexOf(f2.subDimensionID) > -1;
            })
            .forEach((subDimension) => {
              subDimension.boiList
                .filter((f3) => {
                  return deletedBoi.indexOf(f3.boiClientProgramTargetID) > -1;
                })
                .forEach((boi) => {
                  const reason = this.state.sfaForm.deletedBOI.filter((r) => {
                    return (
                      r.CoreDimensionID === dimension.dimensionID &&
                      r.SubDimensionID === subDimension.subDimensionID &&
                      r.BOIID === boi.boiID
                    );
                  })[0].BOIReason;
                  param.push({
                    aggregationLogicMasterID:
                      boi.aggregationLogicMasterID === '' ? 0 : boi.aggregationLogicMasterID,
                    approver: '',
                    boiAdditionComment: boi.boiAdditionComment,
                    boiOldComment: boi.boiOldComment,
                    boiDefinitionMasterID:
                      boi.boiDefinitionMasterID === '' ? 0 : boi.boiDefinitionMasterID,
                    boiDescription: boi.boiDescription,
                    BOIID: boi.boiID,
                    BOIName: boi.boiName,
                    BOIReason: reason,
                    BOIDeletionReason: reason,
                    BOIStatus: boi.boiStatus,
                    BOITarget: boi.paramValue,
                    ClientProgramID: selectedProgram.ClientProgramID
                      ? selectedProgram.ClientProgramID
                      : 0,
                    clientProgramTargetID: boi.boiClientProgramTargetID,
                    CoreDimensionID: dimension.dimensionID,
                    customParameterList: boi.customParameterList,
                    Description: boi.boiDescription,
                    IsActive: 0,
                    isBOIUsedInProject: boi.isBOIUsedInProject,
                    IsCustom: boi.IsCustom,
                    isCustomSubDimension: subDimension.subDimensionID === 0 ? true : false,
                    leadingIndicator: boi.leadingIndicator,
                    loggedInUserName: '',
                    metricDefinitionID: boi.metricDefinitionID,
                    metricSQL: '',
                    noOfParameter: boi.noOfParameter,
                    offeringID:
                      newProgramDetails.offering.value === ''
                        ? 0
                        : newProgramDetails.offering.value,
                    programAggregationSQL: '',
                    projectAggregationSQL: '',
                    reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
                    reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
                    rejectionRecipient: '',
                    SubDimensionID: subDimension.subDimensionID,
                    subDimensionName:
                      subDimension.subDimensionName === 'Add�new�Subdimension'
                        ? boi.newSubDimensionName
                        : subDimension.subDimensionName,
                    subOfferingID: boi.subofferingID,
                    UOM: boi.uom,
                    isDeletedOnSAD: false,
                    wheelVisibility: boi.boiWheelVisibility ? 1 : 0,
                  });
                });
            });
        });

      this.props.ProgramConfigurationData.deletedDimensionList.forEach((dimension) => {
        dimension.subDimensionList.forEach((subDimension) => {
          subDimension.boiList.forEach((boi) => {
            const reason = this.state.sfaForm.deletedBOI.filter((r) => {
              return (
                r.CoreDimensionID === dimension.dimensionID &&
                r.SubDimensionID === subDimension.subDimensionID &&
                r.BOIID === boi.boiID
              );
            })[0].BOIReason;
            param.push({
              aggregationLogicMasterID:
                boi.aggregationLogicMasterID === '' ? 0 : boi.aggregationLogicMasterID,
              approver: '',
              boiAdditionComment: boi.boiAdditionComment,
              boiOldComment: boi.boiOldComment,
              boiDefinitionMasterID:
                boi.boiDefinitionMasterID === '' ? 0 : boi.boiDefinitionMasterID,
              boiDescription: boi.description,
              BOIID: boi.boiID,
              BOIName: boi.boiName,
              BOIReason: reason,
              BOIDeletionReason: reason,
              BOIStatus: boi.boiStatus,
              BOITarget: boi.paramValue,
              ClientProgramID: selectedProgram.ClientProgramID
                ? selectedProgram.ClientProgramID
                : 0,
              clientProgramTargetID: boi.boiClientProgramTargetID,
              CoreDimensionID: dimension.dimensionID,
              customParameterList: boi.customParameterList,
              Description: boi.boiDescription,
              IsActive: 0,
              isBOIUsedInProject: boi.isBOIUsedInProject,
              IsCustom: boi.IsCustom,
              isCustomSubDimension: subDimension.subDimensionID === 0 ? true : false,
              leadingIndicator: boi.leadingIndicator,
              loggedInUserName: '',
              metricDefinitionID: boi.metricDefinitionID,
              metricSQL: '',
              noOfParameter: boi.noOfParameter,
              offeringID:
                newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
              programAggregationSQL: '',
              projectAggregationSQL: '',
              reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
              reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
              rejectionRecipient: '',
              SubDimensionID: subDimension.subDimensionID,
              subDimensionName:
                subDimension.subDimensionName === 'Add�new�Subdimension'
                  ? boi.newSubDimensionName
                  : subDimension.subDimensionName,
              subOfferingID: boi.subofferingID,
              UOM: boi.uom,
              isDeletedOnSAD: true,
            });
          });
        });
      });

      return param;
    };

    validateDeleteReason = () => {
      let dim = this.state.sfaForm.deletedBOI;
      let isValid = true;
      const newDim = dim.map((e) => {
        if (!IsValidStr(e.BOIReason.trim()) && e.BOIStatus !== '') {
          e['inValidReason'] = true;
          isValid = false;
        }
        return { ...e };
      });
      this.setState({
        sfaForm: {
          ...this.state.sfaForm,
          deletedBOI: newDim,
        },
      });
      return isValid;
    };

    validateModifiedReason = () => {
      let dim = this.state.sfaForm.modifiedBOI;
      let isValid = true;
      const newDim = dim.map((e) => {
        if (!IsValidStr(e.BOIReason.trim()) && e.BOIStatus !== '') {
          e['inValidReason'] = true;
          isValid = false;
        }
        return { ...e };
      });
      this.setState({
        sfaForm: {
          ...this.state.sfaForm,
          modifiedBOI: newDim,
        },
      });
      return isValid;
    };

    onClickSFA = async () => {
      await this.getModifiedDeletedBOIs();
      this.props.setProgSFAPayload();
      this.setState({
        sfaForm: {
          ...this.state.sfaForm,
          showSFAReject: true,
        },
      });
    };

    onClickSendSFA = async (comment) => {
      try {
        this.setState({
          disableSendBtn: true
        }, async () => {
          if (this.state.shouldShowDeletedReason) {
            if (!this.validateDeleteReason() && !this.validateModifiedReason() ||
              !this.validateDeleteReason() || !this.validateModifiedReason()) {
              this.setState({
                disableSendBtn: false
              });
              return;
            }
          }
          const dimListToSave = await this.formatBOIForSaving();
          let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
          let selectedApprovers = this.state.sfaForm.selectedApprovers;
          const approverEmails = [
            ...new Set(
              selectedApprovers.map((item) => {
                return item.value;
              })
            ),
          ];

          await this.props.sendEditReject(comment, dimListToSave, approverEmails);

          LogPPCUserAction({
            clientID: programDetails.ClientMasterID,
            ClientProgramID: programDetails.ClientProgramID,
            progStatus: PPC_EX_PROG_UPD,
            module: PROG_ID_VAL_ALA
          });

          if (this.props.showError.show) {
            this.setState({
              sfaForm: {
                ...this.state.sfaForm,
                showSFAReject: true,
              },
              disableSendBtn: true
            });
          }
          else {
            this.setState({
              sfaForm: {
                ...this.state.sfaForm,
                showSFAReject: false,
              },
              disableSendBtn: false
            });
          }
        })
      }
      catch (e) {
        console.log(e)
      }
    };

    initApproverDropdowns = async () => {
      try {
        let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
        let cxlList = newProgramDetails.cxLead;
        let oalList = newProgramDetails.oaLead;

        let oal = [];
        let cxl = [];
        // process data if obj or array to get email
        if (!Array.isArray(oalList)) {
          if (IsValidStr(oalList.value)) {
            oal.push(oalList.value)
          }
        } else {
          oalList.forEach(x => {
            if (IsValidStr(x.value)) {
              oal.push(x.value);
            }
          });
        }
        if (!Array.isArray(cxlList)) {
          if (IsValidStr(cxlList.value)) {
            cxl.push(cxlList.value)
          }
        } else {
          cxlList.forEach(x => {
            if (IsValidStr(x.value)) {
              cxl.push(x.value);
            }
          });
        }
        const approverEmails = [
          ...new Set([...oal, ...cxl]),
        ];

        let approverList = [];
        approverEmails.forEach((approver) => {
          approverList.push({
            label: approver,
            text: approver,
            value: approver
          })
        })
        this.setState({
          sfaForm: {
            ...this.state.sfaForm,
            approverList: approverList
          },
        })
      } catch (e) {

      }
    }
    onChangeApprover = async (selectedApprovers) => {
      this.setState({
        sfaForm: {
          ...this.state.sfaForm,
          selectedApprovers: selectedApprovers,
        },
      })
    };

    onCloseSFAReject = () => {
      this.setState({
        sfaForm: {
          ...this.state.sfaForm,
          showSFAReject: false,
        },
      });

      this.props.setEmailError({
        show: false,
        message: ""
      })
    };

    onChangeDeleteComment = (text, id, boiClientProgramTargetID) => {
      let deletedBOI = this.state.sfaForm.deletedBOI;
      const index = deletedBOI.findIndex((e) => e.BOIID === id && e.BOIClientProgramTargetID === boiClientProgramTargetID);
      deletedBOI[index].BOIReason = text;
      delete deletedBOI[index].inValidReason;
      this.setState({ sfaForm: { ...this.state.sfaForm, deletedBOI } });
    };
    onChangeModifiedComment = (text, id, boiClientProgramTargetID) => {
      let modifiedBOI = this.state.sfaForm.modifiedBOI;
      const index = modifiedBOI.findIndex((e) => e.BOIID === id && e.BOIClientProgramTargetID === boiClientProgramTargetID);
      modifiedBOI[index].BOIReason = text;
      delete modifiedBOI[index].inValidReason;
      this.setState({ sfaForm: { ...this.state.sfaForm, modifiedBOI } });
    };

    onClickSAD = async () => {
      try {
        this.setState({
          disableSADBtn: true,
        }, async () => {
          await this.getModifiedDeletedBOIs();
          await this.props.setProgSFAPayload();
          const dimListToSave = await this.formatBOIForSaving();
          await this.props.sendSADEditReject(dimListToSave, this.props.history);
          this.setState({
            disableSADBtn: false,
          })
        })
      } catch (e) {
        console.log(e);
      }
    };

    showDisclaimerMessage = async () => {
      await this.setState({
        showNotifMessage: true
      })
      setTimeout(() =>
        this.setState({
          showNotifMessage: false
        }), 60000);

    }

    render() {
      const { arsDetails, selectedProgramDetails, programClientDetails, newProgramDetails } =
        this.props.ProgramConfigurationData;
      const { PnPComponents } = this.props.AccessValidationData;
      const { userInformation } = this.props;

      return (
        <ProgramALAppRejectedEditBOIsPresentational
          disableSADSFA={this.state.disableSADSFA}
          dimensionList={this.state.dimensionList}
          dimForBOICRUD={this.state.dimForBOICRUD}
          shouldShowDeletedReason={this.state.shouldShowDeletedReason}
          formatBOIs={this.formatBOIs}
          dimFormCallback={this.dimFormCallback}
          sfaForm={this.state.sfaForm}
          onClickSFA={this.onClickSFA}
          onClickSendSFA={this.onClickSendSFA}
          onChangeApprover={this.onChangeApprover}
          onCloseSFAReject={this.onCloseSFAReject}
          onChangeDeleteComment={this.onChangeDeleteComment}
          onChangeModifiedComment={this.onChangeModifiedComment}
          onClickSAD={this.onClickSAD}
          handleCheckAllOfferings={this.props.handleCheckAllOfferings}
          dimensionListToDisplay={this.state.dimensionListToDisplay}
          onEditBOI={this.onEditBOI}
          programClientDetails={programClientDetails}
          //reducers
          PnPComponents={PnPComponents}
          arsDetails={arsDetails}
          selectedProgramDetails={selectedProgramDetails}
          userInformation={userInformation}
          customBOI={this.props.customBOI}
          ProgramConfigurationData={this.props.ProgramConfigurationData}
          duplicateBOI={this.state.duplicateBOI}
          inComplete={this.state.inComplete}
          showNotifMessage={this.state.showNotifMessage}
          showDisclaimerMessage={this.showDisclaimerMessage}
          disableSADBtn={this.state.disableSADBtn}
          disableSendBtn={this.state.disableSendBtn}
          positionFixed={this.state.positionFixed}
          newProgramDetails={newProgramDetails}
          listenToScroll={this.listenToScroll}
          invalidEmailsList={this.state.invalidEmailsList}
        />
      );
    }
  }

  return ProgramALAppRejectedEditBOIsContainer;
})();

export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
      AccessValidationData: state.AccessValidationData,
      userInformation: state.userInformation,
      customBOI: state.SharedData.customBOI,
      showError: state.SharedData.showError
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getProgramDeletedBOISAD,
          setProgSFAPayload,
          sendEditReject,
          setNewProgramDetails,
          getCustomBOIDetails,
          setEditApproved,
          sendSADEditReject,
          handleCheckAllOfferings,
          setEmailError,
        }
      ),
      dispatch
    )
)(withRouter(ProgramALAppRejectedEditBOIs));
