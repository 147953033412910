import React, { Component } from "react";
import Select, { components } from "react-select";
import { IsValid } from "../../../Utilities/Validations";

require("./CVMSingleSelectDropdownWithoutLabel.css");
const CVMSingleSelectDropdownWithoutLabel = (function () {
  class CVMSingleSelectDropdownWithoutLabelPresentational extends Component {
    render() {
      const styles = {
        option: (base, state) => ({
          ...base,
          "&:hover": {
            color: "#fff",
            backgroundColor: "#a100ff",
            fontFamily: "Graphik-Medium",
          },
          backgroundColor: state.isSelected ? "#a100ff" : "#fff",
          color: state.isSelected ? "#fff" : "#212b36",
          whiteSpace: "nowrap",
          fontFamily: "Graphik-Regular",
          fontSize: "12px",
          padding: "10px 5px",
          wordBreak: "break-word",
          cursor: "pointer",
        }),
        control: (base, state) => ({
          ...base,
          borderRadius: "25px",
          boxShadow: 0,
          fontFamily: "Graphik-Medium",
          fontSize: "14px",
          color: "rgb(161, 0, 255)",
          width: "210px",
          minHeight: "38px",
          maxWidth: "500px",
          cursor: "pointer",
          fontWeight: "500",
          letterSpacing: "-0.25px",
          lineHeight: "14px",
          border: "none",
          backgroundColor: "#ffffff",
          width: "315px",
        }),
        menu: (base, state) => ({
          ...base,
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "0px 2px 24px 0px rgba(0, 0, 0, 0.15)",
          padding: "5px",
          zIndex: 2,
          width: "100%",
          fontSize: "12px",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          "&:hover": { color: "rgb(161, 0, 255)" },
          color: "rgb(161, 0, 255)", // Custom colour
          width: "32px",
        }),
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "rgb(250, 245, 253)",
            borderRadius: "16px",
            border: "1px solid rgb(161, 0, 255)",
            height: "24px",
            width: "79px",
            padding: "0 0px 0 10px",
            display: "flex",
            alignItems: "center",
            margin: "3px 4px",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "rgb(161, 0, 255)",
          fontSize: "12px",
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          ":hover": {},
        }),
      };

      return (
        <div className="multi-select-dropdown">
          <div className="multi-select-label">{this.props.label}</div>
          <Select
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isClearable={false}
            isMulti={false}
            isSearchable={false}
            options={this.props.options}
            styles={styles}
            placeholder={this.props.placeholder}
            name={this.props.name}
            onChange={this.props.onChange}
            value={this.props.value}
        
            components={{
              IndicatorSeparator: () => null,
            }}
            isDisabled={IsValid(this.props.isDisabled) ? this.props.isDisabled : false}
            value={this.props.value}
            onChange={(e) => this.props.callbackFunction (e)}
          />
        </div>
      );
    }
  }

  class CVMSingleSelectDropdownWithoutLabelContainer extends Component {
    constructor(props) {
      super(props);
      this.state = { value: {}};
    }

    componentDidMount = () => {
      this.setState({
        value: this.props.value,
      });
    };

    componentDidUpdate = (nextProps) => {
      if (nextProps.value !== this.props.value) {
        this.setState({
          value: this.props.value,
        });
      }
    };

    onChange = (e) => {
      this.setState(
        {
          value: e,
        },
        () => {
          this.props.callbackFunction(e, this.props.id);
        }
      );
    };

    render() {
      return (
        <CVMSingleSelectDropdownWithoutLabelPresentational
          label={this.props.label}
          placeholder={this.props.placeholder}
          options={this.props.options}
          isDisabled={this.props.isDisabled}
          callbackFunction={this.onChange}
          value={this.state.value}
        />
      );
    }
  }

  return CVMSingleSelectDropdownWithoutLabelContainer;
})();

export default CVMSingleSelectDropdownWithoutLabel;