import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GrooveButton } from "tfo-groove";
// import { history } from "../../../../store";
import { setToasterParam } from "../../../../Actions/Shared.Action";
import { HttpGet, HttpPost } from "../../../../Utilities/HTTPUtil";
import { HasProperty, GetType } from "../../../../Utilities/Validations";
import { EvaluateComponent } from '../../../../Utilities/AccessManagementUtil';
import CVMTable from "../../../Shared/CVMTable/CVMTable";
import ProjectAddModal from "./ProjectAddModal";
import ProjectEditModal from "./ProjectEditModal";
import ProjectNotifyModal from "./ProjectNotifyModal";
import addfiles from "./../../../../Static/images/360cvm/AddFilesIcon.svg";
import { withRouter } from "react-router-dom";
import "./ProjectDetails.css";
const ProjectDetails = (function () {
  const isComDisabledDef = {
    notifBtn: false,
    editBtn: false,
    addBtn: false
  }
  class ProjectDetailsPresentational extends Component {
    render() {
      return (
        <div className="client-config-container">
          <ProjectEditModal
            formData={this.props.selectedProject}
            onClickUpdateProject={this.props.onClickUpdateProject}
          />
          <div className="stepper-tab-left-section">
            <div className="tab-section-header header18-semibold-midnight">
              Programs ({this.props.programList.length})
            </div>
            <div className="subtab-list">
              <ul className="bodytext16-medium-midnight">
                {this.props.programList.map((e) => {
                  return (
                    <li
                      key={e.id}
                      className={
                        e.id === this.props.selectedProgram.id ? "active" : null
                      }
                      onClick={() => {
                        this.props.onSelectProgram(e);
                      }}
                    >
                      {e.programName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="stepper-tab-right-section" id="root">
            {this.props.projDetailsTable.data.length > 0 && (
              <div
                className="tab-section-header header18-semibold-midnight"
                style={{
                  float: "left",
                  color: "#959EA5",
                }}
              >
                {`${this.props.selectedProgramDetails.programName} Projects`}
              </div>
            )}
            <ProjectAddModal
              onClickAddProject={this.props.onClickAddProject}
              selectedProgramDetails={this.props.selectedProgramDetails}
              isDisabled={this.props.selectedProgram.id === -1 || this.props.isComDisabled.addBtn}
            />
            <div className="added-program-container">
              {/* if projects were empty */
                this.props.projDetailsTable.data.length <= 0 ? (
                  <div className="empty-program-container bodytext18-regular-slate">
                    <img src={addfiles} alt="select client" />
                    <span className="bodytext24-medium-slate">
                      No Project Added
                  </span>
                  Please add project(s) to this program by using the 'Add New
                  Project' button above.
                  </div>
                ) : (
                  <div className="added-program-table-container">
                    <CVMTable
                      tableProperties={JSON.parse(
                        JSON.stringify(this.props.projDetailsTable)
                      )}
                      onRowSelect={this.props.isComDisabled.editBtn ? () => { } : this.props.onSelectProject}
                      getGridApi={this.props.getGridApi}
                    />
                  </div>
                )}
            </div>
            <div className="project-details-button" id="message-overlay-root">
              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={(this.props.projDetailsTable.data.length === 0 || this.props.isComDisabled.notifBtn)}
                hasIcon={false}
                type="outline"
                colorClass="stat-alternate"
                size="auto"
                text="Save as Draft"
                callbackFunction={this.props.onClickSaveDraft}
              />
              <ProjectNotifyModal
                label="Project Lead"
                onClickNotify={this.props.onClickNotify}
                projLeadList={this.props.projLeadList}
                isDisabled={(this.props.projDetailsTable.data.length === 0 || this.props.isComDisabled.notifBtn)}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  class ProjectDetailsContainer extends Component {
    constructor() {
      super();
      this.defaultSelectedProject = {
        rowID: -1,
        projectName: "",
        projectLead: {},
        projectDescription: "",
        offeringID: 0,
        offeringName: "",
        deliveryLocations: [],
        deliveredBy: {},
        targetEndDate: "",
      };
      this.defaultProgramDetails = {
        programName: "",
        reportingYear: "",
        offering: "",
        offeringID: -1,
        bTLead: "",
        cXLead: "",
      };
      this.state = {
        isComDisabled: isComDisabledDef,
        selectedProgram: { id: -1, programName: "" },
        selectedProgramDetails: this.defaultProgramDetails,
        selectedProject: this.defaultSelectedProject,
        projLeadList: [],
        programList: [],
        projDetailsTable: {
          columns: [
            { field: "projectName", headerName: "Project Name" },
            { field: "projectDescription", headerName: "Project Description" },
            { field: "projectLeadName", headerName: "Project Lead" },
            { field: "offeringName", headerName: "Offering" },
            { field: "targetEndDateFormatted", headerName: "Target end-date" },
            { field: "deliveryLocationNames", headerName: "Delivery Location" },
            { field: "deliveredByName", headerName: "Delivered By" },
          ],
          data: [],
          rowSelection: "single",
          gridApi: () => { },
        },
      };
    }
    componentDidMount() {
      if (this.props.SharedData.selectedClient.value > 0) {
        this.loadInitialData();
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.clientID !== prevProps.clientID) {
        this.loadInitialData()
      }
    }


    loadInitialData = async () => {
      const reportingYear = this.props.ClientConfigurationData.workFlowReportingYear;
      const progList = await this.getProgramList(this.props.clientID, reportingYear);
      let isDisabled = false

      if (progList.length > 0) {
        this.setState({ selectedProgram: progList[0] });
        this.getProjectList(this.props.clientID, progList[0].id);
        const progDetails = await this.getProgramDetails(this.props.clientID, progList[0].id);
        this.props.setProgDetailsProjectDetails(progDetails);
        isDisabled = false
      } else {
        const objTemp = this.state.tableProperties
        this.setState({
          selectedProgram: { id: -1, programName: "-1" },
          selectedProgramDetails: this.defaultProgramDetails,
          selectedProject: this.defaultSelectedProject,
          projDetailsTable: { ...objTemp, data: [] },
        })
        this.props.setProgDetailsProjectDetails(this.defaultProgramDetails)
        isDisabled = true
      }

      this.evalComAccessibility(isDisabled);
    }

    evalComAccessibility = (isDisabled) => {
      const e = EvaluateComponent(isComDisabledDef)
      this.setState({ isComDisabled: { ...e, addBtn: isDisabled } })
    }

    getProgramList = async (clientID, reportingYear) => {
      const progList = await HttpGet(`client/getProgramList?clientID=${clientID}&reportingYear=${reportingYear}`);
      this.setState({ programList: progList });

      return progList;
    };

    getProgramDetails = async (clientID, programID, reportingYear) => {
      const details = await HttpGet(`client/getProjectDetailProgramDetails?clientID=${clientID}&programID=${programID}&reportingYear=${reportingYear}`);
      this.setState({ selectedProgramDetails: details });
      return details;
    };

    getProjectList = async (clientID, programID) => {
      const details = await HttpGet(`client/getProjDetailsProjList?clientID=${clientID}&programID=${programID}`);
      const finalDetails = details.projectList.map((e, index) => {
        const delivs = details.deliveryCenters.filter(d2 => { return d2.projectID === e.projectID });
        return {
          rowID: index,
          projectID: e.projectID,
          projectName: e.projectName,
          projectLead: {
            value: e.projectLeadName,
            label: e.projectLeadName,
            text: e.projectLeadName,
          },
          projectLeadName: e.projectLeadName,
          projectDescription: e.projectDescription,
          offeringID: e.offeringID,
          offeringName: e.offeringName,
          deliveredBy: {
            value: e.deliveredByID,
            label: e.deliveredByName,
            text: e.deliveredByName,
          },
          deliveredByName: e.deliveredByName,
          targetEndDate: new Date(e.targetEndDate),
          targetEndDateFormatted: new Date(e.targetEndDate).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric", }).replace(/ /g, "-"),
          deliveryLocations: delivs.map(d => {
            return {
              value: d.deliveryCenterID,
              label: d.deliveryCenterName,
              text: d.deliveryCenterName,
            }
          }),
          deliveryLocationNames: delivs.map(d3 => { return d3.deliveryCenterName }).join(', '),
        };
      });

      let objTemp = this.state.projDetailsTable;
      objTemp["data"] = finalDetails;
      const tProjLeadList = [...new Set(objTemp.data.map(e => { return e.projectLeadName }))];
      this.setState({ projDetailsTable: { ...objTemp, data: [] } }, () => {
        this.setState({ projDetailsTable: objTemp, projLeadList: tProjLeadList })
      });
    };
    onSelectProgram = async (program) => {
      const reportingYear = this.props.ClientConfigurationData.workFlowReportingYear;
      this.setState({ selectedProgram: program });
      this.getProjectList(this.props.clientID, program.id);
      const progDetails = await this.getProgramDetails(this.props.clientID, program.id, reportingYear);
      this.props.setProgDetailsProjectDetails(progDetails);
    }

    onSelectProject = (tempProj) => {
      if (tempProj.length === 0 ) return
      let selected = tempProj[0];
      this.setState({
        selectedProject: {
          ...selected,
          targetEndDate: new Date(selected.targetEndDate)
        }
      })
      this.state.gridApi.deselectAll();
    }

    onClickAddProject = (data) => {
      let newData = data;
      const deliv = newData.deliveryLocations;
      newData = {
        ...newData,
        deliveredByName: newData.deliveredBy.label,
        deliveryLocations: deliv.map((e) => { return { ...e, value: Number(e.value) } }),
        deliveryLocationNames: deliv.map((e) => { return e.label; }).join(", "),
        projectLeadName: newData.projectLead.label,
        targetEndDateFormatted: new Date(newData.targetEndDate).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }).replace(/ /g, "-")
      }

      let objTemp = JSON.parse(JSON.stringify(this.state.projDetailsTable));
      objTemp.data.push(newData);
      objTemp.data = objTemp.data.map((e, index) => { return { ...e, rowID: index } })

      const tProjLeadList = [...new Set(objTemp.data.map(e => { return e.projectLeadName }))];

      this.setState({ projDetailsTable: { ...objTemp, data: [] } }, () => {
        this.setState({ projDetailsTable: objTemp, projLeadList: tProjLeadList })
      });
    };

    onClickUpdateProject = (data) => {
      let objTemp = JSON.parse(JSON.stringify(this.state.projDetailsTable));
      const index = objTemp.data.findIndex((e) => e.rowID === this.state.selectedProject.rowID)

      let newData = data;
      const deliv = newData.deliveryLocations;
      newData = {
        ...newData,
        deliveredByName: newData.deliveredBy.label,
        deliveryLocations: deliv.map((e) => { return { ...e, value: Number(e.value) } }),
        deliveryLocationNames: deliv.map((e) => { return e.label; }).join(", "),
        projectLeadName: newData.projectLead.label,
        targetEndDateFormatted: new Date(newData.targetEndDate).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }).replace(/ /g, "-")
      }

      objTemp.data[index] = newData;
      const tProjLeadList = [...new Set(objTemp.data.map(e => { return e.projectLeadName }))]

      this.setState({ projDetailsTable: { ...objTemp, data: [] } }, () => {
        this.setState({ projDetailsTable: objTemp, projLeadList: tProjLeadList })
      })
    }

    onClickSaveDraft = async () => {
      const tableData = this.state.projDetailsTable.data;
      const delivIDs = tableData.map((e) => {
        let strIDs = ''
        e.deliveryLocations.forEach(element => { strIDs += `${element.value},` })
        strIDs = strIDs.slice(0, -1)
        return {
          clientProjectID: HasProperty(e, 'projectID') ? e.projectID : 0,
          deliveryCenterID: strIDs
        }
      })

      let notifData = {
        comments: '',
        clientID: this.props.clientID,
        reportingYear: this.props.reportingYearParam,
        clientMasterID: this.props.clientID,
        clientOfferingID: this.state.selectedProgramDetails.offeringID,
        clientProgramID: this.state.selectedProgram.id,
        deliveryCenters: delivIDs,
        projects: tableData.map((e) => {
          return {
            iD: e.projectID,
            clientProgramID: this.state.selectedProgram.id,
            clientProjectName: e.projectName,
            projectLead: e.projectLead.label,
            projectDescription: e.projectDescription,
            targetEndDate: e.targetEndDate,
            deliveredBy: e.deliveredBy.label,
            deliveryLocations: ''
          };
        })
      }

      const details = await HttpPost("client/projectDetailsConfigSaveAsDraft", notifData);
      if (
        (HasProperty(details, "code") && details.code === "1") ||
        (HasProperty(details, "Code") && details.Code === "1")
      ) {
        this.props.setToasterParam({
          show: true,
          type: 'success',
          message: "Your Progress was saved as draft successfully!",
        });
        this.props.history.push("/ClientConfiguration");
      }

    }

    onClickNotify = async (formData) => {
      const tableData = this.state.projDetailsTable.data;
      const delivIDs = tableData.map((e) => {
        let strIDs = ''
        e.deliveryLocations.forEach(element => { strIDs += `${element.value},` })
        strIDs = strIDs.slice(0, -1)

        return {
          clientProjectID: HasProperty(e, 'projectID') ? e.projectID : 0,
          deliveryCenterID: strIDs
        }
      })

      let notifData = {
        comments: formData.comments,
        clientID: this.props.clientID,
        reportingYear: this.props.reportingYearParam,
        clientMasterID: this.props.clientID,
        clientOfferingID: this.state.selectedProgramDetails.offeringID,
        clientProgramID: this.state.selectedProgram.id,
        deliveryCenters: delivIDs,
        projects: tableData.map((e) => {
          return {
            iD: e.projectID,
            clientProgramID: this.state.selectedProgram.id,
            clientProjectName: e.projectName,
            projectLead: e.projectLead.label,
            projectDescription: e.projectDescription,
            targetEndDate: e.targetEndDate,
            deliveredBy: e.deliveredBy.label,
            deliveryLocations: e.deliveryLocationNames
          }
        })
      }

      const details = await HttpPost("client/projectDetailsConfigNotify", notifData);
      if (
        (HasProperty(details, "code") && details.code === "1") ||
        (HasProperty(details, "Code") && details.Code === "1")
      ) {
        this.props.setToasterParam({
          show: true,
          type: 'success',
          message: "Notification sent successfully",
        });
        this.props.history.push("/ClientConfiguration");
      }

    }
    getGridApi = (api) => {
      this.setState({
        gridApi: api,
      });
    };
    render() {
      return (
        <ProjectDetailsPresentational
          //Add
          onClickAddProject={this.onClickAddProject}
          //Edit
          selectedProject={this.state.selectedProject}
          onClickUpdateProject={this.onClickUpdateProject}
          //Table
          projDetailsTable={this.state.projDetailsTable}
          onSelectProject={this.onSelectProject}
          selectedProgramDetails={this.state.selectedProgramDetails}
          getGridApi={this.getGridApi}
          //Program list
          programList={this.state.programList}
          selectedProgram={this.state.selectedProgram}
          onSelectProgram={this.onSelectProgram}
          //Lower buttons
          onClickSaveDraft={this.onClickSaveDraft}
          onClickNotify={this.onClickNotify}
          projLeadList={this.state.projLeadList}

          isComDisabled={this.state.isComDisabled}
        />
      );
    }
  }

  return ProjectDetailsContainer;
})();

export default connect(
  (state) => {
    return {
      clientID: state.SharedData.selectedClient.value,
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      reportingYearParam: state.ClientConfigurationData.workFlowReportingYear
    };
  },
  (dispatch) =>
    bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(withRouter(ProjectDetails));
