import {
  GET_DASHBOARD_CLIENT,
  SET_DB_DIMENSION_LIST,
  FETCH_DASHBOARD_REPORTING_YEAR,
  SET_DB_REPORTING_YEAR,
  SET_DB_PROGRAM,
  FETCH_DASHBOARD_PROGRAM,
  FETCH_DIMENSION_SUBDIMENSION,
  GET_BOI_PROJECT_DETAILS,
  SET_CLICKED_SUBDIMENSION_NAME,
  SET_CLICKED_PANEL,
  CLEAR_DASHBOARD,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_COMMENTARY,
  GET_PROGRAM_FISCAL_YEAR_RANGE,
  GET_DASHBOARD_PROGRAM_DETAILS,
  SET_GLOBAL_URL_PARAM,
  GET_GLOBAL_DASHBOARD_PROGRAM_DETAILS,
  GET_DASHBOARD_CLIENT_DETAILS,
  UNSET_DASHBOARD_CLIENT_DETAILS
} from '../Constants';

import { HttpGet, HttpPostText } from '../Utilities/HTTPUtil';
import { IsValid } from '../Utilities/Validations';
import DashboardIcon from '../Static/images/360cvm/Dashboard.svg';
import { TryGetObjValueAlt, TryGetValue } from '../Utilities/Formatter';

export const getDashboardClientList = (userId) => async (dispatch, state) => {
  try {
    let body = {
      userId: userId,
    };
    let data = await HttpPostText(`ClientConfiguration/GetClientDashboardList_sp`, body);
    data = JSON.parse(data);
    
    let clients = [];
    if (Object.keys(data).length > 0) {
      data.forEach((item) => {
        clients.push({
          icon: item.ClientMasterLogo || DashboardIcon,
          label: item.ClientMasterName,
          favorite: item.UserID !== 0 ? true : false,
          value: item.Id,
          isClientDataEmpty: item.Pid === 0 ? true : false,
        });
      });
    }
    dispatch({
      type: GET_DASHBOARD_CLIENT,
      dashboardClients: clients,
    });
    return clients;
  } catch (err) {
    console.log(err);
  }
};

export const FetchDashboardData = (clientID) => async (dispatch, state) => {
  const data = !IsValid(clientID)
    ? []
    : await HttpGet(`ValueMeterChart/GetProgramforWheel?clientID=${clientID}`);

  const tDashProg = [
    ...new Set(
      data.map((item) => {
        return item.programName;
      })
    ),
  ];

  const tDashProgList = tDashProg.map(e => {
    const id = data.find(d => d.programName === e).id
    return {
      label: e,
      value: e,
      id
    }
  })

  dispatch({
    type: FETCH_DASHBOARD_PROGRAM,
    dashboardProgram: tDashProgList,
  });
  dispatch({
    type: GET_DASHBOARD_DATA,
    dashboardData: data,
  });
};

export const fetchClientDetails = (clientID) => async (dispatch, state) => {
  let clientDetails = {
    "ClientMasterID": 0,
    "ClientName": "",
    "Industry": "",
    "MarketUnit": "",
    "SubMarketUnit": "",
    "ClientType": "",
    "Stage": "Stage 0",
    "StageName": "Aware & Define Value Meter",
    "StageDescription": " No Program has been created or no program has been approved."
  };
  if (clientID !== "new" && IsValid(clientID)) {
    const data = await HttpGet(`ValueMeterChart/GetClientDetailsAndStages?clientMasterID=${clientID}`);
    if (data.length > 0) clientDetails = data[0];
  }

  dispatch({
    type: GET_DASHBOARD_CLIENT_DETAILS,
    dashboardClientDetails: clientDetails,
  });
}

export const unsetClientDetails = () => async (dispatch, state) => {
  dispatch({
    type: UNSET_DASHBOARD_CLIENT_DETAILS,
  });
}

export const onSelectProgram = (programName, program) => async (dispatch, state) => {
  try {
    let dashboardData = state().DashboardData.dashboardData;

    let reportingYearList = [];

    dashboardData.map((item) => {
      if (item.programName === programName) {
        reportingYearList.push(
          typeof item.ReportingYear === 'number' ? [item.ReportingYear] : item.ReportingYear
        );
      }
    });
    let tempReportingYearList = [...new Set(reportingYearList)];

    reportingYearList = [];
    tempReportingYearList.map((item) => {
      reportingYearList.push({
        label: item,
        value: item,
      });
    });

    dispatch({
      type: FETCH_DASHBOARD_REPORTING_YEAR,
      reportingYear: reportingYearList,
    });

    dispatch({
      type: SET_DB_PROGRAM,
      selectedProgram: programName,
    });
  } catch (err) {
    console.log(err);
  }
};
export const setSelectedReportingYear = (year) => async (dispatch, state) => {
  dispatch({
    type: SET_DB_REPORTING_YEAR,
    selectedYear: year,
  });
};

export const getDashboardDimensions =
  (clientID, reportingYear, programName) => async (dispatch, state) => {
    try {
      let dashboardData = state().DashboardData.dashboardData;
      const year = reportingYear[0];
      let programID = TryGetObjValueAlt(
        dashboardData.find(
          (d) => d.programName === programName && [d.ReportingYear].indexOf(year) > -1
        ),
        0,
        'id'
      );

      const dimensionSubDimension = !IsValid(clientID)
        ? []
        : await HttpGet(
          `ValueMeterChart/GetDimesionSubDimesionBOIlist?ClientID=${clientID}&reportingYear=${reportingYear}&programID=${programID}`
        );

      let dimensionList = [];
      const data = JSON.parse(JSON.stringify(dimensionSubDimension));
      const dimensionSubDimensionList = JSON.parse(JSON.stringify(dimensionSubDimension));

      if (Object.keys(data).length > 0) {
        dimensionList = data.CoreDimesionList.map((dimensionItem) => {
          return {
            dimensionColor: dimensionItem.CoreDimensionColor,
            dimensionID: dimensionItem.CoreDimensionID,
            dimensionName: dimensionItem.CoreDimensionName,
            ID: dimensionItem.ID,
            subDimensionList: dimensionItem.Subdimesions.map((subDimensionItem) => {
              return {
                subDimensionColor: subDimensionItem.SubDimensionColor,
                subDimensionID: subDimensionItem.SubDimensionID,
                subDimensionName: subDimensionItem.SubDimensionName,
                ID: subDimensionItem.ID,
                boiList: subDimensionItem.BIOSlIST.map((boiItem) => {
                  return {
                    boiID: boiItem.BOIID,
                    boiName: boiItem.BOIName,
                    boiTarget: boiItem.BOITarget,
                    boiDescription: boiItem.BOIDescription,
                    boiUOM: boiItem.UOM,
                    parameterList: [],
                    IsCustom: boiItem.IsCustom,
                    BOIDefinitionMasterID: boiItem.BOIDefinitionMasterID,
                    projectCount: boiItem.Projectcnt,
                    trendGraphList: boiItem.TrendGraphList ? boiItem.TrendGraphList : [],
                    offeringName: boiItem.Offering,
                    suboffering: boiItem.SubOfferingName,
                    leadingIndicator: boiItem.LeadingIndicator,
                    earlierApprovedValue: boiItem.EarlierApprovedValue,
                    programCommentary: boiItem.ProgramCommentary,
                    programComments: boiItem.ProgramComments,
                    status: boiItem.Status,
                    subDimensionID: boiItem.SubDimensionID,
                    // Validated
                    boiDeliveredValue: boiItem.DeliveredValue,
                    boiIdentifiedValue: boiItem.IdentifiedValue,
                    // Improvement
                    impIdentified: boiItem.YetToBeIdentified_Improvement,
                    impDelivered: boiItem.Improvement,
                    // Yet to be validated
                    ytvDelivered: boiItem.YetToBeDeliveredValue,
                    ytvIdentified: boiItem.YetToBeIdentifiedValue,

                    customParameterList: boiItem.CustomParameterList,
                    isMultipleUOMwithUSD: boiItem.IsMultipleUOMwithUSD,
                  };
                }),
              };
            }),
          };
        });
      }

      let programFiscalYearRange = {
        min: 0,
        max: 0,
      };
      if (dashboardData && programID) {
        let tempProgramFiscalYearRange = [];
        dashboardData
          .filter((program) => {
            return program.id === programID;
          })
          .map((program) => {
            tempProgramFiscalYearRange.push(program.ReportingYear);
          });

        if (tempProgramFiscalYearRange.length > 0) {
          programFiscalYearRange.min = Math.min(...tempProgramFiscalYearRange);
          programFiscalYearRange.max = Math.max(...tempProgramFiscalYearRange);
        } else {
          programFiscalYearRange.min = year;
          programFiscalYearRange.max = year;
        }
      }

      dispatch(getProgramDetailsForWheel(clientID, programID));

      dispatch({
        type: GET_PROGRAM_FISCAL_YEAR_RANGE,
        programFiscalYearRange: programFiscalYearRange,
      });

      dispatch({
        type: SET_DB_DIMENSION_LIST,
        dbDimensionList: dimensionList,
      });

      dispatch({
        type: FETCH_DIMENSION_SUBDIMENSION,
        dimensionSubDimension: dimensionSubDimensionList,
        programID: programID,
      });

      dispatch({
        type: SET_CLICKED_PANEL,
        panelClicked: null,
        panelIsClicked: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getProgramDetailsForWheel = (clientID, programID) => async (dispatch, state) => {
  try {
    const data = !IsValid(clientID)
      ? []
      : await HttpGet(
        `ValueMeterChart/GetProgramDetailsForWheel?programID=${programID}&clientID=${clientID}`
      );

    let dashboardProgramDetails = {};

    if (Object.keys(data).length > 0) {
      dashboardProgramDetails = {
        clientMasterName: data[0].ClientMasterName,
        clientProgramName: data[0].ClientProgramName,
        marketUnit: data[0].MarketUnit,
        lastUpdate: data[0].LastUpdate,
        submittedBy: data[0].SubmittedBy,
        accountLead: data[0].AccountLead,
        cxLead: data[0].CXLead,
        btLead: data[0].BTLead,
        iandILead: data[0].IandILead,
        clientContact: data[0].ClientContact,
      };
    }

    dispatch({
      type: GET_DASHBOARD_PROGRAM_DETAILS,
      dashboardProgramDetails: dashboardProgramDetails,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getBOIProjectDetails =
  (boiID, selectedYear, clientProgramTargetId) => async (dispatch, state) => {
    let programId = state().DashboardData.programID;
    let tempProject = [];

    try {
      let data = await HttpGet(
        `ValueMeterChart/GetProjectDetail?ProgramID=${programId}&BOIID=${boiID}&reportingYear=${selectedYear}&clientProgramTargetId=${clientProgramTargetId}`
      );
      if (Object.keys(data).length > 0) {
        data.map((projectItem, projectIndex) => {
          tempProject.push({
            boiid: projectItem.BOIID,
            clientProjectID: projectItem.ClientProjectID,
            clientProjectName: projectItem.ClientProjectName,
            //Validated
            boiDeliveredValue: projectItem.DeliveredValue,
            boiIdentifiedValue: projectItem.IdentifiedValue,
            // Improvement
            impIdentified: projectItem.YetToBeIdentified_Improvement,
            impDelivered: projectItem.Improvement,
            // Yet to be Validated
            ytvDelivered: projectItem.YetToBeDeliveredValue,
            ytvIdentified: projectItem.YetToBeIdentifiedValue,

            projectStatus: projectItem.Status,
            boiDescription: '',
            boiName: projectItem.BOIName,
            boiUOM: projectItem.UOM,
            projectLatestComment: projectItem.ProjectLatestComment,
          });
        });
      }
      dispatch({
        type: GET_BOI_PROJECT_DETAILS,
        boiProjectDetails: tempProject,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const onSubdimensionClick = (index) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CLICKED_SUBDIMENSION_NAME,
      subDimensionClicked: index,
    });
  } catch (err) {
    console.log(err);
  }
};

export const panelOnClick = (index, isClicked) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CLICKED_PANEL,
      panelClicked: index,
      panelIsClicked: isClicked,
    });
  } catch (err) {
    console.log(err);
  }
};

export const clearDashboard = (key) => async (dispatch, state) => {
  try {
    const data = state().DashboardData;

    switch (key) {
      case 'reportingYear':
        data.dimensionSubDimension = [];
        data.programID = '';
        data.boiProjectDetails = [];
        data.subDimensionClicked = 0;
        data.panelClicked = null;
        data.panelIsClicked = false;
        data.dbDimensionList = undefined;
        break;
      case 'program':
        data.dimensionSubDimension = [];
        data.programID = '';
        data.boiProjectDetails = [];
        data.subDimensionClicked = 0;
        data.panelClicked = null;
        data.panelIsClicked = false;
        data.dbDimensionList = undefined;
        break;
      default:
        data.reportingYear = [];
        data.dashboardProgram = [];
        data.dimensionSubDimension = [];
        data.programID = '';
        data.boiProjectDetails = [];
        data.subDimensionClicked = 0;
        data.panelClicked = null;
        data.panelIsClicked = false;
        data.dbDimensionList = undefined;
        break;
    }

    dispatch({
      type: CLEAR_DASHBOARD,
      payload: data,
    });
  } catch (err) {
    console.log(err);
  }
};

// PREPARE DATA (program details, boi options)
export const setCommentaryRequiredData =
  (programID, isApprovedBoiOnly) => async (dispatch, state) => {
    const clientID = state().SharedData.selectedClient.value;
    const reportingYear = state().DashboardData.selectedYear.value[0];

    try {
      let selectedProgram = {};
      let boiOption = [];

      const programList = await HttpGet(
        `ProgramConfiguration/GetClientProgramsDetail?ClientID=${clientID}`
      );

      let programDetails = programList.find((program) => program.ClientProgramID === programID);
      if (programDetails) {
        selectedProgram = {
          programName: programDetails.ClientProgramName,
          programID: programDetails.ClientProgramID,
          programCreator: IsValid(programDetails.ProgramCreator) ? programDetails.ProgramCreator.split('@')[0] : '',
        };
      }
      // For Global Dashboard,isApprovedBoiOnly, get only approved BOI
      let dimensionURL = isApprovedBoiOnly
        ? `GlobalDashboard/GetDimesionSubdimesionApprovedBOIlist?ClientID=${clientID}&reportingYear=${reportingYear}&programID=${programID}`
        : `ValueMeterChart/GetDimesionSubDimesionBOIlist?ClientID=${clientID}&reportingYear=${reportingYear}&programID=${programID}`;
      const dimesionList = await HttpGet(dimensionURL);
      dimesionList.CoreDimesionList.forEach((dim) => {
        dim.Subdimesions.forEach((subDim) => {
          subDim.BIOSlIST.forEach((boi) => {
            boiOption.push({
              label: boi.BOIName,
              value: `${boi.BOIID}_${boi.ClientProgramTargetID}`,
              boiID: boi.BOIID,
              clientProgramTargetID: boi.ClientProgramTargetID,
              subDimensionID: subDim.SubDimensionID,
              dimensionID: dim.CoreDimensionID,
              boiUOM: boi.UOM,
              isMultipleUOMwithUSD: boi.IsMultipleUOMwithUSD,
            });
          });
        });
      });

      let data = {
        selectedProgram: selectedProgram,
        boiOption: boiOption,
      };

      dispatch({
        type: GET_DASHBOARD_COMMENTARY,
        dashboardCommentary: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const handleSendComment = (commentDetails, selectedBOI) => async (dispatch, state) => {
  try {
    const enterpriseID = state().userInformation.enterpriseID;
    const programID = state().DashboardData.programID;
    const dashboardCommentary = state().DashboardData.dashboardCommentary;
    const reportingYear = state().DashboardData.selectedYear.label[0];

    const body = {
      id: commentDetails.Id,
      projectDataEntryID: 0,
      clientProgramID: programID,
      boiid: selectedBOI.boiID,
      comment: commentDetails.Comment,
      isEdited: commentDetails.IsEdited,
      createdUser: enterpriseID,
      clientProgramTargetID: selectedBOI.clientProgramTargetID,
      reportingYear: reportingYear,
    };
    let data = await HttpPostText(`ValueMeterChart/AddEditProgramComment`, body);
    if (data) {
      data = JSON.parse(data);
      const recentComment = {
        Comment: data.Comment,
        CreatedTS: data.CreatedTS,
        CreatedUser: data.IsEdited ? commentDetails.CreatedUser : enterpriseID,
        Id: data.Id,
        IsActive: false,
        IsEdited: data.IsEdited,
        IsProgramLevel: true,
      };

      dispatch({
        type: GET_DASHBOARD_COMMENTARY,
        dashboardCommentary: {
          ...dashboardCommentary,
          recentComment: recentComment,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Global Dashboard API
export const GetDashboardProgramDetails =
  (globalClientId, programID) => async (dispatch, state) => {
    try {
      let selectedGlobalClient = {};
      const globalDashboardProgramDetails = await HttpGet(
        `GlobalDashboard/GetDashboardProgramDetails?GlobalClientId=${globalClientId}&ClientProgramId=${programID}`
      );
      console.log('GetDashboardProgramDetails: ', globalDashboardProgramDetails);

      if (globalDashboardProgramDetails) {
        selectedGlobalClient = {
          globalClientId: globalDashboardProgramDetails.GlobalClientId,
          clienMasterId: globalDashboardProgramDetails.ClienMasterId,
          clientMasterName: globalDashboardProgramDetails.ClientMasterName,
          clientMasterLogo: globalDashboardProgramDetails.ClientMasterLogo,
          clientProgramId: globalDashboardProgramDetails.ClientProgramId,
          programName: globalDashboardProgramDetails.ProgramName,
          reportingYears: globalDashboardProgramDetails.ReportingYears,
          selectedClientItem: {
            favorite: false,
            icon: globalDashboardProgramDetails.ClientMasterLogo,
            isClientDataEmpty: false,
            label: globalDashboardProgramDetails.ClientMasterName,
            value: globalDashboardProgramDetails.ClienMasterId,
          },
        };

        let tempDashboardProgramList = [];
        let tempData = [];
        if (selectedGlobalClient) {
          tempDashboardProgramList.push({ name: selectedGlobalClient.programName, id: selectedGlobalClient.clientProgramId, });

          selectedGlobalClient.reportingYears.map((reportingYear) => {
            tempData.push({
              ReportingYear: reportingYear,
              id: selectedGlobalClient.clientProgramId,
              programName: selectedGlobalClient.programName,
            });
          });
        }
        const dashboardProgramList = tempDashboardProgramList.map((i) => {
          return {
            label: i.name,
            value: i.name,
            id: i.id
          };
        });

        dispatch({
          type: FETCH_DASHBOARD_PROGRAM,
          dashboardProgram: dashboardProgramList,
        });
        dispatch({
          type: GET_DASHBOARD_DATA,
          dashboardData: tempData,
        });

        dispatch({
          type: GET_GLOBAL_DASHBOARD_PROGRAM_DETAILS,
          globalDashboardProgramDetails: selectedGlobalClient,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

export const GetGlobalDashboardOfferings = (ClientId) => async (dispatch, state) => {
  try {
    const globalDashboardProgramDetails = await HttpGet(
      `GlobalDashboard/offerings?ClientId=${ClientId}`
    );
    // console.log('GetGlobalDashboardOfferings: ', globalDashboardProgramDetails);
  } catch (err) {
    console.log(err);
  }
};

export const GetGlobalDashboardBOI = (programID) => async (dispatch, state) => {
  try {
    const globalDashboardProgramDetails = await HttpGet(
      `GlobalDashboard/boi?ClientProgramId=${programID}`
    );
    // console.log('GetGlobalDashboardBOI: ', globalDashboardProgramDetails);
  } catch (err) {
    console.log(err);
  }
};

export const getGlobalDashboardDimensions =
  (clientID, reportingYear, programName) => async (dispatch, state) => {
    try {
      let dashboardData = state().DashboardData.dashboardData;
      const year = reportingYear[0];
      let programID = TryGetObjValueAlt(
        dashboardData.find(
          (d) => d.programName === programName && [d.ReportingYear].indexOf(year) > -1
        ),
        0,
        'id'
      );

      const dimensionSubDimension = !IsValid(clientID)
        ? []
        : await HttpGet(
          `GlobalDashboard/GetDimesionSubdimesionApprovedBOIlist?ClientID=${clientID}&reportingYear=${reportingYear}&programID=${programID}`
        );

      let dimensionList = [];
      const data = JSON.parse(JSON.stringify(dimensionSubDimension));
      const dimensionSubDimensionList = JSON.parse(JSON.stringify(dimensionSubDimension));

      if (Object.keys(data).length > 0) {
        dimensionList = data.CoreDimesionList.map((dimensionItem) => {
          return {
            dimensionColor: dimensionItem.CoreDimensionColor,
            dimensionID: dimensionItem.CoreDimensionID,
            dimensionName: dimensionItem.CoreDimensionName,
            ID: dimensionItem.ID,
            subDimensionList: dimensionItem.Subdimesions.map((subDimensionItem) => {
              return {
                subDimensionColor: subDimensionItem.SubDimensionColor,
                subDimensionID: subDimensionItem.SubDimensionID,
                subDimensionName: subDimensionItem.SubDimensionName,
                ID: subDimensionItem.ID,
                boiList: subDimensionItem.BIOSlIST.map((boiItem) => {
                  return {
                    boiID: boiItem.BOIID,
                    boiName: boiItem.BOIName,
                    boiTarget: boiItem.BOITarget,
                    boiDescription: boiItem.BOIDescription,
                    boiUOM: boiItem.UOM,
                    parameterList: [],
                    IsCustom: boiItem.IsCustom,
                    BOIDefinitionMasterID: boiItem.BOIDefinitionMasterID,
                    projectCount: boiItem.Projectcnt,
                    trendGraphList: boiItem.TrendGraphList ? boiItem.TrendGraphList : [],
                    offeringName: boiItem.Offering,
                    suboffering: boiItem.SubOfferingName,
                    leadingIndicator: boiItem.LeadingIndicator,
                    earlierApprovedValue: boiItem.EarlierApprovedValue,
                    programCommentary: boiItem.ProgramCommentary,
                    programComments: boiItem.ProgramComments,
                    status: boiItem.Status,
                    subDimensionID: boiItem.SubDimensionID,
                    // Validated
                    boiDeliveredValue: boiItem.DeliveredValue,
                    boiIdentifiedValue: boiItem.IdentifiedValue,
                    // Improvement
                    impIdentified: boiItem.YetToBeIdentified_Improvement,
                    impDelivered: boiItem.Improvement,
                    // Yet to be validated
                    ytvDelivered: boiItem.YetToBeDeliveredValue,
                    ytvIdentified: boiItem.YetToBeIdentifiedValue,

                    customParameterList: boiItem.CustomParameterList,
                    isMultipleUOMwithUSD: boiItem.IsMultipleUOMwithUSD,
                  };
                }),
              };
            }),
          };
        });
      }

      let programFiscalYearRange = {
        min: 0,
        max: 0,
      };
      if (dashboardData && programID) {
        let tempProgramFiscalYearRange = [];
        dashboardData
          .filter((program) => {
            return program.id === programID;
          })
          .map((program) => {
            tempProgramFiscalYearRange.push(program.ReportingYear);
          });

        if (tempProgramFiscalYearRange.length > 0) {
          programFiscalYearRange.min = Math.min(...tempProgramFiscalYearRange);
          programFiscalYearRange.max = Math.max(...tempProgramFiscalYearRange);
        } else {
          programFiscalYearRange.min = year;
          programFiscalYearRange.max = year;
        }
      }

      dispatch(getProgramDetailsForWheel(clientID, programID));

      dispatch({
        type: GET_PROGRAM_FISCAL_YEAR_RANGE,
        programFiscalYearRange: programFiscalYearRange,
      });

      dispatch({
        type: SET_DB_DIMENSION_LIST,
        dbDimensionList: dimensionList,
      });

      dispatch({
        type: FETCH_DIMENSION_SUBDIMENSION,
        dimensionSubDimension: dimensionSubDimensionList,
        programID: programID,
      });

      dispatch({
        type: SET_CLICKED_PANEL,
        panelClicked: null,
        panelIsClicked: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const SetGlobalURLParam = (urlParam) => async (dispatch, state) => {
  dispatch({
    type: SET_GLOBAL_URL_PARAM,
    globalUrlparam: urlParam,
  });
};
