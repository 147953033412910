import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import CommentsIcon from "../../../../Static/images/svg/CommentsIcon.svg"
import { FormDateDDMMMYYY, FormTimeAPM } from "../../../../Utilities/Formatter";
import { IsValidText } from "../../../../Utilities/Validations";

require("./CommentaryModal.css");

const CommentaryModal = (function () {
	class CommentaryModalPresentational extends Component {
		render() {
			let boiItem = this.props.commentBOIDetails;
			let commentList = boiItem.RVDCommentHistory;

			return (
				<Modal
					show={this.props.show}
					onHide={() => { }}
					size={this.props.size}
					centered
					dialogClassName="rvd-commentary-modal"
				>
					<Modal.Header>
						<Modal.Title>
							{!this.props.isEdit ?
								<div className="header24-semibold-midnight">
									BOI Comment ({commentList.length})
								</div>
								:
								<div className="comment-edit-header">
									<FontAwesomeIcon icon={faChevronLeft} className="back-icon" onClick={() => this.props.handleIsEdit(false, "", 0, "")} />
									<div className="header24-semibold-midnight">
										Edit BOI Comment
									</div>
								</div>
							}
							<GrooveIconSvg
								customClassName="close-modal-btn"
								size="large"
								name="times"
								iconStyle="solid"
								iconColor="primary-acn"
								callbackFunction={() => this.props.onCloseModal()}
							/>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<div className="commentary-boidetails-container">
								<div className="commentary-boi-details">
									<div>
										<div>BOI Name</div>
										<div>{boiItem.boiName}</div>
									</div>
									<div>
										<div>Target Value</div>
										<div>{boiItem.boiTargetApproved !== null ? boiItem.boiTargetApproved + " " + boiItem.boiUOM : "--"}</div>
									</div>
								</div>
								<div className="commentary-boi-details">
									<div>
										<div>Realized Value</div>
										<div>{boiItem.boiRealizedValue !== null ? boiItem.boiRealizedValue + " " + boiItem.boiUOM : "--"}</div>
									</div>
									<div>
										<div>Realized Value - Target Value</div>
										<div>{boiItem.boiValueDiff !== null ? boiItem.boiValueDiff + " " + boiItem.boiUOM : "--"}</div>
									</div>
								</div>
							</div>
							<div className="commentary-comments">
								{commentList.length > 0 ?
									!this.props.isEdit ?
										commentList.map((item, index) => {

											let createdTS = item.CreatedTS
											let fullDate = FormDateDDMMMYYY(createdTS, !(item.Id === 0)) + ' | ' + FormTimeAPM(createdTS, !(item.Id === 0))

											return (
												<div accessKey={index}>
													<div>
														<div>
															<GrooveIconSvg
																size="large"
																name="user-circle"
																iconStyle="regular"
																iconColor="stat-alternate"
																callbackFunction={() => ""}
															/>
														</div>
														<div>
															<div>{item.CreatedUser}</div>
															<div>{item.IsEdited ? <span><span style={{ 'color': '#a100ff' }}>Edited</span> | </span> : ""} {fullDate}</div>
														</div>
													</div>
													<div className="commentary-comment">
														<div>
															<span>{item.Comment}</span>
															{(this.props.enterpriseID === item.CreatedUser && index === 0) && (
																<span onClick={() => this.props.handleIsEdit(true, item.Comment, item, fullDate)}>
																	<GrooveIconSvg
																		id="calendarIcon"
																		customClassName={"calendar-icon-dropdown"}
																		size="small"
																		name="edit"
																		iconStyle="solid"
																		iconColor="stat-alternate"
																	/>
																	Edit
																</span>
															)}
														</div>
													</div>
												</div>
											)
										})
										:
										<div>
											<div>
												<div>
													<GrooveIconSvg
														size="large"
														name="user-circle"
														iconStyle="regular"
														iconColor="stat-alternate"
														callbackFunction={() => ""}
													/>
												</div>
												<div>
													<div>{this.props.commentToUpdate.CreatedUser}</div>
													<div>{this.props.commentToUpdate.IsEdited ? <span><span style={{ 'color': '#a100ff' }}>Edited</span> | </span> : ""} {this.props.fullDate}</div>
												</div>
											</div>
											<div className="commentary-update-comment">
												<input
													id="addComment"
													name="addComment"
													placeholder="Add Comment"
													type="text"
													onChange={(e) => this.props.handleComment(e.target.value)}
													value={this.props.comment}
													maxLength={150}
													className={`${this.props.invalidComment ? "commentary-add-comment-invalid" : ""}`}
												/>
											</div>
										</div>
									:
									<div className="commentary-no-comment">
										<img src={CommentsIcon} alt="CommentsIcon" />
										<div style={{ 'fontWeight': '600', 'paddingBottom': '10px', 'paddingTop': '3px' }}>No comment(s) available.</div>
										<div>Please Add Comment for this BOI using</div>
										<div>the input field below.</div>
									</div>
								}
							</div>
							<div>
								{!this.props.isEdit ?
									< div className="commentary-add-comments">
										<GrooveIconSvg
											size="large"
											name="user-circle"
											iconStyle="regular"
											iconColor="stat-alternate"
											callbackFunction={() => ""}
										/>
										<input
											id="addComment"
											name="addComment"
											placeholder="Add Comment"
											type="text"
											onChange={(e) => this.props.handleComment(e.target.value)}
											value={this.props.comment}
											maxLength={150}
											className={`${this.props.invalidComment ? "commentary-add-comment-invalid" : ""}`}
										/>
										<GrooveIconSvg
											customClassName={this.props.comment.length === 0 || this.props.invalidComment ? "commentary-send-icon send-disabled" : "commentary-send-icon"}
											size="medium"
											name="paper-plane"
											iconStyle="regular"
											iconColor="stat-alternate"
											callbackFunction={() => this.props.handleSendComment()}
										/>
									</div>
									:
									<div className="update-comments-buttons">
										<GrooveButton
											id="primry-btn-1"
											name="Solid Button Primary-Ops"
											isDisabled={false}
											hasIcon={false}
											type="outline"
											colorClass="stat-alternate"
											size="auto"
											text="Cancel"
											callbackFunction={() => this.props.handleIsEdit(false, "", 0, "")}
										/>
										<GrooveButton
											id="primry-btn-1"
											name="Solid Button Primary-Ops"
											isDisabled={this.props.isDisabled || this.props.invalidComment}
											hasIcon={false}
											type="solid"
											colorClass="stat-alternate"
											size="auto"
											text="Update"
											callbackFunction={() => this.props.handleUpdateComment()}
										/>
									</div>
								}
							</div>
						</div>
					</Modal.Body>
				</Modal >
			);
		}
	}

	class CommentaryModalContainer extends Component {
		constructor(props) {
			super(props);
			this.state = {
				comment: "",
				commentList: [],
				isEdit: false,
				commentToUpdate: {},
				fullDate: "",
				invalidComment: false,
			};
		}

		componentDidMount = async () => {
			let commentList = this.props.commentBOIDetails.RVDCommentHistory;
			this.setState({ commentList: commentList })
		}

		handleComment = (comment) => {
			this.setState({ comment: comment })
			if (!IsValidText(comment)) {
				this.setState({ invalidComment: true })
			}else {
				this.setState({ invalidComment: false })
			}
		}

		handleIsEdit = (isEdit, comment, item, fullDate) => {
			this.setState({
				isEdit: isEdit,
				comment: comment,
				commentToUpdate: item,
				fullDate: fullDate,
				invalidComment: false
			})
		}

		handleUpdateComment = () => {
			let commentList = this.state.commentList;
			const cL = commentList.filter(item => this.state.commentToUpdate.Id === 0 ? item.tempID === this.state.commentToUpdate.tempID : item.Id === this.state.commentToUpdate.Id);
			cL[0].Comment = this.state.comment;
			cL[0].IsEdited = true;
			this.setState({
				commentList,
				isEdit: false,
				comment: ""
			})
		}

		handleSendComment = () => {
			let commentList = this.state.commentList;
			let d = new Date
			let dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/') + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');

			commentList.unshift({
				"Id": 0,
				"Comment": this.state.comment,
				"IsEdited": false,
				"CreatedUser": this.props.enterpriseID,
				"CreatedTS": dformat,
				"tempID": Math.floor(Math.random() * 100)
			})

			if (!this.props.editedAnyFieldOnce && this.props.overallStatus === 'Approved') {
				this.props.checkForInvalidEmailsOnLanding();
				this.props.setEditedAnyFieldOnce(true);
			}

			this.setState({
				commentList: commentList,
				comment: ""
			})
		}

		onCloseModal = () => {
			const isEdit = this.state.isEdit
			if (isEdit) {
				this.handleIsEdit(false, "", 0, "")
			} else {
				this.props.onCloseModal(false, [])
			}
		}

		render() {
			return (
				<CommentaryModalPresentational
					show={this.props.show}
					title={this.props.title}
					size={this.props.size}
					content={this.props.content}
					onCloseModal={this.onCloseModal}
					customclass={this.props.customclass}
					comment={this.state.comment}
					handleComment={this.handleComment}
					commentBOIDetails={this.props.commentBOIDetails}
					commentList={this.state.commentList}
					isEdit={this.state.isEdit}
					handleIsEdit={this.handleIsEdit}
					handleSendComment={this.handleSendComment}
					handleUpdateComment={this.handleUpdateComment}
					commentToUpdate={this.state.commentToUpdate}
					fullDate={this.state.fullDate}
					enterpriseID={this.props.enterpriseID}
					invalidComment={this.state.invalidComment}
				/>
			);
		}
	}

	return CommentaryModalContainer;
})();

export default CommentaryModal;
