import React, { Component } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Legend, Tooltip } from 'recharts';

const DashboardTrendChart = (function () {
  class _DashboardTrendChart extends Component {
    constructor() {
      super();
      this.state = {
        boiTrendData: [],
        unit: '',
        reportingYear: [],
      };
    }

    componentDidMount() {
      this.formatTrendData(this.props.trendGraphList);
    }

    componentDidUpdate(prevProps) {
      if (JSON.stringify(this.props.trendGraphList) !== JSON.stringify(prevProps.trendGraphList)) {
        this.formatTrendData(this.props.trendGraphList);
      }
    }

    formatTrendData = (trendGraphList) => {
      let boiTrendData = trendGraphList;
      let uom = this.props.unit;
      let tempTrendData = [];
      // let minMaxYear = [];
      let reportingYear = [];

      if (boiTrendData !== null && boiTrendData !== undefined) {
        boiTrendData.sort(function (a, b) {
          return a['ReportingYear'] - b['ReportingYear'];
        });

        boiTrendData.map((trendData) => {
          let reportYear = trendData.ReportingYear % 100;
          reportingYear.push(reportYear);
          tempTrendData.push({
            reportingYear: reportYear,
            identified: parseFloat(trendData.IdentifiedValue),
            delivered: parseFloat(trendData.DeliveredValue),
          });
        });
      }

      this.setState({
        boiTrendData: tempTrendData,
        unit: uom,
        reportingYear: reportingYear,
      });
    };

    DataFormater = (n) => {
      var ranges = [
        { divider: 1e12, suffix: 'T' },
        { divider: 1e9, suffix: 'B' },
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'K' },
      ];
      if (n.toString() !== '-Infinity') {
        for (var i = 0; i < ranges.length; i++) {
          if (n >= ranges[i].divider) {
            let tempRange =
              (n / ranges[i].divider).toString().split('.').length >= 2
                ? (n / ranges[i].divider).toFixed(0)
                : n / ranges[i].divider;
            return tempRange.toString() + ranges[i].suffix;
          }
        }
      }
      if (n < 1 && n > 0) {
        return "";
      } else {
        return n;
      }
    };

    computeMax = (dataMax) => {
      let max = Math.ceil(dataMax / 4 + dataMax);

      if (dataMax.toString() !== '-Infinity') {
        if (max <= 40) {
          while (max % 4 !== 0) {
            max++;
          }
        } else {
          let minRange = 4;
          let maxRange = 4 * 10;
          while (max < minRange || max > maxRange) {
            minRange = minRange * 10;
            maxRange = maxRange * 10;
          }
          max = max + (minRange - (max % minRange));

          var ranges = [
            { divider: 1e12, suffix: 'T' },
            { divider: 1e9, suffix: 'B' },
            { divider: 1e6, suffix: 'M' },
            { divider: 1e3, suffix: 'K' },
          ];
          if (max.toString() !== '-Infinity') {
            for (var i = 0; i < ranges.length; i++) {
              if (max >= ranges[i].divider) {
                let dividedMax = Math.ceil(max / ranges[i].divider);
                // If formatted maximum is single digit, between 2 and 4, 
                // set to next digit to allow ticks to be divisible by 4
                let tempMax = dividedMax.toString().length === 1 ? 
                  dividedMax >= 2 && dividedMax <= 4 ? 4 * ranges[i].divider : max 
                  : max;
                max = tempMax;
              }
            }
          }

        }
      } else {
        max = 0;
      }
      return max;
    };

    render() {
      return (
        <ResponsiveContainer id="program-trend-chart">
          <LineChart
            width={730}
            height={250}
            data={this.state.boiTrendData}
            margin={{ top: 10, right: 10, left: 0, bottom: 5 }}
            cursor='pointer'
          >
            <XAxis
              dataKey="reportingYear"
              tickLine={false}
              tick={{ fontSize: 10 }}
              domain={[...this.state.reportingYear]}
              ticks={[...this.state.reportingYear]}
              tickFormatter={(value) => {
                return 'FY' + value;
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 9, fill: 'rgb(145, 158, 171)' }}
              width={40}
              type="number"
              domain={[
                0,
                (dataMax) => {
                  let val;
                  dataMax === Infinity || dataMax === -Infinity ? val = 0.1 : val = this.computeMax(dataMax);
                  return val;
                },
              ]}
              allowDecimals={true}
              interval={0}
              tickFormatter={this.DataFormater}
            />
            <Tooltip content={<CustomTooltip uom={this.state.unit}/>} />
            <Legend iconType="square" iconSize={8} align="center" />
            <Line name="Target" dataKey="identified" stroke="#f49342" dot={{ fill: '#f49342' }} />
            <Line name="Delivered" dataKey="delivered" stroke="#26c1c9" dot={{ fill: '#26c1c9' }} />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  }

  class CustomTooltip extends Component {
    constructor() {
      super();
    }

    DataFormater = (n) => {
      var ranges = [
        { divider: 1e12, suffix: 'T' },
        { divider: 1e9, suffix: 'B' },
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'K' },
      ];
      if (n.toString() !== '-Infinity') {
        for (var i = 0; i < ranges.length; i++) {
          if (n >= ranges[i].divider) {
            let tempRange =
              (n / ranges[i].divider).toString().split('.').length >= 2
                ? (n / ranges[i].divider).toFixed(2)
                : n / ranges[i].divider;
            return tempRange.toString() + ' ' + ranges[i].suffix;
          }
        }
      }
      return n;
    };

    render() {
      if (this.props.active) {
        return (
          <div className="custom-tooltip">
            <div className="global-legend-list">
              <div className="global-legend-list-item">
                <span className="dot-square-palette-amber"></span>
                {/* Target |
                {' '}{String(this.props.payload[0].payload.identified) !== 'NaN' ?
                  this.DataFormater(this.props.payload[0].payload.identified) + ' ' + this.props.uom : ''} */}
                <div class="custom-tooltip-row-container">
                  <div class="custom-tooltip-row-label">Target</div>
                  <div class="custom-tooltip-row-value">
                    |  {String(this.props.payload[0].payload.identified) !== 'NaN' ?
                      this.DataFormater(this.props.payload[0].payload.identified) + ' ' + this.props.uom : ''}
                  </div>
                </div>
              </div>
              <div className="global-legend-list-item">
                <span className="dot-square-palette-aqua"></span>
                {/* Delivered |
                {' '}{String(this.props.payload[1].payload.delivered) !== 'NaN' ?
                  this.DataFormater(this.props.payload[1].payload.delivered) + ' ' + this.props.uom : ''} */}
                <div class="custom-tooltip-row-container">
                    <div class="custom-tooltip-row-label">Delivered</div>
                    <div class="custom-tooltip-row-value">
                      |  {String(this.props.payload[1].payload.delivered) !== 'NaN' ?
                        this.DataFormater(this.props.payload[1].payload.delivered) + ' ' + this.props.uom : ''}
                    </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
  }

  return _DashboardTrendChart;
})();

export default DashboardTrendChart;
