import React, { Component } from 'react';
// import { history } from '../../store';
import { withRouter } from "react-router-dom";
import accenture_logo from '../../Static/images/AccentureLogoPurple.png';
import { Row, Col} from 'react-bootstrap';

const Footer = (function () {
  class _Footer extends Component {
    constructor() {
      super();
      this.state = {
        showModal: false,
        modal_message: '',
        urlPath: '',
        modalID: ''
      };
    }

    showTerms = () => {
      this.props.history.push('/TermsAndAgreement');
    };

    goTo = path => {
      this.props.history.push(path);
    };

    render() {
      return (
        <div id="footer_container" className="footer">
          <Row>
            <Col xs={6} sm={3} md={2} lg={2} xl={1} id="footer-logo"><img alt="" id="accenture_logo" src={accenture_logo} /></Col>
            <Col xs={12} sm={6} md={7} lg={6} xl={5} id="company-copyright"><div className="trademark">© 2021 Accenture. All Rights Reserved.</div></Col>
            <Col xs={6} sm={3} md={3} lg={4} xl={6} id="footer-terms"> 
              <div className="footer-links-container">
                <div className="footer-links" onClick={() => this.goTo('/TermsAndAgreement')}>
                  Terms of Use
                </div>
                {/* <div
                  className="footer-links"
                  onClick={() => this.goTo("/PrivacyStatement")}
                >
                  Privacy Statement
                </div>
                <div className="footer-links">
                  <a href="mailto:CVM.Admin@accenture.com">Contact Support</a>
                  Contact Support
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }
  return _Footer;
})();
export default withRouter(Footer)