import {
  SET_NOTIF_LIST,
  GET_NOTIF_HISTORY_LIST,
  GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS,
  GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS,
  GET_NOTIF_HISTORY_COUNT,
  SET_NEW_NOTIF_LIST,
  SET_OLD_NOTIF_LIST,
  SET_NEWLY_ADDED_NOTIF,
  SET_NOTIF_COUNT,
  SHOULD_NOTIF_HIST_SHOW,
  GET_REMINDER_COUNT
} from '../Constants';

const initialState = {
  notificationList: [],
  notifHistoryList: [],
  senderFilterOptions: [],
  clientNameFilterOptions: [],
  notifHistoryCount: 0,
  notifHistTotalPage: 0,
  NewInAppNotifications: [],
  OldInAppNotifications: [],
  newlyAddedList: [],
  notifCount: 0,
  showNotifHist: false,
  reminderCounter: 0,
};

export const NotificationData = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SET_NOTIF_LIST:
      return {
        ...state,
        notificationList: action.notificationList,
      };
    case GET_NOTIF_HISTORY_LIST:
      return {
        ...state,
        notifHistoryList: action.notifHistoryList,
      };
    case GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS:
      return {
        ...state,
        senderFilterOptions: action.senderFilterOptions,
      };
    case GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS:
      return {
        ...state,
        clientNameFilterOptions: action.clientNameFilterOptions,
      };
    case GET_NOTIF_HISTORY_COUNT:
      return {
        ...state,
        notifHistoryCount: action.notifHistoryCount,
        notifHistTotalPage: action.notifHistTotalPage,
      };
    case SET_NEW_NOTIF_LIST:
      return {
        ...state,
        NewInAppNotifications: action.NewInAppNotifications,
      };
    case SET_OLD_NOTIF_LIST:
      return {
        ...state,
        OldInAppNotifications: action.OldInAppNotifications,
      };
    case SET_NEWLY_ADDED_NOTIF:
      return {
        ...state,
        newlyAddedList: action.newlyAddedList,
      };
    case SET_NOTIF_COUNT:
      return {
        ...state,
        notifCount: action.notifCount,
      };
    case SHOULD_NOTIF_HIST_SHOW:
      return {
        ...state,
        showNotifHist: action.showNotifHist,
      };
    case GET_REMINDER_COUNT:
      return {
        ...state,
        reminderCounter: action.reminderCounter
      }
    default:
      return state;
  }
};
