//Common
import AccessDenied from "./AccessDenied";
import AccessRequest from "./AccessRequest";
import PageNotFound from "./PageNotFound";
import NotFound from "./NotFound";
import DisablePopupBlocker from "./DisablePopupBlocker"
import LoginError from "./LoginError";
import SomethingWentWrong from "./SomethingWentWrong";
import CVMTable from "./CVMTable/CVMTable";
require("./Shared.css");

export {
  PageNotFound,
  NotFound,
  AccessDenied,
  AccessRequest,
  LoginError,
  SomethingWentWrong,
  //360 CVM
  CVMTable,
  DisablePopupBlocker
};
