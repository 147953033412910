import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { GrooveSelectMenuBasic, GrooveButton, GrooveIconSvg } from "tfo-groove";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CVMMultiSelectDropdownWithCheckBoxForBA from '../../../Shared/CVMMultiSelectDropdownWithCheckBoxForBA/CVMMultiSelectDropdownWithCheckBoxForBA';
//actions
import {
  onSaveBusinessAdvisor,
} from '../../../../Actions/ConfigureProgram.Action';


const AddEditBusinessAdvisor = (function () {
  class AddEditBusinessAdvisorPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="add-edit-ba-container add-new-program-container">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <CVMMultiSelectDropdownWithCheckBoxForBA
                  label=""
                  id="addEditBaDropdown"
                  placeholder={"Select Business Advisor(s)"}
                  options={this.props.businessAdvisorList}
                  onChange={(e) => this.props.handleChangeBA(e)}
                  value={this.props.businessAdvisor}
                  customClassName={""}
                  shouldDisableOptions={true}
                />
              </Col>
            </Row>
          </div>
          <div className="add-program-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={() => {
                this.props.handleAddEditBA();
              }}
            />
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Save"
              callbackFunction={(e) => {
                this.props.handleSaveBA(e);
              }}
            />
          </div>
        </React.Fragment>
      );
    }
  }
  class AddEditBusinessAdvisorContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        businessAdvisor: []
      };
    }

    componentDidMount = async () => {
      this.setState({
        businessAdvisor: this.props.businessAdvisor
      })
    }

    handleChangeBA = (e) => {
      this.setState({
        businessAdvisor: e
      })
    }

    handleSaveBA = () => {
      this.props.handleAddEditBA("save", this.state.businessAdvisor);
    }


    render() {
      return (
        <AddEditBusinessAdvisorPresentational
          businessAdvisorList={this.props.businessAdvisorList}
          businessAdvisor={this.state.businessAdvisor}
          handleSaveBA={this.handleSaveBA}
          handleChangeBA={this.handleChangeBA}
          handleAddEditBA={this.props.handleAddEditBA} //close
        />
      );
    }
  }

  return AddEditBusinessAdvisorContainer;
})();


export default connect(
  (state) => {
    return {
      DataIngestionData: state.DataIngestionData,
      ConfigureProgramData: state.ConfigureProgramData,
      SharedData: state.SharedData,
      UserInformationData: state.userInformation,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          onSaveBusinessAdvisor,
        }
      ),
      dispatch
    )
)(AddEditBusinessAdvisor);