import React, { Component } from 'react';
// import { history } from "../../store";
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header } from '../MasterLayout';
import { DeleteCallbackURL, DeleteReferrer, DeleteDistiURL } from '../../Utilities/AccessManagementUtil';


const NotFound = (function () {
	class _NotFound extends Component {
		componentDidMount() {
			DeleteCallbackURL()
			DeleteReferrer()
			DeleteDistiURL()
		}
		goBack = (event) => {
			this.props.history.push('/');
		}

		render() {
			return (
				<div className="body_container">
					<Row className="page_notfound_container">
						<Col md={12} xs={12}>
							<FontAwesomeIcon icon={faFrown} fixedWidth />
							<div className="page_notfound_content">
								<h1>Page Not Found</h1>
								<p>Unreachable link. To continue, you can click below and return home.</p>
								<button className="page_notfound_btn" onClick={() => this.props.history.push("/")}>Return Home</button>
							</div>
						</Col>
					</Row>
				</div>
			);
		}
	}


	return _NotFound;
})();

export default withRouter(NotFound)