import React, { Component } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import { TryGetObjValueAlt } from '../../../Utilities/Formatter';
import addfiles from '../../../Static/images/360cvm/AddFilesIcon.svg';
import './DimensionAccordion.css';
import { HasValue, IsValid } from '../../../Utilities/Validations';
import DimensionTable from './DimensionTable';
import EmptyBOI from '../EmptyBOI';

const DimensionAccordion = (function () {
  class DimensionAccordionPresentational extends Component {
    constructor() {
      super();
      this.customeBOIMOdalShow = this.customeBOIMOdalShow.bind(this);
      this.onCustomBOIModalCancelClose = this.onCustomBOIModalCancelClose.bind(this);
      this.state = {
        isShow: false,
      };
    }

    customeBOIMOdalShow() {
      this.setState({
        isShow: true,
      });
    }
    onCustomBOIModalCancelClose = () => {
      this.setState({
        isShow: false,
      });
    };

    render() {
      let isApprovedPanelEmpty = false;
      let isNewPanelEmpty = false;
      this.props.dimensionList.forEach(d =>
        d.subDimensionList.forEach(s =>
          s.boiList.forEach(b => {
            if (!b.isModified) (isApprovedPanelEmpty = true)
            if (b.isModified || !IsValid(b.boiStatus)) (isNewPanelEmpty = true)
          })))
      let headers =
        (['awaiting approval', 'awaiting approval – modified'].indexOf(
          TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
        ) === -1 &&
          this.props.userType === 'admin') ||
          this.props.userType === 'ppcUser'
          ? ['rejected', 'approved'].indexOf(
            TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
          ) > -1 ?
            // rejected and approved
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Status',
              'Dashboard Visibility',
              '',
            ]
            : // In progress, awaiting approval, awaiting approval – modified
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Dashboard Visibility',
              '',
            ]
          :
          ['rejected', 'approved'].indexOf(
            TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
          ) > -1 ?
            // rejected and approved
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Status',
              'Dashboard Visibility',
            ]
            :
            // approver view
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Dashboard Visibility',
            ];

      let statusLeft = this.props.userType === 'admin' || this.props.userType === 'ppcUser' ? '-3px' : '-9px'
      if (['review', 'edit', 'view'].indexOf(this.props.mode) > -1) headers.splice(5, 1);
      const formCB = this.props.formCallback;
      const dimList = this.props.dimensionList;
      return (!HasValue(dimList) || dimList.length === 0) ? (
        <div className="empty-dimension-container">
          <div className="empty-program-container bodytext18-regular-slate">
            <img src={addfiles} alt="select client" />
            <span className="bodytext24-medium-slate">No Business Outcome Indicator Added</span>

            {this.props.origin === 'editConditional'
              ? ''
              : this.props.type === 'Program'
                ? this.props.origin === 'editRejected'
                  ? "Please select BOI(s) to this program by using 'Edit BOIs' button above."
                  : "Please add BOI(s) to this program by using 'Add BOIs' button above."
                : "Please select BOI(s) to this project by using the 'Select BOIs' button above."}
          </div>
        </div>
      ) : (
        <div className='md-stepper-horizontal'>
          <div className="prog-acct-table-header-container">
            <div class="table-title">{this.props.topLeftContainer}</div>
            <div className="accordion-action-btns">
              {this.props.additionalElement}
              <GrooveButton
                id={'expandAll'}
                hasIcon={false}
                type=""
                colorClass=""
                text="Expand All"
                customClassName={
                  this.props.activeExpand ? 'expandAll-button active' : 'expandAll-button'
                }
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
              />
              <GrooveButton
                id={'collapseAll'}
                hasIcon={false}
                type=""
                colorClass=""
                text="Collapse All"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
                customClassName={
                  this.props.activeCollapse ? 'collapseAll-button active' : 'collapseAll-button'
                }
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
              />
              {this.props.topRightContainer}
            </div>
          </div>

          {this.props.selectedProgramDetails.IsEdited &&
            this.props.type === 'Program' &&
            this.props.componentType === 'review' ?
            <>
              <Accordion
                defaultActiveKey={'0'}
                className="dimension-table-collapse categorization-table-collapse"
                onSelect={(e) => {
                  this.props.onSelectAccordion(e);
                }}
              >
                <Card>
                  <Accordion.Collapse eventKey={'0'}>
                    <Card.Body>
                      {!isNewPanelEmpty ?
                        <EmptyBOI type="program" isApprovedProgram={true} />
                        :
                        <DimensionTable
                          dimensionList={this.props.dimensionList}
                          dimensionName={this.props.dimensionName}
                          subDimensionName={this.props.subDimensionName}
                          boi={this.props.boi}
                          isApproverEditView={this.props.isApproverEditView}
                          userType={this.props.userType}
                          showCustomBOI={this.props.showCustomBOI}
                          status={this.props.status}
                          isSuperAdmin={this.props.isSuperAdmin}
                          basicDetails={this.props.basicDetails}
                          type={this.props.type}
                          mode={this.props.mode}
                          formCallback={this.props.formCallback}
                          showMessage={this.props.showMessage}
                          onClickCustomBOI={this.props.onClickCustomBOI}
                          newBOIs={true}
                          listenToScroll={this.props.listenToScroll}
                          selectedProjectStatus ={this.props.selectedProjectStatus}
                        />
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header className="dim-card-header">
                    <div className='dimension-header-title'>New BOIs</div>
                    <Accordion.Toggle
                      id="dim-btn"
                      className="dim-acc-btn"
                      as={Button}
                      variant="link"
                      eventKey={'0'}
                      onClick={(e) => this.props.onClickAccordion(e, 'btn')}
                    >
                      <GrooveIconSvg
                        id="basicDetailsIcon"
                        customClassName="accordion-button"
                        size="large"
                        name="chevron-right"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              </Accordion>

              <Accordion
                className="dimension-table-collapse categorization-table-collapse"
                onSelect={(e) => {
                  this.props.onSelectAccordion(e);
                }}
              >
                <Card>
                  <Accordion.Collapse eventKey={'1'}>
                    <Card.Body>
                      {!isApprovedPanelEmpty ?
                        <EmptyBOI type="program" isApprovedProgram={true} />
                        :
                        <DimensionTable
                          dimensionList={this.props.dimensionList}
                          dimensionName={this.props.dimensionName}
                          subDimensionName={this.props.subDimensionName}
                          boi={this.props.boi}
                          isSuperAdmin={this.props.isSuperAdmin}
                          basicDetails={this.props.basicDetails}
                          isApproverEditView={this.props.isApproverEditView}
                          userType={this.props.userType}
                          status={this.props.status}
                          type={this.props.type}
                          mode={this.props.mode}
                          formCallback={this.props.formCallback}
                          showMessage={this.props.showMessage}
                          showCustomBOI={this.props.showCustomBOI}
                          onClickCustomBOI={this.props.onClickCustomBOI}
                          newBOIs={false}
                          listenToScroll={this.props.listenToScroll}
                          selectedProjectStatus ={this.props.selectedProjectStatus}
                        />
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header className="dim-card-header">
                    <div className='dimension-header-title'>Approved BOIs</div>
                    <Accordion.Toggle
                      id="dim-btn"
                      className="dim-acc-btn"
                      as={Button}
                      variant="link"
                      eventKey={'1'}
                      onClick={(e) => this.props.onClickAccordion(e, 'btn')}
                    >
                      <GrooveIconSvg
                        id="basicDetailsIcon"
                        customClassName="accordion-button"
                        size="large"
                        name="chevron-right"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              </Accordion>
            </>
            :
            <DimensionTable
              dimensionList={this.props.dimensionList}
              dimensionName={this.props.dimensionName}
              subDimensionName={this.props.subDimensionName}
              boi={this.props.boi}
              isApproverEditView={this.props.isApproverEditView}
              userType={this.props.userType}
              status={this.props.status}
              type={this.props.type}
              mode={this.props.mode}
              isSuperAdmin={this.props.isSuperAdmin}
              basicDetails={this.props.basicDetails}
              formCallback={this.props.formCallback}
              showMessage={this.props.showMessage}
              onClickCustomBOI={this.props.onClickCustomBOI}
              showCustomBOI={this.props.showCustomBOI}
              newBOIs={''}
              listenToScroll={this.props.listenToScroll}
              selectedProjectStatus ={this.props.selectedProjectStatus}
            />
          }
        </div >
      );
    }
  }

  class ModalContent extends Component {
    render() {
      return (
        <div className='reject-msg-on-toggle'>
          <p>
            <GrooveIconSvg
              size="large"
              name="exclamation-circle"
              iconStyle="solid"
              iconColor="stat-alternate"
              className="reject-BOI-msg"
            />
          </p>
          <p className="header">Change wheel visibility?</p>
          <p className="body-msg">You need to Approve or Conditionally Approve the BOI inorder to perform this action</p>
        </div>
      )
    }
  }

  class DimensionAccordionContainer extends Component {
    constructor() {
      super();
      this.state = {
        activeExpand: false,
        activeCollapse: false,
        accordionCount: 0,
        onLoadExpand: false,
        isCollapsed: false,
        showCustomBOI: false,
        customDetails: [],
        dimensionName: '',
        subDimensionName: '',
        boi: {
          ClientName: '',
          offeringName: '',
          boiName: '',
          uom: '',
          description: '',
          LeadingIndicator: '',
          comment: '',
          MetricCalculation: '',
          customParameterList: [],
          AggregationLogic: '',
          SubOffering: '',
          AggregationLogicId: 0,
          MetricReason: '',
          AggregateReason: '',
          WheelVisibility: 0,
        },
        showEditToggle: false,
        toggleId: '',
        params: {
          dimI: 0,
          subDI: 0,
          boiI: 0,
          value: null
        }
      };
    }

    componentDidUpdate = (prevProps) => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        //this.onExpandCollapse(null, 'onload');
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
      if (this.state.activeExpand === false) {
        setTimeout(this.props.listenToScroll, 200);
      }
    };

    componentDidMount = () => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        //this.onExpandCollapse(null, 'onload');
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
    };

    onExpandCollapse = (e, action) => {
      try {
        let elems = [];
        let isFirstStage = window.location.pathname.toLowerCase().includes('idvaluesettings')
          ? true
          : false;
        let caseString =
          action !== undefined && action === 'onload'
            ? 'expandAll'
            : e.currentTarget.id.includes('expandAll')
              ? 'expandAll'
              : 'collapseAll';
        switch (caseString) {
          case 'expandAll':
            elems = document.getElementsByClassName('collapse');
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === 'collapse') {
                  document
                    .getElementsByClassName('collapse')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === 'collapse') {
                  document
                    .getElementsByClassName('collapse')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: true,
              activeCollapse: false,
            });
            break;
          case 'collapseAll':
            elems = document.getElementsByClassName('collapse show');
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === 'collapse show') {
                  document
                    .getElementsByClassName('collapse show')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === 'collapse show') {
                  document
                    .getElementsByClassName('collapse show')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: false,
              activeCollapse: true,
            });
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
    };

    onClickCustomBOI = async (boiId, isOpen, dimIdx, sdIdx, boiIdx) => {
      let programId = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
      const isNew = boiId > 0 && programId !== undefined ? false : true;
      let isProject = this.props.type.toLowerCase() === 'project';
      this.setState({
        showCustomBOI: !this.state.showCustomBOI,
      });
      try {
        if (isOpen && isProject) await this.props.getCustomBOIDetails(programId, boiId);
        const data = isOpen && isProject ? this.props.customBOI : this.props.dimensionList;
        const hasData = Object.keys(data).length > 0 ? true : false;

        if (isOpen && hasData) {
          const subDimensionName = isProject
            ? data[0].SubDimensionName
            : data[dimIdx].subDimensionList[sdIdx].subDimensionName;
          const boi = isProject ? data[0] : data[dimIdx].subDimensionList[sdIdx].boiList[boiIdx];
          const newBoi = {
            ClientName: isProject
              ? boi.ClientName
              : this.props.ProgramConfigurationData.programClientDetails.ClientMasterName,
            offeringName: isProject
              ? boi.Offering
              : this.props.ProgramConfigurationData.newProgramDetails.offering.label,
            boiName: isProject ? boi.BOIName : boi.boiName,
            uom: isProject ? boi.UOM : boi.uom,
            description: isProject ? boi.BOIDescription : boi.boiDescription,
            LeadingIndicator: isProject ? boi.LeadingIndicator : boi.leadingIndicator,
            comment: isProject ? boi.BOIAdditionComment : boi.boiAdditionComment,
            MetricCalculation: isProject ? boi.MetricCalculation : boi.boiDefinition,
            newSubDimensionName: isProject ? boi.SubDimensionName : boi.newSubDimensionName,
            customParameterList: hasData
              ? isProject
                ? boi.CustomParameterList.map((x, i) => {
                  return {
                    ParameterCustomCode: x.ParameterName,
                    ParameterUOM: x.ParameterUOM,
                  };
                })
                : boi.customParameterList.map((x, i) => {
                  return {
                    ParameterCustomCode: x.ParameterName,
                    ParameterUOM: x.ParameterUOM,
                  };
                })
              : [],
            AggregationLogic: isProject ? boi.AggregationLogic : boi.aggregationLogic,
            SubOffering: isProject ? boi.SubOfferingName : boi.suboffering,
            AggregationLogicId: isProject
              ? boi.AggregationLogicMasterID
              : boi.aggregationLogicMasterID, //why boi.boiDefinitionMasterID if program ? changed it to boi.aggregationLogicMasterID
            MetricReason: isProject ? boi.ReasonForNoBOIDefinition : boi.reasonForNoBOIDefinition,
            AggregateReason: isProject
              ? boi.ReasonForNoAggregationLogic
              : boi.reasonForNoAggregationLogic,
            WheelVisibility: isProject ? boi.boiWheelVisibility : boi.boiWheelVisibility,
          };

          this.setState({
            dimensionName: isProject ? data[0].CoreDimensionName : data[dimIdx].dimensionName,
            subDimensionName: subDimensionName,
            boi: newBoi,
          });
        } else {
          this.setState({
            dimensionName: '',
            subDimensionName: '',
            boi: {
              ClientName: '',
              offeringName: '',
              boiName: '',
              uom: '',
              description: '',
              LeadingIndicator: '',
              comment: '',
              MetricCalculation: '',
              customParameterList: [],
              AggregationLogic: '',
              SubOffering: '',
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    onSelectAccordion = (e) => {
      try {
        let accordionCount = this.state.accordionCount;
        let activeCollapse = false; //accordion close
        let activeExpand = false; //accordion open

        let dimensionList =
          this.props.dimensionList !== undefined
            ? this.props.dimensionList
            : this.state.dimensionList; //change
        if (e === null) {
          accordionCount -= 1;
        } else {
          accordionCount += 1;
        }

        if (dimensionList.length === accordionCount) {
          activeExpand = true;
          activeCollapse = false;
        } else if (accordionCount === 0) {
          activeCollapse = true;
          activeExpand = false;
        }

        this.setState({
          accordionCount: accordionCount,
          activeExpand: activeExpand,
          activeCollapse: activeCollapse,
        });
      } catch (e) {
        console.log(e);
      }
    };

    formCallback = (type, dimI, subDI, boiI, e) => {
      try {
        let tBOI = this.props.dimensionList[dimI].subDimensionList[subDI].boiList[boiI];
        let uom = tBOI.uom;
        let params = {
          type: type,
          dimIndex: dimI,
          subDIndex: subDI,
          boiIndex: boiI,
          e: e
        }
        let status = '';
        switch (type) {
          case 'comment':
            tBOI.comment = e.target.value;
            delete tBOI.inValidComment;
            break;
          case 'status':
            tBOI.boiStatus = e;
            status = 'Rejected' ? 'Rejected' : '';

            if (this.props.type === 'Program') {
              tBOI.boiWheelVisibility = e.toLowerCase() === 'rejected' ? false : tBOI.boiWheelVisibility;
            }

            if (this.props.type.toLowerCase() === 'project') {
              if (tBOI.programBOIStatus.toLowerCase() !== 'rejected') {
                tBOI.boiWheelVisibility = tBOI.programWheelVisibility && e.toLowerCase() !== 'rejected' ? true : false
              } else {
                tBOI.boiWheelVisibility = e.toLowerCase() === 'rejected' ? false : !(tBOI.programBOIStatus.toLowerCase() === "rejected");
              }
            }
            break;
          case 'parameter':
            tBOI.paramValue = e.value;
            if (e.inValidMsg.length > 0) {
              tBOI.inValidMsg = e.inValidMsg;
            } else {
              delete tBOI.inValidMsg;
            }
            delete tBOI.inValidParam;
            break;
          case 'wheel':
            tBOI.boiWheelVisibility = e.value;
            break;
          default:
            break;
        }
        let tDimFinal = this.props.dimensionList;
        tDimFinal[dimI].subDimensionList[subDI].boiList[boiI] = tBOI;

        if (status === 'Rejected') {
          tDimFinal[dimI].dimensionStatus = status;
          tDimFinal[dimI].subDimensionList[subDI].subDimensionStatus = status;
        }
        this.props.formCallback(tDimFinal, params);
      } catch (e) { }
    };

    onClickAccordion = (e, action) => {
      let isCurrentlyCollapsed =
        e.currentTarget.parentElement.previousSibling.className === 'collapse' ? true : false;
      if (action === 'div' && isCurrentlyCollapsed) {
        e.currentTarget.nextSibling.click();
        e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
      } else {
        //btn
        if (!isCurrentlyCollapsed) {
          e.currentTarget.parentElement.parentElement.style.cursor = 'pointer';
        } else {
          e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
        }
      }
    };

    onCloseModal = () => {
      const toggleId = this.state.toggleId

      this.setState({
        showEditToggle: false
      }, () => {
        // document.getElementById(toggleId).checked = false
      })
    }

    showMessage = async (toggleId, dimI, subDI, boiI, e) => {
      e.value = false

      this.setState({
        toggleId: toggleId,
        showEditToggle: true
      }, () => {
        this.formCallback('wheel', dimI, subDI, boiI, e)
      })
    }

    render() {
      const { selectedProgramDetails } = this.props.ProgramConfigurationData;    
      return (
        <DimensionAccordionPresentational
          dimensionList={this.props.dimensionList}
          onSelectAccordion={this.onSelectAccordion}
          mode={this.props.mode}
          onExpandCollapse={this.onExpandCollapse}
          topLeftContainer={this.props.topLeftContainer}
          topRightContainer={this.props.topRightContainer}
          isSuperAdmin={this.props.isSuperAdmin}
          basicDetails={this.props.basicDetails}
          formCallback={this.formCallback}
          activeExpand={this.state.activeExpand}
          activeCollapse={this.state.activeCollapse}
          onClickAccordion={this.onClickAccordion}
          isCollapsed={this.state.isCollapsed}
          origin={this.props.origin}
          type={this.props.type}
          onClickCustomBOI={this.onClickCustomBOI}
          showCustomBOI={this.state.showCustomBOI}
          subDimensionName={this.state.subDimensionName}
          dimensionName={this.state.dimensionName}
          boi={this.state.boi}
          additionalElement={this.props.additionalElement}
          userType={this.props.userType}
          status={this.props.status}
          isApproverEditView={this.props.isApproverEditView}
          showMessage={this.showMessage}
          showEditToggle={this.state.showEditToggle}
          onCloseModal={this.onCloseModal}
          selectedProgramDetails={selectedProgramDetails}
          componentType={this.props.componentType}
          selectedProjectStatus = {this.props.selectedProjectStatus}
        />
      );
    }
  }

  return DimensionAccordionContainer;
})();
export default DimensionAccordion;
