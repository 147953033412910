import React, { Component } from 'react';
import FileDownloadGrey from '../../../Static/images/360cvm/fileDownloadGrey.svg';
import FileDownload from '../../../Static/images/360cvm/fileDownload.svg';
import fileExcel from '../../../Static/images/360cvm/fileExcel.svg';
import './ClientFileItem.css';
import { HttpGetDownloadFile } from '../../../Utilities/HTTPUtil';
import { HasValue } from '../../../Utilities/Validations';
import { FormDateDDMMMYYY, FormTimeAPM, trimFieldForFileName } from '../../../Utilities/Formatter';
const ClientFileItem = (function () {
  class ClientStoryFileItemViewPresentational extends Component {
    render() {
      let fileDetails = this.props.fileDetails;
      let fileName =
        this.props.uploadType === 'clientStory'
          ? (fileDetails !== null) & (fileDetails !== undefined)
            ? this.props.recentlyRemoveUploadedId > 0
              ? this.props.recentFilename
              : fileDetails.FileName
            : this.props.recentFilename
          : (fileDetails !== null) & (fileDetails !== undefined)
          ? this.props.recentlyRemoveUploadedId -
              this.props.mainSelectedProject.projectDetails.recentlyUploadedClientAlignment
                .length !==
            0
            ? fileDetails.FileName
            : this.props.recentlyRemoveUploadedId === 0
            ? this.props.recentFilename
            : fileDetails.FileName
          : this.props.recentFilename;

      return (
        <div>
          <div className="client-story-recentFile">
            <div className="client-story-filename">
              <img className="download-icon-label" src={fileExcel} alt="FileDownload" />
              {fileName}
            </div>
            <div className="clientstory-download">
              {this.props.userIsApprover ? (
                <div>
                  {/* <label className="download-clientstory-label"></label> */}
                  <span
                    className="download-clientstory-button"
                    onClick={() => this.props.downloadFile(fileDetails)}
                  >
                    {' '}
                    {/* <span className="clientstory-button-text">Download</span> */}
                    Download
                  </span>
                </div>
              ) : (
                <div>
                  <span
                    className="download-clientstory-button"
                    onClick={() => this.props.onClickDownloadedFile(this.props.index, fileDetails)}
                  >
                    {' '}
                    View
                  </span>
                  <span
                    className="download-clientstory-button"
                    onClick={() => this.props.onDeleteUploaded(true, this.props.index, fileDetails)}
                  >
                    {' '}
                    Remove
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  class ClientStoryFileItemViewContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
        modalBody: '',
        closeModal: false,
        projectData: {},
        recentFile: {},
        recentFilename: '',
      };
    }

    componentDidMount() {
      let recentFile = this.props.Files;
      if (recentFile !== null && recentFile !== undefined) {
        if (HasValue(recentFile.file)) {
          if (recentFile.file.length > 0)
            this.setState({
              recentFile: recentFile,
              recentFilename: recentFile.file[this.props.index].name,
            });
        } else if (HasValue(recentFile)) {
          this.setState({
            recentFile: recentFile,
            recentFilename: recentFile.name,
          });
        }
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.Files !== this.state.recentFile) {
        let recentFile = this.props.Files;
        if (recentFile !== null && recentFile !== undefined) {
          if (HasValue(recentFile.file)) {
            if (recentFile.file.length > 0)
              this.setState({
                recentFile: recentFile,
                recentFilename: recentFile.file[this.props.index].name,
              });
          } else if (HasValue(recentFile)) {
            this.setState({
              recentFile: recentFile,
              recentFilename: recentFile.name,
            });
          }
        }
      }
    }

    render() {
      return (
        <>
          <ClientStoryFileItemViewPresentational
            Files={this.props.Files}
            userIsApprover={this.props.userIsApprover}
            downloadFile={this.props.downloadFile}
            fileDetails={this.props.fileDetails}
            Files={this.state.recentFile}
            recentFilename={this.state.recentFilename}
            onClickDownloadedFile={this.props.onClickDownloadedFile}
            onDeleteUploaded={this.props.onDeleteUploaded}
            recentlyRemoveUploadedId={this.props.recentlyRemoveUploadedId}
            index={this.props.index}
            mainSelectedProject={this.props.mainSelectedProject}
            uploadType={this.props.uploadType}
          />
        </>
      );
    }
  }

  return ClientStoryFileItemViewContainer;
})();

export default ClientFileItem;
