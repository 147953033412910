import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../../store';
import { GrooveIconSvg, GrooveInput, GrooveButton } from 'tfo-groove';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';

//actions
import {
  getNotificationHistoryData,
  getNotificationHistoryDataCount,
  getNotificationHistoryFilterOptions,
  updateFilterOptions,
  notifHistoryMarkAsRead,
} from '../../../Actions/Notifcation.Action';

//components
import CVMPagination from '../../Shared/CVMPagination/CVMPagination';
import FilterPopover from './FilterPopover';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import AddFileIcon from '../../../Static/images/360cvm/AddFilesIcon.svg';

//css
import './NotificationHistory.css';

const NotificationHistoryTable = (function () {
  class NotificationHistoryTablePresentational extends Component {
    render() {
      const filterPopover = (
        <Popover id="notif-filter-popover" trigger="focus">
          <Popover.Content>
            <FilterPopover
              placeholder={this.props.placeholder}
              options={
                this.props.placeholder.toLowerCase() === 'sender'
                  ? this.props.senderFilterOptions
                  : this.props.clientNameFilterOptions
              }
              handleApplyFilter={this.props.handleApplyFilter}
            />
          </Popover.Content>
        </Popover>
      );

      return (
        <React.Fragment>
          <div id="notif-hist-modal-content" className="notif-history-container">
            <div className=" notif-history-searchbar search-bar-container">
              <div className="search-bar label14-regular-midnight">
                <GrooveIconSvg
                  customClassName="close-modal-btn"
                  size="large"
                  name="search"
                  iconStyle="solid"
                  iconColor="stat-neutral"
                />
                <input
                  type="text"
                  name="name"
                  placeholder="Search ..."
                  autocomplete="off"
                  id="notifSearchBox"
                  maxlength="255"
                  onChange={(e) => this.props.handleSearch(e)}
                  value={this.props.searchKey}
                />
              </div>
              <div
                className={
                  this.props.isImportanceSelected
                    ? 'importance-btn-container importance-btn-clicked'
                    : 'importance-btn-container'
                }
              >
                <div
                  className={'importance-button'}
                  onClick={() =>
                    this.props.isImportanceSelected
                      ? this.props.handleClearImportantNotif()
                      : this.props.handleImportantNotif()
                  }
                >
                  <GrooveIconSvg
                    customClassName="importance-exclamation"
                    size="normal"
                    name="exclamation"
                    iconStyle="solid"
                    iconColor="stat-warning"
                  />
                  <span>Action Required</span>
                </div>
                <div className="important-x-btn-container">
                  <GrooveIconSvg
                    customClassName="importance-plus-circle"
                    size="normal"
                    name="plus-circle"
                    iconStyle="solid"
                    iconColor="gr-slate"
                    callbackFunction={() =>
                      this.props.isImportanceSelected
                        ? this.props.handleClearImportantNotif()
                        : this.props.handleImportantNotif()
                    }
                  />
                </div>
              </div>
            </div>
            <div className="notif-history-list">
              <div className="notif-table-container">
                <div className="notif-table-header">
                  <div className="row">
                    <div className="col-lg-2">
                      <div>Notification</div>
                    </div>
                    <div className="col-lg-2">
                      <div>Details</div>
                    </div>
                    <div className="col-lg-2">
                      <div>Comments</div>
                    </div>
                    <div className="col-lg-2">
                      <div className="notif-sort">Sender</div>
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="bottom-start"
                        overlay={filterPopover}
                        // container="notif-hist-modal-content"
                      >
                        <div className="filter-btn-container">
                          <GrooveIconSvg
                            className="filter-btn"
                            size="small"
                            name="filter"
                            iconStyle="solid"
                            iconColor="dl-midnight"
                            callbackFunction={() => {
                              this.props.handlePopOverData('Sender');
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="col-lg-2">
                      <div className="notif-sort">Client Name</div>
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="bottom-start"
                        overlay={filterPopover}
                        // container="notif-hist-modal-content"
                      >
                        <div className="filter-btn-container">
                          <GrooveIconSvg
                            className="filter-btn"
                            size="small"
                            name="filter"
                            iconStyle="solid"
                            iconColor="dl-midnight"
                            callbackFunction={() => {
                              this.props.handlePopOverData('Client Name');
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="col-lg-2">
                      <div className="notif-sort" onClick={() => this.props.handleSort()}>
                        Timestamp
                        <div className="sort-btn-container">
                          {this.props.dateTimeSortArrow === 1 ? (
                            <GrooveIconSvg
                              customClassName="sort-btn"
                              size="small"
                              name="sort-amount-up-alt"
                              iconStyle="solid"
                              iconColor="dl-midnight"
                            />
                          ) : this.props.dateTimeSortArrow === 2 ? (
                            <GrooveIconSvg
                              customClassName="sort-btn"
                              size="small"
                              name="sort-amount-down"
                              iconStyle="solid"
                              iconColor="dl-midnight"
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <div>Link</div>
                    </div>
                  </div>
                </div>
                <div className="notif-table-body">
                  {this.props.tableDataPresentational.length > 0 || this.props.searchKey !== '' ? (
                    this.props.tableDataPresentational.map((item, index) => {
                      //tableDataPaged
                      return (
                        <div
                          className={item.isNewNotif ? 'row new-notif-hist' : 'row old-notif-hist'}
                          key={index}
                        >
                          <div
                            className={
                              item.isNewNotif
                                ? 'col-lg-2 new-notif-hist-cell'
                                : 'col-lg-2 old-notif-hist-cell'
                            }
                          >
                            {item.isNewNotif ? <span>NEW</span> : ''}
                            <div className="notif-status" title={item.notifHeader}>
                              {item.isImportantNotif ? (
                                <GrooveIconSvg
                                  customClassName="notif-impt"
                                  size="normal"
                                  name="exclamation"
                                  iconStyle="solid"
                                  iconColor="stat-warning"
                                />
                              ) : (
                                ''
                              )}
                              <span>{item.notifHeader}</span>
                            </div>
                          </div>
                          <div className="col-lg-2" title={item.notifDetails}>
                            {item.notifDetails}
                          </div>
                          <div className="col-lg-2" title={item.notifComments}>
                            {item.notifComments}
                          </div>
                          <div className="col-lg-2" title={item.sender}>
                            {item.sender}
                          </div>
                          <div className="col-lg-2" title={item.clientName}>
                            {item.clientName}
                          </div>
                          <div className="col-lg-2" title={item.notifDateTime}>
                            {item.notifDateTime}
                          </div>
                          {/* <a data-tip data-for="sadFace"> */}
                          <div className="col-lg-1 table-action-button">
                            <OverlayTrigger
                              key="bottom"
                              placement="bottom-start"
                              overlay={
                                <Tooltip id="notif-hist-tooltip">
                                  <span className="">{item.notifAction}</span>
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                className="fa-icons"
                                icon={faArrowAltCircleRight}
                                onClick={(e) => this.props.handleTakeAction(item)}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })
                  ) : this.props.isLoading ? (
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <CVMPagination
                list={this.props.tableDataPaged}
                callbackFunction={(e) => this.props.setPageItems(e)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  class NotificationHistoryTableContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tableDataPresentational: [],
        tableDataPaged: [],
        tableData: [],
        previousTableDataPaged: [],
        tableDataSorted: [],
        // tableDataSearched: [],
        searchKey: '',
        dateTimeSortArrow: 2,
        placeholder: '',
        currentActionCount: 0,
        currentAction: {
          isFiltered: false,
          isSorted: false,
          isImportant: false,
          isSearched: false,
        },
        isLoading: true,
        isImportanceSelected: false,
        prevSearchKey: '',
      };
    }

    componentDidMount = async () => {
      try {
        await this.props.getNotificationHistoryDataCount();

        let notifHistTotalPage = this.props.NotificationData.notifHistTotalPage;

        for (let i = 0; i < notifHistTotalPage; i++) {
          await this.props.getNotificationHistoryData(i + 1);
        }

        await this.props.getNotificationHistoryFilterOptions();

        let notifHistoryList = this.props.NotificationData.notifHistoryList;

        this.setState({
          isLoading: false,
          previousTableDataPaged: notifHistoryList, //prev tableDataPaged
          tableData: notifHistoryList, //ioList original data do not manipulate
          tableDataPresentational: notifHistoryList, //tableDataPresentational data that is being shown to user
          tableDataPaged: notifHistoryList, //table data that is being used to divide the table data for pagination
          tableDataSorted: notifHistoryList,
          // tableDataSearched: notifHistoryList,
        });
      } catch (e) {
        console.log(e);
      }
    };

    setPageItems = (items) => {
      this.setState({
        tableDataPresentational: items,
      });
    };

    handlePopOverData = (field) => {
      this.setState({
        placeholder: field,
      });
    };

    handleSearch = (e) => {
      //current & previous
      let searchKey = e.target.value;
      let result = [];
      let currentAction = this.state.currentAction;
      let tData =
        currentAction.isFiltered || currentAction.isImportant || currentAction.isSearched
          ? this.state.searchKey.length >= searchKey.length ||
            !this.state.searchKey.toLowerCase().includes(searchKey.toLowerCase()) || //condition for pasting of search key
            searchKey.length === 0
            ? this.state.previousTableDataPaged
            : this.state.tableDataPaged
          : currentAction.isSorted
          ? this.state.tableDataSorted
          : this.state.tableData;

      let columns = [
        'clientName',
        'notifComments',
        'notifDateTime',
        'notifDetails',
        'notifHeader',
        'sender',
      ];

      if (searchKey.length <= 1) {
        result = tData;
      } else if (searchKey.length >= 2) {
        result = tData.filter((item) => {
          return columns.some(
            (key) =>
              item[key].toString().toLowerCase().indexOf(searchKey.trim().toLowerCase()) !== -1
          );
        });
      }

      this.setState(
        {
          tableDataPaged: result,
          tableDataPresentational: result,
          // tableDataSearched: result,
          // previousTableDataPaged: this.state.tableDataPaged,
          searchKey: searchKey,
          currentAction: { ...this.state.currentAction, isSearched: searchKey.length >= 2 },
          prevSearchKey: this.state.searchKey,
        },
        async () => {
          this.props.updateFilterOptions(result);
        }
      );
    };

    handleApplyFilter = async (options) => {
      //original table data
      try {
        let tableData = this.state.tableData;
        let labelName = this.state.placeholder.toLowerCase() === 'sender' ? 'sender' : 'clientName';

        let filteredTableData = tableData.filter((x) => {
          return options.some((y) => {
            return y.label === x[labelName];
          });
        });

        this.setState(
          {
            previousTableDataPaged: filteredTableData,
            tableDataPaged: filteredTableData,
            tableDataPresentational: filteredTableData,
            searchKey: '',
            prevSearchKey: '',
            isImportanceSelected: false,
            currentAction: {
              ...this.state.currentAction,
              isFiltered: true,
              isSearched: false,
              isImportant: false,
            },
          },
          () => {
            //call action here to change reducer data of option\
            this.props.updateFilterOptions(filteredTableData);
            document.body.click();
          }
        );
        console.log(options);
      } catch (e) {
        console.log(e);
      }
    };

    handleSort = () => {
      try {
        let arrow = this.state.dateTimeSortArrow;
        let list = this.state.tableDataPaged;
        let sortedList = [].concat(list);

        switch (arrow) {
          case 2:
            arrow = 1;
            sortedList.sort((a, b) => {
              let tDateA = a['notifDateTime'].split(' ');
              let tDateB = b['notifDateTime'].split(' ');
              let dateA = new Date(`${tDateA[0]} ${tDateA[1]}`);
              let dateB = new Date(`${tDateB[0]} ${tDateB[1]}`);
              return dateA - dateB;
            });
            break;
          case 1:
            arrow = 2;
            sortedList.sort((a, b) => {
              let tDateA = a['notifDateTime'].split(' ');
              let tDateB = b['notifDateTime'].split(' ');
              let dateA = new Date(`${tDateA[0]} ${tDateA[1]}`);
              let dateB = new Date(`${tDateB[0]} ${tDateB[1]}`);
              return dateB - dateA;
            });
            break;
          // case 2:
          //   arrow = 0;
          //   sortedList = list;
          //   break;
        }

        this.setState({
          dateTimeSortArrow: arrow,
          tableDataPaged: sortedList,
          previousTableDataPaged: this.state.tableDataPaged,
          tableDataPresentational: sortedList,
          tableDataSorted: sortedList,
          currentAction: { ...this.state.currentAction, isSorted: true },
        });
      } catch (e) {}
    };

    handleImportantNotif = () => {
      //original table data
      let tData = this.state.tableData;
      let filteredTableData = tData.filter((x) => {
        return x.isImportantNotif;
      });

      this.setState(
        {
          previousTableDataPaged: filteredTableData,
          tableDataPaged: filteredTableData,
          tableDataPresentational: filteredTableData,
          tableDataSorted: tData,
          searchKey: '',
          prevSearchKey: '',
          dateTimeSortArrow: 2,
          isImportanceSelected: true,
          currentAction: {
            ...this.state.currentAction,
            isFiltered: false,
            isSearched: false,
            isImportant: true,
            isSorted: false,
          },
        },
        () => {
          //call action here to change reducer data of option\
          this.props.updateFilterOptions(filteredTableData);
        }
      );
    };

    handleClearImportantNotif = () => {
      //original table data
      let tData = this.state.tableData;

      this.setState(
        {
          tableDataPaged: tData,
          tableDataPresentational: tData,
          previousTableDataPaged: tData,
          tableDataSorted: tData,
          isImportanceSelected: false,
          searchKey: '',
          prevSearchKey: '',
          dateTimeSortArrow: 2,
          currentAction: {
            ...this.state.currentAction,
            isFiltered: false,
            isSearched: false,
            isImportant: false,
            isSorted: false,
          },
        },
        () => {
          //call action here to change reducer data of option\
          this.props.updateFilterOptions(tData);
        }
      );
    };

    handleTakeAction = async (data) => {
      await this.props.notifHistoryMarkAsRead([data.notifID]);
      this.props.handleNotificationHistory();
      window.location.href = data.notifActionURL;
    };

    render() {
      const { senderFilterOptions, clientNameFilterOptions } = this.props.NotificationData;
      return (
        <NotificationHistoryTablePresentational
          handleSearch={this.handleSearch}
          tableDataPresentational={this.state.tableDataPresentational}
          tableData={this.state.tableData}
          tableDataPaged={this.state.tableDataPaged}
          setPageItems={this.setPageItems}
          handleApplyFilter={this.handleApplyFilter}
          handleSort={this.handleSort}
          dateTimeSortArrow={this.state.dateTimeSortArrow}
          //new
          //states and functions
          handlePopOverData={this.handlePopOverData}
          handleImportantNotif={this.handleImportantNotif}
          handleClearImportantNotif={this.handleClearImportantNotif}
          handleTakeAction={this.handleTakeAction}
          placeholder={this.state.placeholder}
          isLoading={this.state.isLoading}
          searchKey={this.state.searchKey}
          isImportanceSelected={this.state.isImportanceSelected}
          //reducers
          senderFilterOptions={senderFilterOptions}
          clientNameFilterOptions={clientNameFilterOptions}
          //actions
        />
      );
    }
  }

  return NotificationHistoryTableContainer;
})();
export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      clientID: state.SharedData.selectedClient.value,
      reportingYear: state.ClientConfigurationData.clientDetails.reportingYear,
      SharedData: state.SharedData,
      UserInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
      DataIngestionData: state.DataIngestionData,
      NotificationData: state.NotificationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getNotificationHistoryData,
          getNotificationHistoryDataCount,
          getNotificationHistoryFilterOptions,
          updateFilterOptions,
          notifHistoryMarkAsRead,
        }
      ),
      dispatch
    )
)(NotificationHistoryTable);
