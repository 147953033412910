import React, { Component } from "react";

import "../ProgramIdentified.css";
import addfiles from "../../../../Static/images/360cvm/AddFilesIcon.svg";

const NoInitialBOI = (function() {
  class NoInitialBOIPresentational extends Component {
    render() {
      return (
            <div className="empty-dimension-container">
            <div className="empty-program-container bodytext18-regular-slate">
            <img src={addfiles} alt="select client" />
            <span className="bodytext24-medium-slate">
                No Business Outcome Indicator Added
            </span>
            Please add BOI(s) to this program by using the 'Add BOIs'
            button above.
            </div>
        </div>
        )
    }
  }

  class NoInitialBOIContainer extends Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      return <NoInitialBOIPresentational />;
    }
  }

  return NoInitialBOIContainer;
})();

export default NoInitialBOI;
