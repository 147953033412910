import React, { Component } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { GrooveIconSvg } from 'tfo-groove';
import { TryGetValue, TryGetObjValueAlt } from '../../../Utilities/Formatter';
import CVMInputFieldWithRadio from '../../Shared/CVMInputFieldWithRadio/CVMInputFieldWithRadio';
import CustomBOIModal from '../CustomBOIModal';
import ReactTooltip from 'react-tooltip';
import newInfo from '../../../Static/images/svg/new-info.svg';
import './DimensionAccordion.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SpecificBOIHistoryModal from '../SpecificBOIHistoryModal';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import SwitchToggle from '../../Shared/CVMSwitchToggle/SwitchToggle';
import { IsValid } from '../../../Utilities/Validations';

const DimensionTable = (function () {
  class DimensionTablePresentational extends Component {
    render() {
      let headers =
        (['awaiting approval', 'awaiting approval – modified'].indexOf(
          TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
        ) === -1 &&
          this.props.userType === 'admin') ||
          this.props.userType === 'ppcUser'
          ? ['rejected', 'approved'].indexOf(
            TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
          ) > -1 ?
            // rejected and approved
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Status',
              'Dashboard Visibility',
              '',
            ]
            : // In progress, awaiting approval, awaiting approval – modified
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Dashboard Visibility',
              '',
            ]
          :
          ['rejected', 'approved'].indexOf(
            TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
          ) > -1 ?
            // rejected and approved
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Status',
              'Dashboard Visibility',
            ]
            :
            // approver view
            [
              'Dimension',
              'Subdimension',
              'Business Outcome Indicators',
              'Target Value',
              'Unit of Measure',
              'Approver Comment',
              'Dashboard Visibility',
            ];

      let statusLeft = this.props.userType === 'admin' || this.props.userType === 'ppcUser' ? '-3px' : '-9px'
      if (['review', 'edit', 'view'].indexOf(this.props.mode) > -1) headers.splice(5, 1);
      const formCB = this.props.formCallback;
      const dimList = this.props.dimensionList;
      return (
        <div className="dimension-table dimension-table-program">
          <div className="dimension-table-header">
            <div
              className={
                headers.length === 8
                  ? 'dimension-table-row dimension-table-row-unedit dim-header'
                  : 'dimension-table-row dimension-table-row-unedit'
              }
            >
              {headers.map((name) =>
                name === 'Dashboard Visibility' ? (
                  <div className="row-visibility">
                    {name}
                    <a data-tip data-for="sadFace">
                      <div className="info-icon">
                        <GrooveIconSvg
                          size="large"
                          name="info-circle"
                          iconStyle="solid"
                          iconColor="stat-alternate"
                        />
                      </div>
                    </a>
                    <ReactTooltip id="sadFace" type="dark" effect="solid">
                      <span>Show this BOI on the Value Meter Dashboard?</span>
                    </ReactTooltip>
                  </div>
                ) : (
                  <div>{name}</div>
                )
              )}
            </div>
          </div>
          <div className="dimension-table-body">
            {dimList.map((dimension, i) => {
              if (dimension.subDimensionList === undefined) return <></>;
              let firstSubDChild = [];
              let subDIndex = 0;
              let boiIndex = 0;
              if (this.props.newBOIs === true) {
                firstSubDChild = dimension.subDimensionList.filter(x => IsValid(x.boiList.filter(y => y.isModified === true || !IsValid(y.boiStatus))[0]))[0];
                if (IsValid(firstSubDChild)) subDIndex = dimension.subDimensionList.findIndex(x => x.subDimensionID === firstSubDChild.subDimensionID);
              } else if (this.props.newBOIs === false) {
                firstSubDChild = dimension.subDimensionList.filter(x => IsValid(x.boiList.filter(y => y.isModified === false && IsValid(y.boiStatus))[0]))[0];
                if (IsValid(firstSubDChild)) subDIndex = dimension.subDimensionList.findIndex(x => x.subDimensionID === firstSubDChild.subDimensionID);
              } else if (IsValid(this.props.newBOIs) === false) {
                firstSubDChild = dimension.subDimensionList[0];
                if (IsValid(firstSubDChild)) subDIndex = dimension.subDimensionList.findIndex(x => x.subDimensionID === firstSubDChild.subDimensionID);
              }


              if (firstSubDChild === undefined) return <></>;
              if (firstSubDChild.boiList === undefined) return <></>;
              let firstBoiChild = [];
              if (this.props.newBOIs === true) {
                firstBoiChild = firstSubDChild.boiList.filter(x => x.isModified === true || !IsValid(x.boiStatus))[0];
                if (IsValid(firstBoiChild)) boiIndex = firstSubDChild.boiList.findIndex(x => x.boiID === firstBoiChild.boiID);
              } else if (this.props.newBOIs === false) {
                firstBoiChild = firstSubDChild.boiList.filter(x => x.isModified === false || IsValid(x.boiStatus))[0];
                if (IsValid(firstBoiChild)) boiIndex = firstSubDChild.boiList.findIndex(x => x.boiID === firstBoiChild.boiID);
              } else if (IsValid(this.props.newBOIs) === false) {
                firstBoiChild = firstSubDChild.boiList[0];
                if (IsValid(firstBoiChild)) boiIndex = firstSubDChild.boiList.findIndex(x => x.boiID === firstBoiChild.boiID);
              }

              if (firstBoiChild === undefined) return <></>;
              let skipSubD = false;
              let firstBoicss =
                firstBoiChild.boiStatus === 'Approved' || firstBoiChild.boiStatus === 'Approve'
                  ? 'dim-status-completed'
                  : firstBoiChild.boiStatus === 'Rejected'
                    ? 'dim-status-rejected'
                    : firstBoiChild.boiStatus === ''
                      ? 'dim-no-status'
                      : 'status-break dim-status-conditionallyApproved';
              return (
                <Accordion
                  defaultActiveKey={String(i)}
                  className="dimension-table-collapse"
                  onSelect={(e) => {
                    this.props.onSelectAccordion(e);
                  }}
                >
                  <Card key={i}>
                    <Accordion.Collapse eventKey={String(i)}>
                      <Card.Body>
                        {dimension.subDimensionList.map((subD, i2) => {
                          return (
                            <>
                              {subD.boiList.map((boi, i3) => {
                                let showMessage = boi.boiStatus === 'Rejected' ? true : this.props.type === 'project' ? boi.programBOIStatus === 'Rejected' : false

                                const css =
                                  boi.boiStatus === 'Approved' || boi.boiStatus === 'Approve'
                                    ? 'dim-status-completed'
                                    : boi.boiStatus === 'Rejected'
                                      ? 'dim-status-rejected'
                                      : boi.boiStatus === ''
                                        ? 'dim-no-status'
                                        : 'status-break dim-status-conditionallyApproved';
                                if (this.props.newBOIs === true && !boi.isModified) {
                                  if (IsValid(boi.boiStatus)) return <></>;
                                } else if (this.props.newBOIs === false && (boi.isModified || !IsValid(boi.boiStatus))) {
                                  return <></>;
                                }
                                if (!skipSubD) {
                                  skipSubD = true;
                                  return <></>;
                                }
                                return (
                                  <div className="accordion-child-div-container">
                                    <div className="dimension-table-row dimension-table-row-unedit">
                                      <div></div>
                                      <div>{i3 === 0 ? subD.subDimensionName : ''}</div>

                                      <div className="row boi-column">
                                        <div>
                                          {boi.boiName}
                                          {!boi.IsCustom && (
                                            <div className="info-icon-wrap">
                                              <OverlayTrigger
                                                key="right"
                                                placement="auto"
                                                overlay={
                                                  <Tooltip id="boi-tooltip-right">
                                                    <div className="boi-details-wrap row">
                                                      <div class="col-md-6">
                                                        <h6>Offering</h6>
                                                        <p class="">{boi.offeringName}</p>
                                                        <h6>Dimension</h6>
                                                        <p class="">{dimension.dimensionName}</p>
                                                        <h6>BOI Description</h6>
                                                        <p class="">{boi.boiDescription}</p>
                                                      </div>
                                                      <div class="col-md-6">
                                                        <h6>Sub-Offering</h6>
                                                        <p class="">{boi.suboffering}</p>
                                                        <h6>Sub-dimension</h6>
                                                        <p class="">{subD.subDimensionName}</p>
                                                        <h6>Leading Practice</h6>
                                                        <p class="">{boi.leadingIndicator}</p>
                                                        <table>
                                                          <tr class="col-md-12">
                                                            <th class="col-md-6">
                                                              <h6>Parameter</h6>
                                                            </th>
                                                            <th class="col-md-6">
                                                              <h6>Unit of Measure</h6>
                                                            </th>
                                                          </tr>
                                                          {(boi.hasOwnProperty('parameterList')
                                                            ? boi.parameterList
                                                            : []
                                                          ).map((params) => {
                                                            return (
                                                              <tr>
                                                                <td class="col-md-6">
                                                                  <p>{params.ParameterName}</p>
                                                                </td>
                                                                <td class="col-md-6">
                                                                  <p>{params.ParameterUOM}</p>
                                                                </td>
                                                              </tr>
                                                            );
                                                          })}
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="dimension-new-info">
                                                  <img
                                                    className="new-info-icon"
                                                    src={newInfo}
                                                    alt="info"
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                            </div>
                                          )}
                                          {
                                            showMessage
                                            &&
                                            <OverlayTrigger
                                              key="right"
                                              placement="auto"
                                              overlay={
                                                <Tooltip id="reject-rdbtn-tooltip">
                                                  Value reported will not be reflected in the dashboard as BOI is rejected at program/project level.
                                                </Tooltip>
                                              }
                                            >
                                              <div>
                                                <GrooveIconSvg
                                                  size="large"
                                                  name="exclamation-triangle"
                                                  iconStyle="solid"
                                                  iconColor="stat-alternate"
                                                  customClassName="reject-alert"
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          }
                                        </div>

                                        {boi.IsCustom ? (
                                          <div>
                                            <div>
                                              <button
                                                className="custom-boi"
                                                onClick={() =>
                                                  this.props.onClickCustomBOI(
                                                    boi.boiID,
                                                    true,
                                                    i,
                                                    i2,
                                                    i3
                                                  )
                                                }
                                              >
                                                Custom
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      <div>
                                        <CVMInputFieldWithRadio
                                          key={i3}
                                          mode={this.props.mode}
                                          isInvalid={true}
                                          status={boi.status}
                                          isApprover={true}
                                          dimI={i}
                                          subDI={i2}
                                          boiI={i3}
                                          boi={boi}
                                          callbackFunction={formCB}
                                          type={this.props.type}
                                          isSuperAdmin={this.props.isSuperAdmin}
                                          basicDetails={this.props.basicDetails}
                                          isApproverEditView={this.props.isApproverEditView}
                                          selectedProjectStatus ={this.props.selectedProjectStatus}
                                        />
                                      </div>
                                      <div className={['approved', 'rejected'].indexOf(
                                        TryGetObjValueAlt(this.props, '', 'status').toLowerCase()) > -1 ? 'uom-string' : ''}>{boi.uom}</div>
                                      {['viewWithComment', 'editWithComment'].indexOf(
                                        this.props.mode
                                      ) > -1 && (
                                          <div>
                                            <textarea
                                              className="comment-input"
                                              name="comment"
                                              placeholder={boi.boiOldComment}
                                              onChange={(e) => {
                                                formCB('comment', i, i2, i3, e);
                                              }}
                                              readOnly
                                            />
                                          </div>
                                        )}
                                      {
                                        ['approved', 'rejected'].indexOf(
                                          TryGetObjValueAlt(this.props, '', 'status').toLowerCase()) > -1 && (<div>
                                            <div style={{ left: statusLeft, position: 'relative' }} className={'program-status boi-status ' + css}>
                                              {boi.boiStatus}
                                            </div>
                                          </div>)
                                      }
                                      <div className='toggle-swtch'>
                                        <SwitchToggle
                                          id={'switch-tgl-01' + i + i2 + i3}
                                          callbackFunction={(e) => {
                                            let inValid = boi.boiStatus === 'Rejected' && [0, undefined, false].indexOf(boi.boiWheelVisibility) > -1;

                                            inValid
                                              ? this.props.showMessage('switch-tgl-01' + i.toString() + i2.toString() + i3.toString(), i, i2, i3, e)
                                              : formCB('wheel', i, i2, i3, e);

                                            return inValid;
                                          }}
                                          defaultValue={boi.boiWheelVisibility}
                                          isDisabled={
                                            this.props.type === 'Program'
                                              ? ['edit', 'editWithComment', 'review'].indexOf(
                                                this.props.mode
                                              ) > -1
                                                ? false
                                                : true
                                              : true
                                          }
                                          customClassName={
                                            this.props.type === 'Program'
                                              ? 'wheel-visibility-button'
                                              : 'wheel-visibility-button disabled-switch'
                                          }
                                        />
                                        {
                                          boi.isDashboardVisibiltyUpdated && (this.props.isApproverEditView)
                                            ?
                                            <div className="db-visib-tooltip row-visibility">
                                              <a data-tip data-for="dbVisibUpdate">
                                                <div className="info-icon">
                                                  <GrooveIconSvg
                                                    customClassName="accordion-button"
                                                    size="small"
                                                    name="exclamation-triangle"
                                                    iconStyle="solid"
                                                    iconColor="stat-warning"
                                                  />
                                                </div>
                                              </a>
                                              <ReactTooltip id="dbVisibUpdate" type="dark" effect="solid" className="tooltip-dbVisibUpdate">
                                                <span>Visibility is updated by creator</span>
                                              </ReactTooltip>
                                            </div> : ""
                                        }
                                      </div>

                                      {([
                                        'awaiting approval',
                                        'awaiting approval – modified',
                                      ].indexOf(
                                        TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
                                      ) === -1 &&
                                        this.props.userType === 'admin') ||
                                        this.props.userType === 'ppcUser' ? (
                                        <SpecificBOIHistoryModal
                                          dimensionID={dimension.dimensionID}
                                          subDimensionID={subD.subDimensionID}
                                          boiID={boi.boiID}
                                          uom={boi.uom}
                                          historyType={this.props.type}
                                          clientProgramTargetID={boi.boiClientProgramTargetID}
                                          clientProjectTargetID={boi.boiClientProjectTargetID}
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </div>

                                    <div className="empty-div" />
                                  </div>
                                );
                              })}
                            </>
                          );
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                    <Card.Header className="dim-card-header">
                      <div
                        className="dimension-header-container"
                        onClick={(e) => this.props.onClickAccordion(e, 'div')}
                      >
                        <div className="dimension-table-row dimension-table-row-unedit dim-history">
                          <div className="dim-name">{dimension.dimensionName}</div>
                          <div>{TryGetValue(firstSubDChild.subDimensionName)}</div>
                          <div className="row boi-column">
                            <div>
                              {TryGetValue(firstBoiChild.boiName)}

                              {(firstBoiChild.boiName.length || false) &&
                                !firstBoiChild.IsCustom && (
                                  <div className="info-icon-wrap">
                                    <OverlayTrigger
                                      key="right"
                                      placement="auto"
                                      overlay={
                                        <Tooltip id="boi-tooltip-right">
                                          <div className="boi-details-wrap row">
                                            <div class="col-md-6">
                                              <h6>Offering</h6>
                                              <p class="">{firstBoiChild.offeringName}</p>
                                              <h6>Dimension</h6>
                                              <p class="">{dimension.dimensionName}</p>
                                              <h6>BOI Description</h6>
                                              <p class="">{firstBoiChild.boiDescription}</p>
                                            </div>
                                            <div class="col-md-6">
                                              <h6>Sub-Offering</h6>
                                              <p class="">{firstBoiChild.suboffering}</p>
                                              <h6>Sub-dimension</h6>
                                              <p class="">{firstSubDChild.subDimensionName}</p>
                                              <h6>Leading Practice</h6>
                                              <p class="">{firstBoiChild.leadingIndicator}</p>
                                              <table>
                                                <tr class="col-md-12">
                                                  <th class="col-md-6">
                                                    <h6>Parameter</h6>
                                                  </th>
                                                  <th class="col-md-6">
                                                    <h6>Unit of Measure</h6>
                                                  </th>
                                                </tr>
                                                {(firstBoiChild.hasOwnProperty('parameterList')
                                                  ? firstBoiChild.parameterList
                                                  : []
                                                ).map((params) => {
                                                  return (
                                                    <tr>
                                                      <td class="col-md-6">
                                                        <p>{params.ParameterName}</p>
                                                      </td>
                                                      <td class="col-md-6">
                                                        <p>{params.ParameterUOM}</p>
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                              </table>
                                            </div>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="dimension-new-info">
                                        <img className="new-info-icon" src={newInfo} alt="info" />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                )}
                              {
                                (firstBoiChild.boiStatus === 'Rejected' ? true : this.props.type === 'project' ? firstBoiChild.programBOIStatus === 'Rejected' : false) &&
                                <OverlayTrigger
                                  key="right"
                                  placement="auto"
                                  overlay={
                                    <Tooltip id="reject-rdbtn-tooltip">
                                      Value reported will not be reflected in the dashboard as BOI is rejected at program/project level.
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <GrooveIconSvg
                                      size="large"
                                      name="exclamation-triangle"
                                      iconStyle="solid"
                                      iconColor="stat-alternate"
                                      customClassName="reject-alert first-boi"
                                    />
                                  </div>
                                </OverlayTrigger>
                              }

                              {firstBoiChild.IsCustom ? (
                                <button
                                  className="custom-boi"
                                  onClick={() =>
                                    this.props.onClickCustomBOI(
                                      firstBoiChild.boiID,
                                      true,
                                      i,
                                      subDIndex,
                                      boiIndex
                                    )
                                  }
                                >
                                  Custom
                                </button>
                              ) : null}
                            </div>
                          </div>
                          <div>
                            <CVMInputFieldWithRadio
                              mode={this.props.mode}
                              isInvalid={true}
                              isApprover={true}
                              dimI={i}
                              subDI={subDIndex}
                              boiI={boiIndex}
                              boi={firstBoiChild}
                              isSuperAdmin={this.props.isSuperAdmin}
                              basicDetails={this.props.basicDetails}
                              callbackFunction={formCB}
                              type={this.props.type}
                              isApproverEditView={this.props.isApproverEditView}
                              selectedProjectStatus ={this.props.selectedProjectStatus}
                            />
                          </div>
                          <div className={['approved', 'rejected'].indexOf(
                            TryGetObjValueAlt(this.props, '', 'status').toLowerCase()) > -1 ? 'uom-string' : ''}>{TryGetValue(firstBoiChild.uom)}</div>
                          {['viewWithComment', 'editWithComment'].indexOf(this.props.mode) >
                            -1 && (
                              <div>
                                {
                                  <textarea
                                    className="comment-input"
                                    name="comment"
                                    placeholder={TryGetValue(firstBoiChild.boiOldComment)} //should display reject reason only!!
                                    onChange={(e) => {
                                      formCB('comment', i, subDIndex, boiIndex, e);
                                    }}
                                    readOnly
                                  />
                                }
                              </div>
                            )}
                          {
                            ['approved', 'rejected'].indexOf(
                              TryGetObjValueAlt(this.props, '', 'status').toLowerCase()) > -1 &&
                            (<div>
                              <div style={{ left: statusLeft, position: 'relative' }} className={'program-status boi-status ' + firstBoicss}>
                                {firstBoiChild.boiStatus}
                              </div>
                            </div>)
                          }
                          <div className="toggle-swtch">
                            <SwitchToggle
                              id={'switch-tgl-01' + i + `${subDIndex}` + `${boiIndex}`}
                              callbackFunction={(e) => {
                                const inValid = firstBoiChild.boiStatus === 'Rejected' && [0, undefined, false].indexOf(firstBoiChild.boiWheelVisibility) > -1;
                                inValid
                                  ? this.props.showMessage('switch-tgl-01' + i + `${subDIndex}` + `${boiIndex}`, i, subDIndex, boiIndex, e)
                                  : formCB('wheel', i, subDIndex, boiIndex, e);

                                return inValid;
                              }}
                              defaultValue={firstBoiChild.boiWheelVisibility}
                              isDisabled={
                                this.props.type === 'Program'
                                  ? ['edit', 'editWithComment', 'review'].indexOf(
                                    this.props.mode
                                  ) > -1
                                    ? false
                                    : true
                                  : true
                              }
                              customClassName={
                                this.props.type === 'Program'
                                  ? 'wheel-visibility-button'
                                  : 'wheel-visibility-button disabled-switch'
                              }
                            />
                            {
                              firstBoiChild.isDashboardVisibiltyUpdated && (this.props.isApproverEditView)
                                ?
                                <div className="db-visib-tooltip row-visibility">
                                  <a data-tip data-for="dbVisibUpdate">
                                    <div className="info-icon">
                                      <GrooveIconSvg
                                        customClassName="accordion-button"
                                        size="small"
                                        name="exclamation-triangle"
                                        iconStyle="solid"
                                        iconColor="stat-warning"
                                      />
                                    </div>
                                  </a>
                                  <ReactTooltip id="dbVisibUpdate" type="dark" effect="solid" className="tooltip-dbVisibUpdate">
                                    <span>Visibility is updated by creator</span>
                                  </ReactTooltip>
                                </div> : ""
                            }
                          </div>

                          {(['awaiting approval', 'awaiting approval – modified'].indexOf(
                            TryGetObjValueAlt(this.props, '', 'status').toLowerCase()
                          ) === -1 &&
                            this.props.userType === 'admin') ||
                            this.props.userType === 'ppcUser' ? (
                            <SpecificBOIHistoryModal
                              dimensionID={dimension.dimensionID}
                              subDimensionID={firstSubDChild.subDimensionID}
                              boiID={firstBoiChild.boiID}
                              uom={firstBoiChild.uom}
                              historyType={this.props.type}
                              clientProgramTargetID={firstBoiChild.boiClientProgramTargetID}
                              clientProjectTargetID={firstBoiChild.boiClientProjectTargetID}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <Accordion.Toggle
                        id="dim-btn"
                        className="dim-acc-btn"
                        as={Button}
                        variant="link"
                        eventKey={String(i)}
                        onClick={(e) => this.props.onClickAccordion(e, 'btn')}
                      >
                        <GrooveIconSvg
                          id="basicDetailsIcon"
                          customClassName="accordion-button"
                          size="large"
                          name="chevron-right"
                          iconStyle="solid"
                          iconColor="stat-neutral"
                        />
                      </Accordion.Toggle>
                    </Card.Header>
                  </Card>
                </Accordion>
              );
            })}
          </div>
          {
            this.props.showCustomBOI && (
              <CustomBOIModal
                dimensionName={this.props.dimensionName}
                subDimensionName={this.props.subDimensionName}
                isShow={this.props.showCustomBOI}
                onCustomBOIModalCancelClose={() =>
                  this.props.onClickCustomBOI(0, false, null, null, null)
                }
                boi={this.props.boi}
              />
            )
          }
          <CVMModal
            show={this.props.showEditToggle}
            onCloseModal={this.props.onCloseModal}
            size="md"
            content={
              <ModalContent />
            }
          />
        </div>
      );
    }
  }

  class ModalContent extends Component {
    render() {
      return (
        <div className='reject-msg-on-toggle'>
          <p>
            <GrooveIconSvg
              size="large"
              name="exclamation-circle"
              iconStyle="solid"
              iconColor="stat-alternate"
              className="reject-BOI-msg"
            />
          </p>
          <p className="header">Change wheel visibility?</p>
          <p className="body-msg">You need to Approve or Conditionally Approve the BOI inorder to perform this action</p>
        </div>
      )
    }
  }

  class DimensionTableContainer extends Component {
    constructor() {
      super();
      this.state = {
        activeExpand: false,
        activeCollapse: false,
        accordionCount: 0,
        onLoadExpand: false,
        isCollapsed: false,
        customDetails: [],
        dimensionName: '',
        subDimensionName: '',
        boi: {
          ClientName: '',
          offeringName: '',
          boiName: '',
          uom: '',
          description: '',
          LeadingIndicator: '',
          comment: '',
          MetricCalculation: '',
          customParameterList: [],
          AggregationLogic: '',
          SubOffering: '',
          AggregationLogicId: 0,
          MetricReason: '',
          AggregateReason: '',
          WheelVisibility: 0,
        },
        showEditToggle: false,
        toggleId: '',
        params: {
          dimI: 0,
          subDI: 0,
          boiI: 0,
          value: null
        }
      };
    }

    componentDidUpdate = (prevProps) => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
    };

    componentDidMount = () => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
    };

    onSelectAccordion = (e) => {
      try {
        let accordionCount = this.state.accordionCount;
        let activeCollapse = false; //accordion close
        let activeExpand = false; //accordion open

        let dimensionList =
          this.props.dimensionList !== undefined
            ? this.props.dimensionList
            : this.state.dimensionList; //change
        if (e === null) {
          accordionCount -= 1;
        } else {
          accordionCount += 1;
        }

        if (dimensionList.length === accordionCount) {
          activeExpand = true;
          activeCollapse = false;
        } else if (accordionCount === 0) {
          activeCollapse = true;
          activeExpand = false;
        }

        this.setState({
          accordionCount: accordionCount,
          activeExpand: activeExpand,
          activeCollapse: activeCollapse,
        });
      } catch (e) {
        console.log(e);
      }
    };

    onClickAccordion = (e, action) => {
      let isCurrentlyCollapsed =
        e.currentTarget.parentElement.previousSibling.className === 'collapse' ? true : false;
      if (action === 'div' && isCurrentlyCollapsed) {
        e.currentTarget.nextSibling.click();
        e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
      } else {
        //btn
        if (!isCurrentlyCollapsed) {
          e.currentTarget.parentElement.parentElement.style.cursor = 'pointer';
        } else {
          e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
        }
      }
    };

    onCloseModal = () => {
      this.setState({
        showEditToggle: false
      })
    }

    render() {
      return (
        <DimensionTablePresentational
          dimensionList={this.props.dimensionList}
          onSelectAccordion={this.onSelectAccordion}
          mode={this.props.mode}
          topLeftContainer={this.props.topLeftContainer}
          topRightContainer={this.props.topRightContainer}
          formCallback={this.props.formCallback}
          activeExpand={this.state.activeExpand}
          activeCollapse={this.state.activeCollapse}
          onClickAccordion={this.onClickAccordion}
          isCollapsed={this.state.isCollapsed}
          origin={this.props.origin}
          type={this.props.type}
          isSuperAdmin={this.props.isSuperAdmin}
          basicDetails={this.props.basicDetails}
          onClickCustomBOI={this.props.onClickCustomBOI}
          showCustomBOI={this.props.showCustomBOI}
          subDimensionName={this.props.subDimensionName}
          dimensionName={this.props.dimensionName}
          boi={this.props.boi}
          additionalElement={this.props.additionalElement}
          userType={this.props.userType}
          status={this.props.status}
          isApproverEditView={this.props.isApproverEditView}
          showMessage={this.props.showMessage}
          showEditToggle={this.state.showEditToggle}
          onCloseModal={this.onCloseModal}
          newBOIs={this.props.newBOIs}
          selectedProjectStatus ={this.props.selectedProjectStatus}
        />
      );
    }
  }

  return DimensionTableContainer;
})();
export default DimensionTable;
