import React, { Component } from 'react';
import { GrooveInput, GrooveButton, GrooveTextBox } from 'tfo-groove';
import { GrooveSelectMenuBasic } from 'tfo-groove';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  programNameValidation,
  getBOIMappingListByOffering,
  getCustomBOIDropdownList,
  setNewProgramDetails,
  setSubOfferingList,
  getBOIMappingListALLOffering,
  setOALeadList,
  setCXLeadList,
  setNewlyAddedStakeholders
} from '../../../Actions/ProgramConfiguration.Action';
import { HasValue, IsArrayEmpty, IsObjEmpty, IsValid, IsValidAccentureEmail } from '../../../Utilities/Validations';
import CVMMultiSelectDropdownWithCheckBox from '../../Shared/CVMMultiSelectDropdownWithCheckBox/CVMMultiSelectDropdownWithCheckBox';
import CVMMultiSelectDropdownWithAddButton from '../../Shared/CVMMultiSelectDropdownWithAddButton/CVMMultiSelectDropdownWithAddButton';
import { object } from 'react-dom-factories';
import { HttpPost } from '../../../Utilities/HTTPUtil';

require("../../Shared/CVMMutiSelectDropdownWithCountTooltip/CVMMutiSelectDropdownWithCountTooltip.css");
require("../../Shared/CVMMutiSelectDropdownWithCount/CVMMutiSelectDropdownWithCount.css");

const AddNewProgramPopup = (function () {
  class AddNewProgramPopupPresentational extends Component {
    render() {
      let clientID = this.props.programClientDetails.ClientMasterID;
      let user = this.props.userProfiles.find((x) => x.ClientMasterId === clientID && x.ProfileID !== 16);

      let offeringPlaceholder =
        Object.keys(this.props.selectedOffering).length === 0 ? 'Select Offering' : 'Offering';
      let CXLeadPlaceholder =
        Object.keys(this.props.selectedCXLead).length === 0 ? 'Select CX Lead' : 'CX Lead';
      let ProgramCreatorpPlaceholder =
        Object.keys(this.props.selectedProgCreator).length === 0
          ? 'Select Program Creator'
          : 'Program Creator';
      let OALeadPlaceholder =
        Object.keys(this.props.selectedOALead).length === 0
          ? 'Select Operations Account Lead'
          : 'Operations Account Lead';
      let ProgramName =
        Object.keys(this.props.programName).length !== 0 ? 'Program Name' : 'Enter Program Name';
      const subofferingDisabled = !IsValid(user) ? (!this.props.userInfo.isSuperAdmin) : //if superAdmin & not mapped to client
        ((user.ProfileID === 12 || user.ProfileID === 15) ||
          !this.props.userInfo.isSuperAdmin)

      let isProgramCreator = this.props.selectedProgramDetails.ProgramCreator === this.props.userInfo.emailAddress

      let disbaledUpdateBtn =
        Object.keys(this.props.selectedOALead).length === 0 ||
        Object.keys(this.props.selectedCXLead).length === 0 ||
        Object.keys(this.props.selectedProgCreator).length === 0;
      const isProgramApprover = (this.props.selectedOALead.some((a) => a.text === this.props.userInfo.emailAddress) || //oal
        this.props.selectedCXLead.some((a) => a.text === this.props.userInfo.emailAddress)) && //cxl
        !this.props.userInfo.isSuperAdmin;
      let enabledUpdatePPC = !IsValid(this.props.selectedProgramDetails.ProgramCreator) && isProgramApprover;
      const selectedPPC = IsValid(this.props.selectedProgramDetails.ProgramCreator)
      const isDisabledPPC = selectedPPC ? this.props.userInfo.isSuperAdmin : this.props.userInfo.isSuperAdmin || !this.props.isPPCUser && isProgramApprover || enabledUpdatePPC
      return (
        <React.Fragment>
          <div className="add-program-details">
            <Row>
              <Col lg={6} md={6} sm={12}>
                <GrooveSelectMenuBasic
                  name="selectOffering"
                  isDisabled={this.props.editMode || this.props.readOnly}
                  placeholder={offeringPlaceholder}
                  list={this.props.offeringList || []}
                  defaultData={this.props.selectedOffering}
                  id="offering"
                  value={this.props.onSelectOffering}
                  callbackFunction={(e) => this.props.onSelectOffering(e)}
                  isRequired={true}
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div
                  className={
                    ((!isProgramCreator &&
                      subofferingDisabled &&
                      this.props.readOnly) &&
                      (this.props.selectedProgramDetails.StageId !== undefined &&
                        this.props.selectedProgramDetails.StageId !== 1)) ||
                      (this.props.subOfferingList === undefined ||
                        Object.keys(this.props.selectedOffering).length === 0)
                      || this.props.readOnly
                      ? 'Multi-Select-BT disabled-mulitSelect'
                      : 'Multi-Select-BT with-label'
                  }
                >
                  <CVMMultiSelectDropdownWithCheckBox
                    options={
                      this.props.subOfferingList === undefined ||
                        Object.keys(this.props.selectedOffering).length === 0
                        ? []
                        : this.props.subOfferingList
                    }
                    id="subOffering"
                    placeholder="Select Sub Offering"
                    value={this.props.selectedSubOffering}
                    isDisabled={
                      ((!isProgramCreator &&
                        subofferingDisabled &&
                        this.props.readOnly) &&
                        (this.props.selectedProgramDetails.StageId !== undefined &&
                          this.props.selectedProgramDetails.StageId !== 1)) ||
                      (this.props.subOfferingList === undefined ||
                        Object.keys(this.props.selectedOffering).length === 0)
                      || this.props.readOnly
                    }
                    label={'Sub Offering'}
                    onChange={(e) => this.props.onSelectSuboffering(e)}
                    isRequired={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <GrooveInput
                  id="programName"
                  name="programName"
                  placeholder={ProgramName}
                  type="text"
                  inputValue={this.props.programName}
                  isDisabled={true}
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div
                  className={
                    (
                      this.props.readOnly
                        ? 'Multi-Select-BT disabled-mulitSelect'
                        : 'Multi-Select-BT with-label'
                    )
                  }
                >
                  <CVMMultiSelectDropdownWithAddButton
                    name="selectCXLLead"
                    popdirection="pop-bottom"
                    isDisabled={this.props.readOnly}
                    placeholder={CXLeadPlaceholder}
                    options={this.props.CXLeadList}
                    id="cxlead"
                    onChange={(e) => this.props.onSelectCXLead(e)}
                    value={this.props.selectedCXLead || []}
                    activePaste={false}
                    onAddButton={(e) => this.props.onAddButton(e, 'cxl')}
                    isRequired
                    label="CX Lead"
                    customClassName={!this.props.isValidNewUser.cxl? ' dropdown-invalid' : ''}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div
                  className={
                    (
                      this.props.readOnly
                        ? 'Multi-Select-BT disabled-mulitSelect'
                        : 'Multi-Select-BT with-label'
                    )
                  }
                >
                  <CVMMultiSelectDropdownWithAddButton
                    name="Operations Account Lead"
                    popdirection="pop-bottom"
                    isDisabled={this.props.readOnly}
                    placeholder={OALeadPlaceholder}
                    options={this.props.OALeadList}
                    id="oalead"
                    onChange={(e) => this.props.onSelectOALead(e)}
                    value={this.props.selectedOALead || []}
                    activePaste={false}
                    onAddButton={(e) => this.props.onAddButton(e, 'oal')}
                    isRequired
                    label="Operations Account Lead"
                    customClassName={!this.props.isValidNewUser.oal? ' dropdown-invalid' : ''}
                  />
                </div>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <GrooveSelectMenuBasic
                  name="Program Creator"
                  isDisabled={!isDisabledPPC}
                  placeholder={ProgramCreatorpPlaceholder}
                  list={this.props.ProgCreatorList}
                  defaultData={this.props.selectedProgCreator}
                  id="programcreator"
                  callbackFunction={(e) => this.props.onSelectProgCreator(e)}
                  isRequired={true}
                  customClassName={!this.props.isValidProgramCreator ? ' program-creator-invalid': ''}
                />
              </Col>
            </Row>
            <div className="add-program-buttons ppc">
              <>
                {this.props.validation && <div className="missing">This Program already exists</div>}
                {
                  this.props.cmErrorMessage.map(cmerr => {
                    return (
                      <div className="missing">
                        {cmerr.value}
                      </div>
                    )
                  })
                }
                <div className='missing-container'>
                  {
                    this.props.isValidMappedFields.mappedFieldErrMsgs.map(err => {
                      return (
                        <div className="missing">
                          {err}
                        </div>
                      )
                    })
                  }
                </div>
              </>
              {(this.props.selectedProgramDetails.Status !== 'In Progress' &&
                IsValid(this.props.selectedProgramDetails.Status) && !this.props.readOnly) || enabledUpdatePPC ?
                (
                  <span className="applyFlex-space-between">
                    <GrooveButton
                      id="primry-btn-2"
                      type="solid"
                      colorClass="stat-alternate"
                      text="Update Program"
                      customClassName="add-project-button"
                      callbackFunction={this.props.editProgram}
                      isDisabled={this.props.disableSendBtn || disbaledUpdateBtn || this.props.anyInvalidEmailAvailable}
                    />
                  </span>
                ) : (
                  ''
                )}
            </div>
          </div>
        </React.Fragment >
      );
    }
  }

  class AddNewProgramPopupContainer extends Component {
    constructor() {
      super();
      this.state = {
        selectedOffering: '',
        programName: '',
        subOffering: [],
        tempsubOfferingList: [],
        offering: [],
        CXLeadList: [],
        ProgCreatorList: [],
        offeringID: '',
        subOfferingID: [],
        isValidNewUser: {
          cxl: true,
          oal: true,
        },
        cmErrorMessage: [],
        editedAnyFieldOnce: false,
        isValidProgramCreator: true,
        allInvalidEmails: [],
        anyInvalidCxlEmails: false,
        anyInvalidOalEmails: false,
      };
    }

    checkForInvalidEmailFirst = async () => {
      let isCxlValid = true;
      let isOalValid = true;
      let isProgCreatorValid = true;
      let allInvalidEmails = [];
      let msgValue;
      let CXLeadList = this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.split(',') : [];
      let OALeadList = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.split(',') : [];
      let progCreator = this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator !== null ? [this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator] : [];

      const cxlData = await IsValidAccentureEmail([...CXLeadList]);
      for (let i = 0; i < cxlData.length; i++) {
        if (!cxlData[i].IsValid) {
          isCxlValid = false;
          allInvalidEmails.push(cxlData[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(", ")}` };
        }
      }

      const oalData = await IsValidAccentureEmail([...OALeadList]);
      for (let i = 0; i < oalData.length; i++) {
        if (!oalData[i].IsValid) {
          isOalValid = false;
          allInvalidEmails.push(oalData[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(", ")}` };
        }
      }

      const progCreatorData = await IsValidAccentureEmail([...progCreator]);
      for (let i = 0; i < progCreatorData.length; i++) {
        if (!progCreatorData[i].IsValid) {
          isProgCreatorValid = false;
          allInvalidEmails.push(progCreatorData[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(", ")}` };
        }
      }

      this.props.setAnyInvalidEmailAvailable(!isProgCreatorValid || !isOalValid || !isCxlValid);

      this.setState({
        isValidNewUser: {
          cxl: isCxlValid,
          oal: isOalValid,
        },
        allInvalidEmails: allInvalidEmails,
        isValidProgramCreator: isProgCreatorValid,
        cmErrorMessage: (isCxlValid && isOalValid && isProgCreatorValid) ? [] : [msgValue],
        anyInvalidCxlEmails: !isCxlValid,
        anyInvalidOalEmails: !isOalValid,
      });
    }

    componentDidMount = () => {
      if (this.props.editMode &&
          (this.props.ProgramConfigurationData.selectedProgramDetails.Status !== 'Configured - yet to be aligned' &&
          this.props.ProgramConfigurationData.selectedProgramDetails.Status !== 'Configured & Aligned')
        ) {
          this.checkForInvalidEmailFirst();
        }
    }

    onSelectOffering = (e) => {
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      let userInfo = this.props.userInformation;
      let programName = '';
      let clientDetails = this.props.ProgramConfigurationData.programClientDetails;
      let tempOffering = this.props.ProgramConfigurationData.offeringList;
      let offeringIndex = tempOffering.findIndex((x) => x.value === e.value);
      let tempString = `${clientDetails.ClientMasterName}_${e.label}`;
      programName = tempString.trim();

      this.props.programNameValidation(encodeURIComponent(programName));
      const tempsubOfferingIDObj = {
        clientProgramID: clientDetails.ClientMasterID,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
      };

      let subOfferingId = [];
      let temparray = [];
      let tempsubOfferingID = [];
      if (e.label === 'Overall') {
        tempOffering.map((offering) => {
          offering.subOffering.map((item) => {
            subOfferingId.push(item.ID);

            temparray.push({
              label: item.SubOfferingName,
              value: item.ID,
            });

            tempsubOfferingID.push({
              ...tempsubOfferingIDObj,
              subOfferingID: item.ID,
            });
          });
        });
      } else {
        tempOffering[offeringIndex].subOffering.map((item) => {
          subOfferingId.push(item.ID);

          temparray.push({
            label: item.SubOfferingName,
            value: item.ID,
          });

          tempsubOfferingID.push({
            ...tempsubOfferingIDObj,
            subOfferingID: item.ID,
          });
        });
      }

      this.props.getBOIMappingListByOffering(subOfferingId.join(), "Add New Program"); //Get BOI Mapping list based on sub offering selected
      this.props.getBOIMappingListALLOffering(subOfferingId.join(), "Add New Program"); //Get BOI Mapping list based on sub offering selected
      this.props.getCustomBOIDropdownList(0, "Add New Program");
      this.setState({
        selectedOffering: e,
        offeringID: tempOffering[offeringIndex].Id,
        programName: programName,
        subOffering: temparray,
        subOfferingID: tempsubOfferingID,
      });
      newProgramDetails.programName = programName;
      newProgramDetails.offering = e;
      newProgramDetails.subOffering = temparray;
      this.props.setNewProgramDetails(newProgramDetails);
      this.props.onSaveAddBOI([]);
      this.focusTargerValueSetting();
    };

    onSelectSuboffering = (e) => {
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      let progId =
        Object.keys(this.props.ProgramConfigurationData.selectedProgramDetails).length === 0
          ? 0
          : this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
      let subOfferingId = '';
      e.map((item, index) => {
        if (index === 0) {
          subOfferingId = item.value;
        } else {
          subOfferingId = subOfferingId + ',' + item.value;
        }
      });
      this.props.getBOIMappingListByOffering(subOfferingId, "Add New Program"); //Get BOI Mapping list based on sub offering selected
      this.props.getBOIMappingListALLOffering(subOfferingId, "Add New Program"); //Get BOI Mapping list based on sub offering selected
      this.props.getCustomBOIDropdownList(progId, "Add New Program");
      newProgramDetails.subOffering = e;
      this.props.setNewProgramDetails(newProgramDetails);
      this.focusTargerValueSetting();

      // to check for invalid email ids if project is configured and aligned & configured and yet to be aligned
      if(!this.state.editedAnyFieldOnce && this.props.editMode &&
          (
            this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured - yet to be aligned' ||
            this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured & Aligned'
          )
        ) {
        this.checkForInvalidEmailFirst();
        this.setState({
          editedAnyFieldOnce: true
        })
      }
    };

    checkBOIAvailability = () => {
      let bois = this.props.selectedBOIs;
      let boiSet = this.props.ProgramConfigurationData.boiMappingList.BIOMappingList;
      let newBois = [];
      let boiList = [];
      bois.map((item) => {
        let subDimList = [];
        item.subDimensionList.map((subdim) => {
          boiList = [];
          subdim.boiList.map((boi) => {
            let added = false;
            boiSet.map((boiRef) => {
              if ((boi.boiID === boiRef.BOIID || boi.IsCustom) && !added) {
                boiList.push(boi);
                added = true;
              }
            });
          });

          if (boiList.length > 0) {
            subDimList.push({
              subDimensionID: subdim.subDimensionID,
              subDimensionName: subdim.subDimensionName,
              subDimensionReason: subdim.subDimensionReason,
              subDimensionStatus: subdim.subDimensionStatus,
              subDimensionTarget: subdim.subDimensionTarget,
              boiList: boiList,
            });
          }
        });
        if (boiList.length > 0) {
          newBois.push({
            dimensionID: item.dimensionID,
            dimensionName: item.dimensionName,
            dimensionReason: item.dimensionReason,
            dimensionStatus: item.dimensionStatus,
            dimensionTarget: item.dimensionTarget,
            subDimensionList: subDimList,
          });
        }
      });

      this.props.onSaveAddBOI(newBois);
    };

    onSelectProgCreator = async (e) => {
      let oalIsValid = true;
      let cxlIsValid = true;
      let progCreatorIsValid = true;
      let msgValue;
      let cxlEmail = this.props.ProgramConfigurationData.newProgramDetails.cxLead.length ? this.props.ProgramConfigurationData.newProgramDetails.cxLead.map((item) => item.label) : [];
      let oalEmail = this.props.ProgramConfigurationData.newProgramDetails.oaLead.length ? this.props.ProgramConfigurationData.newProgramDetails.oaLead.map((item) => item.label) : [];
      let allInvalidEmails = [];

      console.log(e);    
  
      const data = await IsValidAccentureEmail([e.value]);
      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          progCreatorIsValid = false;
          allInvalidEmails.push(data[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
        }
      }
      
      const cxlData = await IsValidAccentureEmail([...cxlEmail]);

      for (let i = 0; i < cxlData.length; i++) {
        if (!cxlData[i].IsValid) {
          cxlIsValid = false;
          allInvalidEmails.push(cxlData[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
        }
      }

      const oalData = await IsValidAccentureEmail([...oalEmail]);

      for (let i = 0; i < oalData.length; i++) {
        if (!oalData[i].IsValid) {
          oalIsValid = false;
          allInvalidEmails.push(oalData[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
        }
      }

      this.props.setAnyInvalidEmailAvailable(!progCreatorIsValid || !oalIsValid || !cxlIsValid);
      this.setState({
        isValidNewUser: {
          oal: oalIsValid,
          cxl: cxlIsValid,
        },
        isValidProgramCreator: progCreatorIsValid,
        allInvalidEmails: allInvalidEmails,
        anyInvalidCxlEmails: !cxlIsValid,
        anyInvalidOalEmails: !oalIsValid,
      }, () => {
        let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
        newProgramDetails.progCreator = e;
        this.setState({
          cmErrorMessage:(cxlIsValid && oalIsValid && progCreatorIsValid) ? [] : [msgValue]
        })
        this.props.setNewProgramDetails(newProgramDetails);
        this.focusTargerValueSetting();
      });

      // to check for invalid email ids if project is configured and aligned & configured and yet to be aligned
      if(!this.state.editedAnyFieldOnce && 
          (
            this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured - yet to be aligned' ||
            this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured & Aligned'
          )
        ) {
        this.checkForInvalidEmailFirst();
        this.setState({
          editedAnyFieldOnce: true
        })
      }
    };

    onSelectCXLead = async (e) => {
      let oalIsValid = true;
      let cxlIsValid = true;
      let progCreatorIsValid = true;
      let msgValue;
      let cxlEmail = e.map((item) => item.label);
      let oalEmail = this.props.ProgramConfigurationData.newProgramDetails.oaLead.length ? this.props.ProgramConfigurationData.newProgramDetails.oaLead.map((item) => item.label) : [];
      let progCreatorEmail = Object.keys(this.props.ProgramConfigurationData.newProgramDetails.progCreator).length ? [this.props.ProgramConfigurationData.newProgramDetails.progCreator.label] : [];
      let allInvalidEmails = [];
      const data = await IsValidAccentureEmail([...oalEmail]);

      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          oalIsValid = false;
          allInvalidEmails.push(data[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
        }
      }

      const cxlData = await IsValidAccentureEmail([...cxlEmail]);

      for (let i = 0; i < cxlData.length; i++) {
        if (!cxlData[i].IsValid) {
          cxlIsValid = false;
          allInvalidEmails.push(cxlData[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
        }
      }

      if (progCreatorEmail.length) {
        const progCreatorData = await IsValidAccentureEmail([...progCreatorEmail]);
        for (let i = 0; i < progCreatorData.length; i++) {
          if (!progCreatorData[i].IsValid) {
            progCreatorIsValid = false;
            allInvalidEmails.push(progCreatorData[i].UserName.split('@')[0]);
            msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
          }
        }
      }

      this.props.setAnyInvalidEmailAvailable(!progCreatorIsValid || !oalIsValid || !cxlIsValid);
      this.setState({
        isValidNewUser: {
          oal: oalIsValid,
          cxl: cxlIsValid,
        },
        isValidProgramCreator: progCreatorIsValid,
        allInvalidEmails: allInvalidEmails,
        anyInvalidCxlEmails: !cxlIsValid,
        anyInvalidOalEmails: !oalIsValid,
      }, () => {
        let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
        newProgramDetails.cxLead = e;
        this.setState({
          cmErrorMessage:(cxlIsValid && oalIsValid && progCreatorIsValid)  ? [] : [msgValue]
        })
        this.props.setNewProgramDetails(newProgramDetails);
        this.focusTargerValueSetting();
      });

      // to check for invalid email ids if project is configured and aligned & configured and yet to be aligned
      if(!this.state.editedAnyFieldOnce && 
          (
            this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured - yet to be aligned' ||
            this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured & Aligned'
          )
        ) {
        this.checkForInvalidEmailFirst();
        this.setState({
          editedAnyFieldOnce: true
        })
      }
    };

    onSelectOALead = async (e) => {
      let oalIsValid = true;
      let cxlIsValid = true;
      let progCreatorIsValid = true;
      let msgValue;
      let oalEmail = e.map((item) => item.label);
      let cxlEmail = this.props.ProgramConfigurationData.newProgramDetails.cxLead.length ? this.props.ProgramConfigurationData.newProgramDetails.cxLead.map((item) => item.label) : [];
      let progCreatorEmail = Object.keys(this.props.ProgramConfigurationData.newProgramDetails.progCreator).length ? [this.props.ProgramConfigurationData.newProgramDetails.progCreator.label] : [];
      let allInvalidEmails = [];
      const data = await IsValidAccentureEmail([...cxlEmail]);

      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          cxlIsValid = false;
          allInvalidEmails.push(data[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
        }
      }

      const oalData = await IsValidAccentureEmail([...oalEmail]);

      for (let i = 0; i < oalData.length; i++) {
        if (!oalData[i].IsValid) {
          oalIsValid = false;
          allInvalidEmails.push(oalData[i].UserName.split('@')[0]);
          msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
        }
      }

      if (progCreatorEmail.length) {
        const progCreatorData = await IsValidAccentureEmail([...progCreatorEmail]);
        for (let i = 0; i < progCreatorData.length; i++) {
          if (!progCreatorData[i].IsValid) {
            progCreatorIsValid = false;
            allInvalidEmails.push(progCreatorData[i].UserName.split('@')[0]);
            msgValue = { key: 'Valid', value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}` };
          }
        }
      }

      this.props.setAnyInvalidEmailAvailable(!progCreatorIsValid || !oalIsValid || !cxlIsValid);
      this.setState({
        isValidNewUser: {
          cxl: cxlIsValid,
          oal: oalIsValid,
        },
        isValidProgramCreator: progCreatorIsValid,
        allInvalidEmails: allInvalidEmails,
        anyInvalidCxlEmails: !cxlIsValid,
        anyInvalidOalEmails: !oalIsValid,
      }, () => {
        let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
        this.setState({
          cmErrorMessage:(cxlIsValid && oalIsValid && progCreatorIsValid) ? [] : [msgValue]
        })
        this.props.ProgramConfigurationData.newProgramDetails.oaLead = e;
        this.props.setNewProgramDetails(newProgramDetails);
        this.focusTargerValueSetting();
      });
    };


    focusTargerValueSetting = () => {
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      if (
        Object.keys(newProgramDetails.offering).length !== 0 &&
        newProgramDetails.cxLead.length !== 0 &&
        Object.keys(newProgramDetails.progCreator).length !== 0 &&
        newProgramDetails.oaLead.length !== 0 &&
        newProgramDetails.subOffering.length !== 0 &&
        !newProgramDetails.validation
      )
        if (
          document
            .getElementsByClassName('idValue-setting-add-boi')[0]
            .children[0].children[0].className.indexOf('show') === -1
        ) {
          document
            .getElementsByClassName('idValue-setting-add-boi')[0]
            .children[0].children[1].children[0].click();
          setTimeout(() => {
            if (document.getElementsByClassName('idValue-setting-add-boi')) {
              document.getElementsByClassName('idValue-setting-add-boi')[0].scrollIntoView();
            }
          }, 200);
        }

    };

    componentDidUpdate = (nextProps) => {
      if (
        Object.keys(this.props.ProgramConfigurationData.boiMappingList).length !==
        Object.keys(nextProps.ProgramConfigurationData.boiMappingList).length
      ) {
        this.checkBOIAvailability();
      }
    };

    onAddButton = async (newValue, field) => {
        Object.keys(newValue).forEach(k =>
        newValue[k].label = newValue[k].label.split(' ').join('').trim()
        );
        Object.keys(newValue).forEach(k =>
        newValue[k].text = newValue[k].text.split(' ').join('').trim()
        );
        Object.keys(newValue).forEach(k =>
        newValue[k].value = newValue[k].value.split(' ').join('').trim()
        );
      try {
        // to check for invalid email ids if project is configured and aligned & configured and yet to be aligned
        if(!this.state.editedAnyFieldOnce && 
            (
              this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured - yet to be aligned' ||
              this.props.ProgramConfigurationData.selectedProgramDetails.Status === 'Configured & Aligned'
            )
          ) {
          this.checkForInvalidEmailFirst();
          this.setState({
            editedAnyFieldOnce: true
          })
        }
        let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
        let cxlIsValid = true;
        let oalIsValid = true;
        let newlyAddedUserList = this.props.ProgramConfigurationData.newlyAddedUserList;
        switch (field) {
          case 'oal':
            oalIsValid = await this.validateOALCXLNewUser(newValue[0].label, field);
            if (oalIsValid) {
              newProgramDetails.oaLead = [...newProgramDetails.oaLead, ...newValue];
              newlyAddedUserList = [...newlyAddedUserList, newValue[0].label];
              this.props.setOALeadList(newValue);
            }
            break;
          case 'cxl':
            cxlIsValid = await this.validateOALCXLNewUser(newValue[0].label, field);
            if (cxlIsValid) {
              newProgramDetails.cxLead = [...newProgramDetails.cxLead, ...newValue];
              newlyAddedUserList = [...newlyAddedUserList, newValue[0].label];
              this.props.setCXLeadList(newValue);
            }
            break;
        }

        this.setState({
          isValidNewUser: {
            cxl: cxlIsValid ? !this.state.anyInvalidCxlEmails : cxlIsValid,
            oal: oalIsValid ? !this.state.anyInvalidOalEmails : oalIsValid,
          },
        }, () => {
          if (newlyAddedUserList.length > 0) {
            this.props.setNewlyAddedStakeholders(newlyAddedUserList);
            this.props.setNewProgramDetails(newProgramDetails);
          }
        })
      }
      catch (err) {
        console.log(err);
      }
    }

    validateOALCXLNewUser = async (value, field) => {
      try {
        let isValid = true;
        isValid = await this.validateEmailAddress(value, true, field);

        return isValid;
      }
      catch (err) {
        console.log(err);
      }
    };

    validateEmailAddress = async (value, hasNoError, role) => {
      let isValid = true;
      if (value !== null && value !== '') {
        //has value
        if (hasNoError) {
        this.props.ProgramConfigurationData.newProgramDetails.cxLead.length<=0&& this.handleErrorMessage(1);
        this.props.ProgramConfigurationData.newProgramDetails.oaLead.length<=0&&this.handleErrorMessage(1);
          var re = /.+@.+/;
          if (!re.test(value)) {
            //no @ & .
            isValid = false;
            this.handleErrorMessage(2);
          } else {
            if (hasNoError) {
              this.handleErrorMessage(2, false);

              if (!value.toLowerCase().endsWith('@accenture.com')) {
                //not accenture email
                isValid = false;
                this.handleErrorMessage(3);
              } else {
                if (hasNoError) {
                  this.handleErrorMessage(3, false);

                  let emailCount = value.toLowerCase().split('@');
                  emailCount = emailCount.filter((r) => r.trim() != '');
                  if (emailCount.length > 2) {
                    isValid = false;
                    this.handleErrorMessage(2);
                  } else {
                    if (hasNoError) {
                      this.handleErrorMessage(2, false);

                      const data = await IsValidAccentureEmail([value]);
                      
                      if(!data[0].IsValid){isValid=false;
                      this.handleErrorMessage(6);
                      }
                      else{
                        if(hasNoError){
                          this.handleErrorMessage(6,false)
                          let isDuplicate = this.validateDuplicateEmail(value, role);
                          if (!isDuplicate) {
                            //check email duplication (based on role)
                            isValid = false;
                            this.handleErrorMessage(4);
                          } else {
                            if (hasNoError) {
                              this.handleErrorMessage(4, false);
    
                              let programConfigData = this.props.ProgramConfigurationData;
                              let isUserExist = false;
                              //check if the newly added email is already exist in the field.
                              switch (role) {
                                case 'cxl':
                                  isUserExist = (programConfigData.CXLeadList || []).filter(i => i.label === value).length > 0
                                  break;
                                case 'oal':
                                  isUserExist = (programConfigData.OALeadList || []).filter(i => i.label === value).length > 0
                                  break;
                              }
    
                              if (isUserExist) {
                                isValid = false;
                                this.handleErrorMessage(5);
                              } else {
                                if (hasNoError) {
                                  this.handleErrorMessage(5, false);
                                }
                              }
                            }
                          }

                        }
                      }
                     
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        isValid = false;
        this.handleErrorMessage(2, false);
        this.handleErrorMessage(3, false);
        this.handleErrorMessage(4, false);
        this.handleErrorMessage(5, false);
        this.props.ProgramConfigurationData.newProgramDetails.cxLead.length<=0&& this.handleErrorMessage(1);
        this.props.ProgramConfigurationData.newProgramDetails.oaLead.length<=0&&this.handleErrorMessage(1);
      }
      return isValid;
    };

    validateDuplicateEmail = (value, field) => {
      try {
        let programConfigData = this.props.ProgramConfigurationData;
        let isValid = true;
        let isNotExistOnPPCAndBA = programConfigData.ConfiguratorsList.filter(i => i.label === value).length === 0;
        switch (field) {
          case 'oal':
            isValid = isNotExistOnPPCAndBA && (programConfigData.CXLeadList || []).filter(i => i.label === value).length === 0;
            break;
          case 'cxl':
            isValid = isNotExistOnPPCAndBA && (programConfigData.OALeadList || []).filter(i => i.label === value).length === 0;
            break;
        }

        return isValid;
      }
      catch (err) {
        console.log(err);
      }
    }

    handleErrorMessage = (eType, isError) => {
      isError = isError == undefined ? true : false;
      let cmErrorMessage = this.state.cmErrorMessage;
      
      let msgValue = {};
      switch (eType) {
        case 1:
          msgValue = { key: 'req', value: 'Missing Required Field' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key == 'req').length == 0)
            cmErrorMessage = [msgValue];
          break;
        case 2:
          msgValue = { key: 'invalidEmail', value: 'Invalid Email' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (
            cmErrorMessage.filter((f) => f.key == msgValue.key).length == 0 &&
            cmErrorMessage.filter((f) => f.key == 'accentureValid').length == 0
          )
            cmErrorMessage = [msgValue];
          break;
        case 3:
          msgValue = {
            key: 'accentureValid',
            value: "Valid email ID should have '@accenture.com'",
          };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (
            cmErrorMessage.filter((f) => f.key == 'invalidEmail').length == 0 &&
            cmErrorMessage.filter((f) => f.key == msgValue.key).length == 0
          )
            cmErrorMessage = [msgValue];
          break;
        case 4:
          msgValue = { key: 'sameProfile', value: 'User already added to a different profile.' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key == 'sameProfile').length == 0)
            cmErrorMessage = [msgValue];
          break;
        case 5:
          msgValue = { key: 'exist', value: 'User already exist.' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key == 'exist').length == 0)
            cmErrorMessage = [msgValue];
          break;
        case 6:
          msgValue = { key: 'invalid accentureid', value: 'Invalid Accenture ID' };
          if (!isError) {
            cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          } else if (
            cmErrorMessage.filter((f) => f.key == 'invalid accentureid').length == 0
            ) {
            cmErrorMessage = [msgValue];
          }
          break;
      }

      if (cmErrorMessage.filter(i => i.key === 'Valid').length === 0 && this.props.anyInvalidEmailAvailable) {
          cmErrorMessage.unshift({
          key: 'Valid',
          value: `Please update the given invalid EIDs in order to proceed: ${this.state.allInvalidEmails.join(', ')}`,
        })
      }

      this.setState({ cmErrorMessage });
    };

    render() {
      const {
        offeringList,
        subOfferingList,
        CXLeadList,
        ConfiguratorsList,
        OALeadList,
        newProgramDetails,
        selectedProgramDetails,
        programClientDetails,
      } = this.props.ProgramConfigurationData;

      const {
        userProfiles
      } = this.props.userInformation


      let ppConfiguratorUser = ConfiguratorsList.findIndex(
        (user) => user.label === this.props.userInformation.emailAddress && user.id !== 16 //filter out QC user
      );
      if (IsObjEmpty(newProgramDetails.progCreator)) {
        if (ConfiguratorsList.length === 1) {
          newProgramDetails.progCreator = ConfiguratorsList[0];
        } else if (ppConfiguratorUser > -1 && !selectedProgramDetails.ClientProgramID) {
          newProgramDetails.progCreator = ConfiguratorsList[ppConfiguratorUser];
        }
      }
      // if (CXLeadList.length === 1) {
      //   newProgramDetails.cxLead = CXLeadList;
      // }
      // if (OALeadList.length === 1) {
      //   newProgramDetails.oaLead = OALeadList;
      // }
      let isPPCUser = !IsArrayEmpty(ConfiguratorsList.filter(x => x.label === this.props.userInformation.emailAddress))

      return (
        <AddNewProgramPopupPresentational
          selectedCXLead={newProgramDetails.cxLead}
          selectedOffering={newProgramDetails.offering}
          selectedProgCreator={newProgramDetails.progCreator}
          selectedOALead={newProgramDetails.oaLead}
          selectedSubOffering={newProgramDetails.subOffering}
          programName={newProgramDetails.programName}
          onSelectOffering={this.onSelectOffering}
          onSelectProgCreator={this.onSelectProgCreator}
          onSelectCXLead={this.onSelectCXLead}
          onSelectSuboffering={this.onSelectSuboffering}
          onSelectOALead={this.onSelectOALead}
          editMode={this.props.editMode}
          offeringList={offeringList}
          subOfferingList={subOfferingList}
          ProgCreatorList={ConfiguratorsList}
          OALeadList={OALeadList}
          CXLeadList={CXLeadList}
          validation={newProgramDetails.validation}
          readOnly={this.props.readOnly}
          selectedProgramDetails={selectedProgramDetails}
          userInfo={this.props.userInformation}
          programClientDetails={programClientDetails}
          userProfiles={userProfiles}
          editProgram={this.props.editProgram}
          disableSendBtn={this.props.disableSendBtn}
          onAddButton={this.onAddButton}
          cmErrorMessage={this.state.cmErrorMessage}
          isValidNewUser={this.state.isValidNewUser}
          isValidMappedFields={this.props.isValidMappedFields}
          isPPCUser={isPPCUser}
          isValidProgramCreator={this.state.isValidProgramCreator}
          anyInvalidEmailAvailable={this.props.anyInvalidEmailAvailable}
          setAnyInvalidEmailAvailable={this.props.setAnyInvalidEmailAvailable}
        />
      );
    }
  }

  return AddNewProgramPopupContainer;
})();

export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
      userInformation: state.userInformation,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          programNameValidation,
          getBOIMappingListByOffering,
          getCustomBOIDropdownList,
          setNewProgramDetails,
          setSubOfferingList,
          getBOIMappingListALLOffering,
          setOALeadList,
          setCXLeadList,
          setNewlyAddedStakeholders
        }
      ),
      dispatch
    )
)(AddNewProgramPopup);
