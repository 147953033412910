import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Toast from 'react-bootstrap/Toast';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IsValid } from '../../../Utilities/Validations';
import { setToasterParam } from '../../../Actions/Shared.Action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
require('./CVMCustomToasterNotifUndo.css');
const CVMToasterNotifUndo = (function () {
  class CVMToasterNotifUndoPresentational extends Component {
    render() {
      const notif = this.props.notifToasterParam;
      return (
        <div
          className={
            !IsValid(notif.type) || (notif.type === 'success' && notif.hasUndo === undefined)
              ? 'notify-toast saveDraft-toast'
              : 'notify-toast-error saveDraft-toast'
          }
        >
          <Toast onClose={this.props.onCloseNotifToaster} show={notif.show} delay={!!notif.hasUndo ? 60000 : 3000} autohide>
            <Toast.Header>
              <FontAwesomeIcon
                icon={
                  notif.type === 'success'
                    ? faCheckCircle
                    : notif.type === 'error'
                      ? faTimesCircle
                      : undefined
                }
              />
              <h5>{notif.message}</h5>
              {notif.hasUndo && (
                <div 
                  className="undo-delete-btn" 
                  onClick={() => {
                    notif.callback();
                    this.props.onCloseNotifToaster();
                  }}
                >
                  <span>Undo</span>
                </div>
              )}
            </Toast.Header>
          </Toast>
        </div>
      );
    }
  }

  class CVMToasterNotifUndoContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        notifToasterParam: { show: false, message: '', type: '' },
      };
    }

    componentDidMount() {
      //this.onShowNotifToaster(this.props.toasterParam);
      this.onCloseNotifToaster();
    }
    componentDidUpdate(prevProps) {
      if (JSON.stringify(this.props.toasterParam) !== JSON.stringify(prevProps.toasterParam)) {
        this.onShowNotifToaster(this.props.toasterParam);
      }
    }

    onShowNotifToaster = (param) => {
      this.setState({
        notifToasterParam: { 
          show: param.show, 
          message: param.message, 
          type: param.type, 
          hasUndo: param.hasUndo, 
          callback: param.callback },
      });
    };
    onCloseNotifToaster = () => {
      this.props.setToasterParam({ show: false, message: '' });
      this.setState({
        notifToasterParam: { 
          show: false, 
          message: '', 
          type: this.props.toasterParam.type, 
          hasUndo: false
        },
      });
    };

    render() {
      return (
        <CVMToasterNotifUndoPresentational
          {...this.state}
          {...this.props}
          onCloseNotifToaster={this.onCloseNotifToaster}
        />
      );
    }
  }

  return CVMToasterNotifUndoContainer;
})();

export default connect(
  (state) => {
    return {
      toasterParam: state.SharedData.toasterParam,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(CVMToasterNotifUndo);
