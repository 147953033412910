import React from 'react';
import circles from './../../Static/images/360cvm/circlesColor.svg';
import { Link } from 'react-router-dom';
import { TryGetObjValueAlt } from '../../Utilities/Formatter'
import { HasValue } from '../../Utilities/Validations';

export const GenRVDURL = (progClient, prog, proj) => {
  const projectId = TryGetObjValueAlt(proj, '', 'ProjectID')
  return `/DataIngestion/${progClient.ClientMasterName}/${prog.value}/${projectId}`;

}
export const GenRVDLink = (redirectLink, styles) => {
  return (<div className="rvd-link" style={{ float: 'right' }}>
    <img
      src={circles}
      alt="RVD Link"
      style={!HasValue(styles) ? { height: '12.8px', width: '16px' } : styles}
    />{' '}
    Go to <Link to={redirectLink}>Report Value Delivered</Link> Module
  </div>)
}

export const GenRVDURLProg = (progClient, prog) => {
  try {
    return `/DataIngestion/${progClient}/${prog}`;
  } catch (e) {
    console.log(e)
  }
}

export const GenPPCProg = (progClient, prog) => {
  try{
    let pClient = progClient.replaceAll('/', '*');
    let progClientName = encodeURIComponent(pClient);
    return `/ProgramProjectConfig/${progClientName}/${prog}?isALA=${true}`;
  } catch (e) {
    console.log(e)
  }
}