import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { history } from '../../../store';
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//actions
import { getMappedStakeHolders } from '../../../Actions/ConfigureProgram.Action';
import {
  getProjectDetails,
  setDeliveryCenterSelected,
} from '../../../Actions/DataIngestion.Action';

//components
import CVMTable from '../../Shared/CVMTable/CVMTable';

//icons
import { faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import AddFileIcon from '../../../Static/images/360cvm/AddFilesIcon.svg';

//validations
import { IsValid } from '../../../Utilities/Validations';
import { TryGetNodeAlt } from '../../../Utilities/Formatter';

//css
import './ProjectDataEntryList.css';

const ProjectDataEntryListTable = (function () {
  class ProjectDataEntryListTablePresentational extends Component {
    render() {
      return (
        <>
          <div className="management-table-container">
            {this.props.pdeTableData.length > 0 ? (
              <div className="pde-table-list rvd-pde-table-list">
                <CVMTable
                  tableProperties={this.props.tableProperties}
                  getGridApi={this.props.getGridApi}
                />
                <div className="data-entries">{this.props.pdeTableData.length} data entries</div>
              </div>
            ) : (
              <div className="empty-program-container bodytext18-regular-slate">
                <img src={AddFileIcon} alt="select client" />
                <span className="bodytext24-medium-slate">No Project Data Entry</span>
                Please report project data using the 'New Project Data Entry' button above.
              </div>
            )}
          </div>
        </>
      );
    }
  }

  class BtnCellRenderer extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      const profiles = this.props.data.currentUser.userProfiles;
      const clientId = this.props.data.clientId;
      let editEnabled = false; // editEnabled is PPC user flag
      profiles.map((p) => {
        if (p.ClientMasterId === clientId && p.ProfileID === 12 || p.ProfileID === 15) { //no need to add QC since if QC is 12 or 15
          editEnabled = true;
        }
      });
      const isRejProjSysGen = this.props.data.isRejProjSysGen

      return (
        <div className={`table-action-buttons ${isRejProjSysGen ? 'disable' : ''}`}>
          {(this.props.data.actionOwner !== this.props.data.currentUser.emailAddress ||
            this.props.data.statusID === 6) &&
            !this.props.data.currentUser.isSuperAdmin &&
            !editEnabled ? (
            <FontAwesomeIcon
              className="fa-icons"
              icon={faEye}
              onClick={(e) => this.props.handleViewEdit('view', this.props.data)}
            />
          ) : (
            <FontAwesomeIcon
              className="fa-icons"
              icon={faEdit}
              onClick={(e) => this.props.handleViewEdit('edit', this.props.data)}
            />
          )}
        </div>
      );
    }
  }

  class StatusCellRenderer extends Component {
    render() {
      let data = this.props.data;

      return (
        <>
          <div
            className={
              data.statusID === 1
                ? 'card-row-status status-notStarted'
                : data.statusID === 2 || data.statusID === 9
                  ? 'card-row-status status-notStarted'
                  : data.statusID === 3
                    ? 'card-row-status status-inProgress'
                    : data.statusID === 4
                      ? 'card-row-status status-rejected'
                      : data.statusID === 5
                        ? 'card-row-status status-inProgress'
                        : data.statusID === 6
                          ? 'card-row-status status-completed'
                          : data.statusID === 7
                            ? 'card-row-status status-completed'
                            : data.statusID === 8 && 'card-row-status status-rejected'
            }
            style={{ marginTop: '11px' }}
          >
            {data.status}
          </div>
        </>
      );
    }
  }

  class ProjectDataEntryListTableContainer extends Component {
    constructor() {
      super();
      this.state = {
        tableProperties: {
          columns: [
            {
              field: 'deliveryCenter',
              headerName: 'Delivery Location',
              tooltipField: 'deliveryCenter',
              suppressMovable: true
            },
            {
              field: 'startEndReportedMonth',
              headerName: 'Last Reported Month',
              tooltipField: 'startEndReportedMonth',
              suppressMovable: true
            },
            {
              field: 'reportedBy',
              headerName: 'Last Reported By',
              tooltipField: 'reportedBy',
              suppressMovable: true
            },
            {
              field: 'actionOwner',
              headerName: 'Current Action Owner',
              tooltipField: 'actionOwner',
              suppressMovable: true
            },
            {
              field: 'status',
              headerName: 'Status',
              cellRenderer: 'statusCellRenderer',
              suppressMovable: true
            },
            {
              field: 'actions',
              headerName: 'Actions',
              cellRenderer: 'btnCellRenderer',
              cellRendererParams: {
                handleViewEdit: (action, data) => {
                  this.handleViewEdit(action, data);
                },
              },
              suppressMovable: true
            },
          ],
          cellRenderer: {
            btnCellRenderer: BtnCellRenderer,
            statusCellRenderer: StatusCellRenderer,
          },
          sortable: true,
          data: [],
          pagination: false,
          paginationPageSize: 10,
          profielFlagID: 0,
        },
        prevData: [],
        gridApi: () => { },
      };
    }

    componentDidMount = (nextProps) => {
      let pdeTableData = this.props.DataIngestionData.pdeTableData;
      let tPDETableData = [];
      const currentUser = this.props.UserInformation;
      const clientId = this.props.SharedData.selectedClient.value;
      const isRejProjSysGen = this.isProjectSystemGen();
      pdeTableData.map((pde) => {
        let latestReportingMonth = pde.startEndReportedMonth.split(' - ')[1]; //updated to latest reporting month
        tPDETableData.push({
          //add ID
          startEndReportedMonth: latestReportingMonth,
          deliveryCenter: pde.deliveryCenter,
          reportedMonth: pde.reportedMonth,
          reportedBy: pde.reportedBy,
          actionOwner: pde.actionOwner,
          status: pde.status,
          statusID: pde.statusID,
          programData: pde.programData,
          startReportedMonth: pde.startReportedMonth,
          action: '',
          currentUser: currentUser,
          clientId: clientId,
          projectOverallStatusID: pde.projectOverallStatusID,
          isRejProjSysGen: isRejProjSysGen
        });
      });

      this.setState({
        tableProperties: { ...this.state.tableProperties, data: tPDETableData },
        prevData: this.props.DataIngestionData.pdeTableData,
      });
    };

    componentDidUpdate = (nextProps) => {
      if (this.state.prevData !== this.props.DataIngestionData.pdeTableData) {
        const currentUser = this.props.UserInformation;
        const clientId = this.props.SharedData.selectedClient.value;
        let pdeTableData = this.props.DataIngestionData.pdeTableData;
        let tPDETableData = [];
        const sysGenCheck = this.isProjectSystemGen();
        pdeTableData.map((pde) => {
          let latestReportingMonth = pde.startEndReportedMonth.split(' - ')[1]; //updated to latest reporting month
          tPDETableData.push({
            //add ID
            startEndReportedMonth: latestReportingMonth,
            deliveryCenter: pde.deliveryCenter,
            reportedMonth: pde.reportedMonth,
            reportedBy: pde.reportedBy,
            actionOwner: pde.actionOwner,
            status: pde.status,
            statusID: pde.statusID,
            programData: pde.programData,
            startReportedMonth: pde.startReportedMonth,
            action: '',
            currentUser: currentUser,
            clientId: clientId,
            projectOverallStatusID: pde.projectOverallStatusID,
            isProjSysGen: sysGenCheck
          });
        });

        this.setState({
          tableProperties: { ...this.state.tableProperties, data: tPDETableData },
          prevData: this.props.DataIngestionData.pdeTableData,
        });
      }
    };

    isProjectSystemGen = () => {
      let selectedProject = TryGetNodeAlt(this.props.ConfigureProgramData, {}, 'mainSelectedProject');
      let selectedClientProjectID = IsValid(selectedProject) ? selectedProject.projectDetails.clientProjectID : ""
      let isRejProjSysGen = false;

      let isProjSysGen = this.props.DataIngestionData.projectOptions.filter((x) => x.value === selectedClientProjectID)[0].createdBy
      const projSGStatus = this.props.DataIngestionData.projectOptions.filter((x) => x.value === selectedClientProjectID)[0].systemGeneratedStatus

      if (IsValid(selectedClientProjectID) && isProjSysGen.toLowerCase() === 'system generated' && projSGStatus.toLowerCase() === 'rejected') {
        isRejProjSysGen = true
      }
      else {
        isRejProjSysGen = false
      }
      return isRejProjSysGen
    }

    handleViewEdit = async (action, data) => {
      //call api here, tbc
      let selectProject = this.props.ConfigureProgramData.mainSelectedProject;
      let selectedClientProjID = selectProject.projectDetails.clientProjectID
      const isProjSysGen = IsValid(selectedClientProjID) ? this.props.DataIngestionData.projectOptions.filter((x) => x.value === selectedClientProjID)[0].createdBy.toLowerCase() === 'system generated' : false
      const isRejProjSysGen = this.props.DataIngestionData.projectOptions.filter((x) => x.value === selectedClientProjID)[0].systemGeneratedStatus.toLowerCase() === 'rejected'

      if (action === 'edit' && isProjSysGen && isRejProjSysGen) return

      let prog = {
        programID: data.programData.programID,
        programName: data.programData.programName,
        creationYear: selectProject.creationYear,
      };

      let proj = {
        ID: data.projectOverallStatusID, //
        clientProjectID: data.programData.projectID, //
        deliveryCenterID: data.programData.deliveryCenterID, //
        actionOwner: data.actionOwner,
        deliveryCenterName: data.deliveryCenter, //
        assignedTo: data.actionOwner, //?
        reportedBy: data.reportedBy, //
        reportedMonth: data.reportedMonth, //
        status: data.status, //
        statusID: data.statusID, //
        lastReportingDate: selectProject.projectDetails.lastReportingDate, //
        assignedTo: selectProject.projectDetails.assignedTo,
        clientProjectName: selectProject.projectDetails.clientProjectName,
        projectID: selectProject.projectDetails.projectID,
        status: selectProject.projectDetails.status,
        assignedToRoleId: selectProject.projectDetails.assignedToRoleId,
        createdBy: selectProject.projectDetails.createdBy,
        creationDate: selectProject.projectDetails.creationDate,
        deliveryLocations: selectProject.projectDetails.deliveryLocations,
      };

      await this.props.setDeliveryCenterSelected(data);
      await this.props.getProjectDetails(prog, proj); //sample 38, 29, 3
      await this.props.getMappedStakeHolders(prog.programID, "Value Delivered_Landing page");
      this.props.history.push('/DataIngestion/ProjectDataEntryList/ConfigureProgram');
    };

    getGridApi = (api) => {
      this.setState({
        gridApi: api,
      });
    };

    render() {
      const { pdeTableData, projectOptions } = this.props.DataIngestionData;
      const { mainSelectedProject } = this.props.ConfigureProgramData;

      return (
        <ProjectDataEntryListTablePresentational
          tableProperties={this.state.tableProperties}
          getGridApi={this.state.getGridApi}
          pdeTableData={pdeTableData}
          mainSelectedProject={mainSelectedProject}
          projectOptions={projectOptions}
        />
      );
    }
  }

  return ProjectDataEntryListTableContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      clientID: state.SharedData.selectedClient.value,
      reportingYear: state.ClientConfigurationData.clientDetails.reportingYear,
      SharedData: state.SharedData,
      UserInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
      DataIngestionData: state.DataIngestionData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getProjectDetails,
          getMappedStakeHolders,
          setDeliveryCenterSelected,
        }
      ),
      dispatch
    )
)(withRouter(ProjectDataEntryListTable));
