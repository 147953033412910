import React, { Component } from "react";
import { GrooveButton, GrooveRadioButton } from "tfo-groove";

import ModalFileUpload from "./ModalFileUpload";
import ModalFileLink from "./ModalFileLink";

const ModalTabs = (function () {
    class ModalTabsPresentaional extends Component {
        render() {
            const {
                activeTab, radioSelected,
                onClickTab, onShowModal, onClickSave,
                setDocType, radioBtnValues,
                supportDocs, supportLinks,
                uploadSupportDoc
            } = this.props;
            
            return (
                <React.Fragment>            
                    <div className="modal-tabs">
                        <div className="md-stepper-horizontal">
                            <div 
                                className={`md-step ${activeTab === 0 ? "active" : "inactive"}`} 
                                onClick={() => onClickTab(0)}
                            >
                                <div className="md-step-img-circle" >
                                    <div className="doc-type-tab-icon"></div>
                                </div>
                                <div className="md-step-title">Select Document Type</div>
                            </div>
                            <div 
                                className={`md-step ${activeTab === 1 ? "active" : "inactive"}`} 
                                onClick={() => onClickTab(1)}
                            >
                                <div className="md-step-img-circle">
                                    <div className="upload-support-tab-icon"></div>
                                </div>
                                <div className="md-step-title ">Upload Document/ Add URL</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-tab-content">
                        {activeTab === 0 
                            ? (<GrooveRadioButton
                                callbackFunction={(e) =>{
                                    setDocType(e);
                                }}
                                radioBtnValues={radioBtnValues}
                                radioBtnTheme="Light"
                                customClassName="upload-support-radio"
                            />) 
                            : radioSelected === 2 || radioSelected === 3 
                                ? <ModalFileUpload 
                                    radioSelected={radioSelected}
                                    supportDocs={supportDocs}
                                  />
                                : <ModalFileLink 
                                    radioSelected={radioSelected}
                                    supportLinks={supportLinks}
                                  />
                        }
                    </div>
                    <div className="modal-buttons">
                        <GrooveButton
                            id="primry-btn-1"
                            name="Solid Button Primary-Ops"
                            isDisabled={false}
                            hasIcon={false}
                            type="outline"
                            colorClass="stat-alternate"
                            size="auto"
                            text={activeTab === 0 ? "Cancel" : "Back"}
                            callbackFunction={() => {
                                activeTab === 0 ? 
                                    onShowModal(false) : 
                                    onClickTab(0);
                            }}
                        />
                        <GrooveButton
                            id="primry-btn-2"
                            name="Solid Button Primary-Ops"
                            isDisabled={
                                activeTab === 1 &&
                                    ((uploadSupportDoc.link === "" && [0, 1].includes(radioSelected)) ||
                                    (Object.keys(uploadSupportDoc.file).length === 0 && [2, 3].includes(radioSelected)))
                            }
                            hasIcon={false}
                            type="solid"
                            colorClass="stat-alternate"
                            size="auto"
                            text={activeTab === 0 ? "Next" : "Save"}
                            callbackFunction={() => { 
                                activeTab === 0 ? 
                                    onClickTab(1) : 
                                    onClickSave();
                            }}
                        />
                    </div>
                </React.Fragment>
            );
        }
    }

    class ModalTabsContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                activeTab: 0,
                radioBtnValues: [
                    {
                        radioBtnLabel: 'Tool User Manual (SVM User Manual)',
                        radioBtnValue: 0,
                        defaultChecked: true,
                        name: 'radio-doctype',
                        id: 'radioTUS',
                    },
                    {
                        radioBtnLabel: 'Tool Orientation Session Record (Media Exchange Recording)',
                        radioBtnValue: 1,
                        defaultChecked: false,
                        name: 'radio-doctype',
                        id: 'radio-TOSR',
                    },
                    {
                        radioBtnLabel: 'SynOps Value Meter RACI',
                        radioBtnValue: 2,
                        defaultChecked: false,
                        name: 'radio-doctype',
                        id: 'radioSVMRACI',
                    },
                    {
                        radioBtnLabel: 'Client Story Template',
                        radioBtnValue: 3,
                        defaultChecked: false,
                        name: 'radio-doctype',
                        id: 'radio-CST',
                    },
                ],
            };
        }

        onClickTab = (tabId) => {
            let radioBtnValues =  this.state.radioBtnValues;
            let radioSelected = this.props.radioSelected;

            if(tabId === 0) {
                radioBtnValues.map((rad) => {
                    if(rad.radioBtnValue === radioSelected) {
                        rad.defaultChecked = true;
                    } else {
                        rad.defaultChecked = false;
                    }
                    return rad;
                })

                let uploadSupportDoc = {
                    link: "",
                    file: {}
                };
                this.props.setUploadSupportDoc(uploadSupportDoc)
            }

            this.setState({
                radioBtnValues: radioBtnValues,
                activeTab: tabId
            })
        }

        onShowModal = (show) => {
            this.setState({
                activeTab: 0
            }, () => {
                this.props.onShowModal(show)
            })
        }
    
        render() {
          return (
            <ModalTabsPresentaional
                {...this.state}
                {...this.props}
                onShowModal={this.onShowModal}
                onClickTab={this.onClickTab}
            />
          );
        }
      }
    
      return ModalTabsContainer;
})();

export default ModalTabs;