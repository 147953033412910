import React, { Component } from "react";
import { GrooveInput, GrooveButton, GrooveTextBox } from "tfo-groove";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GrooveSelectMenuBasic } from "tfo-groove";
import { IsValid, IsValidText } from "../../Utilities/Validations";
import CVMModal from "../Shared/CVMModal/CVMModal";

import { GrooveIconSvg } from "tfo-groove";
import CVMInputFieldWithRadio from "../Shared/CVMInputFieldWithRadio/CVMInputFieldWithRadio";
import CVMMultiSelectDropdownWithCheckBox from "../Shared/CVMMultiSelectDropdownWithCheckBox/CVMMultiSelectDropdownWithCheckBox"

import { IsValidStr } from "../../Utilities/Validations";
import { HttpPost } from '../../Utilities/HTTPUtil';
import "./SendForApproval.css";

const SendForApproval = (function () {
  class SendForApprovalPresentational extends Component {
    render() {
      const form = this.props.form;
      const formCB = this.props.formCB;
      let tDelBOIList = this.props.deletedBOI !== undefined ? (this.props.deletedBOI.length > 0 ? this.props.deletedBOI : []) : [];
      let isDelBOINew = false;
      let delBOIList = tDelBOIList.filter((del) => del.BOIStatus !== "");
      isDelBOINew = delBOIList.length > 0 ? false : true;
      let shouldShowDeletedReason = this.props.shouldShowDeletedReason !== undefined ? this.props.shouldShowDeletedReason : false;
      let noSelectedApprovers = false;
      if (this.props.type === "program") {
        noSelectedApprovers = this.props.defaultData.length === 0 ? true : false;
      }
      let noApprovers = this.props.approverList.length === 0;
      return (
        <React.Fragment>
          <GrooveButton
            d="primry-btn-2"
            type="solid"
            colorClass="stat-alternate"
            text="Send for Approval"
            callbackFunction={this.props.onClickSFA}
            isDisabled={this.props.isDisabled || this.props.shouldDisable}
          />
          <CVMModal
            title="Send for Approval"
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            size={this.props.size}
            content={
              <div className="ppc-sfa-modal-container">
                <div className="modal-message">{this.props.message}</div>
                {this.props.deletedBOI !== undefined ? (
                  this.props.deletedBOI.length > 0 && !isDelBOINew && shouldShowDeletedReason ? (
                    <div>
                      <div className="deleted" style={{ fontWeight: '600', paddingBottom: '20px' }}>
                        Deleted Business Outcome Indicators (
                        {delBOIList.length})
                      </div>
                      {this.props.deletedBOI.map((boi) => {
                        if (boi.BOIStatus !== "") {
                          const hasValue =
                            this.props.reasonValue.length > 0
                              ? this.props.reasonValue.filter(
                                (x) => x.id === boi.BOIID && x.boiClientProgramTargetID === boi.BOIClientProgramTargetID
                              )
                              : [];
                          return (
                            <div key={boi.BOIID}>
                              <div>
                                <div className="deleted-item">
                                  {boi.BOIName}
                                  <div className="info-icon">
                                    <GrooveIconSvg
                                      size="large"
                                      name="info-circle"
                                      iconStyle="solid"
                                      iconColor="stat-alternate"
                                    />
                                    <span className="tooltiptext">
                                      {boi.Description}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="comments">
                                <GrooveTextBox
                                  id="comments"
                                  name="comments"
                                  type="text"
                                  placeholder={
                                    hasValue.length > 0
                                      ? hasValue[0].id === boi.BOIID &&
                                        hasValue[0].boiClientProgramTargetID === boi.BOIClientProgramTargetID &&
                                        hasValue[0].value !== ""
                                        ? "Reason *"
                                        : "Enter Reason *"
                                      : "Enter Reason *"
                                  }
                                  maxLength={255}
                                  rows="2"
                                  callbackFunction={(e) =>
                                    this.props.onChangeParam(e, boi.BOIID, boi.BOIClientProgramTargetID)
                                  }
                                  customClassName={
                                    boi["inValidReason"] || false
                                      ? "input-invalid"
                                      : null
                                  }
                                />
                                {boi["inValidReason"] || false ? (
                                  <label
                                    style={{ margin: "7px 0 2px", color: "red" }}
                                  >
                                    Missing Required Field
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : null
                ) : null}

                {this.props.modifiedBOI !== undefined ? (
                  this.props.modifiedBOI.length > 0 ? (
                    <div>
                      <div className="deleted" style={{ fontWeight: '600', paddingBottom: '20px' }}>
                        Edited Business Outcome Indicators (
                        {this.props.modifiedBOI.length})
                      </div>
                      {this.props.modifiedBOI.map((boi) => {
                        const hasValue =
                          this.props.reasonValue.length > 0
                            ? this.props.reasonValue.filter(
                              (x) => x.id === boi.BOIID && x.boiClientProgramTargetID === boi.BOIClientProgramTargetID
                            )
                            : [];
                        return (
                          <div key={boi.BOIID}>
                            <div>
                              <div className="deleted-item">
                                {boi.BOIName}
                                <div className="info-icon">
                                  <GrooveIconSvg
                                    size="large"
                                    name="info-circle"
                                    iconStyle="solid"
                                    iconColor="stat-alternate"
                                  />
                                  <span className="tooltiptext">
                                    {boi.Description}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="comments">
                              <GrooveTextBox
                                id="comments"
                                name="comments"
                                type="text"
                                placeholder={
                                  hasValue.length > 0
                                    ? hasValue[0].id === boi.BOIID &&
                                      hasValue[0].boiClientProgramTargetID === boi.BOIClientProgramTargetID &&
                                      hasValue[0].value !== ""
                                      ? "Reason"
                                      : "Enter Reason"
                                    : "Enter Reason"
                                }
                                maxLength={255}
                                rows="2"
                                callbackFunction={(e) =>
                                  this.props.onChangeParamModified(e, boi.BOIID, boi.BOIClientProgramTargetID)
                                }
                                customClassName={
                                  boi["inValidReason"] || false
                                    ? "input-invalid"
                                    : null
                                }
                              />
                              {boi["inValidReason"] || false ? (
                                <label
                                  style={{ margin: "7px 0 2px", color: "red" }}
                                >
                                  Missing Required Field
                                </label>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null
                ) : null}

                {
                  this.props.type === "project" ? (
                    <GrooveSelectMenuBasic
                      name="Approver ID"
                      isDisabled={true}
                      placeholder="Approver ID"
                      list={this.props.approverList || []}
                      defaultData={this.props.defaultData}
                      id="approver"
                    //formCB
                    //form
                    />
                  ) : (
                    <CVMMultiSelectDropdownWithCheckBox
                      options={this.props.approverList}
                      id="approver"
                      placeholder="Select Approver ID"
                      value={this.props.defaultData || []}
                      isDisabled={false}
                      label={'Approver ID'}
                      customClassName={'sfa-approver-dd'}
                      onChange={(e) => this.props.onChangeApprover(e)}
                      isRequired={true}
                    />
                  )
                }

                <div className="comments">
                  <GrooveTextBox
                    id="comments"
                    name="comments"
                    type="text"
                    placeholder={`${form.comment !== "" ? "" : "Enter "
                      }Comment`}
                    maxLength={255}
                    rows="2"
                    callbackFunction={(e) => this.props.onChangeComment(e)}
                  />
                </div>
                <div className="add-program-buttons">
                  {
                    this.props.isUserValid === false ?
                      <div className="missing">Invalid Accenture ID</div>
                      : ""
                  }
                  {
                    noApprovers ?
                      <div className="missing">There is no user mapped to this program. Please reach out to SVM.Admin.</div>
                      : ""
                  }

                  {
                    this.props.showError.show ?
                      <div className="missing">{this.props.showError.message}</div>
                      :
                      ""
                  }
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Cancel"
                    callbackFunction={this.props.onCloseModal}
                  />
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={this.props.disableSendBtn || this.props.isDisabled || noSelectedApprovers || noApprovers || this.props.invalidComment || !this.props.isUserValid}
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Send"
                    callbackFunction={this.props.onClickSend}
                  />
                </div>
              </div>
            }
          />
        </React.Fragment>
      );
    }
  }

  class SendForApprovalContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: false,
        form: {
          comment: "",
          approverList: [],
        },
        deletedBOI: [],
        modifiedBOI: [],
        isDisabled: false,
        deletedDimension: [],
        reasonValue: [],
        invalidComment: false,
      };
    }

    componentDidMount = () => {
      this.changeTextAreaClass();
    };
    componentDidUpdate = () => {
      this.changeTextAreaClass();
    };

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll(
        "div.groove-textbox-custom-label"
      );
      classGrooveTextBox.forEach((classGroove) => {
        classGroove.classList.remove("groove-textbox-custom-label");
        classGroove.classList.add("groove-input-custom-label");
      });
    };

    onCloseModal = () => {
      this.setState({
        form: {
          comment: "",
        },
      });
      this.props.onCloseModal();
    };

    onClickSFA = async () => {
      try {
        this.props.onClickSFA();
      } catch (e) {
        console.log(e);
      }
    };

    onClickSend = () => {
      try {
        this.props.onClickSend(this.state.form.comment);
      } catch (e) {
        console.log(e);
      }
    };

    onChangeParam = (e, boiID, boiClientProgramTargetID) => {
      try {
        let value = this.state.reasonValue;

        if (value.length > 0) {
          const isExist = value.filter((x) => x.id === boiID && x.boiClientProgramTargetID === boiClientProgramTargetID);

          if (isExist.length > 0) {
            isExist.map((x) => {
              x.value = e.value;
              x.boiClientProgramTargetID = boiClientProgramTargetID;
              return x;
            });
          } else {
            value.push({
              value: e.value,
              id: boiID,
              boiClientProgramTargetID: boiClientProgramTargetID,
            });
          }
        } else {
          value.push({
            value: e.value,
            id: boiID,
            boiClientProgramTargetID: boiClientProgramTargetID,
          });
        }
        this.setState(
          {
            reasonValue: value,
          },
          () => {
            this.props.onChangeDeleteComment(e.value, boiID, boiClientProgramTargetID);
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onChangeParamModified = (e, boiID, boiClientProgramTargetID) => {
      try {
        let value = this.state.reasonValue;

        if (value.length > 0) {
          const isExist = value.filter((x) => x.id === boiID && x.boiClientProgramTargetID === boiClientProgramTargetID);

          if (isExist.length > 0) {
            isExist.map((x) => {
              x.value = e.value;
              x.boiClientProgramTargetID = boiClientProgramTargetID;
              return x;
            });
          } else {
            value.push({
              value: e.value,
              id: boiID,
              boiClientProgramTargetID: boiClientProgramTargetID,
            });
          }
        } else {
          value.push({
            value: e.value,
            id: boiID,
            boiClientProgramTargetID: boiClientProgramTargetID,
          });
        }
        this.setState(
          {
            reasonValue: value,
          },
          () => {
            this.props.onChangeModifiedComment(e.value, boiID, boiClientProgramTargetID);
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onChangeComment = (e) => {      
      this.setState({
        form: {
          comment: e.value,
        },
        invalidComment: !IsValidText(e.value)
      });
      
    };

    onChangeApprover = async(e) => {

      if(e.length === 0) {
        if (this.props.type === "program") {
          this.props.onChangeApprover(e)
        }
        return;
      }

      const data = await HttpPost(`User/validateEmail`,{
        isDomainAppend: true,
        users: e.map(x => x.text)
      });

      let isUserValid = true;

      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          isUserValid = false;
          break;
        }
      }

      console.log(isUserValid)

      this.setState({
        isUserValid
      });

      if (this.props.type === "program") {
        this.props.onChangeApprover(e)
      }
    }
    render() {
      return (
        <SendForApprovalPresentational
          form={this.state.form}
          formCB={this.formCB}
          approverList={this.props.approverList}
          show={this.props.show}
          enableApproverDD={this.props.enableApproverDD || false}
          deletedBOI={this.props.deletedBOI || []}
          modifiedBOI={this.props.modifiedBOI || []}
          onCloseModal={this.onCloseModal}
          onClickSFA={this.onClickSFA}
          onClickSend={this.onClickSend}
          size={this.props.size}
          message={this.props.message}
          defaultData={this.props.defaultData}
          onChangeComment={this.onChangeComment}
          onChangeParam={this.onChangeParam}
          onChangeParamModified={this.onChangeParamModified}
          onChangeApprover={this.onChangeApprover}
          isDisabled={this.state.isDisabled}
          shouldDisable={this.props.shouldDisable}
          reasonValue={this.state.reasonValue}
          shouldShowDeletedReason={this.props.shouldShowDeletedReason}
          type={this.props.type}
          disableSendBtn={this.props.disableSendBtn || false}
          showError={this.props.showError}
          invalidComment={this.state.invalidComment}
          isUserValid={this.state.isUserValid}
        />
      );
    }
  }

  return SendForApprovalContainer;
})();

export default connect(
  (state) => {
    return {
      showError: state.SharedData.showError
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(SendForApproval);
