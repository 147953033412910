import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircleProgress } from "react-gradient-progress";
import { Row, Col } from "react-bootstrap";
import { HttpGet } from "../../../Utilities/HTTPUtil";
import { setStageReportingYearParam } from "../../../Actions/Stage.Action";
import "./ClientStageStyles.css";
import { IsArray } from "../../../Utilities/Validations";

const ClientStage = (function () {
  class ClientStagePresentational extends Component {
    render() {
      const prog = this.props.selectedProgramDetails;
      const projList = this.props.projectList;
      return (
        <div className="client-config-container">
          <div className="stepper-tab-left-section">
            <div
              data-testid="div-program-title-count"
              className="tab-section-header header18-semibold-midnight"
            >
              Programs ({this.props.programList.length})
            </div>
            <div className="subtab-list">
              <ul className="bodytext16-medium-midnight">
                {this.props.programList.map((e) => {
                  return (
                    <li
                      data-testid={`li-program-${e.id}`}
                      onClick={() => {
                        this.props.onSelectProgram(e);
                      }}
                      className={
                        e.id === this.props.selectedProgram.id ? "active" : null
                      }
                      key={e.id}
                    >
                      {e.programName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="stepper-tab-right-section">
            <div
              data-testid="div-program-name"
              className="tab-section-header bodytext18-medium-midnight"
            >
              {prog.programName}
            </div>
            <Row className="progress-detail-section">
              <Col md={3} lg={2} className="progress-visual">
                <div className="progress-bar-unit">
                  <CircleProgress
                    data-testId="circle-program-stage"
                    percentage={(prog.currentStageID / 5) * 100}
                    primaryColor={["#a100ff", "#00baff"]}
                    secondaryColor={"#eeeeee"}
                    width={130}
                  />
                </div>
                <div className="progressbar-innertext">
                  <h5 data-testid="h5-program-stage">
                    0{prog.currentStageID}/05
                  </h5>
                </div>
                <div className="progress-label bodytext12-medium-slate">
                  {prog.currentStage}
                </div>
              </Col>
              <Col md={9} lg={10} className="program-details">
                <div className="details-row">
                  <span
                    className="Text-label bodytext16-medium-slate"
                    style={{ "margin-right": "10px" }}
                  >
                    Program Lead:
                  </span>
                  <span
                    data-testid="span-program-vmProgLead"
                    className="Text-value bodytext16-regular-slate"
                  >
                    {prog.programLead}
                  </span>
                </div>
                <div className="details-row">
                  <span
                    className="Text-label bodytext16-medium-slate"
                    style={{ "margin-right": "10px" }}
                  >
                    Offering:
                  </span>
                  <span
                    data-testid="span-program-offering"
                    className="Text-value bodytext16-regular-slate"
                  >
                    {prog.offering}
                  </span>
                </div>
              </Col>
            </Row>

            <hr />
            <div className="project-list-details">
              <div
                data-testid="div-project-count"
                className="bodytext16-medium-midnight"
              >
                Projects (
                {`${projList.length < 10 ? "0" : ""}${projList.length}`})
              </div>
              {projList.map((e) => {
                let stage = [];
                for (let s = 1; s < 6; s++) {
                  stage.push(
                    <span
                      data-testid={`span-project-stage-${e.id}-${s}`}
                      className={`progress-step ${s < e.currentStageID
                        ? "done"
                        : s === e.currentStageID
                          ? "active"
                          : ""
                        }`}
                    />
                  );
                }

                return (
                  <div className="project-detail-section">
                    <div className="project-detail-header">
                      <div
                        data-testid={`div-project-name-${e.id}`}
                        className="bodytext16-medium-midnight"
                      >
                        {e.projectName}
                      </div>
                      <div className="stepped-progressbar">
                        <p className="bodytext14-regular-slate">
                          Project Details Stage:
                        </p>
                        <div className="progressbar-stepslist">{stage}</div>
                      </div>
                    </div>
                    <div className="project-detail-row">
                      <span className="row-label bodytext14-medium-slate">
                        Program Lead:
                      </span>
                      <span
                        data-testid={`span-project-${e.ID}-projectLead`}
                        className="row-value bodytext14-regular-slate)"
                      >
                        {e.projectLead}
                      </span>
                    </div>
                    <div className="project-detail-row">
                      <span className="row-label bodytext14-medium-slate">
                        Offering:
                      </span>
                      <span
                        data-testid={`span-project-${e.ID}-offering`}
                        className="row-value bodytext14-regular-slate)"
                      >
                        {e.offering}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }

  class ClientStageContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedProgram: {},
        programList: [],
        selectedProgramDetails: {},
        projectList: [],
        clientID: this.props.clientID,
      };
    }

    componentDidMount() {
      if (this.state.clientID > 0) {
        this.loadInitialData();
      }
    }

    static getDerivedStateFromProps(props, state) {
      if (props.clientID !== state.clientID) {
        return {
          clientID: props.clientID,
        };
      }
      return null;
    }

    componentDidUpdate(prevProps) {
      if (
        this.props.clientID !== prevProps.clientID ||
        this.props.ClientConfigurationData.workFlowReportingYear !==
        prevProps.ClientConfigurationData.workFlowReportingYear
      ) {
        this.loadInitialData();
      }
    }

    loadInitialData = async () => {
      const reportingYear = this.props.ClientConfigurationData.workFlowReportingYear
      const progList = await this.getProgramList(this.state.clientID, reportingYear)
      const tRepYearList = this.props.ClientConfigurationData.selectedReportingYear
      if (progList.length > 0) {
        this.setState({ selectedProgram: progList[0] });
        this.getProgramDetails(
          this.state.clientID,
          progList[0].id,
          reportingYear
        )
        this.getProjectList(this.state.clientID, progList[0].id, reportingYear)
        this.props.setStageReportingYearParam({
          options: IsArray(tRepYearList) ? tRepYearList : [tRepYearList],
          value: { value: reportingYear, label: String(reportingYear) },
        })
      } else {
        this.setState({
          selectedProgram: {},
          projectList: [],
          selectedProgramDetails: {},
        })
        this.props.setStageReportingYearParam({
          options: IsArray(tRepYearList) ? tRepYearList : [tRepYearList],
          value: { value: reportingYear, label: String(reportingYear) },
        })
      }
    }

    getProgramList = async (clientID, reportingYear) => {
      const progList = await HttpGet(`client/getProgramList?clientID=${clientID}&reportingYear=${reportingYear}`)
      this.setState({ programList: progList });
      return progList;
    }

    getProgramDetails = async (clientID, programID, reportingYear) => {
      const details = await HttpGet(`client/getClientStageProgramDetails?clientID=${clientID}&programID=${programID}&reportingYear=${reportingYear}`)
      this.setState({ selectedProgramDetails: details });
    }

    getProjectList = async (clientID, programID, reportingYear) => {
      const projList = await HttpGet(`client/getClientStageProjectList?clientID=${clientID}&programID=${programID}&reportingYear=${reportingYear}`)
      this.setState({ projectList: projList });
    }
    onSelectProgram = (program) => {
      const reportingYear = this.props.ClientConfigurationData.workFlowReportingYear;
      this.setState({ selectedProgram: program });
      this.getProgramDetails(this.state.clientID, program.id, reportingYear);
      this.getProjectList(this.state.clientID, program.id, reportingYear);
    }

    render() {
      return (
        <ClientStagePresentational
          selectedProgram={this.state.selectedProgram}
          programList={this.state.programList}
          selectedProgramDetails={this.state.selectedProgramDetails}
          projectList={this.state.projectList}
          onSelectProgram={this.onSelectProgram}
        />
      );
    }
  }

  return ClientStageContainer;
})();

export default connect(
  (state) => {
    return {
      clientID: state.SharedData.selectedClient.value,
      ClientConfigurationData: state.ClientConfigurationData,
      StageData: state.StageData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign({}, { setStageReportingYearParam }),
      dispatch
    )
)(ClientStage);
