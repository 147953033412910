import React, { Component } from 'react';
import { FormatCVMParamValue, TryGetObjValueAlt } from '../../../Utilities/Formatter';
import { IsValid, HasValue } from '../../../Utilities/Validations';
import { GrooveIconSvg } from 'tfo-groove';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

require('./CVMInputFieldWithRadio.css');
const CVMInputFieldWithRadio = (function () {
  
  class CVMInputFieldWithRadioPresentational extends Component {
    
    render() {
      const boi = this.props.boi;
      let copy=sessionStorage.getItem("copy");
      const status = boi.boiStatus.toLowerCase();
      const paramFieldCss = `cvm-text-input  ${boi.offeringName && boi.offeringName.includes('Agnostic') ? 'agnostic-boi-input-container':''}
      ${IsValid(boi['inValidParam']) && 'approved' === status ? ' input-error-rejected' : ''}
      ${this.props.type.toLowerCase() === 'program' && this.props.isApproverEditView ? '' :
          ['view', 'viewWithComment'].indexOf(this.props.mode) > -1
            ? ' disabled-id-val-field'
            : ''
        } `;

      const commentCss = `cvm-input-textarea
      ${status === 'rejected'
          ? boi['inValidComment'] || boi['inValidComment'] === 'invalid text' || false
            ? ' cvm-input-textarea-rejected cvm-input-textarea-rejected-req'
            : ' cvm-input-textarea-rejected'
          : ''
        }
      ${status === 'conditional approval'
          ? boi['inValidComment'] || boi['inValidComment'] === 'invalid text' || false
            ? ' cvm-input-textarea-con-app cvm-input-textarea-con-app-req'
            : ' cvm-input-textarea-con-app'
          : ''
        } `;
      return (
        <React.Fragment>
          <input
            className={paramFieldCss}
            type="text"
            name="name"
            value={boi.paramValue === null ? "" : boi.paramValue}
            onChange={(e) => this.props.onChangeParam(e)}
            onBlur={this.props.onChangeParam}
            disabled={
              (!this.props.isSuperAdmin && this.props.selectedProjectStatus==='Approved' && copy !== "true")||
              (this.props.mode === 'view' ||
                this.props.mode === 'viewWithComment' 
            //||
            //     this.props.mode === 'review'
              ) &&
                ((this.props.type.toLowerCase() === 'program' && !this.props.isApproverEditView) ||
                  this.props.type.toLowerCase() === 'project')
                ? 'disabled'
                : ''
            }
            placeholder={boi.offeringName && boi.offeringName.includes('Agnostic') ? 'Enter Target Value*' : 'Enter Target Value'}
          />
          {(IsValid(this.props.type) ? this.props.type.toLowerCase() === 'project' : false) &&
            boi.offeringName.includes('Agnostic') &&
            (this.props.mode === 'view' ||
              this.props.mode === 'viewWithComment'
              //  ||
              // this.props.mode === 'review'
              ) ? (
            <span className="targetvalue tooltiptext">
              Target Value can be edited at program level
            </span>
          ) : null}
          {(boi.inValidMsg||boi.paramValue&&boi.paramValue.length>=12)? (
          
            <label style={{ margin: '7px 0 2px', color: 'red' }}>{'You have reached your maximum limit of characters allowed.'}</label>
          ) : (
            <></>
          )}
          {this.props.isAgnosticBOIEMpty && boi.offeringName.includes('Agnostic') && !boi.paramValue ? 
          <label style={{ margin: '7px 0 2px', color: 'red' }}>{'Missing required field.'}</label> : <></>}
          {this.props.mode === 'review' && (
            <div
              // className={
              //   boi.boiWheelVisibility
              //     ? 'cvm-radio-container'
              //     : 'cvm-radio-container disabled-toggle-parent'
              // }
              className='cvm-radio-container'
            >
              <div className={status === 'approved' || status === '' ? 'cvm-radio selected-radio' : 'cvm-radio'}>
                <div
                  className="cvm-radio-button"
                  onClick={() => this.props.onChangeStatus('Approved')}
                >
                  <div></div>
                </div>
                <div className="cvm-radio-label">Approve</div>
              </div>
              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id="reject-rdbtn-tooltip">
                    This action will reject the BOI for the program. You may modify the Target Value instead.
                  </Tooltip>
                }
              >
                <div className={status === 'rejected' ? 'cvm-radio selected-radio' : 'cvm-radio'}>
                  <div
                    // className={
                    //   boi.boiWheelVisibility ? 'cvm-radio-button' : 'cvm-radio-button disabled-toggle'
                    // }
                    className='cvm-radio-button'
                    onClick={() => this.props.onChangeStatus('Rejected')}
                  >
                    <div></div>
                  </div>

                  <div className="cvm-radio-label">Reject</div>

                </div>
              </OverlayTrigger>
              <div
                className={
                  status === 'conditional approval' ? 'cvm-radio selected-radio' : 'cvm-radio'
                }
              >
                <div
                  // className={
                  //   boi.boiWheelVisibility ? 'cvm-radio-button' : 'cvm-radio-button disabled-toggle'
                  // }
                  className='cvm-radio-button'
                  onClick={() => this.props.onChangeStatus('Conditional Approval')}
                >
                  <div></div>
                </div>
                <div className="cvm-radio-label">Conditional Approval</div>
              </div>
            </div>
          )}
          {['rejected', 'conditional approval'].indexOf(status) > -1 &&
            this.props.mode === 'review' ? (
            <>
              <textarea
                maxlength={255}
                className={commentCss}
                placeholder={
                  status === 'rejected' ? 'Rejection Comment*' : 'Conditional Approval Comment*'
                }
                onChange={this.props.onChangeComment}
                value={boi.comment}
              ></textarea>
              {boi['inValidComment'] === 'invalid text' ? (
                <label style={{ margin: '7px 0 2px', color: 'red' }}>Invalid comment</label>
              ) : boi['inValidComment'] ? (
                <label style={{ margin: '7px 0 2px', color: 'red' }}>Missing required field</label>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {
            status === 'rejected' && this.props.mode === 'review' && (
              <label className="reject-msg">
                <GrooveIconSvg
                  size="large"
                  name="exclamation-circle"
                  iconStyle="solid"
                  iconColor="stat-alternate"
                  className="reject-BOI-msg"
                /> {this.props.type === 'Program' ? 'Rejected BOIs will continue to be part of Program configuration but will not be shown in the dashboard.'
                  : 'Rejected BOIs will remain part of Project Config as rejected BOIs, however, will not be displayed on the dashboard wheel – project details section'}</label>
            )
          }
        </React.Fragment>
      );
    }
  }

  class CVMInputFieldWithRadioContainer extends Component {
    constructor() {
      super();
      this.state = {
        tempComment: '',
        tempStatus: '',
        flg: true,
      };
    }

    onChangeStatus = (status) => {
      if (this.state.flg) {
        this.setState({
          tempComment: this.props.boi.comment,
          tempStatus: this.props.boi.boiStatus,
          flg: false,
        });
      }
      if (status === this.state.tempStatus) {
        this.props.boi.comment = this.state.tempComment;
      } else {
        this.props.boi.comment = '';
      }
      this.props.callbackFunction(
        'status',
        this.props.dimI,
        this.props.subDI,
        this.props.boiI,
        status
      );
    };
    onChangeParam = (e) => {
      this.props.callbackFunction(
        'parameter',
        this.props.dimI,
        this.props.subDI,
        this.props.boiI,
        FormatCVMParamValue(
          e.type,
          e.target.value,
          TryGetObjValueAlt(this.props.boi, this.props.boi.uom, 'uom.label')
        )
      );
    };
    onChangeComment = (e) => {
      this.props.callbackFunction('comment', this.props.dimI, this.props.subDI, this.props.boiI, e);
    };

    render() {
      return (
        <CVMInputFieldWithRadioPresentational
          isError={this.props.isInvalid}
          boi={this.props.boi}
          onChangeStatus={this.onChangeStatus}
          onChangeParam={this.onChangeParam}
          onChangeComment={this.onChangeComment}
          isSuperAdmin={this.props.isSuperAdmin}
          basicDetails={this.props.basicDetails}
          // isApprover={this.props.isApprover}
          mode={this.props.mode}
          type={HasValue(this.props.type) ? this.props.type : ""}
          isApproverEditView={HasValue(this.props.isApproverEditView) ? this.props.isApproverEditView : false}
          isAgnosticBOIEMpty={this.props.isAgnosticBOIEMpty}
          selectedProjectStatus ={this.props.selectedProjectStatus}
        />
      );
    }
  }

  return CVMInputFieldWithRadioContainer;
})();

export default CVMInputFieldWithRadio;
