import React, { Component } from 'react';
import { GrooveTabComponent, GrooveIconSvg, GrooveRadioButton } from 'tfo-groove';

require('./TabbedDropdown.css');

const TabbedDropdown = (function () {
  class TabbedDropdownPresentational extends Component {
    render() {
      let tab_data = [];
      this.props.list.map((menu, index) => {
        let values = [];
        menu.list.map((opt) => {
          values.push({
            label: opt.label,
            value: opt.value,
            name: 'select',
            id: 'select-agnostic',
          });
        });
        tab_data.push({
          tab_label: menu.name,
          tab_value: index,
          tab_id: menu.name + '-tab',
          tab_content: (
            <>
              <div className="search-bar-container">
                <div className="agnostic-search-bar label14-regular-midnight">
                  <GrooveIconSvg
                    customClassName="close-modal-btn"
                    size="large"
                    name="search"
                    iconStyle="solid"
                    iconColor="stat-neutral"
                  />
                  <input
                    type="text"
                    name="name"
                    placeholder="Search..."
                    autoComplete="off"
                    id="searchBox"
                    className="agnostic-serachbox"
                    maxLength="255"
                    onChange={(e) => {
                      this.props.onSearchAgnosticDropdownValue(
                        e.target.value.toLowerCase(),
                        menu.type
                      );
                    }}
                  />
                </div>
              </div>

              {values.length === 0 ? (
                <div className="select-agnostic-no-option">No available option.</div>
              ) : (
                <div className="tabbed-dropdown-option">
                  {values.map((item) => (
                    <div
                      className={item.id}
                      onClick={() => this.props.onSelect(item.value, item.label)}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </>
          ),
        });
      });
      return (
        <div className="tabbed-dropdown">
          <div
            className={`tabbed-dropdown-field ${this.props.isDisabled ? 'tabbed-disabled' : ''}`}
            onClick={this.props.controllerClick}
          >
            {JSON.stringify(this.props.value) === '{}' ? (
              <div className="tabbed-dropdown-placeholder">{`Select ${this.props.label} ${
                this.props.isRequired ? '*' : ''
              }`}</div>
            ) : (
              <>
                <div className="tabbed-dropdown-label">
                  {this.props.label}
                  {this.props.isRequired ? <span>*</span> : ''}
                </div>
                <div className="tabbed-dropdown-value">{this.props.value.label}</div>
              </>
            )}
            <GrooveIconSvg
              customClassName="tabbed-dropdown-button"
              size="large"
              name={this.props.menuActive ? 'chevron-up' : 'chevron-down'}
              iconStyle="solid"
              iconColor="stat-neutral"
            />
          </div>

          {this.props.menuActive ? (
            <>
              <div className="tabbed-dropdown-overlay" onClick={this.props.controllerClick} />
              <div className="tabbed-dropdown-menu">
                <GrooveTabComponent
                  theme="purple"
                  tab_data={tab_data}
                  selectedIndex={this.props.selectedTab}
                  callbackFunction={this.props.selectAgnosticTab}
                />
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      );
    }
  }

  class TabbedDropdownContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedTab: JSON.stringify(this.props.value) === '{}' ? 0 : this.props.value.tab,
        menuActive: false,
        radioBtnValues: [],
      };
    }

    controllerClick = () => {
      if (!this.props.isDisabled) {
        this.setState({
          menuActive: !this.state.menuActive,
        });
      }
      if (this.state.menuActive) {
        this.props.resetAgnosticDropdownDisplay();
      }
    };

    onSelect = (value, label) => {
      const selected = {
        // tab: parseInt(e.currentTarget.attributes.tab.value),
        value: parseInt(value),
        label: label,
      };
      this.props.callbackFunction(selected);
      this.controllerClick();
    };

    render() {
      return (
        <TabbedDropdownPresentational
          {...this.props}
          selectedTab={this.state.selectedTab}
          menuActive={this.state.menuActive}
          controllerClick={this.controllerClick}
          onSelect={this.onSelect}
        />
      );
    }
  }

  return TabbedDropdownContainer;
})();

export default TabbedDropdown;
