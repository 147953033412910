import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import { HasProperty, IsValidStr, IsValidText } from '../../../Utilities/Validations';
import { TryGetObjValueAlt } from '../../../Utilities/Formatter';
import { getCustomBOIDetails, setStepperActiveIndex, setEditApproved } from '../../../Actions/Shared.Action';
import { getProgramBOIList} from '../../../Actions/ProjectConfiguration.Action';
import { HasValue} from '../../../Utilities/Validations';

const SelectBOIPopup = (function () {
  class SelectBOIPopupPresentational extends Component { 
    render() {
      const subOfferingID = TryGetObjValueAlt(this.props, false, 'newProjectDetails.suboffering');
      let copy=sessionStorage.getItem("copy");
      let hasIOApproverOpt = !subOfferingID
        ? false
        : (this.props.ioApproverList || []).some((ioa) =>
            (subOfferingID || []).some((so) => so.value === ioa.subOfferingID)
          );

      const copiedProjectNameValue= copy == "true" && HasValue(this.props.copiedProjectName)?this.props.copiedProjectName:'';
      const disabled = this.props.numSelected === 0;
      // check if there any dropdown field was empty
      const disableAddBOI =
        this.props.newProjectDetails.projectName.value === '' ||
        this.props.newProjectDetails.projectName.isError ||
        !IsValidText(this.props.newProjectDetails.projectDescription) ||
        this.props.newProjectDetails.suboffering.length === 0 ||
        this.props.newProjectDetails.deliveryLocation.length === 0 ||
        this.props.newProjectDetails.targetEndDate === '' || //if program name already exist
        (Object.keys(this.props.newProjectDetails.ioapprover).length === 0 && hasIOApproverOpt) ||
        (copy !== "true" ? Object.keys(this.props.newProjectDetails.projectcreator).length === 0 : Object.keys(this.props.selectedProjectDetails.ProjectCreator).length ===0) ||
        // Object.keys(this.props.newProjectDetails.projectapprover).length === 0 ||
        Object.keys(this.props.newProjectDetails.deliveredBy).length === 0 ||
        this.props.newProjectDetails.assetName.length === 0 ||
        this.props.newProjectDetails.businessAdvisor.length === 0 ||
        this.props.newProjectDetails.oaLead.length === 0 ||
        this.props.newProjectDetails.cxLead.length === 0 ||
        !IsValidStr(this.props.newProjectDetails.OMID) ||
        !IsValidStr(this.props.newProjectDetails.contractID);
      const disableEditBOI =
        !HasProperty(this.props.selectedProjectDetails, 'ProjectID') ||
        this.props.selectedProjectDetails.ClientProjectName === ''
          ? true
          : false; //if program name already exist
      const title = (
        <div className="modal-title-special">
          Select BOI(s) to {this.props.newProjectDetails.projectName.value}
        </div>
      );
      const tooltipUncheckBoi = (
        <Popover id="uncheckboi-popover" trigger="focus" placement="right">
          <Popover.Content>
            Delivered value is already reported for this BOI for this project. Hence the same cannot
            be de-selected from this project.
          </Popover.Content>
        </Popover>
      );
      return (
        
        <React.Fragment>
          <GrooveButton
            id="primry-btn-2"
            hasIcon={true}
            type="outline"
            colorClass="stat-alternate"
            // text={this.props.mode === "edit" ? "Edit BOIs" : "Select BOIs"}
            text={'Select BOIs'}
            callbackFunction={this.props.onCloseModal}
            iconAlignment="right"
            iconSize="small"
            iconName="plus"
            iconStyle="solid"
            iconId="btn-icon-01"
            isDisabled={copy !== "true" ? (this.props.selectedProjectDetails.OverallStatus=='Approved')
            ||(this.props.mode === 'edit' ? disableEditBOI : disableAddBOI) : copiedProjectNameValue=='' ? true : disableAddBOI
          }
          />
          {/* Modals */}
          <CVMModal
            title={title}
            show={this.props.show}
            onCloseModal={this.props.onCloseBOIModal}
            size={this.props.size}
            special={false}
            content={
              <React.Fragment>
                <div className="cvm-tab-content add-boi-modal">
                  <div className="program-config-link">
                    Navigate to{' '}
                    <span className="program-config-link-span" onClick={() => this.props.onClickEditBOIs(true)}>
                      Program Configuration 
                    </span>{' '}
                    module to add/edit a BOI.
                  </div>
                  <div className="select-boi-modal">
                    <div className="select-boi-header">
                      <div className="select-boi-modal-column">
                        <div
                          className={
                            this.props.selectAll
                              ? 'cvm-custom-check-box select-all-boi-checked'
                              : 'cvm-custom-check-box'
                          }
                          onClick={(e) => {
                            this.props.onSelectAll();
                          }}
                        >
                          <div />
                        </div>
                      </div>
                      <div className="select-boi-modal-column">BOI</div>
                      <div className="select-boi-modal-column">Unit of Measure</div>
                      <div className="select-boi-modal-column">Dimension</div>
                      <div className="select-boi-modal-column">Subdimension</div>
                    </div>
                    <div className="select-boi-body">
                      {this.props.programBOIList.filter((item) => !([4, 5, 6].includes(item.BOIType))).map((item) => {

                        // item.isBOIUsedInRVD = true
                        item.isDisabled = item.isBOIUsedInRVD;
                        return (
                          <div
                            className={
                              item.isDisabled
                                ? 'select-boi-body-row disable-row'
                                : 'select-boi-body-row'
                            }
                          >
                            <div className="select-boi-modal-column">
                              <div
                                className={
                                  item.selected
                                    ? item.isDisabled
                                      ? 'cvm-custom-check-box cvm-custom-checked disable-check-box'
                                      : 'cvm-custom-check-box cvm-custom-checked'
                                    : 'cvm-custom-check-box'
                                }
                                onClick={(e) =>
                                  item.isDisabled ? '' : this.props.onSelectBOI(item)
                                }
                              >
                                <GrooveIconSvg
                                  customClassName="cvm-check-box"
                                  size="small"
                                  name="check"
                                  iconStyle="solid"
                                  iconColor="dl-white"
                                />
                              </div>
                              {item.isBOIUsedInRVD && tooltipUncheckBoi}
                            </div>
                            <div className="select-boi-modal-column boi-name" title={item.BOIName}>
                              {item.BOIName}
                            </div>
                            <div className="select-boi-modal-column" title={item.UOM}>
                              {item.UOM}
                            </div>
                            <div className="select-boi-modal-column" title={item.CoreDimensionName}>
                              {item.CoreDimensionName}
                            </div>
                            <div className="select-boi-modal-column" title={item.SubDimensionName}>
                              {item.SubDimensionName}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="select-boi-footer">
                      {this.props.programBOIList.length < 10
                        ? `0${this.props.programBOIList.length}`
                        : this.props.programBOIList.length}{' '}
                      items,{' '}
                      {this.props.numSelected < 10 && this.props.numSelected > 0
                        ? `0${this.props.numSelected}`
                        : this.props.numSelected}{' '}
                      selected
                    </div>
                  </div>
                </div>
                <div className="add-boi-buttons">
                  {this.props.isExisting ? <p className="missing">BOI already exist</p> : ''}
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    // size="auto"
                    text="Cancel"
                    callbackFunction={() => this.props.onCloseBOIModal()}
                  />

                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    // isDisabled={false}
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    // size="auto"
                    text={
                      ['Edit Rejected', 'Edit'].indexOf(this.props.type) > -1 ? 'Update' : 'Save'
                    }
                    callbackFunction={this.props.onSaveAddBOI}
                    isDisabled={disabled}
                  />
                </div>
              </React.Fragment>
            }
          />
          <CVMModal
            title={''}
            show={this.props.showWarningBOI}
            onCloseModal={this.props.onCloseBOIModal}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                handleCancel={this.props.onCloseBOIModal}
                handleYes={this.props.onCloseModal}
                body={
                  <div>
                    <h5>Are you sure you want to close "Select BOI(s)" modal?</h5>
                    <p>Please note that this would not save your changes.</p>
                  </div>
                }
              />
            }
          />
          <CVMModal
            title={''}
            show={this.props.showConfirmEditBOIs}
            onCloseModal={() => this.props.onClickEditBOIs(false)}
            customclass={'notifyModal-dialog'}
            content={
              <CVMWarningModal
                handleCancel={() => this.props.onClickEditBOIs(false)}
                handleYes={() => this.props.handleConfirmEditBOIs()}
                body={
                  <div>
                    <h5>Are you sure you want to navigate to program configuration?</h5>
                    <p>Please note that this will discard your unsaved changes.</p>
                  </div>
                }
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class SelectBOIPopupContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: false,
        size: 'lg',
        selectedBOI: [],
        showWarningBOI: false,
        programBOIList: [],
        selectAll: false,
        numSelected: 0,
        showConfirmEditBOIs: false,
      };
    }
    componentDidMount = () => {
      this.formatBOIs();
       this.props.getProgramBOIList(
        this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID
      );
    };

    componentDidUpdate = (prevProps) => {
      // Prepare initial list of BOIs
      if (
        prevProps.selectedBOIs !== this.props.selectedBOIs ||
        this.state.selectedBOI !== this.props.selectedBOIs
      ) {
        this.setState(
          {
            selectedBOI: this.props.selectedBOIs,
          },
          this.formatBOIs
        );
      }
    };

    componentWillUnmount = () => {
      this.setState({
        selectedBOI: [],
        showWarningBOI: false,
        programBOIList: [],
        selectAll: false,
        numSelected: 0,
      });
    };

    formatBOIs = (bois) => {
      let selectedBOIs = this.state.selectedBOI;
      let programBOIList = this.props.ProjectConfigurationData.programBOIList.filter((item) => !([4, 5, 6].includes(item.BOIType)));
      let numSelected = 0;
      let isEdit = ['Edit Rejected', 'Edit', 'Add'].indexOf(this.props.type) > -1;
      let currentBoiTargetList =
        this.props.currentBoiTargetList !== undefined
          ? this.props.currentBoiTargetList.length > 0
            ? this.props.currentBoiTargetList
            : []
          : []; // Pass this props if parent container is setting the boiClientTargetID of recently deleted boi to 0
      programBOIList.map((item) => {
        let isSelected = false;
        let isDisabled = false;
        let boiStatus = '';
        let boiClientProjectTargetID = 0;
        let paramValue = item.paramValue;
        let approverComments = '';
        let oldParamValue = item.oldParamValue;
        let isActive = item.IsActive || item.IsActive === 1 ? 1 : 0;
        let isBOIUsedInRVD = false;
        let recentlyDeletedBOIDetails = [];

        //Checks if there are recently deleted boi then added again. Prevents boiClientTargetID of deleted boi to be 0
        if (item.boiClientProjectTargetID === 0 && currentBoiTargetList.length > 0) {
          let findIndex = currentBoiTargetList.findIndex((x) => x.boiID === item.BOIID);
          let findrecentlyDeletedBOIDetails = currentBoiTargetList.find(
            (x) => x.boiID === item.BOIID
          );
          recentlyDeletedBOIDetails =
            findrecentlyDeletedBOIDetails !== undefined ? findrecentlyDeletedBOIDetails : [];
          boiClientProjectTargetID =
            findIndex !== -1
              ? recentlyDeletedBOIDetails.boiClientProjectTargetID
              : boiClientProjectTargetID;
        }

        selectedBOIs.map((dim) => {
          dim.subDimensionList.map((subdim) => {
            subdim.boiList.map((boi, idx) => {
              if (
                boi.boiID === item.BOIID &&
                dim.dimensionID === item.CoreDimensionID &&
                subdim.subDimensionID === item.SubDimensionID
              ) {
                isSelected = boi.isActive === 0 ? false : true;
                numSelected =
                  boi.isActive === 0
                    ? numSelected === 0
                      ? 0
                      : subdim.boiList.length - 1 === idx
                      ? numSelected
                      : numSelected - 1
                    : numSelected + 1;
                isDisabled = isEdit ? (boi.boiStatus !== 'Rejected' ? true : false) : false;
                boiStatus = isEdit ? boi.boiStatus : item.boiStatus;
                boiClientProjectTargetID =
                  recentlyDeletedBOIDetails.length > 0
                    ? boiClientProjectTargetID
                    : isEdit
                    ? boi.boiClientProjectTargetID
                    : boiClientProjectTargetID;
                paramValue = isEdit ? boi.paramValue : item.paramValue ? item.paramValue : '';
                approverComments = isEdit ? boi.comment : item.comment ? item.comment : '';
                oldParamValue = TryGetObjValueAlt(boi, '', 'oldParamValue');
                isActive = isEdit ? (boi.isActive || boi.isActive === 1 ? 1 : 0) : isActive;
                isBOIUsedInRVD = boi.isBOIUsedInRVD;
              }
            });
          });
        });
        item['isBOIUsedInRVD'] = isBOIUsedInRVD;
        item['oldParamValue'] = oldParamValue;
        item['selected'] = isSelected;
        item['paramValue'] = paramValue;
        item['comment'] = approverComments;
        item['boiRealizedValue'] = item.boiRealizedValue ? item.boiRealizedValue : 0;
        item['boiStatus'] = boiStatus;
        item['boiRejectionRecipient'] = item.boiRejectionRecipient
          ? item.boiRejectionRecipient
          : '';
        item['boiCode'] = item.boiCode ? item.boiCode : 0;
        item['isDisabled'] = isDisabled;
        item['boiClientProjectTargetID'] = boiClientProjectTargetID;
        item['IsCustom'] = item.IsCustom;
        item['IsActive'] = isActive;
      });
      this.setState({
        programBOIList: programBOIList,
        numSelected: numSelected,
        selectAll: numSelected === programBOIList.length,
      });
    };

    onCloseBOIModal = (val) => {
      this.setState({
        showWarningBOI: val === 'close' ? false : !this.state.showWarningBOI,
      });
    };

    onCloseModal = async () => {
      if (this.props.getModifiedDeletedBOIs && this.props.prepBOIForSaving) {
        await this.props.getModifiedDeletedBOIs();
        await this.props.prepBOIForSaving();
      }
      this.setState({
        show: !this.state.show,
        selectedBOI: [],
        selectedBOIDisplay: [],
        deletedBOIForUndo: [],
        selectedBOI: [],
        programBOIList: [],
        selectAll: false,
        numSelected: 0,
      });
      this.onCloseBOIModal('close');
    };

    onSaveAddBOI = () => {
      let dimensionList = this.props.ProjectConfigurationData.dimensionList;
      let programBOIList = this.state.programBOIList;
      let isEdit = this.props.type === 'Edit Rejected';
      let list = [];
      let rejectedList = [];
      programBOIList.map((item) => {
        let origStatus = '';
        //if (item.selected) {

        // include the unselected boi from original data with "hide=true" property
        let inDimensionList = false;
        dimensionList.map((dim) => {
          dim.subDimensionList.map((subDim) => {
            subDim.boiList.map((boi) => {
              if (
                boi.boiID === item.BOIID &&
                subDim.subDimensionID === item.SubDimensionID &&
                dim.dimensionID === item.CoreDimensionID
              ) {
                inDimensionList = true;
                origStatus = boi.boiStatus;
              }
            });
          });
        });

        // if(item.selected || inDimensionList){
        if (item.selected) {
          let dimNotExist = true;
          list.map((dim) => {
            if (dim.dimensionID === item.CoreDimensionID) {
              dimNotExist = false;

              // Check if subdimension already added
              let subdimNotExist = true;
              dim.subDimensionList.map((subdim) => {
                if (subdim.subDimensionID === item.SubDimensionID) {
                  subdimNotExist = false;

                  // Check if boi already added
                  let boiNotExist = true;
                  subdim.boiList.map((boi) => {
                    if (boi.boiName === item.BOIName && boi.uom === item.UOM) {
                      boiNotExist = false;
                    }
                  });
                  if (boiNotExist) {
                    subdim.boiList.push({
                      boiID: item.BOIID,
                      boiName: item.BOIName,
                      paramValue: TryGetObjValueAlt(item, '', 'paramValue'), //item.paramValue,
                      oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                      uom: item.UOM,
                      comment: item.comment,
                      boiDescription: item.BOIDescription,
                      boiRealizedValue: item.boiRealizedValue,
                      boiStatus: origStatus,
                      boiClientProjectTargetID: item.boiClientProjectTargetID,
                      boiRejectionRecipient: item.boiRejectionRecipient,
                      boiCode: item.boiCode,
                      IsCustom: item.IsCustom,
                      isActive: item.IsActive ? 1 : 0,
                      isBOIUsedInRVD: item.isBOIUsedInRVD,
                      hide: item.IsActive === 0 || !item.IsActive,
                      offeringName: item.Offering,
                      suboffering: item.SubOfferingName,
                      leadingIndicator: item.LeadingIndicator,
                      boiWheelVisibility: item.WheelVisibility ? 1 : 0,
                      parameterList: item.CustomParameterList ? item.CustomParameterList : [],
                      programBOIStatus: item.BOIStatus
                    });
                  }
                }
              });
              if (subdimNotExist) {
                dim.subDimensionList.push({
                  subDimensionName: item.SubDimensionName,
                  subDimensionID: item.SubDimensionID,
                  subDimensionReason: '',
                  subDimensionStatus: '',
                  subDimensionTarget: '',
                  boiList: [
                    {
                      boiID: item.BOIID,
                      boiName: item.BOIName,
                      paramValue: TryGetObjValueAlt(item, '', 'paramValue'), //item.paramValue,
                      oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                      uom: item.UOM,
                      comment: item.comment,
                      boiDescription: item.BOIDescription,
                      boiRealizedValue: item.boiRealizedValue,
                      boiStatus: origStatus,
                      boiClientProjectTargetID: item.boiClientProjectTargetID,
                      boiRejectionRecipient: item.boiRejectionRecipient,
                      boiCode: item.boiCode,
                      IsCustom: item.IsCustom,
                      isActive: item.IsActive ? 1 : 0,
                      isBOIUsedInRVD: item.isBOIUsedInRVD,
                      hide: item.IsActive === 0 || !item.IsActive,
                      offeringName: item.Offering,
                      suboffering: item.SubOfferingName,
                      leadingIndicator: item.LeadingIndicator,
                      boiWheelVisibility: item.WheelVisibility ? 1 : 0,
                      parameterList: item.CustomParameterList ? item.CustomParameterList : [],
                      programBOIStatus: item.BOIStatus
                    },
                  ],
                });
              }
            }
          });
          if (dimNotExist) {
            list.push({
              dimensionName: item.CoreDimensionName,
              dimensionID: item.CoreDimensionID,
              dimensionReason: '',
              dimensionStatus: '',
              dimensionTarget: '',
              subDimensionList: [
                {
                  subDimensionName: item.SubDimensionName,
                  subDimensionID: item.SubDimensionID,
                  subDimensionReason: '',
                  subDimensionStatus: '',
                  subDimensionTarget: '',
                  boiList: [
                    {
                      boiID: item.BOIID,
                      boiName: item.BOIName,
                      paramValue: TryGetObjValueAlt(item, '', 'paramValue'), //item.paramValue,
                      oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                      uom: item.UOM,
                      comment: item.comment,
                      boiDescription: item.BOIDescription,
                      boiRealizedValue: item.boiRealizedValue,
                      boiStatus: origStatus,
                      boiClientProjectTargetID: item.boiClientProjectTargetID,
                      boiRejectionRecipient: item.boiRejectionRecipient,
                      boiCode: item.boiCode,
                      IsCustom: item.IsCustom,
                      isActive: item.IsActive ? 1 : 0,
                      isBOIUsedInRVD: item.isBOIUsedInRVD,
                      hide: item.IsActive === 0 || !item.IsActive,
                      offeringName: item.Offering,
                      suboffering: item.SubOfferingName,
                      leadingIndicator: item.LeadingIndicator,
                      boiWheelVisibility: item.WheelVisibility ? 1 : 0,
                      parameterList: item.CustomParameterList ? item.CustomParameterList : [],
                      programBOIStatus: item.BOIStatus
                    },
                  ],
                },
              ],
            });
          }
        }
        //} else {
        if (!item.selected) {
          // if (item.boiStatus !== "") {
          rejectedList.push({
            ClientProjectID: this.props.ProjectConfigurationData.selectedProject.value,
            ClientProjectTargetID: item.boiClientProjectTargetID,
            CoreDimensionID: item.CoreDimensionID,
            SubDimensionID: item.SubDimensionID,
            BOIID: item.BOIID,
            BOIName: item.BOIName,
            BOITarget: item.paramValue,
            UOM: item.uom,
            BOIStatus: origStatus,
            approver: '',
            rejectionRecipient: item.boiRejectionRecipient,
            BOIReason: '',
            IsActive: 0,
            Description: item.BOIDescription,
          });
          // }
        }
      });

      this.setState({ show: false });
      // To return the selected bois
      this.props.onSaveAddBOI(list);

      if (this.props.setDeletedBOIList) {
        // To return the deleted bois with status
        this.props.setDeletedBOIList(rejectedList);
      }
    };

    onSelectAll = () => {
      let selectAll = !this.state.selectAll;
      let programBOIList = this.state.programBOIList;
      let isEdit = ['Edit Rejected', 'Edit'].indexOf(this.props.type) > -1;
      let numSelected = 0;
      programBOIList.map((item) => {
        item.selected = item.isDisabled || item.isBOIUsedInRVD ? item.selected : selectAll;
        item.boiStatus = isEdit
          ? this.props.type === 'Edit Rejected'
            ? item.boiStatus
            : selectAll && !item.isDisabled
            ? ''
            : this.props.type === 'Edit'
            ? item.boiStatus
            : ''
          : item.boiStatus;
        numSelected = item.isDisabled ? numSelected + 1 : numSelected;
        item.IsActive = 1;
      });
      this.setState({
        selectAll: selectAll,
        programBOIList: programBOIList,
        numSelected: isEdit
          ? selectAll
            ? programBOIList.length
            : numSelected
          : selectAll
          ? programBOIList.length
          : 0,
      });
    };

    onSelectBOI = (boi) => {
      let programBOIList = this.state.programBOIList;
      let isAllSelected = true;
      let numSelected = programBOIList.length;
      let isEdit = ['Edit Rejected', 'Edit'].indexOf(this.props.type) > -1;
      programBOIList.map((item) => {
        if (
          item.BOIID === boi.BOIID &&
          item.CoreDimensionID === boi.CoreDimensionID &&
          item.SubDimensionID === boi.SubDimensionID
        ) {
          item.boiStatus = isEdit
            ? item.selected
              ? this.props.type === 'Edit' || this.props.type === 'Edit Rejected'
                ? item.boiStatus
                : ''
              : ''
            : item.boiStatus;
          item.selected = !item.selected;
          item.IsActive = item.selected ? 1 : 0;
        }
        if (!item.selected) {
          isAllSelected = false;
          numSelected = numSelected - 1;
          item.IsActive = item.selected ? 1 : 0;
        }
      });
      this.setState({
        selectAll: isAllSelected,
        programBOIList: programBOIList,
        numSelected: numSelected,
      });
    };

    onClickEditBOIs = (e) => {
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      let subOff = [];
      let OALList = '';
      let CXLList = '';
      programDetails.SubOfferingList.map((item) => {
        subOff.push({
          label: item.SubOfferingName,
          value: item.SubOfferingID,
          isFixed: programDetails.Status === 'In Progress' ? false : true,
        });
      });
      newProgramDetails.cxLead.map((x) => (CXLList = CXLList + x.text + ','));
      newProgramDetails.oaLead.map((x) => (OALList = OALList + x.text + ','));
      let isProgDetailsEdit =
        programDetails.ProgramCreator !== newProgramDetails.progCreator.label ||
        JSON.stringify(subOff) !== JSON.stringify(newProgramDetails.subOffering) ||
        CXLList.slice(0, -1) !== programDetails.CXLLead ||
        OALList.slice(0, -1) !== programDetails.OperationsAccountLead;

      if (isProgDetailsEdit) this.setState({ showConfirmEditBOIs: e });
      else this.handleConfirmEditBOIs();
    };

    handleConfirmEditBOIs = () => {
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      this.props.setStepperActiveIndex(2);
      if (programDetails.StatusId === 7 || programDetails.StatusId === 8) {
        this.props.history.push(
          `/ProgramProjectConfig/ProgramAccountLeadershipApproval/approvedboi`
        );
      } else if (programDetails.StatusId ===  4) {
        this.props.history.push(
          `/ProgramProjectConfig/ProgramAccountLeadershipApproval/rejectedboi`
        );
      } else {
        this.props.history.push(
          `/ProgramProjectConfig/ProgramAccountLeadershipApproval/awaitingappboi`
        );
      }
      this.props.setEditApproved(true);
      this.setState({ showConfirmEditBOIs: false });
    };

    render() {
      const { newProjectDetails, selectedProjectDetails, programBOIList } = this.props.ProjectConfigurationData;
      const { IOAApproverList } = this.props.ProgramConfigurationData;
      const{basicDetails}=this.props.ConfigureProgramData
      const { isSuperAdmin } = this.props.userInformation
      return (
        <SelectBOIPopupPresentational
          onCloseModal={this.onCloseModal}
          onSaveAddBOI={this.onSaveAddBOI}
          show={this.state.show}
          size={this.state.size}
          list={this.state.list}
          onSelectDropdown={this.onSelectDropdown}
          isSuperAdmin={isSuperAdmin}
          basicDetails={basicDetails}
          type={this.props.type}
          numSelected={this.state.numSelected}
          showWarningBOI={this.state.showWarningBOI}
          onCloseBOIModal={this.onCloseBOIModal}
          programBOIList={programBOIList}
          onSelectAll={this.onSelectAll}
          onSelectBOI={this.onSelectBOI}
          selectAll={this.state.selectAll}
          //reducers
          newProjectDetails={newProjectDetails}
          selectedProjectDetails={selectedProjectDetails}
          mode={this.props.mode}
          ioApproverList={IOAApproverList}
          onClickEditBOIs={this.onClickEditBOIs}
          showConfirmEditBOIs={this.state.showConfirmEditBOIs}
          handleConfirmEditBOIs={this.handleConfirmEditBOIs}
          copiedProjectName = {this.props.copiedProjectName}
        />
      );
    }
  }

  return SelectBOIPopupContainer;
})();

// export default SelectBOIPopup;
export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      userInformation: state.userInformation,
      ProgramConfigurationData: state.ProgramConfigurationData,
      ProjectConfigurationData: state.ProjectConfigurationData,
      copiedProjectName: state.ProjectConfigurationData.copiedProjectName
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {setStepperActiveIndex, setEditApproved,getProgramBOIList}), dispatch)
)(withRouter(SelectBOIPopup));

SelectBOIPopup.defaultProps = {
  setDeletedBOIList: () => { },
};
