import * as React from "react";

// Msal imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

// Sample app imports
import Loading from "./Loading";
import ErrorComponent from "./ErrorComponent";

const PageLayout = (props) => {
    const authRequest = {
        ...window.webConfig.msalConfig.auth
    };

    //Wrapped the whole layout with MsalAuthenticationTemplate to ensure all pages are secured.
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            {props.children}
        </MsalAuthenticationTemplate>
    );
};

export default PageLayout;

