import React from "react";
import { Provider } from "react-redux";
import { Route, Switch, useHistory, BrowserRouter as Router } from "react-router-dom";
import store, { history } from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "./Static/Style.css";

import {
  Main,
  LoginError,
  AccessDenied,
  DeveloperLogin,
  AccessRequest,
  AuthenticatedRoute,
  Home,
  PageNotFound,
  DisablePopupBlocker,
  SomethingWentWrong,
} from "./Views";
import Utilities from "./Utilities";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./Utilities/NavigationClient";

import { PageLayout } from "./Views/MasterLayout";

/*
    Use AuthenticatedRoute component the user should be authenticated via Database
    Update the _Modules/authenticated-route.js to use your own API.
    Add /:deal to paths if you want to have multi tenant
*/

require("es6-promise").polyfill();
require("isomorphic-fetch");

// history.listen((location, action) => {
//   window.scrollTo(0, 0);
// });

function Application({pca}) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of 
  // the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
      <MsalProvider instance={pca}>
          <PageLayout>
              <Pages />
          </PageLayout>
      </MsalProvider>
  )
}

function Pages() {
  return (
      <Provider store={store}>
          <Router history={history}>
              <Switch>
                <Route exact path="/login">
                  {
                    Utilities.environment.isDevoloperMode ?
                    <DeveloperLogin/> : <Home/>
                    }
                </Route>
                <Route
                  exact
                  path="/logout"
                  component={() => {
                    window.location.href = "/";
                    return null;
                  }}
                />
                <Route exact path="/AccessDenied">
                  <AccessDenied/>
                </Route>
                <Route exact path="/AccessRequest">
                  <AccessRequest/>
                </Route>
                <Route exact path="/LoginError">
                  <LoginError/>
                </Route>
                <Route exact path="/PageNotFound"> 
                  <PageNotFound/>
                </Route>
                <Route exact path="/DisablePopupBlocker">
                  <DisablePopupBlocker/>
                </Route>
                <Route exact path="/SomethingWentWrong">
                  <SomethingWentWrong/>
                </Route>
                <AuthenticatedRoute path="/" component={Main} />
              </Switch>
           </Router>
       </Provider>
  )
}

export default Application;
