import React, { Component } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import {
    GrooveRadioButton,
    GrooveSelectMenuBasic,
    GrooveTextBox,
    GrooveButton,
    GrooveIconSvg,
    GrooveInput
  } from "tfo-groove";

  import CVMMultiSelectDropdown from "../../CVMMutiSelectDropdown/CVMMutiSelectDropdown";

require("../CVMSubmitRejectionModal.css");

const RejectionRecepient = (function() {
  class RejectionRecepientPresentational extends Component {
    render() {
      return (
      <div className="rejection-recipient">
        <p>Rejection Recipient</p>
        <div className="notifyModal-dropdown">
            <CVMMultiSelectDropdown 
              value={this.props.value}
              isDisabled={true}
              label={this.props.label}
            />
        </div>
        </div>
      )
    }
  }

  class RejectionRecepientContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return <RejectionRecepientPresentational 
            value={this.props.value}
            label={this.props.label}
        />;
    }
  }

  return RejectionRecepientContainer;
})();

export default RejectionRecepient;
