import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../store';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import { Col, Accordion, Card, Button, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter from '../Shared/CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter/CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter';
import CVMSingleSelectDropdownWithSearch from '../Shared/CVMSingleSelectDropdownWithSearch/CVMSingleSelectDropdownWithSearch';
import { withRouter } from "react-router-dom";
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import clientDetailIcon from './../../Static/images/newClientConfig/clientDetailIcon.svg';
import listViewIcon from './../../Static/images/newClientConfig/listViewIcon.svg';
import cardViewIcon from './../../Static/images/newClientConfig/cardViewIcon.svg';
import fileAlt from './../../Static/images/360cvm/fileAlt.svg';
import sortIcon from './../../Static/images/360cvm/sort_icon.svg';
import radioIcon from './../../Static/images/svg/Pending.svg';
import radioSelectedIcon from './../../Static/images/svg/Progress.svg';

//actions
import {
  onCloseNotifyToast,
  getProjectDataEntryTableData,
  getProgramList,
  getRDVClientDetails,
  getLandingProgramProjectsList,
  onSelectCreationYearOptions,
  setProgramSelected,
  getProgramDropdownData,
  getProjectDetails,
  getLandingProjectsList,
  setProjectSelected,
  showProjectListRoller
} from '../../Actions/DataIngestion.Action';
import {
  clearMainSelectedDetails,
  getMappedStakeHolders,
  getProgramProjectCreationYear,
  clearRVDCreationYearList
} from '../../Actions/ConfigureProgram.Action';

// import {
//   DataEvent,
// } from '../../Utilities/DataEvents';

//components
import { IsValid, IsValidStr, IsArrayEmpty, IsObjEmpty } from '../../Utilities/Validations';
import { FormDateDDMMMYYY, TryGetValue } from '../../Utilities/Formatter';
import { InitRVD } from '../../Actions/AccessValidation.Action';
import CVMMutiSelectDropdown from '../Shared/CVMMutiSelectDropdown/CVMMutiSelectDropdown';
import CVMToasterNotif from '../Shared/CVMToasterNotif/CVMToasterNotif';
import Tooltip from 'react-bootstrap/Tooltip';

//css
import './DataIngestionLandingStyles.css';
// import { SignalCellularNull } from '@material-ui/icons';

const DataIngestionLanding = (function () {
  const IS_RES_DISABLED = {
    reportProjectData: false,
    statusDrp: false,
    reportingYearDrp: false,
  };
  class DataIngestionLandingPresentational extends Component {
    render() {
      let opsAccountLead = [];
      if (this.props.rdvClientDetails.operationsAccountLead) {
        this.props.rdvClientDetails.operationsAccountLead.forEach((element) => {
          opsAccountLead.push(element);
        });
      }
      const clientDetailsPopover = (
        <Popover id="clientDetails-popover" trigger="focus">
          <Popover.Title>
            <span className="close-btn" onClick={() => document.body.click()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </Popover.Title>
          <Popover.Content>
            <div>
              <div className="sub-section">
                <div className="detail-popup-section">
                  <div className="section-title">Client Name:</div>
                  <div className="section-desc">{this.props.rdvClientDetails.clientMasterName}</div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Operations Account Lead:</div>
                  {opsAccountLead.map((item) => {
                    return <div className="section-desc">{item}</div>;
                  })}
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Total no. of Projects:</div>
                  <div className="section-desc">
                    {this.props.rdvClientDetails.totalNoOfProjects}
                  </div>
                </div>
              </div>
              <div className="sub-section">
                <div className="detail-popup-section">
                  <div className="section-title">No. of Years Reported:</div>
                  <div className="section-desc">
                    {this.props.rdvClientDetails.noOfYearsReported}
                  </div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Total no. of Programs:</div>
                  <div className="section-desc">
                    {this.props.rdvClientDetails.totalNoOfPrograms}
                  </div>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );

      const projectListSorting = (
        <Popover id="projectListSorting-popover" trigger="focus" className='custom-sortmenu'>
          <Popover.Content>
            <div className="custom-sortmenulist">
              <div onClick={() => this.props.selectSort(0)} className={this.props.sortSelected === 0 ? "activesort-item" : " "}>
                System Generated Projects
              </div>
              <div onClick={() => this.props.selectSort(1)} className={this.props.sortSelected === 1 ? "activesort-item" : " "}>
                User Generated Projects
              </div>
              <div onClick={() => this.props.selectSort(2)} className={this.props.sortSelected === 2 ? "activesort-item" : " "}>
                Oldest to Newest
              </div>
              <div onClick={() => this.props.selectSort(3)} className={this.props.sortSelected === 3 ? "activesort-item" : " "}>
                Newest to Oldest
              </div>
              <div onClick={() => this.props.selectSort(4)} className={this.props.sortSelected === 4 ? "activesort-item" : " "}>
                A to Z
              </div>
              <div onClick={() => this.props.selectSort(5)} className={this.props.sortSelected === 5 ? "activesort-item" : " "}>
                Z to A
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );

      return (
        <div className="data-IngestionContainer">
          <div class="title-section">
            <h4 class="header24-semibold-midnight">Report Value Delivered</h4>
            <div class="notify-toast"></div>
            <CVMToasterNotif />
          </div>

          <div className="rvd-nav-dropdowns">
            <Col className="rvd-program-dd-cont" sm={6} md={6} lg={5}>
              <CVMSingleSelectDropdownWithSearch
                options={JSON.parse(JSON.stringify(this.props.programOptions))}
                placeholder={'Program'}
                onChange={(e) => {
                  this.props.handleSelectProgram(e);
                }}
                value={this.props.selectedProgramDDValue}
                key='profile'
                name="selectprofile"
                popdirection="pop-bottom"
                isDisabled={false}
                customClassName="rvd-program-dd"
              />
            </Col>
          </div>
          <div className="client-details-button">
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="right-start"
              overlay={clientDetailsPopover}
            >
              <div>
                <img src={clientDetailIcon} alt="clientDetailIcon" />
                Client Details
              </div>
            </OverlayTrigger>
          </div>

          <div className="client-details-button rvd-client-details-btn column-search-container">

            <div className="search-bar label14-regular-midnight">
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="large"
                name="search"
                iconStyle="solid"
                iconColor="stat-neutral"
              />
              <input
                type="text"
                name="name"
                placeholder="Search Project Name"
                autocomplete="off"
                id="searchBox"
                maxlength="255"
                onChange={(e) => {
                  this.props.searchFunc(e);
                }}
                value={this.props.searchText}
              />
            </div>

            <div className="dIL-dropdowns dIL-actionrow">
              <div className="statusDropdown">
                <CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter
                  options={JSON.parse(JSON.stringify(this.props.creationYearList))}
                  label="Reporting Year:"
                  placeholder={''}
                  isMulti
                  onChange={(e) => {
                    this.props.onSelectCreationYearOptions(e);
                    this.props.getLandingProjectsList();
                  }}
                  value={this.props.creationYearValue}
                  clearsearchText={() => this.props.searchFilterEmpty()}
                />
              </div>

              <div className="rvd-sort-wrapper">
                <OverlayTrigger
                  rootClose
                  placement="bottom-start"
                  trigger="click"
                  overlay={projectListSorting}
                >
                  <img src={sortIcon} className="sort-icon" alt="sortIcon" />
                </OverlayTrigger>
              </div>

              <div className="rvd-view-select landing-img-box">
                <img
                  src={cardViewIcon}
                  alt="cardViewIcon"
                  onClick={() => this.props.onChangeView('Card')}
                />
                <img src={listViewIcon} className="disabled-view" alt="listViewIcon" />
              </div>
            </div>
          </div>
          {this.props.programList.length === 0 ? (
            this.props.showProjectListRoller ?
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
            <div className='empty-project-list'>{this.props.noResultMsg}</div>
          ) : (
            <>
              <div className="add-program-line">
                <div className="bodytext16-medium-midnight">
                  Projects ({this.props.programList.length})
                </div>
              </div>
              <div className="card-view-container">
                {this.props.showListView === 'table' ? (
                  ''
                ) : (
                  <div className="program-card-container" >
                    {this.props.showProjectListRoller ?
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      :
                      <Row>
                        {this.props.programList.map((progItem, ind) => {
                          let hoverBtnID = `btn-hover-proj-${ind}`;
                          // yymmdd to ddmmyy
                          let creationDate = new Date(progItem.pdeCreationDate)
                          let rejSysGen = progItem.createdBy.toLowerCase() ===
                            'system generated' && progItem.systemGeneratedStatus.toLowerCase() === 'rejected';
                          // let isSysGenProjUpdated = progItem.isSysGenProjUpdated;
                          return (
                            <Col sm={12} md={6} lg={3}>
                              {/* <div className={`program-card-box rvd-program-card-box ${isSysGenProjUpdated ? 'error' : ""}`}> */}
                              <div className={`program-card-box rvd-program-card-box`}>
                                <div
                                  className={`program-card-wrap rvd-program-card-wrap ${rejSysGen ? "disabled" : ""}`}
                                  onClick={() =>
                                    rejSysGen || this.props.disableTileSelection ? "" : this.props.onTileSelect(progItem)
                                  }
                                >
                                  <div className="program-card-detail rvd-card rvd-card-detail">
                                    <img src={fileAlt} alt="file" />

                                    {progItem.createdBy.toLowerCase() ===
                                      'system generated' ? (
                                      <div className="system-generated-container">
                                        <div
                                          className="rvd-titleText-medium-14 titleText-medium-14 card-title rdv-label ppc-text-ellipsis title-ellip"
                                          title={progItem.clientProjectName}
                                        >
                                          {progItem.clientProjectName}
                                        </div>
                                        <div className="generated-project-label">
                                          System generated
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="rvd-titleText-medium-14 titleText-medium-14 card-title rdv-label ppc-text-ellipsis title-ellip"
                                        title={progItem.clientProjectName}
                                      >
                                        {progItem.clientProjectName}
                                      </div>
                                    )}
                                  </div>
                                  <div className="program-card-detail rvd-card-detail prog-stage">
                                    <div className="titleText-medium-12">Delivery Location:</div>
                                    <div className="titleText-regular-12 prog-card-stage-cont">
                                      <span className="prog-card-stage-name" title={progItem.deliveryCenters}>
                                        {progItem.deliveryCenters}
                                      </span>
                                      {progItem.IsEdited ? (
                                        <div className="edited-program-project">Edited</div>
                                      ) : (
                                        ''
                                      )}{' '}
                                    </div>
                                  </div>
                                  <div className="program-card-detail rvd-card-detail detail-status">
                                    <div className="titleText-medium-12">Status:</div>
                                    {rejSysGen || progItem.statusID === 0
                                      ? <div className='titleText-regular-12'>-</div>
                                      : <div
                                        className={
                                          progItem.statusID === 0
                                            ? 'card-row-status status-new'
                                            : progItem.statusID === 1
                                              ? 'card-row-status status-notStarted'
                                              : progItem.statusID === 2 || progItem.statusID === 9
                                                ? 'card-row-status status-notStarted'
                                                : progItem.statusID === 3
                                                  ? 'card-row-status status-inProgress'
                                                  : progItem.statusID === 4
                                                    ? 'card-row-status status-rejected'
                                                    : progItem.statusID === 5
                                                      ? 'card-row-status status-inProgress'
                                                      : progItem.statusID === 6
                                                        ? 'card-row-status status-completed'
                                                        : progItem.statusID === 7
                                                          ? 'card-row-status status-completed'
                                                          : progItem.statusID === 8 && 'card-row-status status-rejected'
                                        }
                                      >
                                        {progItem.status}
                                      </div>
                                    }
                                  </div>
                                  <div className="program-card-detail rvd-card-detail">
                                    <div className="titleText-medium-12 text-tile">
                                      Creation Date:
                                    </div>
                                    <div className="titleText-regular-12" title={progItem.statusID === 0 ? "-" : FormDateDDMMMYYY(creationDate, true)}>
                                      {progItem.statusID === 0 ? "-" : FormDateDDMMMYYY(creationDate, true)}
                                    </div>
                                  </div>
                                  <div className="program-card-detail rvd-card-detail text-gap">
                                    <div className="titleText-medium-12 ppc-text-ellipsis">
                                      Created By:
                                    </div>
                                    <div className="titleText-regular-12" title={progItem.statusID === 0 ? "-" : progItem.createdBy}>
                                      {progItem.statusID === 0 ? "-" : progItem.createdBy}
                                    </div>
                                  </div>
                                  <div className="program-card-detail rvd-card-detail text-gap">
                                    <div className="titleText-medium-12 ppc-text-ellipsis">
                                      Action Owner:
                                    </div>
                                    <div className="titleText-regular-12" title={progItem.statusID === 0 ? "-" : progItem.actionOwner}>
                                      {progItem.statusID === 0 || !IsValid(progItem.actionOwner) ? "-" : progItem.actionOwner}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    }
                  </div>
                )}
              </div>
            </>
          )}
        </div >
      );
    }
  }
  class DataIngestionLandingContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: false,
        showListView: 'list',
        prevClient: null,
        programList: [],
        sortSelected: 0,
        isTileCallDisabled: false,
        disableProgramSelection: false,
        disableTileSelection: false,
        noResultMsg: "",
        searchText: "",
        dataEvent: {
          client: 0
        },
      };
    }

    async componentDidMount() {
      try {
        this.props.InitRVD();
        let programList = this.props.DataIngestionData.programList;

        if (IsArrayEmpty(this.props.DataIngestionData.programOptions)) {
          await this.props.getProgramDropdownData();
          let tSelProg = this.props.DataIngestionData.programOptions.length === 1 ?
            this.props.DataIngestionData.programOptions[0] : [];
          this.props.setProgramSelected(tSelProg);
          if (!IsObjEmpty(tSelProg)) await this.props.getLandingProjectsList();
        }

        if (IsValid(this.props.DataIngestionData.programList)) {
          let selectedProgram = this.props.DataIngestionData.selectedProgramDDValue;
          if (!IsObjEmpty(selectedProgram) && this.props.DataIngestionData.creationYear === "") {
            await this.props.getProgramProjectCreationYear(selectedProgram.value);
            await this.props.onSelectCreationYearOptions(this.props.DataIngestionData.creationYearList);
          } else if (IsObjEmpty(selectedProgram) && this.props.DataIngestionData.creationYear === "") {
            this.props.clearRVDCreationYearList()
          }
          this.setState(
            {
              programList: this.props.DataIngestionData.programList,
            },
            () => {
              this.selectSort(this.state.sortSelected);
            }
          );
        }
      }
      catch (e) {

      }

    }

    componentDidUpdate = async (prevProps) => {
      if (prevProps.SharedData.selectedClient.value !== "" && prevProps.SharedData.selectedClient.value !== this.state.dataEvent.client) {
        //DataEvent("rvdLanding", prevProps.SharedData.selectedClient.text)
        this.setState({
          dataEvent: {
            client: prevProps.SharedData.selectedClient.value
          }
        })
      }
      if (
        Object.keys(this.props.SharedData.selectedClient).length > 0 &&
        this.state.prevClient !== this.props.SharedData.selectedClient.value
      ) {
        this.setState(
          {
            prevClient: this.props.SharedData.selectedClient.value,
          },
          async () => {
            // await this.props.getProgramProjectCreationYear();
            // await this.props.getLandingProgramProjectsList();
            await this.props.getRDVClientDetails();
            let cccccc = 'sssssssss'
            // if (
            //   JSON.stringify(this.props.DataIngestionData.programOptions) !==
            //   JSON.stringify(prevProps.DataIngestionData.programOptions)
            // ) {
            //   await this.props.getProgramDropdownData();
            //   let tSelProg = this.props.DataIngestionData.programOptions.length === 1 ?
            //     this.props.DataIngestionData.programOptions[0] : {};
            //   this.props.setProgramSelected(tSelProg);
            //   if (!IsObjEmpty(tSelProg)) await this.props.getLandingProjectsList();
            // }
          }
        );
      }

      if (
        IsValid(this.props.ClientConfigurationData.clientDetails) &&
        JSON.stringify(this.props.ClientConfigurationData.clientDetails) !==
        JSON.stringify(prevProps.ClientConfigurationData.clientDetails)
      ) {
        this.props.InitRVD();
      }

      let prevClient = IsValid(prevProps.SharedData.selectedClient) ? prevProps.SharedData.selectedClient.value : ''

      if (prevClient !== this.props.SharedData.selectedClient.value) {
        this.props.clearRVDCreationYearList()

      }
      let programList = this.props.DataIngestionData.programList;
      if (
        IsValid(programList) &&
        JSON.stringify(programList) !== JSON.stringify(prevProps.DataIngestionData.programList)
      ) {
        this.setState(
          {
            programList: programList,
          },
          () => {
            this.selectSort(this.state.sortSelected);
          }
        );
      }
    };

    onChangeView(type) {
      if (type) {
        this.setState({
          ...this.state,
          showListView: type,
        });
      }
    }

    onCloseNotifyToast = () => {
      this.setState({
        showNotifyToast: false,
      });
    };

    onTileSelect = (projData) => {
      try {
        this.setState({
          disableTileSelection: true
        }, async () => {
          // await this.props.setProgramSelected(prog, proj);
          // await this.props.getProjectDataEntryTableData(prog, proj);
          // await this.props.getMappedStakeHolders(prog.programID);
          const prog = {
            programID: projData.programID,
            programName: projData.programName,
            creationYear: this.props.DataIngestionData.creationYear,
          };

          let selProj = this.props.ConfigureProgramData.mainSelectedProject;

          const proj = {
            actionOwner: projData.actionOwner,
            assignedTo: projData.actionOwner,
            assignedToRoleId: projData.assignedToRoleId,
            clientProjectID: projData.projectID,
            clientProjectName: projData.clientProjectName,
            createdBy: projData.createdBy,
            creationDate: projData.creationDate,
            deliveryCenterID: projData.deliveryCenterID,
            deliveryCenterName: projData.deliveryCenterName,
            deliveryLocations: projData.deliveryLocations,
            lastReportingDate: projData.creationDate, //
            pdeModifiedDate: projData.pdeModifiedDate,
            ID: projData.projectOverallStatusID, //
            projectID: projData.projectID,
            reportedBy: projData.reportedBy,
            reportedMonth: projData.reportedMonth,
            status: projData.status,
            statusID: projData.statusID,
          };

          await this.props.setProjectSelected(projData);
          await this.props.getProjectDetails(prog, proj)
          await this.props.getMappedStakeHolders(projData.programID, "Value Delivered_Landing page");
          this.props.history.push('/DataIngestion/ConfigureProgram');

          this.setState({
            disableTileSelection: false
          })
        })
      } catch (e) {
        console.log(e);
      }
    };

    selectSort = (opt) => {
      this.setState(
        {
          sortSelected: opt,
          searchText: '',
          programList: this.props.DataIngestionData.programList,
        },
        () => {
          this.sortProgramProjectList();
        },

      );
    };

    sortProgramProjectList() {
      let programList = this.state.programList;
      let newProjectList = [];
      if (IsValid(programList)) {
        if (this.state.sortSelected === 0 || this.state.sortSelected === 1) {
          let userGenerated = programList.filter(
            (x) => String(x.createdBy).toLowerCase() !== 'system generated'
          );
          let systemGenerated = programList.filter(
            (x) => String(x.createdBy).toLowerCase() === 'system generated'
          );
          if (this.state.sortSelected === 0) {
            newProjectList = [...systemGenerated, ...userGenerated];
          }
          else if (this.state.sortSelected === 1) {
            newProjectList = [...userGenerated, ...systemGenerated];
          }

        }
        else if (this.state.sortSelected === 2) {
          let newestfilter = programList.sort((a, b) => (a.creationDateWithTime > b.creationDateWithTime ? 1 : -1))
          newProjectList = [...newestfilter];
        }
        else if (this.state.sortSelected === 3) {
          let newestfilter = programList.sort((a, b) => (a.creationDateWithTime < b.creationDateWithTime ? 1 : -1))
          newProjectList = [...newestfilter];
        }
        else if (this.state.sortSelected === 4) {
          let newestfilter = programList.sort(function (a, b) { return a.clientProjectName.localeCompare(b.clientProjectName) })
          newProjectList = [...newestfilter];
        }
        else if (this.state.sortSelected === 5) {
          let newestfilter = programList.sort(function (a, b) { return b.clientProjectName.localeCompare(a.clientProjectName) })
          newProjectList = [...newestfilter];
        }
        this.setState({
          programList: newProjectList,
        });
      }
    }

    handleSelectProgram = async (e) => {
      this.props.showProjectListRoller(true);
      await this.props.setProgramSelected(e);
      await this.props.getLandingProjectsList();
      if (!this.state.disableProgramSelection) {
        this.setState({
          disableProgramSelection: true
        }, async () => {
          await this.props.setProgramSelected(e);
          await this.props.getProgramProjectCreationYear(e.value);
          await this.props.onSelectCreationYearOptions(this.props.DataIngestionData.creationYearList)
          await this.props.getLandingProjectsList();
          // LogUserSiteAction({
          //   module: PROJ_SEL,
          //   clientID,
          //   ClientProgramID: selectedProgram.programID,
          //   ClientProjectID: null,
          //   progStatus: ACC_PROG,
          //   projStatus: null,
          //   ShouldLogPPCID: true,
          // });
          //DataEvent("rvdProjects", this.props.SharedData.selectedClient.text);
          this.setState({
            disableProgramSelection: false
          })
        })
      }
    };

    onSearchFilterEmpty = () => {
      this.setState({
        searchText: ""
      });
    }
    onSearchTextChanged = (e) => {
      let programList = this.state.programList;
      let filteredData = this.state.programList.filter((x) =>
        x.clientProjectName.toLowerCase().includes(e.target.value.toLowerCase())
      );

      if (filteredData.length == 0) {
        this.setState({
          noResultMsg: "No results found"
        })
      } else {
        this.setState({
          noResultMsg: " "
        })
      }

      if (IsValidStr(e.target.value)) {
        this.setState({
          programList: filteredData,
          searchText: e.target.value
        });
      } else {
        this.setState({
          programList: this.props.DataIngestionData.programList,
          searchText: ""
        });
      }

    };
    render() {
      const { showNotifyToastSuccess, clientDetails } = this.props.ClientConfigurationData;

      const {
        rdvClientDetails,
        creationYearValue,
        creationYearList,
        programOptions,
        selectedProgramDDValue,
        showProjectListRoller
      } = this.props.DataIngestionData;

      const { userProfiles } = this.props.userInformation;

      const { selectedClient } = this.props.SharedData;

      const { RVDComponents } = this.props.AccessValidationData;

      const {
        onCloseNotifyToast,
        clearMainSelectedDetails,
        getLandingProgramProjectsList,
        getProgramProjectCreationYear,
        onSelectCreationYearOptions,
        getLandingProjectsList
      } = this.props;

      return (
        <DataIngestionLandingPresentational
          showListView={this.state.showListView}
          onChangeView={(type) => this.onChangeView(type)}
          showNotifyToast={showNotifyToastSuccess}
          onCloseNotifyToast={onCloseNotifyToast}
          show={this.state.show}
          onTileSelect={this.onTileSelect}
          clientDetails={clientDetails}
          programList={this.state.programList}
          noResultMsg={this.state.noResultMsg}
          clearMainSelectedDetails={clearMainSelectedDetails}
          rdvClientDetails={rdvClientDetails}
          getLandingProgramProjectsList={getLandingProgramProjectsList}
          getProgramProjectCreationYear={getProgramProjectCreationYear}
          creationYearList={creationYearList}
          onSelectCreationYearOptions={onSelectCreationYearOptions}
          creationYearValue={JSON.parse(JSON.stringify(creationYearValue))}
          userProfiles={userProfiles}
          selectedClient={selectedClient}
          RVDComponents={RVDComponents}
          selectSort={this.selectSort}
          sortSelected={this.state.sortSelected}
          programOptions={programOptions}
          setProgramSelected={setProgramSelected}
          handleSelectProgram={this.handleSelectProgram}
          selectedProgramDDValue={selectedProgramDDValue}
          showProjectListRoller={showProjectListRoller}
          getLandingProjectsList={getLandingProjectsList}
          disableTileSelection={this.state.disableTileSelection}
          searchFunc={this.onSearchTextChanged}
          searchText={this.state.searchText}
          searchFilterEmpty={this.onSearchFilterEmpty}
        />
      );
    }
  }

  return DataIngestionLandingContainer;
})();

export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      DataIngestionData: state.DataIngestionData,
      ConfigureProgramData: state.ConfigureProgramData,
      SharedData: state.SharedData,
      userInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          onCloseNotifyToast,
          getProjectDataEntryTableData,
          getProgramList,
          clearMainSelectedDetails,
          getRDVClientDetails,
          getMappedStakeHolders,
          getLandingProgramProjectsList,
          getProgramProjectCreationYear,
          onSelectCreationYearOptions,
          InitRVD,
          setProgramSelected,
          getProgramDropdownData,
          getProjectDetails,
          getLandingProjectsList,
          setProjectSelected,
          clearRVDCreationYearList,
          showProjectListRoller
        }
      ),
      dispatch
    )
)(withRouter(DataIngestionLanding));
