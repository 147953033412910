import React, { Component } from 'react';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';

require('./SingleDropdownWithMultiDropdownSelectedView.css');

const Control = (props) => {
  return (
    <>
      {
        props.hasValue ? (
          <>
            <label className="placeholder-title">{props.selectProps.placeholder}</label>
            <components.Control {...props} />
          </>
        ) :
          <components.Control {...props} />
      }
    </>
  );
};

export default class SingleDropdownWithMultiDropdownSelectedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onChange = (value, action) => {
    const currentValue = this.props.value;
    if (value.length > 1) {
      this.props.onChange(value.filter(x => !currentValue.some(y => x.label === y.label)));
    } else {
      if (action.action === 'deselect-option') {
        this.props.onChange(currentValue);
      } else {
        this.props.onChange(value);
      }
    }
  }

  render() {
    const styles = {
      option: (base, state) => ({
        ...base,
        '&:hover': {
          color: 'white',
          backgroundColor: '#a100ff',
          fontFamily: 'Graphik-Regular',
          fontWeight: '400',
        },
        backgroundColor: 'none',
        color: '#212b36',
        whiteSpace: 'nowrap',
        fontFamily: 'Graphik-Regular',
        fontSize: '14px',
        padding: '5px 15px',
        wordBreak: 'break-word',
        cursor: 'pointer',
        fontWeight: '400',
      }),
      placeholder: (base, state) => ({
        color: '#919eab',
      }),
      menu: (base, state) => ({
        ...base,
        borderRadius: '5px',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.15)',
        padding: '5px',
        zIndex: 2,
        width: '100%',
        fontSize: '16px',
      }),
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: 'rgb(250, 245, 253)',
          borderRadius: '16px',
          border: '1px solid rgb(161, 0, 255)',
          height: '22px',
          padding: '0px 0px 0px 10px',
          display: 'flex',
          alignItems: 'center',
          margin: '0px 3px',
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: 'rgb(161, 0, 255)',
        fontSize: '14px',
        fontFamily: 'Graphik-Regular',
        fontWeight: '400',
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {},
        display: data.isFixed ? 'none' : 'block',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        'svg': {
          height: '30px',
          width: '50px',
          fill: '#919eab'
        },
        top: state.hasValue ? '-15px' : '0px',
        position: 'relative'
      }),
      control: (base, state) => ({
        ...base,
        width: '100%',
        '&:hover': {
          border: 'none',
          cursor: state.isDisabled ? 'not-allowed' : 'pointer'
        },
        boxShadow: 'none',
        border: state.isFocused ? 'none' : 'none',
        minHeight: 'unset',
        top: state.hasValue ? '-7px' : '2px',
        height: '30px',
        paddingLeft: state.hasValue ? '0px' : '7px'
      }),
      container: (base, state) => ({
        ...base,
        height: '54px',
        border: '1px solid rgba(0,0,0,.2)',
        borderRadius: '5px',
        '&:hover': {
          border: state.isDisabled ? '1px solid rgba(0,0,0,.2)' : '1.5px solid #a100ff',
          borderRadius: '5px',
          cursor: state.isDisabled ? 'not-allowed' : 'pointer'
        },
        backgroundColor: state.isDisabled ? 'hsl(0,0%,95%)' : '',
        pointerEvents: state.isDisabled ? 'unset' : 'unset'
      }),
      valueContainer: (base, state) => ({
        ...base,
        top: state.hasValue ? '-7px' : '0px'
      }),
      menuPortal: (base) => ({ 
        ...base, 
        zIndex: 9999,
        paddingTop: '25px'
      })
    };

    const props = this.props;

    return (
      <div className='single-dropdown-multi-view'>
        <ReactSelect
          styles={styles}
          isMulti
          options={props.options}
          isClearable={false}
          components={{
            Control,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
          onChange={(value, action) => this.onChange(value, action)}
          value={props.value}
          hideSelectedOptions={false}
          placeholder={props.placeholder}
          isDisabled={props.isDisabled}
          menuPortalTarget={document.body}
        />
      </div>
    )
  }
}

SingleDropdownWithMultiDropdownSelectedView.defaultProps = {
  value: null,
  placeholder: "Select..."
}