import React, { Component } from 'react';
// import { history } from '../../../store';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveButton, GrooveIconSvg } from 'tfo-groove';
import { ACC_PROJ, PROJ_ID_VAL_SETT } from '../../../Constants/Modules';
import DimensionAccordion from '../DimensionAccordion/DimensionAccordion';
import ProgramProjectHeader from '../ProgramProjectHeader';
import Stepper from '../Stepper';
import SelectBOIPopup from './SelectBOIPopup';
import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';

import EmptyBOI from '../EmptyBOI';
import { LogUserSiteAction } from '../../../Utilities/AccessManagementUtil';
import { IsValidStr, IsObjEmpty, IsValid, HasValue, IsArrayEmpty, IsValidAccentureEmail } from '../../../Utilities/Validations';
import { TryGetObjValueAlt, UnRefObj } from '../../../Utilities/Formatter';
import { GetSubMenuId, HttpPost } from "../../../Utilities/HTTPUtil";

import AddNewProjectPopup from './AddNewProjectPopup';
import circles from './../../../Static/images/360cvm/circlesColor.svg';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';

import {
  sendForApprovalProgramIdentified,
  setNewProgramDetails,
} from '../../../Actions/ProgramConfiguration.Action';
import {
  saveAsDraftProjectIdentified,
  sendForApprovalProjectIdentified,
  setNewProjectDetails,
  setProjectDimensionList,
  editProject,
  getProjectBOI,
  saveAndCloseProjectIdentified,
  saveAndReportProjectIdentified
} from '../../../Actions/ProjectConfiguration.Action';
import { getCustomBOIDetails, setEmailError } from '../../../Actions/Shared.Action';
import { GenRVDURL, GenRVDLink } from '../PPCUtil';

//icons

import AddFileIcon from './../../../Static/images/360cvm/AddFilesIcon.svg';
//import { DataEvent } from '../../../Utilities/DataEvents';

const ProjectIDValueSettings = (function () {
  class ProjectIDValueSettingsPresentational extends Component {
    state={
      copy:"",
      copiedProjectName:""
    }
    handlecopy=(copy)=>{
      this.setState({
        copy:copy,
        copiedProjectName:""
      })
    }
    render() {
      let clientID = this.props.programClientDetails.ClientMasterID;
      let user = this.props.userInformation.userProfiles.find(x => x.ClientMasterId === clientID && x.ProfileID !== 16); //exclude QC
      let isProgProjConfig = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15;
      let copy=sessionStorage.getItem("copy");
      let copiedProjectNameValue= copy == "true" && HasValue(this.props.copiedProjectName)?this.props.copiedProjectName:'';
      const subOfferingID = TryGetObjValueAlt(this.props, false, 'newProjectDetails.suboffering');
      let hasIOApproverOpt = !subOfferingID
        ? false
        : (this.props.IOAApproverList || []).some((ioa) =>
          (subOfferingID || []).some((so) => so.value === ioa.subOfferingID)
        );
      const existingProject = Object.keys(this.props.selectedProjectDetails).length > 0 ? true : false;// if new project
      const newProjectDetails = this.props.newProjectDetails;
      const isProjectApprover = (
        !IsArrayEmpty(newProjectDetails.oaLead.filter((a) => a.text === this.props.userInformation.emailAddress)) || //oal
        !IsArrayEmpty(newProjectDetails.cxLead.filter((a) => a.text === this.props.userInformation.emailAddress)) ||  //cxl
        (IsValid(newProjectDetails.ioapprover.label) &&
          this.props.userInformation.emailAddress === newProjectDetails.ioapprover.label)
      ) &&
        !this.props.userInformation.isSuperAdmin;
      const disabledSAD =
        this.props.hasBlank ||
        newProjectDetails.projectName.value === '' ||
        newProjectDetails.projectName.isError ||
        this.props.invalidDesc ||
        newProjectDetails.suboffering.length === 0 ||
        newProjectDetails.deliveryLocation.length === 0 ||
        newProjectDetails.targetEndDate === '' ||
        !/\S/.test(this.props.newProjectDetails.projectName.value) ||
        (Object.keys(newProjectDetails.ioapprover).length === 0 &&
          hasIOApproverOpt &&
          !(
            String(newProjectDetails.projectcreator.label).toLowerCase() === 'system generated' &&
            String(newProjectDetails.projectName.value).toLowerCase() !== 'global bois project'
          )) ||
          (copy !== "true" ? Object.keys(this.props.newProjectDetails.projectcreator).length === 0 : Object.keys(this.props.selectedProjectDetails.ProjectCreator).length === 0) ||        
          Object.keys(newProjectDetails.deliveredBy).length === 0 ||
        newProjectDetails.assetName.length === 0
        || this.props.disableSADBtn ||
        newProjectDetails.businessAdvisor.length === 0 ||
        newProjectDetails.oaLead.length === 0 ||
        newProjectDetails.cxLead.length === 0 ||
        (!isProjectApprover && (!IsValidStr(newProjectDetails.OMID) ||
          !IsValidStr(newProjectDetails.contractID))) ||
          !IsValidStr(newProjectDetails.tve.value) || this.props.anyInvalidEmailAvailable;

      const disabledSFA =
        this.props.selectedBOIs.length === 0 ||
        this.props.hasBlank ||
        disabledSAD || this.props.disableSendBtn || this.props.anyInvalidEmailAvailable;
      
      const showBOIBtn =
        String(newProjectDetails.projectcreator.label).toLowerCase() !== 'system generated' &&
        !this.props.PnPComponents.project.boiButton;
      const showTransactButton =
        !this.props.PnPComponents.project.stageOnetransactBtn ||
        this.props.userInformation.isSuperAdmin;
      const enableFields =
        !this.props.PnPComponents.project.stageOneFields || this.props.userInformation.isSuperAdmin;
      const props = this.props
      const redirectLink = GenRVDURL(props.programClientDetails, props.selectedProgram, props.selectedProjectDetails);


      const projStatus = this.props.selectedProjectDetails.Status;
      const statusValidations = ["Awaiting Approval", "In Progress", "Rejected"];
      const isAwaitApprovalInProg = existingProject ? statusValidations.indexOf(projStatus) > -1 : false;
      const projName = existingProject ? this.props.selectedProjectDetails.ClientProjectName : "";

      let approvalReqData = this.props.arsDetails && isAwaitApprovalInProg ? this.props.arsDetails.filter((ars) => {
        return ars.status === "Approved" && ars.pName === projName;
      }) : [];
      const isProjApproveOnce = approvalReqData && isAwaitApprovalInProg ? approvalReqData.length > 0 : true;
      const hideSAC = !disabledSAD && disabledSFA
      let enabledUpdatePPC = !IsValid(this.props.selectedProjectDetails.ProjectCreator) && isProjectApprover;
      
      return (
        <React.Fragment>
          <div className="BT-Lead-Landing-Page">
            <ProgramProjectHeader type="Project" setCmErrorMessage={this.props.setCmErrorMessage} checkForInvalidEmailFirst={this.props.checkForInvalidEmailFirst} selectedProjectStatus={this.props.selectedProjectStatus} resetErrorStates={this.props.resetErrorStates}/>
            {this.props.showSelectProjectView ? (
              <div className="empty-program-container bodytext18-regular-slate">
                <img src={AddFileIcon} alt="select client" />
                <span className="bodytext24-medium-slate">No Project Selected</span>
                Select Project to display project relevant content.
              </div>
            ) : (
              <div>
                <Stepper type="Project" />
                {this.props.PnPComponents.project.idValueSetting ? (
                  <Row className="access_denied_container">
                    <Col md={12} xs={12}>
                      <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                      <div className="access_denied_content">
                        <h1>Access Denied</h1>
                        <p>
                          You don't have the necessary user privileges to view the page that you are
                          attempting to access.
                        </p>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <React.Fragment>
                    <Accordion className="configure-client-collapse" defaultActiveKey="0">
                      <Card>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                          {copy !== "true" ?
                            (this.props.isSuperAdmin || isProgProjConfig) && (
                                                          <Row>
                                {/* <Col sm={12} md={12} lg={12}>
                                  {!props.PnPComponents.project.downloadAttchmnt &&
                                    existingProject &&
                                    isProjApproveOnce &&
                                    GenRVDLink(redirectLink)}
                                </Col> */}
                                <Col sm={12} md={12} lg={12}>
                                  {!props.PnPComponents.project.downloadAttchmnt &&
                                    existingProject &&
                                    isProjApproveOnce && (
                                      <div className="rvd-link" style={{ float: 'right' }}>
                                        {' '}
                                        <img
                                          src={circles}
                                          alt="RVD Link"
                                          className="rvd-icon"
                                        />
                                        Go to <span className='rvd-link-span' onClick={() => this.props.onClickRVD(true)}>Report Value Delivered</span> Module
                                      </div>
                                    )}
                                </Col>
                              </Row>
                            ):''}
                            <AddNewProjectPopup
                              readOnly={!enableFields}
                              editMode={this.props.selectedProjectDetails.ProjectID}
                              isSystemGenerated={
                                String(this.props.selectedProjectDetails.ProjectCreator).toLowerCase() ===
                                'system generated'
                              }
                              copyCallback={this.handlecopy}
                              redirectLink={redirectLink}
                              invalidDesc={this.props.invalidDesc}
                              setInvalidDesc={this.props.setInvalidDesc}
                              cmErrorMessage={this.props.cmErrorMessage}
                              setCmErrorMessage={this.props.setCmErrorMessage}
                              editedAnyFieldOnce={this.props.editedAnyFieldOnce}
                              setEditedAnyFieldOnce={this.props.setEditedAnyFieldOnce}
                              checkForInvalidEmailFirst={this.props.checkForInvalidEmailFirst}
                              anyInvalidEmailAvailable={this.props.anyInvalidEmailAvailable}
                              setAnyInvalidEmailAvailable={this.props.setAnyInvalidEmailAvailable}
                              isValidNewUser={this.props.isValidNewUser}
                              setIsValidNewUser={this.props.setIsValidNewUser}
                              isValidIoApprover={this.props.isValidIoApprover}
                              setIsValidIoApprover={this.props.setIsValidIoApprover}
                              isValidProjectCreator={this.props.isValidProjectCreator}
                              setIsValidProjectCreator={this.props.setIsValidProjectCreator}
                              allInvalidEmails={this.props.allInvalidEmails}
                              setAllInvalidEmails={this.props.setAllInvalidEmails}
                              anyInvalidEmailsInDropdown={this.props.anyInvalidEmailsInDropdown}
                              setAnyInvalidEmailsInDropdown={this.props.setAnyInvalidEmailsInDropdown}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header onClick={this.props.accordionHeaderClick}>
                          <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            <div className="bodytext18-medium-midnight">Basic Details</div>
                            <GrooveIconSvg
                              customClassName="accordion-button"
                              size="large"
                              name="chevron-down"
                              iconStyle="solid"
                              iconColor="stat-neutral"
                            />
                          </Accordion.Toggle>
                        </Card.Header>
                      </Card>
                    </Accordion>
                    <Accordion className="configure-client-collapse idValue-setting-add-boi" defaultActiveKey={(this.props.selectedProjectDetails.ProjectID) ? "0" : "1"}>
                      <Card>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="BT-Lead-Landing-btn">
                              <div className="applyFlex-space-between">
                                {showBOIBtn ? (
                                  <SelectBOIPopup
                                    type={'Add'}
                                    // mode={'edit'}
                                    onSaveAddBOI={this.props.onSaveAddBOI}
                                    selectedBOIs={this.props.selectedBOIs}                                                                   
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            {this.props.selectedBOIs.length !== 0 ? (
                              <DimensionAccordion
                                type={'project'}
                                getCustomBOIDetails={this.props.getCustomBOIDetails}
                                customBOI={this.props.SharedData.customBOI}
                                ProgramConfigurationData={this.props.ProgramConfigurationData}
                                selectedProjectStatus={this.props.selectedProjectStatus}
                                isSuperAdmin={this.props.isSuperAdmin}
                                basicDetails={this.props.basicDetails}
                                dimensionList={this.props.selectedBOIs}
                                formCallback={this.props.dimFormCallback}
                                mode={
                                  !enableFields
                                    ? 'view'
                                    : String(
                                      this.props.selectedProjectDetails.ProjectCreator
                                    ).toLowerCase() !== 'system generated'
                                      ? 'edit'
                                      : 'view'
                                }
                              />
                            ) : (
                              <EmptyBOI type="project" />
                            )}
                            <div className="add-program-buttons">
                              {this.props.hasBlank ? (
                                <div className="missing">
                                  There are blank Target value field(s)
                                </div>
                              ) : (
                                ''
                              )}
                              {this.props.showError.show ?
                                (<div className="missing">{this.props.showError.message}</div>
                                ) : (
                                  ""
                                )}
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header onClick={this.props.accordionHeaderClick}>
                          <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            <div className="bodytext18-medium-midnight">Target Value Settings</div>
                            <GrooveIconSvg
                              customClassName="accordion-button"
                              size="large"
                              name="chevron-down"
                              iconStyle="solid"
                              iconColor="stat-neutral"
                            />
                          </Accordion.Toggle>
                        </Card.Header>
                      </Card>
                    </Accordion>
                    <div id="track-scroll">
                      <span className="BT-Lead-Landing-bottom-btn">
                        {showTransactButton || enabledUpdatePPC ? (
                          <React.Fragment>
                            <span className="applyFlex-space-between">
                              <GrooveButton
                                id="primry-btn-2"
                                type="outline"
                                colorClass="stat-alternate"
                                text="Save & Close"
                                customClassName="add-project-button"
                                callbackFunction={this.props.onClickSAC}
                                isDisabled={copy !== "true" ?(!this.props.isSuperAdmin&&this.props.selectedProjectDetails.OverallStatus=='Approved'|| disabledSFA):copiedProjectNameValue=='' ? true : disabledSFA}
                                //isDisabled={(!this.props.isSuperAdmin&&this.props.selectedProjectDetails.OverallStatus=='Approved' && copy=="true")?disabledSFA : false}
                              />
                            </span>
                            <span className="applyFlex-space-between">
                              <GrooveButton
                                d="primry-btn-2"
                                type="solid"
                                colorClass="stat-alternate"
                                text="Save & Report Value"
                                callbackFunction={this.props.onClickSAR}
                                isDisabled={copy !== "true" ?(!this.props.isSuperAdmin&&this.props.selectedProjectDetails.OverallStatus=='Approved'|| disabledSFA):copiedProjectNameValue=='' ? true : disabledSFA}
                              />
                            </span>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div
                      className={
                        this.props.positionFixed
                          ? (showTransactButton ? !disabledSFA : '')
                            ? 'sticky-button'
                            : 'sticky-button hide-sticky'
                          : 'sticky-button hide-sticky'
                      }
                    >
                      <span className="BT-Lead-Landing-bottom-btn">
                        {showTransactButton ? (
                          <React.Fragment>
                            <span className="applyFlex-space-between">
                              <GrooveButton
                                id="primry-btn-2"
                                type="outline"
                                colorClass="stat-alternate"
                                text="Save & Close"
                                customClassName="add-project-button"
                                callbackFunction={this.props.onClickSAC}
                                isDisabled={copy !== "true" ?(!this.props.isSuperAdmin&&this.props.selectedProjectDetails.OverallStatus=='Approved'|| disabledSFA):copiedProjectNameValue=='' ? true : disabledSFA}
                              />
                            </span>
                            <span className="applyFlex-space-between">

                              <GrooveButton
                                d="primry-btn-2"
                                type="solid"
                                colorClass="stat-alternate"
                                text="Save & Report Value"
                                callbackFunction={this.props.onClickSAR}
                                isDisabled={copy !== "true" ?(!this.props.isSuperAdmin&&this.props.selectedProjectDetails.OverallStatus=='Approved' || disabledSFA):copiedProjectNameValue=='' ? true : disabledSFA}
                              />
                            </span>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
          <CVMModal
            title={''}
            show={this.props.showRVD}
            onCloseModal={() => this.props.onClickRVD(false)}
            customclass={'notifyModal-dialog'}
            content={
              <CVMWarningModal
                handleCancel={() => this.props.onClickRVD(false)}
                handleYes={() => this.props.history.push(redirectLink)}
                body={
                  <div>
                    <h5>Are you sure you want to navigate to Report Value Delivered?</h5>
                    <p>Please note that this will discard your unsaved changes.</p>
                  </div>
                }
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class ProjectIDValueSettingsContainer extends Component {
    constructor() {
      super();
      this.state = {
        selectedBOIs: [],
        hasBlank: false,
        showSFAModal: false,
        disableSendBtn: false,
        disableSADBtn: false,
        positionFixed: false,
        initialMappings: {
          cxl: [],
          oal: [],
          ioa: {}
        },
        show: false,
        showRVD: false,
        invalidDesc: false,
        cmErrorMessage: [],
        editedAnyFieldOnce: false,
        anyInvalidEmailAvailable: false,
        isValidNewUser: {
          cxl: true,
          oal: true,
          ba: true
        },
        isValidIoApprover: true,
        isValidProjectCreator: true,
        allInvalidEmails: [],
        anyInvalidEmailsInDropdown : {
          cxl: false,
          oal: false,
          ba: false,
        }
      };
    }

    setAnyInvalidEmailsInDropdown = (value) => {
      this.setState({
        anyInvalidEmailsInDropdown: value
      })
    }

    setAllInvalidEmails = (value) => {
      this.setState({
        allInvalidEmails: value
      })
    }

    setIsValidNewUser = (value) => {
      this.setState({
        isValidNewUser: value
      })
    }

    setIsValidIoApprover = (value) => {
      this.setState({
        isValidIoApprover: value
      })
    }

    setIsValidProjectCreator = (value) => {
      this.setState({
        isValidProjectCreator: value
      })
    }

    setAnyInvalidEmailAvailable = (value) => {
      this.setState({
        anyInvalidEmailAvailable: value,
      })
    }

    setInvalidDesc = (value) => {
      this.setState({
        invalidDesc: value
      })
    };

    setCmErrorMessage = (value) => {
      this.setState({
        cmErrorMessage: value
      })
    };

    setEditedAnyFieldOnce = (value) => {
      this.setState({
        editedAnyFieldOnce: value
      })
    };

    accordionHeaderClick = () => {
      setTimeout(this.listenToScroll, 300);
    };

    listenToScroll = () => {
      let trackScroll = document.getElementById('track-scroll');
      let var1 = trackScroll ? document.getElementById('root').clientHeight - trackScroll.offsetTop - 50 : 0;
      let var2 =
        document.getElementById('root').clientHeight -
        document.documentElement.scrollTop -
        window.innerHeight;

      if (var2 > var1) {
        this.setState({
          positionFixed: true,
        });
      } else {
        this.setState({
          positionFixed: false,
        });
      }
    };

    componentDidMount = () => {
      window.scrollTo(0, 0)
      this.listenToScroll();
      this.props.setEmailError({
        show: false,
        message: ""
      })
      LogUserSiteAction({
        module: PROJ_ID_VAL_SETT,
        ShouldLogPPCID: true,
        ClientProgramID: this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
        ClientProjectID: this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID,
        projStatus: ACC_PROJ
      })
      
      let projectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
      let deliveredByList = this.props.ProjectConfigurationData.assetAndDeliveredByList.deliveredByList
      let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      const enableFields =
        !this.props.AccessValidationData.PnPComponents.project.stageOneFields ||
        this.props.userInformation.isSuperAdmin;

      if (projectDetails.ProjectID) {
        let delCntr = [];
        projectDetails.DeliveryCenterList.map((loc) => {
          delCntr.push({
            label: loc.deliveryCenterName,
            text: loc.deliveryCenterName,
            value: loc.deliveryCenterID,
            isFixed: !enableFields,
          });
        });
        let subOffList = [];
        projectDetails.ClientProjectSubOffering.map((subOff) => {
          subOffList.push({
            label: subOff.SubOfferingName,
            text: subOff.SubOfferingName,
            value: subOff.SubOfferingID,
          });
        });
        let clientDetails = this.props.ProgramConfigurationData.programClientDetails;
        const { ClientMasterID } = clientDetails
        const user = this.props.userInformation.userProfiles.find(x => x.ClientMasterId === ClientMasterID && x.ProfileID !== 16); //exclude QC
        let currentUserEmail = this.props.userInformation.emailAddress;
        let isValidProjCreator = IsValid(projectDetails.ProjectCreator);
        //PPC or BA
        const isProgProjConfig = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15;
        let projCreator = IsValid(projectDetails.ProjectCreator) ? projectDetails.ProjectCreator :
          // For existing Projects with deleted Project Creator, if current user is PPC/BA, then auto select as Project Creator
          (isProgProjConfig && !this.props.userInformation.isSuperAdmin ? currentUserEmail : "");

        let projApprover =
          projCreator === projectDetails.ProjectApprover ||
            projectDetails.ProjectApproverProfileId === 0
            ? null
            : projectDetails.ProjectApprover;

        newProjectDetails.projectName.value = projectDetails.ClientProjectName;
        newProjectDetails.suboffering = subOffList;
        newProjectDetails.projectDescription = projectDetails.ProjectDescription;
        newProjectDetails.offering = programDetails.Offering;
        newProjectDetails.deliveryLocation = delCntr;
        newProjectDetails.targetEndDate = { value: new Date(projectDetails.TargetEndDate) };
        newProjectDetails.ioapprover =
          projectDetails.IOApproverProfileId > 0
            ? {
              label: projectDetails.IOApprover,
              text: projectDetails.IOApprover,
              value: projectDetails.IOApprover,
            }
            : {};
        newProjectDetails.projectcreator =
          projectDetails.CreatedUser.toLowerCase() === 'system generated'
            ? { label: 'System Generated', text: 'System Generated', value: 'System Generated' }
            : projectDetails.ProjectCreatorProfileId > 0
              ? { label: projCreator, text: projCreator, value: projCreator }
              : (
                // If project has deleted project creator, and user is PPC/BA then auto set as Project Creator
                !isValidProjCreator && isProgProjConfig && !this.props.userInformation.isSuperAdmin ?
                  { label: currentUserEmail, text: currentUserEmail, value: currentUserEmail }
                  : {}
              );
        newProjectDetails.projectapprover =
          projApprover === null
            ? {}
            : { label: projApprover, text: projApprover, value: projApprover };

        let tempDeliveredBy = deliveredByList.find(deliveredBy => deliveredBy.label === projectDetails.DeliveredBy);
        newProjectDetails.deliveredBy = tempDeliveredBy ? tempDeliveredBy : {};
        newProjectDetails.assetName = projectDetails.ClientProjectAssets.map(asset => ({
          label: asset.AssetName,
          text: asset.AssetName,
          value: asset.AssetID
        }));
        newProjectDetails.OMID = HasValue(projectDetails.OMID) ? projectDetails.OMID : "";
        newProjectDetails.contractID = HasValue(projectDetails.ContractID) ? projectDetails.ContractID : "";
        newProjectDetails.serviceLine = projectDetails.ServiceLine ? projectDetails.ServiceLine : 'Accenture Operations'
        newProjectDetails.tve = HasValue(projectDetails.tve) ? {
          label: projectDetails.tve? "Yes": "No",
          text: projectDetails.tve? "Yes": "No",
          value: projectDetails.tve? "Yes": "No"
        } : {
          label: "No",
          text: "No",
          value: "No"
        };
        this.props.setNewProjectDetails(newProjectDetails).then(() => {
          this.setInitMapping()
        });

        let systemGen = (projectDetails.CreatedUser.toLowerCase() === 'system generated');
        if (systemGen && ["", null].indexOf(projectDetails.DeliveredBy) > -1)
          newProjectDetails.deliveredBy = this.props.ProjectConfigurationData.assetAndDeliveredByList.deliveredByList[0]

        if (
          systemGen &&
          Array.isArray(projectDetails.ClientProjectAssets)
        ) {
          if (projectDetails.ClientProjectAssets.length === 0)
            newProjectDetails.assetName = UnRefObj(this.props.ProjectConfigurationData.assetAndDeliveredByList.assetsNameList.filter(x => x.label === 'Not Applicable'))
        }

        if (
          this.state.selectedBOIs.length !==
          this.props.ProjectConfigurationData.dimensionList.length
        ) {
          this.props.getProjectBOI(projectDetails.ProjectID);
        }
      } else {
        // For new projects, if current user is PPC/BA auto select as Project Creaor
        let clientDetails = this.props.ProgramConfigurationData.programClientDetails;
        const user = this.props.userInformation.userProfiles.find(x => x.ClientMasterId === clientDetails.ClientMasterID && x.ProfileID !== 16); //exclude QC
        const isProgProjConfig = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15; //PPC or BA
        let currentUserEmail = this.props.userInformation.emailAddress
        newProjectDetails.offering = programDetails.Offering;
        newProjectDetails.OMID = HasValue(projectDetails.OMID) ? projectDetails.OMID : "";
        newProjectDetails.contractID = HasValue(projectDetails.ContractID) ? projectDetails.ContractID : "";
        newProjectDetails.tve = HasValue(projectDetails.tve) ? {
          label: projectDetails.tve? "Yes": "No",
          text: projectDetails.tve? "Yes": "No",
          value: projectDetails.tve? "Yes": "No"
        } : {
          label: "No",
          text: "No",
          value: "No"
        };
        newProjectDetails.serviceLine = projectDetails.ServiceLine ? projectDetails.ServiceLine : 'Accenture Operations'
        if (isProgProjConfig && !this.props.userInformation.isSuperAdmin) {
          newProjectDetails.projectcreator = {
            label: currentUserEmail,
            text: currentUserEmail,
            value: currentUserEmail,
          };
          this.props.setNewProjectDetails(newProjectDetails);
        }
      }
    };

    componentDidUpdate = (nextProps) => {
      window.addEventListener('scroll', this.listenToScroll);
      if (
        nextProps.ProjectConfigurationData.dimensionList !==
        this.props.ProjectConfigurationData.dimensionList ||
        (this.state.selectedBOIs !== this.props.ProjectConfigurationData.dimensionList &&
          this.props.ProjectConfigurationData.dimensionList.length !== 0)
      ) {
        this.setState({
          selectedBOIs: this.props.ProjectConfigurationData.dimensionList,
        });
      }

      if (
        nextProps.ProjectConfigurationData.selectedProject.value !==
        this.props.ProjectConfigurationData.selectedProject.value
      ) {
        let projectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
        let deliveredByList = this.props.ProjectConfigurationData.assetAndDeliveredByList.deliveredByList
        let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
        let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;

        if (projectDetails.ProjectID) {
          let delCntr = [];
          projectDetails.DeliveryCenterList.map((loc) => {
            delCntr.push({
              label: loc.deliveryCenterName,
              text: loc.deliveryCenterName,
              value: loc.deliveryCenterID,
            });
          });

          let subOffList = [];
          projectDetails.ClientProjectSubOffering.map((subOff) => {
            subOffList.push({
              label: subOff.SubOfferingName,
              text: subOff.SubOfferingName,
              value: subOff.SubOfferingID,
            });
          });
          let clientDetails = this.props.ProgramConfigurationData.programClientDetails;
          const user = this.props.userInformation.userProfiles.find(x => x.ClientMasterId === clientDetails.ClientMasterID && x.ProfileID !== 16); //exclude QC
          //PPC or BA
          const isProgProjConfig = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15;
          let isValidProjCreator = IsValid(projectDetails.ProjectCreator);
          let currentUserEmail = this.props.userInformation.emailAddress

          let projCreator = IsValid(projectDetails.ProjectCreator) ? projectDetails.ProjectCreator :
            // For existing Projects with deleted Project Creator, if current user is PPC/BA, then auto select as Project Creator
            (isProgProjConfig && !this.props.userInformation.isSuperAdmin ? currentUserEmail : "");

          let projApprover =
            projCreator === projectDetails.ProjectApprover ||
              projectDetails.ProjectApproverProfileId === 0
              ? null
              : projectDetails.ProjectApprover;
          newProjectDetails.assetName = projectDetails.ClientProjectAssets.map(asset => ({
            label: asset.AssetName,
            text: asset.AssetName,
            value: asset.AssetID
          }));
          let tempDeliveredBy = deliveredByList.find(deliveredBy => deliveredBy.label === projectDetails.DeliveredBy)
          newProjectDetails.deliveredBy = tempDeliveredBy ? tempDeliveredBy : {};
          newProjectDetails.projectName.value = projectDetails.ClientProjectName;
          newProjectDetails.suboffering = subOffList;
          newProjectDetails.projectDescription = projectDetails.ProjectDescription;
          newProjectDetails.offering = programDetails.Offering;
          newProjectDetails.deliveryLocation = delCntr;
          newProjectDetails.targetEndDate = { value: new Date(projectDetails.TargetEndDate) };
          newProjectDetails.ioapprover =
            projectDetails.IOApproverProfileId > 0
              ? {
                label: projectDetails.IOApprover,
                text: projectDetails.IOApprover,
                value: projectDetails.IOApprover,
              }
              : {};
          newProjectDetails.projectcreator =
            projectDetails.CreatedUser.toLowerCase() === 'system generated'
              ? { label: 'System Generated', text: 'System Generated', value: 'System Generated' }
              : projectDetails.ProjectCreatorProfileId > 0
                ? { label: projCreator, text: projCreator, value: projCreator }
                : (
                  // If project has deleted project creator, and user is PPC/BA then auto set as Project Creator
                  !isValidProjCreator && isProgProjConfig && !this.props.userInformation.isSuperAdmin ?
                    { label: currentUserEmail, text: currentUserEmail, value: currentUserEmail }
                    : {}
              );
          newProjectDetails.projectapprover =
            projApprover === null
              ? {}
              : { label: projApprover, text: projApprover, value: projApprover };
          newProjectDetails.OMID = HasValue(projectDetails.OMID) ? projectDetails.OMID : "";
          newProjectDetails.contractID = HasValue(projectDetails.ContractID) ? projectDetails.ContractID : "";
          newProjectDetails.tve = HasValue(projectDetails.tve) ? {
            label: projectDetails.tve? "Yes": "No",
            text: projectDetails.tve? "Yes": "No",
            value: projectDetails.tve? "Yes": "No"
          } : {
            label: "No",
            text: "No",
            value: "No"
          };
          newProjectDetails.serviceLine = projectDetails.ServiceLine ? projectDetails.ServiceLine : 'Accenture Operations'
          this.props.setNewProjectDetails(newProjectDetails).then(() => {
            this.setInitMapping();
          });
          this.props.getProjectBOI(projectDetails.ProjectID);
        }
      }
    };

    onSelectOption = (e) => {
      this.setState({
        value: e,
      });
    };
    onClickXButton = () => {
      this.props.history.push('/ProgramProjectConfig');
    };

    prepBOIForSaving = () => {
      let copy=sessionStorage.getItem("copy");
      let list = this.state.selectedBOIs;
      let projectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
      let bois = [];
      list.map((dim) => {
        dim.subDimensionList.map((subdim) => {
          subdim.boiList.map((boi) => {
            bois.push({
              clientProjectID: (projectDetails.ProjectID&&(!copy||copy==null)) ? projectDetails.ProjectID : 0,
              clientProjectTargetID: boi.boiClientProjectTargetID,
              boiid: boi.boiID,
              uom: boi.uom,
              boiName: boi.boiName,
              boiTarget: boi.paramValue === '' ? 0 : boi.paramValue,
              boiStatus: '',
              boiReason: '',
              coreDimensionID: dim.dimensionID,
              subDimensionID: subdim.subDimensionID,
              approver: '',
              rejectionRecipient: '',
              isActive: true,
              // wheelVisibility: boi.boiWheelVisibility ? 1 : 0,
              wheelVisibility: boi.boiWheelVisibility, //always val of programwheelvisibility return in API because there is no status
            });
          });
        });
      });

      let origDimList = this.props.ProjectConfigurationData.origDimensionList;
      origDimList.map((dim) => {
        dim.subDimensionList.map((subdim) => {
          subdim.boiList.map((boi) => {
            let isDeleted = true;
            bois.map((item) => {
              if (
                item.boiid === boi.boiID &&
                item.coreDimensionID === dim.dimensionID &&
                item.subDimensionID === subdim.subDimensionID
              ) {
                isDeleted = false;
              }
            });
            if (isDeleted) {
              bois.push({
                clientProjectID: projectDetails.ProjectID ? projectDetails.ProjectID : 0,
                clientProjectTargetID: boi.boiClientProjectTargetID,
                boiid: boi.boiID,
                uom: boi.uom,
                boiName: boi.boiName,
                boiTarget: boi.paramValue === '' ? 0 : boi.paramValue,
                boiStatus: '',
                boiReason: '',
                coreDimensionID: dim.dimensionID,
                subDimensionID: subdim.subDimensionID,
                approver: '',
                rejectionRecipient: '',
                isActive: false,
                // wheelVisibility: boi.boiWheelVisibility ? 1 : 0,
                wheelVisibility: boi.boiWheelVisibility, //always val of programwheelvisibility return in API because there is no status
              });
            }
          });
        });
      });

      return bois;
    };

    prepDataToSave = (comment, isSaveEdit) => {
      let copy=sessionStorage.getItem("copy");
      let clientDetails = this.props.ProgramConfigurationData.programClientDetails;
      const { ClientMasterID } = clientDetails
      const user = this.props.userInformation.userProfiles.find(x => x.ClientMasterId === ClientMasterID && x.ProfileID !== 16); //exclude QC
      const isProgProjConfig = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15;

      let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
      let projectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let userInfo = this.props.userInformation;
      let oal = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead;
      const isProjectApprover = (newProjectDetails.oaLead.some((a) => a.text === this.props.userInformation.emailAddress) || //oal
        newProjectDetails.cxLead.some((a) => a.text === this.props.userInformation.emailAddress) || //cxl
        newProjectDetails.ioapprover.text === this.props.userInformation.emailAddress) && //io
        !this.props.userInformation.isSuperAdmin;

      let projCreator = copy !=="true"?!IsObjEmpty(newProjectDetails.projectcreator) ? newProjectDetails.projectcreator.label : "": this.props.userInformation.emailAddress;

      let createdUser = isSaveEdit ?
        userInfo.enterpriseID :
        userInfo.isSuperAdmin || IsValid(projCreator) || isProjectApprover ? projCreator.split("@")[0] :
          userInfo.enterpriseID;
      let updatedUser = isSaveEdit ?
        userInfo.enterpriseID : userInfo.isSuperAdmin || isProjectApprover ? projCreator.split("@")[0] :
          userInfo.enterpriseID

      let deliveryCenter = [];
      newProjectDetails.deliveryLocation.map((loc) => {
        deliveryCenter.push({
          projectID: (projectDetails.ProjectID&&(copy=="false"||copy==null)) ? projectDetails.ProjectID : 0,
          deliveryCenterID: loc.value,
          deliveryCenterName: loc.label,
          createdUser: createdUser,
          updatedUser: updatedUser,
        });
      });
      let date = new Date(newProjectDetails.targetEndDate.value);
      let _local = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - _local);

      let subOfferings = [];
      newProjectDetails.suboffering.map((subOff) => {
        subOfferings.push({
          clientProjectID: (projectDetails.ProjectID&&(copy=="false"||copy==null)) ? projectDetails.ProjectID : 0,
          subOfferingID: subOff.value,
          subOfferingName: subOff.label,
          isActive: true,
          createdUser: createdUser,
          updatedUser: updatedUser,
        });
      });

      let configuratorsList = this.props.ProgramConfigurationData.ConfiguratorsList


      let projectAssetsName = []

      newProjectDetails.assetName.map(assetName => {
        projectAssetsName.push({
          clientProjectsID: (projectDetails.ProjectID&&(copy=="false"||copy==null)) ? projectDetails.ProjectID : 0,
          assetID: assetName.value,
          isActive: true,
          createdUser: createdUser,
          updatedUser: updatedUser
        })
      })

      let approvers = '';

      newProjectDetails.oaLead.map(x => {
        approvers += x.label + ",4;"
      })
      newProjectDetails.cxLead.map(x => {
        approvers += x.label + ",7;"
      })
      newProjectDetails.businessAdvisor.map(x => {
        approvers += x.label + ",15;"
      })

      let profileId = this.props.userInformation.userProfiles.some(x=>x.ClientMasterId ===programDetails.ClientMasterID)? this.props.userInformation.userProfiles.filter(x=>x.ClientMasterId ===programDetails.ClientMasterID)[0].ProfileID:0;
      let data = {
        clientProgramID: programDetails.ClientProgramID,
        projectID: (projectDetails.ProjectID&&(copy=="false"||copy==null)) ? projectDetails.ProjectID : 0,
        clientProjectName: newProjectDetails.projectName.value.trim(),
        projectCreator: projCreator,
        projectCreatorProfileId: copy !=="true"? newProjectDetails.projectcreator.label === "System Generated" ? 12 :
          configuratorsList.some(x => x.label === projCreator) ?
            configuratorsList.filter(x => x.label === projCreator)[0].id :
            projectDetails.ProjectCreatorProfileId : profileId === 12 || profileId === 15 ? profileId : userInfo.isSuperAdmin ? 1 : 0,
        ioApprover: newProjectDetails.ioapprover.label,
        projectDescription: newProjectDetails.projectDescription,
        offeringID: programDetails.OfferingID,
        targetEndDate: date,
        operationsAccountLead: oal,
        comments: comment,
        createdUser: createdUser,
        updatedUser: updatedUser,
        mailTo: newProjectDetails.projectapprover.label,
        deliveryCenterList: deliveryCenter,
        projectIdentifiedValueList: this.prepBOIForSaving(),
        clientProjectSubOffering: subOfferings,
        allOfferings: this.props.ProgramConfigurationData.isCheckedAllOfferings,
        deliveredByID: newProjectDetails.deliveredBy.value,
        clientProjectAssets: projectAssetsName,
        cxl_OAL_BA_List: approvers,
        OMID: HasValue(newProjectDetails.OMID) ? newProjectDetails.OMID : "",
        tve: newProjectDetails.tve.value === "Yes",
        ContractID: HasValue(newProjectDetails.contractID) ? newProjectDetails.contractID : "",
        serviceLine: newProjectDetails.serviceLine,
      };
      return data;
    };

    setInitMapping = () => {
      /* 
        Save Initial Mappings of Selected Project
        Upon save of Project, compare new mappings to initial if there are any that are replaced/remove approvers
      */
      try {
        let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
        if (!IsObjEmpty(newProjectDetails)) {
          let selectedioapprover = newProjectDetails.ioapprover;
          let selectedCXLead = newProjectDetails.cxLead;
          let selectedOALead = newProjectDetails.oaLead;
          this.setState({
            initialMappings: {
              cxl: selectedCXLead.length > 0 ? selectedCXLead : [],
              oal: selectedOALead.length > 0 ? selectedOALead : [],
              ioa: !IsObjEmpty(selectedioapprover) ? selectedioapprover : {}
            }
          })
        }
      } catch (e) {

      }
    }

    addEditUserDisclaimer = async () => {
      try {
        let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
        let selectedProjectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
        let initialMappings = this.state.initialMappings;
        // Check mappings for Existing projects with initals mappings
        if (!IsObjEmpty(newProjectDetails) && !IsObjEmpty(selectedProjectDetails) && !IsObjEmpty(initialMappings)) {
          let removedReplacedUser = [];
          let cxStrArr = [];
          let oalStrArr = [];
          newProjectDetails.oaLead.forEach(x => {
            oalStrArr.push(x.label);
          })
          newProjectDetails.cxLead.forEach(x => {
            cxStrArr.push(x.label);
          })

          initialMappings.oal.forEach((y) => {
            if (IsValidStr(y.label)) {
              if (oalStrArr.indexOf(y.label) === -1) {
                removedReplacedUser.push({
                  UserEmailID: y.label,
                  RoleID: 4
                })
              }
            }
          })
          initialMappings.cxl.forEach((y) => {
            if (IsValidStr(y.label)) {
              if (cxStrArr.indexOf(y.label) === -1) {
                removedReplacedUser.push({
                  UserEmailID: y.label,
                  RoleID: 7
                })
              }
            }
          })
          let tmpIOA = initialMappings.ioa;
          let tmpSelIOA = newProjectDetails.ioapprover;
          if (!IsObjEmpty(tmpIOA) && !IsObjEmpty(tmpSelIOA)) {
            if (tmpIOA.label !== tmpSelIOA.label) {
              removedReplacedUser.push({
                UserEmailID: tmpIOA.label,
                RoleID: 8
              })
            }
          }
          if (removedReplacedUser.length > 0) {
            let clientID = this.props.ProgramConfigurationData.programClientDetails.ClientMasterID;
            let body = {
              ClientID: clientID,
              UserRoles: removedReplacedUser
            }
            let subMenuId = GetSubMenuId("Project Identified Value Settings");
            HttpPost(`User/AddEditUserDisclaimer?SubMenuID=${subMenuId}`, body).then((data) => {
              if (data.status === undefined && !IsObjEmpty(data)) {
                console.log("success");
              }
            });
          }
        }
      } catch (e) {

      }
    }

    /* onClickSAD = async () => {
      try {
        this.setState({
          disableSADBtn: true
        }, async () => {
          const hasBlank = this.checkforBlanks(this.state.selectedBOIs);
          if (hasBlank) {
            this.setState({
              hasBlank: hasBlank,
              disableSADBtn: false
            });
            return;
          }
          let data = this.prepDataToSave('', true);
          await this.props.saveAsDraftProjectIdentified(data).then(success => {
            this.setState({
              disableSADBtn: false
            })
            if (success) {
              this.props.history.push('/ProgramProjectConfig/Projects');
            }
          })
          this.setState({
            disableSADBtn: false
          })
        })
      }
      catch (e) {
        console.log(e);
      }
  
    }; */
    onClickSAC = async () => {
      
      try {
        this.setState({
          disableSADBtn: true
        }, async () => {
          const hasBlank = this.checkforBlanks(this.state.selectedBOIs);
          if (hasBlank) {
            this.setState({
              hasBlank: hasBlank,
              disableSADBtn: false
            });
            return;
          }
          let data = this.prepDataToSave('', false);
          await this.props.saveAndCloseProjectIdentified(data).then(async (success) => {
            this.setState({
              disableSADBtn: false
            })
            if (success) {
              // await this.addEditUserDisclaimer();
              this.props.history.push('/ProgramProjectConfig/Projects');
            }
          })
          sessionStorage.setItem("copy",false);
          this.setState({
            disableSADBtn: false
          })
        })
      }
      catch (e) {
        console.log(e);
      }

    };
    onClickSAR = async () => {
      
      try {
        this.setState({
          disableSADBtn: true
        }, async () => {
          const hasBlank = this.checkforBlanks(this.state.selectedBOIs);
          if (hasBlank) {
            this.setState({
              hasBlank: hasBlank,
              disableSADBtn: false
            });
            return;
          }
          let data = this.prepDataToSave('', false);
          await this.props.saveAndReportProjectIdentified(data).then(async (data) => {
            this.setState({
              disableSADBtn: false
            })
            if (data.isSuccess) {
              // await this.addEditUserDisclaimer();

              const clientMasterName = this.props.ProgramConfigurationData.programClientDetails.ClientMasterName;
              const selectedProgram = this.props.ProgramConfigurationData.selectedProgram;
              const projectId = TryGetObjValueAlt(data, '', 'projectID')
              this.props.history.push(`/DataIngestion/${clientMasterName}/${selectedProgram.value}/${projectId}`);
            }
            //DataEvent("projectCreate", this.props.SharedData.selectedClient.text);
          });
          sessionStorage.setItem("copy",false);
          this.setState({
            disableSADBtn: false
          })
        })
      }
      catch (e) {
        console.log(e);
      }

    };
    /* onClickSend = async (comment) => {
      let data = this.prepDataToSave(comment, true);
      this.setState({
        disableSendBtn: true
      }, async () => {
        await this.props.sendForApprovalProjectIdentified(data).then(success => {
          if (success) {
            this.props.history.push('/ProgramProjectConfig/Projects');
          }
        })
        this.setState({
          disableSendBtn: false
        })
      });
  
    }; */
    /* onClickSFA = () => {
      let bois = this.state.selectedBOIs;
      let hasBlank = this.checkforBlanks(bois);
      this.setState({
        hasBlank: hasBlank,
        showSFAModal: hasBlank ? false : !this.state.showSFAModal,
      });
    }; */
    onSaveAddBOI = (bois) => {
      const stageId = this.props.ProjectConfigurationData.selectedProjectDetails.StageId;
      const statusId = this.props.ProjectConfigurationData.selectedProjectDetails.StatusId;
      if (
        (stageId === 1 && statusId === 2) || // on In Progress status
        (stageId === undefined && statusId === undefined)
      ) {
        // on New Create Status
        bois = bois
          .map((dim) => {
            const subDimList = dim.subDimensionList
              .map((subd) => {
                const boiList = subd.boiList.filter((boi) => !boi.hide);
                return { ...subd, boiList: boiList };
              })
              .filter((sd) => sd.boiList.length > 0);
            return { ...dim, subDimensionList: subDimList };
          })
          .filter((d) => d.subDimensionList.length > 0);
      }

      this.setState(
        {
          selectedBOIs: bois,
          hasBlank: this.checkforBlanks(bois),
        },
        () => {
          if (document.getElementById('expandAll')) {
            document.getElementById('expandAll').click();
          }
        }
      );
      this.props.setProjectDimensionList(bois);
    };

    editProject = () => {
      try {
        this.setState({
          disableSendBtn: true
        }, async () => {
          let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
          let selectedProjectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
          let oal = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead;
          let deliveryCenter = [];
          newProjectDetails.deliveryLocation.map((item) => {
            deliveryCenter.push({
              deliveryCenterID: item.value,
              createdUser: this.props.userInformation.enterpriseID,
              updatedUser: this.props.userInformation.enterpriseID,
            });
          });

          let subOfferings = [];
          newProjectDetails.suboffering.map((subOff) => {
            subOfferings.push({
              clientProjectID: selectedProjectDetails.ProjectID ? selectedProjectDetails.ProjectID : 0,
              subOfferingID: subOff.value,
              subOfferingName: subOff.label,
              isActive: true,
              createdUser: this.props.userInformation.enterpriseID,
              updatedUser: this.props.userInformation.enterpriseID,
            });
          });

          let date = new Date(newProjectDetails.targetEndDate.value);
          let _local = date.getTimezoneOffset();
          date.setMinutes(date.getMinutes() - _local);

          let configuratorsList = this.props.ProgramConfigurationData.ConfiguratorsList
          const projCreator = !IsObjEmpty(newProjectDetails.projectcreator) ? newProjectDetails.projectcreator.label : "";

          let projectAssetsName = []

          newProjectDetails.assetName.map(assetName => {
            projectAssetsName.push({
              clientProjectsID: selectedProjectDetails.ProjectID ? selectedProjectDetails.ProjectID : 0,
              assetID: assetName.value,
              isActive: true,
              createdUser: this.props.userInformation.enterpriseID,
              updatedUser: this.props.userInformation.enterpriseID
            })
          })

          let data = {
            clientProgramID: this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
            operationsAccountLead: oal,
            projectID: selectedProjectDetails.ProjectID,
            clientProjectName: newProjectDetails.projectName.value.trim(),
            projectCreator: projCreator,
            projectCreatorProfileId: newProjectDetails.projectcreator.label === "System Generated" ? 12 :
              configuratorsList.some(x => x.label === projCreator) ?
                configuratorsList.filter(x => x.label === projCreator)[0].id :
                selectedProjectDetails.ProjectCreatorProfileId,
            projectApprover: newProjectDetails.projectapprover.label,
            projectApproverProfileId: newProjectDetails.projectapprover.label === "System Approved" ? 12 :
              configuratorsList.some(x => x.label === newProjectDetails.projectapprover.label) ?
                configuratorsList.filter(x => x.label === newProjectDetails.projectapprover.label)[0].id :
                selectedProjectDetails.ProjectApproverProfileId,
            ioApprover: newProjectDetails.ioapprover.label,
            // "ioApproverProfileId": newProjectDetails.ioapprover.value,
            projectDescription: newProjectDetails.projectDescription,
            targetEndDate: date,
            updatedUser: this.props.userInformation.enterpriseID,
            deliveryCenterList: deliveryCenter,
            clientProjectSubOffering: subOfferings,
            deliveredByID: newProjectDetails.deliveredBy.value,
            clientProjectAssets: projectAssetsName,
            OMID: HasValue(newProjectDetails.OMID) ? newProjectDetails.OMID : "",
            ContractID: HasValue(newProjectDetails.contractID) ? newProjectDetails.contractID : "",
          };
          await this.props.editProject(data, this.props.history);
          this.setState({
            disableSendBtn: false
          })
        })
      }
      catch (e) {
        console.log(e);
      }
    };

    checkForInvalidEmailFirst = async () => {
      let isCxlValid = true;
      let isOalValid = true;
      let isBaValid = true;
      let isIOApproverValid = true;
      let isProjectCreatorValid = true;

      let cxlList = this.props.ProjectConfigurationData.selectedProjectDetails.CXLead.length ? this.props.ProjectConfigurationData.selectedProjectDetails.CXLead.split(',') : [];
      let oalList = this.props.ProjectConfigurationData.selectedProjectDetails.OperationsAccountLead.length ? this.props.ProjectConfigurationData.selectedProjectDetails.OperationsAccountLead.split(',') : [];
      let baList = this.props.ProjectConfigurationData.selectedProjectDetails.BusinessAdvisor.length ? this.props.ProjectConfigurationData.selectedProjectDetails.BusinessAdvisor.split(',').filter(item => item !== 'Not Applicable') : [];
      let projCreatorList = this.props.ProjectConfigurationData.selectedProjectDetails.ProjectCreator ? [this.props.ProjectConfigurationData.selectedProjectDetails.ProjectCreator] : [];
      let ioApproverList = this.props.ProjectConfigurationData.selectedProjectDetails.IOApprover ? [this.props.ProjectConfigurationData.selectedProjectDetails.IOApprover] : [];
      let allValues = [...cxlList, ...oalList, ...baList, ...projCreatorList, ...ioApproverList];

      const data = await IsValidAccentureEmail(allValues.filter(item => item !== 'System generated'));
      let isValid = true;
      let allInvalidEmails = [];

      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          isValid = false;
          allInvalidEmails.push(data[i].UserName);
        }
      }

      if (!isValid) {
        this.setCmErrorMessage([
          { 
            key: 'Valid',
            value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.map(item => item.split('@')[0]).join(', ')}` }
        ]);

        allInvalidEmails.map((item) => {
          if (cxlList.map((i) => i).indexOf(item) !== -1) {
            isCxlValid = false;
          } 
          if (oalList.map((i) => i).indexOf(item) !== -1) {
            isOalValid = false;
          }
          if (baList.map((i) => i).indexOf(item) !== -1) {
            isBaValid = false;
          }
          if (ioApproverList.map((i) => i).indexOf(item) !== -1) {
            isIOApproverValid = false;
          }
          if (projCreatorList.map((i) => i).indexOf(item) !== -1) {
            isProjectCreatorValid = false;
          }
        })
      } else {
        this.setCmErrorMessage([]);
      }

      this.setState({
        isValidNewUser: {
          cxl: isCxlValid,
          oal: isOalValid,
          ba: isBaValid,
        },
        isValidIoApprover: isIOApproverValid,
        isValidProjectCreator: isProjectCreatorValid,
        anyInvalidEmailAvailable: !isValid,
        allInvalidEmails: allInvalidEmails,
        anyInvalidEmailsInDropdown: {
          cxl: !isCxlValid,
          oal: !isOalValid,
          ba: !isBaValid,
        }
      });
    }
    
    resetErrorStates = () => {
      this.setState({
          isValidNewUser: {
            cxl: true,
            oal: true,
            ba: true,
          },
          isValidIoApprover: true,
          isValidProjectCreator: true,
          anyInvalidEmailAvailable: false,
          allInvalidEmails: [],
          anyInvalidEmailsInDropdown: {
            cxl: false,
            oal: false,
            ba: false,
          }  
        });
    }

    dimFormCallback = (dim) => {
      this.setState({
        selectedBOIs: dim,
        hasBlank: this.checkforBlanks(dim),
      });
      
      // to check for invalid email ids if user is in edit mode and project is not approved
      if(!this.state.editedAnyFieldOnce && this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID && this.props.ProjectConfigurationData.selectedProjectDetails.OverallStatus === 'Approved') {
        this.checkForInvalidEmailFirst();
        this.setEditedAnyFieldOnce(true);
      }
    };

    checkforBlanks = (item) => {
      return item.some((dim) => {
        return dim.subDimensionList.some((subdim) => {
          return subdim.boiList.some((boi) => {
            return boi.paramValue === '';
          });
        });
      });
    };
    validateDimension = () => {
      const dim = this.state.dimensionList;
      let isValid = true;
      dim.forEach((e) => {
        e.subDimensionList.forEach((e2) => {
          e2.boiList.forEach((e3) => {
            if (!IsValidStr(e3.paramValue.trim())) e3['inValidParam'] = true;
            if (!IsValidStr(e3.comment.trim())) e3['inValidComment'] = true;
          });
        });
      });
      return isValid;
    };

    onClickRVD = (e) => {
      this.setState({ showRVD: e });
      sessionStorage.setItem("copy",false)
    }
    
    render() {
      const { newProjectDetails, selectedProjectDetails, showSelectProjectView, arsDetails } =
        this.props.ProjectConfigurationData;
      const { programClientDetails, IOAApproverList, selectedProgram } = this.props.ProgramConfigurationData;
      const { PnPComponents } = this.props.AccessValidationData;
      const { userInformation, getCustomBOIDetails } = this.props;
      const { isSuperAdmin } = this.props.userInformation;
      const{basicDetails}=this.props.ConfigureProgramData
      return (
        <ProjectIDValueSettingsPresentational
          onSaveAddBOI={this.onSaveAddBOI}
          onClickXButton={this.onClickXButton}
          basicDetails={basicDetails}
          // onClickSAD={this.onClickSAD}
          onClickSAC={this.onClickSAC}
          onClickSAR={this.onClickSAR}
          // onClickSend={this.onClickSend}
          selectedBOIs={this.state.selectedBOIs}
          dimFormCallback={this.dimFormCallback}
          programClientDetails={programClientDetails}
          IOAApproverList={IOAApproverList}
          selectedProjectDetails={selectedProjectDetails}
          newProjectDetails={newProjectDetails}
          editProject={this.editProject}
          hasBlank={this.state.hasBlank}
          // onClickSFA={this.onClickSFA}
          showSFAModal={this.state.showSFAModal}
          PnPComponents={PnPComponents}
          userInformation={userInformation}
          showSelectProjectView={showSelectProjectView}
          SharedData={this.props.SharedData}
          ProgramConfigurationData={this.props.ProgramConfigurationData}
          getCustomBOIDetails={getCustomBOIDetails}
          selectedProgram={selectedProgram}
          isSuperAdmin={isSuperAdmin}
          arsDetails={arsDetails}
          disableSendBtn={this.state.disableSendBtn}
          disableSADBtn={this.state.disableSADBtn}
          positionFixed={this.state.positionFixed}
          accordionHeaderClick={this.accordionHeaderClick}
          onClickRVD={this.onClickRVD}
          showRVD={this.state.showRVD}
          show={this.state.show}
          history={this.props.history}
          showError={this.props.showError}
          invalidDesc={this.state.invalidDesc}
          setInvalidDesc={this.setInvalidDesc}
          cmErrorMessage={this.state.cmErrorMessage}
          setCmErrorMessage={this.setCmErrorMessage}
          editedAnyFieldOnce={this.state.editedAnyFieldOnce}
          setEditedAnyFieldOnce={this.setEditedAnyFieldOnce}
          checkForInvalidEmailFirst={this.checkForInvalidEmailFirst}
          anyInvalidEmailAvailable={this.state.anyInvalidEmailAvailable}
          setAnyInvalidEmailAvailable={this.setAnyInvalidEmailAvailable}
          isValidNewUser={this.state.isValidNewUser}
          setIsValidNewUser={this.setIsValidNewUser}
          isValidIoApprover={this.state.isValidIoApprover}
          setIsValidIoApprover={this.setIsValidIoApprover}
          isValidProjectCreator={this.state.isValidProjectCreator}
          setIsValidProjectCreator={this.setIsValidProjectCreator}
          allInvalidEmails={this.state.allInvalidEmails}
          setAllInvalidEmails={this.setAllInvalidEmails}
          anyInvalidEmailsInDropdown={this.state.anyInvalidEmailsInDropdown}
          setAnyInvalidEmailsInDropdown={this.setAnyInvalidEmailsInDropdown}
          selectedProjectStatus={this.props.ProjectConfigurationData.selectedProjectDetails.OverallStatus}
          resetErrorStates={this.resetErrorStates}
          copiedProjectName = {this.props.copiedProjectName}
          />
      );
    }
  }

  return ProjectIDValueSettingsContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      userInformation: state.userInformation,
      ProjectConfigurationData: state.ProjectConfigurationData,
      ProgramConfigurationData: state.ProgramConfigurationData,
      AccessValidationData: state.AccessValidationData,
      SharedData: state.SharedData,
      showError: state.SharedData.showError,
      copiedProjectName: state.ProjectConfigurationData.copiedProjectName
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          sendForApprovalProgramIdentified,
          setNewProgramDetails,
          saveAsDraftProjectIdentified,
          sendForApprovalProjectIdentified,
          setNewProjectDetails,
          setProjectDimensionList,
          editProject,
          getProjectBOI,
          getCustomBOIDetails,
          saveAndCloseProjectIdentified,
          saveAndReportProjectIdentified,
          setEmailError
        }
      ),
      dispatch
    )
)(withRouter(ProjectIDValueSettings));
