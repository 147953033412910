import React, { Component } from "react";
import {
  Accordion,
  Card,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  useAccordionToggle,
} from "react-bootstrap";
import { connect } from "react-redux";
import { GrooveIconSvg, GrooveInput, GrooveRadioButton } from "tfo-groove";

import "../ProgramIdentified.css";

const BOI = (function() {
  class BOIPresentational extends Component {
    render() {
      return (
        <form>
          <Row key={this.props.boiID} className="boi-details-input">
            <Col
              lg={6}
              md={6}
              sm={6}
              className="sub-dimension-title label14-regular-slate"
            >
              {this.props.boiName}{" "}
              <div className="info-icon">
                <GrooveIconSvg
                  size="large"
                  name="info-circle"
                  iconStyle="solid"
                  iconColor="stat-alternate"
                />
                <span className="tooltiptext">{this.props.boiDescription}</span>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="sub-dimension-input">
                <div className="sub-dim-input">
                  <GrooveInput
                    id="input-basic"
                    name="IdentifiedValue"
                    placeholder="Target Value"
                    type="number"
                    isAutocomplete="false"
                    inputValue={this.props.boiValue === 0 ? "" : this.props.boiValue}
                    isDisabled={this.props.role !== "Operations Account Lead" && this.props.IsReadOnly ? true : false}
                    maxNumber={999999999999}
                    minNumber={0}
                    customClassName={
                      this.props.boiStatus == "Rejected"
                        ? "input-invalid"
                        : this.props.boiStatus === "Conditional Approval"
                        ? "input-conditional"
                        : null
                    }
                    callbackFunction={(a) =>
                      this.props.onChangeBOI(
                        a,
                        "inputIdentified",
                        this.props.dimensionIndex,
                        this.props.subDimensionIndex,
                        this.props.boiIndex
                      )
                    }
                  />
                  <span className="label14-medium-slate">
                    {this.props.boiUOM}
                  </span>
                </div>
                <form>
                  <div className="radio-button-container">
                    {(this.props.role === "Operations Account Lead") ? (
                      <GrooveRadioButton
                        callbackFunction={(a) =>
                          this.props.onChangeBOI(
                            a,
                            "radioButton",
                            this.props.dimensionIndex,
                            this.props.subDimensionIndex,
                            this.props.boiIndex
                          )
                        }
                        radioBtnValues={this.props.radioButton}
                        radioBtnTheme="Light"
                        customClassName="groove-radio-button"
                      />
                    ) : null}
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </form>
      );
    }
  }

  class BOIContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        radioButton: [
          {
            radioBtnLabel: "Approve",
            radioBtnValue: "Approve",
            defaultChecked:
              this.props.boiStatus === "Approve" || this.props.boiStatus === ""
                ? true
                : false,
            name: "radio-select",
            id: "radio-1-" + this.props.selectedProgramID + "-" + this.props.boiID,
          },
          {
            radioBtnLabel: "Reject",
            radioBtnValue: "Rejected",
            defaultChecked: this.props.boiStatus === "Rejected" ? true : false,
            name: "radio-select",
            id: "radio-2-" + this.props.selectedProgramID + "-" + this.props.boiID,
          },
          {
            radioBtnLabel: "Conditional Approval",
            radioBtnValue: "Conditional Approval",
            defaultChecked:
              this.props.boiStatus === "Conditional Approval" ? true : false,
            name: "radio-select",
            id: "radio-3-" + this.props.selectedProgramID + "-" + this.props.boiID,
          },
        ],
      };
    }

    componentDidUpdate(nextProps){
      if(nextProps.selectedProgramID !== this.props.selectedProgramID){
        this.setState({
          radioButton: [
            {
              radioBtnLabel: "Approve",
              radioBtnValue: "Approve",
              defaultChecked:
                this.props.boiStatus === "Approve" || this.props.boiStatus === "" || this.props.boiStatus === null
                  ? true
                  : false,
              name: "radio-select",
              id: "radio-1-" + this.props.selectedProgramID + "-" + this.props.boiID,
            },
            {
              radioBtnLabel: "Reject",
              radioBtnValue: "Rejected",
              defaultChecked: this.props.boiStatus === "Rejected" ? true : false,
              name: "radio-select",
              id: "radio-2-" + this.props.selectedProgramID + "-" + this.props.boiID,
            },
            {
              radioBtnLabel: "Conditional Approval",
              radioBtnValue: "Conditional Approval",
              defaultChecked:
                this.props.boiStatus === "Conditional Approval" ? true : false,
              name: "radio-select",
              id: "radio-3-" + this.props.selectedProgramID + "-" + this.props.boiID,
            },
          ],
        })
      }
      else if(nextProps.boiStatus !== this.props.boiStatus){
        this.setState({
          radioButton: [
            {
              radioBtnLabel: "Approve",
              radioBtnValue: "Approve",
              defaultChecked:
                this.props.boiStatus === "Approve" || this.props.boiStatus === "" || this.props.boiStatus === null
                  ? true
                  : false,
              name: "radio-select",
              id: "radio-1-" + this.props.selectedProgramID + "-" + this.props.boiID,
            },
            {
              radioBtnLabel: "Reject",
              radioBtnValue: "Rejected",
              defaultChecked: this.props.boiStatus === "Rejected" ? true : false,
              name: "radio-select",
              id: "radio-2-" + this.props.selectedProgramID + "-" + this.props.boiID,
            },
            {
              radioBtnLabel: "Conditional Approval",
              radioBtnValue: "Conditional Approval",
              defaultChecked:
                this.props.boiStatus === "Conditional Approval" ? true : false,
              name: "radio-select",
              id: "radio-3-" + this.props.selectedProgramID + "-" + this.props.boiID,
            },
          ],
        })
      }
    }


    render() {
      return (
        <BOIPresentational
          {...this.props}
          radioButton={this.state.radioButton}
        />
      );
    }
  }

  return BOIContainer;
})();

export default BOI;
