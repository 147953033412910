import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import store, { history } from "../../../store";
import { withRouter } from "react-router-dom";
import ClientStage from "./ClientStage";
import ProgramIdentified from "./ProgramIdentified";
import ConfigureClient from "./ConfigureClient";
import ProjectDetails from "./ProjectDetails/ProjectDetails";
import ProjectIdentified from "./ProjectIdentified";
import ConfigurationComplete from "./ConfigurationComplete";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faTimes,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { setStageReportingYearParam } from "../../../Actions/Stage.Action";
import { setWorkFlowReportingYear } from "../../../Actions/ClientConfiguration.Action";

import { GrooveButton, GrooveIconSvg, GrooveTooltip } from "tfo-groove";
import CVMSelectDropdown from "../../Shared/CVMSelectDropdown/CVMSelectDropdown";
import CVMModal from "../../Shared/CVMModal/CVMModal";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Images
import clientconfig from "./../../../Static/images/360cvm/ClientConfigStage.svg";
import identifiedvalue from "./../../../Static/images/360cvm/ProgramIdentifiedStage.svg";
import projectdetails from "./../../../Static/images/360cvm/ProjectDetailsStage.svg";
import configcomplete from "./../../../Static/images/360cvm/ConfigCompleteStage.svg";

import InfoIcon from "./../../../Static/images/360cvm/InfoIcon.svg";
import { HttpGet } from "../../../Utilities/HTTPUtil";
import { IsValid } from "../../../Utilities/Validations";

const STAGE = {
  clientStage: { step: "0", stage: "clientstage", desc: "Client Stage" },
  clientConfig: {
    step: "1",
    stage: "configureclient",
    desc: "Client Configuration",
  },
  progIDValueSettings: {
    step: "2",
    stage: "programidentifiedvaluesettings",
    desc: "Value Meter Program Target Value Settings",
  },
  projDetails: { step: "3", stage: "projectdetails", desc: "Project Details" },
  projIDValueSettings: {
    step: "4",
    stage: "projectidentifiedvaluesettings",
    desc: "Project Target Value Settings",
  },
  configurationComplete: {
    step: "5",
    stage: "configurationcomplete",
    desc: "Configuration Complete",
  }
}

const Stages = (function () {
  class StagesPresentational extends Component {
    constructor(props) {
      super(props);
      this.state = {
        step: "1",
        role: "",
        projectIdentifiedData: [],
        selectedProjectData: [],
        projectCount: 0,
        projectIdentifiedData: [],
        selectedProjectData: [],
        projectCount: 0,
        progDeatilsProjectDetails: {
          programName: "",
          reportingYear: "",
          offering: "",
          offeringID: 0,
          bTLead: "",
          cXLead: "",
        },
      };
    }
    setStep(step) {
      this.setState({
        step: step,
      });
    }

    //Project Identified Screen
    getProjectIdentifiedData = async () => {
      let clienID = store.getState().SharedData.selectedClient.value;
      let reportingYear = store.getState().ClientConfigurationData.workFlowReportingYear;
      const res = await HttpGet(
        `ProjectIdentifiedValueSetting/GetProgramProjectInfo/clientID?clientID=${clienID}&reportingYear=${reportingYear}`
      );
      try {
        let responseData = res;
        let projectCount = 0;
        let data = responseData[0].ClientProjects.filter(
          (project, index) => index == 0
        );
        responseData.map((program) => {
          if (program.ClientProjects !== null) {
            projectCount = projectCount + program.ClientProjects.length;
          }
        });
        this.setState({
          projectIdentifiedData: responseData,
          projectCount: projectCount,
          selectedProjectData: data[0],
          selectedProject: 1,
        });
      } catch (error) {
        console.log(error);
      }
    };

    setSelectedProject = (projectname) => {
      let selectedProject;
      let { projectIdentifiedData } = this.state;
      projectIdentifiedData.forEach((program) => {
        if (program.ClientProjects) {
          if (
            program.ClientProjects.find(
              (project) => project.ClientProjectName === projectname
            )
          ) {
            selectedProject = program.ClientProjects.find(
              (project) => project.ClientProjectName === projectname
            );
          }
        }

      });
      this.setState({
        selectedProjectData: selectedProject,
      });
    };

    setProgDetailsProjectDetails = (data) => {
      this.setState({ progDeatilsProjectDetails: data });
    };

    onProjectDataUpdate = (data) => {
      this.setState({ selectedProjectData: data });
    };

    getUserRole = () => {
      let userProfiles = store.getState().userInformation.userProfiles;
      let selectedClient = store.getState().SharedData.selectedClient;
      let userProfile = userProfiles.find(
        (client) => client.ClientName === selectedClient.label
      );
      this.setState({ role: userProfile.ProfileName });
    }
    componentDidMount() {
      this.getUserRole();
      this.getProjectIdentifiedData();
    }

    render() {
      const progDetails = this.state.progDeatilsProjectDetails;
      const infoPopover =
        this.props.step !== "4" ? (
          <Popover id="info-popover" trigger="focus">
            <Popover.Title as="h3">
              {progDetails.programName}
              <span className="close-btn" onClick={() => document.body.click()}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </Popover.Title>
            <Popover.Content>
              <ul>
                <li>
                  <span className="line-label">Reporting Year</span>
                  <span className="line-text">
                    {progDetails.reportingYear}
                  </span>
                </li>
                <li>
                  <span className="line-label">Offerings</span>
                  <span className="line-text">{progDetails.offering}</span>
                </li>
                <li>
                  <span className="line-label">BT Lead</span>
                  <span className="line-text">{progDetails.bTLead}</span>
                </li>
                <li>
                  <span className="line-label">CX Lead</span>
                  <span className="line-text">{progDetails.cXLead}</span>
                </li>
              </ul>
            </Popover.Content>
          </Popover>
        ) : (
          <Popover id="info-popover">
            <Popover.Title as="h3">
              {this.state.selectedProjectData.ClientProjectName}
              <span className="close-btn" onClick={() => document.body.click()}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </Popover.Title>
            <Popover.Content>
              <ul>
                <li>
                  <span className="line-label">Target End Date</span>
                  <span className="line-text">
                    {new Date(
                      this.state.selectedProjectData.TargetEndDate
                    ).toLocaleDateString()}
                  </span>
                </li>
                <li>
                  <span className="line-label">Delivery Location</span>
                  <span className="line-text">
                    {this.state.selectedProjectData.DeliveryCenterName}
                  </span>
                </li>
                <li>
                  <span className="line-label">Project Lead</span>
                  <span className="line-text">
                    {this.state.selectedProjectData.ProjectLead}
                  </span>
                </li>
              </ul>
            </Popover.Content>
          </Popover>
        );
      const route = window.location.pathname.split("/")[2].toLocaleLowerCase();
      const shouldNav = this.props.shouldNavigate

      return (
        <React.Fragment>
          <div className="clientStage-page-container">

            {/*PAGE TITLE*/}
            <div className="stage-back header24-semibold-midnight">
              {route === STAGE.clientStage.stage ? (
                <div onClick={() => this.props.history.push("/ClientConfiguration")}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className=""
                    style={{ marginRight: "23px" }}
                  />
                  {STAGE.clientStage.desc}
                </div>
              ) : (
                <div className="stage-title">
                  {this.props.stageDescription}{" "}
                  {this.props.stage === "ProjectDetails"
                    ? `-${progDetails.programName}`
                    : this.props.step === "4"
                      ? `-${this.state.selectedProjectData.ClientProjectName}`
                      : ""}
                  {this.props.step === "2" || this.props.step === "3" || this.props.step === "4" ?
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      placement="right"
                      overlay={infoPopover}
                    >
                      <img src={InfoIcon} alt="info" className="infoIcon" />
                    </OverlayTrigger> : ""}
                </div>
              )}
              {route === STAGE.clientStage.stage && (
                <CVMSelectDropdown
                  label="Reporting Year : "
                  placeholder={""}
                  onChange={this.props.onChangeReportingYear}
                  style={{
                    control: { width: "80px" },
                    menu: { width: "80px" },
                    mainContainer: {
                      width: "226px",
                      margin: "0 0 10px",
                      float: "right",
                    },
                  }}
                  {...this.props.reportingYearParam}
                />
              )}
              <div />
            </div>

            {/* STEP ICONS & BODY */}
            <div className="stepper-section">
              {/* STEP ICONS */}
              <div
                className="stepper-close"
                onClick={() => {
                  // this.props.showStepperCloseAlert
                  this.props.history.push("/ClientConfiguration")
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div className="md-stepper-horizontal">
                <div className={this.props.step === "1" ? "md-step active" : "md-step done"}>
                  <div
                    className="md-step-img-circle"
                    style={shouldNav.clientConfig ? {} : { cursor: 'not-allowed', pointerEvents: 'all !important' }}
                    onClick={() => this.props.setStep(STAGE.clientConfig)}
                  >
                    <img src={clientconfig} alt="clientconfig" />
                  </div>
                  <div className="md-step-title md-step-title-1">
                    {STAGE.clientConfig.desc}
                  </div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>

                <div className={this.props.step === "2" ? "md-step active" : "md-step done"} >
                  <div
                    className="md-step-img-circle"
                    style={shouldNav.progIDValueSettings ? {} : { cursor: 'not-allowed', pointerEvents: 'all !important' }}
                    onClick={() => this.props.setStep(STAGE.progIDValueSettings)}
                  >
                    <img src={identifiedvalue} alt="identifiedvalue" />
                  </div>
                  <div className="md-step-title">
                    {STAGE.progIDValueSettings.desc}
                  </div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>

                <div className={this.props.step === "3" ? "md-step active" : "md-step done"}>
                  <div
                    className="md-step-img-circle"
                    style={shouldNav.projDetails ? {} : { cursor: 'not-allowed', pointerEvents: 'all !important' }}
                    onClick={() => this.props.setStep(STAGE.projDetails)}
                  >
                    <img src={projectdetails} alt="projectdetails" />
                  </div>
                  <div className="md-step-title">{STAGE.projDetails.desc}</div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>

                <div className={this.props.step === "4" ? "md-step active" : "md-step done"}>
                  <div
                    className="md-step-img-circle"
                    style={shouldNav.projIDValueSettings ? {} : { cursor: 'not-allowed', pointerEvents: 'all !important' }}
                    onClick={() => this.props.setStep(STAGE.projIDValueSettings)}
                  >
                    <img src={identifiedvalue} alt="identifiedvalue" />
                  </div>
                  <div className="md-step-title">
                    {STAGE.projIDValueSettings.desc}
                  </div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>

                <div className={this.props.step === "5" ? "md-step active" : "md-step done"}>
                  <div
                    className="md-step-img-circle"
                    style={shouldNav.configurationComplete ? {} : { cursor: 'not-allowed', pointerEvents: 'all !important' }}
                    onClick={() => this.props.setStep(STAGE.configurationComplete)}
                  >
                    <img src={configcomplete} alt="configurationcomplete" />
                  </div>
                  <div className="md-step-title">
                    {STAGE.configurationComplete.desc}
                  </div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>
              </div>

              {/* BODY */}
              <div className="stepper-tab-container">
                {route === STAGE.clientStage.stage ? (
                  <ClientStage />
                ) : this.props.step === "1" ? (
                  <ConfigureClient />
                ) : this.props.step === "2" ? (
                  <ProgramIdentified
                    role={this.state.role}
                    setProgDetailsProjectDetails={
                      this.setProgDetailsProjectDetails
                    }
                  />
                ) : this.props.step === "3" ? (
                  <ProjectDetails
                    setProgDetailsProjectDetails={
                      this.setProgDetailsProjectDetails
                    }
                  />
                ) : this.props.step === "4" ? (
                  <ProjectIdentified
                    role={this.state.role}
                    step={this.props.step}
                    projectCount={this.state.projectCount}
                    programs={this.state.projectIdentifiedData}
                    selectedProjectData={this.state.selectedProjectData}
                    setSelectedProject={this.setSelectedProject}
                    onProjectDataUpdate={this.onProjectDataUpdate}
                  />
                ) : this.props.step === "5" ? (
                  <ConfigurationComplete />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>


          <CVMModal
            title={"Alert"}
            show={this.props.showCloseAlert}
            customclass={"notifyModal-dialog"}
            modal65={"true"}
            onCloseModal={this.props.hideStepperCloseAlert}
            content={
              <StepperCloseModal
                stage={this.props.stage}
                onCloseModal={this.props.hideStepperCloseAlert}
                onStepperClose={this.props.onStepperClose}
              />
            }
          />
        </React.Fragment >
      );
    }
  }
  class StepperCloseModal extends Component {
    render() {
      const route = window.location.pathname.split("/")[2].toLocaleLowerCase();
      return (
        <React.Fragment>
          <div className="stepperClose-Alert">
            <div className="stepperClose-Alert-Content">
              <div className="stepperClose-Alert-Icon">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <div className="stepperClose-Alert-text">
                <h5>
                  Are you sure you want to close{" "}
                  {route === STAGE.clientStage.stage ? `${STAGE.clientStage.desc}?`
                    : this.props.stage === STAGE.clientConfig.stage ? `${STAGE.clientConfig.desc}?`
                      : this.props.stage === STAGE.progIDValueSettings.stage ? `${STAGE.progIDValueSettings.desc}?`
                        : this.props.stage === STAGE.projDetails.stage ? `${STAGE.projDetails.desc}?`
                          : this.props.stage === STAGE.projIDValueSettings.stage ? `${STAGE.projIDValueSettings.desc}?`
                            : this.props.stage === STAGE.configurationComplete.stage ? `${STAGE.configurationComplete.desc}?`
                              : ""}
                </h5>

                <p>Please note that this would not save your changes.</p>
              </div>
            </div>
          </div>
          <div className="notifyModal-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={this.props.onCloseModal}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Yes sure"
              callbackFunction={this.props.onStepperClose}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class StagesContainer extends Component {
    constructor(props) {
      super(props);
      this.defaultShoulNavigate = {
        clientStage: true,
        clientConfig: true,
        progIDValueSettings: true,
        projDetails: true,
        projIDValueSettings: true,
        configurationComplete: true
      }
      this.shoulNotNavigate = {
        clientStage: false,
        clientConfig: false,
        progIDValueSettings: false,
        projDetails: false,
        projIDValueSettings: false,
        configurationComplete: false
      }

      this.state = {
        step: "1",
        stage: "clientconfig",
        stageDescription: "Client Configuration",
        showCloseAlert: false,
        disabledOtherScreen: false,
        shouldNavigate: this.defaultShoulNavigate,
        clientCurrentStage: 1
      }
    }

    componentDidMount = () => {
      this.initializeHighligthedStage()
    }
    componentDidUpdate(prevProps) {
      if (this.props.ClientConfigurationData.clientDetails.clientBasicDetailsId !==
        prevProps.ClientConfigurationData.clientDetails.clientBasicDetailsId) {

        this.initializeHighligthedStage()
      }
    }

    initializeHighligthedStage = () => {
      let selectedYear = 0;
      let clientDetailsReportingYear = undefined;
      try {
        selectedYear = this.props.ClientConfigurationData.workFlowReportingYear;
        clientDetailsReportingYear = this.props.ClientConfigurationData.clientDetails.reportingYear.filter(
          (e) => {
            return e.year === selectedYear;
          }
        )[0];
      } catch {
        selectedYear = 0;
        clientDetailsReportingYear = undefined;
      }

      const route = window.location.pathname.split("/")[2].toLocaleLowerCase()
      const step = IsValid(clientDetailsReportingYear) ? String(clientDetailsReportingYear.stageID) : route
      let stage = STAGE["clientConfig"]
      let tempNav = this.shoulNotNavigate
      stage = STAGE[Object.keys(STAGE).filter((key) => { return (isNaN(step) && STAGE[key].stage === step) || (STAGE[key].step === step) })[0]]
      if (route === STAGE.clientStage.stage) {
        tempNav = this.shoulNotNavigate
      }
      else {
        Object.keys(STAGE).forEach((key) => {
          tempNav[key] = Number(stage.step) >= Number(STAGE[key].step)
        })
      }


      this.setState({
        step: stage.step,
        stage: stage.stage,
        stageDescription: stage.desc,
        shouldNavigate: tempNav,
        clientCurrentStage: Number(stage.step)
      })
    }

    setStep = (param) => {
      const route = window.location.pathname.split("/")[2].toLocaleLowerCase()
      if (route === STAGE.clientStage.stage || Number(this.state.clientCurrentStage) < Number(param.step)) return

      let disabledOtherScreen = this.state.disabledOtherScreen;
      this.props.history.push("/ClientConfiguration/" + param.stage);
      if (param.step === "4") {
        disabledOtherScreen = true;
      }
      this.setState({
        step: param.step,
        stage: param.stage,
        stageDescription: param.desc,
        disabledOtherScreen: disabledOtherScreen,
      });
    }

    showStepperCloseAlert = () => {
      this.setState({
        showCloseAlert: true,
      });
    };
    hideStepperCloseAlert = () => {
      this.setState({
        showCloseAlert: false,
      });
    };
    onStepperClose = () => {
      this.props.history.push("/ClientConfiguration");
    };
    onChangeReportingYear = async (e) => {
      this.props.setStageReportingYearParam({ value: e });
      await this.props.setWorkFlowReportingYear(e.value);
      this.initializeHighligthedStage();
    };
    render() {
      const { reportingYearParam } = { ...this.props.StageData };
      return (
        <StagesPresentational
          setStep={this.setStep}
          step={this.state.step}
          stage={this.state.stage}
          shouldNavigate={this.state.shouldNavigate}
          disabledOtherScreen={this.state.disabledOtherScreen}
          stageDescription={this.state.stageDescription}
          showCloseAlert={this.state.showCloseAlert}
          showStepperCloseAlert={this.showStepperCloseAlert}
          hideStepperCloseAlert={this.hideStepperCloseAlert}
          onStepperClose={this.onStepperClose}
          //Reporting Year
          reportingYearParam={reportingYearParam}
          onChangeReportingYear={this.onChangeReportingYear}
          history={this.props.history}
        />
      );
    }
  }

  return StagesContainer;
})();

export default connect(
  (state) => {
    return {
      StageData: state.StageData,
      ClientConfigurationData: state.ClientConfigurationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setStageReportingYearParam,
          setWorkFlowReportingYear
        }
      ),
      dispatch
    )
)(withRouter(Stages));
