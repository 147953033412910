import React, { Component } from "react";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import {
   GrooveIconSvg,
   GrooveInput,
   GrooveButton,
} from "tfo-groove";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HttpGet, HttpPost, HttpPostText } from "../../Utilities/HTTPUtil";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, } from "@fortawesome/free-solid-svg-icons";
import CVMModal from "../Shared/CVMModal/CVMModal";
import CVMMultiSelectTextbox from "../Shared/CVMMultiSelectTextbox/CVMMultiSelectTextbox";
import { setToasterParam } from "../../Actions/Shared.Action";
import CVMPagination from "../Shared/CVMPagination/CVMPagination"
import CVMToasterNotifUndo from '../Shared/CVMToasterNotifUndo/CVMToasterNotifUndo';

import "./IOApproverManagement.css";
import { HasProperty, IsValidAccentureEmail, IsValidStr } from "../../Utilities/Validations";
import ReplacementModal from "./Modals/ReplacementModal";

const IOApproverManagement = (function () {
   class IOApproverManagementPresentational extends Component {
      render() {
         const filterPopover = (

            <Popover id="filter-popover" trigger="focus">
               <Popover.Content>
                  <div>
                     <div className="search-bar-container" id="popover-searchbox">
                        <div className="search-bar label14-regular-midnight">
                           <GrooveIconSvg
                              customClassName="close-modal-btn"
                              size="large"
                              name="search"
                              iconStyle="solid"
                              iconColor="stat-neutral"
                           />
                           <input type="text" name="name" autocomplete="off" placeholder="Search Offerings…" id="searchBox" maxlength="255" onChange={(e) => { this.props.searchOfferingName(e) }} />
                        </div>

                     </div>


                     <div className="filter-select-table">
                        <ul>
                           {
                              this.props.ioListPresentational.map((offerings, index) => {
                                 let isChecked = this.props.checkedOfferings.filter(x => x.offeringID === offerings.offeringID)
                                 return (
                                    <li key={index}>
                                       <label class="checkbox-container">
                                          <input
                                             type="checkbox"
                                             onChange={(e) => this.props.handleCheckbox(e.target.checked, offerings)}
                                             checked={isChecked.length > 0 ? true : false}
                                          />
                                          <span class="checkmark-checkbox"></span>{offerings.offeringName}
                                       </label>
                                    </li>
                                 )
                              })
                           }
                        </ul>
                     </div>

                  </div>
               </Popover.Content>
               <div className="filter-action-btns">
                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="outline"
                     colorClass="gr-silver"
                     size="auto"
                     text="Clear"
                     callbackFunction={this.props.clearOfferingFilter}
                  />

                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="solid"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Apply"
                     callbackFunction={this.props.applyOfferingFilter}
                  />
               </div>

            </Popover>
         );
         let that = this
         return (
            <React.Fragment>
               <CVMToasterNotifUndo />
               <CVMModal
                  title="Add New IO Approver"
                  show={this.props.show}
                  onCloseModal={this.props.onCloseModal}
                  size={this.props.size}
                  content={
                     <ModalContent
                        onCloseModal={this.props.onCloseModal}
                        errorMessges={this.props.errorMessges}
                        profileSelected={this.props.profileSelected}
                        userDetails={this.props.userDetails}
                        onChangeUserDetails={this.props.onChangeUserDetails}
                        saveIOApproverDetails={this.props.saveIOApproverDetails}
                        onAddIOUsers={this.props.onAddIOUsers}
                        onRemoveIOUsers={this.props.onRemoveIOUsers}
                        isError={this.props.isError}
                        ioUsers={this.props.ioUsers}
                        onAddIOUsersTextChange={this.props.onAddIOUsersTextChange}
                        ioUsersList={this.props.ioUsersList}
                        disableSave={this.props.disableSave}
                        invalidEmailsErrorMsg={this.props.invalidEmailsErrorMsg}
                     />

                  }

               />
               <CVMModal
                  title={""}
                  show={this.props.showDelConfirmationModal}
                  onCloseModal={this.props.onConfirmClose}
                  customclass={"select-project-modal"}
                  content={
                     <WarningModal
                        //redux
                        onCloseModal={this.props.onConfirmClose}
                        onCloseProject={this.props.onConfirmDelete}
                        disableDelete={this.props.disableDelete}
                     />
                  }
               />
               <ReplacementModal
                  title={"Replace User"}
                  customclass={"select-project-modal"}
                  show={this.props.showReplacementUserForm}
                  hideReplacementModal={this.props.onCloseForm}
                  isSingle={true}
                  handleSaveReplacement={this.props.handleSaveReplacement}
                  replacementUserList={this.props.replaceUserList}
                  validateEmailAddress={this.props.validateEmailAddress}
                  role={"IOA"}
               />
               <div className="search-bar-container">
                  <div className="search-bar label14-regular-midnight">
                     <GrooveIconSvg
                        customClassName="close-modal-btn"
                        size="large"
                        name="search"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                     />
                     <input type="text" name="name" autocomplete="off" placeholder="Search User Name" id="searchBox" maxlength="255" onChange={(e) => { this.props.searchFunc(e) }} />
                  </div>

               </div>
               <div className="iomanagement-table-container">
                  <div className="subdimension-table">
                     {this.props.ioListOriginal.length > 0 || this.props.searchKey !== "" ?
                        <div className="subdimension-header">
                           <div className="row">
                              <div className="col-lg-3">
                                 <div className="sort-io" onClick={() => this.props.sortOffering()}>Offering
                                    <div className="sort-btn-container">
                                       {
                                          this.props.sortArrow === 1 ?
                                             <GrooveIconSvg
                                                customClassName="sort-btn"
                                                size="tiny"
                                                name="caret-up"
                                                iconStyle="solid"
                                                iconColor="dl-midnight"
                                             />
                                             :
                                             this.props.sortArrow === 2 ?
                                                <GrooveIconSvg
                                                   customClassName="sort-btn"
                                                   size="tiny"
                                                   name="caret-down"
                                                   iconStyle="solid"
                                                   iconColor="dl-midnight"
                                                />
                                                :
                                                ""
                                       }
                                    </div>
                                 </div>
                                 <OverlayTrigger rootClose trigger="click" placement="bottom-start" overlay={filterPopover}>
                                    <div>
                                       <GrooveIconSvg
                                          className="filter-btn"
                                          size="small"
                                          name="filter"
                                          iconStyle="solid"
                                          iconColor="dl-midnight"
                                       />
                                    </div>
                                 </OverlayTrigger>
                              </div>
                              <div className="col-lg-3">
                                 <div className="sort-io" onClick={() => this.props.sortSubOffering()}>Sub Offering
                                    <div className="sort-btn-container">
                                       {
                                          this.props.sortSubArrow === 1 ?
                                             <GrooveIconSvg
                                                customClassName="sort-btn"
                                                size="tiny"
                                                name="caret-up"
                                                iconStyle="solid"
                                                iconColor="dl-midnight"
                                             />
                                             :
                                             this.props.sortSubArrow === 2 ?
                                                <GrooveIconSvg
                                                   customClassName="sort-btn"
                                                   size="tiny"
                                                   name="caret-down"
                                                   iconStyle="solid"
                                                   iconColor="dl-midnight"
                                                />
                                                :
                                                ""
                                       }
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="subdimension-body-container">
                              {
                                 this.props.ioListPaged.map((item, index) => {
                                    return (

                                       <div className="subdimension-body">
                                          <Accordion className="subdimension-row" defaultActiveKey="1">
                                             <Card className="">
                                                <Accordion.Collapse eventKey="0">
                                                   <Card.Body>

                                                      <table className="iouser-table">

                                                         <tr className="bodytext14">
                                                            <th />
                                                            <th>User Name
                                                               <GrooveIconSvg
                                                                  //customClassName="accordion-button"
                                                                  size="medium"
                                                                  name="plus-circle"
                                                                  iconStyle="solid"
                                                                  iconColor="stat-alternate"
                                                                  callbackFunction={() =>
                                                                     this.props.onAddNewUser(item)
                                                                  }
                                                               />

                                                            </th>

                                                            <th>Email ID</th>
                                                            <th>Actions</th>
                                                         </tr>

                                                         {item.Users.map(user => {
                                                            return (
                                                               user.userId>0&&(
                                                               <tr className="bodytext12-regular">
                                                                  <td />
                                                                  <td>{user.userName} </td>
                                                                  <td>{user.userEmailID}</td>
                                                                  <td>
                                                                     <GrooveIconSvg
                                                                        customClassName="delete-icon"
                                                                        size="large"
                                                                        name="trash-alt"
                                                                        iconStyle="solid"
                                                                        iconColor="stat-alternate"
                                                                        callbackFunction={() => {
                                                                           const body = {
                                                                              "offeringID": item.offeringID,
                                                                              "subOfferingID": item.subOfferingID,
                                                                              "userID": user.userId,
                                                                              "isDeleted": true,
                                                                              "username": user.userName,
                                                                              "mappedClientIds": [],
                                                                              "isMapped": user.isMapped,
                                                                              "userList": item.Users,
                                                                              "isReplace": 0,
                                                                              "newUserID": 0,
                                                                              "newUsername": "",
                                                                              "newUserEmailID": ""
                                                                           }
                                                                           this.props.deleteIOApproverUser(body)
                                                                        }

                                                                        }
                                                                     />
                                                                  </td>

                                                               </tr>
                                                               ))
                                                         })}

                                                      </table>

                                                   </Card.Body>
                                                </Accordion.Collapse>
                                                <Card.Header>
                                                   <Accordion.Toggle as={Button} variant="link" eventKey="0" collapse="0">

                                                      <GrooveIconSvg
                                                         customClassName="accordion-button"
                                                         size="small"
                                                         name="caret-right"
                                                         iconStyle="solid"
                                                         iconColor="dl-midnight"
                                                      />
                                                   </Accordion.Toggle>

                                                   <div className="row bodytext14">
                                                      <div className="col-lg-3">{item.offeringName}</div>
                                                      <div className="col-lg-3">{item.subOfferingName}</div>
                                                   </div>
                                                </Card.Header>
                                             </Card>
                                          </Accordion>
                                       </div>

                                    )
                                 })
                              }
                           </div>
                           <CVMPagination list={this.props.filteredData.length > 0 ? this.props.filteredData : this.props.ioList} callbackFunction={(e) => this.props.setPageItems(e)} shouldNotReset={true} />
                        </div> : <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                     }
                  </div>
               </div>

            </React.Fragment>
         );
      }
   }

   //AddNewUserModalContent
   class ModalContent extends Component {
      constructor(props) {
         super(props);
         this.state = {
         };
      }

      render() {
         let errorMessage = [];
         this.props.errorMessges.forEach(f => {
            errorMessage.push(<>{f}<br /></>);
         });

         return (
            <React.Fragment>
               <div className="add-new-program-container">
                  <Row>
                     <Col lg={6} md={6} sm={6}>
                        <GrooveInput
                           name="offeringName"
                           isDisabled={true}
                           placeholder="Offering"
                           inputValue={this.props.userDetails.offeringName || ""}
                           id="offeringName"
                        />
                     </Col>
                     <Col lg={6} md={6} sm={6}>
                        <GrooveInput
                           name="subOfferingName"
                           isDisabled={true}
                           placeholder="Sub Offering"
                           inputValue={this.props.userDetails.subOfferingName}
                           id="subOfferingName"
                        />
                     </Col>
                  </Row>

                  <div className="client-steakholder-title bodytext18-medium-slate">
                     IO Approver List
                  </div>
                  <Accordion className="configure-client-collapse" defaultActiveKey="0">
                     <Card>
                        <Accordion.Collapse eventKey="0">
                           <Card.Body>
                              <Row>
                                 <Col lg={6} md={6} sm={6}>

                                    <CVMMultiSelectTextbox
                                       placeholder="Enter IO Approver Name"
                                       id="ioApproverName"
                                       name="ioApproverName"
                                       addButton={this.props.onAddIOUsers}
                                       onRemoveSelected={(e) => this.props.onRemoveIOUsers(e)}
                                       onTextChange={(e) => this.props.onAddIOUsersTextChange(e)}
                                       list={this.props.ioUsersList}
                                       customClassName={
                                          this.props.isError || this.props.invalidEmailsErrorMsg.length ? "dropdown-invalid" : ""
                                       }
                                       inputValue={this.props.ioUsers}
                                    />

                                 </Col>
                              </Row>
                           </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                           <Accordion.Toggle as={Button} variant="link" eventKey="0">
                              <div className="bodytext18-medium-slate">
                                 Add users
                              </div>
                              <GrooveIconSvg
                                 id="configureProgram"
                                 customClassName="accordion-button"
                                 size="large"
                                 name="chevron-down"
                                 iconStyle="solid"
                                 iconColor="stat-neutral"
                              />
                           </Accordion.Toggle>
                        </Card.Header>
                     </Card>
                  </Accordion>

               </div>

               <div className="add-program-buttons">
                  <div><p className="missing">
                     {errorMessage}
                  </p>
                  <p className='missing'>
                     {this.props.invalidEmailsErrorMsg}
                  </p>
                  </div>
                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="outline"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Cancel"
                     callbackFunction={this.props.onCloseModal}
                  />

                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={this.props.isError || this.props.disableSave || this.props.invalidEmailsErrorMsg.length}
                     hasIcon={false}
                     type="solid"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Save"
                     callbackFunction={this.props.saveIOApproverDetails}
                  />
               </div>
            </React.Fragment>

         )



      };
   }
   //End of Add New User Modal Content
   class IOApproverManagementContainer extends Component {
      constructor(props) {
         super(props);
         this.state = {
            ioUsersList: [],
            origIOUsersList: [],
            ioUsers: "",
            isError: false,
            errorMessges: [],
            userList: [],
            ioListPresentational: [],
            ioListPaged: [],
            ioListOriginal: [],
            show: false,
            size: "md",
            searchKey: "",

            userDetails: {
               offeringID: 0,
               offeringName: "",
               subOfferingID: 0,
               subOfferingName: "",
               users: {
                  userID: 0,
                  username: "",
                  emailID: "",
               }

            },
            gridApi: () => { },
            //isEdit: false,
            ioList: [],
            showDelConfirmationModal: false,
            deleteIOUserDetails: {},
            checkedOfferings: [],
            sortArrow: 0,
            sortSubArrow: 0,
            filteredData: [],
            mappedClientIds: [],
            disableDelete: false,
            disableSave: true,
            disableAdd: false,
            newlyAddedUserList: [],
            showReplacementUserForm: false,
            replaceUserList: [],
            selectedReplacedUser: null,
            userToBeReplaced: [],
            isDeleteIcon: false,
            invalidEmailsErrorMsg: '',
         };
      }

      componentDidMount = async () => {
         try {
            await this.getIOApproverManagementDetails();
            await this.getUserDetails();
            this.checkedAllOfferingOnLoad();
         }
         catch (e) {
            console.log(e)
         }
      }

      getGridApi = (api) => {
         this.setState({
            gridApi: api,
         });
      };


      // onTablSearchTextChanged = (e) => {
      //    try {
      //       let result = [];
      //       let ioList = this.state.ioList;
      //       console.log('ioList', ioList);
      //       ioList.map(o => {
      //          o.Users.map(u => {
      //             if (u.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
      //                if (result.length > 0) {
      //                   if (o.subOfferingID !== result[result.length - 1].subOfferingID) {
      //                      result.push(o)
      //                   }
      //                   console.log('result', result);
      //                } else {
      //                   result.push(o)
      //                   console.log('resultelse', result);
      //                }
      //             }
      //          })
      //       })
      //       this.setState(
      //          {
      //             ioListPaged: result,
      //             searchKey: e.target.value,
      //             filteredData: IsValidStr(e.target.value) ? result : []
      //          }
      //       )
      //       console.log('resultset', this.state.ioListPaged);
      //    }
      //    catch (e) {
      //       console.log(e)
      //    }
      // }

      onTablSearchTextChanged = (e) => {
         try {
             let result = [];
             let ioList = this.state.ioList;
             console.log('ioList', ioList);
             ioList.forEach(o => {
                 o.Users.forEach(u => {
                     if (u.userName && u.userName.trim() !== '' && u.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
                         if (result.length > 0) {
                             if (o.subOfferingID !== result[result.length - 1].subOfferingID) {
                                 result.push(o)
                             }
                             console.log('result', result);
                         } else {
                             result.push(o)
                             console.log('resultelse', result);
                         }
                     }
                 })
             })
             this.setState({
                 ioListPaged: result,
                 searchKey: e.target.value,
                 filteredData: IsValidStr(e.target.value) ? result : []
             })
             console.log('resultset', this.state.ioListPaged);
         }
         catch (e) {
             console.log(e)
         }
     }
     

      //Offering Filter
      filterOfferingName = (e) => {
         try {
            let offerings = [];
            let ioList = [];
            let newList = this.state.ioListOriginal;


            newList.filter(item => {
               let i = ioList.findIndex(x => x.offeringName == item.offeringName);
               if (i <= -1) {
                  ioList.push({ offeringID: item.offeringID, offeringName: item.offeringName })
               }
            })
            ioList.map(oName => {
               if (oName.offeringName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
                  offerings.push(oName)
               }
            })
            this.setState({
               ioListPresentational: offerings,
            })

         }
         catch (e) {
            console.log(e)
         }
      }


      onCloseModal = () => {
         try {
            this.setState({
               ...this.state,
               ioUsers: "",
               isError: false,
               errorMessges: [],
               disableAdd: false,
               show: !this.state.show,
               disableSave: true,
               invalidEmailsErrorMsg: '',
            });
         }
         catch (e) {
            console.log(e)
         }
      };

      onAddNewUser = (item) => {
         let ioUsers = item.Users.map(x => {
            return (
               {
                  text: x.userEmailID,
                  label: x.userEmailID,
                  value: x.userId,
                  userName: x.userName,
                  isMapped: x.isMapped,
                  isReplace: x.isReplace,
                  newUserID: x.newUserID,
                  newUsername: x.newUsername,
                  newUserEmailID: x.newUserEmailID
               }
            )
         });
         this.setState({
            userDetails: {
               offeringID: item.offeringID,
               offeringName: item.offeringName,
               subOfferingID: item.subOfferingID,
               subOfferingName: item.subOfferingName,
               users: item.Users
            },
            ioUsersList: ioUsers,
            origIOUsersList: ioUsers,
         }, () => {
            this.onCloseModal();
         })
      }

      getIOApproverManagementDetails = async () => {
         try {
            let distinctOffering = []
            const res = await HttpGet(`User/GetIOApproverManagementDetails`);
            if (res != null && res.length > 0) {
               let data = [];
               res.map(x => {
                  data.push({
                     offeringID: x.OfferingID,
                     offeringName: x.OfferingName,
                     subOfferingID: x.SubOfferingID,
                     subOfferingName: x.SubOfferingName,
                     Users:
                        x.Users.map(y => {
                           return ({
                              userId: y.UserID,
                              userName: y.Username,
                              userEmailID: y.UserEmailID,
                              isMapped: y.IsMapped === 1,
                              isReplace: 0,
                              newUserID: 0,
                              newUsername: "",
                              newUserEmailID: ""
                           })
                        })
                  })
               })

               data.map(x => {
                  if (distinctOffering.length > 0) {
                     if (distinctOffering.filter(y => y.offeringID === x.offeringID).length === 0) {
                        distinctOffering.push({
                           offeringID: x.offeringID,
                           offeringName: x.offeringName
                        })
                     }
                  } else {
                     distinctOffering.push({
                        offeringID: x.offeringID,
                        offeringName: x.offeringName
                     })
                  }
               })

               this.setState({
                  ioList: data,
                  ioListPresentational: distinctOffering,
                  ioListPaged: data,
                  ioListOriginal: data
               });
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      saveIOApproverDetails = async () => {
         this.setState({
            disableSave: true
         }, async () => {
            const userDetails = this.state.userDetails;
            const userList = this.state.ioUsersList;
            const userToBeReplaced = this.state.userToBeReplaced;
            let users = [];
            userList.map(x => {
              x.label!=null&& users.push({
                  "userID": x.value,
                  "username": x.userName,
                  "userEmailID": x.label,
                  "isReplace": x.isReplace,
                  "newUserID": x.newUserID,
                  "newUsername": x.newUsername,
                  "newUserEmailID": x.newUserEmailID
               })
            })

            userToBeReplaced.map(x => {
              x.label!=null&& users.push({
                  "userID": x.value,
                  "username": x.userName,
                  "userEmailID": x.label,
                  "isReplace": x.isReplace,
                  "newUserID": x.newUserID,
                  "newUsername": x.newUsername,
                  "newUserEmailID": x.newUserEmailID
               })
            })
            let bodyParam = {
               "offeringID": userDetails.offeringID,
               "offeringName": userDetails.offeringName,
               "subOfferingID": userDetails.subOfferingID,
               "subOfferingName": userDetails.subOfferingName,
               "username": this.props.userInformation.userName,
               "users": users
            }
            const data = await HttpPost("User/AddEditIOApproverDetails", bodyParam);
            if (data !== null) {
               if (data.Message === "success") {
                  let returnMsg = "User has been updated.";

                  this.props.setToasterParam({
                     show: true,
                     message: returnMsg,
                     type: 'success'
                  });

                  this.onCloseModal();
                  await this.getIOApproverManagementDetails();
                  this.applyOfferingFilter();
               }
               else {
                  this.setState({
                     isError: true,
                     errorMessges: [data.Message]
                  })
               }
            }
            this.setState({
               disableSave: false
            })
         })
      }


      deleteIOApproverUser = (item) => {
         if (item.isMapped) {
            let userList = item.userList.filter(x => x.userName !== item.username).map(x => {
               return ({
                  label: x.userEmailID,
                  text: x.userEmailID,
                  value: x.userId,
                  userName: x.userName
               })
            })
            this.setState({
               showReplacementUserForm: true,
               replaceUserList: userList,
               deleteIOUserDetails: item,
               isDeleteIcon: true,
               ioUsersList: item.userList.map(x => {
                  return (
                     {
                        text: x.userEmailID,
                        label: x.userEmailID,
                        value: x.userId,
                        userName: x.userName,
                        isMapped: x.isMapped,
                        isReplace: x.isReplace,
                        newUserID: x.newUserID,
                        newUsername: x.newUsername,
                        newUserEmailID: x.newUserEmailID
                     }
                  )
               })
            })
         } else {
            this.setState({
               showDelConfirmationModal: !this.state.showDelConfirmationModal,
               deleteIOUserDetails: item,
            });
         }
      };
      onConfirmClose = () => {
         this.setState({
            ...this.state,
            showDelConfirmationModal: !this.state.showDelConfirmationModal,
         });
      };

      onConfirmDelete = async () => {
         this.setState({
            disableDelete: true
         }, async () => {
            const userDetails = this.state.deleteIOUserDetails
            const data = await HttpPost(`User/DeleteIOApproverDetails`, userDetails);
            if (Object.keys(data).length > 0 && !HasProperty('Code')) {
               this.setState({
                  disableDelete: false,
                  showDelConfirmationModal: false,
                  showReplacementUserForm: false,
                  isDeleteIcon: false
               }, () => {
                  this.props.setToasterParam({
                     type: 'success',
                     show: true,
                     message: 'User has been removed',
                     hasUndo: true,
                     callback: () => this.undoDelete(userDetails)
                  });
                  this.getIOApproverManagementDetails()
                  this.setState({ mappedClientIds: data.mappedprojects.length > 0 ? data.mappedprojects : data.mappedclients.length > 0 ? data.mappedclients : [] })
               });
            } else { //invalid
               this.props.setToasterParam({
                  type: 'error',
                  show: true,
                  message: data.Message,
                  hasUndo: false,
               });
            }
            this.setState({
               disableDelete: false
            })
         })

      }
      undoDelete = async (userDetails) => {
         try {
            userDetails.isDeleted = false;
            userDetails.mappedClientIds = this.state.mappedClientIds;
            const data = await HttpPostText(`User/DeleteIOApproverDetails`, userDetails);
            if (data != null) {
               this.getIOApproverManagementDetails()
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      setPageItems = (items) => {
         let prevDisplayed = this.state.ioListPaged;
         let shouldCollapseAll = prevDisplayed.length !== this.state.ioListOriginal.length && items[0].subOfferingID !== prevDisplayed[0].subOfferingID;
         this.setState({
            ioListPaged: items
         });

         if (shouldCollapseAll) {
            //To Collapse Accordion
            let elems = document.getElementsByClassName("collapse show");
            // while (document.getElementsByClassName("collapse show").length) {
            for (var i = 0; i < elems.length; i++) {
               if (elems[i].className === "collapse show") {
                  document
                     .getElementsByClassName("collapse show")
                  [i].parentElement.childNodes[1].children[0].click();
               }
            }
         }
      }

      onAddIOUsersTextChange = (e) => {
         try {
            this.setState({
               ioUsers: e.value
            }, () => {
               if (e.value === "") {
                  this.setState({
                     isError: false,
                     errorMessges: []
                  })
               }
            })
         }
         catch (e) {
            console.log(e)
         }
      }

      onAddIOUsers = async () => {
         try {
            if (!this.state.disableAdd) {
               this.setState({ disableAdd: true }, async () => {
                  const newUser = this.state.ioUsers.split(' ').join('').trim();
                  let ioUsersList = this.state.ioUsersList;
                  const isValid = await this.validateEmailAddress(newUser);
                  const userId = this.state.userList.filter(x => x.UserEmailID === newUser);
                  let newlyAddedUserList = this.state.newlyAddedUserList;
                  if (isValid) {
                     ioUsersList.push({
                        text: newUser,
                        label: newUser,
                        value: userId.length > 0 ? userId[0].Id : 0,
                        userName: userId.length > 0 ? userId[0].UserName : newUser.split("@")[0],
                        isReplace: 0,
                        newUserID: 0,
                        newUsername: "",
                        newUserEmailID: "",
                        isNew: true
                     })
                     if (this.state.newlyAddedUserList.filter(x => x.userName === newUser.split("@")[0]).length === 0) {
                        newlyAddedUserList.push({
                           text: newUser,
                           label: newUser,
                           value: userId.length > 0 ? userId[0].Id : 0,
                           userName: userId.length > 0 ? userId[0].UserName : newUser.split("@")[0],
                           isReplace: 0,
                           newUserID: 0,
                           newUsername: "",
                           newUserEmailID: "",
                           isNew: true
                        })
                     }
                     this.setState({
                        newlyAddedUserList: newlyAddedUserList,
                        ioUsersList: ioUsersList,
                        ioUsers: '',
                        disableAdd: false,
                        disableSave: false
                     })
                  }

                  const ioUsersListWithoutNull = ioUsersList.filter(x => x.label !== null);
                  const data = await IsValidAccentureEmail([...ioUsersListWithoutNull.map(i => i.label)]);
                  let allInvalidEmails = [];
                  let msgValue;

                  for (let i = 0; i < data.length; i++) {
                     if (!data[i].IsValid) {
                        allInvalidEmails.push(data[i].UserName);
                        msgValue = `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.map(item => item.split('@')[0]).join(', ')}`
                     }
                  }

                  if(allInvalidEmails.length) {
                     this.setState({
                        invalidEmailsErrorMsg: msgValue,
                     })
                  } else {
                     this.setState({
                        invalidEmailsErrorMsg: '',
                     })
                  }

               })
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      checkIfAllEmailsAreValid = async () => {
         let ioUsersList = this.state.ioUsersList;
         const ioUsersListWithoutNull = ioUsersList.filter(x => x.label !== null);
         const data = await IsValidAccentureEmail([...ioUsersListWithoutNull.map(i => i.label)]);
         let allInvalidEmails = [];
         let msgValue;

         for (let i = 0; i < data.length; i++) {
            if (!data[i].IsValid) {
               allInvalidEmails.push(data[i].UserName);
               msgValue = `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.map(item => item.split('@')[0]).join(', ')}`
            }
         }

         if(allInvalidEmails.length) {
            this.setState({
               invalidEmailsErrorMsg: msgValue,
            })
         } else {
            this.setState({
               invalidEmailsErrorMsg: '',
            })
         }
      }

      onRemoveIOUsers = (e) => {
         try {
            if (e.isMapped) {
               let replaceUserList = this.state.ioUsersList.filter(x => x.userName !== e.userName && !x.hasOwnProperty("isNew")).map(x => {
                  return ({
                     label: x.label,
                     text: x.text,
                     value: x.value,
                     userName: x.userName
                  })
               })
               this.setState({
                  showReplacementUserForm: true,
                  replaceUserList: replaceUserList,
                  userToBeReplaced: this.state.ioUsersList.filter(x => x.userName === e.userName)
               })
               this.checkIfAllEmailsAreValid();
            } else {
               let ioUsersList = this.state.ioUsersList.filter(x => x.userName !== e.userName);
               let newlyAddedUserList = this.state.newlyAddedUserList.filter(x => x.userName !== e.userName);

               this.setState({
                  ioUsersList: ioUsersList,
                  disableSave: newlyAddedUserList.length === 0 && ioUsersList.length === this.state.userDetails.users.length,
                  newlyAddedUserList: newlyAddedUserList
               })
               this.checkIfAllEmailsAreValid();
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      validateEmailAddress = async (email, isMainForm = true) => {
         try {
            let isValid = true
            let message = []
            const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (email) { // has value
               if (!re.test(email.trim())) { // is valid email
                  isValid = false
                  message.push("Invalid Email")
               } else {
                  if (!email.toLowerCase().trim().endsWith("@accenture.com")) { // it has @accenture.com
                     isValid = false;
                     message.push("Valid email ID should have '@accenture.com'")
                  } else {
                     let emailCount = email.toLowerCase().split("@"); // it has multiple "@"
                     emailCount = emailCount.filter(r => r.trim() != "");
                     if (emailCount.length > 2) {
                        isValid = false;
                        message.push("Invalid Email")
                     } else {
                        if (this.validateDuplicateEmail(email)) { // duplicate
                           message.push("User already exists")
                           isValid = false
                        } else {
                           if (await this.validateUserIsClientSpecific(email)) {
                              message.push("The user you are trying to add is already mapped as a PPC/BA. Please update the user access using User Management module.")
                              isValid = false
                           }    else{
                              if(isValid)
                              {
                              const data = await HttpPost(`User/validateEmail`,{
                                isDomainAppend: true,
                                users: [
                                 email
                                ]
                              
                              });
                              
                              if(data[0].IsValid)
                              isValid=true;
                              else
                              {
                                 message.push("Invalid Accenture ID.")
                                                isValid = false;
                              }
                               }
                        }  
                        }
                     }
                  }
               }
            } else {
               message.push("Missing Required Field")
               isValid = false
            }

            if (isMainForm) {
               this.setState({
                  errorMessges: message,
                  isError: !isValid,
                  disableAdd: false
               })
            } else {
               isValid = {
                  isValid: isValid,
                  errorMessage: message
               }
            }

            return isValid
         }
         catch (e) {
            console.log(e)
         }
      }

      validateDuplicateEmail = (email) => {
         try {
            let isDuplicate = false
            const userDetails = this.state.ioUsersList.filter(x => x.label.toLowerCase() === email.toLowerCase())

            if (userDetails.length > 0) {
               isDuplicate = true
            } else {
               isDuplicate = false
            }

            return isDuplicate
         }
         catch (e) {
            console.log(e)
         }
      }

      getUserDetails = async () => {
         try {
            const userList = await HttpGet("User/UserDetails")

            if (userList.length > 0) {
               this.setState({
                  userList: userList
               })
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      validateUserIsClientSpecific = async (email) => {
         try {
            let isClientSpecific = false
            const userDetails = await HttpGet("User/CheckUserIfClientStakeholder?emailID=" + email)

            if (userDetails !== null) {
               isClientSpecific = userDetails.IsClientStakeholder
            }

            return isClientSpecific
         }
         catch (e) {
            console.log(e)
         }
      }

      handleCheckbox = (e, item) => {
         try {
            let checkedOfferings = this.state.checkedOfferings;

            if (e) {
               checkedOfferings.push({
                  isChecked: e,
                  ...item
               })
            } else if (!e) {
               checkedOfferings = checkedOfferings.filter(x => x.offeringID !== item.offeringID)
            }
            this.setState(
               {
                  checkedOfferings: checkedOfferings,
               }
            )
         }
         catch (e) {
            console.log(e)
         }
      }

      applyOfferingFilter = () => {
         try {
            const checkedOfferingsList = this.state.checkedOfferings
            let originalList = this.state.ioListOriginal
            let distinctOffering = this.state.ioListPresentational.length > 0 ? this.state.ioListPresentational : [];
            let ioList = [].concat(originalList)
            let tempCheckedOffList = [];

            if (distinctOffering.length > 0) {
               let filteredOffIds = [];
               distinctOffering.map(off => {
                  filteredOffIds.push(off.offeringID);
               })
               tempCheckedOffList = checkedOfferingsList.filter((x) => filteredOffIds.indexOf(x.offeringID) !== -1)
            }

            let filteredIOListPaged = ioList.filter(x => {
               if (tempCheckedOffList.length > 0) {
                  return tempCheckedOffList.some(y => {
                     return y.offeringID === x.offeringID
                  })
               } else {
                  return checkedOfferingsList.some(y => {
                     return y.offeringID === x.offeringID
                  })
               }
            })
            originalList.map(x => {
               if (distinctOffering.length > 0) {
                  if (distinctOffering.filter(y => y.offeringID === x.offeringID).length === 0) {
                     distinctOffering.push({
                        offeringID: x.offeringID,
                        offeringName: x.offeringName
                     })
                  }
               } else {
                  distinctOffering.push({
                     offeringID: x.offeringID,
                     offeringName: x.offeringName
                  })
               }
            })

            if (distinctOffering.length > 0) {
               distinctOffering.sort((a, b) => {
                  return a.offeringName.localeCompare(b.offeringName)
               })
            }

            this.setState({
               ioList: filteredIOListPaged,
               ioListPaged: filteredIOListPaged,
               ioListPresentational: distinctOffering,
               checkedOfferings: tempCheckedOffList.length > 0 ? tempCheckedOffList : checkedOfferingsList
            },
               () => { document.body.click() }
            )
         }
         catch (e) {
            console.log(e)
         }
      }

      clearOfferingFilter = () => {
         try {
            this.setState({
               checkedOfferings: [],
               ioList: this.state.ioList
            })
         }
         catch (e) {
            console.log(e)
         }
      }

      checkedAllOfferingOnLoad = () => {
         try {
            let list = this.state.ioListPresentational

            list.map(x => {
               return ({
                  isChecked: true,
                  ...x
               })
            })


            this.setState({
               checkedOfferings: list
            })
         }
         catch (e) {
            console.log(e)
         }
      }

      sortOffering = () => {
         try {
            let arrow = this.state.sortArrow
            let list = this.state.ioList
            let sortedList = [].concat(list)
            const checkedOfferingsList = this.state.checkedOfferings

            switch (arrow) {
               case 0:
                  arrow = 1
                  sortedList.sort((a, b) => {
                     return a.offeringName.localeCompare(b.offeringName)
                  })
                  break;
               case 1:
                  arrow = 2
                  sortedList.sort((a, b) => {
                     return b.offeringName.localeCompare(a.offeringName)
                  })
                  break;
               case 2:
                  arrow = 0
                  sortedList = this.state.ioListOriginal.filter(x => {
                     return checkedOfferingsList.some(y => {
                        return y.offeringID === x.offeringID
                     })
                  })
                  break;
            }
            this.setState({
               sortArrow: arrow,
               ioList: sortedList,
               sortSubArrow: 0
            }, () => {
               let elems = document.getElementsByClassName("collapse show");
               // while (document.getElementsByClassName("collapse show").length) {
               for (var i = 0; i < elems.length; i++) {
                  if (elems[i].className === "collapse show") {
                     document
                        .getElementsByClassName("collapse show")
                     [i].parentElement.childNodes[1].children[0].click();
                  }
               }
            })
         }
         catch (e) {

         }
      }

      sortSubOffering = () => {
         try {
            let arrow = this.state.sortSubArrow
            let list = this.state.ioList
            let sortedList = [].concat(list)
            const checkedOfferingsList = this.state.checkedOfferings

            switch (arrow) {
               case 0:
                  arrow = 1
                  sortedList.sort((a, b) => {
                     return a.subOfferingName.localeCompare(b.subOfferingName)
                  })
                  break;
               case 1:
                  arrow = 2
                  sortedList.sort((a, b) => {
                     return b.subOfferingName.localeCompare(a.subOfferingName)
                  })
                  break;
               case 2:
                  arrow = 0
                  sortedList = this.state.ioListOriginal.filter(x => {
                     return checkedOfferingsList.some(y => {
                        return y.offeringID === x.offeringID
                     })
                  })
                  break;
            }
            this.setState({
               sortSubArrow: arrow,
               ioList: sortedList,
               sortArrow: 0
            }, () => {
               let elems = document.getElementsByClassName("collapse show");
               // while (document.getElementsByClassName("collapse show").length) {
               for (var i = 0; i < elems.length; i++) {
                  if (elems[i].className === "collapse show") {
                     document
                        .getElementsByClassName("collapse show")
                     [i].parentElement.childNodes[1].children[0].click();
                  }
               }
            })
         }
         catch (e) {

         }
      }

      onCloseForm = () => {
         try {
            this.setState({
               showReplacementUserForm: false,
               isDeleteIcon: false
            })
         }
         catch (e) {
            console.log(e);
         }
      }

      replaceUserOnChange = (value) => {
         try {
            this.setState({
               selectedReplacedUser: value
            })
         }
         catch (e) {
            console.log(e);
         }
      }

      handleSaveReplacement = async (e, role) => {
         if (e.length > 0) {
            const userId = this.state.userList.filter(x => x.UserEmailID === e[0].label);

            if (this.state.isDeleteIcon) { // replace in delete icon
               let userDetails = this.state.deleteIOUserDetails;

               userDetails.isReplace = 1
               userDetails.newUserID = userId.length > 0 ? userId[0].Id : e[0].value
               userDetails.newUsername = e[0].userName
               userDetails.newUserEmailID = e[0].label
               userDetails.isMapped = e[0].isMapped

               this.setState({
                  deleteIOUserDetails: userDetails,
                  isDeleteIcon: false
               }, () => {
                  this.onConfirmDelete();
               })
            } else { // replace in edit modal
               let userToBeReplaced = this.state.userToBeReplaced;
               let ioUsersList = this.state.ioUsersList.filter(x => x.userName !== userToBeReplaced[0].userName);
               if (e[0].hasOwnProperty("isNew")) {
                  let newlyAddedUserList = this.state.newlyAddedUserList;

                  ioUsersList.push({
                     text: e[0].text,
                     label: e[0].label,
                     value: userId.length > 0 ? userId[0].Id : e[0].value,
                     userName: e[0].userName,
                     isReplace: 0,
                     newUserID: 0,
                     newUsername: "",
                     newUserEmailID: "",
                     isNew: true,
                     isMapped: e[0].isMapped
                  })
                  if (this.state.newlyAddedUserList.filter(x => x.userName === e[0].userName).length === 0) {
                     newlyAddedUserList.push({
                        text: e[0].text,
                        label: e[0].label,
                        value: userId.length > 0 ? userId[0].Id : e[0].value,
                        userName: e[0].userName,
                        isReplace: 0,
                        newUserID: 0,
                        newUsername: "",
                        newUserEmailID: "",
                        isNew: true,
                        isMapped: e[0].isMapped
                     })
                  }

                  userToBeReplaced[0].isReplace = 1;
                  userToBeReplaced[0].newUserID = userId.length > 0 ? userId[0].Id : e[0].value;
                  userToBeReplaced[0].newUsername = e[0].userName;
                  userToBeReplaced[0].newUserEmailID = e[0].label;

                  this.setState({
                     newlyAddedUserList: newlyAddedUserList,
                     ioUsersList: ioUsersList,
                     ioUsers: '',
                     disableSave: false,
                     userToBeReplaced: userToBeReplaced,
                     showReplacementUserForm: false
                  });

                  const ioUsersListWithoutNull = ioUsersList.filter(x => x.label !== null);
                  const data = await IsValidAccentureEmail([...ioUsersListWithoutNull.map(i => i.label)]);
                  let allInvalidEmails = [];
                  let msgValue;

                  for (let i = 0; i < data.length; i++) {
                     if (!data[i].IsValid) {
                        allInvalidEmails.push(data[i].UserName);
                        msgValue = `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.map(item => item.split('@')[0]).join(', ')}`
                     }
                  }

                  if(allInvalidEmails.length) {
                     this.setState({
                        invalidEmailsErrorMsg: msgValue,
                     })
                  } else {
                     this.setState({
                        invalidEmailsErrorMsg: '',
                     })
                  }
               } else {
                  let newlyAddedUserList = this.state.newlyAddedUserList.filter(x => x.userName !== e[0].userName);

                  userToBeReplaced[0].isReplace = 1;
                  userToBeReplaced[0].newUserID = userId.length > 0 ? userId[0].Id : e[0].value;
                  userToBeReplaced[0].newUsername = e[0].userName;
                  userToBeReplaced[0].newUserEmailID = e[0].label;

                  this.setState({
                     ioUsersList: ioUsersList,
                     disableSave: newlyAddedUserList.length === 0 && ioUsersList.length === this.state.userDetails.users.length,
                     newlyAddedUserList: newlyAddedUserList,
                     showReplacementUserForm: false
                  });

                  const ioUsersListWithoutNull = ioUsersList.filter(x => x.label !== null);
                  const data = await IsValidAccentureEmail([...ioUsersListWithoutNull.map(i => i.label)]);
                  let allInvalidEmails = [];
                  let msgValue;

                  for (let i = 0; i < data.length; i++) {
                     if (!data[i].IsValid) {
                        allInvalidEmails.push(data[i].UserName);
                        msgValue = `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.map(item => item.split('@')[0]).join(', ')}`
                     }
                  }

                  if(allInvalidEmails.length) {
                     this.setState({
                        invalidEmailsErrorMsg: msgValue,
                     })
                  } else {
                     this.setState({
                        invalidEmailsErrorMsg: '',
                     })
                  }
               }
            }
         }
      }

      render() {
         return (
            <IOApproverManagementPresentational
               show={this.state.show}
               size={this.state.size}
               onCloseModal={this.onCloseModal}
               userDetails={this.state.userDetails}
               onChangeUserDetails={this.onChangeUserDetails}
               getGridApi={this.getGridApi}
               searchFunc={this.onTablSearchTextChanged}
               onAddNewUser={this.onAddNewUser}
               errorMessges={this.state.errorMessges}
               onConfirmClose={this.onConfirmClose}
               //  isEdit={this.state.isEdit}
               ioListPresentational={this.state.ioListPresentational}
               ioList={this.state.ioList}
               ioListPaged={this.state.ioListPaged}
               ioListOriginal={this.state.ioListOriginal}
               setPageItems={this.setPageItems}
               deleteIOApproverUser={this.deleteIOApproverUser}
               saveIOApproverDetails={this.saveIOApproverDetails}
               showDelConfirmationModal={this.state.showDelConfirmationModal}
               onConfirmDelete={this.onConfirmDelete}
               // add modal
               onAddIOUsers={this.onAddIOUsers}
               onRemoveIOUsers={this.onRemoveIOUsers}
               isError={this.state.isError}
               ioUsers={this.state.ioUsers}
               onAddIOUsersTextChange={this.onAddIOUsersTextChange}
               ioUsersList={this.state.ioUsersList}
               searchOfferingName={this.filterOfferingName}
               handleCheckbox={this.handleCheckbox}
               applyOfferingFilter={this.applyOfferingFilter}
               checkedOfferings={this.state.checkedOfferings}
               clearOfferingFilter={this.clearOfferingFilter}
               sortOffering={this.sortOffering}
               sortArrow={this.state.sortArrow}
               sortSubOffering={this.sortSubOffering}
               sortSubArrow={this.state.sortSubArrow}
               searchKey={this.state.searchKey}
               filteredData={this.state.filteredData}
               disableDelete={this.state.disableDelete}
               disableSave={this.state.disableSave}

               //Replacement User Form
               showReplacementUserForm={this.state.showReplacementUserForm}
               onCloseForm={this.onCloseForm}
               replaceUserList={this.state.replaceUserList}
               selectedReplacedUser={this.state.selectedReplacedUser}
               replaceUserOnChange={this.replaceUserOnChange}
               validateEmailAddress={this.validateEmailAddress}
               handleSaveReplacement={this.handleSaveReplacement}
               invalidEmailsErrorMsg={this.state.invalidEmailsErrorMsg}
            />
         );
      }
   }
   class WarningModal extends Component {
      render() {
         return (
            <React.Fragment>
               <div className="stepperClose-Alert">
                  <div className="stepperClose-Alert-Content">
                     <div className="stepperClose-Alert-Icon">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                     </div>
                     <div className="stepperClose-Alert-text">
                        <h5>
                           Are you sure you want to Delete?
                        </h5>
                     </div>
                  </div>
               </div>
               <div className="notifyModal-buttons">
                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={this.props.disableDelete}
                     hasIcon={false}
                     type="outline"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Cancel"
                     callbackFunction={this.props.onCloseModal}
                  />

                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="solid"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Yes sure"
                     callbackFunction={this.props.onCloseProject}
                  />
               </div>
            </React.Fragment>
         );
      }
   }

   return IOApproverManagementContainer;
})();
export default connect(
   (state) => {
      return {
         IOApproverManagementData: state.IOApproverManagementData,
         userInformation: state.userInformation,
         UserManagementData: state.UserManagementData
      };
   },
   (dispatch) =>
      bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(IOApproverManagement);
