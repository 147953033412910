import React, { Component } from "react";
import {
  Accordion,
  Card,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  useAccordionToggle,
} from "react-bootstrap";

import { connect } from "react-redux";
import { GrooveIconSvg, GrooveInput, GrooveRadioButton } from "tfo-groove";

import "../ProgramIdentified.css";
import SubDimension from "./SubDimension";

const Dimension = (function() {
  class DimensionPresentational extends Component {
    render() {
      return (
        <form key={this.props.dimensionID}>
          <div className="dimension-container project-dimension-container">
            <Accordion
              className="dimension-collapse"
              defaultActiveKey={this.props.dimensionID}
            >
              <Card>
                <Accordion.Collapse eventKey={this.props.dimensionID}>
                  <Card.Body>
                    {this.props.subDimension.map(
                      (subDimensionMap, subDimensionIndex) => {
                        let isBOIAdded =
                          subDimensionMap.BOIList.length > 0 &&
                          subDimensionMap.BOIList.find(
                            (boi) => boi.IsActive === true
                          );
                        if (isBOIAdded) {
                          return (
                            <SubDimension
                              dimensionIndex={this.props.dimensionIndex}
                              subDimensionIndex={subDimensionIndex}
                              subDimensionID={subDimensionMap.SubDimensionID}
                              subDimensionName={
                                subDimensionMap.SubDimensionName
                              }
                              subDimensionValue={
                                subDimensionMap.SubDimensionTarget
                              }
                              subDimensionReason={
                                subDimensionMap.SubDimensionReason
                              }
                              subDimensionStatus={
                                subDimensionMap.SubDimensionStatus
                              }
                              boiList={subDimensionMap.BOIList}
                              onChangeSubDimension={
                                this.props.onChangeSubDimension
                              }
                              onChangeBOI={this.props.onChangeBOI}
                              role={this.props.role}
                              IsReadOnly={this.props.IsReadOnly}
                              selectedProjectID={this.props.selectedProjectID}
                              isSubmitClicked={this.props.isSubmitClicked}
                            />
                          );
                        }
                      }
                    )}
                  </Card.Body>
                </Accordion.Collapse>
                <Card.Header>
                  <Row className="dimension-details">
                    <Col
                      lg={11}
                      md={11}
                      sm={11}
                      className="bodytext18-medium-midnight"
                    >
                      Dimension - {this.props.dimensionName}
                    </Col>
                    <div>
                    <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={this.props.dimensionID}
                      >
                        <GrooveIconSvg
                          customClassName="accordion-button"
                          size="large"
                          name="chevron-up"
                          iconStyle="solid"
                          iconColor="stat-neutral"
                        />
                      </Accordion.Toggle>
                    </div>           
                  </Row>
                </Card.Header>
              </Card>
            </Accordion>
          </div>
        </form>
      );
    }
  }

  class DimensionContainer extends Component {
    constructor(props) {
      super(props);
      let subDimensionState = [];
      subDimensionState = { ...this.props.subDimension };
      this.state = {
        radioButton: [
          {
            radioBtnLabel: "Approve",
            radioBtnValue: "Approve",
            defaultChecked:
              this.props.dimensionStatus === "Approve" || this.props.dimensionStatus === "" || this.props.dimensionStatus === null ? true : false,
            name: "radio-select",
            id: "radio-1-" + this.props.dimensionID,
          },
          {
            radioBtnLabel: "Reject",
            radioBtnValue: "Rejected",
            defaultChecked:
              this.props.dimensionStatus === "Rejected" ? true : false,
            name: "radio-select",
            id: "radio-2-" + this.props.dimensionID,
          },
          {
            radioBtnLabel: "Conditional Approval",
            radioBtnValue: "Conditional Approval",
            defaultChecked:
              this.props.dimensionStatus === "Conditional Approval"
                ? true
                : false,
            name: "radio-select",
            id: "radio-3-" + this.props.dimensionID,
          },
        ],
      };
    }
    render() {
      return (
        <DimensionPresentational
          radioButton={this.state.radioButton}
          dimensionID={this.props.dimensionID}
          dimensionName={this.props.dimensionName}
          dimensionIndex={this.props.dimensionIndex}
          dimensionTarget={this.props.dimensionTarget}
          dimensionStatus={this.props.dimensionStatus}
          dimensionReason={this.props.dimensionReason}
          subDimension={this.props.subDimension}
          onChangeDimension={this.props.updateDimension}
          onChangeSubDimension={this.props.updateSubDimension}
          onChangeBOI={this.props.updateBOI}
          showConditional={this.props.showConditionalApproveModal}
          role={this.props.role}
          IsReadOnly={this.props.IsReadOnly}
          selectedProjectID={this.props.selectedProjectID}
          isSubmitClicked={this.props.isSubmitClicked}
        />
      );
    }
  }

  return DimensionContainer;
})();

export default Dimension;
