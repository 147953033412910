import {
  SHOW_TOAST_NOTIFICATION,
  GET_PROJECTS_LIST,
  GET_PROJECT_BOI,
  GET_PROJECT_CONDITIONAL_DIMENSION,
  GET_PROJECT_REJECTED_DIMENSION,
  GET_PROJECT_CA_DETAILS,
  GET_PROJECT_CA_FILE_DATA,
  SET_PROJECT_STAKEHOLDERS,
  SET_PROJECT_DROPDOWN_LIST,
  SET_NEW_PROJECT_DETAILS,
  SET_SELECTED_PROJECT_DETAILS,
  GET_PROGRAM_BOI_LIST,
  GET_PROJECT_EDIT_BOI,
  SET_SELECTED_REPORTED_YEAR,
  SET_REPORTED_YEAR_LIST,
  SET_SELECTED_PROJECT,
  CLEAR_ALL_PROJECT_FIELDS,
  SET_ORIG_PROJECT_BOI,
  SET_PROJECT_SFA_PARAM,
  SET_PROJECT_SFA_PAYLOAD,
  SET_PROJECT_SFA,
  SET_PROJECT_RESEND_PAYLOAD,
  GET_PROJECT_ACCT_LEADERSHIP_DETAILS,
  SET_PROJECT_UPLOAD_FILE,
  SET_PROJECT_UPLOAD,
  GET_PROJECT_STAKEHOLDERS,
  SHOW_SELECT_PROJECT,
  GET_PROJECT_BOIHISTORY,
  GET_PROJECT_SPECIFICBOIHISTORY,
  SAVE_ALREADY_EXIST,
  FETCH_ASSET_AND_DELIVEREDBY_LIST,
  SET_BUSINESS_ADVISOR_LIST_BY_CLIENT,
  SET_TEMP_PROJECT_LIST,
  SET_SORT_PROJECT_LIST,
  COPIED_PROJECT_NAME
} from '../Constants';

const initialState = {
  showToastNotification: false,
  projectProgramDetails: {},
  dimensionList: [],
  origDimensionList: [],
  conditionalDimensionList: [],
  rejectedDimensionList: [],
  projectCAFileDetails: [],
  projectCADetails: {},
  projectList: [],
  // projectStakeHolders: {
  //   clientStakeHolders: [],
  //   deliveryCenters: []
  // },
  projectDropdownList: {
    deliveryCenterList: [],
    projectLeadList: [],
    // deliveryUserList: [],
  },
  newProjectDetails: {
    projectName: { value: '', isError: false },
    suboffering: [],
    projectDescription: '',
    offering: '',
    deliveryLocation: [],
    targetEndDate: '',
    ioapprover: {},
    projectcreator: {},
    projectapprover: {},
    deliveredBy: {},
    assetName: [],
    cxLead: [],
    oaLead: [],
    businessAdvisor: [],
    cxlList: [],
    oalList: [],
    baList: []
  },
  selectedProjectDetails: {},
  programBOIList: [],
  editDimensionList: [],
  selectedProject: {},
  selectedReportedYear: [],
  rejectedSFA: [],
  projSFAPayload: [],
  projectSFA: [],
  arsDetails: [
    {
      //always
      pName: '', //project/program
      lead1: '', //bt lead
      lead2: '', //ops acct lead
      submittedDate: '',
      status: '',
      css: '', //for status
    },
  ],
  projUpload: [],
  projUploadFile: [],
  projUploadPayload: [],
  projectStakeholders: [],
  showSelectProjectView: false,
  projectBOIHistory: {
    rejectedBOIHistories: [],
    modifiedBOIHistories: [],
    deletedBOIHistories: [],
  },
  projectSpecificBOIHistory: {},
  assetAndDeliveredByList: {
    assetsNameList: [],
    deliveredByList: []
  },
  businessAdvisorList: [],
  reportedYearsList: [],
  tempProjectList: [],
  sortClearedList: [],
  copiedProjectName: ''
};

export const ProjectConfigurationData = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SHOW_TOAST_NOTIFICATION:
      return {
        ...state,
        showToastNotification: action.showToastNotification,
      };
      case SET_TEMP_PROJECT_LIST:
        return {
          ...state,
          tempProjectList: action.tempProjectList,
        };
        case SET_SORT_PROJECT_LIST:
          return {
            ...state,
            sortClearedList: action.sortClearedList,
          };
    case GET_PROJECTS_LIST:
      return {
        ...state,
        projectList: action.projectList,
      };
    case SET_PROJECT_STAKEHOLDERS:
      return {
        ...state,
        projectStakeHolders: action.projectStakeHolders,
      };
    case SET_PROJECT_DROPDOWN_LIST:
      return {
        ...state,
        projectDropdownList: action.projectDropdownList,
      };
    case GET_PROJECT_BOI:
      return {
        ...state,
        dimensionList: action.dimensionList,
      };
    case GET_PROJECT_CONDITIONAL_DIMENSION:
      return {
        ...state,
        conditionalDimensionList: action.conditionalDimensionList,
      };
    case GET_PROJECT_REJECTED_DIMENSION:
      return {
        ...state,
        rejectedDimensionList: action.rejectedDimensionList,
      };
    case GET_PROJECT_CA_FILE_DATA:
      return {
        ...state,
        projectCAFileDetails: action.projectCAFileDetails,
      };
    case GET_PROJECT_CA_DETAILS:
      return {
        ...state,
        projectCADetails: action.projectCADetails,
      };
    case SET_NEW_PROJECT_DETAILS:
      return {
        ...state,
        newProjectDetails: action.newProjectDetails,
      };
    case SET_SELECTED_PROJECT_DETAILS:
      return {
        ...state,
        selectedProjectDetails: action.selectedProjectDetails,
      };
    case GET_PROGRAM_BOI_LIST:
      return {
        ...state,
        programBOIList: action.programBOIList,
      };
    case GET_PROJECT_EDIT_BOI:
      return {
        ...state,
        editDimensionList: action.editDimensionList,
      };
    case SET_SELECTED_REPORTED_YEAR:
      return {
        ...state,
        selectedReportedYear: action.selectedReportedYear,
      };
    case SET_REPORTED_YEAR_LIST:
      return {
        ...state,
        reportedYearsList: action.reportedYearsList,
      };
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.selectedProject,
      };
    case CLEAR_ALL_PROJECT_FIELDS:
      return action.payload;
    case SET_ORIG_PROJECT_BOI:
      return {
        ...state,
        origDimensionList: action.origDimensionList,
      };
    case SET_PROJECT_SFA_PARAM:
      return {
        ...state,
        rejectedSFA: action.rejectedSFA,
      };
    case SET_PROJECT_SFA_PAYLOAD:
      return {
        ...state,
        projSFAPayload: action.projSFAPayload,
      };
    case SET_PROJECT_SFA:
      return {
        ...state,
        projectSFA: action.projectSFA,
      };
    case SET_PROJECT_RESEND_PAYLOAD:
      return {
        ...state,
        projResendPayload: action.projResendPayload,
      };
    case GET_PROJECT_ACCT_LEADERSHIP_DETAILS:
      return {
        ...state,
        arsDetails: action.arsDetails,
      };
    case SET_PROJECT_UPLOAD_FILE:
      return {
        ...state,
        projUploadFile: action.projUploadFile,
      };
    case SET_PROJECT_UPLOAD:
      return {
        ...state,
        projUpload: action.projUpload,
      };
    case GET_PROJECT_STAKEHOLDERS:
      return {
        ...state,
        projectStakeholders: action.projectStakeholders,
      };
    case SHOW_SELECT_PROJECT:
      return {
        ...state,
        showSelectProjectView: action.showSelectProjectView,
      };
    case GET_PROJECT_BOIHISTORY:
      return {
        ...state,
        projectBOIHistory: action.projectBOIHistory,
      };
    case GET_PROJECT_SPECIFICBOIHISTORY:
      return {
        ...state,
        projectSpecificBOIHistory: action.projectSpecificBOIHistory,
      };
    case SAVE_ALREADY_EXIST:
      return {
        ...state,
        newProjectDetails: { ...state.newProjectDetails, projectName: { ...state.newProjectDetails.projectName, isError: true } },
      };
    case FETCH_ASSET_AND_DELIVEREDBY_LIST:
      return {
        ...state,
        assetAndDeliveredByList: action.assetAndDeliveredByList
      }
    case SET_BUSINESS_ADVISOR_LIST_BY_CLIENT:
      return {
        ...state,
        businessAdvisorList: action.businessAdvisorList
      }
      case COPIED_PROJECT_NAME:
      return {
        ...state,
        copiedProjectName: action.copiedProjectName,
      }
    default:
      return state;
  }
};
