import {
  GET_CLIENT_DETAILS,
  FETCH_CXLEADLIST,
  FETCH_OFFERING,
  FETCH_SUB_OFFERING,
  FETCH_PROGRAM_DATA,
  FETCH_CONFIGURATORSLIST,
  FETCH_OALEADLIST,
  FETCH_IOA_APPROVERLIST,
  FETCH_CREATED_USER,
  GET_PROGRAM_ACCT_LEADERSHIP_DETAILS,
  SET_PROGRAM_LIST,
  GET_BOIMAPPING_LIST,
  GET_CUSTOMBOIDROPDOWN_LIST,
  GET_PROGRAM_BOI,
  SET_SELECTED_PROGRAM_DETAILS,
  SET_NEW_PROGRAM_DETAILS,
  SET_SELECTED_PROGRAM,
  GET_PROGRAM_CA_DETAILS,
  GET_CA_FILE_DATA,
  GET_REJECTED_DIMENSION,
  CLEAR_ALL_PROGRAM_PROJECT_FIELDS,
  GET_CONDITIONAL_DIMENSION,
  SET_SFA_PARAM,
  SET_RESEND_PARAM,
  SET_PROGRAM_SFA,
  SET_PROGRAM_SFA_PAYLOAD,
  SET_PROGRAM_RESEND_PAYLOAD,
  SET_PROGRAM_UPLOAD,
  SET_PROGRAM_UPLOAD_FILE,
  GET_PROGRAM_EDIT_BOI,
  GET_PROGRAM_STAKEHOLDERS,
  GET_PROGRAM_BOIHISTORY,
  IS_PROGPROJ_DROPDOWN,
  GET_PROGRAM_DELETED_BOI,
  IS_CHECKED_ALL_OFFERINGS,
  GET_BOI_ALL_OFFERINGS,
  PREP_DROPDOWN_LIST_BY_OFFERING,
  PREP_DROPDOWN_LIST_ALL_OFFERING,
  GET_PROGRAM_SPECIFICBOIHISTORY,
  SET_NEWLY_ADDED_STAKEHOLDER,
  ON_SEND_REMINDER_PROGRAM_APPROVAL,
} from '../Constants';

const initialState = {
  programClientDetails: {
    ClientBasicDetailID: 0,
    ClientMasterID: 0,
    ClientTypeName: '',
    IndustryName: '',
    MarketUnitName: '',
    OperationAccountLead: '',
    ClientMasterName: '',
    ClientAdmin: '',
    programData: [],
    offeringList: [],
    CXLeadList: [],
    // ConfiguratorsList: [],
    IOAApproverList: [],
    updatedUser: '',
  },
  rejectedDimensionList: [],
  conditionalDimensionList: [],
  programAcctLSDetails: [],
  programList: [],
  dimensionList: [],
  deletedDimensionList: [],
  editDimensionList: [],
  arsDetails: [],
  boiMappingList: [],
  boiMappingListAllOfferings: [],
  dropdownListByOfferings: [],
  dropdownListAllOfferings: [],
  customBOIDropdownList: [],
  selectedProgramDetails: [],
  selectedProgram: [],
  programCAFileDetails: [],
  programCADetails: {},
  newProgramDetails: {
    offering: {},
    subOffering: [],
    programName: '',
    cxLead: [],
    oaLead: [],
    progCreator: {},
    validation: false,
  },
  rejectedSFA: [],
  dimListForResend: [],
  programSFA: [],
  progSFAPayload: [],
  progUploadPayload: [],
  progUpload: [],
  progUploadFile: [],
  programStakeholders: [],
  programBOIHistory: {
    rejectedBOIHistories: [],
    modifiedBOIHistories: [],
    deletedBOIHistories: [],
  },
  isProgramProjectFromDropdown: false,
  isCheckedAllOfferings: false,
  programSpecificBOIHistory: {},
  ConfiguratorsList: [],
  CXLeadList: [],
  OALeadList: [],
  newlyAddedUserList: [],
  sendReminderProgramApproval: [],
};

export const ProgramConfigurationData = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_CLIENT_DETAILS:
      return {
        ...state,
        programClientDetails: action.programClientDetails,
      };
    case FETCH_OFFERING:
      return {
        ...state,
        offeringList: action.offeringList,
      };
    case FETCH_SUB_OFFERING:
      return {
        ...state,
        subOfferingList: action.subOfferingList,
      };
    case FETCH_PROGRAM_DATA:
      return {
        ...state,
        programData: action.programData,
      };
    case FETCH_CXLEADLIST:
      return {
        ...state,
        CXLeadList: action.CXLeadList,
      };
    case FETCH_CONFIGURATORSLIST:
      return {
        ...state,
        ConfiguratorsList: action.ConfiguratorsList,
      };
    case FETCH_OALEADLIST:
      return {
        ...state,
        OALeadList: action.OALeadList,
      };
    case FETCH_IOA_APPROVERLIST:
      return {
        ...state,
        IOAApproverList: action.IOAApproverList,
      };
    case GET_PROGRAM_ACCT_LEADERSHIP_DETAILS:
      return {
        ...state,
        arsDetails: action.arsDetails,
      };
    case SET_PROGRAM_LIST:
      return {
        ...state,
        programList: action.programList,
      };
    case FETCH_CREATED_USER:
      return {
        ...state,
        updatedUser: action.updatedUser,
      };
    case GET_BOIMAPPING_LIST:
      return {
        ...state,
        boiMappingList: action.boiMappingList,
      };
    case GET_CUSTOMBOIDROPDOWN_LIST:
      return {
        ...state,
        customBOIDropdownList: action.customBOIDropdownList,
      };
    case GET_PROGRAM_BOI:
      return {
        ...state,
        dimensionList: action.dimensionList,
      };
    case GET_PROGRAM_EDIT_BOI:
      return {
        ...state,
        editDimensionList: action.editDimensionList,
      };
    case GET_REJECTED_DIMENSION:
      return {
        ...state,
        rejectedDimensionList: action.rejectedDimensionList,
      };
    case GET_CONDITIONAL_DIMENSION:
      return {
        ...state,
        conditionalDimensionList: action.conditionalDimensionList,
      };
    case SET_SELECTED_PROGRAM_DETAILS:
      return {
        ...state,
        selectedProgramDetails: action.selectedProgramDetails,
      };
    case SET_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: action.selectedProgram,
      };
    case GET_PROGRAM_CA_DETAILS:
      return {
        ...state,
        programCADetails: action.programCADetails,
      };
    case GET_CA_FILE_DATA:
      return {
        ...state,
        programCAFileDetails: action.programCAFileDetails,
      };
    case CLEAR_ALL_PROGRAM_PROJECT_FIELDS:
      return action.payload;
    case SET_NEW_PROGRAM_DETAILS:
      return {
        ...state,
        newProgramDetails: action.newProgramDetails,
      };
    case SET_SFA_PARAM:
      return {
        ...state,
        rejectedSFA: action.rejectedSFA,
      };
    case SET_RESEND_PARAM:
      return {
        ...state,
        dimListForResend: action.dimListForResend,
      };

    case SET_PROGRAM_SFA:
      return {
        ...state,
        programSFA: action.programSFA,
      };
    case SET_PROGRAM_SFA_PAYLOAD:
      return {
        ...state,
        progSFAPayload: action.progSFAPayload,
      };
    case SET_PROGRAM_RESEND_PAYLOAD:
      return {
        ...state,
        progResendPayload: action.progResendPayload,
      };
    case SET_PROGRAM_UPLOAD:
      return {
        ...state,
        progUpload: action.progUpload,
      };
    case SET_PROGRAM_UPLOAD_FILE:
      return {
        ...state,
        progUploadFile: action.progUploadFile,
      };
    case GET_PROGRAM_STAKEHOLDERS:
      return {
        ...state,
        programStakeholders: action.programStakeholders,
      };
    case GET_PROGRAM_BOIHISTORY:
      return {
        ...state,
        programBOIHistory: action.programBOIHistory,
      };
    case IS_PROGPROJ_DROPDOWN:
      return {
        ...state,
        isProgramProjectFromDropdown: action.isProgramProjectFromDropdown,
      };
    case IS_CHECKED_ALL_OFFERINGS:
      return {
        ...state,
        isCheckedAllOfferings: action.isCheckedAllOfferings,
      };
    case GET_PROGRAM_DELETED_BOI:
      return {
        ...state,
        deletedDimensionList: action.deletedDimensionList,
      };
    case GET_BOI_ALL_OFFERINGS:
      return {
        ...state,
        boiMappingListAllOfferings: action.boiMappingListAllOfferings,
      };
    case PREP_DROPDOWN_LIST_BY_OFFERING:
      return {
        ...state,
        dropdownListByOfferings: action.dropdownListByOfferings,
      };
    case PREP_DROPDOWN_LIST_ALL_OFFERING:
      return {
        ...state,
        dropdownListAllOfferings: action.dropdownListAllOfferings,
      };
    case GET_PROGRAM_SPECIFICBOIHISTORY:
      return {
        ...state,
        programSpecificBOIHistory: action.programSpecificBOIHistory,
      };
    case SET_NEWLY_ADDED_STAKEHOLDER:
      return {
        ...state,
        newlyAddedUserList: action.newlyAddedUserList
      };
    case ON_SEND_REMINDER_PROGRAM_APPROVAL:
      return {
        ...state,
        sendReminderProgramApproval: action.sendReminderProgramApproval
      }
    default:
      return state;
  }
};
