import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import { Row, Col } from 'react-bootstrap';

import { faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { history } from '../../store';
import { IsValid, IsObjEmpty } from '../../Utilities/Validations';
import { FormDateDDMMMYYY, TryGetNodeAlt, TryGetObjValueAlt } from '../../Utilities/Formatter';
import CVMSelectDropdown from '../Shared/CVMSelectDropdown/CVMSelectDropdown';
import CVMModal from '../Shared/CVMModal/CVMModal';
import {
	setSelectedProgram,
	setProgramDetails,
	clearAllProgramProjectFields,
	getBOIMappingList,
	getCustomBOIDropdownList,
	getProgramBOI,
	getProgramAcctLeadershipDetails,
	getProgramCADetails,
	isProgramProjectFromDropdown,
	handleCheckAllOfferings,
	removeNewlyAddedStakeholders,
} from '../../Actions/ProgramConfiguration.Action';
import { InitPnP } from '../../Actions/AccessValidation.Action';

import {
	setSelectedProject,
	setSelectedProjectdetails,
	clearAllProjectFields,
	getProjectProgramDetails,
	getProjectAcctLeadershipDetails,
	showSelectProject,
	getProjectBOI,
	getProjectCADetails,
} from '../../Actions/ProjectConfiguration.Action';

import {
	setStepperActiveIndex,
	setEditApproved,
	setHistoryPath,
} from '../../Actions/Shared.Action';

import Popover from 'react-bootstrap/Popover';
import { LogPPCUserAction, LogUserSiteAction } from '../../Utilities/AccessManagementUtil';
import {
	ACC_PROJ,
	ACC_PROG,
	PROG_ID_VAL_ALA,
	PROG_ID_VAL_SETT,
	PROJ_ID_VAL_SETT,
	PROJ_ID_VAL_ALA,
} from '../../Constants/Modules';
import store from "../../../main/store";

const ProgramProjectHeader = (function () {
	class ProgramProjectHeaderPresentational extends Component {
		render() {
			const programStatusId = this.props.selectedProgramDetails.StatusId;
			const projectStatusId = this.props.selectedProjectDetails.StatusId;
			const tooltipProgramContent = programStatusId ? (
				<div className="program-tooltip">
					<Popover id="programDetails-popover" trigger="focus">
						<Popover.Content>
							<div>
								<div className="sub-section">
									<div className="detail-popup-section">
										<div className="section-title">Program Name</div>
										<div className="section-desc">
											{this.props.selectedProgramDetails.ClientProgramName}
										</div>
									</div>
									<div className="detail-popup-section">
										<div className="section-title">Offering</div>
										<div className="section-desc">{this.props.selectedProgramDetails.Offering}</div>
									</div>
									<div className="detail-popup-section">
										<div className="section-title">Sub-Offering</div>
										{this.props.selectedProgramDetails.SubOfferingList.map((x, i) => {
											return (
												<div key={i} className="section-desc">
													- {x.SubOfferingName}
												</div>
											);
										})}
									</div>
									<div className="detail-popup-section">
										<Row>
											<Col md={7}>
												<div className="section-title">Ops Account Lead</div>
												<div className="section-desc">
													{(() => {
														let OALs = this.props.selectedProgramDetails.OperationsAccountLead.replaceAll("@accenture.com", "")
														OALs = OALs.replaceAll(",", ", ");
														return OALs;
													})()
													}
												</div>
											</Col>
											<Col md={5}>
												<div className="section-title">CX Lead</div>
												<div className="section-desc">
													{(() => {
														let CXls = this.props.selectedProgramDetails.CXLLead.replaceAll("@accenture.com", "")
														CXls = CXls.replaceAll(",", ", ")
														return CXls;
													})()
													}
												</div>
											</Col>
										</Row>
									</div>
									<div className="detail-popup-section">
										<Row>
											<Col md={7}>
												<div className="section-title">Program Creator</div>
												<div className="section-desc">
													{this.props.selectedProgramDetails.ProgramCreator !== null
														? this.props.selectedProgramDetails.ProgramCreator.split('@')[0]
														: ''}
												</div>
											</Col>
											<Col md={5}>
												<div className="section-title">Stage</div>
												<div className="section-desc">
													{this.props.selectedProgramDetails.Stage}
												</div>
												<div
													id="program-details-status"
													className={
														programStatusId === 1
															? 'card-row-status status-notStarted'
															: programStatusId === 2
																? 'card-row-status status-notStarted'
																: programStatusId === 3
																	? 'card-row-status status-inProgress'
																	: programStatusId === 4
																		? 'card-row-status status-rejected'
																		: programStatusId === 5
																			? 'card-row-status status-inProgress'
																			: programStatusId === 6
																				? 'card-row-status status-completed'
																				: programStatusId === 7
																					? 'card-row-status status-completed'
																					: programStatusId === 8 && 'card-row-status status-rejected'
													}
												>
													{this.props.selectedProgramDetails.Status}
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</Popover.Content>
					</Popover>
				</div>
			) : (
				''
			);

			const tooltipProjectContent = projectStatusId ? (
				// update this condition, it was causing whitescreen issues upon clicking add new program button hence reverting to the original condition "projectStatusId"
				// this.props.selectedProjectDetails.ClientProgramID === this.props.selectedProgramDetails.ClientProgramID ?
				<Popover id="programDetails-popover" trigger="focus" placement="left">
					<Popover.Content>
						<div>
							<div className="sub-section">
								<div className="detail-popup-section">
									<div className="section-title">Project Name</div>
									<div className="section-desc">
										{this.props.selectedProjectDetails.ClientProjectName}
									</div>
								</div>
								<div className="detail-popup-section">
									<div className="section-title">Offering</div>
									<div className="section-desc">
										{this.props.selectedProjectDetails.OfferingName}
									</div>
								</div>
								<div className="detail-popup-section">
									<div className="section-title">Sub-Offering</div>
									{this.props.selectedProjectDetails.ClientProjectSubOffering.map((x, i) => {
										return (
											<div key={i} className="section-desc">
												- {x.SubOfferingName}
											</div>
										);
									})}
								</div>
								<div className="detail-popup-section">
									<Row>
										<Col md={6}>
											<div className="detail-popup-section">
												<div className="section-title">Project Creator</div>
												<div className="section-desc">
													{this.props.selectedProjectDetails.ProjectCreator !== null
														? this.props.selectedProjectDetails.ProjectCreator.split('@')[0]
														: ''}
												</div>
											</div>
										</Col>
										<Col md={6}>
											<div className="detail-popup-section">
												<div className="section-title">Project Approver</div>
												<div className="section-desc">
													{this.props.selectedProjectDetails.ProjectApprover !== null
														? this.props.selectedProjectDetails.ProjectApprover.split('@')[0]
														: ''}
												</div>
											</div>
										</Col>
									</Row>
								</div>
								<div className="detail-popup-section">
									<Row>
										<Col md={6}>
											<div className="section-title">Delivery Location</div>
											{this.props.selectedProjectDetails.DeliveryCenterList.map((x, i) => {
												return (
													<div i={i} className="section-desc">
														- {x.deliveryCenterName}
													</div>
												);
											})}
										</Col>
										<Col md={6}>
											<div className="section-title">IO Approver</div>
											<div className="section-desc">
												{
													TryGetObjValueAlt(
														this.props,
														'',
														'selectedProjectDetails.IOApprover'
													).split('@')[0]
												}
											</div>
										</Col>
									</Row>
								</div>
								<div className="detail-popup-section">
									<Row>
										<Col md={6}>
											<div className="section-title">Target End-Date</div>
											<div className="section-desc">
												{FormDateDDMMMYYY(
													this.props.selectedProjectDetails.TargetEndDate.split(' ')[0]
												)}
											</div>
										</Col>
										<Col md={6}>
											<div className="section-title">Stage</div>
											<div className="section-desc">{this.props.selectedProjectDetails.Stage}</div>
											<div
												id="program-details-status"
												className={
													projectStatusId === 1
														? 'card-row-status status-notStarted'
														: projectStatusId === 2
															? 'card-row-status status-notStarted'
															: projectStatusId === 3
																? 'card-row-status status-inProgress'
																: projectStatusId === 4
																	? 'card-row-status status-rejected'
																	: projectStatusId === 5
																		? 'card-row-status status-inProgress'
																		: projectStatusId === 6
																			? 'card-row-status status-completed'
																			: projectStatusId === 7
																				? 'card-row-status status-completed'
																				: projectStatusId === 8 && 'card-row-status status-rejected'
												}
											>
												{this.props.selectedProjectDetails.Status}
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Popover.Content>
				</Popover>
			) : (
				''
			);
    let copy=sessionStorage.getItem("copy")
			return (
				
				<div className="program-lead-landing">
					<div className="applyFlex-space-between">
						{(this.props.selectedProgramDetails.StageId && this.props.type !== 'Project') ||
							this.props.selectedProjectDetails.StageId ||
							(this.props.selectedProjectDetails.StageId === undefined &&
								this.props.type === 'Project') ? (
							<div className="applyFlex-align-center row project-header-select">
								<div className="applyFlex-align-center apply-relative col-md-10 col-lg-6 program-dropdown">
									<CVMSelectDropdown
										label="Program : "
										placeholder={''}
										onChange={(e) => this.props.onCloseOnChangeProgram(e, 'Program')}
										style={{
											control: { width: 'inherit' },
											option: {
												width: computeMaxWidths(this.props.programList, 670) + 'px !important',
											},
											menu: { width: 'inherit' },
											mainContainer: {
												width: 'inherit',
												margin: '0 0 0 0',
												float: 'right',
											},
										}}
										options={this.props.programList}
										value={this.props.selectedProgram}
										isSearchable={true}
										closeMenuOnSelect={false}
									/>
									<div className="info-icon top">
										<GrooveIconSvg
											size="large"
											name="info-circle"
											iconStyle="solid"
											iconColor="stat-alternate"
										/>
										<span className="tooltiptext programDetails">{tooltipProgramContent}</span>
									</div>
								</div>
								{this.props.type === 'Project' && (
									<div className="applyFlex-align-center apply-relative col-md-6 project-dropdown">
										<CVMSelectDropdown
											label="Project : "
											placeholder={''}
											onChange={(e) => this.props.onCloseOnChangeProgram(e, 'Project')}
											style={{
												control: { width: 'inherit' },
												option: {
													width: computeMaxWidths(this.props.projectList, 670) + 'px !important',
												},
												menu: { width: 'inherit' },
												mainContainer: {
													width: 'inherit',
													margin: '0 0 0 0',
													float: 'right',
												},
											}}
											options={this.props.projectList}
											value={copy=="true"?"":this.props.selectedProject}
											isSearchable={true}
											closeMenuOnSelect={false}
										/>
										<div className="info-icon top">
											<GrooveIconSvg
												size="large"
												name="info-circle"
												iconStyle="solid"
												iconColor="stat-alternate"
											/>
											<span className="tooltiptext projectDetails">{tooltipProjectContent}</span>
										</div>
									</div>
								)}
							</div>
						) : (
							<div className="applyFlex-align-center row"></div>
						)}
						<div className="stepper-close">
							<FontAwesomeIcon
								icon={faTimes}
								onClick={(e) => this.props.onCloseProgramProject(e)}
							/>
						</div>

						{/* Modal */}
						<CVMModal
							title={''}
							show={this.props.showProgramProject}
							onCloseModal={this.props.onCloseProgramProject}
							customclass={'select-project-modal'}
							content={
								<WarningModal
									//redux
									onCloseModal={this.props.onCloseProgramProject}
									onCloseProject={this.props.onClickClose}
									selectedProgramDetails={this.props.selectedProgramDetails}
									selectedProjectDetails={this.props.selectedProjectDetails}
									type={this.props.type}
									stepperActiveIndex={this.props.stepperActiveIndex}
								/>
							}
						/>
						{/* Modal for on change program */}
						<CVMModal
							title={''}
							show={this.props.showOnChangeProgramProject}
							onCloseModal={this.props.onCloseOnChangeProgram}
							customclass={'select-project-modal'}
							content={
								<WarningOnChangeProgramModal
									//redux
									onCloseModal={this.props.onCloseOnChangeProgram}
									onChangeProgram={this.props.onChangeProgram}
									onChangeProject={this.props.onChangeProject}
									onSelectProgramProject={this.props.onSelectProgramProject}
									dropdownType={this.props.dropdownType}
									disableClick={this.props.disableClick}
									changeClicked={this.props.changeClicked}
								/>
							}
						/>
					</div>
				</div>
			);
		}
	}

	const computeMaxWidths = (optionsArr, defWidth) => {
		let maxWidth = defWidth;
		let ddOptions = optionsArr;
		if (IsValid(ddOptions)) {
			let widths = [];
			ddOptions.map((opt) => {
				widths.push(String(opt.label).toString().length);
			});
			let maxLen = Math.max(...widths);

			if (String(maxLen).toString() !== '-Infinity' && IsValid(maxLen)) {
				let tempMax = maxLen * 6 + 5;

				maxWidth = tempMax > defWidth ? tempMax : defWidth;
			}
		}
		return maxWidth;
	};
	class WarningModal extends Component {
		render() {
			return (
				<React.Fragment>
					<div className="stepperClose-Alert">
						<div className="stepperClose-Alert-Content">
							<div className="stepperClose-Alert-Icon">
								<FontAwesomeIcon icon={faExclamationTriangle} />
							</div>
							<div className="stepperClose-Alert-text">
								<h5>
									{this.props.type === 'Program'
										? this.props.selectedProgramDetails.Stage !== undefined
											? this.props.stepperActiveIndex === 1
												? 'Are you sure you want to close Program Target Value Settings'
												: this.props.stepperActiveIndex === 2
													? 'Are you sure you want to close Account Leadership Approval'
													: this.props.stepperActiveIndex === 3
														? 'Are you sure you want to close Client Alignment'
														: 'Are you sure you want to close Program Target Value Settings'
											: 'Are you sure you want to close Program Target Value Settings'
										: this.props.type === 'Project'
											? this.props.selectedProjectDetails.Stage !== undefined
												? this.props.stepperActiveIndex === 1
													? 'Are you sure you want to close Project Target Value Settings'
													: this.props.stepperActiveIndex === 2
														? 'Are you sure you want to close Project Approval'
														: this.props.stepperActiveIndex === 3
															? 'Are you sure you want to close Client Alignment'
															: ''
												: 'Are you sure you want to close Project Target Value Settings'
											: ''}
								</h5>
								<p>Please note that this would not save your changes.</p>
							</div>
						</div>
					</div>
					<div className="notifyModal-buttons">
						<GrooveButton
							id="primry-btn-1"
							name="Solid Button Primary-Ops"
							isDisabled={false}
							hasIcon={false}
							type="outline"
							colorClass="stat-alternate"
							size="auto"
							text="Cancel"
							callbackFunction={this.props.onCloseModal}
						/>

						<GrooveButton
							id="primry-btn-1"
							name="Solid Button Primary-Ops"
							isDisabled={false}
							hasIcon={false}
							type="solid"
							colorClass="stat-alternate"
							size="auto"
							text="Yes"
							callbackFunction={()=>{
								this.props.onCloseProject(); 
								store.dispatch({
									type: "COPIED_PROJECT_NAME",
									copiedProjectName:'',
								  })}}
						/>
					</div>
				</React.Fragment>
			);
		}
	}

	class WarningOnChangeProgramModal extends Component {
		render() {
			return (
				<React.Fragment>
					<div className="stepperClose-Alert">
						<div className="stepperClose-Alert-Content">
							<div className="stepperClose-Alert-Icon">
								<FontAwesomeIcon icon={faExclamationTriangle} />
							</div>
							<div className="stepperClose-Alert-text">
								<h5>
									You are trying to switch to a different {this.props.dropdownType} without saving
									your progress for current {this.props.dropdownType}. You might lose all your
									unsaved data.
								</h5>

								<p>Do you wish to continue?</p>
							</div>
						</div>
					</div>
					<div className="notifyModal-buttons">
						<GrooveButton
							id="primry-btn-1"
							name="Solid Button Primary-Ops"
							isDisabled={false}
							hasIcon={false}
							type="outline"
							colorClass="stat-alternate"
							size="auto"
							text="Cancel"
							callbackFunction={this.props.onCloseModal}
						/>

						<GrooveButton
							id="primry-btn-1"
							name="Solid Button Primary-Ops"
							isDisabled={this.props.changeClicked}
							hasIcon={false}
							type="solid"
							colorClass="stat-alternate"
							size="auto"
							text="Yes"
							callbackFunction={() => {
								if (this.props.dropdownType === 'Program') {
									this.props.disableClick();
									this.props.onChangeProgram(this.props.onSelectProgramProject);
								} else {
									this.props.disableClick();
									this.props.onChangeProject(this.props.onSelectProgramProject);
								}
							}}
						/>
					</div>
				</React.Fragment>
			);
		}
	}

	class ProgramProjectHeaderContainer extends Component {
		constructor() {
			super();
			this.state = {
				showProgramProject: false,
				showOnChangeProgramProject: false,
				onSelectProgramProject: {},
				showOnChangeProject: false,
				onSelectProject: {},
				prevSelectProgramProject: {},
				dropdownType: '',
				prevDropdownType: '',
				changeClicked: false,
				prevSelectedProject: {},
			};
		}

		async componentDidMount() {
			this.setState({
				prevSelectProgramProject: this.props.ProgramConfigurationData.selectedProgram,
				prevSelectedProject: this.props.ProjectConfigurationData.selectedProject,
			});
			this.props.showSelectProject(false);
			const url = window.location.href;
			if (url.includes('AccountLeadershipApproval'))
				await this.props.getProgramAcctLeadershipDetails();
			this.props.handleCheckAllOfferings(
				this.props.ProgramConfigurationData.selectedProgramDetails.allOfferings
			);
		}
		componentDidUpdate(prevProps) {
			if (this.props.clientID !== prevProps.clientID && IsValid(prevProps.clientID))
				this.props.history.push('/ProgramProjectConfig');
		}
		onClickClose = () => {
			sessionStorage.setItem("copy",false);
			if (IsValid(this.props.onClickClose)) this.props.onClickClose();
			const prog = TryGetNodeAlt(
				this.props.ProgramConfigurationData,
				null,
				'selectedProgramDetails'
			);
			const proj = TryGetNodeAlt(
				this.props.ProjectConfigurationData,
				null,
				'selectedProjectDetails'
			);
			let historyPath = this.props.SharedData.historyPath;
			this.props.setStepperActiveIndex(0);
			if (this.props.type === 'Project') {
				this.props.clearAllProjectFields();
				historyPath = historyPath.length > 0 ? historyPath[0] : null;
				let historyPathState = historyPath
					? historyPath.state
						? historyPath.state.projectStatus === 'approved'
						: false
					: false;
				if (
					IsValid(proj.ProjectID) &&
					((historyPath !== null &&
						!(
							historyPath.url.toLowerCase() ===
							'/programprojectconfig/projectaccountleadershipapproval/review' && historyPathState
						)) ||
						historyPath === null)
				) {
					LogPPCUserAction({
						clientID: prog.ClientMasterID,
						ClientProgramID: prog.ClientProgramID,
						ClientProjectID: proj.ProjectID,
						projStatus: ACC_PROJ,
						module: this.getModule(),
					});
				}
				this.props.history.push('/ProgramProjectConfig/projects');
			} else {
				this.props.clearAllProgramProjectFields();
				this.props.removeNewlyAddedStakeholders();
				historyPath = historyPath.length > 1 ? historyPath[1] : null;
				let historyPathState = historyPath
					? historyPath.state
						? historyPath.state.programStatus === 'approved'
						: false
					: false;
				if (
					(historyPath !== null &&
						!(
							historyPath.url.toLowerCase() ===
							'/programprojectconfig/programaccountleadershipapproval/review' && historyPathState
						)) ||
					historyPath === null
				) {
					LogPPCUserAction({
						clientID: prog.ClientMasterID,
						ClientProgramID: prog.ClientProgramID,
						progStatus: ACC_PROG,
						module: this.getModule(),
					});
				}
				this.props.history.push('/ProgramProjectConfig');
			}
			this.props.showSelectProject(false);
		};

		onCloseProgramProject = (origin, value) => {
			try {
				this.setState(
					{
						...this.state,
						showProgramProject: !this.state.showProgramProject,
					},
					() => {
						this.props.isProgramProjectFromDropdown(false);
					}
				);
			} catch (e) {
				console.log(e);
			}
		};

		onChangeProgram = async (e) => {
			try {
				const programDetails = this.props.ProgramConfigurationData.programList.filter(
					(x) => x.ClientProgramID === e.value
				);
				let tPrevSelected = this.state.prevSelectProgramProject;
				let isPrevCurrentSame = true;
				const prog = TryGetNodeAlt(
					this.props.ProgramConfigurationData,
					null,
					'selectedProgramDetails'
				);
				let historyPath = TryGetNodeAlt(this.props.SharedData, [], 'historyPath');
				historyPath = historyPath.length > 1 ? historyPath[1] : null;
				this.props.clearAllProgramProjectFields();
				this.props.setSelectedProgram(e);
				this.props.setProgramDetails(programDetails[0]);
				this.props.getProgramBOI(e.value);
				this.props.getProgramAcctLeadershipDetails();
				await this.props.getProjectProgramDetails(e.value);
				const clientID = !IsObjEmpty(this.props.SharedData.selectedClient)
					? this.props.SharedData.selectedClient.value
					: '';
				let userRole = '';
				if (!this.props.UserInformationData.isSuperAdmin) {
					userRole = this.props.UserInformationData.userProfiles.filter(
						(x) => x.ClientMasterId === clientID
					)[0].ProfileName;
				}

				let bTLeadUrl = '';
				let OALUrl = '';
				switch (programDetails[0].StatusId) {
					case 1:
					case 2: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramIDValueSettings';
						OALUrl = '/ProgramProjectConfig/ProgramIDValueSettings';
						break;
					}
					case 3: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
						OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval/review';
						break;
					}
					case 4: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
						OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
						break;
					}
					case 5: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
						OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval/review';
						break;
					}
					case 6: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
						OALUrl = '/ProgramProjectConfig/ProgramClientAlignment/submit';
						break;
					}
					case 7: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramClientAlignment';
						OALUrl = '/ProgramProjectConfig/ProgramClientAlignment';
						break;
					}
					case 8: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramClientAlignment';
						OALUrl = '/ProgramProjectConfig/ProgramClientAlignment/submit';
						break;
					}
					case 9: {
						bTLeadUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
						OALUrl = '/ProgramProjectConfig/ProgramAccountLeadershipApproval';
						break;
					}
					default:
						break;
				}

				LogUserSiteAction({
					clientID: prog.ClientMasterID,
					ClientProgramID: e.value,
					ClientProjectID: null,
					progStatus: ACC_PROG,
					projStatus: null,
					module: this.getModule(),
					ShouldLogPPCID: true,
				});
				isPrevCurrentSame =
					tPrevSelected.label !== undefined &&
						e.label !== undefined &&
						tPrevSelected.label !== e.label
						? false
						: true;
				if (!isPrevCurrentSame) {
					this.setState({
						prevSelectProgramProject: e,
					});
					this.props.showSelectProject(true);
				}

				if (this.props.type === 'Project') {
					this.props.setSelectedProject({});
				} else {
					this.props.setHistoryPath({ url: window.location.pathname });
					this.props.setSelectedProject({});
					this.props.getBOIMappingList(programDetails[0].ClientProgramID);
					this.props.getCustomBOIDropdownList(programDetails[0].ClientProgramID);
					this.props.getProgramCADetails(programDetails[0].ClientProgramID);
					this.props.getProgramBOI(programDetails[0].ClientProgramID);
					this.props.InitPnP();
					this.props.setStepperActiveIndex(
						programDetails[0].StatusId === 8
							? programDetails[0].StageId
							: (programDetails[0].StatusId === 3 || programDetails[0].StatusId === 5) &&
								(userRole === 'CX Lead' ||
									userRole === 'Operations Account Lead' ||
									this.props.UserInformationData.isSuperAdmin)
								? programDetails[0].StageId
								: 1
					);
				}

				this.onCloseOnChangeProgram(null, 'Program');
				this.props.isProgramProjectFromDropdown(true);
				this.props.setSelectedProject({});
				this.props.setSelectedProjectdetails({});
				let historyPathState = historyPath
					? historyPath.state
						? historyPath.state.programStatus === 'approved'
						: false
					: false;
				if (
					this.props.type !== 'Project' &&
					((historyPath !== null &&
						!(
							historyPath.url.toLowerCase() ===
							'/programprojectconfig/programaccountleadershipapproval/review' && historyPathState
						)) ||
						historyPath === null)
				) {
					LogPPCUserAction({
						clientID: prog.ClientMasterID,
						ClientProgramID: tPrevSelected.value,
						progStatus: ACC_PROG,
						module: this.getModule(),
					});
				}

				if (programDetails[0].StatusId < 6) {
					// if(this.props.AccessValidationData.PnPComponents.program.stageTwoApprv || userisCreatedUser){ //if user has approve/reject asscess level it will return false, hence the condition
					setTimeout(() => {
						if (this.props.AccessValidationData.PnPComponents.program.stageTwoApprv) {
							this.props.history.push(bTLeadUrl);
						} else {
							this.props.history.push(OALUrl);
						}
					}, 250);
				} else {
					// if(this.props.AccessValidationData.PnPComponents.program.stageThreeAcknwldg || userisCreatedUser){ //if user has acknowledge asscess level it will return false, hence the condition
					setTimeout(() => {
						if (this.props.AccessValidationData.PnPComponents.program.stageTwoApprv) {
							this.props.history.push(bTLeadUrl);
						} else {
							this.props.history.push(OALUrl);
						}
					}, 250);
				}
			} catch (e) {
				console.log(e);
			}
		};
		getModule = () => {
			const url = window.location.pathname.toLowerCase();
			const module = url.includes('/programprojectconfig/programidvaluesettings')
				? PROG_ID_VAL_SETT
				: url.includes('/programprojectconfig/program')
					? PROG_ID_VAL_ALA
					: url.includes('/programprojectconfig/projectidvaluesettings')
						? PROJ_ID_VAL_SETT
						: url.includes('/programprojectconfig/project')
							? PROJ_ID_VAL_ALA
							: {};

			return module;
		};

		onCloseOnChangeProgram = (e, dropdownType) => {
			try {
				let tProgramProject = {};
				let tShowSwitchModal =
					e !== null
						? e.label !== undefined && e.label !== this.state.prevSelectProgramProject.label
							? !this.state.showOnChangeProgramProject
							: false
						: false;
				this.setState(
					{
						showOnChangeProgramProject: tShowSwitchModal,
					},
					() => {
						if (e) {
							tProgramProject = e;
						} else {
							tProgramProject = {};
						}
						this.setState({
							onSelectProgramProject: tProgramProject,
							dropdownType: dropdownType,
							changeClicked: false,
						});
					}
				);
				this.props.setEditApproved(false);
			} catch (e) {
				console.log(e);
			}
		};

		onChangeProject = async (e) => {
			try {
				const projectDetails = this.props.ProjectConfigurationData.projectList.filter(
					(x) => x.ProjectID === e.value
				);
				const currProgID = TryGetNodeAlt(
					this.props.ProjectConfigurationData.selectedProjectDetails,
					null,
					'ClientProgramID'
				);
				let tPrevSelected = this.state.prevSelectedProject;
				const clientID = this.props.SharedData.selectedClient.value;
				const prog = this.props.ProgramConfigurationData.selectedProgramDetails;
				let historyPath = this.props.SharedData.historyPath;

				let userInfo = this.props.UserInformationData;

				let hasSubmitAccess = projectDetails[0].ProjectApprover === userInfo.emailAddress;
				historyPath = historyPath.length > 0 ? historyPath[0] : null;

				this.props.setHistoryPath({ url: window.location.pathname });

				this.props.clearAllProjectFields();
				this.props.setSelectedProjectdetails(projectDetails[0]);
				await this.props.setSelectedProject(e);
				await this.props.getProjectBOI(e.value);
				await this.props.getProjectAcctLeadershipDetails(e.value);

				// this.props.setStepperActiveIndex(projectDetails[0].StageId)
				await this.props.getProjectCADetails();
				await this.props.setStepperActiveIndex(
					projectDetails[0].StatusId === 6 &&
						!this.props.AccessValidationData.PnPComponents.project.stageTwoApprv
						? projectDetails[0].StageId + 1
						: projectDetails[0].StageId
				);
				this.onCloseOnChangeProgram(null, 'Project');
				this.props.showSelectProject(false);
				LogUserSiteAction({
					clientID,
					ClientProgramID: currProgID,
					ClientProjectID: e.value,
					projStatus: ACC_PROJ,
					progStatus: null,
					module: this.getModule(),
					ShouldLogPPCID: true,
				});
				let historyPathState = historyPath
					? historyPath.state
						? historyPath.state.projectStatus === 'approved'
						: false
					: false;
				if (
					IsValid(tPrevSelected.value) &&
					historyPath !== null &&
					!(
						historyPath.url.toLowerCase() ===
						'/programprojectconfig/projectaccountleadershipapproval/review' && historyPathState
					)
				) {
					LogPPCUserAction({
						clientID,
						ClientProgramID: prog.ClientProgramID,
						ClientProjectID: tPrevSelected.value,
						projStatus: ACC_PROJ,
						module: this.getModule(),
					});
				}
				const projectCADetails = this.props.ProjectConfigurationData;

				if (projectDetails[0].StatusId === 7) {
					if (
						hasSubmitAccess &&
						projectDetails[0].ProjectCreator !== userInfo.emailAddress &&
						projectDetails[0].CreatedUser.toLowerCase() !== 'system generated' &&
						((!projectCADetails.projectCADetails.isSubmit &&
							projectCADetails.projectCAFileDetails.length > 0) ||
							(projectCADetails.projectCADetails.isSubmit &&
								!projectCADetails.projectCAFileDetails.length > 0) ||
							(!projectCADetails.projectCADetails.isSubmit &&
								!projectCADetails.projectCAFileDetails.length > 0))
					) {
						this.props.history.push('/ProgramProjectConfig/ProjectClientAlignment/submit');
					} else {
						this.props.history.push('/ProgramProjectConfig/ProjectClientAlignment');
					}
				}
				await this.props.isProgramProjectFromDropdown(true);

				this.setState({
					prevSelectedProject: e,
				});

				this.props.setCmErrorMessage([]);
				this.props.resetErrorStates();
				if (this.props.selectedProjectStatus !== 'Approved') {
					this.props.checkForInvalidEmailFirst();
				}
			} catch (err) {
				console.log(err);
			}
		};

		disableClick = () => {
			try {
				this.setState({
					changeClicked: !this.state.changeClicked,
				});
			} catch (e) {
				console.log(e);
			}
		};

		render() {
			const { programList, selectedProgram, selectedProgramDetails, isProgramProjectFromDropdown } =
				this.props.ProgramConfigurationData;

			const {
				projectList,
				selectedProject,
				selectedProjectDetails,
				projectCADetails,
				projectCAFileDetails,
			} = this.props.ProjectConfigurationData;

			const { stepperActiveIndex } = this.props.SharedData;

			return (
				<ProgramProjectHeaderPresentational
					programList={programList
						.filter((x) => (this.props.type === 'Project' ? x.StatusId > 2 : x.StatusId))
						.map((x) => {
							return {
								value: x.ClientProgramID,
								label: x.ClientProgramName,
								text: x.ClientProgramName,
							};
						})}
					projectList={projectList.map((x) => {
						return { value: x.ProjectID, label: x.ClientProjectName, text: x.ClientProjectName };
					})}
					onClickClose={this.onClickClose}
					type={this.props.type}
					onCloseProgramProject={this.onCloseProgramProject}
					showProgramProject={this.state.showProgramProject}
					stepperActiveIndex={stepperActiveIndex}
					onChangeProgram={this.onChangeProgram}
					selectedProgram={selectedProgram}
					selectedProgramDetails={selectedProgramDetails}
					showOnChangeProgramProject={this.state.showOnChangeProgramProject}
					onCloseOnChangeProgram={this.onCloseOnChangeProgram}
					onSelectProgramProject={this.state.onSelectProgramProject}
					onChangeProject={this.onChangeProject}
					selectedProject={selectedProject}
					selectedProjectDetails={selectedProjectDetails}
					dropdownType={this.state.dropdownType}
					projectCADetails={projectCADetails}
					projectCAFileDetails={projectCAFileDetails}
					changeClicked={this.state.changeClicked}
					disableClick={this.disableClick}
					isProgramProjectFromDropdown={isProgramProjectFromDropdown}
				/>
			);
		}
	}

	return ProgramProjectHeaderContainer;
})();

export default connect(
	(state) => {
		return {
			SharedData: state.SharedData,
			UserInformationData: state.userInformation,
			ProgramConfigurationData: state.ProgramConfigurationData,
			ProjectConfigurationData: state.ProjectConfigurationData,
			AccessValidationData: state.AccessValidationData,
			clientID: state.SharedData.selectedClient.value,
			historyPath: state.SharedData.historyPath,
		};
	},
	(dispatch) =>
		bindActionCreators(
			Object.assign(
				{},
				{
					setEditApproved,
					setSelectedProgram,
					setStepperActiveIndex,
					setProgramDetails,
					clearAllProgramProjectFields,
					getBOIMappingList,
					getCustomBOIDropdownList,
					getProgramBOI,
					getProgramCADetails,
					getProgramAcctLeadershipDetails,
					setSelectedProject,
					setSelectedProjectdetails,
					clearAllProjectFields,
					getProjectProgramDetails,
					getProjectAcctLeadershipDetails,
					showSelectProject,
					getProjectBOI,
					getProjectCADetails,
					isProgramProjectFromDropdown,
					handleCheckAllOfferings,
					setHistoryPath,
					InitPnP,
					removeNewlyAddedStakeholders,
				}
			),
			dispatch
		)
)(withRouter(ProgramProjectHeader));
