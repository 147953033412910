import { ObjToStr } from './Formatter';
import { GetToken } from './UserSession';
import { HasValue, IsValid } from './Validations';
import store from '../../main/store';

export const HTTPUtil = (function () {
  let webHttps = window.webConfig.web;
  let apiHttps = window.webConfig.api;
  let subDirectory = window.webConfig.subDirectory;

  let getWebHttps = function () {
    return webHttps;
  };

  let getApiHttps = function () {
    return apiHttps;
  };
  let getSubDirectory = function () {
    return subDirectory;
  };

  return { getWebHttps, getApiHttps, getSubDirectory };
})();

export const GetApiGlobal = () => {
  return window.webConfig.apiGlobal;
};
export const GetWebGlobal = () => {
  return window.webConfig.webGlobal;
};
export const GetAuthObj = async () => {
  let token = await GetToken();
  let tempData = {
    apiHttps: HTTPUtil.getApiHttps(),
    accessToken: token
  }

  return tempData
}
export async function HttpGet(url, options) {
  let token = await GetToken();
  options = {
    ...options,
    method: 'GET',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
  };

  url = new URL(HTTPUtil.getApiHttps() + url);

  const response = await fetch(url, options);
  const jsonData = [200].indexOf(await response.status) > -1 ? response.json() : response;

  return jsonData;
}
export async function HttpPost(url, data, options) {
  let token = await GetToken();
  options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
    ...options,
  };

  url = new URL(HTTPUtil.getApiHttps() + url);

  const response = await fetch(url, options);
  const jsonData = [200].indexOf(await response.status) > -1 ? response.json() : response;
  return jsonData;
}

export async function HttpGetText(url, options) {
  let token = await GetToken();
  options = {
    ...options,
    method: 'GET',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
  };

  url = new URL(HTTPUtil.getApiHttps() + url);

  const response = await fetch(url, options);
  const textData = await response.text();

  return textData;
}

export async function HttpPostText(url, data, options) {
  let token = await GetToken();
  options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
    ...options,
  };

  url = new URL(HTTPUtil.getApiHttps() + url);

  const response = await fetch(url, options);
  const textData = await response.text();
  return textData;
}

export async function HttpPostWithFile(url, data, options) {
  let token = await GetToken();
  options = {
    method: 'POST',
    headers: {
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
    body: data,
    ...options,
  };

  url = new URL(HTTPUtil.getApiHttps() + url);
  try {
    const response = await fetch(url, options);
    const jsonData = [200].indexOf(await response.status) > -1 ? response.json() : response;

    return jsonData;
  } catch (e) { }
}

export async function HttpPostWithFileString(url, data, options) {
  let token = await GetToken();
  options = {
    method: 'POST',
    headers: {
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
    body: data,
    ...options,
  };

  url = new URL(HTTPUtil.getApiHttps() + url);
  try {
    const response = await fetch(url, options);
    const jsonData = await response.text();

    return jsonData;
  } catch (e) { }
}

export async function HttpGetDownloadFile(url, filename, callback, options) {
  let token = await GetToken();
  options = {
    ...options,
    method: 'GET',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
  };

  url = new URL(HTTPUtil.getApiHttps() + url);
  let responseData;
  await fetch(url, options)
    .then((response) => {
      responseData = response;
      if (response.status === 200) {
        return response.blob();
      }
      return;
    })
    .then((blob) => {
      let a = document.createElement('a');
      let isIE = !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let urlResponse = URL.createObjectURL(blob);
        a.href = urlResponse;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        if (HasValue(callback)) callback();
      }
    })
    .catch((error) => {
      console.error(error);
    });

  return responseData;
}

export async function HttpPostDownloadFile(url, data, filename, callback, options) {
  let token = await GetToken();
  options = {
    ...options,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  };

  url = new URL(HTTPUtil.getApiHttps() + url);

  await fetch(url, options)
    .then((response) => response.blob())
    .then((blob) => {
      let a = document.createElement('a');
      let isIE = !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let urlResponse = URL.createObjectURL(blob);
        a.href = urlResponse;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        if (HasValue(callback)) callback();
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function HttpGetDLFileWithProg(url, filename, callback, progressCB, options) {
  let token = await GetToken();
  options = {
    ...options,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
  };

  url = new URL(HTTPUtil.getApiHttps() + url);
  const response = await fetch(url, options);
  const contentLength = response.headers.get('content-length');
  const total = parseInt(contentLength, 10);
  let loaded = 0;

  if (response.status >= 400) return false;

  const res = new Response(
    new ReadableStream({
      async start(controller) {
        const reader = response.body.getReader();
        for (; ;) {
          const { done, value } = await reader.read();
          if (done) break;
          loaded += value.byteLength;
          if (HasValue(progressCB)) progressCB({ loaded: Math.ceil(loaded), total });
          controller.enqueue(value);
        }
        controller.close();
      },
    })
  );
  const blob = await res.blob();

  let a = document.createElement('a');
  let isIE = !!document.documentMode;
  if (isIE) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let urlResponse = URL.createObjectURL(blob);
    a.href = urlResponse;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    if (HasValue(callback)) callback();
  }
}

export async function HttpGetDirectDownload(url, callback, progressCB, options) {
  let token = await GetToken();
  options = {
    ...options,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
  };

  url = new URL(HTTPUtil.getApiHttps() + url);
  const response = await fetch(url, options);
  const contentLength = response.headers.get('content-length');
  const total = parseInt(contentLength, 10);
  let loaded = 0;

  if (response.status >= 400) return false;

  const res = new Response(
    new ReadableStream({
      async start(controller) {
        const reader = response.body.getReader();
        for (; ;) {
          const { done, value } = await reader.read();
          if (done) break;
          loaded += value.byteLength;
          if (HasValue(progressCB)) progressCB({ loaded: Math.ceil(loaded), total });
          controller.enqueue(value);
        }
        controller.close();
      },
    })
  );
  const blob = await res.blob();

  let name = response.headers.get('Content-Disposition');
  name = decodeURI(name.split('filename')[1].replace('=', '').replace('"', '').replace('"', ''));

  let a = document.createElement('a');
  let isIE = !!document.documentMode;
  if (isIE) {
    window.navigator.msSaveBlob(blob, name);
  } else {
    let downloadUrl = URL.createObjectURL(blob);
    a.href = downloadUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    if (HasValue(callback)) callback();
  }
}

export async function HttpPostTextCusURL(url, data, options) {
  let token = await GetToken();
  options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control':'no-store',
      pragma: 'no-cache',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
    ...options,
  };

  url = new URL(url);

  const response = await fetch(url, options);
  const textData = await response.text();
  return textData;
}
export const SendBacon = async (url, data) => {
  let token = await GetToken();
  data['Token'] = token;
  let form = new FormData();
  form.append('param', ObjToStr(data));

  url = new URL(HTTPUtil.getApiHttps() + url)
  navigator.sendBeacon(url, form);
}

export function GetSubMenuId(subMenuName) {
  let subMenuList = store.getState().SharedData.menus.subMenu;
  let id = subMenuList.find((e) => e.subMenu === subMenuName).subMenuID;
  id = IsValid(id) ? id : 0;
  return id;
}