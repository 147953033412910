import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
// import { history } from '../../../store';
import { withRouter } from 'react-router-dom';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import Popover from 'react-bootstrap/Popover';
// import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ACC_PROG, PROJ_DETAILS_PAGE } from '../../../Constants/Modules';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import CVMSelectDropdown from './../../Shared/CVMSelectDropdown/CVMSelectDropdown';
import CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter from './../../Shared/CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter/CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter';
import CVMToasterNotif from '../../Shared/CVMToasterNotif/CVMToasterNotif';
import { FormDateDDMMMYYY } from '../../../Utilities/Formatter';
import ReactTooltip from 'react-tooltip';
//icons

import clientDetailIcon from './../../../Static/images/newClientConfig/clientDetailIcon.svg';
import listViewIcon from './../../../Static/images/newClientConfig/listViewIcon.svg';
import cardViewIcon from './../../../Static/images/newClientConfig/cardViewIcon.svg';
import AddFileIcon from './../../../Static/images/360cvm/AddFilesIcon.svg';
import sortIcon from './../../../Static/images/360cvm/sort_icon.svg';
// import radioIcon from './../../../Static/images/svg/Pending.svg';
// import radioSelectedIcon from './../../../Static/images/svg/Progress.svg';
import fileAlt from './../../../Static/images/360cvm/fileAlt.svg';
import CopyIcon from './../../../Static/images/copy-Icon.svg';
import {
  setSelectedProgram,
  setProgramDetails,
  fetchAddNewProgramDetails
} from '../../../Actions/ProgramConfiguration.Action';

import { setPath } from '../../../Actions/Home.Action';

import {
  getProjectBOI,
  getProjectProgramDetails,
  getsortedProjectList,
  searchProjectList,
  getLandingProjectsList,
  setSelectedProjectdetails,
  getProgramBOIList,
  onChangeReportedYear,
  setSelectedProject,
  clearAllProjectFields,
  getAssetNameAndDeliveredByList,
  getProjectCADetails,
  getBusinessAdvisorListByClientID
} from '../../../Actions/ProjectConfiguration.Action';

import { setStepperActiveIndex, setHistoryPath,isProfileExisting } from '../../../Actions/Shared.Action';
import { LogUserSiteAction } from '../../../Utilities/AccessManagementUtil';
import { IsValid, IsValidStr, HasProperty, IsObjEmpty } from '../../../Utilities/Validations';

import { InitPnP } from '../../../Actions/AccessValidation.Action';

const ProjectMain = (function () {
  class ProjectMainPresentational extends Component {
    render() {
      const toBeAlignedProjects = this.props.toBeAlignedProjects();
      const statusId = this.props.selectedProgramDetails.StatusId;
      const projectList = this.props.projectList;
      const oalList =
        IsValidStr(this.props.selectedProgramDetails.OperationsAccountLead) &&
        this.props.selectedProgramDetails.OperationsAccountLead.split(',').map((oal, i) => {
          return <div className="ellipsis-hover">{`${oal}\n`}</div>;
        });
      const cxlList =
        IsValidStr(this.props.selectedProgramDetails.CXLLead) &&
        this.props.selectedProgramDetails.CXLLead.split(',').map((cxl, i) => {
          return <div className="ellipsis-hover">{`${cxl}\n`}</div>;
        });
       const {
        currentUserRoleID,
        isSuperAdmin,
        userProfiles
       }=this.props.UserInformationData
       let isExistingPPC = userProfiles.some(
        (x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === this.props.clientID
      );
      let isExistingBA = userProfiles.some(
        (x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === this.props.clientID
      );
      let isPPC =
      currentUserRoleID === 12 ||
        (currentUserRoleID === 16 &&isExistingPPC);
      let isBA =
        currentUserRoleID === 15 ||
        (currentUserRoleID === 16 &&isExistingBA);

        let uProf = this.props.UserInformationData.userProfiles;
        let clientID = this.props.clientID;
        let isPPCBA = uProf.some((x) =>[12, 15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID)


      const clientDetailsPopover = (
        <Popover id="clientDetails-popover" trigger="focus">
          <Popover.Title>
            <span className="close-btn" onClick={() => document.body.click()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </Popover.Title>
          <Popover.Content>
            <div>
              <div className="detail-popup-section">
                <div className="section-title">Program Name</div>
                <div className="section-desc">
                  <div>{this.props.selectedProgramDetails.ClientProgramName}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="detail-popup-section">
                <div className="section-title">Offering</div>
                <div className="section-desc">
                  <div>{this.props.selectedProgramDetails.Offering}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="detail-popup-section">
                <div className="section-title">Sub-Offering</div>
                {Object.keys(this.props.selectedProgramDetails).length !== 0
                  ? this.props.selectedProgramDetails.SubOfferingList.map(x => {
                    return <div className="section-desc">- {x.SubOfferingName}</div>;
                  })
                  : ''}
              </div>
            </div>
            <div>
              <div className="sub-section">
                <div className="detail-popup-section-oal">
                  <div className="section-title">Ops Account Lead</div>
                  <div className="section-desc">
                    {oalList}
                  </div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Program Creator</div>
                  <div className="section-desc">
                    {this.props.selectedProgramDetails.ProgramCreator}
                  </div>
                </div>
              </div>
              <div className="sub-section">
                <div className="detail-popup-section-cxl">
                  <div className="section-title">CX Lead</div>
                  <div className="section-desc">{cxlList}</div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Stage</div>
                  <div className="section-desc">
                    {this.props.selectedProgramDetails.IsEdited
                      ? 'Edited Project: ' + this.props.selectedProgramDetails.Stage
                      : this.props.selectedProgramDetails.Stage}
                  </div>
                  <div
                    id="program-details-status"
                    className={
                      statusId === 1
                        ? 'card-row-status status-notStarted'
                        : statusId === 2 || statusId === 9
                          ? 'card-row-status status-notStarted'
                          : statusId === 3
                            ? 'card-row-status status-inProgress'
                            : statusId === 4
                              ? 'card-row-status status-rejected'
                              : statusId === 5
                                ? 'card-row-status status-inProgress'
                                : statusId === 6
                                  ? 'card-row-status status-completed'
                                  : statusId === 7
                                    ? 'card-row-status status-completed'
                                    : statusId === 8 && 'card-row-status status-rejected'
                    }
                  >
                    {this.props.selectedProgramDetails.Status}
                  </div>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );
      const projectListSorting = (
        <Popover id="projectListSorting-popover" trigger="focus">
          <Popover.Content>
            <div className="custom-sortmenulist">
              <div onClick={() => this.props.selectSort(0)} className={this.props.sortSelected === 0 ? "activesort-item" : " "}>
                System Generated Projects
              </div>
              <div onClick={() => this.props.selectSort(1)} className={this.props.sortSelected === 1 ? "activesort-item" : " "}>
                User Generated Projects
              </div>
              <div onClick={() => this.props.selectSort(2)} className={this.props.sortSelected === 2 ? "activesort-item" : " "}>
                Oldest to Newest
              </div>
              <div onClick={() => this.props.selectSort(3)} className={this.props.sortSelected === 3 ? "activesort-item" : " "}>
                Newest to Oldest
              </div>
              <div onClick={() => this.props.selectSort(4)} className={this.props.sortSelected === 4 ? "activesort-item" : " "}>
                A to Z
              </div>
              <div onClick={() => this.props.selectSort(5)} className={this.props.sortSelected === 5 ? "activesort-item" : " "}>
                Z to A
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );

      return (
        !this.props.PnPComponents.project.add && !this.props.enableAddBtn ? 
          <div class="lds-ellipsis">
             <div></div>
             <div></div>
             <div></div>
             <div></div>
           </div> :
        <React.Fragment>
          <CVMToasterNotif />
          <div>
            <div class="project-title">
              {this.props.isDisplayMessage && toBeAlignedProjects.length !== 0 && (
                <div
                  className="no-boidefinition-warning"
                  style={{
                    position: 'relative',
                    marginBottom: '10px',
                    height: 'unset',
                    wordBreak: 'break-all'
                  }}
                >
                  <GrooveIconSvg
                    customClassName="accordion-button"
                    size="small"
                    name="exclamation-triangle"
                    iconStyle="solid"
                    iconColor="stat-warning"
                  />
                  <div className="no-boidefinition-text">
                    Client Alignment is pending for {toBeAlignedProjects.join(', ')}.
                  </div>
                  <GrooveIconSvg
                    customClassName="accordion-button"
                    size="small"
                    name="times"
                    iconStyle="solid"
                    iconColor="stat-warning"
                    callbackFunction={this.props.onCloseMessage}
                  />
                </div>
              )}
              <div class="title-section">
                <h4 class="header24-semibold-midnight">Program & Project Configuration</h4>
                <div class="notify-toast"></div>
              </div>
            </div>
            <div className="program-dropdown-project-landing">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="back-icon"
                onClick={this.props.onClickBackBtn}
              />
              <CVMSelectDropdown
                label="Program : "
                placeholder={''}
                onChange={this.props.onChangeProgram}
                style={{
                  control: { width: '500px' },
                  menu: { width: '' },
                  mainContainer: {
                    width: '618px',
                    margin: '0 0 0 18px',
                    float: 'right'
                  }
                }}
                options={this.props.programList
                  .filter(x => x.StageId > 1)
                  .map(item => {
                    return {
                      value: item.ClientProgramID,
                      label: item.ClientProgramName
                    };
                  })}
                value={this.props.selectedProgram}
                isSearchable={true}
                closeMenuOnSelect={false}
              />
              {/* <img src={InfoIcon} alt="info" className="info-icon" /> */}
            </div>
            <div className="client-details-button">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="right-start"
                overlay={clientDetailsPopover}
              >
                <div>
                  <img src={clientDetailIcon} alt="clientDetailIcon" />
                  Program Details
                </div>
              </OverlayTrigger>
            </div>
            <div className='client-details-button rvd-client-details-btn column-search-container'>
              <div className="search-bar label14-regular-midnight">
                <GrooveIconSvg
                  customClassName="close-modal-btn"
                  size="large"
                  name="search"
                  iconStyle="solid"
                  iconColor="stat-neutral"
                />
                <input
                  type="text"
                  name="name"
                  placeholder="Search Project Name"
                  autocomplete="off"
                  id="searchBox"
                  maxlength="255"
                  onChange={(e) => {
                    this.props.searchFunc(e);
                  }}
                  value={this.props.searchText}
                />
              </div>
              <div className='project-filter-align'>
                <div className="statusDropdown">
                  <CVMAutoMultiSelectDropdownWithCheckBoxAndPasteFilter
                    label="Reporting Year:"
                    placeholder={''}
                    isMulti
                    onChange={(e) => {
                      this.props.onChangeReportedYear(e);
                      this.props.getLandingProjectsList();
                    }}
                    options={this.props.options}
                    value={this.props.selectedReportedYear}
                    clearsearchText={() => this.props.searchFilterEmpty()}
                  />
                </div>
                {this.props.PnPComponents.project.add ? (
                  ''
                ) : (
                  <GrooveButton
                    id="primry-btn-2"
                    hasIcon={true}
                    type="solid"
                    colorClass="stat-alternate"
                    text="Add New Project"
                    callbackFunction={() => {
                      // this.props.clearAllProgramProjectFields();
                      this.props.clearAllProjectFields();
                      this.props.setStepperActiveIndex(1);
                      // this.props.filterIOApprover(0);
                      this.props.history.push('/ProgramProjectConfig/projectidvaluesettings');
                    }}
                    customClassName="add-project-button"
                    iconAlignment="right"
                    iconSize="small"
                    iconName="plus"
                    iconStyle="solid"
                    iconId="btn-icon-01"
                    isDisabled={!this.props.enableAddBtn}
                  />
                )}
                <div className="sort-wrapper">
                  <OverlayTrigger
                    rootClose
                    placement="bottom-start"
                    trigger="click"
                    overlay={projectListSorting}
                  >
                    <img
                      src={sortIcon}
                      className="sort-icon"
                      alt="sortIcon"
                      onClick={this.props.showSortMenu}
                    />
                  </OverlayTrigger>
                </div>
                <div className="landing-img-box">
                  <img src={cardViewIcon} alt="cardViewIcon" />
                  <img src={listViewIcon} className="disabled-view" alt="listViewIcon" />
                </div>
              </div>
            </div>
            <>
              <div className="no-result-msg">
                {projectList.length === 0 ? (
                  <div className='empty-project-list'>{this.props.noResultMsg}</div>
                ) : (
                  ''
                )}
              </div>
              {this.props.noResultMsg==""&&<div className="add-program-line project-config-select">
                <div className="bodytext16-medium-midnight">Projects ({projectList.length})</div>
              </div>}
              {/* No projects added then display below block */}
              {((projectList.length || 0) === 0 && this.props.noResultMsg=="")? (
                <div className="applyFlex-justify-center">
                  <div className="select-client-container">
                    <img src={AddFileIcon} />
                    <p>No Project Added</p>
                    <span>
                      Please add project(s) to this program by using the ‘Add New Project’ button
                      above.
                    </span>
                  </div>
                </div>
              ) : (
                <div className="program-card-container">
                  <Row>
                    {projectList
                      .filter(
                        x => x.ClientProgramID === this.props.selectedProgramDetails.ClientProgramID
                      )
                      .map((e, idx) => {
                        let deliveryLocation = '';
                        if (IsValid(e.DeliveryCenterList)) {
                          deliveryLocation = e.DeliveryCenterList.length > 0 ? e.DeliveryCenterList[0].deliveryCenterName : '';
                        }

                        let creationDate = new Date(e.CreatedDate);
                        const rejectedGen = String(e.ProjectCreator).toLowerCase() === 'system generated' &&
                          String(e.SystemGeneratedProjectStatus).toLowerCase() === 'rejected';
                        return (
                          <Col sm={12} md={6} lg={3}>
                            <div className="program-card-box">
                              <div
                                className={`project-card-wrap ${rejectedGen ? 'project-disabled' : ''}`}
                                onClick={() => {
                                  if (!rejectedGen) this.props.onClickProject(e)
                                }}
                              >
                                <div className="project-card-title">
                                  {/* removed cardH classname */}
                                  <img src={fileAlt} alt="file" />
                                  <div
                                    className="titleText-medium-14 card-title rdv-label ppc-text-ellipsis"
                                    title={e.ClientProjectName}
                                  >
                                    {e.ClientProjectName}
                                  </div>
                                  {String(e.ProjectCreator).toLowerCase() === 'system generated' && (
                                    <span className="generated-project-label">System generated</span>
                                  )}
                                </div>
                                <div className="program-card-detail">
                                  <div className="titleText-medium-12 text-tile">Delivery Location:</div>
                                  <div className="titleText-regular-12">
                                    {deliveryLocation}
                                  </div>
                                </div>
                                <div className="program-card-detail">
                                  <div className="titleText-medium-12 text-tile">Creation Date:</div>
                                  <div className="titleText-regular-12" title={FormDateDDMMMYYY(creationDate, true)}>
                                    {FormDateDDMMMYYY(creationDate, true)}
                                  </div>
                                </div>
                                <div className="program-card-detail text-gap">
                                  <div className="titleText-medium-12 text-tile">
                                    Modified By:
                                  </div>
                                  <div
                                    className="titleText-regular-12"
                                    title={
                                      e.UpdatedUser !== null ? e.UpdatedUser.split('@')[0] : '-'
                                    }
                                  >
                                    {e.UpdatedUser !== null ? e.UpdatedUser.split('@')[0] : '-'}
                                  </div>
                                </div>
                                {(isPPC||isBA||isSuperAdmin||isPPCBA)&&(String(e.ProjectCreator).toLowerCase() != 'system generated' )&&                               
                               <div>
                               <a data-tip data-for="copy-icon">
                                <img 
                                style={{position: "absolute",
                                        bottom: "6px",
                                        right: "8px"
                                 }} 
                                src={CopyIcon} 
                                onClick={()=>sessionStorage.setItem("copy",true)}  />
                                </a>
                                <ReactTooltip id="copy-icon" place='left-start' type="dark" >
                                <span>
                                Create duplicate project.
                                </span>
                              </ReactTooltip>
                              </div>
                                }
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              )}
            </>
          </div>
        </React.Fragment>
      );
    }
  }

  class ProjectMainContainer extends Component {
    constructor() {
      super();
      this.state = {
        isDisplayMessage: true,
        sortMenu: false,
        sortSelected: 0,
        noResultMsg: "",
        searchText: "",
        enableAddBtn: false
      };
    }

    componentDidMount = async () => {
      try {
        LogUserSiteAction({
          module: PROJ_DETAILS_PAGE,
          clientID: this.props.clientID,
          ShouldLogPPCID: true,
          ClientProgramID: this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
          ClientProjectID: null,
          projStatus: null
        });
        await this.props.getProjectProgramDetails(
          this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        );
        await this.props.getProgramBOIList(
          this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        );
        await this.props.getAssetNameAndDeliveredByList();
        this.setState({
          enableAddBtn: true
        })
        this.selectSort(this.state.sortSelected)
      } catch (err) {
        console.log(err);
      }
    };

    componentDidUpdate(prevProps) {
      if (this.props.clientID !== prevProps.clientID && IsValid(prevProps.clientID))
        this.onClickBackBtn();

    }

    onClickBackBtn = () => {
      this.props.setPath('/ProgramProjectConfig');
      this.props.history.push('/ProgramProjectConfig');
    };

    onChangeProgram = async (e) => {
      try {
        const programDetails = this.props.ProgramConfigurationData.programList.filter(
          x => x.ClientProgramID === e.value
        );

        this.setState({
          searchText: "",
          sortSelected: 0,
        });

        const progDetails = programDetails[0]
        this.props.getProgramBOIList(e.value);
        await this.props.getProjectProgramDetails(e.value)
        this.props.setSelectedProgram(e);
        this.props.setProgramDetails(programDetails[0], "Project Details Page");
        LogUserSiteAction({
          module: PROJ_DETAILS_PAGE,
          clientID: progDetails.ClientMasterID,
          ClientProgramID: progDetails.ClientProgramID,
          ClientProjectID: null,
          progStatus: ACC_PROG,
          projStatus: null,
          ShouldLogPPCID: true,
        })
        this.selectSort(this.state.sortSelected);
      } catch (e) {
        console.log(e);
      }
    };

    onClickProject = async (e) => {
      try {
        let projectLeadUrl = '';
        let OALUrl = '';
        projectLeadUrl = '/ProgramProjectConfig/ProjectIDValueSettings';
        OALUrl = '/ProgramProjectConfig/ProjectIDValueSettings';

        this.props.setHistoryPath({ url: window.location.pathname })

        // Set program
        let tempSelProj = e;
        const client = HasProperty(this.props.SharedData.selectedClient, 'value')
          ? this.props.SharedData.selectedClient.value : undefined;
        const clientProgramID = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
        if (IsValid(client) && IsValid(clientProgramID)) {
          await this.props.fetchAddNewProgramDetails(client);
          await this.props.getBusinessAdvisorListByClientID();
          const projList = await this.props.getProjectProgramDetails(clientProgramID);
          if (IsValid(projList.ProjectList)) {
            let tempSelProjArr = projList.ProjectList.filter((proj) => { return proj.ProjectID === e.ProjectID })
            if (IsValid(tempSelProjArr) && tempSelProjArr.length > 0) {
              tempSelProj = tempSelProjArr[0];
            }
          }
        }

        // Set project
        this.props.getProjectBOI(e.ProjectID);
        if (!IsObjEmpty(tempSelProj)) {
          this.props.setSelectedProjectdetails(tempSelProj, "Project Details Page");
        } else {
          this.props.setSelectedProjectdetails(e, "Project Details Page");
        }
        this.props.setSelectedProject({
          value: e.ProjectID,
          label: e.ClientProjectName,
          text: e.ClientProjectName
        });
        this.props.InitPnP();
        this.props.setStepperActiveIndex(
          1
        );

        let userisCreatedUser = false;
        // if super admin cant review/approve/reject projects he/she created.
        if (this.props.UserInformationData.enterpriseID === e.CreatedUser) {
          userisCreatedUser = true;
        }

        if (e.StatusId < 6) {
          // if(this.props.AccessValidationData.PnPComponents.project.stageTwoApprv || userisCreatedUser){ //if user has approve/reject asscess level it will return false, hence the condition
          setTimeout(() => {
            if (this.props.AccessValidationData.PnPComponents.project.stageTwoApprv) {
              this.props.history.push(projectLeadUrl);
            } else {
              this.props.history.push(OALUrl);
            }
          }, 250);
        } else {
          // if(this.props.AccessValidationData.PnPComponents.project.stageThreeAcknwldg || userisCreatedUser){ //if user has acknowledge asscess level it will return false, hence the condition
          if (this.props.AccessValidationData.PnPComponents.project.stageThreeAcknwldg) {
            this.props.history.push(projectLeadUrl);
          } else {
            this.props.history.push(OALUrl);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    onCloseMessage = () => {
      this.setState({
        isDisplayMessage: false
      });
    };

    toBeAlignedProjects = () => {
      const { projectList } = this.props.ProjectConfigurationData;
      const { selectedProgramDetails } = this.props.ProgramConfigurationData;
      let projects = [];
      try {
        projectList
          .filter(x => x.ClientProgramID === selectedProgramDetails.ClientProgramID)
          .map(e => {
            if (e.StatusId === 8) projects.push(e.ClientProjectName);
          });
      } catch (err) { }
      return projects;
    };

    showSortMenu = () => {
      this.setState({
        sortMenu: !this.state.sortMenu
      });
    };

    selectSort = opt => {
      this.setState({
        sortSelected: opt,
        searchText: '',
      },
        async () => {
          await this.props.getsortedProjectList();
          this.sortProgramProjectList();
        },
      );

    };
    sortProgramProjectList() {
      let projectList = this.props.ProjectConfigurationData.projectList;
      let newProjectList = [];
      if (IsValid(projectList)) {
        if (this.state.sortSelected === 0 || this.state.sortSelected === 1) {
          let userGenerated = projectList.filter(
            (x) => String(x.CreatedUser).toLowerCase() !== 'system generated'
          );
          let systemGenerated = projectList.filter(
            (x) => String(x.CreatedUser).toLowerCase() === 'system generated'
          );
          if (this.state.sortSelected === 0) {
            newProjectList = [...systemGenerated, ...userGenerated];
          }
          else if (this.state.sortSelected === 1) {
            newProjectList = [...userGenerated, ...systemGenerated];
          }

        }
        else if (this.state.sortSelected === 2) {
          let newestfilter = projectList.sort((a, b) => (a.CreatedDate > b.CreatedDate ? 1 : -1))
          newProjectList = [...newestfilter];
        }
        else if (this.state.sortSelected === 3) {
          let newestfilter = projectList.sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1))
          newProjectList = [...newestfilter];
        }
        else if (this.state.sortSelected === 4) {
          let newestfilter = projectList.sort(function (a, b) { return a.ClientProjectName.localeCompare(b.ClientProjectName) })
          newProjectList = [...newestfilter];
        }
        else if (this.state.sortSelected === 5) {
          let newestfilter = projectList.sort(function (a, b) { return b.ClientProjectName.localeCompare(a.ClientProjectName) })
          newProjectList = [...newestfilter];
        }
        this.props.getsortedProjectList(newProjectList);

      }
    }
    onSearchFilterEmpty = () => {
      this.setState({
        searchText: ""
      });
    }
    onSearchTextChanged = (e) => {
      let filteredData = [];
      this.props.searchProjectList(e.target.value);
      if (filteredData.length == 0) {
        this.setState({
          noResultMsg: "No results found"
        })
      } else {
        this.setState({
          noResultMsg: " "
        })
      }
      if (IsValidStr(e.target.value)) {
        this.setState({
          searchText: e.target.value
        });
      } else {
        this.setState({
          searchText: ""
        });
      }
    };

    render() {
      if (!IsValid(this.props.clientID))
        this.props.history.push('/ProgramProjectConfig/programidvaluesettings');
      const {
        programList,
        selectedProgram,
        selectedProgramDetails
      } = this.props.ProgramConfigurationData;

      const {
        projectList,
        reportedYearsList,
        selectedReportedYear
      } = this.props.ProjectConfigurationData;

      const {
        getProgramList,
        setProgramDetails,
        getProjectProgramDetails,
        searchProjectList,
        getsortedProjectList,
        getLandingProjectsList,
        onChangeReportedYear,
        clearAllProjectFields,
        setStepperActiveIndex,
        isProfileExisting
      } = this.props;
      const { PnPComponents } = this.props.AccessValidationData;

      return (
        <ProjectMainPresentational
          options={reportedYearsList}
          onClickBackBtn={this.onClickBackBtn}
          onChangeProgram={this.onChangeProgram}
          onClickProject={this.onClickProject}
          programList={programList}
          selectedProgram={selectedProgram}
          UserInformationData={this.props.UserInformationData}
          selectedProgramDetails={selectedProgramDetails}
          getProgramList={getProgramList}
          isProfileExisting={isProfileExisting}
          setProgramDetails={setProgramDetails}
          getProjectProgramDetails={getProjectProgramDetails}
          getsortedProjectList={getsortedProjectList}
          projectList={projectList.filter(x => this.props.ProjectConfigurationData.selectedReportedYear.some(y => x.ReportedYear == y.value))}
          searchProjectList={searchProjectList}
          onChangeReportedYear={onChangeReportedYear}
          clearAllProjectFields={clearAllProjectFields}
          setStepperActiveIndex={setStepperActiveIndex}
          selectedReportedYear={selectedReportedYear}
          PnPComponents={PnPComponents}
          toBeAlignedProjects={this.toBeAlignedProjects}
          onCloseMessage={this.onCloseMessage}
          isDisplayMessage={this.state.isDisplayMessage}
          showSortMenu={this.showSortMenu}
          selectSort={this.selectSort}
          history={this.props.history}
          {...this.state}
          getLandingProjectsList={getLandingProjectsList}
          searchFunc={this.onSearchTextChanged}
          searchText={this.state.searchText}
          noResultMsg={this.state.noResultMsg}
          searchFilterEmpty={this.onSearchFilterEmpty}
        />
      );
    }
  }

  return ProjectMainContainer;
})();

export default connect(
  state => {
    return {
      SharedData: state.SharedData,
      clientID: state.SharedData.selectedClient.value,
      UserInformationData: state.userInformation,
      HomeData: state.HomeData,
      ProgramConfigurationData: state.ProgramConfigurationData,
      ProjectConfigurationData: state.ProjectConfigurationData,
      AccessValidationData: state.AccessValidationData
    };
  },
  dispatch =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setSelectedProgram,
          setProgramDetails,
          setPath,
          getProjectProgramDetails,
          searchProjectList,
          getsortedProjectList,
          getLandingProjectsList,
          setSelectedProjectdetails,
          getProgramBOIList,
          getProjectBOI,
          onChangeReportedYear,
          setSelectedProject,
          clearAllProjectFields,
          setStepperActiveIndex,
          InitPnP,
          setHistoryPath,
          getAssetNameAndDeliveredByList,
          getProjectCADetails,
          fetchAddNewProgramDetails,
          getBusinessAdvisorListByClientID,
          isProfileExisting
        }
      ),
      dispatch
    )
)(withRouter(ProjectMain));
