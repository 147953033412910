import React, { Component } from "react";
import { GrooveInput } from 'tfo-groove';
import { GrooveSelectMenuBasic, GrooveDateCalendarPicker, GrooveTextBox, GrooveIconSvg } from 'tfo-groove';
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setNewProjectDetails } from "../../../Actions/ProjectConfiguration.Action";
import ReactTooltip from 'react-tooltip';
// Component Import
import CVMMultiSelectDropdownWithCheckBox from "../../Shared/CVMMultiSelectDropdownWithCheckBox/CVMMultiSelectDropdownWithCheckBox";
import CVMMultiSelectDropdownWithCheckBoxForBA from "../../Shared/CVMMultiSelectDropdownWithCheckBoxForBA/CVMMultiSelectDropdownWithCheckBoxForBA";
import CVMInput from '../../Shared/CVMInput/CVMInput';
import CVMMultiSelectDropdownWithAddButton from "../../Shared/CVMMultiSelectDropdownWithAddButton/CVMMultiSelectDropdownWithAddButton";
// Utilities Imports
import { IsValid, IsObjEmpty, IsValidStr, IsArrayEmpty, HasValue, IsValidText, IsValidAccentureEmail } from '../../../Utilities/Validations';
import { formatMultiLabel, parseField } from '../../../Utilities/Formatter';
import { GetSubMenuId, HttpGet, HttpPost } from "../../../Utilities/HTTPUtil";
// CSS imports
import "../../Shared/CVMMultiSelectDropdownWithCheckBox/CVMMultiSelectDropdownWithCheckBox.css";
import "../../Shared/CVMMultiSelectDropdownWithCheckBoxForBA/CVMMultiSelectDropdownWithCheckBoxForBA.css";
import { isProfileExisting } from '../../../Actions/Shared.Action';
import store from "../../../../main/store";

const AddNewProjectPopup = (function () {
  class AddNewProjectPopupPresentational extends Component {
    render() {
    var isemptycreator= Object.keys(existingProject && !isPPCEmpty ? setProjectCreator : this.props.newProjectDetails.projectcreator).length === 0;
      const projCreatorList = this.props.dDownList.projectCreatorList.filter(e => String(e.text).toLowerCase() !== String(this.props.newProjectDetails.projectapprover.text).toLowerCase())
      let copy=sessionStorage.getItem("copy");

      const existingProject = Object.keys(this.props.selectedProjectDetails).length > 0 ? true : false
      const isProjectApprover = existingProject ? this.props.selectedProjectDetails.ProjectApprover === this.props.newProjectDetails.projectcreator.label ? true : false : false
      const setProjectCreator = existingProject ? IsValid(projCreatorList) ? projCreatorList.find(x => x.label === this.props.selectedProjectDetails.ProjectCreator) : "" : ""
      // const setProjectApprover = existingProject ? IsValid(projCreatorList) ? projCreatorList.find(x => x.label === this.props.selectedProjectDetails.ProjectApprover) : "" : ""
      console.log(projCreatorList)
      let projectNamePlaceholder = this.props.newProjectDetails.projectName.value === "" ? "Enter Project Name" : "Project Name";
      let projectDescPlaceholder = this.props.newProjectDetails.projectDescription === "" ? "Enter Project Description" : "Project Description";
      let I0projectApproverPlaceholder = (Object.keys(this.props.newProjectDetails.ioapprover).length === 0) ? "Select Intelligent  Operations Approver" : "Intelligent Operations Approver"
      let ProjectCreatorpPlaceholder = (Object.keys(this.props.newProjectDetails.projectcreator).length === 0) ? "Select Project Creator" : "Project Creator"
      let DeliveredByPlaceholder = (!IsValid(this.props.newProjectDetails.deliveredBy) || Object.keys(this.props.newProjectDetails.deliveredBy).length === 0) ? "Select Delivered By" : "Delivered By"
      let OMIDPlaceholder = !IsValidStr(this.props.newProjectDetails.OMID) ? "Enter OMID/Corebeat ID" : "OMID/Corebeat ID";
      let contractIDPlaceholder = !IsValidStr(this.props.newProjectDetails.contractID) ? "Enter Contract Number" : "Contract Number";
      let serviceLinePlaceholder = this.props.newProjectDetails.serviceLine;
      let isSuperAdmin = this.props.userInformation.isSuperAdmin;
      let isPPCBA = this.props.isPPCBA;
      let tempDeliveryLocation = [];
      let newProjDelivLoc = this.props.newProjectDetails.deliveryLocation;
      const newProjectDetails = this.props.newProjectDetails;
      let isProjectApprover2 = (newProjectDetails.oaLead.some((a) => a.text === this.props.userInformation.emailAddress) || //oal
        newProjectDetails.cxLead.some((a) => a.text === this.props.userInformation.emailAddress) || //cxl
        newProjectDetails.ioapprover.text === this.props.userInformation.emailAddress) && //io
        !this.props.userInformation.isSuperAdmin;
      let isPPCEmpty = IsValid(this.props.newProjectDetails.projectcreator);
      let enabledUpdatePPC = (!IsValid(this.props.selectedProjectDetails.ProjectCreator) && isProjectApprover2) || this.props.userInformation.isSuperAdmin;
      if (!Array.isArray(newProjDelivLoc)) {
        if (IsValid(newProjDelivLoc)) {
          tempDeliveryLocation.push(newProjDelivLoc)
        }
      } else {
        newProjDelivLoc.map(x => {
          if (IsValid(x.value)) {
            tempDeliveryLocation.push({ label: x.label, text: x.text, value: x.value, isFixed: x.isFixed })
          }
        });
      }

      const generatedTalentInc = this.props.isSystemGenerated && String(this.props.newProjectDetails.projectName.value).toLowerCase() !== 'global bois project';
      const sameProjectName = Object.keys(this.props.selectedProjectDetails).length > 0 ?
        this.props.selectedProjectDetails.ClientProjectName === this.props.newProjectDetails.projectName.value ? true : false : false

      let errorMessage = [];
      this.props.cmErrorMessage.forEach((f) => {
        errorMessage.push(
          <>
            {f.value}
            <br />
          </>
        );
      });

      return (
        <React.Fragment>
          <div className="add-program-details add-new-project-popup">
            <Row>
              <Col lg={6} md={6} sm={12}>
                <CVMInput
                  customClassName={`selected-menu-basic ${this.props.newProjectDetails.projectName.isError ? 'invalid-project-name-field' : ''}`}
                  type="text"
                  id="projectName"
                  placeholder={projectNamePlaceholder}
                  inputValue={copy == "true" && this.props.newProjectDetails.projectName.value === this.props.selectedProjectDetails.ClientProjectName? "" : this.props.newProjectDetails.projectName.value}
                  callbackFunction={(e) => this.props.onUpdateField(e.target, "projectName")}
                  isDisabled={(isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")) ? true : this.props.readOnly}
                  maxLength={250}
                  isRequired={true}
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className={this.props.readOnly || isProjectApprover ? "Multi-Select-BT disabled-mulitSelect" : "Multi-Select-BT with-label"}>
                  {
                    this.props.isSystemGenerated ?
                      <CVMMultiSelectDropdownWithCheckBox
                        id="suboffering"
                        placeholder={"Select Sub Offering"}
                        options={this.props.dDownList.subofferingList}
                        value={this.props.newProjectDetails.suboffering}
                        isDisabled={this.props.readOnly || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                        label={"Sub Offering"}
                        onChange={(e) => this.props.onUpdateField(e, "suboffering")}
                        isRequired={true}
                      />
                      :
                      <GrooveSelectMenuBasic
                        customClassName="selected-menu-basic"
                        name="suboffering"
                        isDisabled={this.props.readOnly || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                        placeholder={IsArrayEmpty(this.props.newProjectDetails.suboffering) ? "Select Sub Offering" : "Sub Offering"}
                        list={this.props.dDownList.subofferingList}
                        defaultData={this.props.newProjectDetails.suboffering[0]}
                        id="suboffering"
                        callbackFunction={(e) => this.props.onUpdateField(e, "suboffering")}
                        isRequired={true}
                      />
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="projectDescription">
                  <div className="comments">
                    <GrooveTextBox
                      customClassName={`selected-menu-basic ${this.props.invalidDesc ? 'invalid-project-desc-field' : ''}`}
                      id="projectDescription"
                      name="projectDescription"
                      type="text"
                      placeholder={projectDescPlaceholder}
                      maxLength={255}
                      rows="2"
                      textValue={this.props.newProjectDetails.projectDescription !== null ? this.props.newProjectDetails.projectDescription : ""}
                      callbackFunction={(e) => this.props.onUpdateField(e, "projectDescription")}
                      isDisabled={this.props.readOnly || this.props.isSystemGenerated || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <GrooveInput
                  id="offering"
                  name="offering"
                  placeholder="Offering"
                  type="text"
                  inputValue={this.props.newProjectDetails.offering}
                  isDisabled={copy !== "true" ? true : false}
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className={this.props.readOnly || this.props.isSystemGenerated || isProjectApprover ? "Multi-Select-BT disabled-mulitSelect" : "Multi-Select-BT with-label"}>
                  <GrooveSelectMenuBasic
                    customClassName="selected-menu-basic"
                    name="deliveryLocation"
                    isDisabled={this.props.readOnly || this.props.isSystemGenerated || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                    placeholder={IsArrayEmpty(tempDeliveryLocation) ? "Select Delivery Location" : "Delivery Location"}
                    list={this.props.dropdownList.deliveryCenterList}
                    defaultData={tempDeliveryLocation[0]}
                    id="deliveryLocation"
                    callbackFunction={(e) => this.props.onUpdateField(e, "deliveryLocation")}
                    isRequired={true}
                  />
                </div>
              </Col>
            </Row>
            {/* r12 */}
            <Row>
              <Col lg={3} md={3} sm={6} className="omid-container">
                <CVMInput
                  customClassName="selected-menu-basic"
                  type="text"
                  id="contractID"
                  placeholder={contractIDPlaceholder}
                  inputValue={this.props.newProjectDetails.contractID}
                  callbackFunction={(e) => this.props.onUpdateField(e.target, "contractID")}
                  isDisabled={(!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                  maxLength={15}
                  isRequired={true}
                />
              </Col>
              <Col lg={3} md={3} sm={6} className="contract-container">
                {/* <Col lg={6} md={6} sm={12} className="contract-container"> */}
                <CVMInput
                  customClassName="selected-menu-basic"
                  type="text"
                  id="omid"
                  placeholder={OMIDPlaceholder}
                  inputValue={this.props.newProjectDetails.OMID}
                  callbackFunction={(e) => this.props.onUpdateField(e.target, "omid")}
                  isDisabled={(!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                  maxLength={15}
                  isRequired={true}
                />
              </Col>
              <Col lg={3} md={3} sm={6} className="contract-container">
                <GrooveSelectMenuBasic
                  customClassName="selected-menu-basic"
                  name="tve"
                  isDisabled={(!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                  placeholder={this.props.newProjectDetails.tve ? 'Total Value Enabled (TVE Deal)' : 'Total Value Enabled..'}
                  list={this.props.dDownList.tveList}
                  defaultData={this.props.newProjectDetails.tve}
                  id="tve"
                  callbackFunction={(e) => this.props.onUpdateField(e, "tve")}
                  isRequired={true}
                />
              </Col>
              <Col lg={3} md={3} sm={6} className="contract-container">
                <GrooveSelectMenuBasic
                  customClassName="selected-menu-basic"
                  name="serviceLine"
                  isDisabled={(!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true")}
                  placeholder={'Service Line'}
                  list={this.props.dDownList.serviceLineList}
                  defaultData= {{
                    text : this.props.newProjectDetails.serviceLine,
                    value : this.props.newProjectDetails.serviceLine
                  }}
                  id="serviceLine"
                  callbackFunction={(e) => this.props.onUpdateField(e, "serviceLine")}
                  isRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <GrooveSelectMenuBasic
                  customClassName={`selected-menu-basic ${this.props.isValidIoApprover ? '' : 'project-page-dropdown-invalid'}`}
                  name="ioapprover"
                  isDisabled={
                    this.props.readOnly ||
                    this.props.newProjectDetails.suboffering.length === 0 ||
                    this.props.dDownList.ioapproverList.length === 0 ||
                    isProjectApprover ||
                    !isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true"
                  }
                  placeholder={I0projectApproverPlaceholder}
                  list={this.props.dDownList.ioapproverList}
                  defaultData={this.props.newProjectDetails.ioapprover}
                  id="ioapprover"
                  callbackFunction={(e) => this.props.onUpdateField(e, "ioapprover")}
                  isRequired={this.props.dDownList.ioapproverList.length > 0 && !generatedTalentInc}
                />
                {this.props.isIOApproverSelected
                  && this.props.ioApprover.length > 0
                  && <p className="ioa-mapped-suboffering-container">Selected user is mapped with {this.props.ioApprover.join(', ')}</p>
                }
              </Col>
              <Col lg={6} md={6} sm={12}>
                <GrooveDateCalendarPicker
                  isRequired={true}
                  id="dtTargetEndDate"
                  name="date-01"
                  labelText="Target End Date"
                  maxDate={this.props.dateLimit}
                  dateValue={this.props.newProjectDetails.targetEndDate.value}
                  callbackFunction={(e) => this.props.onUpdateField(e, "targetEndDate")}
                  isDisabled={this.props.readOnly || isProjectApprover || !isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true"}
                  dateFormat='dd-MMM-yyyy'
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <GrooveSelectMenuBasic
                  customClassName={`selected-menu-basic ${this.props.isValidProjectCreator ? '' : 'project-page-dropdown-invalid'}`}
                  name="projectcreator"
                  isDisabled={(!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true") || this.props.isSystemGenerated || !enabledUpdatePPC || (!this.props.userInformation.isSuperAdmin && isPPCBA)}
                  placeholder={ProjectCreatorpPlaceholder}
                  list={projCreatorList}
                  defaultData={!isemptycreator?(existingProject && !isPPCEmpty ? setProjectCreator : this.props.newProjectDetails.projectcreator) :{label:this.props.selectedProjectDetails.ProjectCreator,text:this.props.selectedProjectDetails.ProjectCreator,value:this.props.selectedProjectDetails.ProjectCreator}}
                  id="projectcreator"
                  callbackFunction={(e) => this.props.onUpdateField(e, "projectcreator")}
                  isRequired={true}
                />
              </Col>

              <Col lg={6} md={6} sm={12}>
                <div className={this.props.readOnly || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true") ? "Multi-Select-BT disabled-mulitSelect disabled-addselectfields" : "Multi-Select-BT with-label"}>
                  <CVMMultiSelectDropdownWithAddButton
                    name="businessAdvisor"
                    popdirection="pop-bottom"
                    isDisabled={!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true" ? true : false}
                    placeholder={this.props.newProjectDetails && this.props.newProjectDetails.businessAdvisor.length > 0 ? "Business Advisor" : "Select Business Advisor"}
                    options={this.props.dDownList.baList}
                    id="businessAdvisor"
                    onChange={(e) => this.props.onUpdateField(e, "ba")}
                    value={(this.props.bAEmpty&&!this.props.onUpdate)?[]:(this.props.onUpdate||!this.props.bANA)?this.props.newProjectDetails.businessAdvisor:this.props.newproj}
                    activePaste={false}
                    onAddButton={(e) => this.props.onAddButton(e, 'ba')}
                    isRequired
                    label="Business Advisor"
                    customClassName={!this.props.isValidNewUser.ba ? ' dropdown-invalid' : ''}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div className={this.props.readOnly || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true") ? "Multi-Select-BT disabled-mulitSelect disabled-addselectfields" : "Multi-Select-BT with-label"}>
                  <CVMMultiSelectDropdownWithAddButton
                    name="Operations Account Lead"
                    popdirection="pop-bottom"
                    isDisabled={!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true" ? true : false}
                    placeholder={this.props.newProjectDetails.oaLead.length > 0 ? "Operations Account Lead" : "Select Operations Account Lead"}
                    options={this.props.dDownList.oalList}
                    id="oaLead"
                    onChange={(e) => this.props.onUpdateField(e, "oal")}
                    value={this.props.newProjectDetails.oaLead}
                    activePaste={false}
                    onAddButton={(e) => this.props.onAddButton(e, 'oal')}
                    isRequired
                    label="Operations Account Lead"
                    customClassName={!this.props.isValidNewUser.oal ? ' dropdown-invalid' : ''}
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className={this.props.readOnly || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true") ? "Multi-Select-BT disabled-mulitSelect disabled-addselectfields" : "Multi-Select-BT with-label"}>
                  <CVMMultiSelectDropdownWithAddButton
                    name="selectCXLLead"
                    popdirection="pop-bottom"
                    isDisabled={!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true" ? true : false}
                    placeholder={this.props.newProjectDetails.cxLead.length > 0 ? "CX Lead" : "Select CX Lead"}
                    options={this.props.dDownList.cxlList}
                    id="cxlead"
                    onChange={(e) => this.props.onUpdateField(e, "cxl")}
                    value={this.props.newProjectDetails.cxLead}
                    activePaste={false}
                    onAddButton={(e) => this.props.onAddButton(e, 'cxl')}
                    isRequired
                    label="CX Lead"
                    customClassName={!this.props.isValidNewUser.cxl ? ' dropdown-invalid' : ''}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div className={this.props.readOnly || isProjectApprover || (!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true") ? "Multi-Select-BT disabled-mulitSelect dd-tooltip-right" : "Multi-Select-BT with-label dd-tooltip-right"}>
                  <CVMMultiSelectDropdownWithCheckBoxForBA
                    id="assetName"
                    placeholder={"Select Asset Name"}
                    options={this.props.dDownList.assetNameList}
                    value={this.props.newProjectDetails.assetName}
                    isDisabled={!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true" || this.props.readOnly || isProjectApprover}
                    label={"Asset Name"}
                    onChange={(e) => this.props.onUpdateField(e, "assetName")}
                    isRequired={true}
                    isShowAll={false}
                  />
                  <a data-tip data-for="assetName">
                    <div className="info-icon">
                      <GrooveIconSvg
                        size="large"
                        name="info-circle"
                        iconStyle="solid"
                        iconColor="stat-alternate"
                      />
                    </div>
                  </a>
                  <ReactTooltip id="assetName" type="dark" effect="solid">
                    <span>
                      Please select tools/technology/methodology referred while working on this project.
                    </span>
                  </ReactTooltip>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} className={"dd-tooltip-right"}>
                <GrooveSelectMenuBasic
                  name="deliveredby"
                  isDisabled={!isSuperAdmin && this.props.selectedProjectDetails.OverallStatus == 'Approved' && copy !== "true" || this.props.readOnly || isProjectApprover}
                  placeholder={DeliveredByPlaceholder}
                  list={this.props.dDownList.deliveredByList}
                  defaultData={this.props.newProjectDetails.deliveredBy}
                  id="deliveredby"
                  callbackFunction={(e) => this.props.onUpdateField(e, "deliveredby")}
                  isRequired={true}
                  label={"Delivered By"}
                />
                <a data-tip data-for="deliveredBy">
                  <div className="info-icon">
                    <GrooveIconSvg
                      size="large"
                      name="info-circle"
                      iconStyle="solid"
                      iconColor="stat-alternate"
                    />
                  </div>
                </a>
                <ReactTooltip id="deliveredBy" type="dark" effect="solid">
                  <span>
                    Please select the operational team(s) who will deliver this project.
                  </span>
                </ReactTooltip>
              </Col>
            </Row>
            <div className="add-program-buttons ppc proj">
              <>
                {this.props.newProjectDetails.projectName.isError
                  ? <div className="missing">
                    {this.props.invalidProjectNameErrorType === 'sameProjectName'
                      ?  'This Project already exists'
                      : 'Invalid Project Name'
                    }
                  </div>
                  : this.props.invalidDesc ? <div className="missing">Invalid Project Description</div>
                    : this.props.cmErrorMessage.map(cmerr => {
                      return (
                        <div className="missing">
                          {cmerr.value}
                        </div>
                      )
                    })

                }
                {
                  <div className='missing-container'>
                    {
                      this.props.isValidMappedFields.mappedFieldErrMsgs.map(err => {
                        return (
                          <div className="missing">
                            {err}
                          </div>
                        )
                      })
                    }
                  </div>
                }
              </>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  class AddNewProjectPopupContainer extends Component {
    constructor() {
      super();
      this.state = {
        copiedProjectName: "",
        dateLimit: 0,
        isIOApproverSelected: false,
        onUpdate:false,
        newproj:[],
        ioApprover: [],
        copy:sessionStorage.getItem('copy'),
        dDownList: {
          subofferingList: [],
          ioapproverList: [],
          projectCreatorList: [],
          projectApproverList: [],
          deliveredByList: [],
          assetNameList: [],
          cxlList: [],
          oalList: [],
          baList: [],
          tveList: [{
            label: "No",
            text: "No",
            value: "No"
          },
          {
            label: "Yes",
            text: "Yes",
            value: "Yes"
          }],
          serviceLineList: [{
            label: "Accenture Operations",
            text: "Accenture Operations",
            value: "Accenture Operations"
          },
          {

            label: "Captive & Retained Business Services (CRBS)",
            text: "Captive & Retained Business Services (CRBS)",
            value: "Captive & Retained Business Services (CRBS)"
          }]
        },
        userRole: "",
        projectID: 0,
        isValidMappedFields: {
          projCreator: true,
          ioApprover: true,
          cxl: true,
          oal: true,
          ba: true,
          mappedFieldErrMsgs: []
        },
        invalidProjectNameErrorType: '',
      };
    }


    focusTargerValueSetting = () => {
      let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
      const deliveredBy = IsValid(newProjectDetails.deliveredBy) ? Object.keys(newProjectDetails.deliveredBy).length !== 0 : false

      if (
        newProjectDetails.projectName.value !== "" &&
        newProjectDetails.projectName.isError === false &&
        newProjectDetails.suboffering.length !== 0 &&
        newProjectDetails.deliveryLocation.length !== 0 &&
        newProjectDetails.targetEndDate !== "" &&
        Object.keys(newProjectDetails.ioapprover).length !== 0 &&
        Object.keys(newProjectDetails.projectcreator).length !== 0 &&
        deliveredBy === true &&
        newProjectDetails.assetName.length !== 0 &&
        newProjectDetails.businessAdvisor.length !== 0 &&
        newProjectDetails.oaLead.length !== 0 &&
        newProjectDetails.cxLead.length !== 0 &&
        IsValidStr(newProjectDetails.OMID) &&
        IsValidStr(newProjectDetails.contractID)
      )

        if (document.getElementsByClassName('idValue-setting-add-boi')[0].children[0].children[0].className.indexOf('show') === -1) {
          document.getElementsByClassName('idValue-setting-add-boi')[0].children[0].children[1].children[0].click()
          setTimeout(() => {
            if (document.getElementsByClassName('idValue-setting-add-boi')[0]) {
              document.getElementsByClassName('idValue-setting-add-boi')[0].scrollIntoView();
            }
          }, 200);
        }

      this.changeTextAreaClass();
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    componentDidUpdate = (nextProps) => {
      if (this.state.projectID !== nextProps.ProjectConfigurationData.selectedProjectDetails.ProjectID && nextProps.ProjectConfigurationData.selectedProjectDetails.ProjectID !== undefined) {
        this.setStakeholders();
        this.setState({
          isValidMappedFields: {
            projCreator: true,
            ioApprover: true,
            cxl: true,
            oal: true,
            ba: true,
            mappedFieldErrMsgs: []
          }
        }, () => {
          this.validateMappedFields();
          this.getDisclaimerDetails();
        })
      }
    }

    componentDidMount = () => {
      let projectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
      let offering = this.props.ProgramConfigurationData.selectedProgramDetails.Offering;
      let progSubOfferings = this.props.ProgramConfigurationData.selectedProgramDetails.SubOfferingList;
      let userInfo = this.props.userInformation;
      let optionList = this.state.dDownList;
      let subofferingListTemp = [];
      let selectedProfile = this.props.SharedData.selectedClient;
      let userProfiles = this.props.userInformation.userProfiles;
      /* Use CXL Lead from Client Level
      let selectedCXL = this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead;
      let cxlList = [];

       if (IsValidStr(selectedCXL)) {
        selectedCXL.split(',').forEach(i => {
          cxlList.push({
            text: i,
            label: i,
            value: i
          })
        })
      } */

      let profile = userProfiles.find(x => x.ClientName.toLowerCase() === selectedProfile.label.toLowerCase() && x.ProfileID !== 16) //exclude QC in filter
      if (profile !== undefined) {
        this.setState({ userRole: profile.ProfileName.toLowerCase() })
      }

      progSubOfferings.map((suboffering) => {
        subofferingListTemp.push({
          label: suboffering.SubOfferingName, text: suboffering.SubOfferingName, value: suboffering.SubOfferingID, offeringID: suboffering.OfferingID
        })
      });

      optionList.subofferingList = subofferingListTemp;
      optionList.projectCreatorList = this.props.ProgramConfigurationData.ConfiguratorsList;
      optionList.projectApproverList = this.props.ProgramConfigurationData.ConfiguratorsList;
      optionList.deliveredByList = this.props.ProjectConfigurationData.assetAndDeliveredByList.deliveredByList;
      optionList.assetNameList = this.props.ProjectConfigurationData.assetAndDeliveredByList.assetsNameList;
      // optionList.cxlList = cxlList;
      optionList.cxlList = this.props.ProgramConfigurationData.CXLeadList;
      optionList.oalList = this.props.ProgramConfigurationData.OALeadList;
      optionList.baList = this.props.ProjectConfigurationData.businessAdvisorList;

      let projCreator = optionList.projectCreatorList.length === 1 ? optionList.projectCreatorList[0] : {};
      if (!userInfo.isSuperAdmin) {
        let tempList = [];
        optionList.projectCreatorList.map(user => {
          if (this.props.isSystemGenerated) {
            projCreator = { label: 'System Generated', text: 'System Generated', value: 'System Generated' };
          }
          else if (user.label === userInfo.emailAddress) {
            projCreator = user;
          }
          else {
            tempList.push(user);
          }
        })
        optionList.projectApproverList = this.props.isSystemGenerated ? optionList.projectApproverList : tempList;
      }
      this.setState({
        dDownList: optionList,
      })

      let newProjectDetails = {
        projectName: { value: "", isError: false },
        suboffering: subofferingListTemp.length === 1 ? [subofferingListTemp[0]] : [],
        projectDescription: "",
        offering: offering,
        deliveryLocation: [],
        targetEndDate: "",
        ioapprover: {},
        projectcreator: projCreator,
        projectapprover: optionList.projectApproverList.length === 1 ? optionList.projectApproverList[0] : {},
        deliveredBy: {},
        assetName: [],
        cxLead: [],
        oaLead: [],
        businessAdvisor: [],
        OMID: "",
        contractID: "",
        tve: false,
        serviceLine: "Accenture Operations",
      }
      if (subofferingListTemp.length === 1 && IsObjEmpty(projectDetails)) {
        this.setIOAListForSoloSubOffering(newProjectDetails)
      } else if (Object.keys(projectDetails).length > 0) {
        let selectedSubOffering = projectDetails.ClientProjectSubOffering.map(x => {
          return ({
            text: x.SubOfferingName,
            value: x.SubOfferingID,
            label: x.SubOfferingName
          })
        })

        let ioapprover = projectDetails.IOApproverProfileId > 0 ? { label: projectDetails.IOApprover, text: projectDetails.IOApprover, value: projectDetails.IOApprover } : {}
        this.setIOAList(selectedSubOffering, ioapprover);
      }

      let today = new Date();
      let nextYear = (today.getFullYear() + 1).toString();
      let endDate = new Date("08/31/" + nextYear);
      let timeDiff = endDate.getTime() - today.getTime();
      let dateLimit = Math.ceil(timeDiff / (1000 * 3600 * 24));


      this.setState({
        dateLimit: dateLimit
      }, () => {
        this.setStakeholders();
      })
      this.props.setNewProjectDetails(newProjectDetails);

      this.changeTextAreaClass()

      if(this.props.editMode && this.props.ProjectConfigurationData.selectedProjectDetails.OverallStatus !== 'Approved') {
        this.props.checkForInvalidEmailFirst();
      }
    }

    setStakeholders = () => {
      try {
        let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
        let projectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
        let baList = this.props.ProjectConfigurationData.businessAdvisorList;
        let oal = [];
        let cxl = [];
        let ba = [];

        if (IsValidStr(projectDetails.OperationsAccountLead)) {
          projectDetails.OperationsAccountLead.split(',').forEach(i => {
            oal.push({
              text: i,
              label: i,
              value: i
            })
          })
        }

        if (IsValidStr(projectDetails.CXLead)) {
          projectDetails.CXLead.split(',').forEach(i => {
            cxl.push({
              text: i,
              label: i,
              value: i
            })
          })
        }

        if (IsValidStr(projectDetails.BusinessAdvisor)) {
          projectDetails.BusinessAdvisor.split(',').forEach(i => {
            let selectedBA = baList.filter(x => x.label === i)
            if (selectedBA.length > 0) {
              ba.push(selectedBA[0]);
            }
          })
        }

        if (this.props.isSystemGenerated) {
          const index = ba.findIndex((o) => o.value === 0);
          if(index === -1) {
            ba.push({
              label: "Not Applicable",
              text: "Not Applicable",
              value: 0
            })
          }
        }

        newProjectDetails.cxLead = cxl;
        newProjectDetails.businessAdvisor = ba;
        newProjectDetails.oaLead = oal;
        this.setState({
          projectID: projectDetails.ProjectID
        })
        this.props.setNewProjectDetails(newProjectDetails);
      }
      catch (err) {
        console.log(err);
      }
    }

    setIOAListForSoloSubOffering = (newProjectDetails) => {
      let origIOAList = this.props.ProgramConfigurationData.IOAApproverList;
      let dDownList = this.state.dDownList;
      let IOAListTemp = [];
      let IOAList = [];
      newProjectDetails.suboffering.map(sub => {
        origIOAList.map(ioa => {
          if (ioa.subOfferingID === sub.value && IOAListTemp.indexOf(ioa.label) === -1) {
            IOAList.push(ioa);
            IOAListTemp.push(ioa.label);
          }
        })
      });

      dDownList.ioapproverList = IOAList;
      this.setState({
        dDownList: dDownList,
      }, () => {
        newProjectDetails.ioapprover = IOAList.length === 1 ? IOAList[0] : {}
        this.props.setNewProjectDetails(newProjectDetails);
      })
    }

    setIOAList = (selSubOff, ioapprover) => {
      let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
      let dDownList = this.state.dDownList;
      let origIOAList = IsValid(this.props.ProgramConfigurationData.IOAApproverList) ? this.props.ProgramConfigurationData.IOAApproverList : [];
      let IOAListTemp = [];
      let IOAList = [];
      selSubOff.map(sub => {
        origIOAList.map(ioa => {
          if (ioa.subOfferingID === sub.value && IOAListTemp.indexOf(ioa.label) === -1) {
            IOAList.push(ioa);
            IOAListTemp.push(ioa.label);
          }
        })
      });
      dDownList.ioapproverList = IOAList;
      this.setState({
        dDownList: dDownList,
      }, () => {

      });
    }

    onIOApprover = (newProjectDetails) => {
      let tempSelectedIOApprovername = [];
      let selectedIOApproverSubofferingIDs = [];
      if (newProjectDetails === undefined || newProjectDetails === null) return;

      tempSelectedIOApprovername = this.props.ProgramConfigurationData.IOAApproverList.filter((item) => {
        return item.label === newProjectDetails.ioapprover.label
      });


      tempSelectedIOApprovername.forEach((item) => {
        selectedIOApproverSubofferingIDs.push(item.subOfferingID);
      })

      let tempSubofferingLabels = [];

      if (newProjectDetails.suboffering.length > 0) {
        newProjectDetails.suboffering.forEach((suboffering) => {
          if (selectedIOApproverSubofferingIDs.indexOf(suboffering.value) !== -1) {
            tempSubofferingLabels.push(suboffering.text);
          }
        });
      }

      this.setState({
        isIOApproverSelected: true,
        ioApprover: tempSubofferingLabels
      })

    }

    validateCheckedEmails = async (e, field) => {
      let allValid = true;
      let isCxlValid = true;
      let isOalValid = true;
      let isBaValid = true;
      let isProjectCreatorValid = true;
      let isIOApproverValid = true;
      let msgValue;
      let otherDropdownEmails;
      let selectedDropdownEmails = (field === 'projectcreator' || field === 'ioapprover') ? e.label : e.map((item) => item.label);
      let allValues;

      if (field !== 'projectcreator' && field !== 'ioapprover') {
        selectedDropdownEmails = selectedDropdownEmails.filter(item => item !== 'Not Applicable');
        allValues = [...selectedDropdownEmails];
      } else {
        allValues = [selectedDropdownEmails];
      }

      if (field !== 'ioapprover' && this.props.ProjectConfigurationData.newProjectDetails.ioapprover.label) {
        allValues = [...allValues, this.props.ProjectConfigurationData.newProjectDetails.ioapprover.label];
      }

      if (field !== 'projectcreator' && this.props.ProjectConfigurationData.newProjectDetails.projectcreator.label) {
        allValues = [...allValues, this.props.ProjectConfigurationData.newProjectDetails.projectcreator.label];
      }

      if (field === 'oal') {
        otherDropdownEmails = [...this.props.ProjectConfigurationData.newProjectDetails.cxLead.map((item) => item.label), ...this.props.ProjectConfigurationData.newProjectDetails.businessAdvisor.map((item) => item.label)];
      } else if (field === 'cxl') {
        otherDropdownEmails = [...this.props.ProjectConfigurationData.newProjectDetails.oaLead.map((item) => item.label), ...this.props.ProjectConfigurationData.newProjectDetails.businessAdvisor.map((item) => item.label)];
      } else if (field === 'ba') {
        otherDropdownEmails = [...this.props.ProjectConfigurationData.newProjectDetails.cxLead.map((item) => item.label), ...this.props.ProjectConfigurationData.newProjectDetails.oaLead.map((item) => item.label)];
      } else if (field === 'projectcreator') {
        otherDropdownEmails = [...this.props.ProjectConfigurationData.newProjectDetails.cxLead.map((item) => item.label), ...this.props.ProjectConfigurationData.newProjectDetails.oaLead.map((item) => item.label), ...this.props.ProjectConfigurationData.newProjectDetails.businessAdvisor.map((item) => item.label)];
      } else if (field === 'ioapprover') {
        otherDropdownEmails = [...this.props.ProjectConfigurationData.newProjectDetails.cxLead.map((item) => item.label), ...this.props.ProjectConfigurationData.newProjectDetails.oaLead.map((item) => item.label), ...this.props.ProjectConfigurationData.newProjectDetails.businessAdvisor.map((item) => item.label)];
      }

      allValues = [...allValues, ...otherDropdownEmails.filter(item => item !== 'Not Applicable')];

      const data = await IsValidAccentureEmail(allValues.filter(item => item !== 'System Generated'));
      let allInvalidEmails = [];

      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          allValid = false;
          allInvalidEmails.push(data[i].UserName);
          msgValue = {
            key: 'Valid',
            value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.map(item => item.split('@')[0]).join(', ')}`
          };
        }
      }

      if (!allValid) {
        allInvalidEmails.map((item) => {
          if (this.props.ProjectConfigurationData.newProjectDetails.cxLead.map((i) => i.label).indexOf(item) !== -1) {
            isCxlValid = false;
          }
          if (this.props.ProjectConfigurationData.newProjectDetails.oaLead.map((i) => i.label).indexOf(item) !== -1) {
            isOalValid = false;
          }
          if (this.props.ProjectConfigurationData.newProjectDetails.businessAdvisor.map((i) => i.label).indexOf(item) !== -1) {
            isBaValid = false;
          }
          if (field !== 'ioapprover' ? this.props.ProjectConfigurationData.newProjectDetails.ioapprover.label === item
            : e.label === item) {
            isIOApproverValid = false;
          }
          if (field !== 'projectcreator' ? this.props.ProjectConfigurationData.newProjectDetails.projectcreator.label === item
            : e.label === item) {
            isProjectCreatorValid = false;
          }
        })
      }

      this.props.setAnyInvalidEmailAvailable(!allValid);
      this.props.setCmErrorMessage(allValid ? [] : [msgValue]);
      this.props.setIsValidNewUser({
        cxl: isCxlValid,
        oal: isOalValid,
        ba: isBaValid,
      });
      this.props.setAllInvalidEmails(allInvalidEmails);
      this.props.setIsValidIoApprover(isIOApproverValid);
      this.props.setIsValidProjectCreator(isProjectCreatorValid);
      this.props.setAnyInvalidEmailsInDropdown({
        cxl: !isCxlValid,
        oal: !isOalValid,
        ba: !isBaValid,
      })
    }

    onUpdateField = (e, field) => {
      let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
      let projectList = this.props.ProjectConfigurationData.projectList;
      let tVal = "";
      let val;
      switch (field) {
        case "projectName":
          let error = false;
          if (!IsValidText(e.value)) {
            val = e.value;
            error = true;
            this.setState({
              invalidProjectNameErrorType: 'invalidProjectName'
            })
          }
          else {

            projectList.map((item) => {
              if ((item.ClientProjectName).replace(/ /g, '').toLowerCase() === (e.value).replace(/ /g, '').toLowerCase()) {
                error = true;
              }
              else {
                for (let i = 0; i < projectList.length; i++) {
                  if ((projectList[i].ClientProjectName).replace(/ /g, '').toLowerCase() === (e.value).replace(/ /g, '').toLowerCase()) {
                    error = true;
                  }
                }
              }
            })
            val = e.value.replace(/[^\x00-\x7F\xD1\xF1]/g, "");
            this.setState({
              invalidProjectNameErrorType: 'sameProjectName'
            })
          }
          newProjectDetails.projectName.value = val;
          newProjectDetails.projectName.isError = error;
          this.setState({
            copy: false,
            copiedProjectName: val == '' ? '' : val
          })
          store.dispatch({
            type: "COPIED_PROJECT_NAME",
            copiedProjectName: sessionStorage.getItem('copy') == "true" ? val : '',
          })


          break;
        case "suboffering":
          let selVal = this.props.isSystemGenerated ? e : [e]
          newProjectDetails.suboffering = selVal;
          newProjectDetails.ioapprover = '';
          this.setIOAList(selVal)
          break;
        case "projectDescription":
          newProjectDetails.projectDescription = e.value;
          if (!IsValidText(e.value)) {
            this.props.setInvalidDesc(true);
          } else {
            this.props.setInvalidDesc(false);
          }
          break;
        case "deliveryLocation":
          newProjectDetails.deliveryLocation = [e];
          break;
        case "ioapprover":
          newProjectDetails.ioapprover = e;
          this.validateCheckedEmails(e, field);
          this.onIOApprover(newProjectDetails);
          break;
        case "targetEndDate":
          newProjectDetails.targetEndDate = e;
          break;
        case "projectcreator":
          newProjectDetails.projectcreator = e;
          this.validateCheckedEmails(e, field);
          if (newProjectDetails.projectapprover !== undefined) {
            if (e.value === newProjectDetails.projectapprover.value) {
              newProjectDetails.projectapprover = {};
            }
          }
          break;
        case "projectapprover":
          newProjectDetails.projectapprover = e;
          if (newProjectDetails.projectcreator !== undefined) {
            if (e.value === newProjectDetails.projectcreator.value) {
              newProjectDetails.projectcreator = {};
            }
          }
          break;
        case "deliveredby":
          newProjectDetails.deliveredBy = e;
          break;
        case "assetName":
          newProjectDetails.assetName = e;
          break;
        case "oal":
          newProjectDetails.oaLead = e;
          this.validateCheckedEmails(e, field);
          break;
        case "cxl":
          newProjectDetails.cxLead = e;
          this.validateCheckedEmails(e, field);
          break;
        case "ba":
          newProjectDetails.businessAdvisor = e;
          this.validateCheckedEmails(e, field);
          this.setState({
            onUpdate:true,
          })
          break;
        case "omid":
          tVal = parseField(e.value, 'positive');
          newProjectDetails.OMID = tVal;
          break;
        case "contractID":
          tVal = parseField(e.value, 'positive');
          newProjectDetails.contractID = tVal;
          break;
        case "tve":
          newProjectDetails.tve = e;
          break;
        case "serviceLine":
          newProjectDetails.serviceLine = e.value;
          break;
        default:
          break;
      }

      if (!this.props.cmErrorMessage && !this.props.cmErrorMessage[0].key === 'Valid') {
        this.props.setCmErrorMessage([]);
      }

      this.props.setIsValidNewUser({
        cxl: !this.props.anyInvalidEmailsInDropdown.cxl,
        oal: !this.props.anyInvalidEmailsInDropdown.oal,
        ba: !this.props.anyInvalidEmailsInDropdown.ba
      });

      this.focusTargerValueSetting();
      this.props.setNewProjectDetails(newProjectDetails).then(() => {
        // this.validateMappedFields(false);
      });

      // to check for invalid email ids if user is in edit mode and project is not approved
      if(!this.props.editedAnyFieldOnce && this.props.editMode && this.props.ProjectConfigurationData.selectedProjectDetails.OverallStatus === 'Approved') {
        this.props.checkForInvalidEmailFirst();
        this.props.setEditedAnyFieldOnce(true);
      }
    }

    onAddButton = async (newValue, field) => {
      Object.keys(newValue).forEach(k =>
        newValue[k].label = newValue[k].label.split(' ').join('').trim()
      );
      Object.keys(newValue).forEach(k =>
        newValue[k].text = newValue[k].text.split(' ').join('').trim()
      );
      Object.keys(newValue).forEach(k =>
        newValue[k].value = newValue[k].value.split(' ').join('').trim()
      );
      try {
        let newProjectDetails = this.props.ProjectConfigurationData.newProjectDetails;
        let dDownList = this.state.dDownList;
        let baIsValid = true;
        let oalIsValid = true;
        let cxlIsValid = true;
        switch (field) {
          case "ba":
            let subMenuId = GetSubMenuId("Add New Project");
            const profileValidation = await HttpGet(
              `User/GetUserMultipleProfileValidation?userEmailID=${newValue[0].label}&SubMenuID=${subMenuId}`
            );
            const isNotAgnostic = profileValidation.filter((x) => x.ProfileID === 8).length === 0 && profileValidation.filter((x) => x.ProfileID === 16).length === 0;
            let isBAValid = IsArrayEmpty(newProjectDetails.businessAdvisor) ||
              (IsValid(newProjectDetails.businessAdvisor[0].label)
                && newProjectDetails.businessAdvisor[0].label.toLowerCase() !== 'not applicable');
            baIsValid = await this.validateBAOALCXLNewUser(newValue[0].label, field);

            if (!baIsValid) {
              this.validateEmailAddress(newValue[0].label, true, field);
            } else {
              if (isNotAgnostic) {
                this.handleErrorMessage(6, false);
                if (baIsValid && isBAValid) {
                  newProjectDetails.businessAdvisor = [...newProjectDetails.businessAdvisor, ...newValue];
                  dDownList.baList = [...dDownList.baList, ...newValue];
                } else if ((IsValid(newProjectDetails.businessAdvisor[0].label)
                  && newProjectDetails.businessAdvisor[0].label.toLowerCase() === 'not applicable') && baIsValid) {
                  dDownList.baList = [...dDownList.baList, ...newValue];
                }
              } else {
                baIsValid = false;
                this.handleErrorMessage(6);
              }
            }
            break;
          case 'oal':
            oalIsValid = await this.validateBAOALCXLNewUser(newValue[0].label, field);
            if (oalIsValid) {
              newProjectDetails.oaLead = [...newProjectDetails.oaLead, ...newValue];
              dDownList.oalList = [...dDownList.oalList, ...newValue];
            }
            break;
          case 'cxl':
            cxlIsValid = await this.validateBAOALCXLNewUser(newValue[0].label, field);
            if (cxlIsValid) {
              newProjectDetails.cxLead = [...newProjectDetails.cxLead, ...newValue]
              dDownList.cxlList = [...dDownList.cxlList, ...newValue];
            }
            break;
        }
        this.props.setNewProjectDetails(newProjectDetails);
        this.props.setIsValidNewUser({
          cxl: cxlIsValid ? !this.props.anyInvalidEmailsInDropdown.cxl : cxlIsValid,
          oal: oalIsValid ? !this.props.anyInvalidEmailsInDropdown.oal : oalIsValid,
          ba: baIsValid ? !this.props.anyInvalidEmailsInDropdown.ba : baIsValid
        });
        this.setState({
          dDownList
        });
      }
      catch (err) {
        console.log(err);
      }
    }

    validateBAOALCXLNewUser =  (value, field) => {
      try {
        let isValid = true;
        isValid = this.validateEmailAddress(value, true, field);
        return isValid;
      }
      catch (err) {
        console.log(err);
      }
    }

    validateEmailAddress = async (value, hasNoError, role) => {
      let isValid = true;
      if (value !== null && value !== '') {
        //has value
        if (hasNoError) {
          this.props.ProjectConfigurationData.newProjectDetails.cxLead.length<=0&&this.handleErrorMessage(1);
          this.props.ProjectConfigurationData.newProjectDetails.oaLead.length<=0&&this.handleErrorMessage(1);
          this.props.ProjectConfigurationData.newProjectDetails.businessAdvisor.length<=0&&this.handleErrorMessage(1);
          var re = /.+@.+/;
          if (!re.test(value)) {
            //no @ & .
            isValid = false;
            this.handleErrorMessage(2);
          } else {
            if (hasNoError) {
              this.handleErrorMessage(2, false);

              const data = await IsValidAccentureEmail([value]);

              if(!data[0].IsValid){isValid=false;
                this.handleErrorMessage(7);
              }
              else{
                if(hasNoError){
                  this.handleErrorMessage(7,false)
                  let isDuplicate = this.validateDuplicateEmail(value, role);
                  if (!isDuplicate) {
                    //check email duplication (based on role)
                    isValid = false;
                    this.handleErrorMessage(4);
                  } else {
                    if (hasNoError) {
                      this.handleErrorMessage(4, false);

                      let programConfigData = this.props.ProgramConfigurationData;
                      let isUserExist = false;
                      //check if the newly added email is already exist in the field.
                      switch (role) {
                        case 'cxl':
                          isUserExist = (programConfigData.CXLeadList || []).filter(i => i.label === value).length > 0
                          break;
                        case 'oal':
                          isUserExist = (programConfigData.OALeadList || []).filter(i => i.label === value).length > 0
                          break;
                      }

                      if (isUserExist) {
                        isValid = false;
                        this.handleErrorMessage(5);
                      } else {
                        if (hasNoError) {
                          this.handleErrorMessage(5, false);
                        }
                      }
                    }
                  }

                }
              }

            }
          }
        }
      } else {
        isValid = false;
        this.handleErrorMessage(2, false);
        this.handleErrorMessage(3, false);
        this.handleErrorMessage(4, false);
        this.handleErrorMessage(5, false);
        this.handleErrorMessage(7, false);
        this.props.ProjectConfigurationData.newProjectDetails.cxLead.length==0&&this.handleErrorMessage(1);
        this.props.ProjectConfigurationData.newProjectDetails.oaLead.length==0&&this.handleErrorMessage(1);
        this.props.ProjectConfigurationData.newProjectDetails.businessAdvisor.length==0&&this.handleErrorMessage(1);
      }
      return isValid;
    };

    validateDuplicateEmail = (value, field) => {
      try {
        let dDownList = this.state.dDownList;
        let isValid = true;
        let isNotExistOnPPCAndBA = IsArrayEmpty(this.state.dDownList.projectCreatorList.filter(i => i.label === value));
        let doesNotExistOnIO = IsArrayEmpty(this.state.dDownList.ioapproverList.filter(i => i.label === value));
        switch (field) {
          case 'oal':
            isValid = doesNotExistOnIO && isNotExistOnPPCAndBA &&
              (dDownList.cxlList || []).filter(i => i.label === value).length === 0 && (dDownList.baList || []).filter(i => i.label === value).length === 0
            break;
          case 'cxl':
            isValid = doesNotExistOnIO && isNotExistOnPPCAndBA &&
              (dDownList.oalList || []).filter(i => i.label === value).length === 0 && (dDownList.baList || []).filter(i => i.label === value).length === 0
            break;
          case 'ba':
            isValid = doesNotExistOnIO && isNotExistOnPPCAndBA &&
              (dDownList.cxlList || []).filter(i => i.label === value).length === 0 && (dDownList.oalList || []).filter(i => i.label === value).length === 0
            break;
        }

        return isValid;
      }
      catch (err) {
        console.log(err);
      }
    }

    handleErrorMessage = (eType, isError) => {
      isError = isError == undefined ? true : false;
      let cmErrorMessage = this.props.cmErrorMessage;
      let msgValue = {};
      switch (eType) {
        case 1:
          msgValue = { key: 'req', value: 'Missing Required Field' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key == 'req').length == 0)
            cmErrorMessage = [msgValue];
          break;
        case 2:
          msgValue = { key: 'invalidEmail', value: 'Invalid Email' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (
            cmErrorMessage.filter((f) => f.key == msgValue.key).length == 0 &&
            cmErrorMessage.filter((f) => f.key == 'accentureValid').length == 0
          )
            cmErrorMessage = [msgValue];
          break;
        case 3:
          msgValue = {
            key: 'accentureValid',
            value: "Valid email ID should have '@accenture.com'",
          };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (
            cmErrorMessage.filter((f) => f.key == 'invalidEmail').length == 0 &&
            cmErrorMessage.filter((f) => f.key == msgValue.key).length == 0
          )
            cmErrorMessage = [msgValue];
          break;
        case 4:
          msgValue = { key: 'sameProfile', value: 'User already added to a different profile.' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key == 'sameProfile').length == 0)
            cmErrorMessage = [msgValue];
          break;
        case 5:
          msgValue = { key: 'exist', value: 'User already exist.' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key == 'exist').length == 0)
            cmErrorMessage = [msgValue];
          break;
        case 6:
          msgValue = {
            key: 'qcioWithPPCBAProfle',
            value: 'The user you are trying to add is already mapped as an Approver. Please update the user access using User Management module.',
          };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key === 'qcioWithPPCBAProfle').length === 0)
            cmErrorMessage = [msgValue];
          break;
        case 7:
          msgValue = { key: 'invalid accentureid', value: 'Invalid Accenture ID' };
          if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
          else if (cmErrorMessage.filter((f) => f.key === 'invalid accentureid').length === 0) {
            cmErrorMessage = [msgValue];
          }
          break;
      }

      if (cmErrorMessage.filter(i => i.key === 'Valid').length === 0 && this.props.anyInvalidEmailAvailable) {
        cmErrorMessage.unshift({
          key: 'Valid',
          value: `Please update the given invalid EIDs in order to proceed: ${this.props.allInvalidEmails.map(item => item.split('@')[0]).join(', ')}`
        });
      }

      this.props.setCmErrorMessage(cmErrorMessage);
    };

    validateMappedFields = () => {
      try {
        const {
          newProjectDetails,
          selectedProjectDetails
        } = this.props.ProjectConfigurationData;

        const existingProject = Object.keys(selectedProjectDetails).length > 0 ? true : false;
        if (newProjectDetails && existingProject) {
          let selectedProjCreator = newProjectDetails.projectcreator;
          let selectedioapprover = newProjectDetails.ioapprover;
          let selectedBA = newProjectDetails.businessAdvisor;
          let selectedCXLead = newProjectDetails.cxLead;
          let selectedOALead = newProjectDetails.oaLead;

          let dDownList = this.state.dDownList;
          let projCreatorList = dDownList.projectCreatorList;
          let ioapproverList = dDownList.ioapproverList
          let cxlList = dDownList.cxlList;
          let oalList = dDownList.oalList;
          let baList = dDownList.baList;

          const isProjectApprover = existingProject ? selectedProjectDetails.ProjectApprover === newProjectDetails.projectcreator.label ? true : false : false
          const setProjectCreator = existingProject ? IsValid(projCreatorList) ? projCreatorList.find(x => x.label === selectedProjectDetails.ProjectCreator) : "" : ""
          selectedProjCreator = existingProject && isProjectApprover ? setProjectCreator : newProjectDetails.projectcreator

          let tempIsValidFields = {
            projCreator: true,
            ioApprover: true,
            cxl: true,
            oal: true,
            ba: true,
          }
          let invalidProj = [];
          let invalidApprovers = [];
          let mappedFieldErrMsgs = [];
          // If no Program Creator is mapped to the program and there are options available
          if (IsObjEmpty(selectedProjCreator) && IsArrayEmpty(projCreatorList)) {
            tempIsValidFields.projCreator = false;
            invalidProj.push("Project Creator");
          } else {
            if (!IsValid(selectedProjCreator.value) && IsArrayEmpty(projCreatorList)) {
              tempIsValidFields.projCreator = false;
              invalidProj.push("Project Creator");
            } /* else if (selectedProjCreator.value !== '' && projCreatorList.length > 0) {
              let tempProgCreator = selectedProjCreator.value;
              // Check if mapped Program Creator is available in options
              tempIsValidFields.projCreator = projCreatorList.filter((configurator) => configurator.value === tempProgCreator).length > 0
              if (!tempIsValidFields.projCreator) {
                invalidProj.push("Program Creator");
              }
            } */
          }
          if (IsObjEmpty(selectedioapprover) && IsArrayEmpty(ioapproverList)) {
            tempIsValidFields.ioApprover = false;
            invalidProj.push("IO Approver");
          } else {
            if (selectedioapprover.value === '' && IsArrayEmpty(ioapproverList)) {
              tempIsValidFields.ioApprover = false;
              invalidProj.push("IO Approver");
            } /* else if (selectedioapprover.value !== '' && ioapproverList.length > 0) {
              let tempProgCreator = selectedioapprover.value;
              // Check if mapped IO Approver is available in options
              tempIsValidFields.ioApprover = ioapproverList.filter((configurator) => configurator.value === tempProgCreator).length > 0
              if (!tempIsValidFields.ioApprover) {
                invalidProj.push("IO Approver");
              }
            } */
          }
          /* Check if there are no mapped CX lead/OAL and if not single option 
          since if there is only one option it will be auto selected as per current implementation */
          if (selectedCXLead.length === 0 && IsArrayEmpty(cxlList)) {
            tempIsValidFields.cxl = false;
            invalidApprovers.push("CX Lead");
          }
          if (selectedOALead.length === 0 && IsArrayEmpty(oalList)) {
            tempIsValidFields.oal = false;
            invalidApprovers.push("Operations Account Lead");
          }
          if (selectedBA.length === 0 && baList.length <= 1) {
            tempIsValidFields.oal = false;
            invalidApprovers.push("Business Advisor");
          }

          // Format Error Messages
          if (invalidProj.length > 0) {
            let tmpSRoles = formatMultiLabel(invalidProj);
            let creatorInvalidMsg = 'No ' + tmpSRoles + ' is mapped for the project. Please reach out to SVM admin.';
            mappedFieldErrMsgs.push(creatorInvalidMsg);
          }
          if (invalidApprovers.length > 0) {
            let tmpRoles = formatMultiLabel(invalidApprovers);
            let approverInvalidMsg = 'No ' + tmpRoles + ' is mapped for the project. Please add a user by clicking Add button in '
              + tmpRoles + ' field.';
            mappedFieldErrMsgs.push(approverInvalidMsg);
          }

          this.setState({
            isValidMappedFields: {
              projCreator: tempIsValidFields.projCreator,
              ioApprover: tempIsValidFields.ioApprover,
              cxl: tempIsValidFields.cxl,
              oal: tempIsValidFields.oal,
              ba: tempIsValidFields.ba,
              mappedFieldErrMsgs: mappedFieldErrMsgs
            }
          })
        }
      } catch (e) {

      }

    }

    getDisclaimerDetails = async () => {
      try {
        let selectedProjectDetails = this.props.ProjectConfigurationData.selectedProjectDetails;
        let enterpriseID = this.props.userInformation.enterpriseID

        if (!IsObjEmpty(selectedProjectDetails)) {
          let subOfferingID = 0;
          if (!IsArrayEmpty(selectedProjectDetails.ClientProjectSubOffering)) {
            subOfferingID = selectedProjectDetails.ClientProjectSubOffering[0].SubOfferingID;
          }
          let body = {
            ClientProgramID: selectedProjectDetails.ClientProgramID,
            ClientProjectID: selectedProjectDetails.ProjectID,
            IsPDE: false,
            Username: enterpriseID,
            OfferingID: selectedProjectDetails.OfferingID,
            SubOfferingID: subOfferingID,
          };
          let subMenuId = GetSubMenuId("Add New Project")
          let data = await HttpPost(`User/GetDisclaimerDetails?SubMenuID=${subMenuId}`, body);
          if (data.status === undefined && !IsObjEmpty(data)) {
            if (data.DisclaimerFlag) {
              let isValidMappedFields = this.state.isValidMappedFields;
              let mappedFieldErrMsgs = isValidMappedFields.mappedFieldErrMsgs;
              let invalidApprovers = IsValid(data.Roles) ? data.Roles : [];
              // Remove updated disclaimer for PPC
              invalidApprovers = invalidApprovers.filter(role => { return role !== 'Program and Project Configurator' })
              if (invalidApprovers.length > 0) {
                let tmpRoles = formatMultiLabel(invalidApprovers);
                let approverInvalidMsg = 'Please note that ' + tmpRoles + '  is updated for the project.';
                mappedFieldErrMsgs.push(approverInvalidMsg);
              }

              this.setState({
                isValidMappedFields: {
                  ...isValidMappedFields,
                  mappedFieldErrMsgs: mappedFieldErrMsgs
                }
              })
            }
          }
        }
      } catch (e) {

      }
    }

    render() {
      //console.log( this.props.newProjectDetails);
      //console.log(existingProject && !isPPCEmpty ? setProjectCreator : this.props.newProjectDetails.projectcreator)
      const { projectDropdownList, newProjectDetails,projectList, selectedProjectDetails } = this.props.ProjectConfigurationData;
      const{basicDetails}=this.props.ConfigureProgramData
      const { selectedClient } = this.props.SharedData
      const { userInformation } = this.props;
      const clientID = selectedClient.value;
      this.props.copyCallback(this.state.copy)
      let isExistingPPC = userInformation.userProfiles.some(
        (x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let isExistingBA = userInformation.userProfiles.some(
        (x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let copy=sessionStorage.getItem("copy");

      let bAEmpty=false;
      let bAArray=[];
      let newproj=[];
      let bANA=false;
      copy == "true"?  
      projectList.map((project)=>(project.ClientProjectName ==selectedProjectDetails.ClientProjectName)&&
        (project.BusinessAdvisor==""?
          bAEmpty=true:
          bAArray.push(project.BusinessAdvisor.split(",")))
      ):
      projectList.map((project)=>(project.ClientProjectName ==newProjectDetails.projectName.value)&&
        (project.BusinessAdvisor==""?
          bAEmpty=true:
          bAArray.push(project.BusinessAdvisor.split(",")))
      )

      newProjectDetails.businessAdvisor.map((ba)=>{
        if(ba.label=='Not Applicable'){
          bANA=true;
        }
        for(let j=0;j<bAArray.length;j++){
          if(bAArray[j].indexOf(ba.label)!==-1){
            newproj.push(ba);
          }
          else{
            break;
          }
        }
      })

      return <AddNewProjectPopupPresentational
            dateLimit={this.state.dateLimit}
            dropdownList={projectDropdownList}
            newProjectDetails={newProjectDetails}
            newproj={newproj}
            bAEmpty={bAEmpty}
            bANA={bANA}
            editMode={this.props.editMode}
            basicDetails={basicDetails}
            onUpdateField={this.onUpdateField}
            copy={this.state.copy}
            onUpdate={this.state.onUpdate}
            readOnly={this.props.readOnly}
            isSystemGenerated={this.props.isSystemGenerated}
            selectedProjectDetails={selectedProjectDetails}
            userInformation={userInformation}
            dDownList={this.state.dDownList}
            ioApprover={this.state.ioApprover}
            isIOApproverSelected={this.state.isIOApproverSelected}
            selectedClient={selectedClient}
            userRole={this.state.userRole}
            onAddButton={this.onAddButton}
            isValidNewUser={this.props.isValidNewUser}
            cmErrorMessage={this.props.cmErrorMessage}
            setCmErrorMessage={this.props.setCmErrorMessage}
            isValidMappedFields={this.state.isValidMappedFields}
            isProfileExisting={this.props.isProfileExisting}
            isAssignedProjectApprover={this.props.isAssignedProjectApprover}
            isPPCBA={isExistingBA || isExistingPPC}
            invalidDesc={this.props.invalidDesc}
            setInvalidDesc={this.props.setInvalidDesc}
            invalidProjectNameErrorType={this.state.invalidProjectNameErrorType}
            isValidIoApprover={this.props.isValidIoApprover}
            isValidProjectCreator={this.props.isValidProjectCreator}
          />;
    }
  }

  return AddNewProjectPopupContainer;
})();

export default connect(
  (state) => {
    return {
      userInformation: state.userInformation,
      ProjectConfigurationData: state.ProjectConfigurationData,
      ProgramConfigurationData: state.ProgramConfigurationData,
      SharedData: state.SharedData,
      ConfigureProgramData: state.ConfigureProgramData
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setNewProjectDetails,
          isProfileExisting
        }
      ),
      dispatch
    )
)(AddNewProjectPopup);
