//Redux Reducers

import { combineReducers } from 'redux';
// import { routerReducer } from "react-router-redux";

import getData from './getData';
import userInformation from './UserInformation';
import projectListData from './ProjectList';
import { ClientConfigurationData } from './ClientConfiguration.reducer';
import { SharedData } from './Shared.reducer';
import { ProgramList, ProjLeadList, ProjDelivLocList, ProjDelivByList } from './Client.Reducer';
import { StageData } from './Stage.reducer';
import { UserManagementData } from './UserManagement.reducer';
import { ConfigureProgramData } from './ConfigureProgram.reducer';
import { DataIngestionData } from './DataIngestion.reducer';
import { DashboardData } from './Dashboard.reducer';
import { HomeData } from './Home.reducer';
import { AccessValidationData } from './AccessValidation.reducer';
import { ProgramConfigurationData } from './ProgramConfiguration.reducer';
import { ProjectConfigurationData } from './ProjectConfiguration.reducer';
import { NotificationData } from './Notification.reducer';

const rootReducer = combineReducers({
  //HOME
  HomeData,

  userInformation,
  getData,
  projectListData,
  ClientConfigurationData,
  SharedData,
  StageData,

  //CLIENT
  ProgramList,
  ProjLeadList,
  ProjDelivLocList,
  ProjDelivByList,

  //USER MANAGEMENT
  UserManagementData,

  //CONFIGURE PROGRAM
  ConfigureProgramData,

  //DATA INGESTION
  DataIngestionData,

  //DASHBOARD
  DashboardData,

  //ACCESS VALIDATION
  AccessValidationData,

  //PROGRAM PROJECT CONFIGURATION
  ProgramConfigurationData,
  ProjectConfigurationData,

  //NOTIFICATION
  NotificationData,
});

export default rootReducer;
