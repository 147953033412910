import React, { Component } from "react";
/* import Select, { components } from "react-select";
import { Modal, Container, Button, Row } from "react-bootstrap";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight } from "@fortawesome/free-solid-svg-icons"; */
import { connect } from "react-redux";
import {
  // GrooveTabComponent,
  // GrooveIconSvg,
  GrooveButton,
  // GrooveInput,
  GrooveTextBox,
  GrooveSelectMenuBasic,
} from "tfo-groove";
// import { Accordion, Card } from "react-bootstrap";

import CVMMultiSelectDropdown from "../../Shared/CVMMutiSelectDropdown/CVMMutiSelectDropdown";
import CVMAutoMultiSelectDropdownWithCheckBoxAndPaste from "../../Shared/CVMAutoMultiSelectDropdownWithCheckBoxAndPaste/CVMAutoMultiSelectDropdownWithCheckBoxAndPaste";
import { bindActionCreators } from "redux";
import { IsValidText } from "../../../Utilities/Validations";


require("./CVMSingleApprovalRequestModal.css");

const CVMSingleApprovalRequestModal = (function () {
  class CVMSingleApprovalRequestModalPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="ApprovalRequestModal">
            {this.props.type === "Submit Final" ?
              <p className="submit-question">
                Are you sure you want to submit the Project Data Entries?
              </p>
              : ("")}
            <div className={this.props.role === "CXLLead" || this.props.role === "OperationsAccountLead" ?
              "" : "notifyModal-dropdown"}>
              {
                this.props.role === "CXLLead" || this.props.role === "OperationsAccountLead" ?
                  <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                    name={this.props.label}
                    isDisabled={false} //
                    placeholder={this.props.label} //
                    options={this.props.clientList} //
                    value={this.props.selectedDropdownValue} //
                    id={this.props.label}
                    onChange={(e) => this.props.handleUpdateReceiverDetails(e, this.props.role)}
                    activePaste={false}
                  />
                  : this.props.type === "Submit Reject" ?
                    <CVMMultiSelectDropdown
                      value={this.props.selectedDropdownValue}
                      isDisabled={true}
                      label={this.props.label}
                    />
                    : this.props.type === "Submit Final" ?
                      <div></div>
                      : <GrooveSelectMenuBasic
                        name={this.props.label}
                        isDisabled={this.props.isDropdownDisabled}
                        placeholder={this.props.label}
                        list={this.props.clientList}
                        defaultData={this.props.selectedDropdownValue}
                        id={this.props.label}
                        callbackFunction={(e) => this.props.handleClickDropdown(e)}
                      />
              }
            </div>
            <div className="comments">
              <GrooveTextBox
                maxLength={255}
                id="comment"
                name="comment"
                minLength={20}
                type="text"
                placeholder={`${(this.props.comment !== "") ? "" : "Enter "}Comment`}
                callbackFunction={(e) => this.props.onCommentChange(e)}
                customClassName={`${this.props.invalidComment ? 'ApprovalRequestModal-invalid-comment': ''}`}
              />
            </div>
            {
              this.props.invalidComment &&
                <div className="missing" style={{paddingLeft: '8px'}}>Invalid comment</div>
            }
            {
              this.props.showError.show ?
                <div className="missing">{this.props.showError.message}</div>
                :
                ""
            }
            <div className="notifyModal-buttons add-program-buttons">
              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="outline"
                colorClass="stat-alternate"
                size="auto"
                text="Cancel"
                callbackFunction={this.props.onCloseModal}
              />

              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={this.props.disableSendBtn || this.props.invalidComment}
                hasIcon={false}
                type="solid"
                colorClass="stat-alternate"
                size="auto"
                text={this.props.actionType}
                callbackFunction={(e) => this.props.onSend(e)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  class CVMSingleApprovalRequestModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        comment: '',
        invalidComment: false
      };
    }

    componentDidMount = () => {
      try {

      }
      catch (e) {
        console.log(e)
      }
      this.changeTextAreaClass()
    };

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    onCommentChange = (e) => {
      this.setState({
        comment: e.value
      });
      if (!IsValidText(e.value)) {  
        this.setState({
          invalidComment: true
        });
      } else {
        this.setState({
          invalidComment: false
        });
      }
      try {
        this.props.onChangeTextBox(e);
      }
      catch (e) {
        console.log(e)
      }
    }

    handleUpdateReceiverDetails = (e, role) => {
      try {
        this.props.handleUpdateReceiverDetails(e, role)
      }
      catch (e) {
        console.log(e)
      }
    }

    render() {
      return (
        <CVMSingleApprovalRequestModalPresentational
          onSend={this.props.onSend}
          selectedDropdownValue={this.props.selectedDropdownValue}
          isDropdownDisabled={this.props.isDropdownDisabled}
          value={this.props.options}
          handleClickDropdown={this.props.handleClickDropdown}
          onCommentChange={this.onCommentChange}
          onCloseModal={this.props.onCloseModal}
          clientList={this.props.clientList}
          label={this.props.label}
          actionType={this.props.actionType}
          type={this.props.type}
          comment={this.state.comment}
          disableSendBtn={this.props.disableSendBtn || false}
          role={this.props.role || ""}
          handleUpdateReceiverDetails={this.handleUpdateReceiverDetails}
          showError={this.props.showError}
          invalidComment={this.state.invalidComment}
        />
      );
    }
  }

  return CVMSingleApprovalRequestModalContainer;
})();

export default connect(
  (state) => {
    return {
      showError: state.SharedData.showError
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(CVMSingleApprovalRequestModal);
