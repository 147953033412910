import React, { Component } from "react";

const Loading = () => {
    return (
            <div className="loading-animation">
                <div className="lds-ripple"><div></div><div></div></div>
                <div className="loading-text">Authentication in progress</div>
            </div>
        )
}

export default Loading;