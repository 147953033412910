import CryptoJS from 'crypto-js';
import { GetToken } from './UserSession';
import { ParseJwt } from './Formatter';

import { HasValue } from './Validations';
import Utilities from "./index";

const Cipher = function () {
	try {
		//Initialize Values
		var cipherConfig = Utilities.keys();
		var keySize = cipherConfig.keySize;
		var ivSize = cipherConfig.ivSize;
		var saltSize = cipherConfig.saltSize;
		var iterations = cipherConfig.iterations;
		// var passPhrase = ParseJwt(GetToken()).uti;

		var encryptText = async function (strText) {
			var token = await GetToken()
			var passPhrase = token === undefined ? '' : ParseJwt(token).uti;
			var salt = CryptoJS.lib.WordArray.random(saltSize / 8);
			var key = CryptoJS.PBKDF2(passPhrase, salt, {
				keySize: keySize / 32,
				iterations: iterations
			});
			var iv = CryptoJS.lib.WordArray.random(ivSize / 8);
			var encrypted = CryptoJS.AES.encrypt(strText, key, {
				iv: iv,
				padding: CryptoJS.pad.Pkcs7,
				mode: CryptoJS.mode.CBC

			});
			var encryptedHex = base64ToHex(encrypted.toString());
			var base64result = hexToBase64(salt + iv + encryptedHex);
			return base64result;
		}

		var decryptText = async function (strText, key) {
			var token = await GetToken()
			var passPhrase = token === undefined ? '' : ParseJwt(token).uti;
			var hexResult = base64ToHex(strText)
			var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
			var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
			var encrypted = hexToBase64(hexResult.substring(96));
			key = CryptoJS.PBKDF2(HasValue(key) ? key : passPhrase, salt, {
				keySize: keySize / 32,
				iterations: iterations
			});
			var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
				iv: iv,
				padding: CryptoJS.pad.Pkcs7,
				mode: CryptoJS.mode.CBC

			})

			return decrypted.toString(CryptoJS.enc.Utf8);
		}

		var hexToBase64 = function (str) {
			return btoa(str.match(/\w{2}/g).map(function (a) {
				return String.fromCharCode(parseInt(a, 16));
			}).join(""));
		}

		var base64ToHex = function (str) {
			for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
				var tmp = bin.charCodeAt(i).toString(16);
				if (tmp.length === 1) tmp = "0" + tmp;
				hex[hex.length] = tmp;
			}
			return hex.join("");
		}

		return {
			EncryptText: encryptText,
			DecryptText: decryptText
		};
	} catch { }
}();

export default Cipher;

