import {
    SET_COMPONENT_ACCESS_RVD,
    SET_COMPONENT_ACCESS_PnP
} from '../Constants'

const initialState = {
    RVDComponents: {
        //answers question is component disabled
        reportProjectData: true,
        selectProject: true,
        rvdParams: true,
        computeButton: true,
        approveRejectButton: true,
        sadButton: true,
        sfaButton: true,
        subRejectButton: true,
        submitButton: true,
        upDownLoad: true,
        landingPage: true,
    },
    PnPComponents: {
        //answers question is component disabled
        landingPage: true, //Config Module landing page
        sadButton: true,
        sfaButton: true,
        program: {
            add: true, //Add New Program
            boiButton: true, //Add/ Edit BOIs
            idValueSetting: true, //Program Identified Value Settings
            acctLeadApprv: true, //Program Identified Value - Account Leadership Approval
            clntAlgnmnt: true, //Program Identified Value - Client Alignment
            projectDtls: true, //Project Details Page
        },
        project: {
            add: true, //Add New Project
            boiButton: true, //Select BOIs
            idValueSetting: true, //Project Identified Value Settings
            acctLeadApprv: true, //Project Identified Value - Account Leadership Approval
            clntAlgnmnt: true, //Project Identified Value - Client Alignment
        }
    }
}

export const AccessValidationData = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case SET_COMPONENT_ACCESS_RVD:
            return {
                ...state,
                RVDComponents: action.RVDComponents
            }
        case SET_COMPONENT_ACCESS_PnP:
            return {
                ...state,
                PnPComponents: action.PnPComponents
            }
        default:
            return state
    }
}