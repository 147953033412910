import React, { Component } from "react";
import { setUserInformation } from "../../Actions/index";
import { connect } from "react-redux";


const _Report = (function(props) {
  class ReportPresentational extends Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      return <div />;
    }
  }

  class ReportContainer extends Component {
    render() {
      return <ReportPresentational />;
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      SetUserInformation: (userInfo) => dispatch(setUserInformation(userInfo)),
    };
  }
  function mapStateToProps(state) {
    return {
      userInformation: state.userInformation,
    };
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportContainer);
})();

export default _Report;
