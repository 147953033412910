import React, { Component } from "react";
import { Nav, NavItem, Dropdown } from "react-bootstrap";

export default class NavigationPage extends Component {
  render() {
    return (
      <Nav>
        <NavItem eventKey={1} href="#" className="menu-nav">
          Link
        </NavItem>
        <NavItem eventKey={2} href="#" className="menu-nav">
          Link
        </NavItem>
        <Dropdown
          eventKey={3}
          title="Dropdown"
          id="basic-nav-dropdown"
          className="menu-nav"
        >
          <Dropdown.Item eventKey={3.1}>Action</Dropdown.Item>
          <Dropdown.Item eventKey={3.2}>Another action</Dropdown.Item>
          <Dropdown.Item eventKey={3.3}>Something else here</Dropdown.Item>
          <Dropdown.Item divider />
          <Dropdown.Item eventKey={3.4}>Separated link</Dropdown.Item>
        </Dropdown>
      </Nav>
    );
  }
}
