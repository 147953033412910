import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveIconSvg, GrooveButton, GrooveTextBox, GrooveSwitchToggle } from 'tfo-groove';
import { Accordion, Card, Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactTooltip from 'react-tooltip';

import {
  HasValue,
  IsValidStr,
  HasProperty,
} from '../../../../Utilities/Validations';
import {
  TryGetObjValueAlt,
  FormatCVMParamValue
} from '../../../../Utilities/Formatter';
import ApproveRejectRadioButton from '.././ApproveRejectRadioButton';

import {
  handleClientValueIdentified,
  setRVDSelectedTabs,
} from '../../../../Actions/ConfigureProgram.Action';


import CVMInput from '../../../Shared/CVMInput/CVMInput';

import newInfo from '../../../../Static/images/svg/new-info.svg';
import emptyFolder from '../../../../Static/images/360cvm/empty-folder.svg';

const ClientValueIdentifiedNonUSD = (function () {
  class ClientValueIdentifiedNonUSDPresentational extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      const {
        showNotifMessageArr,
        dItem,
        dIndex,
        inUSD,
        notInUSD,
        activeExpand,
        onExpandCollapse,
        activeCollapse,
        onSelectAccordion,
        basicDetails,
        isSuperAdmin,
        RVDComponents,
        onClickCustomBOI,
        isComment,
        handleBOIComment,
        handleBOICommentHistory,
        setIsReject,
        currentUserRoleID,
        submitReject,
        tempReason,
        updateReason,
        handleParameterChange,
        isIOAdmin,
        isExistingFinancial,
        disableSuperAdmin,
        setTab,
        rvdSelectedTabs,
        isRejected,
        isAwaiting,
        isApproved,
        isApprovedAndYetToEdit,
        isPPC,
        isBA,
        handleChange,
        formatValue,
        SDItem,
        SDIndex,
        selSDItem,
        selTabUSD,
      } = this.props;

      const plSignOffMsg = 'BOIs with $ UOM selected under Financial Dimension will be Considered as client P&L Sign-Off. $ value under all other Dimensions except Financial Dimension, will not be considered as Client P&L Sign-Off.';
      const onDashboardMsg = 'Show this BOI on the Value Meter Dashboard?';
      const reasonMsg = 'Reject Reason is required';
      const awaiting = 'awaiting approval';
      const rejected = 'rejected';
      const tabInDollar = 'Client P&L Impacting BOIs - Measured in Dollar';
      const tabNotDollar = 'Client P&L Impacting BOIs - Not measured in Dollar';
      const deltaTooltip = 'Target Value - Value Impacting Client P&L';
      const deltaTooltipNonUSD = 'Target Value - Realized Value';
      const realizedTooltip = 'Value Impacting Client P&L';
      const noBOI = 'No Business Outcome Indicators Available';
      const noBOIDesc = 'This dimension doesn\'t have any BOI(s) available under this category';
      const cviMsg = '* Autogenerated based on Value Impacting Client P&L';
      let isApproverRejectedView = (isAwaiting && !RVDComponents.approveRejectButton) || isRejected ? "rvd-fin-approver-rejected-non-usd" : "";

      const dimName = dItem.dimensionName.replace(/\s/g, '').replace(/&/g, '').toLowerCase();
      const {
        sdIndex,
        hadFinalized,
        colWidth,
        colStatus
      } = selSDItem;

      // Column Width based on Status & User
      const awappFirstPDE = isApproverRejectedView && !hadFinalized; //AWAPP on new pde 
      const awappApprOnce = isApproverRejectedView && hadFinalized; //AWAPP on new pde 
      const apprInProgApprOnce = !isApproverRejectedView && hadFinalized //appr, in prog 
      const apprInProgNewPDE = !isApproverRejectedView && !hadFinalized //appr, in prog

      return (
        <Accordion
          className="subdimension-section-collapse"
          defaultActiveKey={isIOAdmin ? (isExistingFinancial ? '0' : '1') : '0'}
          onSelect={(e) => onSelectAccordion(e)}
        >
          <Card>
            <Accordion.Collapse
              eventKey={isIOAdmin && isExistingFinancial ? dIndex.toString() : '0'}
            >
              <Card.Body>
                <div key={SDItem.sdIndex} className="rvd-subdimension-table-wrap">
                  <div className="subdimention-tabs">
                    <div
                      onClick={() => setTab(0, SDItem.subDimensionname)}
                      className={selTabUSD ? 'active-tab' : ''}
                    >
                      {tabInDollar}
                    </div>
                    <div
                      onClick={() => setTab(1, SDItem.subDimensionname)}
                      className={!selTabUSD ? 'active-tab' : ''}
                    >
                      {tabNotDollar}
                    </div>
                  </div>
                  {/* table start*/}
                  <div className="subdimension-table rvd-subdimension-table rvd-financial">
                    {/* header start*/}
                    <div className="subdimension-header">
                      <div className="row">
                        <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || awappFirstPDE || awappApprOnce || apprInProgApprOnce || apprInProgNewPDE ? '3' : colWidth.boi} fin-non-usd`}>
                          Business Outcome Indicator
                        </div>
                        <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || awappApprOnce || awappFirstPDE ? '2' : isRejected || apprInProgNewPDE ? '1' : colWidth.targetValue} fin-non-usd`}>
                          Target Value
                        </div>
                        {hadFinalized ?
                          <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || apprInProgApprOnce || apprInProgNewPDE ? '2' : colWidth.realizedValueApp} fin-non-usd`}>
                            {`${'Realized Value'} (Approved)`}
                            <a data-tip data-for="realizedApproved">
                              <div className="info-icon">
                                <GrooveIconSvg
                                  size="large"
                                  name="info-circle"
                                  iconStyle="solid"
                                  iconColor="white"
                                />
                              </div>
                            </a>
                            <ReactTooltip id="realizedApproved" type="dark" effect="solid">
                              <span>{'Realized Value'}</span>
                            </ReactTooltip>
                          </div>
                          : ""
                        }
                        <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || apprInProgApprOnce || apprInProgNewPDE ? '2' : colWidth.realizedValueNew} fin-non-usd`}>
                          {`${'Realized Value'}${hadFinalized ? ' (New)' : ''}`}
                          <a data-tip data-for="realized">
                            <div className="info-icon">
                              <GrooveIconSvg
                                size="large"
                                name="info-circle"
                                iconStyle="solid"
                                iconColor="white"
                              />
                            </div>
                          </a>
                          <ReactTooltip id="realized" type="dark" effect="solid">
                            <span>{'Realized Value'}</span>
                          </ReactTooltip>
                        </div>
                        <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || apprInProgApprOnce || apprInProgNewPDE ? '2' : colWidth.targetRealizeValue} target-realized-${colStatus} fin-non-usd`}>
                          {`Delta${hadFinalized ? ' (New)' : ''}`}
                          <a data-tip data-for="delta">
                            <div className="info-icon">
                              <GrooveIconSvg
                                size="large"
                                name="info-circle"
                                iconStyle="solid"
                                iconColor="white"
                              />
                            </div>
                          </a>
                          <ReactTooltip id="delta" type="dark" effect="solid">
                            <span>{deltaTooltipNonUSD}</span>
                          </ReactTooltip>
                        </div>
                        <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) ? '2' : awappFirstPDE || apprInProgNewPDE || apprInProgApprOnce ? '1' : colWidth.wheelVisibility} wVis-${colStatus} dashboard-visibility-rvd fin-non-usd`}>
                          Dashboard Visibility
                          <a data-tip data-for="sadFace">
                            <div className="info-icon">
                              <GrooveIconSvg
                                size="large"
                                name="info-circle"
                                iconStyle="solid"
                                iconColor="white"
                              />
                            </div>
                          </a>
                          <ReactTooltip id="sadFace" type="dark" effect="solid">
                            <span>{onDashboardMsg}</span>
                          </ReactTooltip>
                        </div>
                        <div className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} fin-non-usd`}>
                          BOI Comment
                        </div>
                        {isAwaiting && !RVDComponents.approveRejectButton ?
                          <div className={`col-sm-${awappApprOnce ? '2' : '3'} app-rej-${colStatus} fin-non-usd heading`}>
                            Approver Section
                          </div>
                          : ""}
                        {isRejected ?
                          <div className={`col-sm-2 pde-comment-${colStatus} fin-non-usd`}>
                            Rejection Comment
                          </div>
                          : ""}
                      </div>
                    </div>

                    {/* header end*/}
                    {/* body start*/}
                    <div className="subdimension-body">
                      {selSDItem.boiList.length === 0 ?
                        <div className="empty-dimension-container">
                          <div className="empty-program-container bodytext18-regular-slate">
                            <img src={emptyFolder} alt="no boi" />
                            <span className="bodytext24-medium-slate">
                              {noBOI}
                            </span>
                            {noBOIDesc}
                          </div>
                        </div>
                        : ""}
                      {selSDItem.boiList.map((boiItem) => {
                        const boiIndex = boiItem.boiIndex;
                        const itemId = `${dIndex}_${sdIndex}_${boiIndex}_${boiItem.boiUOM}`;
                        return (
                          <Accordion
                            className="subdimension-row"
                            defaultActiveKey="0"
                          >
                            <Card className={
                              `${boiItem.projectLevelBOIStatus.toLowerCase() !== 'rejected' ? 'approved-project-level' : 'rejected-project-level'} ${boiItem.boiStatus === 'Rejected' ? 'error-card' : ''}`
                            }>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <div className="bodytext14-medium">
                                    Parameters
                                  </div>
                                  {boiItem.parameterList.sort((a, b) => {
                                    if (boiItem.IsCustom) {
                                      return a.parameterCustomCode.localeCompare(b.parameterCustomCode, undefined, {
                                        numeric: true,
                                        sensitivity: 'base'
                                      })
                                    } else {
                                      return a.parameterCode.localeCompare(b.parameterCode, undefined, {
                                        numeric: true,
                                        sensitivity: 'base'
                                      })
                                    }
                                  }).map((paramItem, paramIndex) => {
                                    const targetParam = {
                                      dimension: {
                                        item: dItem,
                                        index: dIndex,
                                      },
                                      subDimension: {
                                        item: SDItem,
                                        index: sdIndex,
                                      },
                                      boi: {
                                        item: boiItem,
                                        index: boiIndex,
                                      },
                                      parameter: {
                                        item: paramItem,
                                        index: paramIndex,
                                      },
                                    };
                                    return (
                                      <div key={paramIndex}>
                                        <div className={`parameter-field ${''}`}>
                                          <div className={`field-text ${isAwaiting ? 'rejected-status' : ''}`}>{paramItem.parameterName}</div>
                                          <div className="field-value">
                                            <div>
                                              <CVMInput
                                                type="text"
                                                id="text1"
                                                placeholder={'Realized Value'}
                                                customClassName={paramItem.isInvalid ? 'error' : ''}
                                                inputValue={
                                                  paramItem.parameterValue === null
                                                    ? '' : paramItem.parameterValue
                                                }
                                                onBlurCallbackFunction={(e) => {
                                                  if (!this.props.editedAnyFieldOnce && this.props.overallStatus === 'Approved') {
                                                    this.props.checkForInvalidEmailsOnLanding();
                                                    this.props.setEditedAnyFieldOnce(true);
                                                  }
                                                  handleParameterChange(
                                                  targetParam,
                                                  e.target.value,
                                                  'blur');
                                                }} 
                                                callbackFunction={(e) => {
                                                  if (!this.props.editedAnyFieldOnce && this.props.overallStatus === 'Approved') {
                                                    this.props.checkForInvalidEmailsOnLanding();
                                                    this.props.setEditedAnyFieldOnce(true);
                                                  }
                                                  handleParameterChange(
                                                  targetParam,
                                                  e.target.value
                                                  );
                                                }}
                                                isDisabled={RVDComponents.rvdParams || disableSuperAdmin}
                                                maxLength={255}
                                              />
                                              <div className='param-uom'>{paramItem.parameterUOM}</div>
                                            </div>
                                            {paramItem.isInvalid ?
                                              <div className="param-char-length-err">
                                                {paramItem.invalidMsg}
                                              </div>
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                  )}
                                  {boiItem.projectLevelBOIStatus.toLowerCase() === 'rejected' ?
                                    <div className='rejected-projBoi-warning'>
                                      <GrooveIconSvg
                                        className="exclamation-icon"
                                        size="normal"
                                        name="exclamation"
                                        iconStyle="solid"
                                      />
                                      <div>This BOI is rejected at Program/Project configuration level and values reported will neither be shown in the wheel nor will be included for Reporting</div>
                                    </div>
                                    : ""}
                                  {
                                    <div>
                                      {TryGetObjValueAlt(boiItem, false, 'realizedValInvalid') && boiItem.boiUOM.toLowerCase() === 'usd' ? (
                                        <div className='rejected-projBoi-warning' id="invalid-cumulative">You are expected to enter cumulative value, kindly review the value and re-compute or reach out to SVM.admin@accenture for any support</div>)
                                        : ""}
                                    </div>
                                  }
                                </Card.Body>
                              </Accordion.Collapse>
                              <Card.Header>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                >
                                  <GrooveIconSvg
                                    customClassName="accordion-button"
                                    size="tiny"
                                    name="chevron-right"
                                    iconStyle="solid"
                                    iconColor="stat-neutral"
                                  />
                                </Accordion.Toggle>
                                <div className="row bodytext14">
                                  <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || awappFirstPDE || awappApprOnce || apprInProgApprOnce ? '3' : colWidth.boi} fin-non-usd`}>
                                    {boiItem.boiName}
                                    <OverlayTrigger
                                      key="right"
                                      placement="auto"
                                      overlay={
                                        <Tooltip id="boi-tooltip-right">
                                          <div className="boi-details-wrap row">
                                            <div class="col-md-6">
                                              <h6>Offering</h6>
                                              <p>
                                                {boiItem.offeringName
                                                  ? boiItem.offeringName
                                                  : ''}
                                              </p>
                                              <h6>Dimension</h6>
                                              <p>{dItem.dimensionName}</p>
                                              <h6>BOI Description</h6>
                                              <p>{boiItem.boiDescription}</p>
                                            </div>
                                            <div class="col-md-6">
                                              <h6>Sub-Offering</h6>
                                              <p>
                                                {boiItem.subofferingName
                                                  ? boiItem.subofferingName
                                                  : ''}
                                              </p>
                                              <h6>Sub-dimension</h6>
                                              <p>{SDItem.subDimensionname}</p>
                                              <h6>Leading Practice</h6>
                                              <p>{boiItem.leadingIndicator}</p>
                                              <table>
                                                <tr class="col-md-12">
                                                  <th class="col-md-6">
                                                    <h6>Parameter</h6>
                                                  </th>
                                                  <th class="col-md-6">
                                                    <h6>Unit of Measure</h6>
                                                  </th>
                                                </tr>
                                                {(HasProperty(boiItem, 'parameterList')
                                                  ? boiItem.parameterList : []
                                                ).map((params) => {
                                                  return (
                                                    <tr>
                                                      <td class="col-md-6">
                                                        <p>{params.parameterName}</p>
                                                      </td>
                                                      <td class="col-md-6">
                                                        <p>{params.parameterUOM}</p>
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                              </table>
                                            </div>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="rvdboi-new-info">
                                        {!boiItem.IsCustom ?
                                          <img
                                            className="new-info-icon"
                                            src={newInfo}
                                            alt="info"
                                          />
                                          : ""}
                                      </div>
                                    </OverlayTrigger>
                                    {boiItem.IsCustom ?
                                      <span>
                                        <button
                                          className="custom-boi"
                                          style={{ marginLeft: '1em' }}
                                          onClick={() => onClickCustomBOI(
                                            boiItem.boiID,
                                            true
                                          )}
                                        >
                                          Custom
                                        </button>
                                      </span>
                                      : ""}
                                    <div className="projectboi-rejected-alert">
                                      {boiItem.projectLevelBOIStatus.toLowerCase() === 'rejected' ?
                                        <OverlayTrigger
                                          key="bottom"
                                          placement="bottom"
                                          overlay={
                                            <Tooltip id="rvd-disabled-sys">
                                              Value reported will not be reflected in the dashboard as this BOI is rejected at program/project level.
                                            </Tooltip>
                                          }
                                        >
                                          <div>
                                            <GrooveIconSvg
                                              customClassName="dark-red-alert"
                                              size="small"
                                              name="exclamation-triangle"
                                              iconStyle="solid"
                                              iconColor="stat-alternate"
                                            />
                                          </div>
                                        </OverlayTrigger>
                                        : ""}
                                    </div>
                                  </div>
                                  <div
                                    className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || awappApprOnce || awappFirstPDE ? '2' : isRejected ? '1' : colWidth.targetValue} target-value-padding targetValue-${colStatus} fin-non-usd`}
                                    style={
                                      boiItem.isZeroTarget && { display: 'block' }
                                    }
                                  >
                                    <div className={
                                      boiItem.isZeroTarget &&
                                      boiItem.boiTarget === 0 &&
                                      'zero-target-value'
                                    }>
                                      {formatValue(
                                        `${itemId}_TA`,
                                        boiItem.boiTargetApproved,
                                        boiItem.boiUOM
                                      )}
                                    </div>
                                    <div className={
                                      boiItem.isZeroTarget &&
                                      boiItem.boiTarget === 0 &&
                                      'zero-target-value-msg'
                                    }>
                                      {boiItem.zeroTargetMessage}
                                    </div>
                                  </div>
                                  {boiItem.boiApprovedRealizedValue !== null ?
                                    <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || apprInProgApprOnce ? '2' : colWidth.realizedValueApp} realized-value-${colStatus} fin-non-usd`}>
                                      {formatValue(
                                        `${itemId}_ARV`,
                                        boiItem.boiApprovedRealizedValue,
                                        boiItem.boiUOM
                                      )}
                                    </div>
                                    : ""}
                                  <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || apprInProgApprOnce ? '2' : colWidth.realizedValueNew} realizedValue-new-${colStatus} fin-non-usd`}>
                                    <span className={`${TryGetObjValueAlt(
                                      boiItem,
                                      false,
                                      'realizedValInvalid'
                                    ) && 'rvd-invalid-realizedval'
                                      }`}>
                                      {formatValue(
                                        `${itemId}_RV`,
                                        isApprovedAndYetToEdit
                                          ? null
                                          : boiItem.boiRealizedValue,
                                        boiItem.boiUOM
                                      )}
                                    </span>
                                  </div>
                                  <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) || apprInProgApprOnce ? '2' : colWidth.targetRealizeValue} target-realized-${colStatus} fin-non-usd`}>
                                    {formatValue(
                                      `${itemId}_VD`,
                                      boiItem.boiValueDiff,
                                      boiItem.boiUOM
                                    )}
                                  </div>
                                  <div className={`col-sm-${(!isApproverRejectedView && !hadFinalized) ? '2' : awappFirstPDE || apprInProgApprOnce ? '1' : colWidth.wheelVisibility} wVis-${colStatus} fin-non-usd`}>
                                    <GrooveSwitchToggle
                                      id={'switch-tgl-01' + boiIndex}
                                      toggle_labels=""
                                      defaultValue={
                                        boiItem.projectBOIWheelVisibility === true || boiItem.boiWheelVisivility === 1
                                          ? true : false
                                      }
                                      isDisabled={true}
                                      customClassName="wheel-visibility-button disabled-switch"
                                    />
                                  </div>
                                  <div className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} fin-non-usd`}>
                                    <GrooveIconSvg
                                      customClassName="commentary-modal-btn"
                                      size="large"
                                      name="comment"
                                      iconStyle={
                                        boiItem.RVDCommentHistory.length > 0
                                          ? 'solid' : 'regular'
                                      }
                                      iconColor={
                                        isComment
                                          ? boiItem.RVDCommentHistory.length > 0
                                            ? 'stat-alternate'
                                            : 'stat-warning'
                                          : 'stat-alternate'
                                      }
                                      callbackFunction={() =>
                                        !isPPC && !isBA && !isSuperAdmin ?
                                          handleBOICommentHistory(
                                            true,
                                            boiItem,
                                            SDItem.boiList
                                          )
                                          : RVDComponents.approveRejectButton ||
                                            boiItem.isNewValue ||
                                            (isPPC || isBA || isSuperAdmin)
                                            ? handleBOIComment(true, boiItem)
                                            : handleBOICommentHistory(
                                              true,
                                              boiItem,
                                              SDItem.boiList
                                            )
                                      }
                                    />
                                  </div>
                                  {((!RVDComponents.approveRejectButton && isAwaiting) ||
                                    isRejected) ?
                                    <div className={`col-sm-${awappApprOnce ? 2 : (isAwaiting) ? 3 : 2} app-rej-${colStatus} fin-non-usd`}>
                                      <div className="rvd-rejection-comment">
                                        {isAwaiting &&
                                          !RVDComponents.approveRejectButton ?
                                          <ApproveRejectRadioButton
                                            boiID={boiItem.boiID}
                                            boiStatus={boiItem.boiStatus}
                                            dIndex={dIndex}
                                            SDIndex={sdIndex}
                                            boiIndex={boiIndex}
                                            isRejected={setIsReject}
                                            userRoleID={currentUserRoleID}
                                            dimensionName={dItem.dimensionName}
                                            boiUOM={boiItem.boiUOM}
                                            currentUserRoleID={currentUserRoleID}
                                          />
                                          : ""}
                                        {boiItem.boiStatus === 'Rejected' ?
                                          <GrooveTextBox
                                            maxLength={250}
                                            id="comment-textbox-rejected"
                                            name="comment-textbox-rejected"
                                            type="text"
                                            placeholder="Rejection Comment*"
                                            statusMessage={
                                              submitReject && boiItem.boiReason === ''
                                                ? reasonMsg : null
                                            }
                                            isValid={isRejected ? false : (boiItem.boiReason !== '')}
                                            customClassName={
                                              submitReject && boiItem.boiReason === ''
                                                ? 'input-invalid' : null
                                            }
                                            textValue={boiItem.boiReason}
                                            callbackFunction={(e) => updateReason(e, {
                                              dimensionIndex: dIndex,
                                              subDimensionIndex: sdIndex,
                                              boiIndex: boiIndex,
                                            })}
                                            isDisabled={
                                              basicDetails.overallStatus.toLowerCase() !== awaiting
                                            }
                                          />
                                          : ""}
                                      </div>
                                    </div>
                                    : ""}
                                </div>
                              </Card.Header>
                            </Card>
                          </Accordion>
                        );
                      })}
                    </div>

                    {/* body end*/}
                  </div>
                  {/* table end*/}
                </div>
              </Card.Body>
            </Accordion.Collapse>
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={
                  isIOAdmin && isExistingFinancial ? dIndex.toString() : '0'
                }
              >
                <div className="pde-subdimension-name bodytext18-medium-midnight">
                  {SDItem.subDimensionname}
                </div>
                <GrooveIconSvg
                  customClassName="accordion-button"
                  size="large"
                  name="chevron-down"
                  iconStyle="solid"
                  iconColor="stat-neutral"
                />
              </Accordion.Toggle>
            </Card.Header>
          </Card>
        </Accordion >
      );
    }
  }

  class ClientValueIdentifiedNonUSDContainer extends Component {
    constructor(props) {
      super(props);
    }

    // componentDidMount = () => {
    //   const { dItem } = this.props;
    //   let rvdSelectedTabs = {
    //     capital: 0,
    //     opex: 0,
    //     revenue: 0,
    //   };

    //   dItem.subDimensionList.forEach((subDim) => {
    //     let subDimName = subDim.subDimensionname.toLowerCase();
    //     let hasUSD = false, hasNotUSD = false;
    //     subDim.boiList.forEach((boi) => {
    //       if (boi.boiUOM === 'USD') {
    //         hasUSD = true;
    //       } else {
    //         hasNotUSD = true;
    //       };
    //     });
    //     if (!hasUSD && hasNotUSD) rvdSelectedTabs[subDimName] = 1;
    //   });

    //   this.props.setRVDSelectedTabs(rvdSelectedTabs);
    // }

    render() {
      return (
        <ClientValueIdentifiedNonUSDPresentational
          {...this.props}
        />
      );
    }
  }

  return ClientValueIdentifiedNonUSDContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      UserInformationData: state.userInformation,
      SharedData: state.SharedData
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {
    handleClientValueIdentified,
    setRVDSelectedTabs,
  }), dispatch)
)(ClientValueIdentifiedNonUSD);
