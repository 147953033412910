import React, { Component } from 'react';
import { GrooveIconSvg } from 'tfo-groove';
import ProgramProjectHeader from '../ProgramProjectHeader';
import Stepper from '../Stepper';
import { Row, Col } from 'react-bootstrap';
import CACompleteDocUploadPopUp from '../CACompleteDocUploadPopUp';
import ClientConfirmationDocs from '../ClientConfirmationDocs';
// import { history } from "../../../store";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setProgUploadFlag,
  setProgUploadFiles,
  getProgramList,
  setProgUploadComment,
} from '../../../Actions/ProgramConfiguration.Action';
import { HttpPostWithFileString, HttpGet, GetSubMenuId } from '../../../Utilities/HTTPUtil';
import CVMToasterNotif from '../../Shared/CVMToasterNotif/CVMToasterNotif';

import { setToasterParam } from '../../../Actions/Shared.Action';
import './ProgramClientAlignment.css';
import Bell from '../../../Static/images/360cvm/BellFade.svg';
import { ACC_PROG, PROG_ID_VAL_CA } from '../../../Constants/Modules';
import { LogUserSiteAction } from '../../../Utilities/AccessManagementUtil';
import { HasProperty, IsValidText } from '../../../Utilities/Validations';

const ProgramClientAlignmentSubmit = (function () {
  class ProgramClientAlignmentSubmitPresentational extends Component {
    render() {
      return (
        <div className="BT-Lead-Landing-Page">
          <CVMToasterNotif />
          <ProgramProjectHeader type="Program" />
          <Stepper type="Program" />
          {this.props.PnPComponents.program.clntAlgnmnt ||
          (this.props.PnPComponents.program.stageThreeAcknwldg && !this.props.isCXL) ? (
            <Row className="access_denied_container">
              <Col md={12} xs={12}>
                <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                <div className="access_denied_content">
                  <h1>Access Denied</h1>
                  <p>
                    You don't have the necessary user privileges to view the page that you are
                    attempting to access.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="client-confirmation-docs">
              <div className="confirmation">Upload Client Confirmation Document(s)</div>
              <div className="check-box">
                <div
                  className={this.props.isChecked ? 'cvm-checkbox cvm-checked' : 'cvm-checkbox'}
                  onClick={() => this.props.checkBox()}
                >
                  <GrooveIconSvg
                    customClassName="cvm-check-box"
                    size="small"
                    name="check"
                    iconStyle="solid"
                    iconColor="dl-white"
                  />
                </div>
                <div className="check-label">
                  {' '}
                  I confirm that I will upload the documents later{' '}
                </div>
                <div></div>
              </div>

              {this.props.isChecked ? (
                ''
              ) : (
                <div className="upload-subtext">
                  Please upload client confirmation email or document(s) to complete the approval
                  process.
                </div>
              )}
              {this.props.isChecked ? (
                <div className="upload-pending-container">
                  <img src={Bell} alt="bell" className="check-circle" />
                  <div className="upload-pending-label">Document Upload Pending</div>
                  <div className="upload-pending-desc">
                    Please note that you won’t be able to skip the document upload next time and
                    will be reminded to upload it in 3 business days.
                  </div>
                </div>
              ) : (
                <ClientConfirmationDocs
                  onDropAccepted={this.props.onDropAccepted}
                  onDropRejected={this.props.onDropRejected}
                  onDeleteUpload={this.props.onDeleteUpload}
                  Files={this.props.tempFiles}
                  errorUpload={this.props.errorUpload}
                  fileIsValid={this.props.fileIsValid}
                  onChangeComment={this.props.onChangeComment}
                  comment={this.props.comment}
                  invalidComment={this.props.invalidComment}
                />
              )}
              <CACompleteDocUploadPopUp
                onCloseModal={this.props.onCloseModal}
                pending={this.props.isChecked}
                onClickSubmit={this.props.progUploadAttachment}
                uploadSuccess={this.props.uploadSuccess}
                show={this.props.showSubmit}
                onClickSaveasDraft={this.props.onClickSaveasDraft}
                fileCount={this.props.fileCount}
                typeName={this.props.typeName}
                type={'Program'}
                enableSAD={this.props.enableSAD}
                enableSubmit={this.props.enableSubmit}
                invalidComment={this.props.invalidComment}
              />
            </div>
          )}
        </div>
      );
    }
  }

  class ProgramClientAlignmentSubmitContainer extends Component {
    constructor() {
      super();
      this.state = {
        isChecked: false,
        Files: [],
        tempFiles: [],
        fileList: '',
        tempFileList: [],
        fileFlag: 0,
        errorUpload: '',
        fileIsValid: true,
        showSubmit: false,
        uploadSuccess: false,
        fileCount: 0,
        comment: '',
        enableSAD: false,
        enableSubmit: false,
        serverUploadedFilesExist: false,
        invalidComment: false,
      };
    }

    checkBox = () => {
      this.setState(
        {
          isChecked: !this.state.isChecked,
          Files: [],
          tempFiles: [],
          tempFileList: [],
          fileIsValid: true,
        },
        async () => {
          await this.props.setProgUploadFlag(this.state.isChecked);
          await this.props.setProgUploadFiles(this.state.Files);
          await this.props.setProgUploadComment('');
          if (!this.state.isChecked) {
            await this.getExistingFile();
          }
        }
      );
    };

    componentDidUpdate = async (prevProps) => {
      if (
        prevProps.ProgramConfigurationData.progUploadFile.length !==
        this.props.ProgramConfigurationData.progUploadFile.length
      ) {
        this.setState({
          fileCount: this.props.ProgramConfigurationData.progUploadFile.length,
        });
      } else if (
        prevProps.ProgramConfigurationData.selectedProgramDetails.ClientProgramID !==
        this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID
      ) {
        await this.props.setProgUploadFlag(this.state.isChecked);
        await this.props.setProgUploadFiles(this.state.Files);
        await this.props.setProgUploadComment('');
        this.getExistingFile();
      }
    };

    componentDidMount = async () => {
      try {
        LogUserSiteAction({
          module: PROG_ID_VAL_CA,
          ShouldLogPPCID: true,
          ClientProgramID:
            this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
          ClientProjectID: null,
          progStatus: ACC_PROG,
        });
        await this.props.setProgUploadFlag(this.state.isChecked);
        await this.props.setProgUploadFiles(this.state.Files);
        await this.props.setProgUploadComment('');
        this.getExistingFile();
      } catch (e) {
        console.log(e);
      }
    };

    getExistingFile = async () => {
      try {
        let programID = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
        let response = [];
        let tempFiles = [];
        let tempFileList = [];
        let subMenuId = GetSubMenuId('Program Identified Value - Client Alignment');
        let fileCount = 0;
        let serverUploadedFilesExist = false;

        response = await HttpGet(
          `ProgramClientAlignment/GetSavedFiles?clientProgramID=${programID}&SubMenuID=${subMenuId}`
        );

        if (response.length !== 0) {
          if (Array.isArray(response.Files)) {
            response.Files.forEach((e) => {
              tempFiles.push({
                name: e.FileName,
              });
              tempFileList.push({
                name: e.FileName,
              });
            });

            fileCount = response.Files.length;
            serverUploadedFilesExist = true;
          }

          this.setState(
            {
              tempFiles: tempFiles,
              tempFileList: tempFileList,
              comment: response.Comments === null ? '' : response.Comments,
              fileFlag: serverUploadedFilesExist ? 1 : 0,
              fileCount,
              serverUploadedFilesExist,
            },
            () => this.props.setProgUploadComment(this.state.comment)
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    onDropAccepted = (acceptedFiles) => {
      try {
        let uploadFiles = [];
        let uploadCount = acceptedFiles.length;
        let fileCount = this.state.Files.length;
        let tempFileCount = this.state.tempFileList.length;
        let findNameTFiles = '';
        let findNameFiles = '';
        let tempUploadFiles = [];
        let fileName = '';

        if (tempFileCount + fileCount + uploadCount <= 3) {
          acceptedFiles.forEach((e, i) => {
            findNameTFiles = this.state.tempFiles
              .map(function (e2) {
                return e2.name;
              })
              .indexOf(e.name);
            findNameFiles = this.state.Files.map(function (e2) {
              return e2.name;
            }).indexOf(e.name);
            fileName = e.name.split('.');

            if (fileName.length > 2) {
              this.setState({
                errorUpload: 'Invalid File Name. File Name should not contain a dot (.)',
                fileIsValid: false,
              });
            } else {
              if (findNameFiles === -1 && findNameTFiles === -1) {
                tempUploadFiles = this.state.tempFiles.concat(acceptedFiles[i]);
                uploadFiles = this.state.Files.concat(acceptedFiles[i]);

                this.setState(
                  {
                    Files: uploadFiles,
                    tempFiles: tempUploadFiles,
                    fileIsValid: true,
                  },
                  () => {
                    this.props.setProgUploadFiles(this.state.Files);
                  }
                );
              }
            }
          });
        } else {
          this.setState({
            errorUpload:
              "You are only allowed to upload a maximum of 3 files - You can replace old attachment with consolidated approval from client for all the BOI's",
            fileIsValid: false,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    onDropRejected = (FileRejection) => {
      try {
        if (FileRejection[0].errors[0].code === 'file-invalid-type') {
          this.setState({
            errorUpload:
              'Invalid file format. Please upload file with any of the these formats: (.msg, .eml, .pdf).',
            fileIsValid: false,
          });
        } else if (FileRejection[0].errors[0].code === 'file-too-large') {
          this.setState({
            errorUpload: 'File size has exceeded 10MB limit.',
            fileIsValid: false,
          });
        } else if (FileRejection[0].errors[0].code === 'too-many-files') {
          this.setState({
            errorUpload:
              "You are only allowed to upload a maximum of 3 files - You can replace old attachment with consolidated approval from client for all the BOI's",
            fileIsValid: false,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    onDeleteUpload = (fileName) => {
      try {
        let Files = [...this.state.Files];
        let tempFileList = [...this.state.tempFileList];
        let tempFiles = [...this.state.tempFiles];
        let fileFlag = this.state.fileFlag;
        Files.map((e, i) => {
          if (e.name === fileName) {
            Files.splice(i, 1);
          }
        });
        tempFileList.map((e, i) => {
          if (e.name === fileName) {
            tempFileList.splice(i, 1);
          }
        });
        tempFiles.map((e, i) => {
          if (e.name === fileName) {
            tempFiles.splice(i, 1);
          }
        });
        if (!this.state.serverUploadedFilesExist) {
          fileFlag = 0;
        }
        this.setState(
          {
            Files: Files,
            tempFileList: tempFileList,
            tempFiles: tempFiles,
            fileFlag: fileFlag,
            fileCount: tempFiles.length,
            errorUpload: null,
            fileIsValid: true,
          },
          () => {
            this.props.setProgUploadFiles(this.state.Files);
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    progUploadAttachment = async () => {
      try {
        let files = this.props.ProgramConfigurationData.progUploadFile;
        let userName = this.props.userInformation.userName;
        let programID = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;

        let fileFlag = this.state.fileFlag;
        let fileList = this.state.fileList;
        let stakeholders = '';
        const programStakeholders = this.props.ProgramConfigurationData.programStakeholders;
        if(Array.isArray(programStakeholders)) {
          programStakeholders.map((stakeholder, index) => {
            if (index === 0) {
              stakeholders = stakeholder;
            } else {
              stakeholders = stakeholders + ',' + stakeholder;
            }
          });
        }
        this.state.tempFileList.map((e, i) => {
          if (i === 0) {
            fileList = e.name;
          } else {
            fileList = fileList + '~' + e.name;
          }
        });
        let flag = this.props.ProgramConfigurationData.progUpload.Flag;
        let comment = this.props.ProgramConfigurationData.progUpload.Comment;
        let IsEdited = this.props.ProgramConfigurationData.selectedProgramDetails.IsEdited;

        var body = new FormData();
        for (var i = 0; i < files.length; i++) {
          body.append('Files', files[i]);
        }
        if (files.length === 0) {
          body.append('Files', files);
        }
        body.append('ProgramID', programID);
        body.append('UploadedBy', userName);
        body.append('Flag', !flag);
        body.append('Stakeholders', stakeholders);
        body.append('Comments', comment);
        body.append('FileList', fileList);
        body.append('FileFlag', fileFlag);
        body.append('IsEdited', IsEdited ? 1 : 0);

        let subMenuId = GetSubMenuId('Program Identified Value - Client Alignment');
        let response = await HttpPostWithFileString(
          `ProgramClientAlignment/UploadAttachment?SubMenuID=${subMenuId}`,
          body
        );
        if (response === 'success') {
          this.setState({
            showSubmit: !this.state.showSubmit,
            uploadSuccess: true,
          });
        } else {
          let data = JSON.parse(response);
          if (HasProperty(data, 'Code') && data.Code === '2') {
            this.props.setToasterParam({
              show: true,
              type: 'error',
              message: data.Message,
            });
            this.setState({
              enableSubmit: true,
            });
          } else {
            this.setState({
              errorUpload: 'Invalid File',
              fileIsValid: false,
              enableSubmit: true,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    onCloseModal = () => {
      this.setState(
        {
          ...this.state,
          showSubmit: !this.state.showSubmit,
        },
        () => {
          if (this.state.uploadSuccess && !this.state.showSubmit) {
            this.props.history.push('/ProgramProjectConfig');
            this.props.getProgramList(
              this.props.ProgramConfigurationData.programClientDetails.ClientMasterID
            );
            this.props.setToasterParam({
              show: true,
              type: 'success',
              message: 'Notification is sent successfully!',
            });
          }
        }
      );
    };

    onClickSaveasDraft = async () => {
      try {
        let files = this.props.ProgramConfigurationData.progUploadFile;
        let userName = this.props.userInformation.userName;
        let programID = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;

        let fileFlag = this.state.fileFlag;

        let stakeholders = '';
        const programStakeholders = this.props.ProgramConfigurationData.programStakeholders;
        if(Array.isArray(programStakeholders)) {
          programStakeholders.map((stakeholder, index) => {
            if (index === 0) {
              stakeholders = stakeholder;
            } else {
              stakeholders = stakeholders + ',' + stakeholder;
            }
          });
        }
        let fileList = '';
        this.state.tempFileList.map((e, i) => {
          if (i === 0) {
            fileList = e.name;
          } else {
            fileList = fileList + '~' + e.name;
          }
        });
        let flag = this.props.ProgramConfigurationData.progUpload.Flag;
        let comment = this.props.ProgramConfigurationData.progUpload.Comment;

        var body = new FormData();
        for (var i = 0; i < files.length; i++) {
          body.append('Files', files[i]);
        }
        if (files.length === 0) {
          body.append('Files', files);
        }
        body.append('ProgramID', programID);
        body.append('UploadedBy', userName);
        body.append('Flag', !flag);
        body.append('Comments', comment);
        body.append('FileList', fileList);
        body.append('FileFlag', fileFlag);
        body.append('Stakeholders', stakeholders);

        let subMenuId = GetSubMenuId('Program Identified Value - Client Alignment');
        let response = await HttpPostWithFileString(
          `ProgramClientAlignment/SaveAsDraft?SubMenuID=${subMenuId}`,
          body
        );
        if (response === 'success') {
          this.props.history.push('/ProgramProjectConfig');
          this.props.getProgramList(
            this.props.ProgramConfigurationData.programClientDetails.ClientMasterID
          );
          this.props.setToasterParam({
            show: true,
            type: 'success',
            message: 'Your Progress was saved as draft successfully!',
          });
        } else {
          let data = JSON.parse(response);
          if (HasProperty(data, 'Code') && data.Code === '2') {
            this.props.setToasterParam({
              show: true,
              type: 'error',
              message: data.Message,
            });
            this.setState({
              enableSAD: true,
            });
          } else {
            this.setState({
              errorUpload: 'Invalid File',
              fileIsValid: false,
              enableSAD: true,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    onChangeComment = (e) => {
      this.setState({
        invalidComment: !IsValidText(e),
      });
      this.props.setProgUploadComment(e);
    };

    render() {
      const { ClientProgramName, CXLLead } =
        this.props.ProgramConfigurationData.selectedProgramDetails;
      const { PnPComponents } = this.props.AccessValidationData;
      const isCXL =
        CXLLead.split(',').filter((x) => x === this.props.userInformation.emailAddress).length > 0; // Bug 217559: check if the logged in user is CXL on selected program
      return (
        <ProgramClientAlignmentSubmitPresentational
          onCloseModal={this.onCloseModal}
          isChecked={this.state.isChecked}
          checkBox={this.checkBox}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          onDeleteUpload={this.onDeleteUpload}
          Files={this.state.Files}
          tempFiles={this.state.tempFiles}
          errorUpload={this.state.errorUpload}
          fileIsValid={this.state.fileIsValid}
          progUploadAttachment={this.progUploadAttachment}
          showSubmit={this.state.showSubmit}
          onClickSaveasDraft={this.onClickSaveasDraft}
          PnPComponents={PnPComponents}
          onChangeComment={this.onChangeComment}
          fileCount={this.state.fileCount}
          comment={this.state.comment}
          enableSAD={this.state.enableSAD}
          enableSubmit={this.state.enableSubmit}
          typeName={ClientProgramName}
          isCXL={isCXL}
          invalidComment={this.state.invalidComment}
        />
      );
    }
  }

  return ProgramClientAlignmentSubmitContainer;
})();

export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
      userInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setProgUploadFlag,
          setProgUploadFiles,
          setProgUploadComment,
          getProgramList,
          setToasterParam,
        }
      ),
      dispatch
    )
)(withRouter(ProgramClientAlignmentSubmit));
