import {
    SET_STAGE_REPORTING_YEAR_PARAM
} from '../Constants'

const initialState = {
    reportingYearParam: { options: [], value: {}, isDisabled: false }
}

export const StageData = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case SET_STAGE_REPORTING_YEAR_PARAM:
            return {
                ...state,
                reportingYearParam: action.reportingYearParam
            }
        default:
            return state
    }
}