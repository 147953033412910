import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import {
    setUploadSupportDoc,
    onShowUploadSupport,
    setToasterParam,
} from '../../../Actions/Shared.Action';  
import { 
    HttpPostWithFileString, 
    HttpGet, 
    HttpPostText 
} from '../../../Utilities/HTTPUtil';
import ModalTabs from "./ModalTabs";

import "./UploadSupportDocument.css"

const UploadSupportDocument = (function () {
    class UploadSupportDocumentPresentaional extends Component {
        render() {
            const {
                show, showLoader,
                radioSelected,
                uploadSupportDoc,
                onShowModal, 
                onClickSave,
                setDocType,
                supportDocs,
                supportLinks,
                setUploadSupportDoc
            } = this.props;

            return (
                <React.Fragment>
                    <Modal
                        show={show}
                        centered
                        dialogClassName="upload-support-document"
                    >
                        <Modal.Body>
                            <div className="content-wrapper">
                                {showLoader && <div className="modal-loader">
                                    <div className="comment-loader">
                                        <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>}
                                
                                <ModalTabs
                                    radioSelected={radioSelected}
                                    setDocType={setDocType}
                                    onShowModal={onShowModal}
                                    onClickSave={onClickSave}
                                    supportDocs={supportDocs}
                                    supportLinks={supportLinks}
                                    uploadSupportDoc={uploadSupportDoc}
                                    setUploadSupportDoc={setUploadSupportDoc}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            );
        }
    }

    class UploadSupportDocumentContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                activeTab: 0,
                radioSelected: 0,
                showLoader: false,
                supportDocs: [],
                supportLinks: [],
            };
        }

        componentDidMount = async () => {
            let supportDocsTemp = await HttpGet('ProfileMenu/GetSupportDocuments');
            let supportLinksTemp = await HttpGet('ProfileMenu/GetSupportLinks');
            
            if(supportDocsTemp.status !== 204) {
                this.setState({
                    supportDocs: [...supportDocsTemp],
                })
            }
            
            if(supportLinksTemp.status !== 204) {
                this.setState({
                    supportLinks: [...supportLinksTemp],
                })
            }
        }

        setDocType = (e) => {
            this.setState({
                radioSelected: e.value
            })
        }

        onClickSave = async () => {
            let { uploadSupportDoc } = this.props.SharedData;
            let supportDocs = this.state.supportDocs;
            let supportLinks = this.state.supportLinks;
            let radioSelected = this.state.radioSelected.toString();
            let eid = this.props.userInformation.enterpriseID;
                
            try {
                this.setState({
                    showLoader: true,
                })
                if(radioSelected === "0" || radioSelected === "1"){
                    let docID = 0
                    let idx = supportLinks.findIndex((doc) => doc.DocumentType === radioSelected)
                    if(idx !== -1) docID = supportDocs[idx].ID;

                    let body = {
                        ID: docID,
                        DocumentType: radioSelected,
                        Link: uploadSupportDoc.link,
                        LoggedInUser: eid,
                    }
                    let response = await HttpPostText(`ProfileMenu/UploadSupportLinks`, body);
                    if (response === "success") {
                        let supportLinksTemp = await HttpGet('ProfileMenu/GetSupportLinks');
                        if(supportLinksTemp.status !== 204) {
                            this.setState({
                                supportLinks: [...supportLinksTemp],
                            })
                        }
                        this.setState({
                            showLoader: false,
                        })
                        this.onShowModal(false);    
                        this.props.setToasterParam({
                            show: true,
                            type: 'success',
                            message: "Link has been saved successfully!"
                        })
                    } else {
                        this.setState({
                            showLoader: false,
                        })  
                        this.props.setToasterParam({
                            show: true,
                            type: 'error',
                            message: "Link was not saved!"
                        })
                    }
                } else {
                    let docID = 0
                    let idx = supportDocs.findIndex((doc) => doc.DocumentType === radioSelected)
                    if(idx !== -1) docID = supportDocs[idx].ID;

                    let body = new FormData();
                    body.append("File", uploadSupportDoc.file);
                    body.append("ID", docID);
                    body.append("LoggedInUser", eid);
                    body.append("DocumentType", radioSelected);
        
                    let response = await HttpPostWithFileString("ProfileMenu/UploadSupportDocuments", body)
                    if (response === "success") {
                        let supportDocsTemp = await HttpGet('ProfileMenu/GetSupportDocuments');            
                        if(supportDocsTemp.status !== 204) {
                            this.setState({
                                supportDocs: [...supportDocsTemp],
                            })
                        }
                        this.setState({
                            showLoader: false,
                        })
                        this.onShowModal(false);    
                        this.props.setToasterParam({
                            show: true,
                            type: 'success',
                            message: "File has been uploaded successfully!"
                        })
                    } else {
                        this.setState({
                            showLoader: false,
                        })  
                        this.props.setToasterParam({
                            show: true,
                            type: 'error',
                            message: "File was not uploaded!"
                        })
                    }
                }
            }
            catch (e) {
              console.log(e);
            }
        }

        onShowModal = (show) => {
            this.setState({
                radioSelected: 0,
            })
            this.props.onShowUploadSupport(show)

            let { uploadSupportDoc } = this.props.SharedData;
            uploadSupportDoc.link = "";
            uploadSupportDoc.file = {};
            this.props.setUploadSupportDoc(uploadSupportDoc)
        }
    
        render() {
          let { showUploadSupport, uploadSupportDoc } = this.props.SharedData;
          return (
            <UploadSupportDocumentPresentaional
                {...this.state}
                show={showUploadSupport}
                uploadSupportDoc={uploadSupportDoc}
                onShowModal={this.onShowModal}
                setDocType={this.setDocType}
                onClickSave={this.onClickSave}
                setUploadSupportDoc={this.props.setUploadSupportDoc}
            />
          );
        }
      }
    
      return UploadSupportDocumentContainer;
})();

export default connect(
    state => {
      return {
        SharedData: state.SharedData,
        userInformation: state.userInformation,
      }
    },
    dispatch => bindActionCreators(Object.assign({},
      {
        setUploadSupportDoc,
        onShowUploadSupport,
        setToasterParam,
      }), dispatch)
  )(UploadSupportDocument);