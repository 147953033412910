import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { IsValid, IsValidStr, HasValue, IsObjEmpty, HasProperty, IsValidText } from '../../../Utilities/Validations';
import { TryGetObjValueAlt } from '../../../Utilities/Formatter';

import {
  GrooveIconSvg,
  GrooveInput,
  GrooveButton,
  GrooveSelectMenuBasic,
  GrooveTextBox,
} from 'tfo-groove';

const AddCustomBOI = (function () {
  class AddCustomBOIPresentational extends Component {
    render() {
      const opt = this.props.options;
      const callback = this.props.formCallback;
      let form = this.props.formData;
      // check if there any dropdown field was empty
      let failedValidation =
        this.props.isExisting ||
        Object.keys(this.props.customboiToAdd.uom).length === 0 ||
        Object.keys(this.props.customboiToAdd.dimension).length === 0 ||
        Object.keys(this.props.customboiToAdd.subdimension).length === 0 ||
        Object.keys(this.props.customboiToAdd.aggregationlogic).length === 0 ||
        Object.keys(this.props.customboiToAdd.boidefinition).length === 0 ||
        Object.keys(this.props.customboiToAdd.suboffering).length === 0 ||
        this.props.customboiToAdd.boi.label === undefined ||
        this.props.customboiToAdd.boi.label === '' ||
        !IsValidText(this.props.customboiToAdd.boi.label) ||
        this.props.isExistingNewSubdimension ||
        this.props.duplicateParameterName ||
        form.boiDescription === '' ||
        !IsValidText(form.boiDescription) ||
        form.leadingPractice === '' ||
        form.commentboiaddition === '' ||
        !IsValidText(form.commentboiaddition);
      let boidefinitionValidation = false;
      let subdimensionValidation = Object.keys(this.props.customboiToAdd.subdimension).length === 0;
      form.customParameterList.map((item) => {
        if (
          item.ParameterName === '' ||
          item.ParameterName === undefined ||
          !IsValidText(item.ParameterName) ||
          item.ParameterUOM === '' ||
          item.ParameterUOM === undefined
        ) {
          boidefinitionValidation = true;
        }
      });

      if (this.props.customboiToAdd.subdimension.label === 'Add new Subdimension') {
        subdimensionValidation = form.newSubDimensionName === '' || !IsValidText(form.newSubDimensionName);
      }

      var numberOfParameter = [];
      for (let i = 1; i <= form.noOfParameter; i++) {
        numberOfParameter.push(i);
      }

      const tooltipDeleteBoi = (
        <Popover id="deleteCusBoi-popover" trigger="focus" placement="left">
          <Popover.Content>
            This BOI is already selected by a project within this program. In order to remove this
            BOI from the list, kindly remove the same from the project level.
          </Popover.Content>
        </Popover>
      );

      const tooltipDisabledFields = (
        <Popover id="deleteCusBoi-popover" trigger="focus" placement="right">
          <Popover.Content>
            This BOI is already selected by a project within this program. Kindly select other BOI
            from the list.
          </Popover.Content>
        </Popover>
      );

      return (
        <React.Fragment>
          <div className="customboi-container">
            <Accordion>
              <Card
                className={
                  this.props.isExisting
                    ? 'customboi-card-collapse duplicate-boi'
                    : 'customboi-card-collapse customboi-list-card'
                }
              >
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row>
                      <Col className="custom-boi-left" lg={6} md={6} sm={12}>
                        <Row>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveInput
                              id="customClientName"
                              name="customClientName"
                              placeholder="Client Name"
                              type="text"
                              isAutocomplete="false"
                              isDisabled
                              inputValue={this.props.programClientDetails.ClientMasterName}
                            />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveInput
                              id="customOffering"
                              name="customOffering"
                              placeholder="Offering"
                              type="text"
                              isAutocomplete="false"
                              isDisabled
                              inputValue={this.props.newProgramDetails.offering.label}
                            />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveSelectMenuBasic
                              name="suboffering"
                              placeholder={
                                Object.keys(this.props.customboiToAdd.suboffering).length !== 0
                                  ? 'Sub-Offering*'
                                  : 'Select Sub-Offering*'
                              }
                              list={this.props.list.suboffering}
                              id="suboffering"
                              contentType="search"
                              callbackFunction={(e) =>
                                this.props.onSelectDropdown(e, 'suboffering')
                              }
                            />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveInput
                              id="customBOIName"
                              name="customBOIName"
                              placeholder={form.boiName !== '' ? 'BOI Name*' : 'Enter BOI Name*'}
                              type="text"
                              isAutocomplete="false"
                              callbackFunction={callback}
                              inputValue={form.boiName}
                              maxLength={250}
                            />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveSelectMenuBasic
                              name="unitofMeasure"
                              placeholder={
                                Object.keys(this.props.customboiToAdd.uom).length !== 0
                                  ? 'Unit of Measure*'
                                  : 'Select Unit of Measure*'
                              }
                              list={this.props.list.uom}
                              // defaultData={this.props.customboiToAdd.uom}
                              id="uom"
                              contentType="search"
                              callbackFunction={(e) => this.props.onSelectDropdown(e, 'uom')}
                            />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <div className="comments">
                              <GrooveTextBox
                                id="customBOIDesc"
                                name="customBOIDesc"
                                type="text"
                                placeholder={`${form.boiDescription !== '' ? '' : 'Enter '
                                  }BOI Description*`}
                                rows="2"
                                callbackFunction={callback}
                                textValue={form.boiDescription}
                                maxLength={250}
                              />
                            </div>
                          </Col>
                          <Col className="custom-boi-radio" lg={12} md={12} sm={12}>
                            <div className="lead-title">
                              <label className="leading-practice-label">Leading practice*</label>
                            </div>
                            <div className="lead-options">
                              <label className="custom-boi-label">
                                Maximum
                                <input
                                  type="radio"
                                  name="leadingPractice"
                                  id="leadingPractice"
                                  value="maximum"
                                  onChange={(e) => callback(e.target.value, e.target.id)}
                                />
                                <span className="custom-boi-checkmark"></span>
                                <span className="custom-boi-checkmark2"></span>
                              </label>
                              <label className="custom-boi-label">
                                Minimum
                                <input
                                  type="radio"
                                  name="leadingPractice"
                                  id="leadingPractice"
                                  value="minimum"
                                  onChange={(e) => callback(e.target.value, e.target.id)}
                                />
                                <span className="custom-boi-checkmark"></span>
                                <span className="custom-boi-checkmark2"></span>
                              </label>
                            </div>
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <div className="comments">
                              <GrooveTextBox
                                id="customBOIComment"
                                name="customBOIComment"
                                type="text"
                                placeholder={`${form.commentboiaddition !== '' ? '' : 'Enter '
                                  }Comment for BOI Addition*`}
                                rows="2"
                                textValue={form.commentboiaddition}
                                callbackFunction={callback}
                                maxLength={500}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="custom-boi-right" lg={6} md={6} sm={12}>
                        <Row>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveSelectMenuBasic
                              name="dimension"
                              isDisabled={true}
                              placeholder={
                                Object.keys(this.props.customboiToAdd.dimension).length !== 0
                                  ? 'Dimension*'
                                  : 'Select Dimension*'
                              }
                              list={this.props.list.dimension}
                              defaultData={this.props.customboiToAdd.dimension}
                              id="dimension"
                              contentType="search"
                              // callbackFunction={(e) => this.props.onSelectDropdown(e, 'dimension')}
                            />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveSelectMenuBasic
                              name="subdimension"
                              isDisabled={this.props.list.subdimension.length === 0}
                              placeholder={
                                Object.keys(this.props.customboiToAdd.subdimension).length !== 0
                                  ? 'Subdimension*'
                                  : 'Select Subdimension*'
                              }
                              list={this.props.list.subdimension}
                              defaultData={this.props.customboiToAdd.subdimension}
                              id="subdimension"
                              contentType="search"
                              customClassName={
                                this.props.customboiToAdd.dimension.label === 'Custom'
                                  ? ' customboi-dropdown'
                                  : ''
                              }
                              callbackFunction={(e) =>
                                this.props.onSelectDropdown(e, 'subdimension')
                              }
                            />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <GrooveInput
                              id="customSubDimensionName"
                              name="customSubDimensionName"
                              customClassName={
                                this.props.customboiToAdd.subdimension.label ===
                                  'Add new Subdimension'
                                  ? this.props.isExistingNewSubdimension
                                    ? 'add-subdimension-show duplicateErr'
                                    : 'add-subdimension-show'
                                  : 'add-subdimension-hidden'
                              }
                              placeholder={`${form.newSubDimensionName !== '' ? '' : 'Enter '
                                }New Subdimension Name*`}
                              type="text"
                              isAutocomplete="false"
                              isDisabled={false}
                              callbackFunction={callback}
                              inputValue={form.newSubDimensionName}
                              maxLength={30}
                            />
                          </Col>
                          {this.props.isExistingNewSubdimension ? (
                            <Col lg={12} md={12} sm={12}>
                              <p className="custombtn-error-message">
                                Subdimension Name already exist.
                              </p>
                            </Col>
                          ) : (
                            ''
                          )}
                          <Col lg={12} md={12} sm={12}>
                            <GrooveSelectMenuBasic
                              name="boidefinition"
                              isDisabled={
                                this.props.customboiToAdd.uom.label === '%' ||
                                (
                                  this.props.customboiToAdd.uom.label === 'USD' &&
                                  this.props.customboiToAdd.dimension.value === 1
                                )
                              }
                              placeholder={
                                Object.keys(this.props.customboiToAdd.boidefinition).length !== 0
                                  ? 'BOI definition / Metric Calculation*'
                                  : 'Select BOI definition / Metric Calculation*'
                              }
                              list={this.props.list.boidefinition}
                              defaultData={this.props.customboiToAdd.boidefinition}
                              id="boidefinition"
                              contentType="search"
                              callbackFunction={(e) =>
                                this.props.onSelectDropdown(e, 'boidefinition')
                              }
                            />
                          </Col>
                          {form.customParameterList.map((item, index) => (
                            <div className="boidefinition-parameter" key={index + 1}>
                              <Row>
                                <Col lg={6} md={12} sm={6}>
                                  <GrooveInput
                                    id={'parameter' + (index + 1)}
                                    name={'parameter' + (index + 1)}
                                    placeholder={
                                      this.props.customboiToAdd.boidefinition.label === 'Value'
                                        ? 'Define Parameter Name*'
                                        : index === 0
                                          ? (item.ParameterName === '' ? 'Enter ' : '') +
                                          'Numerator Name*'
                                          : (item.ParameterName === '' ? 'Enter ' : '') +
                                          'Denominator Name*'
                                    }
                                    type="text"
                                    isAutocomplete="false"
                                    customClassName={
                                      this.props.duplicateParameterName
                                        ? ' customboi-customparameter duplicateErr'
                                        : ' customboi-customparameter'
                                    }
                                    inputValue={item.ParameterName}
                                    maxLength={250}
                                    callbackFunction={(e) => callback(e, e.id, index + 1)}
                                  />
                                </Col>
                                <Col lg={6} md={12} sm={6}>
                                  <GrooveSelectMenuBasic
                                    name={'unitofMeasure' + (index + 1)}
                                    isDisabled={
                                      this.props.customboiToAdd.boidefinition.label === 'Value'
                                    }
                                    placeholder={
                                      Object.keys(this.props.customboiToAdd.uom).length !== 0
                                        ? 'Unit of Measure*'
                                        : TryGetObjValueAlt(item, '', 'ParameterUOM') !== ''
                                          ? 'Unit of Measure*'
                                          : 'Select Unit of Measure*'
                                    }
                                    list={this.props.list.uom}
                                    defaultData={
                                      this.props.customboiToAdd.boidefinition.label === 'Value'
                                        ? this.props.customboiToAdd.uom
                                        : ''
                                    }
                                    id={'uom' + (index + 1)}
                                    contentType="search"
                                    customClassName=" customboi-customparameter"
                                    callbackFunction={(e) =>
                                      callback(e, 'uom' + (index + 1), index + 1)
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                          ))}
                          {this.props.duplicateParameterName ? (
                            <Col lg={12} md={12} sm={12}>
                              <p className="custombtn-error-message">Duplicate Parameter Name.</p>
                            </Col>
                          ) : (
                            this.props.warningUOM && (
                              <Col lg={12} md={12} sm={12}>
                                <p className="customuom-message">
                                  Note: Make sure that the parameter unit of measurement aligns with
                                  the BOI.
                                </p>
                              </Col>
                            )
                          )}
                          <Col lg={12} md={12} sm={12}>
                            <GrooveSelectMenuBasic
                              name="aggregationlogic"
                              isDisabled={this.props.list.aggregationlogic.length === 0}
                              placeholder={
                                Object.keys(this.props.customboiToAdd.aggregationlogic).length !== 0
                                  ? 'Aggregation Logic*'
                                  : 'Select Aggregation Logic*'
                              }
                              list={this.props.list.aggregationlogic}
                              defaultData={this.props.customboiToAdd.aggregationlogic}
                              id="aggregationlogic"
                              contentType="search"
                              callbackFunction={(e) =>
                                this.props.onSelectDropdown(e, 'aggregationlogic')
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <div className="bodytext18-medium-midnight customboi-add-header">
                      New Custom BOI
                    </div>
                    <GrooveButton
                      id="primry-btn-1"
                      name="Solid Button Primary-Ops"
                      hasIcon={false}
                      type="solid"
                      colorClass="stat-alternate"
                      iconAlignment="right"
                      size="auto"
                      text={'Add BOI'}
                      callbackFunction={() => this.props.addBOI()}
                      isDisabled={
                        failedValidation || boidefinitionValidation || subdimensionValidation
                      }
                    />
                    <GrooveIconSvg
                      customClassName="accordion-button"
                      size="large"
                      name="chevron-down"
                      iconStyle="solid"
                      iconColor="stat-neutral"
                    />
                  </Accordion.Toggle>
                </Card.Header>
              </Card>
            </Accordion>

            {this.props.selectedCustomBOIDisplay.some((x) => x.IsCustom) ? (
              <React.Fragment>
                <div className="divider-horizontal" />
              </React.Fragment>
            ) : (
              ''
            )}

            <div className="deleted-bois-container">
              {this.props.deletedBOIForUndo.map((item) => {
                return (
                  <div className="deleted-bois">
                    <GrooveIconSvg
                      customClassName="close-modal-btn"
                      size="large"
                      name="exclamation-triangle"
                      iconStyle="solid"
                      iconColor="stat-alternate"
                    />
                    <div className="deleted-bois-text">
                      The Business Outcome Indicator
                      <div>
                        <div className="deleted-boi-details">
                          <div className="deleted-boi-details-label">
                            BOI: <span>{item.boi.label}</span>
                          </div>
                          <div className="deleted-boi-details-label">
                            Unit of Measure: <span>{item.uom.label}</span>
                          </div>
                          <div className="deleted-boi-details-label">
                            Dimension: <span>{item.dimension.label}</span>
                          </div>
                          <div className="deleted-boi-details-label">
                            Subdimension: <span>{item.subdimension.label}</span>
                          </div>
                        </div>
                        '{item.boi.label}'
                      </div>
                      has been deleted. Click on the undo button to restore the deleted BOI.
                    </div>
                    <GrooveButton
                      id="primry-btn-3"
                      type="solid"
                      colorClass="stat-alternate"
                      text="Undo Delete"
                      customClassName="undo-deleted-boi-btn"
                      callbackFunction={() => this.props.restoreDeletedBOI(item)}
                    />
                    <GrooveIconSvg
                      customClassName="close-modal-btn"
                      size="large"
                      name="times"
                      iconStyle="solid"
                      iconColor="stat-neutral"
                      callbackFunction={() => this.props.removeDeletedBOI(item)}
                    />
                  </div>
                );
              })}
            </div>

            <Accordion id="customboi-accordion" activeKey={this.props.defaultBOIKey.toString()}>
              {this.props.selectedCustomBOIDisplay.map((item, index) => {
                if (item.IsCustom) {
                  let that = this;
                  let isEditDisabled = item.boiStatus === 'Rejected' ? false : true;
                  let isItemDisabled =
                    item.isExisting || item.missingDetails
                      ? false
                      : this.props.duplicateBOI || this.props.inComplete;
                  let type = that.props.type === 'Edit Rejected' ? true : false;
                  let failedValidation =
                    Object.keys(item.uom).length === 0 ||
                    Object.keys(item.dimension).length === 0 ||
                    Object.keys(item.subdimension).length === 0 ||
                    Object.keys(item.aggregationlogic).length === 0 ||
                    Object.keys(item.boidefinition).length === 0 ||
                    Object.keys(item.suboffering).length === 0 ||
                    item.isExistingNewSubdimension ||
                    item.leadingIndicator === '' ||
                    item.duplicateParameterName ||
                    item.isExisting ||
                    item.missingDetails;
                  let boidefinitionValidation = false;
                  let isDimSubdimDisable = item.isDisableUpdatingDimension
                    && item.uom.value.toLowerCase() === 'usd'
                    && item.dimension.value === 1

                  return (
                    <Card
                      className={
                        this.props.inComplete || item.isExisting || item.missingDetails
                          ? 'customboi-card-collapse duplicate-boi'
                          : 'customboi-card-collapse customboi-list-card'
                      }
                    >
                      <Accordion.Collapse
                        id={'customboi-collapse' + index}
                        eventKey={index.toString()}
                      >
                        <Card.Body>
                          <Row>
                            <Col className="custom-boi-left" lg={6} md={6} sm={12}>
                              <Row>
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveInput
                                    id="customClientName"
                                    name="customClientName"
                                    placeholder="Client Name"
                                    type="text"
                                    isAutocomplete="false"
                                    isDisabled
                                    inputValue={this.props.programClientDetails.ClientMasterName}
                                  />
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveInput
                                    id="customOffering"
                                    name="customOffering"
                                    placeholder="Offering"
                                    type="text"
                                    isAutocomplete="false"
                                    isDisabled
                                    inputValue={this.props.newProgramDetails.offering.label}
                                  />
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveSelectMenuBasic
                                    name="suboffering"
                                    placeholder={
                                      Object.keys(item.suboffering).length !== 0
                                        ? 'Sub-Offering*'
                                        : 'Select Sub-Offering*'
                                    }
                                    list={item.subofferingList}
                                    defaultData={item.suboffering}
                                    id="suboffering"
                                    contentType="search"
                                    callbackFunction={(e) =>
                                      this.props.onEditCustomBOI(e, 'suboffering', item)
                                    }
                                  />
                                </Col>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  className={item.isBOIUsedInProject ? 'disableWithtooltip' : ''}
                                >
                                  {item.isBOIUsedInProject && tooltipDisabledFields}
                                  <GrooveInput
                                    id={'customBOIName' + index}
                                    name="customBOIName"
                                    placeholder={
                                      item.boi.label !== '' ? 'BOI Name*' : 'Enter BOI Name*'
                                    }
                                    type="text"
                                    isAutocomplete="false"
                                    inputValue={item.boi.label}
                                    callbackFunction={(e) =>
                                      this.props.formEditCallback(e, e.id, item, index)
                                    }
                                    maxLength={250}
                                    isDisabled={item.isBOIUsedInProject}
                                  />
                                </Col>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  className={item.isBOIUsedInProject ? 'disableWithtooltip' : ''}
                                >
                                  {item.isBOIUsedInProject && tooltipDisabledFields}
                                  <GrooveSelectMenuBasic
                                    name="unitofMeasure"
                                    placeholder={
                                      Object.keys(item.uom).length !== 0
                                        ? 'Unit of Measure*'
                                        : 'Select Unit of Measure*'
                                    }
                                    list={item.uomList}
                                    defaultData={item.uom}
                                    id="uom"
                                    contentType="search"
                                    callbackFunction={(e) =>
                                      this.props.onEditCustomBOI(e, 'uom', item)
                                    }
                                    isDisabled={item.isBOIUsedInProject}
                                  />
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                  <div className="comments">
                                    <GrooveTextBox
                                      id={'customBOIDesc' + index}
                                      name="customBOIDesc"
                                      type="text"
                                      placeholder={`${item.description !== '' ? '' : 'Enter '
                                        }BOI Description*`}
                                      rows="2"
                                      callbackFunction={(e) =>
                                        this.props.formEditCallback(e, e.id, item, index)
                                      }
                                      textValue={item.boiDescription}
                                      maxLength={250}
                                    />
                                  </div>
                                </Col>
                                <Col className="custom-boi-radio" lg={12} md={12} sm={12}>
                                  <div className="lead-title">
                                    <label className="leading-practice-label">
                                      Leading practice*
                                    </label>
                                  </div>
                                  <div className="lead-options">
                                    <label className="custom-boi-label">
                                      Maximum
                                      <input
                                        type="radio"
                                        name={'leadingPractice' + index}
                                        checked={(item.leadingIndicator || '').toLowerCase() === 'maximum'}
                                        id={'leadingPractice' + index}
                                        value="maximum"
                                        onChange={(e) =>
                                          this.props.formEditCallback(
                                            e.target.value,
                                            e.target.id,
                                            item,
                                            index
                                          )
                                        }
                                      />
                                      <span className="custom-boi-checkmark"></span>
                                      <span className="custom-boi-checkmark2"></span>
                                    </label>
                                    <label className="custom-boi-label">
                                      Minimum
                                      <input
                                        type="radio"
                                        name={'leadingPractice' + index}
                                        checked={(item.leadingIndicator || '').toLowerCase() === 'minimum'}
                                        id={'leadingPractice' + index}
                                        value="minimum"
                                        onChange={(e) =>
                                          this.props.formEditCallback(
                                            e.target.value,
                                            e.target.id,
                                            item,
                                            index
                                          )
                                        }
                                      />
                                      <span className="custom-boi-checkmark"></span>
                                      <span className="custom-boi-checkmark2"></span>
                                    </label>
                                  </div>
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                  <div className="comments">
                                    <GrooveTextBox
                                      id={'customBOIComment' + index}
                                      name="customBOIComment"
                                      type="text"
                                      placeholder={`${item.boiAdditionComment !== '' ? '' : 'Enter '
                                        }Comment for BOI Addition*`}
                                      rows="2"
                                      textValue={item.boiAdditionComment}
                                      callbackFunction={(e) =>
                                        this.props.formEditCallback(e, e.id, item, index)
                                      }
                                      maxLength={500}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="custom-boi-right" lg={6} md={6} sm={12}>
                              <Row>
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveSelectMenuBasic
                                    name="dimension"
                                    isDisabled={true}
                                    placeholder={
                                      Object.keys(item.dimension).length !== 0
                                        ? 'Dimension*'
                                        : 'Select Dimension*'
                                    }
                                    list={item.dimensionList}
                                    defaultData={item.dimension}
                                    id="dimension"
                                    contentType="search"
                                    // callbackFunction={(e) =>
                                    //   this.props.onEditCustomBOI(e, 'dimension', item)
                                    // }
                                  />
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveSelectMenuBasic
                                    name="subdimension"
                                    isDisabled={item.subdimensionList.length === 0 || isDimSubdimDisable}
                                    placeholder={
                                      Object.keys(item.subdimension).length !== 0
                                        ? 'Subdimension*'
                                        : 'Select Subdimension*'
                                    }
                                    list={item.subdimensionList}
                                    defaultData={
                                      item.subdimensionList.filter(
                                        (x) => x.label === item.subdimension.label
                                      ).length === 0
                                        ? item.subdimensionList[0]
                                        : item.subdimension
                                    }
                                    id="subdimension"
                                    contentType="search"
                                    customClassName={
                                      item.dimension.label === 'Custom' ? ' customboi-dropdown' : ''
                                    }
                                    callbackFunction={(e) =>
                                      this.props.onEditCustomBOI(e, 'subdimension', item)
                                    }
                                  />
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveInput
                                    id={'customSubDimensionName' + index}
                                    name="customSubDimensionName"
                                    customClassName={
                                      item.subdimensionList.filter(
                                        (x) => x.label === item.subdimension.label
                                      ).length === 0
                                        ? item.subdimensionList[0]
                                        : item.subdimension.label === 'Add new Subdimension'
                                          ? item.isExistingNewSubdimension
                                            ? 'add-subdimension-show duplicateErr'
                                            : 'add-subdimension-show'
                                          : 'add-subdimension-hidden'
                                    }
                                    placeholder={`${item.newSubDimensionName !== '' ? '' : 'Enter '
                                      }New Subdimension Name*`}
                                    type="text"
                                    isAutocomplete="false"
                                    isDisabled={false}
                                    callbackFunction={(e) =>
                                      this.props.formEditCallback(e, e.id, item, index)
                                    }
                                    inputValue={item.newSubDimensionName}
                                    maxLength={30}
                                  />
                                </Col>
                                {item.isExistingNewSubdimension ? (
                                  <Col lg={12} md={12} sm={12}>
                                    <p className="custombtn-error-message">
                                      Subdimension Name already exist.
                                    </p>
                                  </Col>
                                ) : (
                                  ''
                                )}
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveSelectMenuBasic
                                    name="boidefinition"
                                    isDisabled={item.uom.label === '%' || (item.uom.value === 'USD' && item.dimension.value === 1)}
                                    placeholder={
                                      Object.keys(item.boidefinition).length !== 0
                                        ? 'BOI definition / Metric Calculation*'
                                        : 'Select BOI definition / Metric Calculation*'
                                    }
                                    list={item.boidefinitionList}
                                    defaultData={item.boidefinition}
                                    id="boidefinition"
                                    contentType="search"
                                    callbackFunction={(e) =>
                                      this.props.onEditCustomBOI(e, 'boidefinition', item)
                                    }
                                  />
                                </Col>
                                {item.customParameterList.map((data, index) => (
                                  <div className="boidefinition-parameter metric-calc" key={index}>
                                    <Col lg={6} md={12} sm={6}>
                                      <GrooveInput
                                        id={'parameter' + index}
                                        name="parameter"
                                        isDisabled={false}
                                        customClassName={
                                          item.duplicateParameterName
                                            ? ' customboi-customparameter duplicateErr'
                                            : ' customboi-customparameter'
                                        }
                                        placeholder={
                                          item.boidefinition.label === 'Value'
                                            ? 'Define Parameter Name*'
                                            : index === 0
                                              ? (data.ParameterName === '' ? 'Enter ' : '') +
                                              'Numerator Name*'
                                              : (data.ParameterName === '' ? 'Enter ' : '') +
                                              'Denominator Name*'
                                        }
                                        type="text"
                                        isAutocomplete="false"
                                        inputValue={data.ParameterName}
                                        maxLength={250}
                                        callbackFunction={(e) =>
                                          this.props.formEditCallback(e, e.id, item, index)
                                        }
                                      />
                                    </Col>
                                    <Col lg={6} md={12} sm={6}>
                                      <GrooveSelectMenuBasic
                                        name="unitofMeasure"
                                        customClassName=" customboi-customparameter"
                                        isDisabled={item.boidefinition.label === 'Value'}
                                        placeholder={
                                          Object.keys(item.uom).length !== 0
                                            ? 'Unit of Measure*'
                                            : data.ParameterUOM !== ''
                                              ? 'Unit of Measure*'
                                              : 'Select Unit of Measure*'
                                        }
                                        list={item.uomList}
                                        defaultData={
                                          item.uomList.filter(
                                            (x) => x.label === data.ParameterUOM
                                          )[0]
                                        }
                                        id={'uom' + index}
                                        contentType="search"
                                        callbackFunction={(e) =>
                                          this.props.formEditCallback(e, 'uom' + index, item, index)
                                        }
                                      />
                                    </Col>
                                  </div>
                                ))}
                                {item.duplicateParameterName ? (
                                  <Col lg={12} md={12} sm={12}>
                                    <p className="custombtn-error-message">
                                      Duplicate Parameter Name.
                                    </p>
                                  </Col>
                                ) : (
                                  item.warningUOM && (
                                    <Col lg={12} md={12} sm={12}>
                                      <p className="customuom-message">
                                        Note: Make sure that the parameter unit of measurement
                                        aligns with the BOI.
                                      </p>
                                    </Col>
                                  )
                                )}
                                <Col lg={12} md={12} sm={12}>
                                  <GrooveSelectMenuBasic
                                    name="aggregationlogic"
                                    isDisabled={item.aggregationlogicList.length === 0}
                                    placeholder={
                                      Object.keys(item.aggregationlogic).length !== 0
                                        ? 'Aggregation Logic*'
                                        : 'Select Aggregation Logic*'
                                    }
                                    list={item.aggregationlogicList}
                                    defaultData={item.aggregationlogic}
                                    id="aggregationlogic"
                                    contentType="search"
                                    callbackFunction={(e) =>
                                      this.props.onEditCustomBOI(e, 'aggregationlogic', item)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={index.toString()}
                          onClick={() => this.props.handleCustomBOICollapse(index)}
                        >
                          <div className="bodytext18-medium-midnight customboi-selected-header">
                            {item.boi.label}
                          </div>
                          <GrooveIconSvg
                            customClassName="accordion-button"
                            size="large"
                            name="chevron-down"
                            iconStyle="solid"
                            iconColor="stat-neutral"
                          />
                        </Accordion.Toggle>
                        <div className="customboi-header-btn">
                          <div className="delete-cusboi">
                            {item.isBOIUsedInProject && tooltipDeleteBoi}
                            <GrooveButton
                              id="primry-btn-1"
                              name="Solid Button Primary-Ops"
                              hasIcon={false}
                              type="outline"
                              colorClass="stat-alternate"
                              iconAlignment="right"
                              size="auto"
                              text={'Delete BOI'}
                              customClassName={
                                type
                                  ? item.isBOIUsedInProject
                                    ? 'delete-boi-button disabled-delete'
                                    : 'delete-boi-button'
                                  : isItemDisabled
                                    ? 'delete-boi-button disabled-delete'
                                    : 'delete-boi-button'
                              }
                              callbackFunction={
                                item.isExisting || item.missingDetails
                                  ? () => this.props.deleteBOI(item, index)
                                  : this.props.duplicateBOI || this.props.inComplete
                                    ? ''
                                    : () => this.props.deleteBOI(item, index)
                              }
                              isDisabled={item.isBOIUsedInProject || isItemDisabled}
                            />
                          </div>
                          <div className="update-cusboi">
                            <GrooveButton
                              id="primry-btn-1"
                              name="Solid Button Primary-Ops"
                              hasIcon={false}
                              type="outline"
                              colorClass="stat-alternate"
                              iconAlignment="right"
                              size="auto"
                              text={'Update BOI'}
                              isDisabled={
                                failedValidation ||
                                boidefinitionValidation ||
                                item.isUpdate ||
                                isItemDisabled
                              }
                              callbackFunction={() => this.props.updateCustomBOI(index)}
                            />
                          </div>
                        </div>
                      </Card.Header>
                    </Card>
                  );
                }
              })}
            </Accordion>
          </div>
        </React.Fragment>
      );
    }
  }

  class AddCustomBOIContainer extends Component {
    constructor() {
      super();
      this.state = {
        isExisting: false,
        isExistingNewSubdimension: false,
        list: {
          boi: [],
          uom: [],
          dimension: [],
          subdimension: [],
          boidefinition: [],
          aggregationlogic: [],
          suboffering: [],
        },
        customboiToAdd: {
          boi: {},
          uom: {},
          dimension: {},
          subdimension: {},
          boidefinition: {},
          aggregationlogic: {},
          suboffering: {},
        },
        formData: {
          boiName: '',
          boiDescription: '',
          leadingPractice: '',
          commentboiaddition: '',
          newSubDimensionName: '',
          reasonForNoBOIDefinition: '',
          reasonForNoAggregationLogic: '',
          noOfParameter: '',
          customParameterList: [],
        },
        selectedCustomBOI: [],
        selectedCustomBOIDisplay: [],
        duplicateBOI: false,
        inComplete: false,
        deletedAll: false,
        deletedBOIForUndo: [],
        defaultBOIKey: '',
        duplicateParameterName: false,
        warningUOM: false,
      };
    }
    componentDidMount() {
      this.prepInitialList();
      this.changeTextAreaClass();
      // this.props.customBOIValidationMessage('unsavedNewCustomBOI', false);
    }
    componentDidUpdate = (nextProps) => {
      // Prepare initial list of BOIs
      if (
        nextProps.ProgramConfigurationData.boiMappingList !==
        this.props.ProgramConfigurationData.boiMappingList &&
        nextProps.ProgramConfigurationData.boiMappingList.length !== 0
      ) {
        this.prepInitialList();
      }
      if (
        this.props.selectedBOIs !== undefined &&
        this.props.selectedBOIs.length > 0 &&
        this.props.ProgramConfigurationData.boiMappingList.length !== 0 &&
        this.state.selectedCustomBOI.length === 0 &&
        !this.state.deletedAll
      ) {
        this.formatBOIs(this.props.selectedBOIs);
      }

      this.changeTextAreaClass();
    };

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label');
      classGrooveTextBox.forEach((classGroove) => {
        classGroove.classList.remove('groove-textbox-custom-label');
        classGroove.classList.add('groove-input-custom-label');
      });
    };

    prepDropdownLists = (field, values) => {
      let boiMapping = this.props.ProgramConfigurationData.customBOIDropdownList;
      let subOffering = this.props.ProgramConfigurationData.newProgramDetails.subOffering;
      let list = [];
      let listRef = [];
      switch (field) {
        case 'uom':
          boiMapping.UnitOfMeasureList.map((item) => {
            if (listRef.indexOf(item.UOMName) === -1) {
              list.push({ label: item.UOMName, text: item.UOMName, value: item.UOMName });
              listRef.push(item.UOMName);
            }
          });
          break;
        case 'dimension':
          boiMapping.DimensionList.map((item) => {
            if (listRef.indexOf(item.DimensionName) === -1) {
              list.push({
                label: item.DimensionName,
                text: item.DimensionName,
                value: item.DimensionID,
              });
              listRef.push(item.DimensionName);
            }
          });
          break;
        case 'subdimension':
          let newListSubdimension = [];
          if (values.dimension.label.toLowerCase() === 'custom') {
            newListSubdimension = [
              { label: 'Add new Subdimension', text: 'Add new Subdimension', value: 0 },
            ];
          }
          boiMapping.BOIMappedSubDimensionList.map((item) => {
            if (
              item.DimensionID === values.dimension.value &&
              listRef.indexOf(item.SubDimensionName) === -1
            ) {
              newListSubdimension.push({
                label: item.SubDimensionName,
                text: item.SubDimensionName,
                value: item.SubDimensionID,
              });
              listRef.push(item.SubDimensionName);
            }
          });
          list = newListSubdimension;
          break;
        case 'boidefinition':
          boiMapping.BOIDefinitionMasterList.map((item) => {
            if (listRef.indexOf(item.MetricCalculation) === -1) {
              list.push({
                label: item.MetricCalculation,
                text: item.MetricCalculation,
                value: item.ID,
                numberOfParameter: item.NumberOfParameter,
              });
              listRef.push(item.MetricCalculation);
            }
          });
          break;
        case 'aggregationlogic':
          boiMapping.BOIDefinitionMasterList.map((item) => {
            item.AggregationLogicMasterList.map((i) => {
              if (
                item.ID === values.boidefinition.value &&
                listRef.indexOf(item.MetricCalculation) === -1
              ) {
                if (
                  i.BOIDefinitionMasterID === item.ID &&
                  listRef.indexOf(i.AggregationLogic) === -1
                ) {
                  list.push({ label: i.AggregationLogic, text: i.AggregationLogic, value: i.ID });
                  listRef.push(i.AggregationLogic);
                }
              }
            });
          });
          break;
        case 'suboffering':
          subOffering.map((item) => {
            if (listRef.indexOf(item.label) === -1) {
              list.push({ label: item.label, text: item.label, value: item.value });
              listRef.push(item.label);
            }
          });
          break;
        default:
          break;
      }
      return list;
    };

    prepInitialList = () => {
      let templist = [];
      let templistuom = [];
      let templistdimension = [];
      let templistboidefinition = [];
      let templistsuboffering = [];
      let boi = [];
      let uom = [];
      let dimension = [];
      let boidefinition = [];
      let suboffering = [];

      // selected
      let selectedDimension = {};

      this.props.ProgramConfigurationData.boiMappingList.BOIList.map((item) => {
        if (templist.indexOf(item.BOIName) === -1) {
          boi.push({ label: item.BOIName, text: item.BOIName, value: item.BOIID });
          // saving on a templist to serve as a reference to avoid adding duplicates
          templist.push(item.BOIName);
        }
      });

      this.props.ProgramConfigurationData.customBOIDropdownList.UnitOfMeasureList.map((item) => {
        if (templistuom.indexOf(item.UOMName) === -1) {
          uom.push({ label: item.UOMName, text: item.UOMName, value: item.UOMName });
          // saving on a templist to serve as a reference to avoid adding duplicates
          templistuom.push(item.UOMName);
        }
      });
      this.props.ProgramConfigurationData.customBOIDropdownList.DimensionList.map((item) => {
        if (templistdimension.indexOf(item.DimensionName) === -1) {
          dimension.push({
            label: item.DimensionName,
            text: item.DimensionName,
            value: item.DimensionID,
          });
          // saving on a templist to serve as a reference to avoid adding duplicates
          templistdimension.push(item.DimensionName);

        }
      });

      const index = dimension.findIndex((o) => o.label === "Custom");  
      if(index > -1) {
        selectedDimension = {
          ...dimension[index]
        };
      }

      this.props.ProgramConfigurationData.customBOIDropdownList.BOIDefinitionMasterList.map(
        (item) => {
          if (templistboidefinition.indexOf(item.MetricCalculation) === -1) {
            boidefinition.push({
              label: item.MetricCalculation,
              text: item.MetricCalculation,
              value: item.ID,
              numberOfParameter: item.NumberOfParameter,
            });
            // saving on a templist to serve as a reference to avoid adding duplicates
            templistboidefinition.push(item.MetricCalculation);
          }
        }
      );

      this.props.ProgramConfigurationData.newProgramDetails.subOffering.map((item) => {
        if (templistsuboffering.indexOf(item.label) === -1) {
          suboffering.push({ label: item.label, text: item.label, value: item.value });
          // saving on a templist to serve as a reference to avoid adding duplicates
          templistsuboffering.push(item.label);
        }
      });


      this.setState({
        list: {
          boi: boi,
          uom: uom,
          dimension: dimension,
          subdimension: [],
          boidefinition: boidefinition,
          aggregationlogic: [],
          suboffering: suboffering,
        }
      }, () => {

        if(selectedDimension.label) {
          this.onSelectDropdown(selectedDimension, 'dimension', true);
        }
      });
    };

    formatBOIs = (bois) => {
      let temp = [];
      bois.map((dim) => {
        dim.subDimensionList.map((subdim) => {
          subdim.boiList.map((boi) => {
            //  this is for setting BOI ID based on the boi dropdown
            let boiId = 0;
            let boiList = this.state.list.boi;
            let tempCustomBOIID = "";

            boiList.map((item) => {
              if (item.label === boi.boiName) {
                boiId = item.value;
              }
            });
            //tempboiID for custom bois
            if (boi.IsCustom && !HasProperty(boi, 'tempCustomBOIID')) {
              tempCustomBOIID = `${boi.boiName}-${boi.uom}`
            }

            let toAdd = {
              tempCustomBOIID: tempCustomBOIID,
              boi: { label: boi.boiName, text: boi.boiName, value: boi.boiID },
              uom: { label: boi.uom, text: boi.uom, value: boi.uom },
              dimension: {
                label: dim.dimensionName,
                text: dim.dimensionName,
                value: dim.dimensionID,
              },
              subdimension: {
                label: subdim.subDimensionName,
                text: subdim.subDimensionName,
                value: subdim.subDimensionID,
              },
              boidefinition: {
                label: boi.boiDefinition,
                text: boi.boiDefinition,
                value: boi.boiDefinitionMasterID,
              },
              aggregationlogic: {
                label: boi.aggregationLogic,
                text: boi.aggregationLogic,
                value: boi.aggregationLogicMasterID,
              },
              suboffering: {
                label: boi.suboffering,
                text: boi.suboffering,
                value: boi.subofferingID,
              },
            };
            if (boi.isActive !== 0) {
              temp.push({
                ...toAdd,
                boiList: boiList,
                boiListDisplay: [toAdd.boi],
                uomList: this.prepDropdownLists('uom', toAdd),
                dimensionList: this.prepDropdownLists('dimension', toAdd),
                subdimensionList: this.prepDropdownLists('subdimension', toAdd),
                boidefinitionList: this.prepDropdownLists('boidefinition', toAdd),
                aggregationlogicList: this.prepDropdownLists('aggregationlogic', toAdd),
                subofferingList: this.prepDropdownLists('suboffering', toAdd),

                boiStatus: boi.boiStatus,
                comment: boi.comment,
                boiDescription: boi.boiDescription,
                paramValue: boi.paramValue,
                boiClientProgramTargetID: boi.boiClientProgramTargetID,
                boiCode: boi.boiCode,
                boiRealizedValue: boi.boiRealizedValue,
                boiRejectionRecipient: boi.boiRejectionRecipient,
                IsCustom: boi.IsCustom,
                isExisting: false,
                missingDetails: false,
                isUpdate: true,
                isBOIUsedInProject: boi.isBOIUsedInProject,
                isExistingNewSubdimension: false,
                isDisableUpdatingDimension: boi.hasOwnProperty('isDisableUpdatingDimension') ? boi.isDisableUpdatingDimension : false,
                duplicateParameterName: false,
                leadingIndicator: boi.leadingIndicator,
                reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
                reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
                customParameterList: boi.customParameterList,
                parameterList: boi.parameterList ? boi.parameterList : {},
                newSubDimensionName: boi.newSubDimensionName,
                noOfParameter: boi.noOfParameter,
                boiAdditionComment: boi.boiAdditionComment,
                boiOldComment: boi.boiOldComment,
                metricDefinitionID: boi.metricDefinitionID,
                warningUOM: false,
                offeringName: boi.offeringName,
                offeringId: boi.offeringId,
                boiWheelVisibility:
                  boi.boiWheelVisibility !== undefined ? boi.boiWheelVisibility : 1,
                boiParentID:
                  boi.boiParentID !== undefined || boi.boiParentID === '' ? boi.boiParentID : 2,
                boiOldComment: boi.boiOldComment,
              });
            }
          });
        });
      });

      this.setState({
        selectedCustomBOI: JSON.parse(JSON.stringify(temp)),
        selectedCustomBOIDisplay: JSON.parse(JSON.stringify(temp)),
      });
    };

    filterSubDimension = () => {
      let selected = this.state.customboiToAdd;
      let subdimension = this.prepDropdownLists('subdimension', selected);
      // if options is only 1 then select it by default
      if (subdimension.length === 1) {
        selected.subdimension = subdimension[0];
      } else {
        selected.subdimension = {};
      }
      this.setState({
        list: { ...this.state.list, subdimension: subdimension },
        customboiToAdd: selected,
      }, () => {
        if (selected.dimension.value === 1) {
          this.filterMetricCalculation(selected.dimension, false);
        }
      });
    };

    filterAggregationLogic = (e) => {
      let selected = this.state.customboiToAdd;
      let aggregationlogic = this.prepDropdownLists('aggregationlogic', selected);
      // if options is only 1 then select it by default
      if (aggregationlogic.length === 1) {
        selected.aggregationlogic = aggregationlogic[0];
      } else {
        selected.aggregationlogic = {};
      }
      this.setState({
        list: { ...this.state.list, aggregationlogic: aggregationlogic },
        customboiToAdd: selected,
      });
    };

    onSelectDropdown = (e, field, auto=false) => {
      let selected = this.state.customboiToAdd;
      let form = this.state.formData;
      let tempParameterList = [];
      switch (field) {
        case 'uom':
          selected.uom = e;
          if (['%', 'USD'].indexOf(e.label) > -1) {
            this.filterMetricCalculation(e, false);
          }
          if (selected.boidefinition.label === 'Value') {
            form.customParameterList[0].ParameterUOM = e.label;
          }
          break;
        case 'dimension':
          selected.dimension = e;
          form.newSubDimensionName = '';
          this.filterSubDimension();
          this.setState({
            isExistingNewSubdimension: false,
          });
          break;
        case 'subdimension':
          selected.subdimension = e;
          if (e.label !== 'Add new Subdimension') {
            form.newSubDimensionName = '';
            this.setState({
              isExistingNewSubdimension: false,
            });
          }
          break;
        case 'boidefinition':
          // if (
          //   // Object.keys(selected.boidefinition).length > 0 &&
          //   // selected.boidefinition.label.toLowerCase() !== e.label.toLowerCase()
          // )
          if (form.customParameterList.length !== 0) {
            if (
              Object.keys(selected.boidefinition).length > 0 &&
              selected.boidefinition.label.toLowerCase() !== e.label.toLowerCase()
            ) {
              this.state.list.boidefinition.map((item) => {
                if (item.value === e.value) {
                  form.noOfParameter = item.numberOfParameter;
                  for (let i = 0; i < item.numberOfParameter; i++) {
                    let paramList = form.customParameterList[i];
                    tempParameterList.push({
                      ParameterName: '',
                      ParameterUOM:
                        e.label === 'Value'
                          ? selected.uom.label
                          : paramList == undefined
                            ? ''
                            : paramList.ParameterUOM,
                      ParameterMasterID: 0,
                      ParameterCode: '',
                      ParameterCustomCode: 'P' + (i + 1),
                      BOIID: 0,
                      ClientProgramID: 0,
                      LoggedInUserName: '',
                    });
                  }
                }
              });
              form.customParameterList = tempParameterList;
              form.reasonForNoAggregationLogic = '';
              form.reasonForNoBOIDefinition = '';
              this.setState({
                duplicateParameterName: false,
              });
            }
          } else {
            this.state.list.boidefinition.map((item) => {
              if (item.value === e.value) {
                form.noOfParameter = item.numberOfParameter;
                for (let i = 0; i < item.numberOfParameter; i++) {
                  tempParameterList.push({
                    ParameterName: '',
                    ParameterUOM: e.label === 'Value' ? selected.uom.label : '',
                    ParameterMasterID: 0,
                    ParameterCode: '',
                    ParameterCustomCode: 'P' + (i + 1),
                    BOIID: 0,
                    ClientProgramID: 0,
                    LoggedInUserName: '',
                  });
                }
              }
            });
            form.customParameterList = tempParameterList;
            form.reasonForNoAggregationLogic = '';
            form.reasonForNoBOIDefinition = '';

            this.setState({
              duplicateParameterName: false,
            });
          }

          selected.boidefinition = e;
          this.filterAggregationLogic(e);
          break;
        case 'aggregationlogic':
          selected.aggregationlogic = e;
          break;
        case 'suboffering':
          selected.suboffering = e;
          break;
        default:
          break;
      }
      this.setState(
        {
          customboiToAdd: selected,
          formData: form,
        },
        () => {
          if(!auto) {
            this.checkNewlyAddedBOI(this.state.formData, this.state.customboiToAdd)
          }
          this.setState({
            isExisting: this.checkForDuplicates(
              this.state.customboiToAdd,
              this.state.selectedCustomBOI
            ),
          });
        }
      );
    };

    checkOverAllExistingBOI = async (p, c) => {
      let disableOverallSave = false;

      if (p.length === c.length) {
        p.map((pre) => {
          if (pre.IsCustom) {
            let curBOI = c.filter((cur) => cur.boiClientProgramTargetID === pre.boiClientProgramTargetID);
            curBOI = curBOI.length !== undefined && curBOI.length > 0 ? curBOI[0] : [];
            if (!IsObjEmpty(curBOI) && !disableOverallSave) {
              //check custom parameter list
              let currentBOI = curBOI.customParameterList//.filter((current) => current.ParameterMasterID === previous.ParameterMasterID);
              if (pre.customParameterList.length === curBOI.customParameterList.length) {
                pre.customParameterList.map((previous, i) => {
                  if (currentBOI.length > 0 && !disableOverallSave && (
                    currentBOI[i].ParameterName !== previous.ParameterName ||
                    currentBOI[i].ParameterUOM !== previous.ParameterUOM
                  )) {
                    disableOverallSave = true
                  }
                })
              }

              if (!disableOverallSave &&
                (pre.aggregationlogic.label !== curBOI.aggregationlogic.label ||//
                  pre.boi.label !== curBOI.boi.label ||//
                  pre.boiAdditionComment !== curBOI.boiAdditionComment ||//
                  pre.boiDescription !== curBOI.boiDescription ||//
                  pre.boidefinition.label !== curBOI.boidefinition.label ||//
                  // pre.description !== curBOI.description ||//
                  pre.dimension.label !== curBOI.dimension.label ||//
                  pre.leadingIndicator !== curBOI.leadingIndicator ||//
                  pre.newSubDimensionName !== curBOI.newSubDimensionName ||//
                  pre.reasonForNoAggregationLogic !== curBOI.reasonForNoAggregationLogic ||//
                  pre.reasonForNoBOIDefinition !== curBOI.reasonForNoBOIDefinition ||//
                  pre.subdimension.label !== curBOI.subdimension.label ||//
                  pre.suboffering.label !== curBOI.suboffering.label ||
                  pre.uom.label !== curBOI.uom.label || //
                  pre.noOfParameter !== curBOI.noOfParameter//
                )) {
                disableOverallSave = true
              }
            }
          }
        })
      }

      this.props.customBOIValidationMessage('unsavedExistingCustomBOI', disableOverallSave);
    };

    checkExistingBOI = async (p, c) => {
      let disableOverallSave = false;
      //check custom parameter list
      if (p.customParameterList.length === c.customParameterList.length) {
        p.customParameterList.map((pre) => {
          let curBOI = c.customParameterList.filter((cur) => cur.ParameterMasterID === pre.ParameterMasterID);
          if (curBOI.length > 0 && !disableOverallSave && (
            curBOI[0].ParameterName !== pre.ParameterName ||
            curBOI[0].ParameterUOM !== pre.ParameterUOM
          )) {
            disableOverallSave = true
          }
        })
      }

      if (!disableOverallSave &&
        (p.aggregationlogic.label !== c.aggregationlogic.label ||//
          p.boi.label !== c.boi.label ||//
          p.boiAdditionComment !== c.boiAdditionComment ||//
          p.boiDescription !== c.boiDescription ||//
          p.boidefinition.label !== c.boidefinition.label ||//
          // p.description !== c.description ||//
          p.dimension.label !== c.dimension.label ||//
          p.leadingIndicator !== c.leadingIndicator ||//
          p.newSubDimensionName !== c.newSubDimensionName ||//
          p.reasonForNoAggregationLogic !== c.reasonForNoAggregationLogic ||//
          p.reasonForNoBOIDefinition !== c.reasonForNoBOIDefinition ||//
          p.subdimension.label !== c.subdimension.label ||//
          p.suboffering.label !== c.suboffering.label ||
          p.uom.label !== c.uom.label || //
          p.noOfParameter !== c.noOfParameter//
        )) {
        disableOverallSave = true
      }

      this.props.customBOIValidationMessage('unsavedExistingCustomBOI', disableOverallSave);
    };

    checkNewlyAddedBOI = async (fData, dData) => {
      let disableOverallSave = false;

      //dropdown data
      if (!IsObjEmpty(dData.boi) || !IsObjEmpty(dData.uom) || !IsObjEmpty(dData.dimension)
        || !IsObjEmpty(dData.subdimension) || !IsObjEmpty(dData.boidefinition) || !IsObjEmpty(dData.aggregationlogic)
        || !IsObjEmpty(dData.suboffering)); {
        Object.keys(dData).map(function (key, index) {
          disableOverallSave = !disableOverallSave ? IsValidStr(dData[key].label) : disableOverallSave
        });
      }

      //form data
      if (!disableOverallSave && (IsValidStr(fData.boiDescription) || IsValidStr(fData.boiName)
        || IsValidStr(fData.commentboiaddition)
        || fData.customParameterList.length > 0 || IsValidStr(fData.leadingPractice) || IsValidStr(fData.newSubDimensionName)
        || IsValidStr(fData.noOfParameter) || IsValidStr(fData.reasonForNoAggregationLogic)
        || IsValidStr(fData.reasonForNoBOIDefinition))) {
        disableOverallSave = true;
      }
      this.props.customBOIValidationMessage('unsavedNewCustomBOI', disableOverallSave);
    };

    checkForDuplicates = (toAdd, selectedBOIList) => {
      let doesExist = false;
      selectedBOIList.forEach((item) => {
        if (String(item.boi.label).trim().toLowerCase() === String(toAdd.boi.label).trim().toLowerCase() && String(item.uom.label).trim().toLowerCase() === String(toAdd.uom.label).trim().toLowerCase()) {
          doesExist = true;
        }
      });
      this.props.customBOIValidationMessage('isExisting', doesExist);
      return doesExist;
    };

    checkForDuplicateSubDimension = (formData) => {
      let subdimension =
        this.props.ProgramConfigurationData.customBOIDropdownList.BOIMappedSubDimensionList;
      let doesExist = false;
      subdimension.map((item) => {
        if (
          item.SubDimensionName.toUpperCase() === formData.newSubDimensionName.toUpperCase() &&
          formData.newSubDimensionName !== '' &&
          item.SubDimensionName !== ''
        ) {
          doesExist = true;
        }
      });

      //to confirm if all subdimension or custom subdimension only
      return doesExist;
    };

    addBOI = () => {
      let selectedBOIList = this.state.selectedCustomBOI;
      let list = this.state.list;
      let toAdd = this.state.customboiToAdd;
      let form = this.state.formData;
      let isEditRejected =
        this.props.type === 'Edit Rejected' || this.props.type === 'Edit Approved' ? true : false;
      selectedBOIList.push({
        boiList: list.boi,
        boiListDisplay: [toAdd.boi],
        uomList: list.uom,
        dimensionList: list.dimension,
        subdimensionList: list.subdimension,
        boidefinitionList: list.boidefinition,
        aggregationlogicList: list.aggregationlogic,
        subofferingList: list.suboffering,
        boi: toAdd.boi,
        uom: toAdd.uom,
        dimension: toAdd.dimension,
        subdimension: toAdd.subdimension,
        boidefinition: toAdd.boidefinition,
        aggregationlogic: toAdd.aggregationlogic,
        suboffering: toAdd.suboffering,
        isExisting: false,
        missingDetails: false,
        IsCustom: true,
        isUpdate: true,
        isExistingNewSubdimension: false,
        isBOIUsedInProject: false,

        // additional details
        boiStatus: '',
        comment: isEditRejected ? '' : form.commentboiaddition,
        boiDescription: form.boiDescription,
        paramValue: '',
        leadingIndicator: form.leadingPractice,
        boiClientProgramTargetID: 0,
        boiCode: '',
        boiRealizedValue: '',
        boiRejectionRecipient: '',
        reasonForNoBOIDefinition: form.reasonForNoBOIDefinition,
        reasonForNoAggregationLogic: form.reasonForNoAggregationLogic,
        customParameterList: form.customParameterList,
        newSubDimensionName: form.newSubDimensionName,
        noOfParameter: form.noOfParameter,
        boiAdditionComment: form.commentboiaddition,
        boiOldComment: '',
        isNewlyAddedInReject: isEditRejected ? true : false,
        offeringName: this.props.ProgramConfigurationData.newProgramDetails.offering.label,
        offeringId: this.props.ProgramConfigurationData.newProgramDetails.offering.value,
        boiWheelVisibility: 1,
        boiParentID: 2,
        tempCustomBOIID: `${toAdd.boi.label}-${toAdd.uom.label}`,
      });
      this.removeDeletedBOI(toAdd);
      this.props.customBOIValidationMessage('unsavedNewCustomBOI', false);
      this.props.setAllSelectedBOIs(selectedBOIList);
      this.setState(
        {
          selectedCustomBOI: JSON.parse(JSON.stringify(selectedBOIList)),
          selectedCustomBOIDisplay: JSON.parse(JSON.stringify(selectedBOIList)),
        },
        () => {
          this.clearState();
          this.prepInitialList();
        }
      );
    };

    restoreDeletedBOI = (selected) => {
      let selectedBOIList = this.state.selectedCustomBOI;
      let duplicate = this.checkForDuplicates(selected, this.state.selectedCustomBOI);
      let inComplete = false;
      if (Object.keys(selected.boi).length === 0 || Object.keys(selected.uom).length === 0) {
        inComplete = true;
      }
      if (!duplicate) {
        selectedBOIList.push(selected);
        this.props.setAllSelectedBOIs(selectedBOIList);
        this.setState(
          {
            selectedCustomBOI: selectedBOIList,
            selectedCustomBOIDisplay: selectedBOIList,
            inComplete: inComplete,
          },
          () => {
            this.setState({
              isExisting: this.checkForDuplicates(
                this.state.customboiToAdd,
                this.state.selectedCustomBOI
              ),
            });
          }
        );
      }
      this.removeDeletedBOI(selected);
    };

    deleteBOI = (selected, index) => {
      let selectedBOIList = this.state.selectedCustomBOI;
      let deleted = this.state.deletedBOIForUndo;
      let templist = [];
      let tempDeleted = [];

      selectedBOIList.map((item, i) => {
        if (item.tempCustomBOIID !== selected.tempCustomBOIID) {
          templist.push(item);
        } else {
          if (this.state.duplicateBOI && !item.isExisting) {
            templist.push(item);
          } else {
            tempDeleted.push(item);
          }
        }
      });

      deleted.push(selected);

      this.props.setAllSelectedBOIs(templist);
      this.props.setDeletedAll(templist.length === 0);
      this.setState(
        {
          selectedCustomBOI: templist,
          selectedCustomBOIDisplay: templist,
          deletedAll: templist.length === 0,
          deletedBOIForUndo: deleted,
        },
        () => {
          this.setState({
            isExisting: this.checkForDuplicates(
              this.state.customboiToAdd,
              this.state.selectedCustomBOI
            ),
            duplicateBOI: false,
            inComplete: false,
          });

          this.props.setDeletedBOICustom(deleted);
          this.checkOverAllExistingBOI(this.state.selectedCustomBOI, this.state.selectedCustomBOIDisplay)
        }
      );
    };

    removeDeletedBOI = (selected) => {
      let deleted = this.state.deletedBOIForUndo;
      let templist = [];
      deleted.map((item, i) => {
        if (item.boi.label !== selected.boi.label || item.uom.label !== selected.uom.label) {
          templist.push(item);
        }
      });

      this.setState({
        deletedBOIForUndo: templist,
      });

      this.props.setDeletedBOICustom(templist);
    };

    clearState = () => {
      var ele = document.getElementsByName('leadingPractice');
      for (var i = 0; i < ele.length; i++) {
        ele[i].checked = false;
      }
      this.setState({
        list: {
          boi: [],
          uom: [],
          dimension: [],
          subdimension: [],
          boidefinition: [],
          aggregationlogic: [],
          suboffering: [],
        },
        customboiToAdd: {
          boi: {},
          uom: {},
          dimension: {},
          subdimension: {},
          boidefinition: {},
          aggregationlogic: {},
          suboffering: {},
        },
        formData: {
          boiName: '',
          boiDescription: '',
          leadingPractice: '',
          commentboiaddition: '',
          newSubDimensionName: '',
          reasonForNoBOIDefinition: '',
          reasonForNoAggregationLogic: '',
          noOfParameter: '',
          customParameterList: [],
        },
        isExisting: false,
      });
    };

    componentWillUnmount = () => {
      this.clearState();
      this.props.customBOIValidationMessage('unsavedNewCustomBOI', false);
      this.props.customBOIValidationMessage('missingDetails', false);
      this.props.customBOIValidationMessage('inComplete', false);
      this.props.customBOIValidationMessage('isExisting', false);
      this.props.customBOIValidationMessage('customBOIError', false);
      this.props.customBOIValidationMessage('unsavedExistingCustomBOI', false);
    };

    formCallback = (e, id, i) => {
      id = IsValid(id) ? id : e.id;
      let tempFormData = this.state.formData;
      let tempCustomBOISelected = this.state.customboiToAdd;
      let templist = [];
      let paramID = 0;

      switch (id) {
        case 'customBOIName':
          templist.push({ label: e.value, text: e.value, value: 0 });
          tempCustomBOISelected.boi = templist[0];
          tempFormData.boiName = e.value;
          break;
        case 'customBOIDesc':
          tempFormData.boiDescription = e.value;
          break;
        case 'leadingPractice':
          tempFormData.leadingPractice = e;
          break;
        case 'customBOIComment':
          tempFormData.commentboiaddition = e.value;
          break;
        case 'customSubDimensionName':
          tempFormData.newSubDimensionName = e.value;
          break;
        case 'customBOIReason':
          tempFormData.reasonForNoBOIDefinition = e.value;
          break;
        case 'AggregationLogicReason':
          tempFormData.reasonForNoAggregationLogic = e.value;
          break;
        case 'parameter' + i:
          // paramID = tempFormData.customParameterList.findIndex((obj => obj.ParameterMasterID == i));
          tempFormData.customParameterList[i - 1].ParameterName = e.value;
          if (tempFormData.customParameterList.length > 1) {
            let paramList = tempFormData.customParameterList.filter(
              (x) =>
                x.ParameterCustomCode !==
                tempFormData.customParameterList[i - 1].ParameterCustomCode
            );
            paramList.map((item) => {
              if (e.value === item.ParameterName && e.value !== '') {
                this.setState({ duplicateParameterName: true });
              } else {
                this.setState({ duplicateParameterName: false });
              }
            });
          }
          break;
        case 'uom' + i:
          // paramID = tempFormData.customParameterList.findIndex((obj => obj.ParameterMasterID == i));
          tempFormData.customParameterList[i - 1].ParameterUOM = e.label;
          this.setState({ warningUOM: true });
          break;
        default:
      }

      this.setState(
        {
          formData: tempFormData,
          customboiToAdd: tempCustomBOISelected,
          list: { ...this.state.list, boi: tempCustomBOISelected },
        },
        () => {
          this.checkNewlyAddedBOI(this.state.formData, this.state.customboiToAdd)
          this.setState({
            isExistingNewSubdimension: this.checkForDuplicateSubDimension(this.state.formData),
            isExisting: this.checkForDuplicates(
              this.state.customboiToAdd,
              this.state.selectedCustomBOI
            ),
          });
        }
      );
    };

    formEditCallback = (e, id, prev, i) => {
      let bois = this.state.selectedCustomBOIDisplay;
      id = IsValid(id) ? id : e.id;
      let templist = [];
      let index = 0;
      bois.map((item, i) => {
        if (item.boi.label === prev.boi.label && item.uom.label === prev.uom.label) {
          index = i;
        }
      });

      switch (id) {
        case 'customBOIName' + i:
          templist.push({ label: e.value, text: e.value, value: 0 });
          bois[index].boi = templist[0];
          break;
        case 'customBOIDesc' + i:
          bois[index].description = e.value;
          bois[index].boiDescription = e.value;
          break;
        case 'leadingPractice' + i:
          bois[index].leadingIndicator = e;
          break;
        case 'customBOIComment' + i:
          bois[index].boiAdditionComment = e.value;
          break;
        case 'customSubDimensionName' + i:
          bois[index].newSubDimensionName = e.value;
          bois[index].subdimension.subDimensionName = e.value;
          break;
        case 'customBOIReason' + i:
          bois[index].reasonForNoBOIDefinition = e.value;
          break;
        case 'AggregationLogicReason' + i:
          bois[index].reasonForNoAggregationLogic = e.value;
          break;
        case 'parameter' + i:
          // paramID = bois[index].customParameterList.findIndex((obj => obj.ParameterMasterID == i));
          bois[index].customParameterList[i].ParameterName = e.value;
          if (bois[index].customParameterList.length > 1) {
            let paramList = bois[index].customParameterList.filter(
              (x) =>
                x.ParameterCustomCode !== bois[index].customParameterList[i].ParameterCustomCode
            );
            paramList.map((item) => {
              if (e.value === item.ParameterName && e.value !== '') {
                bois[index].duplicateParameterName = true;
              } else {
                bois[index].duplicateParameterName = false;
              }
            });
          }
          break;
        case 'uom' + i:
          // paramID = bois[index].customParameterList.findIndex((obj => obj.ParameterMasterID == i));
          bois[index].customParameterList[i].ParameterUOM = e.label;
          bois[index].warningUOM = true;
          break;
        default:
      }

      let toAdd = {
        boi: bois[index].boi,
        uom: bois[index].uom,
      };
      let boiListToCheck = [];
      let boiToCheck = [];
      bois.map((item, i) => {
        if (i !== index) {
          boiListToCheck.push(item);
        } else {
          boiToCheck.push(item);
        }
      });
      bois[index].isExistingNewSubdimension = this.checkForDuplicateSubDimension(boiToCheck[0]);
      bois[index].isExisting = this.checkForDuplicates(toAdd, boiListToCheck);

      let duplicateBOI = bois.some((item) => {
        this.props.customBOIValidationMessage('duplicateBOI', item.isExisting);
        return item.isExisting;
      });

      let inComplete = this.state.inComplete;
      let hasBlankPrameterName = false;
      let hasBlankParamUOM = false;
      let isInvalidParameterName = false;
      bois[index].customParameterList.map((param) => {
        if (param.ParameterName === '' || param.ParameterName === undefined) {
          hasBlankPrameterName = true;
        }
        if (param.ParameterUOM === '' || param.ParameterUOM === undefined) {
          hasBlankParamUOM = true;
        }
        if (!IsValidText(param.ParameterName)) {
          isInvalidParameterName = true;
        }
      });
      if (
        hasBlankPrameterName ||
        hasBlankParamUOM ||
        bois[index].boiDescription === '' ||
        bois[index].boiAdditionComment === '' ||
        bois[index].boi.label === '' ||
        Object.keys(bois[index].boi).length === 0 ||
        Object.keys(bois[index].uom).length === 0 ||
        Object.keys(bois[index].dimension).length === 0 ||
        Object.keys(bois[index].subdimension).length === 0 ||
        Object.keys(bois[index].boidefinition).length === 0 ||
        Object.keys(bois[index].aggregationlogic).length === 0 ||
        Object.keys(bois[index].suboffering).length === 0
      ) {
        inComplete = true;
        bois[index].missingDetails = true;
        this.props.customBOIValidationMessage('missingDetails', true);
        this.props.customBOIValidationMessage('inComplete', true);
      } else if (
        isInvalidParameterName ||
        !IsValidText(bois[index].boiDescription) ||
        !IsValidText(bois[index].boiAdditionComment) ||
        !IsValidText(bois[index].boi.label)
      ) {
        inComplete = true;
        bois[index].missingDetails = true;
        this.props.customBOIValidationMessage('invalidValues', true);
      } else {
        inComplete = false;
        bois[index].missingDetails = false;
        this.props.customBOIValidationMessage('missingDetails', false);
        this.props.customBOIValidationMessage('inComplete', false);
        this.props.customBOIValidationMessage('invalidValues', false);
      }

      this.setState({
        selectedCustomBOIDisplay: bois,
        duplicateBOI: duplicateBOI,
        inComplete: inComplete,
      }, () => {
        this.checkOverAllExistingBOI(this.state.selectedCustomBOI, this.state.selectedCustomBOIDisplay)
      });
    };

    onEditCustomBOI = (e, field, prev) => {
      let bois = this.state.selectedCustomBOIDisplay;
      let boisSelected = this.state.selectedCustomBOI;
      let index = 0;
      let tempParameterList = [];
      bois.map((item, i) => {
        if (item.boi.label === prev.boi.label && item.uom.label === prev.uom.label) {
          index = i;
        }
      });

      switch (field) {
        case 'uom':
          bois[index].uom = e;
          if (['%', 'USD'].indexOf(e.label) > -1) {
            this.filterMetricCalculation(e, true, prev);
          }

          if (bois[index].boidefinition.label === 'Value') {
            bois[index].customParameterList[0].ParameterUOM = e.label;
          }
          break;
        case 'dimension':
          bois[index].dimension = e;
          if (e.value === 1) {
            this.filterMetricCalculation(e, true, prev);
          }
          let dataDim = this.filterEditSubDimension({
            boi: bois[index].boi,
            uom: bois[index].uom,
            dimension: bois[index].dimension,
            subdimension: bois[index].subdimension,
            boidefinition: bois[index].boidefinition,
            aggregationlogic: bois[index].aggregationlogic,
            suboffering: bois[index].suboffering,
          });
          bois[index].subdimensionList = dataDim.list;
          if (e.label === 'Custom') {
            bois[index].subdimension.label = 'Add new Subdimension';
            bois[index].subdimension.text = 'Add new Subdimension';
            bois[index].subdimension.value = 0;
          } else {
            // if(bois.subdimensionList.filter(x => x.label === bois.subdimension.label).length === 0){}
            if (Object.keys(dataDim.sel.subdimension).length === 0) {
              bois[index].subdimension = bois[index].subdimensionList[0];
            } else {
              bois[index].subdimension = dataDim.sel.subdimension;
            }
          }
          bois[index].isExistingNewSubdimension = false;
          bois[index].newSubDimensionName = '';
          break;
        case 'subdimension':
          bois[index].subdimension = e;
          if (e.label !== 'Add new Subdimension') {
            bois[index].newSubDimensionName = '';
            bois[index].isExistingNewSubdimension = false;
          }
          break;
        case 'boidefinition':
          let boidef = bois[index].boidefinitionList.filter((x) => x.value === e.value);
          if (bois[index].boidefinition.label.toLowerCase() !== e.label.toLowerCase()) {
            bois[index].duplicateParameterName = false;
            if (boidef[0].value === e.value) {
              if (!bois[index].noOfParameter) {
                bois[index]['noOfParameter'] = boidef[0].numberOfParameter;
              } else {
                bois[index].noOfParameter = boidef[0].numberOfParameter;
              }
              for (let i = 0; i < boidef[0].numberOfParameter; i++) {
                let paramList = boisSelected[index].customParameterList[i];
                tempParameterList.push({
                  ParameterName: '',
                  ParameterUOM: e.label === 'Value' ? bois[index].uom.label : '',

                  ParameterMasterID: paramList === undefined ? 0 : paramList.ParameterMasterID,
                  ParameterCode: paramList === undefined ? '' : paramList.ParameterCode,
                  ParameterCustomCode: 'P' + (i + 1),
                  BOIID: bois[index].boi.value,
                  ClientProgramID: paramList === undefined ? 0 : paramList.ClientProgramID,
                  LoggedInUserName: paramList === undefined ? '' : paramList.LoggedInUserName,
                });
              }
            }
            bois[index].reasonForNoBOIDefinition = '';
            bois[index].reasonForNoAggregationLogic = '';

            bois[index].customParameterList = tempParameterList;
            bois[index].boidefinition = e;
            let dataBoidefinition = this.filterEditAggregationLogic({
              boi: bois[index].boi,
              uom: bois[index].uom,
              dimension: bois[index].dimension,
              subdimension: bois[index].subdimension,
              boidefinition: bois[index].boidefinition,
              aggregationlogic: bois[index].aggregationlogic,
              suboffering: bois[index].suboffering,
            });
            bois[index].aggregationlogicList = dataBoidefinition.list;
            bois[index].aggregationlogic = dataBoidefinition.sel.aggregationlogic;
          }
          break;
        case 'aggregationlogic':
          bois[index].aggregationlogic = e;
          break;
        case 'suboffering':
          bois[index].suboffering = e;
          break;
        default:
          break;
      }
      let toAdd = {
        boi: bois[index].boi,
        uom: bois[index].uom,
      };
      let boiListToCheck = [];

      bois.map((item, i) => {
        if (i !== index) {
          boiListToCheck.push(item);
        }
      });
      bois[index].isExisting = this.checkForDuplicates(toAdd, boiListToCheck);

      let hasBlankPrameterName = false;
      let hasBlankParamUOM = false;
      bois[index].customParameterList.map((param) => {
        if (param.ParameterName === '' || param.ParameterName === undefined) {
          hasBlankPrameterName = true;
        }
        if (param.ParameterUOM === '' || param.ParameterUOM === undefined) {
          hasBlankParamUOM = true;
        }
      });

      if (
        Object.keys(bois[index].boi).length === 0 ||
        Object.keys(bois[index].uom).length === 0 ||
        Object.keys(bois[index].dimension).length === 0 ||
        Object.keys(bois[index].subdimension).length === 0 ||
        Object.keys(bois[index].boidefinition).length === 0 ||
        Object.keys(bois[index].aggregationlogic).length === 0 ||
        Object.keys(bois[index].suboffering).length === 0 ||
        hasBlankPrameterName ||
        hasBlankParamUOM
      ) {
        bois[index].missingDetails = true;
        this.props.customBOIValidationMessage('missingDetails', true);
      } else {
        bois[index].missingDetails = false;
        this.props.customBOIValidationMessage('missingDetails', false);
      }

      let duplicateBOI = false;
      let inComplete = false;
      this.props.customBOIValidationMessage('duplicateBOI', false);
      this.props.customBOIValidationMessage('inComplete', false);
      bois.map((item) => {
        if (item.isExisting) {
          duplicateBOI = true;
          this.props.customBOIValidationMessage('duplicateBOI', true);
        }
        if (
          Object.keys(item.boi).length === 0 ||
            Object.keys(item.uom).length === 0 ||
            Object.keys(item.dimension).length === 0 ||
            Object.keys(item.subdimension).length === 0 ||
            item.subdimension.label === 'Add new Subdimension'
            ? item.newSubDimensionName === ''
            : false ||
            Object.keys(item.boidefinition).length === 0 ||
            Object.keys(item.aggregationlogic).length === 0 ||
            Object.keys(item.suboffering).length === 0
        ) {
          inComplete = true;
          this.props.customBOIValidationMessage('inComplete', true);
        }
      });
      this.setState({
        selectedCustomBOIDisplay: bois,
        duplicateBOI: duplicateBOI,
        inComplete: inComplete,
      }, () => {
        this.checkOverAllExistingBOI(this.state.selectedCustomBOI, this.state.selectedCustomBOIDisplay)
      });
    };

    updateCustomBOI = (index) => {
      let selectedCustomBOIDisplay = this.state.selectedCustomBOIDisplay;
      let selectedCustomBOI = this.state.selectedCustomBOI;

      selectedCustomBOIDisplay[index].isUpdate = true;
      selectedCustomBOI[index] = selectedCustomBOIDisplay[index]

      this.props.setAllSelectedBOIs(selectedCustomBOI);
      this.setState({
        selectedCustomBOI: selectedCustomBOI,
        defaultBOIKey: '',
      }, () => {
        this.checkOverAllExistingBOI(this.state.selectedCustomBOI, this.state.selectedCustomBOIDisplay)
      });
    };

    handleCustomBOICollapse = (index) => {
      let bois = this.state.selectedCustomBOIDisplay;
      for (var i in bois) {
        if (i != index) {
          bois[i].isUpdate = true;
        }
      }

      let isUpdate = bois[index].isUpdate;
      bois[index].isUpdate = !isUpdate;
      this.setState({
        selectedCustomBOIDisplay: bois,
        defaultBOIKey: this.state.defaultBOIKey === index ? '' : index,
      });
    };

    filterEditSubDimension = (toAdd) => {
      let selected = toAdd;
      let subdimension = this.prepDropdownLists('subdimension', selected);
      // if options is only 1 then select it by default
      if (subdimension.length === 1) {
        selected.subdimension = subdimension[0];
      } else {
        selected.subdimension = {};
      }
      return { sel: selected, list: subdimension };
    };

    filterEditAggregationLogic = (toAdd) => {
      let selected = toAdd;
      let aggregationlogic = this.prepDropdownLists('aggregationlogic', selected);
      // if options is only 1 then select it by default
      if (aggregationlogic.length === 1) {
        selected.aggregationlogic = aggregationlogic[0];
      } else {
        selected.aggregationlogic = {};
      }
      return { sel: selected, list: aggregationlogic };
    };

    filterMetricCalculation = (e, isEdit, prevItem = {}) => {
      let selected = this.state.customboiToAdd;
      let boidefinition = this.prepDropdownLists('boidefinition', selected);
      let form = this.state.formData;
      // if uom is % then auto populate with Num/Dem
      if (['%', 'USD'].indexOf(selected.uom.label) === -1) {
        selected.boidefinition = {};
      }
      this.setState(
        {
          list: { ...this.state.list, boidefinition: boidefinition },
          customboiToAdd: selected,
        },
        () => {
          if (!isEdit) {
            if (selected.uom.label === 'USD' && selected.dimension.value === 1) {
              this.onSelectDropdown(boidefinition[1], 'boidefinition');
            } else if (selected.uom.label === '%' || form.customParameterList.length === 2) {
              this.onSelectDropdown(boidefinition[0], 'boidefinition');
            } else if (form.customParameterList.length === 1) {
              this.onSelectDropdown(boidefinition[1], 'boidefinition');
            }
          } else if (prevItem.uom.label === '%') {
            this.onEditCustomBOI(boidefinition[0], 'boidefinition', prevItem);
          } else if (prevItem.uom.label === 'USD' && prevItem.dimension.value === 1) {
            this.onEditCustomBOI(boidefinition[1], 'boidefinition', prevItem);
          }
        }
      );
    };

    render() {
      const { boiMappingList, newProgramDetails, selectedProgramDetails, programClientDetails } =
        this.props.ProgramConfigurationData;
      return (
        <AddCustomBOIPresentational
          addBOI={this.addBOI}
          onEditCustomBOI={this.onEditCustomBOI}
          onSelectDropdown={this.onSelectDropdown}
          type={this.props.type}
          removeDeletedBOI={this.removeDeletedBOI}
          restoreDeletedBOI={this.restoreDeletedBOI}
          deleteBOI={this.deleteBOI}
          onCloseBOIModal={this.props.onCloseBOIModal}
          boiMappingList={boiMappingList}
          newProgramDetails={newProgramDetails}
          programClientDetails={programClientDetails}
          formCallback={this.formCallback}
          formEditCallback={this.formEditCallback}
          updateCustomBOI={this.updateCustomBOI}
          handleCustomBOICollapse={this.handleCustomBOICollapse}
          {...this.state}
        />
      );
    }
  }

  return AddCustomBOIContainer;
})();

// export default AddBOIPopup;
export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(withRouter(AddCustomBOI));
