import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { GrooveIconSvg, GrooveInput } from 'tfo-groove';
import CommentsIcon from "../../../../Static/images/svg/CommentsIcon.svg"
import { FormDateDDMMMYYY, FormTimeAPM } from "../../../../Utilities/Formatter";


require("./CommentaryModal.css");

const CommentaryHistoryModal = (function () {
    class CommentaryHistoryModalPresentational extends Component {
        render() {
            let commentList = this.props.commentList;
            return (
                <Modal
                    show={this.props.show}
                    onHide={() => { }}
                    size={this.props.size}
                    centered
                    dialogClassName="rvd-commentary-history-modal"
                >
                    <Modal.Header>
                        <Modal.Title>
                            <div className="header24-semibold-midnight">
                                Comment History
                            </div>
                            <GrooveIconSvg
                                customClassName="close-modal-btn"
                                size="large"
                                name="times"
                                iconStyle="solid"
                                iconColor="primary-acn"
                                callbackFunction={() => this.props.onCloseModal(false, [])}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="commentary-comments">
                                {commentList.length > 0 ?
                                    commentList.map((item, index) => {
                                        let createdTS = item.CreatedTS

                                        let fullDate = FormDateDDMMMYYY(createdTS, true) + ' | ' + FormTimeAPM(createdTS, true)
                                        return (
                                            <div accessKey={index}>
                                                <div>
                                                    <div>
                                                        <GrooveIconSvg
                                                            size="large"
                                                            name="user-circle"
                                                            iconStyle="regular"
                                                            iconColor="stat-alternate"
                                                            callbackFunction={() => ""}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div>{item.CreatedUser}</div>
                                                        <div>{item.IsEdited ? <span><span style={{ 'color': '#a100ff' }}>Edited</span> | </span> : ""} {fullDate}</div>
                                                    </div>
                                                </div>
                                                <div className="commentary-comment">
                                                    <GrooveInput
                                                        customClassName="commentary-history-label"
                                                        id="comment"
                                                        name="comment"
                                                        placeholder="Comment"
                                                        type="text"
                                                        isAutocomplete="false"
                                                        inputValue={item.Comment}
                                                        maxLength={150}
                                                        isDisabled
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="commentary-no-comment">
                                        <img src={CommentsIcon} alt="CommentsIcon" />
                                        <div style={{ 'fontWeight': '600', 'paddingBottom': '10px', 'paddingTop': '3px' }}>No comment(s) available.</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }
    }

    class CommentaryHistoryModalContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                commentList: []
            };
        }

        componentDidMount = async () => {
            let boiList = this.props.boiList;
            let commentBOIDetails = this.props.commentBOIDetails;
            let list = [];

            list = boiList.filter(item => {
                return item.boiID === commentBOIDetails.boiID
            });

            this.setState({
                commentList: list[0].RVDCommentHistory
            })
        }

        render() {
            return (
                <CommentaryHistoryModalPresentational
                    show={this.props.show}
                    size={this.props.size}
                    onCloseModal={this.props.onCloseModal}
                    commentBOIDetails={this.props.commentBOIDetails}
                    commentList={this.state.commentList}
                />
            );
        }
    }

    return CommentaryHistoryModalContainer;
})();

export default CommentaryHistoryModal;
