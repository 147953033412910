import { createStore, applyMiddleware, compose } from "redux";
import createHistory from "history/createBrowserHistory";
import thunk from "redux-thunk";
import logger from "redux-logger";

import rootReducer from "./Reducers";

export const history = createHistory();

const defaultState = {
  userInformation: {
    userID: undefined,
    userName: "",
    emailAddress: "",
    enterpriseID: "",
    userProfiles: [],
    currentUserRoleID: "",
    currentUserRole: "",
  },
  StageData: {
    reportingYearParam: { options: [], value: {}, isDisabled: false }
  },
  //CLIENT WORKFLOW
  programList: [],
  projLeadList: { list: [], clietnID: 0 },
  projDelivLocList: [],
  projDelivByList: { list: [], clietnID: 0 },
  DashboardData: { reportingYear: [], dashboardProgram: [], dashboardClients: [] }


};

// Add logger after thunk as second paramters in applyMiddleware to enable redux logging
const createStoreWithMiddleware = compose(applyMiddleware(thunk))(
  createStore
);

const store = createStoreWithMiddleware(rootReducer, defaultState);

export default store;
