import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { GrooveIconSvg, GrooveButton } from "tfo-groove";

import { HasProperty } from '../../../Utilities/Validations';
import { FormDateDDMMMYYY } from "../../../Utilities/Formatter";

import "./ALATable.css";

const ProgramApprovalHistoryModal = (function () {
  class ProgramApprovalHistoryModalPresentaional extends Component {
    render() {
      let headers = ['Program Creator', 'Approver', 'Submitted Date', 'Status']
      let statuses = this.props.statuses;
      let css = "";
      return (
        <React.Fragment>
            <div className="btn-prog-approv-history-container">
              <GrooveButton
                id="btn-prog-approv-history"
                hasIcon={true}
                type="utility"
                colorClass="stat-alternate"
                text="Program Approval History"
                iconAlignment="right"
                iconSize="tiny"
                iconName="history"
                iconStyle="solid"
                iconId="btn-icon-history"
                callbackFunction={this.props.handleShow}
              />
            </div>
            <Modal
              show={this.props.show}
              centered
              dialogClassName="programApprovalHistoryModal"
            >
              <Modal.Header>
                <Modal.Title>
                  <div className="header24-semibold-midnight" title={this.props.programName}>
                    {'Program Approval History for ' + this.props.programName} 
                  </div>
                  <div className="pah-search-bar-container">
                    <div className="pah-search-bar label14-regular-midnight">
                      <GrooveIconSvg
                        customClassName="close-modal-btn"
                        size="large"
                        name="search"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                      />
                      <input 
                        type="text" 
                        name="name" 
                        placeholder="Search..." 
                        maxLength="255" 
                        onChange={(e) => this.props.onSearch(e) } 
                        value={this.props.filter}
                      />
                    </div>
                  </div>
                  <GrooveIconSvg
                    customClassName="close-modal-btn"
                    size="large"
                    name="times"
                    iconStyle="solid"
                    iconColor="primary-acn"
                    callbackFunction={this.props.handleShow}
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="program-approval-history">
                  <div className="program-approval-history-content">          
                    <div className="dimension-table-header">
                      <div className="dimension-table-row">
                        {headers.map(name => <div key={name}>{name}</div>)}
                      </div>
                    </div>
                    <div className="dimension-table-body">
                      {
                        this.props.arsDetails.slice(0).reverse().map((item, i) => {
                          css = [statuses.completed, statuses.approved].indexOf(item.status.toLowerCase()) > -1 ? "status-completed"
                            : (item.status.toLowerCase() === statuses.rejected ? "status-rejected"
                              : [statuses.inProgress].indexOf(item.status.toLowerCase()) > -1 ? "status-notStarted"
                                : "status-inProgress");

                          return (
                            HasProperty(item, 'pName') ?
                              <div className="dimension-table-row">
                                {/* <div>{item.pName}</div> */}
                                <div>{item.lead1.split("@")[0]}</div>
                                <div>{item.lead2 !== null ? item.lead2.split("@")[0] : ''}</div>
                                <div>{FormDateDDMMMYYY(item.submittedDate)}</div>
                                <div>
                                  <div className={"program-status " + css}>
                                    {item.status}
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="empty-dimension-container" >
                                <div className="empty-program-container bodytext18-regular-slate">
                                  <span className="bodytext24-medium-slate">
                                    No Approval Request Status
                                  </span>
                                  Please select program.
                                </div>
                              </div>
                          )
                        })

                      }
                    </div>

                  </div>
                </div>
              </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    }
  }

  class ProgramApprovalHistoryModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {  
        filter: '',
        show: false,
        arsDetails: [],
        filteredArsDetails: [],
        programName: ''
      };
    }

    componentDidMount = async () => {
      try {
        this.initApprovalReqData()
      }
      catch (e) {
        console.log(e);
      }
    }

    componentDidUpdate = (nextProps) => {
      if (this.props.arsDetails !== nextProps.arsDetails) {
        this.initApprovalReqData()
      }
    }

    initApprovalReqData = async () => {
      if (this.props.arsDetails) {
        let tempArs = [];
        tempArs = this.props.arsDetails.filter((ars) => { 
          return ['approved','rejected'].indexOf(String(ars.status).toLowerCase()) !== -1
        })
        tempArs = tempArs.map((ars) => {
          return {
            ...ars,
            submittedDateFormatted: FormDateDDMMMYYY(ars.submittedDate)
          }
        })
        let pName = tempArs.length > 0 && tempArs[tempArs.length - 1].pName;
        this.setState({
          programName: pName,
          arsDetails: tempArs,
          filteredArsDetails: tempArs,
        })
      }
    }
  
    onSearch = (e) => {
      let searchKey = e.target.value;
      let result = [];
      let tData = this.state.arsDetails;
      try {
        let columns = [
          'lead1',
          'lead2',
          'submittedDateFormatted',
          'status',
        ];
        if (searchKey.length <= 1) {
          result = tData;
        } else if (searchKey.length >= 2) {
          result = tData.filter((item) => {
            return columns.some(
              (key) =>
                item[key].toString().toLowerCase().indexOf(searchKey.trim().toLowerCase()) !== -1
            );
          });
        }
      } catch (e) {
        result = tData;
      }
    
      this.setState({
        filter: searchKey,
        filteredArsDetails: result
      })
    }

    handleShow = () => {
      try {
        this.setState({
          show: !this.state.show,
          filter: '',
        }, () => {
          this.initApprovalReqData();
        })
      }
      catch (e) {
        console.log(e)
      }
    }

    render() {
      return (
        <ProgramApprovalHistoryModalPresentaional
          show={this.state.show}
          handleShow={this.handleShow}
          filter={this.state.filter}
          onSearch={this.onSearch}

          programName={this.state.programName}
          arsDetails={this.state.filteredArsDetails}
          statuses={this.props.statuses}
        />
      );
    }
  }
  
  return ProgramApprovalHistoryModalContainer;
})();

export default ProgramApprovalHistoryModal;