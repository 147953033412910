import React, { Component } from "react";
import Select, { components } from "react-select";
import { HasProperty, IsValid } from '../../../Utilities/Validations';

require("./CVMSelectDropdown.css");
const CVMSelectDropdown = (function () {
  class CVMSelectDropdownPresentational extends Component {
    render() {
      let maxWidth;
      if (HasProperty(this.props.style, 'option')) {
        maxWidth = IsValid(this.props.style.option.width) ? this.props.style.option.width : '100%';
      }
      const styles = {
        option: (base, state) => ({
          ...base,
          "&:hover": {
            color: "#fff",
            backgroundColor: "#a100ff",
            fontFamily: "Graphik-Medium",
          },
          backgroundColor: state.isSelected ? "#a100ff" : "#fff",
          color: state.isSelected ? "#fff" : "#212b36",
          whiteSpace: "nowrap",
          fontFamily: "Graphik-Regular",
          fontSize: "12px",
          padding: "10px 5px",
          wordBreak: "break-word",
          cursor: "pointer",
          width: IsValid(maxWidth) ? maxWidth : '100%'
        }),
        control: (base, state) => ({
          ...base,
          borderRadius: "25px",
          boxShadow: 0,
          fontFamily: "Graphik-Medium",
          fontSize: "14px",
          color: "rgb(161, 0, 255)",
          width: IsValid(this.props.style.control.width) ? this.props.style.control.width : '100px',
          minHeight: "38px",
          maxWidth: "500px",
          cursor: "pointer",
          fontWeight: "500",
          letterSpacing: "-0.25px",
          lineHeight: "14px",
          border: "none",
          backgroundColor: "#ffffff"
        }),
        menu: (base, state) => ({
          ...base,
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "0px 2px 24px 0px rgba(0, 0, 0, 0.15)",
          padding: "5px",
          zIndex: 2,
          width: IsValid(this.props.style.menu.width) ? this.props.style.menu.width : "100%",
          fontSize: "12px",
        }),
        singleValue: (base, state) => ({
          ...base,
          color: "rgb(123, 142, 152)",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          "&:hover": { color: "rgb(161, 0, 255)" },
          color: "rgb(161, 0, 255)", // Custom colour
        }),
      };

      return (
        <div className="single-select-dropdown" style={HasProperty(this.props.style, 'mainContainer') ? { ...this.props.style.mainContainer } : {}}>
          <div className="single-select-label">{this.props.label}</div>
          <Select
            closeMenuOnSelect={this.props.closeMenuOnSelect}
            hideSelectedOptions={false}
            isClearable={false}
            isSearchable={this.props.isSearchable}
            options={this.props.options}
            styles={styles}
            placeholder={this.props.placeholder}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(e) => this.props.onChange(e)}
            value={this.props.value}
            isDisabled={this.props.isDisabled}
            // menuIsOpen={true}
          />
        </div >
      );
    }
  }

  class CVMSelectDropdownContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: {}
      };
    }

    componentDidMount = () => {
      this.setState({
        value: this.props.value
      })
    }

    componentDidUpdate = (nextProps) => {
      if (nextProps.value !== this.props.value) {
        this.setState({
          value: this.props.value
        })
      }
    }

    onChange = (e) => {
      this.setState({
        value: e
      }, () => {
        this.props.onChange(e);
      })
    }

    render() {
      return (
        <CVMSelectDropdownPresentational
          label={this.props.label}
          placeholder={this.props.placeholder}
          options={this.props.options}
          onChange={this.props.onChange}
          value={this.state.value}
          style={this.props.style}
          isDisabled={this.props.isDisabled}
          isSearchable={this.props.isSearchable || false}
          closeMenuOnSelect={this.props.closeMenuOnSelect}
        />
      );
    }
  }

  return CVMSelectDropdownContainer;
})();

export default CVMSelectDropdown;
