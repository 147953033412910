import React, { Component } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { GrooveIconSvg } from 'tfo-groove';
import CommentsIcon from '../../../Static/images/svg/CommentsIcon.svg';
import ReactTooltip from 'react-tooltip';

const ProjectLevel = (function () {
  class ProjectLevelPresentational extends Component {
    render() {
      let projectList = this.props.projectLevelComments;
      if (!projectList) projectList = [];
      const { selectedYear, reportingYear } = this.props;
      return (
        <div className="dashboard-commentary-comments">
          <ReactTooltip />
          {projectList.length > 0 ? (
            <Accordion
              className="configure-client-collapse visualization-collapse"
              activeKey={
                this.props.panelClicked === 0
                  ? projectList[0].ClientProjectID
                  : this.props.panelClicked
              }
              onSelect={(e) => {
                this.props.panelOnClick(e);
              }}
            >
              {projectList.map((pItem) => {
                return (
                  <Card>
                    <Accordion.Collapse eventKey={pItem.ClientProjectID}>
                      <Card.Body>
                        {pItem.ProjectCommentList.map((item) => {
                          let fullDate = this.props.formatDate(item.CreatedTS);
                          return (
                            <div className="comment-card">
                              <div>
                                <div>
                                  <GrooveIconSvg
                                    id="dbcProjLvlUserCircle"
                                    size="large"
                                    name="user-circle"
                                    iconStyle="regular"
                                    iconColor="stat-alternate"
                                    callbackFunction={() => ''}
                                  />
                                </div>
                                <div>
                                  <div>{item.CreatedUser}</div>
                                  <div>
                                    {item.IsEdited ? (
                                      <span>
                                        <span style={{ color: '#a100ff' }}>Edited</span> |{' '}
                                      </span>
                                    ) : (
                                      ''
                                    )}{' '}
                                    {fullDate}
                                  </div>
                                </div>
                              </div>
                              <div className="commentary-comment">
                                <div className="projectHistory">
                                  <span>{item.Comment}</span>
                                  {(this.props.enterpriseID === this.props.programCreator ||
                                    this.props.isSuperAdmin) &&
                                    !this.props.isHistory &&
                                    selectedYear.value[0] ===
                                      reportingYear[reportingYear.length - 1].value[0] && (
                                      <span
                                        onClick={() => this.props.handleCopyComment(item.Comment)}
                                        data-tip="Copy the comment to Program"
                                      >
                                        <GrooveIconSvg
                                          id="copyIcon"
                                          customClassName={''}
                                          size="normal"
                                          name="copy"
                                          iconStyle="solid"
                                          iconColor="stat-alternate"
                                        />
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey={pItem.ClientProjectID}>
                        <div className="bodytext18-medium-midnight">{pItem.ClientProjectName}</div>
                        <GrooveIconSvg
                          id="dbcProjLvlChevron"
                          customClassName="accordion-button"
                          size="large"
                          name="chevron-down"
                          iconStyle="solid"
                          iconColor="stat-neutral"
                        />
                      </Accordion.Toggle>
                    </Card.Header>
                  </Card>
                );
              })}
            </Accordion>
          ) : (
            <div className="commentary-no-comment">
              <img src={CommentsIcon} alt="CommentsIcon" />
              <div style={{ fontWeight: '600', paddingBottom: '10px', paddingTop: '3px' }}>
                No comment(s) available.
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  class ProjectLevelContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        panelClicked: 0,
      };
    }

    panelOnClick = (index) => {
      this.setState({
        panelClicked: index,
      });
    };

    render() {
      return (
        <ProjectLevelPresentational
          projectLevelComments={this.props.projectLevelComments}
          panelOnClick={this.panelOnClick}
          panelClicked={this.state.panelClicked}
          enterpriseID={this.props.enterpriseID}
          handleCopyComment={this.props.handleCopyComment}
          programCreator={this.props.programCreator}
          formatDate={this.props.formatDate}
          isSuperAdmin={this.props.isSuperAdmin}
          isHistory={this.props.isHistory}
          selectedYear={this.props.selectedYear}
          reportingYear={this.props.reportingYear}
        />
      );
    }
  }

  return ProjectLevelContainer;
})();

export default ProjectLevel;
