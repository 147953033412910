import {
	GET_APP_Modules,
	SET_PATH
} from '../Constants';

import Utilities from './../Utilities/index'
// ----------------------------------CLIENT CONFIG PAGE----------------------------------------------

export const getModules = () => async (dispatch, state) => {
	try {
		let userProfiles = state().userInformation.userProfiles;
		let token = await Utilities.getToken();
		await fetch(
			Utilities.getApiHttps() +
			"Client/GetDashboardMenus",
			{
				method: "GET",
				headers: {
					"Cache-Control":"no-store",
					Pragma: "no-cache",
					Expires: 0,
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			}
		)
			.then((res) => res.json())
			.then((data) => {
				let modules = [];
				let selectedModule = {};
				if (Object.keys(data).length > 0) {
					data.map((module, index) => {
						modules.push({
							...module,
							isEnabled: false
						});
						if (module.ModulePath.toLowerCase() === window.location.pathname.toLowerCase()) {
							selectedModule = module;
						}
						userProfiles.map((profile) => {
							profile.AccessLevel.map((access) => {
								access.subMenu.map((subMenu) => {
									if (subMenu.MenuID === module.Id) {
										modules[index].isEnabled = true
									}
								})
							})
						})
					})
				}

				dispatch({
					type: GET_APP_Modules,
					modules: modules,
				});
			});
	} catch (err) {
		console.log(err);
	}
};

export const setPath = (path) => async (dispatch, state) => {
	try {
		dispatch({
			type: SET_PATH,
			path: path.toLocaleLowerCase(),
		});
	} catch (err) {
		console.log(err);
	}
};