import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  GrooveTabComponent,
  GrooveTextBox,
  GrooveButton,
  GrooveInput,
} from "tfo-groove";
import { Accordion, Card } from "react-bootstrap";
import { BodyDropPivotTarget } from "ag-grid-community";


require("./CVMReportValueRejectionModal.css");

const CVMReportValueRejectionModal = (function () {
  class CVMReportValueRejectionModalPresentational extends Component {
    render() {
      return (
        <div className="CVMSideModal-container-rejected">
          <div
            className="CVMSideModal-close"
          >
            <FontAwesomeIcon icon={faTimes} onClick={() => this.props.onCloseModal({
              dimensionIndex: this.props.dimensionIndex,
              subDimensionIndex: this.props.subDimensionIndex,
              boiIndex: this.props.boiIndex,
            })} />
          </div>
          <div className="CVMSideModal-title">Reject BOI Value</div>
          <div className="CVMSideModal-content">
            <div className="sub-dimension-input">
              <div className="sub-dim-input">
                <GrooveInput
                  id="input-basic"
                  name="BOI"
                  placeholder="BOI"
                  type="text"
                  isAutocomplete="false"
                  inputValue={this.props.name}
                  isDisabled={true}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="sub-dimension-input">
              <div className="sub-dim-input">
                <GrooveInput
                  id="input-basic"
                  name="IdentifiedValue"
                  placeholder="Target Value"
                  type="text"
                  isAutocomplete="false"
                  inputValue={this.props.target + " " + this.props.UOM}
                  isDisabled={true}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="sub-dimension-input">
              <div className="sub-dim-input">
                <GrooveInput
                  id="input-basic3"
                  name="RealizedValue"
                  placeholder="Realized Value"
                  type="text"
                  isAutocomplete="false"
                  inputValue={this.props.realized + " " + this.props.UOM}
                  isDisabled={true}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="sub-dimension-input">
              <div className="sub-dim-input">
                <GrooveInput
                  id="input-basic4"
                  name="DifferenceValue"
                  placeholder="Difference Value yet to be delivered"
                  type="text"
                  isAutocomplete="false"
                  inputValue={this.props.diff + " " + this.props.UOM}
                  isDisabled={true}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="comments">
              <GrooveTextBox
                maxLength={255}
                id="comment-textbox-rejected"
                name="comment-textbox-rejected"
                type="text"
                placeholder="Rejection Comment"
                statusMessage={
                  this.props.submitReject
                    ? this.props.tempReason === ""
                      ? "Reject Reason is required"
                      : null
                    : null
                }
                isValid={this.props.tempReason !== "" ? true : false}
                customClassName={
                  this.props.submitReject
                    ? this.props.tempReason === ""
                      ? "input-invalid"
                      : null
                    : null
                }
                textValue=""
                callbackFunction={e => this.props.updateReason(e)}
              />
            </div>
            <Row className="rejection-modal-buttons">
              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="outline"
                colorClass="stat-alternate"
                size="auto"
                text="Cancel"
                callbackFunction={() => this.props.onCloseModal({
                  dimensionIndex: this.props.dimensionIndex,
                  subDimensionIndex: this.props.subDimensionIndex,
                  boiIndex: this.props.boiIndex,
                })}
              />

              <GrooveButton
                id="primry-btn-2"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="solid"
                colorClass="stat-alternate"
                size="auto"
                text="Save"
                callbackFunction={() => this.props.saveReject({
                  dimensionIndex: this.props.dimensionIndex,
                  subDimensionIndex: this.props.subDimensionIndex,
                  boiIndex: this.props.boiIndex,
                })}
              />
            </Row>
          </div>
        </div>
      );
    }
  }

  class CVMReportValueRejectionModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "",
        target: 0,
        realized: 0,
        diff: 0,
        UOM: ""
      };
    }

    componentDidMount = () => {
      this.changeTextAreaClass()
    };

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    render() {
      const {
        projectDimensionsList,
      } = this.props.ConfigureProgramData;

      let boiList =
        projectDimensionsList[this.props.rejectionModal.dimensionIndex].subDimensionList[this.props.rejectionModal.subDimensionIndex].boiList[this.props.rejectionModal.boiIndex];

      let dimensionIndex = this.props.rejectionModal.dimensionIndex;
      let subDimensionIndex = this.props.rejectionModal.subDimensionIndex;
      let boiIndex = this.props.rejectionModal.boiIndex;

      if (this.state.name !== boiList.boiName
        || this.state.target !== boiList.boiTarget
        || this.state.realized !== boiList.boiRealizedValue
        || this.state.diff !== boiList.boiValueDiff
        || this.state.UOM !== boiList.boiUOM) {
        this.setState({
          name: boiList.boiName,
          target: boiList.boiTarget,
          realized: boiList.boiRealizedValue,
          diff: boiList.boiValueDiff,
          UOM: boiList.boiUOM,
        })
      }



      return (
        <CVMReportValueRejectionModalPresentational
          {...this.state}
          onCloseModal={this.props.closeRejectionModal}
          updateReason={this.props.updateReason}
          saveReject={this.props.saveReject}
          dimensionIndex={dimensionIndex}
          subDimensionIndex={subDimensionIndex}
          boiIndex={boiIndex}
          submitReject={this.props.submitReject}
          tempReason={this.props.tempReason}
        />
      );
    }
  }

  return CVMReportValueRejectionModalContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      clientID: state.ClientConfigurationData.clientDetails.clientBasicDetailsId,
      reportingYear: state.ClientConfigurationData.clientDetails.reportingYear,
      UserInformation: state.userInformation
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
        }
      ),
      dispatch
    )
)(CVMReportValueRejectionModal);
