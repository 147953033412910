import React, { Component } from "react";
import { Modal, Row,Col,Container} from "react-bootstrap";
import { GrooveIconSvg,GrooveButton,GrooveCheckboxlist } from "tfo-groove";
import {HttpGet,HttpPost} from "../../../Utilities/HTTPUtil";
import './SelectBOI.css';

export default class SelectBOI extends Component {

  constructor(){
    super();
    this.state={
      BOIData:[],
      selectAllBOI:false
    }
  }


  fetchBOIData = async()=>{
    const res = await HttpGet(`ProjectIdentifiedValueSetting/GetAllProjectBOI/clientProgramID?clientProgramID=${this.props.programID}`)
     try{
      res.map(boi =>{
      this.props.selectedBOIData.map((boitemp)=>
      {
        if(boitemp.BOIID === boi.BOIID){
          boi.IsMapped = boitemp.IsActive
        }else{
          boi.IsMapped = false;
        }
      })
     });
     let projectDataDimensions = this.props.selectedProjectData.ClientProjectTargets.Dimensions;
     projectDataDimensions.map((dim) => {
       dim.SubDimensionList.map((subDim) => {
        subDim.BOIList.map((boi) => {
          if(boi.IsActive){
            res.map((boiOption, index) => {
              if(boiOption.BOIID === boi.BOIID){
                res[index] = {...res[index], IsMapped: true}
              }
            });
          }
        });
       });
     });
     let checkAll = true;
     res.map((boi) => {
       if(!boi.IsMapped){
        checkAll = false;
        return;
       }
     })
     if(checkAll){
      this.setState({selectAllBOI:true})
     }else{
      this.setState({selectAllBOI:false})
     }

     this.setState({BOIData:res})
    }catch(error){
      console.log(error)}
  }

  selectAllBOI = (selected)=>{
    let {BOIData} = this.state;
    let BOIdataTemp = BOIData.map((boi)=>{
      return {
        ...boi,
        IsMapped:selected.target.checked
      }
    })
    this.setState({
      selectAllBOI:selected.target.checked,
      BOIData:BOIdataTemp})
  }

  selectBOI = (selectedBOI,index) =>{
    let {BOIData} = this.state;
    BOIData.map((boi,i)=>{
      if(i == index){
        boi.IsMapped=selectedBOI.target.checked
      }
    })

    let checkAll = true;
    BOIData.map((boi) => {
      if(!boi.IsMapped){
       checkAll = false;
       return;
      }
    })
    if(checkAll){
      this.setState({selectAllBOI:true})
    }else{
      this.setState({selectAllBOI:false}) 
    }
    this.setState({BOIData:BOIData})
  }

  componentDidMount(){
   this.fetchBOIData(); 
  }
  
  render() {
    console.log('Select BOI props', this.props);
    console.log('Select BOI states', this.state);

    let {programName} = this.props;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={() => this.props.toggleSelectBOIPopup()}
          animation={false}
          size="lg"
          centered
          backdrop='static'
        >
          <Modal.Header>
            <Modal.Title>
              <div className="header">
                {programName}
              </div>
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="large"
                name="times"
                iconStyle="solid"
                iconColor="primary-acn"
                callbackFunction={this.props.toggleSelectBOIPopup}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="table-border">
            <Row className="table-header">
                <Col>
                <input type="checkbox" className="header-checkbox" checked={this.state.selectAllBOI} onClick={(e)=>this.selectAllBOI(e)}/>
                </Col>
                <Col>BOI</Col>
                <Col>Unit of Measure</Col>
                <Col>Dimension</Col>
                <Col>SubDimension</Col>
            </Row>
              {this.state.BOIData.map((boi,index) => {
                return <>
                <Row className="table-content" key={index}>
                <Col>
                <input type="checkbox" className="header-checkbox"
                checked={boi.IsMapped}
                onClick={(e)=>this.selectBOI(e,index)}
                />
                </Col>
                <Col>{boi.BOIName}</Col>
                <Col>{boi.UOM}</Col>
                <Col>{boi.CoreDimensionName}</Col>
                <Col>{boi.SubDimensionName}</Col>
                </Row>
                </>
              })}
          </div>
          <div className="boiCount">
            {this.state.BOIData.length} items
            </div>
            <div className="add-boi-buttons">
            <GrooveButton
                id="outline-btn-1"
                name="Outline Button Slate"
                type="outline"
                colorClass="stat-alternate" 
                text="Cancel"
                callbackFunction={() => this.props.toggleSelectBOIPopup()}
              />
              <GrooveButton
                   id="primry-btn-2"
                   hasIcon={false}
                   type="solid"
                   colorClass="stat-alternate" 
                   isDisabled={this.state.BOIData.length > 0 && this.state.BOIData.filter((boi)=>boi.IsMapped === true).length > 0 ? false : true}
                   text="Save"
                   callbackFunction={() => this.props.selectedBOIs(this.state.BOIData)}
                   iconId="btn-icon-01"
                 />
                 </div>
          </Modal.Body>
          
        </Modal>
      </>
    );
  }
}
