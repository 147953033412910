import Header from './Header';
import Footer from './Footer';
import UserDetails from './UserDetails';
import NavigationPage from './NavigationMenu';
import TermsOfUsePage from './TermsOfUse';
import TermsAndAgreement from './TermsAndAgreement';
import PrivacyStatement from './PrivacyStatement';
import PageLayout from './PageLayout'

require ('./MasterLayout.css');
require ('./MasterLayoutQuery.css');
export {
    Header, Footer, UserDetails, 
    NavigationPage, 
    TermsOfUsePage, TermsAndAgreement,
    PrivacyStatement, PageLayout
}