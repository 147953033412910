import React, { Component } from "react";
import CVMModal from "../Shared/CVMModal/CVMModal";
import { GrooveButton } from 'tfo-groove';
import { faEdit as Edit, faExclamationTriangle as warning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircle from "../../Static/images/360cvm/check-circle.svg";
import { history } from "../../store";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { IsObjEmpty, HasProperty } from "../../Utilities/Validations";

const CACompleteDocPopup = (function () {
  class CACompleteDocPopupPresentational extends Component {
    render() {
      const hideSADBtn = this.props.pending; //this.props.type === "Program" ? this.props.selectedProgramDetails.StatusId === 8 : this.props.selectedProjectDetails.StatusId === 8
      const projectSubmission = this.props.type === 'Project'
      return (
        <React.Fragment>
          <div id="track-scroll">
            <span className="BT-Lead-Landing-bottom-btn">
              {hideSADBtn ? "" :
                <span className="applyFlex-space-between">
                  <GrooveButton
                    id="primry-btn-2"
                    type="outline"
                    colorClass="stat-alternate"
                    text="Save as Draft"
                    callbackFunction={this.props.onClickSaveasDraft}
                    customClassName="add-project-button"
                    iconAlignment="right"
                    iconSize="small"
                    iconStyle="solid"
                    iconId="btn-icon-01"
                    isDisabled={(!this.props.pending && this.props.fileCount === 0) ||
                      this.props.disableSADBtn ? true : false}
                  />
                </span>
              }
              <span className="applyFlex-space-between">
                <GrooveButton
                  id="primry-btn-2"
                  type="solid"
                  colorClass="stat-alternate"
                  text={hideSADBtn && this.props.type === "Program" ? "Upload Later" : "Submit"}
                  callbackFunction={this.props.onClickSubmit}
                  customClassName="add-project-button"
                  iconAlignment="right"
                  iconSize="small"
                  iconStyle="solid"
                  iconId="btn-icon-01"
                  isDisabled={(!this.props.pending && this.props.fileCount === 0) || this.props.disableSendBtn || this.props.invalidComment ?
                    true : false}
                />
              </span>
            </span>
          </div>
          <div className={
            this.props.positionFixed &&
              !((!this.props.pending && this.props.fileCount === 0) || this.props.disableSADBtn
                ? true
                : false)
              ? 'sticky-button'
              : 'sticky-button hide-sticky'
          }>
            <span className="BT-Lead-Landing-bottom-btn">
              {hideSADBtn ? "" :
                <span className="applyFlex-space-between">
                  <GrooveButton
                    id="primry-btn-2"
                    type="outline"
                    colorClass="stat-alternate"
                    text="Save as Draft"
                    callbackFunction={this.props.onClickSaveasDraft}
                    customClassName="add-project-button"
                    iconAlignment="right"
                    iconSize="small"
                    iconStyle="solid"
                    iconId="btn-icon-01"
                    isDisabled={(!this.props.pending && this.props.fileCount === 0) ||
                      this.props.disableSADBtn ? true : false}
                  />
                </span>
              }
              <span className="applyFlex-space-between">
                <GrooveButton
                  id="primry-btn-2"
                  type="solid"
                  colorClass="stat-alternate"
                  text={hideSADBtn && this.props.type === "Program" ? "Upload Later" : "Submit"}
                  callbackFunction={this.props.onClickSubmit}
                  customClassName="add-project-button"
                  iconAlignment="right"
                  iconSize="small"
                  iconStyle="solid"
                  iconId="btn-icon-01"
                  isDisabled={(!this.props.pending && this.props.fileCount === 0) || this.props.disableSendBtn || this.props.invalidComment ?
                    true : false}
                />
              </span>
            </span>
          </div>

          <CVMModal
            title=""
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            size="md"
            content={
              <React.Fragment>
                <div className="CACompleteDocPending">
                  <img src={CheckCircle} alt="info" className="check-circle" />
                  <div className="Program-Configured-text">
                    {this.props.type} Configured
                  </div>
                  <div className="configuration-text">
                    The Configuration of {this.props.type} {this.props.typeName} has been successfully completed
                  </div>
                  {this.props.pending && !projectSubmission ? (
                    <div className="warning-msg">
                      <FontAwesomeIcon icon={warning} />
                      {" "}Client Alignment document upload pending
                    </div>
                  ) : null}
                </div>
              </React.Fragment>
            }
          />
        </React.Fragment>
      );
    }
  }

  class CACompleteDocPopupContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: false,
        fileCount: 0,
        disableSendBtn: false,
        disableSADBtn: false,
        positionFixed: false,
      }
    }

    listenToScroll = () => {
      let trackScroll = document.getElementById('track-scroll');
      let var1 = trackScroll ? document.getElementById('root').clientHeight - trackScroll.offsetTop - 80 : 0;
      let var2 =
        document.getElementById('root').clientHeight -
        document.documentElement.scrollTop -
        window.innerHeight;

      if (var2 > var1) {
        this.setState({
          positionFixed: true,
        });
      } else {
        this.setState({
          positionFixed: false,
        });
      }
    };

    componentDidMount = () => {
      window.scrollTo(0, 0)
      this.listenToScroll();
    }

    componentDidUpdate = (nextProps) => {
      window.addEventListener('scroll', this.listenToScroll)
    }

    onCloseModal = async () => {
      this.props.onCloseModal();
    };

    onClickSubmit = async () => {
      try {
        this.setState({
          disableSendBtn: true,
        }, async () => {
          await this.props.onClickSubmit();
          if (this.props.enableSubmit) {
            this.setState({
              disableSendBtn: false
            })
          }
        })
      }
      catch (e) {
        console.log(e);
      }
    };


    onClickSaveasDraft = async () => {
      try {
        this.setState({
          disableSADBtn: true,
        }, async () => {
          await this.props.onClickSaveasDraft();
          if (this.props.enableSAD) {
            this.setState({
              disableSADBtn: false
            })
          }
        })
      }
      catch (e) {
        console.log(e);
      }
    }


    render() {
      const { selectedProjectDetails } = this.props.ProjectConfigurationData.selectedProjectDetails;
      const { selectedProgramDetails } = this.props.ProgramConfigurationData.selectedProgramDetails;
      return <CACompleteDocPopupPresentational
        onCloseModal={this.onCloseModal}
        onClickSubmit={this.onClickSubmit}
        onClickSaveasDraft={this.onClickSaveasDraft}
        show={this.props.show}
        pending={this.props.pending}
        fileCount={this.props.fileCount}
        typeName={this.props.typeName}
        type={this.props.type}
        selectedProjectDetails={selectedProjectDetails}
        selectedProgramDetails={selectedProgramDetails}
        disableSADBtn={this.state.disableSADBtn}
        disableSendBtn={this.state.disableSendBtn}
        positionFixed={this.state.positionFixed}
        enableSAD={this.props.enableSAD}
        enableSubmit={this.props.enableSubmit}
        invalidComment={this.props.invalidComment}
      />
    }
  }

  return CACompleteDocPopupContainer;
})();

export default connect(
  state => {
    return {
      ProjectConfigurationData: state.ProjectConfigurationData,
      ProgramConfigurationData: state.ProgramConfigurationData,
    }
  },
  dispatch => bindActionCreators(Object.assign({},
    {
    }), dispatch)
)(CACompleteDocPopup);
