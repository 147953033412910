import React, { Component } from 'react';
import { IsValidStr } from './../../../Utilities/Validations';

require('./CVMInput.css');

const CVMInput = (function () {
  class CVMInputPresentational extends Component {
    render() {
      const {
        customClassName,
        isDisabled,
        id,
        inputValue,
        callbackFunction,
        onBlurCallbackFunction,
        placeholder,
        maxLength,
        isRequired,
      } = this.props;
      return (
        <div id="groove-input" className={`cvm-input ${isDisabled ? 'disabled' : ''}`}>
          <div className="floating-label input-box ">
            <div id="" className="label16-regular-silver groove-input-custom-label">
              {isRequired ? (
                <div>
                  {IsValidStr(inputValue) ? (
                    ''
                  ) : (
                    <>
                      {placeholder}
                      <span className="asterisk">*</span>
                    </>
                  )}
                </div>
              ) : (
                <div>{IsValidStr(inputValue) ? '' : placeholder}</div>
              )}
            </div>
            <input
              placeholder={placeholder}
              type="text"
              name={id}
              id={id}
              datatest-id={id}
              className={`form-control groove-input ${customClassName}`}
              maxLength={maxLength}
              autocomplete="false"
              value={inputValue}
              onBlur={onBlurCallbackFunction}
              onChange={callbackFunction}
              disabled={isDisabled}
            />
            <label datatest-id="groove-input-label" for={id}>
              {placeholder}
              {isRequired ? <span className="cvm-shared-input">*</span> : ''}
            </label>
          </div>
        </div>
      );
    }
  }

  class CVMInputContainer extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return <CVMInputPresentational {...this.props} />;
    }
  }

  return CVMInputContainer;
})();

export default CVMInput;
