import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveIconSvg, GrooveButton, GrooveTextBox, GrooveSwitchToggle } from 'tfo-groove';
import { Accordion, Card, Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactTooltip from 'react-tooltip';
import ClientValueIdentifiedUSD from './ClientValueIdentifiedUSD';
import ClientValueIdentifiedNonUSD from './ClientValueIdentifiedNonUSD';
import {
  HasValue,
  IsValidStr,
  HasProperty,
} from '../../../../Utilities/Validations';
import {
  TryGetObjValueAlt,
  FormatCVMParamValue,
  parseField,
} from '../../../../Utilities/Formatter';
import ApproveRejectRadioButton from '.././ApproveRejectRadioButton';

import {
  handleClientValueIdentified,
  handleSendReminderPDE,
  setRVDSelectedTabs,
  updateExpandCollapseBOIView,
} from '../../../../Actions/ConfigureProgram.Action';
import { getReminderCount } from '../../../../Actions/Notifcation.Action';
import CVMInput from '../../../Shared/CVMInput/CVMInput';
import CVMSendReminderButton from '../../../Shared/CVMReminderButton/CVMSendReminderButton';

import newInfo from '../../../../Static/images/svg/new-info.svg';
import emptyFolder from '../../../../Static/images/360cvm/empty-folder.svg';

const ClientValueIdentified = (function () {
  class ClientValueIdentifiedPresentational extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      const {
        showNotifMessageArr,
        dItem,
        dIndex,
        inUSD,
        notInUSD,
        activeExpand,
        onExpandCollapse,
        activeCollapse,
        onSelectAccordion,
        basicDetails,
        isSuperAdmin,
        RVDComponents,
        onClickCustomBOI,
        isComment,
        handleBOIComment,
        handleBOICommentHistory,
        setIsReject,
        currentUserRoleID,
        submitReject,
        tempReason,
        updateReason,
        handleParameterChange,
        isIOAdmin,
        isExistingFinancial,
        disableSuperAdmin,
        setTab,
        rvdSelectedTabs,
        isRejected,
        isAwaiting,
        isApproved,
        isApprovedAndYetToEdit,
        isPPC,
        isBA,
        handleChange,
        formatValue,
        isExistingIOA,
        isExistingCXL,
        isReminderSenderView,
        isReminderCXLOALSenderView,
        isReminderCXLIOASenderView,
        isReminderIOASenderView,
      } = this.props;

      const plSignOffMsg = 'BOIs with $ UOM selected under Financial Dimension will be Considered as client P&L Sign-Off. $ value under all other Dimensions except Financial Dimension, will not be considered as Client P&L Sign-Off.';
      const onDashboardMsg = 'Show this BOI on the Value Meter Dashboard?';
      const reasonMsg = 'Reject Reason is required';
      const awaiting = 'awaiting approval';
      const rejected = 'rejected';
      const tabInDollar = 'Client P&L Impacting BOIs - Measured in Dollar';
      const tabNotDollar = 'Client P&L Impacting BOIs - Not measured in Dollar';
      const deltaTooltip = 'Target Value - Value Impacting Client P&L';
      const deltaTooltipNonUSD = 'Target Value - Realized Value';
      const realizedTooltip = 'Value Impacting Client P&L';
      const noBOI = 'No Business Outcome Indicators Available';
      const noBOIDesc = 'This dimension doesn\'t have any BOI(s) available under this category';
      const cviMsg = '* Autogenerated based on Value Impacting Client P&L';

      const dimName = dItem.dimensionName.replace(/\s/g, '').replace(/&/g, '').toLowerCase();

      return (
        <div>
          <div className="header18-semibold-midnight">{dItem.dimensionName}</div>
          {showNotifMessageArr[dimName] ?
            <div className="disclaimer-message">
              <GrooveIconSvg
                customClassName="accordion-button"
                size="small"
                name="exclamation-triangle"
                iconStyle="solid"
                iconColor="stat-warning"
              />
              {plSignOffMsg}
            </div>
            : ""}
          {dIndex === 0 ?
            <div className="accordion-action-btns">
              {
                isReminderSenderView ?
                  <CVMSendReminderButton tooltipText={this.props.isDisabledReminderButton === false && "Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> :
                  isReminderCXLOALSenderView ?
                    <CVMSendReminderButton tooltipText={this.props.isDisabledReminderButton === false && "Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> :
                    isReminderCXLIOASenderView ?
                      <CVMSendReminderButton tooltipText={this.props.isDisabledReminderButton === false && "Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> : isReminderIOASenderView ?
                        <CVMSendReminderButton tooltipText={this.props.isDisabledReminderButton === false && "Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> : ""
              }
              <GrooveButton
                id={'expandAll' + dIndex}
                text="Expand All"
                customClassName={`expandAll-button ${activeExpand && 'active'}`}
                callbackFunction={(e) => onExpandCollapse(e, dIndex)}
                type=""
                colorClass=""
              />
              <GrooveButton
                id={'collapseAll' + dIndex}
                text="Collapse All"
                customClassName={`collapseAll-button ${activeCollapse && 'active'}`}
                callbackFunction={(e) => onExpandCollapse(e, dIndex)}
                type=""
                colorClass=""
              />
            </div>
            : ""}
          {dItem.subDimensionList.map((SDItem, SDIndex) => {
            const selTabUSD = (rvdSelectedTabs[SDItem.subDimensionname.toLowerCase()] === 0);
            const selSDItem = selTabUSD
              ? inUSD.find((subdim) => subdim.sdIndex === SDIndex)
              : notInUSD.find((subdim) => subdim.sdIndex === SDIndex);
            const {
              sdIndex,
              hadFinalized,
              colWidth,
              colStatus
            } = selSDItem;
            return (
              <>
                {
                  selTabUSD ?
                    <ClientValueIdentifiedUSD
                      {...this.props}
                      SDItem={SDItem}
                      SDIndex={SDIndex}
                      dItem={dItem}
                      dIndex={dIndex}
                      selSDItem={selSDItem}
                      selTabUSD={selTabUSD}
                      dimName={dimName}
                      plSignOffMsg={plSignOffMsg}
                      editedAnyFieldOnce={this.props.editedAnyFieldOnce}
                      setEditedAnyFieldOnce={this.props.setEditedAnyFieldOnce}
                      checkForInvalidEmailsOnLanding={this.props.checkForInvalidEmailsOnLanding}
                      overallStatus={this.props.overallStatus}
                    />
                    :
                    <ClientValueIdentifiedNonUSD
                      {...this.props}
                      SDItem={SDItem}
                      SDIndex={SDIndex}
                      dItem={dItem}
                      dIndex={dIndex}
                      selSDItem={selSDItem}
                      selTabUSD={selTabUSD}
                      dimName={dimName}
                      plSignOffMsg={plSignOffMsg}
                      editedAnyFieldOnce={this.props.editedAnyFieldOnce}
                      setEditedAnyFieldOnce={this.props.setEditedAnyFieldOnce}
                      checkForInvalidEmailsOnLanding={this.props.checkForInvalidEmailsOnLanding}
                      overallStatus={this.props.overallStatus}
                    />
                }
              </>
            );
          })}
        </div>
      );
    }
  }

  class ClientValueIdentifiedContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isDisabledReminderButton: true
      }
    }

    componentDidMount = async () => {
      if (!IsValidStr(this.props.basicDetails.assignedTo)) {
        this.setState({
          isDisabledReminderButton: true
        })
      }
      const { dItem } = this.props;
      let rvdSelectedTabs = {
        capital: 0,
        opex: 0,
        revenue: 0,
      };

      let finUSDArray = [];
      dItem.subDimensionList.forEach((subDim) => {
        let subDimName = subDim.subDimensionname.toLowerCase();
        let hasUSD = false, hasNotUSD = false;
        subDim.boiList.forEach((boi) => {
          if (boi.boiUOM === 'USD') {
            hasUSD = true;
            finUSDArray.push(boi)
          } else {
            hasNotUSD = true;
          };
        });
        if (!hasUSD && hasNotUSD) rvdSelectedTabs[subDimName] = 1;
      });
      const mainSel = this.props.ConfigureProgramData.mainSelectedProject;

      this.props.setRVDSelectedTabs(rvdSelectedTabs);
      this.props.updateExpandCollapseBOIView("", "", "expandAll")
      const { selectedClient } = this.props.SharedData;
      const isCXLStage = this.props.ConfigureProgramData.basicDetails.assignedToRole.trim().toLowerCase() === "cx lead"
      const isIOAStage = this.props.ConfigureProgramData.basicDetails.assignedToRole.trim().toLowerCase() === "intelligent ops approver"
      if (this.props.ConfigureProgramData.basicDetails.overallStatus === 'Awaiting Approval') {
        const notifTypeID = isCXLStage ? 5 : isIOAStage ? 7 : 6
        const isFinancial = dItem.dimensionID === 1 && finUSDArray.length > 0 ? true : false;
        this.setState({
          isDisabledReminderButton: true
        });
        let counterReminder = await this.props.getReminderCount({ ...mainSel, ...selectedClient, notifTypeID, isFinancial }, "PDE")
        if (counterReminder >= 2) {
          this.setState({
            isDisabledReminderButton: true
          });
        } else {
          this.setState({
            isDisabledReminderButton: false
          });
        }
      }
    }

    setTab = (tab, subDim) => {
      const { rvdSelectedTabs } = this.props.ConfigureProgramData;

      rvdSelectedTabs[subDim.toLowerCase()] = tab;
      this.props.setRVDSelectedTabs(rvdSelectedTabs);
    }

    handleChange = (data, tVal, action) => {
      try {
        const tProjectDimensionsList = this.props.ConfigureProgramData.projectDimensionsList;
        const cviItem = tProjectDimensionsList[data.dimension.index]
          .subDimensionList[data.subDimension.index]
          .boiList[data.boi.index].clientValueIdentifiedTemp;
        let value = parseField(tVal, 'positive-decimal');

        if (value !== '') {
          let valDec = value.split('.');
          if (valDec.length === 1) {
            value = Number(value);
          } else {
            value = `${Number(valDec[0])}.${valDec[1]}`;
          }
        }

        const formatCVI = FormatCVMParamValue(action, value.toString(), 'USD');
        cviItem.value = formatCVI.value;
        if (action !== 'blur') cviItem.invalidMsg = formatCVI.inValidMsg;
        cviItem.isInvalid = (cviItem.invalidMsg !== '');

        tProjectDimensionsList[data.dimension.index]
          .subDimensionList[data.subDimension.index]
          .boiList[data.boi.index]
          .clientValueIdentifiedTemp = cviItem;

        this.props.handleClientValueIdentified(tProjectDimensionsList);
      } catch (err) {
        console.log(err);
      }
    }
    onSendReminder = async () => {
      try {
        const { emailAddress } = this.props.UserInformationData;
        let data = {
          view: "Project Data Entry",
          message: "Reminder notification is sent for the approver successfully!",
          user: emailAddress
        }
        this.setState({
          isDisabledReminderButton: true
        }, async () => await this.props.handleSendReminderPDE(data))
      } catch (error) {
        console.log(error);
      }
    }
    render() {
      const {
        RVDComponents,
        basicDetails,
        dItem,
        hadCompute,
        currentUserRoleID
      } = this.props;
      const {
        rvdSelectedTabs
      } = this.props.ConfigureProgramData;
      const { userProfiles } = this.props.UserInformationData;
      const { selectedClient } = this.props.SharedData;

      const statStr = basicDetails.overallStatus.toLowerCase();
      const status = {
        stat: statStr,
        isRejected: (statStr === 'rejected'),
        isAwaiting: (statStr === 'awaiting approval'),
        isApproved: (statStr === 'approved'),
      }

      const isApprovedAndYetToEdit = (status.isApproved && !hadCompute);
      const awaitingApprovalUserRoleID12 = status.isAwaiting && !([].indexOf(currentUserRoleID) === -1);//03212022

      let clientID = selectedClient.value;

      let isExistingPPC = userProfiles.some((x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      let isExistingBA = userProfiles.some((x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      let isExistingIOA = userProfiles.some((x) => [8].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      let isExistingCXL = userProfiles.some((x) => [7].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      const isPPC = currentUserRoleID === 12 || (currentUserRoleID === 16 && isExistingPPC);
      const isBA = currentUserRoleID === 15 || (currentUserRoleID === 16 && isExistingBA);

      const inUSD = [], notInUSD = [];
      dItem.subDimensionList.forEach((subDim, sdIndex) => {
        let boiListUSD = [], boiListNotUSD = [];
        const hadFinalized = HasValue(subDim.boiList[0].boiApprovedRealizedValue);
        const colStatus =
          status.isRejected && !hadFinalized
            ? 'rejected'
            : status.isRejected && hadFinalized
              ? 'rejected-new'
              : status.isAwaiting && hadFinalized
                ? 'awapp-new'
                : status.isAwaiting && !hadFinalized
                  ? 'awapp'
                  : status.isApproved && hadFinalized && 'approved';
        const wVisible = hadFinalized &&
          ['awaiting approval', 'approved', 'in progress'].indexOf(statStr) > -1
          ? RVDComponents.approveRejectButton && status.isAwaiting
            ? 2 : 1
          : !hadFinalized
            ? 1 : 2;
        const colWidth = {
          boi: awaitingApprovalUserRoleID12 ? 2 : 2,
          targetValue: 1,
          realizedValueApp: 1,
          realizedValueNew: 1,
          targetRealizeValue: 1,
          wheelVisibility: status.isRejected && !hadFinalized
            ? 1 : status.isRejected && hadFinalized
              ? 1 : wVisible,
          boiComment: awaitingApprovalUserRoleID12 ? 1 : 1,
        };

        subDim.boiList.forEach((boi, boiIndex) => {
          let boiTemp = { ...boi, boiIndex };

          const colInputValues = status.isAwaiting ||
            (status.isRejected && !IsValidStr(boi.boiRejectionComment))
            ? 1 : 1;
          const realized = awaitingApprovalUserRoleID12
            ? 1 : status.isRejected
              ? 1 : colInputValues;
          colWidth.targetValue =
            awaitingApprovalUserRoleID12 || status.isRejected
              ? 1 : hadFinalized
                ? 1 : colInputValues;
          colWidth.realizedValueApp = realized;
          colWidth.realizedValueNew = realized;
          colWidth.targetRealizeValue = realized;

          if (boi.boiUOM === 'USD') {
            boiListUSD.push(boiTemp);
          } else {
            boiListNotUSD.push(boiTemp);
          }
        });

        let subDimTemp = {
          subDimensionID: subDim.subDimensionID,
          subDimensionname: subDim.subDimensionname,
          sdIndex,
          hadFinalized,
          colWidth,
          colStatus
        };

        let colWidthUSD = { // no need
          ...colWidth,
          targetRealizeValue: colWidth.targetRealizeValue,
          realizedValueNew: colWidth.realizedValueNew
        }

        inUSD.push({ ...subDimTemp, colWidth: colWidthUSD, boiList: boiListUSD })
        notInUSD.push({ ...subDimTemp, boiList: boiListNotUSD })
      });

      return (
        <ClientValueIdentifiedPresentational
          {...this.props}
          {...this.state}
          {...status}
          inUSD={inUSD}
          notInUSD={notInUSD}
          isApprovedAndYetToEdit={isApprovedAndYetToEdit}
          awaitingApprovalUserRoleID12={awaitingApprovalUserRoleID12}
          isPPC={isPPC}
          isBA={isBA}
          rvdSelectedTabs={rvdSelectedTabs}
          setTab={this.setTab}
          handleChange={this.handleChange}
          formatValue={this.props.formatValue}
          onSendReminder={this.onSendReminder}
          isDisabledReminderButton={this.state.isDisabledReminderButton}
          isExistingIOA={isExistingIOA}
          isExistingCXL={isExistingCXL}
        />
      );
    }
  }

  return ClientValueIdentifiedContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      UserInformationData: state.userInformation,
      SharedData: state.SharedData
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {
    handleClientValueIdentified,
    setRVDSelectedTabs,
    updateExpandCollapseBOIView,
    handleSendReminderPDE,
    getReminderCount
  }), dispatch)
)(ClientValueIdentified);
