import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMarker, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
  GrooveRadioButton,
  GrooveSelectMenuBasic,
  GrooveTextBox,
  GrooveButton,
  GrooveIconSvg,
  GrooveInput
} from "tfo-groove";
import { Accordion, Card } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Dropzone from 'react-dropzone';
import RejectionRecepient from './RecepientField/RejectionRecepient';
import ApprovalRecepient from './RecepientField/ApprovalRecepient';
import { HttpGet } from "../../../Utilities/HTTPUtil";
require("./CVMSubmitRejectionModal.css");

const CVMSubmitRejectionModal = (function () {
  class CVMSubmitRejectionModalPresentational extends Component {
    render() {
      let now = 100;
      let rejectionRecipient = this.props.rejectionRecipient;
      let stakeholders = this.props.stakeholderNames;
      let isApproved = !this.props.isRejected;
      return (
        <React.Fragment>
          <div className="submit-settings-container">
            <div className="submit-modal-body">
              <p>Are you sure you want to {this.props.isRejected ? "Reject" : "Approve"} this program?</p>
              <Row>
                <div className="radio-button-container">
                  <GrooveRadioButton
                    callbackFunction={(a) => this.props.onModalRadioChange(a)}
                    radioBtnValues={this.props.radioButton}
                    radioBtnTheme="Light"
                    customClassName="submit-modal-radio"
                  />
                </div>
              </Row>
              <div className="submit-content">
                <div className="rejection-recipient">
                  {this.props.isRejected ? <RejectionRecepient label={this.props.label} value={this.props.value} /> :
                    <ApprovalRecepient
                      value={this.props.value}
                      label={this.props.label}
                    />}

                </div>
                <Dropzone
                  onDropAccepted={acceptedFiles => this.props.onDropAccepted(acceptedFiles)}
                  onDropRejected={rejectedFiles => this.props.onDropRejected(rejectedFiles)}
                  maxSize={10000000}
                  maxFiles={3}
                  accept='.msg'
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="upload-btn-wrapper">
                      <div>
                        <div className="btn">
                          {<FontAwesomeIcon icon={faUpload} />}
                        </div>
                        <div className="fileupload-browse">
                          <input {...getInputProps()} />
                          <span className="approval-attach">
                            Drop files to attach or{" "}
                          </span>
                          <span className="approvalFileBtn">
                            Browse Files
                            </span>
                          <span className="approval-attach">
                            Client Confirmation Document (s) Here
                            </span>
                        </div>
                        <div className="filedrop-msg">
                          <p>(Individual file upload size limit 0 to 10MB)</p>
                        </div>
                      </div>
                    </div>
                  )}

                </Dropzone>

                {this.props.Files.map((file, fileIndex) => (
                  <div className="attaching-file-container">
                    {/* show here attached file with progressbar */}
                    <div className="attaching-file-name">
                      <GrooveIconSvg
                        customClassName="file-icon"
                        size="small"
                        name="file"
                        iconStyle="regular"
                        iconColor="stat-neutral"
                      />
                      {file.name}
                    </div>
                    <ProgressBar now={now} label={`${now}%`} />
                    <GrooveIconSvg
                      customClassName="delete-icon"
                      size="small"
                      name="times"
                      iconStyle="solid"
                      iconColor="stat-neutral"
                      callbackFunction={() => this.props.onDeleteFile(fileIndex)}
                    />
                  </div>
                ))}
                <div className="comments">
                  <GrooveTextBox
                    maxLength={255}
                    id="comment-textbox"
                    name="comment-textbox"
                    type="text"
                    placeholder={`${(this.props.comment !== "") ? "" : "Enter "}Comment`}
                    callbackFunction={(e) => this.props.submitComment(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="CVMSideModal-footer submit-modal-footer">
            {/* <div className="rejection-modal-buttons"> */}
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={this.props.onCloseModal}
            />

            <GrooveButton
              id="primry-btn-2"
              name="Solid Button Primary-Ops"
              isDisabled={
                this.props.isSave
                  ? !this.props.isRejected
                    ? this.props.Files.length > 0
                      ? false
                      : true
                    : false
                  : true
              }
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Submit"
              callbackFunction={() => this.props.type !== "Program" ? this.props.onSubmitClick("Submit", rejectionRecipient) : this.props.onSubmitClick(isApproved, stakeholders)}
            />
            {/* </div> */}
          </div>
        </React.Fragment>
      );
    }
  }

  class CVMSubmitRejectionModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        options: [
          { value: "1", label: "Option1", text: "Option1" },
          { value: "2", label: "Option2", text: "Option2" },
          { value: "3", label: "Option3", text: "Option3" },
          { value: "4", label: "Option4", text: "Option4" },
          { value: "5", label: "Option5", text: "Option5" },
          { value: "6", label: "Option6", text: "Option6" },
        ],
        isSave: false,
        radioButton: [
          {
            radioBtnLabel: "Yes",
            radioBtnValue: "Yes",
            defaultChecked: false,
            name: "radio-select",
            id: "radio-1",
          },
          {
            radioBtnLabel: "No",
            radioBtnValue: "No",
            defaultChecked: true,
            name: "radio-select",
            id: "radio-2",
          },
        ],
        value: [],
        stakeholderNames: [],
        rejectionRecipient: "",
        comment: ""
      };
    }

    componentDidMount() {
      this.setState({
        value: [],
        stakeholderNames: [],
        rejectionRecipient: "",
      });
      this.getStakeholders();
      this.props.clearUpload();

      this.changeTextAreaClass()
    };

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }
    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    getStakeholders = async () => {
      if (this.props.type == "Program") {
        let programID = this.props.selectedProgramID;
        let isApproved = !this.props.isRejected;
        var stakeholders = await HttpGet(`ProgramIdentifiedValueSettings/GetStakeholders?clientProgramID=${programID}&status=${isApproved}`);
        stakeholders.forEach(name => {
          this.setState({
            value: [...this.state.value, { value: name, label: name }],
            stakeholderNames: [...this.state.stakeholderNames, name]
          })
        });
      }
      else if (this.props.type == "Project") {
        let projectID = this.props.selectedProjectData.ClientProjectTargets.ClientProjectID
        let isApproved = !this.props.isRejected;
        var stakeholders = await HttpGet(`ProjectIdentifiedValueSetting/GetStakeholders?clientProjectId=${projectID}&status=${isApproved}`);
        stakeholders.forEach(name => {
          this.setState({
            value: [...this.state.value, { value: name, label: name }],
            rejectionRecipient: name,
          })
        });
      }
    }

    onModalRadioChange = (event) => {
      switch (event.label) {
        case "Yes":
          this.setState({
            isSave: true
          });
          break;
        case "No":
          this.setState({
            isSave: false
          });
          break;
        default:
          this.setState({
            isSave: false
          });
          break;
      }
    }

    submitComment = (e) => {
      this.setState({
        comment: e.value
      });

      try{
        this.props.submitComment(e);
      }
      catch(e){
        console.log(e);
      }
    }


    render() {
      return (
        <CVMSubmitRejectionModalPresentational
          {...this.state}
          {...this.props}
          onModalRadioChange={this.onModalRadioChange}
          getStakeholders={this.getStakeholders}
          submitComment={this.submitComment}
          comment={this.state.comment}
        />
      );
    }
  }

  return CVMSubmitRejectionModalContainer;
})();

export default CVMSubmitRejectionModal;
