import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
// import { history } from "../../store";
import { withRouter } from "react-router-dom";
//components
import { IsValid } from "../../Utilities/Validations";
import ReportValueDeliveredBOIHistory from './ReportValueDeliveredBOIHistory/ReportValueDeliveredBOIHistory';
import ProjectDataEntryList from './ProjectDataEntryList/ProjectDataEntryList';
import ConfigureProgram from './ProjectDataEntry/ConfigureProgram';

//css
import "./SubDataIngestionConfig.css";

const SubDataIngestionConfig = (function () {
  const comp = {
    // ProjectDataEntryList: { key: 1, url: 'projectdataentrylist', com: <ProjectDataEntryList/> },
    ConfigureProgram: { key: 2, url: 'configureprogram', com: <ConfigureProgram/> },
    ReportValueConfigureProjectBOIHistory: { key: 3, url: 'boihistory', com: <ReportValueDeliveredBOIHistory historyType="Project" /> },
  }

  class SubDataIngestionConfigPresentational extends Component {
    render() {
      const url = window.location.pathname.toLowerCase().replace('/dataingestion/', '');

      const page = Object.values(comp).filter(e => {
        return e.url === url
      }).map(e2 => { 
        return e2.com;
      });

      return (
        <React.Fragment>
          {page}
        </React.Fragment>
      );
    }
  }

  class SubDataIngestionConfigContainer extends Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      if (!IsValid(this.props.clientID)) this.props.history.push('/dataingestion')
        return <SubDataIngestionConfigPresentational />;
    }
  }

  return SubDataIngestionConfigContainer;
})();

export default connect(
  state => {
    return {
      clientID: state.SharedData.selectedClient.value,
    }
  },
  dispatch => bindActionCreators(Object.assign({}, {}), dispatch)
)(withRouter(SubDataIngestionConfig));
