import React, { Component } from 'react';
import { components } from 'react-select';
import { GrooveButton, GrooveIconSvg } from 'tfo-groove';
import { default as ReactSelect } from 'react-select';
import { UnRefObj, AreObjsEqual, TryGetObjValueAlt } from '../../../Utilities/Formatter';
import { IsValid } from '../../../Utilities/Validations';
import { setRVDSelectedTabs } from '../../../Actions/ConfigureProgram.Action';

require('./CVMMultiSelectDropdownWithCheckBoxForBA.css');

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className={props.data.isFixed ? 'cvm-checkbox cvm-disabled-option' : (props.isSelected ? 'cvm-checkbox cvm-checked' : 'cvm-checkbox')}>
          <GrooveIconSvg
            customClassName="cvm-check-box"
            size="small"
            name="check"
            iconStyle="solid"
            iconColor="dl-white"
            onClick={props.selectProps.onMenuOpen}
          />
        </div>
        <div>
          {' '}
          <label className="labels">{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};
const { MenuList } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const { onMenuInputFocus, onClickApply, onClickCancel, onPaste, handleSearch, inputValue } =
    selectProps;
  const valuesChecked = selectProps.value;

  // Copied from source
  const ariaAttributes = {
    'aria-autocomplete': 'list',
    'aria-label': selectProps['aria-label'],
    'aria-labelledby': selectProps['aria-labelledby'],
  };

  return (
    <div>
      <div className="search-bar-container">
        <div className="search-bar label14-regular-midnight">
          <GrooveIconSvg
            customClassName="close-modal-btn"
            size="large"
            name="search"
            iconStyle="solid"
            iconColor="stat-neutral"
          />
          <input
            style={{
              width: '100%',
              boxSizing: 'border-box',
              padding: 10,
              border: 'none',
              borderBottom: '1px solid lightgrey',
            }}
            id="CVMMultiSelectDropdownWithCheckBoxForBA"
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            value={inputValue}
            // onPaste={(e) =>
            //   onPaste(e, {
            //     action: 'select-option',
            //   })
            // }
            onKeyDown={(e) =>
              inputValue === '' && e.key === ' ' ? handleSearch(e, 'onKeyDown') : ''
            }
            onChange={(e) => {
              handleSearch(e, 'onChange');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.target.focus();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              e.target.focus();
            }}
            onFocus={onMenuInputFocus}
            placeholder="Search..."
            {...ariaAttributes}
          />
        </div>
      </div>
      <MenuList {...props} selectProps={selectProps} />

      <div className="row dropdown-buttons">
        <div className="dropdown-cancel col-sm-1.5">
          <GrooveButton
            id="primry-btn-2"
            name="Solid Button Primary-Ops"
            hasIcon={false}
            type="outline"
            colorClass="stat-alternate"
            size="auto"
            text="Cancel"
            callbackFunction={onClickCancel}
          />
        </div>
        <div className="col-sm-2 dropdown-apply">
          <GrooveButton
            id="primry-btn-1"
            name="Solid Button Primary-Ops"
            hasIcon={false}
            type="solid"
            colorClass="stat-alternate"
            size="auto"
            text="Apply"
            callbackFunction={onClickApply}
            isDisabled={valuesChecked.length !== 0 ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

const allOption = {
  label: 'Select all',
  value: '*',
  isFixed: false,
  isDisabled: false
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }
  return (
    <components.ValueContainer className="cont" {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};
const MultiValue = (props) => {
  let labelToBeDisplayed = props.data.label;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = 'All';
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};
export default class CVMMultiSelectDropdownWithCheckBoxForBA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: [],
      containerWidth: 0,
      isCounterShown: false,
      inputValue: '',
      menuIsOpen: false,
      toBeApply: [],
      options: [],
      containerID: '',
      isPasted: false,
    };
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
  }

  componentDidMount = () => {
    this.RemoveEvClickOutsideMenu();
    this.setOptions();
  };

  componentDidUpdate = (nextProps) => {
    if (
      !AreObjsEqual(nextProps.value, this.props.value) ||
      !AreObjsEqual(nextProps.options, this.props.options)
    ) {
      this.setOptions();
    }
  };

  componentWillUnmount = () => {
    this.RemoveEvClickOutsideMenu();
  };

  setOptions = () => {
    let tOption = UnRefObj(this.props.options);
    let tSelected = UnRefObj(this.props.value);
    let shouldShowAll = IsValid(this.props.isShowAll) ? this.props.isShowAll : true;
    let isAllSelected = tSelected.some((e) => e.value === '*');
    let isNASelected = tSelected.some((e) => e.label.toLowerCase() === 'not applicable');
    let isNaOnlyOption = tOption.length === 1 && tOption.some((e) => e.label.toLowerCase() === 'not applicable');
    //not yet ready for existing data with both N/A and BA/all selected
    if (tSelected.length >= tOption.length - 1 && tSelected.length > 0 && !isNASelected) { //-1 because N/A is not considered
      tSelected = [this.getAllOpts(tSelected, tOption)].concat(tSelected);
    }

    if ((tOption.length - 1 > 0 && shouldShowAll) || isNaOnlyOption) {//N/A is the only selection
      tOption = [this.getAllOpts(tOption), ...tOption];
    }

    tOption.forEach((op) => {
      if (((isAllSelected || tSelected.length > 0 && !isNASelected) && op.label.toLowerCase() === 'not applicable') ||
        ((isNaOnlyOption || isNASelected) && op.label.toLowerCase() !== 'not applicable')) {
        op.isFixed = true;
        op.isDisabled = true;
      }
      else {
        op.isFixed = false;
        op.isDisabled = false;
      }
    })

    this.setState({
      optionSelected: UnRefObj(tSelected),
      options: UnRefObj(tOption),
    });
  };



  updateOptions = () => {
    let tOption = UnRefObj(this.state.options);
    let tSelected = UnRefObj(this.state.optionSelected);
    let isAllSelected = tSelected.some((e) => e.value === "*")
    let isNASelected = tSelected.some((e) => e.label.toLowerCase() === 'not applicable')
    let isNaOnlyOption = tOption.filter((e) => e.label.toLowerCase() !== 'not applicable').length === 1 && tOption.some((e) => e.label.toLowerCase().includes('not applicable'));

    tOption.forEach((op) => {
      if (((isAllSelected || tSelected.length > 0 && !isNASelected) && op.label.toLowerCase() === 'not applicable') ||
        ((isNaOnlyOption || isNASelected) && op.label.toLowerCase() !== 'not applicable')) {
        op.isFixed = true;
        op.isDisabled = true;
      }
      else {
        op.isFixed = false;
        op.isDisabled = false;
      }
    })
    this.setState({
      options: UnRefObj(tOption),
    });

  }

  AddEvClickOutsideMenu = () => {
    window.addEventListener('click', this.onClickOutside);
  };

  onClickOutside = (e) => {
    const elID = this.state.containerID;
    try {
      const isArrowDownEv =
        TryGetObjValueAlt(e.target, '', 'parentNode.parentNode.className')
          .toLowerCase()
          .includes('-indicatorcontainer') ||
        TryGetObjValueAlt(e.target, '', 'parentNode.className')
          .toLowerCase()
          .includes('-indicatorcontainer') ||
        TryGetObjValueAlt(e.target, '', 'className').toLowerCase().includes('-indicatorcontainer');
      if (
        !document.getElementById(elID).contains(e.target) ||
        (isArrowDownEv && this.state.menuIsOpen)
      ) {
        this.onMenuClose();
      }
    } catch { }
  };

  RemoveEvClickOutsideMenu = () => {
    try {
      window.removeEventListener('click', () => { });
    } catch { }
  };

  getAllOpts = (tOption) => {
    const fix = tOption.some((e) => e.isFixed !== true) ? {} : {
      isFixed: true
      , isDisabled: true
    };

    let d = {
      label: `All (${tOption.filter((e) => e.label.toLowerCase() !== 'not applicable').length})`,
      value: '*',
      isFixed: false,
      isDisabled: false,
      ...fix,
    };
    return d;
  };

  onClickCancel = () => {
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
    this.setState(
      {
        optionSelected: this.getOrigValue(),
        menuIsOpen: false,
        inputValue: '',
        isPasted: false,
      },
      this.RemoveEvClickOutsideMenu
    );
  };

  onClickApply = () => {
    const tSelected = UnRefObj(this.state.optionSelected.filter((t) => t.value !== '*'));
    this.props.onChange(tSelected);
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
    this.setState(
      {
        menuIsOpen: !this.state.menuIsOpen,
        inputValue: '',
        isPasted: false,
      },
      this.RemoveEvClickOutsideMenu
    );
  };

  onMenuOpen = async () => {
    this.AddEvClickOutsideMenu();
    await new Promise((resolve) => setTimeout(resolve, 300));
    this.setState({ menuIsOpen: true, containerID: `cvmMulSelDDWChckCont${Math.random()}` });
  };

  onMenuClose = () => {
    //ON CLICK OUTSIDE RESTORE ORIG VALUES
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
    this.setState(
      {
        optionSelected: this.getOrigValue(),
        menuIsOpen: false,
        inputValue: '',
        isPasted: false,
      },
      this.RemoveEvClickOutsideMenu
    );
    this.setOptions();
  };

  handleChange = (selected, event) => {
    if (this.shouldChangeTrigger) {
      let tSelected = selected;
      let tAllOption = {
        label: `All (${this.props.options.filter((e) => e.label.toLowerCase() !== 'not applicable').length})`,
        value: '*',
        isFixed: false,
        isDisabled: false
      };
      let isNASelected = tSelected.some((e) => e.label.toLowerCase() === 'not applicable');
      let isNaOnlyOption = this.props.options.length === 1 && this.props.options.some((e) => e.label.toLowerCase() === 'not applicable');

      if (event !== undefined) {
        if (event.action === 'remove-value') {
          if (tSelected.some((e) => e.isFixed === true))
            tSelected = selected.filter((t) => t.isFixed === true);
          else tSelected = [];
          this.props.onChange(
            event.removedValue.value === '*' ? tSelected : selected.filter((t) => t.value !== '*')
          );
          this.setState({ optionSelected: tSelected }, () => {
            this.updateOptions();
          });
          return;
        }
        if (selected !== null && selected.length > 0) {
          if (event.option.isFixed) {
            tSelected = UnRefObj(this.state.optionSelected);
          }
          else if (event.action === 'select-option') {
            if (
              selected.some((y) => y.value === tAllOption.value) ||
              ((selected.length === this.state.options.filter((e) => e.label !== "Not Applicable").length - 1 &&
                !selected.some((y) => y.value === tAllOption.value && !tAllOption.isFixed))
              ) || this.state.options.length !== this.props.options.length + 1) { //+1 for All; for searching
              //Prevent duplicate selection
              if (
                this.state.optionSelected.length <= 0 &&
                selected.length === 1 &&
                !selected.some((y) => y.value === tAllOption.value && !tAllOption.isFixed)
              ) {
                if (!selected.some((y) => y.label === "Not Applicable") &&
                  (selected.length === this.props.options.filter((e) => e.label !== "Not Applicable").length)) { //removed -1; no 'All' as props option
                  tSelected = this.state.options.filter((e) => e.label !== "Not Applicable")
                  if (!tSelected.some((a) => a.value === "*")) { //add only 'All' if its not existing; state options for search differs
                    tSelected.unshift({
                      label: `All (${this.props.options.filter((e) => e.label.toLowerCase() !== 'not applicable').length})`,
                      value: '*',
                      isFixed: isNaOnlyOption ? true : isNASelected,
                      isDisabled: isNaOnlyOption ? true : isNASelected,
                    });
                  }
                }
              } else {
                tSelected = this.props.options.filter((e) => e.label !== "Not Applicable");
                if (!tSelected.some((a) => a.value === "*")) { //add only 'All' if its not existing; state options for search differs
                  tSelected.unshift({
                    label: `All (${this.props.options.filter((e) => e.label.toLowerCase() !== 'not applicable').length})`,
                    value: '*',
                    isFixed: isNaOnlyOption ? true : isNASelected,
                    isDisabled: isNaOnlyOption ? true : isNASelected,
                  });
                }
                //FOR SINGLE SELECT
                tSelected = selected.filter(
                  (t) =>
                    t.value !== tAllOption.value && !tAllOption.isFixed ||
                    (t.value !== tAllOption.value && t.isFixed !== true && !tAllOption.isFixed)
                );
              }
            }
          } else if (event.action === 'deselect-option') {
            //IF 'ALL' IS UNSELECTED
            if (
              selected.length === this.props.options.filter((e) => e.label !== "Not Applicable").length &&
              !selected.some((y) => y.value === tAllOption.value && !tAllOption.isFixed)
            ) {
              if (tSelected.some((e) => e.isFixed === true))
                tSelected = selected.filter((t) => t.isFixed === true);
              else {
                tSelected = [];
              }
            } else {
              //FOR SINGLE VALUE
              tSelected = selected.filter(
                (t) =>
                  t.value !== tAllOption.value && !tAllOption.isFixed ||
                  (t.value !== tAllOption.value && t.isFixed !== true && !tAllOption.isFixed)
              );
            }
          }
        }

        this.setState({
          optionSelected: tSelected,
        }, () => {
          this.updateOptions();
        });

      } else {
        this.handleSearch({ target: { value: ' ' } }, '');
      }
    }

    if (!this.shouldChangeTrigger && this.isFirstInputSpace) {
      this.shouldChangeTrigger = true;
      this.isFirstInputSpace = false;
    }
  };

  getOrigValue = () => {
    const opt = this.state.options.filter((e) => e.value !== '*' && e.label !== "Not Applicable");
    const origSelOpt = UnRefObj(this.props.value);
    const origList =
      origSelOpt.length === opt.length ? [this.getAllOpts(opt), ...origSelOpt] : origSelOpt;
    return origList;
  };

  // onPaste = (e, action) => {
  //   try {
  //     let tOptions = UnRefObj(this.props.options);
  //     let options = [];

  //     let tPastedData = e.clipboardData
  //       .getData('text') //get clipboard data
  //       .replace(/(\r\n|\t\r\n|\t\r)/gm, '\n') //replace excel string to readable string
  //       .replace(/(\n|\r|\t)/gm, '\n')
  //       .replace(/\n+$/, '')
  //       .split('\n') //create array
  //       .filter((item, index, list) => list.indexOf(item) == index); //filter unique data

  //     // find in options pasted data
  //     let currentPasted = [];
  //     let selectedData = this.state.optionSelected;
  //     let inputValue = '';

  //     //check if pasted data is existing on options
  //     tPastedData.map((tp) => {
  //       let opData = tOptions.find(
  //         (op) => op.label.toLowerCase() === tp.toLowerCase()
  //         // (op) => op.label.replace(/^\s+/, '').toLowerCase() === tp.toLowerCase()
  //       );
  //       // for currently selected & existing selected Data
  //       if (opData !== undefined) {
  //         if (
  //           selectedData.find((pD) => pD.label.toLowerCase() === tp.toLowerCase()) === undefined
  //         ) {
  //           selectedData.push(opData);
  //         }
  //         // for currently pasted data
  //         if (
  //           currentPasted.find((pD) => pD.label.toLowerCase() === tp.toLowerCase()) === undefined
  //         ) {
  //           currentPasted.push(opData);
  //         }
  //       }
  //     });

  //     if (currentPasted.length >= 1) {
  //       tOptions.unshift({
  //         label: `All (${this.props.options.filter((e) => !e.label.toLowerCase().includes('not applicable')).length})`,
  //         value: '*',
  //       });
  //       options = tOptions;
  //     } else {
  //       inputValue = e.clipboardData.getData('text');
  //       tOptions.map((op) => {
  //         if (op.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
  //           options.push(op);
  //         }
  //       });
  //     }

  //     this.setState(
  //       {
  //         inputValue: inputValue,
  //         isPasted: true,
  //         options: options,
  //       },
  //       () => {
  //         if (currentPasted.length >= 1) {
  //           this.shouldChangeTrigger = true;
  //           this.isFirstInputSpace = false;
  //           this.handleChange(selectedData, action);
  //         }
  //       }
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  handleSearch = (e, origin) => {
    try {
      if (!this.state.isPasted) {
        let options = [];
        let tOptions = this.props.options;
        let searchKey = origin === 'onKeyDown' ? ' ' : e.target.value;
        let shouldShowAll = IsValid(this.props.isShowAll) ? this.props.isShowAll : true
        let curOptions = UnRefObj(this.state.options);
        let tSelected = this.state.optionSelected;
        let isNASelected = tSelected.some((e) => e.label.toLowerCase() === 'not applicable')
        let isNaOnlyOption = tOptions.length === 1 && tOptions.some((e) => e.label.toLowerCase() === 'not applicable');
        let isAllSelected = tSelected.some((e) => e.value === '*');

        tOptions.map((op) => {
          if (String(op.label).toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {
            options.push(op);
          }
        });

        if (options.length === this.props.options.length && shouldShowAll) {
          options.unshift({
            label: `All (${this.props.options.filter((e) => e.label.toLowerCase() !== 'not applicable').length})`,
            value: '*',
            isFixed: isNaOnlyOption ? true : isNASelected,
            isDisabled: isNaOnlyOption ? true : isNASelected,
          });
        }

        this.shouldChangeTrigger = origin !== 'onKeyDown';
        this.isFirstInputSpace = origin === 'onKeyDown';


        options.forEach((op) => {
          if (((isAllSelected || tSelected.length > 0 && !isNASelected) && op.label.toLowerCase() === 'not applicable') ||
            ((isNaOnlyOption || isNASelected) && op.label.toLowerCase() !== 'not applicable')) {
            op.isFixed = true;
            op.isDisabled = true;
          }
          else {
            op.isFixed = false;
            op.isDisabled = false;
          }
        })

        this.setState({
          options: options,
          inputValue: searchKey, //onkeydown
        });
      } else {
        this.setState({
          isPasted: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const props = this.props;
    let maxToShow = 3;
    let shouldBadgeShow = length > maxToShow;
    let displayLength = length - maxToShow;
    var length = Object.keys(props.value).length !== 0 ? Object.keys(props.value).length : 0;
    let selectedCount = this.state.optionSelected.length;
    // let requiredPlaceHolder = props.placeholder + (props.isRequired ? '*' : '');
    const styles = {
      option: (base, state) => ({
        ...base,
        '&:hover': {
          color: state.data.isFixed ? 'rgb(181, 192, 202)' : (state.isSelected ? 'rgb(21, 24, 27)' : 'rgb(99, 115, 129)'),
          backgroundColor: 'white',
          fontFamily: 'Graphik-Medium',
        },
        backgroundColor: 'none',
        color: state.data.isFixed ? 'rgb(181, 192, 202)' : (state.isSelected ? 'rgb(21, 24, 27)' : 'rgb(99, 115, 129)'),
        whiteSpace: 'nowrap',
        fontFamily: 'Graphik-Medium',
        fontSize: '14px',
        padding: '5px 15px',
        wordBreak: 'break-word',
        cursor: 'pointer',
      }),
      placeholder: (base, state) => ({
        color: '#919eab',
      }),
      menu: (base, state) => ({
        ...base,
        borderRadius: '5px',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.15)',
        padding: '5px',
        zIndex: 2,
        width: '100%',
        fontSize: '16px',
      }),
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: 'rgb(250, 245, 253)',
          borderRadius: '16px',
          border: '1px solid rgb(161, 0, 255)',
          height: '24px',
          padding: '0px 0px 0px 10px',
          display: 'flex',
          alignItems: 'center',
          margin: '3px 4px',
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: 'rgb(161, 0, 255)',
        fontSize: '12px',
        fontFamily: 'Graphik-Medium',
        fontWeight: '600',
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {},
        display: data.isFixed ? 'none' : 'block',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      }),
    };
    const customClassName = props.customClassName == undefined ? '' : props.customClassName;
    return (
      <div
        id={this.state.containerID}
        className={
          props.value.length === 0
            ? `multi-select-dropdown-with-count blank-multi-field ${customClassName}`
            : `multi-select-dropdown-with-count ${customClassName}`
        }
      >
        {
          <div className="multi-select-label ">
            {selectedCount > 0 ? props.label : ''}
            {props.isRequired && selectedCount > 0 ? <span className="requiredText">*</span> : ''}
          </div>
        }
        <div id="mt-count-tooltip" className={props.isRequired ? 'required-multi-select' : ''}>
          <ReactSelect
            noOptionsMessage={() => 'No available option.'}
            options={this.state.options}
            ref={r => (this.selectRef = r)}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
              MultiValue,
              ValueContainer,
              MenuList: CustomMenuList,
              IndicatorSeparator: () => null,
            }}
            styles={styles}
            onChange={this.handleChange}
            allowSelectAll={true}
            placeholder={props.placeholder}
            value={this.state.optionSelected}
            isDisabled={props.isDisabled}
            onMenuOpen={this.onMenuOpen}
            openMenuOnClick={this.onMenuOpen}
            menuIsOpen={this.state.menuIsOpen}
            // openMenuOnClick={true}
            isSearchable={false}
            onClickCancel={this.onClickCancel}
            onClickApply={this.onClickApply}
            isClearable={false}
            popDirection={props.popdirection}
            backspaceRemovesValue={false}
            // onPaste={this.onPaste}
            // onKeyDown={e => {
            //   if (e.keyCode === 32 && !this.selectRef.state.inputValue) e.preventDefault();
            // }}
            inputValue={this.state.inputValue}
            handleSearch={this.handleSearch}
            isShowAll={props.isShowAll}
          />
          {shouldBadgeShow && props.isDisabled && (
            <div className="selected-count-container">
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="small"
                name="plus"
                iconStyle="solid"
                iconColor="dl-white"
              />
              {displayLength}
            </div>
          )}
          {this.state.optionSelected.length > 0 ? (
            <div
              id="dropdown-popover"
              className={props.id === 'dashboard' || props.id === 'assetName' ? 'pop-top' : 'pop-left'}
            >
              <div className="pop-title">Selected Options</div>
              <div className="pop-body" style={{ maxHeight: '300px', overflow: 'auto' }}>
                {this.state.optionSelected.length > 0
                  ? this.state.optionSelected.map((item) => {
                    return <div className="dropdown-tooltip">{item.label}</div>;
                  })
                  : ''}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
