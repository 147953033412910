import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveSelectMenuBasic } from "tfo-groove";
import { FetchCMVProjDelivByDrpdown } from '../../../Actions/Client.Action';

const CVMProjectDeliveredByDropdown = (() => {
    class CVMProjectDeliveredByDropdownPresentational extends Component {
        render() {
            return (
                <GrooveSelectMenuBasic
                    id="deliveredBy"
                    name="deliveredby"
                    placeholder="Delivered By*"
                    isDisabled={this.props.isDisabled}
                    list={this.props.list}
                    defaultData={this.props.data}
                    callbackFunction={this.props.onChange}
                    customClassName={this.props.isValid ? "" : " dropdown-invalid"}
                />
            );
        }
    }

    class CVMProjectDeliveredByDropdownContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                list: []
            }
        }
        componentDidMount() {
            if (this.props.projDelivByList.list.length === 0
               || this.props.projDelivByList.clientID !== this.props.clientID
            ) {
                this.props.FetchCMVProjDelivByDrpdown(this.props.clientID)
            } 
            else {
                this.loadList(this.props.projDelivByList.list)
            }
        }
        componentDidUpdate(prevProps) {
            if (this.props.projDelivByList !== prevProps.projDelivByList) {
                this.loadList(this.props.projDelivByList.list)
            }
            if (this.props.clientID != prevProps.clientID) {
                this.props.FetchCMVProjDelivByDrpdown(this.props.clientID)
            }
        }
        
        loadList = (paramList) => {
            const tempList = paramList.map(e => {
                return {
                    value: e.id,
                    label: e.email,
                    text: e.email
                }
            })
            this.setState({ list: tempList })
        }

        onChange = (e) => {
            this.props.callback(e, "deliveredBy");
        }
        render() {
            return (
                <CVMProjectDeliveredByDropdownPresentational
                    {...this.props}
                    {...this.state}
                    onChange={this.onChange}
                />
            );
        }
    }

    return CVMProjectDeliveredByDropdownContainer;

})();

export default connect(
    state => {
        return {
            projDelivByList: state.ProjDelivByList,
            clientID: state.SharedData.selectedClient.value
        }
    },
    dispatch => bindActionCreators(Object.assign({}, { FetchCMVProjDelivByDrpdown }), dispatch)
)(CVMProjectDeliveredByDropdown);
