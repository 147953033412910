import React, { Component } from "react";
// import { history } from "../../store";
import { withRouter } from "react-router-dom";
import { HttpGetDirectDownload } from "../../Utilities/HTTPUtil";
import CVMModal from "../Shared/CVMModal/CVMModal";
import { ProgressBar } from "react-bootstrap";
import { HasValue } from "../../Utilities/Validations";
import { DeleteCallbackURL, DeleteDistiURL, DeleteReferrer } from "../../Utilities/AccessManagementUtil";
import { GrooveButton } from "tfo-groove";

const DirectDownload = (function () {
  class DirectDownloadContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: {
          show: false,
          text: "",
        },
        progress: 0,
      };
    }
    componentDidMount() {
      setTimeout(() => {
        DeleteCallbackURL()
        DeleteDistiURL()
        DeleteReferrer()
      }, 7000)
      const params = this.getURLInfo();
      if (HasValue(params)) this.downloadFile(params);
    }
    getURLInfo = () => {
      const path = window.location.pathname;
      try {
        let segments = path.split("/");
        return segments[1] + "/" + segments[2] + "?" + segments[3];
      } catch (e) {
        this.setState({
          progress: 0,
          modal: { show: true, text: "Missing required URL parameters." },
        });
        return undefined;
      }
    };

    downloadFile = async (url) => {
      const response = await HttpGetDirectDownload(url, undefined, (res) => {
        this.setState({
          progress: Math.ceil((res.loaded / res.total) * 100),
        });
      });

      if (response !== false) {
        this.setState({
          modal: {
            show: true,
            text: "Download completed. You may now close this page.",
          },
        });
      } else {
        this.setState({
          modal: {
            show: true,
            text: "Error encountered during file download.",
          },
        });
      }
    };
    onCloseModal = () => {
      this.setState({
        modal: {
          show: false,
          text: "",
        },
      });
      this.props.history.push("/");
    };
    render() {
      return (
        <>
          <div id="dl-loading-screen">
            <div id="progress-container">
              <h5>{this.state.progress}%</h5>
              <ProgressBar
                animated
                label
                striped={false}
                variant={this.state.progress === 100 ? "success" : "info"}
                now={this.state.progress}
              />
            </div>
          </div>

          <CVMModal
            title="File Download"
            show={this.state.modal.show}
            onCloseModal={this.onCloseModal}
            content={
              <div id="dl-modal-container">
                <label>{this.state.modal.text}</label>
                <GrooveButton
                  d="primry-btn-2"
                  type="solid"
                  colorClass="stat-alternate"
                  text="Go back to Home page"
                  callbackFunction={this.onCloseModal}
                />
              </div>
            }
          />
        </>
      );
    }
  }

  return DirectDownloadContainer;
})();

export default withRouter(DirectDownload);
