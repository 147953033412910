import React, { Component } from "react";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import {
   GrooveIconSvg,
   GrooveInput,
   GrooveButton,
} from "tfo-groove";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HttpGet, HttpPost, HttpPostText } from "../../Utilities/HTTPUtil";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// icons
import Toast from "react-bootstrap/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle, } from "@fortawesome/free-solid-svg-icons";
import CVMModal from "../Shared/CVMModal/CVMModal";
import CVMMultiSelectTextbox from "../Shared/CVMMultiSelectTextbox/CVMMultiSelectTextbox";
import { setToasterParam } from "../../Actions/Shared.Action";
import CVMPagination from "../Shared/CVMPagination/CVMPagination"
import CVMToasterNotifUndo from '../Shared/CVMToasterNotifUndo/CVMToasterNotifUndo';

import "./QualityCheckerManagement.css";
import { IsValid, IsValidStr } from "../../Utilities/Validations";

const QualityCheckerManagement = (function () {
   class QualityCheckerManagementPresentational extends Component {
      render() {
         const filterPopover = (

            <Popover id="filter-popover" trigger="focus">
               <Popover.Content>
                  <div>
                     <div className="search-bar-container" id="popover-searchbox">
                        <div className="search-bar label14-regular-midnight">
                           <GrooveIconSvg
                              customClassName="close-modal-btn"
                              size="large"
                              name="search"
                              iconStyle="solid"
                              iconColor="stat-neutral"
                           />
                           <input type="text" name="name" autocomplete="off" placeholder="Search Offerings…" id="searchBox" maxlength="255" onChange={(e) => { this.props.searchOfferingName(e) }} />
                        </div>

                     </div>


                     <div className="filter-select-table">
                        <ul>
                           {
                              this.props.ioListPresentational.map((offerings, index) => {
                                 let isChecked = this.props.checkedOfferings.filter(x => x.offeringID === offerings.offeringID)
                                 return (
                                    <li key={index}>
                                       <label class="checkbox-container">
                                          <input
                                             type="checkbox"
                                             onChange={(e) => this.props.handleCheckbox(e.target.checked, offerings)}
                                             checked={isChecked.length > 0 ? true : false}
                                          />
                                          <span class="checkmark-checkbox"></span>{offerings.offeringName}
                                       </label>
                                    </li>
                                 )
                              })
                           }
                        </ul>
                     </div>

                  </div>
               </Popover.Content>
               <div className="filter-action-btns">
                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="outline"
                     colorClass="gr-silver"
                     size="auto"
                     text="Clear"
                     callbackFunction={this.props.clearOfferingFilter}
                  />

                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="solid"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Apply"
                     callbackFunction={this.props.applyOfferingFilter}
                  />
               </div>

            </Popover>
         );
         let that = this
         return (
            <React.Fragment>
               <CVMToasterNotifUndo />
               <CVMModal
                  title="Add New Quality Checker"
                  show={this.props.show}
                  onCloseModal={this.props.onCloseModal}
                  size={this.props.size}
                  content={
                     <ModalContent
                        onCloseModal={this.props.onCloseModal}
                        errorMessges={this.props.errorMessges}
                        profileSelected={this.props.profileSelected}
                        userDetails={this.props.userDetails}
                        onChangeUserDetails={this.props.onChangeUserDetails}
                        saveQualityCheckerDetails={this.props.saveQualityCheckerDetails}
                        onAddQualityCheckerUsers={this.props.onAddQualityCheckerUsers}
                        onRemovequalityCheckerUsers={this.props.onRemovequalityCheckerUsers}
                        isError={this.props.isError}
                        qualityCheckerUsers={this.props.qualityCheckerUsers}
                        onAddQualityCheckerUsersTextChange={this.props.onAddQualityCheckerUsersTextChange}
                        qualityCheckerUsersList={this.props.qualityCheckerUsersList}
                        disableSave={this.props.disableSave}
                     />

                  }

               />
               <CVMModal
                  title={""}
                  show={this.props.showDelConfirmationModal}
                  onCloseModal={this.props.onConfirmClose}
                  customclass={"select-project-modal"}
                  content={
                     <WarningModal
                        //redux
                        onCloseModal={this.props.onConfirmClose}
                        onCloseProject={this.props.onConfirmDelete}
                        disableDelete={this.props.disableDelete}
                     />
                  }
               />
               <div className="search-bar-container">
                  <div className="search-bar label14-regular-midnight">
                     <GrooveIconSvg
                        customClassName="close-modal-btn"
                        size="large"
                        name="search"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                     />
                     <input type="text" name="name" autocomplete="off" placeholder="Search User Name" id="searchBox" maxlength="255" onChange={(e) => { this.props.searchFunc(e) }} />
                  </div>

               </div>
               <div className="qcmanagement-table-container">
                  <div className="subdimension-table">
                     {this.props.ioListOriginal.length > 0 || this.props.searchKey !== "" ?
                        <div className="subdimension-header">
                           <div className="row">
                              <div className="col-lg-3">
                                 <div className="sort-io" onClick={() => this.props.sortOffering()}>Offering
                                    <div className="sort-btn-container">
                                       {
                                          this.props.sortArrow === 1 ?
                                             <GrooveIconSvg
                                                customClassName="sort-btn"
                                                size="tiny"
                                                name="caret-up"
                                                iconStyle="solid"
                                                iconColor="dl-midnight"
                                             />
                                             :
                                             this.props.sortArrow === 2 ?
                                                <GrooveIconSvg
                                                   customClassName="sort-btn"
                                                   size="tiny"
                                                   name="caret-down"
                                                   iconStyle="solid"
                                                   iconColor="dl-midnight"
                                                />
                                                :
                                                ""
                                       }
                                    </div>
                                 </div>
                                 <OverlayTrigger rootClose trigger="click" placement="bottom-start" overlay={filterPopover}>
                                    <div>
                                       <GrooveIconSvg
                                          className="filter-btn"
                                          size="small"
                                          name="filter"
                                          iconStyle="solid"
                                          iconColor="dl-midnight"
                                       />
                                    </div>
                                 </OverlayTrigger>
                              </div>
                              {/* <div className="col-lg-3">
                                 <div className="sort-io" onClick={() => this.props.sortSubOffering()}>Sub Offering
                                    <div className="sort-btn-container">
                                       {
                                          this.props.sortSubArrow === 1 ?
                                             <GrooveIconSvg
                                                customClassName="sort-btn"
                                                size="tiny"
                                                name="caret-up"
                                                iconStyle="solid"
                                                iconColor="dl-midnight"
                                             />
                                             :
                                             this.props.sortSubArrow === 2 ?
                                                <GrooveIconSvg
                                                   customClassName="sort-btn"
                                                   size="tiny"
                                                   name="caret-down"
                                                   iconStyle="solid"
                                                   iconColor="dl-midnight"
                                                />
                                                :
                                                ""
                                       }
                                    </div>
                                 </div>
                              </div> */}
                           </div>
                           <div className="subdimension-body-container">
                              {
                                 this.props.ioListPaged.map((item, index) => {
                                    return (

                                       <div className="subdimension-body">
                                          <Accordion className="subdimension-row" defaultActiveKey="1">
                                             <Card className="">
                                                <Accordion.Collapse eventKey="0">
                                                   <Card.Body>

                                                      <table className="iouser-table">

                                                         <tr className="bodytext14">
                                                            <th />
                                                            <th>User Name
                                                               <GrooveIconSvg
                                                                  //customClassName="accordion-button"
                                                                  size="medium"
                                                                  name="plus-circle"
                                                                  iconStyle="solid"
                                                                  iconColor="stat-alternate"
                                                                  callbackFunction={() =>
                                                                     this.props.onAddNewUser(item)
                                                                  }
                                                               />

                                                            </th>

                                                            <th>Email ID</th>
                                                            <th>Actions</th>
                                                         </tr>

                                                         {item.Users.map(user => {

                                                            return (
                                                               user.userId > 0 && (<tr className="bodytext12-regular">
                                                                  <td />
                                                                  <td>{user.userName} </td>
                                                                  <td>{user.userEmailID}</td>
                                                                  <td>
                                                                     <GrooveIconSvg
                                                                        customClassName="delete-icon"
                                                                        size="large"
                                                                        name="trash-alt"
                                                                        iconStyle="solid"
                                                                        iconColor="stat-alternate"
                                                                        callbackFunction={() => {
                                                                           const body = {
                                                                              "offeringID": item.offeringID,
                                                                              "userID": user.userId,
                                                                              "isDeleted": true,
                                                                              "username": user.userName,
                                                                           }
                                                                           this.props.deleteIOApproverUser(body)
                                                                        }

                                                                        }
                                                                     />
                                                                  </td>

                                                               </tr>)
                                                            )
                                                         })}

                                                      </table>

                                                   </Card.Body>
                                                </Accordion.Collapse>
                                                <Card.Header>
                                                   <Accordion.Toggle as={Button} variant="link" eventKey="0" collapse="0">

                                                      <GrooveIconSvg
                                                         customClassName="accordion-button"
                                                         size="small"
                                                         name="caret-right"
                                                         iconStyle="solid"
                                                         iconColor="dl-midnight"
                                                      />
                                                   </Accordion.Toggle>

                                                   <div className="row bodytext14">
                                                      <div className="col-lg-3">{item.offeringName}</div>
                                                   </div>
                                                </Card.Header>
                                             </Card>
                                          </Accordion>
                                       </div>

                                    )
                                 })
                              }
                           </div>
                           <CVMPagination list={IsValidStr(this.props.searchKey) ? this.props.filteredData : this.props.ioList} callbackFunction={(e) => this.props.setPageItems(e)} shouldNotReset={true} />
                        </div> : <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                     }
                  </div>
               </div>

            </React.Fragment>
         );
      }
   }



   //AddNewUserModalContent
   class ModalContent extends Component {
      constructor(props) {
         super(props);
         this.state = {
         };
      }

      render() {
         let errorMessage = [];
         this.props.errorMessges.forEach(f => {
            errorMessage.push(<>{f}<br /></>);
         });

         return (
            <React.Fragment>
               <div className="add-new-program-container">
                  <Row>
                     <Col lg={6} md={6} sm={6}>
                        <GrooveInput
                           name="offeringName"
                           isDisabled={true}
                           placeholder="Offering"
                           inputValue={this.props.userDetails.offeringName || ""}
                           id="offeringName"
                        />
                     </Col>
                  </Row>

                  <div className="client-steakholder-title bodytext18-medium-slate">
                     Quality Checker List
                  </div>
                  <Accordion className="configure-client-collapse" defaultActiveKey="0">
                     <Card>
                        <Accordion.Collapse eventKey="0">
                           <Card.Body>
                              <Row>
                                 <Col lg={6} md={6} sm={6}>
                                    <CVMMultiSelectTextbox
                                       placeholder="Enter Quality Checker Name"
                                       id="qualityCheckerName"
                                       name="qualityCheckerName"
                                       addButton={this.props.onAddQualityCheckerUsers}
                                       onRemoveSelected={(e) => this.props.onRemovequalityCheckerUsers(e)}
                                       onTextChange={(e) => this.props.onAddQualityCheckerUsersTextChange(e)}
                                       list={this.props.qualityCheckerUsersList}
                                       customClassName={
                                          this.props.isError ? "dropdown-invalid" : ""
                                       }
                                       inputValue={this.props.qualityCheckerUsers}
                                    />

                                 </Col>
                              </Row>
                           </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                           <Accordion.Toggle as={Button} variant="link" eventKey="0">
                              <div className="bodytext18-medium-slate">
                                 Add users
                              </div>
                              <GrooveIconSvg
                                 id="configureProgram"
                                 customClassName="accordion-button"
                                 size="large"
                                 name="chevron-down"
                                 iconStyle="solid"
                                 iconColor="stat-neutral"
                              />
                           </Accordion.Toggle>
                        </Card.Header>
                     </Card>
                  </Accordion>

               </div>

               <div className="add-program-buttons">
                  <div><p className="missing">
                     {errorMessage}
                  </p>
                  </div>
                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="outline"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Cancel"
                     callbackFunction={this.props.onCloseModal}
                  />

                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={this.props.isError || this.props.disableSave}
                     hasIcon={false}
                     type="solid"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Save"
                     callbackFunction={this.props.saveQualityCheckerDetails}
                  />
               </div>
            </React.Fragment>

         )



      };
   }
   //End of Add New User Modal Content

   class QualityCheckerManagementContainer extends Component {
      constructor(props) {
         super(props);
         this.state = {
            qualityCheckerUsersList: [],
            qualityCheckerUsers: "",
            isError: false,
            userList: [],
            ioListPresentational: [],
            ioListPaged: [],
            ioListOriginal: [],
            show: false,
            size: "md",
            searchKey: "",

            userDetails: {
               offeringID: 0,
               offeringName: "",
               users: {
                  userID: 0,
                  username: "",
                  emailID: "",
               }

            },
            errorMessges: [],
            gridApi: () => { },
            //isEdit: false,
            ioList: [],
            showDelConfirmationModal: false,
            deleteIOUserDetails: {},
            checkedOfferings: [],
            sortArrow: 0,
            sortSubArrow: 0,
            filteredData: [],
            disableSave: true,
            disableDelete: false,
            disableAdd: false,
            newlyAddedUserList: []
         };
      }

      componentDidMount = async () => {
         try {
            await this.getQualityCheckerManagementDetails();
            await this.getUserDetails();
            this.checkedAllOfferingOnLoad();
         }
         catch (e) {
            console.log(e)
         }
      }

      getGridApi = (api) => {
         this.setState({
            gridApi: api,
         });
      };


      onTablSearchTextChanged = (e) => {
         try {
            let userName = e.target.value
            let result = [];
            let ioList = this.state.ioList;
            let currentOfferingID = 0;
            ioList.map(o => {
               o.Users.map(u => {
                  let mapUserName = IsValidStr(u.userName) ? u.userName.toLowerCase() : ''
                  if (mapUserName.indexOf(userName.toLowerCase()) !== -1 && currentOfferingID !== o.offeringID) {
                     currentOfferingID = o.offeringID
                     result.push(o)
                  }
               })
            })

            this.setState(
               {
                  ioListPaged: result,
                  searchKey: userName,
                  filteredData: IsValidStr(userName) ? result : []
               }
            )
         }
         catch (e) {
            console.log(e)
         }
      }

      //Offering Filter
      filterOfferingName = (e) => {
         try {
            let offerings = [];
            let ioList = [];
            let newList = this.state.ioListOriginal;


            newList.filter(item => {
               let i = ioList.findIndex(x => x.offeringName == item.offeringName);
               if (i <= -1) {
                  ioList.push({ offeringID: item.offeringID, offeringName: item.offeringName })
               }
            })
            ioList.map(oName => {
               if (oName.offeringName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
                  offerings.push(oName)
               }
            })
            this.setState({
               ioListPresentational: offerings,
            })

         }
         catch (e) {
            console.log(e)
         }
      }


      onCloseModal = () => {
         try {
            this.setState({
               ...this.state,
               qualityCheckerUsers: "",
               isError: false,
               errorMessges: [],
               disabledAdd: false,
               show: !this.state.show,
               disableSave: true
            });
         }
         catch (e) {
            console.log(e)
         }
      };

      onAddNewUser = (item) => {
         this.setState({
            userDetails: {
               offeringID: item.offeringID,
               offeringName: item.offeringName,
               users: item.Users
            },
            qualityCheckerUsersList: item.Users.filter(i => i.userId > 0).map(x => {
               return (
                  {
                     text: x.userEmailID,
                     label: x.userEmailID,
                     value: x.userId,
                     userName: x.userName
                  }
               )
            })
         }, () => {
            this.onCloseModal();
         })

      }

      getQualityCheckerManagementDetails = async () => {
         try {
            let distinctOffering = []
            const res = await HttpGet(`User/GetQualityCheckerManagementDetails`);

            if (res != null && res.length > 0) {
               let data = [];
               res.map(x => {
                  data.push({
                     offeringID: x.OfferingID,
                     offeringName: x.OfferingName,
                     Users:
                        x.Users.map(y => {
                           return ({
                              userId: y.UserID,
                              userName: y.Username,
                              userEmailID: y.UserEmailID,
                           })
                        })
                  })
               })

               data.map(x => {
                  if (distinctOffering.length > 0) {
                     if (distinctOffering.filter(y => y.offeringID === x.offeringID).length === 0) {
                        distinctOffering.push({
                           offeringID: x.offeringID,
                           offeringName: x.offeringName
                        })
                     }
                  } else {
                     distinctOffering.push({
                        offeringID: x.offeringID,
                        offeringName: x.offeringName
                     })
                  }
               })

               this.setState({
                  ioList: data,
                  ioListPresentational: distinctOffering,
                  ioListPaged: data,
                  ioListOriginal: data
               });
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      saveQualityCheckerDetails = async () => {
         this.setState({
            disableSave: true
         }, async () => {
            const userDetails = this.state.userDetails;
            const userList = this.state.qualityCheckerUsersList
            let bodyParam = {
               "offeringID": userDetails.offeringID,
               "offeringName": userDetails.offeringName,
               "username": this.props.userInformation.userName,
               "users": userList.map(x => {
                  return ({
                     "userID": x.value,
                     "username": x.userName.trim(),
                     "userEmailID": x.label.trim()
                  })
               })
            }
            const data = await HttpPost("User/AddEditQualityCheckerDetails", bodyParam);
            if (data !== null) {
               if (data.Message === "success") {
                  let returnMsg = "User has been updated.";

                  this.props.setToasterParam({
                     show: true,
                     message: returnMsg,
                     type: 'success'
                  });

                  this.onCloseModal();
                  this.getQualityCheckerManagementDetails();
               } else {
                  this.setState({
                     errorMessges: [data.Message]
                  })
               }
            }
            this.setState({
               disableSave: false
            })
         })

      }


      deleteIOApproverUser = (item) => {
         if (item) {
            this.setState({
               showDelConfirmationModal: !this.state.showDelConfirmationModal,
               deleteIOUserDetails: item,
            });
         }
      };
      onConfirmClose = () => {
         this.setState({
            ...this.state,
            showDelConfirmationModal: !this.state.showDelConfirmationModal,
         });
      };

      onConfirmDelete = async () => {
         this.setState({
            disableDelete: true
         }, async () => {
            const userDetails = this.state.deleteIOUserDetails
            const data = await HttpPostText(`User/DeleteQualityCheckerDetails`, userDetails);
            if (data != null) {
               this.setState({
                  showDelConfirmationModal: !this.state.showDelConfirmationModal,
               }, () => {
                  this.props.setToasterParam({
                     type: 'success',
                     show: true,
                     message: 'User has been removed',
                     hasUndo: true,
                     callback: () => this.undoDelete(userDetails)
                  });
                  this.getQualityCheckerManagementDetails()
               });
            }
            this.setState({
               disableDelete: false
            })
         })

      }
      undoDelete = async (userDetails) => {
         try {
            userDetails.isDeleted = false
            const data = await HttpPostText(`User/DeleteQualityCheckerDetails`, userDetails);
            if (data != null) {
               this.getQualityCheckerManagementDetails()
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      setPageItems = (items) => {
         let prevDisplayed = this.state.ioListPaged;
         let shouldCollapseAll = prevDisplayed.length !== this.state.ioListOriginal.length && items[0].offeringID !== prevDisplayed[0].offeringID;
         this.setState({
            ioListPaged: items
         });

         if(shouldCollapseAll){
            //To Collapse Accordion
            let elems = document.getElementsByClassName("collapse show");
            // while (document.getElementsByClassName("collapse show").length) {
            for (var i = 0; i < elems.length; i++) {
               if (elems[i].className === "collapse show") {
                  document
                     .getElementsByClassName("collapse show")
                  [i].parentElement.childNodes[1].children[0].click();
               }
            }
         }
      }

      onAddQualityCheckerUsersTextChange = (e) => {
         try {
            this.setState({
               qualityCheckerUsers: e.value
            }, () => {
               if (e.value === "") {
                  this.setState({
                     isError: false,
                     errorMessges: []
                  })
               }
            })
         }
         catch (e) {
            console.log(e)
         }
      }

      onAddQualityCheckerUsers = () => {
         try {
            if (!this.state.disableAdd) {
               this.setState({ disableAdd: true }, async () => {
                  const newUser = this.state.qualityCheckerUsers.split(' ').join('').trim();
                  let qualityCheckerUsersList = this.state.qualityCheckerUsersList;
                  const isValid = await this.validateEmailAddress(newUser);
                  const userId = this.state.userList.filter(x => x.UserEmailID === newUser);
                  let newlyAddedUserList = this.state.newlyAddedUserList;

                  if (isValid) {
                     qualityCheckerUsersList.push({
                        text: newUser,
                        label: newUser,
                        value: userId.length > 0 ? userId[0].Id : 0,
                        userName: userId.length > 0 ? userId[0].UserName : newUser.split("@")[0]
                     })

                     if (this.state.newlyAddedUserList.filter(x => x.userName === newUser.split("@")[0]).length === 0) {
                        newlyAddedUserList.push({
                           text: newUser,
                           label: newUser,
                           value: userId.length > 0 ? userId[0].Id : 0,
                           userName: userId.length > 0 ? userId[0].UserName : newUser.split("@")[0]
                        })
                     }
                     this.setState({
                        qualityCheckerUsersList: qualityCheckerUsersList,
                        qualityCheckerUsers: '',
                        disableAdd: false,
                        disableSave: false,
                        newlyAddedUserList: newlyAddedUserList,
                     })
                  }
               })
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      onRemovequalityCheckerUsers = (e) => {
         try {
            let qualityCheckerUsersList = this.state.qualityCheckerUsersList.filter(x => x.userName !== e.userName);
            let newlyAddedUserList = this.state.newlyAddedUserList.filter(x => x.userName !== e.userName);

            this.setState({
               qualityCheckerUsersList: qualityCheckerUsersList,
               disableSave: newlyAddedUserList.length === 0 && qualityCheckerUsersList.length === this.state.userDetails.users.length,
               newlyAddedUserList: newlyAddedUserList
            })
         }
         catch (e) {
            console.log(e)
         }
      }

      validateEmailAddress = async (email) => {
         try {
            let isValid = true
            let message = []
            const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (email) { // has value
               if (!re.test(email.trim())) { // is valid email
                  isValid = false
                  message.push("Invalid Email")
               } else {
                  if (!email.toLowerCase().trim().endsWith("@accenture.com")) { // it has @accenture.com
                     isValid = false;
                     message.push("Valid email ID should have '@accenture.com'")
                  } else {
                     let emailCount = email.toLowerCase().split("@"); // it has multiple "@"
                     emailCount = emailCount.filter(r => r.trim() != "");
                     if (emailCount.length > 2) {
                        isValid = false;
                        message.push("Invalid Email")
                     } else {
                        if (this.validateDuplicateEmail(email)) { // duplicate
                           message.push("User already exists")
                           isValid = false
                        }
                        else {
                           if (await this.validateUserIsAlreadyMappedToAnyProfile(email)) {
                              message.push("The user you are trying to add is already mapped as a PPC/BA. Please update the user access using User Management module.")
                              isValid = false
                           }
                           else{
                              if(isValid)
                              {
                              const data = await HttpPost(`User/validateEmail`,{
                                isDomainAppend: true,
                                users: [
                                 email
                                ]
                              
                              });
                              
                              if(data[0].IsValid)
                              isValid=true;
                              else
                              {
                                 message.push("Invalid Accenture ID.")
                                                isValid = false;
                              }
                               }
                        }  
                        }
                     }
                  }
               }
            } else {
               message.push("Missing Required Field")
               isValid = false
            }

            this.setState({
               errorMessges: message,
               isError: !isValid,
               disableAdd: false
            })
            return isValid
         }
         catch (e) {
            console.log(e)
         }
      }

      validateDuplicateEmail = (email) => {
         try {
            let isDuplicate = false
            const userDetails = this.state.qualityCheckerUsersList.filter(x => x.label.toLowerCase() === email.toLowerCase())

            if (userDetails.length > 0) {
               isDuplicate = true
            } else {
               isDuplicate = false
            }

            return isDuplicate
         }
         catch (e) {
            console.log(e)
         }
      }

      getUserDetails = async () => {
         try {
            const userList = await HttpGet("User/UserDetails")

            if (userList.length > 0) {
               this.setState({
                  userList: userList
               })
            }
         }
         catch (e) {
            console.log(e)
         }
      }

      validateUserIsAlreadyMappedToAnyProfile = async (email) => {//changed to check if already mapped as ppc or ba
         try {
            let isPPCBA = false
            let userDetails = await HttpGet("User/GetUserProfileMappingList?userEmailID=" + email)
            let clientIDs = [];
            let hasClientWithNoPPCBA = false;
            if (userDetails !== null) {
               let tempUserDetails = userDetails.filter(obj => { //api returns id as string
                  return obj.ProfileID === "12" || obj.ProfileID === "15";
               })
               if (tempUserDetails.length > 0) {
                  isPPCBA = true
               }
               // Get all unique client ids
               userDetails.forEach(uProfMap => {
                  let clientMasterID = uProfMap.ClientMasterID;
                  if (clientIDs.indexOf(clientMasterID) === -1) {
                     clientIDs.push(clientMasterID)
                  }
               })
               // Check by all unique client ids if there are clients where user has no PPC/BA profile to allow add
               if (clientIDs.length > 0) {
                  clientIDs.forEach(clientID => {
                     if (!hasClientWithNoPPCBA && IsValid(clientID)) {
                        let nonPPCBAUserDetails = userDetails.filter(obj => {
                           return obj.ClientMasterID === clientID && (obj.ProfileID !== "12" && obj.ProfileID !== "15");
                        });
                        let hasNonPPCBA = nonPPCBAUserDetails.length > 0;

                        let PPCBAUserDetails = userDetails.filter(obj => {
                           return obj.ClientMasterID === clientID && (obj.ProfileID === "12" || obj.ProfileID === "15");
                        })
                        let hasPPCBA = PPCBAUserDetails.length > 0;
                        if (hasNonPPCBA && !hasPPCBA) {
                           hasClientWithNoPPCBA = true;
                        }
                     }
                  })
               }

            }
            return isPPCBA && !hasClientWithNoPPCBA;
         }
         catch (e) {
            console.log(e)
         }
      }

      handleCheckbox = (e, item) => {
         try {
            let checkedOfferings = this.state.checkedOfferings;

            if (e) {
               checkedOfferings.push({
                  isChecked: e,
                  ...item
               })
            } else if (!e) {
               checkedOfferings = checkedOfferings.filter(x => x.offeringID !== item.offeringID)
            }
            this.setState(
               {
                  checkedOfferings: checkedOfferings,
               }
            )
         }
         catch (e) {
            console.log(e)
         }
      }

      applyOfferingFilter = () => {
         try {
            const checkedOfferingsList = this.state.checkedOfferings
            let originalList = this.state.ioListOriginal
            let distinctOffering = this.state.ioListPresentational.length > 0 ? this.state.ioListPresentational : []
            let ioList = [].concat(originalList)
            let tempCheckedOffList = [];

            if (distinctOffering.length > 0) {
               let filteredOffIds = [];
               distinctOffering.map(off => {
                  filteredOffIds.push(off.offeringID);
               })
               tempCheckedOffList = checkedOfferingsList.filter((x) => filteredOffIds.indexOf(x.offeringID) !== -1)
            }
            let filteredIOListPaged = ioList.filter(x => {
               if (tempCheckedOffList.length > 0) {
                  return tempCheckedOffList.some(y => {
                     return y.offeringID === x.offeringID
                  })
               } else {
                  return checkedOfferingsList.some(y => {
                     return y.offeringID === x.offeringID
                  })
               }
            })
            originalList.map(x => {
               if (distinctOffering.length > 0) {
                  if (distinctOffering.filter(y => y.offeringID === x.offeringID).length === 0) {
                     distinctOffering.push({
                        offeringID: x.offeringID,
                        offeringName: x.offeringName
                     })
                  }
               } else {
                  distinctOffering.push({
                     offeringID: x.offeringID,
                     offeringName: x.offeringName
                  })
               }
            })

            if (distinctOffering.length > 0) {
               distinctOffering.sort((a, b) => {
                  return a.offeringName.localeCompare(b.offeringName)
               })
            }

            this.setState({
               ioList: filteredIOListPaged,
               ioListPaged: filteredIOListPaged,
               ioListPresentational: distinctOffering,
               checkedOfferings: tempCheckedOffList.length > 0 ? tempCheckedOffList : checkedOfferingsList
            },
               () => { document.body.click() }
            )
         }
         catch (e) {
            console.log(e)
         }
      }

      clearOfferingFilter = () => {
         try {
            this.setState({
               checkedOfferings: [],
               ioList: this.state.ioList
            })
         }
         catch (e) {
            console.log(e)
         }
      }

      checkedAllOfferingOnLoad = () => {
         try {
            let list = this.state.ioListPresentational

            list.map(x => {
               return ({
                  isChecked: true,
                  ...x
               })
            })


            this.setState({
               checkedOfferings: list
            })
         }
         catch (e) {
            console.log(e)
         }
      }

      sortOffering = () => {
         try {
            let arrow = this.state.sortArrow
            let list = this.state.ioList
            let sortedList = [].concat(list)
            const checkedOfferingsList = this.state.checkedOfferings

            switch (arrow) {
               case 0:
                  arrow = 1
                  sortedList.sort((a, b) => {
                     return a.offeringName.localeCompare(b.offeringName)
                  })
                  break;
               case 1:
                  arrow = 2
                  sortedList.sort((a, b) => {
                     return b.offeringName.localeCompare(a.offeringName)
                  })
                  break;
               case 2:
                  arrow = 0
                  sortedList = this.state.ioListOriginal.filter(x => {
                     return checkedOfferingsList.some(y => {
                        return y.offeringID === x.offeringID
                     })
                  })
                  break;
            }
            this.setState({
               sortArrow: arrow,
               ioList: sortedList,
               sortSubArrow: 0
            }, () => {
               let elems = document.getElementsByClassName("collapse show");
               for (var i = 0; i < elems.length; i++) {
                  if (elems[i].className === "collapse show") {
                     document
                        .getElementsByClassName("collapse show")
                     [i].parentElement.childNodes[1].children[0].click();
                  }
               }
            })
         }
         catch (e) {

         }
      }

      render() {
         return (
            <QualityCheckerManagementPresentational
               show={this.state.show}
               size={this.state.size}
               onCloseModal={this.onCloseModal}
               userDetails={this.state.userDetails}
               onChangeUserDetails={this.onChangeUserDetails}
               getGridApi={this.getGridApi}
               searchFunc={this.onTablSearchTextChanged}
               onAddNewUser={this.onAddNewUser}
               errorMessges={this.state.errorMessges}
               onConfirmClose={this.onConfirmClose}
               //  isEdit={this.state.isEdit}
               ioListPresentational={this.state.ioListPresentational}
               ioList={this.state.ioList}
               ioListPaged={this.state.ioListPaged}
               ioListOriginal={this.state.ioListOriginal}
               setPageItems={this.setPageItems}
               deleteIOApproverUser={this.deleteIOApproverUser}
               saveQualityCheckerDetails={this.saveQualityCheckerDetails}
               showDelConfirmationModal={this.state.showDelConfirmationModal}
               onConfirmDelete={this.onConfirmDelete}
               // add modal
               onAddQualityCheckerUsers={this.onAddQualityCheckerUsers}
               onRemovequalityCheckerUsers={this.onRemovequalityCheckerUsers}
               isError={this.state.isError}
               qualityCheckerUsers={this.state.qualityCheckerUsers}
               onAddQualityCheckerUsersTextChange={this.onAddQualityCheckerUsersTextChange}
               qualityCheckerUsersList={this.state.qualityCheckerUsersList}
               searchOfferingName={this.filterOfferingName}
               handleCheckbox={this.handleCheckbox}
               applyOfferingFilter={this.applyOfferingFilter}
               checkedOfferings={this.state.checkedOfferings}
               clearOfferingFilter={this.clearOfferingFilter}
               sortOffering={this.sortOffering}
               sortArrow={this.state.sortArrow}
               sortSubArrow={this.state.sortSubArrow}
               searchKey={this.state.searchKey}
               filteredData={this.state.filteredData}
               disableSave={this.state.disableSave}
               disableDelete={this.state.disableDelete}
            />
         );
      }
   }
   class WarningModal extends Component {
      render() {
         return (
            <React.Fragment>
               <div className="stepperClose-Alert">
                  <div className="stepperClose-Alert-Content">
                     <div className="stepperClose-Alert-Icon">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                     </div>
                     <div className="stepperClose-Alert-text">
                        <h5>
                           Are you sure you want to Delete?
                        </h5>
                     </div>
                  </div>
               </div>
               <div className="notifyModal-buttons">
                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={false}
                     hasIcon={false}
                     type="outline"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Cancel"
                     callbackFunction={this.props.onCloseModal}
                  />

                  <GrooveButton
                     id="primry-btn-1"
                     name="Solid Button Primary-Ops"
                     isDisabled={this.props.disableDelete}
                     hasIcon={false}
                     type="solid"
                     colorClass="stat-alternate"
                     size="auto"
                     text="Yes sure"
                     callbackFunction={this.props.onCloseProject}
                  />
               </div>
            </React.Fragment>
         );
      }
   }

   return QualityCheckerManagementContainer;
})();
export default connect(
   (state) => {
      return {
         QualityCheckerManagementData: state.QualityCheckerManagementData,
         userInformation: state.userInformation,
         UserManagementData: state.UserManagementData
      };
   },
   (dispatch) =>
      bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(QualityCheckerManagement);
