import React, { Component } from "react";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GrooveIconSvg } from "tfo-groove";

require("./CVMMutiSelectDropdown.css");
const Option = props => {
  return (
    <div >
      <components.Option  {...props}>
        <div className={props.isSelected ? "cvm-checkbox cvm-checked" : "cvm-checkbox"} >
          <GrooveIconSvg
            customClassName="cvm-check-box"
            size="small"
            name="check"
            iconStyle="solid"
            iconColor="dl-white"
          />
        </div>
        <div>
          {" "}
          <label className="labels">{props.label}</label>
        </div>
      </components.Option>

    </div>
  );
};
const CVMMutiSelectDropdown = (function () {
  class CVMMutiSelectDropdownPresentational extends Component {
    render() {
      let isSelectAll = this.props.value !== undefined && this.props.options !== undefined ?
        (this.props.options.findIndex((option) => option.value === 'All') !== -1 && this.props.value.length === this.props.options.length - 1 ||
          this.props.value.filter((val) => val.label === 'All').length > 0 ? true : false) : false;
      const styles = {
        option: (base, state) => ({

          ...base,
          "&:hover": {
            color: "black",
            backgroundColor: "white",
            fontFamily: "Graphik-Medium",
          },
          backgroundColor: "none",
          color: isSelectAll ? "black" : "rgb(99, 115, 129)",
          whiteSpace: "nowrap",
          fontFamily: "Graphik-Regular",
          fontSize: "14px",
          padding: "10px 5px",
          wordBreak: "break-word",
          cursor: "pointer",

          // ...base,
          // "&:hover": {
          //   color: "#fff",
          //   backgroundColor: "#a100ff",
          //   fontFamily: "Graphik-Medium",
          // },
          // backgroundColor: isSelectAll ? "#a100ff" : (state.isSelected ? "#a100ff" : "#fff"),
          // color: isSelectAll ? "#fff" : state.isSelected ? "#fff" : "#212b36",
          // whiteSpace: "nowrap",
          // fontFamily: "Graphik-Regular",
          // fontSize: "12px",
          // padding: "10px 5px",
          // wordBreak: "break-word",
          // cursor: "pointer",
        }),
        control: (base, state) => ({
          ...base,
          borderRadius: "25px",
          boxShadow: 0,
          fontFamily: "Graphik-Medium",
          fontSize: "14px",
          color: "rgb(161, 0, 255)",
          minHeight: "38px",
          maxWidth: "500px",
          cursor: "pointer",
          fontWeight: "500",
          letterSpacing: "-0.25px",
          lineHeight: "14px",
          border: "none",
          backgroundColor: "#ffffff",
          width: "315px",
        }),
        menu: (base, state) => ({
          ...base,
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "0px 2px 24px 0px rgba(0, 0, 0, 0.15)",
          padding: "5px",
          zIndex: 2,
          width: "100%",
          fontSize: "12px",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          "&:hover": { color: "rgb(161, 0, 255)" },
          color: "rgb(161, 0, 255)", // Custom colour
          width: "32px",
          display: window.location.pathname.toLowerCase().includes("/dataingestion/configureprogram") && this.props.isDisabled ? 'none' : 'block',
        }),
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "rgb(250, 245, 253)",
            borderRadius: "16px",
            border: "1px solid rgb(161, 0, 255)",
            height: "24px",
            width: "79px",
            padding: "0 0px 0 10px",
            display: "flex",
            alignItems: "center",
            margin: "3px 4px",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "rgb(161, 0, 255)",
          fontSize: "12px",
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          ":hover": {},
        }),
      };

      return (
        <div className="multi-select-dropdown">
          <div className="multi-select-label">{this.props.label}</div>
          <Select
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
            isMulti={true}
            isSearchable={false}
            options={this.props.options}
            styles={styles}
            placeholder={this.props.placeholder}
            components={{
              Option,
              IndicatorSeparator: () => null,
            }}
            onChange={this.props.onChange}
            value={this.props.value}
            isDisabled={this.props.isDisabled}
          />
        </div>
      );
    }
  }

  class CVMMutiSelectDropdownContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: [],
      };
    }

    componentDidMount = () => {
      this.setState({
        value: this.props.value,
      });
    };

    componentDidUpdate = (nextProps) => {
      if (nextProps.value !== this.props.value) {
        this.setState({
          value: this.props.value,
        });
      }
    };

    onChange = (e, selected) => {
      this.setState(
        {
          value: e,
        },
        () => {
          this.props.onChange(e, this.props.id, selected);
        }
      );
    };

    render() {
      return (
        <CVMMutiSelectDropdownPresentational
          label={this.props.label}
          placeholder={this.props.placeholder}
          options={this.props.options}
          onChange={this.onChange}
          value={this.state.value}
          isDisabled={this.props.isDisabled}
        />
      );
    }
  }

  return CVMMutiSelectDropdownContainer;
})();

export default CVMMutiSelectDropdown;
