import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Close from '@material-ui/icons/Close';

import { HttpPost, HttpGet } from '../../../Utilities/HTTPUtil';

import {
  GrooveTabComponent,
  GrooveIconSvg,
  GrooveSelectMenuBasic,
  GrooveTextBox,
} from 'tfo-groove';

import './ContactUs.css';
import { IsValidText } from '../../../Utilities/Validations';

const MAX_COUNT = 3;

const SUPPOFTED_FILE_FORMATS = [
  '.png',
  '.jpeg',
  '.docx',
  '.xlsx',
  '.csv',
  '.pptx',
  '.pdf',
  '.txt',
  '.eml',
  '.msg',
];

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const ERROR_MESSAGES = {
  FILE_SIZE_EXCEEDED: 'File size exceeds 5 MB.',
  FILE_FORMAT_NOT_SUPPORTED: 'File format not supported.',
  MAX_COUNT_EXCEEDED: 'A maximum of 3 files can be attached.',
};



const TAB_NAMES = {
  ACCESS: 1,
  ISSUE: 2,
  FEEDBACK: 3,
};

const SUBMIT_SUCCESS_MESSAGE = {
  [TAB_NAMES.ACCESS]: "Request submitted successfully",
  [TAB_NAMES.ISSUE]: "Request submitted successfully",
  [TAB_NAMES.FEEDBACK]:"Feedback submitted successfully",
};

const selectedClientInit = {
  value: 0,
  key: 0,
  text: '',
  label: '',
};

const GUIDE_TEXT = 'Please select Accenture, if your client is not listed in drop down';

const ACCESS_PLACEHOLDER_TEXT =
  'Please provide the enterprise id, user profile and purpose of access';

const ISSUE_PLACEHOLDER_TEXT = 'Issue description';

const FEEDBACK_PLACEHOLDER_TEXT = 'Provide your feedback';

const ContactUs = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.defaultTab || TAB_NAMES.ACCESS);
  const [selectedClientAccess, setSelectedClientAccess] = useState(selectedClientInit);
  const [selectedClientIssue, setSelectedClientIssue] = useState(selectedClientInit);
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [accessDescription, setAccessDescription] = useState('');
  const [uploadedFeedbackFiles, setUploadedFeedbackFiles] = useState([]);
  const [uploadedIssueFiles, setUploadedIssueFiles] = useState([]);
  const [uploadedAccessFiles, setUploadedAccessFiles] = useState([]);
  const [errorFeedback, setErrorFeedback] = useState('');
  const [errorIssue, setErrorIssue] = useState('');
  const [errorAccess, setErrorAccess] = useState('');
  const [descError, setDescError] = useState({
    feedback: false,
    issue: false,
    access: false,
  })
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [allClients, setAllClients] = useState([{}]);

  const inputFileAccessRef = useRef(null);
  const inputFileFeedbackRef = useRef(null);
  const inputFileIssueRef = useRef(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await HttpGet('ClientConfiguration/GetClients');
        
        if (response) {
          const clients = response.map((client) => {
            return {
              value: client.ClientMasterName,
              key: client.ClientMasterName,
              text: client.ClientMasterName,
              label: client.ClientMasterName,
            };
          });
          setAllClients(clients);
        }
      } catch (error) {
        console.log('Error in fetching clients: ', error);
      }
    };
    fetchClients();
  }, []);


  const handleDropdownChangeAccess = (e) => {
    setSelectedClientAccess(e);
  };

  const handleDropdownChangeIssue = (e) => {
    setSelectedClientIssue(e);
  };

  const setError = (tabname, error) => {
    if (tabname === TAB_NAMES.FEEDBACK) setErrorFeedback(error);
    else if (tabname === TAB_NAMES.ISSUE) setErrorIssue(error);
    else if (tabname === TAB_NAMES.ACCESS) setErrorAccess(error);
  };

  const handleUploadFiles = (files, tabname) => {
    let uploaded = [];
    if (tabname === TAB_NAMES.FEEDBACK) {
      uploaded = [...uploadedFeedbackFiles];
    } else if (tabname === TAB_NAMES.ISSUE) {
      uploaded = [...uploadedIssueFiles];
    } else if (tabname === TAB_NAMES.ACCESS) {
      uploaded = [...uploadedAccessFiles];
    }

    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length > MAX_COUNT) {
          setError(tabname, ERROR_MESSAGES.MAX_COUNT_EXCEEDED);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      if (tabname === TAB_NAMES.FEEDBACK) setUploadedFeedbackFiles(uploaded);
      else if (tabname === TAB_NAMES.ISSUE) setUploadedIssueFiles(uploaded);
      else if (tabname === TAB_NAMES.ACCESS) setUploadedAccessFiles(uploaded);
    }
  };

  const handleFileEvent = (e, tabname) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setErrorFeedback('');
    setErrorIssue('');
    setErrorAccess('');
    let isError = false;
    chosenFiles.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
      if (SUPPOFTED_FILE_FORMATS.some((format) => fileExtension.endsWith(format))) {
        // File format is supported
        if (file.size <= MAX_FILE_SIZE) {
          // File size is less than or equal to 5 MB
          if (!isError) handleUploadFiles(chosenFiles, tabname);
        } else {
          // File size is greater than 5 MB
          setError(tabname, ERROR_MESSAGES.FILE_SIZE_EXCEEDED);
          isError = true;
        }
      } else {
        // File format is not supported
        setError(tabname, ERROR_MESSAGES.FILE_FORMAT_NOT_SUPPORTED);
        isError = true;
      }
    });
    e.target.value = null;
  };

  const handleDeleteFile = (file, tabname) => {
    const feedbackFiles = [...uploadedFeedbackFiles];
    const issueFiles = [...uploadedIssueFiles];
    const accessFiles = [...uploadedAccessFiles];
    const feedbackIndex = feedbackFiles.findIndex((f) => f.name === file.name);
    const issueIndex = issueFiles.findIndex((f) => f.name === file.name);
    const accessIndex = accessFiles.findIndex((f) => f.name === file.name);
    if (tabname === TAB_NAMES.FEEDBACK) {
      feedbackFiles.splice(feedbackIndex, 1);
      setUploadedFeedbackFiles(feedbackFiles);
    } else if (tabname === TAB_NAMES.ISSUE) {
      issueFiles.splice(issueIndex, 1);
      setUploadedIssueFiles(issueFiles);
    } else if (tabname === TAB_NAMES.ACCESS) {
      accessFiles.splice(accessIndex, 1);
      setUploadedAccessFiles(accessFiles);
    }
  };

  const clearAllStates = () => {
    setSelectedClientAccess(selectedClientInit);
    setSelectedClientIssue(selectedClientInit);
    setFeedbackDescription('');
    setIssueDescription('');
    setAccessDescription('');
    setUploadedFeedbackFiles([]);
    setUploadedIssueFiles([]);
    setUploadedAccessFiles([]);
    setSelectedTab(TAB_NAMES.ACCESS);
    setErrorFeedback('');
    setErrorIssue('');
    setErrorAccess('');
    setDescError({
      feedback: false,
      issue: false,
      access: false,
    });
    setShowSuccessMessage(false);
  };

  let isSubmitDisabled = true;

  if (selectedTab === TAB_NAMES.FEEDBACK) {
    isSubmitDisabled = !feedbackDescription || descError.feedback;
  } else if (selectedTab === TAB_NAMES.ISSUE) {
    isSubmitDisabled = !selectedClientIssue.value || !issueDescription || descError.issue;
  } else if (selectedTab === TAB_NAMES.ACCESS) {
    isSubmitDisabled = !selectedClientAccess.value || !accessDescription || descError.access;
  }

  const handleSubmit = async () => {
    let sender = props.userInformation.enterpriseID;
    setShowLoader(true);
    let subject = '';
    if (selectedTab === TAB_NAMES.FEEDBACK) {
      subject = `Feedback Provided for SVM: ${sender}`;
    } else if (selectedTab === TAB_NAMES.ISSUE) {
      subject = `Issue Reported in SVM: ${sender}`;
    } else if (selectedTab === TAB_NAMES.ACCESS) {
      subject = `Access Request for SVM: ${sender}`;
    }

    let body = '';
    if (selectedTab === TAB_NAMES.FEEDBACK) {
      body = feedbackDescription;
    } else if (selectedTab === TAB_NAMES.ISSUE) {
      body = issueDescription;
    } else if (selectedTab === TAB_NAMES.ACCESS) {
      body = accessDescription;
    }

    let event = '';
    if (selectedTab === TAB_NAMES.FEEDBACK) {
      event = 'Feedback';
    } else if (selectedTab === TAB_NAMES.ISSUE) {
      event = 'Issue';
    } else if (selectedTab === TAB_NAMES.ACCESS) {
      event = 'Access';
    }

    let client = '';
    if (selectedTab === TAB_NAMES.FEEDBACK) {
      client = '';
    } else if (selectedTab === TAB_NAMES.ISSUE) {
      client = selectedClientIssue.label;
    } else if (selectedTab === TAB_NAMES.ACCESS) {
      client = selectedClientAccess.label;
    }

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
      });

    let base64StringsAccess = await Promise.all(
      uploadedAccessFiles.map(async (file) => {
        return { fileName: file.name, base64String: await toBase64(file) };
      })
    );
    let base64StringsIssue = await Promise.all(
      uploadedIssueFiles.map(async (file) => {
        return { fileName: file.name, base64String: await toBase64(file) };
      })
    );
    let base64StringsFeedback = await Promise.all(
      uploadedFeedbackFiles.map(async (file) => {
        return { fileName: file.name, base64String: await toBase64(file) };
      })
    );

    let attachments = [];
    if (selectedTab === TAB_NAMES.FEEDBACK) {
      attachments = base64StringsFeedback;
    } else if (selectedTab === TAB_NAMES.ISSUE) {
      attachments = base64StringsIssue;
    } else if (selectedTab === TAB_NAMES.ACCESS) {
      attachments = base64StringsAccess;
    }

    const request = {
      mailSubject: subject,
      mailBody: body,
      eventType: event,
      notificationDescription: body,
      sender: '',
      client: client,
      attachments: attachments,
    };

    try {
      await  HttpPost('EmailService/SendMailForContactSupport', request);
      setTimeout(() => {
        props.closeContactSupportModal();
        clearAllStates();
      }, 2000);
      setShowSuccessMessage(true);
      setShowLoader(false);
    } catch (error) {
      console.log('Error in sending mail: ', error);
      setShowLoader(false);
    }
  };

  const tab_data = [
    {
      tab_label: 'Request Access',
      tab_value: TAB_NAMES.ACCESS,
      tab_id: TAB_NAMES.ACCESS,
      tab_content: (
        <div>
          <Row>
            <Col className="pl-0">
              <GrooveSelectMenuBasic
                defaultData={selectedClientAccess}
                callbackFunction={handleDropdownChangeAccess}
                list={allClients}
                placeholder="Select Client*"
                customClassName="client-dropdown"
                contentType={'search'}
              />
            </Col>
            <Col className="pl-0">
              <div className="support-text">{GUIDE_TEXT}</div>
            </Col>
          </Row>

          <textarea
              className={`access-issue-input ${descError.access ? 'contact-us-textarea-error' : ''}`}
              name="comment"
              placeholder={ACCESS_PLACEHOLDER_TEXT + '*'}
              onChange={(e) => {
                setAccessDescription(e.target.value);
                if (!IsValidText(e.target.value)) {
                  setDescError({
                    ...descError, access: true
                  });
                } else {
                  setDescError({
                    ...descError, access: false
                  });
                }
              }}
              value={accessDescription}
              maxLength={500}
            />
            
            {descError.access && <div className="contact-us-error">Please enter valid text</div>}
         
          <input
            id="fileUpload"
            type="file"
            multiple
            onChange={(e) => handleFileEvent(e, TAB_NAMES.ACCESS)}
            hidden
            ref={inputFileAccessRef}
          />
          <div
            width="485px"
            height="50px"
            display="flex"
            className="Grid__StyledDiv-sc-9k217a-0 hAXbWy mt-3"
          >
            <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 cFOIUY">
              <AddCircleOutline
                className="cursor css-fbkt3g"
                onClick={() => inputFileAccessRef.current.click()}
              />

              <div className="Grid__StyledDiv-sc-9k217a-0 la-dTuR">
                <div font-size="12px" className="Grid__StyledDiv-sc-9k217a-0 dAhoyD">
                  Add attachments
                </div>
                <div
                  font-size="10px"
                  color="#919EAB"
                  className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                >
                  5 MB per file
                </div>
                <div
                  font-size="10px"
                  color="#919EAB"
                  className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                >
                  Supported file formats:&nbsp;
                  {SUPPOFTED_FILE_FORMATS.join(', ')}
                </div>
              </div>
            </div>
            <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 bRDpfN">
              {uploadedAccessFiles.map((file) => (
                <div class="Grid__StyledDiv-sc-9k217a-0 cGODdJ">
                  <div display="flex" class="Grid__StyledDiv-sc-9k217a-0 fJlQtE">
                    <span class="sc-fWCJzd jzuRnk">{file.name}</span>

                    <Close
                      className="cursor"
                      onClick={() => handleDeleteFile(file, TAB_NAMES.ACCESS)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="contact-us-error">{errorAccess}</div>
        </div>
      ),
    },
    {
      tab_label: 'Report an Issue',
      tab_value: TAB_NAMES.ISSUE,
      tab_id: TAB_NAMES.ISSUE,
      tab_content: (
        <div>
          <Row>
            <Col className="pl-0">
              <GrooveSelectMenuBasic
                defaultData={selectedClientIssue}
                selectedValue={selectedClientIssue}
                callbackFunction={handleDropdownChangeIssue}
                list={allClients}
                placeholder="Select Client*"
                customClassName="client-dropdown"
                contentType={'search'}
              />
            </Col>
            <Col className="pl-0">
              <div className="support-text">{GUIDE_TEXT}</div>
            </Col>
          </Row>
          
            <textarea
              className={`access-issue-input ${descError.issue ? 'contact-us-textarea-error' : ''}`}
              name="comment"
              placeholder={ISSUE_PLACEHOLDER_TEXT + '*'}
              onChange={(e) => {
                setIssueDescription(e.target.value);
                if (!IsValidText(e.target.value)) {
                  setDescError({
                    ...descError, issue: true
                  });
                } else {
                  setDescError({
                    ...descError, issue: false
                  });
                }
              }}
              value={issueDescription}
              maxLength={500}
            />

            {descError.issue && <div className="contact-us-error">Please enter valid text</div>}
         

          <input
            id="fileUpload"
            type="file"
            multiple
            onChange={(e) => handleFileEvent(e, TAB_NAMES.ISSUE)}
            hidden
            ref={inputFileIssueRef}
          />
          <div
            width="485px"
            height="50px"
            display="flex"
            className="Grid__StyledDiv-sc-9k217a-0 hAXbWy mt-3"
          >
            <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 cFOIUY">
              <AddCircleOutline
                className="cursor css-fbkt3g"
                onClick={() => inputFileIssueRef.current.click()}
              />

              <div className="Grid__StyledDiv-sc-9k217a-0 la-dTuR">
                <div font-size="12px" className="Grid__StyledDiv-sc-9k217a-0 dAhoyD">
                  Add attachments
                </div>
                <div
                  font-size="10px"
                  color="#919EAB"
                  className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                >
                  5 MB per file
                </div>
                <div
                  font-size="10px"
                  color="#919EAB"
                  className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                >
                  Supported file formats:&nbsp;
                  {SUPPOFTED_FILE_FORMATS.join(', ')}
                </div>
              </div>
            </div>
            <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 bRDpfN">
              {uploadedIssueFiles.map((file) => (
                <div class="Grid__StyledDiv-sc-9k217a-0 cGODdJ">
                  <div display="flex" class="Grid__StyledDiv-sc-9k217a-0 fJlQtE">
                    <span class="sc-fWCJzd jzuRnk">{file.name}</span>

                    <Close
                      className="cursor"
                      onClick={() => handleDeleteFile(file, TAB_NAMES.ISSUE)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="contact-us-error">{errorIssue}</div>
        </div>
      ),
    },
    {
      tab_label: 'Provide Feedback',
      tab_value: TAB_NAMES.FEEDBACK,
      tab_id: TAB_NAMES.FEEDBACK,
      tab_content: (
        <div>
          <textarea
              className={`feedback-input ${descError.feedback ? 'contact-us-textarea-error' : ''}`}
              name="comment"
              placeholder={FEEDBACK_PLACEHOLDER_TEXT + '*'}
              onChange={(e) => {
                setFeedbackDescription(e.target.value);
                if (!IsValidText(e.target.value)) {
                  setDescError({
                    ...descError, feedback: true
                  });
                } else {
                  setDescError({
                    ...descError, feedback: false
                  });
                }
              }}
              value={feedbackDescription}
              maxLength={500}
            />
            {descError.feedback && <div className="contact-us-error">Please enter valid text</div>}
          <input
            id="fileUpload"
            type="file"
            multiple
            onChange={(e) => handleFileEvent(e, TAB_NAMES.FEEDBACK)}
            hidden
            ref={inputFileFeedbackRef}
          />
          <div
            width="485px"
            height="50px"
            display="flex"
            className="Grid__StyledDiv-sc-9k217a-0 hAXbWy mt-3"
          >
            <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 cFOIUY">
              <AddCircleOutline
                className="cursor css-fbkt3g"
                onClick={() => inputFileFeedbackRef.current.click()}
              />

              <div className="Grid__StyledDiv-sc-9k217a-0 la-dTuR">
                <div font-size="12px" className="Grid__StyledDiv-sc-9k217a-0 dAhoyD">
                  Add attachments
                </div>
                <div
                  font-size="10px"
                  color="#919EAB"
                  className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                >
                  5 MB per file
                </div>
                <div
                  font-size="10px"
                  color="#919EAB"
                  className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                >
                  Supported file formats:&nbsp;
                  {SUPPOFTED_FILE_FORMATS.join(', ')}
                </div>
              </div>
            </div>
            <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 bRDpfN">
              {uploadedFeedbackFiles.map((file) => (
                <div class="Grid__StyledDiv-sc-9k217a-0 cGODdJ">
                  <div display="flex" class="Grid__StyledDiv-sc-9k217a-0 fJlQtE">
                    <span class="sc-fWCJzd jzuRnk">{file.name}</span>

                    <Close
                      className="cursor"
                      onClick={() => handleDeleteFile(file, TAB_NAMES.FEEDBACK)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="contact-us-error">{errorFeedback}</div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Modal show={props.open} className="feedbackFormModal" backdrop="static">
        <Modal.Header>
          <Modal.Title>
            <div className="contact-us-header">Contact Us</div>
            <GrooveIconSvg
              customClassName="close-modal-btn"
              size="large"
              name="times"
              iconStyle="solid"
              iconColor="white"
              callbackFunction={() => {
                if (!showLoader) {
                  props.closeContactSupportModal();
                  clearAllStates();
                }
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '0px', paddingBottom: '0px' }}></div>
          <div className="support-modal-body">
            <GrooveTabComponent
              theme="purple"
              tab_data={tab_data}
              selectedIndex={selectedTab - 1}
              callbackFunction={(e) => setSelectedTab(e.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="success-message">{showSuccessMessage ? SUBMIT_SUCCESS_MESSAGE[selectedTab] : ""}</p>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitDisabled || showLoader || showSuccessMessage}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInformation: state.userInformation
  };
}

export default connect(mapStateToProps)(ContactUs);
