import {
  GET_PROGRAM_SPECIFICBOIHISTORY,
  GET_PROJECT_SPECIFICBOIHISTORY,
  GET_PROGRAM_BOIHISTORY,
  GET_PROJECT_BOIHISTORY,
} from '../Constants';
import { HttpGet, GetSubMenuId } from '../Utilities/HTTPUtil';
import { HasProperty } from '../Utilities/Validations';
import { FormDateDDMMMYYY, FormTimeAPM } from '../Utilities/Formatter';

const convertLocal = (datetime) => {
  return FormDateDDMMMYYY(datetime, true) + ' ' + FormTimeAPM(datetime, true);
};

const mapHistory = (dimensionList) => {
  let convertList = [];

  dimensionList.map((dimension) => {
    let convertSubDimList = [];
    dimension.SubDimensionList.map((subDimension) => {
      let convertBOIList = [];
      subDimension.boiDataList.map((boi) => {
        let newBOI = boi;
        if (HasProperty(boi, 'RejectedOn')) {
          newBOI.RejectedOn = convertLocal(boi.RejectedOn);
        }
        if (HasProperty(boi, 'ModifiedOn')) {
          newBOI.ModifiedOn = convertLocal(boi.ModifiedOn);
        }
        if (HasProperty(boi, 'DeletedOn')) {
          newBOI.DeletedOn = convertLocal(boi.DeletedOn);
        }

        if (HasProperty(newBOI, 'Value')) {
          if (newBOI.Value !== null) {
            if (newBOI.Value.includes('.00')) {
              newBOI.Value = newBOI.Value.replace(".00", "");
            }
          }
        }
        if (HasProperty(newBOI, 'OldValue')) {
          if (newBOI.OldValue !== null) {
            if (newBOI.OldValue.includes('.00')) {
              newBOI.OldValue = newBOI.OldValue.replace(".00", "");
            }
          }
        }
        if (HasProperty(newBOI, 'ModifiedValue')) {
          if (newBOI.ModifiedValue !== null) {
            if (newBOI.ModifiedValue.includes('.00')) {
              newBOI.ModifiedValue = newBOI.ModifiedValue.replace(".00", "");
            }
          }
        }

        convertBOIList.push(newBOI);
      });
      convertSubDimList.push({
        SubDimension: subDimension.SubDimension,
        boiDataList: convertBOIList,
      });
    });

    convertList.push({
      Dimension: dimension.Dimension,
      SubDimensionList: convertSubDimList,
    });
  });

  return convertList;
};

export const getProgramBOIHistory = (programID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
    let data = await HttpGet(`ProgramConfiguration/ProgramBOIHistory?ProgramID=${programID}&SubMenuID=${subMenuId}`);
    // let data = ProgramBOIHistoryTest

    dispatch({
      type: GET_PROGRAM_BOIHISTORY,
      programBOIHistory: {
        rejectedBOIHistories: mapHistory(data.rejectedBOIHistories),
        modifiedBOIHistories: mapHistory(data.modifiedBOIHistories),
        deletedBOIHistories: mapHistory(data.deletedBOIHistories),
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProjectBOIHistory = (projectID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let data = await HttpGet(`ProjectConfiguration/ProjectBOIHistory?ProjectID=${projectID}&SubMenuID=${subMenuId}`);
    // let data = ProjectBOIHistoryTest
    dispatch({
      type: GET_PROJECT_BOIHISTORY,
      projectBOIHistory: {
        rejectedBOIHistories: mapHistory(data.rejectedBOIHistories),
        modifiedBOIHistories: mapHistory(data.modifiedBOIHistories),
        deletedBOIHistories: mapHistory(data.deletedBOIHistories),
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProgramSpecificBOIHistory = (program) => async (dispatch, state) => {
  try {
    const clientProgramID = program.clientProgramID;
    const dimensionID = program.dimensionID;
    const subDimensionID = program.subDimensionID;
    const boiID = program.boiID;
    const clientProgramTargetID = program.clientProgramTargetID;
    let uom = encodeURIComponent(program.uom);
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
    let data = await HttpGet(
      `ProgramConfiguration/GetBOILevelProgramHistory?ClientProgramTargetID=${clientProgramTargetID}&ProgramID=${clientProgramID}&BOIID=${boiID}&SubDimensionID=${subDimensionID}&DimensionID=${dimensionID}&UOM=${uom}&SubMenuID=${subMenuId}`
    );
    dispatch({
      type: GET_PROGRAM_SPECIFICBOIHISTORY,
      programSpecificBOIHistory: data.boiLevelHistories,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProjectSpecificBOIHistory = (project) => async (dispatch, state) => {
  try {
    const uom = encodeURIComponent(project.uom);
    const { clientProjectID, dimensionID, subDimensionID, boiID, clientProjectTargetID } = project;
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let data = await HttpGet(
      `ProjectConfiguration/GetBOILevelProjectHistory?ProjectID=${clientProjectID}&BOIID=${boiID}&SubDimensionID=${subDimensionID}&DimensionID=${dimensionID}&UOM=${uom}&ClientProjectTargetID=${clientProjectTargetID}&SubMenuID=${subMenuId}`
    );
    dispatch({
      type: GET_PROJECT_SPECIFICBOIHISTORY,
      projectSpecificBOIHistory: data.boiLevelHistories,
    });
  } catch (err) {
    console.log(err);
  }
};
