import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import clientDetailIcon from '../../Static/images/newClientConfig/clientDetailIcon.svg';
import angleUp from '../../Static/images/svg/angle-up.svg';

const DashboardClientDetails = (function () {
  class DashboardClientDetailsPresentational extends Component {
    render() {
      
      const clientDetailsPopover = (
        <Popover id="clientDetails-popover" trigger="focus">
          <Popover.Title>
            <span className="close-btn" onClick={() => document.body.click()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </Popover.Title>
          <Popover.Content>
            <div>
              <div className="sub-section">
                <div className="detail-popup-section">
                  <div className="section-title">Client Name</div>
                  <div className="section-desc">
                  {this.props.dashboardClientDetails && this.props.dashboardClientDetails.ClientName}
                  </div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Industry</div>
                  <div className="section-desc">{this.props.dashboardClientDetails.Industry}</div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Market Unit</div>
                  <div className="section-desc">
                  {this.props.dashboardClientDetails && this.props.dashboardClientDetails.MarketUnit}
                  </div>
                </div>
              </div>
              <div className="sub-section">
                <div className="detail-popup-section">
                  <div className="section-title">Sub-Market Unit</div>
                  <div className="section-desc">
                  {this.props.dashboardClientDetails && this.props.dashboardClientDetails.SubMarketUnit}
                  </div>
                </div>
                <div className="detail-popup-section">
                  <div className="section-title">Client Type</div>
                  <div className="section-desc">
                    {this.props.dashboardClientDetails && this.props.dashboardClientDetails.ClientType}
                  </div>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );
      return(
        <>
          <div className={`dashboard-client-details ${this.props.isKnowMore ? 'margin-left' : ''}`}>
            <div className="client-details-button">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="right-start"
                overlay={clientDetailsPopover}
              >
                <div>
                  <img src={clientDetailIcon} alt="clientDetailIcon" />
                  {this.props.selectedClient.label !== "" && this.props.selectedClient.label}
                </div>
              </OverlayTrigger>
            </div>
            <div className="client-stage-details">
              <OverlayTrigger
                rootClose
                placement="bottom"
                overlay={<Tooltip id="client-stage-defination">{this.props.dashboardClientDetails && this.props.dashboardClientDetails.StageDescription}</Tooltip>}
              >
                <img src={angleUp} alt="angleUp" />
              </OverlayTrigger>
              &nbsp;<span className="client-stage-label">{this.props.dashboardClientDetails && this.props.dashboardClientDetails.Stage}</span>&nbsp;<span className="word-divider">|</span>&nbsp;<span>{this.props.dashboardClientDetails && this.props.dashboardClientDetails.StageName}</span> 
            </div>
          </div>
        </>
      );
    }
  }

  class DashboardClientDetailsContainer extends Component {
    constructor() {
      super();
      this.state = {
      }
    }

    render() {
      const {selectedClient} = this.props.SharedData
      const {dashboardClientDetails} = this.props.DashboardData
      return (
        <DashboardClientDetailsPresentational
        selectedClient={selectedClient}
        dashboardClientDetails={dashboardClientDetails}
        isKnowMore={this.props.isKnowMore}
        />
      );
    }
  }

  return DashboardClientDetailsContainer;
})();

export default connect(
  state => {
    return {
      SharedData: state.SharedData,
      DashboardData: state.DashboardData,
    };
  },
  dispatch =>
    bindActionCreators(
      Object.assign(
        {},
        {
        }
      ),
      dispatch
    )
)(DashboardClientDetails);
