import React, { Component } from "react";
import { FormGroup, FormLabel, FormControl, Button } from "react-bootstrap";
import Utilities from "../../Utilities";
import AuthenticatedRoute from "./authenticated-route";
import "./Authentication.css";

class Authentication extends Component {
  constructor(props) {
    super();
    this.state = {
      userName: "",
    };
  }

  onChangeUserName = (event) => {
    this.setState({ userName: event.target.value });
  };

  onClickLogin = (event) => {
    this.props.changeHasAccess(this.state.userName);
    Utilities.setSessionStorage("access_token", this.state.userName);
  };

  render() {
    return (
      <div className="full-container">
        <FormGroup
          controlId="authentication-form-group"
          className="authentication-container"
        >
          <FormLabel>Enterprise ID:</FormLabel>
          <FormControl
            type="text"
            placeholder="Enterprise ID"
            onChange={this.onChangeUserName}
            value={this.state.userName}
          />
          <Button
            bsStyle="primary"
            className="authentication-button"
            onClick={this.onClickLogin}
          >
            Login
          </Button>
        </FormGroup>
      </div>
    );
  }
}

export default class DeveloperLogin extends Component {
  constructor() {
    super();
    this.state = {
      hasAccess: false,
    };
  }

  async componentWillMount() {
    let token = await Utilities.getToken();
    this.changeHasAccess(token);
  }

  changeHasAccess = (token) => {
    if (token !== null && token !== "") {
      this.setState({ hasAccess: true });
    }
  };

  render() {
    if (this.state.hasAccess) {
      return this.props.children;
    } else {
      return <Authentication changeHasAccess={this.changeHasAccess} />;
    }
  }
}

export { AuthenticatedRoute };
