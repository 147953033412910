import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { history } from '../../../store';
import { withRouter } from "react-router-dom";
import { ACC_PROG, ACC_PROJ, PROJ_SEL, REP_PROJ_DATA } from '../../../Constants/Modules';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrooveButton } from 'tfo-groove';
import { LogUserSiteAction } from '../../../Utilities/AccessManagementUtil';
import { HasProperty } from '../../../Utilities/Validations';

//actions
import {
  getProgramProject,
  onChangeSelectProject,
  onSaveSelectProject,
  onCancelSelectProject,
  clearMainSelectedDetails,
  getProgramProjectCreationYear,
  getMappedStakeHolders,
  getAggregatedDisabledBOIs,
} from '../../../Actions/ConfigureProgram.Action';
import {
  getProjectDetails,
  setProgramSelected,
  setProjectSelected,
  getProjectDataEntryTableData,
} from '../../../Actions/DataIngestion.Action';

//components
import { IsValid } from '../../../Utilities/Validations';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import CVMToasterNotif from '../../Shared/CVMToasterNotif/CVMToasterNotif';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import CVMSelectDropdown from '../../Shared/CVMSelectDropdown/CVMSelectDropdown';
import NewProjectDataEntry from '../Modals/NewProjectDataEntry/NewProjectDataEntry';
import ProjectDataEntryTable from './ProjectDataEntryTable';
import ViewAggregatedBOIDetails from './ViewAggregatedBOIDetails';

// icons
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddFileIcon from '../../../Static/images/360cvm/AddFilesIcon.svg';
import clientDetailIcon from '../../../Static/images/newClientConfig/clientDetailIcon.svg';
import DraftingCompassNew from '../../../Static/images/360cvm/DraftingCompassNew.svg';

//css
import './ProjectDataEntryList.css';

const ProjectDataEntryList = (function () {
  class ProjectDataEntryListPresentational extends Component {
    render() {
      let clientID = this.props.clientID;
      let user = this.props.userInformation.userProfiles.find((x) => x.ClientMasterId === clientID);

      let isPPC = this.props.currentUserRoleID === 12 ||
        (this.props.currentUserRoleID === 16 && this.props.isExistingPPC);
      let isBA = this.props.currentUserRoleID === 15 ||
        (this.props.currentUserRoleID === 16 && this.props.isExistingBA);

      const selectedProgram = HasProperty(this.props.selectedProgram, 'value')
        ? this.props.selectedProgram.value
        : '';
      const selectedProject = HasProperty(this.props.selectedProject, 'value')
        ? this.props.selectedProject.value
        : '';
      const isProjSysGen = IsValid(selectedProject) ? this.props.projectOptions.filter((x) => x.value === selectedProject)[0].createdBy.toLowerCase() === 'system generated' : false
      const isRejProjSysGen = IsValid(selectedProject) ? this.props.projectOptions.filter((x) => x.value === selectedProject)[0].systemGeneratedStatus.toLowerCase() === 'rejected' : false
      const redirectionLink = `/ProgramProjectConfig/${this.props.rdvClientDetails.clientMasterName}/${selectedProgram}/${selectedProject}`;
      let aggregatedDisabledBOIs = this.props.aggregatedDisabledBOIs
        ? this.props.aggregatedDisabledBOIs
        : [];
      const disabledBOIPopover = (
        <Popover id="aggregated-boi-popover" trigger="focus">
          <Popover.Content>
            <div>
              <ViewAggregatedBOIDetails aggregatedDisabledBOIs={aggregatedDisabledBOIs} />
            </div>
          </Popover.Content>
        </Popover>
      );

      return (
        <>
          <div className="projectEntry-container">
            <CVMToasterNotif />
            <div className="title-section">
              <h4 className="header24-semibold-midnight">Project Data Entry</h4>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  this.props.onCloseProjectDataEntry();
                }}
              />
            </div>
            <div className="aggregated-boi-popover">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="auto"
                overlay={disabledBOIPopover}
              >
                <div>
                  <img src={clientDetailIcon} alt="clientDetailIcon" />
                  View aggregated details of all BOIs
                </div>
              </OverlayTrigger>
            </div>
            <div className="row new-pde">
              <div className="applyFlex-align-center apply-relative col-md-12 col-lg-4">
                <CVMSelectDropdown
                  label="Program : "
                  placeholder={''}
                  style={{
                    control: { width: '120px' },
                    option: { width: computeMaxWidths(this.props.programOptions, 420) },
                    menu: { width: 'inherit' },
                    mainContainer: {
                      width: 'inherit',
                      margin: '0 0 0 0',
                      float: 'right',
                    },
                  }}
                  options={this.props.programOptions}
                  value={this.props.selectedProgram}
                  onChange={(e) => {
                    this.props.handleSelectProgram(e);
                  }}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                />
              </div>
              <div className="applyFlex-align-center apply-relative col-md-8 col-lg-4 pde-project-dropdown">
                <CVMSelectDropdown
                  label="Project : "
                  placeholder={''}
                  style={{
                    control: { width: '125px' },
                    option: { width: computeMaxWidths(this.props.projectOptions, 420) },
                    menu: { width: 'inherit' },
                    mainContainer: {
                      width: 'inherit',
                      margin: '0 0 0 0',
                      float: 'right',
                    },
                  }}
                  options={this.props.projectOptions}
                  value={this.props.selectedProject}
                  isSearchable={true}
                  onChange={(e) => {
                    this.props.handleSelectProject(e);
                  }}
                  closeMenuOnSelect={false}
                />
              </div>
              <div className="new-proj-btn col-md-4 col-lg-4">
                <GrooveButton
                  id="primry-btn-2"
                  hasIcon={true}
                  type="solid"
                  colorClass="stat-alternate"
                  text="New Project Data Entry"
                  customClassName="add-project-button rvd-proj-btn"
                  iconAlignment="right"
                  iconSize="small"
                  iconName="plus"
                  iconStyle="solid"
                  iconId="btn-icon-01"
                  callbackFunction={(e) => {
                    this.props.onCloseSelectProjectModal('select');
                  }}
                  isDisabled={
                    (this.props.isSuperAdmin || isPPC || isBA) &&
                      Object.keys(this.props.selectedProject).length > 0 && (!isProjSysGen || !isRejProjSysGen)
                      ? false
                      : true
                  }
                />
              </div>
              {(this.props.isSuperAdmin || isPPC || isBA) &&
                Object.keys(this.props.selectedProject).length > 0 && (
                  <div
                    style={{
                      color: 'rgb(99, 115, 129)',
                      fontFamily: 'Graphik-Regular',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      height: '16px',
                      letterSpacing: '-0.25px',
                      width: '100%',
                      padding: '1em',
                    }}
                  >
                    <img
                      src={DraftingCompassNew}
                      alt="PPC_Link"
                      style={{ height: '16px', width: '16px' }}
                    />{' '}
                    Go back to{' '}
                    <span
                      onClick={() => this.props.handleRedirect(redirectionLink)}
                      style={{ color: '#A100FF', cursor: 'pointer' }}
                    >
                      Project Configuration
                    </span>{' '}
                    Module
                  </div>
                )}
            </div>
            {Object.keys(this.props.selectedProject).length > 0 ? (
              this.props.showRoller ? (
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <ProjectDataEntryTable />
              )
            ) : (
              <div className="empty-program-container bodytext18-regular-slate">
                <img src={AddFileIcon} alt="select client" />
                <span className="bodytext24-medium-slate">No Project Selected</span>
                Select Project to display project relevant content.
              </div>
            )}
          </div>

          {/* MODALS */}
          <CVMModal
            title={''}
            show={this.props.showProjectDataEntry}
            onCloseModal={this.props.onCloseProjectDataEntry}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                handleCancel={this.props.onCloseProjectDataEntry}
                handleYes={this.props.closeProject}
                body={
                  <div>
                    <h5>Are you sure you want to close Project Data Entry List?</h5>

                    <p>Please note that this would not save your changes. </p>
                  </div>
                }
              />
            }
          />
          <CVMModal
            title={'New Project Data Entry'}
            show={this.props.showSelectProjectModal}
            onCloseModal={this.props.onCloseSelectProjectModal}
            customclass={'select-project-modal rvd-new-project-data-entry-modal'}
            content={
              <NewProjectDataEntry
                getProgramProjectCreationYear={this.props.getProgramProjectCreationYear}
                reportingYearOptions={this.props.reportingYearList}
                //redux
                onCloseSelectProjectModal={this.props.onCloseSelectProjectModal}
                onChangeSelectProject={this.props.onChangeSelectProject}
                fieldValidation={this.props.fieldValidation}
                mainSelectedProject={this.props.mainSelectedProject}
                onCancelSelectProject={this.props.onCancelSelectProject}
                selectProjectDropdown={this.props.selectProjectDropdown}
                getProgramProject={this.props.getProgramProject}
                getProjectDetails={this.props.getProjectDetails}
                onClickSelectProject={this.props.onClickSelectProject}
                programOptions={this.props.programOptions}
                projectOptions={this.props.projectOptions}
                selectedProgram={this.props.selectedProgram}
                selectedProject={this.props.selectedProject}
              />
            }
          />
        </>
      );
    }
  }

  const computeMaxWidths = (optionsArr, defWidth) => {
    let maxWidth = defWidth;
    let ddOptions = optionsArr;
    if (IsValid(ddOptions)) {
      let widths = [];
      ddOptions.map((opt) => {
        widths.push(String(opt.label).toString().length);
      })
      let maxLen = Math.max(...widths);

      if (String(maxLen).toString() !== '-Infinity' && IsValid(maxLen)) {
        let tempMax = maxLen * 6 + 5;

        maxWidth = tempMax > defWidth ? tempMax : defWidth;
      }
    }
    return maxWidth;
  }
  class ProjectDataEntryListContainer extends Component {
    constructor() {
      super();
      this.state = {
        showSelectProjectModal: false,
        reportingYearOptions: [],
        showProjectDataEntry: false,
        comment: '',
        radioButtonIdList: [],
        currentBOIStatus: {
          status: '',
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0,
          ID: 0,
        },
        showRoller: false,
        isDisabled: {
          project: false,
          program: false,
        },
      };
      this.tempBasicDetails = {};
    }

    componentDidMount = async () => {
      if (!IsValid(this.props.clientID)) this.closeProject();
      let selectProject = this.props.ConfigureProgramData.mainSelectedProject;
      let program = {
        programID: selectProject.programID,
        programName: selectProject.programName,
        creationYear: selectProject.creationYear,
        programStatus: selectProject.programStatus,
      };
      let project = {
        ID: selectProject.projectDetails.ID,
        assignedTo: selectProject.projectDetails.assignedTo,
        clientProjectID: selectProject.projectDetails.clientProjectID,
        clientProjectName: selectProject.projectDetails.clientProjectName,
        cssClass: selectProject.projectDetails.cssClass,
        deliveryCenterID: selectProject.projectDetails.deliveryCenterID,
        deliveryCenterName: selectProject.projectDetails.deliveryCenterName,
        status: selectProject.projectDetails.status,
        projectID: selectProject.projectDetails.projectID,
        status: selectProject.projectDetails.status,
        assignedToRoleId: selectProject.projectDetails.assignedToRoleId,
        createdBy: selectProject.projectDetails.createdBy,
        creationDate: selectProject.projectDetails.creationDate,
        deliveryLocations: selectProject.projectDetails.deliveryLocations,
        reportedBy: selectProject.projectDetails.reportedBy,
        reportedMonth: selectProject.projectDetails.reportedMonth,
        statusID: selectProject.projectDetails.statusID,
        lastReportingDate: selectProject.projectDetails.lastReportingDate,
      };
      if (
        selectProject.programName !== '' &&
        selectProject.projectDetails.clientProjectName !== '' &&
        selectProject.projectDetails.deliveryCenterName !== ''
      ) {
        await this.props.getProjectDataEntryTableData(program, project); //program, project
      }
      this.loadAggregatedDisabledBOI();
      LogUserSiteAction({
        module: PROJ_SEL,
        ShouldLogPPCID: true,
        ClientProgramID: program.programID,
        ClientProjectID: project.clientProjectID,
        projStatus: ACC_PROJ,
      });
    };
    componentDidUpdate(prevProps) {
      if (this.props.clientID !== prevProps.clientID) this.closeProject();
      if (
        JSON.stringify(this.props.ConfigureProgramData.basicDetails) !==
        JSON.stringify(this.tempBasicDetails)
      ) {
      }
    }

    onCloseSelectProjectModal = (origin) => {
      this.setState(
        {
          ...this.state,
          showSelectProjectModal: !this.state.showSelectProjectModal,
        },
        () => {
          if (origin !== 'select') {
            //cancel
            this.props.onCancelSelectProject();
          }
        }
      );
    };

    onCloseProjectDataEntry = (origin, value) => {
      try {
        this.setState({
          ...this.state,
          showProjectDataEntry: !this.state.showProjectDataEntry,
        });
      } catch (e) {
        console.log(e);
      }
    };

    closeProject = (origin, value) => {
      try {
        this.props.history.push('/DataIngestion');
        this.props.clearMainSelectedDetails();
      } catch (e) {
        console.log(e);
      }
    };

    handleRedirect = (redirectionLink) => {
      this.props.history.push(redirectionLink);
    };

    onClickSelectProject = async () => {
      try {
        this.props.onSaveSelectProject();
        let selectProject = this.props.ConfigureProgramData.mainSelectedProject;
        if (
          selectProject.programName !== '' &&
          selectProject.projectDetails.clientProjectName !== '' &&
          selectProject.projectDetails.deliveryCenterName !== '' &&
          selectProject.projectDetails.lastReportingDate !== ''
        ) {
          let programDetails = {
            programID: selectProject.programID,
            programName: selectProject.programName,
            creationYear: selectProject.creationYear,
            programStatus: selectProject.programStatus,
          };
          let projectDetails = {
            ID: selectProject.projectDetails.ID,
            assignedTo: selectProject.projectDetails.assignedTo,
            clientProjectID: selectProject.projectDetails.clientProjectID,
            clientProjectName: selectProject.projectDetails.clientProjectName,
            cssClass: selectProject.projectDetails.cssClass,
            deliveryCenterID: selectProject.projectDetails.deliveryCenterID,
            deliveryCenterName: selectProject.projectDetails.deliveryCenterName,
            status: selectProject.projectDetails.status,
            projectID: selectProject.projectDetails.projectID,
            status: selectProject.projectDetails.status,
            assignedToRoleId: selectProject.projectDetails.assignedToRoleId,
            createdBy: selectProject.projectDetails.createdBy,
            creationDate: selectProject.projectDetails.creationDate,
            deliveryLocations: selectProject.projectDetails.deliveryLocations,
            reportedBy: selectProject.projectDetails.reportedBy,
            reportedMonth: selectProject.projectDetails.reportedMonth,
            statusID: selectProject.projectDetails.statusID,
            lastReportingDate: selectProject.projectDetails.lastReportingDate,
          };
          this.onCloseSelectProjectModal('select');
          await this.props.getProjectDetails(programDetails, projectDetails, false);
          await this.props.getMappedStakeHolders(programDetails.programID, "Value Delivered_Landing page");
          await LogUserSiteAction({
            module: REP_PROJ_DATA,
            ShouldLogPPCID: true,
            ClientProgramID: programDetails.programID,
            ClientProjectID: projectDetails.clientProjectID,
            projStatus: ACC_PROJ,
          });
          this.props.history.push('/DataIngestion/ProjectDataEntryList/ConfigureProgram');
        }
      } catch (e) {
        console.log(e);
      }
    };

    handleSelectProgram = (e) => {
      const clientID = this.props.SharedData.selectedClient.value;
      let selectedProgram = this.props.DataIngestionData.programList.filter(
        (prog) => prog.programID === e.value
      )[0];
      this.props.setProgramSelected(selectedProgram, {});
      LogUserSiteAction({
        module: PROJ_SEL,
        clientID,
        ClientProgramID: selectedProgram.programID,
        ClientProjectID: null,
        progStatus: ACC_PROG,
        projStatus: null,
        ShouldLogPPCID: true,
      });
    };

    handleSelectProject = async (e) => {
      if (!this.state.isDisabled.project) {
        this.setState(
          {
            showRoller: true,
            isDisabled: { ...this.state.isDisabled, project: true }
          },
          async () => {
            let selectedProgram = this.props.DataIngestionData.selectedProgramDDValue;
            const clientID = this.props.SharedData.selectedClient.value;
            let program = this.props.DataIngestionData.programList.filter(
              (prog) => prog.programID === selectedProgram.value
            )[0];
            let project = program.projects.filter((proj) => proj.clientProjectID === e.value)[0];
            await this.props.setProjectSelected(project);
            await this.loadAggregatedDisabledBOI();
            LogUserSiteAction({
              module: PROJ_SEL,
              clientID,
              ClientProgramID: program.programID,
              ClientProjectID: project.clientProjectID,
              projStatus: ACC_PROJ,
              progStatus: null,
              ShouldLogPPCID: true,
            });

            await this.props.getProjectDataEntryTableData(program, project); //program, project
            this.setState({
              showRoller: false,
              isDisabled: { ...this.state.isDisabled, project: false }
            });
          }
        );
      }
    };

    loadAggregatedDisabledBOI = async () => {
      let selectedProject = this.props.DataIngestionData.selectedProjectDDValue;
      await this.props.getAggregatedDisabledBOIs(selectedProject.value);
    };

    render() {
      const {
        selectProjectDropdown,
        fieldValidation,
        showSelectProject,
        mainSelectedProject,
        aggregatedDisabledBOIs,
      } = this.props.ConfigureProgramData;

      const {
        programOptions,
        projectOptions,
        selectedProgramDDValue,
        selectedProjectDDValue,
        creationYearList,
        rdvClientDetails,
      } = this.props.DataIngestionData;

      const {
        onChangeSelectProject,
        onSaveSelectProject,
        onCancelSelectProject,
        getProgramProject,
        getProjectDetails,
        getProgramProjectCreationYear,
        //new
        clearMainSelectedDetails,
      } = this.props;
      const { programClientDetails } = this.props.ProgramConfigurationData;
      const { isSuperAdmin, currentUserRoleID, userProfiles } = this.props.userInformation;
      const { userInformation } = this.props;
      const { selectedClient } = this.props.SharedData;
      let clientID = selectedClient.value;
      let isExistingPPC = userProfiles.some((x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      let isExistingBA = userProfiles.some((x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      return (
        <ProjectDataEntryListPresentational
          showSelectProjectModal={this.state.showSelectProjectModal} //
          onCloseSelectProjectModal={this.onCloseSelectProjectModal} //
          reportingYearOptions={this.state.reportingYearOptions} //
          creationYearList={creationYearList} //
          closeProject={this.closeProject} //
          onCloseProjectDataEntry={this.onCloseProjectDataEntry} //
          onClickSelectProject={this.onClickSelectProject} //
          showProjectDataEntry={this.state.showProjectDataEntry} //
          //actions
          onChangeSelectProject={onChangeSelectProject} //
          onSaveSelectProject={onSaveSelectProject} //
          onCancelSelectProject={onCancelSelectProject} //
          fieldValidation={fieldValidation} //
          showSelectProject={showSelectProject} //
          selectProjectDropdown={selectProjectDropdown} //
          getProgramProject={getProgramProject} //
          getProjectDetails={getProjectDetails} //
          getProgramProjectCreationYear={getProgramProjectCreationYear} //
          //reducers
          mainSelectedProject={mainSelectedProject} //
          //new
          //states and functions
          handleViewEdit={this.handleViewEdit}
          handleNewPDE={this.handleNewPDE}
          handleSelectProject={this.handleSelectProject}
          handleSelectProgram={this.handleSelectProgram}
          showRoller={this.state.showRoller}
          //actions
          clearMainSelectedDetails={clearMainSelectedDetails}
          //reducers
          programOptions={programOptions}
          projectOptions={projectOptions}
          selectedProgram={selectedProgramDDValue}
          selectedProject={selectedProjectDDValue}
          aggregatedDisabledBOIs={aggregatedDisabledBOIs}
          rdvClientDetails={rdvClientDetails}
          handleRedirect={this.handleRedirect}
          isSuperAdmin={isSuperAdmin}
          programClientDetails={programClientDetails}
          userInformation={userInformation}
          clientID={this.props.clientID}
          currentUserRoleID={currentUserRoleID}
          isExistingPPC={isExistingPPC}
          isExistingBA={isExistingBA}
        />
      );
    }
  }

  return ProjectDataEntryListContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      clientID: state.SharedData.selectedClient.value,
      reportingYear: state.ClientConfigurationData.clientDetails.reportingYear,
      SharedData: state.SharedData,
      userInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
      DataIngestionData: state.DataIngestionData,
      ProgramConfigurationData: state.ProgramConfigurationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getProgramProject, //modal only
          onChangeSelectProject, //modal only
          onSaveSelectProject, //
          onCancelSelectProject, //
          clearMainSelectedDetails, //
          getProjectDetails, //
          getProgramProjectCreationYear, //modal only
          getMappedStakeHolders,
          //new
          setProjectSelected,
          setProgramSelected,
          getProjectDataEntryTableData,
          getAggregatedDisabledBOIs,
        }
      ),
      dispatch
    )
)(withRouter(ProjectDataEntryList));
