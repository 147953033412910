import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

let privacyStatementURL = {
    cookiesPolicy : "https://www.accenture.com/be-en/support/company-cookies-similar-technology",
    accentureGlobalDataPrivacyPolicy : "https://policies.accenture.com/policy/0090?rd=1&Country=United%20Kingdom",
    privacyStatement : "https://in.accenture.com/protectingaccenture/data-security/5422-2/"
}

export default class PrivacyStatement extends Component{
    componentDidMount = () => {
        if (
          document.getElementById("navigation").getElementsByClassName("selected")
            .length > 0
        ) {
          document
            .getElementById("navigation")
            .getElementsByClassName("selected")[0]
            .classList.remove("selected");
        }
    };
    
    render(){
        return(
            <Row className="privacyStatement body_container">
                <Col md={12} xs={12}>
                    <h1 className="privacy-header"> Privacy Statement</h1>
                    <p>
                    This website is administered by Accenture. The primary purpose of this site is to provide information and knowledge resources for Accenture employees. We want you to feel secure when visiting our site and are committed to maintaining your privacy when doing so.
                    </p>
                    
                    <p>
                    Before using and providing information through this site, we invite you to carefully read the below and our privacy statement.
                    </p>

                    <p>
                    One way that we collect information is through the use of cookies. Cookies are small files of information that save and retrieve information about your visit to this site – for example, how you entered our site, how you navigated through the site, and what information was of interest to you. Please read our <a href={privacyStatementURL.cookiesPolicy}>cookies policy</a> for further information on this.
                    </p>

                    <h2>What information do we gather?</h2>
                    <p>
                    Information in this site is gathered in two ways: indirectly (for example, through our site's technology) and directly (for example, when you provide information on various pages of the Accenture Portal). Although we do not generally seek to collect sensitive personal data through this site, we may do so in certain cases where we have the legal right to do so, or where you have expressly consented to this.
                    </p>

                    <h2>How do we use your information?</h2>
                    <p>
                    We process your personal data as permitted by applicable data protection laws and our internal policies, including <a href={privacyStatementURL.accentureGlobalDataPrivacyPolicy}>Accenture’s Global Data Privacy Policy 0090.</a>
                    </p>

                    <p>
                    We analyze your information to determine what is most effective about our site, to help us identify ways to improve it, and eventually, to determine how we can tailor our site to make it more effective for you and other users. We may also use your information for certain other purposes, where we have the legal right to do so, such as complying with requests from public authorities or conducting internal investigations.
                    </p>

                    <h2>Will we share your information with outside parties?</h2>
                    <p>
                    As a global organization, information we collect may be transferred internationally throughout Accenture's worldwide organization. In addition, we may at times share your information with certain third parties, such as our service providers.
                    </p>

                    <h2>How do we protect your information?</h2>
                    <p>
                    The protection of your information is important to us. We will process your information in accordance with applicable data protection legislation, and will take appropriate steps to maintain the security of this site.
                    </p>

                    <h2>Where can I find further information on this?</h2>
                    <p>
                    You can find further information on how and for what purposes we use your information, and on what rights you have in relation to your information, in our <a href={privacyStatementURL.privacyStatement}>privacy statement.</a>
                    </p>
                </Col>
            </Row>
        );
    }
};