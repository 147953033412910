import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { GrooveTabComponent, GrooveIconSvg, GrooveButton } from "tfo-groove";
import { Accordion, Card, Row } from "react-bootstrap";
import { GrooveTextBox } from "tfo-groove";

import {
  saveRejectionReason
} from "../../../Actions/ConfigureProgram.Action";

require("./CVMReportValueViewComments.css");

const CVMReportValueViewComments = (function () {
  class CVMReportValueViewCommentsPresentational extends Component {
    render() {
      return (
        <div className="CVMSideModal-container-comment">
          <div className="CVMSideModal-close" onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="CVMSideModal-title">Reject Comments</div>
          <div className="CVMSideModal-tab-container">
            {this.props.rejectedComments.length > 0 //used only for rejected pdes
              ? (
                this.props.rejectedComments.map((item, index) => {
                  return (
                    <Accordion>
                      <Card>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="comments">
                              <GrooveTextBox
                                isDisabled={this.props.basicDetails.overallStatus === "Rejected" ? true : false}
                                maxLength={255}
                                id="comment-textbox"
                                name="comment-textbox"
                                type="text"
                                placeholder="Reason for Rejection"
                                textValue={item.boiReason}
                                callbackFunction={this.props.updateRejectReason}
                              />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                          {/* Toggle 'open-card' class to toggle the accordion arrow */}
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="open-card"
                          >
                            <FontAwesomeIcon icon={faCaretRight} />
                            {item.boiName}
                          </Accordion.Toggle>
                        </Card.Header>
                      </Card>
                    </Accordion>
                  )
                })
              ) : (
                this.props.projectDimensionsList.map((dimension, dIndex) => {
                  return (
                    dimension.subDimensionList.map((subDimension, SDIndex) => {
                      return (
                        subDimension.boiList.map((boi, boiIndex) => {
                          if (boi.boiReason !== "") {
                            return (
                              <Accordion defaultActiveKey="0">
                                <Card>
                                  <Accordion.Collapse eventKey={boi.boiID}>
                                    <Card.Body>
                                      <div className="comments">
                                        <GrooveTextBox
                                          isDisabled={false}
                                          maxLength={255}
                                          id="comment-textbox"
                                          name="comment-textbox"
                                          type="text"
                                          placeholder="Reason for Rejection"
                                          textValue={boi.boiReason}
                                          callbackFunction={(e) => this.props.updateRejectReason(e, ({
                                            dimensionIndex: dIndex,
                                            subDimensionIndex: SDIndex,
                                            boiIndex: boiIndex,
                                          }))}
                                        />
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                  <Card.Header>
                                    {/* Toggle 'open-card' class to toggle the accordion arrow */}
                                    <Accordion.Toggle
                                      as={Button}
                                      variant="link"
                                      eventKey={boi.boiID}
                                      className="open-card"
                                    >
                                      <FontAwesomeIcon icon={faCaretRight} />
                                      {boi.boiName}
                                    </Accordion.Toggle>
                                  </Card.Header>
                                </Card>
                              </Accordion>
                            )
                          }
                          else {
                            return null
                          }
                        })
                      )
                    })
                  )
                })
              )
            }
            {
              (this.props.currentUserRoleID === 4
                || this.props.currentUserRoleID === 7
                || this.props.currentUserRoleID === 8) && this.props.mainSelectedProject.projectDetails.status !== "Approved" && this.props.mainSelectedProject.projectDetails.status !== "Rejected" ?
                <div className="CVMSideModal-button-container">
                  <Row className="rejection-modal-buttons">
                    <GrooveButton
                      id="primry-btn-1"
                      name="Solid Button Primary-Ops"
                      isDisabled={false}
                      hasIcon={false}
                      type="outline"
                      colorClass="stat-alternate"
                      size="auto"
                      text="Cancel"
                      callbackFunction={this.props.onCloseModal}
                    />
                    <GrooveButton
                      id="primry-btn-2"
                      name="Solid Button Primary-Ops"
                      isDisabled={false}
                      hasIcon={false}
                      type="solid"
                      colorClass="stat-alternate"
                      size="auto"
                      text="Update"
                      callbackFunction={this.props.saveRejectUpdate}
                    />
                  </Row>
                </div>
                :
                ""
            }
          </div>
        </div>
      );
    }
  }

  class CVMReportValueViewCommentsContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        rejectReason: {
          reason: "",
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0
        }
      };
    }

    componentDidMount = () => {
      this.changeTextAreaClass()
     };

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }
    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    updateRejectReason = (e, indexes) => {
      this.setState({
        rejectReason: {
          reason: e.value,
          dimensionIndex: indexes.dimensionIndex,
          subDimensionIndex: indexes.subDimensionIndex,
          boiIndex: indexes.boiIndex
        }
      })
    }

    saveRejectUpdate = () => {
      let reason = this.state.rejectReason.reason;
      let indexes = {
        dimensionIndex: this.state.rejectReason.dimensionIndex,
        subDimensionIndex: this.state.rejectReason.subDimensionIndex,
        boiIndex: this.state.rejectReason.boiIndex,
      }

      this.props.saveRejectionReason("Rejected", reason, indexes);

      this.setState({
        rejectReason: {
          reason: "",
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0
        }
      })

      this.props.closeCommentsSection();
    }

    onCloseModal = () => {
      this.setState({
        rejectReason: {
          reason: "",
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0
        }
      })

      this.props.closeCommentsSection();
    }

    render() {
      const {
        rejectedComments,
        basicDetails,
        projectDimensionsList,
        mainSelectedProject,
        isDraft,
      } = this.props.ConfigureProgramData;

      const {
        currentUserRoleID
      } = this.props.UserInformation

      return (
        <CVMReportValueViewCommentsPresentational
          role={this.props.role}
          show={this.props.show}
          onCloseModal={this.onCloseModal}
          updateRejectReason={this.updateRejectReason}
          saveRejectUpdate={this.saveRejectUpdate}
          //reducers
          rejectedComments={rejectedComments}
          basicDetails={basicDetails}
          currentUserRoleID={currentUserRoleID}
          projectDimensionsList={projectDimensionsList}
          mainSelectedProject={mainSelectedProject}
          isDraft={isDraft}
        />
      );
    }
  }

  return CVMReportValueViewCommentsContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      clientID: state.ClientConfigurationData.clientDetails.clientBasicDetailsId,
      reportingYear: state.ClientConfigurationData.clientDetails.reportingYear,
      UserInformation: state.userInformation
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          saveRejectionReason
        }
      ),
      dispatch
    )
)(CVMReportValueViewComments);