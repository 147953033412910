import {
  SET_FILTER_KEYWORD,
  SHOW_CLIENT_DROPDOWN,
  SET_CLIENT_VALUE,
  LEFT_PANEL_CLICKED,
  GET_CLIENT_LIST,
  GET_RDV_PROGRAM_LIST,
  SET_SHOULD_SHOW_TOASTER_NOTIF,
  SET_USER_INFORMATION,
  SET_CUSTOM_BOI_DETAILS,
  SET_STEPPER_ACTIVE_INDEX,
  SET_PROFILE_OFF,
  SET_UPLOAD_SUPPORT_OFF,
  SET_UPLOAD_SUPPORT_DOC,
  SET_EDIT_APPROVED,
  SET_MENUS,
  SET_HIS_PATH,
  SHOW_HIDE_LEFT_SIDE_BAR,
  SET_URL_PPC,
  SHOW_EMAIL_ERROR,
} from '../Constants';

import { history } from '../store';
import DashboardIcon from '../Static/images/360cvm/Dashboard.svg';
import { HttpGet, HttpPost, HttpPostWithFileString,HttpPostText } from '../Utilities/HTTPUtil';
import { TryGetValue, TryGetNodeAlt } from '../Utilities/Formatter';
import { HasValue, IsArrayEmpty, } from '../Utilities/Validations';

// ----------------------------------------SHARED----------------------------------------------

export const onSearch = (event) => async (dispatch) => {
  try {
    const value = event.target.value;

    dispatch({
      type: SET_FILTER_KEYWORD,
      filterKeyword: value,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getClientList = () => async (dispatch, state) => {
  let userInfo = state().userInformation;
  const isGoingDG = String(window.location.href).toLowerCase().includes('dashboard/global');

  try {
    let body = {
      userId: userInfo.userID,
    };
    let data = userInfo.isSuperAdmin // && !userInfo.isIOAdmin //for global dashboard, IO is not admin
      ? await HttpPostText(`ClientConfiguration/GetClientDashboardList_sp`, body)
      : await HttpPostText(`ClientConfiguration/ClientList`,body);
    let obj = [];
    data = JSON.parse(data);
    data.forEach((item) => {
      obj.push({
        icon: item.ClientMasterLogo || DashboardIcon,
        label: item.ClientMasterName,
        favorite: item.UserID !== 0 ? true : false,
        value: item.Id,
        isClientDataEmpty: item.Pid === 0 ? true : false,
      });
    });

    dispatch({
      type: GET_CLIENT_LIST,
      clientList: obj,
    });

    // Global dashboard

    //UNSET CLIENT IF NOT IN THE FETCHED LIST
    if (!isGoingDG) {
      const client = TryGetValue(state().SharedData.selectedClient.value);
      if (
        !obj.some((e) => {
          return e.value === client;
        })
      )
        dispatch({
          type: SET_CLIENT_VALUE,
          selectedClient: {
            value: 0,
            text: '',
            label: '',
            iconName: '',
            iconStyle: '',
          },
        });
    }
    return obj;
  } catch (err) {
    console.log(err);
  }
};
export const explicitSetClientList = (list) => (dispatch, state) => {
  try {
    dispatch({
      type: GET_CLIENT_LIST,
      clientList: list,
    });
  } catch (e) {
    console.log(e);
  }
};

export const showClientDropdown = (fromModule, toPath) => async (dispatch) => {
  try {
    const path = window.location.pathname.toLowerCase();
    let currentPath = fromModule
      ? ['/clientconfiguration', '/dataingestion', '/dashboard', '/programprojectconfig'].some(
        (e) => {
          return toPath.includes(e);
        }
      )
      : ['/clientconfiguration', '/dataingestion', '/dashboard', '/programprojectconfig'].some(
        (e) => {
          return path.includes(e);
        }
      );

    if (path === "/masterconfiguration") {
      await dispatch(getClientList());
    }

    if (currentPath) {
      dispatch({
        type: SHOW_CLIENT_DROPDOWN,
        showClientDropdown: true,
      });
    } else {
      dispatch({
        type: SHOW_CLIENT_DROPDOWN,
        showClientDropdown: false,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const setSelectedClient = (value) => async (dispatch) => {
  try {
    const data = {
      value: value.value,
      text: value.label,
      label: value.label,
      iconName: '',
      iconStyle: '',
      isClientDataEmpty: value.isClientDataEmpty,
    };
    dispatch({
      type: SET_CLIENT_VALUE,
      selectedClient: data,
    });
    dispatch({
      type: SET_FILTER_KEYWORD,
      filterKeyword: '',
    });
    dispatch({
      type: GET_RDV_PROGRAM_LIST,
      programList: [],
    });
  } catch (err) {
    console.log(err);
  }
};

export const leftPanelOnClick = (isClicked) => async (dispatch) => {
  try {
    dispatch({
      type: LEFT_PANEL_CLICKED,
      isLeftPanel: isClicked,
    });
  } catch (err) {
    console.log(err);
  }
};
export const setToasterParam = (param) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHOULD_SHOW_TOASTER_NOTIF,
      toasterParam: param,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setCurrentUserDetails = (value) => async (dispatch, state) => {
  try {
    let selectedClient = state().SharedData.selectedClient.value;
    let userProfiles = state().userInformation.userProfiles;
    let currentUserRole = state().userInformation;
    let tUserProf = [];

    if (selectedClient !== undefined && selectedClient !== null && selectedClient !== '') {
      // profile combinations: QC + other profiles
      //other profiles supersedes QC
      tUserProf = userProfiles.filter((item) => item.ClientMasterId === selectedClient ||
        (currentUserRole.isSuperAdmin && item.ClientMasterId === ''));

      if (tUserProf.length === 1) {
        currentUserRole.currentUserRoleID = tUserProf[0].ProfileID;
        currentUserRole.currentUserRole = tUserProf[0].ProfileName;
      }
      else if (tUserProf.length > 1) {
        let fUserProf = tUserProf.filter((a) => a.ProfileID === 16).length === tUserProf.length ?
          tUserProf[0]
          : tUserProf.filter((a) => a.ProfileID !== 16)[0];

        currentUserRole.currentUserRoleID = fUserProf.ProfileID;
        currentUserRole.currentUserRole = fUserProf.ProfileName;
      }
    }

    dispatch({
      type: SET_USER_INFORMATION,
      payload: currentUserRole,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setStepperActiveIndex = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_STEPPER_ACTIVE_INDEX,
      stepperActiveIndex: value,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCustomBOIDetails = (programId, boiId) => async (dispatch) => {
  try {
    const data = await HttpGet(
      `CustomBOI/GetProgramCustomBOIs?programID=${programId}&BOIID=${boiId}`
    );

    if (data) {
      dispatch({
        type: SET_CUSTOM_BOI_DETAILS,
        customBOI: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const showProfileButton = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PROFILE_OFF,
      showProfile: value,
    });
  } catch (err) {
    console.log(err);
  }
};

export const onShowUploadSupport = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UPLOAD_SUPPORT_OFF,
      showUploadSupport: value,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setUploadSupportDoc = (data) => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_UPLOAD_SUPPORT_DOC,
      uploadSupportDoc: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setEditApproved = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_EDIT_APPROVED,
      editApproved: value,
    });
  } catch (err) {
    console.log(err);
  }
};

export const SetMenus = (prof) => async (dispatch) => {
  try {
    let tMenu = []
    let tSubMenu = []
    if (prof.length > 0) {
      prof.forEach(e => {
        e.AccessLevel.forEach(e4 => {
          e4.subMenu.forEach(e5 => {
            tMenu.push({
              menuID: e5.MenuID,
              menu: e5.MenuName
            })
            tSubMenu.push({
              menuID: e5.MenuID,
              menu: e5.MenuName,
              subMenuID: e5.ID,
              subMenu: e5.SubMenuName
            })
          })
        })
      })

      const seen = new Set();
      tMenu = tMenu.filter(el => {
        const duplicate = seen.has(el.menuID);
        seen.add(el.menuID);
        return !duplicate;
      }).map(e => {
        const menuName = e.menu.toLowerCase()
        return {
          ...e,
          alias: menuName === "report value delivered" ? 'RVD'
            : menuName === "value meter dashboard" ? 'Dashboard'
              : menuName === "program & project configuration" ? 'PPC'
                : menuName === 'admin' ? 'Admin' : 'other'
        }
      });

      const seen2 = new Set();
      tSubMenu = tSubMenu.filter(el => {
        const duplicate = seen2.has(el.subMenuID);
        seen2.add(el.subMenuID);
        return !duplicate;
      });

    }

    dispatch({
      type: SET_MENUS,
      menu: {
        menu: tMenu,
        subMenu: tSubMenu
      }
    })
  } catch { }
}

export const setHistoryPath = (value) => async (dispatch, state) => {
  try {
    let hisValue = TryGetNodeAlt(state().SharedData, [], 'historyPath')
    if (Array.isArray(hisValue)) hisValue.unshift(value)
    else hisValue = [value]

    if (hisValue.length > 10) hisValue.splice(10, 10)
    dispatch({
      type: SET_HIS_PATH,
      historyPath: hisValue
    });
  } catch (err) {
    console.log(err);
  }
};

export const isProfileExisting = (profiles) => async (dispatch, state) => {
  try {
    let uProf = state().userInformation.userProfiles;
    let clientID = state().SharedData.selectedClient.value;

    return uProf.some((x) => profiles.some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID)
  } catch (err) {
    console.log(err);
  }
};

export const showLeftSideBar = (show) => async (dispatch, state) => {
  try {
    let showHide = state().SharedData.showHide;
    dispatch({
      type: SHOW_HIDE_LEFT_SIDE_BAR,
      showHide: HasValue(show) ? show : !showHide

    })
  }
  catch (err) {
    console.log(err)
  }
}

export const updatePPCBOI = (val) => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_URL_PPC,
      isAccountLeadership: val,
    });
  } catch (e) {
    console.log(e)
  }
}

export const setEmailError = (data) => async (dispatch, state) => {
  try {
    dispatch({
      type: SHOW_EMAIL_ERROR,
      showError: data
    })
  }
  catch (e) {
    console.log(e);
  }
}
