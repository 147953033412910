import React, { Component } from "react";
import { setUserInformation } from "../../Actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const _AccessManagement = (function() {
  class AccessManagementPresentational extends Component {
    render() {
      return <div />;
    }
  }

  class AccessManagementContainer extends Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      return <AccessManagementPresentational />;
    }
  }
  // return AccessManagementContainer;
  function mapDispatchToProps(dispatch) {
    return {
      SetUserInformation: (userInfo) => dispatch(setUserInformation(userInfo)),
    };
  }
  function mapStateToProps(state) {
    return {
      userInformation: state.userInformation,
    };
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccessManagementContainer);
})();

export default _AccessManagement;
