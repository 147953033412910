//Redux Constants - Use for Actions and Reducers

export const REQUEST_DATA = 'REQUEST_DATA';
export const RECEIVED_DATA = 'RECEIVED_DATA';
export const ERROR_DATA = 'ERROR_DATA';

export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';

export const GET_BO_DASHBOARD_FILTER = 'GET_BO_DASHBOARD_FILTER';
export const SET_BO_DASHBOARD_FILTER = 'SET_BO_DASHBOARD_FILTER';

export const GET_IS_MONET_DASHBOARD_ACTIVE = 'GET_IS_MONET_DASHBOARD_ACTIVE';
export const SET_IS_MONET_DASHBOARD_ACTIVE = 'SET_IS_MONET_DASHBOARD_ACTIVE';

export const GET_MONET_DASHBOARD_FILTER = 'GET_MONET_DASHBOARD_FILTER';
export const SET_MONET_DASHBOARD_FILTER = 'SET_MONET_DASHBOARD_FILTER';

export const SET_PROJECT_LIST = 'SET_PROJECT_LIST';
export const SET_RELOAD_PROJECT_LIST = 'SET_RELOAD_PROJECT_LIST';
export const SET_INITIAL_PROJECT_LIST = 'SET_INITIAL_PROJECT_LIST';

export const SET_EDIT_APPROVED = 'SET_EDIT_APPROVED';
export const SET_MENUS = 'SET_MENUS'

export const SET_HIS_PATH = 'SET_HIS_PATH'

export const SET_VIRUS_FOUND = 'SET_VIRUS_FOUND';

//HOME
export const GET_APP_Modules = 'GET_APP_Modules';
export const SET_PATH = 'SET_PATH';

//CLIENT WORKFLOW
export const FETCH_PROGRAM_LIST = 'FETCH_PROGRAM_LIST';
export const FETCH_CVM_PROJ_LEAD_DRPDOWN = 'FETCH_CVM_PROJ_LEAD_DRPDOWN';
export const FETCH_CVM_PROJ_DELIV_LOC_DRPDOWN = 'FETCH_CVM_PROJ_DELIV_LOC_DRPDOWN';
export const FETCH_CVM_PROJ_DELIV_BY_DRPDOWN = 'FETCH_CVM_PROJ_DELIV_BY_DRPDOWN';

export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_FILTER_KEYWORD = 'SET_FILTER_KEYWORD';
export const SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS';
export const SET_REPORTING_YEAR = 'SET_REPORTING_YEAR';
export const SET_WORKFLOW_REPORTING_YEAR = 'SET_WORKFLOW_REPORTING_YEAR';
export const GET_REPORTING_YEAR_LIST = 'GET_REPORTING_YEAR_LIST';
export const GET_CLIENT_ADMIN_LIST = 'GET_CLIENT_ADMIN_LIST';
export const GET_OFFERING_LIST = 'GET_OFFERING_LIST';
export const GET_MARKET_LIST = 'GET_MARKET_LIST';
export const GET_OP_ACCOUNT_LEAD_LIST = 'GET_OP_ACCOUNT_LEAD_LIST';
export const GET_CLIENT_TYPE_LIST = 'GET_CLIENT_TYPE_LIST';
export const GET_INDUSTRY_LIST = 'GET_INDUSTRY_LIST';
export const ENABLE_ADD_NEW_PROGRAM_BUTTON = 'ENABLE_ADD_NEW_PROGRAM_BUTTON';
export const SET_ADD_NEW_PROGRAM_OFFERING = 'SET_ADD_NEW_PROGRAM_OFFERING';
export const SET_ADD_NEW_PROGRAM_SUB_OFFERING = 'SET_ADD_NEW_PROGRAM_SUB_OFFERING';
export const SET_ADD_NEW_PROGRAM_BT_LEAD = 'SET_ADD_NEW_PROGRAM_BT_LEAD';
export const SET_ADD_NEW_PROGRAM_CX_LEAD = 'SET_ADD_NEW_PROGRAM_CX_LEAD';
export const SET_ADD_NEW_PROGRAM_IOA = 'SET_ADD_NEW_PROGRAM_IOA';
export const SET_ADD_NEW_PROGRAM_NAME = 'SET_ADD_NEW_PROGRAM_NAME';
export const GET_CX_LEAD_LIST = 'GET_CX_LEAD_LIST,';
export const GET_BT_LEAD_LIST = 'GET_BT_LEAD_LIST,';
export const GET_IOA_LIST = 'GET_IOA_LIST';
export const GET_SUB_OFFERING_LIST = 'GET_SUB_OFFERING_LIST';
export const ADD_NEW_PROGRAM = 'ADD_NEW_PROGRAM';
export const SHOW_SAVE_AS_DRAFT_TOASTER = 'SHOW_SAVE_AS_DRAFT_TOASTER';
export const SHOW_NOTIFY_TOAST_SUCCESS = 'SHOW_NOTIFY_TOAST_SUCCESS';
export const CLEAR_CONFIG_CLIENT_FIELDS = 'CLEAR_CONFIG_CLIENT_FIELDS';
export const SET_BASIC_DETAILS = 'SET_BASIC_DETAILS';
export const SET_PROGRAM_DETAILS = 'SET_PROGRAM_DETAILS';
export const SET_BASIC_DETAILS_IS_VALID = 'SET_BASIC_DETAILS_IS_VALID';
export const SET_PROGRAM_IS_VALID = 'SET_PROGRAM_IS_VALID';
export const SET_ALL_SELECTED_OFFERING = 'SET_ALL_SELECTED_OFFERING';
export const SET_ALL_SELECTED_SUB_OFFERING = 'SET_ALL_SELECTED_SUB_OFFERING';
export const SET_TEMP_SUB_OFFERING_LIST = 'SET_TEMP_SUB_OFFERING_LIST';
export const REMOVED_PROGRAM = 'REMOVED_PROGRAM';

export const SHOW_CLIENT_DROPDOWN = 'SHOW_CLIENT_DROPDOWN';
export const SET_CLIENT_VALUE = 'SET_CLIENT_VALUE';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const LEFT_PANEL_CLICKED = 'LEFT_PANEL_CLICKED';
export const SET_SHOULD_SHOW_TOASTER_NOTIF = 'SET_SHOULD_SHOW_TOASTER_NOTIF';
export const SET_STAGE_REPORTING_YEAR_PARAM = 'SET_STAGE_REPORTING_YEAR_PARAM';
export const SET_PROFILE_OFF = 'SET_PROFILE_OFF';
export const SET_UPLOAD_SUPPORT_OFF = 'SET_UPLOAD_SUPPORT_OFF';
export const SET_UPLOAD_SUPPORT_DOC = 'SET_UPLOAD_SUPPORT_DOC';

export const SET_INDUSTRY = 'SET_INDUSTRY';
export const SET_CLIENT_TYPE = 'SET_CLIENT_TYPE';
export const SET_OPERATION_ACCT_LEAD = 'SET_OPERATION_ACCT_LEAD';
export const SET_REPORTING_YEAR_SELECT = 'SET_REPORTING_YEAR_SELECT';
export const SET_MARKET = 'SET_MARKET';
export const SET_OFFERING = 'SET_OFFERING';
export const SET_CLIENT_ADMIN = 'SET_CLIENT_ADMIN';

export const SET_REQUEST_TARGET_RECEPIENTS = 'SET_REQUEST_TARGET_RECEPIENTS';
export const SET_ACKNOWLEDGEMENT_RECEPIENTS = 'SET_ACKNOWLEDGEMENT_RECEPIENTS';

export const SET_MODAL_COMMENTS = 'SET_MODAL_COMMENTS';

export const SHOW_HIDE_NOTIFY_MODAL_CONFIG_CLIENT = 'SHOW_HIDE_NOTIFY_MODAL_CONFIG_CLIENT';

//User Management

export const ON_CHANGE_USER_DETAILS = 'ON_CHANGE_USER_DETAILS';

//DATA INGESTION
export const SHOW_TOAST_NOTIFICATION = 'SHOW_TOAST_NOTIFICATION';
export const ON_TILE_SELECT_PROJECT = 'ON_TILE_SELECT_PROJECT';
export const SET_BUTTON_STATE = 'SET_BUTTON_STATE';
export const GET_FILE_DETAILS = 'GET_FILE_DETAILS';
export const GET_RDV_PROJECT_LIST = 'GET_RDV_PROJECT_LIST';
export const GET_RDV_PROGRAM_LIST = 'GET_RDV_PROGRAM_LIST';
export const GET_LANDING_PROGRAM_PROJECTS_LIST = 'GET_LANDING_PROGRAM_PROJECTS_LIST';
export const ON_SELECT_STATUS_OPTIONS = 'ON_SELECT_STATUS_OPTIONS';
export const ON_SELECT_REPORTING_YEAR_OPTIONS = 'ON_SELECT_REPORTING_YEAR_OPTIONS';
export const ON_SELECT_CREATION_YEAR_OPTIONS = 'ON_SELECT_CREATION_YEAR_OPTIONS';
export const SET_REJECTED_COMMENTS = 'SET_REJECTED_COMMENTS';
export const SET_RDV_CLIENT_DETAILS = 'SET_RDV_CLIENT_DETAILS';
export const MAPPED_STAKE_HOLDERS = 'MAPPED_STAKE_HOLDERS';
export const GET_RVD_BOI_HISTORY = 'GET_RVD_BOI_HISTORY';
export const GET_PDE_TABLE_DATA = 'GET_PDE_TABLE_DATA';
export const GET_PROGRAM_OPTIONS = 'GET_PROGRAM_OPTIONS';
export const GET_PROJECT_OPTIONS = 'GET_PROJECT_OPTIONS';
export const SET_RVD_SELECTED_PROGRAM = 'SET_RVD_SELECTED_PROGRAM';
export const SET_RVD_SELECTED_PROJECT = 'SET_RVD_SELECTED_PROJECT';
export const GET_PROJECT_CREATION_YEAR_LIST = 'GET_PROJECT_CREATION_YEAR_LIST';
export const SET_GLOBAL_URL_PARAM = 'SET_GLOBAL_URL_PARAM';
export const SET_PROJECT_LIST_ROLLER = 'SET_PROJECT_LIST_ROLLER';

//CONFIGURE PROGRAM
export const ON_SELECT_PROJECT_ENTRY = 'ON_SELECT_PROJECT_ENTRY';
export const ON_SELECT_PROJECT = 'ON_SELECT_PROJECT';
export const ON_SAVE_SELECT_PROJECT = 'ON_SAVE_SELECT_PROJECT';
export const ON_CANCEL_SELECT_PROJECT = 'ON_CANCEL_SELECT_PROJECT';
export const ON_SELECT_SEND_FOR_APPROVAL = 'ON_SELECT_SEND_FOR_APPROVAL';
export const ON_SEND_FOR_APPROVAL = 'ON_SEND_FOR_APPROVAL';
export const GET_PROGRAM_PROJECT = 'GET_PROGRAM_PROJECT';
export const GET_CXL_LEAD = 'GET_CXL_LEAD';
export const ON_CHANGE_PARAM = 'ON_CHANGE_PARAM';
export const ON_CHANGE_TAB_FINANCIAL = 'ON_CHANGE_TAB_FINANCIAL';
export const ON_CLICK_RDV_SFA = 'ON_CLICK_RDV_SFA';
export const ON_SFA_UPDATE_SUBMIT = 'ON_SFA_UPDATE_SUBMIT';
export const GET_PROJECT_REPORTING_YEAR_LIST = 'GET_PROJECT_REPORTING_YEAR_LIST';
export const DIS_UI_ELEMENTS = 'DIS_UI_ELEMENTS';
export const UPDATE_BOI_LIST = 'UPDATE_BOI_LIST';
export const BOI_IS_REFRESH = 'BOI_IS_REFRESH';
export const SHOW_CUMULATIVE_MODAL = 'SHOW_CUMULATIVE_MODAL';
export const GET_AGGREGATED_DISABLED_BOIS = 'GET_AGGREGATED_DISABLED_BOIS';
export const GET_CLIENT_STORY_FILE_HISTORY = 'GET_CLIENT_STORY_FILE_HISTORY';
export const GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY = 'GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY';
export const SET_CLIENT_STORY_FILE = 'SET_CLIENT_STORY_FILE';
export const SET_CLIENT_STORY = 'SET_CLIENT_STORY';
export const SET_CLIENT_ALIGNMENT_FILE = 'SET_CLIENT_ALIGNMENT_FILE';
export const SET_UPLOAD_IS_COMPLETED = 'SET_UPLOAD_IS_COMPLETED';
export const REMOVE_CLIENT_STORY_FILE = 'REMOVE_CLIENT_STORY_FILE';
export const REMOVE_CLIENT_ALIGNMENT_FILE = 'REMOVE_CLIENT_ALIGNMENT_FILE';
export const SET_BUSINESS_ADVISOR_LIST = 'SET_BUSINESS_ADVISOR_LIST';
export const SET_RVD_BASIC_DETAILS = 'SET_RVD_BASIC_DETAILS';

//DASHBOARD
export const FETCH_DASHBOARD_REPORTING_YEAR = 'FETCH_DASHBOARD_REPORTING_YEAR';
export const SET_DB_REPORTING_YEAR = 'SET_DB_REPORTING_YEAR';
export const SET_DB_PROGRAM = 'SET_DB_PROGRAM';
export const FETCH_DASHBOARD_PROGRAM = 'FETCH_DASHBOARD_PROGRAM';
export const FETCH_DIMENSION_SUBDIMENSION = 'FETCH_DIMENSION_SUBDIMENSION';
export const GET_DASHBOARD_PROGRAM_DETAILS = 'GET_DASHBOARD_PROGRAM_DETAILS';
export const SET_DB_DIMENSION_LIST = 'SET_DB_DIMENSION_LIST';
export const GET_BOI_PROJECT_DETAILS = 'GET_BOI_PROJECT_DETAILS';
export const SET_CLICKED_SUBDIMENSION_NAME = 'SET_CLICKED_SUBDIMENSION_NAME';
export const SET_CLICKED_PANEL = 'SET_CLICKED_PANEL';
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';
export const GET_DASHBOARD_CLIENT = 'GET_DASHBOARD_CLIENT';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const SELECTED_PROGRAM_PROJECT = 'SELECTED_PROGRAM_PROJECT';
export const GET_DASHBOARD_COMMENTARY = 'GET_DASHBOARD_COMMENTARY';
export const GET_PROGRAM_FISCAL_YEAR_RANGE = 'GET_PROGRAM_FISCAL_YEAR_RANGE';
export const GET_DASHBOARD_CLIENT_DETAILS = 'GET_DASHBOARD_CLIENT_DETAILS';
export const UNSET_DASHBOARD_CLIENT_DETAILS = 'UNSET_DASHBOARD_CLIENT_DETAILS';
export const SET_URL_PPC = 'SET_URL_PPC';
// GLOBAL DASHBOARD
export const GET_GLOBAL_DASHBOARD_PROGRAM_DETAILS = 'GET_GLOBAL_DASHBOARD_PROGRAM_DETAILS';

//ACCESS VALIDATION
export const SET_COMPONENT_ACCESS_RVD = 'SET_COMPONENT_ACCESS_RVD';
export const SET_COMPONENT_ACCESS_PnP = 'SET_COMPONENT_ACCESS_PnP';

//PROGRAM CONFIGURATION
export const GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS';
export const FETCH_OFFERING = 'FETCH_OFFERING';
export const FETCH_SUB_OFFERING = 'FETCH_SUB_OFFERING';
export const FETCH_PROGRAM_DATA = 'FETCH_PROGRAM_DATA';
export const FETCH_CXLEADLIST = 'FETCH_CXLEADLIST';
export const FETCH_CONFIGURATORSLIST = 'FETCH_CONFIGURATORSLIST';
export const FETCH_OALEADLIST = 'FECH_OALEADLIST';
export const FETCH_IOA_APPROVERLIST = 'FETCH_IOA_APPROVERLIST';
export const FETCH_CREATED_USER = 'FETCH_CREATED_USER';
export const GET_PROGRAM_ACCT_LEADERSHIP_DETAILS = 'GET_PROGRAM_ACCT_LEADERSHIP_DETAILS';
export const SET_PROGRAM_LIST = 'SET_PROGRAM_LIST';
export const GET_BOIMAPPING_LIST = 'GET_BOIMAPPING_LIST';
export const GET_CUSTOMBOIDROPDOWN_LIST = 'GET_CUSTOMBOIDROPDOWN_LIST';
export const GET_PROGRAM_BOI = 'GET_PROGRAM_BOI';
export const SET_SELECTED_PROGRAM = 'SET_SELECTED_PROGRAM';
export const SET_SELECTED_PROGRAM_DETAILS = 'SET_SELECTED_PROGRAM_DETAILS';
export const SET_NEW_PROGRAM_DETAILS = 'SET_NEW_PROGRAM_DETAILS';
export const GET_PROGRAM_CA_DETAILS = 'GET_PROGRAM_CA_DETAILS';
export const GET_CA_FILE_DATA = 'GET_CA_FILE_DATA';
export const CHANGE_PROGRAM_REJECTED = 'CHANGE_PROGRAM_REJECTED';
export const GET_REJECTED_DIMENSION = 'GET_REJECTED_DIMENSION';
export const SET_STEPPER_ACTIVE_INDEX = 'SET_STEPPER_ACTIVE_INDEX';
export const CLEAR_ALL_PROGRAM_PROJECT_FIELDS = 'CLEAR_ALL_PROGRAM_PROJECT_FIELDS';
export const GET_CONDITIONAL_DIMENSION = 'GET_CONDITIONAL_DIMENSION';
export const SET_SFA_PARAM = 'SET_SFA_PARAM';
export const SET_RESEND_PARAM = 'SET_RESEND_PARAM';
export const SET_PROGRAM_SFA = 'SET_PROGRAM_SFA';
export const SET_PROGRAM_SFA_PAYLOAD = 'SET_PROGRAM_SFA_PAYLOAD';
export const SET_PROGRAM_RESEND_PAYLOAD = 'SET_PROGRAM_RESEND_PAYLOAD';
export const SET_PROGRAM_UPLOAD = 'SET_PROGRAM_UPLOAD';
export const SET_PROGRAM_UPLOAD_PAYLOAD = 'SET_PROGRAM_UPLOAD_PAYLOAD';
export const SET_PROGRAM_UPLOAD_FILE = 'SET_PROGRAM_UPLOAD_FILE';
export const GET_PROGRAM_EDIT_BOI = 'GET_PROGRAM_EDIT_BOI';
export const GET_PROGRAM_STAKEHOLDERS = 'GET_PROGRAM_STAKEHOLDERS';
export const IS_PROGPROJ_DROPDOWN = 'IS_PROGPROJ_DROPDOWN';
export const GET_PROGRAM_BOIHISTORY = 'GET_PROGRAM_BOIHISTORY';
export const GET_PROGRAM_DELETED_BOI = 'GET_PROGRAM_DELETED_BOI';
export const IS_CHECKED_ALL_OFFERINGS = 'IS_CHECKED_ALL_OFFERINGS';
export const GET_BOI_ALL_OFFERINGS = 'GET_BOI_ALL_OFFERINGS';
export const PREP_DROPDOWN_LIST_BY_OFFERING = 'PREP_DROPDOWN_LIST_BY_OFFERING';
export const PREP_DROPDOWN_LIST_ALL_OFFERING = 'PREP_DROPDOWN_LIST_ALL_OFFERING';
export const GET_PROGRAM_SPECIFICBOIHISTORY = 'GET_PROGRAM_SPECIFICBOIHISTORY';
export const SET_NEWLY_ADDED_STAKEHOLDER = 'SET_NEWLY_ADDED_STAKEHOLDER';

//PROJECT CONFIGURATION
export const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
export const SET_TEMP_PROJECT_LIST = 'SET_TEMP_PROJECT_LIST';
export const SET_SORT_PROJECT_LIST = 'SET_SORT_PROJECT_LIST';
export const GET_PROJECT_BOI = 'GET_PROJECT_BOI';
export const GET_PROJECT_CONDITIONAL_DIMENSION = 'GET_PROJECT_CONDITIONAL_DIMENSION';
export const GET_PROJECT_REJECTED_DIMENSION = 'GET_PROJECT_REJECTED_DIMENSION';
export const GET_PROJECT_CA_FILE_DATA = 'GET_PROJECT_CA_FILE_DATA';
export const GET_PROJECT_CA_DETAILS = 'GET_PROJECT_CA_DETAILS';
export const SET_PROJECT_STAKEHOLDERS = 'SET_PROJECT_STAKEHOLDERS';
export const SET_PROJECT_DROPDOWN_LIST = 'SET_PROJECT_DROPDOWN_LIST';
export const SET_NEW_PROJECT_DETAILS = 'SET_NEW_PROJECT_DETAILS';
export const SET_SELECTED_PROJECT_DETAILS = 'SET_SELECTED_PROJECT_DETAILS';
export const GET_PROGRAM_BOI_LIST = 'GET_PROGRAM_BOI_LIST';
export const GET_PROJECT_EDIT_BOI = 'GET_PROJECT_EDIT_BOI';

export const GET_PROJECT_BOIHISTORY = 'GET_PROJECT_BOIHISTORY';
export const GET_PROJECT_SPECIFICBOIHISTORY = 'GET_PROJECT_SPECIFICBOIHISTORY';

export const SAVE_ALREADY_EXIST = 'SAVE_ALREADY_EXIST';

export const FETCH_ASSET_AND_DELIVEREDBY_LIST = 'FETCH_ASSET_AND_DELIVEREDBY_LIST';
export const SET_BUSINESS_ADVISOR_LIST_BY_CLIENT = 'SET_BUSINESS_ADVISOR_LIST_BY_CLIENT';

//CUSTOM BOI
export const SET_CUSTOM_BOI_DETAILS = 'SET_CUSTOM_BOI_DETAILS';
export const SET_SELECTED_REPORTED_YEAR = 'SET_SELECTED_REPORTED_YEAR';
export const SET_REPORTED_YEAR_LIST = 'SET_REPORTED_YEAR_LIST';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const CLEAR_ALL_PROJECT_FIELDS = 'CLEAR_ALL_PROJECT_FIELDS';
export const SET_ORIG_PROJECT_BOI = 'SET_ORIG_PROJECT_BOI';
export const SET_PROJECT_SFA_PARAM = 'SET_PROJECT_SFA_PARAM';
export const SET_PROJECT_SFA_PAYLOAD = 'SET_PROJECT_SFA_PAYLOAD';
export const SET_PROJECT_SFA = 'SET_PROJECT_SFA';
export const SET_PROJECT_RESEND_PAYLOAD = 'SET_PROJECT_RESEND_PAYLOAD';
export const GET_PROJECT_ACCT_LEADERSHIP_DETAILS = 'GET_PROJECT_ACCT_LEADERSHIP_DETAILS';
export const SET_PROJECT_UPLOAD_FILE = 'SET_PROJECT_UPLOAD_FILE';
export const SET_PROJECT_UPLOAD = 'SET_PROJECT_UPLOAD';
export const GET_PROJECT_STAKEHOLDERS = 'GET_PROJECT_STAKEHOLDERS';
export const SHOW_SELECT_PROJECT = 'SHOW_SELECT_PROJECT';

// NOTIFICATIONS
export const SET_NOTIF_LIST = 'SET_NOTIF_LIST';
export const GET_NOTIF_HISTORY_LIST = 'GET_NOTIF_HISTORY_LIST';
export const GET_NOTIF_HISTORY_COUNT = 'GET_NOTIF_HISTORY_COUNT';
export const GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS = 'GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS';
export const GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS =
  'GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS';
export const SET_NEW_NOTIF_LIST = 'SET_NEW_NOTIF_LIST';
export const SET_OLD_NOTIF_LIST = 'SET_OLD_NOTIF_LIST';
export const SET_NEWLY_ADDED_NOTIF = 'SET_NEWLY_ADDED_NOTIF';
export const SET_NOTIF_COUNT = 'SET_NOTIF_COUNT';
export const SHOULD_NOTIF_HIST_SHOW = 'SHOULD_NOTIF_HIST_SHOW';

//LEFT SIDE BAR
export const SHOW_HIDE_LEFT_SIDE_BAR = "SHOW_HIDE_LEFT_SIDE_BAR";

//SEND REMINDER
export const ON_SEND_REMINDER_PROGRAM_APPROVAL = "ON_SEND_REMINDER_PROGRAM_APPROVAL"
export const ON_SEND_REMINDER_PROJECT_DATA_ENTRY = "ON_SEND_REMINDER_PROJECT_DATA_ENTRY"
export const GET_REMINDER_COUNT = "GET_REMINDER_COUNT"

//SHOW EMAIL ERROR ON POST
export const SHOW_EMAIL_ERROR = 'SHOW_EMAIL_ERROR';
export const COPIED_PROJECT_NAME = 'COPIED_PROJECT_NAME';