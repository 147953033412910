import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { Row, Col } from "react-bootstrap";
import { history } from '../../../store';
import ProgramProjectHeader from '../ProgramProjectHeader'
import DimensionAccordion from "../DimensionAccordion/DimensionAccordion";
import ALATable from "../ALATable/ALATable"
import Stepper from '../Stepper'
import {
  getProgramBOI,
  setResendPayload, setNewProgramDetails,
  sendProgResend, setResendComment
} from '../../../Actions/ProgramConfiguration.Action'
// import ResendForApproval from "../ResendForApproval";

import { getCustomBOIDetails } from "../../../Actions/Shared.Action";
import { GrooveButton } from "tfo-groove";
import BOIHistoryModal from "../BOIHistoryModal";
import { LogUserSiteAction } from "../../../Utilities/AccessManagementUtil";
import { TryGetObjValueAlt } from '../../../Utilities/Formatter'
import { PROG_ID_VAL_ALA, ACC_PROG } from "../../../Constants/Modules";
import { IsValidAccentureEmail } from "../../../Utilities/Validations";

const ProgramAcntLAppReview = (function () {
  class ProgramAcntLAppReviewPresentational extends Component {
    render() {
      let clientID = this.props.programClientDetails.ClientMasterID;
      let user = this.props.userInformation.userProfiles.find(x => x.ClientMasterId === clientID && x.ProfileID !== 16); //exclude in filter QC user
      let isProgProjConfig = user === undefined ? false : user.ProfileID === 12 || user.ProfileID === 15; //find if user role in client is BTLead
      const isReadOnly = !isProgProjConfig && !this.props.userInformation.isSuperAdmin;
      let status = this.props.arsDetails.length > 0 ? this.props.arsDetails[this.props.arsDetails.length - 1].status : '';
      const userType = this.props.userInformation.isSuperAdmin ? 'admin' : isProgProjConfig ? 'ppcUser' : '';

      return (
        <div className="BT-Lead-Landing-Page">
          {/* <ResendForApproval
            show={this.props.showResend}
            onCloseModal={this.props.onCloseResend}
            resendPopup={this.props.resendPopup}
            resendForApproval={this.props.onResendForApproval}
            onCommentChange={this.props.onChangeResendComment}
            defaultData={this.props.defaultData}
            type={"Program"}
            disableSendBtn={this.props.disableSendBtn}
          /> */}
          <ProgramProjectHeader type="Program" />
          <Stepper type="Program" />
          {this.props.PnPComponents.program.acctLeadApprv ?
            <Row className="access_denied_container">
              <Col md={12} xs={12}>
                <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                <div className="access_denied_content">
                  <h1>Access Denied</h1>
                  <p>You don't have the necessary user privileges to view the page that you are attempting to access.</p>
                </div>
              </Col>
            </Row>
            :
            <div className="BT-container">
              <ALATable
                type={"program"}
                // onCloseResend={this.props.onCloseResend}
                arsDetails={this.props.arsDetails}
                isReadOnly={isReadOnly}
              // resendPopup={this.props.resendPopup}
              checkForInvalidEmailFirst={this.props.checkForInvalidEmailFirst}
              />
              <DimensionAccordion
                type={"Program"}
                mode={"viewWithComment"}
                customBOI={this.props.SharedData.customBOI}
                ProgramConfigurationData={this.props.ProgramConfigurationData}
                dimensionList={this.props.dimensionList}
                callbackFunction={this.props.dimAccCFunc}
                status={status}
                userType={userType}
                topLeftContainer={<React.Fragment>
                  <div class='header18-semibold-midnight'>Program Target Value Settings</div>
                  <BOIHistoryModal historyType="Program" />
                </React.Fragment>}
                additionalElement={
                  <GrooveButton
                    id="primry-btn-2"
                    hasIcon={true}
                    type="outline"
                    colorClass="stat-alternate"
                    text="Edit BOIs"
                    iconAlignment="right"
                    iconSize="small"
                    iconName="edit"
                    iconStyle="solid"
                    iconId="btn-icon-01"
                    isDisabled={true}
                  />
                }
              />
            </div>
          }
        </div>
      );
    }
  }

  class ProgramAcntLAppReviewContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: true,
        step: 1,
        stage: "",
        stageDescription: "",
        showAddBOI: false,
        /* showResend: false,
        resendPopup: {
          date: "",
          time: "",
          count: 0,
        },
        defaultData: {}, */
        dimensionList: [],
        disableSendBtn: false,
        invalidEmailsList: [],
      };
    }

    checkForInvalidEmailFirst = async () => {
      let invalidEmailsList = [];
      let CXLeadList = this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.CXLLead.split(',') : [];
      let OALeadList = this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.length ? this.props.ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead.split(',') : [];
      let progCreator = this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator !== null ? [this.props.ProgramConfigurationData.selectedProgramDetails.ProgramCreator] : [];

      const data = await IsValidAccentureEmail([...CXLeadList, ...OALeadList, ...progCreator]);
      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          invalidEmailsList.push(data[i].UserName.split('@')[0]);
        }
      }

      this.setState({
        invalidEmailsList: invalidEmailsList,
      })
    }

    componentDidMount = async () => {
      try {
        const prevPath = TryGetObjValueAlt(history, '', 'location.state.ppcPrevPath').toLowerCase()
        const unRecord = ['/awaitingappboi', '/approvedboi', '/rejectedboi']
        const ppcData = this.props.ProgramConfigurationData
        if (prevPath === '' || !unRecord.some(e => prevPath.includes(e))) LogUserSiteAction({
          module: PROG_ID_VAL_ALA,
          ShouldLogPPCID: true,
          ClientProgramID: ppcData.selectedProgram.value,
          ClientProjectID: null,
          progStatus: ACC_PROG
        })
        await this.props.getProgramBOI();
        // const arsDetails = ppcData.arsDetails;
        // let oal = arsDetails[arsDetails.length - 1].lead2.split("@")[0] + "@accenture.com"
        this.setState({
          /* defaultData: {
            text: oal,
            value: oal,
            label: oal,
          },
          resendPopup: {
            date: arsDetails[arsDetails.length - 1].submittedDate,
            time: arsDetails[arsDetails.length - 1].submittedTime,
            count: arsDetails[arsDetails.length - 1].resendCounter,
          } */
        }, this.setNewProgDetails)
      }
      catch (e) {
        console.log(e);
      }
    }
    componentDidUpdate(prevProps) {
      if (this.props.ProgramConfigurationData.dimensionList !== prevProps.ProgramConfigurationData.dimensionList) {
        this.formatBOIs();
      }

    }
    setNewProgDetails = () => {
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      if (programDetails.ClientProgramID) {
        let subOff = [];
        let cxl = [];
        let oal = [];
        programDetails.SubOfferingList.map((item) => {
          subOff.push({
            label: item.SubOfferingName,
            value: item.SubOfferingID
          })
        });

        programDetails.OperationsAccountLead.split(',').map(x => {
          oal.push({
            label: x,
            text: x,
            value: x
          });
        });

        programDetails.CXLLead.split(',').map(x => {
          cxl.push({
            label: x,
            text: x,
            value: x
          });
        });

        newProgramDetails.offering = { "label": programDetails.Offering, "text": programDetails.Offering, "value": programDetails.OfferingID };
        newProgramDetails.subOffering = subOff;
        newProgramDetails.programName = programDetails.ClientProgramName;
        newProgramDetails.cxLead = cxl;
        newProgramDetails.oaLead = oal;
        newProgramDetails.progCreator = { "label": programDetails.ProgramCreator, "text": programDetails.ProgramCreator, "value": programDetails.ProgramCreator };

        this.props.setNewProgramDetails(newProgramDetails);
      }
    }
    setStep = (param) => {
      try {
        this.setState({
          step: param.step,
          stage: param.stage,
          stageDescription: param.desc,

        });
      }
      catch (e) {
        console.log(e);
      }
    }

    onCloseModal = () => {
      try {
        this.setState({
          ...this.state,
          show: !this.state.show,
        });
      }
      catch (e) {
        console.log(e);
      }
    };

    onCloseAddBOI = () => {
      try {
        this.setState({
          ...this.state,
          showAddBOI: !this.state.showAddBOI,
        });
      }
      catch (e) {
        console.log(e)
      }
    }

    onSelectOption = (e) => {
      try {
        this.setState({
          value: e
        })
      }
      catch (e) {
        console.log(e)
      }
    }

    /* onCloseResend = () => {
      try {
        const arsDetails = this.props.ProgramConfigurationData.arsDetails;
        this.setState({
          showResend: !this.state.showResend,
          resendPopup: {
            date: arsDetails[arsDetails.length - 1].submittedDate,
            time: arsDetails[arsDetails.length - 1].submittedTime,
            count: arsDetails[arsDetails.length - 1].resendCounter,
          }
        }, () => {
          this.props.setResendPayload();
        })

      }
      catch (e) {
        console.log(e);
      }
    } */

    onResendForApproval = () => {
      try {
        this.setState({
          disableSendBtn: true
        }, async () => {
          await this.props.sendProgResend();
          this.setState({
            disableSendBtn: false
          })
        })
      }
      catch (e) {
        console.log(e);
      }
    }

    onChangeResendComment = (e) => {
      try {
        this.props.setResendComment(e)
      }
      catch (e) {
        console.log(e)
      }
    }
    formatBOIs = () => {
      let tempBois = this.props.ProgramConfigurationData.dimensionList
      //sort boi
      tempBois.map((dim) => {
        dim.subDimensionList.map((subdim, index) => {
          let tempFilterList = [];
          let tempCustomList = [];
          let sortedBoiList = [];
          let customBoiList = [];
          let filteredBoiList = [];
          // subdim.boiList.sort(function(a,b){
          //   return a.boiParentID - b.boiParentID
          // })

          //stores custom boi list
          customBoiList = subdim.boiList.filter((e) => e.boiParentID === 2);
          customBoiList.forEach((e) => {
            tempCustomList.push(e);
          });

          //removes custom from boilist and sorts boi parent id
          filteredBoiList = subdim.boiList.filter((e) => e.boiParentID !== 2);
          filteredBoiList.sort((a, b) => a.boiParentID - b.boiParentID);
          filteredBoiList.forEach((e) => {
            tempFilterList.push(e);
          });

          sortedBoiList.push(...tempFilterList, ...tempCustomList);

          subdim.boiList = sortedBoiList;
        });
      });
      this.setState(
        {
          dimensionList: tempBois,
        });
    }

    render() {

      const {
        arsDetails,
        selectedProgramDetails,
        programClientDetails
      } = this.props.ProgramConfigurationData
      const { PnPComponents } = this.props.AccessValidationData;
      const { userInformation } = this.props;
      return <ProgramAcntLAppReviewPresentational

        setStep={this.setStep}
        showAddBOI={this.state.showAddBOI}
        onCloseAddBOI={this.onCloseAddBOI}
        onExpandCollapse={this.onExpandCollapse}
        // onCloseResend={this.onCloseResend}
        // showResend={this.state.showResend}
        // resendPopup={this.state.resendPopup}
        onResendForApproval={this.onResendForApproval}
        onChangeResendComment={this.onChangeResendComment}
        // defaultData={this.state.defaultData}
        dimensionList={this.state.dimensionList}
        //reducers
        PnPComponents={PnPComponents}
        arsDetails={arsDetails}
        selectedProgramDetails={selectedProgramDetails}
        userInformation={userInformation}
        programClientDetails={programClientDetails}

        SharedData={this.props.SharedData}
        ProgramConfigurationData={this.props.ProgramConfigurationData}
        disableSendBtn={this.state.disableSendBtn}
        checkForInvalidEmailFirst={this.checkForInvalidEmailFirst}
      />;
    }
  }

  return ProgramAcntLAppReviewContainer;
})();


export default connect(
  state => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
      AccessValidationData: state.AccessValidationData,
      userInformation: state.userInformation,
      SharedData: state.SharedData
    }
  },
  dispatch => bindActionCreators(Object.assign({},
    {
      getProgramBOI,
      setNewProgramDetails,
      setResendPayload,
      sendProgResend,
      setResendComment,
      getCustomBOIDetails
    }), dispatch)
)(ProgramAcntLAppReview);