import React, { Component } from "react";
import { connect } from "react-redux";
import store, { history } from "../../../store";
import { bindActionCreators } from "redux";
import addfiles from "../../../Static/images/360cvm/AddFilesIcon.svg";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import {
  GrooveIconSvg,
  GrooveInput,
  GrooveButton,
  GrooveRadioButton,
} from "tfo-groove";
import { withRouter } from "react-router-dom";
import "./ProjectIdentified.css";
import SelectBOI from "./SelectBOI";
import CVMViewComments from "../../Shared/CVMViewComments/CVMViewComments";
import CVMRejectionModal from "../../Shared/CVMRejectionModal/CVMRejectionModal";
import CVMConditionalApproveModal from "../../Shared/CVMConditionalApproveModal/CVMConditionalApproveModal";
import CVMApprovalRequestModal from "../../Shared/CVMApprovalRequestModal/CVMApprovalRequestModal";
import CVMSubmitRejectionModal from "../../Shared/CVMSubmitRejectionModal/CVMSubmitRejectionModal";
import CVMModal from "../../Shared/CVMModal/CVMModal";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoIcon from "./../../../Static/images/360cvm/InfoIcon.svg";
import { HttpGet, HttpPost } from "../../../Utilities/HTTPUtil";
import Utilities from "../../../Utilities";
import Dimension from "./ProjectIdentified/Dimension";

import { setToasterParam } from "../../../Actions/Shared.Action";

const ProjectIdentified = (function() {
  class ProjectIdentifiedPresentational extends Component {
    constructor() {
      super();
      this.state = {
      };
    }

    setSelectedProject = (e) => {
      let { textContent } = e.target;
      this.props.setSelectedProject(textContent);
    };


    componentDidMount() {
    }

    render() {
      console.log("ProjectIdentified props", this.props);
      console.log("ProjectIdentified states", this.state);
      let isActiveBOI =
        Object.keys(this.props.selectedProjectData).length > 0 &&
        this.props.selectedProjectData.ClientProjectTargets.Dimensions.find(
          (dimension) =>
            dimension.SubDimensionList.find(
              (subDimension) =>
                subDimension.BOIList.length > 0 &&
                subDimension.BOIList.find((boi) => boi.IsActive === true)
            )
        );
      let { programs, selectedProjectData, projectCount, toggleSelectBOIPopupState } = this.props;
      return (
        <React.Fragment>
          <div className="client-config-container">
            <div className="stepper-tab-left-section">
              <div className="tab-section-header header18-semibold-midnight">
                Programs ({programs.length})
              </div>
              <div className="subtab-list">
                {/* {programs.map((program) => { */}
                  {/* return ( */}
                    <Accordion defaultActiveKey={selectedProjectData.ClientProgramID ? selectedProjectData.ClientProgramID.toString() : "0"}>                    
                    {programs.map((program) => {
                      if (program.ClientProjects) {
                        return (
                          <Card>
                            <Accordion.Collapse
                              eventKey={
                                program.Id.toString()
                              }
                            >
                              <Card.Body>
                                <div className="tab-section-header header18-semibold-midnight">
                                  Projects ({program.ClientProjects.length})
                                </div>
                                <ul className="bodytext16-medium-midnight">
                                  {program.ClientProjects.map((project) => {
                                    return (
                                      <li
                                        className={
                                          selectedProjectData.ID === project.ID
                                            ? "active"
                                            : null
                                        }
                                        value={project.ClientProjectName}
                                        onClick={(e) => this.setSelectedProject(e)}
                                      >
                                        {project.ClientProjectName}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                            <Card.Header>
                              <Row>
                                <Col>
                                  {program.ClientProgramName}
                                  <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey={
                                      program.Id.toString()
                                    }
                                  >
                                    <GrooveIconSvg
                                      customClassName="accordion-button"
                                      size="tiny"
                                      name="chevron-down"
                                      iconStyle="solid"
                                      iconColor="stat-neutral"
                                    />
                                  </Accordion.Toggle>
                                </Col>
                              </Row>
                            </Card.Header>
                          </Card>
                          );
                      }
                      else{
                        return null;
                      }
                      
                      
                    })}
                    </Accordion>
                  {/* );
                })} */}
              </div>
            </div>
            <div className="stepper-tab-right-section">
            {this.props.isRejected && this.props.role !== "Operations Account Lead" ? 
              <div className="container-PIV">
                <div className="container-PIV-box">
                  <div className="errorbox" onClick={this.props.showCommentsSection}>
                    This Project Target Value settings has been rejected by the
                    Operations Account Lead {this.props.value.value}. Please click here
                    to view the rejection comment.
                  </div>
                </div>
              </div>
            : null
            }
                <div>
                  <Row className="tab-header-row">
                  {Object.keys(selectedProjectData).length > 0 && (
                    <div className="bodytext18-medium-midnight">
                      {selectedProjectData.ClientProjectName} Dimensions                    
                    </div>
                  )}
                    <div>
                      <Row className="tab-header-row-left">
                        <GrooveButton
                          id="primry-btn-1"
                          hasIcon={true}
                          type=""
                          colorClass=""
                          text="View Comment"
                          callbackFunction={this.props.showCommentsSection}
                          customClassName="viewComment-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="comment"
                          iconStyle="regular"
                          iconId="btn-icon-01"
                          isDisabled={Object.keys(this.props.commentList).length > 0 
                            ? this.props.commentList.ReJectedList.length > 0 || this.props.commentList.ConditionalApprovedList.length > 0 
                            ? false
                            : true
                            : true}
                        />
                        {this.props.showcomments && (
                          <CVMViewComments
                            projectID={
                              Object.keys(selectedProjectData).length > 0 &&
                              selectedProjectData.ID
                            }
                            show={this.props.showcomments}
                            commentList={this.props.commentList}
                            closeCommentsSection={this.props.closeCommentsSection}
                          />
                        )}
                        {Object.keys(selectedProjectData).length > 0 && (
                          <>
                        <GrooveButton
                          id="expandAll"
                          hasIcon={false}
                          type=""
                          colorClass=""
                          text="Expand All"
                          callbackFunction={(e) => this.props.onExpandCollapse(e)}
                          customClassName="expandAll-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="plus"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                        />
                        <GrooveButton
                          id="collapseAll"
                          hasIcon={false}
                          type=""
                          colorClass=""
                          text="Collapse All"
                          callbackFunction={(e) => this.props.onExpandCollapse(e)}
                          customClassName="collapseAll-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="plus"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                        />                        
                        <GrooveButton
                          id="primry-btn-2"
                          hasIcon={true}
                          type="solid"
                          colorClass="stat-alternate"
                          text={
                            this.props.role === "Operations Account Lead"
                              ? " Edit BOIs"
                              : Object.keys(selectedProjectData).length > 0
                              ? selectedProjectData.ClientProjectTargets.Dimensions.length > 0 
                              ? " Edit BOIs"
                              : " Select BOIs"
                              : " Select BOIs"
                          }
                          iconAlignment="right"
                          iconStyle="solid"
                          iconName="plus"
                          isDisabled={
                            this.props.role !== "Operations Account Lead" && this.props.selectedProjectData.IsReadOnly
                              ? true
                              : Object.keys(this.props.selectedProjectData).length > 0
                              ? this.props.role === "Operations Account Lead"
                              ? true
                              : false
                              : true
                          }
                          callbackFunction={() => this.props.toggleSelectBOIPopup()}
                          iconSize="tiny"
                          iconId="btn-icon-01"
                        />
                        </>                       
                        )}
                      </Row>
                    </div>
                  </Row>
                  {Object.keys(selectedProjectData).length > 0 && (
                    <>
                  {isActiveBOI &&
                  selectedProjectData.ClientProjectTargets.Dimensions.length >
                    0 ? (
                    selectedProjectData.ClientProjectTargets.Dimensions.map(
                      (dimension, dimensionIndex) => {
                        let isBOIAdded = dimension.SubDimensionList.find(
                          (subDimension) =>
                            subDimension.BOIList.length > 0 &&
                            subDimension.BOIList.find(
                              (boi) => boi.IsActive === true
                            )
                        );
                        if (isBOIAdded) {
                          return (
                            <Dimension                            
                              dimensionName={dimension.CoreDimensionName}
                              dimensionID={dimension.CoreDimensionID}
                              dimensionTarget={dimension.CoreDimensionTarget}
                              subDimension={dimension.SubDimensionList}
                              dimensionReason={dimension.CoreDimensionReason}
                              dimensionStatus={dimension.CoreDimensionStatus}
                              dimensionIndex={dimensionIndex}
                              updateDimension={this.props.handleDimensionField}
                              updateSubDimension={
                                this.props.handleSubDimensionField
                              }
                              updateBOI={this.props.handleBOIInputField}
                              role={this.props.role}
                              IsReadOnly={this.props.selectedProjectData.IsReadOnly}
                              selectedProjectID={selectedProjectData.ClientProjectTargets.ClientProjectID}
                              isSubmitClicked = {this.props.isSubmitClicked}
                            />
                          );
                        }
                      }
                    )
                  ) : (
                    <div className="empty-dimension-container">
                      <div className="empty-program-container bodytext18-regular-slate">
                          <img src={addfiles} alt="select client" />
                          <span className="bodytext24-medium-slate">
                            No Business Outcome Indicator Added
                          </span>
                          Please Select BOI(s) to this Project by using the
                          'Select BOIs' button above.
                      </div>
                    </div>
                  )}
                  </>
                  )}
                </div>
              {Object.keys(selectedProjectData).length > 0 && isActiveBOI && (
                <div className="program-identified-buttons">
                  <GrooveButton
                    id="outline-btn-1"
                    name="Outline Button Slate"
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    isDisabled={
                      this.props.role !== "Operations Account Lead" && this.props.selectedProjectData.IsReadOnly
                        ? true
                        : this.props.BOIData.length > 0
                        ? false
                        : true
                    }
                    text="Save as Draft"
                    callbackFunction={() =>
                      this.props.postCall("Save as Draft")
                    }
                  />
                  <GrooveButton
                    id="primry-btn-2"
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    text={
                      this.props.role === "Operations Account Lead"
                        ? "Submit"
                        : "Send for Approval"
                    }
                    isDisabled={
                      this.props.role !== "Operations Account Lead" && this.props.selectedProjectData.IsReadOnly
                        ? true
                        : this.props.BOIData.length > 0
                        ? false
                        : true
                    }
                    callbackFunction={() =>
                      this.props.role === "Operations Account Lead"
                        ? this.props.onCloseSubmitSettingsModal()
                        : this.props.onApprovalRequest()
                    }
                  />
                </div>
              )}
            </div>
          </div>

          {toggleSelectBOIPopupState && (
            <SelectBOI
              show={toggleSelectBOIPopupState}
              programID={selectedProjectData.ClientProgramID}
              programName={selectedProjectData.ClientProjectName}
              selectedBOIData={this.props.BOIData}
              toggleSelectBOIPopup={this.props.toggleSelectBOIPopup}
              selectedBOIs={this.props.selectedBOIs}
              selectedProjectData={this.props.selectedProjectData}
            />
          )}

          {this.props.rejectionModal.show && (
            <CVMRejectionModal
              {...this.props.rejectionModal}
              onChangeDimension={this.props.handleDimensionField}
              onChangeSubDimension={this.props.handleSubDimensionField}
              onChangeBOI={this.props.handleBOIInputField}
              step={this.props.step}
              send={this.sendUpdatedReason}
              closeReasonModal={this.props.closeReasonModal}
            />
          )}
          {this.props.conditionalModal.show && (
            <CVMConditionalApproveModal
              {...this.props.conditionalModal}
              onChangeDimension={this.props.handleDimensionField}
              onChangeSubDimension={this.props.handleSubDimensionField}
              onChangeBOI={this.props.handleBOIInputField}
              step={this.props.step}
              send={this.sendUpdatedReason}
              closeReasonModal={this.props.closeReasonModal}
            />
          )}
          <CVMModal
            title={"Send for Approval"}
            show={this.props.approvalRequestModal}
            onCloseModal={this.props.closeRequestModal}
            customclass={"notifyModal-dialog"}
            content={
              <CVMApprovalRequestModal
                label={"Approver ID"}
                onCloseModal={this.props.closeRequestModal}
                clientList={this.props.clientList}
                actionType={"Send"}
                value={this.props.value}
                sendApproval={this.props.postCall}
                sendForApprovalComment={this.props.sendForApprovalComment}
                disableSendBtn={this.props.disableSendBtn}
              />
            }
          />

          <CVMModal
            title={"Submit Settings"}
            customclass={"submitSettings-dialog"}
            show={this.props.showSubmitSettingsModal}
            onCloseModal={this.props.onCloseSubmitSettingsModal}
            content={
              <CVMSubmitRejectionModal
                onCloseModal={this.props.onCloseSubmitSettingsModal}
                selectedProgramDetails={
                  Object.keys(this.props.selectedProjectData).length > 0 &&
                  this.props.selectedProjectData.ClientProjectTargets.Dimensions
                }
                isRejected={this.props.isRejected}
                Files={this.props.Files}
                onDropAccepted={this.props.onDropAccepted}
                onDropRejected={this.props.onDropRejected}
                onDeleteFile={this.props.onDeleteFile}
                onSubmitClick={this.props.postCall}
                selectedProjectData={this.props.selectedProjectData}
                clearUpload={this.props.clearUpload}
                type="Project"
                label={
                  !this.props.isRejected ? "Mapped Stakeholders" : "Project Lead"
                }
                submitComment={this.props.submitComment}
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class ProjectIdentifiedContainer extends Component {
    constructor() {
      super();
      this.state = {
        showRejection: false,
        showcomments: false,
        approvalRequestModal: false,
        showConditionalApproveModal: false,
        showSubmitSettingsModal: false,
        Files: [],
        ApproverData: [],
        value: {
          value: "",
          label: "",
          text: "",
        },
        clientList: [
          {
            value: "",
            label: "",
            text: "",
          }          
        ],
        rejectionModal: {
          show: false,
          type: "",
          name: "",
          value: "",
          reason: "",
          dimensionIndex: "",
          subDimensionIndex: "",
          boiIndex: "",
        },
        conditionalModal: {
          show: false,
          type: "",
          name: "",
          value: "",
          reason: "",
          dimensionIndex: "",
          subDimensionIndex: "",
          boiIndex: "",
        },
        isRejected: false,
        commentList: [],
        isNull: false,
        BOIData: [],
        toggleSelectBOIPopup: false,
        submitComment: "",
        sendForApprovalComment: "",
        isSubmitClicked: false,
        currentBOIStatus: {
          status: "",
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0,
        },
        selectedProjectData: [],
        disableSendBtn: false,
      };
    }

    getApproverData = async () => {
      const clienID = store.getState().SharedData.selectedClient.value;
      const Approverdata = await HttpGet(
        `ProjectIdentifiedValueSetting/GetApproverDetails/clientID?clientID=${clienID}`
      );
      try {
        let value = {
          value: Approverdata[0].UserEmailID,
          label: Approverdata[0].UserEmailID,
          text: Approverdata[0].UserEmailID,
        };
        this.setState({ ApproverData: Approverdata, value: value });
      } catch (error) {
        console.log(error);
      }
    };

    componentDidMount = () => {
      this.getApproverData();
        this.setMappedBOI();
    };

    componentDidUpdate = (nextProps) => {
      if(JSON.stringify(this.props.selectedProjectData) !== JSON.stringify(this.state.selectedProjectData)){
        this.checkRejectedStatus();
        this.getCommentList();
        this.setMappedBOI();
        this.checkNullBOI();
        this.setState({
          selectedProjectData: this.props.selectedProjectData
        })
      }
    }

    clearUpload = () => {
      this.setState({
        Files: [],
      });
    }

    getCommentList = async () => {
      let selectedProjectID = this.props.selectedProjectData.ClientProjectTargets.ClientProjectID;
      const res = await HttpGet(
        `ProjectIdentifiedValueSetting/GetProjectBOIApprovalList/clientProjectId?clientProjectId=${selectedProjectID}`
      )
      this.setState({
        commentList: res,
      })
    };

    checkRejectedStatus() {
      this.setState({
        isRejected: false //set default value for isRejected
      }, () => {
          let project = this.props.selectedProjectData.ClientProjectTargets.Dimensions;
          let isRejected = false;
          for(var i = 0; i < project.length && !isRejected; i++){
            if (project[i].CoreDimensionStatus === "Rejected") {
              isRejected = true;
              break;
            }
            else {
              for (var j = 0; j < project[i].SubDimensionList.length && !isRejected; j++){
                if (project[i].SubDimensionList[j].SubDimensionStatus === "Rejected") {
                  isRejected = true;
                  break;
              }
              else {
                for (var k = 0; k < project[i].SubDimensionList[j].BOIList.length && !isRejected; k++) {
                  if (project[i].SubDimensionList[j].BOIList[k].BOIStatus === "Rejected") {
                    isRejected = true;
                    break;
                  }
                }
                }
              }
            }
          }
          this.setState({
            isRejected: isRejected
          }) 
      })     
    }

    checkNullBOI = () => {
      let project = this.props.selectedProjectData.ClientProjectTargets.Dimensions
      let isNull = false;

      for(var i = 0; i < project.length; i++){
        for (var j = 0; j < project[i].SubDimensionList.length; j++){
          for (var k = 0; k < project[i].SubDimensionList[j].BOIList.length; k++) {
            if (project[i].SubDimensionList[j].BOIList[k].BOITarget === "" || project[i].SubDimensionList[j].BOIList[k].BOITarget === undefined) {
              isNull = true;
              break;
            }
          }
        }
      }
      this.setState({
        isNull: isNull,
      })
    }

    onRadioButtonSelect = (event, type, index) => {
      let tempCurrentDimension = this.props.selectedProjectData;
      console.log("Radio button data", event, type, index);
      switch (event.label) {
        case "Reject":
          this.setState({
            rejectionModal: {
              show: true,
              name:
                tempCurrentDimension.ClientProjectTargets[index][
                  "CoreDimensionName"
                ],
              type: type,
              value:
                tempCurrentDimension.ClientProjectTargets[index][
                  `${type}Target`
                ],
              reason:
                tempCurrentDimension.ClientProjectTargets[index][
                  `${type}Reason`
                ],
              dimensionIndex: index,
              subDimensionIndex: index,
              boiIndex: index,
            },
            conditionalModal: {
              show: false,
              type: "",
              name: "",
              value: "",
              reason: "",
              dimensionIndex: "",
              subDimensionIndex: "",
              boiIndex: "",
            },
          });
          break;
        case "Conditional Approval":
          this.setState({
            rejectionModal: {
              show: false,
              type: "",
              name: "",
              value: "",
              reason: "",
              dimensionIndex: "",
              subDimensionIndex: "",
              boiIndex: "",
            },
            conditionalModal: {
              show: true,
              name:
                tempCurrentDimension.ClientProjectTargets[index][
                  "CoreDimensionName"
                ],
              type: type,
              value:
                tempCurrentDimension.ClientProjectTargets[index][
                  `${type}Target`
                ],
              reason:
                tempCurrentDimension.ClientProjectTargets[index][
                  `${type}Reason`
                ],
              dimensionIndex: index,
              subDimensionIndex: index,
              boiIndex: index,
            },
          });
          break;
        default:
          this.setState({
            rejectionModal: {
              show: false,
              type: "",
              name: "",
              value: "",
              reason: "",
              dimensionIndex: "",
              subDimensionIndex: "",
              boiIndex: "",
            },
            conditionalModal: {
              show: false,
              type: "",
              name: "",
              value: "",
              reason: "",
              dimensionIndex: "",
              subDimensionIndex: "",
              boiIndex: "",
            },
          });
          break;
      }
    };

    showCommentsSection = () => {
      this.setState({ showcomments: true });
    };
    closeCommentsSection = () => {
      this.setState({ showcomments: false });
    };

    closeRequestModal = () => {
      this.setState({
        approvalRequestModal: false,
      });
    };
    onApprovalRequest = () => { 
      this.setState({
        isSubmitClicked: true
      })
      if (this.state.isNull === false){
        this.setState({
          approvalRequestModal: true,
        });
      }   
    };

    showRejectionModal = () => {
      this.setState({
        showRejection: true,
      });
    };
    closeRejectionModal = () => {
      this.setState({
        showRejection: false,
      });
    };

    closeConditionalApproveModal = () => {
      this.setState({
        showConditionalApproveModal: false,
      });
    };
    onCloseSubmitSettingsModal = () => {
      this.setState({
        isSubmitClicked: true
      })
      this.checkRejectedStatus();
      if(this.state.isNull === false){
        this.setState({
          ...this.state,
          showSubmitSettingsModal: !this.state.showSubmitSettingsModal,
        });
      }
    };

    onChangeClient = (client) => {
      console.log("clientval", client);
    };

    closeReasonModal = (type) => {
      let tempCurrentDimension = this.props.selectedProjectData.ClientProjectTargets.Dimensions;
      tempCurrentDimension[this.state.currentBOIStatus.dimensionIndex]["SubDimensionList"][this.state.currentBOIStatus.subDimensionIndex]["BOIList"][this.state.currentBOIStatus.boiIndex]["BOIStatus"] = this.state.currentBOIStatus.status
      
      this.setState({
        currentBOIStatus: {
          status: "",
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0,
        },
      })
      switch (type) {
        case "Reject":
          this.setState({
            rejectionModal: {
              show: false,
            },
          });
          break;
        case "Conditional":
          this.setState({
            conditionalModal: {
              show: false,
            },
          });
          break;
      }
    };

    handleDimensionField = (event, type, dimensionIndex) => {
      let selectedProjectData = this.props.selectedProjectData;
      switch (type) {
        case "radioButton":
          let tempCurrentDimension = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          tempCurrentDimension[dimensionIndex]["CoreDimensionStatus"] =
            event.value;
          selectedProjectData.ClientProjectTargets.Dimensions = tempCurrentDimension;
          switch (event.label) {
            case "Reject":
              this.setState({
                rejectionModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionName"],
                  type: "Dimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            case "Conditional Approval":
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionName"],
                  type: "Dimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["CoreDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            default:
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
          }
          this.checkRejectedStatus();
          break;
        case "inputReason":
          let tempReason;
          tempReason = event.value;
          this.setState({
            tempReason: tempReason,
          });
          break;
        case "saveReason":
          let tempDimensionReason = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          tempDimensionReason[dimensionIndex][
            "CoreDimensionReason"
          ] = this.state.tempReason;
          selectedProjectData.ClientProjectTargets.Dimensions = tempDimensionReason;
          this.setState({
            rejectionModal: {
              show: false,
            },
            conditionalModal: {
              show: false,
            },
          });
          break;
        case "inputIdentified":
          let val = event.value;
          if(val.toFixed(2) >= 9999.99){
            val = 9999.99
          } 
          let tempDimensionInput = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          tempDimensionInput[dimensionIndex]["CoreDimensionTarget"] = val;
          selectedProjectData.ClientProjectTargets.Dimensions = tempDimensionInput;
          break;
        default:
      }
      this.props.onProjectDataUpdate(selectedProjectData);
    };
    handleSubDimensionField = (
      event,
      type,
      dimensionIndex,
      subDimensionIndex
    ) => {
      let selectedProjectData = this.props.selectedProjectData;
      switch (type) {
        case "radioButton":
          let tempCurrentDimension = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          let tempCurrentSubDimension =
            tempCurrentDimension[dimensionIndex]["SubDimensionList"];
          tempCurrentSubDimension[subDimensionIndex]["SubDimensionStatus"] =
            event.value;
          selectedProjectData.ClientProjectTargets.Dimensions = tempCurrentDimension;
          switch (event.label) {
            case "Reject":
              this.setState({
                rejectionModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionName"],
                  type: "SubDimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: "",
                },
              });
              break;
            case "Conditional Approval":
              this.setState({
                conditionalModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionName"],
                  type: "SubDimension",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionTarget"],
                  reason:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["SubDimensionReason"],
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: "",
                },
              });
              break;
            default:
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
          }
          this.checkRejectedStatus();
          break;
        case "inputReason":
          let tempReason;
          tempReason = event.value;
          this.setState({
            tempReason: tempReason,
          });
          break;
        case "saveReason":
          let tempCurrentDimensionSave = this.props.selectedProjectData.ClientProjectTargets.Dimensions;
          let tempCurrentSubDimensionSave =
            tempCurrentDimensionSave[dimensionIndex]["SubDimensionList"];
          tempCurrentSubDimensionSave[subDimensionIndex][
            "SubDimensionReason"
          ] = this.state.tempReason;
          selectedProjectData.ClientProjectTargets.Dimensions = tempCurrentDimensionSave;
          this.setState({
            rejectionModal: {
              show: false,
            },
            conditionalModal: {
              show: false,
            },
          });
          break;
        case "inputIdentified":
          let val = event.value;
          if(val.toFixed(2) >= 9999.99){
            val = 9999.99
          } 
          let tempCurrentDimensionIdentified = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          let tempCurrentSubDimensionIdentified =
            tempCurrentDimensionIdentified[dimensionIndex]["SubDimensionList"];
          tempCurrentSubDimensionIdentified[subDimensionIndex][
            "SubDimensionTarget"
          ] = event.textLength === 0 ? "" : val;
          selectedProjectData.ClientProjectTargets.Dimensions = tempCurrentDimensionIdentified;
          break;
        default:
      }
      this.props.onProjectDataUpdate(selectedProjectData);
    };

    handleBOIInputField = (event, type, dimensionIndex, subDimensionIndex, boiIndex) => {
      let selectedProjectData = this.props.selectedProjectData;
      switch (type) {
        case "radioButton":
          let tempCurrentDimension = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          let currentStatus = tempCurrentDimension[dimensionIndex]["SubDimensionList"][subDimensionIndex]["BOIList"][boiIndex]["BOIStatus"];
              if(this.state.currentBOIStatus.status === "" 
                || this.state.currentBOIStatus.dimensionIndex !== dimensionIndex && this.state.currentBOIStatus.dimensionIndex !== 0
                || this.state.currentBOIStatus.subDimensionIndex !== subDimensionIndex && this.state.currentBOIStatus.subDimensionIndex !== 0
                || this.state.currentBOIStatus.boiIndex !== boiIndex && this.state.currentBOIStatus.boiIndex !== 0){
                  if(this.state.currentBOIStatus.dimensionIndex !== dimensionIndex && this.state.currentBOIStatus.dimensionIndex !== 0
                    || this.state.currentBOIStatus.subDimensionIndex !== subDimensionIndex && this.state.currentBOIStatus.subDimensionIndex !== 0
                    || this.state.currentBOIStatus.boiIndex !== boiIndex && this.state.currentBOIStatus.boiIndex !== 0){
                      tempCurrentDimension[this.state.currentBOIStatus.dimensionIndex]["SubDimensionList"][
                        this.state.currentBOIStatus.subDimensionIndex
                      ]["BOIList"][this.state.currentBOIStatus.boiIndex]["BOIStatus"] = this.state.currentBOIStatus.status
                    }
                  this.setState({
                    currentBOIStatus: {
                      status: tempCurrentDimension[dimensionIndex]["SubDimensionList"][subDimensionIndex]["BOIList"][boiIndex]["BOIStatus"],
                      dimensionIndex: dimensionIndex,
                      subDimensionIndex: subDimensionIndex,
                      boiIndex: boiIndex,
                    },
                  })
                }
            
          let tempCurrentSubDimensionRadio =
            tempCurrentDimension[dimensionIndex]["SubDimensionList"];
          let tempCurrentBOIRadio =
            tempCurrentSubDimensionRadio[subDimensionIndex]["BOIList"];
          tempCurrentBOIRadio[boiIndex]["BOIStatus"] = event.value;
          selectedProjectData.ClientProjectTargets.Dimensions = tempCurrentDimension;
          switch (event.label) {
            case "Reject":
              this.setState({
                rejectionModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOIName"],
                  type: "BOI",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOITarget"],
                  reason: currentStatus === "Rejected"?
                  tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                    subDimensionIndex
                  ]["BOIList"][boiIndex]["BOIReason"] : "",
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: boiIndex,
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            case "Conditional Approval":
              this.setState({
                conditionalModal: {
                  show: true,
                  name:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOIName"],
                  type: "BOI",
                  value:
                    tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                      subDimensionIndex
                    ]["BOIList"][boiIndex]["BOITarget"],
                  reason: currentStatus === "Conditional Approval"?
                  tempCurrentDimension[dimensionIndex]["SubDimensionList"][
                    subDimensionIndex
                  ]["BOIList"][boiIndex]["BOIReason"] : "",
                  dimensionIndex: dimensionIndex,
                  subDimensionIndex: subDimensionIndex,
                  boiIndex: boiIndex,
                },
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
            case "Approve":
              let tempCurrentDimensionSave = this.props.selectedProjectData
                .ClientProjectTargets.Dimensions;
              let tempCurrentSubDimensionSave =
                tempCurrentDimensionSave[dimensionIndex]["SubDimensionList"];
              let tempCurrentBOISave =
                tempCurrentSubDimensionSave[subDimensionIndex]["BOIList"];
              tempCurrentBOISave[boiIndex]["BOIReason"] = "";
              this.setState({
                currentBOIStatus: {
                  status: "",
                  dimensionIndex: 0,
                  subDimensionIndex: 0,
                  boiIndex: 0,
                },
              })
            default:
              this.setState({
                rejectionModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
                conditionalModal: {
                  show: false,
                  type: "",
                  name: "",
                  value: "",
                  reason: "",
                  dimensionIndex: "",
                  subDimensionIndex: "",
                  boiIndex: "",
                },
              });
              break;
          }
          this.checkRejectedStatus();
          break;
        case "inputReason":
          let tempReason;
          tempReason = event.value;
          this.setState({
            tempReason: tempReason,
          });
          break;
        case "saveReason":
          let tempCurrentDimensionSave = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          let tempCurrentSubDimensionSave =
            tempCurrentDimensionSave[dimensionIndex]["SubDimensionList"];
          let tempCurrentBOISave =
            tempCurrentSubDimensionSave[subDimensionIndex]["BOIList"];
          tempCurrentBOISave[boiIndex]["BOIReason"] = this.state.tempReason;
          selectedProjectData.ClientProjectTargets.Dimensions = tempCurrentDimensionSave;
          this.setState({
            rejectionModal: {
              show: false,
            },
            conditionalModal: {
              show: false,
            },
            currentBOIStatus: {
              status: "",
              dimensionIndex: 0,
              subDimensionIndex: 0,
              boiIndex: 0,
            },
          });
          break;
        case "inputIdentified":
          let val = event.value;
          if(val.toFixed(2) >= 999999999999.99){
            val = 999999999999.99
          }  
          let tempCurrentDimensionIdentified = this.props.selectedProjectData
            .ClientProjectTargets.Dimensions;
          let tempCurrentBOIIdentified =
            tempCurrentDimensionIdentified[dimensionIndex]["SubDimensionList"][
              subDimensionIndex
            ]["BOIList"];
          tempCurrentBOIIdentified[boiIndex]["BOITarget"] = event.textLength === 0 ? "" : val;
          selectedProjectData.ClientProjectTargets.Dimensions = tempCurrentDimensionIdentified;
          break;
        default:
      }
      this.props.onProjectDataUpdate(selectedProjectData);
      this.checkNullBOI();
    };

    

    postCall = async (btnType, stakeholders) => {
      let { selectedProjectData } = this.props;
      const tempBOIList = [];
      selectedProjectData.ClientProjectTargets.Dimensions.map((dimension) => {
        return dimension.SubDimensionList.map((subDimension) => {
          return subDimension.BOIList.map((boi) => {
            if(boi.IsActive){
              var approvalData = {
                clientProjectID:
                  selectedProjectData.ClientProjectTargets.ClientProjectID,
                clientProjectTargetID:
                  boi.ClientProjectTargetID,
                boiid: boi.BOIID,
                uom: boi.UOM,
                boiName: boi.BOIName,
                boiDescription: boi.BOIDescription,
                boiTarget: boi.BOITarget === "" ? 0 : boi.BOITarget,
                boiStatus: boi.BOIStatus === null || boi.BOIStatus === "" ? "Approve" : boi.BOIStatus,
                boiReason: boi.BOIReason,
                isActive: boi.IsActive,
                coreDimensionID: dimension.CoreDimensionID,
                coreDimensionName: dimension.CoreDimensionName,
                coreDimensionTarget: dimension.CoreDimensionTarget,
                coreDimensionStatus: dimension.CoreDimensionStatus === null || dimension.CoreDimensionStatus === ""  ? "Approve" : dimension.CoreDimensionStatus,
                coreDimensionReason: dimension.CoreDimensionReason,
                subDimensionID: subDimension.SubDimensionID,
                subDimensionName: subDimension.SubDimensionName,
                subDimensionTarget: subDimension.SubDimensionTarget,
                subDimensionStatus:  subDimension.SubDimensionStatus === null || subDimension.SubDimensionStatus === "" ? "Approve" : subDimension.SubDimensionStatus,
                subDimensionReason: subDimension.SubDimensionReason,
                approver: "",
                rejectionRecipient: stakeholders,
                createdUser: this.props.userInformation.enterpriseID,
                createdTS: new Date(),
                updatedUser: this.props.userInformation.enterpriseID,
                updatedTS: new Date(),
              };
              tempBOIList.push(approvalData);
            }            
            return;
          });
        });
      });
      let token = await Utilities.getToken();
        switch (btnType) {
          case "Save as Draft": {
            await fetch(
              Utilities.getApiHttps() +
                `ProjectIdentifiedValueSetting/AddProjectIdentifiedValueDetails/projectIdentifiedValueList`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  "Cache-Control":"no-store",
                  pragma: "no-cache",
                  Authorization: "Bearer " + token,
                },
                body: JSON.stringify(tempBOIList),
              }
            );
            await fetch(
              Utilities.getApiHttps() +
                `ProjectIdentifiedValueSetting/AddProjectIdentifiedValueStatusDetails/projectIdentifiedValueList`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  "Cache-Control":"no-store",
                  pragma: "no-cache",
                  Authorization: "Bearer " + token,
                },
                body: JSON.stringify(tempBOIList),
              }
            )
              .then((response) => response.text())
              .then((response) => { 
                if(JSON.parse(response).Code === "200"){
                  this.props.setToasterParam({
                    show: true,
                    type: 'success',
                    message: "Your Progress was saved as draft successfully!",
                  });
                  this.props.history.push("/ClientConfiguration");
                }                            
              })
              .catch((error) => console.log(error));

            break;
          }
          case "Submit": {
            await fetch(
              Utilities.getApiHttps() +
                `ProjectIdentifiedValueSetting/AddProjectIdentifiedValueDetails/projectIdentifiedValueList`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  "Cache-Control":"no-store",
                  pragma: "no-cache",
                  Authorization: "Bearer " + token,
                },
                body: JSON.stringify(tempBOIList),
              }
            );
            let data = {
              projectIdentifiedValueList: tempBOIList,
              approval: {
                operationsAccountLead: "",
                operationsAccountLeadUserID: 0,
                comments: this.state.submitComment, //comment from modal
                notificationType: "",
                recipients: [""]
              }
            }
            await fetch(
              Utilities.getApiHttps() +
                `ProjectIdentifiedValueSetting/ProjectIdentifiedValueDetailsStatusNotify/projectIdentifiedValueList`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  "Cache-Control":"no-store",
                  pragma: "no-cache",
                  Authorization: "Bearer " + token,
                },
                body: JSON.stringify(data),
              }
            )
              .then((response) => response.text())
              .then((response) => {
                if(JSON.parse(response).Code === "1"){
                  this.uploadFiles();
                  this.props.setToasterParam({
                    show: true,
                    type: 'success',
                    message: "Project has been submitted",
                  });
                  this.props.history.push("/ClientConfiguration");
                }
              })
              .catch((error) => console.log(error));

            break;
          }
          case "Send for Approval": {
            try {
              let data = {
                projectIdentifiedValueList: tempBOIList,
                approval: {
                  operationsAccountLead: this.state.ApproverData[0].UserEmailID,
                  operationsAccountLeadUserID: this.state.ApproverData[0].ID,
                  comments: this.state.sendForApprovalComment, //comment in modal
                  notificationType: "",
                },
              };
              this.setState({
                disableSendBtn: true,
              }, () => {
                fetch(
                  Utilities.getApiHttps() +
                    `ProjectIdentifiedValueSetting/ProjectIdentifiedValueNotify/projectIdentifiedValueList`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json; charset=utf-8",
                      "Cache-Control":"no-store",
                      pragma: "no-cache",
                      Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(data),
                  }
                )
                  .then((response) => response.text())
                  .then((response) => {
                    if(JSON.parse(response).Code === "1"){
                    this.closeRequestModal();
                    this.props.setToasterParam({
                      show: true,
                      type: 'success',
                      message: "Notification sent successfully",
                    });
                    this.props.history.push("/ClientConfiguration");
                  }
                  })
                  .catch((error) => console.log(error));
                  this.setState({
                    disableSendBtn: false
                  })
              });
              
            } catch (error) {
              console.log(error);
            }
            break;
          }
        }
    };

    validate = () => {
      let { selectedProjectData } = this.props;
      let isValid = true;
      selectedProjectData.ClientProjectTargets.Dimensions.map(
        (dimension, dimensionIndex) => {
          dimension.SubDimensionList.map((subDimension, subDimensionIndex) => {
            subDimension.BOIList.map((boi) => {
              if (boi.IsActive && boi.BOITarget === "") {
                isValid = false;
              }
            });
          });
        }
      );
      return isValid;
    };

    
    toggleSelectBOIPopup = () => {
      let { toggleSelectBOIPopup } = this.state;
      toggleSelectBOIPopup
        ? this.setState({ toggleSelectBOIPopup: false })
        : this.setState({ toggleSelectBOIPopup: true });
    };

    setMappedBOI = () => {
      const mappedBOIs = [];
      Object.keys(this.props.selectedProjectData).length > 0 &&
        this.props.selectedProjectData.ClientProjectTargets.Dimensions.map(
          (dimension, dimensionIndex) => {
            dimension.SubDimensionList.map(
              (subDimension, subDimensionIndex) => {
                subDimension.BOIList.map((boi) => {
                  let boiTemp = {
                    BOIID: boi.BOIID,
                    BOIName: boi.BOIName,
                    BOIDescription: boi.BOIDescription,
                    UOM: boi.UOM,
                    CoreDimensionID: dimension.CoreDimensionID,
                    CoreDimensionName: dimension.CoreDimensionName,
                    SubDimensionID: subDimension.SubDimensionID,
                    SubDimensionName: subDimension.SubDimensionName,
                    IsActive: boi.IsActive,
                  };
                  if (boi.IsActive) {
                    var valueArr =
                      mappedBOIs.length > 0 &&
                      mappedBOIs.map(function(item) {
                        return item.BOIID;
                      });
                    var isDuplicate =
                      valueArr &&
                      valueArr.some(function(item, idx) {
                        return valueArr.indexOf(item) != idx;
                      });
                    if (!isDuplicate) {
                      mappedBOIs.push(boiTemp);
                    }
                  }
                  return mappedBOIs;
                });
              }
            );
          }
        );  
      this.setState({ BOIData: mappedBOIs });
    };

    selectedBOIs = (BOIData) => {
     
      let { selectedProjectData } = this.props;
      selectedProjectData.ClientProjectTargets.Dimensions = [];
      BOIData.map((selectedBOI) => {
        let existingDimension = true;
        let existingSubDimension = true;
        let existingBOI = true;
        //If Dimension/SubDimension Already Exists
        selectedProjectData.ClientProjectTargets.Dimensions.map((dimension, dimIndex) => {
          if (dimension.CoreDimensionID === selectedBOI.CoreDimensionID) {
            existingDimension = true;
            dimension.SubDimensionList.map((subDimension, subDimIndex) => {
              if (subDimension.SubDimensionID === selectedBOI.SubDimensionID) {
                existingSubDimension = true;
                subDimension.BOIList.map((boi, boiIndex) => {
                  //Find Existing Mapped BOIs
                  if (boi.BOIID === selectedBOI.BOIID) {
                    existingBOI = true;
                    if (boi.IsActive != selectedBOI.IsMapped) {
                      boi.IsActive = selectedBOI.IsMapped;
                    }
                  } else {
                    existingBOI = false;
                  }
                });
                // If BOI is not Existing
                if ((!existingBOI || selectedProjectData.ClientProjectTargets.Dimensions[dimIndex].SubDimensionList[subDimIndex].BOIList.length === 0 ) && selectedBOI.IsMapped ) {
                  let boiData = {
                    BOICode: null,
                    BOIDescription: selectedBOI.BOIDescription,
                    BOIID: selectedBOI.BOIID,
                    BOIName: selectedBOI.BOIName,
                    BOIReason: "",
                    BOIStatus: "",
                    BOITarget: "",
                    ClientProjectTargetID: 0,
                    IsActive: selectedBOI.IsMapped,
                    RejectionRecipient: null,
                    Text: null,
                    UOM: selectedBOI.UOM,
                    Value: null,
                  };
                  subDimension.BOIList.push(boiData);
                }
              } else {
                existingSubDimension = false;
              }
            });
            // If Subdimension is not Existing
            if ((!existingSubDimension || selectedProjectData.ClientProjectTargets.Dimensions[dimIndex].SubDimensionList.length === 0) && selectedBOI.IsMapped) {
              let subDimensionData = {
                SubDimensionID: selectedBOI.SubDimensionID,
                SubDimensionName: selectedBOI.SubDimensionName,
                SubDimensionReason: "",
                SubDimensionStatus: "",
                SubDimensionTarget: 0,
                BOIList: [
                  {
                    BOICode: null,
                    BOIDescription: selectedBOI.BOIDescription,
                    BOIID: selectedBOI.BOIID,
                    BOIName: selectedBOI.BOIName,
                    BOIReason: "",
                    BOIStatus: "",
                    BOITarget: "",
                    ClientProjectTargetID: 0,
                    IsActive: selectedBOI.IsMapped,
                    RejectionRecipient: null,
                    Text: null,
                    UOM: selectedBOI.UOM,
                    Value: null,
                  },
                ],
              };
              dimension.SubDimensionList.push(subDimensionData);
            }
          } else {
            existingDimension = false;
          }
        });
        //If Dimension is not Existing
        if ((!existingDimension || selectedProjectData.ClientProjectTargets.Dimensions.length === 0) && selectedBOI.IsMapped) {
          let dimensionData = {
            CoreDimensionID: selectedBOI.CoreDimensionID,
            CoreDimensionName: selectedBOI.CoreDimensionName,
            CoreDimensionReason: "",
            CoreDimensionStatus: "",
            CoreDimensionTarget: 0,
            SubDimensionList: [
              {
                SubDimensionID: selectedBOI.SubDimensionID,
                SubDimensionName: selectedBOI.SubDimensionName,
                SubDimensionReason: "",
                SubDimensionStatus: "",
                SubDimensionTarget: 0,
                BOIList: [
                  {
                    BOICode: null,
                    BOIDescription: selectedBOI.BOIDescription,
                    BOIID: selectedBOI.BOIID,
                    BOIName: selectedBOI.BOIName,
                    BOIReason: "",
                    BOIStatus: "",
                    BOITarget: selectedBOI.BOITarget,
                    ClientProjectTargetID: 0,
                    IsActive: selectedBOI.IsMapped,
                    RejectionRecipient: null,
                    Text: null,
                    UOM: selectedBOI.UOM,
                    Value: null,
                  },
                ],
              },
            ],
          };
          selectedProjectData.ClientProjectTargets.Dimensions.push(
            dimensionData
          );
        }
      });
      this.props.onProjectDataUpdate(selectedProjectData);
      this.setMappedBOI();
      this.toggleSelectBOIPopup();
      setTimeout(() => {
        this.checkNullBOI();
    }, 500)
     
    };

    onExpandCollapse = (e) => {
      let elems = [];
      var accordion = document.getElementsByClassName(
        "stepper-tab-right-section"
      )[0];
      switch (e.currentTarget.id) {
        case "expandAll":
          elems = accordion.getElementsByClassName("collapse");
          for (var i = 0; i < elems.length; i++) {
            if (elems[i].className === "collapse") {
              accordion
                .getElementsByClassName("collapse")
                [
                  i
                ].parentElement.childNodes[1].childNodes[0].childNodes[1].childNodes[0].click()
            }
          }
          break;
        case "collapseAll":
          elems = accordion.getElementsByClassName("collapse show");
          for (var i = 0; i < elems.length; i++) {
            if (elems[i].className === "collapse show") {
              accordion
                .getElementsByClassName("collapse show")
                [
                  i
                ].parentElement.childNodes[1].childNodes[0].childNodes[1].childNodes[0].click()
            }
          }
          break;
        default:
          break;
      }
    };

    uploadFiles = async (index, file) => {
      let userName = store.getState().userInformation.userName;
      var files = new FormData();
      for (var i = 0; i < this.state.Files.length; i++) {
        files.append("files", this.state.Files[i]);
      }
      files.append("uploadedBy", userName);
      files.append(
        "project_id",
        this.props.selectedProjectData.ClientProjectTargets.ClientProjectID
      );
      let token = await Utilities.getToken();
      var _progressPercentage = await fetch(
        Utilities.getApiHttps() + `ProjectIdentifiedValueSetting/AddAttachment`,
        {
          headers: {
            "Cache-Control":"no-store",
            Pragma: "no-cache",
            Expires: 0,
            Authorization: "Bearer " + token,
          },
          method: "POST",
          body: files,
        }
      )
        .then((res) => {
          console.log(res);
          this.onCloseSubmitSettingsModal();
          if (res.status === 200) {
            return res.text();
          }
        })
        .catch((error) => console.log(error));
    };

    submitComment = (e) => {
      this.setState({
        submitComment: e.value
      });
    }

    sendForApprovalComment = (e) => {
      this.setState({
        sendforApprovalComment: e.value
      })
    }

    onDropAccepted = (acceptedFiles) => {
      let uploadFiles = acceptedFiles;
      let uploadCount = uploadFiles.length;
      let fileCount = this.state.Files.length;
      if (fileCount + uploadCount <= 3) {
        uploadFiles = this.state.Files.concat(uploadFiles);
        this.setState({
          Files: uploadFiles,
        });
      }
      else{
        console.log("File upload exceeded total (3)");
      }
    };

    onDropRejected = (rejectedFiles) => {
      let rejectFiles = rejectedFiles;
      console.log(rejectFiles);
    };

    onDeleteFile = (fileIndex) => {
      let tempFiles = [...this.state.Files];
      tempFiles.splice(fileIndex, 1);
      this.setState({
        Files: tempFiles,
      });
    };

    render() {
      console.log("ProjectIdentifiedContainer Props", this.props);
      console.log("ProjectIdentifiedContainer States", this.state);
      return (
        <>
          <ProjectIdentifiedPresentational
            commentList={this.state.commentList}
            step={this.props.step}
            closeReasonModal={this.closeReasonModal}
            rejectionModal={this.state.rejectionModal}
            conditionalModal={this.state.conditionalModal}
            showSubmitSettingsModal={this.state.showSubmitSettingsModal}
            onCloseSubmitSettingsModal={this.onCloseSubmitSettingsModal}
            closeCommentsSection={this.closeCommentsSection}
            showCommentsSection={this.showCommentsSection}
            closeRequestModal={this.closeRequestModal}
            onApprovalRequest={this.onApprovalRequest}
            onRadioButtonSelect={this.onRadioButtonSelect}
            closeRejectionModal={this.closeRejectionModal}
            showRejection={this.state.showRejection}
            showConditionalApproveModal={this.state.showConditionalApproveModal}
            closeConditionalApproveModal={this.closeConditionalApproveModal}
            clientList={this.state.clientList}
            onChange={this.onChangeClient}
            value={this.state.value}
            approvalRequestModal={this.state.approvalRequestModal}
            showcomments={this.state.showcomments}
            projectCount={this.props.projectCount}
            programs={this.props.programs}
            selectedProjectData={this.props.selectedProjectData}
            setSelectedProject={this.props.setSelectedProject}
            onProjectDataUpdate={this.props.onProjectDataUpdate}
            handleBOIInputField={this.handleBOIInputField}
            handleSubDimensionField={this.handleSubDimensionField}
            handleDimensionField={this.handleDimensionField}
            role={this.props.role}
            postCall={this.postCall}
            onExpandCollapse={this.onExpandCollapse}
            Files={this.state.Files}
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}
            onDeleteFile={this.onDeleteFile}
            isRejected={this.state.isRejected}
            clearUpload={this.clearUpload}
            isNull={this.state.isNull}
            selectedBOIs={this.selectedBOIs}
            BOIData={this.state.BOIData}
            toggleSelectBOIPopupState={this.state.toggleSelectBOIPopup}
            toggleSelectBOIPopup={this.toggleSelectBOIPopup}
            submitComment={this.submitComment}
            sendForApprovalComment={this.sendForApprovalComment}
            isSubmitClicked={this.state.isSubmitClicked}
            disableSendBtn={this.state.disableSendBtn}
          />
        </>
      );
    }
  }

  return ProjectIdentifiedContainer;
})();
export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      userInformation: state.userInformation,
    };
  },
  (dispatch) =>
    bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(withRouter(ProjectIdentified));
