import { ANY, IDLE_LOGIN, USER_SITE_ACT_PAR2 } from '../Constants/Modules'
import {
  LogUserLogOut, LogUserSiteAction,
  SetShowTime, GetShowTime, SetHideTime
} from "./AccessManagementUtil";
import { DeleteSessionStorage, GetSessionStorage, SetSessionStorage } from './BrowserUtil';
import { StrToObj, TryGetNodeAlt } from './Formatter';

export const OnLoad = () => {
  const beforeUnload = async (event) => {
    DeleteSessionStorage(USER_SITE_ACT_PAR2)
  }
  window.addEventListener("beforeunload", beforeUnload);

  document.addEventListener("visibilitychange", async () => {
    if (document.visibilityState === 'visible') {
      const url = window.location.href.toLowerCase()
      SetSessionStorage('idleTimeTab', new Date().getTime())
      SetShowTime()
      await new Promise(resolve => setTimeout(async () => {
        const param = StrToObj(GetSessionStorage(USER_SITE_ACT_PAR2))
        await LogUserSiteAction({
          action: IDLE_LOGIN,
          module: ANY,
          ShouldLogPPCID: [
            'programprojectconfig',
            'reportvaluedelivered',
            'projectdataentry',
            'dataingestion',
            'dashboard'
          ].some(e => url.includes()),
          ShouldLogPPCID: TryGetNodeAlt(param, null, 'ShouldLogPPCID'),
          ClientProgramID: TryGetNodeAlt(param, null, 'ClientProgramID'),
          ClientProjectID: TryGetNodeAlt(param, null, 'ClientProjectID'),
          progStatus: TryGetNodeAlt(param, null, 'ClientProgramStatus'),
          projStatus: TryGetNodeAlt(param, null, 'ClientProjectStatus'),
          StartTimeAdjustment: '-' + GetShowTime(),
        })
        resolve()
      }, 5000))
    } else {
      //WHEN HIDDING
      SetHideTime()
      SetSessionStorage('idleTimeTab', new Date().getTime())
      await LogUserLogOut()
    }
  });
}
