import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import { GrooveIconSvg } from 'tfo-groove';
import Dropzone from 'react-dropzone';
import { ProgressBar } from 'react-bootstrap';
//actions
import {
  setClientAlignmentUpload,
  isUploadCompleted,
  removeRecentClientAlignmentUpload,
} from '../../../Actions/ConfigureProgram.Action';
//components
import { GetSubMenuId, HttpGetDownloadFile } from '../../../Utilities/HTTPUtil';
import upload from './../../../Static/images/svg/uploadfill.svg';
import { HasProperty, IsValid } from '../../../Utilities/Validations';
import ClientFileItem from './ClientFileItem';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import fileExcel from '../../../Static/images/360cvm/fileExcel.svg';
import {
  FormDateDDMMMYYY,
  FormTimeAPM,
  trimFieldForFileName,
  getUserTimeZone,
} from '../../../Utilities/Formatter';

const ClientAlignmentPanel = (function () {
  let progress;
  class ClientAlignmentPanelPresentational extends Component {
    render() {
      let recentlyUploadedClientAlignment = IsValid(
        this.props.mainSelectedProject.projectDetails.recentlyUploadedClientAlignment
      )
        ? this.props.mainSelectedProject.projectDetails.recentlyUploadedClientAlignment
        : [];
      let status = this.props.mainSelectedProject.projectDetails.status;
      let hasRecentlyUploaded = HasProperty(recentlyUploadedClientAlignment[0], 'FileName');
      let recentlyRemoveUploadedId = this.props.removeClientAlignmentFileId.length;
      let fileCount = this.props.Files.file.length;
      let userApprover =
        (!this.props.isPPC && !this.props.isBA && !this.props.isSuperAdmin) ||
        (this.props.isSuperAdmin && status === 'Awaiting Approval');
      let indextoDelete = 0;
      return (
        <React.Fragment>
          <Accordion className="configure-client-collapse with-additional-header-buttons">
            <Card
              id="configCardID"
              className={
                !this.props.fileIsValid || this.props.hasNoAttachment ? 'configure-error' : ''
              }
            >
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {userApprover ? (
                    // Recent File Section
                    hasRecentlyUploaded ? (
                      recentlyUploadedClientAlignment.map((data, i) => (
                        <ClientFileItem
                          fileDetails={data}
                          userIsApprover={true}
                          mainSelectedProject={this.props.mainSelectedProject}
                          selectedClient={this.props.selectedClient}
                          downloadFile={this.props.downloadFile}
                          uploadType={'alignment'}
                        />
                      ))
                    ) : (
                      ''
                    )
                  ) : hasRecentlyUploaded &&
                    recentlyRemoveUploadedId !== recentlyUploadedClientAlignment.length ? (
                    <React.Fragment>
                      {fileCount > 0 && this.props.progressNow < 100
                        ? this.props.filesForUpload.file.map((files, fileIndex) => (
                          <div className="cs-uploaded-files" key={fileIndex}>
                            <div className="uploaded-files-icon">
                              <img src={fileExcel} alt="file-excel-icon" />
                            </div>
                            <div className="uploaded-files-name">{files.name}</div>
                            <ProgressBar animated now={this.props.progressNow} striped={false} />
                            {/* <span className="progress-label">{`${this.props.progressNow}% File Uploading...`}</span> */}
                            <div className="uploaded-files-close">
                              <GrooveIconSvg
                                customClassName="delete-icon"
                                size="small"
                                name="times"
                                iconStyle="solid"
                                iconColor="stat-neutral"
                                callbackFunction={() =>
                                  this.props.onDeleteUpload(
                                    this.props.filesForUpload.file.length ===
                                      this.props.Files.file.length
                                      ? fileIndex
                                      : this.props.filesForUpload.file.length === 2
                                        ? fileIndex + 1
                                        : this.props.Files.file.length - 1,
                                    null
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))
                        : recentlyUploadedClientAlignment
                          .filter((x) => !this.props.removeClientAlignmentFileId.includes(x.ID))
                          .map((data, i) => (
                            <React.Fragment key={i}>
                              <ClientFileItem
                                fileDetails={data}
                                Files={null}
                                userIsApprover={false}
                                onClickDownloadedFile={this.props.onClickDownloadedFile}
                                onDeleteUploaded={this.props.onClickConfirmRemoveModal}
                                recentlyRemoveUploadedId={recentlyRemoveUploadedId}
                                mainSelectedProject={this.props.mainSelectedProject}
                                selectedClient={this.props.selectedClient}
                                index={i}
                                uploadType={'alignment'}
                              />
                            </React.Fragment>
                          ))}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {userApprover ? (
                    ''
                  ) : fileCount > 0 && this.props.progressNow === 100 ? (
                    this.props.Files.file.map((data, i) => (
                      <React.Fragment key={i}>
                        <ClientFileItem
                          fileDetails={null}
                          Files={this.props.Files}
                          userIsApprover={false}
                          onClickDownloadedFile={this.props.onClickDownloadedFile}
                          onDeleteUploaded={this.props.onClickConfirmRemoveModal}
                          recentlyRemoveUploadedId={recentlyRemoveUploadedId}
                          mainSelectedProject={this.props.mainSelectedProject}
                          selectedClient={this.props.selectedClient}
                          index={i}
                          uploadType={'alignment'}
                        />
                      </React.Fragment>
                    ))
                  ) : recentlyUploadedClientAlignment.length - recentlyRemoveUploadedId === 0 ? (
                    <React.Fragment>
                      {fileCount > 0 ? (
                        this.props.filesForUpload.file.map((files, fileIndex) => (
                          <div className="cs-uploaded-files" key={fileIndex}>
                            <div className="uploaded-files-icon">
                              <img src={fileExcel} alt="file-excel-icon" />
                            </div>
                            <div className="uploaded-files-name">{files.name}</div>
                            <ProgressBar animated now={this.props.progressNow} striped={false} />
                            {/* <span className="progress-label">{`${this.props.progressNow}% File Uploading...`}</span> */}
                            <div className="uploaded-files-close">
                              <GrooveIconSvg
                                customClassName="delete-icon"
                                size="small"
                                name="times"
                                iconStyle="solid"
                                iconColor="stat-neutral"
                                callbackFunction={() =>
                                  this.props.onDeleteUpload(
                                    this.props.filesForUpload.file.length ===
                                      this.props.Files.file.length
                                      ? fileIndex
                                      : this.props.filesForUpload.file.length === 2
                                        ? fileIndex + 1
                                        : this.props.Files.file.length - 1,
                                    null
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="empty-program-container">
                          <span>No Available Attachment(s)</span>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <div className="client-upload-collapse-container">
                  <div className="bodytext18-medium-midnight">Client Alignment</div>
                  <div className="client-dropzone-button" style={{ marginLeft: 'auto' }}>
                    <Dropzone
                      onDropAccepted={(acceptedFiles) => this.props.onDropAccepted(acceptedFiles)}
                      onDropRejected={(rejectedFiles) => this.props.onDropRejected(rejectedFiles)}
                      maxSize={10000000}
                      maxFiles={3}
                      accept=".msg, .eml, .pdf"
                      noClick={
                        this.props.disableUpload || userApprover
                          ? true
                          : this.props.isSuperAdmin || this.props.isPPC || this.props.isBA
                            ? false
                            : true
                      }
                      noDrag={true}
                      disabled={
                        (this.props.disableUpload || userApprover
                          ? true
                          : this.props.isSuperAdmin 
                            ? false
                            : (!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')?true:false)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className={`client-upload-btn ${this.props.disableUpload || userApprover
                            ? `disabled-upload`
                            : this.props.isSuperAdmin 
                              ? ``
                              : (!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')?`disabled-upload`:``
                            }
                        `}
                        >
                          <input {...getInputProps()} />
                          <img className="client-upload-ico" src={upload} alt="upload-icon" />
                          <div>Upload File</div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div className="client-attachment">
                    <GrooveIconSvg
                      size="small"
                      name="paperclip"
                      iconStyle="solid"
                      iconColor="stat-neutral"
                    />
                    <span>
                      {hasRecentlyUploaded &&
                        recentlyUploadedClientAlignment.length - recentlyRemoveUploadedId !== 0
                        ? `${recentlyUploadedClientAlignment.length -
                        recentlyRemoveUploadedId +
                        fileCount
                        } Attachment`
                        : `${fileCount} Attachment`}

                      {recentlyUploadedClientAlignment.length - recentlyRemoveUploadedId > 1
                        ? 's'
                        : fileCount > 1
                          ? 's'
                          : ''}
                    </span>
                  </div>
                </div>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <GrooveIconSvg
                    id="basicDetailsExpand"
                    customClassName="accordion-button"
                    size="large"
                    name="chevron-down"
                    iconStyle="solid"
                    iconColor="stat-neutral"
                  />
                </Accordion.Toggle>
              </Card.Header>
            </Card>
          </Accordion>
          {!this.props.fileIsValid || this.props.hasNoAttachment ? (
            <div className="client-invalid-reason">{this.props.errorUpload}</div>
          ) : null}
          <CVMModal
            title={''}
            customclass={'notifyModal-dialog'}
            show={this.props.showRemoveModal}
            onCloseModal={this.props.onDeleteUpload}
            content={
              <CVMWarningModal
                body={'Are you sure you want to remove this attachment?'}
                handleCancel={this.props.onDeleteUpload}
                handleYes={() =>
                  this.props.onDeleteFile(this.props.fileToDelete, this.props.fileDetails)
                }
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class ClientAlignmentPanelContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        fileIsValid: true,
        progressNow: 0,
        disableUpload: false,
        errorUpload: '',
        showRemoveModal: false,
        fileToDelete: null,
        fileDetails: null,
        files: {
          file: [],
        },
        filesForUpload: {
          file: [],
        },
      };
    }

    componentDidUpdate(prevProps) {
      try {
        if (prevProps.hasNoCAAttachment !== this.props.hasNoCAAttachment) {
          let errorMessage;
          const { basicDetails } =
        this.props.ConfigureProgramData;
        const { isSuperAdmin} = this.props.UserInformationData;
         if(isSuperAdmin&&basicDetails.overallStatus=='Approved'){
           errorMessage ='Please Attach the Client Alignment before updating the project';
         }else{
          errorMessage ='Please Attach the Client Alignment before sending the data for approval.';
         }
          
          this.setState({ errorUpload: errorMessage });
        }
      } catch (e) {
        console.log(e);
      }
    }

    onDropAccepted = async (acceptedFiles) => {
      try {
        if (!this.props.editedAnyFieldOnce && this.props.ConfigureProgramData.basicDetails.overallStatus === 'Approved') {
          this.props.checkForInvalidEmailsOnLanding();
          this.props.setEditedAnyFieldOnce(true);
        }
        let configureProgramData = this.props.ConfigureProgramData;
        let files = configureProgramData.clientAlignmentFiles;
        let uploadedFiles =
          configureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientAlignment
            .length;
        let recentlyRemoveUploadedId = configureProgramData.removeClientAlignmentFileId.length;
        let uploadFiles = acceptedFiles;
        let uploadCount = uploadFiles.length;
        let fileCount = files.file.length;
        let fileName = '';
        if (fileCount + uploadCount + (uploadedFiles - recentlyRemoveUploadedId) <= 3) {
          acceptedFiles.forEach(async (e, i) => {
            fileName = e.name.split('.');

            if (fileName.length > 2) {
              this.setState({
                fileIsValid: false,
                errorUpload: 'Invalid File Name. File Name should not contain a dot (.)',
              });
            } else {
              if (
                document
                  .getElementsByClassName('with-additional-header-buttons')[1]
                  .children[0].children[0].className.indexOf('show') === -1
              ) {
                document
                  .getElementsByClassName('with-additional-header-buttons')[1]
                  .children[0].children[1].children[1].click();
              }
              uploadFiles = this.props.ConfigureProgramData.clientAlignmentFiles.file.concat(e);
              await this.props.setClientAlignmentUpload(uploadFiles, new Date());
              uploadFiles = this.state.files.file.concat(e);
              this.setState(
                {
                  files: {
                    file: uploadFiles,
                  },
                  filesForUpload: {
                    file: acceptedFiles,
                  },
                  fileIsValid: true,
                },
                () => {
                  this.props.onCloseCAAttachmentError();
                }
              );
            }
          });
          this.setState(
            {
              progressNow: 0,
            },
            () => {
              this.buffProgressBar();
            }
          );
        } else {
          this.setState({
            errorUpload:
              "You are only allowed to upload a maximum of 3 files - You can replace old attachment with consolidated approval from client for all the BOI's",
            fileIsValid: false,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    onDropRejected = (rejectedFiles) => {
      try {
        let rejectFiles = rejectedFiles;

        let errorCode = rejectedFiles[0].errors[0].code;
        let message = '';

        switch (errorCode) {
          case 'file-invalid-type':
            message =
              'Invalid file format. Please upload file with any of the these formats: (.msg, .eml, .pdf).';
            break;
          case 'too-many-files':
            message =
              "You are only allowed to upload a maximum of 3 files - You can replace old attachment with consolidated approval from client for all the BOI's";
            break;
          case 'file-too-large':
            message = 'File size exceeds permissible limit. Please note maximum file size supported is 10MB.';
            break;
          default:
            break;
        }
        this.props.onCloseCAAttachmentError();
        this.setState({
          fileIsValid: false,
          errorUpload: message,
        });
      } catch (e) {
        console.log(e);
      }
    };

    buffProgressBar = () => {
      try {
        progress = setInterval(() => {
          this.setState({ progressNow: this.state.progressNow + 1, disableUpload: true });
          if (this.state.progressNow === 100) {
            clearInterval(progress);
            this.props.isUploadCompleted(true);
            this.setState({ disableUpload: false, filesForUpload: { file: [] } });
          }
        }, 10);
      } catch (e) {
        console.log(e);
      }
    };

    validateFiles = (file) => {
      let isValid = true;
      const nfile = file[0];
      const fileExt = nfile.name.split('.');
      const extList = ['pptx', 'ppt'];
      let message = '';
      try {
        if (nfile.size > 10000000) {
          isValid = false;
          message =
            'File size exceeds permissible limit. Please note maximum file size supported is 10MB.';
        } else if (fileExt.length > 2) {
          //for double extension
          isValid = false;
          message = 'Invalid File Name. File Name should not contain a dot (.)';
        } else if (
          nfile.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ) {
          isValid = false;
          message =
            'Invalid file format. Please upload file with any of the these formats: (.msg, .eml, .pdf).';
        } else if (!extList.includes(fileExt[fileExt.length - 1])) {
          isValid = false;
          message =
            'Invalid file format. Please upload file with any of the these formats: (.msg, .eml, .pdf).';
        } else if (fileExt[0].length > 100) {
          isValid = false;
          message =
            'File name exceeds 100 maximum character limit. Please update file name then upload again.';
        }

        this.props.onCloseCAAttachmentError();
        this.setState({
          fileIsValid: isValid,
          errorUpload: message,
        });
      } catch (e) {
        console.log(e);
        isValid = false;
      }

      return isValid;
    };

    onDeleteUpload = (i, f) => {
      try {
        this.setState(
          {
            showRemoveModal: !this.state.showRemoveModal,
            fileToDelete: i,
            fileDetails: f,
          },
          () => {
            if (this.state.showRemoveModal) {
              clearInterval(progress);
            } else {
              if (this.state.progressNow !== 100) {
                this.buffProgressBar();
              }
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onClickConfirmRemoveModal = async (e, i, f) => {
      this.setState({ showRemoveModal: e, fileToDelete: i, fileDetails: f });
    };

    onClickDownloadedFile = async (i) => {
      let clientAlignmentFiles = this.props.ConfigureProgramData.clientAlignmentFiles;
      let isFileNew = clientAlignmentFiles.file.length > 0;
      let recentUlClientAlignment =
        this.props.ConfigureProgramData.mainSelectedProject.projectDetails
          .recentlyUploadedClientAlignment;
      let mainSelectedProject = this.props.ConfigureProgramData.mainSelectedProject;
      let tFile = isFileNew
        ? clientAlignmentFiles.file[i].name
        : recentUlClientAlignment[i].FileName;
      let fileType = tFile.substring(tFile.lastIndexOf('.') + 1);
      let fileName = tFile.substring(0, tFile.lastIndexOf('.'));

      let projectName = trimFieldForFileName(
        mainSelectedProject.projectDetails.clientProjectName,
        100
      );
      let clientName = this.props.SharedData.selectedClient.label;
      let uniqueID = mainSelectedProject.projectDetails.projectID;

      let tDate;
      // mainSelectedProject.projectDetails.statusID === 2 || //in prog 2 rejected 4
      // mainSelectedProject.projectDetails.statusID === 4
      let date;

      if (isFileNew) {
        tDate = clientAlignmentFiles.fileUploadedDate;
        date = `${FormDateDDMMMYYY(tDate, !isFileNew)}_${FormTimeAPM(tDate, !isFileNew)}`;
        date = date.replace(':', ' ');
        date = date.replace(/ /g, '_');
        date = date + '';
      } else {
        date = recentUlClientAlignment[i].FileUploadDate.replace('|', '');
        date = date.replace('   ', ' ');
        date = date.replace('  ', ' ');
        date = date.replace(':', ' ');
        date = date.replace(/ /g, '_');
        date = date + '';
      }

      let fileNameLength = `${clientName}_${projectName}_${uniqueID}__${date}.${fileType}`.length;
      let lastIndex = 200 - fileNameLength;
      fileName = trimFieldForFileName(fileName, lastIndex);

      let downloadFileName = `${clientName}_${projectName}_${uniqueID}_${fileName}_${date}.${fileType}`;

      if (isFileNew) {
        let file = clientAlignmentFiles.file[0];
        var data = new Blob([file], { type: file.type });
        var pptURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');

        tempLink.href = pptURL;
        tempLink.setAttribute('download', downloadFileName);
        tempLink.click();
      } else {
        if (recentUlClientAlignment !== null && recentUlClientAlignment !== undefined) {
          let id = recentUlClientAlignment[i].ID;
          let tz = getUserTimeZone();
          let isNegative = tz.includes('-');
          tz = tz.replace('-', '').replace('+', '');
          let subMenuId = GetSubMenuId("Project Data Entry");

          await HttpGetDownloadFile(
            `ReportValueDelivered/DownloadClientAcknowledgementFileHistory?clientProjectID=${uniqueID}&clientAcknowlegdementFileIDs=${id}&UTCOffset=${tz}&isNegative=${isNegative}&SubMenuID=${subMenuId}`,
            downloadFileName
          );
        }
      }
    };

    downloadFile = async (fileItem) => {
      let fileType = fileItem.FileName.substring(fileItem.FileName.lastIndexOf('.') + 1);
      let fileName = fileItem.FileName.substring(0, fileItem.FileName.lastIndexOf('.'));

      let projectName = trimFieldForFileName(
        this.props.ConfigureProgramData.mainSelectedProject.projectDetails.clientProjectName,
        100
      );
      let clientName = this.props.SharedData.selectedClient.label;
      let uniqueID = this.props.ConfigureProgramData.mainSelectedProject.projectDetails.projectID;

      let date = fileItem.FileUploadDate.replace('|', '');
      date = date.replace('   ', ' ');
      date = date.replace('  ', ' ');
      date = date.replace(':', ' ');
      date = date.replace(/ /g, '_');
      date = date + '';

      let fileNameLength = `${clientName}_${projectName}_${uniqueID}__${date}.${fileType}`.length;
      let lastIndex = 200 - fileNameLength;
      fileName = trimFieldForFileName(fileName, lastIndex);

      let downloadFileName = `${clientName}_${projectName}_${uniqueID}_${fileName}_${date}.${fileType}`;
      let tz = getUserTimeZone();
      let isNegative = tz.includes('-');
      tz = tz.replace('-', '').replace('+', '');
      let subMenuId = GetSubMenuId("Project Data Entry");

      await HttpGetDownloadFile(
        `ReportValueDelivered/DownloadClientAcknowledgementFileHistory?clientProjectID=${uniqueID}&clientAcknowlegdementFileIDs=${fileItem.ID}&UTCOffset=${tz}&isNegative=${isNegative}&SubMenuID=${subMenuId}`,
        downloadFileName
      );
    };

    onDeleteFile = (fileIndex, fileDetails) => {
      try {
        let tempFiles = null;
        let recentlyUploadedClientAlignment =
          this.props.ConfigureProgramData.mainSelectedProject.projectDetails
            .recentlyUploadedClientAlignment;
        let hasRecentlyUploaded = false;
        let file = null;
        if (fileDetails !== null && fileDetails !== undefined) {
          file = recentlyUploadedClientAlignment.filter((x) => x.ID === fileDetails.ID)[0];
          hasRecentlyUploaded = HasProperty(file, 'FileName');
        } else {
          let recentlyUploaded = this.props.ConfigureProgramData.clientAlignmentFiles.file;
          recentlyUploaded.splice(fileIndex, 1);
          tempFiles = [...this.state.files.file];
          tempFiles.splice(fileIndex, 1);
          this.setState({ files: { file: tempFiles }, filesForUpload: { file: [] } });
        }

        if (hasRecentlyUploaded) {
          this.props.removeRecentClientAlignmentUpload(file);
        }
        this.setState({
          progressNow: 100,
          showRemoveModal: false,
          disableUpload: false,
          errorUpload: null,
          fileIsValid: true,
        });
      } catch (e) {
        console.log(e);
      }
    };

    render() {
      const { mainSelectedProject, removeClientAlignmentFileId, clientAlignmentFiles,basicDetails, } =
        this.props.ConfigureProgramData;
      const { userProfiles, isSuperAdmin, currentUserRoleID } = this.props.UserInformationData;
      const { selectedClient } = this.props.SharedData;
      let clientID = selectedClient.value;
      let isExistingPPC = userProfiles.some(
        (x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let isExistingBA = userProfiles.some(
        (x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      return (
        <ClientAlignmentPanelPresentational
          //States
          errorUpload={this.state.errorUpload}
          fileIsValid={this.state.fileIsValid}
          progressNow={this.state.progressNow}
          disableUpload={this.state.disableUpload}
          showRemoveModal={this.state.showRemoveModal}
          fileToDelete={this.state.fileToDelete}
          fileDetails={this.state.fileDetails}
          Files={this.state.files}
          filesForUpload={this.state.filesForUpload}
          //funtions
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          basicDetails={basicDetails}
          removeUploadedFile={this.removeUploadedFile}
          onDeleteUpload={this.onDeleteUpload}
          onClickConfirmRemoveModal={this.onClickConfirmRemoveModal}
          onClickDownloadedFile={this.onClickDownloadedFile}
          onDeleteFile={this.onDeleteFile}
          downloadFile={this.downloadFile}
          //props
          hasNoAttachment={this.props.hasNoCAAttachment}
          //reducers
          isSuperAdmin={isSuperAdmin}
          mainSelectedProject={mainSelectedProject}
          removeClientAlignmentFileId={removeClientAlignmentFileId}
          selectedClient={selectedClient}
          isPPC={currentUserRoleID === 12 || (currentUserRoleID === 16 && isExistingPPC)}
          isBA={currentUserRoleID === 15 || (currentUserRoleID === 16 && isExistingBA)}
        />
      );
    }
  }

  return ClientAlignmentPanelContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      SharedData: state.SharedData,
      UserInformationData: state.userInformation,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setClientAlignmentUpload,
          isUploadCompleted,
          removeRecentClientAlignmentUpload,
        }
      ),
      dispatch
    )
)(ClientAlignmentPanel);
