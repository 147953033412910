import React, { Component, useEffect } from "react";

const ErrorComponent = ({error}) => {

    // Hard
    useEffect(() => {
        const isHardRefresh = sessionStorage.getItem("hard-refresh");
        if(!isHardRefresh) {
          sessionStorage.setItem("hard-refresh", true);
          window.location.reload(true);
        }
    }, [])

    return <h1>An Error Occurred: {error.errorCode}</h1>;
}

export default ErrorComponent;