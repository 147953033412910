import {
    SET_STAGE_REPORTING_YEAR_PARAM
} from '../Constants';
import { HasProperty, IsValid } from '../Utilities/Validations';

export const setStageReportingYearParam = (param) => async (dispatch, state) => {
    let oldState = state().StageData.reportingYearParam;
    let initParam = { options: [], value: {}, isDisabled: false }
    initParam.options = HasProperty(param, 'options') ? param.options : oldState.options;
    initParam.value = HasProperty(param, 'value') ? param.value : oldState.value;
    initParam.isDisabled = HasProperty(param, 'isDisabled') ? param.isDisabled : oldState.isDisabled


    try {
        dispatch({
            type: SET_STAGE_REPORTING_YEAR_PARAM,
            reportingYearParam: initParam,
        });
    } catch (err) {
        console.log(err);
    }
};