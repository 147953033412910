import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import AddCustomBOI from './AddCustomBOI';
import AddAgnosticBOI from './AddAgnosticBOI';
import Popover from 'react-bootstrap/Popover';
import {
  GrooveIconSvg,
  GrooveButton,
  GrooveSelectMenuBasic,
  GrooveTabComponent,
  GrooveCheckbox,
} from 'tfo-groove';
import { FormatCVMParamValue, TryGetObjValueAlt } from '../../../Utilities/Formatter';
import { HasProperty, HasValue } from '../../../Utilities/Validations';
import {
  handleCheckAllOfferings,
  getBOIMappingList,
  getBOIMappingListByOffering,
} from '../../../Actions/ProgramConfiguration.Action';
import newInfo from '../../../Static/images/svg/new-info.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const AddBOIPopup = (function () {
  class AddBOIPopupPresentational extends Component {
    render() {
      const disabledBtnSBSave =
        this.props.selectedBOI.filter((x) => x.IsCustom === false).length === 0;
      const disabledBtnCBSave =
        this.props.selectedBOI.filter((x) => x.IsCustom === true).length === 0;
      const disabledBtnABSave = this.props.selectedAgnosticBOIList.length === 0;
      const form = this.props.formValue;
      const opt = this.props.options;
      // check if there any dropdown field was empty
      const failedValidation =
        this.props.isExisting ||
        Object.keys(this.props.boiToAdd.boi).length === 0 ||
        Object.keys(this.props.boiToAdd.uom).length === 0 ||
        Object.keys(this.props.boiToAdd.dimension).length === 0 ||
        Object.keys(this.props.boiToAdd.subdimension).length === 0 ||
        this.props.duplicateBOI ||
        this.props.inComplete;

      const disableAddBOI =
        this.props.boiMappingList.length === 0 ||
        Object.keys(this.props.newProgramDetails.offering).length === 0 ||
        Object.keys(this.props.newProgramDetails.cxLead).length === 0 ||
        Object.keys(this.props.newProgramDetails.progCreator).length === 0 ||
        this.props.newProgramDetails.subOffering.length === 0 ||
        this.props.newProgramDetails.validation ||
        this.props.disableAddBOI; //if program name already exist
      const standardBOI = this.props.selectedBOI.filter((boi) => !boi.IsCustom);
      const isOverall =
        this.props.newProgramDetails.offering.label === 'Overall' ||
        this.props.newProgramDetails.offering.label === 'Overall-';
      const tooltipDeleteBoi = (
        <Popover id="deleteboi-popover" trigger="focus" placement="left">
          <Popover.Content>
            This BOI is already selected by a project within this program. In order to remove this
            BOI from the list, kindly remove the same from the project level.
          </Popover.Content>
        </Popover>
      );

      const tooltipDisabledFields = (
        <Popover id="deleteboi-popover" trigger="focus" placement="right">
          <Popover.Content>
            This BOI is already selected by a project within this program. Kindly select other BOI
            from the list.
          </Popover.Content>
        </Popover>
      );
      const showNotif = this.props.showNotifMessage;
      const tab_data = [
        {
          tab_label: 'Standard Business Outcome Indicators',
          tab_value: 1,
          tab_id: 'standard-boi-tab',
          tab_content: (
            <React.Fragment>
              <div className="cvm-tab-content add-boi-modal">
                <div className="check-box check-box-all-boi">
                  <div
                    className={`cvm-checkbox ${isOverall
                      ? 'cvm-checked disable-check-box'
                      : this.props.isCheckedAllOfferings && 'cvm-checked'
                      }`}
                    onClick={() => this.props.clickCheckBox(!this.props.isCheckedAllOfferings)}
                  >
                    <GrooveIconSvg
                      customClassName="cvm-check-box"
                      size="small"
                      name="check"
                      iconStyle="solid"
                      iconColor="dl-white"
                    />
                  </div>
                  <div className="check-label">
                    {' '}
                    Include the complete list of BOIs from all Offerings
                  </div>
                  <div></div>
                </div>
                <div className="add-boi-box-container">
                  <div
                    className={
                      this.props.isExisting
                        ? 'add-boi-field-container duplicate-boi'
                        : 'add-boi-field-container'
                    }
                  >
                    <div className="select-boi-dropdown">
                      <GrooveSelectMenuBasic
                        name="BOI"
                        isDisabled={
                          this.props.list.boi.length === 0 ||
                          this.props.duplicateBOI ||
                          this.props.inComplete
                        }
                        placeholder={
                          Object.keys(this.props.boiToAdd.boi).length !== 0 ? 'BOI*' : 'Select BOI*'
                        }
                        list={this.props.list.boi}
                        id="boi"
                        contentType="search"
                        callbackFunction={(e) => this.props.onSelectDropdown(e, 'boi')}
                      />
                    </div>
                    <div className="select-boi-dropdown">
                      <GrooveSelectMenuBasic
                        name="unitofMeasure"
                        isDisabled={
                          this.props.list.uom.length === 0 ||
                          this.props.duplicateBOI ||
                          this.props.inComplete
                        }
                        placeholder={
                          Object.keys(this.props.boiToAdd.uom).length !== 0
                            ? 'Unit of Measure*'
                            : 'Select Unit of Measure*'
                        }
                        list={this.props.list.uom}
                        defaultData={this.props.boiToAdd.uom}
                        id="uom"
                        contentType="search"
                        callbackFunction={(e) => this.props.onSelectDropdown(e, 'uom')}
                      />
                    </div>
                    <div className="select-boi-dropdown">
                      <GrooveSelectMenuBasic
                        name="dimension"
                        isDisabled={
                          Object.keys(this.props.boiToAdd.uom).length === 0 ||
                          this.props.list.dimension.length === 0 ||
                          this.props.duplicateBOI ||
                          this.props.inComplete
                        }
                        placeholder={
                          Object.keys(this.props.boiToAdd.dimension).length !== 0
                            ? 'Dimension*'
                            : 'Select Dimension*'
                        }
                        list={this.props.list.dimension}
                        defaultData={this.props.boiToAdd.dimension}
                        id="dimension"
                        contentType="search"
                        callbackFunction={(e) => this.props.onSelectDropdown(e, 'dimension')}
                      />
                    </div>
                    <div className="select-boi-dropdown">
                      <GrooveSelectMenuBasic
                        name="subdimension"
                        isDisabled={
                          this.props.list.subdimension.length === 0 ||
                          this.props.duplicateBOI ||
                          this.props.inComplete
                        }
                        placeholder={
                          Object.keys(this.props.boiToAdd.subdimension).length !== 0
                            ? 'Sub Dimension*'
                            : 'Select Sub Dimension*'
                        }
                        list={this.props.list.subdimension}
                        defaultData={this.props.boiToAdd.subdimension}
                        id="subdimension"
                        contentType="search"
                        callbackFunction={(e) => this.props.onSelectDropdown(e, 'subdimension')}
                      />
                    </div>
                  </div>
                </div>
                {showNotif ? (
                  <div className="disclaimer-message">
                    <GrooveIconSvg
                      customClassName="accordion-button"
                      size="small"
                      name="exclamation-triangle"
                      iconStyle="solid"
                      iconColor="stat-warning"
                    />
                    BOIs with $ UOM selected under Financial Dimension will be Considered as client P&L Sign-Off. $ value under all other Dimensions except Financial Dimension, will not be considered as Client P&L Sign-Off.
                  </div>
                ) : (
                  ''
                )}
                <GrooveButton
                  id="primry-btn-3"
                  hasIcon={true}
                  type="filter-outline"
                  colorClass="stat-alternate"
                  text="Add"
                  iconAlignment="right"
                  iconSize="small"
                  iconName="plus"
                  iconStyle="solid"
                  iconId="btn-icon-01"
                  customClassName="add-boi-button"
                  callbackFunction={() => this.props.addBOI()}
                  isDisabled={failedValidation}
                />
                {
                  //this.props.selectedBOI.length > 0
                  standardBOI.length > 0 ? (
                    <React.Fragment>
                      <div className="divider-horizontal" />
                      <div className="search-bar-container add-boi-search">
                        <div className="search-bar label14-regular-midnight">
                          <GrooveIconSvg
                            customClassName="close-modal-btn"
                            size="large"
                            name="search"
                            iconStyle="solid"
                            iconColor="stat-neutral"
                          />
                          <input
                            type="text"
                            name="name"
                            placeholder="Search..."
                            autoComplete="off"
                            id="searchBox"
                            maxLength="255"
                            onChange={(e) => {
                              this.props.setKeyword(e.target.value.toLowerCase());
                            }}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )
                }
                <div className="added-boi-container">
                  <div className="deleted-bois-container">
                    {this.props.deletedBOIForUndo.map((item) => {
                      return (
                        <div className="deleted-bois">
                          <GrooveIconSvg
                            customClassName="close-modal-btn"
                            size="large"
                            name="exclamation-triangle"
                            iconStyle="solid"
                            iconColor="stat-alternate"
                          />
                          <div className="deleted-bois-text">
                            The Business Outcome Indicator
                            <div>
                              <div className="deleted-boi-details">
                                <div className="deleted-boi-details-label">
                                  BOI: <span>{item.boi.label}</span>
                                </div>
                                <div className="deleted-boi-details-label">
                                  Unit of Measure: <span>{item.uom.label}</span>
                                </div>
                                <div className="deleted-boi-details-label">
                                  Dimension: <span>{item.dimension.label}</span>
                                </div>
                                <div className="deleted-boi-details-label">
                                  Subdimension: <span>{item.subdimension.label}</span>
                                </div>
                              </div>
                              '{item.boi.label}'
                            </div>
                            has been deleted. Click on the undo button to restore the deleted BOI.
                          </div>
                          <GrooveButton
                            id="primry-btn-3"
                            type="solid"
                            colorClass="stat-alternate"
                            text="Undo Delete"
                            customClassName="undo-deleted-boi-btn"
                            callbackFunction={() => this.props.restoreDeletedBOI(item)}
                          />
                          <GrooveIconSvg
                            customClassName="close-modal-btn"
                            size="large"
                            name="times"
                            iconStyle="solid"
                            iconColor="stat-neutral"
                            callbackFunction={() => this.props.removeDeletedBOI(item)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {this.props.selectedBOIDisplay.map((item, index) => {
                    let that = this;
                    let isEditDisabled = item.boiStatus === 'Rejected' ? false : true;
                    let isItemDisabled =
                      item.isExisting || item.missingDetails
                        ? false
                        : this.props.duplicateBOI || this.props.inComplete;
                    let type = that.props.type === 'Edit Rejected' ? true : false;

                    let dimSubDisabled = item.uom.value === 'USD' && item.dimension.value === 1 && item.isDisableUpdatingDimension
                    if (!item.IsCustom)
                      return (
                        <div
                          className={
                            item.isExisting ||
                              item.missingDetails ||
                              (item.boiOutsideOffering && this.props.clickedUncheck)
                              ? 'add-boi-field-container duplicate-boi'
                              : 'add-boi-field-container'
                          }
                        >
                          <div className="new-info-wrap">
                            <OverlayTrigger
                              key="right"
                              placement="auto"
                              overlay={
                                <Tooltip id="boi-tooltip-right">
                                  <div className="boi-details-wrap row">
                                    <div class="col-md-6">
                                      <h6>Offering</h6>
                                      <p class="">{item.offeringName}</p>
                                      <h6>Dimension</h6>
                                      <p class="">{item.dimension.label}</p>
                                      <h6>BOI Description</h6>
                                      <p class="">{item.boiDescription}</p>
                                    </div>
                                    <div class="col-md-6">
                                      <h6>Sub-Offering</h6>
                                      <p class="">{item.suboffering.label}</p>
                                      <h6>Sub-dimension</h6>
                                      <p class="">{item.subdimension.label}</p>
                                      <h6>Leading Practice</h6>
                                      <p class="">{item.leadingIndicator}</p>
                                      <table>
                                        <tr class="col-md-12">
                                          <th class="col-md-6">
                                            <h6>Parameter</h6>
                                          </th>
                                          <th class="col-md-6">
                                            <h6>Unit of Measure</h6>
                                          </th>
                                        </tr>
                                        {(item.hasOwnProperty('parameterList')
                                          ? item.parameterList
                                          : []
                                        ).map((params) => {
                                          return (
                                            <tr>
                                              <td class="col-md-6">
                                                <p>{params.ParameterName}</p>
                                              </td>
                                              <td class="col-md-6">
                                                <p>{params.ParameterUOM}</p>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </table>
                                    </div>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div className="rdv-new-info">
                                <img className="new-info-icon" src={newInfo} alt="info" />
                              </div>
                            </OverlayTrigger>
                          </div>
                          <div className="addboi-dropdown-containers">
                            <div className="addedBOI-dropdown">
                              {item.isBOIUsedInProject && tooltipDisabledFields}
                              <GrooveSelectMenuBasic
                                name="BOI"
                                isDisabled={
                                  item.isBOIUsedInProject
                                    ? true
                                    : item.boiList.length === 0 || isItemDisabled
                                }
                                placeholder={
                                  Object.keys(item.boi).length !== 0 ? 'BOI*' : 'Select BOI*'
                                }
                                list={item.boiListDisplay}
                                defaultData={item.boi}
                                id="boi"
                                contentType="search"
                                callbackFunction={(e) =>
                                  this.props.onEditBOI(e, 'boi', item, index)
                                }
                                onSelectmenuClick={() => this.props.onClickSelectMenuBOI(index)}
                              />
                            </div>
                            <div className="addedBOI-dropdown">
                              {item.isBOIUsedInProject && tooltipDisabledFields}
                              <GrooveSelectMenuBasic
                                name="unitofMeasure"
                                isDisabled={
                                  item.isBOIUsedInProject
                                    ? true
                                    : (item.uomList.length === 0 &&
                                      JSON.stringify(item.uom) === '{}') ||
                                    isItemDisabled
                                }
                                placeholder={
                                  Object.keys(item.uom).length !== 0
                                    ? 'Unit of Measure*'
                                    : 'Select Unit of Measure*'
                                }
                                list={item.uomList}
                                defaultData={item.uom}
                                id="uom"
                                contentType="search"
                                callbackFunction={(e) =>
                                  this.props.onEditBOI(e, 'uom', item, index)
                                }
                              />
                            </div>
                            <div className="addedBOI-dropdown">
                              <GrooveSelectMenuBasic
                                name="dimension"
                                isDisabled={item.dimensionList.length === 0 || isItemDisabled || dimSubDisabled}
                                placeholder={
                                  Object.keys(item.dimension).length !== 0
                                    ? 'Dimension*'
                                    : 'Select Dimension*'
                                }
                                list={item.dimensionList}
                                defaultData={item.dimension}
                                id="dimension"
                                contentType="search"
                                callbackFunction={(e) =>
                                  this.props.onEditBOI(e, 'dimension', item, index)
                                }
                              />
                            </div>
                            <div className="addedBOI-dropdown">
                              <GrooveSelectMenuBasic
                                name="subdimension"
                                isDisabled={item.subdimensionList.length === 0 || isItemDisabled || dimSubDisabled}
                                placeholder={
                                  Object.keys(item.subdimension).length !== 0
                                    ? 'Sub Dimension*'
                                    : 'Select Sub Dimension*'
                                }
                                list={item.subdimensionList}
                                defaultData={item.subdimension}
                                id="subdimension"
                                contentType="search"
                                callbackFunction={(e) =>
                                  this.props.onEditBOI(e, 'subdimension', item, index)
                                }
                              />
                            </div>{' '}
                          </div>
                          {/*(item.isExisting || item.missingDetails ? "delete-boi-button" : this.props.duplicateBOI || this.props.inComplete ? "delete-boi-button disabled-delete" : "delete-boi-button") */}
                          <div className="delete-boi">
                            {item.isBOIUsedInProject && tooltipDeleteBoi}
                            <div
                              className={
                                isItemDisabled || item.isBOIUsedInProject
                                  ? 'delete-boi edit-rejected-boi'
                                  : 'delete-boi'
                              }
                            >
                              <GrooveIconSvg
                                customClassName={
                                  type
                                    ? item.isBOIUsedInProject
                                      ? 'delete-boi-button disabled-delete'
                                      : 'delete-boi-button'
                                    : isItemDisabled
                                      ? 'delete-boi-button disabled-delete'
                                      : 'delete-boi-button'
                                }
                                size="large"
                                name="trash-alt"
                                iconStyle="solid"
                                iconColor="stat-alternate"
                                callbackFunction={
                                  item.isBOIUsedInProject
                                    ? ''
                                    : item.isExisting || item.missingDetails
                                      ? () => this.props.deleteBOI(item, index)
                                      : this.props.duplicateBOI || this.props.inComplete
                                        ? ''
                                        : () => this.props.deleteBOI(item, index)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            </React.Fragment>
          ),
        },
        {
          tab_label: 'Offering Agnostic Business Outcome Indicators',
          tab_value: 2,
          tab_id: 'agnostic-boi-tab',
          tab_content: (
            <React.Fragment>
              <AddAgnosticBOI
                {...{
                  failedValidation,
                  standardBOI,
                  tooltipDeleteBoi,
                  tooltipDisabledFields,
                }}
                inComplete={this.props.inComplete}
                duplicateBOI={this.props.duplicateBOI}
                isExisting={this.props.isExisting}
                deletedBOIForUndo={this.props.deletedBOIForUndo}
                selectedAgnosticBOIList={this.props.selectedAgnosticBOIList}
                filterAgnosticBOIList={this.props.filterAgnosticBOIList}
                filterAgnosticDropdownList={this.props.filterAgnosticDropdownList}
                selectedAgnosticBOIDisplay={this.props.selectedAgnosticBOIDisplay}
                agnosticDropdownList={this.props.agnosticDropdownList}
                agnosticDropdownDisplay={this.props.agnosticDropdownDisplay}
                agnosticBOIList={this.props.agnosticBOIList}
                onSearchAgnostic={this.props.onSearchAgnostic}
                tabValue={this.props.tabValue}
                boiToAddDetails={this.props.boiToAddDetails}
                selectAgnostic={this.props.selectAgnostic}
                addAgnosticBOI={this.props.addAgnosticBOI}
                editAgnosticBOI={this.props.editAgnosticBOI}
                deleteAgnosticBOI={this.props.deleteAgnosticBOI}
                deletedAgnosticBOIForUndo={this.props.deletedAgnosticBOIForUndo}
                setKeyword={this.props.setKeyword}
                restoreDeletedAgnosticBOI={this.props.restoreDeletedAgnosticBOI}
                removeDeletedAgnosticBOI={this.props.removeDeletedAgnosticBOI}
                onSearchAgnosticDropdownValue={this.props.onSearchAgnosticDropdownValue}
                selectAgnosticTab={this.props.selectAgnosticTab}
                resetAgnosticDropdownDisplay={this.props.resetAgnosticDropdownDisplay}
              />
            </React.Fragment>
          ),
        },
        {
          tab_label: 'Custom Business Outcome Indicators',
          tab_value: 3,
          tab_id: 'custom-boi-tab',
          tab_content: (
            <React.Fragment>
              <AddCustomBOI
                setDeletedAll={this.props.setDeletedAll}
                selectedBOIs={this.props.selectedBOIs}
                onCloseBOIModal={this.props.onCloseBOIModal}
                customBOICloseModal={this.props.customBOICloseModal}
                type={this.props.type}
                setAllSelectedBOIs={this.props.setAllSelectedBOIs}
                customBOIValidationMessage={this.props.customBOIValidationMessage}
                setDeletedBOICustom={this.props.setDeletedBOICustom}
              />
            </React.Fragment>
          ),
        },
      ];
      return (
        <React.Fragment>
          {this.props.type === 'Add' ? (
            <GrooveButton
              id="primry-btn-2"
              hasIcon={true}
              type="outline"
              colorClass="stat-alternate"
              text="Add BOIs"
              callbackFunction={() => this.props.onCloseModal()}
              iconAlignment="right"
              iconSize="small"
              iconName="plus"
              iconStyle="solid"
              iconId="btn-icon-01"
              isDisabled={disableAddBOI}
            />
          ) : (
            this.props.type !== undefined &&
            this.props.type.includes('Edit') && (
              <GrooveButton
                id="primry-btn-2"
                hasIcon={true}
                type="outline"
                colorClass="stat-alternate"
                text="Add/Edit BOIs"
                callbackFunction={() => this.props.onCloseModal()}
                iconAlignment="right"
                iconSize="small"
                iconName="edit"
                iconStyle="solid"
                iconId="btn-icon-01"
                isDisabled={disableAddBOI}
              />
            )
          )}
          {/* Modals */}
          <CVMModal
            title={
              this.props.type === 'Add'
                ? `Add BOI(s) to ${this.props.newProgramDetails.programName}`
                : this.props.type !== undefined && this.props.type.includes('Edit')
                  ? `Edit BOI(s) to ${this.props.newProgramDetails.programName}`
                  : ''
            }
            show={this.props.show}
            onCloseModal={this.props.onCloseBOIModal}
            size={this.props.size}
            content={
              <React.Fragment>
                <GrooveTabComponent
                  theme="purple"
                  customClassName="add-boi-popup"
                  tab_data={tab_data}
                  callbackFunction={this.props.onTabChange}
                />
                <div className="add-boi-buttons">
                  {this.props.customBOIError.invalidValues ? (
                    <p className="missing">Invalid value(s) entered</p>
                    ) : (
                      this.props.customBOIError.isExisting ||
                      this.props.customBOIError.duplicateBOI ||
                      this.props.isExisting ||
                      this.props.duplicateBOI
                    ) ? (
                      <p className="missing">BOI already exists</p>
                    ) : this.props.customBOIError.missingDetails ||
                    this.props.customBOIError.inComplete ||
                    this.props.inComplete ? (
                    <p className="missing">Missing Required Field(s)</p>
                  ) : (
                    ''
                  )}
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    text="Cancel"
                    callbackFunction={() => this.props.onCloseBOIModal()}
                  />
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={
                      this.props.duplicateBOI ||
                      this.props.inComplete ||
                      this.props.customBOIError.inComplete ||
                      this.props.customBOIError.missingDetails ||
                      this.props.customBOIError.unsavedNewCustomBOI ||
                      this.props.customBOIError.unsavedExistingCustomBOI ||
                      this.props.customBOIError.duplicateBOI ||
                      this.props.customBOIError.isExisting ||
                      this.props.customBOIError.invalidValues ||
                      (disabledBtnSBSave && disabledBtnCBSave & disabledBtnABSave)
                    }
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    text={this.props.type === 'Edit Rejected' ? 'Update' : 'Save'}
                    callbackFunction={this.props.onSaveAddBOI}
                  />
                </div>
              </React.Fragment>
            }
          />
          <CVMModal
            title={''}
            show={this.props.showWarningBOI}
            onCloseModal={this.props.onCloseBOIModal}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                handleCancel={this.props.onCloseBOIModal}
                handleYes={() => this.props.onCloseModal()}
                body={
                  <div>
                    <h5>{`Are you sure you want to close "${this.props.type} BOI(s)" modal?`}</h5>

                    <p>Please note that this would not save your changes.</p>
                  </div>
                }
              />
            }
          />

          <CVMModal
            title={''}
            show={this.props.showDeleteBOIAlert}
            onCloseModal={this.props.deleteBOIAlert}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                handleCancel={() => this.props.deleteBOIAlert()}
                handleYes={() => this.props.deleteBOIAlert()}
                hideButtons={true}
                body={
                  <div>
                    <h5>
                      {`You cannot un-select the checkbox as you have added BOIs from other offerings into the program. Kindly delete BOIs that are not mapped with selected offering to uncheck it`}
                    </h5>
                  </div>
                }
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class AddBOIPopupContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: false,
        size: 'lg',
        isExisting: false,
        keyword: '',
        list: {
          boi: [],
          uom: [],
          dimension: [],
          subdimension: [],
        },
        boiToAdd: {
          boi: {},
          uom: {},
          dimension: {},
          subdimension: {},
        },
        selectedBOI: [],
        selectedBOIDisplay: [],
        showWarningBOI: false,
        duplicateBOI: false,
        inComplete: false,
        deletedAll: false,
        deletedBOIForUndo: [],
        deletedBOIForUndoCustom: [],
        selectedTab: 1,
        selectedBOIs: [],
        customBOIError: {
          isExisting: false,
          inComplete: false,
          duplicateBOI: false,
          missingDetails: false,
          unsavedNewCustomBOI: false,
          unsavedExistingCustomBOI: false,
          invalidValues: false,
        },
        deletedBOIList: [],
        isClick: false,
        origCheckStat: false,
        showDeleteBOIAlert: false,
        clickedUncheck: false,
        //Agnostic BOI states
        selectedAgnosticTab: 1,
        tabValue: {},
        agnosticBOIList: [],
        selectedAgnosticBOIList: [],
        selectedAgnosticBOIDisplay: [],
        agnosticDropdownList: [],
        agnosticDropdownDisplay: [],
        agnosticType: [
          //agnostic filter config, update to filter boilist if agnostic type will be updated
          {
            label: 'Talent',
            value: 4,
          },
          {
            label: 'Inclusion & Diversity',
            value: 5,
          },
          {
            label: 'Global',
            value: 6,
          },
        ],
        boiToAddDetails: {
          UOM: '',
          CoreDimensionName: '',
          SubDimensionName: '',
          BOIName: '',
        },
        deletedAgnosticBOIForUndo: [],
        deletedAgnosticBOIList: [],
        showNotifMessage: false,
      };
    }
    componentDidUpdate = async (nextProps) => {
      // Prepare initial list of BOIs
      if (
        nextProps.ProgramConfigurationData.boiMappingListAllOfferings !==
        this.props.ProgramConfigurationData.boiMappingListAllOfferings &&
        nextProps.ProgramConfigurationData.boiMappingListAllOfferings.length !== 0
      ) {
        this.prepInitialBOIList(this.props.ProgramConfigurationData.isCheckedAllOfferings);
      }
      if (
        this.props.selectedBOIs !== undefined &&
        this.props.selectedBOIs.length > 0 &&
        Object.keys(this.props.ProgramConfigurationData.boiMappingListAllOfferings).length !== 0 &&
        this.state.selectedBOI.length === 0 &&
        this.state.selectedAgnosticBOIList.length === 0 &&
        !this.state.deletedAll
      ) {
        this.formatBOIs(this.props.selectedBOIs);
      }
      if (!this.state.isClick) {
        this.handleCheckAllOfferings(this.props.ProgramConfigurationData.isCheckedAllOfferings);
        this.showDisclaimerMessage();
      }
    };
    componentDidMount = () => {
      this.prepInitialBOIList(this.props.ProgramConfigurationData.isCheckedAllOfferings);
      if (
        this.props.ProgramConfigurationData.selectedProgramDetails.length <= 0 &&
        !this.state.isClick
      ) {
        this.handleCheckAllOfferings(
          this.props.ProgramConfigurationData.selectedProgramDetails.allOfferings
        );
        this.showDisclaimerMessage();
      } else if (
        this.props.ProgramConfigurationData.newProgramDetails.offering.label === 'Overall'
      ) {
        this.handleCheckAllOfferings(true);
      }
    };

    clickCheckBox = (checkboxState) => {
      if (!checkboxState) {
        this.setState({
          clickedUncheck: true,
        });
      }
      this.handleCheckAllOfferings(checkboxState);
    };

    handleCheckAllOfferings = (checkboxState) => {
      let shouldContinue = false;
      if (!checkboxState) {
        shouldContinue = !this.checkForBOIOutsideOffering(); //if user uncheck the box but ther is boi outside the selected offering process should not continue.
      } else {
        shouldContinue = true;
      }
      if (shouldContinue) {
        const { isCheckedAllOfferings, newProgramDetails, selectedProgramDetails } =
          this.props.ProgramConfigurationData;
        const offering = newProgramDetails.offering.label;

        if (offering !== 'OverallTest') {
          let subofferingsID = '';

          if (this.props.ProgramConfigurationData.selectedProgramDetails.length <= 0) {
            subofferingsID = this.prepNewSubofferingsID(
              this.props.ProgramConfigurationData.newProgramDetails.subOffering
            );
          }
          let tmpcheckboxState = checkboxState ? checkboxState : false;
          this.props.handleCheckAllOfferings(tmpcheckboxState).then((result) => {
            this.prepInitialBOIList(result);
          });
        }
        this.setState({
          isClick: true,
        });
      } else {
        this.deleteBOIAlert();
      }
    };

    deleteBOIAlert = () => {
      this.setState({
        showDeleteBOIAlert: !this.state.showDeleteBOIAlert,
        isClick: true
      });
    };

    checkForBOIOutsideOffering = () => {
      const selectedBOI = this.state.selectedBOI;
      const boiUnderOffering = this.props.ProgramConfigurationData.boiMappingList.BIOMappingList
        ? this.props.ProgramConfigurationData.boiMappingList.BIOMappingList
        : [];
      let hasBOIOutside = false;
      if (selectedBOI.length > 0) {
        selectedBOI.map((b) => {
          if (!b.IsCustom) {
            let boi = b.boi.label;
            let uom = b.uom.label;
            let doesMatch = false;
            // if(!hasBOIOutside){
            boiUnderOffering.map((a) => {
              if (a.BOIName === boi && a.UOM === uom) {
                doesMatch = true;
              }
            });
            // }
            if (!doesMatch) {
              hasBOIOutside = true;
              b.boiOutsideOffering = true;
            }
          }
        });
      }
      return hasBOIOutside;
    };

    prepNewSubofferingsID = (subOfferings) => {
      let arrId = [];
      if (subOfferings.length > 0) {
        subOfferings.map((item) => {
          arrId.push(item.value);
        });
      }
      return arrId.join(',');
    };
    onCloseBOIModal = (val) => {
      this.setState({
        showWarningBOI: val === 'close' ? false : !this.state.showWarningBOI,
        duplicateBOI: val === 'close' ? false : this.state.duplicateBOI,
        inComplete: val === 'close' ? false : this.state.inComplete,
        clickedUncheck: false,
      });
    };

    onTabChange = (e) => {
      let prevSelectedTab = this.state.selectedTab;
      if (prevSelectedTab !== e.value) {
        let list = this.prepForSaving();
        this.setSelectedBOIs(list);
        this.setState(
          {
            selectedTab: e.value,
          },
          async () => {
            await this.clearState();
            if (e.value === 1) {
              this.onSearch();
            } else {
              this.onSearchAgnostic();
            }
          }
        );
      }
    };

    setAllSelectedBOIs = (bois) => {
      let allBOIs = bois.map((boi) => {
        if (boi.boiParentID !== 3) {
          let toAdd = {
            boi: boi.boi,
          };
          return {
            ...boi,
            uomList: this.prepDropdownLists('uom', toAdd),
          };
        }
      });
      this.setState(
        {
          selectedBOI: allBOIs,
          selectedBOIDisplay: allBOIs,
        },
        () => {
          this.clearState();
          this.prepInitialBOIList(this.props.ProgramConfigurationData.isCheckedAllOfferings);
          this.onSearch();
        }
      );
    };

    prepDropdownLists = (field, values) => {
      //check if exist on the mappinglist
      let boiMapping = this.props.ProgramConfigurationData.boiMappingListAllOfferings;
      let boiMappingPerOffering = this.props.ProgramConfigurationData.boiMappingList.BIOMappingList
      let list = [];
      let listRef = [];

      switch (field) {
        case 'uom':
          if (this.props.ProgramConfigurationData.isCheckedAllOfferings) {
            boiMapping.BOIUnitOfMeasureList.map((item) => {
              if (item.BOIName === values.boi.label && listRef.indexOf(item.UOM) === -1) {
                list.push({ label: item.UOM, text: item.UOM, value: item.UOM });
                listRef.push(item.UOM);
              }
            });
          } else { //unchecked checkbox
            boiMappingPerOffering.map((item) => {
              if (item.BOIName === values.boi.label && listRef.indexOf(item.UOM) === -1) {
                list.push({ label: item.UOM, text: item.UOM, value: item.UOM });
                listRef.push(item.UOM);
              }
            });
          }
          break;
        case 'dimension':
          // boiMapping.DimensionList.map((item) => {
          // if (item.BOIName === values.boi.label && item.UOM === values.uom.label && listRef.indexOf(item.DimensionName) === -1) {
          boiMapping.DimensionList.map((item) => {
            if (listRef.indexOf(item.DimensionName) === -1) {
              list.push({
                label: item.DimensionName,
                text: item.DimensionName,
                value: item.DimensionID,
              });
              listRef.push(item.DimensionName);
            }
          });
          break;
        case 'subdimension':
          // boiMapping.BIOMappingList.map((item) => {
          //   if (item.BOIName === values.boi.label && item.UOM === values.uom.label && item.CoreDimensionID === values.dimension.value && listRef.indexOf(item.SubDimensionName) === -1) {
          //     list.push({ label: item.SubDimensionName, text: item.SubDimensionName, value: item.SubDimensionID });
          //     listRef.push(item.SubDimensionName);
          //   }
          // })
          boiMapping.BOIMappedSubDimensionList.map((item) => {
            if (
              item.DimensionID === values.dimension.value &&
              listRef.indexOf(item.SubDimensionName) === -1
            ) {
              list.push({
                label: item.SubDimensionName,
                text: item.SubDimensionName,
                value: item.SubDimensionID,
              });
              listRef.push(item.SubDimensionName);
            }
          });
          break;
        default:
          break;
      }
      return list;
    };

    formatBOIs = (bois) => {
      let selectedBOI = [];
      let selectedAgnosticBOIList = [];
      bois.map((dim) => {
        dim.subDimensionList.map((subdim) => {
          subdim.boiList.map((boi) => {
            //  this is for setting BOI ID based on the boi dropdown
            let boiId = 0;
            let boiList = this.state.list.boi;
            boiList.map((item) => {
              if (item.label === boi.boiName) {
                boiId = item.value;
              }
            });
            if (boi.boiParentID !== 3) {
              let toAdd = {
                boi: {
                  label: boi.boiName,
                  text: boi.boiName,
                  value: boi.IsCustom ? boi.boiID : boiId,
                },
                uom: { label: boi.uom, text: boi.uom, value: boi.uom },
                dimension: {
                  label: dim.dimensionName,
                  text: dim.dimensionName,
                  value: dim.dimensionID,
                },
                subdimension: {
                  label: subdim.subDimensionName,
                  text: subdim.subDimensionName,
                  value: subdim.subDimensionID,
                },
                boidefinition: {
                  label: boi.IsCustom ? boi.boiDefinition : '',
                  text: boi.IsCustom ? boi.boiDefinition : '',
                  value: boi.IsCustom ? boi.boiDefinitionMasterID : 0,
                },
                aggregationlogic: {
                  label: boi.IsCustom ? boi.aggregationLogic : '',
                  text: boi.IsCustom ? boi.aggregationLogic : '',
                  value: boi.IsCustom ? boi.aggregationLogicMasterID : 0,
                },
                suboffering: {
                  label: boi.suboffering,
                  text: boi.suboffering,
                  value: boi.subofferingID,
                },
              };

              if (boi.isActive !== 0) {
                selectedBOI.push({
                  ...toAdd,
                  boiListDisplay: [toAdd.boi],
                  boiList: boiList,
                  uomList: this.prepDropdownLists('uom', toAdd),
                  dimensionList: this.prepDropdownLists('dimension', toAdd),
                  subdimensionList: this.prepDropdownLists('subdimension', toAdd),

                  boiStatus: boi.boiStatus,
                  comment: boi.comment,
                  boiDescription: boi.boiDescription,
                  //added description since some of the files are using description instead of boiDescription
                  description: boi.boiDescription, //description = boiDescription
                  paramValue: boi.paramValue,
                  oldParamValue: TryGetObjValueAlt(boi, '', 'oldParamValue'),
                  boiClientProgramTargetID: boi.boiClientProgramTargetID,
                  boiCode: boi.boiCode,
                  boiRealizedValue: boi.boiRealizedValue,
                  boiRejectionRecipient: boi.boiRejectionRecipient,
                  IsCustom: boi.IsCustom,
                  isBOIUsedInProject: boi.isBOIUsedInProject,
                  isExisting: false,
                  isDisableUpdatingDimension: boi.hasOwnProperty('isDisableUpdatingDimension') ? boi.isDisableUpdatingDimension : false,
                  missingDetails: false,
                  oldUOM: boi.uom,
                  boiWheelVisibility:
                    boi.boiWheelVisibility !== undefined ? boi.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1

                  leadingIndicator: boi.leadingIndicator,
                  reasonForNoBOIDefinition: boi.IsCustom ? boi.reasonForNoBOIDefinition : '',
                  reasonForNoAggregationLogic: boi.IsCustom ? boi.reasonForNoAggregationLogic : '',
                  customParameterList: boi.IsCustom ? boi.customParameterList : [],
                  newSubDimensionName: boi.newSubDimensionName ? boi.newSubDimensionName : '',
                  noOfParameter: boi.IsCustom ? boi.noOfParameter : 0,
                  boiAdditionComment: boi.boiAdditionComment,
                  boiOldComment: boi.boiOldComment,
                  metricDefinitionID: boi.metricDefinitionID,
                  offeringId: boi.offeringID,
                  offeringName: boi.offeringName,
                  parameterList: boi.parameterList ? boi.parameterList : [],
                  boiOutsideOffering: false,
                  boiParentID: boi.boiParentID,
                });
              }
            } else {
              if (boi.isActive !== 0) {
                selectedAgnosticBOIList.push({
                  boi: boi.boiName,
                  boiID: boi.boiID,
                  uom: boi.uom,
                  dimension: dim.dimensionName,
                  dimensionID: dim.dimensionID,
                  subdimension: subdim.subDimensionName,
                  subDimensionID: subdim.subDimensionID,
                  suboffering: {
                    label: boi.suboffering,
                    text: boi.suboffering,
                    value: boi.subofferingID,
                  },
                  // boiList: boiList,
                  // uomList: this.prepDropdownLists('uom', toAdd),
                  // dimensionList: this.prepDropdownLists('dimension', toAdd),
                  // subdimensionList: this.prepDropdownLists('subdimension', toAdd),

                  boiStatus: boi.boiStatus,
                  comment: boi.comment,
                  boiDescription: boi.boiDescription,
                  //added description since some of the files are using description instead of boiDescription
                  description: boi.boiDescription, //description = boiDescription
                  paramValue: boi.paramValue,
                  oldParamValue: TryGetObjValueAlt(boi, '', 'oldParamValue'),
                  boiClientProgramTargetID: boi.boiClientProgramTargetID,
                  boiCode: boi.boiCode,
                  boiRealizedValue: boi.boiRealizedValue,
                  boiRejectionRecipient: boi.boiRejectionRecipient,
                  IsCustom: boi.IsCustom,
                  isBOIUsedInProject: boi.isBOIUsedInProject,
                  isDisableUpdatingDimension: boi.hasOwnProperty('isDisableUpdatingDimension') ? boi.isDisableUpdatingDimension : false,
                  isExisting: false,
                  missingDetails: false,
                  oldUOM: boi.uom,
                  boiWheelVisibility:
                    boi.boiWheelVisibility !== undefined ? boi.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1

                  leadingIndicator: boi.leadingIndicator,
                  reasonForNoBOIDefinition: boi.IsCustom ? boi.reasonForNoBOIDefinition : '',
                  reasonForNoAggregationLogic: boi.IsCustom ? boi.reasonForNoAggregationLogic : '',
                  customParameterList: boi.IsCustom ? boi.customParameterList : [],
                  newSubDimensionName: boi.newSubDimensionName ? boi.newSubDimensionName : '',
                  noOfParameter: boi.IsCustom ? boi.noOfParameter : 0,
                  boiAdditionComment: boi.boiAdditionComment,
                  boiOldComment: boi.boiOldComment,
                  metricDefinitionID:
                    boi.metricDefinitionID !== undefined ? boi.metricDefinitionID : 0,
                  offeringId: boi.offeringID,
                  offeringName: boi.offeringName,
                  parameterList: boi.parameterList ? boi.parameterList : [],
                  boiOutsideOffering: false,
                  boiParentID: boi.boiParentID,
                });
              }
            }
          });
        });
      });

      this.setState(
        {
          selectedBOI: JSON.parse(JSON.stringify(selectedBOI)),
          selectedBOIDisplay: JSON.parse(JSON.stringify(selectedBOI)),
          selectedAgnosticBOIList: selectedAgnosticBOIList,
          selectedAgnosticBOIDisplay: selectedAgnosticBOIList,
        },
        () => {
          // if onload and there is a boi selected that was outside the selected offering then check boc should default to checked
          if (this.checkForBOIOutsideOffering()) {
            this.handleCheckAllOfferings(true);
          }
        }
      );
    };

    prepInitialBOIList = (status) => {
      let { dropdownListByOfferings, dropdownListAllOfferings } =
        this.props.ProgramConfigurationData;
      this.setState({
        list: {
          boi: status ? dropdownListAllOfferings : dropdownListByOfferings,
          uom: [],
          dimension: [],
          subdimension: [],
        },
      });
    };

    onCloseModal = () => {
      // Clear state on modal close
      if (this.state.show) {
        this.clearState();
        this.props.handleCheckAllOfferings(this.state.origCheckStat);
      } else {
        this.prepInitialBOIList(this.props.ProgramConfigurationData.isCheckedAllOfferings);
        this.setState({
          origCheckStat: this.props.ProgramConfigurationData.isCheckedAllOfferings,
        });
        this.showDisclaimerMessage();
      }
      this.setState({
        show: !this.state.show,
        selectedBOI: [],
        selectedBOIDisplay: [],
        deletedBOIForUndo: [],
        selectedTab: 1,
        selectedAgnosticTab: 1,
        deletedAgnosticBOIForUndo: [],
        selectedAgnosticBOIDisplay: [],
        selectedAgnosticBOIList: [],
      });
      this.onCloseBOIModal('close');
    };

    showDisclaimerMessage = async () => {
      await this.setState({
        showNotifMessage: true,
      });
      setTimeout(
        () =>
          this.setState({
            showNotifMessage: false,
          }),
        60000
      );
    };
    filterUOM = () => {
      let selected = this.state.boiToAdd;
      let uom = this.prepDropdownLists('uom', selected);
      // if options is only 1 then select it by default
      if (uom.length === 1) {
        selected.uom = uom[0];
      } else {
        selected.uom = {};
      }
      this.setState(
        {
          list: { ...this.state.list, uom: uom },
          boiToAdd: selected,
        },
        this.filterDimension
      );
    };

    filterDimension = () => {
      // let templist = [];
      let selected = this.state.boiToAdd;
      let dimension = this.prepDropdownLists('dimension', selected);
      let listDimension = [];
      // if options is only 1 then select it by default
      this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList.map((item) => {
        if (
          item.BOIName === this.state.boiToAdd.boi.label &&
          item.UOM === this.state.boiToAdd.uom.label &&
          listDimension.indexOf(item.CoreDimensionName) === -1
        ) {
          listDimension.push({
            label: item.CoreDimensionName,
            text: item.CoreDimensionName,
            value: item.CoreDimensionID,
          });
        }
      });
      if (listDimension.length === 1) {
        selected.dimension = listDimension[0];
      } else {
        selected.dimension = {};
      }
      this.setState(
        {
          list: { ...this.state.list, dimension: dimension },
          boiToAdd: selected,
        },
        this.filterSubDimension
      );
    };

    filterSubDimension = () => {
      let selected = this.state.boiToAdd;
      let subdimension = this.prepDropdownLists('subdimension', selected);
      let listSubDimension = [];
      // if options is only 1 then select it by default
      this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList.map((item) => {
        if (
          item.BOIName === this.state.boiToAdd.boi.label &&
          item.UOM === this.state.boiToAdd.uom.label &&
          item.CoreDimensionName === this.state.boiToAdd.dimension.label &&
          listSubDimension.indexOf(item.SubDimensionName) === -1
        ) {
          listSubDimension.push({
            label: item.SubDimensionName,
            text: item.SubDimensionName,
            value: item.SubDimensionID,
          });
        }
      });
      if (listSubDimension.length === 1) {
        selected.subdimension = listSubDimension[0];
      } else {
        selected.subdimension = {};
      }
      this.setState(
        {
          list: { ...this.state.list, subdimension: subdimension },
          boiToAdd: selected,
        },
        () => {
          this.setState({
            isExisting: this.checkForDuplicates(this.state.boiToAdd, this.state.selectedBOI),
          });
        }
      );
    };

    onSelectDropdown = (e, field) => {
      let selected = this.state.boiToAdd;
      switch (field) {
        case 'boi':
          selected.boi = e;
          this.filterUOM();
          break;
        case 'uom':
          selected.uom = e;
          this.filterDimension();
          break;
        case 'dimension':
          selected.dimension = e;
          this.filterSubDimension();
          break;
        case 'subdimension':
          selected.subdimension = e;
          break;
        default:
          break;
      }
      this.setState(
        {
          boiToAdd: selected,
        },
        () => {
          this.setState({
            isExisting: this.checkForDuplicates(this.state.boiToAdd, this.state.selectedBOI),
          });
        }
      );
    };

    setSelectedBOIs = (list) => {
      this.setState({
        selectedBOIs: list,
      });
    };

    prepForSaving = () => {
      let selectedBOI = this.state.selectedBOI;
      let boiMappingList =
        this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList;
      let boiList = this.props.ProgramConfigurationData.boiMappingListAllOfferings.BOIList;

      let list = [];
      selectedBOI.forEach((item) => {
        // get real BOI id of each BOI
        let trueBoiId = 0;

        boiMappingList.forEach((boi) => {
          if (boi.BOIName === item.boi.label && boi.UOM === item.uom.label && trueBoiId === 0) {
            trueBoiId = boi.BOIID;
          }
          if (
            boi.BOIName === item.boi.label &&
            boi.UOM === item.uom.label &&
            boi.CoreDimensionID === item.dimension.value &&
            boi.SubDimensionID === item.subdimension.value &&
            boi.SubOfferingID === item.suboffering.value
          ) {
            trueBoiId = boi.BOIID;
          }
        });
        //format paramValue if uom has change from usd
        if (
          TryGetObjValueAlt(item, '', 'oldUOM').toLowerCase() === 'usd' &&
          item.uom.text.toLowerCase() !== 'usd' &&
          item.paramValue !== null
        ) {
          const pValue = FormatCVMParamValue('', item.paramValue.toString(), item.uom.text);
          item['paramValue'] = pValue.value;
          item['inValidMsg'] = pValue.inValidMsg;
        }

        let boiDesc = TryGetObjValueAlt(
          boiList.filter((boi) => {
            return boi.BOIID === trueBoiId;
          })[0],
          '',
          'BOIDescription'
        );

        // Check if dimension already added
        let dimNotExist = true;
        list.forEach((dim) => {
          if (String(dim.dimensionID) === String(item.dimension.value)) {
            dimNotExist = false;
            // Check if subdimension already added
            let subdimNotExist = true;
            dim.subDimensionList.forEach((subdim) => {
              if (
                subdim.subDimensionID === item.subdimension.value &&
                item.subdimension.value !== 0
              ) {
                subdimNotExist = false;
                subdim.boiList.push({
                  boiID: item.IsCustom ? item.boi.value : trueBoiId,
                  boiName: item.boi.label,
                  paramValue: item.paramValue,
                  oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                  uom: item.uom.label,
                  comment: item.comment,
                  boiDescription: item.IsCustom ? item.boiDescription : boiDesc,
                  //added description since some of the files are using description instead of boiDescription
                  description: item.IsCustom ? item.boiDescription : boiDesc, //description = boiDescription
                  boiRealizedValue: item.boiRealizedValue,
                  boiStatus: item.boiStatus,
                  boiClientProgramTargetID: item.boiClientProgramTargetID,
                  boiRejectionRecipient: item.boiRejectionRecipient,
                  boiCode: item.boiCode,
                  IsCustom: item.IsCustom,
                  isActive: 1,
                  //additional details
                  reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                  reasonForNoAggregationLogic: item.IsCustom
                    ? item.reasonForNoAggregationLogic
                    : '',
                  aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                  aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                  boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                  boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                  leadingIndicator: item.leadingIndicator,
                  customParameterList: item.IsCustom ? item.customParameterList : [],
                  newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                  noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                  offeringID: item.offeringNameID,
                  offeringName: item.offeringName,
                  suboffering: item.suboffering.label,
                  subofferingID: item.suboffering.value,
                  boiAdditionComment: item.boiAdditionComment,
                  boiOldComment: item.boiOldComment,
                  metricDefinitionID: item.metricDefinitionID,
                  isBOIUsedInProject: item.isBOIUsedInProject,
                  isDisableUpdatingDimension: item.isDisableUpdatingDimension,
                  boiWheelVisibility:
                    item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                  parameterList: item.parameterList !== undefined ? item.parameterList : [],
                  boiParentID: item.boiParentID,
                });
              }
            });

            //IF SUBDIM IS NOT YET CREATED
            if (subdimNotExist) {
              dim.subDimensionList.push({
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension.label,
                subDimensionID: item.subdimension.value,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiID: item.IsCustom ? item.boi.value : trueBoiId,
                    boiName: item.boi.label,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom.label,
                    comment: item.comment,
                    boiDescription: item.IsCustom ? item.boiDescription : boiDesc,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.IsCustom ? item.boiDescription : boiDesc, //description = boiDescription
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringNameID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID: item.metricDefinitionID,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility: item.boiWheelVisibility ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    boiParentID: item.boiParentID,
                  },
                ],
              });
            }
          }
        });
        //IF DIM IS NOT YET CREATED
        if (dimNotExist) {
          list.push({
            dimensionName: item.dimension.label,
            dimensionID: item.dimension.value,
            dimensionReason: '',
            dimensionStatus: '',
            dimensionTarget: '',
            subDimensionList: [
              {
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension.label,
                subDimensionID: item.subdimension.value,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiID: item.IsCustom ? item.boi.value : trueBoiId,
                    boiName: item.boi.label,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom.label,
                    comment: item.comment,
                    leadingIndicator: item.leadingIndicator,
                    boiDescription: item.IsCustom ? item.boiDescription : boiDesc,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.IsCustom ? item.boiDescription : boiDesc, //description = boiDescription
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringNameID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID: item.metricDefinitionID,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility:
                      item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    boiParentID: item.boiParentID,
                  },
                ],
              },
            ],
          });
        }
      });
      return list;
    };

    prepForSavingAgnostic = () => {
      let selectedBOI = this.state.selectedBOI;
      let selectedAgnosticBOIList = this.state.selectedAgnosticBOIList;
      let boiMappingList =
        this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList;
      let boiList = this.props.ProgramConfigurationData.boiMappingListAllOfferings.BOIList;

      let list = [];
      selectedBOI.forEach((item) => {
        // get real BOI id of each BOI
        let trueBoiId = 0;

        boiMappingList.forEach((boi) => {
          if (boi.BOIName === item.boi.label && boi.UOM === item.uom.label && trueBoiId === 0) {
            trueBoiId = boi.BOIID;
          }
          if (
            boi.BOIName === item.boi.label &&
            boi.UOM === item.uom.label &&
            boi.CoreDimensionID === item.dimension.value &&
            boi.SubDimensionID === item.subdimension.value &&
            boi.SubOfferingID === item.suboffering.value
          ) {
            trueBoiId = boi.BOIID;
          }
        });
        //format paramValue if uom has change from usd
        if (
          TryGetObjValueAlt(item, '', 'oldUOM').toLowerCase() === 'usd' &&
          item.uom.text.toLowerCase() !== 'usd' &&
          item.paramValue !== null
        ) {
          const pValue = FormatCVMParamValue('', item.paramValue.toString(), item.uom.text);
          item['paramValue'] = pValue.value;
          item['inValidMsg'] = pValue.inValidMsg;
        }

        let boiDesc = TryGetObjValueAlt(
          boiList.filter((boi) => {
            return boi.BOIID === trueBoiId;
          })[0],
          '',
          'BOIDescription'
        );

        // Check if dimension already added
        let dimNotExist = true;
        list.forEach((dim) => {
          if (String(dim.dimensionID) === String(item.dimension.value)) {
            dimNotExist = false;
            // Check if subdimension already added
            let subdimNotExist = true;
            dim.subDimensionList.forEach((subdim) => {
              if (
                subdim.subDimensionID === item.subdimension.value &&
                item.subdimension.value !== 0
              ) {
                subdimNotExist = false;
                subdim.boiList.push({
                  boiID: item.IsCustom ? item.boi.value : trueBoiId,
                  boiName: item.boi.label,
                  paramValue: item.paramValue,
                  oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                  uom: item.uom.label,
                  comment: item.comment,
                  boiDescription: item.IsCustom ? item.boiDescription : boiDesc,
                  //added description since some of the files are using description instead of boiDescription
                  description: item.IsCustom ? item.boiDescription : boiDesc, //description = boiDescription
                  boiRealizedValue: item.boiRealizedValue,
                  boiStatus: item.boiStatus,
                  boiClientProgramTargetID: item.boiClientProgramTargetID,
                  boiRejectionRecipient: item.boiRejectionRecipient,
                  boiCode: item.boiCode,
                  IsCustom: item.IsCustom,
                  isActive: 1,
                  //additional details
                  reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                  reasonForNoAggregationLogic: item.IsCustom
                    ? item.reasonForNoAggregationLogic
                    : '',
                  aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                  aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                  boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                  boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                  leadingIndicator: item.leadingIndicator,
                  customParameterList: item.IsCustom ? item.customParameterList : [],
                  newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                  noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                  offeringID: item.offeringNameID,
                  offeringName: item.offeringName,
                  suboffering: item.suboffering.label,
                  subofferingID: item.suboffering.value,
                  boiAdditionComment: item.boiAdditionComment,
                  boiOldComment: item.boiOldComment,
                  metricDefinitionID: item.metricDefinitionID,
                  isBOIUsedInProject: item.isBOIUsedInProject,
                  boiWheelVisibility:
                    item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                  parameterList: item.parameterList !== undefined ? item.parameterList : [],
                  boiParentID: item.boiParentID,
                });
              }
            });

            //IF SUBDIM IS NOT YET CREATED
            if (subdimNotExist) {
              dim.subDimensionList.push({
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension.label,
                subDimensionID: item.subdimension.value,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiID: item.IsCustom ? item.boi.value : trueBoiId,
                    boiName: item.boi.label,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom.label,
                    comment: item.comment,
                    boiDescription: item.IsCustom ? item.boiDescription : boiDesc,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.IsCustom ? item.boiDescription : boiDesc, //description = boiDescription
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringNameID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID: item.metricDefinitionID,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility: item.boiWheelVisibility ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    boiParentID: item.boiParentID,
                  },
                ],
              });
            }
          }
        });
        //IF DIM IS NOT YET CREATED
        if (dimNotExist) {
          list.push({
            dimensionName: item.dimension.label,
            dimensionID: item.dimension.value,
            dimensionReason: '',
            dimensionStatus: '',
            dimensionTarget: '',
            subDimensionList: [
              {
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension.label,
                subDimensionID: item.subdimension.value,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiID: item.IsCustom ? item.boi.value : trueBoiId,
                    boiName: item.boi.label,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom.label,
                    comment: item.comment,
                    leadingIndicator: item.leadingIndicator,
                    boiDescription: item.IsCustom ? item.boiDescription : boiDesc,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.IsCustom ? item.boiDescription : boiDesc, //description = boiDescription
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringNameID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID: item.metricDefinitionID,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility:
                      item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    boiParentID: item.boiParentID,
                  },
                ],
              },
            ],
          });
        }
      });

      selectedAgnosticBOIList.map((item) => {
        // Check if dimension already added
        let dimNotExist = true;
        list.forEach((dim) => {
          if (String(dim.dimensionID) === String(item.dimensionID)) {
            dimNotExist = false;
            // Check if subdimension already added
            let subdimNotExist = true;
            dim.subDimensionList.forEach((subdim) => {
              if (subdim.subDimensionID === item.subDimensionID && item.subDimensionID !== 0) {
                subdimNotExist = false;
                subdim.boiList.push({
                  boiID: item.boiID,
                  boiName: item.boi,
                  paramValue: item.paramValue,
                  oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                  uom: item.uom,
                  comment: item.comment,
                  boiDescription: item.boiDescription,
                  //added description since some of the files are using description instead of boiDescription
                  description: item.description, //description = boiDescription
                  boiRealizedValue: item.boiRealizedValue,
                  boiStatus: item.boiStatus,
                  boiClientProgramTargetID: item.boiClientProgramTargetID,
                  boiRejectionRecipient: item.boiRejectionRecipient,
                  boiCode: item.boiCode,
                  IsCustom: item.IsCustom,
                  isActive: 1,
                  //additional details
                  reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                  reasonForNoAggregationLogic: item.IsCustom
                    ? item.reasonForNoAggregationLogic
                    : '',
                  aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                  aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                  boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                  boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                  leadingIndicator: item.leadingIndicator,
                  customParameterList: item.IsCustom ? item.customParameterList : [],
                  newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                  noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                  offeringID: item.offeringID,
                  offeringName: item.offeringName,
                  suboffering: item.suboffering.label,
                  subofferingID: item.suboffering.value,
                  boiAdditionComment: item.boiAdditionComment,
                  boiOldComment: item.boiOldComment,
                  metricDefinitionID:
                    item.metricDefinitionID !== undefined ? item.metricDefinitionID : 0,
                  isBOIUsedInProject: item.isBOIUsedInProject,
                  boiWheelVisibility:
                    item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                  parameterList: item.parameterList !== undefined ? item.parameterList : [],
                  boiParentID: item.boiParentID,
                });
              }
            });

            //IF SUBDIM IS NOT YET CREATED
            if (subdimNotExist) {
              dim.subDimensionList.push({
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension,
                subDimensionID: item.subDimensionID,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiID: item.boiID,
                    boiName: item.boi,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom,
                    comment: item.comment,
                    boiDescription: item.boiDescription,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.description, //description = boiDescription
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID:
                      item.metricDefinitionID !== undefined ? item.metricDefinitionID : 0,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility:
                      item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    boiParentID: item.boiParentID,
                  },
                ],
              });
            }
          }
        });
        //IF DIM IS NOT YET CREATED
        if (dimNotExist) {
          list.push({
            dimensionName: item.dimension,
            dimensionID: item.dimensionID,
            dimensionReason: '',
            dimensionStatus: '',
            dimensionTarget: '',
            subDimensionList: [
              {
                subDimensionName:
                  item.newSubDimensionName !== '' && item.newSubDimensionName !== undefined
                    ? item.newSubDimensionName
                    : item.subdimension,
                subDimensionID: item.subDimensionID,
                subDimensionReason: '',
                subDimensionStatus: '',
                subDimensionTarget: '',
                boiList: [
                  {
                    boiID: item.boiID,
                    boiName: item.boi,
                    paramValue: item.paramValue,
                    oldParamValue: TryGetObjValueAlt(item, '', 'oldParamValue'),
                    uom: item.uom,
                    comment: item.comment,
                    boiDescription: item.boiDescription,
                    //added description since some of the files are using description instead of boiDescription
                    description: item.description, //description = boiDescription
                    boiRealizedValue: item.boiRealizedValue,
                    boiStatus: item.boiStatus,
                    boiClientProgramTargetID: item.boiClientProgramTargetID,
                    boiRejectionRecipient: item.boiRejectionRecipient,
                    boiCode: item.boiCode,
                    IsCustom: item.IsCustom,
                    isActive: 1,
                    //additional details
                    reasonForNoBOIDefinition: item.IsCustom ? item.reasonForNoBOIDefinition : '',
                    reasonForNoAggregationLogic: item.IsCustom
                      ? item.reasonForNoAggregationLogic
                      : '',
                    aggregationLogic: item.IsCustom ? item.aggregationlogic.label : '',
                    aggregationLogicMasterID: item.IsCustom ? item.aggregationlogic.value : 0,
                    boiDefinition: item.IsCustom ? item.boidefinition.label : '',
                    boiDefinitionMasterID: item.IsCustom ? item.boidefinition.value : 0,
                    leadingIndicator: item.leadingIndicator,
                    customParameterList: item.IsCustom ? item.customParameterList : [],
                    newSubDimensionName: item.IsCustom ? item.newSubDimensionName : '',
                    noOfParameter: item.IsCustom ? item.noOfParameter : 0,
                    offeringID: item.offeringID,
                    offeringName: item.offeringName,
                    suboffering: item.suboffering.label,
                    subofferingID: item.suboffering.value,
                    boiAdditionComment: item.boiAdditionComment,
                    boiOldComment: item.boiOldComment,
                    metricDefinitionID:
                      item.metricDefinitionID !== undefined ? item.metricDefinitionID : 0,
                    isBOIUsedInProject: item.isBOIUsedInProject,
                    boiWheelVisibility:
                      item.boiWheelVisibility !== undefined ? item.boiWheelVisibility : 1, // Get Status from previous, if undefined then default to 1
                    parameterList: item.parameterList !== undefined ? item.parameterList : [],
                    boiParentID: item.boiParentID,
                  },
                ],
              },
            ],
          });
        }
      });
      return list;
    };

    onSaveAddBOI = async () => {
      try {
        await this.props.setDeletedBOIonSavePopup([
          ...this.state.deletedBOIList,
          ...this.state.deletedBOIForUndoCustom,
        ]);
        let list = this.prepForSavingAgnostic();
        this.setState({ show: false, deletedAll: false });
        // To return the selected bois
        await this.props.onSaveAddBOI(list, true);
      } catch (err) {
        console.log(err);
      }
    };

    setDeletedBOICustom = (deletedBOI) => {
      this.setState({
        deletedBOIForUndoCustom: deletedBOI,
      });
    };

    customBOICloseModal = () => {
      this.setState({ show: false });
    };

    checkForDuplicates = (toAdd, selectedBOIList, agnostic = false) => {
      let doesExist = false;
      selectedBOIList.map((item) => {
        if (!agnostic) {
          if (
            item.boi.label === toAdd.boi.label &&
            item.uom.label === toAdd.uom.label &&
            item.dimension.label === toAdd.dimension.label &&
            item.subdimension.label === toAdd.subdimension.label
          ) {
            doesExist = true;
          }
        } else {
          // check duplicate for agnotic boi
          if (
            (item.boi === toAdd.boi || item.boi === toAdd.BOIName) &&
            (item.uom === toAdd.uom || item.uom === toAdd.UOM) &&
            (item.dimension === toAdd.dimension || item.dimension === toAdd.CoreDimensionName) &&
            (item.subdimension === toAdd.subdimension ||
              item.subdimension === toAdd.SubDimensionName)
          ) {
            doesExist = true;
          }
        }
      });
      return doesExist;
    };

    addBOI = () => {
      let ProgramConfigurationData = this.props.ProgramConfigurationData;
      let selectedProgramDetails = ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = ProgramConfigurationData.newProgramDetails;

      let OfferingID = HasProperty(selectedProgramDetails, 'OfferingID') ? selectedProgramDetails.OfferingID : newProgramDetails.offering.value;
      let allOfferings = HasProperty(selectedProgramDetails, 'allOfferings') ? selectedProgramDetails.allOfferings : newProgramDetails.offering.label.toLowerCase() === "overall";
      let isCheckedAllOfferings = HasValue(ProgramConfigurationData.isCheckedAllOfferings) ? ProgramConfigurationData.isCheckedAllOfferings :
        (HasProperty(selectedProgramDetails, 'Offering') ? selectedProgramDetails.Offering.toLowerCase() === "overall" : newProgramDetails.offering.label.toLowerCase() === "overall");


      let toAdd = this.state.boiToAdd;
      let boiMappingList = ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList;
      let boiDetails = [];

      let selectedBOIList = this.state.selectedBOI;
      let list = this.state.list;

      let allOfferingTemp = (isCheckedAllOfferings || allOfferings); //isCheckedAllOfferings checkbox allOfferings overall

      let trueBOIID = 0;
      boiMappingList.forEach((boi) => {
        if (
          boi.BOIName === toAdd.boi.label &&
          boi.UOM === toAdd.uom.label &&
          (allOfferingTemp ? true : boi.OfferingID === OfferingID) &&
          (trueBOIID === 0 ||
            (boi.CoreDimensionID === toAdd.dimension.value &&
              boi.SubDimensionID === toAdd.subdimension.value)
          )
        ) {
          boiDetails = boi;
          trueBOIID = boi.BOIID;
        }
      });
      selectedBOIList.push({
        boiListDisplay: [toAdd.boi],
        boiList: list.boi,
        uomList: list.uom,
        dimensionList: list.dimension,
        subdimensionList: list.subdimension,
        boi: toAdd.boi,
        uom: toAdd.uom,
        dimension: toAdd.dimension,
        subdimension: toAdd.subdimension,
        isExisting: false,
        missingDetails: false,
        IsCustom: false,
        // additional details
        boiStatus: '',
        comment: '',
        boiDescription: JSON.stringify(boiDetails) !== '{}' ? boiDetails.BOIDescription : '',
        //added description since some of the files are using description instead of boiDescription
        description: JSON.stringify(boiDetails) !== '{}' ? boiDetails.BOIDescription : '', //description = boiDescription
        paramValue: '',
        oldParamValue: '',
        oldUOM: '',
        boiWheelVisibility: 1, // Get Status from previous, if undefined then default to 1

        boiClientProgramTargetID: 0,
        boiCode: '',
        boiRealizedValue: '',
        boiRejectionRecipient: '',
        isBOIUsedInProject: false,
        offeringID: JSON.stringify(boiDetails) !== '{}' ? boiDetails.OfferingID : '',
        offeringName: JSON.stringify(boiDetails) !== '{}' ? boiDetails.OfferingName : '',
        subofferingID: JSON.stringify(boiDetails) !== '{}' ? boiDetails.SubOfferingID : '',
        suboffering:
          JSON.stringify(boiDetails) !== '{}'
            ? {
              label: boiDetails.SubOfferingName,
              text: boiDetails.SubOfferingName,
              value: boiDetails.SubOfferingID,
            }
            : '',
        leadingIndicator: JSON.stringify(boiDetails) !== '{}' ? boiDetails.LeadingIndicator : '',
        parameterList:
          JSON.stringify(boiDetails) !== '{}'
            ? boiDetails.parameterList !== undefined
              ? boiDetails.parameterList
              : []
            : [],
        boiParentID: boiDetails.BoiParentID,
      });
      this.removeDeletedBOI(toAdd);
      this.setState(
        {
          selectedBOI: selectedBOIList,
          selectedBOIDisplay: selectedBOIList,
          clickedUncheck: false,
        },
        () => {
          this.clearState();
          this.setState({
            boi: [],
            uom: [],
            dimension: [],
            subdimension: [],
          });
          this.onSearch();
        }
      );
    };

    deleteBOI = (selected, index) => {
      let selectedBOIList = this.state.selectedBOI;
      let deleted = this.state.deletedBOIForUndo;
      let templist = [];
      let tempDeleted = this.state.deletedBOIList; //Added this to get all deleted BOI

      selectedBOIList.map((item, i) => {
        if (
          item.boi.label !== selected.boi.label ||
          item.uom.label !== selected.uom.label ||
          item.dimension.label !== selected.dimension.label ||
          item.subdimension.label !== selected.subdimension.label
        ) {
          templist.push(item);
        } else {
          if (this.state.duplicateBOI && !item.isExisting) {
            templist.push(item);
          } else {
            tempDeleted.push(item);
          }
        }
      });

      deleted.push(selected);
      this.setState(
        {
          selectedBOI: templist,
          deletedAll: templist.length === 0,
          deletedBOIForUndo: deleted,
          deletedBOIList: tempDeleted,
        },
        () => {
          this.setState({
            isExisting: this.checkForDuplicates(this.state.boiToAdd, this.state.selectedBOI),
            duplicateBOI: false,
            inComplete: false,
          });
          this.onSearch();
        }
      );
    };

    removeDeletedBOI = (selected) => {
      let deleted = this.state.deletedBOIForUndo;
      let templist = [];
      deleted.map((item, i) => {
        if (
          item.boi.label !== selected.boi.label ||
          item.uom.label !== selected.uom.label ||
          item.dimension.label !== selected.dimension.label ||
          item.subdimension.label !== selected.subdimension.label
        ) {
          templist.push(item);
        }
      });

      this.setState({
        deletedBOIForUndo: templist,
      });
    };

    removeDeletedBOIList = (selected) => {
      let deleted = this.state.deletedBOIList;
      let templist = [];
      deleted.map((item, i) => {
        if (
          item.boi.label !== selected.boi.label ||
          item.uom.label !== selected.uom.label ||
          item.dimension.label !== selected.dimension.label ||
          item.subdimension.label !== selected.subdimension.label
        ) {
          templist.push(item);
        }
      });

      this.setState({
        deletedBOIList: templist,
      });
    };

    restoreDeletedBOI = (selected) => {
      let selectedBOIList = this.state.selectedBOI;
      let duplicate = this.checkForDuplicates(selected, this.state.selectedBOI);
      let inComplete = false;
      if (
        Object.keys(selected.boi).length === 0 ||
        Object.keys(selected.uom).length === 0 ||
        Object.keys(selected.dimension).length === 0 ||
        Object.keys(selected.subdimension).length === 0
      ) {
        inComplete = true;
      }
      if (!duplicate) {
        selectedBOIList.push(selected);
        this.setState(
          {
            selectedBOI: selectedBOIList,
            selectedBOIDisplay: selectedBOIList,
            inComplete: inComplete,
          },
          () => {
            this.onSearch();
            this.setState({
              isExisting: this.checkForDuplicates(this.state.boiToAdd, this.state.selectedBOI),
            });
          }
        );
      }
      this.removeDeletedBOI(selected);
      this.removeDeletedBOIList(selected);
    };

    setKeyword = (keyword, agnostic = false) => {
      this.setState(
        {
          keyword: keyword,
        },
        () => {
          if (!agnostic) {
            this.onSearch();
          } else {
            this.onSearchAgnostic();
          }
        }
      );
    };

    onSearch = () => {
      let bois = this.state.selectedBOI;
      let temp = [];
      let key = this.state.keyword;
      bois.map((item) => {
        if (
          item.boi.label.toLowerCase().includes(key) ||
          item.uom.label.toLowerCase().includes(key) ||
          item.dimension.label.toLowerCase().includes(key) ||
          item.subdimension.label.toLowerCase().includes(key)
        ) {
          temp.push(item);
        }
      });
      this.setState({
        selectedBOIDisplay: temp,
      });
    };

    clearState = () => {
      this.setState({
        list: {
          boi: [],
          uom: [],
          dimension: [],
          subdimension: [],
        },
        boiToAdd: {
          boi: {},
          uom: {},
          dimension: {},
          subdimension: {},
        },
        boiToAddDetails: {
          UOM: '',
          CoreDimensionName: '',
          SubDimensionName: '',
          BOIName: '',
        },
        isExisting: false,
        keyword: '',
        isClick: false,
        tabValue: {},
      });
    };

    componentWillUnmount = () => {
      this.clearState();
    };

    filterEditUOM = (toAdd) => {
      let selected = toAdd;
      let uom = this.prepDropdownLists('uom', selected);
      // if options is only 1 then select it by default
      if (uom.length === 1) {
        selected.uom = uom[0];
      } else {
        selected.uom = {};
      }
      return { sel: selected, list: uom };
    };

    filterEditDimension = (toAdd) => {
      let selected = toAdd;
      let dimension = this.prepDropdownLists('dimension', selected);
      let listDimension = [];
      // if options is only 1 then select it by default
      this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList.map((item) => {
        if (
          item.BOIName === selected.boi.label &&
          item.UOM === selected.uom.label &&
          listDimension.indexOf(item.CoreDimensionName) === -1
        ) {
          listDimension.push({
            label: item.CoreDimensionName,
            text: item.CoreDimensionName,
            value: item.CoreDimensionID,
          });
        }
      });
      if (listDimension.length === 1) {
        selected.dimension = listDimension[0];
      } else {
        selected.dimension = {};
      }
      return { sel: selected, list: dimension };
    };

    filterEditSubDimension = (toAdd) => {
      let selected = toAdd;
      let subdimension = this.prepDropdownLists('subdimension', selected);
      let listSubDimension = [];
      // if options is only 1 then select it by default
      this.props.ProgramConfigurationData.boiMappingListAllOfferings.BIOMappingList.map((item) => {
        if (
          item.BOIName === selected.boi.label &&
          item.UOM === selected.uom.label &&
          item.CoreDimensionName === selected.dimension.label &&
          listSubDimension.indexOf(item.SubDimensionName) === -1
        ) {
          listSubDimension.push({
            label: item.SubDimensionName,
            text: item.SubDimensionName,
            value: item.SubDimensionID,
          });
        }
      });
      if (listSubDimension.length === 1) {
        selected.subdimension = listSubDimension[0];
      } else {
        selected.subdimension = {};
      }
      return { sel: selected, list: subdimension };
    };

    onEditBOI = (e, field, prev, index) => {
      let bois = this.state.selectedBOI;
      // let index = 0;
      // bois.map((item, i) => {
      //   if (item.boi.label === prev.boi.label && item.uom.label === prev.uom.label && item.dimension.value === prev.dimension.value && item.subdimension.value === prev.subdimension.value) {
      //     index = i;
      //   }
      // });

      switch (field) {
        case 'boi':
          bois[index].boi = e;
          let dataBoi = this.filterEditUOM({
            boi: bois[index].boi,
            uom: bois[index].uom,
            dimension: bois[index].dimension,
            subdimension: bois[index].subdimension,
          });
          bois[index].uomList = dataBoi.list;
          bois[index].uom = dataBoi.sel.uom;
          let dataBoi2 = this.filterEditDimension(dataBoi.sel);
          bois[index].dimensionList = dataBoi2.list;
          bois[index].dimension = dataBoi2.sel.dimension;
          let dataBoi3 = this.filterEditSubDimension(dataBoi2.sel);
          bois[index].subdimensionList = dataBoi3.list;
          bois[index].subdimension = dataBoi3.sel.subdimension;

          break;
        case 'uom':
          bois[index].uom = e;
          let dataUom = this.filterEditDimension({
            boi: bois[index].boi,
            uom: bois[index].uom,
            dimension: bois[index].dimension,
            subdimension: bois[index].subdimension,
          });
          bois[index].dimensionList = dataUom.list;
          bois[index].dimension = dataUom.sel.dimension;
          let dataUom2 = this.filterEditSubDimension(dataUom.sel);
          bois[index].subdimensionList = dataUom2.list;
          bois[index].subdimension = dataUom2.sel.subdimension;
          break;
        case 'dimension':
          bois[index].dimension = e;
          let dataDim = this.filterEditSubDimension({
            boi: bois[index].boi,
            uom: bois[index].uom,
            dimension: bois[index].dimension,
            subdimension: bois[index].subdimension,
          });
          bois[index].subdimensionList = dataDim.list;
          bois[index].subdimension = dataDim.sel.subdimension;
          break;
        case 'subdimension':
          bois[index].subdimension = e;
          break;
        default:
          break;
      }
      let toAdd = {
        boi: bois[index].boi,
        dimension: bois[index].dimension,
        subdimension: bois[index].subdimension,
        uom: bois[index].uom,
      };
      let boiListToCheck = [];

      bois.map((item, i) => {
        if (i !== index) {
          boiListToCheck.push(item);
        }
      });
      bois[index].isExisting = this.checkForDuplicates(toAdd, boiListToCheck);
      if (
        Object.keys(bois[index].boi).length === 0 ||
        Object.keys(bois[index].uom).length === 0 ||
        Object.keys(bois[index].dimension).length === 0 ||
        Object.keys(bois[index].subdimension).length === 0
      ) {
        bois[index].missingDetails = true;
      } else {
        bois[index].missingDetails = false;
      }

      let duplicateBOI = false;
      let inComplete = false;
      bois.map((item) => {
        if (item.isExisting) {
          duplicateBOI = true;
        }
        if (
          Object.keys(item.boi).length === 0 ||
          Object.keys(item.uom).length === 0 ||
          Object.keys(item.dimension).length === 0 ||
          Object.keys(item.subdimension).length === 0
        ) {
          inComplete = true;
        }
      });
      this.setState({
        selectedBOI: bois,
        selectedBOIDisplay: bois,
        duplicateBOI: duplicateBOI,
        inComplete: inComplete,
      });
    };

    customBOIValidationMessage = (err, isErr) => {
      let customBOIError = this.state.customBOIError;
      switch (err) {
        case 'duplicateBOI':
          isErr ? (customBOIError.duplicateBOI = true) : (customBOIError.duplicateBOI = false);
          break;
        case 'invalidValues':
          isErr ? (customBOIError.invalidValues = true) :     (customBOIError.invalidValues = false);
        case 'missingDetails':
          isErr ? (customBOIError.missingDetails = true) : (customBOIError.missingDetails = false);
          break;
        case 'inComplete':
          isErr ? (customBOIError.inComplete = true) : (customBOIError.inComplete = false);
          break;
        case 'isExisting':
          isErr ? (customBOIError.isExisting = true) : (customBOIError.isExisting = false);
          break;
        case 'unsavedNewCustomBOI':
          isErr ? (customBOIError.unsavedNewCustomBOI = true) : (customBOIError.unsavedNewCustomBOI = false);
          break;
        case 'unsavedExistingCustomBOI':
          isErr ? (customBOIError.unsavedExistingCustomBOI = true) : (customBOIError.unsavedExistingCustomBOI = false);
          break;
        default:
          break;
      }
      this.setState({ customBOIError: customBOIError });
    };

    setDeletedAll = (deletedAll) => {
      this.setState({
        deletedAll: deletedAll,
      });
    };

    onClickSelectMenuBOI = (i) => {
      let selectedBOIDisplay = this.state.selectedBOIDisplay;
      let allBOIs = selectedBOIDisplay.map((boi, index) => {
        if (i === index) {
          return {
            ...boi,
            boiListDisplay: boi.boiList,
          };
        } else {
          return {
            ...boi,
            boiListDisplay: [boi.boi]
          };
        }
      });
      this.setState({
        selectedBOIDisplay: allBOIs
      })
    }

    //Agnostic BOI functions start here

    filterAgnosticBOIList = () => {
      let BOIList = this.props.ProgramConfigurationData.boiMappingList.BIOMappingList;
      let agnosticBOIList = [];
      agnosticBOIList = BOIList.filter((boi) => {
        return boi.BoiParentID === 3;
      });
      this.setState({
        agnosticBOIList: agnosticBOIList,
      });
    };

    filterAgnosticDropdownList = () => {
      const BOIList = this.state.agnosticBOIList;
      const agnosticType = this.state.agnosticType;
      let agnosticDropdownList = [];
      let list = [];
      agnosticType.map((type, index) => {
        let tempList = [];
        let tempAgnosticList = [];
        tempAgnosticList = BOIList.filter((boi) => {
          return boi.BOITypeId === type.value;
        });
        tempAgnosticList.map((boi) => {
          tempList.push({
            value: boi.BOIID,
            label: boi.BOIName,
          });
        });
        agnosticDropdownList.push({
          name: type.label,
          list: tempList,
          type: type.value,
        });
      });
      this.setState({
        agnosticDropdownDisplay: agnosticDropdownList,
        agnosticDropdownList: agnosticDropdownList,
      });
    };

    selectAgnostic = (e) => {
      const BOIList = this.state.agnosticBOIList;
      let boiToAddDetails = [];
      boiToAddDetails = BOIList.filter((boi) => {
        return boi.BOIID === e.value;
      });
      this.setState(
        {
          tabValue: e,
          boiToAddDetails: boiToAddDetails[0],
        },
        () => {
          this.setState({
            isExisting: this.checkForDuplicates(
              this.state.boiToAddDetails,
              this.state.selectedAgnosticBOIList,
              true
            ),
          });
        }
      );
    };

    addAgnosticBOI = () => {
      const boiToAddDetails = this.state.boiToAddDetails;
      let agnosticBOIList = this.state.agnosticDropdownList;
      let selectedBOIList = this.state.selectedAgnosticBOIList;

      selectedBOIList.push({
        boiList: agnosticBOIList,
        boi: boiToAddDetails.BOIName,
        boiID: boiToAddDetails.BOIID,
        uom: boiToAddDetails.UOM,
        dimension: boiToAddDetails.CoreDimensionName,
        dimensionID: boiToAddDetails.CoreDimensionID,
        subdimension: boiToAddDetails.SubDimensionName,
        subDimensionID: boiToAddDetails.SubDimensionID,

        isExisting: false,
        missingDetails: false,
        IsCustom: false,
        // additional details
        boiStatus: '',
        comment: '',
        boiDescription: boiToAddDetails.BOIDescription,
        //added description since some of the files are using description instead of boiDescription
        description: boiToAddDetails.BOIDescription, //description = boiDescription
        paramValue: boiToAddDetails.DefaultTarget !== 0 ? boiToAddDetails.DefaultTarget : '',
        oldParamValue: '',
        oldUOM: '',
        boiWheelVisibility: 1, // Get Status from previous, if undefined then default to 1

        boiClientProgramTargetID: 0,
        boiCode: '',
        boiRealizedValue: '',
        boiRejectionRecipient: '',
        isBOIUsedInProject: false,
        offeringID: boiToAddDetails.OfferingID,
        offeringName: boiToAddDetails.OfferingName,
        subofferingID: boiToAddDetails.SubOfferingID,
        suboffering: {
          label: boiToAddDetails.SubOfferingName,
          text: boiToAddDetails.SubOfferingName,
          value: boiToAddDetails.SubOfferingID,
        },
        leadingIndicator: boiToAddDetails.LeadingIndicator,
        parameterList: boiToAddDetails.parameterList,
        boiParentID: boiToAddDetails.BoiParentID,
      });

      this.setState(
        {
          selectedAgnosticBOIList: selectedBOIList,
          selectedAgnosticBOIDisplay: selectedBOIList,
          clickedUncheck: false,
        },
        () => {
          this.clearState();
          this.onSearchAgnostic();
        }
      );
    };

    editAgnosticBOI = (e, prev, index) => {
      let bois = this.state.selectedAgnosticBOIList;
      const BOIList = this.state.agnosticBOIList;
      let selectedEditBOI = [];

      selectedEditBOI = BOIList.filter((boi) => {
        return boi.BOIID === e.value;
      });
      bois[index].boi = selectedEditBOI[0].BOIName;
      bois[index].uom = selectedEditBOI[0].UOM;
      bois[index].dimension = selectedEditBOI[0].CoreDimensionName;
      bois[index].subdimension = selectedEditBOI[0].SubDimensionName;
      bois[index].boiID = selectedEditBOI[0].BOIID;
      bois[index].subDimensionID = selectedEditBOI[0].SubDimensionID;
      bois[index].paramValue =
        selectedEditBOI[0].DefaultTarget !== 0 ? selectedEditBOI[0].DefaultTarget : '';
      let toAdd = {
        boi: bois[index].boi,
        dimension: bois[index].dimension,
        subdimension: bois[index].subdimension,
        uom: bois[index].uom,
        paramValue: bois[index].paramValue,
      };
      let boiListToCheck = [];
      bois.map((item, i) => {
        if (i !== index) {
          boiListToCheck.push(item);
        }
      });

      bois[index].isExisting = this.checkForDuplicates(toAdd, boiListToCheck, true);

      let duplicateBOI = false;
      bois.map((item) => {
        if (item.isExisting) {
          duplicateBOI = true;
        }
      });
      this.setState({
        selectedAgnosticBOIList: bois,
        selectedAgnosticBOIDisplay: bois,
        duplicateBOI: duplicateBOI,
      });
    };

    onSearchAgnostic = () => {
      let bois = this.state.selectedAgnosticBOIList;
      let temp = [];
      let key = this.state.keyword;
      bois.map((item) => {
        if (
          item.boi.toLowerCase().includes(key) ||
          item.uom.toLowerCase().includes(key) ||
          item.dimension.toLowerCase().includes(key) ||
          item.subdimension.toLowerCase().includes(key)
        ) {
          temp.push(item);
        }
      });
      this.setState({
        selectedAgnosticBOIDisplay: temp,
      });
    };

    onSearchAgnosticDropdownValue = (key, type) => {
      const agnosticDropdownList = this.state.agnosticDropdownList;
      let tempList = [];
      let temp = [];
      agnosticDropdownList.forEach((item) => {
        if (item.type === type) {
          item.list.map((boi) => {
            if (boi.label.toLowerCase().includes(key)) {
              tempList.push({
                value: boi.value,
                label: boi.label,
              });
            }
          });
        }
        temp.push({
          name: item.name,
          list: tempList,
          type: item.type,
        });
      });
      this.setState({
        agnosticDropdownDisplay: temp,
      });
    };

    deleteAgnosticBOI = (selected, index) => {
      let selectedBOIList = this.state.selectedAgnosticBOIList;
      let deleted = this.state.deletedAgnosticBOIForUndo;
      let templist = [];
      let tempDeleted = this.state.deletedAgnosticBOIList; //Added this to get all deleted BOI

      selectedBOIList.map((item, i) => {
        if (
          item.boi !== selected.boi ||
          item.uom !== selected.uom ||
          item.dimension !== selected.dimension ||
          item.subdimension !== selected.subdimension
        ) {
          templist.push(item);
        } else {
          if (this.state.duplicateBOI && !item.isExisting) {
            templist.push(item);
          } else {
            tempDeleted.push(item);
          }
        }
      });

      deleted.push(selected);
      this.setState(
        {
          selectedAgnosticBOIList: templist,
          deletedAll: templist.length === 0,
          deletedAgnosticBOIForUndo: deleted,
          deletedAgnosticBOIList: tempDeleted,
        },
        () => {
          this.setState({
            isExisting: this.checkForDuplicates(
              this.state.boiToAddDetails,
              this.state.selectedBOI,
              true
            ),
            duplicateBOI: false,
            inComplete: false,
          });
          this.onSearchAgnostic();
        }
      );
    };

    removeDeletedAgnosticBOI = (selected) => {
      let deleted = this.state.deletedBOIForUndo;
      let templist = [];
      deleted.map((item, i) => {
        if (
          item.boi.label !== selected.boi.label ||
          item.uom.label !== selected.uom.label ||
          item.dimension.label !== selected.dimension.label ||
          item.subdimension.label !== selected.subdimension.label
        ) {
          templist.push(item);
        }
      });

      this.setState({
        deletedAgnosticBOIForUndo: templist,
      });
    };

    removeDeletedAgnosticBOIList = (selected) => {
      let deleted = this.state.deletedAgnosticBOIList;
      let templist = [];
      deleted.map((item, i) => {
        if (
          item.boi !== selected.boi ||
          item.uom !== selected.uom ||
          item.dimension !== selected.dimension ||
          item.subdimension !== selected.subdimension
        ) {
          templist.push(item);
        }
      });

      this.setState({
        deletedAgnosticBOIList: templist,
      });
    };

    restoreDeletedAgnosticBOI = (selected) => {
      let selectedBOIList = this.state.selectedAgnosticBOIList;
      let duplicate = this.checkForDuplicates(selected, this.state.selectedAgnosticBOIList, true);
      if (!duplicate) {
        selectedBOIList.push(selected);
        this.setState(
          {
            selectedAgnosticBOIList: selectedBOIList,
            selectedAgnosticBOIDisplay: selectedBOIList,
          },
          () => {
            this.onSearchAgnostic();
            this.setState({
              isExisting: this.checkForDuplicates(
                this.state.boiToAddDetails,
                this.state.selectedAgnosticBOIList,
                true
              ),
            });
          }
        );
      }
      this.removeDeletedAgnosticBOI(selected);
      this.removeDeletedAgnosticBOIList(selected);
    };

    selectAgnosticTab = (e) => {
      let prevSelectedAgnosticTab = this.state.selectedAgnosticTab;
      if (prevSelectedAgnosticTab !== e.value) {
        this.setState({
          selectedAgnosticTab: e.value,
        });
        this.resetAgnosticDropdownDisplay();
      }
    };

    resetAgnosticDropdownDisplay = () => {
      const agnosticDropdownList = this.state.agnosticDropdownList;
      this.setState({
        agnosticDropdownDisplay: agnosticDropdownList,
      });
    };

    //Agnostic BOI functions end here

    render() {
      const {
        boiMappingListAllOfferings,
        newProgramDetails,
        selectedProgramDetails,
        isCheckedAllOfferings,
      } = this.props.ProgramConfigurationData;
      return (
        <AddBOIPopupPresentational
          setDeletedAll={this.setDeletedAll}
          onCloseModal={this.onCloseModal}
          onSaveAddBOI={this.onSaveAddBOI}
          addBOI={this.addBOI}
          deleteBOI={this.deleteBOI}
          setKeyword={this.setKeyword}
          show={this.state.show}
          size={this.state.size}
          list={this.state.list}
          onSelectDropdown={this.onSelectDropdown}
          boiToAdd={this.state.boiToAdd}
          selectedBOI={this.state.selectedBOI}
          duplicateBOI={this.state.duplicateBOI}
          deletedBOIForUndo={this.state.deletedBOIForUndo}
          inComplete={this.state.inComplete}
          isExisting={this.state.isExisting}
          selectedBOIDisplay={this.state.selectedBOIDisplay}
          onEditBOI={this.onEditBOI}
          type={this.props.type}
          removeDeletedBOI={this.removeDeletedBOI}
          restoreDeletedBOI={this.restoreDeletedBOI}
          selectedBOIs={this.state.selectedBOIs}
          showWarningBOI={this.state.showWarningBOI}
          onCloseBOIModal={this.onCloseBOIModal}
          boiMappingList={boiMappingListAllOfferings}
          newProgramDetails={newProgramDetails}
          customBOICloseModal={this.customBOICloseModal}
          onTabChange={this.onTabChange}
          setAllSelectedBOIs={this.setAllSelectedBOIs}
          selectedTab={this.state.selectedTab}
          customBOIError={this.state.customBOIError}
          customBOIValidationMessage={this.customBOIValidationMessage}
          setDeletedBOICustom={this.setDeletedBOICustom}
          isCheckedAllOfferings={isCheckedAllOfferings}
          handleCheckAllOfferings={this.handleCheckAllOfferings}
          showDeleteBOIAlert={this.state.showDeleteBOIAlert}
          deleteBOIAlert={this.deleteBOIAlert}
          clickedUncheck={this.state.clickedUncheck}
          clickCheckBox={this.clickCheckBox}
          disableAddBOI={this.props.disableAddBOI}
          checkForDuplicates={this.checkForDuplicates}
          onClickSelectMenuBOI={this.onClickSelectMenuBOI}
          //Agnostic BOI props and functions
          filterAgnosticBOIList={this.filterAgnosticBOIList}
          filterAgnosticDropdownList={this.filterAgnosticDropdownList}
          onSearchAgnostic={this.onSearchAgnostic}
          selectAgnostic={this.selectAgnostic}
          addAgnosticBOI={this.addAgnosticBOI}
          editAgnosticBOI={this.editAgnosticBOI}
          deleteAgnosticBOI={this.deleteAgnosticBOI}
          restoreDeletedAgnosticBOI={this.restoreDeletedAgnosticBOI}
          removeDeletedAgnosticBOI={this.removeDeletedAgnosticBOI}
          selectedAgnosticBOIList={this.state.selectedAgnosticBOIList}
          selectedAgnosticBOIDisplay={this.state.selectedAgnosticBOIDisplay}
          agnosticDropdownList={this.state.agnosticDropdownList}
          agnosticDropdownDisplay={this.state.agnosticDropdownDisplay}
          agnosticBOIList={this.state.agnosticDropdownList}
          tabValue={this.state.tabValue}
          boiToAddDetails={this.state.boiToAddDetails}
          deletedAgnosticBOIForUndo={this.state.deletedAgnosticBOIForUndo}
          onSearchAgnosticDropdownValue={this.onSearchAgnosticDropdownValue}
          selectAgnosticTab={this.selectAgnosticTab}
          resetAgnosticDropdownDisplay={this.resetAgnosticDropdownDisplay}
          showNotifMessage={this.state.showNotifMessage}
          showDisclaimerMessage={this.showDisclaimerMessage}
        />
      );
    }
  }

  return AddBOIPopupContainer;
})();

// export default AddBOIPopup;
export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          handleCheckAllOfferings,
          getBOIMappingList,
          getBOIMappingListByOffering,
        }
      ),
      dispatch
    )
)(withRouter(AddBOIPopup));

AddBOIPopup.defaultProps = {
  setDeletedBOIonSavePopup: () => { },
};
