import React, { Component } from 'react';
import DashboardBarChart from './DashboardBarChart';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveSelectMenuBasic } from 'tfo-groove';
import DashboardCommentaryHistoryModal from '../Dashboard/DashboardCommentaryModal/DashboardCommentaryHistoryModal';

const ScrollableTabsButtonAuto = (function () {
  class ScrollableTabsButtonAutoPresentational extends Component {
    render() {
      return (
        <div className="project-tabs-list">
          <div position="static" color="default"></div>
          <GrooveSelectMenuBasic
            name="selectproject"
            isDisabled={false}
            placeholder="Project"
            callbackFunction={this.props.handleChange}
            list={this.props.boiProjectDetails.map((projectItem, projectIndex) => {
              return {
                value: projectItem.clientProjectID,
                key: projectItem.clientProjectID,
                text: projectItem.clientProjectName,
                label: projectItem.clientProjectName,
              };
            })}
            defaultData={this.props.selectedProject}
            contentType={'search'}
          />
          <div
            role="tabpanel"
            id={`scrollable-auto-tabpanel-0`}
            aria-labelledby={`scrollable-auto-tab-0`}
          >
            <div>
              <div className="project-tab-content">
                <div className="comment-section comment-section-history">
                  <div className="comment">{this.props.selProjDetail.projectLatestComment}</div>
                  {this.props.selProjDetail.projectLatestComment !== '' &&
                    this.props.selProjDetail.projectLatestComment !== null && (
                      <DashboardCommentaryHistoryModal
                        boiItem={this.props.boiItem}
                        sdItem={this.props.sdItem}
                        dItem={this.props.dItem}
                        selectedYear={this.props.selectedYear}
                        isProject={true}
                        projectItem={this.props.selProjDetail}
                        isFromGlobal={true}
                      />
                    )}
                </div>
                <div className="charts">
                  <div className="global-project-desc-chart">
                    <DashboardBarChart projectItem={this.props.selProjDetail} mode="modal" />
                    <div className="global-legend-list">
                      <div className="global-legend-list-item">
                        <span className="global-dot-square-palette-purple"></span>Identified Value
                      </div>
                      <div className="global-legend-list-item">
                        <span className="global-dot-square-palette-amber"></span>Delivered Value
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  class ScrollableTabsButtonAutoContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selProjDetail: {
          boiDeliveredValue: '',
          boiDescription: '',
          boiIdentifiedValue: '',
          boiName: '',
          boiUOM: '',
          boiid: '',
          clientProjectID: '',
          clientProjectName: ' ',
          impDelivered: '',
          impIdentified: '',
          projectLatestComment: '',
          ytvDelivered: '',
          ytvIdentified: '',
        },
        selectedProject: {},
        boiProjectDetails: [],
      };
    }
    componentDidMount() {
      this.getProjectDetails();
    }
    componentDidUpdate(prevProps) {
      if (
        JSON.stringify(this.props.boiProjectDetails) !== JSON.stringify(prevProps.boiProjectDetails)
      ) {
        this.getProjectDetails();
      }
    }

    getProjectDetails = () => {
      const list = this.props.boiProjectDetails.sort((a, b) => {
        if (a.clientProjectName.toLowerCase() < b.clientProjectName.toLowerCase()) {
          return -1;
        }
        if (a.clientProjectName.toLowerCase() > b.clientProjectName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const proj = list.length > 0 ? list[0] : {};
      this.setState({
        selProjDetail: proj,
        boiProjectDetails: list,
        selectedProject:
          JSON.stringify(proj) === '"{}"'
            ? {}
            : {
              value: proj.clientProjectID,
              key: proj.clientProjectID,
              text: proj.clientProjectName,
              label: proj.clientProjectName,
            },
      });
    };

    handleChange = (e) => {
      this.setState({
        selProjDetail: this.state.boiProjectDetails.find(
          (proj) => proj.clientProjectID === e.value
        ),
        selectedProject: e,
      });

      const programID = this.props.programID;
      this.props.createLog({
        programID: programID,
        projectID: e.value,
      });
    };
    render() {
      return (
        <ScrollableTabsButtonAutoPresentational
          selProjDetail={this.state.selProjDetail}
          handleChange={this.handleChange}
          boiProjectDetails={this.state.boiProjectDetails}
          boiItem={this.props.boiItem}
          handleBOIComment={this.props.handleBOIComment}
          sdItem={this.props.sdItem}
          dItem={this.props.dItem}
          selectedYear={this.props.selectedYear}
          selectedProject={this.state.selectedProject}
        />
      );
    }
  }

  return ScrollableTabsButtonAutoContainer;
})();

export default connect(
  (state) => {
    return {
      boiProjectDetails: state.DashboardData.boiProjectDetails,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(ScrollableTabsButtonAuto);
