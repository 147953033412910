import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveIconSvg, GrooveButton, GrooveTextBox, GrooveSwitchToggle } from 'tfo-groove';
import { Accordion, Card, Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactTooltip from 'react-tooltip';

import { HasValue, IsValidStr, HasProperty, IsValid, IsArrayEmpty } from '../../../../Utilities/Validations';
import { TryGetObjValueAlt, FormatCVMParamValue } from '../../../../Utilities/Formatter';
import ApproveRejectRadioButton from '.././ApproveRejectRadioButton';

import {
  handleClientValueIdentified,
  setRVDSelectedTabs,
  updateExpandCollapseBOIView,
  handleExpandCollapseYearQtr,
} from '../../../../Actions/ConfigureProgram.Action';

import CVMInput from '../../../Shared/CVMInput/CVMInput';

import newInfo from '../../../../Static/images/svg/new-info.svg';
import emptyFolder from '../../../../Static/images/360cvm/empty-folder.svg';

const ClientValueIdentifiedUSD = (function () {
  class ClientValueIdentifiedUSDPresentational extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      const {
        showNotifMessageArr,
        dItem,
        dIndex,
        inUSD,
        notInUSD,
        activeExpand,
        onExpandCollapse,
        activeCollapse,
        onSelectAccordion,
        basicDetails,
        isSuperAdmin,
        RVDComponents,
        onClickCustomBOI,
        isComment,
        handleBOIComment,
        handleBOICommentHistory,
        setIsReject,
        currentUserRoleID,
        submitReject,
        tempReason,
        updateReason,
        handleParameterChange,
        isIOAdmin,
        isExistingFinancial,
        disableSuperAdmin,
        setTab,
        rvdSelectedTabs,
        isRejected,
        isAwaiting,
        isApproved,
        isApprovedAndYetToEdit,
        isPPC,
        isBA,
        handleChange,
        formatValue,
        SDItem,
        SDIndex,
        selSDItem,
        selTabUSD,
        boiFieldID,
        showInputField,
        handleExpandCollapseYearQtr,
        isCreatorView,
      } = this.props;

      const plSignOffMsg =
        'BOIs with $ UOM selected under Financial Dimension will be Considered as client P&L Sign-Off. $ value under all other Dimensions except Financial Dimension, will not be considered as Client P&L Sign-Off.';
      const onDashboardMsg = 'Show this BOI on the Value Meter Dashboard?';
      const reasonMsg = 'Reject Reason is required';
      const awaiting = 'awaiting approval';
      const rejected = 'rejected';
      const tabInDollar = 'Client P&L Impacting BOIs - Measured in Dollar';
      const tabNotDollar = 'Client P&L Impacting BOIs - Not measured in Dollar';
      const deltaTooltip = 'Target Value - Value Impacting Client P&L';
      const deltaTooltipNonUSD = 'Target Value - Realized Value';
      const realizedTooltip = 'Value Impacting Client P&L';
      const noBOI = 'No Business Outcome Indicators Available';
      const noBOIDesc = "This dimension doesn't have any BOI(s) available under this category";
      const cviMsg = '* Autogenerated based on Value Impacting Client P&L';
      const prevYearMessage = 'Click here to view previous year data';
      const prevQtrMessage = 'Click here to view previous quarter data';
      const cumulativeMessage = !isCreatorView ?
        'Overall cumulative displays the summed up values of approved and unapproved records.'
        : `Overall cumulative displays the summed up values of approved and unapproved records after clicking on 'Compute All'.`

      const dimName = dItem.dimensionName.replace(/\s/g, '').replace(/&/g, '').toLowerCase();
      const { sdIndex, hadFinalized, colWidth, colStatus } = selSDItem;
      let isApproverRejectedView = (isAwaiting && !RVDComponents.approveRejectButton) || isRejected ? "rvd-fin-approver-rejected" : "rvd-fin-creator-view";
      const wvisCreatorView = !isAwaiting && RVDComponents.approveRejectButton || !isRejected ? 1 : colWidth.wheelVisibility
      return (
        <Accordion
          className="subdimension-section-collapse rvd-accordion-cvi-container"
          defaultActiveKey={isIOAdmin ? (isExistingFinancial ? '0' : '1') : '0'}
          onSelect={(e) => onSelectAccordion(e)}
        >
          <Card>
            <Accordion.Collapse
              eventKey={isIOAdmin && isExistingFinancial ? dIndex.toString() : '0'}
            >
              <Card.Body>
                <div key={SDItem.sdIndex} className="rvd-subdimension-table-wrap">
                  <div className="subdimention-tabs">
                    <div
                      onClick={() => setTab(0, SDItem.subDimensionname)}
                      className={selTabUSD ? 'active-tab' : ''}
                    >
                      {tabInDollar}
                    </div>
                    <div
                      onClick={() => setTab(1, SDItem.subDimensionname)}
                      className={!selTabUSD ? 'active-tab' : ''}
                    >
                      {tabNotDollar}
                    </div>
                  </div>
                  {/* table start*/}
                  <div className="subdimension-table rvd-subdimension-table rvd-financial">
                    {/* header start*/}

                    <div className="subdimension-header">
                      <div className="row">
                        <div className={`col-sm-${isAwaiting ? '2' : colWidth.boi} col-boi-name ${isApproverRejectedView}`}>
                          Business Outcome Indicator
                        </div>
                        <div className={`col-sm-1 col-year ${isApproverRejectedView}`}>Years</div>
                        <div className={`col-sm-1 col-qtr ${isApproverRejectedView}`}>Qtr</div>
                        <div className={`col-sm-1 col-month ${isApproverRejectedView}`}>Month</div>
                        {/* <div className={`col-sm-${isRejected ? '1' : colWidth.targetValue}`}> */}
                        <div className={`col-sm-${'1'} col-target-value ${isApproverRejectedView}`}>Target Value</div>
                        <div className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                          ? '1'
                          : '2'} col-vic ${isApproverRejectedView}`}>
                          {`Value Impacting Client P&L`}
                        </div>
                        <div
                          className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                            ? '1'
                            : '2'
                            } target-realized-${colStatus} col-cvi ${isApproverRejectedView}`}
                        >
                          Client Value Identified
                        </div>
                        <div
                          className={`col-sm-${wvisCreatorView} wVis-${colStatus} dashboard-visibility-rvd col-dashboard ${isApproverRejectedView} ${isRejected ? 'rej' : ''}`}
                        >
                          Dashboard Visibility
                          <a data-tip data-for="sadFace">
                            <div className="info-icon">
                              <GrooveIconSvg
                                size="large"
                                name="info-circle"
                                iconStyle="solid"
                                iconColor="white"
                              />
                            </div>
                          </a>
                          <ReactTooltip id="sadFace" type="dark" effect="solid">
                            <span>{onDashboardMsg}</span>
                          </ReactTooltip>
                        </div>
                        <div className={`col-sm-${'1'} boiComm-${colStatus} col-boi-comment ${isApproverRejectedView} heading`}>BOI Comment</div>
                        {isAwaiting && !RVDComponents.approveRejectButton ? (
                          <div className={`col-sm-2 app-rej-${colStatus} col-app-rej ${isApproverRejectedView} heading`}>Approver Section</div>
                        ) : (
                          ''
                        )}
                        {isRejected ? (
                          <div className={`col-sm-2 pde-comment-${colStatus}`}>
                            Rejection Comment
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    {/* table header end*/}
                    {/* body start*/}
                    <div className="subdimension-body">
                      {selSDItem.boiList.length === 0 ? (
                        <div className="empty-dimension-container">
                          <div className="empty-program-container bodytext18-regular-slate">
                            <img src={emptyFolder} alt="no boi" />
                            <span className="bodytext24-medium-slate">{noBOI}</span>
                            {noBOIDesc}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {selSDItem.boiList.map((boiItem, boiI) => {
                        const boiIndex = boiItem.boiIndex;
                        const itemId = `${dIndex}_${sdIndex}_${boiIndex}_${boiItem.boiUOM}`;
                        let paramItem = boiItem.parameterList.sort((a, b) => {
                          if (boiItem.IsCustom) {
                            return a.parameterCustomCode.localeCompare(
                              b.parameterCustomCode,
                              undefined,
                              {
                                numeric: true,
                                sensitivity: 'base',
                              }
                            );
                          } else {
                            return a.parameterCode.localeCompare(b.parameterCode, undefined, {
                              numeric: true,
                              sensitivity: 'base',
                            });
                          }
                        })[0];
                        const targetParam = {
                          dimension: {
                            item: dItem,
                            index: dIndex,
                          },
                          subDimension: {
                            item: SDItem,
                            index: sdIndex,
                          },
                          boi: {
                            item: boiItem,
                            index: boiIndex,
                          },
                          parameter: {
                            item: paramItem,
                            index: 0,
                          },
                        };
                        let finUSDData = boiItem.finUSDData;
                        let headerYear = finUSDData.latestDate.year;
                        let headerQtr = finUSDData.latestDate.quarter;
                        let headerMonth = finUSDData.latestDate.month;
                        let finRYIndex = boiItem.boiDeliveredValueList.length - 1;

                        let headerVIC = !boiItem.expandedBOI ? finUSDData.cumulativeBOIApprovedRealizedValue : //total cumulative
                          finUSDData.expandedYear ?
                            !finUSDData.expandedQuarter ?
                              boiItem.boiDeliveredValueList[0].reportingQuarters[0].totalBOIApprovedRealizedValue //total qtr
                              : boiItem.boiApprovedRealizedValue //boiApprovedRealizedValue approved once; not yet apr boiRealizedValue
                            : boiItem.boiDeliveredValueList[0].totalPerYrBOIApprovedRealizedValue; //total year

                        let headerCVI = !boiItem.expandedBOI ? finUSDData.cumulativeClientValueIdentified : //total cumulative
                          finUSDData.expandedYear ?
                            !finUSDData.expandedQuarter ?
                              boiItem.boiDeliveredValueList[0].reportingQuarters[0].totalClientValueIdentified
                              : boiItem.clientValueIdentified
                            : boiItem.boiDeliveredValueList[0].totalPerYrClientValueIdentified;

                        let headerTV = !boiItem.expandedBOI ? finUSDData.cumulativeBOITargetApprovedValue : //total cumulative
                          finUSDData.expandedYear ?
                            !finUSDData.expandedQuarter ?
                              boiItem.boiDeliveredValueList[0].reportingQuarters[0].totalBOITargetApprovedValue
                              : boiItem.boiTargetApprovedValue //same mappings on get for boiTargetApproved
                            : boiItem.boiDeliveredValueList[0].totalPerYrBOITargetApprovedValue;

                        let headerBodyCSS = !finUSDData.expandedYear || !finUSDData.expandedQuarter || !boiItem.expandedBOI
                          ? "bodytext14" : "fin-usd-body-text";

                        let showHeaderIcons = boiItem.boiDeliveredValueList.length > 0 &&
                          !IsArrayEmpty(boiItem.boiDeliveredValueList.filter((a) => a.reportingYear === boiItem.finUSDData.latestDate.year));

                        // let boiClassName = boiIndex
                        return (
                          <Accordion className="subdimension-row" defaultActiveKey="0">
                            <Card
                              className={`${boiItem.projectLevelBOIStatus.toLowerCase() !== 'rejected'
                                ? 'approved-project-level'
                                : 'rejected-project-level'
                                } ${boiItem.boiStatus === 'Rejected' ? 'error-card' : ''}`}
                            >
                              <Accordion.Collapse class="cvi-" eventKey="0">
                                {/* accordion body start */}
                                <Card.Body className={boiItem.clientValueIdentifiedTemp.isInvalid ? 'fin-dimension' : paramItem.isInvalid ? 'param-fin-error' : ''}>
                                  {
                                    boiItem.boiDeliveredValueList.length > 0 ?
                                      boiItem.boiDeliveredValueList.map((del, yInd) => {
                                        return (
                                          <>
                                            {del.reportingQuarters.map((quart, qInd) => {
                                              return (
                                                <>
                                                  {quart.reportingMonth.map((boiData, boiInd) => {
                                                    let isFirstQtr = qInd === 0;
                                                    let showYear =
                                                      del.reportingYear !== headerYear &&
                                                      isFirstQtr &&
                                                      boiInd === 0;
                                                    let showQtr =
                                                      del.reportingYear !== headerYear
                                                        ? boiInd === 0
                                                        : yInd === 0 && qInd !== 0
                                                          ? boiInd === 0
                                                          : false;
                                                    let rowClassName = `${!showYear &&
                                                      !showQtr ? `fin-usd-main-con-${itemId} ry-body-con-${itemId}_${yInd} qtr-body-con-${itemId}_${yInd}_${qInd}` :
                                                      !showYear && showQtr ? `fin-usd-main-con-${itemId} ry-body-con-${itemId}_${yInd} qtr-in-header-con` :
                                                        showYear && showQtr ? `ry-in-header-container ${itemId}_${yInd}_${qInd}_${boiInd}` : ""}`
                                                    let vic = del.expandedYear ?
                                                      quart.expandedQuarter ? boiData.boiApprovedRealizedValue : quart.totalBOIApprovedRealizedValue
                                                      : del.totalPerYrBOIApprovedRealizedValue;
                                                    let cvi = del.expandedYear ?
                                                      quart.expandedQuarter ? boiData.clientValueIdentified : quart.totalClientValueIdentified
                                                      : del.totalPerYrClientValueIdentified;
                                                    let tv = del.expandedYear ?
                                                      quart.expandedQuarter ? boiData.boiTargetApprovedValue : quart.totalBOITargetApprovedValue
                                                      : del.totalPerYrBOITargetApprovedValue;
                                                    let mainBodyCSS = !quart.expandedQuarter || !del.expandedYear
                                                      ? "bodytext14" : "fin-usd-body-text";
                                                    let isSingleYrAndQrt = boiItem.boiDeliveredValueList.length === 1 && del.reportingQuarters.length === 1;
                                                    let paramCollapse = isSingleYrAndQrt ? (!del.expandedYear ? "yr-collapsed" : !quart.expandedQuarter ? "qtr-collapsed" : "") : "";
                                                    return (
                                                      <>
                                                        {yInd === 0 && qInd === 0 && boiInd === 0 && !IsArrayEmpty(boiItem.parameterList)
                                                          ?
                                                          <>
                                                            <div className={`fin-usd-body-container row fin-usd-body-text cvi-non-header fin-usd-parameter`}>
                                                              {/* parameter */}
                                                              <div className={`col-sm-2 col-boi-name ${isApproverRejectedView} bodytext14-medium fin-usd-param-body ${paramCollapse} ${boiItem.clientValueIdentifiedTemp.isInvalid ? 'error-adjust' : ''}`}
                                                                title={!IsArrayEmpty(boiItem.parameterList) ? boiItem.parameterList[0].parameterName : ""}>
                                                                <div className='fin-usd-param-label'>Parameters</div>{!IsArrayEmpty(boiItem.parameterList) ? boiItem.parameterList[0].parameterName : ""}
                                                              </div>
                                                              {/* parameter name */}
                                                              {/* <div className={`col-sm-6 col-boi-name ${isApproverRejectedView} field-text`}>
                                                                {!IsArrayEmpty(boiItem.parameterList) ? boiItem.parameterList[0].parameterName : ""}
                                                              </div> */}
                                                            </div>
                                                          </> : ""
                                                        }
                                                        <div className={`fin-usd-body-container ${rowClassName} show row fin-usd-body-text cvi-non-header`}>
                                                          {/* <div className="row fin-usd-body-text cvi-non-header"> */}
                                                          {/* boi */}
                                                          <div
                                                            className={`col-sm-${isAwaiting ? '2' : colWidth.boi
                                                              } col-boi-name ${isApproverRejectedView}`}
                                                          >
                                                          </div>
                                                          {/* year */}
                                                          <div
                                                            className={`col-sm-${'1'} col-year ${isApproverRejectedView} bodytext14`}
                                                          >
                                                            {showYear ? (
                                                              <>
                                                                <a data-tip data-for={`ry_${itemId}_${yInd}_${qInd}_${boiInd}`}>
                                                                  <div className="cvi-plus-icon-container">
                                                                    <GrooveIconSvg
                                                                      customClassName="cvi-plus-icon"
                                                                      size="small"
                                                                      name={!del.expandedYear ? "plus" : "minus"}
                                                                      iconStyle="solid"
                                                                      iconColor="stat-alternate"
                                                                      callbackFunction={(e) => handleExpandCollapseYearQtr(
                                                                        {
                                                                          year: del.reportingYear,
                                                                          quarter: quart.quarter,
                                                                          month: boiData.month,
                                                                          trigger: "year",
                                                                          id: itemId,
                                                                          index: {
                                                                            year: yInd,
                                                                            quarter: qInd,
                                                                            month: boiInd,
                                                                          },
                                                                          isExpanded: del.expandedYear
                                                                        },
                                                                        targetParam)}
                                                                    />
                                                                  </div>
                                                                </a>
                                                                {
                                                                  !del.expandedYear ?
                                                                    <ReactTooltip id={`ry_${itemId}_${yInd}_${qInd}_${boiInd}`} type="dark" effect="solid">
                                                                      <span>{prevYearMessage}</span>
                                                                    </ReactTooltip>
                                                                    :
                                                                    ""
                                                                }
                                                                {del.reportingYear}
                                                              </>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </div>
                                                          {/* quarter */}
                                                          <div
                                                            className={`col-sm-${'1'} col-qtr ${isApproverRejectedView} bodytext14`}
                                                          >
                                                            {showQtr & del.expandedYear ? (
                                                              <>
                                                                <a data-tip data-for={`qtr_${itemId}_${yInd}_${qInd}_${boiInd}`}>
                                                                  <div className="cvi-plus-icon-container">
                                                                    <GrooveIconSvg
                                                                      customClassName="cvi-plus-icon"
                                                                      size="small"
                                                                      name={!quart.expandedQuarter ? "plus" : "minus"}
                                                                      iconStyle="solid"
                                                                      iconColor="stat-alternate"
                                                                      callbackFunction={(e) => handleExpandCollapseYearQtr({
                                                                        year: del.reportingYear,
                                                                        quarter: quart.quarter,
                                                                        month: boiData.month,
                                                                        trigger: "qtr",
                                                                        id: itemId,
                                                                        index: {
                                                                          year: yInd,
                                                                          quarter: qInd,
                                                                          month: boiInd,
                                                                        },
                                                                        isExpanded: quart.expandedQuarter
                                                                      },
                                                                        targetParam)}
                                                                    />
                                                                  </div>
                                                                </a>
                                                                {
                                                                  !quart.expandedQuarter ?
                                                                    <ReactTooltip id={`qtr_${itemId}_${yInd}_${qInd}_${boiInd}`} type="dark" effect="solid">
                                                                      <span>{prevQtrMessage}</span>
                                                                    </ReactTooltip>
                                                                    :
                                                                    ""
                                                                }
                                                                Qtr {quart.quarter}
                                                              </>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </div>
                                                          {/* month */}
                                                          <div
                                                            className={`col-sm-${'1'} col-month ${isApproverRejectedView}`}
                                                          >
                                                            {quart.expandedQuarter ? boiData.month : ""}
                                                          </div>
                                                          {/* target value */}
                                                          <div
                                                            className={`col-sm-${'1'} ${mainBodyCSS} target-value-padding targetValue-${colStatus} col-target-value ${isApproverRejectedView}`}
                                                            style={boiItem.isZeroTarget && { display: 'block' }}
                                                          >
                                                            <div
                                                              className={
                                                                boiItem.isZeroTarget &&
                                                                boiItem.boiTarget === 0 &&
                                                                'zero-target-value'
                                                              }
                                                            >
                                                              {formatValue(
                                                                `${itemId}_${yInd}_${qInd}_${boiInd}_TA`,
                                                                tv,
                                                                boiItem.boiUOM
                                                              )}
                                                            </div>
                                                          </div>
                                                          {/* Value Impacting Client P&L approved*/}
                                                          <div
                                                            className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                                                              ? '1'
                                                              : '2'} ${mainBodyCSS} realized-value-${colStatus} col-vic ${isApproverRejectedView}`}
                                                          >
                                                            {
                                                              formatValue(
                                                                `$${itemId}_${yInd}_${qInd}_${boiInd}_ARV`,
                                                                vic,
                                                                boiItem.boiUOM
                                                              )
                                                            }
                                                          </div>
                                                          {/* Client Value Identified */}
                                                          <div
                                                            className={`col-sm-${(isAwaiting &&
                                                              !RVDComponents.approveRejectButton) ||
                                                              isRejected
                                                              ? '1'
                                                              : '2'
                                                              } target-realized-${colStatus} ${mainBodyCSS} col-cvi ${isApproverRejectedView}`}
                                                          >
                                                            {formatValue(
                                                              `$${itemId}_${yInd}_${qInd}_${boiInd}_CVI`,
                                                              cvi,
                                                              boiItem.boiUOM
                                                            )}
                                                          </div>

                                                          {/* Dashboard Visibility */}
                                                          <div
                                                            className={`col-sm-${wvisCreatorView} wVis-${colStatus} col-dashboard ${isApproverRejectedView} ${isRejected ? 'rej' : ''} ${boiItem.expandedBOI && finUSDData.expandedYear && finUSDData.expandedQuarter ? 'open' : ''}`}
                                                          ></div>
                                                          {/* boi comment */}
                                                          <div
                                                            className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} col-boi-comment ${isApproverRejectedView}`}
                                                          ></div>
                                                          {/* approve reject */}
                                                          {(!RVDComponents.approveRejectButton &&
                                                            isAwaiting) ||
                                                            isRejected ? (
                                                            <div
                                                              className={`col-sm-${isAwaiting ? 2 : 2
                                                                } app-rej-${colStatus} col-app-rej ${isApproverRejectedView}`}
                                                            >
                                                              <div className="rvd-rejection-comment">
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                          {/* </div> */}
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                  {/* total per qtr */}
                                                  <div className={`fin-usd-body-container fin-usd-main-con-${itemId} ry-body-con-${itemId}_${yInd} qtr-body-con-${itemId}_${yInd}_${qInd} row fin-usd-body-text cvi-non-header total-per-qtr-con`}>
                                                    {/* <div className="row fin-usd-body-text cvi-non-header"> */}
                                                    {/* boi */}
                                                    <div className={`col-sm-${isAwaiting ? '2' : colWidth.boi} col-boi-name ${isApproverRejectedView}`}>
                                                      {/* {yInd === 0 && qInd === 0 && !IsArrayEmpty(boiItem.parameterList) ? boiItem.parameterList[0].parameterName : ""} */}
                                                    </div>
                                                    {/* year */}
                                                    <div className={`col-sm-${'1'} col-year ${isApproverRejectedView}`}></div>
                                                    {/* quarter */}
                                                    <div className={`col-sm-${'2'} col-qtr ${isApproverRejectedView} ${itemId}_${del.reportingYear}_${quart.quarter}_0`}>
                                                      Total value per Qtr
                                                    </div>
                                                    {/* month */}
                                                    {/* <div className={`col-sm-${'1'} col-month ${isApproverRejectedView}`} ></div> */}
                                                    {/* target value */}
                                                    <div
                                                      className={`col-sm-${'1'} bodytext14 target-value-padding targetValue-${colStatus} col-target-value ${isApproverRejectedView}`}
                                                      style={boiItem.isZeroTarget && { display: 'block' }}
                                                    >
                                                      <div
                                                        className={
                                                          boiItem.isZeroTarget &&
                                                          boiItem.boiTarget === 0 &&
                                                          'zero-target-value'
                                                        }
                                                      >
                                                        {formatValue(
                                                          `${itemId}_${yInd}_${qInd}_TA_TPQ`,
                                                          quart.totalBOITargetApprovedValue,
                                                          boiItem.boiUOM
                                                        )}
                                                      </div>
                                                    </div>
                                                    {/* Value Impacting Client P&L approved*/}
                                                    <div
                                                      className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                                                        ? '1'
                                                        : '2'} bodytext14 realized-value-${colStatus} col-vic ${isApproverRejectedView}`}
                                                    >
                                                      {formatValue(
                                                        `${itemId}_${yInd}_${qInd}_ARV_TPQ`,
                                                        quart.totalBOIApprovedRealizedValue,
                                                        boiItem.boiUOM
                                                      )}

                                                    </div>
                                                    {/* Client Value Identified */}
                                                    <div
                                                      className={`col-sm-${(isAwaiting &&
                                                        !RVDComponents.approveRejectButton) ||
                                                        isRejected
                                                        ? '1'
                                                        : '2'
                                                        } target-realized-${colStatus} bodytext14 col-cvi ${isApproverRejectedView}`}
                                                    >
                                                      {formatValue(
                                                        `${itemId}_${yInd}_${qInd}_CVI_TPQ`,
                                                        quart.totalClientValueIdentified,
                                                        boiItem.boiUOM
                                                      )}
                                                    </div>
                                                    {/* Dashboard Visibility */}
                                                    <div
                                                      className={`col-sm-${wvisCreatorView} wVis-${colStatus} col-dashboard ${isApproverRejectedView} ${isRejected ? 'rej' : ''} ${boiItem.expandedBOI && finUSDData.expandedYear && finUSDData.expandedQuarter ? 'open' : ''}`}
                                                    ></div>
                                                    {/* boi comment */}
                                                    <div
                                                      className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} col-boi-comment ${isApproverRejectedView}`}
                                                    ></div>
                                                    {/* approve reject */}
                                                    {(!RVDComponents.approveRejectButton &&
                                                      isAwaiting) ||
                                                      isRejected ? (
                                                      <div
                                                        className={`col-sm-${isAwaiting ? 2 : 2
                                                          } app-rej-${colStatus} col-app-rej ${isApproverRejectedView}`}
                                                      >
                                                        <div className="rvd-rejection-comment"></div>
                                                      </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {/* </div> */}
                                                    {/* total per qtr*/}
                                                  </div>
                                                </>
                                              );
                                            })}
                                            {/* total per year */}
                                            < div className={`fin-usd-body-container fin-usd-main-con-${itemId} total-per-year-con ry-body-con-${itemId}_${yInd} row fin-usd-body-text cvi-non-header`}>
                                              {/* <div className="row fin-usd-body-text cvi-non-header"> */}
                                              {/* boi */}
                                              <div className={`col-sm-${isAwaiting ? '2' : colWidth.boi} col-boi-name ${isApproverRejectedView}`}></div>
                                              {/* year */}
                                              <div className={`col-sm-${'2'} col-year ${isApproverRejectedView}`}>
                                                Total value per Year
                                              </div>
                                              {/* quarter */}
                                              {/* <div className={`col-sm-${'1'} col-qtr ${isApproverRejectedView}`}></div> */}
                                              {/* month */}
                                              <div className={`col-sm-${'1'} col-month ${isApproverRejectedView}`} ></div>
                                              {/* target value */}
                                              <div
                                                className={`col-sm-${'1'} bodytext14 target-value-padding targetValue-${colStatus} col-target-value ${isApproverRejectedView}`}
                                                style={boiItem.isZeroTarget && { display: 'block' }}
                                              >
                                                <div
                                                  className={
                                                    boiItem.isZeroTarget &&
                                                    boiItem.boiTarget === 0 &&
                                                    'zero-target-value'
                                                  }
                                                >
                                                  {formatValue(
                                                    `${itemId}_${yInd}_TA_TPY`,
                                                    del.totalPerYrBOITargetApprovedValue,
                                                    boiItem.boiUOM
                                                  )}
                                                </div>
                                              </div>
                                              {/* Value Impacting Client P&L approved*/}
                                              <div
                                                className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                                                  ? '1'
                                                  : '2'} realized-value-${colStatus} bodytext14 col-vic ${isApproverRejectedView}`}
                                              >
                                                {formatValue(
                                                  `${itemId}_${yInd}_ARV_TPY`,
                                                  del.totalPerYrBOIApprovedRealizedValue,
                                                  boiItem.boiUOM
                                                )}

                                              </div>
                                              {/* Client Value Identified */}
                                              <div
                                                className={`col-sm-${(isAwaiting &&
                                                  !RVDComponents.approveRejectButton) ||
                                                  isRejected
                                                  ? '1'
                                                  : '2'
                                                  } target-realized-${colStatus} bodytext14 col-cvi ${isApproverRejectedView}`}
                                              >
                                                {formatValue(
                                                  `${itemId}_${yInd}_CVI_TPY`,
                                                  del.totalPerYrClientValueIdentified,
                                                  boiItem.boiUOM
                                                )}
                                              </div>
                                              {/* Dashboard Visibility */}
                                              <div
                                                className={`col-sm-${wvisCreatorView} wVis-${colStatus} col-dashboard ${isApproverRejectedView} ${isRejected ? 'rej ' : ''} ${boiItem.expandedBOI && finUSDData.expandedYear && finUSDData.expandedQuarter ? 'open' : ''}`}
                                              ></div>
                                              {/* boi comment */}
                                              <div
                                                className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} col-boi-comment ${isApproverRejectedView}`}
                                              ></div>
                                              {/* approve reject */}
                                              {(!RVDComponents.approveRejectButton &&
                                                isAwaiting) ||
                                                isRejected ? (
                                                <div
                                                  className={`col-sm-${isAwaiting ? 2 : 2
                                                    } app-rej-${colStatus} col-app-rej ${isApproverRejectedView}`}
                                                >
                                                  <div className="rvd-rejection-comment"></div>
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                            {/* </div> */}
                                            {/* total per year */}
                                            {
                                              finRYIndex === yInd ?
                                                /* cumulative number */
                                                < div className={`fin-usd-body-container bodytext14 cumulative-number-container total-per-year-con ${itemId}_${del.reportingYear}_0_0 row cvi-non-header`}>
                                                  {/* <div className="row cvi-non-header"> */}
                                                  {/* boi */}
                                                  <div className={`col-sm-${isAwaiting ? '2' : colWidth.boi} col-boi-name ${isApproverRejectedView}`}></div>
                                                  {/* year */}
                                                  <div className={`col-sm-${'2'} col-year ${isApproverRejectedView} cumulative-number-label`}>
                                                    Overall Cumulative Value
                                                    <div className="cumulative-popup">
                                                      <a data-tip data-for={`rvd-cumulative-exp`}>
                                                        <div className="rvd-cumulative-exp-icon info-icon">
                                                          <GrooveIconSvg
                                                            size="large"
                                                            name="info-circle"
                                                            iconStyle="solid"
                                                            iconColor="stat-alternate"
                                                          />
                                                        </div>
                                                      </a>
                                                      <ReactTooltip id={`rvd-cumulative-exp`} type="dark" effect="solid">
                                                        <span>{cumulativeMessage}</span>
                                                      </ReactTooltip>
                                                    </div>

                                                  </div>
                                                  {/* quarter */}
                                                  {/* <div className={`col-sm-${'1'} col-qtr ${isApproverRejectedView}`}></div> */}
                                                  {/* month */}
                                                  <div className={`col-sm-${'1'} col-month ${isApproverRejectedView}`} ></div>
                                                  {/* target value */}
                                                  <div
                                                    className={`col-sm-${'1'} target-value-padding targetValue-${colStatus} col-target-value ${isApproverRejectedView}`}
                                                    style={boiItem.isZeroTarget && { display: 'block' }}
                                                  >
                                                    <div
                                                      className={
                                                        boiItem.isZeroTarget &&
                                                        boiItem.boiTarget === 0 &&
                                                        'zero-target-value'
                                                      }
                                                    >
                                                      {formatValue(
                                                        `${itemId}_${yInd}_TA_CUMULATIVE`,
                                                        finUSDData.cumulativeBOITargetApprovedValue,
                                                        boiItem.boiUOM
                                                      )}
                                                    </div>
                                                  </div>
                                                  {/* Value Impacting Client P&L approved*/}
                                                  <div
                                                    className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                                                      ? '1'
                                                      : '2'} realized-value-${colStatus} col-vic ${isApproverRejectedView}`}
                                                  >
                                                    {formatValue(
                                                      `${itemId}_${yInd}_ARV_CUMULATIVE`,
                                                      finUSDData.cumulativeBOIApprovedRealizedValue,
                                                      boiItem.boiUOM
                                                    )}

                                                  </div>
                                                  {/* Client Value Identified */}
                                                  <div
                                                    className={`col-sm-${(isAwaiting &&
                                                      !RVDComponents.approveRejectButton) ||
                                                      isRejected
                                                      ? '1'
                                                      : '2'
                                                      } target-realized-${colStatus} col-cvi ${isApproverRejectedView}`}
                                                  >
                                                    {formatValue(
                                                      `${itemId}_${yInd}_CVI_CUMULATIVE`,
                                                      finUSDData.cumulativeClientValueIdentified,
                                                      boiItem.boiUOM
                                                    )}
                                                  </div>
                                                  {/* Dashboard Visibility */}
                                                  <div
                                                    className={`col-sm-${wvisCreatorView} wVis-${colStatus} col-dashboard ${isApproverRejectedView} ${isRejected ? 'rej ' : ''} ${boiItem.expandedBOI && finUSDData.expandedYear && finUSDData.expandedQuarter ? 'open' : ''}`}
                                                  ></div>
                                                  {/* boi comment */}
                                                  <div
                                                    className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} col-boi-comment ${isApproverRejectedView}`}
                                                  ></div>
                                                  {/* approve reject */}
                                                  {(!RVDComponents.approveRejectButton &&
                                                    isAwaiting) ||
                                                    isRejected ? (
                                                    <div
                                                      className={`col-sm-${isAwaiting ? 2 : 2
                                                        } app-rej-${colStatus} col-app-rej ${isApproverRejectedView}`}
                                                    >
                                                      <div className="rvd-rejection-comment"></div>
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {/* </div> */}
                                                </div>
                                                /* cumulative number*/
                                                :
                                                ""
                                            }
                                          </>
                                        );
                                      })
                                      :
                                      <>
                                        <div className={`fin-usd-body-container row fin-usd-body-text cvi-non-header`}>
                                          {/* boi */}
                                          <div className={`col-sm-${isAwaiting ? '2' : colWidth.boi} col-boi-name ${isApproverRejectedView} bodytext14-medium`}>
                                            Parameters
                                          </div>
                                        </div>
                                        <div className={`fin-usd-body-container row fin-usd-body-text cvi-non-header`}>
                                          {/* boi */}
                                          <div className={`col-sm-${isAwaiting ? '2' : colWidth.boi} col-boi-name ${isApproverRejectedView} field-text`}>
                                            {!IsArrayEmpty(boiItem.parameterList) ? boiItem.parameterList[0].parameterName : ""}
                                          </div>
                                        </div>
                                      </>
                                  }
                                </Card.Body>
                                {/* accordion body end */}
                              </Accordion.Collapse>
                              {/* accordion header start */}
                              <Card.Header className={boiItem.expandedBOI &&
                                finUSDData.expandedYear &&
                                finUSDData.expandedQuarter ? 'expanded-header' : 'collapsed-header'}>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                  id={itemId}
                                  onClick={(e) => onExpandCollapse(e, itemId, targetParam)}
                                >
                                  <GrooveIconSvg
                                    customClassName="accordion-button"
                                    size="tiny"
                                    name="chevron-right"
                                    iconStyle="solid"
                                    iconColor="stat-neutral"
                                  />
                                </Accordion.Toggle>
                                <div className="row fin-usd-body-text">
                                  {/* boi */}
                                  <div className={`col-sm-${isAwaiting ? '2' : colWidth.boi} col-boi-name ${isApproverRejectedView} bodytext14`}>
                                    {boiItem.boiName}
                                    <OverlayTrigger
                                      key="right"
                                      placement="auto"
                                      overlay={
                                        <Tooltip id="boi-tooltip-right">
                                          <div className="boi-details-wrap row">
                                            <div class="col-md-6">
                                              <h6>Offering</h6>
                                              <p>
                                                {boiItem.offeringName ? boiItem.offeringName : ''}
                                              </p>
                                              <h6>Dimension</h6>
                                              <p>{dItem.dimensionName}</p>
                                              <h6>BOI Description</h6>
                                              <p>{boiItem.boiDescription}</p>
                                            </div>
                                            <div class="col-md-6">
                                              <h6>Sub-Offering</h6>
                                              <p>
                                                {boiItem.subofferingName
                                                  ? boiItem.subofferingName
                                                  : ''}
                                              </p>
                                              <h6>Sub-dimension</h6>
                                              <p>{SDItem.subDimensionname}</p>
                                              <h6>Leading Practice</h6>
                                              <p>{boiItem.leadingIndicator}</p>
                                              <table>
                                                <tr class="col-md-12">
                                                  <th class="col-md-6">
                                                    <h6>Parameter</h6>
                                                  </th>
                                                  <th class="col-md-6">
                                                    <h6>Unit of Measure</h6>
                                                  </th>
                                                </tr>
                                                {(HasProperty(boiItem, 'parameterList')
                                                  ? boiItem.parameterList
                                                  : []
                                                ).map((params) => {
                                                  return (
                                                    <tr>
                                                      <td class="col-md-6">
                                                        <p>{params.parameterName}</p>
                                                      </td>
                                                      <td class="col-md-6">
                                                        <p>{params.parameterUOM}</p>
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                              </table>
                                            </div>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="rvdboi-new-info">
                                        {!boiItem.IsCustom ? (
                                          <img className="new-info-icon" src={newInfo} alt="info" />
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                    {boiItem.IsCustom ? (
                                      <span>
                                        <button
                                          className="custom-boi"
                                          style={{ marginLeft: '1em' }}
                                          onClick={() => onClickCustomBOI(boiItem.boiID, true)}
                                        >
                                          Custom
                                        </button>
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    <div className="projectboi-rejected-alert">
                                      {boiItem.projectLevelBOIStatus.toLowerCase() ===
                                        'rejected' ? (
                                        <OverlayTrigger
                                          key="bottom"
                                          placement="bottom"
                                          overlay={
                                            <Tooltip id="rvd-disabled-sys">
                                              Value reported will not be reflected in the dashboard
                                              as this BOI is rejected at program/project level.
                                            </Tooltip>
                                          }
                                        >
                                          <div>
                                            <GrooveIconSvg
                                              customClassName="dark-red-alert"
                                              size="small"
                                              name="exclamation-triangle"
                                              iconStyle="solid"
                                              iconColor="stat-alternate"
                                            />
                                          </div>
                                        </OverlayTrigger>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  {/* year */}
                                  <div className={`col-sm-${'1'} col-year ${isApproverRejectedView} bodytext14`}>
                                    {boiItem.expandedBOI ?
                                      <>
                                        {
                                          showHeaderIcons ?
                                            <>
                                              <a data-tip data-for={`yr_${itemId}_${boiI}_${0}_${0}`}>
                                                <div className="cvi-plus-icon-container">
                                                  <GrooveIconSvg
                                                    customClassName="cvi-plus-icon"
                                                    size="small"
                                                    name={!finUSDData.expandedYear ? "plus" : "minus"}
                                                    // name={"minus"}
                                                    iconStyle="solid"
                                                    iconColor="stat-alternate"
                                                    callbackFunction={(e) => handleExpandCollapseYearQtr(
                                                      {
                                                        year: headerYear,
                                                        quarter: headerQtr,
                                                        month: headerMonth,
                                                        trigger: "year",
                                                        id: itemId,
                                                        index: {
                                                          year: 0,
                                                          quarter: 0,
                                                          month: 0,
                                                        },
                                                        isExpanded: finUSDData.expandedYear
                                                      },
                                                      targetParam)}
                                                  />
                                                </div>
                                              </a>
                                              {
                                                !finUSDData.expandedYear ?
                                                  <ReactTooltip id={`yr_${itemId}_${boiI}_${0}_${0}`} type="dark" effect="solid">
                                                    <span>{prevYearMessage}</span>
                                                  </ReactTooltip>
                                                  :
                                                  ""
                                              }
                                            </>
                                            :
                                            ""
                                        }
                                        {headerYear}
                                      </>
                                      : ""
                                    }

                                  </div>
                                  {/* quarter */}
                                  <div className={`col-sm-${'1'} col-qtr ${isApproverRejectedView} bodytext14`}>
                                    {boiItem.expandedBOI && finUSDData.expandedYear ?
                                      <>
                                        {
                                          showHeaderIcons ?
                                            <>
                                              <a data-tip data-for={`qtr_${itemId}_${boiI}_${0}_${0}`}>
                                                <div className="cvi-plus-icon-container">
                                                  <GrooveIconSvg
                                                    customClassName="cvi-plus-icon"
                                                    size="small"
                                                    name={!finUSDData.expandedQuarter ? "plus" : "minus"}
                                                    // name={"minus"}
                                                    iconStyle="solid"
                                                    iconColor="stat-alternate"
                                                    callbackFunction={(e) => handleExpandCollapseYearQtr(
                                                      {
                                                        year: headerYear,
                                                        quarter: headerQtr,
                                                        month: headerMonth,
                                                        trigger: "qtr",
                                                        id: itemId,
                                                        index: {
                                                          year: 0,
                                                          quarter: 0,
                                                          month: 0,
                                                        },
                                                        isExpanded: finUSDData.expandedQuarter
                                                      },
                                                      targetParam)}
                                                  />
                                                </div>
                                              </a>
                                              {
                                                !finUSDData.expandedQuarter ?
                                                  <ReactTooltip id={`qtr_${itemId}_${boiI}_${0}_${0}`} type="dark" effect="solid">
                                                    <span>{prevQtrMessage}</span>
                                                  </ReactTooltip>
                                                  :
                                                  ""
                                              }
                                            </>
                                            :
                                            ""
                                        }
                                        Qtr {headerQtr}
                                      </>
                                      : ""
                                    }
                                  </div>
                                  {/* month */}
                                  <div className={`col-sm-${'1'} col-month ${isApproverRejectedView}`}>
                                    {
                                      boiItem.expandedBOI ?
                                        finUSDData.expandedYear ?
                                          finUSDData.expandedQuarter ? headerMonth : ""
                                          : ""
                                        :
                                        ""
                                    }

                                  </div>
                                  {
                                    boiItem.expandedBOI &&
                                      finUSDData.expandedYear &&
                                      finUSDData.expandedQuarter ?
                                      <>
                                        {/* target value */}
                                        <div
                                          className={`col-sm-${'1'} target-value-padding targetValue-${colStatus} col-target-value ${isApproverRejectedView}`}
                                          style={boiItem.isZeroTarget && { display: 'block' }}
                                        >
                                          <div
                                            className={
                                              boiItem.isZeroTarget &&
                                              boiItem.boiTarget === 0 &&
                                              'zero-target-value'
                                            }
                                          >
                                            {formatValue(
                                              `${itemId}_TA`,
                                              boiItem.boiTargetApproved,
                                              boiItem.boiUOM
                                            )}
                                          </div>
                                          <div
                                            className={
                                              boiItem.isZeroTarget &&
                                              boiItem.boiTarget === 0 &&
                                              'zero-target-value-msg'
                                            }
                                          >
                                            {boiItem.zeroTargetMessage}
                                          </div>
                                        </div>

                                        {/* Value Impacting Client P&L approved */}
                                        <div
                                          className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                                            ? '1'
                                            : '2'} realized-value-${colStatus} field-value col-vic ${isApproverRejectedView}`}
                                        >
                                          <div className="finUSD-cont">
                                            <CVMInput
                                              type="text"
                                              id="finUSDVICInput"
                                              placeholder={'Value Impacting Client P&L'}
                                              customClassName={paramItem.isInvalid ? 'error' : ''}
                                              inputValue={
                                                paramItem.parameterValue === null
                                                  ? ''
                                                  : paramItem.parameterValue
                                              }
                                              onBlurCallbackFunction={(e) => {
                                                if (!this.props.editedAnyFieldOnce && this.props.overallStatus === 'Approved') {
                                                  this.props.checkForInvalidEmailsOnLanding();
                                                  this.props.setEditedAnyFieldOnce(true);
                                                }
                                                handleParameterChange(
                                                  targetParam,
                                                  e.target.value,
                                                  'blur'
                                                );
                                              }
                                              }
                                              callbackFunction={(e) => {
                                                if (!this.props.editedAnyFieldOnce && this.props.overallStatus === 'Approved') {
                                                  this.props.checkForInvalidEmailsOnLanding();
                                                  this.props.setEditedAnyFieldOnce(true);
                                                }
                                                handleParameterChange(targetParam, e.target.value);
                                              }
                                              }
                                              isDisabled={
                                                RVDComponents.rvdParams || disableSuperAdmin ||(!isSuperAdmin&&basicDetails.overallStatus.toLowerCase() == "approved")
                                              }
                                              maxLength={255}
                                            />
                                          </div>
                                          {(paramItem.parameterValue && paramItem.parameterValue.length >= 12) && (
                                            <div className="param-char-length-err fin-usd" title={paramItem.invalidMsg}>
                                              {'You have reached your maximum limit of characters allowed.'}
                                            </div>
                                          )}
                                          {(paramItem.isInvalid && (paramItem.parameterValue == ""||paramItem.parameterValue == null)) && (
                                            <div className="param-char-length-err fin-usd" title={paramItem.invalidMsg}>
                                              {'Missing Required Field'}
                                            </div>
                                          )}
                                        </div>
                                        {/* Client Value Identified */}
                                        <div
                                          className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) ||
                                            isRejected
                                            ? '1'
                                            : '2'
                                            } target-realized-${colStatus} field-value field-cv col-cvi ${isApproverRejectedView}`}
                                        >
                                          <div className='finUSD-cont'>
                                            <CVMInput
                                              type="text"
                                              // id="client-value-identified"
                                              id="finUSDCVIInput"
                                              placeholder="Client Value Identified"
                                              customClassName={
                                                boiItem.clientValueIdentifiedTemp.isInvalid
                                                  ? 'error'
                                                  : ''
                                              }
                                              inputValue={
                                                boiItem.clientValueIdentifiedTemp.value === null ||
                                                  boiItem.clientValueIdentifiedTemp.value === 0
                                                  ? ''
                                                  : boiItem.clientValueIdentifiedTemp.value
                                              }
                                              onBlurCallbackFunction={(e) =>
                                                handleChange(targetParam, e.target.value, 'blur')
                                              }
                                              callbackFunction={(e) =>
                                                handleChange(targetParam, e.target.value)
                                              }
                                              // isDisabled={boiItem.clientValueIdentifiedTemp.disabled || RVDComponents.rvdParams || disableSuperAdmin}
                                              isDisabled={true}
                                              maxLength={255}
                                            />
                                            <OverlayTrigger
                                              key="bottom"
                                              placement="bottom"
                                              overlay={
                                                <Tooltip id="calculation-tooltip">
                                                  <div className="boi-details-wrap">
                                                    <h6>Calculation Logic</h6>
                                                    <div className="calculation-logic">
                                                      <div className="calculation-logic-header">
                                                        <div className="row">
                                                          <div className="col-sm-4">
                                                            Sub-Dimension
                                                          </div>
                                                          <div className="col-sm-8">
                                                            Percentage Applicable for P&L
                                                            conversion from Identified Value
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="calculation-logic-body">
                                                        <div className="row">
                                                          <div className="col-sm-4">Capital</div>
                                                          <div className="col-sm-8">10%</div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-4">Revenue</div>
                                                          <div className="col-sm-8">15%</div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-4">Opex</div>
                                                          <div className="col-sm-8">100%</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <h6 className='cvi-text-h6'>To get more details on Client Value Identified Field, please reach out to SVM.admin</h6>
                                                  </div>
                                                </Tooltip>
                                              }
                                            >
                                              <div className="info-icon">
                                                <GrooveIconSvg
                                                  size="large"
                                                  name="info-circle"
                                                  iconStyle="solid"
                                                  iconColor="stat-alternate"
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          </div>
                                          <div className="param-char-length-err error-message-wrap">
                                            {/* <div className='cvi-text'> */}
                                            <div className="" title={cviMsg}>
                                              {cviMsg}
                                            </div>
                                            {boiItem.clientValueIdentifiedTemp.isInvalid ? (
                                              <div>
                                                {boiItem.clientValueIdentifiedTemp.invalidMsg}
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                        {/* warning */}
                                        {boiItem.projectLevelBOIStatus.toLowerCase() ===
                                          'rejected' ? (
                                          <div className="rejected-projBoi-warning">
                                            <GrooveIconSvg
                                              className="exclamation-icon"
                                              size="normal"
                                              name="exclamation"
                                              iconStyle="solid"
                                            />
                                            <div>
                                              This BOI is rejected at Program/Project configuration
                                              level and values reported will neither be shown in the
                                              wheel nor will be included for Reporting
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        {/* warning 2 */}
                                        <div className={`invalid-cumulative-cont ${boiItem.clientValueIdentifiedTemp.isInvalid ? 'error-adj' : ''}`}>
                                          {TryGetObjValueAlt(
                                            boiItem,
                                            false,
                                            'realizedValInvalid'
                                          ) && boiItem.boiUOM.toLowerCase() === 'usd' ? (
                                            <div
                                              className="rejected-projBoi-warning"
                                              id="invalid-cumulative"
                                            >
                                              You are expected to enter cumulative value, kindly
                                              review the value and re-compute or reach out to
                                              SVM.admin@accenture for any support
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </>
                                      :
                                      <>
                                        {/* target value */}
                                        <div
                                          className={`col-sm-${'1'} ${headerBodyCSS} target-value-padding targetValue-${colStatus} col-target-value ${isApproverRejectedView}`}
                                          style={boiItem.isZeroTarget && { display: 'block' }}
                                        >
                                          <div
                                            className={
                                              boiItem.isZeroTarget &&
                                              boiItem.boiTarget === 0 &&
                                              'zero-target-value'
                                            }
                                          >
                                            {formatValue(
                                              `${itemId}_TA`,
                                              headerTV,
                                              boiItem.boiUOM
                                            )}
                                          </div>
                                          <div
                                            className={
                                              boiItem.isZeroTarget &&
                                              boiItem.boiTarget === 0 &&
                                              'zero-target-value-msg'
                                            }
                                          >
                                            {boiItem.zeroTargetMessage}
                                          </div>
                                        </div>
                                        {/* Value Impacting Client P&L approved*/}
                                        <div
                                          className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) || isRejected
                                            ? '1'
                                            : '2'} ${headerBodyCSS} realized-value-${colStatus} col-vic ${isApproverRejectedView}`}
                                        >
                                          {formatValue(
                                            `${itemId}_ARV`,
                                            headerVIC,
                                            boiItem.boiUOM
                                          )}
                                        </div>
                                        {/* Client Value Identified */}
                                        <div
                                          className={`col-sm-${(isAwaiting && !RVDComponents.approveRejectButton) ||
                                            isRejected
                                            ? '1'
                                            : '2'
                                            } target-realized-${colStatus} ${headerBodyCSS} col-cvi ${isApproverRejectedView}`}
                                        >
                                          {formatValue(
                                            `${itemId}_CVI`,
                                            headerCVI,
                                            boiItem.boiUOM
                                          )}
                                        </div>
                                      </>
                                  }


                                  {/* Dashboard Visibility */}
                                  <div
                                    className={`col-sm-${wvisCreatorView} wVis-${colStatus} col-dashboard ${isApproverRejectedView} ${isRejected ? 'rej' : ''} ${boiItem.expandedBOI && finUSDData.expandedYear && finUSDData.expandedQuarter ? 'open' : ''} body`}
                                  >
                                    <GrooveSwitchToggle
                                      id={'switch-tgl-01' + boiIndex}
                                      toggle_labels=""
                                      defaultValue={
                                        boiItem.projectBOIWheelVisibility === true ||
                                          boiItem.boiWheelVisivility === 1
                                          ? true
                                          : false
                                      }
                                      isDisabled={true}
                                      customClassName="wheel-visibility-button disabled-switch"
                                    />
                                  </div>
                                  {/* boi comment */}
                                  <div
                                    className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} col-boi-comment ${isApproverRejectedView}`}
                                  >
                                    <GrooveIconSvg
                                      customClassName="commentary-modal-btn"
                                      size="large"
                                      name="comment"
                                      iconStyle={
                                        boiItem.RVDCommentHistory.length > 0 ? 'solid' : 'regular'
                                      }
                                      iconColor={
                                        isComment
                                          ? boiItem.RVDCommentHistory.length > 0
                                            ? 'stat-alternate'
                                            : 'stat-warning'
                                          : 'stat-alternate'
                                      }
                                      callbackFunction={() =>
                                        !isPPC && !isBA && !isSuperAdmin ?
                                          handleBOICommentHistory(
                                            true,
                                            boiItem,
                                            SDItem.boiList
                                          )
                                          : RVDComponents.approveRejectButton ||
                                            boiItem.isNewValue ||
                                            (isPPC || isBA || isSuperAdmin)
                                            ? handleBOIComment(true, boiItem)
                                            : handleBOICommentHistory(true, boiItem, SDItem.boiList)
                                      }
                                    />
                                  </div>
                                  {/* approve reject */}
                                  {(!RVDComponents.approveRejectButton && isAwaiting) ||
                                    isRejected ? (
                                    <div
                                      className={`col-sm-${isAwaiting ? 2 : 2
                                        } app-rej-${colStatus} col-app-rej ${isApproverRejectedView}`}
                                    >
                                      <div className="rvd-rejection-comment">
                                        {isAwaiting && !RVDComponents.approveRejectButton ? (
                                          <ApproveRejectRadioButton
                                            boiID={boiItem.boiID}
                                            boiStatus={boiItem.boiStatus}
                                            dIndex={dIndex}
                                            SDIndex={sdIndex}
                                            boiIndex={boiIndex}
                                            isRejected={setIsReject}
                                            userRoleID={currentUserRoleID}
                                            dimensionName={dItem.dimensionName}
                                            boiUOM={boiItem.boiUOM}
                                            currentUserRoleID={currentUserRoleID}
                                          />
                                        ) : (
                                          ''
                                        )}
                                        {boiItem.boiStatus === 'Rejected' ? (
                                          <GrooveTextBox
                                            maxLength={250}
                                            id="comment-textbox-rejected"
                                            name="comment-textbox-rejected"
                                            type="text"
                                            placeholder="Rejection Comment*"
                                            statusMessage={
                                              submitReject && boiItem.boiReason === ''
                                                ? reasonMsg
                                                : null
                                            }
                                            isValid={isRejected ? false : boiItem.boiReason !== ''}
                                            customClassName={
                                              submitReject && boiItem.boiReason === ''
                                                ? 'input-invalid'
                                                : null
                                            }
                                            textValue={boiItem.boiReason}
                                            callbackFunction={(e) => {
                                              if (!this.props.editedAnyFieldOnce && this.props.overallStatus === 'Approved') {
                                                this.props.checkForInvalidEmailsOnLanding();
                                                this.props.setEditedAnyFieldOnce(true);
                                              }
                                              updateReason(e, {
                                                dimensionIndex: dIndex,
                                                subDimensionIndex: sdIndex,
                                                boiIndex: boiIndex,
                                              });
                                            }}
                                            isDisabled={
                                              basicDetails.overallStatus.toLowerCase() !== awaiting
                                            }
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </Card.Header>
                              {/* accordion header end */}
                            </Card>
                          </Accordion>
                        );
                      })}
                    </div>
                    {/* body end*/}
                  </div>
                  {/* table end*/}
                </div>
              </Card.Body>
            </Accordion.Collapse>
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={isIOAdmin && isExistingFinancial ? dIndex.toString() : '0'}
              >
                <div className="pde-subdimension-name bodytext18-medium-midnight">
                  {SDItem.subDimensionname}
                </div>
                <GrooveIconSvg
                  customClassName="accordion-button"
                  size="large"
                  name="chevron-down"
                  iconStyle="solid"
                  iconColor="stat-neutral"
                />
              </Accordion.Toggle>
            </Card.Header>
          </Card>
        </Accordion >
      );
    }
  }

  class ClientValueIdentifiedUSDContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    componentDidMount = () => {
      try {
        this.onLoadExpandCollapseRYQtr("year");
      }
      catch (e) {
        console.log(e)
      }
    }

    onLoadExpandCollapseRYQtr = (trigger) => {
      try {
        const { dItem, dIndex } = this.props;
        let p = this.props;
        const { sdIndex, hadFinalized, colWidth, colStatus } = p.selSDItem;

        p.selSDItem.boiList.map((boiItem) => {
          const boiIndex = boiItem.boiIndex;
          const itemId = `${dIndex}_${sdIndex}_${boiIndex}_${boiItem.boiUOM}`;

          let data = {
            year: "",
            quarter: "",
            month: "",
            trigger: trigger,
            id: itemId,
            index: {
              year: 0,
              quarter: 0,
              month: 0,
            },
            isExpanded: false,
          };
          let targetParam = {
            dimension: {
              item: dItem,
              index: dIndex,
            },
            subDimension: {
              item: p.SDItem,
              index: sdIndex,
            },
            boi: {
              item: p.boiItem,
              index: boiIndex,
            },
            parameter: {
              item: [],
              index: "",
            },
          };
          //expand first year per boi if there is an existing historical data
          if (!IsArrayEmpty(boiItem.boiDeliveredValueList.filter((a) => a.reportingYear === boiItem.finUSDData.latestDate.year))) {
            this.handleExpandCollapseYearQtr(data, targetParam);
          }

          //collapse ff years
          // data
          // this.handleExpandCollapseYearQtr(data, targetParam);
        });
      }
      catch (e) {
        console.log(e)
      }
    }

    onExpandCollapse = async (e, id, targetParam) => {
      try {
        let isExpanded = document
          .getElementById(id)
          .parentElement.parentElement.childNodes[0].className.includes('show');

        let data = {
          year: "",
          quarter: "",
          month: "",
          trigger: isExpanded ? "boi" : "year",
          id: id,
          index: {
            year: 0,
            quarter: 0,
            month: 0,
          },
          isExpanded: isExpanded,
        };

        await this.props.updateExpandCollapseBOIView(targetParam, !isExpanded, '');


        this.handleExpandCollapseYearQtr(data, targetParam);
      } catch (e) {
        console.log(e);
      }
    };

    handleExpandCollapseYearQtr = async (data, targetParam) => {
      try {
        let className = "";
        let parent = [];
        switch (data.trigger) {
          case "qtr":
            className = `qtr-body-con-${data.id}_${data.index.year}_${data.index.quarter}`;
            parent = document.getElementsByClassName(className);
            break;
          case "year":
            className = `ry-body-con-${data.id}_${data.index.year}`;
            parent = document.getElementsByClassName(className);
            break;
          case "boi":
            className = `fin-usd-main-con-${data.id}`;
            parent = document.getElementsByClassName(className);
            break;
          default:
            break;
        }

        for (var i = 0; i < parent.length; i++) {
          let panel = parent[i];
          panel.style.maxHeight = data.isExpanded ? 0 : panel.scrollHeight + "px";
        }

        await this.props.handleExpandCollapseYearQtr(targetParam, data);
      }
      catch (e) {
        console.log(e)
      }
    }

    render() {
      return (
        <ClientValueIdentifiedUSDPresentational
          {...this.props}
          onExpandCollapse={this.onExpandCollapse}
          handleExpandCollapseYearQtr={this.handleExpandCollapseYearQtr}
          boiFieldID={this.state.boiFieldID}
          showInputField={this.state.showInputField}
        />
      );
    }
  }

  return ClientValueIdentifiedUSDContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      UserInformationData: state.userInformation,
      SharedData: state.SharedData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          handleClientValueIdentified,
          setRVDSelectedTabs,
          updateExpandCollapseBOIView,
          handleExpandCollapseYearQtr,
        }
      ),
      dispatch
    )
)(ClientValueIdentifiedUSD);
