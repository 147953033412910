import React, { Component } from "react";
import { GrooveInput, GrooveTextBox } from 'tfo-groove';
import commentIcon from '../../Static/images/360cvm/commentIcon.svg'
import CVMModal from "../Shared/CVMModal/CVMModal";

const RequestorCommentPopup = (function () {
  class RequestorCommentPopupPresentational extends Component {
    render() {
      const form = this.props.formContents
      return (
        <React.Fragment>
          <span className='top-left-container-text' onClick={this.props.onCloseModal}>
            <img src={commentIcon} alt='comment' className='top-left-container-icon' />
            Requestor comment
          </span>
          <div className="BT-Lead-Comment-body" >
            <CVMModal
              title="Requestor Comment"
              show={this.props.show}
              onCloseModal={this.props.onCloseModal}
              size="md"
              content={
                <div className="BT-Lead-Comment">
                  <div className="input">
                    <GrooveInput
                      name="Approver ID"
                      isDisabled={true}
                      placeholder={form.requestorLabel}
                      id="approver"
                      inputValue={form.requestor}
                    />
                  </div>
                  <div className="Requestor-comments">
                    <GrooveTextBox
                      id="comments"
                      name="comments"
                      type="text"
                      isDisabled={true}
                      placeholder={"Comment for approval"}
                      textValue={form.comment}
                      rows="5"
                      className="check-comment"
                    />
                  </div>
                </div>
              }
            />
          </div>
        </React.Fragment>

      );
    }
  }

  class RequestorCommentPopupContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: false
      }
    }

    onCloseModal = () => {
      this.setState({ show: !this.state.show });
    }

    componentDidMount = () => {
      this.changeTextAreaClass()
    }

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }
    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    render() {
      return <RequestorCommentPopupPresentational
        show={this.state.show}
        onCloseModal={this.onCloseModal}
        formContents={this.props.formContents}
      />;
    }
  }

  return RequestorCommentPopupContainer;
})();

export default RequestorCommentPopup;
