import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { GrooveTabComponent } from "tfo-groove";
import { Row, Col } from "react-bootstrap";
import { CheckModuleAccess } from '../../Utilities/AccessManagementUtil';
// icons
import ClientManagement from "./ClientManagement";
import UserManagement from "./UserManagement";
import IOApproverManagement from "./IOApproverManagement";
import "./MasterConfigurationStyles.css";
import QualityCheckerManagement from "./QualityCheckerManagement";
const MasterConfiguration = (function () {
  class MasterConfigurationPresentational extends Component {
    render() {
      const tab_data = [
        {
          tab_label: "Client Management",
          tab_value: 1,
          tab_id: "client-management-tab",
          tab_content: (
            <div className="cvm-tab-content">
              <ClientManagement />
            </div>
          ),
        },
        {
          tab_label: "User Management",
          tab_value: 2,
          tab_id: "user-management-tab",
          tab_content: (
            <div className="cvm-tab-content">
              <UserManagement />
            </div>
          ),
        },
        {
          tab_label: "IO Approver Management",
          tab_value: 2,
          tab_id: "io-approver-management-tab",
          tab_content: (
            <div className="cvm-tab-content">
              <IOApproverManagement />
            </div>
          ),
        },
        // {
        //   tab_label: "Quality Checker Management",
        //   tab_value: 3,
        //   tab_id: "quality-checker-management-tab",
        //   tab_content: (
        //     <div className="cvm-tab-content">
        //       <QualityCheckerManagement />
        //     </div>
        //   ),
        // },
      ];
      return (
        <React.Fragment>
          <div className="title-section">
            <h4 className="header24-semibold-midnight">Admin</h4>
          </div>
          <div className="master-config-contents">
            <GrooveTabComponent theme="purple" tab_data={tab_data} />
          </div>
        </React.Fragment>
      );
    }
  }

  class MasterConfigurationContainer extends Component {
    constructor() {
      super();
      this.state = {};
    }
    render() {
      const { modules } = this.props.HomeData;
      return (modules.length > 0 ?
        CheckModuleAccess(modules) ?
          <MasterConfigurationPresentational />
          :
          <Row className="access_denied_container">
            <Col md={12} xs={12}>
              <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
              <div className="access_denied_content">
                <h1>Access Denied</h1>
                <p>You don't have the necessary user privileges to view the client that you are attempting to access.</p>
              </div>
            </Col>
          </Row>
        :
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      );
    }
  }

  return MasterConfigurationContainer;
})();

export default connect(
  (state) => {
    return {
      userInformation: state.userInformation,
      HomeData: state.HomeData
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {},
      ),
      dispatch
    )
)(MasterConfiguration);
