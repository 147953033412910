import React, { Component } from "react";
import { GrooveIconSvg, GrooveTextBox } from 'tfo-groove';
import Upload from "../../Static/images/360cvm/upload.svg";
import Dropzone from 'react-dropzone';


const ClientConfirmationDocs = (function () {
  class ClientConfirmationDocsPresentational extends Component {
    render() {
      return (
        <React.Fragment>
            <Dropzone
                  onDropAccepted={acceptedFiles => this.props.onDropAccepted(acceptedFiles)}
                  onDropRejected={rejectedFiles => this.props.onDropRejected(rejectedFiles)}
                  maxSize={10000000}
                  maxFiles={3}
                  accept='.msg, .eml, .pdf'
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className={this.props.fileIsValid === null ?  "cvm-dropzone" : this.props.fileIsValid ? "cvm-dropzone valid" : "cvm-dropzone invalid"}>
                        <div className="btn">
                        </div>
                        <input {...getInputProps()} />
                        <img src={Upload} alt="upload"/>
                        <div className="cvm-dropzone-label">
                          Drag & Drop File here or <span>Browse files</span>
                        </div>
                    </div>
                  )}
            </Dropzone>
            {!this.props.fileIsValid ? (
              <div className="invalid-reason">{this.props.errorUpload}</div>
            ) : null}
          <div className="uploaded-files-container">
            {this.props.Files.map((files, fileIndex) => (
              <div className="uploaded-files">
                <div className="uploaded-files-icon">
                  <GrooveIconSvg
                    // customClassName="delete-icon"
                    size="small"
                    name="file"
                    iconStyle="regular"
                    iconColor="stat-neutral"
                  />  
                </div>
                <div className="uploaded-files-name">{files.name}</div>
                <div className="uploaded-files-progressbar">
                  <div className="upload-progress"></div>
                </div>
                <div className="uploaded-files-close">
                  <GrooveIconSvg
                    customClassName="delete-icon"
                    size="small"
                    name="times"
                    iconStyle="solid"
                    iconColor="stat-neutral"
                    callbackFunction={() => this.props.onDeleteUpload(files.name)}
                  />  
                </div>
            </div>
            ))}            
          </div>
          <div className="comments">
          {/* callbackFunction did not update this.props.comment */}
          <GrooveTextBox
              id="comments"
              name="comments"
              type="text"
              placeholder={`${(this.props.commentValue !== "" || this.props.comment !== "" ) ? "" : "Enter "}Comment`}
              maxLength={255}
              rows="2"
              textValue={this.props.comment}
              callbackFunction={(e) => this.props.onChangeComment(e)}
              customClassName={`${this.props.invalidComment ? "doc-upload-invalid-comment" : ""}`}
          /> 
          </div>
          {
            this.props.invalidComment && (
              <label style={{ margin: '7px 0 2px', color: 'red' }}>Invalid comment</label>
            )
          }
        </React.Fragment>
      );
    }
  }

  class ClientConfirmationDocsContainer extends Component {
    constructor() {
      super();
      this.state = {
        Files: [],
        errorUpload: '',
        fileIsValid: true,
        commentValue: ''
      }
    }

    onChangeComment = (e) => {
      this.setState({
        commentValue: e.value
      });

      try{
        this.props.onChangeComment(e.value);
      }
      catch(e){
        console.log(e);
      }
    }

    componentDidMount = () => {
      this.changeTextAreaClass()
    }

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }
    

    render() {
      return <ClientConfirmationDocsPresentational
      onDropAccepted={this.props.onDropAccepted}
      onDropRejected={this.props.onDropRejected}
      onDeleteUpload={this.props.onDeleteUpload}
      Files={this.props.Files}
      errorUpload={this.props.errorUpload}
      onChangeComment={this.onChangeComment}
      fileIsValid={this.props.fileIsValid}
      comment={this.props.comment}
      commentValue={this.state.commentValue}
      invalidComment={this.props.invalidComment}
      />;
    }
  }

  return ClientConfirmationDocsContainer;
})();

export default ClientConfirmationDocs;
