
import {
    FETCH_PROGRAM_LIST,
    FETCH_CVM_PROJ_LEAD_DRPDOWN,
    FETCH_CVM_PROJ_DELIV_LOC_DRPDOWN,
    FETCH_CVM_PROJ_DELIV_BY_DRPDOWN
} from '../Constants';

const initialState = {
    programList: [],
    projLeadList: { list: [], clietnID: 0 },
    projDelivLocList: [],
    projDelivByList: { list: [], clietnID: 0 },
};


export const ProgramList = (state, action) => {
    state = state || initialState.programList;

    if (action.type === FETCH_PROGRAM_LIST) {
        return action.programList
    } else {
        return state;
    }
}
export const ProjLeadList = (state, action) => {
    state = state || initialState.projLeadList;
    if (action.type === FETCH_CVM_PROJ_LEAD_DRPDOWN) {
        return action.projLeadList
    } else {
        return state;
    }
}
export const ProjDelivLocList = (state, action) => {
    state = state || initialState.projDelivLocList;

    if (action.type === FETCH_CVM_PROJ_DELIV_LOC_DRPDOWN) {
        return action.projDelivLocList
    } else {
        return state;
    }
}
export const ProjDelivByList = (state, action) => {
    state = state || initialState.projDelivByList;

    if (action.type === FETCH_CVM_PROJ_DELIV_BY_DRPDOWN) {
        return action.projDelivByList
    } else {
        return state;
    }
}