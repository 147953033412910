//Redux Action
import {
    REQUEST_DATA, RECEIVED_DATA, ERROR_DATA, SET_USER_INFORMATION, GET_USER_INFORMATION, GET_BO_DASHBOARD_FILTER, SET_BO_DASHBOARD_FILTER,
    SET_IS_MONET_DASHBOARD_ACTIVE, GET_IS_MONET_DASHBOARD_ACTIVE, GET_MONET_DASHBOARD_FILTER, SET_MONET_DASHBOARD_FILTER, SET_PROJECT_LIST, SET_RELOAD_PROJECT_LIST,
    SET_INITIAL_PROJECT_LIST, GET_USER_DETAILS
} from '../Constants';

export function requestData() {
    return {
        type: REQUEST_DATA
    };
}

export function receivedData() {
    return {
        type: RECEIVED_DATA
    };
}

export function errorData() {
    return {
        type: ERROR_DATA
    }
}

export function getUserInformation() {
    return {
        type: GET_USER_INFORMATION
    };
}

export function setUserInformation(userInformation) {
    return {
        type: SET_USER_INFORMATION,
        payload: userInformation
    };
}

export function getBODashboardFilter() {
    return {
        type: GET_BO_DASHBOARD_FILTER
    };
}

export function setBODashboardFilter(boDashboardFilter) {
    return {
        type: SET_BO_DASHBOARD_FILTER,
        payload: boDashboardFilter
    };
}
export function getIsMonetDashboardActive() {
    return {
        type: GET_IS_MONET_DASHBOARD_ACTIVE
    };
}
export function setIsMonetDashboardActive(isMonetDashboardActive) {
    return {
        type: SET_IS_MONET_DASHBOARD_ACTIVE,
        payload: isMonetDashboardActive
    };
}
export function getMonetDashboardFilter() {
    return {
        type: GET_MONET_DASHBOARD_FILTER
    };
}
export function setMonetDashboardFilter(monetDashboardFilter) {
    return {
        type: SET_MONET_DASHBOARD_FILTER,
        payload: monetDashboardFilter
    };
}

export function setBOProjectSearchFilter(searchFilter) {
    return {
        type: SET_PROJECT_LIST,
        payload: searchFilter
    };
}

export function setReloadProjectSearch(isReload) {
    return {
        type: SET_RELOAD_PROJECT_LIST,
        payload: isReload
    };
}

export function setInitialProjectList() {
    return {
        type: SET_INITIAL_PROJECT_LIST
    }
}

export const getUserDetails = () => async (dispatch) => {
    try {
        const data = {
            "role": [1, 2, 3, 4]
        }
        dispatch({
            type: GET_USER_DETAILS,
            userDetails: data
        });
    }
    catch (err) {
        console.log(err)
    }
}