import React, { Component } from "react";
import { history } from "../../store";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { GrooveIconSvg } from "tfo-groove"

import SynOps from "./../../Static/images/360cvm/SynOpsLarge.svg";

import {
  showClientDropdown,
  setSelectedClient,
  leftPanelOnClick
} from "../../Actions/Shared.Action";

import {
  getModules,
  setPath
} from "../../Actions/Home.Action";
import { DeleteReferrer, DeleteDistiURL, LogUserSiteAction } from "../../Utilities/AccessManagementUtil";
import { APP_HOME_PAGE } from "../../Constants/Modules";

const _Home = (function () {
  class HomePresentational extends Component {
    render() {
      return (
        <div className="cvm-homepage">
          <div className="cvm-banner">
            <div>
              <img src={SynOps} alt="dashboard" className="overlay-image" />
              <div className="cvm-welcome">
                Welcome to 360° Synops Value Meter
              </div>
              <div className="cvm-verbiage">
                The SynOps Platform powers a client’s journey to intelligent
                operations <br />
                optimized human + machine workforce, work orchestration, and data
                driven insights.
              </div>
            </div>
            <Row className="modules-cards-container ">
              {
                this.props.dashboardClients ?
                  this.props.modules.map((module) => {
                    return (
                      <Col sm={6} lg={3}>
                        <div className={`modules-card ${module.ModulePath.toLowerCase() === "/dashboard" ? module.isEnabled || this.props.dashboardClients.length > 0 ? "" : "no-access"
                          : module.isEnabled ? "" : "no-access"}`}>
                          <div className="module-info">
                            <GrooveIconSvg
                              size="large"
                              name="info-circle"
                              iconStyle="solid"
                              iconColor="stat-alternate"
                            />
                          </div>
                          <div className="module-long-desc">
                            <div className="module-desc-head">
                              <img className="module-desc-icon" src={module.Icon} alt={module.MenuName} />
                              <div className="module-desc-name">{module.MenuName}</div>
                            </div>
                            <div className="module-desc">
                              {module.LongDescription}
                            </div>
                            <div className="module-desc-footer">
                              {
                                module.ModulePath.toLowerCase() === "/dashboard" ? module.isEnabled || this.props.dashboardClients.length > 0 ?
                                  <div
                                    className="module-button"
                                    onClick={module.ModulePath.toLowerCase() === "/dashboard" ? module.isEnabled || this.props.dashboardClients.length > 0 ? () => this.props.goTo(module) : null
                                      : module.isEnabled ? () => this.props.goTo(module) : null}
                                  >
                                    Get Started <FontAwesomeIcon icon={faChevronRight} />
                                  </div>
                                  : ""
                                  : module.isEnabled ?
                                    <div
                                      className="module-button"
                                      onClick={module.ModulePath.toLowerCase() === "/dashboard" ? module.isEnabled || this.props.dashboardClients.length > 0 ? () => this.props.goTo(module) : null
                                        : module.isEnabled ? () => this.props.goTo(module) : null}
                                    >
                                      Get Started <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                    : ""
                              }
                            </div>
                          </div>
                          <div className="module-icon">
                            <img src={module.Icon} alt={module.MenuName} />
                          </div>
                          <div className="module-name">{module.MenuName}</div>
                          <div className="module-description">
                            {module.ShortDescription}
                          </div>
                          <div
                            className="module-button"
                            onClick={module.ModulePath.toLowerCase() === "/dashboard" ? module.isEnabled || this.props.dashboardClients.length > 0 ? () => this.props.goTo(module) : null
                              : module.isEnabled ? () => this.props.goTo(module) : null}
                          >
                            Get Started <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </Col>
                    )
                  }) : ""
              }
            </Row>
          </div>
        </div>
      );
    }
  }
  class HomeContainer extends Component {
    constructor() {
      super();
      this.state = {
        accessRole: [
          2
        ]
      };
    }

    componentDidMount = () => {
      LogUserSiteAction({ module: APP_HOME_PAGE })
      this.props.setPath("");
    };

    goTo = (module) => {
      let data = {}

      // to clear the selected client if any
      if (module.ModulePath === "/MasterConfiguration") {
        data = {
          value: "",
          label: ""
        }
      }

      this.props.history.push(module.ModulePath);
      this.props.showClientDropdown();
      this.props.leftPanelOnClick(false);
      this.props.setSelectedClient(data)
    };
    render() {
      const { modules } = this.props.HomeData;
      const { dashboardClients } = this.props.DashboardData;
      return <HomePresentational
        goTo={this.goTo}
        accessRole={[...new Map(this.props.userInformation.userProfiles.map(x => [x.ProfileID, x.ProfileID])).values()] || []}
        modules={modules}
        dashboardClients={dashboardClients}
      />;
    }
  }

  return HomeContainer;
})();

export default connect(
  state => state,
  dispatch => bindActionCreators(Object.assign({}, { showClientDropdown }, { setSelectedClient }, { leftPanelOnClick }, { getModules }, { setPath }), dispatch)
)(withRouter(_Home));
