import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { GrooveButton, GrooveIconSvg } from 'tfo-groove';
import CVMModal from '../../Shared/CVMModal/CVMModal';

const ProgramSaveAsDraftModal = (function () {
  class SaveAsDraftPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <GrooveButton
            id="primry-btn-2"
            type="outline"
            colorClass="stat-alternate"
            text="Save as Draft"
            customClassName="add-project-button"
            callbackFunction={this.props.onClickSAD}
            isDisabled={this.props.isDisabled || this.props.shouldDisable}
          />
          <CVMModal
            title={''}
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            customclass="approved-edit-modal"
            content={
              <React.Fragment>
                <Row>
                  <Row>
                    <Col md={3} xs={3}>
                      <GrooveIconSvg
                        customClassName="warning-edit-icon"
                        size="large"
                        name="exclamation-triangle"
                        iconStyle="solid"
                        iconColor="sp-orange"
                      />
                    </Col>
                    <Col md={9} xs={9}>
                      <Row className="approved-edit-bold">You are about to save your changes.</Row>
                      <Row>Please note that Offering cannot be changed once saved.</Row>
                      <Row>Do you still want to continue?</Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12} className="approved-edit-btn">
                      <GrooveButton
                        id="primry-btn-1"
                        name="Solid Button Primary-Ops"
                        isDisabled={false}
                        hasIcon={false}
                        type="outline"
                        colorClass="stat-alternate"
                        size="auto"
                        text="No"
                        callbackFunction={this.props.onCloseModal}
                      />

                      <GrooveButton
                        id="primry-btn-1"
                        name="Solid Button Primary-Ops"
                        isDisabled={false}
                        hasIcon={false}
                        type="solid"
                        colorClass="stat-alternate"
                        size="auto"
                        text="Yes"
                        callbackFunction={this.props.onClickYes}
                      />
                    </Col>
                  </Row>
                </Row>
              </React.Fragment>
            }
          />
        </React.Fragment>
      );
    }
  }

  class SaveAsDraftContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: false,
        isDisabled: false,
      };
    }

    onCloseModal = () => {
      this.props.onCloseModal();
    };

    onClickYes = () => {
      try {
        this.props.onClickYes();
      } catch (e) {
        console.log(e);
      }
    };

    onClickSAD = () => {
      try {
        this.props.onClickSAD();
      } catch (e) {
        console.log(e);
      }
    };

    render() {
      return (
        <SaveAsDraftPresentational
          show={this.props.show}
          isDisabled={this.state.isDisabled}
          onClickYes={this.onClickYes}
          onClickSAD={this.onClickSAD}
          shouldDisable={this.props.shouldDisable}
          onCloseModal={this.onCloseModal}
        />
      );
    }
  }
  return SaveAsDraftContainer;
})();

export default ProgramSaveAsDraftModal;
