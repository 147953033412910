import store from '../../main/store';
import {
  CLIENT_CHANGE, IDLE_LOGOUT, IDLE_LOGIN, LOGIN,
  LOGOUT, ANY, USER_SITE_ACT_PAR, USER_SITE_ACT_PAR2,
  LOGOUT_TIME, LOGIN_TIME
} from '../Constants/Modules';
import {
  SetSessionStorage, GetSessionStorage, DeleteSessionStorage, RandIntBetween
} from './BrowserUtil';
import { TryGetObjValueAlt, ObjToStr, StrToObj, TryGetNodeAlt, AreObjsEqual, ParseJwt } from './Formatter';
import { GetWebGlobal, SendBacon, HttpPost } from './HTTPUtil';
import { GetToken } from './UserSession';

import { HasValue, IsValidStr, HasProperty, IsValid } from './Validations';

export function EvaluateComponent(params) {
  let t = params;
  return t;
}

export function UserAccess(matrix, clientID, subMenu) {
  let userAccess = [];
  //check each user profile
  matrix.forEach((profile) => {
    if (profile.ClientMasterId === clientID || (profile.ProfileID === 1 /* || profile.ProfileID === 8 */)) {
      //check each access level on the user profile
      profile.AccessLevel.forEach((access) => {
        //check each submenu per access leve
        access.subMenu.forEach((item) => {
          //if submenu was available on the access level, check access level to determine if component should be enabled/disabled
          if (item.SubMenuName.toLowerCase() === subMenu.toLowerCase()) {
            switch (access.AccessLevelName) {
              case 'Read':
                userAccess.push(1);
                break;
              case 'Write':
                userAccess.push(2);
                break;
              case 'Edit':
                userAccess.push(3);
                break;
              case 'Approve/Reject':
                userAccess.push(4);
                break;
              case 'Acknowledge':
                userAccess.push(5);
                break;
              case 'Write (Download)':
                userAccess.push(6);
                break;
              default:
            }
          }
        });
      });
    }
  });
  return userAccess;
}

export function HasNoAccess(userAccess, requiredAccess) {
  //inital disabled
  let shouldDisable = true;
  for (var i = 0; i < userAccess.length; i++) {
    if (requiredAccess.indexOf(userAccess[i]) !== -1) {
      shouldDisable = false;
      break;
    }
  }

  return shouldDisable;
}

export function CheckModuleAccess(modules) {
  //inital disabled
  let access = false;
  let path = window.location.pathname.toLowerCase();
  let module = modules.filter((module) => module.ModulePath.toLowerCase() === path);
  module.map((x) => (access = x.isEnabled));
  return access;
}

export function ValidateApproverAccess(selectedProgramDetails) {
  /* 
    Get User Approver Access in Program if user is mapped to selected program as OAL or CX Lead.
  */
  let approvers = [];
  try {
    selectedProgramDetails.OperationsAccountLead.split(',').map(x => {
      if (IsValidStr(x)) {
        approvers.push(x);
      }
    });
    selectedProgramDetails.CXLLead.split(',').map(x => {
      if (IsValidStr(x)) {
        approvers.push(x);
      }
    });
  } catch (e) {

  }
  const approverEmails = [
    ...new Set(approvers),
  ];
  return approverEmails;
}

export function SetCallbackURL(url) {
  const cb = GetSessionStorage('callbackURL');
  if (!HasValue(cb) || cb.trim() === '/') SetSessionStorage('callbackURL', url);
}
export function DeleteCallbackURL() {
  DeleteSessionStorage('callbackURL');
}
export function GetCallbackURL() {
  return GetSessionStorage('callbackURL');
}

export function SetReferrer() {
  let ref = GetSessionStorage('referrer');
  let url = document.referrer;
  try {
    url = url === '' ? '' : new URL(url).hostname + '/';
  } catch { }
  const webG = String(GetWebGlobal()).toLowerCase()
  if (
    String(ref).toLowerCase() !== webG + '/'
    && (url).toLowerCase().includes(webG)
  ) {
    SetSessionStorage('referrer', webG);
    SetSessionStorage('distinationUrl', window.location.href.replace(window.location.origin, ''));
  }
}
export function GetReferrer() {
  try {
    return GetSessionStorage('referrer');
  } catch {
    return '';
  }
}
export function GetDistiURL() {
  try {
    return String(GetSessionStorage('distinationUrl'))
  } catch {
    return '';
  }
}
export function DeleteReferrer() {
  DeleteSessionStorage('referrer');
}
export function DeleteDistiURL() {
  DeleteSessionStorage('distinationUrl');
}


//LOG USER ACTIONS
export const GetShouldLogUserAct = () => {
  return String(window.webConfig.shouldLogUserAction) === 'true'
}

export function GetGDURLParams() {
  const disti = GetDistiURL().toLowerCase().replace('/dashboard/global', '')
  const params = String(disti === 'null' ? window.location.search : disti)
    .substring(1)
    .split('&')
    .map((v) => {
      const obj = v.split('=');
      return {
        key: obj[0],
        value: decodeURIComponent(obj[1]).trim(),
      };
    });

  let backUrl = '';
  if (IsValidStr(disti)) {
    backUrl = String(GetDistiURL())
      .substring(1)
      .split('&backUrl=')[1];
  }

  let urlParams = {
    preview: TryGetNodeAlt(
      params.find((e) => e.key.toLowerCase() === 'preview'),
      'null',
      'value'
    ).toLowerCase(),
    clientID: TryGetNodeAlt(
      params.find((e) => e.key.toLowerCase() === 'clientid'),
      0,
      'value'
    ),
    programID: TryGetNodeAlt(
      params.find((e) => e.key.toLowerCase() === 'programid'),
      0,
      'value'
    ),
    backBtnURL: TryGetNodeAlt(
      params.find((e) => e.key.toLowerCase() === 'backurl'),
      'https://' + GetWebGlobal(),
      'value'
    ),
  };

  urlParams.preview = urlParams.preview === 'true' ? true : urlParams.preview === 'false' ? false : null;
  urlParams.clientID = Number(urlParams.clientID) === isNaN ? 0 : Number(urlParams.clientID);
  urlParams.programID = Number(urlParams.programID) === isNaN ? 0 : Number(urlParams.programID);
  urlParams.backBtnURL = IsValidStr(backUrl) ? backUrl : urlParams.backBtnURL;

  return urlParams;
}

export const GetUserSiteActionParam = (node) => {
  let data = GetSessionStorage(USER_SITE_ACT_PAR)
  data = IsValidStr(data) ? StrToObj(data) : ''
  if (HasValue(node)) {
    data = TryGetNodeAlt(data, 0, node)
  }
  return data
}

export const SetSessionTabID = async () => {
  try {
    var token = await GetToken()
    const tabID = GetSessionStorage('SessionTabID')
    if (!HasValue(tabID) || !IsValidStr(window.name.toString())) {
      const id = `${String(ParseJwt(token).uti).substr(0, 30)}${new Date().getTime()}${RandIntBetween(1, 9999)}`
      SetSessionStorage('SessionTabID', id)
      window.name = id
    }
  } catch { }
}

export const GetSessionTabID = () => {
  let tabID = GetSessionStorage('SessionTabID')
  return HasValue(tabID) ? tabID : ''
}
export const LogUserLogInOut = async (param) => {
  if (!GetShouldLogUserAct()) return
  /**
   * login
   * new tab
   * 30 min idle - logout
   */
  const action = TryGetNodeAlt(param, '', 'action')
  const prevData = GetUserSiteActionParam()

  const body = {
    TabID: GetSessionTabID(),
    ClientProgramID: TryGetNodeAlt(prevData, 0, 'ClientProgramID'),
    ClientProjectID: TryGetNodeAlt(prevData, 0, 'ClientProjectID'),
    IsLogin: !(action === LOGOUT || action === IDLE_LOGOUT),
    StartTimeAdjustment: TryGetNodeAlt(param, 0, 'StartTimeAdjustment'),
    EndTimeAdjustment: TryGetNodeAlt(param, 0, 'EndTimeAdjustment')
  }

  const module = action === IDLE_LOGOUT || action === IDLE_LOGIN ? {
    menuID: TryGetNodeAlt(prevData, 0, 'AccessingMenuMasterID'),
    subMenuID: TryGetNodeAlt(prevData, 0, 'AccessingSubMenuMasterID')
  } : {}

  const res = await HttpPost('user/UpsertUserLogin', { ...body, ...module })
  SetSessionStorage(action === IDLE_LOGIN || action === LOGIN ? LOGIN_TIME : LOGOUT_TIME, new Date().getTime())
  if (HasProperty(res, 'UserLoginID')) SetSessionStorage(USER_SITE_ACT_PAR, ObjToStr({ ...body, ...module, UserLoginID: res.UserLoginID }))
}

export const LogUserLogOut = async (param) => {
  if (!GetShouldLogUserAct()) return
  /**
   * browser refresh
   * browser close
   * tab close 
   */

  const prevData = GetUserSiteActionParam()
  const body = {
    TabID: GetSessionTabID(),
    UserLoginID: TryGetNodeAlt(prevData, 0, 'UserLoginID'),
    UserActivityID: TryGetNodeAlt(prevData, 0, 'UserActivityID'),
    AccessingMenuMasterID: TryGetNodeAlt(prevData, 0, 'AccessingMenuMasterID'),
    AccessingSubMenuMasterID: TryGetNodeAlt(prevData, 0, 'AccessingSubMenuMasterID'),
    AccessingClientMasterID: TryGetNodeAlt(prevData, 0, 'AccessingClientMasterID'),
    ClientProgramID: TryGetNodeAlt(prevData, 0, 'ClientProgramID'),
    ClientProjectID: TryGetNodeAlt(prevData, 0, 'ClientProjectID'),
    StartTimeAdjustment: TryGetNodeAlt(param, 0, 'StartTimeAdjustment'),
    EndTimeAdjustment: TryGetNodeAlt(param, 0, 'EndTimeAdjustment'),
    IsLogout: true,
    IsLogin: false
  }

  if (body.TabID === '') return
  await SendBacon('Logout/UpsertUserActivity', body)
  SetSessionStorage(LOGOUT_TIME, new Date().getTime())
  DeleteSessionStorage(USER_SITE_ACT_PAR)
}

export const LogUserBounce = async () => {
  if (!GetShouldLogUserAct()) return
  /**
   * Login and Logout
   * 15 min idle
   *  
   */
  return HttpPost('user/AddUserBounce', GetUserSiteActionParam('UserLoginID'))
}

export const LogUserSiteAction = async (param) => {
  if (!GetShouldLogUserAct()) return
  try {
    /*
    home - module/
    module - home/    
    module - module/    
    home - non module/
    non module - home/
    non module - module/
    module - non module/
    onchange client /
    */

    //PREPARE IDS
    const state = store.getState()
    let prevData = GetUserSiteActionParam()
    const clientID = HasProperty(param, 'clientID') ? param.clientID : TryGetObjValueAlt(state, 0, 'SharedData.selectedClient.value')
    const ClientProgramID = TryGetNodeAlt(param, null, 'ClientProgramID')
    const ClientProjectID = TryGetNodeAlt(param, null, 'ClientProjectID')
    const ClientProgramStatus = TryGetNodeAlt(param, null, 'progStatus')
    const ClientProjectStatus = TryGetNodeAlt(param, null, 'projStatus')
    const UserLoginID = HasProperty(prevData, 'UserLoginID') ? prevData.UserLoginID : null

    //CALL LOGIN WHEN NO PARAMVALUES YET
    if (prevData === '' || UserLoginID === null) {
      let prevData2 = GetSessionStorage(USER_SITE_ACT_PAR2)
      prevData2 = IsValidStr(prevData2) ? StrToObj(prevData2) : {}
      const act = AreObjsEqual(param.module, ANY) && TryGetNodeAlt(prevData2, 0, 'AccessingMenuMasterID') === 0 ? LOGIN : IDLE_LOGIN

      //IF LOGIN FROM IDLE THEN USE UserSiteActionParam2/ PREVIOUS VALUES
      if (act === IDLE_LOGIN) SetSessionStorage(USER_SITE_ACT_PAR, GetSessionStorage(USER_SITE_ACT_PAR2))
      await LogUserLogInOut({ ...param, action: act })
      prevData = act === IDLE_LOGIN ? { ...prevData2, UserLoginID: GetUserSiteActionParam('UserLoginID') } : GetUserSiteActionParam()
      param.ShouldLogPPCID = TryGetNodeAlt(prevData, TryGetNodeAlt(param, false, 'ShouldLogPPCID'), 'ShouldLogPPCID')
    }

    //EXIT WHEN CHANGING CLIENT WITHOUT SELECTED SUBMODULE - e.g. FROM MASTER CONFIG THEN NAV TO OTHER MODULES
    if (param.action === CLIENT_CHANGE && TryGetNodeAlt(prevData, 0, 'AccessingMenuMasterID') === 0) return

    //SET SELECTED MODULE
    let selectedModule = {
      menuID: TryGetNodeAlt(prevData, 0, 'AccessingMenuMasterID'),
      subMenuID: TryGetNodeAlt(prevData, 0, 'AccessingSubMenuMasterID')
    }
    if (TryGetNodeAlt(param, '', 'module') !== '' && !AreObjsEqual(param.module, ANY)) {
      selectedModule = param.module
    }
    if (selectedModule.menuID === 0 || selectedModule.subMenuID === 0) {
      let param2 = GetSessionStorage(USER_SITE_ACT_PAR2)
      param2 = IsValidStr(param2) ? StrToObj(param2) : ''

      let tMenuID = TryGetNodeAlt(param2, 0, 'AccessingMenuMasterID')
      let tSubMenuID = TryGetNodeAlt(param2, 0, 'AccessingSubMenuMasterID')
      selectedModule = {
        menuID: tMenuID,
        subMenuID: tSubMenuID,
      }
    }

    let userActID = TryGetNodeAlt(prevData, 0, 'UserActivityID')
    //COMPOSE BODY
    var token = await GetToken()
    let body = {
      ...prevData,
      TabID: GetSessionTabID(),
      UserActivityID: !IsValid(userActID) ? 0 : userActID,
      AccessingMenuMasterID: selectedModule.menuID,
      AccessingSubMenuMasterID: selectedModule.subMenuID,
      AccessingClientMasterID: clientID,
      ShouldLogPPCID: clientID > 0 ? TryGetNodeAlt(param, false, 'ShouldLogPPCID') : false,
      ClientProgramID,
      ClientProjectID,
      ClientProgramStatus,
      ClientProjectStatus,
      StartTimeAdjustment: TryGetNodeAlt(param, 0, 'StartTimeAdjustment'),
      EndTimeAdjustment: TryGetNodeAlt(param, 0, 'EndTimeAdjustment'),

      Token: token,
      IsLogin: false,
      IsLogout: TryGetNodeAlt(param, 0, 'LOGOUT') === LOGOUT
    }

    const res = await HttpPost('user/upsertUserActivity', body)
    const hadDoneNav = TryGetNodeAlt(prevData, '', 'hadDoneNav') === false
    delete body.Token

    //SAVE NEW DATA TO STORAGE
    if (res) {
      SetSessionStorage(USER_SITE_ACT_PAR, ObjToStr({ ...body, hadDoneNav, UserActivityID: res.UserActivityID }))
      SetSessionStorage(USER_SITE_ACT_PAR2, ObjToStr({ ...body, hadDoneNav, UserActivityID: res.UserActivityID }))
    }
  } catch { }
}

export const SetShowTime = (t) => {
  SetSessionStorage('ShowTime', HasValue(t) ? t : new Date().getTime())
}
export const GetShowTime = () => {
  const t = parseInt(GetSessionStorage('ShowTime'))
  return t !== NaN ? parseInt((new Date().getTime() / 1000) - (t / 1000)) : 0
}

export const SetHideTime = (t) => {
  SetSessionStorage('HideTime', HasValue(t) ? t : new Date().getTime())
}
export const GetHideTime = () => {
  const t = parseInt(GetSessionStorage('HideTime'))
  return t !== NaN ? parseInt((new Date().getTime() / 1000) - (t / 1000)) : 0
}

export const LogRVDprojectAction = async (param) => {
  if (!GetShouldLogUserAct()) return
  try {
    //PREPARE IDS
    const clientMasterID = TryGetNodeAlt(param, null, 'clientMasterID')
    const clientProgramID = TryGetNodeAlt(param, null, 'clientProgramID')
    const clientProjectID = TryGetNodeAlt(param, null, 'clientProjectID')
    const clientProgramStatus = null
    const clientProjectStatus = TryGetNodeAlt(param, null, 'clientProjectStatus')
    const AccessingMenuMasterID = TryGetObjValueAlt(param, 0, 'module.menuID')
    const AccessingSubMenuMasterID = TryGetObjValueAlt(param, 0, 'module.subMenuID')
    const userDetails = GetUserSiteActionParam()
    const UserLoginID = TryGetNodeAlt(userDetails, 0, 'UserLoginID')
    const UserActivityID = TryGetNodeAlt(userDetails, 0, 'UserActivityID')

    //COMPOSE BODY
    let body = {
      UserLoginID: !IsValid(UserLoginID) ? 0 : UserLoginID,
      UserActivityID: !IsValid(UserActivityID) ? 0 : UserActivityID,
      clientMasterID,
      clientProgramID,
      clientProgramStatus,
      clientProjectID,
      clientProjectStatus,
      AccessingMenuMasterID,
      AccessingSubMenuMasterID,
      StartTimeAdjustment: 0,
      EndTimeAdjustment: 0,
      TabID: GetSessionTabID()
    }
    await HttpPost('/User/AddProgramProjectTracker', body)
  } catch { }

}

export const LogPPCUserAction = async (param) => {
  if (!GetShouldLogUserAct()) return
  try {
    const clientID = TryGetNodeAlt(param, null, 'clientID')
    const ClientProgramID = TryGetNodeAlt(param, null, 'ClientProgramID')
    const ClientProjectID = TryGetNodeAlt(param, null, 'ClientProjectID')
    const ClientProgramStatus = TryGetNodeAlt(param, null, 'progStatus')
    const ClientProjectStatus = TryGetNodeAlt(param, null, 'projStatus')
    const AccessingMenuMasterID = TryGetObjValueAlt(param, 0, 'module.menuID')
    const AccessingSubMenuMasterID = TryGetObjValueAlt(param, 0, 'module.subMenuID')

    const userDetails = GetUserSiteActionParam()
    const UserLoginID = TryGetNodeAlt(userDetails, 0, 'UserLoginID')
    const UserActivityID = TryGetNodeAlt(userDetails, 0, 'UserActivityID')

    //COMPOSE BODY
    let body =
    {
      UserLoginID: !IsValid(UserLoginID) ? 0 : UserLoginID,
      UserActivityID: !IsValid(UserActivityID) ? 0 : UserActivityID,
      clientMasterID: clientID,
      clientProgramID: ClientProgramID,
      clientProgramStatus: ClientProgramStatus,
      clientProjectID: ClientProjectID,
      clientProjectStatus: ClientProjectStatus,
      AccessingMenuMasterID,
      AccessingSubMenuMasterID,
      StartTimeAdjustment: 0,
      EndTimeAdjustment: 0,
      TabID: GetSessionTabID()
    }

    await HttpPost('user/AddProgramProjectTracker', body)
  } catch { }
}

export const LogDashboardAction = async (param) => {
  if (!GetShouldLogUserAct()) return
  try {
    const clientID = TryGetNodeAlt(param, null, 'clientID')
    const ClientProgramID = TryGetNodeAlt(param, null, 'ClientProgramID')
    const ClientProjectID = TryGetNodeAlt(param, null, 'ClientProjectID')
    const ClientProgramStatus = TryGetNodeAlt(param, null, 'progStatus')
    const ClientProjectStatus = TryGetNodeAlt(param, null, 'projStatus')
    const AccessingMenuMasterID = TryGetObjValueAlt(param, 0, 'module.menuID')
    const AccessingSubMenuMasterID = TryGetObjValueAlt(param, 0, 'module.subMenuID')

    const userDetails = GetUserSiteActionParam()
    const UserLoginID = TryGetNodeAlt(userDetails, 0, 'UserLoginID')
    const UserActivityID = TryGetNodeAlt(userDetails, 0, 'UserActivityID')

    //COMPOSE BODY
    let body =
    {
      UserLoginID: !IsValid(UserLoginID) ? 0 : UserLoginID,
      UserActivityID: !IsValid(UserActivityID) ? 0 : UserActivityID,
      clientMasterID: clientID,
      clientProgramID: ClientProgramID,
      clientProgramStatus: ClientProgramStatus,
      clientProjectID: ClientProjectID,
      clientProjectStatus: ClientProjectStatus,
      AccessingMenuMasterID,
      AccessingSubMenuMasterID,
      StartTimeAdjustment: 0,
      EndTimeAdjustment: 0,
      TabID: GetSessionTabID()
    }

    await HttpPost('user/AddProgramProjectTracker', body)
  } catch { }
}