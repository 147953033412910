import React, { Component } from 'react';
// import { history } from '../../../store';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveButton, GrooveIconSvg } from 'tfo-groove';
import DimensionAccordion from '../DimensionAccordion/DimensionAccordion';
import ProgramProjectHeader from '../ProgramProjectHeader';
import Stepper from '../Stepper';
import AddBOIPopup from './AddBOIPopup';
import SendForApproval from '../SendForApproval';
import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';

import EmptyBOI from '../EmptyBOI';
import { IsValidStr, IsValid, IsArrayEmpty, IsObjEmpty, HasValue } from '../../../Utilities/Validations';
import { formatMultiLabel } from '../../../Utilities/Formatter';
import { getCustomBOIDetails, setStepperActiveIndex, setEditApproved, isProfileExisting, setEmailError } from '../../../Actions/Shared.Action';
import { getReminderCount } from '../../../Actions/Notifcation.Action';
import CVMToasterNotif from '../../Shared/CVMToasterNotif/CVMToasterNotif';
import { GetSubMenuId, HttpPost } from "../../../Utilities/HTTPUtil";

import AddNewProgramPopup from './AddNewProgramPopup';

import {
  saveAsDraftProgramIdentified,
  sendForApprovalProgramIdentified,
  setNewProgramDetails,
  editProgram,
  setDimensionList,
  handleSendReminderProgram
} from '../../../Actions/ProgramConfiguration.Action';

// import {
//   DataEvent,
// } from '../../../Utilities/DataEvents';

import ProgramSaveAsDraftModal from './ProgramSaveAsDraftModal';
import { ACC_PROG, PROG_ID_VAL_SETT } from '../../../Constants/Modules';
import { LogUserSiteAction } from '../../../Utilities/AccessManagementUtil';
import circles from './../../../Static/images/360cvm/circlesColor.svg';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import CVMSendReminderButton from '../../Shared/CVMReminderButton/CVMSendReminderButton';

const ProgramIDValueSettings = (function () {
  let showBOIBtn;
  class ProgramIDValueSettingsPresentational extends Component {
    
    render() {
      const disabledSAD =
        Object.keys(this.props.newProgramDetails.offering).length === 0 ||
        Object.keys(this.props.newProgramDetails.cxLead).length === 0 ||
        Object.keys(this.props.newProgramDetails.progCreator).length === 0 ||
        Object.keys(this.props.newProgramDetails.oaLead).length === 0 ||
        this.props.newProgramDetails.subOffering.length === 0 ||
        this.props.newProgramDetails.validation ||
        this.props.disableSADBtn || this.props.anyInvalidEmailAvailable; //if program name already exist

      const disabledSFA = this.props.selectedBOIs.length === 0 || disabledSAD || this.props.disableSendBtn || this.props.anyInvalidEmailAvailable;
       showBOIBtn =
        (this.props.selectedProgramDetails.StageId === 1 ||
          this.props.selectedProgramDetails.StageId === undefined) &&
        !this.props.PnPComponents.program.boiButton;
      const showTransactButton =
        !this.props.PnPComponents.program.stageOnetransactBtn ||
        this.props.userInformation.isSuperAdmin;
      const enableFields =
        !this.props.PnPComponents.program.stageOneFields || this.props.userInformation.isSuperAdmin;
      const { userProfiles } = this.props.userInformation;
      const { selectedClient } = this.props.SharedData;
      let clientID = selectedClient.value;
      let isExistingPPC = userProfiles.some(
        (x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let isExistingBA = userProfiles.some(
        (x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      const hideSFA = !disabledSAD && disabledSFA
      const statusValue = ['awaiting approval', 'awaiting approval – modified', 'configured - yet to be aligned']
      const isReminderSenderView = this.props.userInformation.isSuperAdmin || isExistingPPC || isExistingBA;
      return (
        <React.Fragment>
          <div className="BT-Lead-Landing-Page">
            <ProgramProjectHeader type="Program" />
            <CVMToasterNotif />
            <Stepper type="Program" />
            {this.props.PnPComponents.program.idValueSetting ? (
              <Row className="access_denied_container">
                <Col md={12} xs={12}>
                  <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                  <div className="access_denied_content">
                    <h1>Access Denied</h1>
                    <p>
                      You don't have the necessary user privileges to view the page that you are
                      attempting to access.
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              <React.Fragment>
                <Accordion className="configure-client-collapse" defaultActiveKey="0">
                  <Card>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <AddNewProgramPopup
                          readOnly={!enableFields}
                          editMode={this.props.selectedProgramDetails.ClientProgramID}
                          onSaveAddBOI={this.props.onSaveAddBOI}
                          selectedBOIs={this.props.selectedBOIs}
                          editProgram={this.props.editProgram}
                          disableSendBtn={this.props.disableSendBtn}
                          isValidMappedFields={this.props.isValidMappedFields}
                          anyInvalidEmailAvailable={this.props.anyInvalidEmailAvailable}
                          setAnyInvalidEmailAvailable={this.props.setAnyInvalidEmailAvailable}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                    <Card.Header onClick={this.props.accordionHeaderClick}>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <div className="bodytext18-medium-midnight">Basic Details</div>
                        <GrooveIconSvg
                          customClassName="accordion-button"
                          size="large"
                          name="chevron-down"
                          iconStyle="solid"
                          iconColor="stat-neutral"
                        />
                      </Accordion.Toggle>
                    </Card.Header>
                  </Card>
                </Accordion>
                <Accordion
                  className="configure-client-collapse idValue-setting-add-boi"
                  defaultActiveKey={IsValid(this.props.selectedProgramDetails.Status) ? '0' : '1'}
                >
                  <Card>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="BT-Lead-Landing-btn">
                          <div className="applyFlex-space-between">
                            {showBOIBtn ? (
                              <AddBOIPopup
                                type={this.props.selectedBOIs.length !== 0 ? 'Edit' : 'Add'}
                                onSaveAddBOI={this.props.onSaveAddBOI}
                                selectedBOIs={this.props.selectedBOIs}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {this.props.selectedBOIs.length !== 0 ? (
                          <DimensionAccordion
                            type={'Program'}
                            customBOI={this.props.SharedData.customBOI}
                            ProgramConfigurationData={this.props.ProgramConfigurationData}
                            dimensionList={this.props.selectedBOIs}
                            formCallback={this.props.dimFormCallback}
                            origin={'addProgramBOI'}
                            mode={
                              !enableFields
                                ? 'view'
                                : this.props.selectedProgramDetails.StageId === 1 ||
                                  this.props.selectedProgramDetails.StageId === undefined
                                  ? 'edit'
                                  : 'view'
                            }
                          />
                        ) : (
                          <EmptyBOI type="program" />
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                    <Card.Header onClick={this.props.accordionHeaderClick}>
                      {
                        isReminderSenderView ?
                          (!IsArrayEmpty(this.props.selectedProgramDetails) && statusValue.indexOf(this.props.selectedProgramDetails.Status.toLowerCase()) !== -1) ?
                            (<span className="applyFlex-space-between main-reminder-button">
                              <CVMSendReminderButton tooltipText={statusValue.indexOf(this.props.selectedProgramDetails.Status.toLowerCase()) === 2 ? "Send reminder for uploading client alignment" : "Send reminder for program approval"} onSendReminder={() => this.props.onSendReminder()} isDisabledReminderButton={this.props.isDisabledReminderButton} />
                            </span>)
                            :
                            ('')
                          : ""
                      }
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <div className='edit-boi-link-container'>
                          <div className="bodytext18-medium-midnight">Target Value Settings</div>
                          {this.props.selectedProgramDetails.Status === 'In Progress' ||
                            !IsValid(this.props.selectedProgramDetails.Status)
                            || !showTransactButton ? (
                            ''
                          ) : (
                            <div className="edit-boi-link">
                              <img src={circles} alt="Edit BOIs" className="edit-boi-link-icon" />{' '}
                              Go to{' '}
                              <span
                                className="edit-boi-link-span"
                                onClick={() => this.props.onClickEditBOIs(true)}
                              >
                                Edit BOIs{` `}
                              </span>
                              section
                              <span className="edit-boi-link-divider">|</span>
                            </div>
                          )}
                        </div>
                        <span className="applyFlex-space-between">
                          <GrooveIconSvg
                            customClassName="accordion-button"
                            size="large"
                            name="chevron-down"
                            iconStyle="solid"
                            iconColor="stat-neutral"
                          />
                        </span>
                      </Accordion.Toggle>
                    </Card.Header>
                  </Card>
                </Accordion>
                <div
                  id='track-scroll'
                >
                  <span className="BT-Lead-Landing-bottom-btn">
                    {showTransactButton ? (
                      this.props.selectedProgramDetails.StageId === 1 ||
                        !IsValid(this.props.selectedProgramDetails.StageId) ? (
                        <React.Fragment>
                          <span className="applyFlex-space-between">
                            <ProgramSaveAsDraftModal
                              onClickSAD={
                                !IsValid(this.props.selectedProgramDetails.StageId)
                                  ? this.props.onShowSADModal
                                  : this.props.onClickSAD
                              }
                              onCloseModal={this.props.onShowSADModal}
                              show={this.props.showSADModal}
                              onClickYes={this.props.onClickSAD}
                              shouldDisable={disabledSAD}
                            />
                          </span>
                          <span className='applyFlex-space-between'>
                            <SendForApproval
                              preSFA={() => {
                                return true;
                              }}
                              onClickSFA={this.props.onClickSFA}
                              onCloseModal={this.props.onClickSFA}
                              show={this.props.showSFAModal}
                              onClickSend={this.props.onClickSend}
                              onChangeApprover={this.props.onChangeApprover}
                              size="md"
                              approverList={this.props.approverList}
                              defaultData={this.props.selectedApprovers}
                              shouldDisable={this.props.disableSADBtn ? false : disabledSFA}
                              type={'program'}
                              shouldShowDeletedReason={false}
                              disableSendBtn={this.props.disableSADBtn ? false : disabledSFA}
                            />
                          </span>
                        </React.Fragment>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </div>
                <div
                  className={
                    this.props.positionFixed ?
                      (showTransactButton ? (!disabledSAD &&
                        (this.props.selectedProgramDetails.StageId === 1 ||
                          !IsValid(this.props.selectedProgramDetails.StageId))) : '')
                        ? 'sticky-button' : 'sticky-button hide-sticky '
                      : 'sticky-button hide-sticky'
                  }
                >
                  <span className="BT-Lead-Landing-bottom-btn">
                    {showTransactButton ? (
                      this.props.selectedProgramDetails.StageId === 1 ||
                        !IsValid(this.props.selectedProgramDetails.StageId) ? (
                        <React.Fragment>
                          <span className="applyFlex-space-between">
                            <ProgramSaveAsDraftModal
                              onClickSAD={
                                !IsValid(this.props.selectedProgramDetails.StageId)
                                  ? this.props.onShowSADModal
                                  : this.props.onClickSAD
                              }
                              onCloseModal={this.props.onShowSADModal}
                              show={this.props.showSADModal}
                              onClickYes={this.props.onClickSAD}
                              shouldDisable={disabledSAD}
                            />
                          </span>
                          <span className={`applyFlex-space-between ${this.props.disableSADBtn ? false : disabledSFA ? 'hide-sfa' : ''}`}>
                            <SendForApproval
                              preSFA={() => {
                                return true;
                              }}
                              onClickSFA={this.props.onClickSFA}
                              onCloseModal={this.props.onClickSFA}
                              show={this.props.showSFAModal}
                              onClickSend={this.props.onClickSend}
                              onChangeApprover={this.props.onChangeApprover}
                              size="md"
                              approverList={this.props.approverList}
                              defaultData={this.props.selectedApprovers}
                              shouldDisable={this.props.disableSADBtn ? false : disabledSFA}
                              type={'program'}
                              shouldShowDeletedReason={false}
                              disableSendBtn={this.props.disableSADBtn ? false : disabledSFA}
                            />
                          </span>
                        </React.Fragment>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
          <CVMModal
            title={''}
            customclass={'notifyModal-dialog'}
            show={this.props.showConfirmEditBOIs}
            onCloseModal={() => this.props.onClickEditBOIs(false)}
            content={
              <CVMWarningModal
                body={
                  'Are you sure you want to go to Edit BOIs section? Please note that this will not save your changes.'
                }
                handleCancel={() => this.props.onClickEditBOIs(false)}
                handleYes={() => this.props.handleConfirmEditBOIs()}
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class ProgramIDValueSettingsContainer extends Component {
    constructor() {
      super();
      this.state = {
        showLoader: true,
        selectedBOIs: [],
        showSFAModal: false,
        showSADModal: false,
        disableSADBtn: false,
        disableSendBtn: false,
        showConfirmEditBOIs: false,
        selectedApprovers: [],
        approverList: [],
        isDisabledReminderButton: true,
        positionFixed: false,
        isValidMappedFields: {
          progCreator: true,
          cxl: true,
          oal: true,
          mappedFieldErrMsgs: []
        },
        anyInvalidEmailAvailable: false,
      };
    }

    setAnyInvalidEmailAvailable = (value) => {
      this.setState({
        anyInvalidEmailAvailable: value,
      })
    }

    accordionHeaderClick = () => {
      setTimeout(this.listenToScroll, 300);
    };

    listenToScroll = () => {
      let trackScroll = document.getElementById('track-scroll');
      let var1 = trackScroll ? document.getElementById('root').clientHeight - trackScroll.offsetTop - 50 : 0;
      let var2 =
        document.getElementById('root').clientHeight -
        document.documentElement.scrollTop -
        window.innerHeight;

      if (var2 > var1) {
        this.setState({
          positionFixed: true,
        });
      } else {
        this.setState({
          positionFixed: false,
        });
      }
    };

    componentDidMount = async () => {
      this.setState({ showLoader: true });
      //Code for 5000msec loading times
      this.timer = setTimeout(() => {
        this.setState({ showLoader: false });
      }, 5000);
      window.scrollTo(0, 0)
      this.listenToScroll();
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      let clientDetails = this.props.ProgramConfigurationData.programClientDetails;
      const statusValue = ['awaiting approval', 'awaiting approval – modified', 'configured - yet to be aligned']
      LogUserSiteAction({
        module: PROG_ID_VAL_SETT,
        ShouldLogPPCID: true,
        ClientProgramID: programDetails.ClientProgramID,
        ClientProjectID: null,
        progStatus: ACC_PROG
      })

      if (programDetails.ClientProgramID) {
        let subOff = [];
        let oal = [];
        let cxl = [];

        programDetails.OperationsAccountLead.split(',').map(x => {
          if (IsValid(x)) {
            oal.push({
              label: x,
              text: x,
              value: x,
            });
          }
        });

        programDetails.CXLLead.split(',').map(x => {
          if (IsValid(x)) {
            cxl.push({
              label: x,
              text: x,
              value: x,
            });
          }
        });

        programDetails.SubOfferingList.map((item) => {
          subOff.push({
            label: item.SubOfferingName,
            value: item.SubOfferingID,
            isFixed: programDetails.Status === 'In Progress' ? false : true,
          });
        });
        newProgramDetails.offering = {
          label: programDetails.Offering,
          text: programDetails.Offering,
          value: programDetails.OfferingID,
        };
        newProgramDetails.subOffering = subOff;
        newProgramDetails.programName = programDetails.ClientProgramName;
        newProgramDetails.cxLead = cxl;
        if (IsValid(programDetails.ProgramCreator)) {
          newProgramDetails.progCreator = {
            label: programDetails.ProgramCreator,
            text: programDetails.ProgramCreator,
            value: programDetails.ProgramCreator,
          };
        } else {
          let isPPCBA = await this.props.isProfileExisting([12, 15]);
          let userInfo = this.props.userInformation;
          // For existing Programs with deleted Program Creator, if current user is PPC/BA, then auto select as Program Creator
          if (isPPCBA && !userInfo.isSuperAdmin) {
            newProgramDetails.progCreator = {
              label: userInfo.emailAddress,
              text: userInfo.emailAddress,
              value: userInfo.emailAddress,
            };
          } else {
            newProgramDetails.progCreator = {};
          }
        }
        newProgramDetails.oaLead = oal;

        this.props.setNewProgramDetails(newProgramDetails);
        this.formatBOIs(this.props.selectedBOIs)
        this.initApproverDropdowns();
        this.validateMappedFields();
        this.getDisclaimerDetails()
      } else {
        let isPPCBA = await this.props.isProfileExisting([12, 15]);
        let userInfo = this.props.userInformation;

        // For new Programs, and user is PPC/BA, auto select as Program Creator
        if (isPPCBA && !userInfo.isSuperAdmin) {
          newProgramDetails.progCreator = {
            label: userInfo.emailAddress,
            text: userInfo.emailAddress,
            value: userInfo.emailAddress,
          };
          this.props.setNewProgramDetails(newProgramDetails);
        }
      }

      if (!IsValidStr(programDetails.ActionOwner)) {
        this.setState({
          isDisabledReminderButton: true
        })
      }
      if (HasValue(programDetails.Status)) {
        if (statusValue.indexOf(programDetails.Status.toLowerCase()) !== -1) {
          const notifTypeID = statusValue.indexOf(programDetails.Status.toLowerCase()) === 2 ? 19 : 13;
          this.setState({
            isDisabledReminderButton: true
          });
          let counterReminder = await this.props.getReminderCount({ ...clientDetails, ...programDetails, notifTypeID }, "Program")
          if (counterReminder >= 2) {
            this.setState({
              isDisabledReminderButton: true
            })
          }
          else {
            this.setState({
              isDisabledReminderButton: false
            });
          }
        }
      }
    };


    componentWillUnmount() {
      // Clear timer if component unmounts
      clearTimeout(this.timer);
    }

    componentDidUpdate = (nextProps) => {
      window.addEventListener('scroll', this.listenToScroll);
      try {
        let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
        let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
        if (
          nextProps.ProgramConfigurationData.dimensionList !==
          this.props.ProgramConfigurationData.dimensionList ||
          (this.state.selectedBOIs !== this.props.ProgramConfigurationData.dimensionList &&
            this.props.ProgramConfigurationData.dimensionList.length !== 0)
        ) {
          this.setState({
            selectedBOIs: this.props.ProgramConfigurationData.dimensionList,
          });
        }
        if (this.props.ProgramConfigurationData.dimensionList !== nextProps.ProgramConfigurationData.dimensionList) {
          this.formatBOIs();
        }
        if (this.props.ProgramConfigurationData.newProgramDetails.cxLead !== nextProps.ProgramConfigurationData.newProgramDetails.cxLead
          || this.props.ProgramConfigurationData.newProgramDetails.oaLead !== nextProps.ProgramConfigurationData.newProgramDetails.oaLead) {
          this.initApproverDropdowns();
        }

        if (
          Object.keys(newProgramDetails.offering).length === 0 &&
          programDetails.ClientProgramID
        ) {
          if (programDetails.ClientProgramID) {
            let subOff = [];
            let oal = [];
            let cxl = [];

            programDetails.OperationsAccountLead.split(',').map(x => {
              oal.push({
                label: x,
                text: x,
                value: x
              });
            });

            programDetails.CXLLead.split(',').map(x => {
              cxl.push({
                label: x,
                text: x,
                value: x
              });
            });

            programDetails.SubOfferingList.map((item) => {
              subOff.push({
                label: item.SubOfferingName,
                value: item.SubOfferingID,
              });
            });
            newProgramDetails.offering = {
              label: programDetails.Offering,
              text: programDetails.Offering,
              value: programDetails.OfferingID,
            };
            newProgramDetails.subOffering = subOff;
            newProgramDetails.programName = programDetails.ClientProgramName;
            newProgramDetails.cxLead = cxl;
            newProgramDetails.progCreator = {
              label: programDetails.ProgramCreator,
              text: programDetails.ProgramCreator,
              value: programDetails.ProgramCreator,
            };
            newProgramDetails.oaLead = oal;

            this.props.setNewProgramDetails(newProgramDetails);
            this.initApproverDropdowns();
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    onSelectOption = (e) => {
      this.setState({
        value: e,
      });
    };
    onClickXButton = () => {
      this.props.history.push('/ProgramProjectConfig');
    };

    prepBOIForSaving = () => {
      let list = this.state.selectedBOIs;
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      let bois = [];
      list.map((dim) => {
        dim.subDimensionList.map((subdim) => {
          subdim.boiList.map((boi) => {
            bois.push({
              clientProgramID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
              clientProgramTargetID: 0,
              coreDimensionID: dim.dimensionID,
              subDimensionID: subdim.subDimensionID,
              boiid: boi.boiID,
              boiName: boi.boiName,
              boiTarget: boi.paramValue === '' ? '' : boi.paramValue, //"" ? "" : paramValue
              uom: boi.uom,
              boiStatus: boi.boiStatus,
              boiReason: '',
              boiDeletionReason: '',
              approver: '',
              rejectionRecipient: '',
              isActive: true,
              isCustom: boi.IsCustom,
              isCustomSubDimension: subdim.subDimensionID == 0 ? true : false,
              boiDescription: boi.boiDescription,
              offeringID:
                newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
              offeringName: dim.offeringName,
              subOfferingID: boi.subofferingID,
              subOfferingName: dim.subofferingName,
              subDimensionName:
                subdim.subDimensionName === 'Add new Subdimension'
                  ? boi.newSubDimensionName
                  : subdim.subDimensionName,
              boiDefinitionMasterID:
                boi.boiDefinitionMasterID === '' ? 0 : boi.boiDefinitionMasterID,
              aggregationLogicMasterID:
                boi.aggregationLogicMasterID === '' ? 0 : boi.aggregationLogicMasterID,
              leadingIndicator: boi.leadingIndicator,
              boiAdditionComment: boi.boiAdditionComment,
              reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
              reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
              noOfParameter: boi.noOfParameter === '' ? 0 : boi.noOfParameter,
              loggedInUserName: '',
              metricSQL: '',
              projectAggregationSQL: '',
              programAggregationSQL: '',
              accountAggregationSQL: '',
              boiMappingID: boi.boiMappingID ? boi.boiMappingID : 0,
              metricDefinitionID: boi.metricDefinitionID ? boi.metricDefinitionID : 0,
              isDeletedCustomBOI: boi.isDeleted,
              isDuplicateSubDimensionName: false,
              customParameterList: boi.customParameterList,
              wheelVisibility:
                boi.boiWheelVisibility || boi.boiWheelVisibility === undefined ? 1 : 0,
            });
          });
        });
      });
      return bois;
    };

    prepDataToSave = async (comment) => {
      let clientDetails = this.props.ProgramConfigurationData.programClientDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let userInfo = this.props.userInformation;

      let subOfferingList = this.props.ProgramConfigurationData.newProgramDetails.subOffering;
      let subOfferingListTemp = [];
      subOfferingList.map((item) => {
        subOfferingListTemp.push({
          clientProgramID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
          subOfferingID: item.value,
          isActive: true,
          createdUser: userInfo.enterpriseID,
          updatedUser: userInfo.enterpriseID,
        });
      });

      let isPPCBA = await this.props.isProfileExisting([12, 15])
      let configuratorsList = this.props.ProgramConfigurationData.ConfiguratorsList;
      let progCreator = userInfo.isSuperAdmin || isPPCBA
        ? newProgramDetails.progCreator.label
        : userInfo.emailAddress;
      let selectedApprovers = this.state.selectedApprovers;
      const approverEmails = [
        ...new Set(
          selectedApprovers.map((item) => {
            return item.value;
          })
        ),
      ];

      let approvers = '';
      newProgramDetails.cxLead.map((x) => {
        approvers += x.label + ',' + '7;';
      });

      newProgramDetails.oaLead.map((x) => {
        approvers += x.label + ',' + '4;';
      });
      const programActionOwner = IsValidStr(programDetails.ActionOwner) && programDetails.ActionOwner.split(",")

      let data = {
        programID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
        loggedInUserName: this.props.userInformation.enterpriseID,
        comments: comment,
        operationsAccountLead: newProgramDetails.oaLead.label,
        isResend: false,
        clientBasicDetailsID: clientDetails.ClientBasicDetailID,
        offeringID: newProgramDetails.offering.value,
        clientOfferingID: programDetails.ClientOfferingID,
        clientProgramName: newProgramDetails.programName,
        reportingYear: 0,
        programCreator: progCreator,
        programCreatorProfileId: configuratorsList.filter(x => x.label === progCreator)[0].id,
        cxlLead: newProgramDetails.cxLead.label,
        // "cxlLeadProfileId": newProgramDetails.cxLead.value,
        clientAdmin: clientDetails.ClientAdmin,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
        clientProgramSubOfferingList: subOfferingListTemp,
        clientProgramTargetList: this.prepBOIForSaving(),
        allOfferings: this.props.ProgramConfigurationData.isCheckedAllOfferings,
        mailTo: approverEmails.join(";") || (!IsArrayEmpty(programActionOwner) && (Array.isArray(programActionOwner) ? programActionOwner : []).join(";")),
        cxl_OAL_List: approvers.toString()
      };
      return data;
    };

    onShowSADModal = () => {
      this.setState({ showSADModal: !this.state.showSADModal });
    };

    onClickSAD = async () => {
      try {
        this.setState({
          disableSADBtn: true,
        }, async () => {
          let data = await this.prepDataToSave('');
          await this.props.saveAsDraftProgramIdentified(data);
          this.setState({
            disableSADBtn: false,
          }, () => {
            if (this.state.showSADModal) {
              this.onShowSADModal();
            }
          });
        })
      }
      catch (e) {
        console.log(e);
      }
    };

    onClickSend = async (comment) => {
      let data = await this.prepDataToSave(comment);
      this.setState({
        disableSendBtn: true,
      }, async () => {
        await this.props.sendForApprovalProgramIdentified(data);
        //DataEvent("programCreate", this.props.SharedData.selectedClient.text);
        this.setState({
          disableSendBtn: false
        })
      })

    };

    initApproverDropdowns = async () => {
      try {
        let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
        let oalList = newProgramDetails.oaLead;
        let cxlList = newProgramDetails.cxLead;

        let oal = [];
        let cxl = [];
        // process data if obj or array to get email
        if (!Array.isArray(oalList)) {
          if (IsValidStr(oalList.value)) {
            oal.push(oalList.value)
          }
        } else {
          oalList.forEach(x => {
            if (IsValidStr(x.value)) {
              oal.push(x.value);
            }
          });
        }
        if (!Array.isArray(cxlList)) {
          if (IsValidStr(cxlList.value)) {
            cxl.push(cxlList.value)
          }
        } else {
          cxlList.forEach(x => {
            if (IsValidStr(x.value)) {
              cxl.push(x.value);
            }
          });
        }
        const approverEmails = [
          ...new Set([...oal, ...cxl]),
        ];

        let approverList = [];
        approverEmails.forEach((approver) => {
          approverList.push({
            label: approver,
            text: approver,
            value: approver
          })
        })
        this.setState({
          approverList: approverList
        })
      } catch (e) {

      }
    }
    onChangeApprover = async (selectedApprovers) => {
      this.setState({
        selectedApprovers: selectedApprovers,
      })
    };
    onClickSFA = () => {
      if (this.state.showSFAModal) {
        this.props.setEmailError({
          show: false,
          message: ""
        })
      }
      this.initApproverDropdowns();
      this.setState({
        showSFAModal: !this.state.showSFAModal,
      });
    };

    onSaveAddBOI = (bois) => {
      try {
        this.setState(
          {
            selectedBOIs: bois,
          },
          () => {
            if (document.getElementById('expandAll')) {
              document.getElementById('expandAll').click();
            }
          }
        );
        if(showBOIBtn){
        this.props.setDimensionList(bois); //edit
      }
      } catch (e) {
        console.log(e);
      }
    };

    dimFormCallback = (dim) => {
      try {
        this.setState({
          selectedBOIs: dim,
        });
      } catch (e) {
        console.log(e);
      }
    };

    validateDimension = () => {
      const dim = this.state.dimensionList;
      let isValid = true;
      dim.forEach((e) => {
        e.subDimensionList.forEach((e2) => {
          e2.boiList.forEach((e3) => {
            if (!IsValidStr(e3.paramValue.trim())) e3['inValidParam'] = true;
            if (!IsValidStr(e3.comment.trim())) e3['inValidComment'] = true;
          });
        });
      });
      return isValid;
    };

    editProgram = async () => {
      try {
        this.setState({
          disableSendBtn: true
        }, async () => {
          let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
          let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
          let userInfo = this.props.userInformation;

          let clientProgramSubOfferingList = [];
          let isPPCBA = await this.props.isProfileExisting([12, 15])
          const isProgramApprover = (newProgramDetails.oaLead.some((a) => a.text === this.props.userInformation.emailAddress) || //oal
            newProgramDetails.cxLead.some((a) => a.text === this.props.userInformation.emailAddress)) && //cxl
            !this.props.userInformation.isSuperAdmin;
          newProgramDetails.subOffering.forEach((val) => {
            let subOfferingHolder = {
              clientProgramID: programDetails.ClientProgramID,
              subOfferingID: val.value,
              isActive: true,
              createdUser: programDetails.CreatedUser,
              updatedUser: userInfo.userName,
            };

            clientProgramSubOfferingList.push(subOfferingHolder);
          });

          let approvers = '';
          newProgramDetails.cxLead.map(x => {
            approvers += x.label + "," + '7;';
          });

          newProgramDetails.oaLead.map(x => {
            approvers += x.label + "," + '4;';
          });

          let data = {
            clientOfferingID: programDetails.ClientOfferingID,
            programID: this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
            programCreator: userInfo.isSuperAdmin || isPPCBA || isProgramApprover
              ? newProgramDetails.progCreator.label
              : userInfo.emailAddress,
            UpdatedUser: this.props.userInformation.enterpriseID,
            clientProgramSubOfferingList: clientProgramSubOfferingList,
            cxl_OAL_List: approvers,
            CreatedUser: this.props.userInformation.enterpriseID,
          };
          await this.props.editProgram(data);
         // DataEvent("programUpdate", this.props.SharedData.selectedClient.text)
          this.setState({
            disableSendBtn: false,
          });
        }
        );
      } catch (e) {
        console.log(e);
      }
    };

    formatBOIs = () => {
      let tempBois = this.props.ProgramConfigurationData.dimensionList;
      //sort boi
      tempBois.map((dim) => {
        dim.subDimensionList.map((subdim, index) => {
          let tempFilterList = [];
          let tempCustomList = [];
          let sortedBoiList = [];
          let customBoiList = [];
          let filteredBoiList = [];
          // subdim.boiList.sort(function(a,b){
          //   return a.boiParentID - b.boiParentID
          // })

          //stores custom boi list
          customBoiList = subdim.boiList.filter((e) => e.boiParentID === 2);
          customBoiList.forEach((e) => {
            tempCustomList.push(e);
          });

          //removes custom from boilist and sorts boi parent id
          filteredBoiList = subdim.boiList.filter((e) => e.boiParentID !== 2);
          filteredBoiList.sort((a, b) => a.boiParentID - b.boiParentID);
          filteredBoiList.forEach((e) => {
            tempFilterList.push(e);
          });

          sortedBoiList.push(...tempFilterList, ...tempCustomList);

          subdim.boiList = sortedBoiList;
        });
      });
      this.setState(
        {
          selectedBOIs: tempBois,
        });
    }

    onClickEditBOIs = (e) => {
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      let newProgramDetails = this.props.ProgramConfigurationData.newProgramDetails;
      let subOff = [];
      let OALList = '';
      let CXLList = '';
      programDetails.SubOfferingList.map((item) => {
        subOff.push({
          label: item.SubOfferingName,
          value: item.SubOfferingID,
          isFixed: programDetails.Status === 'In Progress' ? false : true,
        });
      });
      newProgramDetails.cxLead.map((x) => (CXLList = CXLList + x.text + ','));
      newProgramDetails.oaLead.map((x) => (OALList = OALList + x.text + ','));
      let isProgDetailsEdit =
        programDetails.ProgramCreator !== newProgramDetails.progCreator.label ||
        JSON.stringify(subOff) !== JSON.stringify(newProgramDetails.subOffering) ||
        CXLList.slice(0, -1) !== programDetails.CXLLead ||
        OALList.slice(0, -1) !== programDetails.OperationsAccountLead;

      if (isProgDetailsEdit) this.setState({ showConfirmEditBOIs: e });
      else this.handleConfirmEditBOIs();
    };

    handleConfirmEditBOIs = () => {
      let programDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
      this.props.setStepperActiveIndex(2);
      if (programDetails.StatusId === 7 || programDetails.StatusId === 8 || programDetails.StatusId === 9) {
        this.props.history.push(
          `/ProgramProjectConfig/ProgramAccountLeadershipApproval/approvedboi`
        );
      } else if (programDetails.StatusId === 4) {
        this.props.history.push(
          `/ProgramProjectConfig/ProgramAccountLeadershipApproval/rejectedboi`
        );
      } else {
        this.props.history.push(
          `/ProgramProjectConfig/ProgramAccountLeadershipApproval/awaitingappboi`
        );
      }
      this.props.setEditApproved(true);
      this.setState({ showConfirmEditBOIs: false });
    };

    onSendReminder = async () => {
      try {
        this.setState({
          isDisabledReminderButton: true
        }, async () => {
          let body = await this.prepDataToSave("");
          let userInfo = this.props.userInformation;
          let params = {
            view: "Program",
            message: "Reminder notification is sent for the approver successfully!",
            user: userInfo.emailAddress,
            body: body,
          };
          await this.props.handleSendReminderProgram(params)
        })

      } catch (error) {
        console.log(error);
      }
    }

    validateMappedFields = () => {
      try {
        let {
          CXLeadList,
          ConfiguratorsList,
          OALeadList,
          newProgramDetails,
        } = this.props.ProgramConfigurationData;

        if (!IsObjEmpty(newProgramDetails)) {
          let selectedProgCreator = newProgramDetails.progCreator;
          let selectedCXLead = newProgramDetails.cxLead;
          let selectedOALead = newProgramDetails.oaLead;
          let tempIsValidFields = {
            progCreator: true,
            cxl: true,
            oal: true,
          }
          let invalidProg = [];
          let invalidApprovers = [];
          let mappedFieldErrMsgs = [];
          // If no Program Creator is mapped to the program and there are options available
          if (IsObjEmpty(selectedProgCreator) && IsArrayEmpty(ConfiguratorsList)) {
            tempIsValidFields.progCreator = false;
            invalidProg.push("Program Creator");
          } else {
            if (!IsValid(selectedProgCreator.value) && IsArrayEmpty(ConfiguratorsList)) {
              tempIsValidFields.progCreator = false;
              invalidProg.push("Program Creator");
            } /* else if (selectedProgCreator.value !== '' && ConfiguratorsList.length > 0) {
              let tempProgCreator = selectedProgCreator.value;
              // Check if mapped Program Creator is available in options
              tempIsValidFields.progCreator = ConfiguratorsList.filter((configurator) => configurator.value === tempProgCreator).length > 0
              if (!tempIsValidFields.progCreator) {
                invalidProg.push("Program Creator");
              }
            } */
          }
          /* Check if there are no mapped CX lead/OAL and if not single option 
          since if there is only one option it will be auto selected as per current implementation */
          if (selectedCXLead.length === 0 && IsArrayEmpty(CXLeadList)) {
            tempIsValidFields.cxl = false;
            invalidApprovers.push("CX Lead");
          }
          if (selectedOALead.length === 0 && IsArrayEmpty(OALeadList)) {
            tempIsValidFields.oal = false;
            invalidApprovers.push("Operations Account Lead");
          }

          // Format Error Messages
          if (invalidProg.length > 0) {
            let creatorInvalidMsg = 'No Program Creator is mapped for the program. Please reach out to SVM admin.';
            mappedFieldErrMsgs.push(creatorInvalidMsg);
          }
          if (invalidApprovers.length > 0) {
            let tmpRoles = formatMultiLabel(invalidApprovers);
            let approverInvalidMsg = 'No ' + tmpRoles + ' is mapped for the program. Please add a user by clicking Add button in '
              + tmpRoles + ' field.';
            mappedFieldErrMsgs.push(approverInvalidMsg);
          }

          this.setState({
            isValidMappedFields: {
              progCreator: tempIsValidFields.progCreator,
              cxl: tempIsValidFields.cxl,
              oal: tempIsValidFields.oal,
              mappedFieldErrMsgs: mappedFieldErrMsgs
            }
          })
        }
      } catch (e) {

      }

    }

    getDisclaimerDetails = async () => {
      try {
        let selectedProgramDetails = this.props.ProgramConfigurationData.selectedProgramDetails;
        let enterpriseID = this.props.userInformation.enterpriseID

        if (!IsObjEmpty(selectedProgramDetails)) {
          let body = {
            ClientProgramID: selectedProgramDetails.ClientProgramID,
            ClientProjectID: 0,
            IsPDE: false,
            Username: enterpriseID,
            OfferingID: selectedProgramDetails.OfferingID,
            SubOfferingID: null,
          };
          let subMenuId = GetSubMenuId("Program Identified Value Settings");
          let data = await HttpPost(`User/GetDisclaimerDetails?SubMenuID=${subMenuId}`, body);
          if (data.status === undefined && !IsObjEmpty(data)) {
            if (data.DisclaimerFlag) {
              let isValidMappedFields = this.state.isValidMappedFields;
              let mappedFieldErrMsgs = isValidMappedFields.mappedFieldErrMsgs;
              let invalidApprovers = IsValid(data.Roles) ? data.Roles : [];
              // Remove updated disclaimer for PPC
              invalidApprovers = invalidApprovers.filter(role => { return role !== 'Program and Project Configurator' })
              if (invalidApprovers.length > 0) {
                let tmpRoles = formatMultiLabel(invalidApprovers);
                let approverInvalidMsg = 'Please note that ' + tmpRoles + '  is updated for the program.';
                mappedFieldErrMsgs.push(approverInvalidMsg);
              }

              this.setState({
                isValidMappedFields: {
                  ...isValidMappedFields,
                  mappedFieldErrMsgs: mappedFieldErrMsgs
                }
              })
            }
          }
        }
      } catch (e) {

      }
    }

    render() {
      const { showLoader } = this.state;
      const { programClientDetails, selectedProgramDetails, newProgramDetails } =
        this.props.ProgramConfigurationData;
      const { PnPComponents } = this.props.AccessValidationData;
      const { userInformation } = this.props;
      return (
        <div>
        {showLoader?
          <div className="comment-loader">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
          </div>:        
        <ProgramIDValueSettingsPresentational
          onSaveAddBOI={this.onSaveAddBOI}
          onClickXButton={this.onClickXButton}
          onClickSAD={this.onClickSAD}
          onClickSend={this.onClickSend}
          editProgram={this.editProgram}
          onChangeApprover={this.onChangeApprover}
          selectedApprovers={this.state.selectedApprovers}
          approverList={this.state.approverList}
          selectedBOIs={this.state.selectedBOIs}
          dimFormCallback={this.dimFormCallback}
          programClientDetails={programClientDetails}
          selectedProgramDetails={selectedProgramDetails}
          newProgramDetails={newProgramDetails}
          PnPComponents={PnPComponents}
          onClickSFA={this.onClickSFA}
          showSFAModal={this.state.showSFAModal}
          userInformation={userInformation}
          SharedData={this.props.SharedData}
          ProgramConfigurationData={this.props.ProgramConfigurationData}
          showSADModal={this.state.showSADModal}
          onShowSADModal={this.onShowSADModal}
          disableSendBtn={this.state.disableSendBtn}
          disableSADBtn={this.state.disableSADBtn}
          onClickEditBOIs={this.onClickEditBOIs}
          handleConfirmEditBOIs={this.handleConfirmEditBOIs}
          showConfirmEditBOIs={this.state.showConfirmEditBOIs}
          onSendReminder={this.onSendReminder}
          isDisabledReminderButton={this.state.isDisabledReminderButton}
          positionFixed={this.state.positionFixed}
          accordionHeaderClick={this.accordionHeaderClick}
          isValidMappedFields={this.state.isValidMappedFields}
          anyInvalidEmailAvailable={this.state.anyInvalidEmailAvailable}
          setAnyInvalidEmailAvailable={this.setAnyInvalidEmailAvailable}
        />}
        </div>
      );
    }
  }

  return ProgramIDValueSettingsContainer;
})();

export default connect(
  (state) => {
    return {
      userInformation: state.userInformation,
      ProgramConfigurationData: state.ProgramConfigurationData,
      AccessValidationData: state.AccessValidationData,
      SharedData: state.SharedData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          saveAsDraftProgramIdentified,
          sendForApprovalProgramIdentified,
          setNewProgramDetails,
          editProgram,
          setDimensionList,
          getCustomBOIDetails,
          setStepperActiveIndex,
          setEditApproved,
          handleSendReminderProgram,
          getReminderCount,
          isProfileExisting,
          setEmailError
        }
      ),
      dispatch
    )
)(withRouter(ProgramIDValueSettings));
