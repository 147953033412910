import {
  GET_DASHBOARD_CLIENT,
  FETCH_DASHBOARD_REPORTING_YEAR,
  SET_DB_REPORTING_YEAR,
  SET_DB_PROGRAM,
  FETCH_DASHBOARD_PROGRAM,
  FETCH_DIMENSION_SUBDIMENSION,
  GET_DASHBOARD_PROGRAM_DETAILS,
  SET_DB_DIMENSION_LIST,
  GET_BOI_PROJECT_DETAILS,
  SET_CLICKED_SUBDIMENSION_NAME,
  SET_CLICKED_PANEL,
  CLEAR_DASHBOARD,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_COMMENTARY,
  GET_PROGRAM_FISCAL_YEAR_RANGE,
  SET_GLOBAL_URL_PARAM,
  GET_GLOBAL_DASHBOARD_PROGRAM_DETAILS,
  GET_DASHBOARD_CLIENT_DETAILS,
  UNSET_DASHBOARD_CLIENT_DETAILS
} from '../Constants';

const initialState = {
  dashboardClients: [],
  dashboardData: [],
  reportingYear: [],
  dashboardProgram: [],
  dimensionSubDimension: [],
  programID: '',
  boiProjectDetails: [],
  subDimensionClicked: 0,
  panelClicked: 0,
  panelIsClicked: false,
  dbDimensionList: undefined,
  selectedYear: '',
  selectedProgram: '',
  dashboardCommentary: {},
  programFiscalYearRange: {
    min: 0,
    max: 0,
  },
  dashboardProgramDetails: {},
  globalDashboardProgramDetails: {
    globalClientId: 0,
    clienMasterId: 0,
    clientMasterName: '',
    clientMasterLogo: '',
    clientProgramId: 0,
    programName: '',
    reportingYears: [],
    selectedClientItem: {},
  },
  globalUrlparam: {
    preview: null,
    clientID: 0,
    programID: 0,
    backBtnURL: '',
  },
  dashboardClientDetails: {}
};

export const DashboardData = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_DASHBOARD_CLIENT:
      return {
        ...state,
        dashboardClients: action.dashboardClients,
      };
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.dashboardData,
      };
    case GET_BOI_PROJECT_DETAILS:
      return {
        ...state,
        boiProjectDetails: action.boiProjectDetails,
      };
    case SET_DB_DIMENSION_LIST:
      return {
        ...state,
        dbDimensionList: action.dbDimensionList,
      };
    case SET_DB_PROGRAM:
      return {
        ...state,
        selectedProgram: action.selectedProgram,
      };
    case FETCH_DASHBOARD_REPORTING_YEAR:
      return {
        ...state,
        reportingYear: action.reportingYear,
      };
    case SET_DB_REPORTING_YEAR:
      return {
        ...state,
        selectedYear: action.selectedYear,
      };

    case FETCH_DASHBOARD_PROGRAM:
      return {
        ...state,
        dashboardProgram: action.dashboardProgram,
      };
    case FETCH_DIMENSION_SUBDIMENSION:
      return {
        ...state,
        dimensionSubDimension: action.dimensionSubDimension,
        programID: action.programID,
        boiProjectDetails: [],
      };
    case GET_DASHBOARD_PROGRAM_DETAILS:
      return {
        ...state,
        dashboardProgramDetails: action.dashboardProgramDetails,
      };
    case SET_CLICKED_SUBDIMENSION_NAME:
      return {
        ...state,
        subDimensionClicked: action.subDimensionClicked,
      };
    case SET_CLICKED_PANEL:
      return {
        ...state,
        panelClicked: action.panelClicked,
        panelIsClicked: action.panelIsClicked,
      };
    case GET_DASHBOARD_COMMENTARY:
      return {
        ...state,
        dashboardCommentary: action.dashboardCommentary,
      };
    case CLEAR_DASHBOARD:
      return action.payload;
    case GET_PROGRAM_FISCAL_YEAR_RANGE:
      return {
        ...state,
        programFiscalYearRange: action.programFiscalYearRange,
      };
    case SET_GLOBAL_URL_PARAM:
      return {
        ...state,
        globalUrlparam: action.globalUrlparam,
      };
    case GET_GLOBAL_DASHBOARD_PROGRAM_DETAILS:
      return {
        ...state,
        globalDashboardProgramDetails: action.globalDashboardProgramDetails,
      };
    case GET_DASHBOARD_CLIENT_DETAILS:
    return {
      ...state,
      dashboardClientDetails: action.dashboardClientDetails,
    };
    case UNSET_DASHBOARD_CLIENT_DETAILS:
    return {
      ...state,
      dashboardClientDetails: {}
    };
    default:
      return state;
  }
};
