import React, { Component } from 'react';
// import { history } from '../../store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  faCheck as Edit,
  faUser as User,
  faClipboardCheck as Approval,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import identifiedvalue from './../../Static/images/360cvm/ProgramIdentifiedStage.svg';

import { setSelectedProgram } from '../../Actions/ProgramConfiguration.Action';
import { getProjectCADetails } from '../../Actions/ProjectConfiguration.Action';
import { setStepperActiveIndex, updatePPCBOI } from '../../Actions/Shared.Action';
import { UnRefObj } from '../../Utilities/Formatter';
import { IsObjEmpty } from '../../Utilities/Validations';

const Stepper = (function () {
  //change these constant values base on what is on the DB
  class StepperPresentational extends Component {
    render() {
      const stage = this.props.stage;
      const isProj = this.props.type === 'Project' ? true : false;
      const stageId =
        isProj
          ? 1 // this.props.selectedProjectDetails.StageId -  for Project default to 1 for stage
          : this.props.selectedProgramDetails.StageId;
      const statusID =
        isProj
          ? this.props.selectedProjectDetails.StatusId
          : this.props.selectedProgramDetails.StatusId;
      return (
        <div className="md-stepper-horizontal">
          {
            isProj ? (
              <>
                <div className={`md-step active`}>
                  <div
                    className="md-step-img-circle stepper-initial-pointer"
                    onClick={() => {
                      if (1 <= stageId) {
                        this.props.setStep(stage.progIDValueSettings);
                      }
                    }}
                  >
                    {/* <FontAwesomeIcon icon={Edit} size="lg" color="white" /> */}
                    {stageId > 1 ? (
                      <FontAwesomeIcon icon={Edit} size="lg" color="white" />
                    ) : (
                      <img src={identifiedvalue} alt="identifiedvalue" />
                    )}
                  </div>
                  <div className="md-step-title">{stage.progIDValueSettings.desc}</div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>
              </>
            ) : (
              <>
                <div
                  className={`md-step ${this.props.activeIndex === 1 ? 'active' : stageId > 1 ? 'done' : ''
                    }`}
                >
                  <div
                    className="md-step-img-circle"
                    onClick={() => {
                      if (1 <= stageId) {
                        this.props.setStep(stage.progIDValueSettings);
                      }
                    }}
                  >
                    {/* <FontAwesomeIcon icon={Edit} size="lg" color="white" /> */}
                    {stageId > 1 ? (
                      <FontAwesomeIcon icon={Edit} size="lg" color="white" />
                    ) : (
                      <img src={identifiedvalue} alt="identifiedvalue" />
                    )}
                  </div>
                  <div className="md-step-title">{stage.progIDValueSettings.desc}</div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>

                <div
                  className={`md-step ${this.props.activeIndex === 2 ? 'active' : stageId >= 2 ? 'done' : ''
                    }`}
                >
                  <div
                    className="md-step-img-circle"
                    onClick={() => {
                      if (2 <= stageId) {
                        // this.props.setStep((this.props.userRole === 'Operations Account Lead' && statusID < 6) ? stage.accountLeadAppReview : stage.accountLeadApp)
                        if (this.props.type === 'Program') {
                          this.props.setStep(
                            !this.props.PnPComponents.program.stageTwoApprv &&
                              statusID < 6 &&
                              this.props.enterpriseID !== this.props.selectedProgramDetails.CreatedUser
                              ? statusID === 4
                                ? stage.accountLeadApp
                                : stage.accountLeadAppReview
                              : stage.accountLeadApp
                          );
                        } else {
                          this.props.setStep(
                            !this.props.PnPComponents.project.stageTwoApprv &&
                              statusID < 6 &&
                              this.props.enterpriseID !== this.props.selectedProjectDetails.CreatedUser
                              ? statusID === 4
                                ? stage.accountLeadApp
                                : stage.accountLeadAppReview
                              : stage.accountLeadApp
                          );
                        }
                      }
                    }}
                  >
                    {stageId > 2 ||
                      ((!this.props.PnPComponents.program.stageTwoApprv ||
                        !this.props.PnPComponents.project.stageTwoApprv) &&
                        statusID === 6) ? (
                      <FontAwesomeIcon icon={Edit} size="lg" color="white" />
                    ) : stageId === 2 ? (
                      <FontAwesomeIcon icon={Approval} size="lg" color="white" />
                    ) : (
                      <FontAwesomeIcon icon={Approval} size="lg" color="grey" />
                    )}
                  </div>
                  <div className="md-step-title ">{stage.accountLeadApp.desc}</div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>

                <div
                  className={`md-step ${this.props.activeIndex === 3
                    ? 'active'
                    : stageId >= 3 ||
                      ((!this.props.PnPComponents.program.stageTwoApprv ||
                        !this.props.PnPComponents.project.stageTwoApprv) &&
                        statusID === 6)
                      ? 'done'
                      : ''
                    }`}
                >
                  <div
                    className="md-step-img-circle"
                    onClick={() => {
                      if (
                        3 <= stageId ||
                        ((!this.props.PnPComponents.program.stageTwoApprv ||
                          !this.props.PnPComponents.project.stageTwoApprv) &&
                          statusID === 6)
                      ) {
                        // this.props.setStep(this.props.userRole === 'Operations Account Lead' ? stage.clientAlignmentSubmit : stage.clientAlignment)
                        if (this.props.type === 'Program') {
                          this.props.setStep(
                            !this.props.PnPComponents.program.stageTwoApprv &&
                              [6, 8].indexOf(statusID) > -1
                              ? stage.clientAlignmentSubmit
                              : stage.clientAlignment
                          );
                        } else {
                          const projCADetails = this.props.projectCADetails
                          const projDetails = this.props.selectedProjectDetails
                          const files = this.props.projectCAFileDetails.length
                          let stageTwoApprv = this.props.PnPComponents.project

                          if (!stageTwoApprv.stageTwoApprv
                            && (projDetails.CreatedUser.toLowerCase() !== 'system generated')
                            && ((!projCADetails.isSubmit && !files > 0)
                              || (!projCADetails.isSubmit && files > 0)
                              || (projCADetails.isSubmit && !files > 0)
                            ) || projDetails.ProjectApprover === this.props.emailAddress
                          ) {
                            this.props.setStep(stage.clientAlignmentSubmit) //Save as Draft, closed, Upload Later, from appr stage 2 
                          }
                          if (((!stageTwoApprv.stageTwoApprv
                            && [6, 7].indexOf(statusID) > -1) && (projCADetails.isSubmit && files > 0))
                            || ((projDetails.CreatedUser.toLowerCase() === 'system generated') || projDetails.ProjectCreator === this.props.emailAddress || projDetails.ProjectApprover !== this.props.emailAddress)) {
                            this.props.setStep(stage.clientAlignment) //approved, project Creator, & system generated 
                          }
                        }
                      }
                    }}
                  >
                    {stageId > 3 || statusID === 7 || statusID === 6 ? (
                      <FontAwesomeIcon icon={Edit} size="lg" color="white" />
                    ) : stageId === 3 ? (
                      <FontAwesomeIcon icon={User} size="lg" color="white" />
                    ) : (
                      <FontAwesomeIcon icon={User} size="lg" color="grey" />
                    )}
                  </div>
                  <div className="md-step-title ">{stage.clientAlignment.desc}</div>
                  <div className="md-step-bar-left" />
                  <div className="md-step-bar-right" />
                </div>
            </>
          )
          }
        </div>
      );
    }
  }

  class StepperContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        activeIndex: 0,
        userRole: -1,
        prevSelectedID: 0,
        isAwaiting: false,
      };
      this.stage = {
        progIDValueSettings: {
          step: 1,
          stage: 'programIdentified',
          desc: this.props.type === 'Project' ? 'Project Details' : 'Program Target Value Settings',
          url: `${this.props.type}IDValueSettings`,
        },
        accountLeadApp: {
          step: 2,
          stage: 'accountLeadership',
          desc: this.props.type === 'Project' ? 'Project Approval' : 'Account Leadership Approval',
          url: `${this.props.type}AccountLeadershipApproval`,
        },
        accountLeadAppReject: {
          step: 2,
          stage: 'accountLeadership',
          desc:
            this.props.type === 'Project'
              ? 'Project Approval Reject BOI'
              : 'Account Leadership Approval Reject BOI',
          url: `${this.props.type}AccountLeadershipApproval/rejectedboi`,
        },
        accountLeadAppReview: {
          step: 2,
          stage: 'accountLeadership',
          desc: this.props.type === 'Project' ? 'Project Approval' : 'Account Leadership Approval',
          url: `${this.props.type}AccountLeadershipApproval/review`,
        },
        clientAlignment: {
          step: 3,
          stage: 'clientAlignment',
          desc: 'Client Alignment',
          url: `${this.props.type}ClientAlignment`,
        },
        clientAlignmentSubmit: {
          step: 3,
          stage: 'clientAlignment',
          desc: 'Client Alignment',
          url: `${this.props.type}ClientAlignment/submit`,
        },
      };
    }

    componentDidMount() {
      let data;
      let selectedID;
      let isApproved = String(window.location.href).toLowerCase().includes('approvedboi');
      // this.setUserRole()
      if (this.props.type === 'Project') {
        data = this.props.ProjectConfigurationData.selectedProjectDetails;
        selectedID = this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID;
      } else if (this.props.type === 'Program') {
        data = this.props.ProgramConfigurationData.selectedProgramDetails;
        selectedID = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
      }

      if (!this.props.ProgramConfigurationData.isProgramProjectFromDropdown) {
        if (Object.keys(data).length > 0) {
          this.setState({
            activeIndex:
            this.props.SharedData.isAccountLeadership 
              ? 2
                : data.StatusId === 6 &&
                  (!this.props.AccessValidationData.PnPComponents.program.stageTwoApprv ||
                    !this.props.AccessValidationData.PnPComponents.project.stageTwoApprv)
                  ? isApproved
                    ? 2
                    : data.StageId + 1
                  : isApproved
                    ? 2
                    : data.StageId,
          });
        } else {
          this.setState({
            activeIndex: 1,
          });
        }
      }
      this.setState({
        prevSelectedID: selectedID,
        isAwaiting:
          String(window.location.href).toLowerCase().includes('awaitingappboi') &&
          selectedID ===
          (this.props.type === 'Program'
            ? this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID
            : this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID),
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        this.state.activeIndex !== this.props.SharedData.stepperActiveIndex &&
        !this.props.SharedData.editApproved
      ) {
        let currentStage;
        let status;
        let step;
        let isRejectBOI = String(window.location.href).toLowerCase().includes('rejectedboi');
        let isPrevSelected;

        if (this.props.type === 'Program') {
          currentStage =
            this.props.SharedData.isAccountLeadership 
              ? 2
              : this.props.SharedData.stepperActiveIndex === 0
              ? this.props.ProgramConfigurationData.selectedProgramDetails.StageId
              : this.props.SharedData.stepperActiveIndex;

          status = this.props.ProgramConfigurationData.selectedProgramDetails.StatusId;

          isPrevSelected =
            this.state.prevSelectedID ===
              this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID
              ? true
              : false;

          step =
            currentStage === 1
              ? this.stage.progIDValueSettings
              : currentStage === 2 &&
                !this.props.AccessValidationData.PnPComponents.program.stageTwoApprv &&
                status < 6
                ? status === 4
                  ? isRejectBOI && isPrevSelected
                    ? this.stage.accountLeadAppReject
                    : this.stage.accountLeadApp
                  : this.stage.accountLeadAppReview
                : currentStage === 2
                  ? status === 4 && isRejectBOI && isPrevSelected
                    ? this.stage.accountLeadAppReject
                    : this.stage.accountLeadApp
                  : currentStage === 3 &&
                    !this.props.AccessValidationData.PnPComponents.program.stageTwoApprv &&
                    [6, 8].indexOf(status) > -1
                    ? this.stage.clientAlignmentSubmit
                    : currentStage === 3
                      ? this.stage.clientAlignment
                      : 0;
          if(this.state.prevSelectedID !== this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID){
            this.setState({
              prevSelectedID:
                this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
            });
          }
          this.props.updatePPCBOI(false) //set false to isAccountLeadership
        } else if (this.props.type === 'Project') {
          currentStage =
            this.props.SharedData.stepperActiveIndex === 0
              ? this.props.ProjectConfigurationData.selectedProjectDetails.StageId
              : this.props.SharedData.stepperActiveIndex;


          /* isPrevSelected =
            this.state.prevSelectedID ===
              this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID
              ? true
              : false;

          status = this.props.ProjectConfigurationData.selectedProjectDetails.StatusId;

          const isSuperAdmin = this.props.UserInformationData.isSuperAdmin

          let profileID = this.props.UserInformationData.userProfiles.filter(
            (x) => x.ClientMasterId === this.props.SharedData.selectedClient.value && x.ProfileID !== 16 //filter out Quality checker
          );
          profileID = profileID.length > 0 ? profileID[0].ProfileID : 0; */

          step = this.stage.progIDValueSettings;
            /* currentStage === 1
              ? this.stage.progIDValueSettings
              : currentStage === 2 &&
                !this.props.AccessValidationData.PnPComponents.program.stageTwoApprv &&
                status < 6
                ? status === 4
                  ? isRejectBOI && isPrevSelected
                    ? this.stage.accountLeadAppReject
                    : this.stage.accountLeadApp
                  : profileID === 4 && status < 6
                    ? this.stage.accountLeadApp
                    : this.stage.accountLeadAppReview
                : currentStage === 2
                  ? status === 4 && isRejectBOI && isPrevSelected
                    ? this.stage.accountLeadAppReject
                    : this.stage.accountLeadApp
                  : currentStage === 3 &&
                    (!this.props.AccessValidationData.PnPComponents.project.stageTwoApprv
                      && [6, 7].indexOf(status) > -1
                      && ((!this.props.ProjectConfigurationData.projectCADetails.isSubmit && !this.props.ProjectConfigurationData.projectCAFileDetails.length > 0)
                        || (this.props.ProjectConfigurationData.projectCADetails.isSubmit && !this.props.ProjectConfigurationData.projectCAFileDetails.length > 0)
                        || (!this.props.ProjectConfigurationData.projectCADetails.isSubmit && this.props.ProjectConfigurationData.projectCAFileDetails.length > 0)))
                    || this.props.ProjectConfigurationData.selectedProjectDetails.CreatedUser !== 'System Generated'
                    || this.props.ProjectConfigurationData.selectedProjectDetails.ProjectCreator !== this.props.UserInformationData.emailAddress
                    || this.props.ProjectConfigurationData.selectedProjectDetails.ProjectApprover === this.props.UserInformationData.emailAddress
                    || window.location.pathname.includes("/submit")
                    ?
                    profileID === 4
                      ? this.stage.clientAlignmentSubmit
                      : window.location.pathname.includes("/submit")
                        ? this.stage.clientAlignmentSubmit
                        : this.stage.clientAlignment
                    : currentStage === 3 && IsObjEmpty(this.props.ProjectConfigurationData.selectedProjectDetails)
                      ? (
                        window.location.pathname.includes("/submit")
                          ? this.stage.clientAlignmentSubmit
                          : this.stage.clientAlignment
                      )
                      : (currentStage === 3
                        ? this.stage.clientAlignment
                        : 0); */
          if(this.state.prevSelectedID !== this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID){
            this.setState({
              prevSelectedID: this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID,
            });
          }
          
        }

        if (currentStage) {
          this.setStep(step);
        }
        // this.props.updatePPCBOI(false) //set false to isAccountLeadership
      } else if (
        ((prevProps.ProgramConfigurationData.selectedProgram.length === 0 ||
          Object.keys(prevProps.ProgramConfigurationData.selectedProgram).length <= 0) &&
          this.props.type === 'Program') ||
        ((prevProps.ProjectConfigurationData.selectedProject.length === 0 ||
          Object.keys(prevProps.ProjectConfigurationData.selectedProject).length <= 0) &&
          this.props.type === 'Project')
      ) {
        if (this.props.SharedData.stepperActiveIndex !== 1)
          this.setState({
            activeIndex: 0,
          });
      }
      // if (this.props.SharedData.selectedClient.value !== nextProps.SharedData.selectedClient.value) { this.setUserRole() }
    }
    setUserRole = () => {
      this.setState({
        userRole: this.props.UserInformationData.userProfiles.filter(
          (x) => x.ClientMasterId === this.props.SharedData.selectedClient.value && x.ProfileID !== 16 //filter out QC user
        )[0].ProfileName,
      });
    };

    setStep = (stp) => {
      try {
        let activeIndex = 0;
        const step = stp.stage;
        let tState = {};
        switch (step) {
          case 'programIdentified':
            activeIndex = 1;
            break;
          case 'accountLeadership':
            activeIndex = 2;
            tState = UnRefObj({ ppcPrevPath: window.location.pathname });
            break;
          case 'clientAlignment':
            activeIndex = 3;
            break;
          default:
            activeIndex = this.props.SharedData.stepperActiveIndex;
            break;
        }
        this.setState(
          {
            activeIndex: activeIndex,
          },
          () => {
            this.props.setStepperActiveIndex(activeIndex);
            this.props.history.push({
              pathname: `/ProgramProjectConfig/${this.state.isAwaiting &&
                activeIndex === 2
                ? stp.url.concat('/awaitingappboi')
                : stp.url || ''
                }`,
              state: { ...tState },
            });
          }
        );
      } catch (err) {
        console.log(err);
      }
    };

    render() {
      const { selectedProgramDetails } = this.props.ProgramConfigurationData;
      const { selectedProjectDetails, projectCADetails, projectCAFileDetails } = this.props.ProjectConfigurationData;
      const { PnPComponents } = this.props.AccessValidationData;
      const { enterpriseID, emailAddress } = this.props.UserInformationData;
      return (
        <StepperPresentational
          setStep={this.setStep}
          stage={this.stage}
          activeIndex={this.state.activeIndex}
          selectedProgramDetails={selectedProgramDetails}
          selectedProjectDetails={selectedProjectDetails}
          userRole={this.state.userRole}
          PnPComponents={PnPComponents}
          type={this.props.type}
          enterpriseID={enterpriseID}
          projectCADetails={projectCADetails}
          projectCAFileDetails={projectCAFileDetails}
          emailAddress={emailAddress}
        />
      );
    }
  }

  return StepperContainer;
})();

export default connect(
  (state) => {
    return {
      SharedData: state.SharedData,
      UserInformationData: state.userInformation,
      ProgramConfigurationData: state.ProgramConfigurationData,
      ProjectConfigurationData: state.ProjectConfigurationData,
      AccessValidationData: state.AccessValidationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setSelectedProgram,
          setStepperActiveIndex,
          getProjectCADetails,
          updatePPCBOI
        }
      ),
      dispatch
    )
)(withRouter(Stepper));
