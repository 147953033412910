import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import { GrooveIconSvg } from "tfo-groove";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faDownload } from "@fortawesome/free-solid-svg-icons";

import {
    onShowUploadSupport,
    setUploadSupportDoc,
} from '../../../Actions/Shared.Action';
import { FormDateDDMMMYYYUTC } from '../../../Utilities/Formatter';
import { HttpGetDownloadFile } from "../../../Utilities/HTTPUtil";

import Upload from "../../../Static/images/360cvm/upload.svg";

const ModalFileUpload = (function () {
    class ModalFileUploadPresentaional extends Component {
        render() {
            let file = this.props.File;
            let document = this.props.document;
            return (
                <React.Fragment>
                    <Dropzone
                        onDropAccepted={acceptedFiles => this.props.onDropAccepted(acceptedFiles)}
                        onDropRejected={rejectedFiles => this.props.onDropRejected(rejectedFiles)}
                        maxSize={10000000}
                        maxFiles={1}
                        accept='.ppt, .pptx'
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className={this.props.fileIsValid === null ? "cvm-dropzone" : this.props.fileIsValid ? "cvm-dropzone valid" : "cvm-dropzone invalid"}>
                                <div className="btn">
                                </div>
                                <input {...getInputProps()} />
                                <img src={Upload} alt="upload" />
                                <div className="cvm-dropzone-label">
                                    Drag & Drop File here or <span>Browse files</span>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    {!this.props.fileIsValid ? (
                        <div className="invalid-reason">{this.props.errorUpload}</div>
                    ) : null}
                    <div className="uploaded-files-container">
                        {(Object.keys(file).length > 0) && (
                            <div className="uploaded-files">
                                <div className="uploaded-files-icon">
                                    <GrooveIconSvg
                                        size="small"
                                        name="file"
                                        iconStyle="regular"
                                        iconColor="stat-neutral"
                                    />
                                </div>
                                <div className="uploaded-files-name">{file.name}</div>
                                <div className="uploaded-files-progressbar">
                                    <div className="upload-progress"></div>
                                </div>
                                <div className="uploaded-files-close">
                                    <GrooveIconSvg
                                        customClassName="delete-icon"
                                        size="small"
                                        name="times"
                                        iconStyle="solid"
                                        iconColor="stat-neutral"
                                        callbackFunction={() => this.props.onDeleteUpload(file.name)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {Object.keys(document).length > 0 && <div className="view-button-wrapper">
                        <div className="view-button" onClick={() => { this.props.onViewLast() }}>
                            View last uploaded Document
                            <FontAwesomeIcon icon={this.props.viewLastOpen ? faChevronUp : faChevronDown} size="sm" />
                        </div>
                    </div>}
                    {this.props.viewLastOpen && Object.keys(document).length > 0 && <div className="view-last-wrapper">
                        <div>
                            <GrooveIconSvg
                                size="small"
                                name="file"
                                iconStyle="solid"
                                iconColor="stat-alternate"
                            />
                            <span>{document.FileName}</span>
                        </div>
                        <div>{FormDateDDMMMYYYUTC(document.LastModifiedDate)}</div>
                        <div>
                            <FontAwesomeIcon
                                className="view-last-download"
                                icon={faDownload} size="sm"
                                onClick={() => { this.props.downloadFile() }}
                            />
                        </div>
                    </div>}
                </React.Fragment>
            );
        }
    }

    class ModalFileUploadContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                File: {},
                errorUpload: '',
                fileIsValid: null,
                tempFileList: [],
                tempFile: "",
                fileList: '',
                viewLastOpen: false,
            };
        }

        onDropAccepted = (acceptedFiles) => {
            try {
                let { uploadSupportDoc } = this.props.SharedData;
                let fileName = acceptedFiles[0].name.split('.')
                if (fileName.length > 2) { //checks double extension
                    this.setState({
                        fileIsValid: false,
                        errorUpload: 'Invalid File Name. File Name should not contain a dot (.)'
                    })
                } else {
                    uploadSupportDoc.file = acceptedFiles[0];
                    uploadSupportDoc.docType = this.props.radioSelected;

                    this.setState({
                        File: acceptedFiles[0],
                        fileIsValid: true,
                    }, () => { this.props.setUploadSupportDoc(uploadSupportDoc) });
                }
            }
            catch (e) {
                console.log(e)
            }
        }

        onDropRejected = (FileRejection) => {
            try {
                if (FileRejection[0].errors[0].code === 'file-invalid-type') {
                    this.setState({
                        errorUpload: "Invalid file format. Please upload file with any of the these formats: (.ppt, .pptx).",
                        fileIsValid: false,
                    })
                }
                else if (FileRejection[0].errors[0].code === 'file-too-large') {
                    this.setState({
                        errorUpload: "File size has exceeded 10MB limit.",
                        fileIsValid: false,
                    })
                }
                else if (FileRejection[0].errors[0].code === 'too-many-files') {
                    this.setState({
                        errorUpload: "You are only allowed to upload a maximum of 1 files",
                        fileIsValid: false,
                    })
                }
            }
            catch (e) {
                console.log(e)
            }
        }

        onDeleteUpload = (fileName) => {
            try {
                let File = {};

                let { uploadSupportDoc } = this.props.SharedData;
                uploadSupportDoc.file = File;
                uploadSupportDoc.docType = null;

                this.setState({
                    File: File,
                }, () => { this.props.setUploadSupportDoc(uploadSupportDoc) });
            }
            catch (e) {
                console.log(e)
            }
        }

        onViewLast = () => {
            this.setState({
                viewLastOpen: !this.state.viewLastOpen,
            })
        }

        downloadFile = async () => {
            try {
                let document = this.props.supportDocs.find((doc) => doc.DocumentType === this.props.radioSelected.toString())
                let responce = await HttpGetDownloadFile(
                    `ProfileMenu/DownloadSupportDocuments?ID=${document.ID}`, document.FileName
                );
                if (responce.status === 200) {

                } else {

                }
            } catch (err) {
                console.log(err);
            }
        };

        render() {
            let document = {};
            let idx = this.props.supportDocs.findIndex((doc) => doc.DocumentType === this.props.radioSelected.toString())
            if (idx !== -1) document = this.props.supportDocs[idx];
            return (
                <ModalFileUploadPresentaional
                    {...this.state}
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onDropRejected}
                    onDeleteUpload={this.onDeleteUpload}
                    onViewLast={this.onViewLast}
                    document={document}
                    downloadFile={this.downloadFile}
                />
            );
        }
    }

    return ModalFileUploadContainer;
})();

export default connect(
    state => {
        return {
            SharedData: state.SharedData,
        }
    },
    dispatch => bindActionCreators(Object.assign({},
        {
            onShowUploadSupport,
            setUploadSupportDoc,
        }), dispatch)
)(ModalFileUpload);