import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../store'
import CVMToasterNotif from '../Shared/CVMToasterNotif/CVMToasterNotif';
import CVMSingleSelectDropdownWithoutLabel from '../Shared/CVMSingleSelectDropdownWithoutLabel/CVMSingleSelectDropdownWithoutLabel';
import {
  DeleteCallbackURL,
  DeleteDistiURL,
  GetGDURLParams,
  LogUserSiteAction
} from '../../Utilities/AccessManagementUtil';

import { ACC_PROG, ACC_PROJ, GLOBAL_DASHBOARD, GLOBAL_PREVIEW } from '../../Constants/Modules';
import {
  getClientList,
  showClientDropdown,
  setSelectedClient,
  explicitSetClientList,
  setToasterParam,
} from '../../Actions/Shared.Action';
import {
  FetchDashboardData,
  getDashboardDimensions,
  onSubdimensionClick,
  panelOnClick,
  clearDashboard,
  onSelectProgram,
  setSelectedReportingYear,
  setCommentaryRequiredData,
  SetGlobalURLParam,
  GetDashboardProgramDetails,
  getGlobalDashboardDimensions,
  fetchClientDetails
} from '../../Actions/Dashboard.Action';
import { withRouter } from "react-router-dom";
import DashboardDimGraph from '../Dashboard/DashboardDimGraph';
import SummaryWheel from '../Dashboard/SummaryWheel';
import DashboardProjectDetails from './DashboardProjectDetails';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import AddFileIcon from './../../Static/images/360cvm/AddFilesIcon.svg';
import '../Dashboard/Dashboard.css';
import { TryGetNodeAlt, TryGetObjValueAlt } from '../../Utilities/Formatter';

import DashboardClientDetails from '../Dashboard/DashboardClientDetails';
import { HasValue } from '../../Utilities/Validations';
const DashboardGlobal = (function () {
  class DashboardPresentational extends Component {
    render() {
      return (
        <>
          <CVMToasterNotif />
          {this.props.hasGlobalClientData ? (
            <div className="data-visualisation-container">
              <div className={`title-section  ${this.props.isKnowMore ? 'bottom-margin' : ''}`}>
                <div className="value-meter-title">
                  <h4>
                    {!this.props.isGlobalPreview &&
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="db-back-icon"
                        onClick={this.props.onClickBackBtn}
                      />}
                    360° Synops Value Meter
                  </h4>
                  {this.props.dashboardClientDetails && <DashboardClientDetails isKnowMore={true} />}
                </div>
                <div className={`dIL-dropdowns ${!this.props.isKnowMore ? 'db-not-project-details' : 'db-project-details'}`}>
                  <div className="statusDropdown disabled dashboard-prog-select" title={this.props.selectedProgram.value}>
                    {this.props.programOptions && (
                      <CVMSingleSelectDropdownWithoutLabel
                        options={this.props.programOptions}
                        placeholder="Program"
                        callbackFunction={this.props.onSelectProgram}
                        value={this.props.selectedProgram}
                        isDisabled={true}
                      />
                    )}
                  </div>
                  <div className="reportingYrDropdown">
                    {this.props.yearOptions && (
                      <CVMSingleSelectDropdownWithoutLabel
                        options={this.props.yearOptions}
                        placeholder="Year(s)"
                        callbackFunction={this.props.onSelectReportingYear}
                        value={this.props.selectedYear}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!this.props.isStart && (
                <div className="default-view-container">
                  <div className="default-view-global">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              )}

              {this.props.isStart &&
                (this.props.isKnowMore ? (
                  <div className="data-view-container">
                    <div className="">
                      <div className="row">
                        <div className="col-md-5 graph-view-section">
                          <div id="piechartContainer" className="align-piechart">
                            <DashboardDimGraph
                              selectedProgram={this.props.selectedProgram.value}
                              selectedYear={this.props.selectedYear.value}
                            />
                          </div>
                        </div>
                        <div className="col-md-7 details-view-section">
                          <DashboardProjectDetails
                            selectedYear={this.props.selectedYear}
                            createLog={this.props.createLog}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <SummaryWheel
                    onKnowMore={this.props.onKnowMore}
                    isGlobal={true}
                    isGlobalPreview={this.props.isGlobalPreview}
                  />
                ))}
            </div>
          ) : (
            <div className="select-client-container">
              <img src={AddFileIcon} alt="fileIcon" />
              <p>No Client Data</p>
              <span>No wheels are configured for the selected client yet.</span>
            </div>
          )}
        </>
      );
    }
  }

  class DashboardContainer extends Component {
    constructor() {
      super();
      this.state = {
        years: [],
        size: 'md',
        programOptions: [],
        yearOptions: [],
        isStart: false,
        clientValue: 0,
        selectedYear: '',
        selectedProgram: '',
        selectedprogramID: 0,
        startButton: true,
        flg: true,
        flg1: true,
        isKnowMore: false,
        hasGlobalClientData: true,
        isGlobalPreview: true,
      };
    }
    componentDidMount = () => {
      DeleteCallbackURL()
      const url = window.location.href.replace(window.location.origin, '').toLowerCase()
      if (url === '/dashboard/global' || url === '/dashboard/global/' ||
        TryGetNodeAlt(this.props.userInformation, false, 'isGlobalUser') === false ||
        TryGetNodeAlt(this.props.userInformation, false, 'fromGlobal') === false
      ) this.props.history.push('/AccessDenied')
      this.setState(
        {
          isStart: false,
        },
        () => {
          this.props.clearDashboard();
        }
      );
      const urlData = this.saveURLData();
      this.initializeGlobalDashboard(urlData);

      setTimeout(() => {
        DeleteDistiURL()
      }, 7000)
    };

    componentDidUpdate = (prevProps) => {
      if (
        this.props.DashboardData.dashboardProgram.length === 0 &&
        this.state.selectedProgram !== ''
      ) {
        this.setState({
          flg: true,
          flg1: true,
          selectedYear: '',
          selectedProgram: '',
          isStart: false,
          isKnowMore: false,
        });
      }

      if (this.state.clientValue !== this.props.SharedData.selectedClient.value) {
        this.setState({
          clientValue: this.props.SharedData.selectedClient.value,
        });
      }
      if (this.props.SharedData.selectedClient.value !== prevProps.SharedData.selectedClient.value) {
        this.props.fetchClientDetails(this.props.SharedData.selectedClient.value)
      }

    };

    saveURLData = () => {
      const urlParams = GetGDURLParams();
      this.props.SetGlobalURLParam(urlParams);
      return urlParams;
    };

    initializeGlobalDashboard = async (urlData) => {
      let globalclientid = urlData.clientID;
      let programid = urlData.programID;
      let isGlobalPreview = urlData.preview;
      if (globalclientid && programid) {
        await this.props.GetDashboardProgramDetails(globalclientid, programid);
        let globalDashboardProgramDetails = this.props.DashboardData.globalDashboardProgramDetails;

        if (globalDashboardProgramDetails !== null && globalDashboardProgramDetails !== undefined) {
          this.createLog({ programID: programid })
          this.onSelectClient(globalDashboardProgramDetails.selectedClientItem);

          if (globalDashboardProgramDetails.reportingYears.length > 0) {
            let fiscalYear = Math.max(...globalDashboardProgramDetails.reportingYears);
            let selectedProgramName = globalDashboardProgramDetails.programName;
            if (fiscalYear) {
              let tempProgram = {
                label: selectedProgramName,
                value: selectedProgramName,
              };
              let tempSelectedReportYear = {
                label: [fiscalYear],
                value: [fiscalYear],
              };

              this.setState(
                {
                  isStart: false,
                  startButton: true,
                  selectedProgram: tempProgram,
                  isGlobalPreview: isGlobalPreview,
                },
                async () => {
                  this.props.showClientDropdown();
                  await this.onSelectProgram(tempProgram);
                  await this.onSelectReportingYear(tempSelectedReportYear);
                  this.onStart();
                }
              );
            }
          } else {
            this.setState({
              hasGlobalClientData: false,
            });
          }
        } else {
          this.setState({
            hasGlobalClientData: false,
          });
        }
      }
    };

    onSelectClient = (item) => {
      this.setState(
        {
          show: false,
          selectedYear: '',
          flg1: true,
          flg: true,
        },
        () => {
          this.props.setSelectedClient(item);

          this.setState({ clientValue: item.value });
        }
      );
    };

    onSelectReportingYear = async (e) => {
      if (!this.state.startButton) {
        this.props.clearDashboard('reportingYear');
      }
      if (this.state.selectedProgram === '') {
        this.setState({
          selectedYear: e,
          startButton: false,
          isStart: false,
        });
      } else {
        this.setState(
          {
            selectedYear: e,
          },
          () => {
            if (this.state.isStart) {
              this.props.setToasterParam({
                show: true,
                type: 'success',
                message: 'Program data loaded successfully.',
              });
            }
          }
        );
      }
      this.props.setSelectedReportingYear(e);
      // await this.props.getDashboardDimensions(
      await this.props.getGlobalDashboardDimensions(
        this.state.clientValue,
        e.value,
        this.state.selectedProgram.value
      );

      let dashboardData = this.props.DashboardData.dashboardData;
      const year = e.value[0];
      let programID = TryGetObjValueAlt(
        dashboardData.find(
          (d) =>
            d.programName === this.state.selectedProgram.value &&
            [d.ReportingYear].indexOf(year) > -1
        ),
        0,
        'id'
      );
      this.props.setCommentaryRequiredData(programID, true);
    };

    onSelectProgram = async (e) => {
      this.props.onSelectProgram(e.value);
      if (this.state.isStart) {
        this.setState({ selectedProgram: e });
      } else {
        this.setState({
          selectedProgram: e,
          flg: true,
        });
      }
    };

    onStart = () => {
      this.setState({ isStart: true });
    };

    onKnowMore = () => {
      this.setState({ isKnowMore: true });
    };
    onClickBackBtn = () => {
      if (this.state.isKnowMore) {
        this.setState({ isKnowMore: false }, () => {
          this.props.panelOnClick(null, false);
        });
      } else {
        window.location.href = this.props.DashboardData.globalUrlparam.backBtnURL;
        // window.history.go(-2);
      }
    };
    createLog = (props) => {
      const client = this.props.DashboardData.globalDashboardProgramDetails.selectedClientItem.value
      LogUserSiteAction({
        module: GetGDURLParams().preview ? GLOBAL_PREVIEW : GLOBAL_DASHBOARD,
        ShouldLogPPCID: true,
        clientID: client,
        ClientProgramID: props.programID,
        ClientProjectID: props.projectID,
        progStatus: HasValue(props.programID) && !HasValue(props.projectID) ? ACC_PROG : null,
        projStatus: HasValue(props.projectID) ? ACC_PROJ : null
      });
    }
    render() {
      const {
        reportingYear,
        dashboardProgram,
        dimensionSubDimension,
        dbDimensionList,
        panelClicked,
        panelIsClicked,
        programID,
        dashboardClientDetails
      } = this.props.DashboardData;

      const { onSubdimensionClick, panelOnClick } = this.props;

      const { modules } = this.props.HomeData;

      return modules.length > 0 ? (
        <div id="module-container">
          <div className="right-section global-dashboard-rs">
            <DashboardPresentational
              size={this.state.size}
              onSelectClient={this.onSelectClient}
              programOptions={dashboardProgram}
              yearOptions={reportingYear}
              isStart={this.state.isStart}
              startButton={this.state.startButton}
              onStart={this.onStart}
              onSelectReportingYear={this.onSelectReportingYear}
              onSelectProgram={this.onSelectProgram}
              dimensionSubDimension={dimensionSubDimension}
              dimensionList={this.state.dimension}
              dbDimensionList={dbDimensionList}
              onSubdimensionClick={onSubdimensionClick}
              panelOnClick={panelOnClick}
              panelClicked={panelClicked}
              panelIsClicked={panelIsClicked}
              selectedProgram={this.state.selectedProgram}
              selectedYear={this.state.selectedYear}
              onKnowMore={this.onKnowMore}
              isKnowMore={this.state.isKnowMore}
              onClickBackBtn={this.onClickBackBtn}
              programID={programID}
              hasGlobalClientData={this.state.hasGlobalClientData}
              isGlobalPreview={this.state.isGlobalPreview}
              dashboardClientDetails={dashboardClientDetails}
              createLog={this.createLog}
            />
            <div id="right-section"></div>
          </div>
        </div>
      ) : (
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
  }

  return DashboardContainer;
})();

export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      DashboardData: state.DashboardData,
      userInformation: state.userInformation,
      HomeData: state.HomeData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getClientList,
          setSelectedClient,
          showClientDropdown,
          FetchDashboardData,
          getDashboardDimensions,
          onSubdimensionClick,
          panelOnClick,
          clearDashboard,
          onSelectProgram,
          explicitSetClientList,
          setToasterParam,
          setSelectedReportingYear,
          setCommentaryRequiredData,
          SetGlobalURLParam,
          GetDashboardProgramDetails,
          getGlobalDashboardDimensions,
          fetchClientDetails
        }
      ),
      dispatch
    )
)(withRouter(DashboardGlobal));
