import React, { Component } from "react";
import {
  Accordion,
  Card,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  useAccordionToggle,
} from "react-bootstrap";
import { connect } from "react-redux";
import { GrooveIconSvg, GrooveInput, GrooveRadioButton } from "tfo-groove";

import "../ProgramIdentified.css";
import BOI from "./BOI";
import CVMRejectionModal from "../../../Shared/CVMRejectionModal/CVMRejectionModal";
import CVMConditionalApproveModal from "../../../Shared/CVMConditionalApproveModal/CVMConditionalApproveModal";

const SubDimension = (function() {
  class SubDimensionPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <form key={this.props.subDimensionID}>
            <div className="sub-dimension-details">
              <Col
                lg={11}
                md={11}
                sm={11}
                className="sub-dimension-title bodytext14-medium-slate"
              >
                Subdimension - {this.props.subDimensionName}
              </Col>
            </div>
            <div className="boi-details">
              <Row className="sub-dimension-title bodytext14-medium-slate">
                Business Outcome Indicators
              </Row>
              {this.props.boiList.map((boiMap, boiIndex) => (
                <BOI
                  dimensionIndex={this.props.dimensionIndex}
                  subDimensionIndex={this.props.subDimensionIndex}
                  boiIndex={boiIndex}
                  boiID={boiMap.BOIID}
                  boiName={boiMap.BOIName}
                  boiDescription={boiMap.BOIDescription}
                  boiReason={boiMap.BOIReason}
                  boiStatus={boiMap.BOIStatus}
                  boiUOM={boiMap.UOM}
                  boiValue={boiMap.BOITarget}
                  boiCode={boiMap.BOICode}
                  boiCPTID={boiMap.ClientProgramTargetID}
                  boiRejectionRecepient={boiMap.rejectionRecepient}
                  onChangeBOI={this.props.onChangeBOI}
                  role={this.props.role}
                  IsReadOnly={this.props.IsReadOnly}
                  selectedProgramID={this.props.selectedProgramID}
                />
              ))}
            </div>
          </form>
        </React.Fragment>
      );
    }
  }

  class SubDimensionContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        radioButton: [
          {
            radioBtnLabel: "Approve",
            radioBtnValue: "Approve",
            defaultChecked:
              this.props.subDimensionStatus === "Approve" ||
              this.props.subDimensionStatus === ""
                ? true
                : false,
            name: "radio-select",
            id: "radio-1-" + this.props.subDimensionID,
          },
          {
            radioBtnLabel: "Reject",
            radioBtnValue: "Rejected",
            defaultChecked:
              this.props.subDimensionStatus === "Rejected" ? true : false,
            name: "radio-select",
            id: "radio-2-" + this.props.subDimensionID,
          },
          {
            radioBtnLabel: "Conditional Approval",
            radioBtnValue: "Conditional Approval",
            defaultChecked:
              this.props.subDimensionStatus === "Conditional Approval"
                ? true
                : false,
            name: "radio-select",
            id: "radio-3-" + this.props.subDimensionID,
          },
        ],
      };
    }

    render() {
      return (
        <SubDimensionPresentational
          boiList={this.props.boiList}
          dimensionIndex={this.props.dimensionIndex}
          subDimensionIndex={this.props.subDimensionIndex}
          subDimensionName={this.props.subDimensionName}
          subDimensionValue={this.props.subDimensionValue}
          subDimensionStatus={this.props.subDimensionStatus}
          subDimensionReason={this.props.subDimensionReason}
          onChangeSubDimension={this.props.onChangeSubDimension}
          onChangeBOI={this.props.onChangeBOI}
          radioButton={this.state.radioButton}
          role={this.props.role}
          IsReadOnly={this.props.IsReadOnly}
          selectedProgramID={this.props.selectedProgramID}
        />
      );
    }
  }

  return SubDimensionContainer;
})();

export default SubDimension;
