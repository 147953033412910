import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  GrooveTabComponent,
  GrooveIconSvg,
  GrooveButton,
  GrooveInput,
  GrooveTextBox,
} from "tfo-groove";
import { Accordion, Card } from "react-bootstrap";

import CVMMutiSelectDropdown from "../../Shared/CVMMutiSelectDropdown/CVMMutiSelectDropdown";

require("./CVMApprovalRequestModal.css");

const CVMApprovalRequestModal = (function () {
  class CVMApprovalRequestModalPresentational extends Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      return (
        <React.Fragment>
          <div className="ApprovalRequestModal">
            <div className="notifyModal-dropdown">
              <CVMMutiSelectDropdown
                label={this.props.label}
                placeholder={""}
                isDisabled={true}
                options={
                  this.props.clientOperationAccountLead
                    ? [
                      {
                        value: this.props.clientOperationAccountLead
                          .OperationsAccountLead,
                        label: this.props.clientOperationAccountLead
                          .OperationsAccountLead,
                      },
                    ]
                    : this.props.clientList
                }
                onChange={this.props.onChange}
                value={
                  this.props.clientOperationAccountLead
                    ? {
                      value: this.props.clientOperationAccountLead
                        .OperationsAccountLead,
                      label: this.props.clientOperationAccountLead
                        .OperationsAccountLead,
                    }
                    : this.props.value
                }
              />
            </div>
            <div className="notifyModal-textarea">
              <div className="comments">
                <GrooveTextBox
                  id="notifyTextarea"
                  name="notifyTextarea"
                  type="text"
                  placeholder="Comment"
                  rows="4"
                  callbackFunction={this.props.sendForApprovalComment}
                  maxLength={255}
                />
              </div>
              {/* <div className="textareaLabel">Comment</div>
              <textarea
                id="notifyTextarea"
                name="notifyTextarea"
                rows="4"
                cols="73"
                maxlength="255"
                onChange={this.props.sendForApprovalComment}
              ></textarea> */}
            </div>
          </div>
          <div className="notifyModal-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={this.props.onCloseModal}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={this.props.disableSendBtn}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text={this.props.actionType}
              callbackFunction={() =>
                this.props.sendApproval("Send for Approval")
              }
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class CVMApprovalRequestModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount = () => { };

    render() {
      return (
        <CVMApprovalRequestModalPresentational
          onCloseModal={this.props.onCloseModal}
          clientList={this.props.clientList}
          label={this.props.label}
          actionType={this.props.actionType}
          onChange={this.props.onChange}
          value={this.props.value}
          sendApproval={this.props.sendApproval}
          sendForApprovalComment={this.props.sendForApprovalComment}
          clientOperationAccountLead={this.props.clientOperationAccountLead}
          disableSendBtn={this.props.disableSendBtn}
        />
      );
    }
  }

  return CVMApprovalRequestModalContainer;
})();

export default CVMApprovalRequestModal;
