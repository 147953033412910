import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveSelectMenuBasic } from "tfo-groove";
import { FetchCMVProjDelivLocDrpdown } from '../../../Actions/Client.Action';

const CVMProjectDeliveryLocationDropdown = (() => {
    class CVMProjectDeliveryLocationPresentational extends Component {
        render() {
            return (
                <GrooveSelectMenuBasic
                    id="deliveryLocation"
                    name="deliverylocation"
                    contentType="checkbox"
                    placeholder="Delivery Location*"
                    isDisabled={this.props.isDisabled}
                    list={this.props.list}
                    defaultCheckedData={this.props.selected}
                    callbackFunction={(e) => { this.props.callback(e, 'deliveryLocation') }}
                    customClassName={this.props.isValid ? "" : " dropdown-invalid"}
                />
            );
        }
    }

    class CVMProjectDeliveryLocationContainer extends Component {
        constructor(props) {
            super(props);

            this.state = {
                selected: this.props.data,
                clientID: 0,
                list: this.props.projDelivLocList.map(e => {
                    return {
                        text: e.deliveryLocation,
                        value: e.id
                    }
                })
            };
        }
        componentDidMount = async () => {
            if (this.props.projDelivLocList.length === 0) {
                await this.props.FetchCMVProjDelivLocDrpdown(this.props.clientID)
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.projDelivLocList !== prevProps.projDelivLocList) {
                const tempList = this.props.projDelivLocList.map(e => {
                    return {
                        text: e.deliveryLocation,
                        value: e.id
                    }
                })
                this.setState({ list: tempList });
            }
            if (this.props.clientID != prevProps.clientID) {
                this.props.FetchCMVProjDelivLocDrpdown(this.props.clientID);
                this.setState({ clientID: this.props.clientID })
            }
            if (this.props.data !== this.state.selected) {
                this.setState({ selected: this.props.data })
            }
        }

        render() {
            return (
                <CVMProjectDeliveryLocationPresentational
                    {...this.props}
                    {...this.state}
                />
            );
        }
    }

    return CVMProjectDeliveryLocationContainer;

})();

export default connect(
    state => {
        return {
            projDelivLocList: state.ProjDelivLocList,
            clientID: state.SharedData.selectedClient.value
        }
    },
    dispatch => bindActionCreators(Object.assign({}, { FetchCMVProjDelivLocDrpdown }), dispatch)
)(CVMProjectDeliveryLocationDropdown);
