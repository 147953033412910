import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveSelectMenuBasic } from "tfo-groove";
import { FetchCMVProjLeadDrpdown } from '../../../Actions/Client.Action';

const ProjectLeadDropdown = (() => {
    class ProjectLeadDropdownPresentational extends Component {
        render() {
            return (
                <GrooveSelectMenuBasic
                    id="projectLead"
                    name="projectlead"
                    placeholder="Please select Project Lead*"
                    isDisabled={this.props.isDisabled}
                    list={this.props.list}
                    defaultData={this.props.data}
                    callbackFunction={(e) => { this.props.callback(e, 'projectLead') }}
                    customClassName={this.props.isValid ? "" : " dropdown-invalid"}

                />
            );
        }
    }

    class ProjectLeadDropdownContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                list: []
            }
        }
        componentDidMount() {
            if (this.props.projLeadList.list.length === 0
                || this.props.projLeadList.clientID !== this.props.clientID
            ) {
                this.props.FetchCMVProjLeadDrpdown(this.props.clientID)
            }
            else {
                this.loadList(this.props.projLeadList.list)
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.projLeadList !== prevProps.projLeadList) {
                this.loadList(this.props.projLeadList.list)
            }
            if (this.props.clientID != prevProps.clientID) {
                this.props.FetchCMVProjDelivByDrpdown(this.props.clientID)
            }
        }
        loadList = (paramList) => {
            const tempList = paramList.map(e => {
                return {
                    value: e.id,
                    label: e.email,
                    text: e.email
                }
            })
            this.setState({ list: tempList });
        }
        render() {
            return (
                <ProjectLeadDropdownPresentational
                    {...this.props}
                    {...this.state}
                />
            );
        }

    }

    return ProjectLeadDropdownContainer;

})();

export default connect(
    state => {
        return {
            projLeadList: state.ProjLeadList,
            clientID: state.SharedData.selectedClient.value
        }
    },
    dispatch => bindActionCreators(Object.assign({}, { FetchCMVProjLeadDrpdown }), dispatch)
)(ProjectLeadDropdown);
