import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { GrooveSelectMenuBasic, GrooveButton, GrooveIconSvg } from "tfo-groove";

const DeleteMappedUser = (function () {
  class DeleteMappedUserPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="stepperClose-Alert-Content dmu-text">
            <span>Please note that this user is mapped to a program for Client</span>
            <span className="dmu-client-list" title={this.props.clientList}>
              {this.props.clientList}
            </span>
            <span>Go to Client Management to update the user mappings</span>
          </div>
          <div className="notifyModal-buttons delete-mapped-user-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={() => this.props.handleCancel('cancel')}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Yes, Delete"
              callbackFunction={() => this.props.handleYes('delete')}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class DeleteMappedUserContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // showNotifMessage: false,
        // isDisableRPD: false,
      };
    }

    componentDidMount = async () => {
      // this.showDisclaimerMessage();
    }

    render() {
      return (
        <DeleteMappedUserPresentational
          //redux
          handleYes={this.props.handleYes}
          handleCancel={this.props.handleCancel}
          clientList={this.props.clientList}
        />
      );
    }
  }

  return DeleteMappedUserContainer;
})();

export default DeleteMappedUser;
