import {
    SET_USER_INFORMATION
} from '../Constants';

export default function UserInformation(state = {}, action = {}) {
    if (action.type === SET_USER_INFORMATION) {
        return action.payload;
    } else{
        return state;
    }
}