import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import 'chartjs-plugin-datalabels';
// import 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { HasValue } from '../../Utilities/Validations';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const DashboardBarChart = (function () {
  class _DashboardBarChart extends Component {
    constructor() {
      super();
      this.state = {};
    }

    computMax = () => {
      let max = 0;

      if (
        this.props.projectItem.boiIdentifiedValue > 0 &&
        this.props.projectItem.boiDeliveredValue > 0
      ) {
        max = Math.max(
          this.props.projectItem.boiIdentifiedValue,
          this.props.projectItem.boiDeliveredValue
        );
      }

      if (max < 0) {
        max = 0;
      } else {
        let values = [
          Math.abs(this.props.projectItem.boiIdentifiedValue),
          Math.abs(this.props.projectItem.boiDeliveredValue),
        ];
        max = Math.max(...values);

        max = Math.ceil(max / 4 + max);

        if (max <= 40) {
          while (max % 4 !== 0) {
            max++;
          }
        } else {
          let minRange = 4;
          let maxRange = 4 * 10;
          while (max < minRange || max > maxRange) {
            minRange = minRange * 10;
            maxRange = maxRange * 10;
          }
          max = max + (minRange - (max % minRange));

          var ranges = [
            { divider: 1e12, suffix: 'T' },
            { divider: 1e9, suffix: 'B' },
            { divider: 1e6, suffix: 'M' },
            { divider: 1e3, suffix: 'K' },
          ];
          if (max.toString() !== '-Infinity') {
            for (var i = 0; i < ranges.length; i++) {
              if (max >= ranges[i].divider) {
                let dividedMax = Math.ceil(max / ranges[i].divider);
                // If formatted maximum is single digit, between 2 and 4, 
                // set to next digit to allow ticks to be divisible by 4
                let tempMax = dividedMax.toString().length === 1 ? 
                  dividedMax >= 2 && dividedMax <= 4 ? 4 * ranges[i].divider : max 
                    : max;
                max = tempMax;
              }
            }
          }

        }
      }
      return max;
    };
    computeMin = () => {
      let min = 0;

      if (
        this.props.projectItem.boiIdentifiedValue > 0 ||
        this.props.projectItem.boiDeliveredValue > 0
      ) {
        min = Math.max(
          this.props.projectItem.boiIdentifiedValue,
          this.props.projectItem.boiDeliveredValue
        );
      }

      if (min >= 0) {
        min = 0;
      } else {
        let maxMin = 0;

        if (
          this.props.projectItem.boiIdentifiedValue > 0 ||
          this.props.projectItem.boiDeliveredValue > 0
        ) {
          maxMin = Math.max(
            Math.abs(this.props.projectItem.boiIdentifiedValue),
            Math.abs(this.props.projectItem.boiDeliveredValue)
          );
        }
        maxMin = Math.ceil(maxMin / 4 + maxMin);

        if (maxMin <= 40) {
          while (maxMin % 4 !== 0) {
            maxMin++;
          }
        } else {
          let minRange = 4;
          let maxRange = 4 * 10;
          while (maxMin <= minRange || maxMin >= maxRange) {
            minRange = minRange * 10;
            maxRange = maxRange * 10;
          }
          maxMin = maxMin + (minRange - (maxMin % minRange));
        }
        min = maxMin * -1;
      }

      return min;
    };

    computeStepSize = () => {
      let max = this.computMax();
      let stepSize = Math.ceil(max / 4);
      return stepSize;
    };

    render() {
      let identifiedValue = this.props.projectItem.boiIdentifiedValue;
      let deliveredValue = this.props.projectItem.boiDeliveredValue;
      const state = {
        labels: [''],
        datasets: [
          {
            label: 'Identified',
            backgroundColor: ['#a100ff'],
            data: [identifiedValue],
            datalabels: {
              display: HasValue(identifiedValue),
              anchor: 'end',
              align: 'end',
              clamp: true,
              formatter: function (value) {
                var ranges = [
                  { divider: 1e12, suffix: 'T' },
                  { divider: 1e9, suffix: 'B' },
                  { divider: 1e6, suffix: 'M' },
                  { divider: 1e3, suffix: 'K' },
                ];
                function formatNumber(n) {
                  for (var i = 0; i < ranges.length; i++) {
                    if (Math.ceil(n) >= ranges[i].divider) {
                      let tempRange =
                        (n / ranges[i].divider).toString().split('.').length >= 2
                          ? (n / ranges[i].divider).toFixed(2)
                          : n / ranges[i].divider;
                      return tempRange.toString() + ' ' + ranges[i].suffix;
                    }
                  }
                  return n;
                }
                let res = formatNumber(value) === null ? '' : formatNumber(value);
                let isZeroDecimal = res.split('.')[1] === '00' ? true : false;
                res = isZeroDecimal ? res.split('.')[0] : res;
                return res;
              },
              font: {
                weight: 'bold',
                size: 10,
              },
              offset: 2,
              // rotation: -90,
            },
          },
          {
            label: 'Delivered',
            backgroundColor: ['#ffc82c'],
            data: [deliveredValue],
            datalabels: {
              display: HasValue(deliveredValue),
              anchor: 'end',
              align: 'end',
              clamp: true,
              formatter: function (value) {
                var ranges = [
                  { divider: 1e12, suffix: 'T' },
                  { divider: 1e9, suffix: 'B' },
                  { divider: 1e6, suffix: 'M' },
                  { divider: 1e3, suffix: 'K' },
                ];
                function formatNumber(n) {
                  for (var i = 0; i < ranges.length; i++) {
                    if (Math.ceil(n) >= ranges[i].divider) {
                      let tempRange =
                        (n / ranges[i].divider).toString().split('.').length >= 2
                          ? (n / ranges[i].divider).toFixed(2)
                          : n / ranges[i].divider;
                      return tempRange.toString() + ' ' + ranges[i].suffix;
                    }
                  }
                  return n;
                }
                let res = formatNumber(value) === null ? '' : formatNumber(value);
                let isZeroDecimal = res.split('.')[1] === '00' ? true : false;
                res = isZeroDecimal ? res.split('.')[0] : res;
                return res;
              },
              font: {
                weight: 'bold',
                size: 10,
              },
              offset: 2,
              // rotation: -90,
            },
          },
        ],
      };

      const id = 'bar-chart';
      return (
        <Bar
          data={state}
          options={{
            maintainAspectRatio: false,
            aspectRatio: 1,
            cornerRadius: 0,
            responsive: true,
            /* legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltips: {
              enabled: false,
            }, */
            indexAxis: 'y',
            layout: {
              padding: {
                left: 0,
                right: 20,
              },
            },
            datasets: {
              bar: {
                // barThickness: 15,
                categoryPercentage: 0.7,
                barPercentage: 0.5,
              }
            },
            scales: {
              y: //[
                {
                  display: true,
                  title: {
                    display: true,
                  },
                  grid: {
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                    offset: false,
                  },
                  ticks: {
                    padding: 15,
                    color: 'rgb(45, 58, 75)',
                    fontSize: 12,
                  },
                  maxBarThickness: 15,
                  categoryPercentage: 0.6,
                  barPercentage: 1.0,
              },
              //],
              x: //[
                {
                  display: true,
                  title: {
                    display: true,
                  },
                  grid: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false,
                    drawTicks: true,
                    tickLength: 10
                  },
                  beginAtZero: true,
                  max: this.computMax(),
                  min: this.computeMin(),
                  ticks: {
                    padding: 10,
                    color: 'rgb(145, 158, 171)',
                    font: {
                      size: 12
                    },
                    // beginAtZero: true,
                    stepSize:
                      this.computMax() === 0
                        ? Math.abs(this.computeMin()) / 4
                        : this.computMax() / 4,
                    // max: this.computMax(),
                    // min: this.computeMin(),
                    maxTicksLimit: 5,
                    callback: function (value) {
                      var ranges = [
                        { divider: 1e12, suffix: 'T' },
                        { divider: 1e9, suffix: 'B' },
                        { divider: 1e6, suffix: 'M' },
                        { divider: 1e3, suffix: 'K' },
                      ];
                      function formatNumber(val) {
                        let n = Math.abs(val);
                        for (var i = 0; i < ranges.length; i++) {
                          if (n >= ranges[i].divider) {
                            let tempRange =
                              (n / ranges[i].divider).toString().split('.').length >= 2
                                ? (n / ranges[i].divider).toFixed(0)
                                : n / ranges[i].divider;
                            return `${val < 0 ? '-' : ''}${tempRange.toString()}${' '}${
                              ranges[i].suffix
                            }`;
                          }
                        }
                        return val < 0 ? n * -1 : n;
                      }
                      return `${formatNumber(value)}`;
                    },
                    // maxRotation: 90,
                    // minRotation: 90,
                  },
                },
              //],
            },
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false,
              },
              tooltip: {
                enabled: false,
              }
            }
          }}
          id={id}
        />
      );
    }
  }

  return _DashboardBarChart;
})();

export default DashboardBarChart;
