import React, { Component } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'react-bootstrap/Tooltip';
import { GrooveIconSvg, GrooveButton, GrooveSwitchToggle, GrooveSelectMenuBasic } from 'tfo-groove';

import { TryGetObjValueAlt } from '../../../Utilities/Formatter';
import CVMInputFieldWithRadio from '../../Shared/CVMInputFieldWithRadio/CVMInputFieldWithRadio';
import CustomBOIModal from '../CustomBOIModal';
import { IsValidStr } from '../../../Utilities/Validations';
import SpecificBOIHistoryModal from '../SpecificBOIHistoryModal';

import addfiles from '../../../Static/images/360cvm/AddFilesIcon.svg';
import newInfo from '../../../Static/images/svg/new-info.svg';

import './DimensionAccordion.css';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import SwitchToggle from '../../Shared/CVMSwitchToggle/SwitchToggle';

const ProgramDimAccordionRejected = (function () {
  class ProgramDimAccordionRejectedPresentational extends Component {
    render() {
      let headers = this.props.userType
        ? [
          'Dimension',
          'Subdimension',
          'Business Outcome Indicators',
          'Target Value',
          'Unit of Measure',
          'Approver Comment',
          'Status',
          'Dashboard Visibility',
          '',
          '',
        ]
        : [
          'Dimension',
          'Subdimension',
          'Business Outcome Indicators',
          'Target Value',
          'Unit of Measure',
          'Approver Comment',
          'Status',
          'Dashboard Visibility',
          '',
        ];
      if (['review', 'edit', 'view'].indexOf(this.props.mode) > -1) headers.pop();
      const formCB = this.props.formCallback;
      const dimList = this.props.dimensionList;
      const boiList = this.props.dimensionListToDisplay;
      return (dimList.length || 0) === 0 ? (
        <div className="empty-dimension-container">
          <div className="prog-acct-table-header-container">
            <div class="table-title">{this.props.topLeftContainer}</div>
            <div className="accordion-action-btns">{this.props.topRightContainer}</div>
          </div>
          <div className="empty-program-container bodytext18-regular-slate">
            <img src={addfiles} alt="select client" />
            <span className="bodytext24-medium-slate">No Business Outcome Indicator Added</span>
            {this.props.type === 'Program'
              ? "Please select BOI(s) to this program by using 'Edit BOIs' button above."
              : "Please select BOI(s) to this project by using the 'Select BOIs' button above."}
          </div>
        </div>
      ) : (
        <div>
          <div className="prog-acct-table-header-container">
            <div class="table-title">{this.props.topLeftContainer}</div>
            <div className="accordion-action-btns">
              {this.props.topRightContainer}
              <GrooveButton
                id={'expandAll'}
                hasIcon={false}
                type=""
                colorClass=""
                text="Expand All"
                customClassName={
                  this.props.activeExpand ? 'expandAll-button active' : 'expandAll-button'
                }
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
              />
              <GrooveButton
                id={'collapseAll'}
                hasIcon={false}
                type=""
                colorClass=""
                text="Collapse All"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
                customClassName={
                  this.props.activeCollapse ? 'collapseAll-button active' : 'collapseAll-button'
                }
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
              />
            </div>
          </div>

          <div className="dimension-table dimension-table-program">
            <div className="dimension-table-header">
              <div className="dimension-table-row dimension-table-row-rejected">
                {headers.map((name) =>
                  name === 'Dashboard Visibility' ? (
                    <div className="row-visibility">
                      {name}
                      <a data-tip data-for="sadFace">
                        <div className="info-icon">
                          <GrooveIconSvg
                            size="large"
                            name="info-circle"
                            iconStyle="solid"
                            iconColor="stat-alternate"
                          />
                        </div>
                      </a>
                      <ReactTooltip id="sadFace" type="dark" effect="solid">
                        <span>Show this BOI on the Value Meter Dashboard?</span>
                      </ReactTooltip>
                    </div>
                  ) : (
                    <div className={name === 'Status' ? 'program-status-header' : ''}>{name}</div>
                  )
                )}
              </div>
            </div>
            <div className="dimension-table-body">
              {dimList.map((dimension, i) => {
                if (dimension.subDimensionList === undefined) return <></>;
                let firstSubDChild = dimension.subDimensionList[0];

                if (firstSubDChild === undefined) return <></>;
                if (firstSubDChild.boiList === undefined) return <></>;
                //let firstBoiChild = firstSubDChild.boiList[0];
                let firstBoiChild = boiList.filter(
                  (x) => x.dimension.value === dimension.dimensionID
                )[0];

                if (firstBoiChild === undefined) return <></>;
                let firstBoiChildIndex = boiList.findIndex(
                  (x) => x.dimension.value === dimension.dimensionID
                );
                let isFirstChildDisabled =
                  firstBoiChild.isExisting || firstBoiChild.missingDetails
                    ? false
                    : this.props.duplicateBOI || this.props.inComplete;
                let skipSubD = false;
                let firstBoicss =
                  firstBoiChild.boiStatus === 'Approved' || firstBoiChild.boiStatus === 'Approve'
                    ? 'dim-status-completed'
                    : firstBoiChild.boiStatus === 'Rejected'
                      ? 'dim-status-rejected'
                      : firstBoiChild.boiStatus === ''
                        ? 'dim-no-status'
                        : 'status-break dim-status-conditionallyApproved';

                const firstBoiChildAgnostic = firstBoiChild.boiParentID === 3;
                const firstBoiChildUsed =
                  this.props.type.toLowerCase() === 'program'
                    ? firstBoiChild.isBOIUsedInProject
                    : firstBoiChild.isBOIUsedInRVD;

                //Checks if Financial with uom of USD & has already approved RVD project  
                const firstBoiChildDimSDDisable = firstBoiChild.isDisableUpdatingDimension
                  && firstBoiChild.uom.value.toLowerCase() === 'usd'
                  && firstBoiChild.dimension.value === 1

                if (firstBoiChild.length === 0) {
                  return <></>;
                }

                return (
                  <Accordion
                    defaultActiveKey={String(i)}
                    className="dimension-table-collapse"
                    onSelect={(e) => {
                      this.props.onSelectAccordion(e);
                    }}
                  >
                    <Card key={i}>
                      <Accordion.Collapse eventKey={String(i)}>
                        <Card.Header className="dim-card-header">
                          <div className="dimension-header-container">
                            {dimension.subDimensionList.map((subD, i2) => {
                              return (
                                <>
                                  {subD.boiList.map((boi, i3) => {
                                    let tempCustomBOIID = boi.IsCustom ? `${boi.boiName}-${boi.uom}` : ""
                                    const css =
                                      (boi.boiStatus === 'Approved' || boi.boiStatus === 'Approve')
                                        ?
                                        'dim-status-completed'
                                        : boi.boiStatus === 'Rejected'
                                          ? 'dim-status-rejected'
                                          : boi.boiStatus === ''
                                            ? 'dim-no-status'
                                            : 'status-break dim-status-conditionallyApproved';

                                    const isBOIUsed =
                                      this.props.type.toLowerCase() === 'program'
                                        ? boi.isBOIUsedInProject
                                        : boi.isBOIUsedInRVD;
                                    const isAgnosticBOI = boi.boiParentID === 3;
                                    const boiForEditNotCustom = boiList.filter(
                                      (x) =>
                                        x.boiClientProgramTargetID ===
                                        boi.boiClientProgramTargetID &&
                                        x.boi.value === boi.boiID &&
                                        x.subdimension.value === subD.subDimensionID
                                    )[0];

                                    const boiForEditCustom = boiList.filter(
                                      (x) =>
                                        x.boiClientProgramTargetID ===
                                        boi.boiClientProgramTargetID &&
                                        x.boi.value === boi.boiID &&
                                        x.subdimension.value === subD.subDimensionID &&
                                        (boi.IsCustom ? `${x.boi.label}-${x.uom.label}` === tempCustomBOIID : '')
                                    )[0];
                                    const boiForEdit = boi.IsCustom ? boiForEditCustom : boiForEditNotCustom

                                    const boiForEditIndexNotCustom = boiList.findIndex(
                                      (x) =>
                                        x.boiClientProgramTargetID ===
                                        boi.boiClientProgramTargetID && x.boi.value === boi.boiID
                                        && x.subdimension.value === subD.subDimensionID
                                    );

                                    const boiForEditIndexCustom = boiList.findIndex(
                                      (x) =>
                                        x.boiClientProgramTargetID ===
                                        boi.boiClientProgramTargetID && x.boi.value === boi.boiID
                                        && x.subdimension.value === subD.subDimensionID &&
                                        (boi.IsCustom ? `${x.boi.label}-${x.uom.label}` === tempCustomBOIID : '')
                                    );
                                    const boiForEditIndex = boi.IsCustom ? boiForEditIndexCustom : boiForEditIndexNotCustom

                                    let isItemDisabled =
                                      boiForEdit.isExisting || boiForEdit.missingDetails
                                        ? false
                                        : this.props.duplicateBOI || this.props.inComplete;

                                    //Checks if Financial with uom of USD & has already approved RVD project  
                                    let isDimSubdimDisable = boiForEdit.isDisableUpdatingDimension
                                      && boiForEdit.uom.value.toLowerCase() === 'usd'
                                      && boiForEdit.dimension.value === 1

                                    if (!skipSubD) {
                                      skipSubD = true;
                                      return <></>;
                                    }
                                    if (boi.isActive === 0) {
                                      return (
                                        <div className="deleted-bois">
                                          <GrooveIconSvg
                                            customClassName="close-modal-btn"
                                            size="large"
                                            name="exclamation-triangle"
                                            iconStyle="solid"
                                            iconColor="stat-alternate"
                                          />
                                          <div className="deleted-bois-text">
                                            The Business Outcome Indicator
                                            <div>
                                              <div className="deleted-boi-details">
                                                <div className="deleted-boi-details-label">
                                                  BOI: <span>{boi.boiName}</span>
                                                </div>
                                                <div className="deleted-boi-details-label">
                                                  Unit of Measure: <span>{boi.uom}</span>
                                                </div>
                                                <div className="deleted-boi-details-label">
                                                  Dimension: <span>{dimension.dimensionName}</span>
                                                </div>
                                                <div className="deleted-boi-details-label">
                                                  Subdimension: <span>{subD.subDimensionName}</span>
                                                </div>
                                              </div>
                                              '{boi.boiName}'
                                            </div>
                                            has been deleted. Click on the undo button to restore
                                            the deleted BOI.
                                          </div>
                                          <GrooveButton
                                            id="primry-btn-3"
                                            type="solid"
                                            colorClass="stat-alternate"
                                            text="Undo Delete"
                                            customClassName="undo-deleted-boi-btn"
                                            callbackFunction={() =>
                                              this.props.restoreDeletedBOI(i, i2, i3)
                                            }
                                          />
                                          <GrooveIconSvg
                                            customClassName="close-modal-btn"
                                            size="large"
                                            name="times"
                                            iconStyle="solid"
                                            iconColor="stat-neutral"
                                            callbackFunction={() =>
                                              this.props.removeDeletedBOI(i, i2, i3)
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                    return (
                                      <div
                                        className={`dimension-table-row 
                                        dimension-table-row-rejected 
                                        ${boiForEdit.isExisting || boiForEdit.missingDetails
                                            ? 'dimension-table-row-duplicate-boi'
                                            : ''
                                          }`}
                                      >
                                        <div className={isAgnosticBOI ? 'dim-name' : ''}>
                                          <GrooveSelectMenuBasic
                                            name="dimension"
                                            list={boiForEdit.dimensionList}
                                            defaultData={boiForEdit.dimension}
                                            isDisabled={
                                              (boiForEdit.uomList.length === 0 &&
                                                JSON.stringify(boiForEdit.uom) === '{}') ||
                                              isItemDisabled ||
                                              isAgnosticBOI ||
                                              isDimSubdimDisable ||
                                              boi.IsCustom
                                            }
                                            id="dimension"
                                            contentType="search"
                                            customClassName="rejected-accordion-dropdown"
                                            callbackFunction={(e) =>
                                              this.props.onEditBOI(
                                                e,
                                                'dimension',
                                                boiForEdit,
                                                boiForEditIndex
                                              )
                                            }
                                          />
                                          {isAgnosticBOI && (
                                            <span className="boiAgnosticdimension tooltiptext">
                                              For Offering Agnostic BOIs, user cannot change the
                                              mapped Dimension.
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          <div className={isAgnosticBOI ? 'dim-name' : ''}>
                                            <GrooveSelectMenuBasic
                                              name="subdimension"
                                              isDisabled={
                                                (boiForEdit.uomList.length === 0 &&
                                                  JSON.stringify(boiForEdit.uom) === '{}') ||
                                                isItemDisabled ||
                                                isAgnosticBOI ||
                                                isDimSubdimDisable
                                              }
                                              list={boiForEdit.subdimensionList}
                                              defaultData={boiForEdit.subdimension}
                                              id="subdimension"
                                              contentType="search"
                                              customClassName="rejected-accordion-dropdown"
                                              callbackFunction={(e) =>
                                                this.props.onEditBOI(
                                                  e,
                                                  'subdimension',
                                                  boiForEdit,
                                                  boiForEditIndex
                                                )
                                              }
                                            />
                                            {isAgnosticBOI && (
                                              <span className="boiAgnosticdimension tooltiptext">
                                                For Offering Agnostic BOIs, user cannot change the
                                                mapped Subdimension.
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        <div className="row boi-column">
                                          <div>
                                            {boi.boiName}
                                            {!boi.IsCustom && (
                                              <div className="info-icon-wrap">
                                                <OverlayTrigger
                                                  key="right"
                                                  placement="auto"
                                                  overlay={
                                                    <Tooltip id="boi-tooltip-right">
                                                      <div className="boi-details-wrap row">
                                                        <div class="col-md-6">
                                                          <h6>Offering</h6>
                                                          <p class="">{boi.offeringName}</p>
                                                          <h6>Dimension</h6>
                                                          <p class="">{dimension.dimensionName}</p>
                                                          <h6>BOI Description</h6>
                                                          <p class="">{boi.boiDescription}</p>
                                                        </div>
                                                        <div class="col-md-6">
                                                          <h6>Sub-Offering</h6>
                                                          <p class="">{boi.suboffering}</p>
                                                          <h6>Sub-dimension</h6>
                                                          <p class="">{subD.subDimensionName}</p>
                                                          <h6>Leading Practice</h6>
                                                          <p class="">{boi.leadingIndicator}</p>
                                                          <table>
                                                            <tr class="col-md-12">
                                                              <th class="col-md-6">
                                                                <h6>Parameter</h6>
                                                              </th>
                                                              <th class="col-md-6">
                                                                <h6>Unit of Measure</h6>
                                                              </th>
                                                            </tr>
                                                            {(boi.hasOwnProperty('parameterList')
                                                              ? boi.parameterList
                                                              : []
                                                            ).map((params) => {
                                                              return (
                                                                <tr>
                                                                  <td class="col-md-6">
                                                                    <p>{params.ParameterName}</p>
                                                                  </td>
                                                                  <td class="col-md-6">
                                                                    <p>{params.ParameterUOM}</p>
                                                                  </td>
                                                                </tr>
                                                              );
                                                            })}
                                                          </table>
                                                        </div>
                                                      </div>
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div className="dimension-new-info">
                                                    <img
                                                      className="new-info-icon"
                                                      src={newInfo}
                                                      alt="info"
                                                    />
                                                  </div>
                                                </OverlayTrigger>
                                              </div>
                                            )}

                                            {
                                              boi.boiStatus === 'Rejected' &&
                                              <OverlayTrigger
                                                key="right"
                                                placement="auto"
                                                overlay={
                                                  <Tooltip id="reject-rdbtn-tooltip">
                                                    Value reported will not be reflected in the dashboard as BOI is rejected at program/project level.
                                                  </Tooltip>
                                                }
                                              >
                                                <div>
                                                  <GrooveIconSvg
                                                    size="large"
                                                    name="exclamation-triangle"
                                                    iconStyle="solid"
                                                    iconColor="stat-alternate"
                                                    customClassName="reject-alert first-boi"
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                            }

                                            {boi.IsCustom ? (
                                              <button
                                                className="custom-boi"
                                                onClick={() =>
                                                  this.props.onClickCustomBOI(
                                                    boi.boiID,
                                                    true,
                                                    i,
                                                    i2,
                                                    i3
                                                  )
                                                }
                                              >
                                                Custom
                                              </button>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div>
                                          <CVMInputFieldWithRadio
                                            key={i3}
                                            mode={''} // this.props.mode
                                            isInvalid={true}
                                            status={boi.status}
                                            isApprover={true}
                                            dimI={i}
                                            subDI={i2}
                                            boiI={i3}
                                            boi={boi}
                                            callbackFunction={formCB}
                                          />
                                        </div>
                                        <div
                                          className={
                                            isBOIUsed || isAgnosticBOI
                                              ? 'disabled-uom-accordion'
                                              : ''
                                          }
                                        >
                                          <GrooveSelectMenuBasic
                                            name="unitofMeasure"
                                            isDisabled={
                                              (boiForEdit.uomList.length === 0 &&
                                                JSON.stringify(boiForEdit.uom) === '{}') ||
                                              isItemDisabled ||
                                              boiForEdit.isBOIUsedInProject ||
                                              isAgnosticBOI
                                            }
                                            list={boiForEdit.uomList}
                                            defaultData={boiForEdit.uom}
                                            id="uom"
                                            contentType="search"
                                            customClassName="rejected-accordion-dropdown"
                                            callbackFunction={(e) =>
                                              this.props.onEditBOI(
                                                e,
                                                'uom',
                                                boiForEdit,
                                                boiForEditIndex
                                              )
                                            }
                                          />
                                          {
                                            (isAgnosticBOI && isBOIUsed) && (
                                              <span className="boiUomUsedInProj tooltiptext">
                                                For Offering Agnostic BOIs, user cannot change the
                                                mapped UOM.
                                              </span>
                                            )
                                            ||
                                            isAgnosticBOI && (
                                              <span className="boiUomUsedInProj tooltiptext">
                                                For Offering Agnostic BOIs, user cannot change the
                                                mapped UOM.
                                              </span>
                                            )
                                            ||
                                            isBOIUsed && (
                                              <span className="boiUomUsedInProj tooltiptext">
                                                This BOI is already selected by a project within this
                                                program. Kindly select other BOI from the list.
                                              </span>
                                            )
                                          }
                                        </div>
                                        {['viewWithComment', 'editWithComment'].indexOf(
                                          this.props.mode
                                        ) > -1 && (
                                            <div>
                                              <textarea
                                                className="comment-input"
                                                name="comment"
                                                placeholder={boi.boiOldComment}
                                                onChange={(e) => {
                                                  formCB('comment', i, i2, i3, e);
                                                }}
                                                readOnly
                                              />
                                            </div>
                                          )}
                                        <div>
                                          <div style={{ left: '-3px', position: 'relative' }} className={'program-status boi-status ' + css}>
                                            {boi.boiStatus}
                                          </div>
                                        </div>

                                        <div>
                                          <SwitchToggle
                                            id={'switch-tgl-01' + i}
                                            callbackFunction={(e) => {
                                              if (boi.boiStatus === 'Rejected' && [0, undefined, false].indexOf(boi.boiWheelVisibility) > -1) {
                                                this.props.showMessage('switch-tgl-01' + i, i, i2, i3, e)
                                              } else {
                                                formCB('wheel', i, i2, i3, e);
                                              }
                                              return true;
                                            }}
                                            toggle_labels=""
                                            defaultValue={
                                              boi.boiWheelVisibility === 1 ? true : false
                                            }
                                            isDisabled={
                                              this.props.type === 'Program' ? false : true
                                            }
                                            customClassName={
                                              this.props.type === 'Program'
                                                ? 'wheel-visibility-button-approve'
                                                : 'wheel-visibility-button-approve disabled-switch'
                                            }
                                          />
                                        </div>

                                        {IsValidStr(this.props.userType) && (
                                          <SpecificBOIHistoryModal
                                            dimensionID={dimension.dimensionID}
                                            subDimensionID={subD.subDimensionID}
                                            boiID={boi.boiID}
                                            uom={boi.uom}
                                            historyType={this.props.type}
                                            clientProgramTargetID={boi.boiClientProgramTargetID}
                                            clientProjectTargetID={boi.boiClientProjectTargetID}
                                          />
                                        )}

                                        <div>
                                          <div className={isBOIUsed ? 'disabled-delete' : ''}>
                                            <GrooveIconSvg
                                              className="quick-delete"
                                              size="large"
                                              name="trash-alt"
                                              iconStyle="solid"
                                              iconColor="stat-neutral"
                                              callbackFunction={() => {
                                                if (!isBOIUsed)
                                                  this.props.quickDeleteOnClick(i, i2, i3);
                                              }}
                                            />
                                            {isBOIUsed && (
                                              <span className="quickDelete tooltiptext">
                                                {isAgnosticBOI
                                                  ? `Delivered value is already reported for this BOI. 
                                                    Hence the same cannot be de-selected from this program.`
                                                  : `This BOI is already selected by a project within this program.
                                                    In order to remove this BOI from the list, kindly remove the
                                                    same from the project level.` }
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </div>
                        </Card.Header>
                      </Accordion.Collapse>
                      <Card.Header className="dim-card-header dim-history">
                        <div
                          className="dimension-header-container"
                          onClick={(e) => this.props.onClickAccordion(e, 'div')}
                        >
                          {firstBoiChild.isActive === 0 ? (
                            <div className="deleted-bois" style={{ width: '100%' }}>
                              <GrooveIconSvg
                                customClassName="close-modal-btn"
                                size="large"
                                name="exclamation-triangle"
                                iconStyle="solid"
                                iconColor="stat-alternate"
                              />
                              <div className="deleted-bois-text">
                                The Business Outcome Indicator
                                <div>
                                  <div className="deleted-boi-details">
                                    <div className="deleted-boi-details-label">
                                      BOI: <span>{firstBoiChild.boi.label}</span>
                                    </div>
                                    <div className="deleted-boi-details-label">
                                      Unit of Measure: <span>{firstBoiChild.uom.label}</span>
                                    </div>
                                    <div className="deleted-boi-details-label">
                                      Dimension: <span>{dimension.dimensionName}</span>
                                    </div>
                                    <div className="deleted-boi-details-label">
                                      Subdimension: <span>{firstSubDChild.subDimensionName}</span>
                                    </div>
                                  </div>
                                  '{firstBoiChild.boi.label}'
                                </div>
                                has been deleted. Click on the undo button to restore the deleted
                                BOI.
                              </div>
                              <GrooveButton
                                id="primry-btn-3"
                                type="solid"
                                colorClass="stat-alternate"
                                text="Undo Delete"
                                customClassName="undo-deleted-boi-btn"
                                callbackFunction={() => this.props.restoreDeletedBOI(i, 0, 0)}
                              />
                              <GrooveIconSvg
                                customClassName="close-modal-btn"
                                size="large"
                                name="times"
                                iconStyle="solid"
                                iconColor="stat-neutral"
                                callbackFunction={() => this.props.removeDeletedBOI(i, 0, 0)}
                              />
                            </div>
                          ) : (
                            <div
                              className={`dimension-table-row 
                              dimension-table-row-rejected 
                              ${firstBoiChild.isExisting || firstBoiChild.missingDetails
                                  ? 'dimension-table-row-duplicate-boi'
                                  : ''
                                }`}
                            >
                              <div className={firstBoiChildAgnostic ? 'dim-name ' : ''}>
                                <GrooveSelectMenuBasic
                                  name="dimension"
                                  list={firstBoiChild.dimensionList}
                                  defaultData={firstBoiChild.dimension}
                                  isDisabled={
                                    (firstBoiChild.uomList.length === 0 &&
                                      JSON.stringify(firstBoiChild.uom) === '{}') ||
                                    isFirstChildDisabled ||
                                    firstBoiChildAgnostic ||
                                    firstBoiChildDimSDDisable ||
                                    firstBoiChild.IsCustom
                                  }
                                  id="dimension"
                                  contentType="search"
                                  customClassName="rejected-accordion-dropdown"
                                  callbackFunction={(e) =>
                                    this.props.onEditBOI(
                                      e,
                                      'dimension',
                                      firstBoiChild,
                                      firstBoiChildIndex
                                    )
                                  }
                                />
                                {firstBoiChildAgnostic && (
                                  <span className="boiAgnosticdimension tooltiptext">
                                    For Offering Agnostic BOIs, user cannot change the mapped
                                    Dimension.
                                  </span>
                                )}
                              </div>
                              <div>
                                <div className={firstBoiChildAgnostic ? 'dim-name ' : ''}>
                                  <GrooveSelectMenuBasic
                                    name="subdimension"
                                    isDisabled={
                                      (firstBoiChild.uomList.length === 0 &&
                                        JSON.stringify(firstBoiChild.uom) === '{}') ||
                                      isFirstChildDisabled ||
                                      firstBoiChildAgnostic ||
                                      firstBoiChildDimSDDisable
                                    }
                                    list={firstBoiChild.subdimensionList}
                                    defaultData={firstBoiChild.subdimension}
                                    id="subdimension"
                                    contentType="search"
                                    customClassName="rejected-accordion-dropdown"
                                    callbackFunction={(e) =>
                                      this.props.onEditBOI(
                                        e,
                                        'subdimension',
                                        firstBoiChild,
                                        firstBoiChildIndex
                                      )
                                    }
                                  />
                                  {firstBoiChildAgnostic && (
                                    <span className="boiAgnosticdimension tooltiptext">
                                      For Offering Agnostic BOIs, user cannot change the mapped
                                      Subdimension.
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="row boi-column">
                                <div>
                                  {TryGetObjValueAlt(firstBoiChild, '', 'boi.label')}
                                  {(firstBoiChild.boi.label.length || false) &&
                                    !firstBoiChild.IsCustom && (
                                      <div className="info-icon-wrap">
                                        <OverlayTrigger
                                          key="right"
                                          placement="auto"
                                          overlay={
                                            <Tooltip id="boi-tooltip-right">
                                              <div className="boi-details-wrap row">
                                                <div class="col-md-6">
                                                  <h6>Offering</h6>
                                                  <p class="">{firstBoiChild.offeringName}</p>
                                                  <h6>Dimension</h6>
                                                  <p class="">{dimension.dimensionName}</p>
                                                  <h6>BOI Description</h6>
                                                  <p class="">{firstBoiChild.boiDescription}</p>
                                                </div>
                                                <div class="col-md-6">
                                                  <h6>Sub-Offering</h6>
                                                  <p class="">{firstBoiChild.suboffering.label}</p>
                                                  <h6>Sub-dimension</h6>
                                                  <p class="">{firstSubDChild.subDimensionName}</p>
                                                  <h6>Leading Practice</h6>
                                                  <p class="">{firstBoiChild.leadingIndicator}</p>
                                                  <table>
                                                    <tr class="col-md-12">
                                                      <th class="col-md-6">
                                                        <h6>Parameter</h6>
                                                      </th>
                                                      <th class="col-md-6">
                                                        <h6>Unit of Measure</h6>
                                                      </th>
                                                    </tr>
                                                    {(firstBoiChild.hasOwnProperty('parameterList')
                                                      ? firstBoiChild.parameterList
                                                      : []
                                                    ).map((params) => {
                                                      return (
                                                        <tr>
                                                          <td class="col-md-6">
                                                            <p>{params.ParameterName}</p>
                                                          </td>
                                                          <td class="col-md-6">
                                                            <p>{params.ParameterUOM}</p>
                                                          </td>
                                                        </tr>
                                                      );
                                                    })}
                                                  </table>
                                                </div>
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="dimension-new-info">
                                            <img
                                              className="new-info-icon"
                                              src={newInfo}
                                              alt="info"
                                            />
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                </div>
                                {
                                  firstBoiChild.boiStatus === 'Rejected' &&
                                  <OverlayTrigger
                                    key="right"
                                    placement="auto"
                                    overlay={
                                      <Tooltip id="reject-rdbtn-tooltip">
                                        Value reported will not be reflected in the dashboard as BOI is rejected at program/project level.
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      <GrooveIconSvg
                                        size="large"
                                        name="exclamation-triangle"
                                        iconStyle="solid"
                                        iconColor="stat-alternate"
                                        customClassName="reject-alert first-boi"
                                      />
                                    </div>
                                  </OverlayTrigger>
                                }
                                {firstBoiChild.IsCustom ? (
                                  <div>
                                    <div>
                                      <button
                                        className="custom-boi"
                                        onClick={() =>
                                          this.props.onClickCustomBOI(
                                            firstBoiChild.boi.value,
                                            true,
                                            i,
                                            0,
                                            0
                                          )
                                        }
                                      >
                                        Custom
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <CVMInputFieldWithRadio
                                  mode="" // this.props.mode
                                  isInvalid={true}
                                  isApprover={true}
                                  dimI={i}
                                  subDI={0}
                                  boiI={0}
                                  boi={firstBoiChild}
                                  callbackFunction={formCB}
                                />
                              </div>
                              <div
                                className={
                                  firstBoiChildUsed || firstBoiChildAgnostic
                                    ? 'disabled-uom-accordion'
                                    : ''
                                }
                              >
                                <GrooveSelectMenuBasic
                                  name="unitofMeasure"
                                  isDisabled={
                                    (firstBoiChild.uomList.length === 0 &&
                                      JSON.stringify(firstBoiChild.uom) === '{}') ||
                                    isFirstChildDisabled ||
                                    firstBoiChild.isBOIUsedInProject ||
                                    firstBoiChildAgnostic
                                  }
                                  list={firstBoiChild.uomList}
                                  defaultData={firstBoiChild.uom}
                                  id="uom"
                                  contentType="search"
                                  customClassName="rejected-accordion-dropdown"
                                  callbackFunction={(e) =>
                                    this.props.onEditBOI(
                                      e,
                                      'uom',
                                      firstBoiChild,
                                      firstBoiChildIndex
                                    )
                                  }
                                />
                                {
                                  (firstBoiChildAgnostic && firstBoiChildUsed) && (
                                    <span className="boiUomUsedInProj tooltiptext">
                                      For Offering Agnostic BOIs, user cannot change the mapped UOM.
                                    </span>
                                  )

                                  ||
                                  firstBoiChildAgnostic && (
                                    <span className="boiUomUsedInProj tooltiptext">
                                      For Offering Agnostic BOIs, user cannot change the mapped UOM.
                                    </span>
                                  )
                                  ||
                                  firstBoiChildUsed && (
                                    <span className="boiUomUsedInProj tooltiptext">
                                      This BOI is already selected by a project within this program.
                                      Kindly select other BOI from the list.
                                    </span>
                                  )
                                }
                              </div>
                              {['viewWithComment', 'editWithComment'].indexOf(this.props.mode) >
                                -1 && (
                                  <div>
                                    <textarea
                                      className="comment-input"
                                      name="comment"
                                      placeholder={TryGetObjValueAlt(firstBoiChild, '', 'boiOldComment')}
                                      onChange={(e) => {
                                        formCB('comment', i, 0, 0, e);
                                      }}
                                      readOnly
                                    />
                                  </div>
                                )}
                              <div>
                                <div style={{ left: '-3px', position: 'relative' }} className={'program-status boi-status ' + firstBoicss}>
                                  {firstBoiChild.boiStatus}
                                </div>
                              </div>

                              <div>
                                <SwitchToggle
                                  id={'switch-tgl-01' + i}
                                  callbackFunction={(e) => {
                                    if (firstBoiChild.boiStatus === 'Rejected' && [0, undefined, false].indexOf(firstBoiChild.boiWheelVisibility) > -1) {
                                      this.props.showMessage('switch-tgl-01' + i, i, 0, 0, e)
                                    } else {
                                      formCB('wheel', i, 0, 0, e);
                                    }
                                    return true;
                                  }}
                                  toggle_labels=""
                                  defaultValue={
                                    firstBoiChild.boiWheelVisibility === 1 ? true : false
                                  }
                                  isDisabled={this.props.type === 'Program' ? false : true}
                                  customClassName={
                                    this.props.type === 'Program'
                                      ? 'wheel-visibility-button-approve'
                                      : 'wheel-visibility-button-approve disabled-switch'
                                  }
                                />
                              </div>

                              {IsValidStr(this.props.userType) && (
                                <SpecificBOIHistoryModal
                                  dimensionID={dimension.dimensionID}
                                  subDimensionID={firstSubDChild.subDimensionID}
                                  boiID={firstBoiChild.boi.value}
                                  uom={firstBoiChild.uom.label}
                                  historyType={this.props.type}
                                  clientProgramTargetID={firstBoiChild.boiClientProgramTargetID}
                                  clientProjectTargetID={firstBoiChild.boiClientProjectTargetID}
                                />
                              )}

                              <div>
                                <div className={firstBoiChildUsed ? 'disabled-delete' : ''}>
                                  <GrooveIconSvg
                                    className="quick-delete"
                                    size="large"
                                    name="trash-alt"
                                    iconStyle="solid"
                                    iconColor="stat-neutral"
                                    callbackFunction={() => {
                                      if (!firstBoiChildUsed)
                                        this.props.quickDeleteOnClick(i, 0, 0);
                                    }}
                                  />

                                  {firstBoiChildUsed && (
                                    <span className="quickDelete tooltiptext">
                                      {firstBoiChildAgnostic
                                        ? `Delivered value is already reported for this BOI. 
                                          Hence the same cannot be de-selected from this program.`
                                        : `This BOI is already selected by a project within this program.
                                          In order to remove this BOI from the list, kindly remove the
                                          same from the project level.` }
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <Accordion.Toggle
                          id="dim-btn"
                          className="dim-acc-btn"
                          as={Button}
                          variant="link"
                          eventKey={String(i)}
                          onClick={(e) => this.props.onClickAccordion(e, 'btn')}
                        >
                          <GrooveIconSvg
                            id="basicDetailsIcon"
                            customClassName="accordion-button"
                            size="large"
                            name="chevron-right"
                            iconStyle="solid"
                            iconColor="stat-neutral"
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                  </Accordion>
                );
              })}
            </div>
          </div>
          {this.props.showCustomBOI && (
            <CustomBOIModal
              dimensionName={this.props.dimensionName}
              subDimensionName={this.props.subDimensionName}
              isShow={this.props.showCustomBOI}
              onCustomBOIModalCancelClose={() =>
                this.props.onClickCustomBOI(0, false, null, null, null)
              }
              boi={this.props.boi}
            />
          )}

          <CVMModal
            show={this.props.showEditToggle}
            onCloseModal={this.props.onCloseModal}
            size="md"
            content={
              <ModalContent
                onClickOk={this.props.onClickOk}
                onClickCancel={this.props.onCloseModal}
              />
            }
          />
        </div>
      );
    }
  }

  class ModalContent extends Component {
    render() {
      return (
        <div className='reject-msg-on-toggle'>
          <p>
            <GrooveIconSvg
              size="large"
              name="exclamation-circle"
              iconStyle="solid"
              iconColor="stat-alternate"
              className="reject-BOI-msg"
            />
          </p>
          <p className="header">You will have to send the program for Approval again</p>
          <p className="body-msg">Changes will be reflected only once the Ops Account Lead approves it</p>

          <div className='reject-msg-btn-container'>
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              type="outline"
              colorClass="stat-alternate"
              text="Cancel"
              callbackFunction={() =>
                this.props.onClickCancel('Cancel')
              }
            />
            <GrooveButton
              id="primry-btn-3"
              type="solid"
              colorClass="stat-alternate"
              text="Edit"
              callbackFunction={() =>
                this.props.onClickOk()
              }
            />
          </div>
        </div>
      )
    }
  }

  class ProgramDimAccordionRejectedContainer extends Component {
    constructor() {
      super();
      this.state = {
        activeExpand: false,
        activeCollapse: false,
        accordionCount: 0,
        onLoadExpand: false,
        isCollapsed: false,
        showCustomBOI: false,
        customDetails: [],
        dimensionName: '',
        subDimensionName: '',
        boi: {
          ClientName: '',
          offeringName: '',
          boiName: '',
          uom: '',
          description: '',
          LeadingIndicator: '',
          comment: '',
          MetricCalculation: '',
          customParameterList: [],
          AggregationLogic: '',
          SubOffering: '',
          AggregationLogicId: 0,
          MetricReason: '',
          AggregateReason: '',
          WheelVisibility: 0,
        },
        deletedBOI: [],
        tempBOIListForEditBOI: [],
        showEditToggle: false,
        params: {
          dimI: 0,
          subDI: 0,
          boiI: 0,
          e: null
        },
        toggleId: ''
      };
    }

    componentDidUpdate = (prevProps) => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.onExpandCollapse(null, 'onload');
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
      if (this.state.activeExpand === false) {
        setTimeout(this.props.listenToScroll, 200);
      }
    };

    componentDidMount = () => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.onExpandCollapse(null, 'onload');
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
    };

    onExpandCollapse = (e, action) => {
      try {
        let elems = [];
        let isFirstStage = window.location.pathname.toLowerCase().includes('idvaluesettings')
          ? true
          : false;
        let caseString =
          action !== undefined && action === 'onload'
            ? 'expandAll'
            : e.currentTarget.id.includes('expandAll')
              ? 'expandAll'
              : 'collapseAll';
        switch (caseString) {
          case 'expandAll':
            elems = document.getElementsByClassName('collapse');
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === 'collapse') {
                  document
                    .getElementsByClassName('collapse')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === 'collapse') {
                  document
                    .getElementsByClassName('collapse')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: true,
              activeCollapse: false,
            });
            break;
          case 'collapseAll':
            elems = document.getElementsByClassName('collapse show');
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === 'collapse show') {
                  document
                    .getElementsByClassName('collapse show')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === 'collapse show') {
                  document
                    .getElementsByClassName('collapse show')
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: false,
              activeCollapse: true,
            });
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
    };

    onClickCustomBOI = async (boiId, isOpen, dimIdx, sdIdx, boiIdx) => {
      let programId = this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
      const isNew = boiId > 0 && programId !== undefined ? false : true;
      let isProject = this.props.type.toLowerCase() === 'project';
      this.setState({
        showCustomBOI: !this.state.showCustomBOI,
      });
      try {
        if (isOpen && isProject) await this.props.getCustomBOIDetails(programId, boiId);
        const data = isOpen && isProject ? this.props.customBOI : this.props.dimensionList;
        const hasData = Object.keys(data).length > 0 ? true : false;

        if (isOpen && hasData) {
          const subDimensionName = isProject
            ? data[0].SubDimensionName
            : data[dimIdx].subDimensionList[sdIdx].subDimensionName;
          const boi = isProject ? data[0] : data[dimIdx].subDimensionList[sdIdx].boiList[boiIdx];
          const newBoi = {
            ClientName: isProject
              ? boi.ClientName
              : this.props.ProgramConfigurationData.programClientDetails.ClientMasterName,
            offeringName: isProject
              ? boi.Offering
              : this.props.ProgramConfigurationData.newProgramDetails.offering.label,
            boiName: isProject ? boi.BOIName : boi.boiName,
            uom: isProject ? boi.UOM : boi.uom,
            description: isProject ? boi.BOIDescription : boi.boiDescription,
            LeadingIndicator: isProject ? boi.LeadingIndicator : boi.leadingIndicator,
            comment: isProject ? boi.BOIAdditionComment : boi.boiAdditionComment,
            MetricCalculation: isProject ? boi.MetricCalculation : boi.boiDefinition,
            newSubDimensionName: isProject ? boi.SubDimensionName : boi.newSubDimensionName,
            customParameterList: hasData
              ? isProject
                ? boi.CustomParameterList.map((x, i) => {
                  return {
                    ParameterCustomCode: x.ParameterName,
                    ParameterUOM: x.ParameterUOM,
                  };
                })
                : boi.customParameterList.map((x, i) => {
                  return {
                    ParameterCustomCode: x.ParameterName,
                    ParameterUOM: x.ParameterUOM,
                  };
                })
              : [],
            AggregationLogic: isProject ? boi.AggregationLogic : boi.aggregationLogic,
            SubOffering: isProject ? boi.SubOfferingName : boi.suboffering,
            AggregationLogicId: isProject
              ? boi.AggregationLogicMasterID
              : boi.aggregationLogicMasterID,
            MetricReason: isProject ? boi.ReasonForNoBOIDefinition : boi.reasonForNoBOIDefinition,
            AggregateReason: isProject
              ? boi.ReasonForNoAggregationLogic
              : boi.reasonForNoAggregationLogic,
            WheelVisibility: isProject ? boi.WheelVisibility : boi.boiWheelVisibility,
          };

          this.setState({
            dimensionName: isProject ? data[0].CoreDimensionName : data[dimIdx].dimensionName,
            subDimensionName: subDimensionName,
            boi: newBoi,
          });
        } else {
          this.setState({
            dimensionName: '',
            subDimensionName: '',
            boi: {
              ClientName: '',
              offeringName: '',
              boiName: '',
              uom: '',
              description: '',
              LeadingIndicator: '',
              comment: '',
              MetricCalculation: '',
              customParameterList: [],
              AggregationLogic: '',
              SubOffering: '',
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    onSelectAccordion = (e) => {
      try {
        let accordionCount = this.state.accordionCount;
        let activeCollapse = false; //accordion close
        let activeExpand = false; //accordion open

        let dimensionList =
          this.props.dimensionList !== undefined
            ? this.props.dimensionList
            : this.state.dimensionList; //change
        if (e === null) {
          accordionCount -= 1;
        } else {
          accordionCount += 1;
        }

        if (dimensionList.length === accordionCount) {
          activeExpand = true;
          activeCollapse = false;
        } else if (accordionCount === 0) {
          activeCollapse = true;
          activeExpand = false;
        }

        this.setState({
          accordionCount: accordionCount,
          activeExpand: activeExpand,
          activeCollapse: activeCollapse,
        });
      } catch (e) {
        console.log(e);
      }
    };

    formCallback = (type, dimI, subDI, boiI, e) => {
      try {
        let tBOI = this.props.dimensionList[dimI].subDimensionList[subDI].boiList[boiI];
        let uom = tBOI.uom;
        let status = '';
        switch (type) {
          case 'comment':
            tBOI.comment = e.target.value;
            delete tBOI.inValidComment;
            break;
          case 'status':
            tBOI.boiStatus = e;
            status = 'Rejected' ? 'Rejected' : '';
            break;
          case 'parameter':
            tBOI.paramValue = e.value;
            if (e.inValidMsg.length > 0) {
              tBOI.inValidMsg = e.inValidMsg;
            } else {
              delete tBOI.inValidMsg;
            }
            delete tBOI.inValidParam;
            break;
          case 'wheel':
            tBOI.boiWheelVisibility = e.value ? 1 : 0;
            break;
          default:
            break;
        }
        let tDimFinal = this.props.dimensionList;
        tDimFinal[dimI].subDimensionList[subDI].boiList[boiI] = tBOI;

        if (status === 'Rejected') {
          tDimFinal[dimI].dimensionStatus = status;
          tDimFinal[dimI].subDimensionList[subDI].subDimensionStatus = status;
        }

        this.props.formCallback(tDimFinal);
      } catch (e) { }
    };

    onClickAccordion = (e, action) => {
      let isCurrentlyCollapsed =
        e.currentTarget.parentElement.previousSibling.className === 'collapse' ? true : false;
      if (action === 'div' && isCurrentlyCollapsed) {
        e.currentTarget.nextSibling.click();
        e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
      } else {
        //btn
        if (!isCurrentlyCollapsed) {
          e.currentTarget.parentElement.parentElement.style.cursor = 'pointer';
        } else {
          e.currentTarget.parentElement.parentElement.style.removeProperty('cursor');
        }
      }
    };

    quickDeleteOnClick = (dimIdx, subDimIdx, boiIdx) => {
      try {
        const dimList = this.props.dimensionList;
        dimList[dimIdx].subDimensionList[subDimIdx].boiList[boiIdx].isActive = 0;
        this.props.formCallback(dimList);
      } catch (e) {
        console.log(e);
      }
    };

    restoreDeletedBOI = (dimIdx, subDimIdx, boiIdx) => {
      try {
        const dimList = this.props.dimensionList;
        dimList[dimIdx].subDimensionList[subDimIdx].boiList[boiIdx].isActive = 1;
        this.props.formCallback(dimList);
      } catch (e) {
        console.log(e);
      }
    };

    removeDeletedBOI = (dimIdx, subDimIdx, boiIdx) => {
      try {
        const dimList = this.props.dimensionList;
        let deletedDimList = [];
        if (
          !dimList[dimIdx].subDimensionList[subDimIdx].boiList[boiIdx].isActive &&
          dimList[dimIdx].subDimensionList[subDimIdx].boiList.length === 1 &&
          dimList[dimIdx].subDimensionList.length === 1
        ) {
          deletedDimList = dimList.splice(dimIdx, 1);
        } else if (
          !dimList[dimIdx].subDimensionList[subDimIdx].boiList[boiIdx].isActive &&
          dimList[dimIdx].subDimensionList[subDimIdx].boiList.length === 1
        ) {
          deletedDimList = dimList[dimIdx].subDimensionList.splice(subDimIdx, 1);
        } else {
          deletedDimList = dimList[dimIdx].subDimensionList[subDimIdx].boiList.splice(boiIdx, 1);
        }
        this.props.formCallback(dimList, 'removeDeleted');
        this.props.deletedBOI(deletedDimList);
      } catch (e) {
        console.log(e);
      }
    };

    onCloseModal = (action) => {
      const params = this.state.params;
      params.e.value = false;
      this.setState({
        showEditToggle: false
      }, () => {
        this.formCallback('wheel', params.dimI, params.subDI, params.boiI, params.e);
      })
    }

    onClickOk = () => {
      const params = this.state.params
      params.e.value = true;
      this.setState({
        showEditToggle: false
      }, () => {
        this.formCallback('wheel', params.dimI, params.subDI, params.boiI, params.e)
      })
    }

    showMessage = (toggleId, dimI, subDI, boiI, e) => {
      this.setState({
        toggleId: toggleId,
        showEditToggle: true,
        params: {
          dimI: dimI,
          subDI: subDI,
          boiI: boiI,
          e: e
        }
      })
    }

    render() {
      return (
        <ProgramDimAccordionRejectedPresentational
          dimensionListToDisplay={this.props.dimensionListToDisplay}
          dimensionList={this.props.dimensionList}
          onSelectAccordion={this.onSelectAccordion}
          mode={this.props.mode}
          onExpandCollapse={this.onExpandCollapse}
          topLeftContainer={this.props.topLeftContainer}
          topRightContainer={this.props.topRightContainer}
          formCallback={this.formCallback}
          activeExpand={this.state.activeExpand}
          activeCollapse={this.state.activeCollapse}
          onClickAccordion={this.onClickAccordion}
          isCollapsed={this.state.isCollapsed}
          origin={this.props.origin}
          type={this.props.type}
          onClickCustomBOI={this.onClickCustomBOI}
          showCustomBOI={this.state.showCustomBOI}
          subDimensionName={this.state.subDimensionName}
          dimensionName={this.state.dimensionName}
          boi={this.state.boi}
          onEditBOI={this.props.onEditBOI}
          userType={this.props.userType}
          //edit approved bois
          // isEditApprovedBOI={this.props.isEditApprovedBOI}
          quickDeleteOnClick={this.quickDeleteOnClick}
          restoreDeletedBOI={this.restoreDeletedBOI}
          removeDeletedBOI={this.removeDeletedBOI}
          duplicateBOI={this.props.duplicateBOI}
          inComplete={this.props.inComplete}
          showEditToggle={this.state.showEditToggle}
          onCloseModal={this.onCloseModal}
          onClickOk={this.onClickOk}
          showMessage={this.showMessage}
        />
      );
    }
  }

  return ProgramDimAccordionRejectedContainer;
})();
export default ProgramDimAccordionRejected;

ProgramDimAccordionRejected.defaultProps = {
  deletedBOI: () => { },
};
