import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import DimensionAccordionSpecificBOIHistory from '../Shared/CVMDimensionAccordionSpecificBOIHistory/DimensionAccordionSpecificBOIHistory';

import {
  getProgramSpecificBOIHistory,
  getProjectSpecificBOIHistory,
} from '../../Actions/ProgramProjectBOIHistory.Action';

import './BOIHistoryModal.css';

const SpecificBOIHistoryModal = (function () {
  class SpecificBOIHistoryModalPresentaional extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="btn-boi-history-container">
            <GrooveButton
              id="btn-boi-history"
              hasIcon={true}
              type="utility"
              colorClass="stat-alternate"
              iconAlignment="right"
              iconSize="tiny"
              iconName="history"
              iconStyle="solid"
              iconId="btn-icon-history"
              callbackFunction={this.props.handleShow}
            />
          </div>
          <Modal show={this.props.show} centered dialogClassName="boiHistoryModal">
            <Modal.Header>
              <Modal.Title>
                <div className="header24-semibold-midnight">
                  <GrooveIconSvg
                    customClassName="back-modal-btn"
                    size="small"
                    name="chevron-left"
                    iconStyle="solid"
                    iconColor="stat-alternate"
                    callbackFunction={this.props.handleShow}
                  />
                  Business Outcome Indicators History
                </div>
                <GrooveIconSvg
                  customClassName="close-modal-btn"
                  size="large"
                  name="times"
                  iconStyle="solid"
                  iconColor="primary-acn"
                  callbackFunction={this.props.handleShow}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="boi-history">
                <div className="boi-history-content">
                  <div className="table-view-wraps tab-modified">
                    <DimensionAccordionSpecificBOIHistory
                      dimensionList={this.props.boiHistory}
                      mode="Modified"
                      filter={this.props.filter}
                      ProgramConfigurationData={this.props.ProgramConfigurationData}
                      historyType={this.props.historyType}
                      SharedData={this.props.SharedData}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    }
  }

  class SpecificBOIHistoryModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        filter: '',
        show: false,
      };
    }

    componentDidMount = async () => {
      try {
      } catch (e) {
        console.log(e);
      }
    };

    handleShow = async () => {
      try {
        const {
          dimensionID,
          subDimensionID,
          boiID,
          uom,
          clientProgramTargetID,
          clientProjectTargetID
        } = this.props;
        let data;
        if (!this.state.show) {
          if (this.props.historyType === 'Program') {
            const clientProgramID =
              this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID;

            data = {
              clientProgramID,
              dimensionID,
              subDimensionID,
              boiID,
              uom,
              clientProgramTargetID,
            };
            await this.props.getProgramSpecificBOIHistory(data);
          } else {
            const clientProjectID =
              this.props.ProjectConfigurationData.selectedProjectDetails.ProjectID;
            data = {
              clientProjectID,
              dimensionID,
              subDimensionID,
              boiID,
              uom,
              clientProjectTargetID
            };
            await this.props.getProjectSpecificBOIHistory(data);
          }
        }
        this.setState({
          show: !this.state.show,
        });
      } catch (e) {
        console.log(e);
      }
    };

    render() {
      const boiHistory =
        this.props.historyType === 'Program'
          ? this.props.ProgramConfigurationData.programSpecificBOIHistory
          : this.props.ProjectConfigurationData.projectSpecificBOIHistory;

      return (
        <SpecificBOIHistoryModalPresentaional
          show={this.state.show}
          handleShow={this.handleShow}
          filter={this.state.filter}
          historyType={this.props.historyType}
          ProgramConfigurationData={this.props.ProgramConfigurationData}
          SharedData={this.props.SharedData}
          //reducer
          boiHistory={boiHistory}
        />
      );
    }
  }

  return SpecificBOIHistoryModalContainer;
})();

export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
      ProjectConfigurationData: state.ProjectConfigurationData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getProgramSpecificBOIHistory,
          getProjectSpecificBOIHistory,
        }
      ),
      dispatch
    )
)(SpecificBOIHistoryModal);
