import * as Msal from 'msal';
import {
	SetLocalStorage, GetLocalStorage, ResetLocalStorage, ResetSessionStorage,
	GetSessionStorage, SetSessionStorage, DeleteSessionStorage
} from './BrowserUtil';
import {
	LogUserLogInOut, LogUserBounce, LogUserSiteAction, LogUserLogOut,
	GetHideTime, GetShowTime, SetShowTime, SetHideTime
} from './AccessManagementUtil'
import {
	LOGOUT, IDLE_LOGOUT, IDLE_LOGIN, USER_SITE_ACT_PAR, ANY,
	LOGIN_TIME, LOGOUT_TIME, USER_SITE_ACT_PAR2
} from '../Constants/Modules';
import { ObjToStr, StrToObj, TryGetNodeAlt } from './Formatter';
import { HasProperty } from './Validations';
import Utilities from '.';


export const SetToken = (token) => {
	SetLocalStorage("access_token", token);
}
export const GetToken = async () => {
	// return GetLocalStorage("access_token");
	let token = await Utilities.getToken();
	SetToken(token);
	return token;
}

const UserSession = function () {
	let msalConfig = window.webConfig.msalConfig;
	let msalInstance = new Msal.UserAgentApplication(msalConfig);
	let isDevoloperMode = window.webConfig.isDeveloperMode;

	let hadUserAction = false;

	let handleUserActions = () => {
		window.onmousemove = resetTimer; // catches mouse movements
		window.onmousedown = resetTimer; // catches mouse movements
		window.onclick = resetTimer; // catches mouse clicks
		window.onscroll = resetTimer; // catches scrolling
		window.onkeypress = resetTimer; //catches keyboard actions
		SetLocalStorage('idleTime', new Date().getTime());
		SetSessionStorage('idleTimeTab', new Date().getTime())

		setInterval(async () => {
			const currentDT = new Date().getTime();
			const idleTime = parseInt(GetLocalStorage('idleTime'))
			const idleTimeTab = parseInt(GetSessionStorage('idleTimeTab'))

			//1 MIN IDLE
			if (currentDT >= new Date(idleTimeTab + (1 * 60000)).getTime()
				&& currentDT <= new Date(idleTimeTab + (2 * 60000)).getTime()
				&& document.visibilityState !== 'visible'
			) {
				const t = GetHideTime()
				SetHideTime(null)
				await LogUserLogOut({ EndTimeAdjustment: '-' + t })
			}
			//15 MIN IDLE
			if (currentDT >= new Date(idleTime + (15 * 60000)).getTime()
				&& currentDT <= new Date(idleTime + (16 * 60000)).getTime()
				&& document.visibilityState === 'visible'
			) {
				LogUserBounce()
			}
			//30 MIN IDLE
			if (currentDT >= new Date(idleTime + (30 * 60000)).getTime()
				&& currentDT <= new Date(idleTime + (31 * 60000)).getTime()
				&& document.visibilityState === 'visible'
			) {
				const prevData = StrToObj(GetSessionStorage(USER_SITE_ACT_PAR))
				if (HasProperty(prevData, 'AccessingMenuMasterID') && prevData.AccessingMenuMasterID > 0) await LogUserSiteAction({ action: LOGOUT })
				else await LogUserLogInOut({ action: IDLE_LOGOUT })
				await LogUserLogInOut({ action: IDLE_LOGIN })
			}

			//1 HOUR IDLE
			if (currentDT >= new Date(idleTime + (60 * 60000)).getTime()) {
				LogUserLogInOut({ action: LOGOUT })
				ResetLocalStorage();
				ResetSessionStorage()
				window.location.href = msalConfig.auth.logout;
			}
			else if (hadUserAction) {
				//IF CURRENT TAB IS ACTIVE THEN CLOSE OTHER INVISIBLBE TAB SESSIONS
				//AND OPEN NEW SESSION FOR CURRENT TAB IF NO SESSION YET
				if (document.visibilityState === 'visible') {
					const loginTime = parseInt(GetSessionStorage(LOGIN_TIME))
					const logoutTime = parseInt(GetSessionStorage(LOGOUT_TIME))
					if (loginTime < logoutTime) {
						const url = window.location.href.toLowerCase()
						DeleteSessionStorage(USER_SITE_ACT_PAR)
						const t = GetShowTime()
						SetShowTime(null)
						const param = ObjToStr(GetSessionStorage(USER_SITE_ACT_PAR2))
						await LogUserSiteAction({
							action: IDLE_LOGIN,
							module: ANY,
							ShouldLogPPCID: [
								'programprojectconfig',
								'reportvaluedelivered',
								'projectdataentry',
								'dataingestion',
								'dashboard'
							].some(e => url.includes(e)),
							ShouldLogPPCID: TryGetNodeAlt(param, null, 'ShouldLogPPCID'),
							ClientProgramID: TryGetNodeAlt(param, null, 'ClientProgramID'),
							ClientProjectID: TryGetNodeAlt(param, null, 'ClientProjectID'),
							progStatus: TryGetNodeAlt(param, null, 'ClientProgramStatus'),
							projStatus: TryGetNodeAlt(param, null, 'ClientProjectStatus'),
							StartTimeAdjustment: '-' + t
						})
					}
				}
				SetLocalStorage('idleTime', new Date().getTime());
				SetSessionStorage('idleTimeTab', new Date().getTime())
				hadUserAction = false;
			}

		}, 1000 * 60);

		// Add additional checker for last movement
		setInterval(async() => {
			if (hadUserAction) {
				SetLocalStorage('idleTime', new Date().getTime());
				SetSessionStorage('idleTimeTab', new Date().getTime())
			}
		}, 1000 * 15);
	}

	let resetTimer = () => {
		hadUserAction = true;
	}

	return {
		environment: {
			msalConfig: msalConfig,
			msalInstance: msalInstance,
			isDevoloperMode: isDevoloperMode
		},
		handleUserActions,
	}
}();

export default UserSession;
