export const CLIENT_MANAGEMENT = { menuID: 1, menu: 'Admin', menuAlias: 'Admin', subMenuID: 1, subMenu: 'Client Management', url: '/masterconfiguration' }
export const USER_MANAGEMENT = { menuID: 1, menu: 'Admin', menuAlias: 'Admin', subMenuID: 2, subMenu: 'User Management', url: '/masterconfiguration' }
export const CONFIG_MOD_LANDING_PAGE = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 3, subMenu: 'Config Module landing page', url: '/programprojectconfig' }
export const PROG_ID_VAL_SETT = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 6, subMenu: 'Program Identified Value Settings', url: '/programprojectconfig/programidvaluesettings' }
export const ADD_EDIT_BOIS = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 7, subMenu: 'Add/ Edit BOIs', url: '' }
export const PROJ_DETAILS_PAGE = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 8, subMenu: 'Project Details Page', url: '/programprojectconfig/projects' }
export const PROJ_ID_VAL_SETT = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 9, subMenu: 'Project Identified Value Settings', url: '/programprojectconfig/projectidvaluesettings' }
export const SELECT_BOIS = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 10, subMenu: 'Select BOIs', url: '' }
export const VAL_DELIV_LANDING = { menuID: 3, menu: 'Report Value Delivered', menuAlias: 'RVD', subMenuID: 11, subMenu: 'Value Delivered_Landing page', url: '/dataingestion' }
export const REP_PROJ_DATA = { menuID: 3, menu: 'Report Value Delivered', menuAlias: 'RVD', subMenuID: 11, subMenu: 'Value Delivered_Landing page', url: '/dataingestion' }
export const PROJ_SEL = { menuID: 3, menu: 'Report Value Delivered', menuAlias: 'RVD', subMenuID: 13, subMenu: 'Project Selection', url: '' }
export const PDE = { menuID: 3, menu: 'Report Value Delivered', menuAlias: 'RVD', subMenuID: 14, subMenu: 'Project Data Entry', url: '/dataingestion/configureprogram' }
export const SUB_MOD_DASHBOARD = { menuID: 4, menu: 'Value Meter Dashboard', menuAlias: 'Dashboard', subMenuID: 15, subMenu: 'Dashboard', url: '/dashboard' }
export const APP_HOME_PAGE = { menuID: 5, menu: 'Application Home Page', menuAlias: 'Home', subMenuID: 16, subMenu: 'Application Home Page', url: '/' }
export const ADD_NEW_PROG = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 18, subMenu: 'Add New Program', url: '' }
export const PROG_ID_VAL_ALA = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 19, subMenu: 'Program Identified Value - Account Leadership Approval', url: '/programprojectconfig/programaccountleadershipapproval,/programprojectconfig/programaccountleadershipapproval/review' }
export const PROG_ID_VAL_CA = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 20, subMenu: 'Program Identified Value - Client Alignment', url: '/programprojectconfig/programclientalignment,/programprojectconfig/programclientalignment/submit' }
export const ADD_NEW_PROJ = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 21, subMenu: 'Add New Project', url: '' }
export const PROJ_ID_VAL_ALA = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 22, subMenu: 'Project Identified Value - Account Leadership Approval', url: '/programprojectconfig/projectaccountleadershipapproval,/programprojectconfig/projectaccountleadershipapproval/review' }
export const PROJ_ID_VAL_CA = { menuID: 2, menu: 'Program & Project Configuration', menuAlias: 'PPC', subMenuID: 23, subMenu: 'Project Identified Value - Client Alignment', url: '' }
export const CANNED_REP = { menuID: 6, menu: 'View Reports landing page', menuAlias: 'Reports', subMenuID: 24, subMenu: 'Canned Reports', url: '/reportingmodule' }
export const ADHOC_REP = { menuID: 6, menu: 'View Reports landing page', menuAlias: 'Reports', subMenuID: 25, subMenu: 'Adhoc Reports', url: '/reportingmodule' }
export const GLOBAL_DASHBOARD = { menuID: 8, menu: 'Global', menuAlias: 'Global', subMenuID: 26, subMenu: 'Global Dashboard', url: '/dashboard/global' }
export const GLOBAL_PREVIEW = { menuID: 8, menu: 'Global', menuAlias: 'Global', subMenuID: 27, subMenu: 'Global Preview', url: '/dashboard/global?preview=true' }
export const ANY = { menuID: 0, menu: 'ANY', menuAlias: 'ANY', subMenuID: 0, subMenu: 'ANY', url: '' }

//ACTIONS
export const LOGOUT = 'logout'
export const LOGIN = 'login'
export const CLIENT_CHANGE = 'client change'
export const IDLE_LOGOUT = 'idle logout'
export const IDLE_LOGIN = 'idle login'

export const USER_SITE_ACT_PAR = 'UserSiteActionParam'
export const USER_SITE_ACT_PAR2 = 'UserSiteActionParam2'
export const LOGOUT_TIME = 'logoutTime'
export const LOGIN_TIME = 'loginTime'

export const ACC_PROG = 'Accessed Program'
export const ACC_PROJ = 'Accessed Project'

//RVD PROJECT DATA ENTRY
export const PROJ_DATA_ENTRY_REC = "Project Data entry recorded"
export const PROJ_DATA_APP_L1 = "Project Data entry approved - L1"
export const PROJ_DATA_APP_L2 = "Project Data entry approved - L2"
export const PROJ_DATA_APP_L3 = "Project Data entry approved - L3"

//PPC
export const PPC_NEW_PROG = 'New Program config created'
export const PPC_NEW_PROG_APP = 'New Program config approved'
export const PPC_EX_PROG_UPD = 'Existing Program config updated'
export const PPC_EX_PROG_APP = 'Existing Program config approved'

export const PPC_NEW_PROJ = 'New Project config created'
export const PPC_NEW_PROJ_APP = 'New Project config approved'
export const PPC_EX_PROJ_UPD = 'Existing Project config updated'
export const PPC_EX_PROJ_APP = 'Existing Project config approved'