import { HttpGet, HttpPost } from '../Utilities/HTTPUtil';
import {
	FETCH_PROGRAM_LIST, FETCH_CVM_PROJ_LEAD_DRPDOWN,
	FETCH_CVM_PROJ_DELIV_LOC_DRPDOWN, FETCH_CVM_PROJ_DELIV_BY_DRPDOWN
} from '../Constants';
import { IsValid } from '../Utilities/Validations';

export const FetchProgramList = (clientID) => async (dispatch) => {
	const programList = await HttpGet(`client/getProgramList?clientID=${clientID}`);
	dispatch({
		type: FETCH_PROGRAM_LIST,
		programList
	});
}
export const FetchCMVProjLeadDrpdown = (clientID) => async (dispatch, state) => {
	const projLeadList = !IsValid(clientID) ? [] : await HttpGet(`client/getProjectLeads?clientID=${clientID}`);
	dispatch({
		type: FETCH_CVM_PROJ_LEAD_DRPDOWN,
		projLeadList: {
			list: projLeadList,
			clientID: clientID
		}
	});
}
export const FetchCMVProjDelivLocDrpdown = (clientID) => async (dispatch, state) => {
	const projDelivLocList = !IsValid(clientID) ? [] : await HttpGet(`client/getProjectDeliveryLocation?clientID=${clientID}`);
	dispatch({
		type: FETCH_CVM_PROJ_DELIV_LOC_DRPDOWN,
		projDelivLocList
	});
}

export const FetchCMVProjDelivByDrpdown = (clientID) => async (dispatch, state) => {
	const projDelivByList = !IsValid(clientID) ? [] : await HttpGet(`client/getProjectDeliveredBy?clientID=${clientID}`);
	dispatch({
		type: FETCH_CVM_PROJ_DELIV_BY_DRPDOWN,
		projDelivByList: {
			list: projDelivByList,
			clientID: clientID
		}
	});
}