import { REQUEST_DATA, RECEIVED_DATA, ERROR_DATA } from '../Constants';

const initialState = {
    getData: {
        list: [],
        isFetching: false
    }
}

export default function getData(state = initialState, action = {}) {
    switch (action.type) {
        case REQUEST_DATA:
            return {
                ...state,
                list: [],
                isFetching: true
            };
        case RECEIVED_DATA:
            return {
                ...state,
                list: action.payload,
                isFetching: false
            };
        case ERROR_DATA:
            return {
                ...state,
                errorMessage: action.error,
                isFetching: false,
            };
        default:
            return state;
    }
}