import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { history } from '../../store';
import { withRouter } from "react-router-dom";
import ProgramIDValueSettings from './Progam/ProgramIDValueSettings';
import ProgramAccountLeadership from './Progam/ProgramAccountLeadership';
import ProgramALAppAAEditBOIs from './Progam/ProgramALAppAAEditBOIs.js';
import ProgramALAppApprovedBOIs from './Progam/ProgramALAppApprovedBOIs';
import ProgramALAppReview from './Progam/ProgramALAppReview';
import ProgramALAppRejectedEditBOIs from './Progam/ProgramALAppRejectedEditBOIs';
import ProgramClientAlignment from './Progam/ProgramClientAlignment';
import ProgramClientAlignmentSubmit from './Progam/ProgramClientAlignmentSubmit';

import ProjectIDValueSettings from './Project/ProjectIDValueSettings';
/* import ProjectAccountLeadership from './Project/ProjectAccountLeadership';
import ProjectALAppAAEditBOIs from './Project/ProjectALAppAAEditBOIs';
import ProjectALAppReview from './Project/ProjectALAppReview';
import ProjectALAppApprovedBOIs from './Project/ProjectALAppApprovedBOIs';
import ProjectAccountLeadershipRejected from './Project/ProjectAccountLeadershipRejected';
import ProjectClientAlignment from './Project/ProjectClientAlignment'; */
import './SubProgramProjectConfig.css';
import { IsValid } from '../../Utilities/Validations';
// import ProjectClientAlignmentSubmit from './Project/ProjectClientAlignmentSubmit';

const SubProgramProjectConfig = (function () {
  const comp = {
    programIDValueSettings: {
      id: 1,
      url: 'programidvaluesettings',
      com: <ProgramIDValueSettings />,
    },
    programAccountLeadershipApproval: {
      id: 2,
      url: 'programaccountleadershipapproval',
      com: <ProgramAccountLeadership />,
    },
    programAccountLeadershipApprovalReview: {
      id: 2,
      url: 'programaccountleadershipapproval/review',
      com: <ProgramALAppReview />,
    },
    programAccountLeadershipApprovalResend: {
      id: 2,
      url: 'programaccountleadershipapproval/awaitingappboi',
      com: <ProgramALAppAAEditBOIs />,
    },
    programAccountLeadershipApprovalApproved: {
      id: 2,
      url: 'programaccountleadershipapproval/approvedboi',
      com: <ProgramALAppApprovedBOIs />,
    },
    programAccountLeadershipApprovalReject: {
      id: 2,
      url: 'programaccountleadershipapproval/rejectedboi',
      com: <ProgramALAppRejectedEditBOIs />,
    },
    programClientAlignment: {
      id: 3,
      url: 'programclientalignment',
      com: <ProgramClientAlignment />,
    },
    ProgramClientAlignmentSubmit: {
      id: 3,
      url: 'programclientalignment/submit',
      com: <ProgramClientAlignmentSubmit />,
    },

    projectIDValueSettings: {
      id: 1,
      url: 'projectidvaluesettings',
      com: <ProjectIDValueSettings />,
    },
    projectAccountLeadershipApproval: {
      id: 2,
      url: 'projectaccountleadershipapproval',
      com: <ProjectIDValueSettings />, //<ProjectAccountLeadership />,
    },
    projectAccountLeadershipApprovalReview: {
      id: 3,
      url: 'projectaccountleadershipapproval/review',
      com: <ProjectIDValueSettings />, //<ProjectALAppReview />,
    },
    projectAccountLeadershipApprovalApproved: {
      id: 2,
      url: 'projectaccountleadershipapproval/approvedboi',
      com: <ProjectIDValueSettings />, //<ProjectALAppApprovedBOIs />,
    },
    projectAccountLeadershipApprovalResend: {
      id: 2,
      url: 'projectaccountleadershipapproval/awaitingappboi',
      com: <ProjectIDValueSettings />, //<ProjectALAppAAEditBOIs />,
    },
    projectAccountLeadershipApprovalReject: {
      id: 2,
      url: 'projectaccountleadershipapproval/rejectedboi',
      com: <ProjectIDValueSettings />, //<ProjectAccountLeadershipRejected />,
    },
    projectClientAlignment: {
      id: 3,
      url: 'projectclientalignment',
      com: <ProjectIDValueSettings />, //<ProjectClientAlignment />,
    },
    projectClientAlignmentSubmit: {
      id: 3,
      url: 'projectclientalignment/submit',
      com: <ProjectIDValueSettings />, //<ProjectClientAlignmentSubmit />,
    },
  };

  class SubProgramProjectConfigPresentational extends Component {
    render() {
      const url = window.location.pathname.toLowerCase().replace('/programprojectconfig/', '');
      const page = Object.values(comp)
        .filter((e) => {
          return e.url === url;
        })
        .map((e2) => {
          return e2.com;
        });

      return <React.Fragment>{page}</React.Fragment>;
    }
  }

  class SubProgramProjectConfigContainer extends Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      if (!IsValid(this.props.clientID)) this.props.history.push('/ProgramProjectConfig');
      return <SubProgramProjectConfigPresentational />;
    }
  }

  return SubProgramProjectConfigContainer;
})();
export default connect(
  (state) => {
    return {
      clientID: state.SharedData.selectedClient.value,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(withRouter(SubProgramProjectConfig));
