import React, { Component } from 'react';
// import { history } from "../../store";
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Header } from '../MasterLayout';
import { GetToken } from '../../Utilities/UserSession';
import { HasValue } from '../../Utilities/Validations';
import LoadingScreen from './LoadingScreen/LoadingScreen';

const AccessDenied = (function () {
	class _AccessDenied extends Component {
		goBack = (event) => {
			this.props.history.push('/AccessRequest');
		}

		render() {
			return (
				!HasValue(GetToken()) ? <LoadingScreen text='Verifying User' /> :
					<div className="body_container">
						<Header />
						<Row className="access_denied_container">
							<Col md={12} xs={12}>
								<div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
								<div className="access_denied_content">
									<h1>Access Denied</h1>
									<p>You dont have the necessary user privileges to view the page you are attempting to access.</p>
								</div>
							</Col>
						</Row>
					</div>
			);
		}
	}
	return _AccessDenied;
})();

export default withRouter(AccessDenied)