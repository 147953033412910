import {
	GET_CLIENT_LIST,
	SHOW_CLIENT_DROPDOWN,
	SET_CLIENT_VALUE,
	GET_USER_DETAILS,
	LEFT_PANEL_CLICKED,
	SET_SHOULD_SHOW_TOASTER_NOTIF,
	SET_CUSTOM_BOI_DETAILS,
	SET_STEPPER_ACTIVE_INDEX,
	SET_PROFILE_OFF,
	SET_UPLOAD_SUPPORT_OFF,
	SET_UPLOAD_SUPPORT_DOC,
	SET_EDIT_APPROVED,
	SET_MENUS,
	SET_HIS_PATH,
	SHOW_HIDE_LEFT_SIDE_BAR,
	SET_URL_PPC,
	SHOW_EMAIL_ERROR,
} from '../Constants'

const initialState = {
	clientList: [],
	showClientDropdown: false,
	selectedClient: {},
	userDetails: {},
	isLeftPanel: false,
	toasterParam: { show: false, message: '', type: '' },
	stepperActiveIndex: 0,
	customBOI: {},
	showProfile: false,
	showUploadSupport: false,
	showHide: true,
	uploadSupportDoc: {
		file: {},
		link: "",
		docType: 0,
		docID: 0,
	},
	editApproved: false,
	menus: {
		menu: [],
		subMenu: []
	},
	historyPath: [],
	isAccountLeadership: false,
	showError: {
		show: false,
		message: ""
	}
}

export const SharedData = (state, action) => {
	state = state || initialState;

	switch (action.type) {
		case GET_CLIENT_LIST:
			return {
				...state,
				clientList: action.clientList
			}
		case SHOW_CLIENT_DROPDOWN:
			return {
				...state,
				showClientDropdown: action.showClientDropdown
			}
		case SET_CLIENT_VALUE:
			return {
				...state,
				selectedClient: action.selectedClient
			}
		case GET_USER_DETAILS:
			return {
				...state,
				userDetails: action.userDetails
			}
		case LEFT_PANEL_CLICKED:
			return {
				...state,
				isLeftPanel: action.isLeftPanel
			}
		case SET_SHOULD_SHOW_TOASTER_NOTIF:
			return {
				...state,
				toasterParam: action.toasterParam
			}
		case SET_STEPPER_ACTIVE_INDEX:
			return {
				...state,
				stepperActiveIndex: action.stepperActiveIndex
			}
		case SET_CUSTOM_BOI_DETAILS:
			return {
				...state,
				customBOI: action.customBOI
			}
		case SET_PROFILE_OFF:
			return {
				...state,
				showProfile: action.showProfile
			}
		case SET_UPLOAD_SUPPORT_OFF:
			return {
				...state,
				showUploadSupport: action.showUploadSupport
			}
		case SET_UPLOAD_SUPPORT_DOC:
			return {
				...state,
				uploadSupportDoc: action.uploadSupportDoc
			}
		case SET_EDIT_APPROVED:
			return {
				...state,
				editApproved: action.editApproved
			}
		case SET_MENUS:
			return {
				...state,
				menus: action.menu
			}
		case SET_HIS_PATH:
			return {
				...state,
				historyPath: action.historyPath
			}
		case SHOW_HIDE_LEFT_SIDE_BAR:
			return {
				...state,
				showHide: action.showHide
			}
		case SET_URL_PPC:
			return {
				...state,
				isAccountLeadership: action.isAccountLeadership
			}
		case SHOW_EMAIL_ERROR:
			return {
				...state,
				showError: action.showError
			}
		default:
			return state
	}
}