import React, { Component } from "react";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import {
  GrooveTabComponent,
  GrooveIconSvg,
  GrooveInput,
  GrooveSelectMenuBasic,
  GrooveButton,
  GrooveTextBox
} from "tfo-groove";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CVMTable from "../../Shared/CVMTable/CVMTable";
import CVMModal from "../../Shared/CVMModal/CVMModal";
import addfiles from "./../../../Static/images/360cvm/AddFilesIcon.svg";

import CVMMutiSelectDropdown from "../../Shared/CVMMutiSelectDropdown/CVMMutiSelectDropdown";
import Toast from "react-bootstrap/Toast";

import "./ConfigureClientStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import {
  getOfferingsList,
  getMarketList,
  getClientStakeHolders,
  getClientTypeList,
  getIndustryList,
  setIndustry,
  setClientType,
  setOperationAcctLead,
  setReportingYearSelect,
  setMarket,
  setOffering,
  setClientAdmin,
  setAddNewProgramFields,
  getSubOffering,
  getIOAList,
  addNewProgram,
  saveAsDraft,
  notify,
  hideSaveAsDraftToaster,
  showHideNotifyModalConfigClient,
  clearConfigClientFields,
  enableDisableAddNewProgramButton,
  setProgramDetails,
  setModalComments,
  validateBasicDetails,
  validateProgramFields,
} from "../../../Actions/ClientConfiguration.Action";

const ConfigureClient = (function () {
  class ConfigureClientPresentational extends Component {
    render() {
      return (
        <>
          <div className="saveDraft-toast">
            <Toast
              onClose={this.props.onCloseSaveDraftToast}
              show={this.props.showSaveDraftToast}
              delay={3000}
              autohide
            >
              <Toast.Header>
                <FontAwesomeIcon icon={faCheckCircle} />
                <h5>Your progress was saved as draft successfully!</h5>
              </Toast.Header>
            </Toast>
          </div>

          <div className="notify-toast">
            <Toast
              onClose={this.props.onCloseNotifyToast}
              show={this.props.showNotifyToast}
              delay={3000}
              autohide
            >
              <Toast.Header>
                <FontAwesomeIcon icon={faCheckCircle} />
                <h5>Notification sent successfully!</h5>
              </Toast.Header>
            </Toast>
          </div>

          <div className="stage-container">
            <CVMModal
              title={
                this.props.isEdit
                  ? "Edit Value Meter Program"
                  : "Add New Value Meter Program"
              }
              show={this.props.show}
              onCloseModal={() => {
                this.props.clearConfigClientFields("programValidation");
                this.props.onCloseModal();
              }}
              content={
                <ModalContent
                  options={this.props.options}
                  onCloseModal={this.props.onCloseModal}
                  selectAddNewOffering={this.props.selectAddNewOffering}
                  offeringList={
                    this.props.offeringList.filter(x => {
                      return (this.props.selectOffering.hasOwnProperty("data")
                        ? this.props.selectOffering.data
                        : Array.isArray(this.props.selectOffering)
                          ? this.props.selectOffering
                          : [this.props.selectOffering]).some(y => {
                            return x.value === y.value
                          })
                    })
                  }
                  setAddNewProgramFields={this.props.setAddNewProgramFields}
                  selectAddNewSubOffering={this.props.selectAddNewSubOffering}
                  selectAddNewCXLead={this.props.selectAddNewCXLead}
                  selectAddNewBTLead={this.props.selectAddNewBTLead}
                  selectAddNewIOA={this.props.selectAddNewIOA}
                  programNameValue={this.props.programNameValue}
                  cxLeadList={this.props.cxLeadList}
                  btLeadList={this.props.btLeadList}
                  ioaList={this.props.ioaList}
                  subOfferingList={this.props.subOfferingList}
                  addNewProgram={this.props.addNewProgram}
                  isEdit={this.props.isEdit}
                  selectedRow={this.props.selectedRow}
                  programIsValid={this.props.programIsValid}
                  validateProgramFields={this.props.validateProgramFields}
                  clearConfigClientFields={this.props.clearConfigClientFields}
                  showErrorFields={this.props.showErrorFields}
                  showIsExistError={this.props.showIsExistError}
                />
              }
            />
            <CVMModal
              title={"Notify Stakeholders"}
              show={this.props.showNotifyModalConfigClient}
              onCloseModal={this.props.showHideNotifyModalConfigClient}
              customclass={"notifyModal-dialog"}
              content={
                <NotifyModalContent
                  notifyModalActiveKey={this.props.notifyModalActiveKey}
                  onChangeNotifyModalTab={this.props.onNotifyChangeModalTab}
                  clientList={this.props.clientList}
                  onCloseModal={this.props.showHideNotifyModalConfigClient}
                  onSaveNotifyModal={this.props.onSaveNotifyModal}
                  requestTargetRecepients={this.props.requestTargetRecepients}
                  acknowledgementRecepients={
                    this.props.acknowledgementRecepients
                  }
                  setModalComments={this.props.setModalComments}
                  programIdentifiedModalComments={
                    this.props.programIdentifiedModalComments
                  }
                />
              }
            />
            <Row className="tab-header-row-left">
              <GrooveButton
                id="expandAll"
                hasIcon={false}
                type="solid"
                colorClass=""
                text="Expand All"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
                customClassName="expandAll-button"
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
              />
              <GrooveButton
                id="collapseAll"
                hasIcon={false}
                type="solid"
                colorClass=""
                text="Collapse All"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
                customClassName="collapseAll-button"
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
              />
            </Row>
            <Row>
              <Accordion
                className="configure-client-collapse"
                defaultActiveKey="0"
              >
                <Card>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Row>
                        <Col lg={6} md={6} sm={12}>
                          <GrooveSelectMenuBasic
                            name="projectmain"
                            isDisabled={false}
                            placeholder="Project"
                            list={this.props.options}
                            id="projectMain"
                            customClassName={
                              this.props.fieldValidation.projectMainValid
                                ? ""
                                : " dropdown-invalid"
                            }
                            name="clientlist"
                            isDisabled={true}
                            placeholder="Client"
                            list={this.props.clientList}
                            id="clientList"
                            defaultData={this.props.selectedClient}
                          />
                        </Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          className="reporting-year-ddown"
                        >
                          <GrooveSelectMenuBasic
                            name="reportingyearmain"
                            labelText="Reporting Year"
                            customClassName={
                              this.props.fieldValidation.reportingYearMainValid
                                ? ""
                                : " dropdown-invalid"
                            }
                            isDisabled={false}
                            placeholder={
                              Object.keys(this.props.selectReportingYear)
                                .length > 0
                                ? "Reporting Year*"
                                : "Select Reporting Year*"
                            }
                            list={this.props.reportingYearList.filter((x) => {
                              let obj = [];
                              const data = Object.keys(this.props.clientDetails).length > 0 ? this.props.clientDetails.reportingYear : [];
                              for (let i = 0; i < data.length; i++) {
                                obj.push(data[i].year);
                              }
                              return obj.indexOf(x.value) === -1;
                            })
                            }
                            id="reportingYearMain"
                            defaultData={
                              Array.isArray(this.props.selectReportingYear)
                                ? this.props.selectReportingYear[0]
                                : this.props.selectReportingYear
                            }
                            callbackFunction={(e) =>
                              this.props.setReportingYear(e)
                            }
                            isValid={
                              this.props.basicDetailsIsValid.reportingYear
                            }
                          />
                          <GrooveIconSvg
                            id="calendarIcon"
                            customClassName="calendar-icon-dropdown"
                            size="large"
                            name="calendar-alt"
                            iconStyle="solid"
                            iconColor="gr-silver"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={6} sm={12}>
                          <GrooveSelectMenuBasic
                            name="industrymain"
                            isDisabled={this.props.disableFields.industry}
                            placeholder={
                              Object.keys(this.props.selectIndustry).length > 0
                                ? "Industry*"
                                : "Select Industry*"
                            }
                            list={this.props.industryList}
                            id="industryMain"
                            customClassName={
                              this.props.fieldValidation.industryMainValid
                                ? ""
                                : " dropdown-invalid"
                            }
                            callbackFunction={(e) => this.props.setIndustry(e)}
                            defaultData={this.props.selectIndustry}
                            isValid={this.props.basicDetailsIsValid.industry}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <GrooveSelectMenuBasic
                            name="marketmain"
                            isDisabled={this.props.disableFields.market}
                            placeholder={
                              Object.keys(this.props.selectMarket).length > 0
                                ? "Market*"
                                : "Select Market*"
                            }
                            list={this.props.marketList}
                            id="marketMain"
                            customClassName={
                              this.props.fieldValidation.marketMainValid
                                ? ""
                                : " dropdown-invalid"
                            }
                            callbackFunction={(e) => this.props.setMarket(e)}
                            defaultData={this.props.selectMarket}
                            isValid={this.props.basicDetailsIsValid.market}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={6} sm={12}>
                          <GrooveSelectMenuBasic
                            name="clienttypemain"
                            isDisabled={this.props.disableFields.clientType}
                            placeholder={
                              Object.keys(this.props.selectClientType).length >
                                0
                                ? "Client Type*"
                                : "Select Client Type*"
                            }
                            list={this.props.clientTypeList}
                            id="clientTypeMain"
                            customClassName={
                              this.props.fieldValidation.clientTypeMainValid
                                ? ""
                                : " dropdown-invalid"
                            }
                            callbackFunction={(e) =>
                              this.props.setClientType(e)
                            }
                            defaultData={this.props.selectClientType}
                            isValid={this.props.basicDetailsIsValid.clientType}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <GrooveSelectMenuBasic
                            name="offeringmain"
                            isDisabled={false}
                            placeholder={
                              Object.keys(
                                this.props.selectOffering.hasOwnProperty("data")
                                  ? this.props.selectOffering.data
                                  : this.props.selectOffering
                              ).length > 0
                                ? this.props.selectOffering.label !== ""
                                  ? "Offerings*"
                                  : "Select Offerings*"
                                : "Select Offerings*"
                            }
                            list={this.props.offeringList}
                            id="offeringsMain"
                            contentType="checkbox"
                            callbackFunction={(e) => this.props.setOffering(e)}
                            defaultCheckedData={this.props.selectOffering.hasOwnProperty("data") ? this.props.selectOffering.data : Array.isArray(this.props.selectOffering) ? this.props.selectOffering : Object.keys(this.props.selectOffering).length > 0 ? [this.props.selectOffering] : []}
                            isValid={this.props.basicDetailsIsValid.offerings}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={6} sm={12}>
                          <GrooveSelectMenuBasic
                            name="operationsaccountleadmain"
                            isDisabled={true}
                            placeholder="Operations Account Lead"
                            list={this.props.operationAcctLeadList}
                            id="operationsAccountLeadMain"
                            defaultData={this.props.selectOperationAcctLead}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <GrooveSelectMenuBasic
                            name="clientadminmain"
                            isDisabled={true}
                            placeholder="Client Admin"
                            list={this.props.clientAdminList}
                            id="clientAdminMain"
                            defaultData={this.props.selectClientAdmin}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <div className="bodytext18-medium-midnight">
                        Basic Details
                      </div>
                      <GrooveIconSvg
                        id="basicDetailsIcon"
                        customClassName="accordion-button"
                        size="large"
                        name="chevron-down"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              </Accordion>
            </Row>
            <Row>
              <Accordion
                className="configure-client-collapse"
                defaultActiveKey="0"
              >
                <Card>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Row className="tab-section-header">
                        <GrooveButton
                          id="primry-btn-2"
                          hasIcon={true}
                          type="solid"
                          colorClass="stat-alternate"
                          text="Add New Value Meter Program"
                          callbackFunction={() => {
                            this.props.clearConfigClientFields(
                              "programValidation"
                            );
                            this.props.onCloseModal();
                          }}
                          customClassName="add-project-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="plus"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                          isDisabled={this.props.disableAddNewProgram}
                        />
                      </Row>
                      <Row>
                        <div className="added-program-container">
                          {Object.keys(this.props.tableProperties.data).length >
                            0 ? (
                            <div className="added-program-table-container">
                              <CVMTable
                                tableProperties={this.props.tableProperties}
                                onRowSelect={this.props.onRowSelect}
                                getGridApi={this.props.getGridApi}
                              />
                            </div>
                          ) : (
                            <div className="empty-program-container bodytext18-regular-slate">
                              <img src={addfiles} alt="select client" />
                              <span className="bodytext24-medium-slate">
                                No Program Added
                              </span>
                              Please add program(s) to this client by using the
                              'Add New Value Meter Program' button above.
                            </div>
                          )}
                        </div>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <div className="bodytext18-medium-midnight">
                        Configure Program
                      </div>
                      <GrooveIconSvg
                        id="configureProgram"
                        customClassName="accordion-button"
                        size="large"
                        name="chevron-down"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              </Accordion>
            </Row>
            <Row className="configure-client-buttons">
              <Col md={6} className="error-message">
                {this.props.showErrorFields.basic
                  ? "Missing Required Fields"
                  : ""}
              </Col>
              <Col md={6} className="config-client-button-container">
                <GrooveButton
                  id="primry-btn-1"
                  name="Solid Button Primary-Ops"
                  isDisabled={false}
                  hasIcon={false}
                  type="outline"
                  colorClass="stat-alternate"
                  size="normal"
                  text="Save as Draft"
                  callbackFunction={this.props.onShowSaveDraftToast}
                />

                <GrooveButton
                  id="primry-btn-2"
                  name="Solid Button Primary-Ops"
                  isDisabled={Object.keys(this.props.tableProperties.data).length > 0 ? false : true}
                  hasIcon={false}
                  type="solid"
                  colorClass="stat-alternate"
                  size="normal"
                  text="Notify"
                  callbackFunction={this.props.validateOnNotify}
                />
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }

  class ModalContent extends Component {
    magic = () => {
      document.getElementById('mystery').style.display = "none";
      document.getElementsByName("subofferingmodal")[0].childNodes[0].click()
    }

    onClickOffering = (e) => {
      document.getElementById('mystery').style.display = "block";
      this.props.setAddNewProgramFields(e, "offering");
    }

    render() {
      return (
        <>
          <div className="add-new-program-container">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveSelectMenuBasic
                  name="offeringmodal"
                  isDisabled={false}
                  placeholder={
                    Object.keys(this.props.selectAddNewOffering).length > 0
                      ? "Offering*"
                      : "Select Offering*"
                  }
                  list={this.props.offeringList}
                  id="offeringModal"
                  callbackFunction={(e) =>
                    this.onClickOffering(e)
                  }
                  defaultData={this.props.selectAddNewOffering}
                  isValid={this.props.programIsValid.offering}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div id="mystery" onClick={() => this.magic()}></div>
                <GrooveSelectMenuBasic
                  name="subofferingmodal"
                  isDisabled={false}
                  placeholder={
                    Object.keys(this.props.selectAddNewSubOffering.hasOwnProperty("data") ? this.props.selectAddNewSubOffering.data : this.props.selectAddNewSubOffering).length > 0
                      ? "Sub-Offering*"
                      : "Select Sub-Offering*"
                  }
                  list={this.props.subOfferingList}
                  id="subOfferingModal"
                  contentType="checkbox"
                  callbackFunction={(e) =>
                    this.props.setAddNewProgramFields(e, "suboffering")
                  }
                  defaultCheckedData={this.props.selectAddNewSubOffering.hasOwnProperty("data") ? this.props.selectAddNewSubOffering.data : Array.isArray(this.props.selectAddNewSubOffering) ? this.props.selectAddNewSubOffering : Object.keys(this.props.selectAddNewSubOffering).length > 0 ? [this.props.selectAddNewSubOffering] : []}
                  isValid={this.props.programIsValid.subOffering}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveInput
                  id="programNameModal"
                  name="programnamemodal"
                  placeholder="Program Name"
                  type="text"
                  isAutocomplete="false"
                  isDisabled
                  inputValue={this.props.programNameValue}
                  maxLength={255}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveSelectMenuBasic
                  name="btleadmodal"
                  isDisabled={false}
                  placeholder="BT Lead*"
                  placeholder={
                    Object.keys(this.props.selectAddNewBTLead).length > 0
                      ? "BT Lead*"
                      : "Select BT Lead*"
                  }
                  list={this.props.btLeadList || {}}
                  id="btLeadModal"
                  callbackFunction={(e) =>
                    this.props.setAddNewProgramFields(e, "btlead")
                  }
                  defaultData={this.props.selectAddNewBTLead}
                  isValid={this.props.programIsValid.btLead}
                />
                {/* <a href="#">Raise request to access BT Lead</a> */}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveSelectMenuBasic
                  name="cxlleadmodal"
                  isDisabled={false}
                  placeholder={
                    Object.keys(this.props.selectAddNewCXLead).length > 0
                      ? "CXL Lead*"
                      : "Select CXL Lead*"
                  }
                  list={this.props.cxLeadList}
                  id="cxlLeadModal"
                  callbackFunction={(e) =>
                    this.props.setAddNewProgramFields(e, "cxlead")
                  }
                  defaultData={this.props.selectAddNewCXLead}
                  isValid={this.props.programIsValid.cxlLead}
                />
                {/* <a href="#">Raise request to access CXL Lead</a> */}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveSelectMenuBasic
                  name="intelligentoperationsapprovermodal"
                  isDisabled={false}
                  placeholder={
                    Object.keys(this.props.selectAddNewIOA).length > 0
                      ? "Intelligent Operations Approver*"
                      : "Select Intelligent Operations Approver*"
                  }
                  list={this.props.ioaList}
                  id="intelligentOperationsApproverModal"
                  callbackFunction={(e) =>
                    this.props.setAddNewProgramFields(e, "ioa")
                  }
                  defaultData={this.props.selectAddNewIOA}
                  isValid={this.props.programIsValid.ioa}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="error-message">
                {
                  this.props.showErrorFields.program
                    ? "Missing Required Fields"
                    : ""
                }
                {
                  this.props.showErrorFields.program && this.props.showIsExistError
                    ? <br />
                    : ""
                }
                {
                  this.props.showIsExistError
                    ? "Program Name Already Exists."
                    : ""
                }
              </Col>
            </Row>
          </div>
          <div className="add-program-buttons client-config">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="normal"
              text="Cancel"
              callbackFunction={() => {
                this.props.clearConfigClientFields("programValidation");
                this.props.onCloseModal();
              }}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="normal"
              text={
                this.props.isEdit
                  ? "Save Value Meter Program"
                  : "Add Value Meter Program"
              }
              callbackFunction={this.props.addNewProgram}
            />
          </div>
        </>
      );
    }
  }

  class NotifyModalContent extends Component {
    render() {
      const notify_tab_data = [
        {
          tab_label: "Request for Target Setting",
          tab_value: 1,
          tab_id: "request_for_identified_setting",
          tab_content: (
            <div>
              <div className="notifyModal-dropdown">
                <CVMMutiSelectDropdown
                  label="Enter ID"
                  placeholder={""}
                  options={this.props.requestTargetRecepients}
                  value={this.props.requestTargetRecepients}
                  isDisabled={true}
                />
              </div>
              <div className="notifyModal-textarea">
                <div className="comments">
                  <GrooveTextBox
                    id="notifyTextarea"
                    name="notifyTextarea"
                    type="text"
                    placeholder="Description"
                    rows="4"
                    callbackFunction={(e) => this.props.setModalComments(e, "request")}
                    textValue={this.props.programIdentifiedModalComments.reqTarget}
                    maxLength={255}
                  />
                </div>
                {/* <div className="textareaLabel">Description</div>
                <textarea
                  id="notifyTextarea"
                  name="notifyTextarea"
                  rows="4"
                  cols="73"
                  maxlength="255"
                  value={this.props.programIdentifiedModalComments.reqTarget}
                  onChange={(e) => this.props.setModalComments(e, "request")}
                ></textarea> */}
              </div>
            </div>
          ),
        },
        {
          tab_label: "Acknowledgement",
          tab_value: 2,
          tab_id: "acknowledgement",
          tab_content: (
            <div>
              <div className="notifyModal-dropdown">
                <CVMMutiSelectDropdown
                  label="Mapped Stakeholders"
                  placeholder={""}
                  options={this.props.acknowledgementRecepients}
                  value={this.props.acknowledgementRecepients}
                  isDisabled={true}
                />
              </div>
              <div className="notifyModal-textarea">
                <div className="comments">
                  <GrooveTextBox
                    id="notifyTextarea"
                    name="notifyTextarea"
                    type="text"
                    placeholder={`${(this.props.programIdentifiedModalComments.acknowledgement !== "") ? "" : "Enter "}Comment`}
                    rows="4"
                    callbackFunction={(e) =>
                      this.props.setModalComments(e, "acknowledgement")}
                    textValue={this.props.programIdentifiedModalComments.acknowledgement}
                    maxLength={255}
                  />
                </div>
                {/* <div className="textareaLabel">Comment</div>
                <textarea
                  id="notifyTextarea"
                  name="notifyTextarea"
                  rows="4"
                  cols="73"
                  maxlength="255"
                  value={
                    this.props.programIdentifiedModalComments.acknowledgement
                  }
                  onChange={(e) =>
                    this.props.setModalComments(e, "acknowledgement")
                  }
                ></textarea> */}
              </div>
            </div>
          ),
        },
      ];
      return (
        <React.Fragment>
          <div className="notifyModal">
            <GrooveTabComponent theme="purple" tab_data={notify_tab_data} />
          </div>
          <div className="notifyModal-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="normal"
              text="Cancel"
              callbackFunction={this.props.onCloseModal}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="normal"
              text="Notify"
              callbackFunction={() => {
                this.props.onSaveNotifyModal();
                this.props.onCloseModal();
              }}
            />
          </div>
        </React.Fragment>
      );
    }
  }
  class ConfigureClientContainer extends Component {
    constructor() {
      super();
      this.state = {
        addProjectMain: {
          projectMain: "",
          reportingYearMain: "",
          industryMain: "",
          marketMain: "",
          clientTypeMain: "",
          offeringsMain: "",
          operationsAccountLeadMain: "",
          clientAdminMain: "",
        },
        fieldValidation: {
          projectMainValid: true,
          reportingYearMainValid: true,
          industryMainValid: true,
          marketMainValid: true,
          clientTypeMainValid: true,
          offeringsMainValid: true,
          operationsAccountLeadMainValid: true,
          clientAdminMainValid: true,
        },
        options: [
          { value: "1", label: "Option1", text: "Option1" },
          { value: "2", label: "Option2", text: "Option2" },
          { value: "3", label: "Option3", text: "Option3" },
          { value: "4", label: "Option4", text: "Option4" },
          { value: "5", label: "Option5", text: "Option5" },
          { value: "6", label: "Option6", text: "Option6" },
        ],
        tableProperties: {
          columns: [
            { field: "projectname", headerName: "Program Name" },
            { field: "offering", headerName: "Offering" },
            { field: "btlead", headerName: "BT Lead" },
            { field: "cxlead", headerName: "CXL Lead" },
          ],
          data: [
            {
              projectname: "AAAAAAAA",
              offering: "AAAAAAAA",
              btlead: "AAAAAAAA",
              cxllead: "AAAAAAAA",
            },
            {
              projectname: "AAAAAAAA",
              offering: "AAAAAAAA",
              btlead: "AAAAAAAA",
              cxllead: "AAAAAAAA",
            },
            {
              projectname: "AAAAAAAA",
              offering: "AAAAAAAA",
              btlead: "AAAAAAAA",
              cxllead: "AAAAAAAA",
            },
            {
              projectname: "AAAAAAAA",
              offering: "AAAAAAAA",
              btlead: "AAAAAAAA",
              cxllead: "AAAAAAAA",
            },
          ],
        },
        show: false,
        showSaveDraftToast: false,
        showNotifyToast: false,
        notifyShow: false,
        notifyModalActiveKey: 1,
        clientList: [
          {
            value: "carry.linn@accenture.com",
            label: "carry.linn@accenture.com",
            text: "carry.linn@accenture.com",
          },
          {
            value: "vijay.singh@accenture.com",
            label: "vijay.singh@accenture.com",
            text: "vijay.singh@accenture.com",
          },
          {
            value: "john.doe@accenture.com",
            label: "john.doe@accenture.com",
            text: "john.doe@accenture.com",
          },
          {
            value: "abc.xyz@accenture.com",
            label: "abc.xyz@accenture.com",
            text: "abc.xyz@accenture.com",
          },
        ],
        accordionIcon1: "chevron-up",
        accordionIcon2: "chevron-up",
        newSubOfferingList: [],
        newIOAList: [],
        isEdit: false,
        gridApi: () => { },
        showErrorFields: {
          basic: false,
          program: false,
        },
        showIsExistError: false
      };
    }
    componentDidMount = () => {
      document.getElementsByClassName(
        "md-stepper-horizontal"
      )[0].style.borderRadius = "16px";

      if (
        Object.keys(this.props.ClientConfigurationData.selectOffering)
          .length === 0
      ) {
        this.props.getOfferingsList();
      }
      this.props.getSubOffering();
      this.props.getMarketList();
      this.props.getClientStakeHolders();
      this.props.getClientTypeList();
      this.props.getIndustryList();
      this.props.getIOAList();
      this.props.enableDisableAddNewProgramButton();
      this.props.clearConfigClientFields("basicDetailsValidation");
    };
    componentWillUnmount = () => {
      document.getElementsByClassName(
        "md-stepper-horizontal"
      )[0].style.borderRadius = "16px 16px 0 0";
    };

    componentDidUpdate = (nextProps) => {
      if (
        nextProps.ClientConfigurationData.selectAddNewOffering.value !==
        this.props.ClientConfigurationData.selectAddNewOffering.value
      ) {
        this.setIOAList();
      }
    };

    onChangeModalTab = (event) => {
      let selectedActiveKey = event;
      this.setState({
        ...this.state,
        modalActiveKey: selectedActiveKey,
      });
    };

    setIOAList = () => {
      try {
        const selectedIOA = this.props.ClientConfigurationData.selectAddNewIOA
        const ioa = this.props.ClientConfigurationData.ioaList
          .filter(
            (x) =>
              x.OfferingID ===
              parseInt(
                this.props.ClientConfigurationData.selectAddNewOffering.value ||
                "0"
              )
          )
          .map((item) => {
            return {
              value: item.UserID,
              label: item.UserEmailID,
              text: item.UserEmailID,
            };
          });
        this.setState(
          {
            newIOAList: ioa,
          },
          () => {
            if (ioa.length === 1) {
              this.props.setAddNewProgramFields(ioa[0], "ioa");
            } else {
              if (Object.keys(selectedIOA).length > 0) {
                this.props.setAddNewProgramFields(selectedIOA, "ioa");
              } else {
                this.props.setAddNewProgramFields({}, "ioa");
              }
            }
          }
        );
      } catch (err) {
        console.log(err);
      }
    };

    onChangeModalTab = (event) => {
      let selectedActiveKey = event;
      this.setState({
        ...this.state,
        modalActiveKey: selectedActiveKey,
      });
    };
    onCloseModal = () => {
      if (this.state.isEdit) {
        this.state.gridApi.setRowData(
          this.props.ClientConfigurationData.programTableProperties.data
        );
        this.state.gridApi.deselectAll();
      }

      this.setState(
        {
          ...this.state,
          show: !this.state.show,
          isEdit: false,
          showErrorFields: {
            basic: false,
            program: false,
          },
          showIsExistError: false
        },
        () => {
          this.props.clearConfigClientFields("add");
        }
      );
    };
    onCloseSaveDraftToast = () => {
      this.setState({
        showSaveDraftToast: false,
      });
    };
    onCloseNotifyToast = () => {
      this.setState({
        showNotifyToast: false,
      });
    };
    onSaveNotifyModal = () => {
      this.setState({
        ...this.state,
        showNotifyToast: true,
      });
    };
    validateOnNotify = () => {
      this.props.validateBasicDetails((data) => {
        if (data) {
          this.props.showHideNotifyModalConfigClient();
        }
      });
    };
    onChangeNotifyModalTab = (event) => {
      let selectedActiveKey = event;
      this.setState({
        ...this.state,
        notifyModalActiveKey: selectedActiveKey,
      });
    };
    addProjectMainCallback = (e, id) => {
      let tempFormData = this.state.addProjectMain;
      let fieldsValidation = this.state.fieldValidation;
      switch (id) {
        case "projectMain":
          tempFormData.projectMain = e.value;
          fieldsValidation.projectMainValid = true;
          break;
        case "reportingYearMain":
          tempFormData.reportingYearMain = e.value;
          fieldsValidation.reportingYearMainValid = true;
          break;
        case "industryMain":
          tempFormData.industryMain = e.value;
          fieldsValidation.industryMainValid = true;
          break;
        case "marketMain":
          tempFormData.marketMain = e.value;
          fieldsValidation.marketMainValid = true;
          break;
        case "clientTypeMain":
          tempFormData.clientTypeMain = e.value;
          fieldsValidation.clientTypeMainValid = true;
          break;
        case "offeringsMain":
          tempFormData.offeringsMain = e.value;
          break;
        case "operationsAccountLeadMain":
          tempFormData.operationsAccountLeadMain = e.value;
          break;
        case "clientAdminMain":
          tempFormData.clientAdminMain = e.value;
          break;
        default:
      }
      this.setState({
        addProjectMain: tempFormData,
        fieldValidation: fieldsValidation,
      });
    };

    accordionOnClick = (i, e) => {
      switch (i) {
        case 1:
          this.setState({
            accordionIcon1:
              e === "chevron-down" ? "chevron-up" : "chevron-down",
          });
          break;
        case 2:
          this.setState({
            accordionIcon2:
              e === "chevron-down" ? "chevron-up" : "chevron-down",
          });
          break;
      }
    };

    onRowSelect = (selected) => {
      if (Object.keys(selected).length > 0) {
        this.setState(
          {
            ...this.state,
            show: !this.state.show,
            isEdit: true,
          },
          () => {
            this.props.setProgramDetails(selected[0]);
            this.setIOAList();
          }
        );
      }
    };

    getGridApi = (api) => {
      this.setState({
        gridApi: api,
      });
    };

    onExpandCollapse = (e) => {
      let elems = [];
      switch (e.currentTarget.id) {
        case "expandAll":
          elems = document.getElementsByClassName("collapse");
          // while (document.getElementsByClassName("collapse").length) {
          for (var i = 0; i < elems.length; i++) {
            if (elems[i].className === "collapse") {
              document
                .getElementsByClassName("collapse")
              [i].parentElement.childNodes[1].children[0].click();
            }
          }
          break;
        case "collapseAll":
          elems = document.getElementsByClassName("collapse show");
          // while (document.getElementsByClassName("collapse show").length) {
          for (var i = 0; i < elems.length; i++) {
            if (elems[i].className === "collapse show") {
              document
                .getElementsByClassName("collapse show")
              [i].parentElement.childNodes[1].children[0].click();
            }
          }
          break;
        default:
          break;
      }
    };

    onSaveAsDraft = () => {
      this.props.validateBasicDetails((data) => {
        if (data) {
          const basicFields = this.props.ClientConfigurationData
            .basicDetailsIsValid;
          const programFields = this.props.ClientConfigurationData
            .programIsValid;
          if (
            basicFields.industry &&
            basicFields.clientType &&
            basicFields.reportingYear &&
            basicFields.market &&
            basicFields.offerings
          ) {
            this.setState(
              {
                showErrorFields: {
                  basic: false,
                  program: false,
                },
              },
              () => {
                this.props.saveAsDraft();
              }
            );
          } else {
            this.setState({
              showErrorFields: {
                basic: true,
                program: false,
              },
            });
          }
        } else {
          this.setState({
            showErrorFields: {
              basic: true,
              program: false,
            },
          });
        }
      });
    };

    onAddNewProgram = () => {
      this.props.validateProgramFields((data) => {
        if (data) {
          this.setState(
            {
              showErrorFields: {
                basic: false,
                program: false,
              },
            },
            () => {
              this.props.addNewProgram(
                this.state.isEdit,
                this.props.ClientConfigurationData.selectedRow,
                (data) => {
                  if (data) {
                    this.setState({
                      showIsExistError: true
                    })
                  } else {
                    this.setState({
                      showIsExistError: false
                    })
                    this.onCloseModal();
                  }
                }
              );
            }
          );
        } else {
          this.setState({
            showErrorFields: {
              basic: false,
              program: true,
            },
          });
        }
      });
    };

    onSetOffering = async (e) => {
      try {
        await this.props.setOffering(e, (data) => {
          this.state.gridApi.setRowData(
            data
          )
        })
      }
      catch (err) {
        console.log(err)
      }
    }
    render() {
      const {
        industryList,
        clientTypeList,
        operationAcctLeadList,
        marketList,
        offeringList,
        clientAdminList,
        reportingYearList,
        selectIndustry,
        selectClientType,
        selectOperationAcctLead,
        selectReportingYear,
        selectMarket,
        selectOffering,
        selectClientAdmin,
        disableAddNewProgram,
        selectAddNewOffering,
        selectAddNewSubOffering,
        selectAddNewCXLead,
        selectAddNewBTLead,
        selectAddNewIOA,
        programNameValue,
        cxLeadList,
        btLeadList,
        programTableProperties,
        saveAsDraftToaster,
        acknowledgementRecepients,
        requestTargetRecepients,
        showNotifyModalConfigClient,
        programIdentifiedModalComments,
        selectedRow,
        basicDetailsIsValid,
        programIsValid,
        selectedReportingYear,
        disableFields,
        tempSubOfferingList,
        clientDetails
      } = this.props.ClientConfigurationData;

      const { clientList, selectedClient } = this.props.SharedData;

      const {
        setIndustry,
        setClientType,
        setOperationAcctLead,
        setReportingYearSelect,
        setMarket,
        setOffering,
        setClientAdmin,
        setAddNewProgramFields,
        notify,
        hideSaveAsDraftToaster,
        showHideNotifyModalConfigClient,
        validateProgramFields,
        clearConfigClientFields,
        setModalComments,
      } = this.props;

      return (
        <ConfigureClientPresentational
          modalActiveKey={this.state.modalActiveKey}
          onChangeModalTab={this.onChangeModalTab}
          show={this.state.show}
          onCloseModal={this.onCloseModal}
          onSaveNotifyModal={notify}
          showNotifyModalConfigClient={showNotifyModalConfigClient}
          showHideNotifyModalConfigClient={showHideNotifyModalConfigClient}
          options={this.state.options}
          tableProperties={programTableProperties}
          showSaveDraftToast={saveAsDraftToaster}
          onShowSaveDraftToast={this.onSaveAsDraft}
          onCloseSaveDraftToast={hideSaveAsDraftToaster}
          showNotifyToast={this.state.showNotifyToast}
          onCloseNotifyToast={this.onCloseNotifyToast}
          notifyShow={this.state.notifyShow}
          validateOnNotify={this.validateOnNotify}
          notifyModalActiveKey={this.state.notifyModalActiveKey}
          onChangeNotifyModalTab={this.onChangeNotifyModalTab}
          clientList={this.state.clientList}
          addProjectMainCallback={this.addProjectMainCallback}
          addProjectMain={this.state.addProjectMain}
          fieldValidation={this.state.fieldValidation}
          clientList={clientList}
          industryList={industryList}
          clientTypeList={clientTypeList}
          operationAcctLeadList={operationAcctLeadList}
          marketList={marketList}
          offeringList={offeringList}
          clientAdminList={clientAdminList}
          reportingYearList={reportingYearList}
          options={this.state.options}
          accordionOnClick={this.accordionOnClick}
          accordionIcon1={this.state.accordionIcon1}
          accordionIcon2={this.state.accordionIcon2}
          selectedClient={selectedClient}
          selectIndustry={selectIndustry}
          selectClientType={selectClientType}
          selectOperationAcctLead={selectOperationAcctLead}
          selectReportingYear={selectReportingYear}
          selectMarket={selectMarket}
          selectOffering={selectOffering}
          selectClientAdmin={selectClientAdmin}
          setIndustry={setIndustry}
          setClientType={setClientType}
          setOperationAcctLead={setOperationAcctLead}
          setReportingYear={setReportingYearSelect}
          setMarket={setMarket}
          setOffering={this.onSetOffering}
          setClientAdmin={setClientAdmin}
          disableAddNewProgram={disableAddNewProgram}
          selectAddNewOffering={selectAddNewOffering}
          setAddNewProgramFields={setAddNewProgramFields}
          selectAddNewSubOffering={selectAddNewSubOffering}
          selectAddNewCXLead={selectAddNewCXLead}
          selectAddNewBTLead={selectAddNewBTLead}
          selectAddNewIOA={selectAddNewIOA}
          programNameValue={programNameValue}
          cxLeadList={cxLeadList}
          btLeadList={btLeadList}
          ioaList={this.state.newIOAList}
          subOfferingList={tempSubOfferingList}
          addNewProgram={this.onAddNewProgram}
          requestTargetRecepients={requestTargetRecepients}
          acknowledgementRecepients={acknowledgementRecepients}
          onRowSelect={this.onRowSelect}
          isEdit={this.state.isEdit}
          getGridApi={this.getGridApi}
          onExpandCollapse={this.onExpandCollapse}
          setModalComments={setModalComments}
          selectedRow={selectedRow}
          basicDetailsIsValid={basicDetailsIsValid}
          programIsValid={programIsValid}
          validateProgramFields={validateProgramFields}
          clearConfigClientFields={clearConfigClientFields}
          selectedReportingYear={selectedReportingYear}
          programIdentifiedModalComments={programIdentifiedModalComments}
          disableFields={disableFields}
          showErrorFields={this.state.showErrorFields}
          clientDetails={clientDetails}
          showIsExistError={this.state.showIsExistError}
        />
      );
    }
  }

  return ConfigureClientContainer;
})();

export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getOfferingsList,
          getMarketList,
          getClientStakeHolders,
          getClientTypeList,
          getIndustryList,
          setIndustry,
          setClientType,
          setOperationAcctLead,
          setReportingYearSelect,
          setMarket,
          setOffering,
          setClientAdmin,
          setAddNewProgramFields,
          getSubOffering,
          getIOAList,
          addNewProgram,
          saveAsDraft,
          notify,
          hideSaveAsDraftToaster,
          showHideNotifyModalConfigClient,
          clearConfigClientFields,
          enableDisableAddNewProgramButton,
          setProgramDetails,
          setModalComments,
          validateBasicDetails,
          validateProgramFields
        }
      ),
      dispatch
    )
)(ConfigureClient);
