import React, { Component } from "react";

require("./LoadingScreen.css");

const LoadingScreen = (function () {
  class LoadingScreenPresentational extends Component {
    render() {
      return (
        <div className="loading-animation">
          <div className="lds-ripple"><div></div><div></div></div>
          <div className="loading-text">{this.props.text}</div>
        </div>
      );
    }
  }

  class LoadingScreenContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return (
        <LoadingScreenPresentational
          {...this.props}
        />
      );
    }
  }

  return LoadingScreenContainer;
})();

export default LoadingScreen;
