import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  GrooveTabComponent,
  GrooveTextBox,
  GrooveButton,
  GrooveInput,
} from "tfo-groove";
import { Accordion, Card } from "react-bootstrap";

require("./CVMRejectionModal.css");

const CVMRejectionModal = (function () {
  class CVMRejectionModalPresentational extends Component {
    render() {
      return (
        <div className="CVMSideModal-container">
          <div
            className="CVMSideModal-close"
          >
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => this.props.closeReasonModal("Reject")}
            />
          </div>
          <div className="CVMSideModal-title">
            Reject {this.props.type} Value
          </div>
          <div className="CVMSideModal-content">
            <div className="sub-dimension-input">
              <div className="sub-dim-input">
                <GrooveInput
                  id="input-basic"
                  name={this.props.type}
                  placeholder={this.props.type}
                  isDisabled={true}
                  inputValue={this.props.name}
                  type="text"
                  isAutocomplete="false"
                  callbackFunction={this.callbackFunction}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="sub-dimension-input">
              <div className="sub-dim-input">
                <GrooveInput
                  id="input-basic"
                  name="IdentifiedValue"
                  placeholder="Target Value"
                  type="text"
                  inputValue={this.props.value}
                  isDisabled={true}
                  isAutocomplete="false"
                  maxLength={255}
                />
              </div>
            </div>
            <div className="comments">
              <GrooveTextBox
                maxLength={255}
                id="comment-textbox"
                name="comment-textbox"
                type="text"
                placeholder="Reason for Rejection"
                textValue={this.props.reason ? this.props.reason : ""}
                callbackFunction={
                  this.props.type == "Dimension"
                    ? (a) =>
                      this.props.onChangeDimension(
                        a,
                        "inputReason",
                        this.props.dimensionIndex
                      )
                    : this.props.type == "SubDimension"
                      ? (a) =>
                        this.props.onChangeSubDimension(
                          a,
                          "inputReason",
                          this.props.dimensionIndex,
                          this.props.subDimensionIndex
                        )
                      : this.props.type == "BOI"
                        ? (a) =>
                          this.props.onChangeBOI(
                            a,
                            "inputReason",
                            this.props.dimensionIndex,
                            this.props.subDimensionIndex,
                            this.props.boiIndex
                          )
                        : null
                }
              />
            </div>
          </div>
          <div className="CVMSideModal-footer footer-position">
            <Row className="rejection-modal-buttons">
              <GrooveButton
                id="primry-btn-1"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="outline"
                colorClass="stat-alternate"
                size="auto"
                text="Cancel"
                callbackFunction={() => this.props.closeReasonModal("Reject")}
              />

              <GrooveButton
                id="primry-btn-2"
                name="Solid Button Primary-Ops"
                isDisabled={false}
                hasIcon={false}
                type="solid"
                colorClass="stat-alternate"
                size="auto"
                text="Save"
                callbackFunction={
                  this.props.type == "Dimension"
                    ? (a) =>
                      this.props.onChangeDimension(
                        a,
                        "saveReason",
                        this.props.dimensionIndex
                      )
                    : this.props.type == "SubDimension"
                      ? (a) =>
                        this.props.onChangeSubDimension(
                          a,
                          "saveReason",
                          this.props.dimensionIndex,
                          this.props.subDimensionIndex
                        )
                      : this.props.type == "BOI"
                        ? (a) =>
                          this.props.onChangeBOI(
                            a,
                            "saveReason",
                            this.props.dimensionIndex,
                            this.props.subDimensionIndex,
                            this.props.boiIndex
                          )
                        : () => this.props.closeReasonModal("Reject")
                }
              />
            </Row>
          </div>
        </div>
      );
    }
  }

  class CVMRejectionModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        reason: [],
      };
    }

    componentDidMount = () => {
      this.changeTextAreaClass()
    };

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }
    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    render() {
      return (
        <CVMRejectionModalPresentational
          {...this.props}
          onCloseModal={this.props.closeRejectionModal}
          onChangeDimension={this.props.onChangeDimension}
          onChangeSubDimension={this.props.onChangeSubDimension}
          onChangeBOI={this.props.onChangeBOI}
        />
      );
    }
  }

  return CVMRejectionModalContainer;
})();

export default CVMRejectionModal;
