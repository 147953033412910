import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import UserDefaultImage from '../../Static/images/sidenavbar/Username_people icon.png';
import { setUserInformation } from "../../Actions/index";

const _UserDetails = function () {

    class UserDetailsPresentational extends Component {

        render() {
            return (
                <Row className="userDetailsRowContainer">
                    <Row>
                        <Col xs={12} md={12} className="userPhotoContainer">
                            <UserPhoto />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="userDetailsContainer">
                            <div id="userContainer">
                                <Row><span id="userFullName">{this.props.lastname}, {this.props.firstname} {this.props.middleInitial}</span></Row>
                                <Row><span id="userRole">{this.props.userRole}</span></Row>
                            </div>
                        </Col>
                    </Row>
                </Row>
            );
        }
    }

    class UserPhoto extends React.Component {
        constructor() {
            super();
            this.state = { imageError: false }

            this.handleError = this.handleError.bind(this);
        }
        handleError(event) {
            this.setState({ imageError: true });
        }
        render() {
            if (this.state.imageError) {
                return (
                    <img className="img-rounded userLogo" src={UserDefaultImage} alt="" />
                )
            } else {
                return (
                    <img className="img-rounded userLogo" onError={this.handleError} src={"https://thumbnail.accenture.com/" + window.localStorage.getItem("PeopleKey") + ".jpg"} alt="" />
                )
            }
        }
    }

    class UserDetailsContainer extends Component {
        constructor() {
            super();
            this.state = {
                firstname: "",
                lastname: "",
                middlename: "",
                middleInitial: "",
                userRole: 0,
            };
        }

        componentDidMount() {

            var firstName = this.props.userInformation.firstName;
            var lastName = this.props.userInformation.lastName;
            var middleName = this.props.userInformation.middleInitial;
            var role = this.props.userInformation.role;

            this.setState({
                firstname: firstName !== "" ? this.toTitleCase(firstName) : "",
                lastname: lastName !== "" ? this.toTitleCase(lastName) : "",
                middlename: middleName !== "" ? this.toTitleCase(middleName) : "",
                middleInitial: middleName !== "" ? middleName.substr(0, 1).toUpperCase() + "." : "",
                userRole: role !== "" ? role : "User"
            })
        }

        toTitleCase = (str) => {
            return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        }

        render() {
            return (
                <UserDetailsPresentational
                    firstname={this.state.firstname}
                    lastname={this.state.lastname}
                    middlename={this.state.middlename}
                    middleInitial={this.state.middleInitial}
                    userRole={this.state.userRole}
                />
            );
        }
    }


    function mapDispatchToProps(dispatch) {
        return {
            SetUserInformation: userInfo => dispatch(setUserInformation(userInfo))
        };
    }
    function mapStateToProps(state) {
        return {
            userInformation: state.userInformation
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer);
}();

export default _UserDetails;
