import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { HttpGet } from '../../../Utilities/HTTPUtil';

import CVMSelectDropdown from '../../Shared/CVMSelectDropdown/CVMSelectDropdown';
import CommentaryList from './CommentaryList';
import ProjectLevel from './ProjectLevel';
import { FormDateDDMMMYYY, FormTimeAPM } from '../../../Utilities/Formatter';
import { HasProperty, HasValue, IsValid } from '../../../Utilities/Validations';

require('./DashboardCommentaryModal.css');

/* 
  Notice:
  This file is used only in Global SVM Dashboard. Changes in svm-dashboard library should be applied here.
*/

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const DashboardCommentaryHistoryModal = (function () {
  class DashboardCommentaryHistoryModalPresentational extends Component {
    render() {
      const {
        selectedProgram,
        boiOption,
        selectedBOI,
        isLoading,
        formatDate,
        boiItem,
        sdItem,
        dItem,
        selectedTab,
        handleChangeTab,
        isProject,
        selectedProject,
        isSuperAdmin,
        enterpriseID,
      } = this.props;

      const tab_data = [
        {
          tab_label: 'Program Level Commentary',
          tab_value: 1,
          tab_id: 'programLevel',
          tab_content: (
            <div>
              <div className="dashboard-commentary-comments">
                <CommentaryList
                  commentList={selectedBOI.comments.ProgramCommentList}
                  formatDate={formatDate}
                />
              </div>
            </div>
          ),
        },
        {
          tab_label: 'Project Level Commentary',
          tab_value: 2,
          tab_id: 'projectLevel',
          tab_content: (
            <ProjectLevel
              projectLevelComments={selectedBOI.comments.ProjectLevelComments}
              formatDate={formatDate}
              isHistory={true}
            />
          ),
        },
      ];

      let commentList = [];
      let isLatestProgramCommentOwner = false;
      if (isProject) {
        let projectDetails = selectedBOI.comments.ProjectLevelComments.find(
          (project) => project.ClientProjectID === selectedProject.value
        );
        if (projectDetails) commentList = projectDetails.ProjectCommentList;
      } else {
        if (IsValid(selectedBOI.comments.ProgramCommentList)) {
          let latestComment = selectedBOI.comments.ProgramCommentList.length > 0 ? selectedBOI.comments.ProgramCommentList[0] : null;
          let latestProgramComment = IsValid(boiItem.programComments) ?  boiItem.programComments.length > 0 ?  boiItem.programComments[0] : null : null;
          if (IsValid(latestComment)) {
            isLatestProgramCommentOwner = latestComment.CreatedUser === enterpriseID;
          } else if (IsValid(latestProgramComment)) {
            isLatestProgramCommentOwner = latestProgramComment.CreatedUser === enterpriseID;
          }
        }
      }

      // If component is called in Global Dashboard, default to view only
      let isProgramCreator = this.props.isFromGlobal
        ? false
        : isLatestProgramCommentOwner || isSuperAdmin;

      return (
        <React.Fragment>
          {(!isProgramCreator || isProject) && (
            <div className="comment-btn history-btn">
              <GrooveButton
                id={'addComment'}
                hasIcon={true}
                type="utility"
                colorClass=""
                text="View Previous Comments"
                iconAlignment="right"
                iconSize="tiny"
                iconName={'history'}
                iconStyle="solid"
                iconId="btn-icon-history"
                callbackFunction={(e) => {
                  this.props.handleBOIModal(
                    true,
                    boiItem.boiID,
                    sdItem.subDimensionID,
                    dItem.dimensionID
                  );
                }}
              />
            </div>
          )}
          <Modal
            show={this.props.show}
            centered
            dialogClassName="dashboard-commentary-modal dashboard-commentary-history-modal"
          >
            <Modal.Header>
              <Modal.Title>
                <div>
                  <GrooveIconSvg
                    id="dbCommentHistBckBtn"
                    customClassName="back-modal-btn"
                    size="normal"
                    name="arrow-left"
                    iconStyle="solid"
                    iconColor="stat-alternate"
                    callbackFunction={() => this.props.handleBOIModal(false)}
                  />
                  <div className="header24-semibold-midnight">History</div>
                </div>
                <GrooveIconSvg
                  id="dbCommentHistClsBtn"
                  customClassName="close-modal-btn"
                  size="large"
                  name="times"
                  iconStyle="solid"
                  iconColor="primary-acn"
                  callbackFunction={() => this.props.handleBOIModal(false)}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="commentary-dropdowns">
                  <CVMSelectDropdown
                    label="BOI: "
                    placeholder={''}
                    onChange={(e) => this.props.onSelectBOI(e)}
                    style={{
                      control: { width: '80px' },
                      menu: { width: '80px' },
                      mainContainer: {
                        width: '226px',
                        margin: '0 24px 0 0',
                        float: 'right',
                      },
                    }}
                    options={boiOption}
                    value={selectedBOI}
                    closeMenuOnSelect={true}
                  />
                  {isProject && (
                    <CVMSelectDropdown
                      label="Project: "
                      placeholder={''}
                      onChange={(e) => this.props.onSelectProject(e, true)}
                      style={{
                        control: { width: '80px' },
                        menu: { width: '80px' },
                        mainContainer: {
                          width: '250px',
                          margin: '0 24px 0 0',
                          float: 'right',
                        },
                      }}
                      options={selectedBOI.projectOption}
                      value={selectedProject}
                      closeMenuOnSelect={true}
                    />
                  )}
                </div>
                {isProject ? (
                  <div>
                    <div className="dashboard-commentary-comments">
                      {isLoading ? (
                        this.props.commentLoader()
                      ) : (
                        <CommentaryList
                          commentList={commentList}
                          formatDate={this.props.formatDate}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="dashboard-commentary-tabs">
                    <div className="project-tabs-list dim-tabs">
                      <div position="static" color="default">
                        <Tabs
                          className="project-detail-tabs"
                          value={selectedTab || 0}
                          onChange={handleChangeTab}
                          variant="scrollable"
                          scrollButtons="on"
                          indicatorColor="primary"
                          textColor="primary"
                          aria-label="scrollable auto tabs example"
                        >
                          {tab_data.map((tab, index) => {
                            return <Tab label={tab.tab_label} {...a11yProps(index)}/>;
                          })}
                        </Tabs>
                      </div>
                      {tab_data.map((tab, index) => {
                        return (
                          <TabPanel value={selectedTab} index={index}>
                            {isLoading ? this.props.commentLoader() : tab.tab_content}
                          </TabPanel>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    }
  }

  class DashboardCommentaryHistoryModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedTab: 0,

        show: false,
        boiOption: [],
        selectedBOI: {
          label: '',
          value: 0,
          clientProgramTargetID: 0,
          comments: {
            ProgramCommentList: [],
            ProjectLevelComments: [],
          },
          subDimensionID: 0,
          dimensionID: 0,
        },
        isLoading: true,
        selectedProject: {
          text: '',
          label: '',
          value: 0,
        },
        projectOption: [],
      };
    }

    handleChangeTab = (event, newValue) => {
      this.setState({
        selectedTab: newValue,
      });
    };

    formatDate = (createdTS) => {
      return FormDateDDMMMYYY(createdTS, true) + ' | ' + FormTimeAPM(createdTS, true);
    };

    commentLoader = () => {
      return (
        <div className="comment-loader">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    };

    handleBOIModal = async (isShow, boiID, subDimensionID, dimensionID) => {
      try {
        const { programID, dashboardCommentary, selectedYear } = this.props.DashboardData;
        const boiOption = dashboardCommentary.boiOption;
        const reportingYear = selectedYear.value[0];

        const projectItem = this.props.projectItem;
        const isProject = this.props.isProject;

        this.setState({
          show: isShow,
        });
        if (isShow) {
          let selectedProject = {
            value: 0,
            label: '',
            text: '',
          };
          if (projectItem) {
            selectedProject = {
              value: projectItem.clientProjectID,
              label: projectItem.clientProjectName,
              text: projectItem.clientProjectName,
            };
          }
          let selectedBOI = boiOption.find(
            (boi) =>
              boi.boiID === boiID &&
              boi.subDimensionID === subDimensionID &&
              boi.dimensionID === dimensionID
          );
          if (selectedBOI) {
            let projectOption = [];
            if (isProject) {
              const projectDetails = await HttpGet(
                `ValueMeterChart/GetProjectDetail?ProgramID=${programID}&BOIID=${boiID}&reportingYear=${reportingYear}&clientProgramTargetId=${selectedBOI.clientProgramTargetID}`
              );
              if (projectDetails) {
                projectDetails.forEach((projectItem) => {
                  let projectIdx = projectOption.findIndex(
                    (project) => project.value === projectItem.ClientProjectID
                  );
                  if (projectIdx === -1) {
                    projectOption.push({
                      value: projectItem.ClientProjectID,
                      label: projectItem.ClientProjectName,
                      text: projectItem.ClientProjectName,
                    });
                  }
                });
              }
            }

            let data = await HttpGet(
              `ValueMeterChart/GetProgramProjectComments?programId=${programID}&boiId=${boiID}&clientProgramTargetId=${selectedBOI.clientProgramTargetID}&reportingYear=${reportingYear}`
            );
            if (data) {
              let tempData = {
                ProgramCommentList: HasValue(data.ProgramCommentList)
                  ? data.ProgramCommentList
                  : [],
                ProjectLevelComments: HasValue(data.ProjectLevelComments)
                  ? data.ProjectLevelComments
                  : [],
              };
              selectedBOI.comments = tempData;
              selectedBOI.projectOption = projectOption;
              this.setState({
                selectedBOI: selectedBOI,
                selectedProject: selectedProject,
                isLoading: false,
              });
            }
          }
        } else {
          // reset data
        }
      } catch (e) {}
    };

    setProjectDetails = async (boiID) => {
      const isProject = this.props.isProject;
      const programID = this.props.DashboardData.programID;
      const reportingYear = this.props.DashboardData.selectedYear.value[0];
      const selectedBOI = this.state.selectedBOI;

      let projectOption = [];
      if (isProject) {
        const projectDetails = await HttpGet(
          `ValueMeterChart/GetProjectDetail?ProgramID=${programID}&BOIID=${boiID}&reportingYear=${reportingYear}&clientProgramTargetId=${selectedBOI.clientProgramTargetID}`
        );
        if (projectDetails) {
          projectDetails.forEach((projectItem) => {
            projectOption.push({
              value: projectItem.ClientProjectID,
              label: projectItem.ClientProjectName,
              text: projectItem.ClientProjectName,
            });
          });
        }
      }
      return projectOption;
    };

    onSelectBOI = async (e) => {
      const programID = this.props.DashboardData.programID;
      const isProject = this.props.isProject;
      const reportingYear = this.props.DashboardData.selectedYear.value[0];

      let selectedProject = {
        value: 0,
        label: '',
        text: '',
      };

      let projectOption = [];
      if (isProject) {
        const projectDetails = await HttpGet(
          `ValueMeterChart/GetProjectDetail?ProgramID=${programID}&BOIID=${e.boiID}&reportingYear=${reportingYear}&clientProgramTargetId=${e.clientProgramTargetID}`
        );
        if (projectDetails) {
          projectDetails.forEach((projectItem) => {
            let projectIdx = projectOption.findIndex(
              (project) => project.value === projectItem.ClientProjectID
            );
            if (projectIdx === -1) {
              projectOption.push({
                value: projectItem.ClientProjectID,
                label: projectItem.ClientProjectName,
                text: projectItem.ClientProjectName,
              });
            }
          });
        }
      }

      if (projectOption.length > 0) {
        selectedProject = projectOption[0];
      }

      this.setState({
        isLoading: true,
      });

      let data = await HttpGet(
        `ValueMeterChart/GetProgramProjectComments?programId=${programID}&boiId=${e.boiID}&clientProgramTargetId=${e.clientProgramTargetID}&reportingYear=${reportingYear}`
      );
      if (data) {
        let tempData = {
          ProgramCommentList: HasValue(data.ProgramCommentList) ? data.ProgramCommentList : [],
          ProjectLevelComments: HasValue(data.ProjectLevelComments)
            ? data.ProjectLevelComments
            : [],
        };
        e.comments = tempData;
        e.projectOption = projectOption;
        this.setState({
          selectedBOI: e,
          selectedProject: selectedProject,
          isLoading: false,
        });
      }
    };

    onSelectProject = (e) => {
      this.setState({
        selectedProject: e,
      });
    };

    render() {
      const { enterpriseID, isSuperAdmin, /*isIOAdmin*/ } = this.props.UserInformation;
      const { dashboardCommentary } = this.props.DashboardData;

      let boiOption = [];
      let selectedProgram = {};
      if (dashboardCommentary) {
        boiOption = dashboardCommentary.boiOption;
        selectedProgram = dashboardCommentary.selectedProgram;
      }

      // let superAdmin = String(window.location.href).toLowerCase().includes('dashboard/global') ? 
      // isSuperAdmin && !isIOAdmin : isSuperAdmin;

      let superAdmin = isSuperAdmin;

      return (
        <DashboardCommentaryHistoryModalPresentational
          {...this.state}
          handleBOIModal={this.handleBOIModal}
          onSelectBOI={this.onSelectBOI}
          handleChangeTab={this.handleChangeTab}
          formatDate={this.formatDate}
          commentLoader={this.commentLoader}
          onSelectProject={this.onSelectProject}
          enterpriseID={enterpriseID}
          isSuperAdmin={superAdmin}
          selectedProgram={
            HasProperty(dashboardCommentary, 'selectedProgram')
              ? dashboardCommentary.selectedProgram
              : ''
          }
          boiItem={this.props.boiItem}
          sdItem={this.props.sdItem}
          dItem={this.props.dItem}
          isProject={this.props.isProject}
          boiOption={boiOption}
          isFromGlobal={this.props.isFromGlobal}
        />
      );
    }
  }

  return DashboardCommentaryHistoryModalContainer;
})();

export default connect(
  (state) => {
    return {
      DashboardData: state.DashboardData,
      UserInformation: state.userInformation,
      SharedData: state.SharedData,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(DashboardCommentaryHistoryModal);
