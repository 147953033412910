import {
    GET_CLIENT_LIST,
    // SET_CLIENT_DETAILS,
    SET_REPORTING_YEAR,
    GET_REPORTING_YEAR_LIST,
    GET_CLIENT_ADMIN_LIST,
    GET_OFFERING_LIST,
    GET_MARKET_LIST,
    GET_OP_ACCOUNT_LEAD_LIST,
    GET_CLIENT_TYPE_LIST,
    GET_INDUSTRY_LIST,
    SET_INDUSTRY,
    SET_CLIENT_TYPE,
    SET_OPERATION_ACCT_LEAD,
    SET_WORKFLOW_REPORTING_YEAR,
    SET_MARKET,
    SET_OFFERING,
    SET_SHOULD_SHOW_TOASTER_NOTIF,
    SET_CLIENT_ADMIN,
    ENABLE_ADD_NEW_PROGRAM_BUTTON,
    SET_ADD_NEW_PROGRAM_OFFERING,
    SET_ADD_NEW_PROGRAM_SUB_OFFERING,
    SET_ADD_NEW_PROGRAM_BT_LEAD,
    SET_ADD_NEW_PROGRAM_CX_LEAD,
    SET_ADD_NEW_PROGRAM_IOA,
    SET_ADD_NEW_PROGRAM_NAME,
    GET_CX_LEAD_LIST,
    GET_BT_LEAD_LIST,
    GET_IOA_LIST,
    GET_SUB_OFFERING_LIST,
    ADD_NEW_PROGRAM,
    SHOW_SAVE_AS_DRAFT_TOASTER,
    SET_REQUEST_TARGET_RECEPIENTS,
    SET_ACKNOWLEDGEMENT_RECEPIENTS,
    SET_MODAL_COMMENTS,
    SHOW_HIDE_NOTIFY_MODAL_CONFIG_CLIENT,
    CLEAR_CONFIG_CLIENT_FIELDS,
    SET_BASIC_DETAILS,
    SET_PROGRAM_DETAILS,
    SHOW_NOTIFY_TOAST_SUCCESS,
    SET_BASIC_DETAILS_IS_VALID,
    SET_PROGRAM_IS_VALID,
    SET_ALL_SELECTED_OFFERING,
    SET_ALL_SELECTED_SUB_OFFERING,
    SET_TEMP_SUB_OFFERING_LIST,
    REMOVED_PROGRAM
} from '../Constants';

import DashboardIcon from "../Static/images/360cvm/Dashboard.svg"
import Utilities from './../Utilities/index'
// import editIcon from "../Static/images/360cvm/EditIcon.svg";
// import viewIcon from "../Static/images/360cvm/ViewIcon.svg";
// import { history } from "./../store";
import { getDashboardClientList } from "./Dashboard.Action";
import { getClientList } from "./Shared.Action";
import { HttpGet, GetSubMenuId } from '../Utilities/HTTPUtil';

// ----------------------------------CLIENT CONFIG PAGE----------------------------------------------

export const onClickFavorite = (value) => async (dispatch, state) => {
    try {
        var body = {
            ClientMasterId: value.value,
            UserId: state().userInformation.userID,
            IsActive: !value.favorite ? 1 : 0,
        };
        let token = await Utilities.getToken();
        fetch(Utilities.getApiHttps() + "ClientConfiguration/Favorite", {
            method: "POST",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(getDashboardClientList(state().userInformation.userID));
                dispatch(getClientList(state().userInformation.userID));
                let obj = [];
                for (let i = 0; i < data.length; i++) {
                    obj.push({
                        icon: data[i].ClientMasterLogo || DashboardIcon,
                        label: data[i].ClientMasterName,
                        favorite: data[i].UserID !== 0 ? true : false,
                        value: data[i].Id,
                    });
                }
                if (!state().userInformation.isSuperAdmin) {
                    dispatch({
                        type: GET_CLIENT_LIST,
                        clientList: obj,
                    });
                }
            });
    } catch (err) {
        console.log(err);
    }
};

export const getReportingYearList = () => async (dispatch) => {
    try {
        const offSet = 5
        let yearList = []
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth() + 1

        // check the FY cycle to add new FY
        if (currentMonth > 9) {
            const date = currentYear + 1
            yearList.push({
                label: date.toString(),
                value: date
            })
        }

        // get the list from current year minus 5 (offset)
        for (let i = 0; i < offSet; i++) {
            const date = currentYear - i
            yearList.push({
                label: date.toString(),
                value: date
            })
        }

        // check if the last year is 2016 hence it will add until FY2016
        const diffDate = yearList[yearList.length - 1].value - 2016
        if (diffDate > 0) {
            for (let i = 1; i <= diffDate; i++) {
                const date = yearList[yearList.length - 1].value - i
                yearList.push({
                    label: date.toString(),
                    value: date
                })
            }
        }

        dispatch({
            type: GET_REPORTING_YEAR_LIST,
            reportingYearList: yearList,
        });
    } catch (err) {
        console.log(err);
    }
};

export const setReportingYear = (event) => async (dispatch) => {
    try {
        dispatch({
            type: SET_REPORTING_YEAR,
            selectedReportingYear: event,
        });
    } catch (err) {
        console.log(err);
    }
};
export const setWorkFlowReportingYear = (event) => async (dispatch) => {
    try {
        dispatch({
            type: SET_WORKFLOW_REPORTING_YEAR,
            workFlowReportingYear: event,
        });
    } catch (err) {
        console.log(err);
    }
};

// ----------------------------------------CLIENT CONFIG AND PROGRAM STEP 1----------------------------------------------
export const getIndustryList = () => async (dispatch) => {
    try {
        let token = await Utilities.getToken();
        fetch(Utilities.getApiHttps() + "ClientAndProgram/IndustryDetails", {
            method: "GET",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                let data = [];
                for (let i = 0; i < res.length; i++) {
                    data.push({
                        value: res[i].Id,
                        label: res[i].IndustryName,
                        text: res[i].IndustryName,
                    });
                }
                dispatch({
                    type: GET_INDUSTRY_LIST,
                    industryList: data,
                });
            });
    } catch (err) {
        console.log(err);
    }
};
export const getClientTypeList = () => async (dispatch) => {
    try {
        let token = await Utilities.getToken();
        fetch(Utilities.getApiHttps() + "ClientAndProgram/ClientTypeDetails", {
            method: "GET",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                let data = [];
                for (let i = 0; i < res.length; i++) {
                    data.push({
                        value: res[i].Id,
                        label: res[i].ClientTypeName,
                        text: res[i].ClientTypeName,
                    });
                }
                dispatch({
                    type: GET_CLIENT_TYPE_LIST,
                    clientTypeList: data,
                });
            });
    } catch (err) {
        console.log(err);
    }
};
export const getClientStakeHolders = () => async (dispatch, state) => {
    try {
        let token = await Utilities.getToken();
        const clientID = state().SharedData.selectedClient.value
        fetch(Utilities.getApiHttps() + "ClientAndProgram/GetClientStakeHolders/Client?Client=" + clientID, {
            method: "GET",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(res => res.json())
            .then(res => {

                // Operation Account Lead
                dispatch({
                    type: GET_OP_ACCOUNT_LEAD_LIST,
                    operationAcctLeadList: res.filter(x => x.ProfileID === 4).map((item) => {
                        return (
                            {
                                value: item.UserID,
                                label: item.UserEmailID,
                                text: item.UserEmailID
                            }
                        )
                    })
                });
                // Client Admin
                dispatch({
                    type: GET_CLIENT_ADMIN_LIST,
                    clientAdminList: res.filter(x => x.ProfileID === 5).map((item) => {
                        return (
                            {
                                value: item.UserID,
                                label: item.UserEmailID,
                                text: item.UserEmailID
                            }
                        )
                    })
                });

                if (Object.keys(state().SharedData.selectedClient).length > 0) {
                    // OPERATION ACCOUNT LEAD VALUE
                    dispatch({
                        type: SET_OPERATION_ACCT_LEAD,
                        selectOperationAcctLead: res.filter(x => x.ProfileID === 4).map((item) => {
                            return (
                                {
                                    value: item.UserID,
                                    label: item.UserEmailID,
                                    text: item.UserEmailID
                                }
                            )
                        })[0]
                    });
                    // CLIENT ADMIN VALUE
                    dispatch({
                        type: SET_CLIENT_ADMIN,
                        selectClientAdmin: res.filter(x => x.ProfileID === 5).map((item) => {
                            return (
                                {
                                    value: item.UserID,
                                    label: item.UserEmailID,
                                    text: item.UserEmailID
                                }
                            )
                        })[0]
                    });
                    // BT Lead
                    dispatch({
                        type: GET_BT_LEAD_LIST,
                        btLeadList: res.filter(x => x.ProfileID === 6).map((item) => {
                            return (
                                {
                                    value: item.UserID,
                                    label: item.UserEmailID,
                                    text: item.UserEmailID
                                }
                            )
                        })
                    });
                    // CX Lead
                    dispatch({
                        type: GET_CX_LEAD_LIST,
                        cxLeadList: res.filter(x => x.ProfileID === 7).map((item) => {
                            return (
                                {
                                    value: item.UserID,
                                    label: item.UserEmailID,
                                    text: item.UserEmailID
                                }
                            )
                        })
                    });
                }
            });
    } catch (err) {
        console.log(err);
    }
};
export const getMarketList = () => async (dispatch) => {
    try {
        let token = await Utilities.getToken();
        fetch(Utilities.getApiHttps() + "ClientAndProgram/GetMarket", {
            method: "GET",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                let data = [];
                for (let i = 0; i < res.length; i++) {
                    data.push({
                        value: res[i].Id,
                        label: res[i].MarketUnitName,
                        text: res[i].MarketUnitName,
                    });
                }
                dispatch({
                    type: GET_MARKET_LIST,
                    marketList: data,
                });
            });
    } catch (err) {
        console.log(err);
    }
};
export const getOfferingsList = () => async (dispatch, state) => {
    try {
        let token = await Utilities.getToken();
        fetch(Utilities.getApiHttps() + "ClientAndProgram/GetOffering", {
            method: "GET",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                let data = [];
                for (let i = 0; i < res.length; i++) {
                    data.push({
                        value: res[i].Id,
                        label: res[i].OfferingLevel2,
                        text: res[i].OfferingLevel2,
                    });
                }
                dispatch({
                    type: GET_OFFERING_LIST,
                    offeringList: data,
                });
            });
    } catch (err) {
        console.log(err);
    }
};

export const setIndustry = (e) => async (dispatch, state) => {
    try {
        dispatch({
            type: SET_INDUSTRY,
            selectIndustry: e,
        });

        let selectFields = state().ClientConfigurationData;
        const offering = selectFields.selectOffering.hasOwnProperty("data")
            ? selectFields.selectOffering.data
            : selectFields.selectOffering;
        if (
            Object.keys(offering).length > 0 &&
            Object.keys(selectFields.selectMarket).length > 0 &&
            Object.keys(selectFields.selectReportingYear).length > 0 &&
            Object.keys(selectFields.selectClientType).length > 0 &&
            Object.keys(selectFields.selectIndustry).length > 0
        ) {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: false,
            });
        } else {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: true,
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const setClientType = (e) => async (dispatch, state) => {
    try {
        dispatch({
            type: SET_CLIENT_TYPE,
            selectClientType: e,
        });

        let selectFields = state().ClientConfigurationData;
        const offering = selectFields.selectOffering.hasOwnProperty("data")
            ? selectFields.selectOffering.data
            : selectFields.selectOffering;
        if (
            Object.keys(offering).length > 0 &&
            Object.keys(selectFields.selectMarket).length > 0 &&
            Object.keys(selectFields.selectReportingYear).length > 0 &&
            Object.keys(selectFields.selectClientType).length > 0 &&
            Object.keys(selectFields.selectIndustry).length > 0
        ) {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: false,
            });
        } else {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: true,
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const setOperationAcctLead = (e) => async (dispatch) => {
    try {
        dispatch({
            type: SET_OPERATION_ACCT_LEAD,
            selectOperationAcctLead: e,
        });
    } catch (err) {
        console.log(err);
    }
};

export const setReportingYearSelect = (e) => async (dispatch, state) => {
    try {
        const fields = state().ClientConfigurationData
        const clientId = Object.keys(fields.clientDetails).length > 0 ? fields.clientDetails.clientBasicDetailsId : []
        let offering = []

        if (clientId > 0) {
            let token = await Utilities.getToken();
            fetch(Utilities.getApiHttps() + "ClientAndProgram/GetReportingYear?ClientID=" + clientId,
                {
                    method: "GET",
                    headers: {
                        "Cache-Control":"no-store",
                        Pragma: "no-cache",
                        Expires: 0,
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                .then(res => res.json())
                .then(async year => {
                    if (Object.keys(year).length > 0) {
                        let token = await Utilities.getToken();
                        fetch(Utilities.getApiHttps() + "ClientAndProgram/GetClientBasicDetail?ClientID=" + clientId + "&reportingYear=" + year[0].ReportingYear,
                            {
                                method: "GET",
                                headers: {
                                    "Cache-Control":"no-store",
                                    Pragma: "no-cache",
                                    Expires: 0,
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + token,
                                },
                            })
                            .then(res => res.json())
                            .then(data => {
                                if (Object.keys(data).length > 0) {
                                    fields.selectIndustry = { text: data.IndustryName, label: data.IndustryName, value: data.IndustryID }
                                    fields.selectClientType = { text: data.ClientTypeName, label: data.ClientTypeName, value: data.ClientTypeID }
                                    fields.selectMarket = { text: data.MarketUnitName, label: data.MarketUnitName, value: data.MarketID }
                                    fields.selectReportingYear = e

                                    fields.disableFields = {
                                        industry: true,
                                        market: true,
                                        clientType: true
                                    }

                                    dispatch({
                                        type: SET_BASIC_DETAILS,
                                        payload: fields
                                    })
                                }
                            })
                    } else {
                        fields.selectReportingYear = e

                        dispatch({
                            type: SET_BASIC_DETAILS,
                            payload: fields
                        })
                    }
                })
        } else {
            fields.selectReportingYear = e
            fields.disableFields = {
                industry: false,
                market: false,
                clientType: false
            }
            dispatch({
                type: SET_BASIC_DETAILS,
                payload: fields
            })
        }

        let selectFields = state().ClientConfigurationData;
        const selectOffering = selectFields.selectOffering.hasOwnProperty("data")
            ? selectFields.selectOffering.data
            : selectFields.selectOffering;
        if (
            Object.keys(selectOffering).length > 0 &&
            Object.keys(selectFields.selectMarket).length > 0 &&
            Object.keys(selectFields.selectReportingYear).length > 0 &&
            Object.keys(selectFields.selectClientType).length > 0 &&
            Object.keys(selectFields.selectIndustry).length > 0
        ) {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: false,
            });
        } else {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: true,
            });
        }

    } catch (err) {
        console.log(err);
    }
};

export const setMarket = (e) => async (dispatch, state) => {
    try {
        dispatch({
            type: SET_MARKET,
            selectMarket: e,
        });

        let selectFields = state().ClientConfigurationData;
        const offering = selectFields.selectOffering.hasOwnProperty("data")
            ? selectFields.selectOffering.data
            : selectFields.selectOffering;
        if (
            Object.keys(offering).length > 0 &&
            Object.keys(selectFields.selectMarket).length > 0 &&
            Object.keys(selectFields.selectReportingYear).length > 0 &&
            Object.keys(selectFields.selectClientType).length > 0 &&
            Object.keys(selectFields.selectIndustry).length > 0
        ) {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: false,
            });
        } else {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: true,
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const setOffering = (e, onSuccess) => async (dispatch, state) => {
    try {
        dispatch({
            type: SET_OFFERING,
            selectOffering: e,
        });

        let selectFields = state().ClientConfigurationData;
        const offering = selectFields.selectOffering.hasOwnProperty("data")
            ? selectFields.selectOffering.data
            : selectFields.selectOffering;
        if (
            Object.keys(offering).length > 0 &&
            Object.keys(selectFields.selectMarket).length > 0 &&
            Object.keys(selectFields.selectReportingYear).length > 0 &&
            Object.keys(selectFields.selectClientType).length > 0 &&
            Object.keys(selectFields.selectIndustry).length > 0
        ) {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: false,
            });
        } else {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: true,
            });
        }

        const currentOffering = e.hasOwnProperty("data") ? e.data : Array.isArray(e) ? e : [e]
        if (Object.keys(currentOffering).length > 0) {
            // add check
            currentOffering.filter((x) => {
                return !selectFields.allOffering.some((y) => {
                    return x.value === y.value
                })
            }).map(x => {
                selectFields.allOffering.push({
                    ...x,
                    isChecked: true
                })
            })

            // uncheck
            selectFields.allOffering.filter((x) => {
                return !currentOffering.some((y) => {
                    return x.value === y.value
                })
            }).map(x => {
                return ({
                    ...x.isChecked = false
                })
            })

            // set to Active the InActive offering that is already existing on database
            selectFields.allOffering.filter((x) => {
                return currentOffering.some((y) => {
                    return x.value === y.value && x.isChecked !== y.isChecked
                })
            }).map(x => {
                return ({
                    ...x.isChecked = true
                })
            })

            // to set the program to inactive that are mapped in unchecked offering
            if (selectFields.programTableProperties.data.length > 0) {

                // offering to table
                selectFields.programTableProperties.data.filter(x => {
                    return selectFields.allOffering.some((y) => {
                        return x.offering === y.label && !y.isChecked
                    })
                }).map(x => {
                    return ({
                        ...x.isActive = false,
                        // to uncheck the suboffering
                        ...x.suboffering = (x.suboffering.hasOwnProperty("data") ? x.suboffering.data : Array.isArray(x.suboffering) ? x.suboffering : [x.suboffering]).map(y => {
                            let newData = y.hasOwnProperty("isChecked") ? y.isChecked = false
                                :
                                {
                                    ...y,
                                    isChecked: false
                                }
                            return ({
                                ...y = newData
                            })
                        })
                    })
                })

                const newData = selectFields.programTableProperties.data.filter(x => x.isActive)
                const removeData = selectFields.programTableProperties.data.filter(x => !x.isActive)

                dispatch({
                    type: ADD_NEW_PROGRAM,
                    programTableProperties: newData
                })

                dispatch({
                    type: REMOVED_PROGRAM,
                    removedProgram: removeData
                })

                onSuccess(newData)
            }

            dispatch({
                type: SET_ALL_SELECTED_OFFERING,
                allOffering: selectFields.allOffering
            })
        }
    } catch (err) {
        console.log(err);
    }
};

export const setClientAdmin = (e) => async (dispatch) => {
    try {
        dispatch({
            type: SET_CLIENT_ADMIN,
            selectClientAdmin: e,
        });
    } catch (err) {
        console.log(err);
    }
};

export const setAddNewProgramFields = (e, key) => async (dispatch, state) => {
    try {
        const selectFields = state().ClientConfigurationData
        const clientName = state().SharedData.selectedClient;
        let programIsValid = state().ClientConfigurationData.programIsValid
        switch (key) {
            case "offering":
                const subOfferingList = selectFields.subOfferingList.filter(x => x.OfferingID === e.value).map(x => {
                    return ({
                        text: x.SubOfferingName,
                        label: x.SubOfferingName,
                        value: x.ID
                    })
                })
                dispatch({
                    type: SET_TEMP_SUB_OFFERING_LIST,
                    tempSubOfferingList: subOfferingList
                })

                dispatch({
                    type: SET_ADD_NEW_PROGRAM_OFFERING,
                    selectAddNewOffering: e,
                });

                dispatch({
                    type: SET_ADD_NEW_PROGRAM_NAME,
                    programNameValue: clientName.label + "_" + e.label,
                });

                programIsValid.offering = Object.keys(e).length > 0 ? programIsValid.offering === null ? null : true : false
                break;
            case "suboffering":
                dispatch({
                    type: SET_ADD_NEW_PROGRAM_SUB_OFFERING,
                    selectAddNewSubOffering: e,
                });

                programIsValid.subOffering = Object.keys(e).length > 0 ? programIsValid.subOffering === null ? null : true : false

                const currentSubOffering = e.hasOwnProperty("data") ? e.data : Array.isArray(e) ? e : [e]
                if (Object.keys(currentSubOffering).length > 0) {
                    // add check
                    currentSubOffering.filter((x) => {
                        return !selectFields.allSubOffering.some((y) => {
                            return x.value === y.value
                        })
                    }).map(x => {
                        selectFields.allSubOffering.push({
                            ...x,
                            isChecked: true,
                            offeringId: selectFields.selectAddNewOffering.value
                        })
                    })

                    // check if the basic details offering is unchecked 
                    selectFields.allSubOffering.filter(x => {
                        return selectFields.allOffering.some(y => {
                            return y.Id === x.offeringId && !y.isChecked
                        })
                    }).map(x => {
                        x.isChecked = false
                    })

                    // uncheck the all suboffering from current suboffering
                    selectFields.allSubOffering.filter(z => z.offeringId === selectFields.selectAddNewOffering.value).filter((x) => {
                        return !currentSubOffering.some((y) => {
                            return x.value === y.value
                        })
                    }).map(x => {
                        return ({
                            ...x.isChecked = false
                        })
                    })

                    // set to Active the InActive offering that is already existing on database
                    selectFields.allSubOffering.filter(z => z.offeringId === selectFields.selectAddNewOffering.value && !z.isChecked).filter((x) => {
                        return currentSubOffering.some((y) => {
                            return x.value === y.value
                        })
                    }).map(x => {
                        return ({
                            ...x.isChecked = !x.isChecked
                        })
                    })

                    dispatch({
                        type: SET_ALL_SELECTED_SUB_OFFERING,
                        allSubOffering: selectFields.allSubOffering
                    })
                }
                break;
            case "btlead":
                dispatch({
                    type: SET_ADD_NEW_PROGRAM_BT_LEAD,
                    selectAddNewBTLead: e,
                });

                programIsValid.btLead = Object.keys(e).length > 0 ? programIsValid.btLead === null ? null : true : false
                break;
            case "cxlead":
                dispatch({
                    type: SET_ADD_NEW_PROGRAM_CX_LEAD,
                    selectAddNewCXLead: e,
                });

                programIsValid.cxlLead = Object.keys(e).length > 0 ? programIsValid.cxlLead === null ? null : true : false
                break;
            case "ioa":
                dispatch({
                    type: SET_ADD_NEW_PROGRAM_IOA,
                    selectAddNewIOA: e,
                });

                programIsValid.ioa = Object.keys(e).length > 0 ? programIsValid.ioa === null ? null : true : false
                break;
            default:
                break;
        }

        dispatch({
            type: SET_PROGRAM_IS_VALID,
            programIsValid: programIsValid
        })
    } catch (err) {
        console.log(err);
    }
};

export const getSubOffering = () => async (dispatch) => {
    try {
        let token = await Utilities.getToken();
        fetch(Utilities.getApiHttps() + "ClientAndProgram/GetSubOffering", {
            method: "GET",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                dispatch({
                    type: GET_SUB_OFFERING_LIST,
                    subOfferingList: data,
                });
            });
    } catch (err) {
        console.log(err);
    }
};

export const getIOAList = () => async (dispatch) => {
    try {
        let token = await Utilities.getToken();
        fetch(
            Utilities.getApiHttps() + "ClientAndProgram/GetOfferingStakeHolders",
            {
                method: "GET",
                headers: {
                    "Cache-Control":"no-store",
                    Pragma: "no-cache",
                    Expires: 0,
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch({
                    type: GET_IOA_LIST,
                    ioaList: data,
                });
            });
    } catch (err) {
        console.log(err);
    }
};

export const addNewProgram = (isEdit, rowID, isExist) => async (dispatch, state) => {
    try {
        const clientId = state().SharedData.selectedClient.value;
        const addNewProgram = state().ClientConfigurationData;
        const tableData = state().ClientConfigurationData.programTableProperties.data
        const isExistOnTable = Object.keys(tableData).length > 0 ? tableData.filter(x => x.projectname === addNewProgram.programNameValue) : []
        let isExistOnDB = false
        const addData = {
            projectname: addNewProgram.programNameValue,
            offering: addNewProgram.selectAddNewOffering.label,
            btlead: addNewProgram.selectAddNewBTLead.label,
            cxlead: addNewProgram.selectAddNewCXLead.label,
            ioa: addNewProgram.selectAddNewIOA,
            suboffering: addNewProgram.selectAddNewSubOffering,
            id: 0,
            rowID: Object.keys(tableData).length > 0 ? tableData.length : 0,
            clientOfferingId: 0,
            subOfferingListId: [],
            isActive: true
        }

        if (isEdit && rowID !== -1) {
            tableData[rowID].projectname = addNewProgram.programNameValue
            tableData[rowID].offering = addNewProgram.selectAddNewOffering.label
            tableData[rowID].btlead = addNewProgram.selectAddNewBTLead.label
            tableData[rowID].cxlead = addNewProgram.selectAddNewCXLead.label
            tableData[rowID].ioa = addNewProgram.selectAddNewIOA
            tableData[rowID].suboffering = addNewProgram.selectAddNewSubOffering

            dispatch({
                type: ADD_NEW_PROGRAM,
                programTableProperties: tableData,
            });
            isExist(false)
        } else {
            let token = await Utilities.getToken();
            let subMenuId = GetSubMenuId("Add New Program");
            fetch(Utilities.getApiHttps() + `ClientAndProgram/CheckProgramName?ProgramName=${addNewProgram.programNameValue}&ClientID=${clientId}&ReportingYear=${addNewProgram.selectReportingYear.value}&SubMenuID=${subMenuId}`,
                {
                    method: "GET",
                    headers: {
                        "Cache-Control":"no-store",
                        Pragma: "no-cache",
                        Expires: 0,
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                .then(res => res.text())
                .then(data => {
                    isExistOnDB = data === "true"
                    if (isExistOnTable.length === 0 && !isExistOnDB) {
                        tableData.push(addData);
                        isExist(false)
                    }
                    else {
                        isExist(true)
                    }

                    dispatch({
                        type: ADD_NEW_PROGRAM,
                        programTableProperties: tableData,
                    });
                })

        }
    } catch (err) {
        console.log(err);
    }
};

export const saveAsDraft = () => async (dispatch, state) => {
    try {
        const data = state().ClientConfigurationData;
        const client = state().SharedData.selectedClient;
        const offering = data.allOffering
        const programTable = data.programTableProperties.data;

        let body = {
            Id: data.clientDetails.clientBasicDetailsId || 0,
            clientMasterID: client.value,
            industryID: data.selectIndustry.value,
            clientTypeID: data.selectClientType.value,
            marketID: data.selectMarket.value,
            clientOfferingList:
                Object.keys(offering).length > 0 ? (offering.length > 1 ? offering : typeof (offering) !== "object" ? offering : [offering]).map((item) => {

                    const clientOfferingId = Object.keys(data.clientOfferingIdList).length > 0
                        ? data.clientOfferingIdList.filter(x => x.label === (!Array.isArray(item) ? item.label : item[0].label)) : []

                    const selectedOffering = data.offeringList.filter(x => x.value === (!Array.isArray(item) ? item.value : item[0].value))

                    const removedProgram = Object.keys(data.removedProgram).length > 0 ? data.removedProgram.filter(x => x.clientOfferingId === (clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0)) : []

                    if ((clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0) === 0 && (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked)) {
                        return null
                    } else {
                        return {
                            Id: clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0,
                            offeringID: (!Array.isArray(item) ? item.value : item[0].value),
                            reportingYear: data.selectReportingYear.value,
                            operationsAccountLead: data.selectOperationAcctLead.label,
                            clientAdmin: data.selectClientAdmin.label,
                            IsActive: (!Array.isArray(item) ? item.isChecked : item[0].isChecked),
                            clientProgramList:
                                Object.keys(removedProgram).length > 0 ?
                                    removedProgram.map(remove => {
                                        return {
                                            id: remove.id || 0,
                                            clientProgramName: remove.projectname,
                                            btLead: remove.btlead,
                                            cxlLead: remove.cxlead,
                                            ioApprover: typeof (remove.ioa) === "string" ? remove.ioa : remove.ioa.label || "",
                                            isActive: false,
                                            clientProgramSubOfferingList:
                                                Object.keys(data.allSubOffering).length > 0 ? data.allSubOffering.filter(x => x.offeringId === (!Array.isArray(item) ? item.value : item[0].value)).map((subOffering) => {
                                                    const id = Object.keys(remove.subOfferingListId).length > 0 ? remove.subOfferingListId.filter(x => x.SubOfferingID === subOffering.value).map(x => x.Id)[0] : 0

                                                    return {
                                                        Id: id,
                                                        subOfferingID: subOffering.value,
                                                        IsActive: false
                                                    };
                                                })
                                                    : [],
                                        };
                                    })
                                    :
                                    Object.keys(programTable).length > 0
                                        ? programTable
                                            .filter((x) => {
                                                return selectedOffering.some(y => {
                                                    return x.offering === (!Array.isArray(item) ? item.label : item[0].label) || x.offering === y.label
                                                })
                                            })
                                            .map((row) => {
                                                return {
                                                    id: row.id || 0,
                                                    clientProgramName: row.projectname,
                                                    btLead: row.btlead,
                                                    cxlLead: row.cxlead,
                                                    ioApprover: typeof (row.ioa) === "string" ? row.ioa : row.ioa.label || "",
                                                    isActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : row.isActive,
                                                    clientProgramSubOfferingList:
                                                        Object.keys(data.allSubOffering).length > 0 ? data.allSubOffering.filter(x => x.offeringId === (!Array.isArray(item) ? item.value : item[0].value)).map((subOffering) => {
                                                            const id = Object.keys(row.subOfferingListId).length > 0 ? row.subOfferingListId.filter(x => x.SubOfferingID === subOffering.value).map(x => x.Id)[0] : 0

                                                            if (id === 0 && subOffering.isChecked) {
                                                                return {
                                                                    Id: id,
                                                                    subOfferingID: subOffering.value,
                                                                    IsActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : subOffering.isChecked
                                                                };
                                                            } else if (id !== 0) {
                                                                return {
                                                                    Id: id,
                                                                    subOfferingID: subOffering.value,
                                                                    IsActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : subOffering.isChecked
                                                                };
                                                            } else {
                                                                return {
                                                                    Id: null
                                                                }
                                                            }
                                                        }).filter((y) => y.Id !== null)
                                                            : [],
                                                };
                                            })
                                        : [],
                        }
                    }
                }).filter(x => x)
                    : [],
        };
        let token = await Utilities.getToken();
        fetch(
            Utilities.getApiHttps() + "ClientAndProgram/AddEditClientBasicDetail",
            {
                method: "POST",
                headers: {
                    "Cache-Control":"no-store",
                    Pragma: "no-cache",
                    Expires: 0,
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(body),
            }
        )
            .then((res) => res.text())
            .then((data) => {
                if (data.includes("success")) {
                    dispatch({
                        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
                        toasterParam: {
                            show: true,
                            type: 'success',
                            message: "Your progress was saved as draft successfully!",
                        },
                    });
                    setTimeout(() => {
                        this.props.history.push("/ClientConfiguration")
                    }, 3000)
                } else {
                    alert("something went wrong")
                }
            });
    } catch (err) {
        console.log(err);
    }
};

export const hideSaveAsDraftToaster = () => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SAVE_AS_DRAFT_TOASTER,
            saveAsDraftToaster: false,
        });
    } catch (err) {
        console.log(err);
    }
};

export const showSaveAsDraftToaster = () => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SAVE_AS_DRAFT_TOASTER,
            saveAsDraftToaster: true,
        });
    } catch (err) {
        console.log(err);
    }
};

export const clearConfigClientFields = (key) => async (dispatch, state) => {
    try {
        const fields = state().ClientConfigurationData
        switch (key) {
            case "basic":
                fields.selectIndustry = ""
                fields.selectClientAdmin = ""
                fields.selectClientType = ""
                fields.selectReportingYear = ""
                fields.selectMarket = ""
                fields.selectOffering = []
                fields.disableAddNewProgram = true
                fields.programTableProperties.data = []
                fields.disableFields = {
                    industry: false,
                    market: false,
                    clientType: false
                }
                break;
            case "add":
                fields.selectAddNewBTLead = ""
                fields.selectAddNewCXLead = ""
                fields.selectAddNewIOA = ""
                fields.selectAddNewOffering = ""
                fields.selectAddNewSubOffering = ""
                fields.programNameValue = ""
                fields.tempSubOfferingList = []
                break;
            case "programValidation":
                fields.programIsValid = {
                    offering: null,
                    subOffering: null,
                    btLead: null,
                    cxlLead: null,
                    ioa: null
                }
                break;
            case "basicDetailsValidation":
                fields.basicDetailsIsValid = {
                    industry: null,
                    clientType: null,
                    reportingYear: null,
                    market: null,
                    offerings: null,
                }
                break;
            default:
                fields.selectIndustry = ""
                fields.selectClientAdmin = ""
                fields.selectClientType = ""
                fields.selectReportingYear = ""
                fields.selectMarket = ""
                fields.selectOffering = []
                fields.selectAddNewBTLead = ""
                fields.selectAddNewCXLead = ""
                fields.selectAddNewIOA = ""
                fields.selectAddNewOffering = ""
                fields.selectAddNewSubOffering = ""
                fields.disableAddNewProgram = true
                fields.programTableProperties.data = []
                fields.disableFields = {
                    industry: false,
                    market: false,
                    clientType: false
                }
                break;
        }
        dispatch({
            type: CLEAR_CONFIG_CLIENT_FIELDS,
            payload: fields
        })
    }
    catch (err) {
        console.log(err)
    }
}

export const getClientBasicDetails = (clientId, reportingYear) => async (dispatch, state) => {
    try {
        const fields = state().ClientConfigurationData
        let tableData = []
        let offering = []
        let subOffering = []
        let token = await Utilities.getToken();
        fetch(Utilities.getApiHttps() + "ClientAndProgram/GetClientBasicDetail?ClientID=" + clientId + "&reportingYear=" + reportingYear, {
            method: "GET",
            headers: {
                "Cache-Control":"no-store",
                Pragma: "no-cache",
                Expires: 0,
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(res => res.json())
            .then(data => {
                fields.clientId = data.Id
                fields.selectIndustry = { text: data.IndustryName, label: data.IndustryName, value: data.IndustryID }
                fields.selectClientType = { text: data.ClientTypeName, label: data.ClientTypeName, value: data.ClientTypeID }
                fields.selectMarket = { text: data.MarketUnitName, label: data.MarketUnitName, value: data.MarketID }
                fields.selectReportingYear = fields.reportingYearList.filter(x => x.value === reportingYear)

                // offering
                if (Object.keys(data.ClientOfferingList).length > 0) {
                    data.ClientOfferingList.map((item) => {
                        offering.push({
                            value: item.OfferingID,
                            label: item.OfferingLevel2,
                            text: item.OfferingLevel2,
                            isChecked: item.IsActive
                        })
                        fields.clientOfferingIdList.push({
                            Id: item.Id,
                            value: item.OfferingID,
                            label: item.OfferingLevel2,
                        })
                        // programs data
                        if (Object.keys(item.ClientProgramList).length > 0) {
                            item.ClientProgramList.map((program, index) => {
                                //clean suboffering
                                subOffering = []
                                // suboffering
                                if (Object.keys(program.ClientProgramSubOfferingList).length > 0) {
                                    program.ClientProgramSubOfferingList.map((sub) => {
                                        subOffering.push({
                                            value: sub.SubOfferingID,
                                            label: sub.SubOfferingName,
                                            text: sub.SubOfferingName,
                                            isChecked: sub.IsActive
                                        })
                                    })
                                }
                                // Program table
                                tableData.push({
                                    projectname: program.ClientProgramName,
                                    offering: item.OfferingLevel2,
                                    btlead: program.BTLead,
                                    cxlead: program.CXLLead,
                                    ioa: program.IOApprover,
                                    suboffering: subOffering.filter(x => x.isChecked),
                                    id: program.Id,
                                    rowID: index,
                                    clientOfferingId: item.Id,
                                    isActive: program.IsActive,
                                    subOfferingListId: program.ClientProgramSubOfferingList.map(x => {
                                        return ({
                                            Id: x.Id,
                                            SubOfferingID: x.SubOfferingID,
                                            IsActive: x.IsActive
                                        })
                                    })
                                })

                                subOffering.map(i => {
                                    fields.allSubOffering.push({
                                        ...i,
                                        offeringId: item.OfferingID
                                    })
                                })
                            })
                        }
                    })

                    fields.selectReportingYear = {
                        value: reportingYear,
                        label: reportingYear.toString(),
                        text: reportingYear.toString()
                    }
                    fields.selectOffering = offering.filter(x => x.isChecked)
                    fields.allOffering = offering
                }

                fields.disableFields.industry = true
                fields.disableFields.market = true
                fields.disableFields.clientType = true
                fields.programTableProperties.data = tableData

                dispatch({
                    type: SET_BASIC_DETAILS,
                    payload: fields
                })

                if (window.location.pathname.toLowerCase() !== "/clientconfiguration/configureclient") {
                    this.props.history.push("/ClientConfiguration/ConfigureClient")
                }
            })

    }
    catch (err) {
        console.log(err)
    }
}

export const enableDisableAddNewProgramButton = () => async (dispatch, state) => {
    try {
        const fields = state().ClientConfigurationData
        const offering = fields.selectOffering.hasOwnProperty("data") ? fields.selectOffering.data : fields.selectOffering

        if (
            Object.keys(offering).length > 0 &&
            Object.keys(fields.selectMarket).length > 0 &&
            Object.keys(fields.selectReportingYear).length > 0 &&
            Object.keys(fields.selectClientType).length > 0 &&
            Object.keys(fields.selectIndustry).length > 0
        ) {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: false
            });
        } else {
            dispatch({
                type: ENABLE_ADD_NEW_PROGRAM_BUTTON,
                disableAddNewProgram: true
            });
        }
    }
    catch (err) {
        console.log(err)
    }
}

export const notify = () => async (dispatch, state) => {
    try {
        const data = state().ClientConfigurationData;
        const client = state().SharedData.selectedClient;
        const offering = data.allOffering
        const programTable = data.programTableProperties.data;

        let body = {
            Id: data.clientDetails.clientBasicDetailsId || 0,
            clientMasterID: client.value,
            industryID: data.selectIndustry.value,
            clientTypeID: data.selectClientType.value,
            marketID: data.selectMarket.value,
            notifycomment: {
                acknowledgeComment: data.programIdentifiedModalComments.acknowledgement,
                stakeholderComment: data.programIdentifiedModalComments.reqTarget,
            },
            clientOfferingList:
                Object.keys(offering).length > 0 ? (offering.length > 1 ? offering : typeof (offering) !== "object" ? offering : [offering]).map((item) => {

                    const clientOfferingId = Object.keys(data.clientOfferingIdList).length > 0
                        ? data.clientOfferingIdList.filter(x => x.label === (!Array.isArray(item) ? item.label : item[0].label)) : []

                    const selectedOffering = data.offeringList.filter(x => x.value === (!Array.isArray(item) ? item.value : item[0].value))

                    const removedProgram = Object.keys(data.removedProgram).length > 0 ? data.removedProgram.filter(x => x.clientOfferingId === (clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0)) : []

                    if ((clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0) === 0 && (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked)) {
                        return null
                    } else {
                        return {
                            Id: clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0,
                            offeringID: (!Array.isArray(item) ? item.value : item[0].value),
                            reportingYear: data.selectReportingYear.value,
                            operationsAccountLead: data.selectOperationAcctLead.label,
                            clientAdmin: data.selectClientAdmin.label,
                            IsActive: (!Array.isArray(item) ? item.isChecked : item[0].isChecked),
                            clientProgramList:
                                Object.keys(removedProgram).length > 0 ?
                                    removedProgram.map(remove => {
                                        return {
                                            id: remove.id || 0,
                                            clientProgramName: remove.projectname,
                                            btLead: remove.btlead,
                                            cxlLead: remove.cxlead,
                                            ioApprover: typeof (remove.ioa) === "string" ? remove.ioa : remove.ioa.label || "",
                                            isActive: false,
                                            clientProgramSubOfferingList:
                                                Object.keys(data.allSubOffering).length > 0 ? data.allSubOffering.filter(x => x.offeringId === (!Array.isArray(item) ? item.value : item[0].value)).map((subOffering) => {
                                                    const id = Object.keys(remove.subOfferingListId).length > 0 ? remove.subOfferingListId.filter(x => x.SubOfferingID === subOffering.value).map(x => x.Id)[0] : 0

                                                    return {
                                                        Id: id,
                                                        subOfferingID: subOffering.value,
                                                        IsActive: false
                                                    };
                                                })
                                                    : [],
                                        };
                                    })
                                    :
                                    Object.keys(programTable).length > 0
                                        ? programTable
                                            .filter((x) => {
                                                return selectedOffering.some(y => {
                                                    return x.offering === (!Array.isArray(item) ? item.label : item[0].label) || x.offering === y.label
                                                })
                                            })
                                            .map((row) => {
                                                return {
                                                    id: row.id || 0,
                                                    clientProgramName: row.projectname,
                                                    btLead: row.btlead,
                                                    cxlLead: row.cxlead,
                                                    ioApprover: typeof (row.ioa) === "string" ? row.ioa : row.ioa.label || "",
                                                    isActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : row.isActive,
                                                    clientProgramSubOfferingList:
                                                        Object.keys(data.allSubOffering).length > 0 ? data.allSubOffering.filter(x => x.offeringId === (!Array.isArray(item) ? item.value : item[0].value)).map((subOffering) => {
                                                            const id = Object.keys(row.subOfferingListId).length > 0 ? row.subOfferingListId.filter(x => x.SubOfferingID === subOffering.value).map(x => x.Id)[0] : 0

                                                            if (id === 0 && subOffering.isChecked) {
                                                                return {
                                                                    Id: id,
                                                                    subOfferingID: subOffering.value,
                                                                    IsActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : subOffering.isChecked
                                                                };
                                                            } else if (id !== 0) {
                                                                return {
                                                                    Id: id,
                                                                    subOfferingID: subOffering.value,
                                                                    IsActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : subOffering.isChecked
                                                                };
                                                            } else {
                                                                return {
                                                                    Id: null
                                                                }
                                                            }
                                                        }).filter((y) => y.Id !== null)
                                                            : [],
                                                };
                                            })
                                        : [],
                        }
                    }
                }).filter(x => x)
                    : [],
        };
        let token = await Utilities.getToken();
        fetch(
            Utilities.getApiHttps() + "ClientAndProgram/ClientandProgramNotification",
            {
                method: "POST",
                headers: {
                    "Cache-Control":"no-store",
                    Pragma: "no-cache",
                    Expires: 0,
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(body),
            }
        )
            .then((res) => res.text())
            .then((data) => {
                if (data === "success") {
                    dispatch({
                        type: SHOW_NOTIFY_TOAST_SUCCESS,
                        showNotifyToastSuccess: true,
                    });
                    this.props.history.push("/ClientConfiguration");
                }
            });
    } catch (err) {
        console.log(err);
    }
};

export const showHideNotifyModalConfigClient = () => async (
    dispatch,
    state
) => {
    try {
        const data = state().ClientConfigurationData;
        const isValid = data.basicDetailsIsValid.industry &&
            data.basicDetailsIsValid.clientType &&
            data.basicDetailsIsValid.reportingYear &&
            data.basicDetailsIsValid.market &&
            data.basicDetailsIsValid.offerings
        if (isValid) {
            if (!data.showNotifyModalConfigClient) {

                const client = state().SharedData.selectedClient;
                const offering = data.allOffering
                const programTable = data.programTableProperties.data;

                let body = {
                    Id: data.clientDetails.clientBasicDetailsId || 0,
                    clientMasterID: client.value,
                    industryID: data.selectIndustry.value,
                    clientTypeID: data.selectClientType.value,
                    marketID: data.selectMarket.value,
                    notifycomment: {
                        acknowledgeComment: data.programIdentifiedModalComments.acknowledgement,
                        stakeholderComment: data.programIdentifiedModalComments.reqTarget,
                    },
                    clientOfferingList:
                        Object.keys(offering).length > 0 ? (offering.length > 1 ? offering : typeof (offering) !== "object" ? offering : [offering]).map((item) => {

                            const clientOfferingId = Object.keys(data.clientOfferingIdList).length > 0
                                ? data.clientOfferingIdList.filter(x => x.label === (!Array.isArray(item) ? item.label : item[0].label)) : []

                            const selectedOffering = data.offeringList.filter(x => x.value === (!Array.isArray(item) ? item.value : item[0].value))

                            const removedProgram = Object.keys(data.removedProgram).length > 0 ? data.removedProgram.filter(x => x.clientOfferingId === (clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0)) : []

                            if ((clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0) === 0 && (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked)) {
                                return null
                            } else {
                                return {
                                    Id: clientOfferingId.length > 0 ? clientOfferingId[0].Id : 0,
                                    offeringID: (!Array.isArray(item) ? item.value : item[0].value),
                                    reportingYear: data.selectReportingYear.value,
                                    operationsAccountLead: data.selectOperationAcctLead.label,
                                    clientAdmin: data.selectClientAdmin.label,
                                    IsActive: (!Array.isArray(item) ? item.isChecked : item[0].isChecked),
                                    clientProgramList:
                                        Object.keys(removedProgram).length > 0 ?
                                            removedProgram.map(remove => {
                                                return {
                                                    id: remove.id || 0,
                                                    clientProgramName: remove.projectname,
                                                    btLead: remove.btlead,
                                                    cxlLead: remove.cxlead,
                                                    ioApprover: typeof (remove.ioa) === "string" ? remove.ioa : remove.ioa.label || "",
                                                    isActive: false,
                                                    clientProgramSubOfferingList:
                                                        Object.keys(data.allSubOffering).length > 0 ? data.allSubOffering.filter(x => x.offeringId === (!Array.isArray(item) ? item.value : item[0].value)).map((subOffering) => {
                                                            const id = Object.keys(remove.subOfferingListId).length > 0 ? remove.subOfferingListId.filter(x => x.SubOfferingID === subOffering.value).map(x => x.Id)[0] : 0

                                                            return {
                                                                Id: id,
                                                                subOfferingID: subOffering.value,
                                                                IsActive: false
                                                            };
                                                        })
                                                            : [],
                                                };
                                            })
                                            :
                                            Object.keys(programTable).length > 0
                                                ? programTable
                                                    .filter((x) => {
                                                        return selectedOffering.some(y => {
                                                            return x.offering === (!Array.isArray(item) ? item.label : item[0].label) || x.offering === y.label
                                                        })
                                                    })
                                                    .map((row) => {
                                                        return {
                                                            id: row.id || 0,
                                                            clientProgramName: row.projectname,
                                                            btLead: row.btlead,
                                                            cxlLead: row.cxlead,
                                                            ioApprover: typeof (row.ioa) === "string" ? row.ioa : row.ioa.label || "",
                                                            isActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : row.isActive,
                                                            clientProgramSubOfferingList:
                                                                Object.keys(data.allSubOffering).length > 0 ? data.allSubOffering.filter(x => x.offeringId === (!Array.isArray(item) ? item.value : item[0].value)).map((subOffering) => {
                                                                    const id = Object.keys(row.subOfferingListId).length > 0 ? row.subOfferingListId.filter(x => x.SubOfferingID === subOffering.value).map(x => x.Id)[0] : 0

                                                                    if (id === 0 && subOffering.isChecked) {
                                                                        return {
                                                                            Id: id,
                                                                            subOfferingID: subOffering.value,
                                                                            IsActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : subOffering.isChecked
                                                                        };
                                                                    } else if (id !== 0) {
                                                                        return {
                                                                            Id: id,
                                                                            subOfferingID: subOffering.value,
                                                                            IsActive: (!Array.isArray(item) ? !item.isChecked : !item[0].isChecked) ? false : subOffering.isChecked
                                                                        };
                                                                    } else {
                                                                        return {
                                                                            Id: null
                                                                        }
                                                                    }
                                                                }).filter((y) => y.Id !== null)
                                                                    : [],
                                                        };
                                                    })
                                                : [],
                                }
                            }
                        }).filter(x => x)
                            : [],
                };

                let requestTargetRecepients = [];
                let acknowledgementRecepients = [];
                for (var i = 0; i < body.clientOfferingList.length; i++) {
                    acknowledgementRecepients.push(
                        body.clientOfferingList[i].operationsAccountLead
                    );
                    for (
                        var j = 0;
                        j < body.clientOfferingList[i].clientProgramList.length;
                        j++
                    ) {
                        requestTargetRecepients.push(
                            body.clientOfferingList[i].clientProgramList[j].btLead
                        );
                        acknowledgementRecepients.push(
                            body.clientOfferingList[i].clientProgramList[j].btLead,
                            body.clientOfferingList[i].clientProgramList[j].cxlLead,
                            body.clientOfferingList[i].clientProgramList[j].ioApprover
                        );
                    }
                }
                requestTargetRecepients = Array.from(new Set(requestTargetRecepients));
                acknowledgementRecepients = Array.from(
                    new Set(acknowledgementRecepients)
                );
                let tempReqTargetRecList = [];
                for (var k = 0; k < requestTargetRecepients.length; k++) {
                    tempReqTargetRecList.push({
                        value: requestTargetRecepients[k],
                        label: requestTargetRecepients[k],
                    });
                }
                requestTargetRecepients = tempReqTargetRecList;
                let ackRecList = [];
                for (var l = 0; l < acknowledgementRecepients.length; l++) {
                    ackRecList.push({
                        value: acknowledgementRecepients[l],
                        label: acknowledgementRecepients[l],
                    });
                }
                acknowledgementRecepients = ackRecList;
                dispatch({
                    type: SET_REQUEST_TARGET_RECEPIENTS,
                    requestTargetRecepients: requestTargetRecepients,
                });
                dispatch({
                    type: SET_ACKNOWLEDGEMENT_RECEPIENTS,
                    acknowledgementRecepients: acknowledgementRecepients,
                });
                dispatch({
                    type: SHOW_HIDE_NOTIFY_MODAL_CONFIG_CLIENT,
                    showNotifyModalConfigClient: !data.showNotifyModalConfigClient,
                });
            }
            //if modal will close, clear fields
            else {
                dispatch({
                    type: SET_REQUEST_TARGET_RECEPIENTS,
                    requestTargetRecepients: [],
                });

                dispatch({
                    type: SET_ACKNOWLEDGEMENT_RECEPIENTS,
                    acknowledgementRecepients: [],
                });

                dispatch({
                    type: SET_MODAL_COMMENTS,
                    programIdentifiedModalComments: { reqTarget: "", acknowledgement: "" },
                });

                dispatch({
                    type: SHOW_HIDE_NOTIFY_MODAL_CONFIG_CLIENT,
                    showNotifyModalConfigClient: !data.showNotifyModalConfigClient,
                });
            }
        }
    } catch (err) {
        console.log(err);
    }
};

export const setProgramDetails = (selectedData) => async (dispatch, state) => {
    try {
        let fieldData = state().ClientConfigurationData
        const selectedOfferingList = fieldData.selectOffering.hasOwnProperty("data") ? fieldData.selectOffering.data : fieldData.selectOffering
        const offeringList = fieldData.offeringList.filter(x => {
            return (Array.isArray(selectedOfferingList) ? selectedOfferingList : [selectedOfferingList]).some(y => {
                return x.value === y.value
            })
        })
        const offering1 = (Array.isArray(offeringList) ? offeringList : [offeringList]).filter(x => x.label === selectedData.offering || x.label === selectedData.projectname.split("_")[1]).map(x => {
            return ({ value: x.value, label: x.label, text: x.text })
        })[0]

        const offering2 = (Array.isArray(selectedOfferingList) ? selectedOfferingList : [selectedOfferingList]).filter(x => x.label === selectedData.offering || x.label === selectedData.projectname.split("_")[1]).map(x => {
            return ({ value: x.value, label: x.label, text: x.text })
        })[0]

        fieldData.selectAddNewOffering = offering1 !== undefined ? offering1 : offering2

        fieldData.selectAddNewBTLead = fieldData.btLeadList.filter(x => x.label === selectedData.btlead)[0]
        fieldData.selectAddNewCXLead = fieldData.cxLeadList.filter(x => x.label === selectedData.cxlead)[0]
        fieldData.selectAddNewIOA = typeof selectedData.ioa !== "string" ? selectedData.ioa : fieldData.ioaList.filter(x => x.OfferingID === fieldData.selectAddNewOffering.value && x.UserEmailID === selectedData.ioa).length > 0 ? fieldData.ioaList.filter(x => x.OfferingID === fieldData.selectAddNewOffering.value && x.UserEmailID === selectedData.ioa).map(x => {
            return ({
                value: x.UserID,
                label: x.UserEmailID,
                text: x.UserEmailID
            })
        })[0] : {}
        fieldData.programNameValue = selectedData.projectname
        fieldData.tempSubOfferingList = fieldData.subOfferingList.filter(x => x.OfferingID === fieldData.selectAddNewOffering.value).map(x => {
            return ({
                text: x.SubOfferingName,
                label: x.SubOfferingName,
                value: x.ID
            })
        })
        fieldData.selectAddNewSubOffering = (selectedData.suboffering.hasOwnProperty("data") ? selectedData.suboffering.data : Array.isArray(selectedData.suboffering) ? selectedData.suboffering : [selectedData.suboffering]).map((x) => {
            return ({
                ...x,
                isChecked: true
            })
        })
        fieldData.selectedRow = selectedData.rowID


        dispatch({
            type: SET_PROGRAM_DETAILS,
            payload: fieldData
        })
    }
    catch (err) {
        console.log(err)
    }
}

export const setModalComments = (e, tab) => async (dispatch, state) => {
    try {
        const data = state().ClientConfigurationData;
        let comments = data.programIdentifiedModalComments;
        switch (tab) {
            case "request":
                comments.reqTarget = e.target.value;
                break;
            case "acknowledgement":
                comments.acknowledgement = e.target.value;
                break;
            default:
                break;
        }
        dispatch({
            type: SET_MODAL_COMMENTS,
            programIdentifiedModalComments: comments,
        });
    } catch (err) {
        console.log(err);
    }
};
export const onCloseNotifyToast = () => async (dispatch, state) => {
    try {
        dispatch({
            type: SHOW_NOTIFY_TOAST_SUCCESS,
            showNotifyToastSuccess: false,
        });
    } catch (err) {
        console.log(err);
    }
};

export const validateBasicDetails = (isCompleted) => async (dispatch, state) => {
    try {
        const fields = state().ClientConfigurationData
        let basicDetailsFields = fields.basicDetailsIsValid

        basicDetailsFields.industry = Object.keys(fields.selectIndustry).length > 0 ? true : false
        basicDetailsFields.clientType = Object.keys(fields.selectClientType).length > 0 ? true : false
        basicDetailsFields.reportingYear = Object.keys(fields.selectReportingYear).length > 0 ? true : false
        basicDetailsFields.market = Object.keys(fields.selectMarket).length > 0 ? true : false
        basicDetailsFields.offerings = Object.keys(fields.selectOffering.hasOwnProperty("data") ? fields.selectOffering.data : fields.selectOffering).length > 0 ? true : false

        dispatch({
            type: SET_BASIC_DETAILS_IS_VALID,
            basicDetailsIsValid: basicDetailsFields
        })

        isCompleted(true)
    }
    catch (err) {
        isCompleted(false)
        console.log(err)
    }
}

export const validateProgramFields = (isValid) => async (dispatch, state) => {
    try {
        const fields = state().ClientConfigurationData
        let programFields = fields.programIsValid

        programFields.offering = Object.keys(fields.selectAddNewOffering).length > 0 ? true : false
        programFields.subOffering = Object.keys(fields.selectAddNewSubOffering.hasOwnProperty("data") ? fields.selectAddNewSubOffering.data : fields.selectAddNewSubOffering).length > 0 ? true : false
        programFields.btLead = Object.keys(fields.selectAddNewBTLead).length > 0 ? true : false
        programFields.cxlLead = Object.keys(fields.selectAddNewCXLead).length > 0 ? true : false
        programFields.ioa = Object.keys(fields.selectAddNewIOA).length > 0 ? true : false

        if (
            programFields.offering &&
            programFields.subOffering &&
            programFields.btLead &&
            programFields.cxlLead &&
            programFields.ioa
        ) {
            isValid(true)
        } else {
            isValid(false)
        }

        dispatch({
            type: SET_PROGRAM_IS_VALID,
            programIsValid: programFields
        })
    }
    catch (err) {
        console.log(err)
    }
}

export const isUserIOApprover = (value) => async (dispatch, state) => {
    try {
        let subMenuId = GetSubMenuId("Client Management")
        let data = await HttpGet(`ClientConfiguration/CheckIfIOApproverUser?UseremailId=${value}&SubMenuID=${subMenuId}`)
        return data

    } catch (err) {
        console.log(err)
    }
}