import React, { Component } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import {
    GrooveRadioButton,
    GrooveSelectMenuBasic,
    GrooveTextBox,
    GrooveButton,
    GrooveIconSvg,
    GrooveInput
  } from "tfo-groove";
import { HttpGet } from "../../../../Utilities/HTTPUtil";

  import CVMMultiSelectDropdown from "../../CVMMutiSelectDropdown/CVMMutiSelectDropdown";

require("../CVMSubmitRejectionModal.css");

const ApprovalRecepient = (function() {
  class ApprovalRecepientPresentational extends Component {
    render() {
      return (
        <div className="rejection-recipient">
            <p>Approval Recipient</p>
          <div className="notifyModal-dropdown">
              <CVMMultiSelectDropdown 
                value={this.props.value}
                isDisabled={true}
                label={this.props.label}
              />
          </div>
          </div>
        )
    }
  }

  class ApprovalRecepientContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }


    render() {
      return <ApprovalRecepientPresentational 
        value={this.props.value}
        label={this.props.label}
      />;
    }
  }

  return ApprovalRecepientContainer;
})();

export default ApprovalRecepient;
