import {
  SHOW_TOAST_NOTIFICATION,
  ON_TILE_SELECT_PROJECT,
  SET_BUTTON_STATE,
  GET_FILE_DETAILS,
  GET_RDV_PROGRAM_LIST,
  SET_REJECTED_COMMENTS,
  ON_SELECT_STATUS_OPTIONS,
  ON_SELECT_REPORTING_YEAR_OPTIONS,
  ON_SELECT_CREATION_YEAR_OPTIONS,
  SET_RDV_CLIENT_DETAILS,
  GET_PDE_TABLE_DATA,
  SELECTED_PROGRAM_PROJECT,
  GET_PROGRAM_OPTIONS,
  SET_RVD_SELECTED_PROGRAM,
  SET_PROJECT_LIST_ROLLER,
} from '../Constants';
import { HttpGet, GetSubMenuId } from '../Utilities/HTTPUtil';
import { HasProperty, IsValid, HasValue, IsObjEmpty, IsValidStr, IsArrayEmpty } from '../Utilities/Validations';
import { TryGetValue, FormDateDDMMMYYY, GetMonthMMM, GetQuarter, GetMonthIndex, getUserTimeZone } from '../Utilities/Formatter';
import { getSRDetails, getBusinessAdvisorList, getSFADetails, updateHTMLStringForPPT } from './ConfigureProgram.Action';
import {clientStoryInitialState} from '../Reducers/ConfigureProgram.reducer';
import { validateAccessRVD, InitRVD } from './AccessValidation.Action';
import { isProfileExisting } from './Shared.Action';
// import projectDataJSON from './samp2.json';

let isDisabled = false;
export const onCloseNotifyToast = (event, key) => async (dispatch, state) => {
  try {
    dispatch({
      type: SHOW_TOAST_NOTIFICATION,
      showToastNotification: false,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setConfProgButtonState = (params) => async (dispatch, state) => {
  dispatch({
    type: SET_BUTTON_STATE,
    buttonState: params,
  });
};

export const getFileDetails = (projectID, deliveryCenterID) => async (dispatch, state) => {
  try {
    let clientID = state().SharedData.selectedClient.value;
    let fileData = {
      iD: 0,
      clientProjectID: 0,
      deliveryCenterID: 0,
      fileName: '',
      fileUploadDate: '',
      fileUploadedBy: '',
    };
    let subMenuId = GetSubMenuId("Project Data Entry");
    let data = IsValid(projectID)
      ? await HttpGet(
        `ProjectDataEntry/GetDeliveryUserUploadedFileDetails?clientID=${clientID}&projectID=${projectID}&deliveryCenterID=${deliveryCenterID}&SubMenuID=${subMenuId}`
      )
      : fileData;
    if (IsValid(data)) {
      dispatch({
        type: GET_FILE_DETAILS,
        fileData: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getProgramList = () => async (dispatch, state) => {
  try {
    let clientID = state().SharedData.selectedClient.value;
    let programIDs = [];
    let tempProg = [];
    let cssClass = '';

    let data = await HttpGet('ReportValueDelivered/GetProjectOverallStatus?clientID=' + clientID);

    if (Object.keys(data).length > 0) {
      programIDs = Array.from(new Set(data.map((item) => item.ClientProgramID)));
      //get number of programs
      programIDs.map((item, index) => {
        tempProg.push({
          programName: '',
          programID: item,
          projects: [],
        });
      });

      //map projects to respective program arrays
      data.map((item, index) => {
        switch (item.Status) {
          case 'Awaiting Approval':
            cssClass = 'card-awaitingApproval';
            break;
          case 'In Progress':
            cssClass = '';
            break;
          case 'Approved':
            cssClass = 'card-approved';
            break;
          case 'Rejected':
            cssClass = 'card-rejected';
            break;
        }
        let progIndex = tempProg.findIndex(({ programID }) => programID === item.ClientProgramID);
        tempProg[progIndex].programName = item.ClientProgramName;
        tempProg[progIndex].projects.push({
          ID: item.ID,
          clientProjectID: item.ClientProjectID,
          clientProjectName: item.ClientProjectName,
          deliveryCenterID: item.DeliveryCenterID,
          projectDeliveryCenterID: item.ProjectDeliveryCenterID,
          deliveryCenterName: item.DeliveryCenterName,
          status: item.Status,
          assignedTo: item.AssignedTo,
          cssClass: cssClass,
        });
      });

      //sort project array according to ID
      tempProg.map((item, index) => {
        item.projects.sort(function (a, b) {
          return a['ID'] - b['ID'];
        });
      });
    }
    dispatch({
      type: GET_RDV_PROGRAM_LIST,
      programList: tempProg,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getRejectedValues = () => async (dispatch, state) => {
  let projectDetails = state().ConfigureProgramData.mainSelectedProject.projectDetails;
  let rejectedComments = [];
  let subMenuId = GetSubMenuId("Project Data Entry")
  try {
    let data = await HttpGet(
      `ReportValueDelivered/GetRejectedValues?clientProjectID=${projectDetails.clientProjectID}&deliveryCenterID=${projectDetails.deliveryCenterID}&SubMenuID=${subMenuId}`
    );
    if (Object.keys(data).length > 0) {
      data.map((item, index) => {
        if (item.BOIReason !== '') {
          rejectedComments.push({
            boiID: item.BOIID,
            boiName: item.BOIName,
            boiReason: item.BOIReason,
            projectDataEntryID: item.ProjectDataEntryID,
          });
        }
      });
    }
    dispatch({
      type: SET_REJECTED_COMMENTS,
      rejectedComments: rejectedComments,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getRDVClientDetails = () => async (dispatch, state) => {
  let clientID = state().SharedData.selectedClient.value;
  let rdvClientDetails = {};

  try {
    let subMenuId = GetSubMenuId("Value Delivered_Landing page")
    let data = await HttpGet(`ReportValueDelivered/GetClientDetails?clientID=${clientID}&SubMenuID=${subMenuId}`);
    if (Object.keys(data).length > 0) {
      rdvClientDetails.clientAdmin = data.ClientAdmin;
      rdvClientDetails.clientBasicDetailsID = data.ClientBasicDetailsID;
      rdvClientDetails.clientMasterName = data.ClientMasterName;
      rdvClientDetails.noOfYearsReported = data.NoofYearsReported;
      rdvClientDetails.operationsAccountLead = data.OperationsAccountLead;
      rdvClientDetails.totalNoOfPrograms = data.TotalNoofPrograms;
      rdvClientDetails.totalNoOfProjects = data.TotalNoofProjects;
      dispatch({
        type: SET_RDV_CLIENT_DETAILS,
        rdvClientDetails: rdvClientDetails,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const onSelectStatusOptions = (event, origin) => async (dispatch, state) => {
  try {
    let tStatusOptions = [];
    let tSelected = event;
    let statusOptions = state().DataIngestionData.statusOptions;

    if (event.filter((x) => x.value === 'All Status').length > 0 && event.length === 1) {
      //first load
      tStatusOptions = statusOptions.filter(function (value, index, arr) {
        return value.value !== 'All Status';
      });
    } else if (event.filter((x) => x.value === 'All Status').length > 0 && event.length >= 2) {
      //all status then clicked on other status
      //selected All Status after selecting a status
      if (
        event.findIndex((x) => x.value === 'All Status') >
        event.findIndex((x) => x.value !== 'All Status')
      ) {
        tStatusOptions = statusOptions.filter(function (value, index, arr) {
          return value.value !== 'All Status';
        });
        tSelected = [{ label: 'All Status', value: 'All Status' }];
      } else {
        //selected All Status before selecting another status
        tStatusOptions.push({
          label: 'All Status',
          value: 'All Status',
        });
        tSelected = tSelected.filter(function (value, index, arr) {
          return value.value !== 'All Status';
        });
      }
    } else {
      //empty
      tStatusOptions = [
        { label: 'Awaiting Approval', value: 'Awaiting Approval' },
        { label: 'In Progress', value: 'In Progress' },
        { label: 'Rejected', value: 'Rejected' },
        { label: 'Approved', value: 'Approved' },
        { label: 'All Status', value: 'All Status' },
      ];
    }

    dispatch({
      type: ON_SELECT_STATUS_OPTIONS,
      statusFilterKeyword: tSelected,
      statusOptions: tStatusOptions,
    });
  } catch (err) {
    console.log(err);
  }
};

export const onSelectReportingYearOptions = (event) => async (dispatch) => {
  try {
    dispatch({
      type: ON_SELECT_REPORTING_YEAR_OPTIONS,
      reportingYearKeyword: event,
    });
  } catch (err) {
    console.log(err);
  }
};

export const onSelectCreationYearOptions = (arr) => async (dispatch, state) => {
  try {
    let tCY = arr.map((y) => y.label).join(",")

    dispatch({
      type: ON_SELECT_CREATION_YEAR_OPTIONS,
      creationYearValue: arr,
      creationYear: tCY,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getLandingProgramProjectsList = () => async (dispatch, state) => {
  //new API 08022021
  try {
    let creationYear = state().DataIngestionData.creationYearValue;
    let clientID = state().SharedData.selectedClient.value;
    let programOptions = [];
    let tempProg = [];
    let selectedCreationYear = state().DataIngestionData.creationYear;

    if (creationYear.length > 0) {
      let data = await HttpGet(
        `ReportValueDelivered/getLandingProgramProjectsList?ClientID=${clientID}&creationYear=${selectedCreationYear}`
      );

      if (Object.keys(data).length > 0) {
        data.map((item, index) => {
          // programOptions.push({
          //   value: item.ProgramID,
          //   text: item.ProgramName,
          //   label: item.ProgramName,
          //   programStatus: item.Status
          // });
          let programStatusID = 0;
          switch (item.Status.toLowerCase()) {
            case 'not started':
              programStatusID = 1;
              break;
            case 'in progress':
              programStatusID = 2;
              break;
            case 'awaiting approval':
              programStatusID = 3;
              break;
            case 'rejected':
              programStatusID = 4;
              break;
            case 'awaiting approval – modified':
              programStatusID = 5;
              break;
            case 'approved':
              programStatusID = 6;
              break;
            case 'configured & aligned':
              programStatusID = 7;
              break;
            case 'configured - yet to be aligned':
              programStatusID = 8;
              break;
            case 'value identification in progress':
              programStatusID = 9;
              break;
            default:
              break;
          }
          item.ProjectDetails.map((proj) => {
            let delivCenters = '';
            tempProg.push({
              programID: item.ProgramID,
              programName: item.ProgramName,
              programStatus: item.Status,
              programStatusID: programStatusID,
              clientProjectID: proj.clientProjectId, //
              clientProjectName: proj.clientProjectName, //
              createdBy: proj.createdBy, //
              creationDate: proj.creationDate.split('T')[0],
              creationDateWithTime: proj.creationDate,
              systemGeneratedStatus: proj.SystemGeneratedProjectStatus,
              deliveryLocations: proj.deliveryLocation.map((delLoc) => {
                if (delivCenters === '') {
                  delivCenters = delLoc.deliveryCentreName;
                } else {
                  delivCenters += ', ' + delLoc.deliveryCentreName;
                }
                return {
                  projectDeliveryCenterID: delLoc.projectDeliveryCentreID,
                  deliveryCenterID: delLoc.deliveryCentreID,
                  deliveryCenterName: delLoc.deliveryCentreName,
                };
              }),
              deliveryCenters: delivCenters,
            });
          });
        });



        //sort project array according to date
        // tempProg.map((item) => {
        tempProg.sort((a, b) => {
          let dateA = new Date(a['creationDateWithTime']);
          let dateB = new Date(b['creationDateWithTime']);
          return dateB - dateA;
        });
        // });
      }
    }
    dispatch({
      type: GET_RDV_PROGRAM_LIST,
      programList: tempProg,
    });

    // dispatch({
    //   type: GET_PROGRAM_OPTIONS,
    //   programOptions: programOptions,
    // });
    return tempProg;
  } catch (err) {
    console.log(err);
  }
};

let getProjectDataEntryTableData_Disable = false;
export const getProjectDataEntryTableData =
  (program, project, upload) => async (dispatch, state) => {
    try {
      if (!getProjectDataEntryTableData_Disable) {
        getProjectDataEntryTableData_Disable = true;
        let clientProjectID = project.clientProjectID;
        let pdeTableData = [];
        let mainSelectedProject = {
          creationYear: state().DataIngestionData.creationYear,
          programID: program.programID,
          programName: program.programName,
          programStatus: program.programStatus,
          projectDetails: {
            ID: '',
            projectID: '',
            assignedTo: '',
            clientProjectID: project.clientProjectID,
            clientProjectName: project.clientProjectName,
            deliveryCenterID: '',
            deliveryCenterName: '',
            status: '',
            assignedToRoleId: '',
            createdBy: project.createdBy,
            creationDate: project.creationDate,
            deliveryLocations: project.deliveryLocations,
            reportedBy: '',
            reportedMonth: '',
            statusID: '',
            endMonth: '',
          },
        };

        let data = await HttpGet(
          'ReportValueDelivered/GetProjectDataEntryDetails?clientProjectID=' + clientProjectID
        );

        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ];

        if (Object.keys(data).length > 0) {
          data.map((pde) => {
            let status = '';
            let startReportedMonth = `${months[parseInt(new Date(pde.StartReportedMonth).getMonth() + 1) - 1]
              } ${pde.StartReportedMonth.split(' ')[1]}`;
            let lastReportedMonth = `${months[parseInt(new Date(pde.LastReportedMonth).getMonth() + 1) - 1]
              } ${pde.LastReportedMonth.split(' ')[1]}`;

            switch (pde.Status) {
              case 'Not started':
                status = 1;
                break;
              case 'In Progress':
                status = 2;
                break;
              case 'Awaiting Approval':
                status = 3;
                break;
              case 'Rejected':
                status = 4;
                break;
              case 'Awaiting Approval – Modified':
                status = 5;
                break;
              case 'Approved':
                status = 6;
                break;
              case 'Configured & Aligned':
                status = 7;
                break;
              case 'Configured - yet to be aligned':
                status = 8;
                break;
              case 'Value Identification In progress':
                status = 9;
                break;
            }

            pdeTableData.push({
              deliveryCenter: pde.DeliveryCentreName,
              actionOwner: pde.CurrentActionOwner,
              status: pde.Status,
              statusID: status,
              reportedBy: pde.LastReportedBy,
              reportedMonth: pde.LastReportedMonth,
              startReportedMonth: pde.StartReportedMonth,
              startEndReportedMonth: `${startReportedMonth} - ${lastReportedMonth}`,
              projectOverallStatusID: pde.ProjectOverallStatusID,
              programData: {
                deliveryCenterID: pde.DeliveryCentreID,
                programID: program.programID,
                programName: program.programName,
                projectID: clientProjectID,
              },
            });
          });
        }


        dispatch({
          type: GET_PDE_TABLE_DATA,
          pdeTableData: pdeTableData,
        });

        dispatch({
          type: SELECTED_PROGRAM_PROJECT,
          mainSelectedProject: mainSelectedProject,
        });
        getProjectDataEntryTableData_Disable = false;
        return {
          pdeTableData,
          mainSelectedProject,
        };
      }
    } catch (err) {
      getProjectDataEntryTableData_Disable = false;
      console.log(err);
    }
  };

export const setProgramSelected = (val) => async (dispatch, state) => {
  try {
    let tProjecList = [];
    let mainSelectedProject = state().ConfigureProgramData.mainSelectedProject;
    let selectedProgramDDValue = HasProperty(val, 'value') ? val : [];

    if (HasProperty(val, 'value')) {
      mainSelectedProject.programID = val.value;
      mainSelectedProject.programName = val.label;
      mainSelectedProject.programStatus = val.programStatus
      mainSelectedProject.creationYear = state().DataIngestionData.creationYear;
    }


    dispatch({
      type: SET_RVD_SELECTED_PROGRAM,
      selectedProgramDDValue: selectedProgramDDValue,
    });

    dispatch({
      type: SELECTED_PROGRAM_PROJECT,
      mainSelectedProject: mainSelectedProject,
    });

    // dispatch({
    //   type: GET_PROJECT_OPTIONS,
    //   projectOptions: tProjecList,
    // });
  } catch (err) {
    console.log(err);
  }
};

export const setProjectSelected = (project) => async (dispatch, state) => {
  try {
    let mainSelectedProject = state().ConfigureProgramData.mainSelectedProject;
    mainSelectedProject.creationYear = state().DataIngestionData.creationYear;
    mainSelectedProject.programID = project.programID;
    mainSelectedProject.programName = project.programName;
    mainSelectedProject.programStatus = project.programStatus;

    mainSelectedProject.projectDetails = {
      ID: project.projectOverallStatusID, //?
      assignedTo: project.actionOwner, //?
      clientProjectID: project.clientProjectID,
      clientProjectName: project.clientProjectName, //
      assignedToRoleId: '', //?
      createdBy: project.createdBy, //
      creationDate: project.creationDate, //
      deliveryLocations: project.deliveryLocations, //
      lastReportingDate: project.lastReportingDate, //?
      pdeModifiedDate: project.pdeModifiedDate, //
      actionOwner: project.actionOwner, //
      systemGeneratedStatus: project.systemGeneratedStatus,
      statusID: project.statusID,
      startReportedMonth: project.startReportedMonth,
      status: project.status,
      startEndReportedMonth: project.startEndReportedMonth,
      reportedMonth: project.reportedMonth,
      reportedBy: project.reportedBy,
      projectOverallStatusID: project.projectOverallStatusID,
      projectID: project.projectID,
      programStatusID: project.programStatusID,
      programStatus: project.programStatus,
      programID: project.programID,
      deliveryCenters: project.deliveryCenters,
      deliveryCenterID: project.deliveryCenterID,
      deliveryCenterName: project.deliveryCenterName,
      creationDateWithTime: project.creationDateWithTime,
    };

    dispatch({
      type: SELECTED_PROGRAM_PROJECT,
      mainSelectedProject: mainSelectedProject,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setDeliveryCenterSelected = (project) => async (dispatch, state) => {
  try {
    let mainSelectedProject = state().ConfigureProgramData.mainSelectedProject;
    mainSelectedProject.projectDetails.assignedTo = project.actionOwner;
    mainSelectedProject.projectDetails.ID = project.projectOverallStatusID;
    mainSelectedProject.projectDetails.reportedBy = project.reportedBy;
    mainSelectedProject.projectDetails.reportedMonth = project.reportedMonth;
    mainSelectedProject.projectDetails.status = project.status;
    mainSelectedProject.projectDetails.statusID = project.statusID;
    mainSelectedProject.projectDetails.deliveryCenterName = project.deliveryCenter;
    mainSelectedProject.projectDetails.deliveryCenterID = project.programData.deliveryCenterID;

    dispatch({
      type: SELECTED_PROGRAM_PROJECT,
      mainSelectedProject: mainSelectedProject,
    });
  } catch (err) {
    console.log(err);
  }
};


// R10

export const getProgramDropdownData = () => async (dispatch, state) => {
  //new API 08022021
  try {
    let creationYear = state().DataIngestionData.creationYearValue;
    let clientID = state().SharedData.selectedClient.value;
    let programOptions = [];
    let tempProg = [];
    let selectedCreationYear = state().DataIngestionData.creationYear;
    let subMenuId = GetSubMenuId("Value Delivered_Landing page")



    let data = await HttpGet(
      'ReportValueDelivered/GetClientProgramList?clientID=' + clientID + '&SubMenuID=' + subMenuId
    );

    if (Object.keys(data).length > 0) {
      data.map((item, index) => {
        programOptions.push({
          value: item.ProgramID,
          text: item.ProgramName,
          label: item.ProgramName,
          programStatus: item.Status
        });
      });
    }
    // programOptions = [programOptions[0]];
    if (programOptions.length === 1) {
      dispatch(setProgramSelected(programOptions[0]))
    }


    dispatch({
      type: GET_PROGRAM_OPTIONS,
      programOptions: programOptions,
    });
    return programOptions;
  } catch (err) {
    console.log(err);
  }
};

export const clearProgramProjectList = () => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_RVD_SELECTED_PROGRAM,
      selectedProgramDDValue: [],
    });

    dispatch({
      type: GET_PROGRAM_OPTIONS,
      programOptions: [],
    });

    dispatch({
      type: GET_RDV_PROGRAM_LIST,
      programList: [],
    });
  } catch (err) {
    console.log(err);
  }
};


export const setStatusID = (stat) => async (dispatch, state) => {
  try {
    let statusID = '';


    return statusID;
  } catch (err) {
    console.log(err);
  }
};

export const getLandingProjectsList = () => async (dispatch, state) => {
  //new API 08022021
  try {
    let creationYear = state().DataIngestionData.creationYearValue;
    let clientID = state().SharedData.selectedClient.value;
    let programID = state().DataIngestionData.selectedProgramDDValue.value;
    let tempProg = [];
    let selectedCreationYear = state().DataIngestionData.creationYear;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    let subMenuId = GetSubMenuId("Value Delivered_Landing page")

    if (creationYear.length > 0) {
      await dispatch(showProjectListRoller(true));
      let data = await HttpGet(
        `ReportValueDelivered/getLandingProjectsList?programID=${programID}&creationYear=${selectedCreationYear}&SubMenuID=${subMenuId}`
      );

      if (Object.keys(data).length > 0) {
        data.map(async (item, index) => {
          let programStatusID = 0;
          switch (item.Status.toLowerCase()) {
            case 'new':
              programStatusID = 0;
              break;
            case 'not started':
              programStatusID = 1;
              break;
            case 'in progress':
              programStatusID = 2;
              break;
            case 'awaiting approval':
              programStatusID = 3;
              break;
            case 'rejected':
              programStatusID = 4;
              break;
            case 'awaiting approval – modified':
              programStatusID = 5;
              break;
            case 'approved':
              programStatusID = 6;
              break;
            case 'configured & aligned':
              programStatusID = 7;
              break;
            case 'configured - yet to be aligned':
              programStatusID = 8;
              break;
            case 'value identification in progress':
              programStatusID = 9;
              break;
            default:
              break;
          }
          item.ProjectDetails.map(async (proj, index) => {
            let pde = !IsArrayEmpty(proj.projectDataEntry) ? proj.projectDataEntry[0] : [];
            let statusID = 0;
            if (IsValidStr(pde.Status)) {
              switch (pde.Status.toLowerCase()) {
                case 'new':
                  statusID = 0;
                  break;
                case 'not started':
                  statusID = 1;
                  break;
                case 'in progress':
                  statusID = 2;
                  break;
                case 'awaiting approval':
                  statusID = 3;
                  break;
                case 'rejected':
                  statusID = 4;
                  break;
                case 'awaiting approval – modified':
                  statusID = 5;
                  break;
                case 'approved':
                  statusID = 6;
                  break;
                case 'configured & aligned':
                  statusID = 7;
                  break;
                case 'configured - yet to be aligned':
                  statusID = 8;
                  break;
                case 'value identification in progress':
                  statusID = 9;
                  break;
                default:
                  break;
              }
            }

            let startReportedMonth = !IsArrayEmpty(proj.projectDataEntry) ? `${months[parseInt(new Date(pde.StartReportedMonth).getMonth() + 1) - 1]
              } ${pde.StartReportedMonth.split(' ')[1]}` : "";
            let lastReportedMonth = !IsArrayEmpty(proj.projectDataEntry) ? `${months[parseInt(new Date(pde.LastReportedMonth).getMonth() + 1) - 1]
              } ${pde.LastReportedMonth.split(' ')[1]}` : "";

            tempProg.push({
              programID: item.ProgramID, //
              programName: item.ProgramName, //
              programStatus: item.Status, //
              programStatusID: programStatusID, //
              clientProjectID: proj.clientProjectId, //
              clientProjectName: proj.clientProjectName, //
              createdBy: proj.createdBy, //
              creationDate: proj.creationDate.split('T')[0], //
              creationDateWithTime: proj.creationDate, //
              systemGeneratedStatus: proj.SystemGeneratedProjectStatus, //
              deliveryLocations: {
                projectDeliveryCenterID: IsValid(pde.projectDeliveryCentreID) ? pde.projectDeliveryCentreID : "", //
                deliveryCenterID: IsValid(pde.DeliveryCentreID) ? pde.DeliveryCentreID : "", //
                deliveryCenterName: IsValid(pde.DeliveryCentreName) ? pde.DeliveryCentreName : "", //
              },
              deliveryCenters: IsValid(pde.DeliveryCentreName) ? pde.DeliveryCentreName : "", //
              // isNewSysGenProj: index % 2 === 0,
              // isSysGenProjUpdated: index % 3 === 0,
              // projectConfigLink: "",
              deliveryCenterName: IsValid(pde.DeliveryCentreName) ? pde.DeliveryCentreName : "", //
              actionOwner: IsValid(pde.CurrentActionOwners) ? pde.CurrentActionOwners : "", //
              status: IsValid(pde.Status) ? pde.Status : "", //
              statusID: statusID,  //
              reportedBy: IsValid(pde.LastReportedBy) ? pde.LastReportedBy : "", //
              reportedMonth: IsValid(pde.LastReportedMonth) ? pde.LastReportedMonth : "", //
              startReportedMonth: IsValid(pde.StartReportedMonth) ? pde.StartReportedMonth : "", //
              startEndReportedMonth: `${startReportedMonth} - ${lastReportedMonth}`, //
              projectOverallStatusID: IsValid(pde.ProjectOverallStatusID) ? pde.ProjectOverallStatusID : 0, //
              deliveryCenterID: IsValid(pde.DeliveryCentreID) ? pde.DeliveryCentreID : 0, //
              projectID: proj.clientProjectId, //
              pdeCreationDate: IsValid(proj.creationDate) ? proj.creationDate : "", //
              pdeModifiedDate: IsValid(pde.PDEModifiedDate) ? pde.PDEModifiedDate : "", //
            });
          });
        });



        //sort project array according to date
        // tempProg.map((item) => {
        tempProg.sort((a, b) => {
          let dateA = new Date(a['creationDateWithTime']);
          let dateB = new Date(b['creationDateWithTime']);
          return dateB - dateA;
        });
        // });
      }
    }

    dispatch({
      type: GET_RDV_PROGRAM_LIST,
      programList: tempProg,
    });

    dispatch(showProjectListRoller(false));

    return tempProg;
  } catch (err) {
    console.log(err);
  }
};

export const showProjectListRoller = (show) => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_PROJECT_LIST_ROLLER,
      showProjectListRoller: show,
    });
  } catch (err) {
    console.log(err);
  }
};

///////////////////////////////////////////
export const getProjectDetails = (program, project, upload) => async (dispatch, state) => {
  try {
    let programId = program.programID;
    let projectID = project.clientProjectID;
    let deliveryCenterID = project.deliveryCenterID;
    let fields = state().ConfigureProgramData;
    let dimensionList = [];
    let buttonState = {
      isSelectProjectDisabled: false,
      isCommentDisabled: false,
      isSaveAsDraftDisabled: false,
    };
    let mainSelectedProject = state().ConfigureProgramData.mainSelectedProject;
    let isWriteAccess = false;
    let isDraft = false;
    let isExistingDataEntry = false;
    let projectDimensionsList = [];
    let isExistingPPCBA = await dispatch(isProfileExisting([12, 15]));
    let isExistingIO = await dispatch(isProfileExisting([8]));

    // let data = projectDataJSON;
    let subMenuId = GetSubMenuId("Project Data Entry");
    let data = await HttpGet(
      'ProjectDataEntry/GetProjectData?programId=' +
      programId +
      '&projectID=' +
      projectID +
      '&deliveryCenterID=' +
      deliveryCenterID +
      '&SubMenuID=' + subMenuId
    );
    let status = data.ProjectOverallStatus;
    // let businessAdvisor = [];
    // let businessAdvisorText = "";
    let tz = getUserTimeZone();
    let isNegative = tz.includes('-');
    tz = tz.replace('-', '').replace('+', '');
    let dataClientAlignment = await HttpGet(
      `ReportValueDelivered/RVDClientAcknowledgementHistory?clientProjectID=${project.clientProjectID}&projectDeliveryCenterID=${data.ProjectDeliveryCenterID}&isNegative=${isNegative}&UTCOffset=${tz}&SubMenuID=${subMenuId}`
    );

    if (HasProperty(data, 'ProjectID')) {
      let cxLeads = data.CXLeadLists;
      let oaLeads = data.OALeadLists;
      let cxList =
        cxLeads.map((cx, ind) => {
          return {
            value: cx.CXLeadUserID,
            label: cx.CXLead, //cx.email
            text: cx.CXLead
          }
        })
      let oaList =
        oaLeads.map((oa, ind) => {
          return {
            value: oa.OALeadUserID,
            label: oa.OALead,
            text: oa.OALead,
          }
        })

      let assignedTo = data.AssignedTo;
      let assignedToList = IsValid(assignedTo) ? assignedTo.split(",").map((at, ind) => {
        return {
          value: ind,
          label: at,
          text: at
        }
      }) : [];
      let isCXLNonSelectedApprover = !IsArrayEmpty(cxList.filter((a) => a.label === state().userInformation.emailAddress)) &&
        IsArrayEmpty(assignedToList.filter((a) => a.label === state().userInformation.emailAddress)) && data.AssignedToRoleId === 7;

      let isOALNonSelectedApprover = !IsArrayEmpty(oaList.filter((a) => a.label === state().userInformation.emailAddress)) &&
        IsArrayEmpty(assignedToList.filter((a) => a.label === state().userInformation.emailAddress)) && data.AssignedToRoleId === 4;

      let isComputedOnce = data.IsComputedOnce;
      mainSelectedProject = {
        creationYear: program.creationYear,
        programID: programId,
        programName: program.programName,
        programStatus: data.Status,
        projectDetails: {
          ID: project.ID,
          projectID: data.ProjectID,
          assignedTo: assignedTo,
          assignedToList: assignedToList,
          assignedToRoleId: data.AssignedToRoleId,
          clientProjectID: project.clientProjectID,
          clientProjectName: project.clientProjectName,
          deliveryCenterID: project.deliveryCenterID,
          deliveryCenterName: project.deliveryCenterName,
          status: data.ProjectOverallStatus,
          createdBy: project.createdBy,
          creationDate: project.creationDate,
          deliveryLocations: project.deliveryLocations,
          reportedBy: project.reportedBy,
          reportedMonth: project.reportedMonth,
          statusID: project.statusID,
          lastReportingDate: data.LastReportingDate,
          pdeModifiedDate: project.pdeModifiedDate, //
          //
          cxlLeadUserID: data.CXLLeadUserID, //not being used on API as per jemar
          cxLead: "", //selectedCXL not data.CXLLead, flexib
          cxLeadList: cxList,
          cxLeadString: data.CXLLead,
          oaLead: "", //selectedOAL not data.OperationsAccountLead, flexib
          oaLeadList: oaList,
          oaLeadString: data.OperationsAccountLead,
          //
          isNewBoiAddedOnPPCModule: data.IsNewBoiAddedOnPPCModule,
          isTargetValueUpdatedOnPPC: data.IsTargetValueUpdatedOnPPC,
          recentlyUploadedClientStory: data.RecentlyUploadedClientStory,
          recentlyUploadedClientAlignment: dataClientAlignment,
          projectDeliveryCenterID: data.ProjectDeliveryCenterID,
          isFirstSubmission: data.IsFirstSubmission,
          isComputedOnce: isComputedOnce,
          clientApproverRole: data.ClientApproverRole,
          isOMIDContractIDExisting: data.isOMIDContractIDExisting === 1
        },
      };

      isWriteAccess =
        isCXLNonSelectedApprover || isOALNonSelectedApprover ||
          (data.AssignedTo === data.IOApprover && data.AssignedToRoleId === 8) ||
          IsArrayEmpty(assignedToList) ||
          !IsArrayEmpty(assignedToList.filter((a) => a.label === state().userInformation.emailAddress))
          ? true
          : false;

      //populate BAs
      await dispatch(getBusinessAdvisorList());

      fields.basicDetails.reportingYear = program.hasOwnProperty('reportingYear')
        ? program.reportingYear
        : state().DataIngestionData.reportingYearKeyword.value;
      fields.basicDetails.reportingYearID = program.hasOwnProperty('reportingYearID')
        ? program.reportingYearID
        : state().DataIngestionData.reportingYearKeyword.value;
      fields.basicDetails.programId = programId;
      fields.basicDetails.programName = data.ProgramName;
      fields.basicDetails.deliveryCenter = data.DeliveryCenterName;
      fields.basicDetails.deliveryCenterID = data.DeliveryCenterID;
      fields.basicDetails.projectEntryDate = data.ProjectEntryDate;
      fields.basicDetails.targetEndDate = data.TargetEndDate;
      fields.basicDetails.offeringName = data.Offering;
      fields.basicDetails.offeringID = data.OfferingID;
      fields.basicDetails.noOfDimensions = data.DimensionCount;
      fields.basicDetails.noOfSubdimensions = data.SubDimensionCount;
      fields.basicDetails.projectName = data.ProjectName;
      fields.basicDetails.projectID = data.ProjectID;
      fields.basicDetails.projectDeliveryCenterID = data.ProjectDeliveryCenterID;
      fields.basicDetails.overallStatus = data.ProjectOverallStatus; //data.ProjectOverallStatus
      fields.basicDetails.assignedTo = assignedTo; //data.AssignedTo flexib
      fields.basicDetails.assignedToRoleID = data.AssignedToRoleId; //data.AssignedToRoleId
      fields.basicDetails.assignedToRole = data.AssignedToRole; //data.AssignedToRole

      fields.basicDetails.cxLead = data.CXLLead; //data.CXLLead flexib
      fields.basicDetails.oaLead = data.OperationsAccountLead; //data.OperationsAccountLead flexib
      fields.basicDetails.ioApprover = data.IOApprover;
      fields.basicDetails.projectCreator = data.ProjectCreator;
      fields.basicDetails.programCreator = data.ProgramCreator;
      fields.basicDetails.dataSubmitter = data.DataSubmitter;
      fields.basicDetails.subOfferingList = data.SubOfferingList;
      // fields.basicDetails.businessAdvisor = businessAdvisor; //
      // fields.basicDetails.businessAdvisorText = businessAdvisorText; //
      fields.basicDetails.projectDeliveredBy = data.ProjectDeliveredBy;
      fields.basicDetails.oldClientStoryFileExists = data.OldClientStoryFileExists;

      if (
        data.DimensionList.some((dim) => {
          return dim.SubDimensionList.some((sDim) => {
            return sDim.BOIList.some((boi) => {
              return HasValue(boi.BOIApprovedRealizedValue);
            });
          });
        })
      ) {
        isExistingDataEntry = true;
      }

      if (Object.keys(data.DimensionList).length > 0) {
        data.DimensionList.map((dimensionItem, dimensionIndex) => {
          dimensionList.push({
            dimensionName: dimensionItem.CoreDimensionName,
            dimensionID: dimensionItem.CoreDimensionID,
            subDimensionList: [],
          });

          if (Object.keys(dimensionItem.SubDimensionList).length > 0) {
            data.DimensionList[dimensionIndex].SubDimensionList.map(
              (subDimensionItem, subDimensionIndex) => {
                dimensionList[dimensionIndex].subDimensionList.push({
                  subDimensionname: subDimensionItem.SubDimensionName,
                  subDimensionID: subDimensionItem.SubDimensionID,
                  boiList: [],
                });

                if (Object.keys(subDimensionItem.BOIList).length > 0) {
                  data.DimensionList[dimensionIndex].SubDimensionList[
                    subDimensionIndex
                  ].BOIList.map((boiItem, boiIndex) => {
                    let boiDeliveredValueList = [];
                    let isFinUSD = dimensionItem.CoreDimensionID === 1 && boiItem.UOM.toLowerCase() === "usd";
                    let date = new Date();
                    let latestDate = {
                      year: [8, 9, 10, 11].includes(date.getMonth()) ? (date).getFullYear() + 1 : (date).getFullYear(), //fiscal
                      month: GetMonthMMM(date.getMonth()),
                      quarter: GetQuarter(date.getMonth()),
                    }
                    let cumulativeClientValueIdentified = isComputedOnce && IsValidStr(boiItem.ClientValueIdentified) ?
                      Number(boiItem.ClientValueIdentified) : 0; //CVI

                    let cumulativeBOITarget = isComputedOnce && IsValidStr(boiItem.BOITargetApprovedValue) ?
                      Number(boiItem.BOITargetApprovedValue) : 0; //TV unused

                    let cumulativeBOIApprovedRealizedValue = isComputedOnce &&
                      !IsArrayEmpty(boiItem.ParameterList) &&
                      IsValidStr(boiItem.ParameterList[0].ParameterValue) ?
                      Number(boiItem.ParameterList[0].ParameterValue)
                      : 0; //VIC approved once

                    let cumulativeBOITargetApprovedValue = isComputedOnce && IsValidStr(boiItem.BOITargetApprovedValue) ?
                      Number(boiItem.BOITargetApprovedValue) : 0; //TV used

                    // let cumulativeClientValueIdentified = 0; //CVI
                    // let cumulativeBOITarget = 0; //TV ununsed
                    // let cumulativeBOIApprovedRealizedValue = 0; //VIC
                    // let cumulativeBOITargetApprovedValue = 0; //TV used

                    // b.finUSDData.cumulativeBOIApprovedRealizedValue = cumulativeBOIApprovedRealizedValue +
                    //   (!IsArrayEmpty(boiItem.ParameterList) && IsValidStr(boiItem.ParameterList[0].ParameterValue) ? Number(boiItem.ParameterList[0].ParameterValue) : 0);
                    // b.finUSDData.cumulativeBOITarget = cumulativeBOITarget + b.boiTarget;
                    // b.finUSDData.cumulativeBOITargetApprovedValue = cumulativeBOITargetApprovedValue + b.boiApprovedRealizedValue;
                    // b.finUSDData.cumulativeClientValueIdentified = cumulativeClientValueIdentified +
                    //   (IsValidStr(b.clientValueIdentifiedTemp.value) ? Number(b.clientValueIdentifiedTemp.value) : 0);

                    if (isFinUSD) {
                      boiItem.BOIDeliveredValueLists.sort((a, b) => b.ReportingYear - a.ReportingYear)
                        .map((val, yInd) => {
                          let totalPerYrClientValueIdentified = 0;
                          let totalPerYrBOITarget = 0;
                          let totalPerYrBOIApprovedRealizedValue = 0;
                          let totalPerYrBOITargetApprovedValue = 0;
                          boiDeliveredValueList.push({
                            expandedYear: yInd === 0 && val.ReportingYear === latestDate.year,
                            reportingYear: val.ReportingYear,
                            reportingQuarters: val.ReportingQuarters.sort((a, b) => parseInt(b.Quarter) - parseInt(a.Quarter))
                              .map((quart, qInd) => {
                                let totalClientValueIdentified = 0;
                                let totalBOITarget = 0;
                                let totalBOIApprovedRealizedValue = 0;
                                let totalBOITargetApprovedValue = 0;
                                let tReportingMonthList = [];
                                quart.MonthlyReportings.sort((a, b) => GetMonthIndex(b.Month) - GetMonthIndex(a.Month))
                                  .map((mon) => {
                                    totalClientValueIdentified += mon.ClientValueIdentifiedHist;
                                    totalBOITarget += mon.BOITargetHist;
                                    totalBOIApprovedRealizedValue += mon.BOIRealizedValueHist;
                                    totalBOITargetApprovedValue += mon.BOITargetHist;

                                    cumulativeClientValueIdentified += mon.ClientValueIdentifiedHist;
                                    cumulativeBOITarget += mon.BOITargetHist;
                                    cumulativeBOIApprovedRealizedValue += mon.BOIRealizedValueHist;
                                    cumulativeBOITargetApprovedValue += mon.BOITargetHist;

                                    totalPerYrClientValueIdentified += mon.ClientValueIdentifiedHist;
                                    totalPerYrBOITarget += mon.BOITargetHist;
                                    totalPerYrBOIApprovedRealizedValue += mon.BOIRealizedValueHist;
                                    totalPerYrBOITargetApprovedValue += mon.BOITargetHist; //BOITargetApprovedValue -> convert to Hist

                                    tReportingMonthList.push({
                                      month: mon.Month,
                                      boiTarget: mon.BOITargetHist, //taget
                                      clientValueIdentified: mon.ClientValueIdentifiedHist, //cvi
                                      boiRealizedValue: mon.BOIRealizedValueHist, //vic
                                      boiApprovedRealizedValue: mon.BOIRealizedValueHist, //BOIApprovedRealizedValue -> BOIRealizedValueHist
                                      boiTargetApprovedValue: mon.BOITargetHist, //removed
                                      rvdStatus: mon.RVDStatus,
                                    })
                                  })
                                return {
                                  expandedQuarter: yInd === 0 && val.ReportingYear === latestDate.year,
                                  quarter: quart.Quarter,
                                  reportingMonth: tReportingMonthList,
                                  totalClientValueIdentified: totalClientValueIdentified, //cvi
                                  totalBOITarget: totalBOITarget, //target
                                  totalBOIApprovedRealizedValue: totalBOIApprovedRealizedValue, //p&l approved
                                  totalBOITargetApprovedValue: totalBOITargetApprovedValue, //p&l new //will not be used?
                                }
                              }),
                            totalPerYrClientValueIdentified: totalPerYrClientValueIdentified, //cvi
                            totalPerYrBOITarget: totalPerYrBOITarget, //target
                            totalPerYrBOIApprovedRealizedValue: totalPerYrBOIApprovedRealizedValue, //p&l approved
                            totalPerYrBOITargetApprovedValue: totalPerYrBOITargetApprovedValue, //p&l new //will not be used?,
                          })
                        });
                    }

                    dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList.push({
                      //rvd num view start
                      boiDeliveredValueList: boiDeliveredValueList,
                      finUSDData: {
                        cumulativeClientValueIdentified: cumulativeClientValueIdentified, //cvi
                        cumulativeBOITarget: cumulativeBOITarget, //target
                        cumulativeBOIApprovedRealizedValue: cumulativeBOIApprovedRealizedValue, //p&l approved
                        cumulativeBOITargetApprovedValue: cumulativeBOITargetApprovedValue, //p&l new //will not be used?,
                        latestDate: latestDate,
                        expandedQuarter: true,
                        expandedYear: true,
                        isComputedOnce: isComputedOnce //
                      },
                      expandedBOI: true, //
                      //rvd num view end
                      boiID: boiItem.BOIID, //
                      boiName: boiItem.BOIName, //
                      boiDescription: boiItem.BOIDescription, //
                      // new data origin start
                      boiRealizedValue: boiItem.BOIRealizedValue,
                      clientValueIdentifiedTemp: { //current
                        value: status === 'Approved'? '': boiItem.ClientValueIdentified,
                        isInvalid: false,
                        invalidMsg: '',
                        disabled: [null, ''].indexOf(boiItem.ClientValueIdentified) > -1
                      },
                      clientValueIdentified: boiItem.ClientValueIdentified,
                      boiTarget: boiItem.BOITarget,
                      boiTargetApproved: boiItem.BOITargetApprovedValue,
                      boiApprovedRealizedValue: boiItem.BOIApprovedRealizedValue,
                      boiValueDiff: boiItem.BOIValueDifference,
                      boiTargetApprovedValue: boiItem.BOITargetApprovedValue,
                      // new data origin end
                      boiReason: boiItem.BOIReason, //
                      boiStatus: boiItem.BOIStatus, //
                      boiClientProjectTargetID: boiItem.ClientProjectTargetID, //
                      boiProjectDataEntryID: boiItem.ProjectDataEntryID, //
                      boiRejectionComment: boiItem.RejectionComment, //
                      boiUOM: boiItem.UOM, //
                      parameterList: [], //
                      hasChange: false, //
                      IsCustom: boiItem.IsCustom, //
                      isNewValue: boiItem.IsNewValue, //
                      leadingIndicator: boiItem.LeadingIndicator, //
                      RVDCommentHistory: boiItem.RVDCommentHistory, //
                      offeringName: boiItem.OfferingName, //
                      subofferingName: boiItem.SubOfferingName, //
                      boiWheelVisivility: boiItem.WheelVisibility, // 
                      isUpdatedOnPPCModule: boiItem.IsUpdatedOnPPCModule, //
                      projectBOIWheelVisibility: boiItem.ProjectBOIWheelVisibility === 'True' ? true : false, //ok //added validation for now due to PBI 148714 dependency
                      isAgnosticBOI: boiItem.IsAgnosticBOI, //
                      projectLevelBOIStatus: boiItem.ProjectLevelBOIStatus ? boiItem.ProjectLevelBOIStatus : '', //
                    });

                    isDraft = !isDraft ? boiItem.BOIStatus === 'Rejected' : isDraft;

                    if (Object.keys(boiItem.ParameterList).length > 0) {
                      data.DimensionList[dimensionIndex].SubDimensionList[
                        subDimensionIndex
                      ].BOIList[boiIndex].ParameterList.map((paramItem, paramIndex) => {
                        if (upload) {
                          dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList[boiIndex].parameterList.push({
                            parameterMasterID: paramItem.ParameterMasterID,
                            parameterName: paramItem.ParameterName,
                            parameterUOM: paramItem.ParameterUOM,
                            parameterValue:
                            status === 'Approved' || paramItem.ParameterValue === '0' ? '' : paramItem.ParameterValue,
                            oldParamValue: paramItem.ParameterValue === '0' ? '' : paramItem.ParameterValue,
                            parameterValueID: paramItem.ProjectParameterValueID,
                            parameterCustomCode: paramItem.ParameterCustomCode,
                            parameterCode: paramItem.ParameterCode,
                            hasChange: true,
                            isInvalid: false
                          });
                        } else {
                          dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList[boiIndex].parameterList.push({
                            parameterMasterID: paramItem.ParameterMasterID,
                            parameterName: paramItem.ParameterName,
                            parameterUOM: paramItem.ParameterUOM,
                            parameterValue:
                            status === 'Approved' || paramItem.ParameterValue === '0' ? '' : paramItem.ParameterValue,
                            oldParamValue: paramItem.ParameterValue === '0' ? '' : paramItem.ParameterValue,
                            parameterValueID: paramItem.ProjectParameterValueID,
                            parameterCustomCode: paramItem.ParameterCustomCode,
                            parameterCode: paramItem.ParameterCode,
                            isInvalid: false
                          });
                        }
                      });
                    }
                  });
                }
              }
            );
          }
        });

        dispatch(getFileDetails(projectID, deliveryCenterID));
        await dispatch(InitRVD());
        let shouldInitializeRVD = true;
        let userProfiles = state().userInformation.userProfiles;
        let isSuperAdmin = state().userInformation.isSuperAdmin;
        let currentUserRoleID = state().userInformation.currentUserRoleID;

        let tempRVDParams = {};
        //FOR APPROVED/FINALIZED DATA SO THE USER CAN UPDATE DATA - CUMULATIVE - FOR PROGRAM AND PROJECT CONFIGURATOR
        if (currentUserRoleID === 12 || currentUserRoleID === 15 || (currentUserRoleID === 16 && isExistingPPCBA)) {
          //PPC || BA || QC that is BA or PPC
          let shouldEdit = status === 'Approved' || status === 'Awaiting Approval';
          shouldInitializeRVD = false;
          tempRVDParams['rvdParams'] = false; //
          tempRVDParams['computeButton'] = false; //
          tempRVDParams['approveRejectButton'] = isExistingDataEntry ? state().AccessValidationData.RVDComponents.approveRejectButton : shouldEdit; //
          tempRVDParams['sfaButton'] = false; //
          tempRVDParams['sadButton'] = false; //
          tempRVDParams['submitButton'] = isExistingDataEntry ? false : shouldEdit;
          tempRVDParams['upDownLoad'] = false;
        } else if (
          (status === 'Awaiting Approval' && (isCXLNonSelectedApprover || isOALNonSelectedApprover)) ||
          (status === 'Awaiting Approval' && (data.AssignedToRoleId === 8 && data.AssignedTo === data.IOApprover &&
            data.AssignedTo === state().userInformation.emailAddress))
        ) {
          shouldInitializeRVD = false;
          tempRVDParams['approveRejectButton'] = false;
          tempRVDParams['computeButton'] = true;
          tempRVDParams['landingPage'] = false;
          tempRVDParams['reportProjectData'] = true;
          tempRVDParams['rvdParams'] = true;
          tempRVDParams['sadButton'] = false;
          tempRVDParams['selectProject'] = true;
          tempRVDParams['sfaButton'] = false;
          tempRVDParams['subRejectButton'] = true;
          tempRVDParams['submitButton'] = true;
          tempRVDParams['upDownLoad'] = true;
        } else if ( //approver view?
          status === 'Approved' ||
          (IsArrayEmpty(assignedToList.filter((a) => a.label === state().userInformation.emailAddress))
            && IsValid(data.AssignedTo))
        ) {
          shouldInitializeRVD = false;
          tempRVDParams['rvdParams'] = true;
          tempRVDParams['computeButton'] = true;
          tempRVDParams['approveRejectButton'] = true;
          tempRVDParams['sfaButton'] = true;
          tempRVDParams['sadButton'] = true;
          tempRVDParams['submitButton'] = true;
          tempRVDParams['upDownLoad'] = true;
        }

        if (status === '' || status === 'In Progress' || status === 'Rejected') {
          shouldInitializeRVD = true;
        }

        if (isSuperAdmin) {
          if (
            status === '' ||
            status === 'In Progress' ||
            status === 'Rejected' ||
            status === 'Approved'
          ) {
            shouldInitializeRVD = false;
            let isFirstStage = !IsArrayEmpty(assignedToList.filter((a) => a.label === data.DataSubmitter));
            let isRejected = status === 'Rejected' ? true : false;
            let initialCreate = status === 'In Progress' || status === '' ? true : false;
            tempRVDParams['rvdParams'] = false;
            tempRVDParams['computeButton'] = false;
            tempRVDParams['approveRejectButton'] = initialCreate
              ? true
              : isRejected
                ? isFirstStage
                : false;
            tempRVDParams['sfaButton'] = false;
            tempRVDParams['sadButton'] = false;
            tempRVDParams['submitButton'] = true;
            tempRVDParams['upDownLoad'] = false;
          } else if (status === 'Awaiting Approval') {
            let isFinalStage = data.AssignedToRole === 'Operations Account Lead' ? true : false;
            shouldInitializeRVD = false;
            tempRVDParams['rvdParams'] = false;
            tempRVDParams['computeButton'] = false;
            tempRVDParams['approveRejectButton'] = false;
            tempRVDParams['sfaButton'] = isFinalStage;
            tempRVDParams['sadButton'] = false;
            tempRVDParams['submitButton'] = !isFinalStage;
            tempRVDParams['upDownLoad'] = false;
          } else {
            shouldInitializeRVD = false;
            tempRVDParams['rvdParams'] = true;
            tempRVDParams['computeButton'] = true;
            tempRVDParams['approveRejectButton'] = true;
            tempRVDParams['sfaButton'] = true;
            tempRVDParams['sadButton'] = true;
            tempRVDParams['submitButton'] = true;
            tempRVDParams['upDownLoad'] = true;
          }
        }

        // Validation profile id vs data stage

        let profileID = 0;
        if (!isSuperAdmin) {
          profileID = TryGetValue(
            userProfiles.filter((e) => {
              return e.ClientMasterId === state().SharedData.selectedClient.value;
            })[0].ProfileID
          );
        }

        if (['awaiting approval', 'approved'].includes(status.toLowerCase()) && !isSuperAdmin && isExistingPPCBA) {
          tempRVDParams['rvdParams'] = false;
          tempRVDParams['computeButton'] = false;
          tempRVDParams['approveRejectButton'] = true;
          tempRVDParams['sfaButton'] = false;
          tempRVDParams['sadButton'] = false;
          tempRVDParams['submitButton'] = true;
          tempRVDParams['upDownLoad'] = false;
        }
        else if (data.AssignedToRoleId !== profileID &&
          IsValid(data.AssignedToRoleId) &&
          !isSuperAdmin
        ) {

          shouldInitializeRVD = false;
          tempRVDParams['submitButton'] = true;
        }
        if (!shouldInitializeRVD) {
          await dispatch(validateAccessRVD(tempRVDParams));
        } else await dispatch(InitRVD());

        if ([0, 10].indexOf(data.AssignedToRoleId) === -1 && !isSuperAdmin) {
          tempRVDParams = {};
          tempRVDParams['upDownLoad'] = true;
          await dispatch(validateAccessRVD(tempRVDParams));
        }

        buttonState = {
          isSelectProjectDisabled: data.ProjectOverallStatus === 'Awaiting Approval' ? true : false,
          isCommentDisabled: data.ProjectOverallStatus === 'Awaiting Approval' ? !isDraft : false,
          isSaveAsDraftDisabled: true,
        };

        //sort dimList: financial first, then the rest
        // if (
        //   (isSuperAdmin && (fields.basicDetails.assignedToRoleID === 8 || fields.basicDetails.assignedToRoleID === 16)) ||
        //   ((state().userInformation.currentUserRoleID === 8 || (state().userInformation.currentUserRoleID === 16 && isExistingIO)) &&
        //     fields.basicDetails.assignedToRoleID === state().userInformation.currentUserRoleID)
        // ) {
        // if (
        //   (isSuperAdmin && (fields.basicDetails.assignedToRoleID === 8 || fields.basicDetails.assignedToRoleID === 16)) ||
        //   ((state().userInformation.currentUserRoleID === 8 || (state().userInformation.currentUserRoleID === 16 && isExistingIO)) &&
        //     fields.basicDetails.assignedToRoleID === state().userInformation.currentUserRoleID)
        // ) {
        if (
          (isSuperAdmin && (fields.basicDetails.assignedToRoleID === 8 || fields.basicDetails.assignedToRoleID === 16)) ||
          ((state().userInformation.currentUserRoleID === 8 || (state().userInformation.currentUserRoleID === 16 && isExistingIO)) &&
            fields.basicDetails.assignedToRoleID === state().userInformation.currentUserRoleID)
        ) {
          projectDimensionsList = dimensionList
            .filter((dim) => dim.dimensionName.toLowerCase() === 'financial')
            .map((e) => {
              let subDUSD = [];
              let subD = e.subDimensionList.map((e2) => {
                let tBoiL = e2.boiList.filter((e3) => e3.boiUOM.toLowerCase() === 'usd');
                if (tBoiL.length > 0) {
                  subDUSD.push(e2.subDimensionID);
                }
                let boiList = tBoiL.concat(
                  e2.boiList.filter((e3) => e3.boiUOM.toLowerCase() !== 'usd')
                );
                return { ...e2, boiList: boiList };
              });

              let finSubD = subD
                .filter((sub) => {
                  if (subDUSD.indexOf(sub.subDimensionID) !== -1) return sub;
                })
                .concat(
                  subD.filter((sub1) => {
                    if (subDUSD.indexOf(sub1.subDimensionID) === -1) return sub1;
                  })
                );

              return { ...e, subDimensionList: finSubD };
            })
            .concat(dimensionList.filter((dim) => dim.dimensionName.toLowerCase() !== 'financial'));
        } else {
          projectDimensionsList = dimensionList;
        }
      }
    }

    isDraft =
      isDraft && fields.basicDetails.assignedToRoleID === state().userInformation.currentUserRoleID
        ? true
        : false;

        let clientStory = clientStoryInitialState;
        if(data.ClientStoryData){
          clientStory = {
            ...clientStory,
            keyBusinessChallenges : updateHTMLStringForPPT(data.ClientStoryData.KeyBusinessChallenges),
            exKeyBusinessChallenges : updateHTMLStringForPPT(data.ClientStoryData.KeyBusinessChallenges),
            innovationEnablers: updateHTMLStringForPPT(data.ClientStoryData.InnovationEnablers),
            exInnovationEnablers: updateHTMLStringForPPT(data.ClientStoryData.InnovationEnablers),
            businessOutcomes: updateHTMLStringForPPT(data.ClientStoryData.BusinessOutcomes),
            exBusinessOutcomes: updateHTMLStringForPPT(data.ClientStoryData.BusinessOutcomes),
            isExistingProject: true
          }
        } else {
          clientStory = {
            ...clientStory,
            isExistingProject: true
          }
        } 
    dispatch({
      type: ON_TILE_SELECT_PROJECT,
      basicDetails: fields.basicDetails,
      projectDimensionsList: projectDimensionsList,
      buttonState: buttonState,
      isWriteAccess: isWriteAccess,
      isProjectSelected: true,
      mainSelectedProject: mainSelectedProject,
      isDraft: isDraft,
      isExistingDataEntry: isExistingDataEntry,
      clientStory
    });

    if (fields.basicDetails.overallStatus === 'Rejected') {
      dispatch(getSRDetails());
      // dispatch(getRejectedValues());
    }
    dispatch(getRejectedValues());
    if (fields.basicDetails.overallStatus === 'Awaiting Approval') {
      dispatch(getSFADetails("sfa"))
    }
    return mainSelectedProject
  } catch (err) {
    console.log(err);
  }
};