import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { GrooveIconSvg, GrooveButton } from "tfo-groove";
import { TryGetValue } from "../../../Utilities/Formatter";
import addfiles from "../../../Static/images/360cvm/AddFilesIcon.svg";
import "./DimensionAccordionBOIHistory.css";
import svgHistory from "../../../Static/images/360cvm/History.svg";
import newInfo from "../../../Static/images/svg/new-info.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const DimensionAccordionBOIHistory = (function () {  
  class DimensionAccordionBOIHistoryPresentational extends Component {

    removeSec = (dateTime) => {
      if (dateTime === null) return ""

      try {
        const dateTimeArr = dateTime.split(" ")
        const timeArr = dateTimeArr[1].split(":")
        return `${dateTimeArr[0]} ${timeArr[0]}:${timeArr[1]}`
      } catch (err) {
        return ""
      }
    }
    removeDomain = (name) => {
      if (name === null) return ""
      return name.split("@")[0]
    }

    render() {
      const dimList = this.props.dimensionList
      const headers = this.props.headers
      let mode = this.props.mode

      if (mode.includes("RVD")) {
        mode = mode.split("-")[0]
      }

      if ((dimList.length || 0) === 0) return (
        < div className="empty-dimension-container" >
          <div className="empty-program-container bodytext18-regular-slate">
            <img src={addfiles} alt="select client" />
            <span className="bodytext24-medium-slate">
              No Business Outcome Indicator {mode}
            </span>
          </div>
        </div>
      )

      return (
        <div>
          <div className="prog-acct-table-header-container">
            <div class="table-title">{this.props.topLeftContainer}</div>
            <div className="accordion-action-btns" style={{ margin: "24px 0" }}>
              <GrooveButton
                id={"expandAll"}
                hasIcon={false}
                type=""
                colorClass=""
                text="Expand All"
                customClassName={this.props.activeExpand ? "expandAll-button active" : "expandAll-button"}
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
              />
              <GrooveButton
                id={"collapseAll"}
                hasIcon={false}
                type=""
                colorClass=""
                text="Collapse All"
                callbackFunction={(e) => this.props.onExpandCollapse(e)}
                customClassName={this.props.activeCollapse ? "collapseAll-button active" : "collapseAll-button"}
                iconAlignment="right"
                iconSize="small"
                iconName="plus"
                iconStyle="solid"
                iconId="btn-icon-01"
              />
              {this.props.topRightContainer}
            </div>
          </div>

          <div className="dimension-table">
            <div className="dimension-table-header">
              <div className="dimension-table-row history-header dimension-boi-history">
                {headers.map((name, i) => <div style={(name === "Revised Target Value") ? { width: "12%" } : {}} key={i}>{name}</div>)}
              </div>
            </div>
            <div className="dimension-table-body">
              {dimList.map((dimension, i) => {

                let firstSubDChild = (dimension.SubDimensionList[0] || [])
                if (firstSubDChild.length === 0) return <></>

                let firstBoiChild = (firstSubDChild.boiDataList[0] || [])
                let skipSubD = false

                return (
                  <Accordion
                    className="dimension-table-collapse"
                    defaultActiveKey={String(i)}
                    onSelect={(e) => {
                      this.props.onSelectAccordion(e);
                    }}
                  >
                    <Card key={i}>
                      <Accordion.Collapse eventKey={String(i)}>
                        <Card.Header className="dim-card-header">
                          <div className="dimension-header-container">
                            {dimension.SubDimensionList.map((subD, i2) => {

                              return (
                                <>
                                  {subD.boiDataList.map((boi, i3) => {
                                    if (!skipSubD) {
                                      skipSubD = true
                                      return (<></>)
                                    }
                                    return (
                                      <div className="dimension-table-row dimension-boi-history" style={{ alignItems: "center" }}>
                                        <div></div>
                                        <div>{(i3 === 0) && subD.SubDimension}</div>
                                        <div className="row boi-column">
                                          <div>
                                            {boi.BOI}
                                            <div className="info-icon-wrap">
                                              <OverlayTrigger
                                                key="right"
                                                placement="auto"
                                                overlay={
                                                  <Tooltip id="boi-tooltip-right">
                                                    <div className="boi-details-wrap row">
                                                      <div class="col-md-6">
                                                        <h6>Offering</h6>
                                                        <p class="">{boi.OfferingName ? boi.OfferingName : ""}</p>
                                                        <h6>Dimension</h6>
                                                        <p class="">{dimension.Dimension ? dimension.Dimension : ""}</p>
                                                        <h6>BOI Description</h6>
                                                        <p class="">{boi.BOIDescription ? boi.BOIDescription : ""}</p>

                                                      </div>
                                                      <div class="col-md-6">
                                                        <h6>Sub-Offering</h6>
                                                        <p class="">{boi.SubOfferingName ? boi.SubOfferingName : ""}</p>
                                                        <h6>Sub-dimension</h6>
                                                        <p class="">{subD.SubDimension ? subD.SubDimension : ""}</p>
                                                        <h6>Leading Practice</h6>
                                                        <p class="">{boi.LeadingIndicator ? boi.LeadingIndicator : ""}</p>
                                                        <table>
                                                          <tr class="col-md-12">
                                                            <th class="col-md-6"><h6>Parameter</h6></th>
                                                            <th class="col-md-6"><h6>Unit of Measure</h6></th>
                                                          </tr>
                                                          {(boi.hasOwnProperty("ParameterList") ? boi.ParameterList : []).map((params) => {
                                                            return (
                                                              <tr>
                                                                <td class="col-md-6">
                                                                  <p>{params.ParameterName}</p>
                                                                </td>
                                                                <td class="col-md-6">
                                                                  <p>{params.ParameterUOM}</p>
                                                                </td>
                                                              </tr>
                                                            )
                                                          })}
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="dimension-new-info">
                                                  <img className="new-info-icon" src={newInfo} alt="info" />
                                                </div>
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                        </div>

                                        {(headers.includes("Target Value") || headers.includes("Value Impacting Client P&L")) && <div>{boi.Value !== null && boi.Value !== "" ? `${boi.Value} ${boi.UOM}` : ""}</div>}
                                        {(headers.includes("Rejected By & On")) && <div>
                                          {this.removeDomain(boi.RejectedBy)}
                                          <span className="boi-divider"></span>
                                          <div style={{ display: "inline" }}>
                                            {boi.RejectedOn}
                                          </div>
                                        </div>}
                                        {(headers.includes("Previous Target Value") || headers.includes("Previous Value Impacting Client P&L")) && <div>
                                          {boi.OldValue !== null && boi.OldValue !== "" ?
                                            `${boi.OldValue} ${!boi.OldValue.toLowerCase().includes("on") && !boi.OldValue.toLowerCase().includes("off") ? boi.UOM : ""}`
                                            : ""}
                                        </div>}
                                        {(headers.includes("Revised Target Value") || headers.includes("Revised Value Impacting Client P&L")) && <div style={{ width: "12%" }}>
                                          {boi.ModifiedValue !== null && boi.ModifiedValue !== "" ?
                                            `${boi.ModifiedValue} ${!boi.ModifiedValue.toLowerCase().includes("on") && !boi.ModifiedValue.toLowerCase().includes("off") ? boi.UOM : ""}`
                                            : ""}
                                        </div>}
                                        {(headers.includes("Modified By & On")) && <div>
                                          {this.removeDomain(boi.ModifiedBy)}
                                          <span className="boi-divider"></span>
                                          <div style={{ whiteSpace: "nowrap", display: "inline" }}>
                                            {boi.ModifiedOn}
                                          </div>
                                        </div>}
                                        {(headers.includes("Deleted By & On")) && <div>{
                                          this.removeDomain(boi.DeletedBy)}
                                          <span className="boi-divider"></span>
                                          <div style={{ display: "inline" }}>
                                            {boi.DeletedOn}
                                          </div>
                                        </div>}

                                        {(headers.includes("Approver Comment") || headers.includes("Deletion Comment")) && <div>
                                          <textarea className="comment-input" name="comment" placeholder={(mode === "Rejected" || mode === "Rejected-RVD") ? boi.ApproverComment : boi.DeletionComment} readOnly />
                                        </div>}
                                      </div>
                                    )
                                  })}
                                </>
                              )
                            })}
                          </div>
                        </Card.Header>
                      </Accordion.Collapse>
                      <Card.Header className="dim-card-header">
                        <div className="dimension-header-container" onClick={(e) => this.props.onClickAccordion(e, "div")}>
                          <div className="dimension-table-row dimension-boi-history" style={{ alignItems: "center" }}>
                            <div className="dim-name">{dimension.Dimension}</div>
                            <div>{TryGetValue(firstSubDChild.SubDimension)}</div>
                            <div className="row boi-column">
                              <div>
                                {TryGetValue(firstBoiChild.BOI)}
                                {(firstBoiChild.BOI.length || false) &&
                                  <div className="info-icon-wrap">
                                    <OverlayTrigger
                                      key="right"
                                      placement="auto"
                                      overlay={
                                        <Tooltip id="boi-tooltip-right">
                                          <div className="boi-details-wrap row">
                                            <div class="col-md-6">
                                              <h6>Offering</h6>
                                              <p class="">{firstBoiChild.OfferingName ? firstBoiChild.OfferingName : ""}</p>
                                              <h6>Dimension</h6>
                                              <p class="">{dimension.Dimension ? dimension.Dimension : ""}</p>
                                              <h6>BOI Description</h6>
                                              <p class="">{firstBoiChild.BOIDescription ? firstBoiChild.BOIDescription : ""}</p>

                                            </div>
                                            <div class="col-md-6">
                                              <h6>Sub-Offering</h6>
                                              <p class="">{firstBoiChild.SubOfferingName ? firstBoiChild.SubOfferingName : ""}</p>
                                              <h6>Sub-dimension</h6>
                                              <p class="">{firstSubDChild.SubDimension ? firstSubDChild.SubDimension : ""}</p>
                                              <h6>Leading Practice</h6>
                                              <p class="">{firstBoiChild.LeadingIndicator ? firstBoiChild.LeadingIndicator : ""}</p>
                                              <table>
                                                <tr class="col-md-12">
                                                  <th class="col-md-6"><h6>Parameter</h6></th>
                                                  <th class="col-md-6"><h6>Unit of Measure</h6></th>
                                                </tr>
                                                {(firstBoiChild.hasOwnProperty("ParameterList") ? firstBoiChild.ParameterList : []).map((params) => {
                                                  return (
                                                    <tr>
                                                      <td class="col-md-6">
                                                        <p>{params.ParameterName}</p>
                                                      </td>
                                                      <td class="col-md-6">
                                                        <p>{params.ParameterUOM}</p>
                                                      </td>
                                                    </tr>
                                                  )
                                                })}
                                              </table>
                                            </div>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="dimension-new-info">
                                        <img className="new-info-icon" src={newInfo} alt="info" />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                }
                              </div>
                            </div>
                            {(headers.includes("Target Value") || headers.includes("Value Impacting Client P&L")) && <div>{firstBoiChild.Value !== null && firstBoiChild.Value !== "" ? `${firstBoiChild.Value} ${firstBoiChild.UOM}` : ""}</div>}
                            {(headers.includes("Rejected By & On")) && <div>{
                              this.removeDomain(firstBoiChild.RejectedBy)}
                              <span className="boi-divider"></span>
                              <div style={{ display: "inline" }}>
                                {firstBoiChild.RejectedOn}
                              </div>
                            </div>}
                            {(headers.includes("Previous Target Value") || headers.includes("Previous Value Impacting Client P&L")) &&
                              <div>
                                {firstBoiChild.OldValue !== null && firstBoiChild.OldValue !== "" ?
                                  `${firstBoiChild.OldValue} ${!firstBoiChild.OldValue.toLowerCase().includes("on") && !firstBoiChild.OldValue.toLowerCase().includes("off") ? firstBoiChild.UOM : ""}` : ""}
                              </div>
                            }
                            {(headers.includes("Revised Target Value") || headers.includes("Revised Value Impacting Client P&L")) &&
                              <div style={{ width: "12%" }}>
                                {firstBoiChild.ModifiedValue !== null && firstBoiChild.ModifiedValue !== "" ? `
                              ${firstBoiChild.ModifiedValue} ${!firstBoiChild.ModifiedValue.toLowerCase().includes("on") && !firstBoiChild.ModifiedValue.toLowerCase().includes("off") ? firstBoiChild.UOM : ""}` : ""}
                              </div>
                            }
                            {(headers.includes("Modified By & On")) && <div>{
                              this.removeDomain(firstBoiChild.ModifiedBy)}
                              <span className="boi-divider"></span>
                              <div style={{ whiteSpace: "nowrap", display: "inline" }}>
                                {firstBoiChild.ModifiedOn}
                              </div>
                            </div>}
                            {(headers.includes("Deleted By & On")) && <div>{
                              this.removeDomain(firstBoiChild.DeletedBy)}
                              <span className="boi-divider"></span>
                              <div style={{ display: "inline" }}>
                                {firstBoiChild.DeletedOn}
                              </div>
                            </div>}

                            {(headers.includes("Approver Comment") || headers.includes("Deletion Comment")) && <div>
                              <textarea className="comment-input" name="comment" placeholder={(mode === "Rejected" || mode === "Rejected-RVD") ? firstBoiChild.ApproverComment : firstBoiChild.DeletionComment} readOnly />
                            </div>}
                          </div>
                        </div>
                        <Accordion.Toggle id="dim-btn" className="dim-acc-btn" as={Button} variant="link" eventKey={String(i)} onClick={(e) => this.props.onClickAccordion(e, "btn")}>
                          <GrooveIconSvg
                            id="basicDetailsIcon"
                            customClassName="accordion-button"
                            size="large"
                            name="chevron-right"
                            iconStyle="solid"
                            iconColor="stat-neutral"
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                  </Accordion>
                )
              })}
            </div>
          </div>
        </div>
      )
    }
  }

  class DimensionAccordionBOIHistoryContainer extends Component {
    constructor() {
      super();
      this.state = {
        activeExpand: false,
        activeCollapse: false,
        accordionCount: 0,
        onLoadExpand: false,
        isCollapsed: false,
      };
    }

    componentDidUpdate = () => {
      const dimList = this.props.dimensionList || [];
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.onExpandCollapse(null, "onload");
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true,
        });
      }
    };

    componentDidMount = () => {
      const dimList = (this.props.dimensionList || [])
      if (dimList.length > 0 && !this.state.onLoadExpand) {
        this.onExpandCollapse(null, "onload");
        this.setState({
          activeExpand: true,
          activeCollapse: false,
          accordionCount: dimList.length,
          onLoadExpand: true
        })
      }
    }

    onExpandCollapse = (e, action) => {
      try {
        let elems = [];
        let isFirstStage = window.location.pathname.toLowerCase().includes("idvaluesettings") ? true : false;
        let caseString = action !== undefined && action === "onload" ? "expandAll" :
          (e.currentTarget.id).includes("expandAll") ? "expandAll" : "collapseAll";
        switch (caseString) {
          case "expandAll":
            elems = document.getElementsByClassName("collapse");
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === "collapse") {
                  document
                    .getElementsByClassName("collapse")
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === "collapse") {
                  document
                    .getElementsByClassName("collapse")
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: true,
              activeCollapse: false,
            });
            break;
          case "collapseAll":
            elems = document.getElementsByClassName("collapse show");
            if (isFirstStage) {
              for (var i = 2; i < elems.length; i++) {
                if (elems[i].className === "collapse show") {
                  document
                    .getElementsByClassName("collapse show")
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            } else {
              for (var i = 0; i < elems.length; i++) {
                if (elems[i].className === "collapse show") {
                  document
                    .getElementsByClassName("collapse show")
                  [i].parentElement.childNodes[1].children[1].click();
                }
              }
            }

            this.setState({
              activeExpand: false,
              activeCollapse: true,
            });
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
    };

    onSelectAccordion = (e) => {
      try {
        let accordionCount = this.state.accordionCount;
        let activeCollapse = false; //accordion close
        let activeExpand = false; //accordion open

        let dimensionList =
          this.props.dimensionList !== undefined ? this.props.dimensionList : this.state.dimensionList; //change
        if (e === null) {
          accordionCount -= 1;
        } else {
          accordionCount += 1;
        }

        if (dimensionList.length === accordionCount) {
          activeExpand = true;
          activeCollapse = false;
        } else if (accordionCount === 0) {
          activeCollapse = true;
          activeExpand = false;
        }

        this.setState({
          accordionCount: accordionCount,
          activeExpand: activeExpand,
          activeCollapse: activeCollapse,
        });
      } catch (e) {
        console.log(e);
      }
    };

    onClickAccordion = (e, action) => {
      let isCurrentlyCollapsed = e.currentTarget.parentElement.previousSibling.className === "collapse" ? true : false;
      if (action === "div" && isCurrentlyCollapsed) {
        e.currentTarget.nextSibling.click();
        e.currentTarget.parentElement.parentElement.style.removeProperty("cursor");
      } else {
        //btn
        if (!isCurrentlyCollapsed) {
          e.currentTarget.parentElement.parentElement.style.cursor = "pointer";
        } else {
          e.currentTarget.parentElement.parentElement.style.removeProperty("cursor");
        }
      }
    };

    setHeaders = () => {
      let headers = ['Dimension', 'Subdimension', 'Business Outcome Indicators']
      if (this.props.mode === 'Rejected') return [...headers, 'Target Value', 'Rejected By & On', 'Approver Comment']
      if (this.props.mode === 'Modified') return [...headers, 'Previous Target Value', 'Revised Target Value', 'Modified By & On']
      if (this.props.mode === 'Modified-RVD') return [...headers, 'Previous Value Impacting Client P&L', 'Revised Value Impacting Client P&L', 'Modified By & On']
      if (this.props.mode === 'Rejected-RVD') return [...headers, 'Value Impacting Client P&L', 'Rejected By & On', 'Approver Comment']
      return [...headers, 'Target Value', 'Deleted By & On', 'Deletion Comment']
    }

    mapFilter = () => {

      let dimensionList = this.props.dimensionList
      let filter = this.props.filter.toLowerCase()
      let filtersList = []
      let mode = this.props.mode;

      dimensionList.map((dimension) => {
        if (dimension.Dimension.toLowerCase().indexOf(filter) !== -1) {
          filtersList.push(dimension)
        } else {
          let filterSubDim = []
          dimension.SubDimensionList.map((subDimension) => {
            if (subDimension.SubDimension.toLowerCase().indexOf(filter) !== -1) {
              filterSubDim.push(subDimension)
            } else {
              let filterBOI = []
              subDimension.boiDataList.map(boi => {
                
                try {
                  let val = mode === "Modified" || mode === "Modified-RVD" ? `${boi.ModifiedValue} ${boi.UOM.toLowerCase()}` : `${boi.Value} ${boi.UOM.toLowerCase()}`;
                  if (val === null) val = ''

                  if ((boi.BOI.toLowerCase().indexOf(filter) !== -1) || (val.indexOf(filter) !== -1) || (boi.RejectedBy.toLowerCase().indexOf(filter) !== -1) || (boi.RejectedOn.toLowerCase().indexOf(filter) !== -1)) {
                    filterBOI.push(boi)
                  }
                }
                catch (err) {
                  let val = `${boi.OldValue} ${boi.UOM.toLowerCase()}`;
                  if (val === null) val = ''

                  if ((boi.BOI.toLowerCase().indexOf(filter) !== -1) || (val.indexOf(filter) !== -1) || (boi.ModifiedBy.toLowerCase().indexOf(filter) !== -1) || (boi.ModifiedOn.toLowerCase().indexOf(filter) !== -1)) {
                    filterBOI.push(boi)
                  }
                }
              })
              if (filterBOI.length !== 0) {
                filterSubDim.push({
                  SubDimension: subDimension.SubDimension,
                  boiDataList: filterBOI
                })
              }
            }
          })
          if (filterSubDim.length !== 0) {
            filtersList.push({
              Dimension: dimension.Dimension,
              SubDimensionList: filterSubDim
            })
          }
        }
      })

      return filtersList
    }

    render() {
      return (
        <DimensionAccordionBOIHistoryPresentational
          dimensionList={this.mapFilter()}
          onSelectAccordion={this.onSelectAccordion}
          mode={this.props.mode}
          headers={this.setHeaders()}
          onExpandCollapse={this.onExpandCollapse}
          topLeftContainer={this.props.topLeftContainer}
          topRightContainer={this.props.topRightContainer}
          activeExpand={this.state.activeExpand}
          activeCollapse={this.state.activeCollapse}
          onClickAccordion={this.onClickAccordion}
          isCollapsed={this.state.isCollapsed}
        />
      );
    }
  }

  return DimensionAccordionBOIHistoryContainer;
})();

export default DimensionAccordionBOIHistory;
