import React, { Component } from "react";
import { connect } from "react-redux";
// import { history } from "../../../store";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { GrooveTabComponent } from 'tfo-groove';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GrooveIconSvg } from "tfo-groove";

//components
import DimensionAccordionBOIHistory from '../../Shared/CVMDimensionAccordionBOIHistory/DimensionAccordionBOIHistory';

//actions
import {
  getRVDBOIHistory
} from '../../../Actions/ReportValueDeliveredBOIHistory.Action';

//icons
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const ReportValueDeliveredBOIHistory = (function () {

  class ReportValueDeliveredBOIHistoryPresentational extends Component {

    render() {
      const tab_data = [
        {
          tab_label: "Rejected BOIs",
          tab_value: 1,
          tab_id: "Rejected",
          tab_content: (
            <React.Fragment>
              <div className="table-view-wraps">
                <DimensionAccordionBOIHistory
                  dimensionList={this.props.rejectedDimensionList}
                  mode="Rejected-RVD"
                  filter={this.props.filter} />
              </div>
            </React.Fragment>
          ),
        },
        {
          tab_label: "Modified BOIs",
          tab_value: 2,
          tab_id: "Modified",
          tab_content: (
            <React.Fragment>
              <div className="table-view-wraps">
                <DimensionAccordionBOIHistory
                  dimensionList={this.props.modifiedDimensionList}
                  mode="Modified-RVD"
                  filter={this.props.filter} />
              </div>
            </React.Fragment>
          ),
        },

      ];

      return (
        <React.Fragment>
          <div className="Rejected-BOIS">
            <div className="first-line">
              <div className="page-title">
                <FontAwesomeIcon icon={faChevronLeft} className="back-icon" onClick={this.props.onClickBackBtn} style={{ marginRight: "10px", cursor: "pointer" }} />
                Business Outcome Indicators History
              </div>
              <div className="stepper-close">
                <FontAwesomeIcon icon={faTimes} onClick={() => this.props.onCloseRejectedBOI()} />
              </div>
            </div>
            <div className="rejected-BOIS-content">
              <div className="search-bar-container">
                <div className="search-bar label14-regular-midnight">
                  <GrooveIconSvg
                    customClassName="close-modal-btn"
                    size="large"
                    name="search"
                    iconStyle="solid"
                    iconColor="stat-neutral"
                  />
                  <input type="text" name="name" placeholder="Search..." maxLength="255" onChange={(e) => this.props.onSearch(e)} value={this.props.filter} />
                </div>
              </div>
              <GrooveTabComponent theme="purple" tab_data={tab_data} callbackFunction={this.props.tabCallback} />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  class ReportValueDeliveredBOIHistoryContainer extends Component {
    constructor() {
      super();
      this.state = {
        selectedProgramName: '',
        selectedProjectName: '',
        filter: ''
      };
    }


    componentDidMount = async () => {
      try {
        let selectProject = this.props.ConfigureProgramData.mainSelectedProject;

        let projectID = selectProject.projectDetails.clientProjectID;
        let deliveryCenterID = selectProject.projectDetails.deliveryCenterID;
        this.props.getRVDBOIHistory(projectID, deliveryCenterID);

        let selectedProjectNameFunc = () => {
          if (this.props.historyType === "Program") return '';

          return selectProject.projectDetails.clientProjectName;
        }

        this.setState({
          selectedProgramName: selectProject.programName,
          selectedProjectName: selectedProjectNameFunc()
        })
      }
      catch (e) {
        console.log(e);
      }
    }

    onSearch = (e) => {
      const filter = e.target.value;
      this.setState({
        filter: filter
      })
    }

    tabCallback = (tab) => {
      this.setState({
        filter: ''
      })
    }

    onClickBackBtn = () => {
      this.props.history.goBack();
    }

    render() {
      const boiHistoryList = this.props.DataIngestionData.rvdBOIHistory;

      return <ReportValueDeliveredBOIHistoryPresentational
        historyType={this.props.historyType}
        onClickBackBtn={this.onClickBackBtn}
        onCloseRejectedBOI={this.onClickBackBtn}
        programName={this.state.selectedProgramName}
        tabCallback={this.tabCallback}
        onSearch={this.onSearch}
        filter={this.state.filter}

        // reducers
        rejectedDimensionList={boiHistoryList.rejectedBOIHistories}
        modifiedDimensionList={boiHistoryList.modifiedBOIHistories}

      />;
    }
  }

  return ReportValueDeliveredBOIHistoryContainer;
})();

export default connect(
  state => {
    return {
      DataIngestionData: state.DataIngestionData,
      ConfigureProgramData: state.ConfigureProgramData
    }
  },
  dispatch => bindActionCreators(Object.assign({},
    {
      getRVDBOIHistory
    }), dispatch)
)(withRouter(ReportValueDeliveredBOIHistory));

