import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { GrooveButton, } from "tfo-groove";

const CVMWarningModal = (function () {
  class CVMWarningModalPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="stepperClose-Alert">
            <div className="stepperClose-Alert-Content">
              <div className="stepperClose-Alert-Icon">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <div className="stepperClose-Alert-text">
                {this.props.body}
              </div>
            </div>
          </div>
          <div className="notifyModal-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="OK"
              callbackFunction={() => this.props.handleOK()}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class CVMWarningModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount = () => { };

    render() {
      return (
        <CVMWarningModalPresentational
          handleOK={this.props.handleOK}
          body={this.props.body}
        />
      );
    }
  }

  return CVMWarningModalContainer;
})();

export default CVMWarningModal;
