import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Nav, NavItem, Button } from "react-bootstrap";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { GrooveIconSvg, GrooveButton } from 'tfo-groove';

require("./CVMDateRange.css");
const CVMDateRange = (function () {
  class CVMDateRangePresentational extends Component {
    render() {
      const placeholder = this.props.required ? this.props.placeholder + "*" : this.props.placeholder;
      const value = this.props.value;
      const year = this.props.selectedField === "" || this.props.selectedField === "start" ? this.props.range.start.year : this.props.range.end.year;
      const month = this.props.selectedField === "" || this.props.selectedField === "start" ? this.props.range.start.month : this.props.range.end.month;

      const startDate = this.props.range.start.month + " " + this.props.range.start.year;
      const endDate = this.props.range.end.month + " " + this.props.range.end.year;

      const currentYear = new Date().getFullYear();
      const startDateDisabled = (this.props.selectedField === "" || this.props.selectedField === "start") && this.props.isStartDateDisabled;
      const endDateDisabled = this.props.selectedField === "end" && this.props.isEndDateDisabled;
      const customCSS = this.props.customCSS;

      return (
        <div className="cvm-date-range">
          {
            this.props.showPop ?
              <React.Fragment>
                <div className="cvm-date-range-overlay" onClick={() => this.props.onShowPop()} />
                <div className="cvm-date-range-pop">
                  <Row>
                    <Col sm={6} md={6} lg={6}>
                      <div className={this.props.selectedField === "" || this.props.selectedField === "start" ?
                        (this.props.isStartDateDisabled ? "date-range-selected disabled-field" : "date-range-selected selected-date-field") :
                        (this.props.isStartDateDisabled ? "date-range-selected disabled-field" : "date-range-selected")}>
                        {
                          startDate !== " " ?
                            <div className="date-range-label">Project Creation Month</div> : ""
                        }
                        <div className={startDate === " " ? "date-range-value blank-value" : "date-range-value"}>
                          {startDate === " " ? "Project Creation Month" : startDate}
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} md={6} lg={6}>
                      <div className={this.props.selectedField === "end" ? (this.props.isEndDateDisabled ? "date-range-selected selected-date-field disabled-field" : "date-range-selected selected-date-field")
                        : (this.props.isEndDateDisabled ? "date-range-selected disabled-field" : "date-range-selected")}
                        onClick={() => this.props.onChangeField("end")}>
                        {
                          endDate !== " " ?
                            <div className="date-range-label">Report Value Delivered Month</div> : ""
                        }
                        <div className={endDate === " " ? "date-range-value blank-value" : "date-range-value"}>
                          {endDate === " " ? "Report Value Delivered Month" : endDate}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="date-range-picker-container">
                      <div className="date-range-picker-header">
                        <div className="adjust-year-button">
                          <GrooveIconSvg
                            id="chev-icon"
                            size="large"
                            name="chevron-left"
                            iconStyle="solid"
                            iconColor="stat-alternate"
                            callbackFunction={() => this.props.onChangeYear(false)} //false means -
                          />
                        </div>
                        <div>{year === "" ? "" : "FY" + year}</div>
                        <div className="adjust-year-button">
                          <GrooveIconSvg
                            id="chev-icon"
                            size="large"
                            name="chevron-right"
                            iconStyle="solid"
                            iconColor="stat-alternate"
                            callbackFunction={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeYear(true)} //true means +
                          />
                        </div>
                      </div>
                      <div className="date-range-picker-body">
                        <div className="month-row">
                          <div className={this.props.checkMonth("Jan") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Jan" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Jan')}>Jan</div>
                          <div className={this.props.checkMonth("Feb") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Feb" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Feb')}>Feb</div>
                          <div className={this.props.checkMonth("Mar") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Mar" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Mar')}>Mar</div>
                        </div>
                        <div className="month-row">
                          <div className={this.props.checkMonth("Apr") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Apr" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Apr')}>Apr</div>
                          <div className={this.props.checkMonth("May") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "May" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('May')}>May</div>
                          <div className={this.props.checkMonth("Jun") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Jun" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Jun')}>Jun</div>
                        </div>
                        <div className="month-row">
                          <div className={this.props.checkMonth("Jul") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Jul" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Jul')}>Jul</div>
                          <div className={this.props.checkMonth("Aug") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Aug" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Aug')}>Aug</div>
                          <div className={this.props.checkMonth("Sep") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Sep" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Sep')}>Sep</div>
                        </div>
                        <div className="month-row">
                          <div className={this.props.checkMonth("Oct") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Oct" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Oct')}>Oct</div>
                          <div className={this.props.checkMonth("Nov") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Nov" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Nov')}>Nov</div>
                          <div className={this.props.checkMonth("Dec") || (startDateDisabled || endDateDisabled) ? "month-tile disabled-month" : month === "Dec" ? "month-tile selected-month" : "month-tile"} onClick={() => (startDateDisabled || endDateDisabled) ? "" : this.props.onChangeMonth('Dec')}>Dec</div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="date-range-buttons">
                      <GrooveButton
                        id="primry-btn-2"
                        hasIcon={false}
                        type="outline"
                        colorClass="stat-alternate"
                        text="Cancel"
                        callbackFunction={() => this.props.onShowPop()}
                      />
                      <GrooveButton
                        id="primry-btn-2"
                        hasIcon={false}
                        type="solid"
                        colorClass="stat-alternate"
                        text="Update"
                        callbackFunction={() => this.props.onUpdate()}
                        isDisabled={(this.props.isEndDateDisabled && this.props.isStartDateDisabled) ||
                          (!this.props.isEndDateDisabled && endDate === " ") ||
                          (!this.props.isStartDateDisabled && startDate === " ") ? true : false}
                      />
                    </div>
                  </Row>
                </div>
              </React.Fragment>
              : ""
          }
          <div className={value === "" ? "cvm-date-range-input blank-value " + customCSS : "cvm-date-range-input"} onClick={() => this.props.onShowPop()}>
            {
              value === "" ? ""
                :
                <div className="custom-field-label">{this.props.label}</div>
            }
            {
              value === "" ? placeholder : value
            }
            <GrooveIconSvg
              id="cal-icon"
              size="large"
              name="calendar-alt"
              iconStyle="regular"
              iconColor="gr-silver"
            />
          </div>
        </div>
      );
    }
  }

  class CVMDateRangeContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        range: {
          start: {
            month: "",
            year: ""
          },
          end: {
            month: "",
            year: ""
          }
        },
        selectedField: "",
        showPop: false,
        value: "",
        monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      };
    }

    componentDidMount = () => {
      this.initComponent();
    }

    initComponent = () => {
      const months = this.state.monthNames;
      let range = this.state.range;
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      range = {
        start: {
          month: JSON.stringify(this.props.start) !== '{}' ? months[this.props.start.getMonth()] : months[currentMonth],
          year: JSON.stringify(this.props.start) !== '{}' ? this.props.start.getFullYear() : currentYear
        },
        end: {
          month: JSON.stringify(this.props.end) !== '{}' ? months[this.props.end.getMonth()] : "",
          year: JSON.stringify(this.props.end) !== '{}' ? this.props.end.getFullYear() : ""
        }
      }

      let start = range.start.month + " " + range.start.year;
      let end = range.end.month + " " + range.end.year;

      let date = start === " " || end === " " ? "" : start + " - " + end;

      this.setState({
        range: range,
        value: date,
        selectedField: "start"
      })
    }

    onChangeField = (field) => {
      const months = this.state.monthNames;
      let range = this.state.range;
      let currentMonth = new Date().getMonth();

      if (field === 'end') {
        range.end.month = range.end.month === "" ?
          (months.indexOf(range.start.month) + 1 === 12 ? months[0] :
            (this.props.isStartDateDisabled ? months[currentMonth] : months[months.indexOf(range.start.month) + 1]))
          : range.end.month;
        range.end.year = range.end.year === "" ?
          (months.indexOf(range.start.month) + 1) === 12 ? range.start.year + 1 : range.start.year
          : range.end.year;
      }

      this.setState({
        range: range,
        selectedField: field
      });
    }

    onChangeYear = (shouldAdd) => {
      let range = this.state.range;
      let field = this.state.selectedField;
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      const months = this.state.monthNames;

      if (field === 'start') {
        if (shouldAdd) {
          if (range.end.year !== "") {
            range.start.year = range.start.year + 1 > range.end.year ? range.start.year : range.start.year + 1;
          }
          else {
            range.start.year = range.start.year + 1;
          }
        }
        else {
          range.start.year = range.start.year - 1 < currentYear ? range.start.year : range.start.year - 1;
        }
      }
      else {
        if (shouldAdd) {
          range.end.year = range.end.year + 1;
        }
        else {
          if (range.end.year > range.start.year && range.start.year === range.end.year - 1 &&
            ((months.indexOf(range.end.month) + 1 <= months.indexOf(range.start.month) + 1) ||
              (this.props.isStartDateDisabled && months.indexOf(range.end.month) + 1 <= currentMonth + 1))
          ) {
            range.end.month = (months.indexOf(range.start.month) + 1) === 12 ? months[0] : months[currentMonth];
          }
          range.end.year = range.end.year - 1 < range.start.year ||
            this.props.isStartDateDisabled && (months.indexOf(range.start.month) + 1) === 12 && range.end.year - 1 === range.start.year ?
            range.end.year : range.end.year - 1;
        }
      }

      this.setState({
        range: range,
      })
    }

    onChangeMonth = (month) => {
      const disabledMonth = this.checkMonth(month);
      if (!disabledMonth) {
        let range = this.state.range;
        let field = this.state.selectedField;

        if (field === '' || field === 'start') {
          range.start.month = month;
          field = "start";
        }
        else {
          range.end.month = month;
        }
        this.setState({
          range: range,
          selectedField: field
        })
      }
    }

    onShowPop = () => {
      let show = this.state.showPop;
      let range = this.state.range;
      let selectedField = this.state.selectedField
      if (show) {
        range = {
          start: {
            month: "",
            year: ""
          },
          end: {
            month: "",
            year: ""
          }
        };
        selectedField = "";
      }
      this.setState({
        showPop: !show,
        range: range,
        selectedField: selectedField
      }, () => {
        if (!show) {
          this.initComponent();
        }
      });
    }

    onUpdate = () => {
      const months = this.state.monthNames;
      const range = this.state.range;
      let start = range.start.month + " " + range.start.year;
      let end = range.end.month + " " + range.end.year;

      this.setState({
        value: start + " - " + end,
        showPop: false
      });
      let dateRange = {
        start: new Date(range.start.year, months.indexOf(range.start.month)),
        end: new Date(range.end.year, months.indexOf(range.end.month)),
      }
      this.props.callbackFunction(dateRange);
    }

    //check if month is less than current month
    checkMonth = (mon) => {
      const selectedField = this.state.selectedField;
      const range = this.state.range;
      const currentYear = new Date().getFullYear();

      const months = this.state.monthNames;
      const currentMonth = new Date().getMonth();

      let shouldDisable = true;

      if (selectedField === 'start') {
        if (range.start.year === range.end.year) {
          if (range.start.year === currentYear) {
            shouldDisable = months.indexOf(mon) < currentMonth || months.indexOf(mon) > months.indexOf(range.end.month) - 1;
          }
          else {
            shouldDisable = months.indexOf(mon) > months.indexOf(range.end.month) - 1;
          }
        }
        else {
          if (range.start.year === currentYear) {
            shouldDisable = months.indexOf(mon) < currentMonth;
          }
          else {
            shouldDisable = false;
          }
        }
      }
      else {
        if (range.start.year === range.end.year) {
          if (range.end.year === currentYear) {
            shouldDisable = months.indexOf(mon) < currentMonth || months.indexOf(mon) < months.indexOf(range.start.month) + 1;
          }
          else {
            shouldDisable = months.indexOf(mon) < months.indexOf(range.start.month) + 1;
          }
        }
        else {
          shouldDisable = false;
        }
      }

      return shouldDisable;
    }

    render() {
      return (
        <CVMDateRangePresentational
          value={this.state.value}
          label={this.props.label}
          placeholder={this.props.placeholder}
          required={this.props.required}
          showPop={this.state.showPop}
          range={this.state.range}
          selectedField={this.state.selectedField}
          onShowPop={this.onShowPop}
          onChangeField={this.onChangeField}
          onChangeYear={this.onChangeYear}
          onChangeMonth={this.onChangeMonth}
          onUpdate={this.onUpdate}
          checkMonth={this.checkMonth}
          isStartDateDisabled={this.props.isStartDateDisabled}
          isEndDateDisabled={this.props.isEndDateDisabled}
          customCSS={this.props.customCSS}
        />
      );
    }
  }

  return CVMDateRangeContainer;
})();

export default CVMDateRange;