import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { GrooveIconSvg } from "tfo-groove";
import { IsValid } from "../../../Utilities/Validations"
require("./CVMModal.css");

const CVMModal = (function () {
  class CVMModalPresentational extends Component {
    render() {
      return (
        <Modal
          show={this.props.show}
          onHide={() => { }}
          size={this.props.size}
          centered
          dialogClassName={this.props.customclass}
          enforceFocus={this.props.enforceFocus}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="header24-semibold-midnight">
                {this.props.title}
              </div>
              {
                this.props.special ? "" :
                  <GrooveIconSvg
                    customClassName="close-modal-btn"
                    size="large"
                    name="times"
                    iconStyle="solid"
                    iconColor="primary-acn"
                    callbackFunction={this.props.onCloseModal}
                  />
              }

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.content}</Modal.Body>
        </Modal>
      );
    }
  }

  class CVMModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount = () => { };

    render() {
      return (
        <CVMModalPresentational
          show={this.props.show}
          title={this.props.title}
          size={this.props.size}
          content={this.props.content}
          onCloseModal={this.props.onCloseModal}
          customclass={this.props.customclass}
          special={this.props.special}
          enforceFocus={IsValid(this.props.enforceFocus) ? this.props.enforceFocus : true}
        />
      );
    }
  }

  return CVMModalContainer;
})();

export default CVMModal;
