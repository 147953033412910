import {
	SET_COMPONENT_ACCESS_RVD,
	SET_COMPONENT_ACCESS_PnP
} from '../Constants';

import { UserAccess, HasNoAccess, ValidateApproverAccess } from '../Utilities/AccessManagementUtil'

// ----------------------------------CLIENT CONFIG PAGE----------------------------------------------

export const validateAccessRVD = (RVDParam) => async (dispatch, state) => {
	try {
		const RVDComponents = {
			...state().AccessValidationData.RVDComponents,
			...RVDParam
		}

		dispatch({
			type: SET_COMPONENT_ACCESS_RVD,
			RVDComponents: RVDComponents,
		});
	} catch (err) {
		console.log(err);
	}
};

export const InitRVD = () => async (dispatch, state) => {
	try {
		let userProfiles = state().userInformation.userProfiles;
		let isSuperAdmin = state().userInformation.isSuperAdmin;
		let clientID = state().SharedData.selectedClient.value;
		const RVDComponents = {
			...state().AccessValidationData.RVDComponents,
			reportProjectData: HasNoAccess(UserAccess(userProfiles, clientID, "Report Project Data"), [2, 3, 4]),
			selectProject: HasNoAccess(UserAccess(userProfiles, clientID, "Project Selection"), [2]),
			rvdParams: HasNoAccess(UserAccess(userProfiles, clientID, "Project Data Entry"), [2, 3]),
			computeButton: HasNoAccess(UserAccess(userProfiles, clientID, "Project Data Entry"), [2, 3]),
			approveRejectButton: HasNoAccess(UserAccess(userProfiles, clientID, "Project Data Entry"), [4]),
			submitButton: HasNoAccess(UserAccess(userProfiles, clientID, "Project Data Entry Submit"), [2, 3]),
			sadButton: HasNoAccess(UserAccess(userProfiles, clientID, "Project Data Entry"), [2, 3, 4]),
			sfaButton: HasNoAccess(UserAccess(userProfiles, clientID, "Project Data Entry"), [2, 3, 4]),
			upDownLoad: HasNoAccess(UserAccess(userProfiles, clientID, "Project Data Entry"), [2, 3]),
			landingPage: clientID !== undefined ? isSuperAdmin ? false : HasNoAccess(UserAccess(userProfiles, clientID, "Value Delivered_Landing page"), [1, 2, 3, 4]) : false,
		}

		dispatch({
			type: SET_COMPONENT_ACCESS_RVD,
			RVDComponents: RVDComponents,
		});
	} catch (err) {
		console.log(err);
	}

}


export const validateAccessPnP = (PnPParam) => async (dispatch, state) => {
	try {
		const PnPComponents = {
			...state().AccessValidationData.PnPComponents,
			...PnPParam
		}

		dispatch({
			type: SET_COMPONENT_ACCESS_PnP,
			PnPComponents: PnPComponents,
		});
	} catch (err) {
		console.log(err);
	}
};

export const InitPnP = () => async (dispatch, state) => {
	try {
		let userProfiles = state().userInformation.userProfiles;
		let userInfo = state().userInformation;
		let selectedProgramDetails = state().ProgramConfigurationData.selectedProgramDetails;
		let selectedProjectDetails = state().ProjectConfigurationData.selectedProjectDetails;
		let clientID = state().SharedData.selectedClient.value;
		let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
		
		const approverEmails = ValidateApproverAccess(selectedProgramDetails, newProgramDetails);
		let isApprover = approverEmails.indexOf(userInfo.emailAddress) !== -1;
		
		const PnPComponents = {
			...state().AccessValidationData.PnPComponents,
			landingPage: clientID !== undefined ? HasNoAccess(UserAccess(userProfiles, clientID, "Config Module landing page"), [1, 2, 3, 4]) : false, //Config Module landing page

			program: {
				add: HasNoAccess(UserAccess(userProfiles, clientID, "Add New Program"), [1, 2]), //Add New Program
				boiButton: HasNoAccess(UserAccess(userProfiles, clientID, "Add/ Edit BOIs"), [2]), //Add/ Edit BOIs
				idValueSetting: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value Settings"), [1]), //if has access only
				acctLeadApprv: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value - Account Leadership Approval"), [1]), //if has access only
				clntAlgnmnt: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value - Client Alignment"), [1]),//if has access only
				projectDtls: HasNoAccess(UserAccess(userProfiles, clientID, "Project Details Page"), [1]), //if has access only
				stageOnetransactBtn: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value Settings"), [2, 3]),
				stageOneFields: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value Settings"), [2, 3]),
				stageTwotransactBtn: isApprover ? false : HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value - Account Leadership Approval"), [2, 3, 4]),
				stageTwoFields: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value - Account Leadership Approval"), [2, 3]),
				stageTwoApprv: isApprover ? false : HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value - Account Leadership Approval"), [4]) || (selectedProgramDetails.OperationsAccountLead !== userInfo.emailAddress && !userInfo.isSuperAdmin),
				stageThreeAcknwldg: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value - Client Alignment"), [5]),
				downloadAttchmnt: HasNoAccess(UserAccess(userProfiles, clientID, "Program Identified Value - Client Alignment"), [6]),
			},
			project: {
				add: HasNoAccess(UserAccess(userProfiles, clientID, "Add New Project"), [1, 2]), //Add New Project
				boiButton: HasNoAccess(UserAccess(userProfiles, clientID, "Select BOIs"), [2]), //Select BOIs
				idValueSetting: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value Settings"), [1]), //Project Identified Value Settings
				acctLeadApprv: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value - Account Leadership Approval"), [1]), //Project Identified Value - Account Leadership Approval
				clntAlgnmnt: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value - Client Alignment"), [1]), //Project Identified Value - Client Alignment
				stageOnetransactBtn: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value Settings"), [2, 3]),
				stageOneFields: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value Settings"), [2, 3]),
				stageTwotransactBtn: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value - Account Leadership Approval"), [2, 3]),
				stageTwoFields: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value - Account Leadership Approval"), [2, 3]) || (selectedProjectDetails.ProjectApprover === userInfo.emailAddress && !userInfo.isSuperAdmin),
				stageTwoApprv: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value - Account Leadership Approval"), [4]) || (selectedProjectDetails.ProjectApprover !== userInfo.emailAddress && !userInfo.isSuperAdmin),
				stageThreeAcknwldg: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value - Client Alignment"), [5]),
				downloadAttchmnt: HasNoAccess(UserAccess(userProfiles, clientID, "Project Identified Value - Client Alignment"), [6]),
			}
		}

		dispatch({
			type: SET_COMPONENT_ACCESS_PnP,
			PnPComponents: PnPComponents,
		});
	} catch (err) {
		console.log(err);
	}
}