
import React, { Component } from 'react';
import { components } from 'react-select';
import { GrooveButton, GrooveIconSvg } from 'tfo-groove';
import { default as ReactSelect } from 'react-select';
import { UnRefObj, AreObjsEqual, TryGetObjValueAlt, } from '../../../Utilities/Formatter';
import { HasValue } from '../../../Utilities/Validations';

require('./CVMSingleSelectDropdownWithSearch.css');

const Control = (props) => {
  return (
    <>
      {
        props.hasValue ? (
          <>
            {/* <label className="placeholder-title">{props.selectProps.placeholder}</label> */}
            <components.Control {...props} />
          </>
        ) :
          <components.Control {...props} />
      }
    </>
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div>
          {' '}
          <label className="labels">{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};
const { MenuList } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const { onMenuInputFocus,
    // onClickApply, onClickCancel, 
    onPaste, handleSearch, inputValue } =
    selectProps;
  const valuesChecked = selectProps.value;

  // Copied from source
  const ariaAttributes = {
    'aria-autocomplete': 'list',
    'aria-label': selectProps['aria-label'],
    'aria-labelledby': selectProps['aria-labelledby'],
  };

  return (
    <div>
      <div className="search-bar-container">
        <div className="search-bar label14-regular-midnight">
          <GrooveIconSvg
            customClassName="close-modal-btn"
            size="large"
            name="search"
            iconStyle="solid"
            iconColor="stat-neutral"
          />
          <input
            style={{
              width: '100%',
              boxSizing: 'border-box',
              padding: 10,
              border: 'none',
              borderBottom: '1px solid lightgrey',
            }}
            id="CVMSingleSelectDropdownWithSearch"
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            value={inputValue}
            // onPaste={(e) =>
            //   onPaste(e, {
            //     action: 'select-option',
            //   })
            // }
            onKeyDown={(e) =>
              inputValue === '' && e.key === ' ' ? handleSearch(e, 'onKeyDown') : ''
            }
            onChange={(e) => {
              handleSearch(e, 'onChange');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.target.focus();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              e.target.focus();
            }}
            onFocus={onMenuInputFocus}
            placeholder="Search..."
            {...ariaAttributes}
          />
        </div>
      </div>
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let toBeRendered = children;

  return (
    <components.ValueContainer className="cont" {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

export default class CVMSingleSelectDropdownWithSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: [],
      containerWidth: 0,
      isCounterShown: false,
      inputValue: '',
      menuIsOpen: false,
      toBeApply: [],
      options: [],
      containerID: '',
      isPasted: false,
    };
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
  }

  componentDidMount = () => {
    this.setOptions();
    this.defaultOptionSelect();
    this.RemoveEvClickOutsideMenu();

  };

  componentDidUpdate = (prevProps) => {
    if (
      !AreObjsEqual(prevProps.value, this.props.value) ||
      !AreObjsEqual(prevProps.options, this.props.options)
    ) {
      this.setOptions();
      this.defaultOptionSelect();
    }
  };

  componentWillUnmount = () => {
    this.RemoveEvClickOutsideMenu();
  };

  setOptions = () => {
    let tOption = UnRefObj(this.props.options);
    let tSelected = UnRefObj(this.props.value);

    tOption.forEach((e) => {
      e.isFixed = HasValue(e.isFixed) ? e.isFixed : false;
      e.isDisabled = HasValue(e.isDisabled) ? e.isDisabled : false;
    });
    this.setState({
      optionSelected: UnRefObj(tSelected),
      options: UnRefObj(tOption),
    });
  };

  AddEvClickOutsideMenu = () => {
    window.addEventListener('click', this.onClickOutside);
  };

  onClickOutside = (e) => {
    const elID = this.state.containerID;
    try {
      const isArrowDownEv =
        TryGetObjValueAlt(e.target, '', 'parentNode.parentNode.className')
          .toLowerCase()
          .includes('-indicatorcontainer') ||
        TryGetObjValueAlt(e.target, '', 'parentNode.className')
          .toLowerCase()
          .includes('-indicatorcontainer') ||
        TryGetObjValueAlt(e.target, '', 'className').toLowerCase().includes('-indicatorcontainer');
      if (
        !document.getElementById(elID).contains(e.target) ||
        (isArrowDownEv && this.state.menuIsOpen)
      ) {
        this.onMenuClose();
      }
    } catch { }
  };

  RemoveEvClickOutsideMenu = () => {
    try {
      window.removeEventListener('click', () => { });
    } catch { }
  };

  onClickCancel = () => {
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
    this.setState(
      {
        optionSelected: this.getOrigValue(),
        menuIsOpen: false,
        inputValue: '',
        isPasted: false,
      },
      this.RemoveEvClickOutsideMenu
    );
  };

  onClickApply = (tSelected) => {
    this.props.onChange(tSelected);
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
    this.setState(
      {
        menuIsOpen: !this.state.menuIsOpen,
        inputValue: '',
        isPasted: false,
      },
      this.RemoveEvClickOutsideMenu
    );
  };

  defaultOptionSelect = () => {
    const { options } = this.props;
    if(Array.isArray(options) && options.length === 1) {
      const selected = {
        isDisabled: false,
        isFixed: false,
        label: options[0].label,
        programStatus: options[0].programStatus,
        text: options[0].text,
        value: options[0].value
      };
      
      this.setState({
        optionSelected: selected
      }, () => {
        this.props.onChange(UnRefObj(selected));
        // close menu
        this.setState({
            menuIsOpen: false,
            inputValue: '',
            isPasted: false,
        })
      });
    }

  };

  onMenuOpen = async () => {
    this.AddEvClickOutsideMenu();
    await new Promise((resolve) => setTimeout(resolve, 300));
    this.setState({ menuIsOpen: true, containerID: `cvmMulSelDDWChckCont${Math.random()}` });
  };

  onMenuClose = () => {
    //ON CLICK OUTSIDE RESTORE ORIG VALUES
    this.shouldChangeTrigger = true;
    this.isFirstInputSpace = false;
    this.setState(
      {
        optionSelected: this.getOrigValue(),
        menuIsOpen: false,
        inputValue: '',
        isPasted: false,
      },
      this.RemoveEvClickOutsideMenu
    );
    this.setOptions();
  };

  handleChange = (selected, event) => {
    try {
      if (this.shouldChangeTrigger && HasValue(selected.isDisabled) && !selected.isDisabled) {
        let tSelected = selected;
        if (event !== undefined) {
          this.setState({
            optionSelected: tSelected,
          }, () => {
            this.onClickApply(UnRefObj(tSelected));
          });
        } else {
          this.handleSearch({ target: { value: ' ' } }, '');
        }
      }

      if (!this.shouldChangeTrigger && this.isFirstInputSpace) {
        this.shouldChangeTrigger = true;
        this.isFirstInputSpace = false;
      }
    }
    catch (e) {
      console.log(e)
    }
  };

  getOrigValue = () => {
    const origList = UnRefObj(this.props.value);
    return origList;
  };

  // onPaste = (e, action) => {
  //   try {
  //     if (this.props.activePaste) {
  //       let tOptions = UnRefObj(this.props.options);
  //       let options = [];

  //       tOptions.forEach((e) => {
  //         e.isFixed = e.isFixed;
  //         e.isDisabled = e.isFixed;
  //       });

  //       let tPastedData = e.clipboardData
  //         .getData('text') //get clipboard data
  //         .replace(/(\r\n|\t\r\n|\t\r)/gm, '\n') //replace excel string to readable string
  //         .replace(/(\n|\r|\t)/gm, '\n')
  //         .replace(/\n+$/, '')
  //         .split('\n') //create array
  //         .filter((item, index, list) => list.indexOf(item) == index); //filter unique data

  //       // find in options pasted data
  //       let currentPasted = [];
  //       let selectedData = this.state.optionSelected;
  //       let inputValue = '';

  //       //check if pasted data is existing on options
  //       tPastedData.map((tp) => {
  //         let opData = tOptions.find(
  //           (op) => op.label.toLowerCase() === tp.toLowerCase() // && !op.isFixed
  //           // (op) => op.label.replace(/^\s+/, '').toLowerCase() === tp.toLowerCase()
  //         );
  //         // for currently selected & existing selected Data
  //         if (opData !== undefined) {
  //           if (
  //             selectedData.find((pD) => pD.label.toLowerCase() === tp.toLowerCase()) === undefined
  //           ) {
  //             selectedData.push(opData);
  //           }
  //           // for currently pasted data
  //           if (
  //             currentPasted.find((pD) => pD.label.toLowerCase() === tp.toLowerCase()) === undefined
  //           ) {
  //             currentPasted.push(opData);
  //           }
  //         }
  //       });

  //       if (currentPasted.length >= 1) {
  //         tOptions.unshift({
  //           label: `All (${this.props.options.length})`,
  //           value: '*',
  //         });
  //         options = tOptions;
  //       } else {
  //         inputValue = e.clipboardData.getData('text');
  //         tOptions.map((op) => {
  //           if (op.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
  //             options.push(op);
  //           }
  //         });
  //       }

  //       this.setState(
  //         {
  //           inputValue: inputValue,
  //           isPasted: true,
  //           options: options,
  //         },
  //         () => {
  //           if (currentPasted.length >= 1) {
  //             this.shouldChangeTrigger = true;
  //             this.isFirstInputSpace = false;
  //             this.handleChange(selectedData.filter(e => !e.isFixed), action);
  //           }
  //         }
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  handleSearch = (e, origin) => {
    try {
      if (!this.state.isPasted) {
        let options = [];
        let tOptions = UnRefObj(this.props.options);
        let searchKey = origin === 'onKeyDown' ? ' ' : e.target.value;

        tOptions.forEach((e) => {
          e.isFixed = HasValue(e.isFixed) ? e.isFixed : false;
          e.isDisabled = HasValue(e.isDisabled) ? e.isDisabled : false;
        });

        tOptions.map((op) => {
          if (op.label.toString().toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {
            options.push(op);
          }
        });

        this.shouldChangeTrigger = origin !== 'onKeyDown';
        this.isFirstInputSpace = origin === 'onKeyDown';
        this.setState({
          options: UnRefObj(options),
          inputValue: searchKey, //onkeydown
        });
      } else {
        this.setState({
          isPasted: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const props = this.props;
    let maxToShow = 3;
    let shouldBadgeShow = length > maxToShow;
    let displayLength = length - maxToShow;
    var length = Object.keys(props.value).length !== 0 ? Object.keys(props.value).length : 0;
    let selectedCount = this.state.optionSelected.length;
    // let requiredPlaceHolder = props.placeholder + (props.isRequired ? '*' : '');
    const styles = {
      container: (base, state) => ({
        ...base,
        borderRadius: '5px',

        boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.15)',
        padding: '5px',
        zIndex: 2,
        width: '100%',
        fontSize: '16px',
      }),
      option: (base, state) => ({
        ...base,
        '&:hover': {
          color: state.isSelected ? '#FFFFFF' : '#FFFFFF',
          backgroundColor: '#A100FF',
          fontFamily: 'Graphik-Medium',
        },
        backgroundColor: 'none',
        color: state.isSelected ? 'rgb(21, 24, 27)' : 'rgb(99, 115, 129)',
        whiteSpace: 'nowrap',
        fontFamily: 'Graphik-Medium',
        fontSize: '14px',
        padding: '5px 15px',
        wordBreak: 'break-word',
        cursor: 'pointer',
      }),
      placeholder: (base, state) => ({
        color: '#919eab',
      }),
      menu: (base, state) => ({
        ...base,
        borderRadius: "5px",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 24px 0px rgba(0, 0, 0, 0.15)",
        padding: "5px",
        zIndex: 2,
        width: "100%",
        fontSize: "12px",
      }),
      singleValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "rgb(250, 245, 253)",
          borderRadius: "16px",
          border: "1px solid rgb(161, 0, 255)",
          height: "24px",
          width: "79px",
          padding: "0 0px 0 10px",
          display: "flex",
          alignItems: "center",
          margin: "3px 4px",
        };
      },
      valueContainer: (styles, { data }) => ({
        ...styles,
        color: "rgb(161, 0, 255)",
        fontSize: "12px",
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      }),
    };
    const customClassName = props.customClassName == undefined ? '' : props.customClassName;
    return (
      <div
        id={this.state.containerID}
        className={
          props.value.length === 0
            ? `single-select-dropdown-with-search blank-multi-field ${customClassName}`
            : `single-select-dropdown-with-search ${customClassName}`
        }
      >
        {
          <div className="multi-select-label ">
            {selectedCount > 0 ? props.label : ''}
            {props.isRequired && selectedCount > 0 ? <span className="requiredText">*</span> : ''}
          </div>
        }
        <div id="mt-count-tooltip" className={props.isRequired ? 'required-multi-select' : ''}>
          <ReactSelect
            noOptionsMessage={() => 'No available option.'}
            options={this.state.options}
            ref={r => (this.selectRef = r)}

            // isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
              // MultiValue,
              ValueContainer,
              MenuList: CustomMenuList,
              IndicatorSeparator: () => null,
              Control
            }}
            styles={styles}
            onChange={this.handleChange}
            allowSelectAll={true}
            placeholder={props.placeholder}
            value={this.state.optionSelected}
            isDisabled={props.isDisabled}
            onMenuOpen={this.onMenuOpen}
            openMenuOnClick={this.onMenuOpen}
            menuIsOpen={this.state.menuIsOpen}
            // openMenuOnClick={true}
            isSearchable={false}
            // onClickCancel={this.onClickCancel}
            // onClickApply={this.onClickApply}
            isClearable={false}
            popDirection={props.popdirection}
            backspaceRemovesValue={false}
            // onPaste={this.onPaste}
            onKeyDown={e => {
              if (e.keyCode === 32 && !this.selectRef.state.inputValue) e.preventDefault();
            }}
            inputValue={this.state.inputValue}
            handleSearch={this.handleSearch}
          />
        </div>
      </div>
    );
  }
}
