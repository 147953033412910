import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { GrooveTabComponent, GrooveIconSvg, GrooveButton } from "tfo-groove";
import { Accordion, Card, Row } from "react-bootstrap";
import { GrooveTextBox } from "tfo-groove";
import { HttpGet } from "../../../Utilities/HTTPUtil";

require("./CVMViewComments.css");

const CVMViewComments = (function () {
  class CVMViewCommentsPresentational extends Component {
    render() {
      const tab_data = [
        {
          tab_label: "Rejection",
          tab_value: 1,
          tab_id: "rejection-tab",
          tab_content: (
            <div className="CVMSideModal-tab-container">
              {Object.keys(this.props.commentList).length > 0 &&
                this.props.commentList.ReJectedList.length > 0 &&
                this.props.commentList.ReJectedList.map((rejected) => {
                  return (
                    <>
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Accordion.Collapse eventKey={rejected.ID}>
                            <Card.Body>
                              <div className="comments">
                                <GrooveTextBox
                                  maxLength={255}
                                  id="comment-textbox"
                                  name="comment-textbox"
                                  type="text"
                                  placeholder="Reason for Rejection"
                                  textValue={rejected.Reason}
                                  isDisabled={true}
                                />
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                          <Card.Header>
                            {/* Toggle 'open-card' class to toggle the accordion arrow */}
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={rejected.ID}
                              className="open-card"
                            >
                              <FontAwesomeIcon icon={faCaretRight} />
                              {rejected.Type + " - " + rejected.Name}
                            </Accordion.Toggle>
                          </Card.Header>
                        </Card>
                      </Accordion>
                    </>
                  );
                })}
            </div>
          ),
        },
        {
          tab_label: "Conditional Approval",
          tab_value: 2,
          tab_id: "conditionalApproval-tab",
          tab_content: (
            <div className="CVMSideModal-tab-container">
              {Object.keys(this.props.commentList).length > 0 &&
                this.props.commentList.ConditionalApprovedList.length > 0 &&
                this.props.commentList.ConditionalApprovedList.map(
                  (conditionalApproved) => {
                    return (
                      <>
                        <Accordion defaultActiveKey="0">
                          <Card>
                            <Accordion.Collapse eventKey={conditionalApproved.ID}>
                              <Card.Body>
                                <div className="comments">
                                  <GrooveTextBox
                                    maxLength={255}
                                    id="comment-textbox"
                                    name="comment-textbox"
                                    type="text"
                                    placeholder="Reason for Conditional Approval"
                                    textValue={conditionalApproved.Reason}
                                    isDisabled={true}
                                  //  callbackFunction={this.callbackFunction}
                                  />
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                            <Card.Header>
                              {/* Toggle 'open-card' class to toggle the accordion arrow */}
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={conditionalApproved.ID}
                                className="open-card"
                              >
                                <FontAwesomeIcon icon={faCaretRight} />
                                {conditionalApproved.Type + " - " + conditionalApproved.Name}
                              </Accordion.Toggle>
                            </Card.Header>
                          </Card>
                        </Accordion>
                      </>
                    );
                  }
                )}
            </div>
          ),
        },
      ];
      return (
        <div className="CVMSideModal-container">
          <div className="CVMSideModal-close" onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="CVMSideModal-title">View Comments</div>
          <GrooveTabComponent theme="purple" tab_data={tab_data}
            selectedIndex={this.props.commentList.ReJectedList.length > 0 ? 0 : 1}
          />
          {/* <div className="CVMSideModal-footer footer-position">
                    <Row className="rejection-modal-buttons">
                            <GrooveButton
                            id="primry-btn-1"
                            name="Solid Button Primary-Ops"
                            isDisabled={false}
                            hasIcon={false}
                            type="outline"
                            colorClass="stat-alternate"
                            size="auto"
                            text="Cancel"
                            callbackFunction={this.props.onCloseModal}
                            />

                            <GrooveButton
                            id="primry-btn-2"
                            name="Solid Button Primary-Ops"
                            isDisabled={false}
                            hasIcon={false}
                            type="solid"
                            colorClass="stat-alternate"
                            size="auto"
                            text="Update"
                            />
                        </Row>
            
                  </div>
                */}
        </div>
      );
    }
  }

  class CVMViewCommentsContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        BOIList: [],
      };
    }

    componentDidMount() {
      this.changeTextAreaClass()
    }

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }


    render() {
      return (
        <CVMViewCommentsPresentational
          {...this.props}
          onCloseModal={this.props.closeCommentsSection}
        />
      );
    }
  }

  return CVMViewCommentsContainer;
})();

export default CVMViewComments;
