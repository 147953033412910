import React, { Component } from "react";
import Select, { components } from "react-select";
import { Modal, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GrooveIconSvg, GrooveInput, GrooveButton } from "tfo-groove";

require("./CVMMultiSelectTextbox.css");

const CVMMultiSelectTextbox = (function () {
  class CVMMultiSelectTextboxPresentational extends Component {
    render() {
      return (
        <div className="cvm-multiselect-text">
          <GrooveInput
            id={this.props.id}
            name={this.props.name}
            placeholder={this.props.placeholder}
            type="text"
            isAutocomplete="false"
            callbackFunction={this.props.onTextChange}
            maxLength={255}
            customClassName={this.props.customClassName}
            inputValue={this.props.inputValue}
            isDisabled={this.props.isDisabled}
          />
          <GrooveButton
            id="primry-btn-2"
            hasIcon={true}
            type="solid"
            colorClass="stat-alternate"
            text="Add"
            callbackFunction={this.props.onAddButton}
            iconAlignment="right"
            iconSize="small"
            iconName="plus"
            iconStyle="solid"
            iconId="btn-icon-01"
            isDisabled={this.props.isDisabled}
          />
          <div className="cvm-multiselect-text-selected">
            {this.props.list ? this.props.list.map((selected, index) => (
              selected.label!=null&&(
              <div className="cvm-multiselect-text-selected-values" key={index}>
                <div>{selected.label}</div>
                <GrooveIconSvg
                  size="small"
                  name="times"
                  iconStyle="solid"
                  iconColor="stat-alternate"
                  callbackFunction={() => { this.props.onRemoveSelected(selected) }}
                />
              </div>)
            )) : []}
          </div>
        </div>
      );
    }
  }

  class CVMMultiSelectTextboxContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount = () => { };

    render() {
      return (
        <CVMMultiSelectTextboxPresentational
          placeholder={this.props.placeholder}
          id={this.props.id}
          name={this.props.name}
          callbackFunction={this.props.callbackFunction}
          onAddButton={this.props.addButton}
          onRemoveSelected={this.props.onRemoveSelected}
          onTextChange={this.props.onTextChange}
          list={this.props.list}
          customClassName={this.props.customClassName}
          inputValue={this.props.inputValue}
          isDisabled={this.props.isDisabled}
        />
      );
    }
  }

  return CVMMultiSelectTextboxContainer;
})();

export default CVMMultiSelectTextbox;
