import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { GrooveIconSvg, GrooveTabComponent, GrooveButton } from "tfo-groove";
import DimensionAccordionBOIHistory from '../Shared/CVMDimensionAccordionBOIHistory/DimensionAccordionBOIHistory';

import {
  getProgramBOIHistory,
  getProjectBOIHistory
} from '../../Actions/ProgramProjectBOIHistory.Action';

import "./BOIHistoryModal.css"

const BOIHistoryModal = (function () {
    class BOIHistoryModalPresentaional extends Component {
        render() {

            const tab_data = [
                {
                  tab_label: "Rejected BOIs",
                  tab_value: 1,
                  tab_id: "Rejected",
                  tab_content: (
                      <div className="table-view-wraps">
                        <DimensionAccordionBOIHistory 
                          dimensionList={this.props.rejectedDimensionList} 
                          mode="Rejected" 
                          filter={this.props.filter} 
                        />
                      </div>
                  ),
                },
                {
                  tab_label: "Modified BOIs",
                  tab_value: 2,
                  tab_id: "Modified",
                  tab_content: (
                      <div className="table-view-wraps tab-modified">
                        <DimensionAccordionBOIHistory 
                          dimensionList={this.props.modifiedDimensionList} 
                          mode="Modified" 
                          filter={this.props.filter} 
                        />
                      </div>
                  ),
                },
                {
                  tab_label: "Deleted BOIs",
                  tab_value: 3,
                  tab_id: "Deleted",
                  tab_content: (
                      <div className="table-view-wraps">
                        <DimensionAccordionBOIHistory 
                          dimensionList={this.props.deletedDimensionList} 
                          mode="Deleted" 
                          filter={this.props.filter} 
                        />
                      </div>
                  ),
                }
            ];

            return (
                <React.Fragment>
                    <div className="btn-boi-history-container">
                      <GrooveButton
                            id="btn-boi-history"
                            hasIcon={true}
                            type="utility"
                            colorClass="stat-alternate"
                            text="BOI History"
                            iconAlignment="right"
                            iconSize="tiny"
                            iconName="history"
                            iconStyle="solid"
                            iconId="btn-icon-history"
                            callbackFunction={this.props.handleShow}
                      />
                    </div>
                    <Modal
                        show={this.props.show}
                        centered
                        dialogClassName="boiHistoryModal"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                <div className="header24-semibold-midnight">
                                    <GrooveIconSvg
                                        customClassName="back-modal-btn"
                                        size="small"
                                        name="chevron-left"
                                        iconStyle="solid"
                                        iconColor="stat-alternate"
                                        callbackFunction={this.props.handleShow}
                                    />
                                    Business Outcome Indicators History
                                </div>
                                <GrooveIconSvg
                                    customClassName="close-modal-btn"
                                    size="large"
                                    name="times"
                                    iconStyle="solid"
                                    iconColor="primary-acn"
                                    callbackFunction={this.props.handleShow}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="boi-history">
                                <div className="boi-history-content">
                                    <div className="search-bar-container">
                                        <div className="search-bar label14-regular-midnight">
                                            <GrooveIconSvg
                                                customClassName="close-modal-btn"
                                                size="large"
                                                name="search"
                                                iconStyle="solid"
                                                iconColor="stat-neutral"
                                            />
                                            <input 
                                                type="text" 
                                                name="name" 
                                                placeholder="Search..." 
                                                maxlength="255" 
                                                onChange={(e) => this.props.onSearch(e) } 
                                                value={this.props.filter}
                                            />
                                        </div>
                                    </div>
                                    <GrooveTabComponent 
                                        theme="purple" 
                                        tab_data={tab_data} 
                                        callbackFunction={this.props.tabCallback} 
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            );
        }
    }

    class BOIHistoryModalContainer extends Component {
        constructor(props) {
          super(props);
          this.state = {  
            filter: '',
            show: false
          };
        }

        componentDidMount = async () => {
            try {
              if(this.props.historyType === "Program"){
                await this.props.getProgramBOIHistory(this.props.ProgramConfigurationData.selectedProgram.value)
              }else{
                await this.props.getProjectBOIHistory(this.props.ProjectConfigurationData.selectedProject.value)
              }      
            }
            catch (e) {
              console.log(e);
            }
        }
      
        onSearch = (e) => {
            const filter = e.target.value
            this.setState({
              filter: filter
            })
        }
      
        tabCallback = (tab) => {
            this.setState({
              filter: ''
            })
        }

        handleShow = () => {
          try {
            this.setState({
              show: !this.state.show
            })
          }
          catch (e) {
            console.log(e)
          }
        }
    
        render() {
            const boiHistoryList = (this.props.historyType === "Program") ? 
              this.props.ProgramConfigurationData.programBOIHistory : 
              this.props.ProjectConfigurationData.projectBOIHistory ;
              
          return (
            <BOIHistoryModalPresentaional
                show={this.state.show}
                handleShow={this.handleShow}
                filter={this.state.filter}
                historyType={this.props.historyType}
                
                tabCallback={this.tabCallback}
                onSearch={this.onSearch}

                rejectedDimensionList={boiHistoryList.rejectedBOIHistories}
                modifiedDimensionList={boiHistoryList.modifiedBOIHistories}
                deletedDimensionList={boiHistoryList.deletedBOIHistories}
            />
          );
        }
      }
    
      return BOIHistoryModalContainer;
})();

export default connect(
    state => {
      return {
        ProgramConfigurationData: state.ProgramConfigurationData,
        ProjectConfigurationData: state.ProjectConfigurationData
      }
    },
    dispatch => bindActionCreators(Object.assign({},
      {
        getProgramBOIHistory,
        getProjectBOIHistory
      }), dispatch)
  )(BOIHistoryModal);