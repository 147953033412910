import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import {
  GrooveInput,
  GrooveDateCalendarPicker,
  GrooveButton,
  GrooveTextBox,
} from "tfo-groove";
import {
  HasProperty,
  IsValid,
  IsADate,
} from "../../../../Utilities/Validations";
import CVMModal from "../../../Shared/CVMModal/CVMModal";
import CVMProjectLeadDropdown from "../../../Shared/CVMProjectLeadDropdown/CVMProjectLeadDropdown";
import CVMProjectDeliveryLocationDropdown from "../../../Shared/CVMProjectDeliveryLocationDropdown/CVMProjectDeliveryLocationDropdown";
import CVMProjectDeliveredByDropdown from "../../../Shared/CVMProjectDeliveredByDropdown/CVMProjectDeliveredByDropdown";
import "./ProjectDetails.css";
const ProjectAddModal = (() => {
  class ProjectAddModalPresentational extends Component {
    render() {
      const form = this.props.formData;
      const callback = this.props.formCallback;
      const isValid = this.props.fieldValidation;
      return (
        <div className="tab-section-header bodytext18-medium-midnight">
          <GrooveButton
            id="primry-btn-1"
            name="Solid Button Primary-Ops"
            isDisabled={this.props.isDisabled}
            hasIcon={true}
            type="solid"
            colorClass="stat-alternate"
            size="auto"
            text="Add New Project"
            iconAlignment="right"
            iconSize="small"
            iconName="plus"
            iconStyle="solid"
            iconId="btn-icon-01"
            callbackFunction={this.props.onOpenCloseModal}
            customClassName={"add-button-right"}
          />
          <CVMModal
            title="Add New Project"
            show={this.props.show}
            onCloseModal={this.props.onOpenCloseModal}
            size="md"
            content={
              <React.Fragment>
                <div className="add-project-modal">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <GrooveInput
                        id="projectName"
                        name="projectname"
                        placeholder="Project Label*"
                        type="text"
                        isAutocomplete="false"
                        customClassName={
                          isValid.projectNameValid ? "" : " dropdown-invalid"
                        }
                        inputValue={form.projectName}
                        callbackFunction={callback}
                        maxLength={255}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <CVMProjectLeadDropdown
                        data={form.projectLead}
                        callback={callback}
                        isValid={isValid.projectLeadValid}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="comments" lg={12} md={12} sm={12}>
                      <GrooveTextBox
                        id="projectDescription"
                        name="projectdescription"
                        minLength={20}
                        type="text"
                        placeholder="Project Description"
                        textValue={form.projectDescription}
                        callbackFunction={callback}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <GrooveInput
                        id="offering"
                        name="offering"
                        placeholder="Offering"
                        type="text"
                        isAutocomplete="false"
                        customClassName="dimension-input"
                        isDisabled={true}
                        inputValue={form.offeringName}
                        callbackFunction={callback}
                        maxLength={255}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <CVMProjectDeliveryLocationDropdown
                        data={form.deliveryLocations}
                        callback={callback}
                        isValid={isValid.deliveryLocationsValid}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <CVMProjectDeliveredByDropdown
                        data={form.deliveredBy}
                        callback={callback}
                        isValid={isValid.deliveredByValid}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <GrooveDateCalendarPicker
                        id="targetEndDate"
                        name="targetenddate"
                        labelText="Target End Date*"
                        dateValue={form.targetEndDate}
                        callbackFunction={callback}
                        customClassName={
                          isValid.targetEndDateValid ? "" : " dropdown-invalid"
                        }
                        maxDate={this.props.dateLimit}
                      />
                    </Col>
                  </Row>
                  <div>
                    <br />
                  </div>
                  {isValid.projectNameValid &&
                    isValid.projectLeadValid &&
                    isValid.deliveryLocationsValid &&
                    isValid.deliveredByValid &&
                    isValid.targetEndDateValid ? (
                    ""
                  ) : (
                    <p style={{ color: "red" }}>Missing Required field</p>
                  )}
                </div>
                <div className="add-boi-buttons">
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Cancel"
                    callbackFunction={this.props.onOpenCloseModal}
                  />
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    isDisabled={false}
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Add Project"
                    callbackFunction={this.props.onClickSaveProject}
                  />
                </div>
              </React.Fragment>
            }
          />
        </div>
      );
    }
  }

  class ProjectAddModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        dateLimit: 0
      };
      this.defaultFormData = {
        projectName: "",
        projectLead: {},
        projectDescription: "",
        offeringID: 0,
        offeringName: "",
        deliveryLocations: [],
        deliveredBy: {},
        targetEndDate: "",
      };
      this.defaultValidation = {
        projectNameValid: true,
        projectLeadValid: true,
        offeringIDValid: true,
        offeringNameValid: true,
        deliveryLocationsValid: true,
        deliveredByValid: true,
        targetEndDateValid: true,
      };

      this.state = {
        show: true,
        formData: this.defaultFormData,
        fieldValidation: JSON.parse(JSON.stringify(this.defaultValidation)),
      }
    }
    componentDidMount() {
      let today = new Date();
      let nextYear = (today.getFullYear() + 1).toString();
      let endDate = new Date("08/31/" + nextYear);
      let timeDiff = endDate.getTime() - today.getTime();
      let dateLimit = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.setState({
        show: false,
        dateLimit: dateLimit      
      })

      this.changeTextAreaClass()
    }

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    onOpenCloseModal = () => {
      const progDetails = this.props.selectedProgramDetails;
      this.setState({
        show: !this.state.show,
        formData: {
          ...this.defaultFormData,
          offeringID: progDetails.offeringID,
          offeringName: progDetails.offering,
        },
        fieldValidation: JSON.parse(JSON.stringify(this.defaultValidation)),
      });
    };
    formCallback = (e, id) => {
      id = IsValid(id) ? id : e.id;
      let tempFormData = this.state.formData;
      let tempValidation = this.state.fieldValidation;

      switch (id) {
        case "projectName":
          tempFormData.projectName = e.value;
          tempValidation.projectNameValid = true;
          break;
        case "projectLead":
          delete e.id;
          tempFormData.projectLead = e;
          tempValidation.projectLeadValid = true;
          break;
        case "projectDescription":
          tempFormData.projectDescription = e.value;
          break;
        case "deliveryLocation":
          delete e.id;
          tempFormData.deliveryLocations = HasProperty(e, "data") ? e.data : [e];
          tempValidation.deliveryLocationsValid = true;
          break;
        case "deliveredBy":
          tempFormData.deliveredBy = e;
          tempValidation.deliveredByValid = true;
          break;
        case "targetEndDate":
          tempFormData.targetEndDate = new Date(e.value);
          tempValidation.targetEndDateValid = true;
          break;
        default:
      }
      this.setState({
        formData: tempFormData,
        fieldValidation: tempValidation,
      });
    };
    onClickSaveProject = () => {
      let fieldsValidation = this.state.fieldValidation;
      let shouldProceed = true;
      let fieldValues = this.state.formData;
      if (fieldValues.projectName === "") {
        fieldsValidation.projectNameValid = false;
        shouldProceed = false;
      }
      if (!IsADate(fieldValues.targetEndDate)) {
        fieldsValidation.targetEndDateValid = false;
        shouldProceed = false;
      }
      if (
        (HasProperty(fieldValues.deliveryLocations, "value") &&
          fieldValues.deliveryLocations.value === null) ||
        (!HasProperty(fieldValues.deliveryLocations, "value") &&
          fieldValues.deliveryLocations.length === 0)
      ) {
        fieldsValidation.deliveryLocationsValid = false;
        shouldProceed = false;
      }
      if (!HasProperty(fieldValues.deliveredBy, "value")) {
        fieldsValidation.deliveredByValid = false;
        shouldProceed = false;
      }
      if (!HasProperty(fieldValues.projectLead, "value")) {
        fieldsValidation.projectLeadValid = false;
        shouldProceed = false;
      }
      if (!shouldProceed) {
        this.setState({ fieldValidation: fieldsValidation });
        return;
      }

      this.onOpenCloseModal();
      this.props.onClickAddProject(this.state.formData);
      this.setState({ formData: this.defaultFormData });
    };

    render() {
      return (
        <ProjectAddModalPresentational
          {...this.props}
          {...this.state}
          formCallback={this.formCallback}
          onOpenCloseModal={this.onOpenCloseModal}
          onClickSaveProject={this.onClickSaveProject}
          dateLimit = {this.state.dateLimit}
        />
      );
    }
  }

  return ProjectAddModalContainer;
})();

export default ProjectAddModal;
