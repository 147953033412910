export const ScreenWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

export function SetLocalStorage(storageName, value) {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem(storageName, value);
    return true;
  }
  return null;
}
export function GetLocalStorage(storageName) {
  if (typeof Storage !== 'undefined') {
    return localStorage.getItem(storageName);
  }
  return null;
}
export function DeleteLocalStorage(storageName) {
  if (typeof Storage !== 'undefined') {
    localStorage.removeItem(storageName);
  }
}

export function ResetLocalStorage() {
  localStorage.clear();
}

export function SetSessionStorage(storageName, value) {
  if (typeof Storage !== 'undefined') {
    sessionStorage.setItem(storageName, value);
    return true;
  }
  return null;
}
export function GetSessionStorage(storageName) {
  if (typeof Storage !== 'undefined') {
    return sessionStorage.getItem(storageName);
  }
  return null;
}
export function DeleteSessionStorage(storageName) {
  if (typeof Storage !== 'undefined') {
    sessionStorage.removeItem(storageName);
  }
}

export function ResetSessionStorage() {
  sessionStorage.clear();
}

export function RandIntBetween(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}