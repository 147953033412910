import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { GrooveButton } from 'tfo-groove';
import { IsValid, IsValidAccentureEmail } from '../../../Utilities/Validations';
import CVMAutoMultiSelectDropdownWithCheckBoxAndPaste from '../../Shared/CVMAutoMultiSelectDropdownWithCheckBoxAndPaste/CVMAutoMultiSelectDropdownWithCheckBoxAndPaste';
import CVMMultiSelectTextbox from '../../Shared/CVMMultiSelectTextbox/CVMMultiSelectTextbox';
import SingleDropdownWithMultiDropdownSelectedView from '../../Shared/SingleDropdownWithMultiDropdownSelectedView/SingleDropdownWithMultiDropdownSelectedView';
import { HttpPost } from '../../../Utilities/HTTPUtil';
require('./ReplacementModal.css');

const ReplacementModal = (function () {
  class ReplacementModalPresentational extends Component {
    render() {
      return (
        <Modal
          show={this.props.show}
          onHide={() => { }}
          size={this.props.size}
          centered
          dialogClassName="replacement-modal"
          enforceFocus={this.props.enforceFocus}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="replacement-modal-header">Replace User</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="replacement-modal-message">
                The user you are trying to remove is mapped to program or project. Please select a
                replacement user to proceed.
              </div>
              {
                !this.props.isSingle ? (
                  <div className="replacement-multiselect-dropdown">
                    <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                      id="mappedToClient"
                      placeholder={this.props.replacementSelectedUsers.length !== 0 ? '' : 'Select Replacement Users'}
                      options={this.props.replacementUserList}
                      value={this.props.replacementSelectedUsers}
                      label={'Replacement Users'}
                      onChange={(e) => this.props.handleSetReplacementUsers(e)}
                      popdirection="pop-bottom"
                      activePaste={false}
                      customClassName={`${this.props.replacementUserList.length === 0 ? 'disabled' : ''} ${this.props.isErrorInDropdown ? 'invalid-replacement-users' : ''}`}
                      isDisabled={this.props.replacementUserList.length === 0 ? true : false}
                    />
                  </div>
                )
                  : (
                    <SingleDropdownWithMultiDropdownSelectedView
                      id="singleUser"
                      options={this.props.replacementUserList}
                      value={this.props.replacementSelectedUsers}
                      onChange={(e) => this.props.handleSetReplacementUsers(e)}
                      placeholder={this.props.replacementSelectedUsers !== null ? this.props.replacementSelectedUsers.length > 0 ? "Replacement User" : "Select Replacement User" : "Select Replacement User"}
                      isDisabled={this.props.replacementUserList.length === 0 || this.props.newUserReplacement.length > 0}
                    />
                  )
              }
              <div>
                <CVMMultiSelectTextbox
                  placeholder={
                    this.props.replacementUserList.length === 0 ? 'Add New User *' : 'Add New User'
                  }
                  id="newUser"
                  name="newUser"
                  addButton={() => this.props.onAddUserEmail()}
                  onRemoveSelected={(e) => this.props.onRemoveUserEmail(e)}
                  onTextChange={(e) => this.props.onChangeAddUser(e)}
                  list={this.props.newUserReplacement}
                  customClassName={this.props.isError ? "invalid" : ""}
                  inputValue={this.props.addUserEmail}
                  isDisabled={this.props.role === "IOA" && this.props.replacementSelectedUsers.length > 0}
                />
              </div>
              <div className="replacement-buttons">
                {
                  this.props.isError || !this.props.isUserValid || this.props.isErrorInDropdown ? (
                    <div className="error-message">
                      <span>
                        {this.props.errorMessage}
                      </span>
                    </div>
                  ) : ''
                }
                <div className="button-container">
                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    hasIcon={false}
                    type="outline"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Cancel"
                    callbackFunction={() => this.props.hideReplacementModal()}
                  />

                  <GrooveButton
                    id="primry-btn-1"
                    name="Solid Button Primary-Ops"
                    hasIcon={false}
                    type="solid"
                    colorClass="stat-alternate"
                    size="auto"
                    text="Save"
                    callbackFunction={(e) => this.props.handleSaveReplacement(e)}
                    isDisabled={!this.props.isUserValid || (this.props.newUserReplacement.length === 0 && this.props.replacementSelectedUsers.length === 0) || this.props.isErrorInDropdown}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }

  class ReplacementModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        replacementSelectedUsers: [],
        addUserEmail: '',
        newUserReplacement: [],
        errorMessage: "",
        isError: false,
        isUserValid: true
      };
    }

    handleSetReplacementUsers = async(e) => {

      if(e.length === 0) {
        this.setState({
          replacementSelectedUsers: e,
          isErrorInDropdown: false,
          errorMessage: this.state.isError ? this.state.errorMessage : '',
        });
        return;
      }

      const data = await IsValidAccentureEmail([...e.map(x => x.label)]);

      let errorMessage;

      let isUserValid = true;
      let allInvalidEmails = [];

      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          isUserValid = false;
          allInvalidEmails.push(data[i].UserName.split("@")[0]);
          errorMessage = `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(", ")}`;
        }
      }

      this.setState({
        replacementSelectedUsers: e,
        isErrorInDropdown: errorMessage,
        isError: false,
        isUserValid,
        errorMessage
      });
    };

    onChangeAddUser = (e) => {
      this.setState({ addUserEmail: e.value });
    };

    onAddUserEmail = async () => {
      let newUserReplacement = this.state.newUserReplacement;
      let addUserEmail = this.state.addUserEmail;
      let isValid = {
        isValid: true,
        errorMessage: ''
      };
      let returnData = {};
      switch (this.props.role) {
         case "IOA" || "QC":
          if (newUserReplacement.length > 0) {
            isValid = {
              isValid: false,
              errorMessage: 'There can be only 1 replacement user for IO approver.'
            }
          } else if (newUserReplacement.length === 0) {
            isValid = await this.props.validateEmailAddress(addUserEmail, false);
          } else {
            isValid = {
              isValid: true,
              errorMessage: ''
            };
          }

          if (isValid.isValid) {
            newUserReplacement.push({
              value: 0,
              label: addUserEmail,
              text: addUserEmail,
              userName: addUserEmail.split("@")[0],
              isNew: true,
              isMapped: true
            });
          }
          break;
        case 'cxLeads':
          if (newUserReplacement.filter(x => x.label === addUserEmail).length !== 0) {
            isValid = {
              isValid: false,
              errorMessage: 'User already exists.'
            }
          } else {
            returnData = await this.props.validateMultiSelectUserData(
              this.props.clientManagementProperties.cxLeads,
              addUserEmail,
              'cxLeads',
              true
            );
            isValid = {
              isValid: returnData.isError ? false : true,
              errorMessage: returnData.errorMessage[0].value
            };
          }
          if (isValid.isValid) {
            newUserReplacement.push({
              value: addUserEmail,
              label: addUserEmail,
              text: addUserEmail,
              clientProgramID: this.props.replacedUser.clientProgramID,
              clientProjectID: this.props.replacedUser.clientProjectID,
              isNew: true
            });
          }
          break;
        case 'opAccLeads':
          if (newUserReplacement.filter(x => x.label === addUserEmail).length !== 0) {
            isValid = {
              isValid: false,
              errorMessage: 'User already exists.'
            }
          } else {
            returnData = await this.props.validateMultiSelectUserData(
              this.props.clientManagementProperties.opAccLeads,
              addUserEmail,
              'opAccLeads',
              true
            );
            isValid = {
              isValid: returnData.isError ? false : true,
              errorMessage: returnData.errorMessage[0].value
            };
          }
          if (isValid.isValid) {
            newUserReplacement.push({
              value: addUserEmail,
              label: addUserEmail,
              text: addUserEmail,
              clientProgramID: this.props.replacedUser.clientProgramID,
              clientProjectID: this.props.replacedUser.clientProjectID,
              isNew: true
            });
          }
          break;
        default:
          break;
      }


      this.setState({
        newUserReplacement: newUserReplacement,
        addUserEmail: '',
        errorMessage: isValid.errorMessage,
        isError: !isValid.isValid
      });
    };

    getDataObjectIndex = (labelValue, arrayList) => {
      return arrayList
        .map(function (e) {
          return e.label;
        })
        .indexOf(labelValue);
    };

    onRemoveUserEmail = (eObj) => {
      let newUserReplacement = this.state.newUserReplacement;
      let index = 0;
      index = this.getDataObjectIndex(eObj.label, newUserReplacement);
      newUserReplacement.splice(index, 1);

      this.setState({ newUserReplacement: newUserReplacement });
    };

    handleSaveReplacement = async () => {
      let selectedUser = this.state.replacementSelectedUsers;
      let newAddUser = this.state.newUserReplacement;
      newAddUser.forEach(x => selectedUser.push(x))

      await this.props.handleSaveReplacement(selectedUser, this.props.role);

      this.setState({
        replacementSelectedUsers: [],
        addUserEmail: '',
        newUserReplacement: [],
        isError: false,
        errorMessage: "",
        isErrorInDropdown: false,
      });
    }

    hideReplacementModal = () => {
      this.setState({
        replacementSelectedUsers: [],
        addUserEmail: '',
        newUserReplacement: [],
        isError: false,
        errorMessage: "",
        isErrorInDropdown: false,
      });
      this.props.hideReplacementModal();
    };

    render() {
      return (
        <ReplacementModalPresentational
          show={this.props.show}
          title={this.props.title}
          size={this.props.size}
          onCloseModal={this.props.onCloseModal}
          customclass={this.props.customclass}
          enforceFocus={IsValid(this.props.enforceFocus) ? this.props.enforceFocus : true}
          replacementUserList={this.props.replacementUserList}
          handleSaveReplacement={this.handleSaveReplacement}
          addUserEmail={this.state.addUserEmail}
          replacementSelectedUsers={this.state.replacementSelectedUsers}
          newUserReplacement={this.state.newUserReplacement}
          handleSetReplacementUsers={this.handleSetReplacementUsers}
          onChangeAddUser={this.onChangeAddUser}
          onAddUserEmail={this.onAddUserEmail}
          onRemoveUserEmail={this.onRemoveUserEmail}
          hideReplacementModal={this.hideReplacementModal}
          isError={this.state.isError}
          isErrorInDropdown = {this.state.isErrorInDropdown}
          isUserValid={this.state.isUserValid}
          errorMessage={this.state.errorMessage}
          isSingle={this.props.isSingle}
          role={this.props.role}
        />
      );
    }
  }

  return ReplacementModalContainer;
})();

export default ReplacementModal;

ReplacementModal.defaultProps = {
  isSingle: false
}