import React, { Component } from 'react';

require('./SwitchToggle.css');

const SwitchToggle = (function () {
  class SwitchTogglePresentational extends Component {
    render() {      
      const {
        customClass, 
        isDisabled, 
        controllerClick, 
        id, 
        toggleState
      } = this.props;
      
      return (
        <div 
            id="custom-toggle" 
            data-testid="custom-toggle" 
            className={`${customClass} ${isDisabled ? 'disabled' : ''}`}
        >
            <label class="mini-toggle-switch">
                <input 
                    type="checkbox" 
                    onChange={controllerClick} 
                    data-testid={id} 
                    id={id} 
                    checked={toggleState ? 'checked' : ''} 
                    disabled={isDisabled}
                />
                <span 
                    data-testid="switch_toggle_click" 
                    id="switch_toggle_click" 
                    class="mini-toggle-slider"
                >
                    <div class="label16-regular-midnight"></div>
                </span>
            </label>
        </div>
      );
    }
  }

  class SwitchToggleContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        toggleState: false,
      };
    }

    componentDidMount = () => {
        this.setState({
            toggleState: this.props.defaultValue
        })
    }

    componentDidUpdate = () => {
        const { defaultValue } = this.props;
        if(defaultValue !== this.state.toggleState) {
            this.setState({
                toggleState: defaultValue
            })
        }
    }

    controllerClick = (e) => {
        const {id, callbackFunction, isDisabled} = this.props;
        const toggleTemp = e.currentTarget.checked;

        const eTemp = {
            id: id,
            value: toggleTemp
        }
        const inValid = callbackFunction(eTemp);

        if(!inValid && !isDisabled) {
            this.setState({
                toggleState: toggleTemp
            })
        }
    };

    render() {
      return (
        <SwitchTogglePresentational
          {...this.props}
          toggleState={this.state.toggleState}
          controllerClick={this.controllerClick}
        />
      );
    }
  }

  return SwitchToggleContainer;
})();

export default SwitchToggle;
