import React, { Component } from 'react';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';

import { GrooveIconSvg, GrooveSelectMenuBasic, GrooveButton, GrooveCheckboxlist } from 'tfo-groove';

import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTruckMonster } from '@fortawesome/free-solid-svg-icons';
import editIcon from './../../Static/images/360cvm/EditIcon.svg';
import deleteIcon from './../../Static/images/360cvm/TrashBin.svg';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import CVMTable from '../Shared/CVMTable/CVMTable';
import CVMModal from '../Shared/CVMModal/CVMModal';
import CVMMultiSelectTextbox from '../Shared/CVMMultiSelectTextbox/CVMMultiSelectTextbox';
import { setToasterParam } from '../../Actions/Shared.Action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './ClientManagementStyles.css';
import { HttpGet, HttpPost, HttpPostText, GetSubMenuId } from '../../Utilities/HTTPUtil';
import { LogUserSiteAction } from '../../Utilities/AccessManagementUtil';
import { CLIENT_MANAGEMENT } from '../../Constants/Modules';
import { fetchClientDetails, unsetClientDetails } from '../../Actions/Dashboard.Action';
import DashboardClientDetails from '../Dashboard/DashboardClientDetails';
import CVMToasterNotifUndo from '../Shared/CVMToasterNotifUndo/CVMToasterNotifUndo';
import { IsValid, IsValidStr, HasProperty, IsValidAccentureEmail } from '../../Utilities/Validations';
import { TryGetNodeAlt } from '../../Utilities/Formatter';
import { isUserIOApprover } from '../../Actions/ClientConfiguration.Action';
import ReplacementModal from './Modals/ReplacementModal';
import { isValid } from 'date-fns';

const ClientManagement = (function () {
  class ClientManagementPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <CVMToasterNotifUndo />
          <CVMModal
            title={'Enable New Client'}
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            size={this.props.size}
            content={
              <ModalContent
                options={this.props.options}
                isEdit={this.props.isEdit}
                onCloseModal={this.props.onCloseModal}
                saveClientManagement={this.props.saveClientManagement}
                onClientChange={this.props.onClientChange}
                onEnableCheck={this.props.onEnableCheck}
                onAddSingleUser={this.props.onAddSingleUser}
                onAddMUTextChange={this.props.onAddMUTextChange}
                onAddMUButton={this.props.onAddMUButton}
                onRemoveopAccLeadsData={this.props.onRemoveopAccLeadsData}
                onRemovecxLeadsData={this.props.onRemovecxLeadsData}
                onRemoveproConfigsData={this.props.onRemoveproConfigsData}
                onRemovebusAdvisorData={this.props.onRemovebusAdvisorData}
                clientManagementErrors={this.props.clientManagementErrors}
                cmErrorMessage={this.props.cmErrorMessage}
                clientManagementProperties={this.props.clientManagementProperties}
                onEnableNewClientClick={this.props.onEnableNewClientClick}
                showDelConfirmationModal={this.props.showDelConfirmationModal}
                onConfirmClose={this.props.onConfirmClose}
                onConfirmDelete={this.props.onConfirmDelete}
                opAccLeads={this.props.opAccLeads}
                cxLeads={this.props.cxLeads}
                proConfig={this.props.proConfig}
                busAdvisor={this.props.busAdvisor}
                dashboardClientDetails={this.props.dashboardClientDetails}
                disableSave={this.props.disableSave}
                invalidAccentureEmail={this.props.invalidAccentureEmail}
                //Basic Details
                clientTypeList={this.props.clientTypeList}
                marketSubMarketUnitList={this.props.marketSubMarketUnitList}
                industriesList={this.props.industriesList}
                selectedClientType={this.props.selectedClientType}
                selectedIndustries={this.props.selectedIndustries}
                selectedMarketUnit={this.props.selectedMarketUnit}
                selectedSubMarketUnit={this.props.selectedSubMarketUnit}
                onChangeClientType={this.props.onChangeClientType}
                onChangeIndustries={this.props.onChangeIndustries}
                onChangeMarketUnit={this.props.onChangeMarketUnit}
                onChangeSubMarketUnit={this.props.onChangeSubMarketUnit}
                basicDetailsErrors={this.props.basicDetailsErrors}
              />
            }
          />
          <div className="search-bar-container">
            <div className="search-bar label14-regular-midnight">
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="large"
                name="search"
                iconStyle="solid"
                iconColor="stat-neutral"
              />
              <input
                type="text"
                name="name"
                placeholder="Search Client Name"
                autocomplete="off"
                id="searchBox"
                maxlength="255"
                onChange={(e) => {
                  this.props.searchFunc(e);
                }}
              />
            </div>
            <GrooveButton
              id="primry-btn-2"
              hasIcon={true}
              type="solid"
              colorClass="stat-alternate"
              text="Enable New Client"
              callbackFunction={this.props.onEnableNewClientClick}
              iconAlignment="right"
              iconSize="small"
              iconName="plus"
              iconStyle="solid"
              iconId="btn-icon-01"
            />
          </div>
          <div className="management-table-container">
            <CVMModal
              title={''}
              show={this.props.showDelConfirmationModal}
              onCloseModal={this.props.onConfirmClose}
              customclass={'select-project-modal'}
              content={
                <WarningModal
                  //redux
                  onCloseModal={this.props.onConfirmClose}
                  onCloseProject={this.props.onConfirmDelete}
                  disableDelete={this.props.disableDelete}
                />
              }
            />
            {this.props.tableProperties.data.length > 0 ? (
              <CVMTable
                tableProperties={this.props.tableProperties}
                getGridApi={this.props.getGridApi}
                filteredData={this.props.filteredData}
              />
            ) : (
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </div>
          <ReplacementModal
            show={this.props.showReplacementModal}
            replacementUserList={this.props.replacementUserList}
            hideReplacementModal={this.props.hideReplacementModal}
            handleSaveReplacement={this.props.handleSaveReplacement}
            replacedUser={this.props.replacedUser}
            role={this.props.removedRole}
            validateMultiSelectUserData={this.props.validateMultiSelectUserData}
            clientManagementProperties={this.props.clientManagementProperties}
          />
        </React.Fragment>
      );
    }
  }

  class ModalContent extends Component {
    render() {
      const checkbox = [
        {
          value: 'Enabled',
          label: 'Enable Client',
          isChecked: this.props.clientManagementProperties.isenabled,
          isDisabled: false,
        },
      ];
      const isErrorList = this.props.clientManagementErrors;
      let errorMessage = [];
      this.props.cmErrorMessage.forEach((f) => {
        errorMessage.push(
          <>
            {f.value}
            <br />
          </>
        );
      });
      let selectedClient = {};
      if (this.props.isEdit)
        selectedClient = {
          values: this.props.clientManagementProperties.clientID,
          label: this.props.clientManagementProperties.clientName,
          text: this.props.clientManagementProperties.clientName,
        };
      else {
        selectedClient = this.props.options.filter(
          (f) => f.value == this.props.clientManagementProperties.clientID
        )[0];
      }
      return (
        <React.Fragment>
          <div className="add-new-program-container">
            <Row>
              <Col lg={12} md={12} sm={12}>
                {this.props.isEdit ? (
                  <GrooveSelectMenuBasic
                    name="selectclient"
                    isDisabled={true}
                    placeholder="Client"
                    list={[selectedClient]}
                    id="client"
                    contentType={'search'}
                    customClassName={isErrorList.clientID ? ' dropdown-invalid' : ''}
                    defaultData={selectedClient}
                  />
                ) : (
                  <GrooveSelectMenuBasic
                    name="selectclient"
                    isDisabled={false}
                    placeholder={selectedClient != null ? 'Client' : 'Select Client'}
                    list={this.props.options}
                    id="client"
                    contentType={'search'}
                    callbackFunction={(e) => {
                      this.props.onClientChange(e);
                    }}
                    customClassName={isErrorList.clientID ? ' dropdown-invalid' : ''}
                    defaultData={selectedClient}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveCheckboxlist
                  id="enableClient"
                  checkboxlistData={checkbox}
                  isDisabled={false}
                  callbackFunction={(e) => {
                    this.props.onEnableCheck(e);
                  }}
                />
              </Col>
            </Row>
            <div className="divider-horizontal"></div>
            <Accordion className="configure-client-collapse" defaultActiveKey="0">
              <Card>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row>
                      <Col sm={12}>
                        <div className="admin-client-details">
                          {this.props.dashboardClientDetails !== undefined &&
                            Object.keys(this.props.dashboardClientDetails).length > 0 && (
                              <DashboardClientDetails />
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} sm={6}>
                        <GrooveSelectMenuBasic
                          name="clientType"
                          // isDisabled={this.props.editMode || this.props.readOnly}
                          placeholder={
                            this.props.selectedClientType.value
                              ? 'Client Type'
                              : 'Select Client Type'
                          }
                          list={this.props.clientTypeList}
                          defaultData={this.props.selectedClientType}
                          id="clientType"
                          callbackFunction={(e) => this.props.onChangeClientType(e)}
                          isRequired={true}
                          customClassName={
                            this.props.basicDetailsErrors.clientType ? ' dropdown-invalid' : ''
                          }
                        />
                      </Col>
                      <Col lg={6} md={6} sm={6}>
                        <GrooveSelectMenuBasic
                          name="industry"
                          // isDisabled={this.props.editMode || this.props.readOnly}
                          placeholder={
                            this.props.selectedIndustries.value ? 'Industry' : 'Select Industry'
                          }
                          list={this.props.industriesList}
                          defaultData={this.props.selectedIndustries}
                          id="industry"
                          callbackFunction={(e) => this.props.onChangeIndustries(e)}
                          isRequired={true}
                          customClassName={
                            this.props.basicDetailsErrors.industries ? ' dropdown-invalid' : ''
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} sm={6}>
                        <GrooveSelectMenuBasic
                          name="marketUnit"
                          // isDisabled={this.props.editMode || this.props.readOnly}
                          placeholder={
                            this.props.selectedMarketUnit.value
                              ? 'Market Unit'
                              : 'Select Market Unit'
                          }
                          list={this.props.marketSubMarketUnitList.map((x) => {
                            return {
                              label: x.label,
                              value: x.value,
                              text: x.label,
                            };
                          })}
                          defaultData={this.props.selectedMarketUnit}
                          id="marketUnit"
                          callbackFunction={(e) => this.props.onChangeMarketUnit(e)}
                          isRequired={true}
                          customClassName={
                            this.props.basicDetailsErrors.marketUnit ? ' dropdown-invalid' : ''
                          }
                        />
                      </Col>
                      <Col lg={6} md={6} sm={6}>
                        <GrooveSelectMenuBasic
                          name="region"
                          // isDisabled={this.props.editMode || this.props.readOnly}
                          placeholder={
                            this.props.selectedSubMarketUnit !== null &&
                              this.props.selectedSubMarketUnit !== undefined
                              ? Object.keys(this.props.selectedSubMarketUnit).length > 0
                                ? 'Sub-Market Unit'
                                : 'Select Sub-Market Unit'
                              : 'Select Sub-Market Unit'
                          }
                          list={
                            this.props.marketSubMarketUnitList
                              .filter((x) => x.value === this.props.selectedMarketUnit.value)
                              .map((x) => x.SubMarketUnits)[0] || []
                          }
                          defaultData={this.props.selectedSubMarketUnit}
                          id="region"
                          callbackFunction={(e) => this.props.onChangeSubMarketUnit(e)}
                          isRequired={true}
                          customClassName={
                            this.props.basicDetailsErrors.subMarketUnit ? ' dropdown-invalid' : ''
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <div className="bodytext18-medium-slate">Basic Details</div>
                    <GrooveIconSvg
                      id="configureProgram"
                      customClassName="accordion-button"
                      size="large"
                      name="chevron-down"
                      iconStyle="solid"
                      iconColor="stat-neutral"
                    />
                  </Accordion.Toggle>
                </Card.Header>
              </Card>
            </Accordion>
            <Accordion className="configure-client-collapse" defaultActiveKey="0">
              <Card>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <CVMMultiSelectTextbox
                          placeholder="Enter Operations Account Lead *"
                          id="opAccLeads"
                          name="opAccLeads"
                          addButton={() => {
                            this.props.onAddMUButton('opAccLeads');
                          }}
                          onRemoveSelected={(e) => {
                            this.props.onRemoveopAccLeadsData(e);
                          }}
                          onTextChange={(e) => {
                            this.props.onAddMUTextChange(e);
                          }}
                          list={TryGetNodeAlt(
                            this.props.clientManagementProperties,
                            [],
                            'opAccLeads'
                          )}
                          customClassName={isErrorList.opAccLeads ? 'dropdown-invalid' : ''}
                          inputValue={this.props.opAccLeads}
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <CVMMultiSelectTextbox
                          placeholder="Enter CX Lead *"
                          id="cxLead"
                          name="cxlead"
                          addButton={() => {
                            this.props.onAddMUButton('cxLead');
                          }}
                          onRemoveSelected={(e) => {
                            this.props.onRemovecxLeadsData(e);
                          }}
                          onTextChange={(e) => {
                            this.props.onAddMUTextChange(e);
                          }}
                          list={TryGetNodeAlt(this.props.clientManagementProperties, [], 'cxLeads')}
                          customClassName={isErrorList.cxLeads ? 'dropdown-invalid' : ''}
                          inputValue={this.props.cxLeads}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col id="proConfigCol" lg={6} md={12} sm={12}>
                        <CVMMultiSelectTextbox
                          placeholder="Enter Program & Project Configurator*"
                          id="proConfig"
                          name="proConfig"
                          addButton={() => {
                            this.props.onAddMUButton('proConfig');
                          }}
                          onRemoveSelected={(e) => {
                            this.props.onRemoveproConfigsData(e);
                          }}
                          onTextChange={(e) => {
                            this.props.onAddMUTextChange(e);
                          }}
                          list={TryGetNodeAlt(
                            this.props.clientManagementProperties,
                            [],
                            'proConfig'
                          )}
                          customClassName={isErrorList.proConfig ? 'dropdown-invalid' : ''}
                          inputValue={this.props.proConfig}
                        />
                      </Col>
                      <Col id="busAdvisor" lg={6} md={12} sm={12}>
                        <CVMMultiSelectTextbox
                          placeholder="Enter Business Advisor"
                          id="busAdvisor"
                          name="busAdvisor"
                          addButton={() => {
                            this.props.onAddMUButton('busAdvisor');
                          }}
                          onRemoveSelected={(e) => {
                            this.props.onRemovebusAdvisorData(e);
                          }}
                          onTextChange={(e) => {
                            this.props.onAddMUTextChange(e);
                          }}
                          list={TryGetNodeAlt(
                            this.props.clientManagementProperties,
                            [],
                            'busAdvisor'
                          )}
                          customClassName={isErrorList.busAdvisor ? 'dropdown-invalid' : ''}
                          inputValue={this.props.busAdvisor}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <div className="bodytext18-medium-slate">Client Stakeholders</div>
                    <GrooveIconSvg
                      id="configureProgram"
                      customClassName="accordion-button"
                      size="large"
                      name="chevron-down"
                      iconStyle="solid"
                      iconColor="stat-neutral"
                    />
                  </Accordion.Toggle>
                </Card.Header>
              </Card>
            </Accordion>
          </div>
          <div className="add-program-buttons enable-new-client">
            <div>
              <p class="missing">{errorMessage[errorMessage.length - 1]}</p>
            </div>
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={(e) => {
                this.props.onCloseModal(e);
              }}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={this.props.disableSave || this.props.invalidAccentureEmail}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Save"
              callbackFunction={this.props.saveClientManagement}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class BtnCellRenderer extends Component {
    constructor(props) {
      super(props);
      this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
      this.btnDeleteClickedHandler = this.btnDeleteClickedHandler.bind(this);
    }
    btnEditClickedHandler() {
      this.props.edit(this.props.data);
    }
    btnDeleteClickedHandler() {
      this.props.delete(this.props.data);
    }

    render() {
      return (
        <div className="table-action-buttons">
          <img
            className="edit-btn"
            onClick={this.btnEditClickedHandler}
            src={editIcon}
            alt="edit"
          />
          <img
            className="delete-btn"
            onClick={this.btnDeleteClickedHandler}
            src={deleteIcon}
            alt="delete"
          />
        </div>
      );
    }
  }

  class ClientManagementContainer extends Component {
    constructor(props) {
      super(props);
      this.getDetailsofClientManagement = this.getDetailsofClientManagement.bind(this);
      this.deleteClientManagementData = this.deleteClientManagementData.bind(this);
      this.state = {
        options: [],
        tableProperties: {
          columns: [
            { field: 'client', headerName: 'Client', tooltipField: 'client' },
            {
              field: 'isenabled',
              headerName: 'Is Enabled',
              tooltipField: 'isenabled',
              suppressFilter: true,
              getQuickFilterText: () => '',
            },
            {
              field: 'actions',
              headerName: 'Actions',
              cellRenderer: 'btnCellRenderer',
              cellRendererParams: {
                edit: (field) => {
                  this.getDetailsofClientManagement(field.Id);
                  this.props.fetchClientDetails(field.Id);
                },
                delete: (field) => {
                  this.deleteClientManagementData(field.Id);
                },
              },
            },
          ],
          cellRenderer: {
            btnCellRenderer: BtnCellRenderer,
          },
          data: [],
          pagination: true,
          paginationPageSize: 10,
          sortable: true,
        },
        show: false,
        size: 'md',
        gridApi: () => { },
        isEdit: false,
        isChangedInForm: false,
        clientManagementProperties: {
          clientID: 0,
          clientName: '',
          isenabled: false,
          opAccLeads: [],
          cxLeads: [],
          proConfig: [],
          busAdvisor: [],
        },
        opAccLeads: '',
        cxLeads: '',
        proConfig: '',
        busAdvisor: '',
        clientManagementErrors: {
          clientID: false,
          cxLeads: false,
          opAccLeads: false,
          proConfig: false,
          busAdvisor: false,
        },
        cmErrorMessage: [],
        showDelConfirmationModal: false,
        deleteID: 0,
        allStakeHolders: [],
        clientTypeList: [],
        marketSubMarketUnitList: [],
        industriesList: [],
        selectedClientType: {},
        selectedIndustries: {},
        selectedMarketUnit: {},
        selectedSubMarketUnit: {},
        basicDetailsErrors: {
          clientType: false,
          industries: false,
          marketUnit: false,
          subMarketUnit: false,
        },
        filteredData: [],
        disableSave: false,
        invalidAccentureEmail: false,
        disableDelete: false,
        disableAdd: false,
        showReplacementModal: false,
        replacementUserList: [],
        replacedUser: {},
        removedRole: '',

        deletedProfiles: {
          opAccLeads: [],
          cxLeads: [],
          proConfig: [],
          busAdvisor: [],
        }
      };
    }

    componentWillMount() {
      this.initialCall();
    }
    componentDidMount() {
      LogUserSiteAction({ module: CLIENT_MANAGEMENT });
    }

    closeDelConfirmationModal = () => {
      this.setState({
        ...this.state,
        showDelConfirmationModal: !this.state.showDelConfirmationModal,
      });
    };

    onCloseModal = () => {
      if (this.state.show) {
        this.props.unsetClientDetails();
      }
      this.setState({
        ...this.state,
        show: !this.state.show,
        invalidAccentureEmail: false,
      });
    };

    getGridApi = (api) => {
      this.setState({
        gridApi: api,
      });
    };

    onSearchTextChanged = (e) => {
      let filteredData = this.state.tableProperties.data.filter((x) =>
        x.client.toLowerCase().includes(e.target.value.toLowerCase())
      );

      if (IsValidStr(e.target.value)) {
        this.setState({
          filteredData: filteredData,
        });
      } else {
        this.setState({
          filteredData: this.state.tableProperties.data,
        });
      }

      this.state.gridApi.setQuickFilter(e.target.value);
    };

    handleErrorMessage = (eType, isError = true, isModalValidation = false) => {
      let cmErrorMessage = this.state.cmErrorMessage;
      let errorMessage = [];
      let msgValue = {};
      switch (eType) {
        case 1:
          msgValue = { key: 'req', value: 'Missing Required Field' };
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (cmErrorMessage.filter((f) => f.key == 'req').length == 0)
              cmErrorMessage.push(msgValue);

          break;
        case 2:
          msgValue = { key: 'invalidEmail', value: 'Invalid Email' };
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (
              cmErrorMessage.filter((f) => f.key == msgValue.key).length == 0 &&
              cmErrorMessage.filter((f) => f.key == 'accentureValid').length == 0
            )
              cmErrorMessage.push(msgValue);
          break;
        case 3:
          msgValue = {
            key: 'accentureValid',
            value: "Valid email ID should have '@accenture.com'",
          };
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (
              cmErrorMessage.filter((f) => f.key == 'invalidEmail').length == 0 &&
              cmErrorMessage.filter((f) => f.key == msgValue.key).length == 0
            )
              cmErrorMessage.push(msgValue);
          break;
        case 4:
          msgValue = { key: 'sameProfile', value: 'User already added to a different profile.' };
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (cmErrorMessage.filter((f) => f.key == 'sameProfile').length == 0)
              cmErrorMessage.push(msgValue);
          break;
        case 5:
          msgValue = { key: 'req', value: 'Atleast 2 users required' };
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (cmErrorMessage.filter((f) => f.key == 'req').length == 0)
              cmErrorMessage.push(msgValue);
          break;
        case 6:
          msgValue = {
            key: 'qcioWithPPCBAProfle',
            value: 'The user you are trying to add is already mapped as an Approver. Please update the user access using User Management module.',
          };
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (cmErrorMessage.filter((f) => f.key === 'qcioWithPPCBAProfle').length === 0)
              cmErrorMessage.push(msgValue);
          break;

        case 7:
          msgValue = {
            key: 'notAcnDomain',
            value: 'One or more email ids are invalid. Valid email id should have @accenture.com'
          }
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (
              cmErrorMessage.filter((f) => f.key == 'notAcnDomain').length == 0 &&
              cmErrorMessage.filter((f) => f.key == msgValue.key).length == 0
            )
              cmErrorMessage.push(msgValue);
          break;
        case 8:
          msgValue = {
            key: 'Valid',
            value: 'Invalid Accenture ID',
          };
          if (isModalValidation) errorMessage.push(msgValue)
          else
            if (!isError) cmErrorMessage.splice(cmErrorMessage.indexOf(msgValue), 1);
            else if (cmErrorMessage.filter((f) => f.key === 'Valid').length === 0)
              cmErrorMessage.push(msgValue);
          break;
      }
      if (isModalValidation === false) {
        this.setState({ cmErrorMessage: cmErrorMessage });
        return '';
      } else {
        return errorMessage;
      }
    };

    validateAllEmailIds = async (value) => {
      let emailsValid = true;
      let allInvalidEmails = [];
      const opAccLeads = this.state.clientManagementProperties.opAccLeads.map(item => item.label);
      const cxLeads = this.state.clientManagementProperties.cxLeads.map(item => item.label);
      const proConfig = this.state.clientManagementProperties.proConfig.map(item => item.label);
      const busAdvisor = this.state.clientManagementProperties.busAdvisor.map(item => item.label);

      const response = value ? 
                        await IsValidAccentureEmail([...opAccLeads, ...cxLeads, ...proConfig, ...busAdvisor, value]) :
                        await IsValidAccentureEmail([...opAccLeads, ...cxLeads, ...proConfig, ...busAdvisor]);

      for (let i = 0; i < response.length; i++) {
        if (!response[i].IsValid) {
          emailsValid = false;   
          allInvalidEmails.push(response[i].UserName.split('@')[0]);
        }
      }

      return {
        emailsValid,
        allInvalidEmails
      };
    }

    validtionClientManagement = async () => 
    {
      let isValid = true;
      let errors = this.state.clientManagementErrors;
      const data = this.state.clientManagementProperties;
      let cmErrorMessage = this.state.cmErrorMessage;
      if (data.clientID == 0) {
        isValid = false;
        errors.clientID = true;
        this.handleErrorMessage(1);
      } else {
        errors.clientID = false;
        this.handleErrorMessage(1, false);
      }

      // if (data.operationACLeads === ""){
      //   isValid = false;
      //   errors.operationACLeads = true;
      //   this.handleErrorMessage(1)
      // }
      // else{
      //   errors.operationACLeads = false;
      //   this.handleErrorMessage(1, false)
      // }

      if (!this.validateMultiEmail(data.opAccLeads)) {
        isValid = false;
        errors.opAccLeads = true;
      } else {
        errors.opAccLeads = false;
      }

      if (!this.validateMultiEmail(data.cxLeads)) {
        isValid = false;
        errors.cxLeads = true;
      } else {
        errors.cxLeads = false;
      }

      if (!this.validateMultiEmailProConfig(data.proConfig)) {
        isValid = false;
        errors.proConfig = true;
      } else {
        errors.proConfig = false;
      }

      if (!this.validateBasicDetails(isValid)) isValid = false;

      if (this.state.isChangedInForm) {
        let {emailsValid, allInvalidEmails} = await this.validateAllEmailIds();
        if (!emailsValid) {
          isValid = false;
          cmErrorMessage = [{
            key: 'Valid',
            value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}`,
          }];
        }
      }
      
      if (isValid) cmErrorMessage = [];

      this.setState({
        clientManagementErrors: errors,
        invalidAccentureEmail: cmErrorMessage.length > 0 ? cmErrorMessage[0].key === 'Valid' : false,
        cmErrorMessage,
      });

      return isValid;
    };

    validateMultiEmail = (value) => {
      let isValid = true;
      if (value.length == null || value.length == 0) {
        isValid = false;
        this.handleErrorMessage(1);
      }
      return isValid;
    };

    validateMultiEmailProConfig = (value) => {
      let isValid = true;
      if (value.length == null || value.length == 0 || value.length < 2) {
        isValid = false;
        this.handleErrorMessage(5);
      }
      return isValid;
    };

    validateEmailIOApprover = async (value) => {
      let isValid = true;
      value = value.trim();

      let isIOApprover = await this.props.isUserIOApprover(value);
      if (!IsValid(value) || value.length === 0 || isIOApprover) {
        isValid = false;
        this.handleErrorMessage(6);
      }
      return isValid;
    };

    validateUserQCwithOtherProfile = async (email, role) => {
      try {
        let subMenuId = GetSubMenuId("Client Management")
        const profileValidation = await HttpGet(
          `User/GetUserMultipleProfileValidation?userEmailID=${email}&SubMenuID=${subMenuId}`
        );
        const hasQCProfile = profileValidation.filter((x) => x.ProfileID === 16).length > 0;
        let sameProfileAsExisting = false; //User should only be allowed to be added on the profile that he already has if he is also a QC.
        if (hasQCProfile && profileValidation.length > 1) {
          switch (role) {
            case 'opAccLeads':
              sameProfileAsExisting = profileValidation.filter((x) => x.ProfileID == 4).length > 0;
              break;
            case 'cxLead':
              sameProfileAsExisting = profileValidation.filter((x) => x.ProfileID == 7).length > 0;
              break;
            case 'proConfig':
              sameProfileAsExisting = profileValidation.filter((x) => x.ProfileID == 12).length > 0;
              break;
            case 'busAdvisor':
              sameProfileAsExisting = profileValidation.filter((x) => x.ProfileID == 15).length > 0;
              break;
          }
        } else {
          return false;
        }
        return !sameProfileAsExisting;
      } catch (e) {
        console.log(e);
      }
    };

    validateUserIOQC = async (email, role) => {
      try {
        let subMenuId = GetSubMenuId("Client Management")
        const profileValidation = await HttpGet(
          `User/GetUserMultipleProfileValidation?userEmailID=${email}&SubMenuID=${subMenuId}`
        );
        const hasIOProfile = profileValidation.filter((x) => x.ProfileID === 8).length > 0;
        const hasQCProfile = profileValidation.filter((x) => x.ProfileID === 16).length > 0;
        let error = false;
        if (profileValidation.length >= 1 && (hasIOProfile || hasQCProfile)) {
          if (role.toLowerCase() === 'opaccleads' || role.toLowerCase() === 'cxlead') {
            error = false;
          } else {
            error = true;
          }
        }
        return error;
      } catch (e) {
        console.log(e);
      }
    };

    validateEmailAddress = async (value, hasNoError, role, isModalValidation = false) => {
      let isValid = { isValid: true, errorMessage: [] };

      if (value !== null && value !== '') {
        //has value
        if (hasNoError) {
          isValid.errorMessage = this.handleErrorMessage(1, false, isModalValidation);
          var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (!re.test(value.trim())) {
            //no @ & .
            isValid.isValid = false;
            isValid.errorMessage = this.handleErrorMessage(2, true, isModalValidation);
          } else {
            if (hasNoError) {
              isValid.errorMessage = this.handleErrorMessage(2, false, isModalValidation);

              if (!value.toLowerCase().trim().endsWith('@accenture.com')) {
                //not accenture email
                isValid.isValid = false;
                isValid.errorMessage = this.handleErrorMessage(3, true, isModalValidation);
              } else {
                if (hasNoError) {
                  isValid.errorMessage = this.handleErrorMessage(3, false, isModalValidation);

                  let emailCount = value.toLowerCase().split('@');
                  emailCount = emailCount.filter((r) => r.trim() != '');
                  if (emailCount.length > 2) {
                    isValid.isValid = false;
                    isValid.errorMessage = this.handleErrorMessage(2, true, isModalValidation);
                  } else {
                    if (hasNoError) {
                      isValid.errorMessage = this.handleErrorMessage(2, false, isModalValidation);

                      if (!this.validateDuplicateEmail(value, 0, role)) {
                        //check email duplication (based on role)
                        isValid.isValid = false;
                        isValid.errorMessage = this.handleErrorMessage(4, true, isModalValidation);
                      } else {
                        if (hasNoError) {
                          isValid.errorMessage = this.handleErrorMessage(4, false, isModalValidation);

                          // check if email entered is valid accenture email
                          const data = await IsValidAccentureEmail([ value ]);
                          if (!data[0].IsValid) {
                            isValid.isValid = false;
                            isValid.errorMessage = this.handleErrorMessage(8,true,isModalValidation);
                          } else {
                            if (hasNoError) {
                              isValid.errorMessage = this.handleErrorMessage(8, false, isModalValidation);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        isValid.isValid = false;
        isValid.errorMessage = this.handleErrorMessage(1, true, isModalValidation);
      }
      return isValid;
    };

    validateEmailAddressClientAdmin = (value, hasNoError) => {
      let isValid = true;
      if (value != null && value != '') {
        if (hasNoError) this.handleErrorMessage(1, false);
        var re = /.+@.+/;
        if (!re.test(value)) {
          isValid = false;
          this.handleErrorMessage(2);
        } else {
          if (hasNoError) this.handleErrorMessage(2, false);
          if (!value.toLowerCase().endsWith('@accenture.com')) {
            isValid = false;
            this.handleErrorMessage(3);
          } else {
            if (hasNoError) this.handleErrorMessage(3, false);

            let emailCount = value.toLowerCase().split('@');
            emailCount = emailCount.filter((r) => r.trim() != '');
            if (emailCount.length > 2) {
              isValid = false;
              this.handleErrorMessage(2);
            } else {
              if (hasNoError) this.handleErrorMessage(2, false);
              if (this.validateDuplicateEmail(value, 0)) {
                if (hasNoError) this.handleErrorMessage(4, false);
              } else {
                isValid = false;
                this.handleErrorMessage(4);
              }
            }
          }
        }
      }
      return isValid;
    };

    validateDuplicateEmail = (email, idFlag, role) => {
      idFlag = idFlag == undefined ? 0 : idFlag;
      let isValid = true;
      const stateProperies = { ...this.state.clientManagementProperties };
      let emailList = [];
      role = role.toLowerCase();

      if (IsValid(stateProperies.opAccLeads))
        if (stateProperies.opAccLeads.length > 0)
          stateProperies.opAccLeads.forEach((f) => emailList.push({ ...f, role: 'opAccLeads' }));
      if (IsValid(stateProperies.cxLeads))
        if (stateProperies.cxLeads.length > 0)
          stateProperies.cxLeads.forEach((f) => emailList.push({ ...f, role: 'cxLead' }));
      if (IsValid(stateProperies.proConfig))
        if (stateProperies.proConfig.length > 0)
          stateProperies.proConfig.forEach((f) => emailList.push({ ...f, role: 'proConfig' }));
      if (IsValid(stateProperies.busAdvisor))
        if (stateProperies.busAdvisor.length > 0)
          stateProperies.busAdvisor.forEach((f) => emailList.push({ ...f, role: 'busAdvisor' }));

      if (emailList.length > 0) {
        const filteredEmails = emailList.filter(
          (x) => x.value.toLowerCase() === email.trim().toLowerCase()
        );
        if (filteredEmails.length === 0) {
          //no same email
          isValid = true;
        } else if (filteredEmails.length > 1) {
          //more than 1 same email
          isValid = false;
        } else if (filteredEmails.length === 1) {
          //same email only 1
          const sameEmail = filteredEmails[0].role;
          if (role === 'opaccleads' || role === 'cxlead') {
            //if not BA or PPC, no duplicate should be allowed
            isValid = false;
          } else if (role === 'busadvisor' && sameEmail.toLowerCase() === 'proconfig') {
            isValid = true;
          } else if (role === 'proconfig' && sameEmail.toLowerCase() === 'busadvisor') {
            isValid = true;
          } else {
            //same roles for same email
            isValid = false;
          }
        }
      } else {
        //first entry of email in list
        isValid = true;
      }
      return isValid;
    };

    addUsersIntoProfile = (dataList, valueList, deletedList, profileID) => {
      let users = [];
      let deletedUsers = [];

      if (valueList != null && valueList.length > 0 && dataList != null) {
        valueList.forEach(x => {
          let eid = x.value.split('@')[0];
          users.push({
            userEmailID: x.value,
            userName: eid,
            userEnterpriseID: eid,
            clientProgramID: x.clientProgramID,
            clientProjectID: x.clientProjectID
          })
        })
      }
      if (deletedList != null && deletedList.length > 0 && dataList != null) {
        deletedList.forEach(x => {
          let eid = x.value.split('@')[0];
          deletedUsers.push({
            userEmailID: x.value,
            userName: eid,
            userEnterpriseID: eid,
            clientProgramID: x.clientProgramID,
            clientProjectID: x.clientProjectID,
            newUsers: x.newUsers
          })
        })
      }

      dataList.push({
        profileID: profileID,
        users: users,
        removedUsers: deletedUsers
      });

      return dataList;
    };

    saveClientManagement = async () => {
      this.setState({
        disableSave: true,
      }, async () => {
        if (await this.validtionClientManagement()) {
          const stateData = this.state.clientManagementProperties;
          const deletedData = this.state.deletedProfiles;
          let bodyParam = {
            id: stateData.id,
            clientMasterId: stateData.clientID,
            isActive: stateData.isenabled,
            industryID: this.state.selectedIndustries.value,
            clientTypeID: this.state.selectedClientType.value,
            marketID: this.state.selectedMarketUnit.value,
            subMarketUnitID: this.state.selectedSubMarketUnit.value,
            userProfiles: [],
          };

          bodyParam.userProfiles = this.addUsersIntoProfile(
            bodyParam.userProfiles,
            stateData.opAccLeads,
            deletedData.opAccLeads,
            4
          );

          bodyParam.userProfiles = this.addUsersIntoProfile(
            bodyParam.userProfiles,
            stateData.cxLeads,
            deletedData.cxLeads,
            7
          );
          bodyParam.userProfiles = this.addUsersIntoProfile(
            bodyParam.userProfiles,
            stateData.proConfig,
            deletedData.proConfig,
            12
          );
          bodyParam.userProfiles = this.addUsersIntoProfile(
            bodyParam.userProfiles,
            stateData.busAdvisor,
            deletedData.busAdvisor,
            15
          );

          const usersToClearCache = stateData.cxLeads.concat(
            stateData.opAccLeads,
            stateData.proConfig,
            this.state.allStakeHolders
          );

          bodyParam = {
            ClientConfig: bodyParam,
            usersToClearCache: [
              ...new Set(
                usersToClearCache.map((e) => {
                  return e.value;
                })
              ),
            ],
          };
          let subMenuId = GetSubMenuId("Client Management")
          let data = await HttpPostText(`ClientConfiguration/AddEditClientConfig?SubMenuID=${subMenuId}`, bodyParam);
          if (data === 'success') {
            this.onCloseModal();
            let returnMsg = 'New Client has been enabled successfully!';
            if (this.state.isEdit) {
              returnMsg = 'Client has been updated successfully!';
            }
            this.props.setToasterParam({
              show: true,
              message: returnMsg,
              type: 'success',
            });

            this.initialCall();

            this.setState({
              invalidAccentureEmail: false,
            })
          } else {
            data = JSON.parse(data)
            if (HasProperty(data, 'Code') && data.Code === "2") {
              this.handleErrorMessage(7, true, false)
            }
          }
        }
        this.setState({
          disableSave: false,
          deletedProfiles: {
            opAccLeads: [],
            cxLeads: [],
            proConfig: [],
            busAdvisor: [],
          },
          isChangedInForm: false,
        })
      })

    };

    onClientChange = (e) => {
      this.props.fetchClientDetails('new');
      this.setState({
        clientManagementProperties: { ...this.state.clientManagementProperties, clientID: e.value },
      },()=>{
        this.state.cmErrorMessage.length!=0&&this.validtionClientManagement();
      });
    };

    onEnableCheck = (e) => {
      this.setState({
        clientManagementProperties: {
          ...this.state.clientManagementProperties,
          isenabled: !this.state.clientManagementProperties.isenabled,
        },
      });
      if(this.state.isEdit) this.setState({
          isChangedInForm: true,
      });
    };

    onAddSingleUser = (e) => {
      let clientManagementProperties = this.state.clientManagementProperties;
      switch (e.id) {
        case 'clientAdmin':
          clientManagementProperties.clientAdmins = e.value;
          break;
      }

      this.setState({
        clientManagementProperties,
      });
    };

    onAddMUTextChange = (e) => {
      if (e.value !== null && e.value !== '') {
        switch (e.id) {
          case 'opAccLeads':
            this.setState({
              opAccLeads: e.value,
            });
            break;
          case 'cxLead':
            this.setState({
              cxLeads: e.value,
            });
            break;
          case 'proConfig':
            this.setState({
              proConfig: e.value,
            });
            break;
          case 'busAdvisor':
            this.setState({
              busAdvisor: e.value,
            });
            break;
        }
      }
    };

    validateMultiSelectUserData = async (dataList, value, role, isModalValidation = false) => {
      let returnData = { isError: false, listData: [], errorMessage: [] };
      let validateEmail = {};
      if (value != null && value.length > 0) {
        returnData.errorMessage =this.handleErrorMessage(1, false, isModalValidation);
        returnData.isError = true;
        validateEmail = await this.validateEmailAddress(value, true, role, isModalValidation)
        if (validateEmail.isValid === false) {
          returnData.errorMessage = validateEmail.errorMessage;
          returnData.isError = true;
        } else {
          //no error when when email is validated
          returnData.errorMessage = this.handleErrorMessage(2, false, isModalValidation);
          if (value !== null) {
            returnData.isError = false;
            returnData.errorMessage = this.handleErrorMessage(4, false, isModalValidation);
            let validateIOQC = await this.validateUserIOQC(value, role);
            if (validateIOQC) {
              returnData.isError = true;
              returnData.errorMessage = this.handleErrorMessage(6, true, isModalValidation);
            } else {
              if (!returnData.isError && !isModalValidation) {
                dataList.push({
                  value: value,
                  label: value,
                  clientProgramID: null,
                  clientProjectID: null,
                  isNew: true
                });
              }
            }
          } else {
            returnData.isError = true;
            returnData.errorMessage = this.handleErrorMessage(4, true, isModalValidation);
          }
        }
      } else {
        returnData.isError = true;
        returnData.errorMessage = this.handleErrorMessage(1, true, isModalValidation);
      }
      
      if(this.state.clientManagementProperties.cxLeads.length<1||this.state.clientManagementProperties.opAccLeads.length<1||this.state.clientManagementProperties.proConfig.length<1){
         //returnData.isError=true;
         returnData.errorMessage = this.handleErrorMessage(1, true, isModalValidation);
       }

      returnData.listData = dataList;

      return returnData;
    };

    onAddMUButton = (id) => {
      if(this.state.isEdit) this.setState({
          isChangedInForm: true,
      });
      if (!this.state.disableAdd) {
        this.setState({ disableAdd: true }, async () => {
          let data = this.state.clientManagementProperties;
          let errors = this.state.clientManagementErrors;
          let returnData = {};
          let opAccLeads = this.state.opAccLeads.split(' ').join('').trim();
          let cxLeads = this.state.cxLeads.split(' ').join('').trim();
          let proConfig = this.state.proConfig.split(' ').join('').trim();
          let busAdvisor = this.state.busAdvisor.split(' ').join('').trim();
          switch (id) {
            case 'opAccLeads':
              returnData = await this.validateMultiSelectUserData(
                data.opAccLeads,
                this.state.opAccLeads.split(' ').join('').trim(),
                id
              );
              data.opAccLeads = returnData.listData;
              errors.opAccLeads = returnData.isError;

              if (!returnData.isError) opAccLeads = '';
              else {
                errors.opAccLeads = true;
                opAccLeads = '';
              }
              break;
            case 'cxLead':
              returnData = await this.validateMultiSelectUserData(data.cxLeads, this.state.cxLeads.split(' ').join('').trim(), id);
              data.cxLeads = returnData.listData;
              errors.cxLeads = returnData.isError;
              if (!returnData.isError) cxLeads = '';
              else {
                errors.cxLeads = true;
                cxLeads = '';
              }
              break;
            case 'proConfig':
              returnData = await this.validateMultiSelectUserData(
                data.proConfig,
                this.state.proConfig.split(' ').join('').trim(),
                id
              );
              data.proConfig = returnData.listData;
              errors.proConfig = returnData.isError;
              if (!returnData.isError) proConfig = '';
              else {
                errors.proConfig = true;
                proConfig = '';
              }
              break;
            case 'busAdvisor':
              returnData = await this.validateMultiSelectUserData(
                data.busAdvisor,
                this.state.busAdvisor.split(' ').join('').trim(),
                id
              );
              data.busAdvisor = returnData.listData;
              errors.busAdvisor = returnData.isError;
              if (!returnData.isError) busAdvisor = '';
              else {
                errors.busAdvisor = true;
                busAdvisor = '';
              }
              break;
          }

          const {emailsValid, allInvalidEmails} = await this.validateAllEmailIds();
          if (!emailsValid) {
            this.setState({
              cmErrorMessage: [{
                  key: 'Valid',
                value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}`,
              }],
            })
          }

          this.setState({
            clientManagementProperties: data,
            clientManagementErrors: errors,
            opAccLeads,
            cxLeads,
            proConfig,
            busAdvisor,
            disableAdd: false
          });
        })
      }
    };

    onRemoveopAccLeadsData = async (eObj) => {
      let clientManagementProperties = this.state.clientManagementProperties;
      let replacedUsersList = [];
      let index = 0;
      index = this.getClientDataObjectIndex(eObj.label, clientManagementProperties.opAccLeads);

      if (
        clientManagementProperties.opAccLeads[index].clientProgramID !== null ||
        clientManagementProperties.opAccLeads[index].clientProjectID !== null
      ) {
        if(this.state.isEdit) this.setState({
          isChangedInForm: true,
        });
        clientManagementProperties.opAccLeads.forEach((x) => {
          if (x.isNew === false) {
            replacedUsersList.push(x)
          }
        });
        replacedUsersList.splice(index, 1);
        this.setState({
          showReplacementModal: true,
          replacementUserList: replacedUsersList,
          replacedUser: clientManagementProperties.opAccLeads[index],
          removedRole: 'opAccLeads',
        });
      } else {
        await this.onRemoveMUData('opAccLeads', eObj, []);
      }
    };
    onRemovecxLeadsData = async (eObj) => {
      let clientManagementProperties = this.state.clientManagementProperties;
      let replacedUsersList = [];
      let index = 0;
      index = this.getClientDataObjectIndex(eObj.label, clientManagementProperties.cxLeads);

      if (
        clientManagementProperties.cxLeads[index].clientProgramID !== null ||
        clientManagementProperties.cxLeads[index].clientProjectID !== null
      ) {
        if(this.state.isEdit) this.setState({
          isChangedInForm: true,
        });
        clientManagementProperties.cxLeads.forEach((x) =>{if(x.isNew=== false){ replacedUsersList.push(x)}});
        replacedUsersList.splice(index, 1);
        this.setState({
          showReplacementModal: true,
          replacementUserList: replacedUsersList,
          replacedUser: clientManagementProperties.cxLeads[index],
          removedRole: 'cxLeads'
        });
      } else {
        await this.onRemoveMUData('cxLeads', eObj, []);
      }
    };
    onRemoveproConfigsData = (eObj) => {
      this.onRemoveMUData('proConfig', eObj, []);
    };
    onRemovebusAdvisorData = (eObj) => {
      this.onRemoveMUData('busAdvisor', eObj, []);
    };

    getClientDataObjectIndex = (labelValue, arrayList) => {
      return arrayList
        .map(function (e) {
          return e.label;
        })
        .indexOf(labelValue);
    };
    onRemoveMUData = async (id, eObj, replacedUser) => {
      if (eObj != null && eObj.label.length >= 0) {
        let clientManagementProperties = this.state.clientManagementProperties;
        let deletedProfiles = this.state.deletedProfiles;
        let removedUser = [];
        let index = 0;
        switch (id) {
          case 'opAccLeads':
            replacedUser.forEach(x => removedUser.push(x.value))
            eObj = {
              ...eObj,
              newUsers: removedUser.join(',')
            }
            index = this.getClientDataObjectIndex(
              eObj.label,
              clientManagementProperties.opAccLeads
            );
            clientManagementProperties.opAccLeads.splice(index, 1);
            deletedProfiles.opAccLeads.push(eObj);
            break;
          case 'cxLeads':
            replacedUser.forEach(x => removedUser.push(x.value))
            eObj = {
              ...eObj,
              newUsers: removedUser.join(',')
            }
            index = this.getClientDataObjectIndex(
              eObj.label,
              clientManagementProperties.cxLeads
            );
            clientManagementProperties.cxLeads.splice(index, 1);
            deletedProfiles.cxLeads.push(eObj);
            break;
          case 'proConfig':
            eObj = {
              ...eObj,
              newUsers: removedUser.length === 0 ? "" : removedUser
            }
            index = this.getClientDataObjectIndex(eObj.label, clientManagementProperties.proConfig);
            clientManagementProperties.proConfig.splice(index, 1);
            deletedProfiles.proConfig.push(eObj);
            break;
          case 'busAdvisor':
            eObj = {
              ...eObj,
              newUsers: removedUser.length === 0 ? "" : removedUser
            }
            index = this.getClientDataObjectIndex(
              eObj.label,
              clientManagementProperties.busAdvisor
            );
            clientManagementProperties.busAdvisor.splice(index, 1);
            deletedProfiles.busAdvisor.push(eObj);
            break;
        }

        if(this.state.isEdit) this.setState({
          isChangedInForm: true,
        });
        const opAccLeads = clientManagementProperties.opAccLeads.map(item => item.label);
        const cxLeads = clientManagementProperties.cxLeads.map(item => item.label);
        const proConfig = clientManagementProperties.proConfig.map(item => item.label);
        const busAdvisor = clientManagementProperties.busAdvisor.map(item => item.label);

        let isValid = true;
        let response = await IsValidAccentureEmail([...opAccLeads, ...cxLeads, ...proConfig, ...busAdvisor]);
        let allInvalidEmails = [];
        for (let i = 0; i < response.length; i++) {
          if (!response[i].IsValid) {
            isValid = false;
            allInvalidEmails.push(response[i].UserName.split('@')[0]);
          }
        }
        if (!isValid) {
          this.setState({
            cmErrorMessage: [{
              key: 'Valid',
              value: `Please update the given invalid EIDs in order to proceed: ${allInvalidEmails.join(', ')}`,
            }],
            invalidAccentureEmail: true,
          });
        } else {
          this.setState({
            cmErrorMessage: [],
            clientManagementErrors: {
              cxLeads: false,
              opAccLeads: false,
              proConfig: false,
              busAdvisor: false,
            },
            invalidAccentureEmail: false,
          });
        }

        this.setState({
          clientManagementProperties,
          deletedProfiles
        });
      }
    };

    initialCall = () => {
      this.getClientManagementList();
      this.getClientList();
      this.getClientBasicDetailsDropdown();
    };

    getClientManagementList = async () => {
      let subMenuId = GetSubMenuId("Client Management")
      const res = await HttpGet(`ClientConfiguration/GetConfigClients?SubMenuID=${subMenuId}`);
      if (res != null && res.length > 0) {
        let data = [];
        for (let i = 0; i < res.length; i++) {
          data.push({
            Id: res[i].Id,
            client: res[i].ClientMasterName,
            isenabled: res[i].IsActive ? 'Yes' : 'No',
          });
        }
        this.setState((state, props) => ({
          tableProperties: { ...state.tableProperties, data: data },
          filteredData: data,
        }));
      }
    };

    getClientList = async () => {
      let subMenuId = GetSubMenuId("Client Management")
      const res = await HttpGet(`ClientConfiguration/GetAllClients?SubMenuID=${subMenuId}`);
      if (res != null && res.length > 0) {
        let options = [];

        for (let i = 0; i < res.length; i++) {
          options.push({
            value: res[i].Id,
            label: res[i].ClientMasterName,
            text: res[i].ClientMasterName,
          });
        }

        this.setState({
          options,
        });
      }
    };

    getDetailsofClientManagement = async (id) => {
      if (id != null && id > 0) {
        let subMenuId = GetSubMenuId("Client Management")
        const res = await HttpGet(`ClientConfiguration/GetClientConfigInfo?ClientMasterId=${id}&SubMenuID=${subMenuId}`);
        if (res != null) {
          let marketUnits = this.state.marketSubMarketUnitList.filter(
            (x) => x.value === res.MarketID
          )[0];
          const bussAds = res.userProfiles.filter((f) => f.ProfileID === 15);
          let clientManagementProperties = {
            clientID: res.ClientMasterId,
            clientName: res.ClientName,
            isenabled: res.IsActive,
            opAccLeads: this.getUserEmailId(res.userProfiles.filter((f) => f.ProfileID == 4)),
            cxLeads: this.getUserEmailId(res.userProfiles.filter((f) => f.ProfileID == 7)),
            proConfig: this.getUserEmailId(res.userProfiles.filter((f) => f.ProfileID == 12)),
            busAdvisor:
              bussAds.length > 0
                ? this.getUserEmailId(res.userProfiles.filter((f) => f.ProfileID == 15))
                : [],
          };
          let allStakeHolders = clientManagementProperties;
          allStakeHolders = allStakeHolders.opAccLeads.concat(
            allStakeHolders.cxLeads,
            allStakeHolders.proConfig,
            allStakeHolders.busAdvisor
          );

          this.setState(
            {
              isEdit: true,
              clientManagementProperties,
              clientManagementErrors: {
                clientID: false,
                opAccLeads: false,
                cxLeads: false,
                proConfig: false,
                busAdvisor: false,
              },
              opAccLeads: '',
              cxLeads: '',
              proConfig: '',
              busAdvisor: '',
              cmErrorMessage: [],
              allStakeHolders: allStakeHolders,
              selectedClientType: this.state.clientTypeList
                .filter((x) => x.value === res.ClientTypeID)
                .map((x) => {
                  return {
                    ...x,
                    text: x.label,
                  };
                })[0],
              selectedIndustries: this.state.industriesList
                .filter((x) => x.value === res.IndustryID)
                .map((x) => {
                  return {
                    ...x,
                    text: x.label,
                  };
                })[0],
              selectedMarketUnit: {
                label: marketUnits.label,
                value: marketUnits.value,
                text: marketUnits.label,
              },
              selectedSubMarketUnit: marketUnits.SubMarketUnits.filter(
                (x) => x.value === res.SubMarketUnitID
              ).map((x) => {
                return {
                  ...x,
                  text: x.label,
                };
              })[0],
              basicDetailsErrors: {
                clientType: false,
                industries: false,
                marketUnit: false,
                subMarketUnit: false,
              },
            },
            () => {
              this.onCloseModal();
            }
          );
        }
      }
    };

    getUserEmailId = (list, profileID) => {
      profileID = profileID == undefined ? 0 : profileID;
      let userList = [];
      if (list != null && list.length > 0) {
        list = list[0].users;
        if (list != null && list.length > 0)
          list.map((e) =>
            userList.push({
              value: e.UserEmailID,
              label: e.UserEmailID,
              clientProgramID: e.ClientProgramID,
              clientProjectID: e.ClientProjectID,
              isNew: false
            })
          );
      }

      return profileID == 5
        ? userList != null && userList.length > 0
          ? userList[0].value
          : ''
        : userList;
    };

    onEnableNewClientClick = () => {
      this.setState(
        {
          isEdit: false,
          clientManagementProperties: {
            clientID: 0,
            isenabled: false,
            opAccLeads: [],
            cxLeads: [],
            proConfig: [],
            busAdvisor: [],
          },
          clientManagementErrors: {
            clientID: false,
            opAccLeads: false,
            cxLeads: false,
            proConfig: false,
            busAdvisor: false,
          },
          opAccLeads: '',
          cxLeads: '',
          proConfig: '',
          busAdvisor: '',
          cmErrorMessage: [],
          selectedClientType: {},
          selectedIndustries: {},
          selectedMarketUnit: {},
          selectedSubMarketUnit: {},
          basicDetailsErrors: {
            clientType: false,
            industries: false,
            marketUnit: false,
            subMarketUnit: false,
          },
        },
        () => {
          this.onCloseModal();
        }
      );
    };

    deleteClientManagementData = (id) => {
      if (id != null && id > 0) {
        this.setState({
          ...this.state,
          showDelConfirmationModal: !this.state.showDelConfirmationModal,
          deleteID: id,
        });
      }
    };

    onConfirmClose = () => {
      this.setState({
        ...this.state,
        showDelConfirmationModal: !this.state.showDelConfirmationModal,
      });
    };

    onConfirmDelete = async () => {
      this.setState({
        disableDelete: true
      }, async () => {
        let subMenuId = GetSubMenuId("Client Management");
        const data = await HttpPost(
          `ClientConfiguration/DeleteClientConfig?clientMasterID=${this.state.deleteID}&SubMenuID=${subMenuId}`
        );
        if (data != null) {
          this.props.setToasterParam({
            show: true,
            message: 'Client was deleted successfully!',
            type: 'success',
          });

          this.setState(
            {
              deleteID: 0,
              showDelConfirmationModal: !this.state.showDelConfirmationModal,
            },
            () => {
              this.initialCall();
            }
          );
        }
        this.setState({
          disableDelete: false
        })
      })
    };

    getClientBasicDetailsDropdown = async () => {
      try {
        let subMenuId = GetSubMenuId("Client Management")
        const data = await HttpGet(`ClientConfiguration/GetClientBasicDetailsDropdown?SubMenuID=${subMenuId}`);
        if (data !== null) {
          this.setState({
            clientTypeList: data.ClientTypes,
            marketSubMarketUnitList: data.MarketUnits,
            industriesList: data.Industries,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    onChangeClientType = (e) => {
      try {
        this.setState({
          selectedClientType: e,
        },()=>{
          this.state.cmErrorMessage.length!=0&&this.validtionClientManagement()
        });
        if(this.state.isEdit) this.setState({
          isChangedInForm: true,
        });
      } catch (err) {
        console.log(err);
      }
    };

    onChangeIndustries = (e) => {
      try {
        this.setState({
          selectedIndustries: e,
        },
        ()=>{
          this.state.cmErrorMessage.length!=0&&this.validtionClientManagement()
        }
        );
        if(this.state.isEdit) this.setState({
          isChangedInForm: true,
        });
      } catch (err) {
        console.log(err);
      }
    };

    onChangeMarketUnit = (e) => {
      try {
        this.setState(
          {
            selectedMarketUnit: e,
            selectedSubMarketUnit: {},
          },
          () => {
            this.state.cmErrorMessage.length!=0&&this.validtionClientManagement()
            const subMarketList = this.state.marketSubMarketUnitList
              .filter((x) => x.value === e.value)
              .map((x) => x.SubMarketUnits)[0];

            if (subMarketList.length === 1) {
              subMarketList[0]['text'] = subMarketList[0].label;
              this.onChangeSubMarketUnit(subMarketList[0]);
            }
          }
        );
        if(this.state.isEdit) this.setState({
          isChangedInForm: true,
        });
      } catch (err) {
        console.log(err);
      }
    };

    onChangeSubMarketUnit = (e) => {
      try {
        this.setState({
          selectedSubMarketUnit: e,
        },()=>{
          this.state.cmErrorMessage.length!=0&&this.validtionClientManagement()
        });
        if(this.state.isEdit) this.setState({
          isChangedInForm: true,
        });
      } catch (err) {
        console.log(err);
      }
    };

    validateBasicDetails = (stakeHolderIsValid) => {
      try {
        let isValid = true;
        const fields = this.state;

        if (
          Object.keys(fields.selectedClientType).length === 0 ||
          Object.keys(fields.selectedIndustries).length === 0 ||
          Object.keys(fields.selectedMarketUnit).length === 0 ||
          Object.keys(fields.selectedSubMarketUnit).length === 0
        ) {
          isValid = false;

          this.setState(
            {
              basicDetailsErrors: {
                clientType: Object.keys(fields.selectedClientType).length === 0 ? true : false,
                industries: Object.keys(fields.selectedIndustries).length === 0 ? true : false,
                marketUnit: Object.keys(fields.selectedMarketUnit).length === 0 ? true : false,
                subMarketUnit:
                  Object.keys(fields.selectedSubMarketUnit).length === 0 ? true : false,
              },
            },
            () => {
              this.handleErrorMessage(1);
            }
          );
        } else {
          isValid = true;
          this.setState(
            {
              basicDetailsErrors: {
                clientType: false,
                industries: false,
                marketUnit: false,
                subMarketUnit: false,
              },
            },
            () => {
              if (stakeHolderIsValid) {
                this.handleErrorMessage(1, false);
              }
            }
          );
        }

        return isValid;
      } catch (err) {
        console.log(err);
      }
    };

    hideReplacementModal = () => {
      this.setState({ showReplacementModal: false, replacedUser: {}, removedRole: '' })
    }

    handleSaveReplacement = async (replacedUser, role) => {
      let clientManagementProperties = this.state.clientManagementProperties;
      let index = 0;
      if (role === 'opAccLeads') {
        replacedUser.forEach(x => {
          if (x.isNew) {
            clientManagementProperties.opAccLeads.push(x)
          } else {
            index = clientManagementProperties.opAccLeads.findIndex(y => y.value === x.value);
            IsValid(clientManagementProperties.opAccLeads[index].clientProgramID) ?
              clientManagementProperties.opAccLeads[index].clientProgramID =
              clientManagementProperties.opAccLeads[index].clientProgramID + ',' + this.state.replacedUser.clientProgramID
              : clientManagementProperties.opAccLeads[index].clientProgramID = this.state.replacedUser.clientProgramID;
            IsValid(clientManagementProperties.opAccLeads[index].clientProjectID) ?
              clientManagementProperties.opAccLeads[index].clientProjectID =
              clientManagementProperties.opAccLeads[index].clientProjectID + ',' + this.state.replacedUser.clientProjectID
              : clientManagementProperties.opAccLeads[index].clientProjectID = this.state.replacedUser.clientProjectID;
          }
        })
        await this.onRemoveMUData('opAccLeads', this.state.replacedUser, replacedUser);
      } else if (role === 'cxLeads') {
        replacedUser.forEach(x => {
          if (x.isNew) {
            clientManagementProperties.cxLeads.push(x)
          } else {
            index = clientManagementProperties.cxLeads.findIndex(y => y.value === x.value);
            IsValid(clientManagementProperties.cxLeads[index].clientProgramID) ?
              clientManagementProperties.cxLeads[index].clientProgramID =
              clientManagementProperties.cxLeads[index].clientProgramID + ',' + this.state.replacedUser.clientProgramID
              : clientManagementProperties.cxLeads[index].clientProgramID = this.state.replacedUser.clientProgramID;
            IsValid(clientManagementProperties.cxLeads[index].clientProjectID) ?
              clientManagementProperties.cxLeads[index].clientProjectID =
              clientManagementProperties.cxLeads[index].clientProjectID + ',' + this.state.replacedUser.clientProjectID
              : clientManagementProperties.cxLeads[index].clientProjectID = this.state.replacedUser.clientProjectID;
          }
        })
        await this.onRemoveMUData('cxLeads', this.state.replacedUser, replacedUser);
      }
      this.setState({
        clientManagementProperties,
        showReplacementModal: false,
        replacedUser: {},
        removedRole: ''
      })
    }

    render() {
      const { dashboardClientDetails } = this.props.DashboardData;
      return (
        <ClientManagementPresentational
          disableDelete={this.state.disableDelete}
          tableProperties={this.state.tableProperties}
          show={this.state.show}
          onCloseModal={this.onCloseModal}
          options={this.state.options}
          size={this.state.size}
          isEdit={this.state.isEdit}
          getGridApi={this.getGridApi}
          searchFunc={this.onSearchTextChanged}
          saveClientManagement={this.saveClientManagement}
          onClientChange={this.onClientChange}
          onEnableCheck={this.onEnableCheck}
          onAddSingleUser={this.onAddSingleUser}
          onAddMUTextChange={this.onAddMUTextChange}
          onAddMUButton={this.onAddMUButton}
          onRemoveopAccLeadsData={this.onRemoveopAccLeadsData}
          onRemovecxLeadsData={this.onRemovecxLeadsData}
          onRemoveproConfigsData={this.onRemoveproConfigsData}
          onRemovebusAdvisorData={this.onRemovebusAdvisorData}
          clientManagementErrors={this.state.clientManagementErrors}
          cmErrorMessage={this.state.cmErrorMessage}
          clientManagementProperties={this.state.clientManagementProperties}
          onEnableNewClientClick={this.onEnableNewClientClick}
          showDelConfirmationModal={this.state.showDelConfirmationModal}
          closeDelConfirmationModal={this.closeDelConfirmationModal}
          onConfirmClose={this.onConfirmClose}
          onConfirmDelete={this.onConfirmDelete}
          opAccLeads={this.state.opAccLeads}
          cxLeads={this.state.cxLeads}
          proConfig={this.state.proConfig}
          busAdvisor={this.state.busAdvisor}
          dashboardClientDetails={dashboardClientDetails}
          validateEmailIOApprover={this.validateEmailIOApprover}
          // Basic Details
          clientTypeList={this.state.clientTypeList}
          marketSubMarketUnitList={this.state.marketSubMarketUnitList}
          industriesList={this.state.industriesList}
          selectedClientType={this.state.selectedClientType}
          selectedIndustries={this.state.selectedIndustries}
          selectedMarketUnit={this.state.selectedMarketUnit}
          selectedSubMarketUnit={this.state.selectedSubMarketUnit}
          onChangeClientType={this.onChangeClientType}
          onChangeIndustries={this.onChangeIndustries}
          onChangeMarketUnit={this.onChangeMarketUnit}
          onChangeSubMarketUnit={this.onChangeSubMarketUnit}
          basicDetailsErrors={this.state.basicDetailsErrors}
          filteredData={this.state.filteredData}
          disableSave={this.state.disableSave}
          invalidAccentureEmail={this.state.invalidAccentureEmail}
          // Replacement Modal
          showReplacementModal={this.state.showReplacementModal}
          replacementUserList={this.state.replacementUserList}
          replacedUser={this.state.replacedUser}
          removedRole={this.state.removedRole}
          hideReplacementModal={this.hideReplacementModal}
          handleSaveReplacement={this.handleSaveReplacement}
          validateMultiSelectUserData={this.validateMultiSelectUserData}
        />
      );
    }
  }

  class WarningModal extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="stepperClose-Alert">
            <div className="stepperClose-Alert-Content">
              <div className="stepperClose-Alert-Icon">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <div className="stepperClose-Alert-text">
                <h5>Are you sure you want to Delete?</h5>
              </div>
            </div>
          </div>
          <div className="notifyModal-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={this.props.onCloseModal}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={this.props.disableDelete}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Yes sure"
              callbackFunction={this.props.onCloseProject}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  return ClientManagementContainer;
})();

export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      userInformation: state.userInformation,
      DashboardData: state.DashboardData,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        { setToasterParam, fetchClientDetails, unsetClientDetails, isUserIOApprover }
      ),
      dispatch
    )
)(ClientManagement);
