import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar as solidStar,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { GrooveTabComponent, GrooveIconSvg } from "tfo-groove";

const CVMSelectClientModal = (function () {
  class CVMSelectClientModalPresentational extends Component {
    render() {
      const customCSS = this.props.disableClientTile ? "disable-select-client-tile" : "";
      const tab_data = [
        {
          tab_label: "All Clients",
          tab_value: 1,
          tab_id: "all-tab",
          tab_content: (
            <div className="cvm-tab-content">
              <div className="cvm-project-tile-container">
                <Row>
                  {this.props.clientList.filter((x) => x.label.toLowerCase().includes(this.props.filterKeyword.toLowerCase())).map((item, index) => {
                    return (
                      <Col lg={4} md={6} key={index}>
                        <div className={`cvm-project-tile ${customCSS}`}>
                          <div className="client-details-select" onClick={() => this.props.onSelectClient(item, false)}>
                            <div className="project-logo">
                              <img src={item.icon} alt="dashboard" />
                            </div>
                            <div className="project-name label14-medium-midnight">
                              {item.label}
                            </div>
                          </div>
                          <div className={`star-container ${item.favorite ? "no-filter" : ""}`}>
                            <div onClick={() => this.props.onClickFavorite(item)}>
                              <FontAwesomeIcon icon={item.favorite ? solidStar : regularStar} />
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </div>
          ),
        },
        {
          tab_label: "Favorites",
          tab_value: 2,
          tab_id: "favorites-tab",
          tab_content: (
            <div className="cvm-tab-content">
              <div className="cvm-project-tile-container">
                <Row>
                  {this.props.clientList.filter((x) => x.label.toLowerCase().includes(this.props.filterKeyword.toLowerCase()) && x.favorite).map((item, index) => {
                    return (
                      <Col lg={4} md={6} key={index}>
                        <div className={`cvm-project-tile ${customCSS}`}>
                          <div className="client-details-select" onClick={() => this.props.onSelectClient(item, false)}>
                            <div className="project-logo">
                              <img src={item.icon} alt="dashboard" />
                            </div>
                            <div className="project-name label14-medium-midnight">
                              {item.label}
                            </div>
                          </div>
                          <div className={`star-container ${item.favorite ? "no-filter" : ""}`}>
                            <div onClick={() => this.props.onClickFavorite(item)}>
                              <FontAwesomeIcon icon={item.favorite ? solidStar : regularStar} />
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </div>
          ),
        },
      ];
      return (
        <div>
          <div className="search-bar-container">
            <div className="search-bar label14-regular-midnight">
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="large"
                name="search"
                iconStyle="solid"
                iconColor="stat-neutral"
              />
              <input type="text" name="name" placeholder="Search..." maxLength="255" onChange={(e) => this.props.onSearch(e)} />
            </div>
          </div>
          <GrooveTabComponent theme="purple" tab_data={tab_data} selectedIndex={this.props.selectedTab} />
        </div>
      );
    }
  }

  class CVMSelectClientModalContainer extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <CVMSelectClientModalPresentational
          modalActiveKey={this.props.modalActiveKey}
          onChangeModalTab={this.props.onChangeModalTab}
          clientList={this.props.clientList}
          onClickFavorite={this.props.onClickFavorite}
          onSearch={this.props.onSearch}
          filterKeyword={this.props.filterKeyword}
          onSelectClient={this.props.onSelectClient}
          selectedTab={this.props.selectedTab}
          disableClientTile={this.props.disableClientTile}
        />
      );
    }
  }

  return CVMSelectClientModalContainer;
})();

export default CVMSelectClientModal;
