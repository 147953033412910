import React, { Component } from 'react';
// import { history } from "../../store";
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Header } from '../MasterLayout';
import { DeleteCallbackURL, DeleteReferrer, DeleteDistiURL } from '../../Utilities/AccessManagementUtil';

const LoginError = (function () {
	class _LoginError extends Component {
		componentDidMount() {
			DeleteCallbackURL()
			DeleteReferrer()
			DeleteDistiURL()
		}
		goBack = (event) => {
			this.props.history.push('/AccessRequest');
		}

		render() {
			return (
				<div className="body_container">
					<Header />
					<Row className="access_denied_container">
						<Col md={12} xs={12}>
							<div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
							<div className="access_denied_content">
								<h1>Something went wrong</h1>
								<p>We are having trouble logging you in. Please clear your user credentials in the browser and try to login again.</p>
							</div>
						</Col>
					</Row>
				</div>
			);
		}
	}	

	return _LoginError;
})();

export default withRouter(LoginError)