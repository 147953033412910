import {
    SET_PROJECT_LIST,
    SET_RELOAD_PROJECT_LIST,
    SET_INITIAL_PROJECT_LIST
} from '../Constants';

const initialState = {
    filter: {
        id: ""
    },
    isReload: false
}

export default function ProjectListData(state = initialState, action = {}) {
    switch (action.type) {
        case SET_PROJECT_LIST:
            return {
                ...state,
                filter: action.payload
            };
        case SET_RELOAD_PROJECT_LIST:
            return {
                ...state,
                isReload: action.payload
            };
        case SET_INITIAL_PROJECT_LIST:
            return initialState
        default:
            return state;
    }
}