import React, { Component } from 'react';
import { history } from "../../store";
import { Row, Col } from 'react-bootstrap';
import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header } from '../MasterLayout';
import { GrooveTabComponent } from 'tfo-groove';

import Chrome_1 from "../../Static/images/instructionimg/Chrome_1.PNG";
import Chrome_2 from "../../Static/images/instructionimg/Chrome_2.PNG";
import Chrome_3 from "../../Static/images/instructionimg/Chrome_3.PNG";
import Chrome_4 from "../../Static/images/instructionimg/Chrome_4.PNG";

import Edge_1 from "../../Static/images/instructionimg/Edge_1.PNG";
import Edge_2 from "../../Static/images/instructionimg/Edge_2.PNG";
import Edge_3 from "../../Static/images/instructionimg/Edge_3.PNG";
import Edge_4 from "../../Static/images/instructionimg/Edge_4.PNG";

export default class DisablePopupBlocker extends Component {

    goBack = (event) =>{
      this.props.push('/');
    }

    render() {
        const tab_data = [
            {
              tab_label: "Google Chrome",
              tab_value: 1,
              tab_id: "standard-boi-tab",
              tab_content: (
                <React.Fragment>    
                    <div className="instructions-steps">1. Click the lock icon from the address bar, it will open a popup then click <strong>site settings</strong>.</div>
                    <img src={Chrome_1}/> 
                    <div className="instructions-steps">2. New window will open and from there change the value of <strong>Pop-ups and redirects</strong> to <strong>Allow</strong> then close this window.</div>
                    <img src={Chrome_2}/> 
                    <div className="instructions-steps">3. On the original window, a <strong>Reload</strong> button will show. Once the button is clicked the page will refresh to apply the updated settings.</div>
                    <img src={Chrome_3}/> 
                    <div className="instructions-steps">4. Once the page was refreshed, once again click on the lock icon to verify if the pop-ups and redirects is already allowed.</div>
                    <img src={Chrome_4}/> 
                </React.Fragment>
              ),
            },
            {
              tab_label: "Microsoft Edge",
              tab_value: 2,
              tab_id: "custom-boi-tab",
              tab_content: (
                <React.Fragment>
                 <div className="instructions-steps">1. Click the lock icon from the address bar, it will open a popup then click <strong>Permission for this site</strong>.</div>
                <img src={Edge_1}/> 
                <div className="instructions-steps">2. New window will open and from there change the value of <strong>Pop-ups and redirects</strong> to <strong>Allow</strong> then close this window.</div>
                <img src={Edge_2}/> 
                <div className="instructions-steps">3. On the original window, a <strong>Refresh</strong> button will show. Once the button is clicked the page will refresh to apply the updated settings.</div>
                <img src={Edge_3}/> 
                <div className="instructions-steps">4. Once the page was refreshed, once again click on the lock icon to verify if the pop-ups and redirects is already allowed.</div>
                <img src={Edge_4}/> 
                </React.Fragment>
              ),
            },
          ];
        return (
            <div className="body_container">
                <Header />
                <div className="instructions-container">
                    <GrooveTabComponent theme="purple" tab_data={tab_data}/>
                </div>
            </div>
        );
    }
}