import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
// import { history } from "../../store";
import { withRouter } from "react-router-dom";
import MasterConfiguration from "../MasterConfiguration/MasterConfiguration"
import Dashboard from "../Dashboard/Dashboard"
import DataIngestion from "../DataIngestion/DataIngestion"
import ProgramMain from "../ProgramProjectConfiguration/Progam/ProgramMain"
import ProjectMain from "../ProgramProjectConfiguration/Project/ProjectMain"

import AddFileIcon from './../../Static/images/360cvm/AddFilesIcon.svg'

import {
  showClientDropdown,
  setSelectedClient,
  leftPanelOnClick,
  updatePPCBOI
} from "../../Actions/Shared.Action";

import { InitRVD, InitPnP } from "../../Actions/AccessValidation.Action"
import { setPath } from "../../Actions/Home.Action"


const _Modules = (function () {
  class ModulesPresentational extends Component {
    render() {
      // console.log("_Modules: path: ", this.props.path, " ,client: ", this.props.selectedClient);
      return (
        <div>
          <div id="module-container">
            { this.props.showHide ? 
              <div className="left-sidebar">
              <ul className="">
                {
                  this.props.modules.map((module, i) => {
                    return (
                      <li
                        key={i}
                        className={this.props.path === "/programprojectconfig/projects" && module.ModulePath.toLocaleLowerCase() === "/programprojectconfig" ? "active" : this.props.path === module.ModulePath.toLocaleLowerCase() ? "active" : module.ModulePath.toLowerCase() === "/dashboard" ? module.isEnabled || this.props.dashboardClients.length > 0 ? "" : "no-access" : module.isEnabled ? "" : "no-access"}
                        onClick={module.ModulePath.toLowerCase() === "/dashboard" ? module.isEnabled || this.props.dashboardClients.length > 0 ? () => {
                          this.props.onChangeModule(module);
                          this.props.history.push({ pathname: module.ModulePath, state: { prevPath: window.location.pathname } });
                        } : null

                          : module.isEnabled ? () => {
                            this.props.onChangeModule(module);
                            this.props.history.push({ pathname: module.ModulePath, state: { prevPath: window.location.pathname } });
                          } : null
                        }
                      >
                        <img src={module.SmallIcon} alt={module.MenuName} />
                        <span>{module.MenuName}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>: ''
            }
            <div className={this.props.showHide ? "right-section" : "right-section-comment"}>
              {(this.props.path === "/dashboard" || this.props.path.includes("/dashboard?")) && this.props.selectedClient.value !== "" ? (
                <Dashboard />
              ) : this.props.path.includes("/dataingestion") && this.props.selectedClient.value !== "" ? (
                <DataIngestion />
              ) : this.props.path.includes("/programprojectconfig/projects") && this.props.selectedClient.value !== "" ? (
                <ProjectMain />
              ) : this.props.path.includes("/programprojectconfig") && this.props.selectedClient.value !== "" ? (
                <ProgramMain />
              ) : this.props.path === "/masterconfiguration" ? (
                <MasterConfiguration />
              ) : (
                <SelectClient pageName={this.props.path} />
              )}
              <div id='right-section'></div>
            </div>
          </div>
        </div >
      );
    }
  }

  class SelectClient extends Component {
    render() {
      const pageName = this.props.pageName === "dataingestion" ? "Data Ingestion" : this.props.pageName === "clientconfiguration" ? "Client Configuration" : this.props.pageName === "dashoard" ? "Dashboard" : this.props.pageName === "programprojectconfig" ? "Program and Project" : ""
      return (
        <div className="select-client-container">
          <img src={AddFileIcon} alt="" />
          <p>Select Client</p>
          <span> Please select a client to access {pageName} Module</span>
        </div>
      )
    }
  }

  class ModulesContainer extends Component {
    constructor(props) {
      super(props);
    }
    componentDidMount() {
      let path = window.location.pathname;
      let url_string = window.location.href;
      let url = new URL(url_string);
      let isALA = url.searchParams.get('isALA');
      this.props.updatePPCBOI(isALA) //set true to isAccountLeadership
      this.props.setPath(path);
    };

    onChangeModule = (module) => {
      const path = module.ModulePath.toLowerCase()

      //SET AND NAVIGATE TO MODULE
      this.props.setPath(path);
      this.props.showClientDropdown(true, path)
      this.props.leftPanelOnClick(true);
      if (path === "/dataingestion") {
        this.props.InitRVD();
      } else if (path.includes("/programprojectconfig")) {
        this.props.InitPnP();
      }
    };

    clearSelectedClient = () => {
      const data = {
        value: "",
        label: ""
      }
      this.props.setSelectedClient(data)
    }

    render() {
      const {
        selectedClient,
        showHide
      } = this.props.SharedData
      const { modules, path } = this.props.HomeData;
      const { dashboardClients } = this.props.DashboardData;
      return (
        <ModulesPresentational
          path={path}
          onChangeModule={this.onChangeModule}
          clearSelectedClient={this.clearSelectedClient}
          selectedClient={selectedClient}
          showHide={showHide}
          modules={modules}
          dashboardClients={dashboardClients}
          history={this.props.history}
        />
      );
    }
  }

  return ModulesContainer;
})();

export default connect(
  state => {
    return {
      SharedData: state.SharedData,
      userInformation: state.userInformation,
      HomeData: state.HomeData,
      DashboardData: state.DashboardData
    }
  },
  dispatch => bindActionCreators(Object.assign({},
    {
      showClientDropdown,
      setSelectedClient,
      leftPanelOnClick,
      setPath,
      InitRVD,
      InitPnP,
      updatePPCBOI
    }
  ),
    dispatch)
)(withRouter(_Modules));
