import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

export default class TermsAndAgreement extends Component {
  componentDidMount = () => {
    if (document.getElementById('navigation').getElementsByClassName('selected').length > 0) {
      document
        .getElementById('navigation')
        .getElementsByClassName('selected')[0]
        .classList.remove('selected');
    }
  };

  render() {
    return (
      <Row id="module-container" className="body_container">
        <Col md={12} xs={12}>
          <h1 className="terms-header"> Terms and Agreement</h1>
          <p>
            Your access to and use of this site is subject to the following terms and conditions and
            all applicable laws. By accessing and using this site, you accept the following terms
            and conditions, without limitation or qualification.
          </p>

          <p>
            The information provided on this site is free of charge and for informational purposes
            and internal use only. Unless otherwise stated, the contents of this site including, but
            not limited to, the text and images contained herein and their arrangement are the
            property of Accenture. All trademarks used or referred to in this website are the
            property of their respective owners. Nothing contained in this site shall be construed
            as conferring by implication, estoppel, or otherwise, any license or right to any
            copyright, patent, trademark or other proprietary interest of Accenture or any third
            party. This site and the content provided in this site, including, but not limited to,
            graphic images, audio, video, html code, buttons, and text, may not be copied,
            reproduced, republished, uploaded, posted, transmitted, or distributed in any way,
            without the prior written consent of Accenture, except that you may download, display,
            and print one copy of the materials on any single computer solely for your personal,
            non-commercial use, provided that you do not modify the material in any way and you keep
            intact all copyright, trademark, and other proprietary notices.
          </p>

          <p>
            Links on this site may lead to services or sites not operated by Accenture. When you
            click on a link to a third party site you are subject to the Terms of Use of that site
            and not of this Accenture site. Accordingly, Accenture takes no responsibility for other
            sites or related services. This site and its contents are provided "as is" and Accenture
            makes no representation or warranty of any kind with respect to this site or any site or
            service accessible through this site. Accenture expressly disclaims all express and
            implied warranties including, but not limited to, the implied warranties of
            merchantability, fitness for a particular purpose, title, and non-infringement. In no
            event will Accenture be liable to any party for any direct, indirect, incidental,
            special, exemplary, consequential, or other damages (including, but not limited to, lost
            profits, business interruption, loss of programs or data) without regard to the form of
            action and whether in contract, tort, negligence, strict liability, or otherwise,
            arising out of or in connection with this site, any content on or accessed through this
            site or any site service linked to, or any copying, displaying, or use thereof.
          </p>

          <p>
            This system is the property of Accenture, and is to be used in accordance with
            applicable Accenture Policies. Unauthorized access or activity is a violation of
            Accenture Policies and may be a violation of law. Use of this system constitutes consent
            to monitoring for unauthorized use, in accordance with the Accenture Policies, local
            laws, and regulations. Unauthorized use may result in penalties including, but not
            limited to, reprimand, dismissal, financial penalties, and legal action. Accenture
            maintains this site in the Philippines. You agree that these terms of use and any legal
            action or proceeding relating to this site shall be governed by the laws of the State of
            Illinois without reference to its choice of law rules. If you attempt to bring any legal
            proceedings against Accenture, you specifically acknowledge that Accenture is free to
            choose the jurisdiction of our preference as to where such action against us may be
            held. As you have agreed by using this site to choose the laws of the State of Illinois
            to govern any such proceedings, we will probably choose to defend any such action in
            Illinois and we can make this decision entirely as it suits us, without regard to where
            in the world you are located, or from where in the world you visited this site.
          </p>

          <p>
            You are responsible for complying with the laws of the jurisdiction from which you are
            accessing this site and you agree that you will not access or use the information on
            this site in violation of such laws. Unless expressly stated otherwise herein, any
            information submitted by you through this site shall be deemed non-confidential and
            non-proprietary. You represent that you have the lawful right to submit such information
            and agree that you will not submit any information unless you are legally entitled to do
            so. Because of the open nature of the Internet, we recommend that you not submit
            information you consider confidential.
          </p>
        </Col>
      </Row>
    );
  }
}
