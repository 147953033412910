import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Toast from "react-bootstrap/Toast"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { IsValid } from '../../../Utilities/Validations'
import { setToasterParam } from '../../../Actions/Shared.Action'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
require('./CVMToasterNotif.css')
const CVMToasterNotif = (function () {
    class CVMToasterNotifPresentational extends Component {
        render() {
            const notif = this.props.notifToasterParam
            return (<div className={(!IsValid(notif.type) || notif.type === 'success') ? "notify-toast" : 'notify-toast-error'}>
                <Toast
                    onClose={this.props.onCloseNotifToaster}
                    show={notif.show}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <FontAwesomeIcon icon={notif.type === 'success' ? faCheckCircle : notif.type === 'error' ? faTimesCircle : undefined} />
                        <h5>{notif.message}</h5>
                    </Toast.Header>
                </Toast>
            </div>)
        }
    }

    class CVMToasterNotifContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                notifToasterParam: { show: false, message: '', type: '' }
            }
        }

        componentDidMount() {
            this.onShowNotifToaster(this.props.toasterParam)
        }
        componentDidUpdate(prevProps) {
            if (JSON.stringify(this.props.toasterParam) !== JSON.stringify(prevProps.toasterParam)) {
                this.onShowNotifToaster(this.props.toasterParam)
            }
        }


        onShowNotifToaster = (param) => {
            this.setState({ notifToasterParam: { show: param.show, message: param.message, type: param.type } });
        }
        onCloseNotifToaster = () => {
            this.props.setToasterParam({ show: false, message: '' });
            this.setState({ notifToasterParam: { show: false, message: '', type: this.props.toasterParam.type } })
        }

        render() {
            return (
                <CVMToasterNotifPresentational
                    {...this.state}
                    {...this.props}
                    onCloseNotifToaster={this.onCloseNotifToaster}
                />
            );
        }
    }

    return CVMToasterNotifContainer;
})();

export default connect(
    (state) => {
        return {
            toasterParam: state.SharedData.toasterParam
        }
    },
    (dispatch) =>
        bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(CVMToasterNotif);
