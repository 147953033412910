import React, { Component } from "react";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { Row, Col, Tab, Nav, NavItem, Button } from "react-bootstrap";
import { history } from "../../../store";
import Utilities from "../../../Utilities";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { HasValue } from "../../../Utilities/Validations";

require("./CVMTable.css");
const CVMTable = (function () {
  class CVMTablePresentational extends Component {
    render() {
      const styles_pageSize = {
        option: (base, state) => ({
          ...base,
          "&:hover": {
            color: "#fff",
            backgroundColor: "#a100ff",
            fontFamily: "Graphik-Medium-Web",
          },
          backgroundColor: state.isSelected ? "#a100ff" : "#fff",
          color: state.isSelected ? "#fff" : "#212b36",
          whiteSpace: "nowrap",
          borderRadius: "4px",
          fontFamily: "Graphik-Regular-Web",
          fontSize: "12px",
          padding: "3px",
          wordBreak: "break-word",
          cursor: "pointer"
        }),
        control: (base, state) => ({
          ...base,
          "&:hover": { borderColor: "rgb(220, 226, 231)" },
          borderRadius: "3px",
          boxShadow: state.menuIsOpen ? "0 0 7px rgb(161 0 255 / 60%)" : 0,
          borderColor: state.menuIsOpen ? "rgb(220, 226, 231)" : "#dfe3e8",
          fontFamily: "Graphik-Regular-Web",
          fontSize: "12px",
          color: "rgb(123, 142, 152)",
          width: "60px",
          height: "30px",
          padding: "0 5px",
          cursor: "pointer",
        }),
        menu: (base, state) => ({
          ...base,
          borderRadius: "0 0 8px 8px",
          backgroundColor: "#fff",
          boxShadow: "0px 2px 24px 0px rgba(0, 0, 0, 0.15)",
          padding: "5px",
          zIndex: 2,
          width: "60px",
        }),
        singleValue: (base, state) => ({
          ...base,
          color: "rgb(123, 142, 152)",
        }),
      };

      return (
        <div style={{ width: "100%", height: "100%" }}>
          <i className="fa fa-spinner fa-spin" />
          <div className="table-container">
            <div
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              }}
              className="ag-theme-alpine"
            >
              <div style={{ height: "100%" }} className="ag-grid-table-wrap">
                <AgGridReact
                  suppressDragLeaveHidesColumns={true}
                  debounceVerticalScrollbar={true}
                  enableBrowserTooltips={true}
                  columnDefs={this.props.gridOptions.columnDefs}
                  frameworkComponents={this.props.gridOptions.frameworkComponents}
                  defaultColDef={this.props.gridOptions.defaultColDef}
                  onGridReady={this.props.onGridReady}
                  rowData={this.props.gridOptions.rowData}
                  rowClass={this.props.gridOptions.rowClass}
                  rowHeight={60}
                  gridOptions={this.props.gridOptions}
                  cellClicked={(e) => {
                    alert(e);
                  }}
                  rowSelection={this.props.rowSelection}
                  onSelectionChanged={this.props.onSelectionChanged}
                />
              </div>
            </div>

            {/* pagination */}
            <div className="cvm-table-pagination-container">
              <ul className="pagination">
                <li id="firstPage">
                  <button
                    className={
                      this.props.pager.currentPage === 0
                        ? "pagination-btn-disable"
                        : ""
                    }
                    onClick={() => this.props.setPage(0)}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </button>
                </li>
                <li id="prevPage">
                  <button
                    className={
                      this.props.pager.currentPage === 0
                        ? "pagination-btn-disable"
                        : ""
                    }
                    onClick={() =>
                      this.props.setPage(this.props.pager.currentPage - 1)
                    }
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                </li>
                {this.props.pager.pages.map((page, index) => (
                  <li key={index}>
                    <button
                      className={
                        this.props.pager.currentPage === page - 1
                          ? "pagination-btn-active pagination-btn-pages"
                          : "pagination-btn-pages"
                      }
                      onClick={() => this.props.setPage(page - 1)}
                    >
                      {page}
                    </button>
                  </li>
                ))}
                <li id="nextPage">
                  <button
                    className={
                      this.props.pager.currentPage ===
                        this.props.pager.totalPages - 1
                        ? "pagination-btn-disable"
                        : ""
                    }
                    onClick={() => {
                      if (
                        this.props.pager.currentPage <
                        this.props.pager.totalPages - 1
                      ) {
                        this.props.setPage(this.props.pager.currentPage + 1);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </li>
                <li id="lastPage">
                  <button
                    className={
                      this.props.pager.currentPage ===
                        this.props.pager.totalPages - 1
                        ? "pagination-btn-disable"
                        : ""
                    }
                    onClick={() =>
                      this.props.setPage(this.props.pager.totalPages - 1)
                    }
                  >
                    {" "}
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </button>
                </li>
              </ul>
              <div className="page-size">
                <label>Page Size</label>
                <Select
                  id="page-size-select"
                  onChange={this.props.setPageSize}
                  options={this.props.pager.pageSizeOptions}
                  value={this.props.pager.pageSize}
                  styles={styles_pageSize}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0, //border radius of select
                    spacing: {
                      ...theme.spacing,
                      baseUnit: 1, //padding of select
                      controlHeight: 30, //height of select
                      menuGutter: 0, //margin of select to option menu
                    },
                  })}
                  components={{ IndicatorSeparator: () => null }}
                  maxMenuHeight={55}
                  defaultValue={{ label: 10, value: 10 }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  class CVMTableContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        gridOptions: {
          columnDefs: this.props.tableProperties.columns,
          frameworkComponents: this.props.tableProperties.cellRenderer,
          defaultColDef: {
            sortable: this.props.tableProperties.sortable,
          },
          rowClass: "cvm-table-row",
          rowData: null, // table data
          pagination: this.props.tableProperties.pagination, // pagination on/off
          paginationPageSize: 10, // page size
          rowSelection: this.props.tableProperties.rowSelection,
        },
        pager: {
          currentPage: 0, // initial page
          noOfPages: 4, // no of pages shown on pagination
          totalPages: 0, // last page
          pages: [], // displayed pages options
          pageSizeOptions: [
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 30, label: 30 },
            { value: 40, label: 40 },
            { value: 50, label: 50 },
          ],
          pageSize: { value: 10, label: 10 },
        },
        previousRowCount: 0
      };
    }
    componentDidUpdate = (nextProps) => {
      if (JSON.stringify(this.state.gridOptions.rowData) !== JSON.stringify(HasValue(this.props.filteredData) ? this.props.filteredData : nextProps.tableProperties.data)) {
        this.setState(
          {
            previousRowCount: nextProps.tableProperties.data.length,
            gridOptions: {
              ...this.state.gridOptions,
              rowData: HasValue(this.props.filteredData) ? this.props.filteredData : nextProps.tableProperties.data,
            },
          },
          () => {
            if (this.gridApi === undefined) return;
            this.gridApi.setRowData(HasValue(this.props.filteredData) ? this.props.filteredData : nextProps.tableProperties.data);
            this.totalPages();
            setTimeout(() => {
              this.setPage(0);
            }, 100);
          }
        );
      }
    };

    componentDidMount = () => {
      var pages = [];
      for (var i = 1; i <= this.state.pager.noOfPages; i++) {
        pages.push(i);
      }
      this.setState({ pager: { ...this.state.pager, pages: pages } });
    };

    onGridReady = (params) => {
      this.gridApi = params.api;
      params.api.sizeColumnsToFit();
      window.onresize = () => {
        this.gridApi.sizeColumnsToFit();
      }
      this.setState(
        {
          gridOptions: {
            ...this.state.gridOptions,
            rowData: this.props.tableProperties.data,
          },
        },
        () => {
          this.totalPages();
          setTimeout(() => {
            this.setPage(0);
          }, 100);

          this.props.getGridApi(params.api) // to handle the row deselect function on the outside of this component
        }
      );
    };

    totalPages = () => {
      // call after data has been fetched to compute for the total number of pages
      var dataLength = this.state.gridOptions.rowData.length;
      let pageRows = this.state.gridOptions.paginationPageSize;
      let totalNoPage =
        dataLength % pageRows === 0
          ? dataLength / pageRows
          : (dataLength - (dataLength % pageRows)) / pageRows + 1;
      this.setState({
        pager: {
          ...this.state.pager,
          totalPages: totalNoPage,
        },
      });
    };

    setPage = (pageNo) => {
      //function to change page (custom pagination)
      let page =
        pageNo < 0
          ? 0
          : pageNo > this.state.pager.totalPages
            ? this.state.pager.totalPages
            : pageNo;
      this.gridApi.paginationGoToPage(page);

      //set the visible pages options
      var pages = [];
      var midPageNo =
        this.state.pager.noOfPages % 2 > 0
          ? (this.state.pager.noOfPages - (this.state.pager.noOfPages % 2)) /
          2 +
          1
          : this.state.pager.noOfPages / 2;
      var pageNoStart =
        page + 1 - midPageNo <= 0 ? 1 : page + 1 - midPageNo + 1;

      // when current page belongs to the last pages
      if (
        this.state.pager.totalPages - pageNoStart <
        this.state.pager.noOfPages - 1
      ) {
        pageNoStart =
          this.state.pager.totalPages - this.state.pager.noOfPages + 1;
      }
      var noOfPages = this.state.pager.noOfPages;
      if (midPageNo >= this.state.pager.totalPages) {
        pageNoStart = 1;
        noOfPages = this.state.pager.totalPages;
      }

      if (pageNoStart === 0) {
        pageNoStart = 1;
      }
      pages.push(pageNoStart);

      var dataLength = this.state.gridOptions.rowData.length;
      let pageRows = this.state.gridOptions.paginationPageSize;

      for (var i = 1; i < noOfPages; i++) {
        pageNoStart = pageNoStart + 1;
        if (Math.ceil(dataLength / pageRows) < pageNoStart) {
          break;
        }
        pages.push(pageNoStart);
      }

      this.setState({
        pager: {
          ...this.state.pager,
          currentPage: parseInt(page),
          pages: pages,
        },
      });
    };
    onSelectionChanged = () => {
      var selectedRow = this.gridApi.getSelectedRows();
      this.props.onRowSelect(selectedRow);
    };
    setPageSize = (pageSize) => {
      let numberOfItems = parseInt(pageSize.value);
      this.gridApi.paginationSetPageSize(numberOfItems);
      this.setState(
        {
          gridOptions: {
            ...this.state.gridOptions,
            paginationPageSize: numberOfItems,
          },
          pager: {
            ...this.state.pager,
            pageSize: { value: numberOfItems, label: numberOfItems },
          },
        }, () => {
          this.totalPages();
          setTimeout(() => {
            this.setPage(0);
          }, 100);
        }

      );
    };

    render() {
      return (
        <CVMTablePresentational
          onGridReady={this.onGridReady}
          gridOptions={this.state.gridOptions}
          setPage={this.setPage}
          setPageSize={this.setPageSize}
          pager={this.state.pager}
          gridFunc={this.gridApi}
          onSelectionChanged={this.onSelectionChanged}
          rowSelection={this.state.rowSelection}
        />
      );
    }
  }

  return CVMTableContainer;
})();

export default CVMTable;

CVMTable.defaultProps = {
  getGridApi: () => { }
}