export const SliceLableText = (str, widths, lineCount) => {
  const getLabelLen = (masterParent, str) => {
    let tSpan = document.createElement('tspan');
    tSpan.classList.add('tspanLabel');
    tSpan.innerHTML = str
    masterParent.appendChild(tSpan)
    return tSpan.offsetWidth
  }

  let masterParent = document.getElementById('right-section')
  let label1 = ''
  let label2 = ''

  if (widths.label1 >= getLabelLen(masterParent, str) || widths.label1 >= getLabelLen(masterParent, str.slice(0, -1) + '-')) {
    masterParent.innerHTML = '';
    return [str]
  } else {
    let lastI = 0
    let suffix = lineCount > 1 ? '-' : '...'
    str.split('').every((s, i) => {
      if (widths.label1 < getLabelLen(masterParent, label1 + s + suffix)) {
        lastI = i
        label1 += suffix
        masterParent.innerHTML = '';
        return false
      }
      masterParent.innerHTML = '';
      label1 += s
      return true
    })
    if (lineCount === 1) return [label1]

    if (widths.label2 >= getLabelLen(masterParent, str.substr(lastI))) {
      masterParent.innerHTML = '';
      return [label1, str.substr(lastI)]
    } else {
      str.substr(lastI).split('').every(s => {
        if (widths.label2 < getLabelLen(masterParent, label2 + s + '...')) {
          label2 += '...'
          masterParent.innerHTML = '';
          return false
        }
        masterParent.innerHTML = '';
        label2 += s
        return true
      })
    }
  }
  return [label1, label2]
}
