import { HttpPost } from "./HTTPUtil";

export function IsValid(data) {
    return ([undefined, null, '', 0].indexOf(data) === -1);
}

export function IsValidStr(data) {
    return ([undefined, null, ''].indexOf(data) === -1);
}

export function IsValidText(data) {
    const allowRegex = /^[A-Za-z\w!@#$%&*()\-:;',.?/\\\s]*$/;
    return allowRegex.test(data);
}

export function findInvalidChars(data) {
    const disallowedRegex = /[^A-Za-z\w!@#$%&*()\-:;',.?/\\\s]/g;
    const matches = data.match(disallowedRegex);
    return matches ? matches : [];
}

export const removeInvalidChars = (data) => {
    return data.replace(/[^A-Za-z\w!@#$%&*()\-:;',.?/\\\s]/g, '');
}

export async function IsValidAccentureEmail(data) {
    const response = await HttpPost('User/validateEmail', { 
        isDomainAppended: true,
        users: data
    });
    return response;
}

export function HasValue(data) {
    return ([null, undefined].indexOf(data) === -1)
}

export function HasProperty(obj, prop) {
    if (!IsValid(obj)) return false;
    return obj.hasOwnProperty(prop);
}
export function IsObjEmpty(obj) {
    if (!IsValid(obj)) return true
    return Object.keys(obj).length === 0;
}
export function GetObjectKeyLen(obj) {
    if (!IsValid(obj)) return 0
    return Object.keys(obj).length;
}

export function IsArray(arr) {
    return Array.isArray(arr)
}

export function IsArrayEmpty(arr) {
    if (!IsValid(arr)) return true
    return Array.isArray(arr) && arr.length === 0;
}

export function GetType(param) {
    if (Array.isArray(param)) return 'Array'
    return typeof param
}

export function IsADate(d) {
    return !(String(new Date(d)) === 'Invalid Date')
}

export function TextLengthIsValid(text, length) {
    return text.lenght <= length
}

export function IsValidNum(data) {
    return ([' ', '-', "."].indexOf(data) === -1);
}

export function IsNumZeroesOnly(data) {
    let rev = /[1-9]+/g;
    return rev.test(data);
}

export function isValidURL(url) {
    // let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-%\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    // let rgx = new Regex(pattern, RegexOptions.Compiled | RegexOptions.IgnoreCase);
    return pattern.test(url);
}