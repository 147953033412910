import {
    SET_FILTER_KEYWORD,
    SET_CLIENT_DETAILS,
    GET_REPORTING_YEAR_LIST,
    SET_REPORTING_YEAR,
    SET_WORKFLOW_REPORTING_YEAR,
    GET_CLIENT_ADMIN_LIST,
    GET_OFFERING_LIST,
    GET_MARKET_LIST,
    GET_OP_ACCOUNT_LEAD_LIST,
    GET_CLIENT_TYPE_LIST,
    GET_INDUSTRY_LIST,
    SET_INDUSTRY,
    SET_CLIENT_TYPE,
    SET_OPERATION_ACCT_LEAD,
    SET_REPORTING_YEAR_SELECT,
    SET_MARKET,
    SET_OFFERING,
    SET_CLIENT_ADMIN,
    SET_SHOULD_SHOW_TOASTER_NOTIF,
    ENABLE_ADD_NEW_PROGRAM_BUTTON,
    SET_ADD_NEW_PROGRAM_OFFERING,
    SET_ADD_NEW_PROGRAM_SUB_OFFERING,
    SET_ADD_NEW_PROGRAM_BT_LEAD,
    SET_ADD_NEW_PROGRAM_CX_LEAD,
    SET_ADD_NEW_PROGRAM_IOA,
    SET_ADD_NEW_PROGRAM_NAME,
    GET_CX_LEAD_LIST,
    GET_BT_LEAD_LIST,
    GET_IOA_LIST,
    GET_SUB_OFFERING_LIST,
    ADD_NEW_PROGRAM,
    SHOW_SAVE_AS_DRAFT_TOASTER,
    SET_REQUEST_TARGET_RECEPIENTS,
    SET_ACKNOWLEDGEMENT_RECEPIENTS,
    SET_MODAL_COMMENTS,
    SHOW_HIDE_NOTIFY_MODAL_CONFIG_CLIENT,
    CLEAR_CONFIG_CLIENT_FIELDS,
    SET_BASIC_DETAILS,
    SET_PROGRAM_DETAILS,
    SHOW_NOTIFY_TOAST_SUCCESS,
    SET_BASIC_DETAILS_IS_VALID,
    SET_PROGRAM_IS_VALID,
    SET_ALL_SELECTED_OFFERING,
    SET_ALL_SELECTED_SUB_OFFERING,
    SET_TEMP_SUB_OFFERING_LIST,
    REMOVED_PROGRAM
} from '../Constants'

const initialState = {
    filterKeyword: "",
    clientDetails: {},
    reportingYearList: [],
    selectedReportingYear: [],
    workFlowReportingYear: 0,
    industryList: [],
    clientTypeList: [],
    operationAcctLeadList: [],
    marketList: [],
    offeringList: [],
    clientAdminList: [],
    selectClientAdmin: {},
    selectOffering: {},
    selectMarket: {},
    selectReportingYear: {},
    selectOperationAcctLead: {},
    selectClientType: {},
    selectIndustry: {},
    disableAddNewProgram: true,
    selectAddNewOffering: {},
    selectAddNewSubOffering: {},
    selectAddNewCXLead: {},
    selectAddNewBTLead: {},
    selectAddNewIOA: {},
    programNameValue: "",
    cxLeadList: [],
    btLeadList: [],
    ioaList: [],
    subOfferingList: [],
    programTableProperties: {
        columns: [
            { field: "projectname", headerName: "Program Name" },
            { field: "offering", headerName: "Offering" },
            { field: "btlead", headerName: "BT Lead" },
            { field: "cxlead", headerName: "CX Lead" },
        ],
        data: [],
        rowSelection: "single"
    },
    saveAsDraftToaster: false,
    requestTargetRecepients: [],
    acknowledgementRecepients: [],
    programIdentifiedModalComments: { reqTarget: "", acknowledgement: "" },
    showNotifyModalConfigClient: false,
    showNotifyToastSuccess: false,
    selectedRow: -1,
    clientId: 0,
    basicDetailsIsValid: {
        industry: null,
        clientType: null,
        reportingYear: null,
        market: null,
        offerings: null,
    },
    programIsValid: {
        offering: null,
        subOffering: null,
        btLead: null,
        cxlLead: null,
        ioa: null
    },
    clientOfferingIdList: [],
    disableFields: {
        industry: false,
        market: false,
        clientType: false
    },
    allSubOffering: [],
    allOffering: [],
    tempSubOfferingList: [],
    removedProgram: []
}

export const ClientConfigurationData = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case SET_FILTER_KEYWORD:
            return {
                ...state,
                filterKeyword: action.filterKeyword
            }
        case SET_CLIENT_DETAILS:
            return {
                ...state,
                clientDetails: action.clientDetails
            }
        case GET_REPORTING_YEAR_LIST:
            return {
                ...state,
                reportingYearList: action.reportingYearList
            }
        case SET_REPORTING_YEAR:
            return {
                ...state,
                selectedReportingYear: action.selectedReportingYear
            }
        case SET_WORKFLOW_REPORTING_YEAR:
            return {
                ...state,
                workFlowReportingYear: action.workFlowReportingYear
            }
        case GET_INDUSTRY_LIST:
            return {
                ...state,
                industryList: action.industryList
            }
        case GET_CLIENT_TYPE_LIST:
            return {
                ...state,
                clientTypeList: action.clientTypeList
            }
        case GET_OP_ACCOUNT_LEAD_LIST:
            return {
                ...state,
                operationAcctLeadList: action.operationAcctLeadList
            }
        case GET_MARKET_LIST:
            return {
                ...state,
                marketList: action.marketList
            }
        case GET_OFFERING_LIST:
            return {
                ...state,
                offeringList: action.offeringList
            }
        case GET_CLIENT_ADMIN_LIST:
            return {
                ...state,
                clientAdminList: action.clientAdminList
            }
        case SET_INDUSTRY:
            return {
                ...state,
                selectIndustry: action.selectIndustry
            }
        case SET_CLIENT_TYPE:
            return {
                ...state,
                selectClientType: action.selectClientType
            }
        case SET_OPERATION_ACCT_LEAD:
            return {
                ...state,
                selectOperationAcctLead: action.selectOperationAcctLead
            }
        case SET_REPORTING_YEAR_SELECT:
            return {
                ...state,
                selectReportingYear: action.selectReportingYear
            }
        case SET_MARKET:
            return {
                ...state,
                selectMarket: action.selectMarket
            }
        case SET_OFFERING:
            return {
                ...state,
                selectOffering: action.selectOffering
            }
        case SET_CLIENT_ADMIN:
            return {
                ...state,
                selectClientAdmin: action.selectClientAdmin
            }
        case ENABLE_ADD_NEW_PROGRAM_BUTTON:
            return {
                ...state,
                disableAddNewProgram: action.disableAddNewProgram
            }
        case SET_ADD_NEW_PROGRAM_OFFERING:
            return {
                ...state,
                selectAddNewOffering: action.selectAddNewOffering
            }
        case SET_ADD_NEW_PROGRAM_SUB_OFFERING:
            return {
                ...state,
                selectAddNewSubOffering: action.selectAddNewSubOffering
            }
        case SET_ADD_NEW_PROGRAM_BT_LEAD:
            return {
                ...state,
                selectAddNewBTLead: action.selectAddNewBTLead
            }
        case SET_ADD_NEW_PROGRAM_CX_LEAD:
            return {
                ...state,
                selectAddNewCXLead: action.selectAddNewCXLead
            }
        case SET_ADD_NEW_PROGRAM_IOA:
            return {
                ...state,
                selectAddNewIOA: action.selectAddNewIOA
            }
        case SET_ADD_NEW_PROGRAM_NAME:
            return {
                ...state,
                programNameValue: action.programNameValue
            }
        case GET_CX_LEAD_LIST:
            return {
                ...state,
                cxLeadList: action.cxLeadList
            }
        case GET_BT_LEAD_LIST:
            return {
                ...state,
                btLeadList: action.btLeadList
            }
        case GET_IOA_LIST:
            return {
                ...state,
                ioaList: action.ioaList
            }
        case GET_SUB_OFFERING_LIST:
            return {
                ...state,
                subOfferingList: action.subOfferingList
            }
        case ADD_NEW_PROGRAM:
            return {
                ...state,
                programTableProperties: { ...state.programTableProperties, data: action.programTableProperties }
            }
        case SHOW_SAVE_AS_DRAFT_TOASTER:
            return {
                ...state,
                saveAsDraftToaster: action.saveAsDraftToaster
            }

        case SET_REQUEST_TARGET_RECEPIENTS:
            return {
                ...state,
                requestTargetRecepients: action.requestTargetRecepients
            }
        case SET_ACKNOWLEDGEMENT_RECEPIENTS:
            return {
                ...state,
                acknowledgementRecepients: action.acknowledgementRecepients
            }
        case SET_MODAL_COMMENTS:
            return {
                ...state,
                programIdentifiedModalComments: action.programIdentifiedModalComments,
            }
        case SHOW_HIDE_NOTIFY_MODAL_CONFIG_CLIENT:
            return {
                ...state,
                showNotifyModalConfigClient: action.showNotifyModalConfigClient,
            }
        case SHOW_NOTIFY_TOAST_SUCCESS:
            return {
                ...state,
                showNotifyToastSuccess: action.showNotifyToastSuccess,
            }
        case CLEAR_CONFIG_CLIENT_FIELDS:
            return action.payload
        case SET_BASIC_DETAILS:
            return action.payload
        case SET_PROGRAM_DETAILS:
            return action.payload
        case SET_BASIC_DETAILS_IS_VALID:
            return {
                ...state,
                basicDetailsIsValid: action.basicDetailsIsValid
            }
        case SET_PROGRAM_IS_VALID:
            return {
                ...state,
                programIsValid: action.programIsValid
            }
        case SET_ALL_SELECTED_OFFERING:
            return {
                ...state,
                allOffering: action.allOffering
            }
        case SET_ALL_SELECTED_SUB_OFFERING:
            return {
                ...state,
                allSubOffering: action.allSubOffering
            }
        case SET_TEMP_SUB_OFFERING_LIST:
            return {
                ...state,
                tempSubOfferingList: action.tempSubOfferingList
            }
        case REMOVED_PROGRAM:
            return {
                ...state,
                removedProgram: action.removedProgram
            }
        default:
            return state
    }
}