import {
  GET_RVD_BOI_HISTORY
} from "../Constants";
import { GetSubMenuId, HttpGet } from '../Utilities/HTTPUtil';
import { HasProperty } from '../Utilities/Validations';
import { FormDateDDMMMYYY, FormTimeAPM } from '../Utilities/Formatter';

const convertLocal = (datetime) => {
  return FormDateDDMMMYYY(datetime, true) + ' ' + FormTimeAPM(datetime, true)
}

const sortBOI = (boi, dimensionType) => {
  if (dimensionType !== "") {
    boi.sort(function (a, b) {
      return new Date(convertLocal(b[dimensionType])) - new Date(convertLocal(a[dimensionType]));
    });
  }
  return boi;
}

const mapHistory = (dimensionList) => {
  let convertList = [];
  let dimensionType = "";

  if (dimensionList !== null && dimensionList !== undefined) {
    dimensionList.map((dimension) => {
      let convertSubDimList = [];

      dimension.SubDimensionList.map((subDimension) => {
        let convertBOIList = [];

        subDimension.boiDataList.map(boi => {
          let newBOI = boi;
          if (HasProperty(boi, 'RejectedOn')) {
            newBOI.RejectedOn = convertLocal(boi.RejectedOn);
            dimensionType = 'RejectedOn';
          }
          if (HasProperty(boi, 'ModifiedOn')) {
            newBOI.ModifiedOn = convertLocal(boi.ModifiedOn);
            dimensionType = 'ModifiedOn';
          }
          if (HasProperty(boi, 'DeletedOn')) {
            newBOI.DeletedOn = convertLocal(boi.DeletedOn);
            dimensionType = 'DeletedOn';
          }

          if (HasProperty(newBOI, 'Value')) {
            if (newBOI.Value !== null) {
              if (newBOI.Value.includes('.00')) {
                newBOI.Value = newBOI.Value.slice(0, -3);
              }
            }
          }
          if (HasProperty(newBOI, 'OldValue')) {
            if (newBOI.OldValue !== null) {
              if (newBOI.OldValue.includes('.00')) {
                newBOI.OldValue = newBOI.OldValue.slice(0, -3);
              }
            }
          }
          if (HasProperty(newBOI, 'ModifiedValue')) {
            if (newBOI.ModifiedValue !== null) {
              if (newBOI.ModifiedValue.includes('.00')) {
                newBOI.ModifiedValue = newBOI.ModifiedValue.slice(0, -3);
              }
            }
          }

          convertBOIList.push(newBOI)
        })

        convertSubDimList.push({
          SubDimension: subDimension.SubDimension,
          boiDataList: sortBOI(convertBOIList, dimensionType)
        })

      })

      convertList.push({
        Dimension: dimension.Dimension,
        SubDimensionList: convertSubDimList
      })

    });
  }

  return convertList
}

export const getRVDBOIHistory = (projectID, deliveryCenterID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Data Entry")
    let data = await HttpGet(`ReportValueDelivered/RVDBOIHistory?projectID=${projectID}&deliveryCenterID=${deliveryCenterID}&SubMenuID=${subMenuId}`);

    dispatch({
      type: GET_RVD_BOI_HISTORY,
      rvdBOIHistory: {
        rejectedBOIHistories: mapHistory(data.rejectedBOIHistories),
        modifiedBOIHistories: mapHistory(data.modifiedBOIHistories),
        // deletedBOIHistories: mapHistory(data.deletedBOIHistories)
      }
    });
  } catch (err) {
    console.log(err);
  }
}
