import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GrooveButton, } from "tfo-groove";
import { setCPPointsYesNo } from '../../../../Actions/ConfigureProgram.Action'
require('./CPPointsModal.css');

const CPPointsModal = (function () {
  class CPPointsModalPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="stepperClose-Alert cpModal">
            <div className="stepperClose-Alert-Content">
              <div className="stepperClose-Alert-text cpModal">
                <h5>Please confirm if BA(s) has implemented a new idea/initiative for this client program.</h5>
                <h5>{String(this.props.BAList.join(', '))}</h5>
              </div>
            </div>
          </div>
          <div className="notifyModal-buttons cpModal">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="No"
              callbackFunction={() => this.props.onClickYesNo('no')}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Yes"
              callbackFunction={() => this.props.onClickYesNo('yes')}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class CPPointsModalContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    onClickYesNo = (e) => {
      if (e === 'yes') this.props.handleCPYesNo(true)
      else this.props.handleCPYesNo(false)

      this.props.handleYesNo()
    }
    componentDidMount = () => { };

    render() {
      return (
        <CPPointsModalPresentational
          onClickYesNo={this.onClickYesNo}
          handleYesNo={this.props.handleYesNo}
          handleCPYesNo={this.props.handleCPYesNo}
          BAList={this.props.BAList}
        />
      );
    }
  }

  return CPPointsModalContainer;
})();

export default CPPointsModal;
